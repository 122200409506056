/** This module contains the UsageMonitorService that can be used to query usage data
 *  @module
 */
import { ApiService } from 'utils/services/ApiService';

import { STRINGS } from 'app-strings';

// The URL for the API server.
export const USAGE_MONITORING_BASE_URL = '/api/usage_reporting/1.0/';

export enum dateRangeEnum {
    'currentmonth' = STRINGS.USAGE_MONITORING_PAGE.graphs.dateRange.currentMonth,
    'lastmonth' = STRINGS.USAGE_MONITORING_PAGE.graphs.dateRange.lastMonth,
};

/** this class defines the UsageMonitorApiService. */
class UsageMonitorApiService extends ApiService {
	/** the constructor for the class. */
	constructor() {
		super(USAGE_MONITORING_BASE_URL);
	}

    /** returns the base uri, this can be overridden in subclasses to allow the uri to change
     *      after construction.
     *  @returns a String with the base uri. */
	protected getBaseUri(): string {
        if (ApiService.USE_REGION) {
            const region = ApiService.AUTH_SERVICE.getRegion();
            return `/api/iq/${region}/usage_reporting/1.0/`;    
        } else {
            return this.baseApiUri;
        }
    }

	/** returns node use data.
	 *  @returns a Promise which resolves to the returned nodes use data. */
	getNodeUse(duration: string): Promise<any> {
		const utid = ApiService.AUTH_SERVICE.getTenantId();
		return new Promise((resolve, reject) => {
			super.get<any>(`tenants/${utid}/nodeuse?duration=${duration}`).then(
				(response) => {
					resolve(response);
				},
				(err) => {
					reject(err);
					console.error(err);
				}
			);
		});
	}

	/** returns runbooks use data.
	 *  @returns a Promise which resolves to the returned runbooks use data. */
	getRunbookUse(duration: string): Promise<any> {
		const utid = ApiService.AUTH_SERVICE.getTenantId();
		return new Promise((resolve, reject) => {
			super.get<any>(`tenants/${utid}/runbookuse?duration=${duration}`).then(
				(response) => {
					resolve(response);
				},
				(err) => {
					reject(err);
					console.error(err);
				}
			);
		});
	}

}

/** a constant with an instance of the UsageMonitorApiService. */
const UsageMonitorService = new UsageMonitorApiService();
export { UsageMonitorService };
