import React from 'react';
import { Alignment, Switch, Card, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@tir-ui/react-components';
import { DETECTOR_TYPE_STRINGS } from '../AnalyticsConfigurationPage.tsx';
import { openConfirm } from 'components/common/modal/ConfirmModal.tsx';

import { STRINGS } from 'app-strings';

interface DeviceViewProps {
	objMetricMetaData: any;
	allPolicies: any;
	devicePolicies?: any;
	updatePolicy: Function;
}

/** Renders the  devices view.
 *  @param props the properties passed in.
 *  @returns JSX with the device list page.*/
const DevicesView = (props: DeviceViewProps): JSX.Element => {
	const policies = props.devicePolicies.sort((a, b) =>
		a.metrics[0] > b.metrics[0] ? 1 : b.metrics[0] > a.metrics[0] ? -1 : 0
	);

	const handlePolicyStateChange = (enabled, policy) => {
		const newPolicy = {
			enabled: enabled,
			entityKind: policy.entityKind,
			granularity: policy.granularity,
			metrics: policy.metrics,
			detector: {
				type: policy.detector.type,
				upper: policy.detector.upper,
				lower: policy.detector.lower,
				okValue: policy.detector.okValue,
				percentile: policy.detector.percentile,
				tolerance: policy.detector.tolerance,
				n: policy.detector.n,
				m: policy.detector.m,
			},
			matchFunctions: policy.matchFunctions,
		};
		const allPolicies = [newPolicy].concat(props.allPolicies);

		if (!enabled) {
			openConfirm({
				message: (
					<div
						className="px-2 py-2"
						dangerouslySetInnerHTML={{
							__html: STRINGS.ANALYTICS_CONFIGURATION_PAGE
								.disableMetricMessage,
						}}
					></div>
				),
				onConfirm: () => {
					props.updatePolicy(allPolicies);
				},
				icon: IconNames.INFO_SIGN,
				intent: Intent.PRIMARY,
			});
		} else {
			props.updatePolicy(allPolicies);
		}
	};

	return (
		<div className="mt-4">
			<Card
				key={'device-policies'}
				interactive={false}
				elevation={Elevation.TWO}
			>
				<h5>
					{
						STRINGS.ANALYTICS_CONFIGURATION_PAGE.sections.devices
							.title
					}
				</h5>
				<div className="container-fluid">
					<div className="row mb-3">
						<div className="col-md-3"></div>
						<div className="col-md-3 fw-bold">
							{
								STRINGS.ANALYTICS_CONFIGURATION_PAGE.sections
									.devices.columns.analytics
							}
						</div>
					</div>
					{policies &&
						policies.length > 0 &&
						policies.map((policy) => {
							return (
								<React.Fragment key={Math.random()}>
									<div className="row">
										<div className="col md-3">
											<Switch
												key={policy.metrics[0]}
												id={policy.metrics[0]}
												className="text-start ms-4"
												checked={policy.enabled}
												label={
													STRINGS.METRICS[
														policy.metrics[0]
													]
												}
												alignIndicator={Alignment.LEFT}
												disabled={false}
												onChange={(event) => {
													handlePolicyStateChange(
														event.currentTarget
															.checked,
														policy
													);
												}}
											/>
										</div>
										<div className="col-md-3">
											{policy.detector.type
												? DETECTOR_TYPE_STRINGS[
														policy.detector.type
												  ]
												: ''}
										</div>
										<div className="col-md-6"></div>
									</div>
								</React.Fragment>
							);
						})}
				</div>
			</Card>
		</div>
	);
};

export default DevicesView;
