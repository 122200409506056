import { BehaviorSubject } from "rxjs";
import { filter } from "rxjs/operators"
import type { GlobalEventTypes, WidgetEventTypes } from './EventTypes.ts'
import type { EventBase } from 'reporting-infrastructure/event-hub/base/EventBase.ts'

const globalSubject = new BehaviorSubject<EventBase|null>(null);

// todo: I think we should create the behavior subjects when the 1st real event is fired. A subscriber will get garbage otherwise.

const widgetInteractionSubject = new BehaviorSubject<EventBase|null>(null);

// Thinking... can we create Symbol('globalSub') and Symbol('widgetSub') and use those to parameterize the below functions??
// It looks like we have duplication.
export interface EventMapItem {
    type: GlobalEventTypes | WidgetEventTypes,
    target?:  Array<string>
    source?: Array<string> 
}
export interface EventMap {
    GlobalEvents?: {
        willEmit: Array<EventMapItem >
        listensTo: Array<EventMapItem>
    },
    WidgetEvents?: {
        willEmit: Array<EventMapItem>
        listensTo: Array<EventMapItem>
    }
}


function subscribeToGlobalEvents(subscriber: Function, widgetId: string , eventsList:Array<EventMapItem>) {
    //console.debug(`SUBSCRIBING to Global events for ${widgetId}`);
    const subscription = globalSubject.pipe(filter((e: any) => {
        if (e && eventsList && eventsList.find((event) => e.type === event.type) && e.source !== widgetId) {
            return true;
        }
        return false;
    })).subscribe(result => {
        subscriber(result);
    });
    return subscription;
}

function subscribeToWidgetEvents(subscriber: Function, widgetId: string, eventsList:Array<EventMapItem>) {
    //console.debug(`SUBSCRIBING to Widget events for ${widgetId}`);
    const subscription = widgetInteractionSubject.pipe(filter((e: any) => {
        if (e) {
            // Check if the the current widget (subscriber) is interested in this specific type of event (based on EventMap) 
            if (eventsList && eventsList.find((event) => e.type === event.type) && e.source !== widgetId) {
                // Also verify if the event is targetted towards this widget
                if (e.target && e.target.length > 0) {
                    return e.target.find((targetWidget) => targetWidget === widgetId);
                } else {
                    return true;
                }
            }
        }
        return false;
    })).subscribe(result => {
        subscriber(result);
    });
    return subscription;
}

function resetAllSubjects() {
    globalSubject.next(null);
    widgetInteractionSubject.next(null);
}
export { globalSubject, widgetInteractionSubject, subscribeToGlobalEvents, subscribeToWidgetEvents, resetAllSubjects }

