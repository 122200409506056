import React from 'react';
import {
	Alignment,
	Button,
	Card,
	Elevation,
	Intent,
	Switch,
} from '@blueprintjs/core';
import { IconNames } from '@tir-ui/react-components';
import { defaultFormatter } from 'reporting-infrastructure/utils/formatters/MetricFormatter.ts';
import {
	cleanTypename,
	DETECTOR_TYPE_STRINGS,
	DETECTOR_TYPES,
	getOperation,
} from '../AnalyticsConfigurationPage.tsx';
import { openConfirm } from 'components/common/modal/ConfirmModal.tsx';

import { STRINGS } from 'app-strings';

interface ApplicationViewProps {
	objMetricMetaData: any;
	allPolicies: any;
	applicationPolicies?: any;
	updatePolicy: Function;
	editThreshold: Function;
}

/** Renders the  analytics configuration page.
 *  @param props the properties passed in.
 *  @returns JSX with the device list page.*/
const ApplicationsView = (props: ApplicationViewProps): JSX.Element => {
	const policies = props.applicationPolicies.sort((policyA, policyB) => {
		return (
			policyA.metrics[0].localeCompare(policyB.metrics[0]) ||
			policyA.detector.type.localeCompare(policyB.detector.type)
		);
	});

	const handlePolicyStateChange = (enabled, policy) => {
		const newPolicy = cleanTypename({
			enabled: enabled,
			entityKind: policy.entityKind,
			granularity: policy.granularity,
			metrics: policy.metrics,
			detector: {
				type: policy.detector.type,
				upper: policy.detector.upper,
				lower: policy.detector.lower,
				okValue: policy.detector.okValue,
				percentile: policy.detector.percentile,
				tolerance: policy.detector.tolerance,
				n: policy.detector.n,
				m: policy.detector.m,
			},
			matchFunctions: policy.matchFunctions,
		});
		const allPolicies = [newPolicy].concat(props.allPolicies);
		if (!enabled) {
			openConfirm({
				message: (
					<div
						className="px-2 py-2"
						dangerouslySetInnerHTML={{
							__html: STRINGS.ANALYTICS_CONFIGURATION_PAGE
								.disableMetricMessage,
						}}
					></div>
				),
				onConfirm: () => {
					props.updatePolicy(allPolicies);
				},
				icon: IconNames.INFO_SIGN,
				intent: Intent.PRIMARY,
			});
		} else {
			props.updatePolicy(allPolicies);
		}
	};

	return (
		<div className="mt-4">
			<Card
				key={'application-policies'}
				interactive={false}
				elevation={Elevation.TWO}
			>
				<h5>
					{
						STRINGS.ANALYTICS_CONFIGURATION_PAGE.sections
							.applications.title
					}
				</h5>
				<div className="container-fluid">
					<div className="row mb-3">
						<div className="col-md-3"></div>
						<div className="col-md-3 fw-bold">
							{
								STRINGS.ANALYTICS_CONFIGURATION_PAGE.sections
									.applications.columns.analytics
							}
						</div>
						<div className="col-md-2 fw-bold">
							{
								STRINGS.ANALYTICS_CONFIGURATION_PAGE.sections
									.applications.columns.thresholdConfiguration
							}
						</div>
					</div>
					{policies &&
						policies.length > 0 &&
						policies.map((policy) => {
							const unit =
								props.objMetricMetaData.metrics[
									policy.metrics[0]
								].unit &&
								props.objMetricMetaData.metrics[
									policy.metrics[0]
								].unit !== 'none'
									? props.objMetricMetaData.metrics[
											policy.metrics[0]
									  ].unit
									: '';
							const utilValue =
								policy.detector.type !==
								DETECTOR_TYPES.DYNAMIC_THRESHOLD
									? policy.detector.upper
										? defaultFormatter(
												policy.detector.upper,
												unit
										  )
										: policy.detector.lower
										? defaultFormatter(
												policy.detector.lower,
												unit
										  )
										: ''
									: policy.detector.tolerance
									? defaultFormatter(
											policy.detector.tolerance,
											unit
									  )
									: policy.detector.percentile
									? defaultFormatter(
											policy.detector.percentile,
											unit
									  )
									: '';
							return (
								<React.Fragment key={Math.random()}>
									<div className="row">
										<div className="col md-3">
											<Switch
												key={policy.metrics[0]}
												id={policy.metrics[0]}
												className="text-start ms-4"
												checked={policy.enabled}
												label={
													policy.metrics[0] ===
													'throughput'
														? props
																.objMetricMetaData
																.metrics[
																policy
																	.metrics[0]
														  ].label +
														  ' ' +
														  STRINGS
																.ANALYTICS_CONFIGURATION_PAGE
																.sections
																.applications
																.voipLabel
														: props
																.objMetricMetaData
																.metrics[
																policy
																	.metrics[0]
														  ].label
												}
												alignIndicator={Alignment.LEFT}
												disabled={false}
												onChange={(event) => {
													handlePolicyStateChange(
														event.currentTarget
															.checked,
														policy
													);
												}}
											/>
										</div>
										<div className="col-md-3">
											{policy.detector.type
												? DETECTOR_TYPE_STRINGS[
														policy.detector.type
												  ]
												: ''}
										</div>
										<div className="col-md-4">
											{policy.detector.type !==
												DETECTOR_TYPES.CHANGE &&
											policy.detector.type !==
												DETECTOR_TYPES.BASELINE &&
											policy.detector.type !==
												DETECTOR_TYPES.DYNAMIC_THRESHOLD
												? STRINGS.formatString(
														STRINGS
															.ANALYTICS_CONFIGURATION_PAGE
															.sections
															.applications
															.thresholdText,
														policy.isCustom
															? STRINGS
																	.ANALYTICS_CONFIGURATION_PAGE
																	.customConfig
															: STRINGS
																	.ANALYTICS_CONFIGURATION_PAGE
																	.defaultConfig,
														getOperation(policy),
														utilValue,
														policy.detector.n !==
															null
															? policy.detector.n
															: '',
														policy.detector.m !==
															null
															? policy.detector.m
															: ''
												  )
												: ''}
										</div>
										<div className="col-md-2">
											{policy.detector.type !==
												DETECTOR_TYPES.CHANGE &&
											policy.detector.type !==
												DETECTOR_TYPES.BASELINE &&
											policy.detector.type !==
												DETECTOR_TYPES.DYNAMIC_THRESHOLD &&
											!policy.isSpecialized ? (
												<Button
													small
													aria-label="policy-edit-button"
													icon={IconNames.EDIT}
													className="policy-action-icon"
													disabled={false}
													onClick={() => {
														props.editThreshold(
															policy
														);
													}}
													text={
														STRINGS.runbooks.more
															.editMenuItem
													}
												/>
											) : (
												''
											)}
										</div>
									</div>
								</React.Fragment>
							);
						})}
				</div>
			</Card>
		</div>
	);
};

export default ApplicationsView;
