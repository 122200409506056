import { useEffect, useState } from 'react';
import { 
    getTime, setTime, resetTime, getAbsoluteTime, durationToTimeRange, addChangeCallback, 
    removeChangeCallback, TIME_OBJECT, TIME_RANGE, DURATION, durationToRoundedTimeRange, isCustomTimeSet 
} from 'utils/stores/GlobalTimeStore.ts';

export interface useGlobalTimeProps {
    /** React will not let you use a hook conditionally. So, in a case where
     * you want to use useGlobalTime hoook but based on a condition, you can
     * pass this flag as true to prevent re-renders of the consuming component
     */
    dontUpdateOnTimeChange?: boolean;
}

function useGlobalTime (props?:useGlobalTimeProps) {
    
    const [time, setTimeFromStore] = useState<TIME_OBJECT>(getTime());
    const [absoluteTime, setAbsoluteTimeFromStore] = useState<TIME_RANGE>(getAbsoluteTime() as TIME_RANGE);

    useEffect(() => {
        // As long as this hook is mounted and active, listen to changes happening in
        // the global time singleton store. But if dontUpdateOnTimeChange flag is passed
        // as true, then don't listen to time changes. This can be used to conditionally
        // not re-render a component that uses useGlobalTime hook (e.g. useQuery)
        if (!props?.dontUpdateOnTimeChange) {
            addChangeCallback(syncTimeWithStore);
            return () => {
                // Unsubscribe the callback when unmounting
                removeChangeCallback(syncTimeWithStore);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function syncTimeWithStore () {
        // Whenever a time change happens, fetch a latest copy of and update
        // local state with it so that the consumer of this hook will get re-rendered
        setTimeFromStore(getTime());
        setAbsoluteTimeFromStore(getAbsoluteTime() as TIME_RANGE);
    }

    function setTimeRange (timeRange: TIME_RANGE) {
        setTime(timeRange);
    }
    
    function setDuration (duration: DURATION) {
        setTime({ duration });
    }
    
    return {
        time,
        absoluteTime,
        setTimeRange,
        setDuration,
        // The following are pass-through methods exposed through hook's instance
        durationToTimeRange,
        durationToRoundedTimeRange,
        resetTime,
        isCustomTimeSet,
    };
};

export { useGlobalTime };
