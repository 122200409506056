/** This module defines a React component that displays the impacted user search result.
 *  @module
 */
import { SearchItem, SearchResultsItemProps } from "components/common/search/search-items/search-results-item/SearchResultsItem";
import { LinkInfo, LinkType } from "components/common/search/search-links-group/LinksGroup";
import { STRINGS } from "app-strings";
import { SearchStandardItem } from "components/common/search/search-items/search-standard-item/SearchStandardItem";
import { FACET_FIELDS } from "utils/services/SearchApiService";

/** Renders the search result for the impacted user.
 *  @param data the properties passed in.
 *  @returns JSX with the impacted user search result react component.*/
export function SearchImpactedUserItem({ data }: SearchResultsItemProps) {
    let ip: string | undefined, userName: string | undefined;
    let modifiedData: SearchItem = data;
    try {
        modifiedData = JSON.parse(JSON.stringify(data));
        const impactedUser: string | undefined = data.impactedUser;
        if (impactedUser) {
            [ip, userName] = modifiedData.id.split(";") || [];
            modifiedData.formattedData.title = (userName || ip) + (userName && ip ? " (" + ip + ")" : "");
            if (ip && userName) {
                modifiedData.formattedData.description = STRINGS.formatString(STRINGS.SEARCH.descImpactedUserWithIpAndUserName, {ip, userName});
            } else if (userName) {
                modifiedData.formattedData.description = STRINGS.formatString(STRINGS.SEARCH.descImpactedUserWithUserName, {userName});
            } else {
                modifiedData.formattedData.description = STRINGS.formatString(STRINGS.SEARCH.descImpactedUserWithIp, {ip});
            }
        }    
    } catch (error) {
        console.error(error);
    } 

    const links: Array<LinkInfo> = [
        {
            path: "incident-search",
            label: STRINGS.SEARCH.viewIncidents,
            type: LinkType.PAGE,
            params: {
                searchType: "incident",
                searchText: data.formattedData.description,
                facets: {
                    [FACET_FIELDS.IMPACTED_USER_IP_ADDRESS]: [ip]
                }
            }
        }
    ];
    return <SearchStandardItem data={modifiedData} links={links} showCharts={false} showHealth={false} />;
}
