import { STRINGS } from "app-strings";
import { INVOCATION_TYPE, RunbookInputs } from "utils/services/RunbookApiService.ts";
import { InputConfig, RunbookInputsForm } from "./RunbookInputsForm.tsx";
import { Button, Classes, IconSize } from "@blueprintjs/core";
import { Icon, SuccessToaster } from "@tir-ui/react-components";
import { updateDialogState } from "components/common/basic-dialog/BasicDialog.tsx";
import { setUserPreferences } from "utils/stores/UserPreferencesStore.ts";
import { runbookService } from "utils/runbooks/RunbookUtils.ts";
import { SEARCH_TYPE } from "components/reporting/facet/FacetUtils.ts";
import { getURL } from "utils/hooks/useQueryParams.ts";
import { GetURLPath as getURLPath } from 'config/routes.ts';
import { FACET_FIELDS } from "utils/services/SearchApiService.ts";
import type { OnDemandRunbookInputsPreference } from "utils/services/UserPrefsTypes.ts";

/** handler for the run runbook on demand.
 *  @param setDialogState the function from useState that is used to set the dialog state.
 *  @param runbookId a string with the id of the runbook to run on demand. On the Runbook Analyses page the value is null.
 *  @onDemandRunbookPrefs an object containing the saved user preferences runbook related information. 
 *  @navigate the react-router-dom useNavigate hook object. 
 *  @refreshSearch the function from IncidentSearchPage used to rerun the search query. 
 *  @hasSearchResults a boolean value which is true if the runbook analyses page has search results or false otherwise. */
export function runRunbookOnDemand(setDialogState: Function, runbookId: string | null, onDemandRunbookPrefs: OnDemandRunbookInputsPreference | undefined | null, navigate?: any, refreshSearch?: Function, hasSearchResults?: boolean): void {
    let jsonInput: RunbookInputs | undefined = undefined;
    let inputConfig: InputConfig | undefined = undefined;

    const newDialogState: any = { showDialog: true, title: STRINGS.runbookInvocations.invokeRunbookOnDemandDialogTitle };

    const singleRunbookProps = runbookId ? { selectedRunbook: runbookId } : {};
    
    newDialogState.dialogContent = <>
        <RunbookInputsForm
            allowRunbookSelection={true}
            invocationType={INVOCATION_TYPE.onDemand}
            onRunbookInputChange={(inputs: RunbookInputs | undefined, inputConfigObj: InputConfig) => {
                jsonInput = inputs;
                inputConfig = inputConfigObj;
            }}
            {...singleRunbookProps}
            runOnDemandRunbook={true}
        />
    </>;

    newDialogState.dialogFooter = (<>
        <Button active={true} outlined={true} onClick={async (evt) => {
            if (jsonInput && !jsonInput.errors?.length) {
                const newPrefs = JSON.parse(JSON.stringify(onDemandRunbookPrefs || {runbooks: {}}));
                if (jsonInput?.runbookId) {
                    newPrefs.runbooks[jsonInput.runbookId] = {
                        ...jsonInput, 
                        primarySearchItem: inputConfig?.primarySearchItem, 
                        secondarySearchItem: inputConfig?.secondarySearchItem
                    };
                }
                setUserPreferences({onDemandRunbookInputs: newPrefs});
                try {
                    const runButton: HTMLButtonElement | null = document.querySelector(`.runbook-on-demand-inputs-dialog .${Classes.DIALOG_FOOTER} button`);
                    if (runButton) {
                        runButton.disabled = true;
                    }
                    setDialogState(updateDialogState(newDialogState, true, true, []));
                    await (runbookService as any).runRunbook(jsonInput);    
                    if (jsonInput?.runbookId) {
                        const runbook = await runbookService.getRunbook(jsonInput.runbookId);
                        // create a delay in order to make sure that the executed runbook will be visible on the runbook analyses page
                        await (function() {
                            return new Promise((resolve, _reject) => {
                              setTimeout(function() {
                                resolve("done");
                              }, 10000);
                            });
                        })();
                        newDialogState.dialogContent = <>
                            <p className="runbook-in-progress mb-4">
                                <Icon icon="tick-circle" size={IconSize.STANDARD} intent="success" className="me-2" />
                                {!runbookId ? <span>{STRINGS.runbookInvocations.runbookWillStartMomentarily}</span> : 
                                <span>
                                    {STRINGS.runbookInvocations.runbookWillStartMomentarily} {STRINGS.runbookInvocations.runbookProgressCanBeAccessedFrom} <a href={getURL(getURLPath("runbook-outputs"), {
                                        searchType: SEARCH_TYPE.ondemandrunbooks
                                    }, {replaceQueryParams: true})} target="_blank" rel="noreferrer">{STRINGS.runbookInvocations.runbookAnalyses}</a> page.</span>}</p>
                            <p className="mb-0 text-end">{runbookId && <Button text={STRINGS.runbookInvocations.stayOnPage} active={true} outlined={true} onClick={() => {
                                    setDialogState(updateDialogState(newDialogState, false, false, []));                           
                                }} className="me-2"
                                />}<Button text={STRINGS.runbookInvocations.viewRunbookProgress} active={true} outlined={true} onClick={() => {
                                    if (!runbookId) {
                                        // if runbook was executed from the runbook analyses page
                                        const redirectURL = getURL(getURLPath("runbook-outputs"), {
                                            searchType: SEARCH_TYPE.ondemandrunbooks,
                                            facets: {
                                                [FACET_FIELDS.NAME]: [runbook.name]
                                            },
                                            sortColumn: "timestamp"
                                        }, {replaceQueryParams: true});

                                        if (`${window.location.pathname}${window.location.search}` === redirectURL && refreshSearch) {
                                            refreshSearch(true);
                                        } else {
                                            navigate(redirectURL);
                                        }
                                    } else {
                                        navigate(getURL(getURLPath("runbook-outputs"), {
                                            searchType: SEARCH_TYPE.ondemandrunbooks,
                                            facets: {
                                                [FACET_FIELDS.NAME]: [runbook.name]
                                            },
                                            sortColumn: "timestamp"
                                        }, {replaceQueryParams: true}));
                                    }

                                    setDialogState(updateDialogState(newDialogState, false, false, []));                          
                                }}
                            /></p>
                        </>;
                    } else {
                        setDialogState(updateDialogState(newDialogState, false, false, []));
                        SuccessToaster({
                            message: STRINGS.runbookInvocations.runbookSuccessfullyQueuedMsg
                        });
                    }
                    setDialogState(updateDialogState(newDialogState, true, false, []));
                    if (runButton) {
                        runButton.style.display = "none";
                    }
                    const runRunbookDialog: HTMLDivElement | null = document.querySelector(".runbook-on-demand-inputs-dialog");
                    if (runRunbookDialog) {
                        runRunbookDialog.classList.add("runbook-added-to-queue");
                    }
                } catch (error) {
                    setDialogState(updateDialogState(newDialogState, true, false, [STRINGS.runbookInvocations.launchErrorText]));
                }
            }  else if (jsonInput && jsonInput.errors?.length) {
                setDialogState(updateDialogState(newDialogState, true, false, jsonInput.errors));
                return;
            } else {
                setDialogState(updateDialogState(newDialogState, true, false, [STRINGS.viewRunbooks.inputErrorText]));
                return;
            }
        }} text={STRINGS.runbookInvocations.runBtnText} />
    </>);

    if (!hasSearchResults && !runbookId) {
        newDialogState.dialogContent = <>
            <p>{STRINGS.runbookInvocations.noOnDemandRunbooksDefined}</p>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <p>Go to <a href="#" onClick={(e) => {
                e.preventDefault();
                navigate(getURL(getURLPath("on-demand-runbooks"), {
                    tab: "all"
                }, {replaceQueryParams: true}));
            }}>Automation &gt; On-demand Runbooks</a> page to define some.</p>
        </>;
        newDialogState.dialogFooter = <Button active={true} outlined={true}
            text={STRINGS.runbookOutput.okBtnText}
            onClick={async (evt) => {
                setDialogState(updateDialogState(newDialogState, false, false, []));
            }}
        />;
    }

    setDialogState(newDialogState);
}
