``/** This module contains the component for displaying a navigator widget.  The navigator widget displays
 *      any visualization that is to be displayed in the navigator.
 *  @module
 */
import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { IconNames, useStateSafePromise } from "@tir-ui/react-components";
import { Button, Menu, MenuItem, Popover, PopoverInteractionKind, Position } from "@blueprintjs/core";
import { STRINGS } from "app-strings";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BasicDialog, updateDialogState } from "components/common/basic-dialog/BasicDialog.tsx";
import { LAYOUT_TYPE } from "components/common/vis-framework/layout/Layout.type.ts";
import { INTERACTION_TYPE } from "components/common/vis-framework/widget/Widget.type.ts";
import { 
    Column, DATA_TYPE, DEBUG_OUTPUT_TYPE, DataPoint, DataPointKeys,
    DataSet, DataSetInfo, DebugOutput, GraphqlDataValue,
} from "pages/riverbed-advisor/views/runbook-view/Runbook.type.ts";
import { Widget } from "components/common/vis-framework/widget/Widget.type.ts";
import { GenericKey, NodeUtils } from "utils/runbooks/NodeUtil.ts";
import { createDataset, getMetrics } from "utils/runbooks/RunbookFakeDataUtils.ts";
import NavigatorWidgetConfigDialog from "./NavigatorWidgetConfigDialog.tsx";
import { PARAM_NAME } from "components/enums/QueryParams.ts";
import { useQueryParams } from "utils/hooks/useQueryParams.ts";
import { AutoUpdateContext, USE_DAL } from "pages/navigator/NavigatorPage.tsx";
import { InfoContent } from "pages/riverbed-advisor/views/runbook-view-container/InfoContent.tsx";
import { JsonViewer } from "pages/incident-details/views/primary-indicator/JsonViewer.tsx";
import { createWidget } from "components/common/vis-framework/widget/WidgetFactory.tsx";
import { 
    FILTER_KEYS, FILTER_OPERATORS, FILTER_TYPE, FilterEntry, NAVIGATOR_MODE, NavigatorWidgetConfig, QueryConfig 
} from "pages/navigator/Navigator.type.ts";
import { DataOceanUtils } from "components/common/graph/editors/data-ocean/DataOceanUtils.ts";
import ReactFilterBox, {GridDataAutoCompleteHandler} from "react-filter-box";
import { DataOceanService } from "utils/services/DataOceanApiService.ts";
import { isEqual } from 'lodash';
import datos from './data';
import "react-filter-box/lib/react-filter-box.css";
import "./NavigatorWidget.scss";

//extend this class to add custom operators
class CustomAutoComplete extends GridDataAutoCompleteHandler {
    // override this method to add new your operator
    needOperators(parsedCategory) {
        const result = super.needOperators(parsedCategory);
        return result.filter(x => !["==", "!=", "contains", "!contains"].includes(x)).concat(["equals", "startsWith", "endsWith"]);
    }
}
export enum filterOperator {
    equals      = "EQUAL",
    startsWith  = "STARTS_WITH",
    endsWith    = "ENDS_WITH",
}

// The width of the widget card
const ORIG_CARD_WIDTH: string = "700px";
const ICON_CARD_WIDTH: string = "500px";
// The height of the widget card
const ORIG_CARD_HEIGHT: string = "380px";
const DASH_CARD_HEIGHT: string = "280px";

/** used to toggle the `<ShowMore />` control */
const SHOW_MORE_CONTROLS = true;

/** this interface defines the properties passed into the NavigatorWidget React component. */
export interface NavigatorWidgetProps {
    /** the configuration of this current navigator widget. */
    config: NavigatorWidgetConfig;
    /** the list of all configured widgets, this is needed to setup interactions. */
    widgets: NavigatorWidgetConfig[];
    /** the current LAYOUT_TYPE. */
    layout: LAYOUT_TYPE;
    /** a boolean value, true if the navigator is being edited. */
    isEditing: boolean;
    /** notifies any listeners about a widget edit action. */
    notifyWidgetEditAction?: (type: WidgetEditAction, value?: any) => void;
    /** the handler for interaction events. */
    notifyInteraction?: (type: INTERACTION_TYPE, data: any[]) => void;
    /** notifies when the filter changes in the search control. */
    notifyFiltersetChange: (filterSet: any[]) => void;
    /** notify whether the widget is locked.  This is not the correct terminology and 
     *  should be changed when we figure out the functionality, but right now when you lock, the 
     *  widget will stop opening the add widget sidebar and will send interactions instead. */
    notifyLock: (lock: boolean) => void;
    /** specifies whether widgets are locked. */
    lock: boolean;
}

/** this interface defines the functions available in the navigator widget ref. */
export interface NavigatorWidgetRef {
    /** toggles the edit widget functionality. */
    editWidget: (edit: boolean) => void;
    /** the notification for when new runbook input is received. */
    notifyNewRunbookInput: (entities: any[]) => void;
    /** returns the id of the widget. */
    getId: () => string;
}

/** this enum specifies all the supported widget edit action types.  The widget edit action type is passed
 *  to the handler for widget edit actions.*/
export enum WidgetEditAction {
    /** this enumerated type specifies that the user modified the widget's columns set. */
    COLUMNS_CHANGE = "columns_change",
    /** this enumerated type specifies that the user modified the widget's configuration. */
    CONFIG_CHANGE = "config_change",
    /** this enumerated type specifies that the user deleted the widget. */
    DELETE = "delete_widget",
}

/** Renders the navigator widget.
 *  @param props the properties passed in.
 *  @returns JSX with the navigator widget component.*/
const NavigatorWidget = React.forwardRef<NavigatorWidgetRef, NavigatorWidgetProps>((props: NavigatorWidgetProps, ref: any): JSX.Element => {
    let { params } = useQueryParams({ listenOnlyTo: [PARAM_NAME.debug, PARAM_NAME.navigatorMode] });
    const showDebugInformation = params[PARAM_NAME.debug] === "true";

    const initDialogState = { showDialog: false, title: STRINGS.navigator.debugDialogTitle, loading: false, dialogContent: <></>, dialogFooter: <></> };
    const [dialogState, setDialogState] = useState<any>(initDialogState);
    const [passedEntities, setPassedEntities] = useState<any[]>([]);
    const [tableLimit, setTableLimit] = useState(
        props?.config?.queryConfig?.properties?.limit ?? 10,
    );

    const [isOpen, setIsOpen] = useState<boolean>(false);

    // Filter searchbar vars
    const [exp, setExp] = useState<any[]>([]);
    const [filterError, setFilterError] = useState<boolean>(false);
    const [data] = useState<any[]>(datos);
    
    const [queryString, setQueryString] = useState<string>(getQueryString(props.config.queryConfig?.properties?.filters || []))

    const autoCompleteOptions = (DataOceanUtils.dataOceanMetaData?.obj_types?.[props.config.queryConfig.properties.objType]?.group_by || DataOceanUtils.dataOceanMetaData?.obj_types?.[props.config.queryConfig.properties.objType]?.keys).map(gBy => {
        return {
            columnField: gBy,
            // columnText: DataOceanUtils.dataOceanMetaData.keys[gBy]?.label,
            type: 'selection'
        }
    })

    /** must run before you can crawl - call `npmSearch.run()` for data
     * retrieveal */
    const [npmSearch, setNpmSearch] = useState<{loading: boolean, data: any, error: string | undefined}>(
        {loading: false, data: undefined, error: undefined}
    );
    const queryConfigForSearch = useRef<QueryConfig>(props?.config?.queryConfig);

    useEffect(() => {
            ref({
                editWidget: (edit: boolean): void => {
                    //editCached.current = !editCached.current;
                    //setEdit(editCached.current);
                },
                notifyNewRunbookInput: (entities: any[]): void => {
                    setPassedEntities([...(entities || [])]);
                    setNpmSearch({loading: false, data: undefined, error: undefined});
                },
                getId: (): string => {
                    return props.config.id;
                }
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const autoUpdate = useContext(AutoUpdateContext);

    const [executeSafely] = useStateSafePromise();
    const lastRunQuery = useRef<any>({});

    // NOTE: this `useEffect()` controls the querying logic to retrieve data
    // from DAL via the `props.config.queryConfig.properties`
    useEffect(() => {
        if (!props.isEditing && !npmSearch.loading) {
            try {
                if (props.config?.queryConfig?.properties) {
                    //setLoadingState(true);

                    const objType = props.config.queryConfig.properties.objType;

                    let groupBy = [...(props.config.queryConfig.properties?.groupBy || [])];

                    // Set the filter to be the filter in the query config
                    let filters: FilterEntry[] = [...(props.config.queryConfig.properties?.filters || [])];

                    // Add any filters passed in by interactivity
                    if (passedEntities?.length) {
                        for (const filterKey in passedEntities[0]) {
                            if (filterKey === "keys") {
                                continue;
                            }
                            if (FILTER_KEYS.includes(filterKey)) {
                                filters.push({type: filterKey as FILTER_TYPE, values: [passedEntities[0][filterKey]]});
                            }
                        }
                    }

                    const duration = props.config.queryConfig.properties.duration;
                    const endTime: number = autoUpdate.time || new Date().getTime();
                    const startTime = endTime - (duration ? duration * 1000 : 4 * 60 * 60 * 1000);
                    let time: {startTime: string, endTime: string, granularity?: string} = {
                        startTime: (60 * Math.floor(startTime / 1000 / 60)).toString() + ".000000000", 
                        endTime: (60 * Math.floor(endTime / 1000 / 60)).toString() + ".000000000"
                    };
                    //time = {"startTime": "1684756800.000000000", "endTime": "1707487200.000000000"};
                    // let limit = tableLimit
                    // limit = typeof limit === "number" ? limit || 10 : parseInt(limit);
                    // NOTE: tableLimit defaults to 10, this ensures non-table
                    // widgets will work appropriately
                    let limit = props.config?.queryConfig?.properties.limit || tableLimit;

                    let top = !isNaN(limit) ? limit : 10;

                    if (props.config?.queryConfig?.properties?.timeSeries) {
                        time.granularity = "P1M";
                        if (!DataOceanUtils.dataOceanMetaData.obj_types[objType].group_by_required) {
                            groupBy = ["time", ...groupBy];
                        }
                        //top = top * 60;
                    }

                    const request = {
                        "dataset": objType,
                        "groupBy": groupBy,
                        "keys": groupBy?.length ? groupBy : DataOceanUtils.dataOceanMetaData.obj_types[objType].keys,
                        "metrics": props.config.queryConfig.properties.metrics || [],
                        "filter": {
                            "filters": filters,
                            "time": time
                        },
                        top_by: props.config.queryConfig.properties.topBy,
                        "order": getOrderByFromTopBy(props.config.queryConfig.properties.topBy as {id: string, direction: "desc" | "asc"}[]),
                        "skip": 0,
                        "top": top,
                        "dataSource": props.config.queryConfig.properties.dataSource
                    };

                    (request as any).time_series = props.config?.queryConfig?.properties?.timeSeries === true;
                    if (!isEqual(lastRunQuery.current, request) || (!npmSearch.error && !npmSearch.data)) {
                        lastRunQuery.current = request;
                        const doPromise = executeSafely(DataOceanService.runDataOceanQuery(request));
                        doPromise.then(
                            (result: any) => {
                                queryConfigForSearch.current = props.config.queryConfig;
                                setNpmSearch({loading: false, data: result, error: undefined});
                            },
                            (error) => {
                                queryConfigForSearch.current = props.config.queryConfig;
                                setNpmSearch({loading: false, data: undefined, error: error?.data?.message || "Unknown error"});
                            }
                        );
                        if (!npmSearch.loading) {
                            queryConfigForSearch.current = props.config.queryConfig;
                            setNpmSearch({loading: true, data: undefined, error: undefined});
                        }    
                    }    
                }
            } catch (error) {
                console.log("Could not create the navigator list");
            }
        }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    [
        props.isEditing, props.config?.id, props.config.queryConfig, props.config.options, 
        props.config.widgetType, npmSearch, passedEntities,  tableLimit
    ]);

    // Whenever auto update runs, run the query
    const lastSequenceNumber = useRef(0);
    useEffect(
        () => {
            if (lastSequenceNumber.current !== autoUpdate.sequenceNumber) {
                lastSequenceNumber.current = autoUpdate.sequenceNumber;
                if (!npmSearch.loading) {
                    setNpmSearch({loading: false, data: undefined, error: undefined});
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [autoUpdate]
    );

    // Whenever isEditing changes run the query
    useEffect(
        () => {
            setNpmSearch({loading: false, data: undefined, error: undefined});
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.isEditing]
    );

    const customAutoComplete = new CustomAutoComplete(data, autoCompleteOptions);

    const customRenderCompletionItem = (self, data) => {
        const className = ` hint-value cm-${data.type} ${['(', ')', 'OR'].includes(data.value) && "cm-hide"}`
        return  <div className={className}>
                    <span>{data.value}</span>
                </div>
    }

    // Create the fake data
    let fakeDatasets: Array<DataSet> = useMemo(() => {   
        let datasets: Array<DataSet> = [];   
        const datasetId = "xxxx";
        let isComparison = false;

        // Do we need this check or should we always generate the fake data.
        if (props.isEditing) {
            let limit = props.config.queryConfig?.properties?.limit || 10;
            let metrics = props.config.queryConfig?.properties?.metrics || [];
            const objType = props.config.queryConfig.properties.objType;
            //limit = dataNode ? Math.min(limit, getLimitFromFilters(dataNode)) : limit;
            const isTimeSeries = props.config.queryConfig?.properties?.timeSeries === true;
            const isMultiMetric = metrics?.length > 1;
            isComparison = props.config.queryConfig?.properties?.comparedTo ? props.config.queryConfig.properties.comparedTo !== undefined : false;
            const numDataPoints = isTimeSeries && isMultiMetric ? 1 : Math.min(limit, /*(dataNode ? getMaxLimitByType(dataNode) : 10)*/ 10);
            let keyDefs: Array<GenericKey | Column> = NodeUtils.getExpandedKeys(DataOceanUtils.dataOceanMetaData, objType);
            let groupBys = props.config.queryConfig?.properties?.groupBy || [];
            if (DataOceanUtils.dataOceanMetaData.obj_types[objType].group_by_required) {
                keyDefs = [];
                let expandedKeys: GenericKey[] = NodeUtils.getExpandedKeysForAllGroupBys(DataOceanUtils.dataOceanMetaData, objType);
                expandedKeys.forEach((keyDef) => {
                    if (groupBys.includes(keyDef.id)) {
                        keyDefs.push(keyDef);
                    }
                });
            } else if (groupBys?.length > 0) {
                // If a group by is specified then we only want the keys in the group by, because it does not make sense
                // to show the other keys.  If the group by summed up N rows, then the string keys would just be the key
                // for one of the N rows.
                keyDefs = NodeUtils.getExpandedKeysForKeyList(DataOceanUtils.dataOceanMetaData, groupBys);
            }
            const metricDefs = getMetrics(objType, metrics, DataOceanUtils.dataOceanMetaData);
            const duration = props.config.queryConfig?.properties?.duration || 3600;
            const debug: Array<DebugOutput> = []; //DEBUG_DATA[fakeRunbookOutput.datasets.length % DEBUG_DATA.length];
            const dataset: DataSet = createDataset(
                datasetId, isTimeSeries, duration, numDataPoints, [], keyDefs as Array<Column>,
                metricDefs, debug
            );
            datasets.push(dataset);
            if (isComparison && props.config.queryConfig?.properties?.comparedTo) {
                let comparisonOffset: number = 0;
                switch (props.config.queryConfig.properties?.comparedTo) {
                    case "yesterday":
                        comparisonOffset = 24 * 60 * 60;
                        break;
                    case "last_week":
                        comparisonOffset = 7 * 24 * 60 * 60;
                        break;
                    case "4_weeks_ago":
                        comparisonOffset = 4 * 7 * 24 * 60 * 60;
                        break;
                }
                //const debug: Array<DebugOutput> = DEBUG_DATA[fakeRunbookOutput.datasets.length % DEBUG_DATA.length];
                const dataset: DataSet = createDataset(
                    datasetId, isTimeSeries, duration, numDataPoints, [], keyDefs as Array<Column>,
                    metricDefs, debug, comparisonOffset
                );
                dataset.isComparison = true;
                // We usually call process data set before pushing it on the list
                datasets.push(dataset);
            }
            // This is needed to turn on the interactions, maybe we should do this another way
            updateDatasetsWithEntities(datasets);
        }
        return datasets;
    }, [npmSearch.data, props.isEditing, props.config.queryConfig]);

    let realDatasets: Array<DataSet> = useMemo(() => {   
        let datasets: Array<DataSet> = [];   
        const datasetId = "xxxx";
        let isComparison = false;
        // We only want to generate the real data when we aren't in edit mode and we aren't already loading data.
        if (!props.isEditing && !npmSearch?.loading) {
            if (npmSearch?.data?.searchItems?.page?.length > 0 && isEqual(queryConfigForSearch.current, props.config.queryConfig)) {
                //let limit = props.config.queryConfig?.properties?.limit || 10;
                let metrics = props.config.queryConfig?.properties?.metrics || [];
                const objType = props.config.queryConfig?.properties.objType;
                const isTimeSeries = props.config.queryConfig?.properties?.timeSeries === true;
                isComparison = props.config.queryConfig?.properties?.comparedTo ? props.config.queryConfig.properties.comparedTo !== undefined : false;
                let keyDefs: Array<GenericKey | Column> = NodeUtils.getExpandedKeys(DataOceanUtils.dataOceanMetaData, objType);
                let groupBys = props.config.queryConfig?.properties?.groupBy || [];
                if (DataOceanUtils.dataOceanMetaData.obj_types[objType].group_by_required) {
                    keyDefs = [];
                    const expandedKeys: GenericKey[] = NodeUtils.getExpandedKeysForAllGroupBys(DataOceanUtils.dataOceanMetaData, objType);
                    expandedKeys.forEach((keyDef) => {
                        if (groupBys.includes(keyDef.id)) {
                            keyDefs.push(keyDef);
                        }
                    });    
                } else if (groupBys?.length > 0 && ["app_response_and_profiler", "profiler_traffic"].includes(objType)) {
                    // If a group by is specified then we only want the keys in the group by, because it does not make sense
                    // to show the other keys.  If the group by summed up N rows, then the string keys would just be the key
                    // for one of the N rows.
                    keyDefs = NodeUtils.getExpandedKeysForKeyList(DataOceanUtils.dataOceanMetaData, groupBys);
                } else {
                    keyDefs = NodeUtils.getExpandedKeysForKeyList(DataOceanUtils.dataOceanMetaData, DataOceanUtils.dataOceanMetaData.obj_types[objType].keys);
                }
                const metricDefs = getMetrics(objType, metrics, DataOceanUtils.dataOceanMetaData);
                //const duration = props.config.queryConfig?.properties?.duration || 3600;
                const debug: Array<DebugOutput> = []; //DEBUG_DATA[fakeRunbookOutput.datasets.length % DEBUG_DATA.length];
                
                // Create the data set
                const info: DataSetInfo = {actualTimes: [], dataSources: []};
                if (npmSearch!.data.searchItems.info) {
                    // The info format is different than the format from DAL
                    if (npmSearch!.data.searchItems.info.actual_times?.length) {
                        for (const actualTime of npmSearch!.data.searchItems.info.actual_times) {
                            info.actualTimes!.push({startTime: actualTime.start, endTime: actualTime.end, granularities: actualTime.granularities});
                        }
                    }
                    if (npmSearch!.data.searchItems.info.data_sources?.length) {
                        for (const dataSource of npmSearch!.data.searchItems.info.data_sources) {
                            info.dataSources!.push({type: dataSource.type, name: dataSource.name, url: dataSource.hostname});
                        }
                    }
                    if (npmSearch!.data.searchItems.info.actual_filters) {
                        info.actualFilters = JSON.stringify(npmSearch!.data.searchItems.info.actual_filters);
                    }
                }
                let refId = "", timeReference: any = undefined;
                //if (comparisonOffset > 0) {
                //    refId = ":ref1";
                //    timeReference = {name: "ref1", startTime: String(now - comparisonOffset - duration), endTime: String(now - comparisonOffset)};
                //}

                const datapoints: Array<DataPoint> = [];
                for (const dataPoint of npmSearch?.data?.searchItems?.page) {
                    datapoints.push(dataPoint);
                }

                const dataset: DataSet = {
                    id: datasetId + refId,
                    type: isTimeSeries ? DATA_TYPE.TIMESERIES : DATA_TYPE.SUMMARY,
                    timeReference,
                    keys: keyDefs as Array<Column>,
                    metrics: metricDefs,
                    datapoints,
                    info,
                    debug
                };
                datasets.push(dataset);
                updateDatasetsWithEntities(datasets);
            }
        }
        return datasets;
    }, [npmSearch.data, props.isEditing, props.config.queryConfig]);

    const widget = { id: props.config.id, name: props.config.name, options: props.config.options } as Widget;
    const setTableLimitHandler = (item: number) => {
        setTableLimit(item);
    };
/*
    if (isComparison && widget.options && dataNode) {
        widget.options.comparedTo = dataNode.properties.comparedTo;
    }
*/

    // Create the widget component
    const datasets = props.isEditing ? fakeDatasets : realDatasets;
    let widgetComponent: JSX.Element = createWidget(
        props.config.widgetType, widget, datasets, npmSearch.loading, npmSearch.error, props.notifyInteraction, 
        setTableLimitHandler, tableLimit, SHOW_MORE_CONTROLS
    );

    return <div
        id={props.config.id}
        style={props.layout !== LAYOUT_TYPE.CUSTOM ? {
            width: (props.layout !== LAYOUT_TYPE.VERTICAL ? params.navigatorMode === NAVIGATOR_MODE.icons ? ICON_CARD_WIDTH : ORIG_CARD_WIDTH : "98.6%"), 
            height: params.navigatorMode === NAVIGATOR_MODE.dashboard ? DASH_CARD_HEIGHT : ORIG_CARD_HEIGHT,
            borderRadius: "10px"
        } : {}} className={props.layout !== LAYOUT_TYPE.CUSTOM ? "me-4 my-3 bg-light overflow-auto workspace-widget widget-item" : 'widget-item'}>

        <BasicDialog dialogState={dialogState} className="navigator-page-dialog" onClose={() => setDialogState(updateDialogState(dialogState, false, false, []))} />
        <div className="workspace-widget-top bg-light">
            <div className={`${props.layout === LAYOUT_TYPE.CUSTOM ? "widget-headings" : ''} bg-light d-flex flex-row justify-content-between p-2`} style={{ width: "100%" }} >
                <span className={"display-8 fw-bold mt-1"}>{props.config.name}</span>
                <Popover
                    position={Position.BOTTOM_RIGHT}
                    interactionKind={PopoverInteractionKind.CLICK}
                    content={
                        <Menu>
                            {params.navigatorMode !== NAVIGATOR_MODE.dashboard &&
                                <MenuItem text={"Edit Widget"} icon={IconNames.EDIT} onClick={(e) => {
                                    if (props.notifyWidgetEditAction) {
                                        props.notifyWidgetEditAction(WidgetEditAction.COLUMNS_CHANGE);
                                    }
                                }} />
                            }
                            {params.navigatorMode === NAVIGATOR_MODE.dashboard && props.isEditing &&
                                <MenuItem text={"Configure"} icon={IconNames.COG} onClick={(e) => {
                                    //showRunbookEditorDialog(runbookConfig, dialogState, setDialogState);
                                    setIsOpen(true);
                                }} />
                            }
                            {!props.isEditing &&
                                <>
                                    <MenuItem text={"Information"} icon={IconNames.INFO_SIGN} onClick={(e) => {
                                        showInfoDialog(datasets || [], dialogState, setDialogState, showDebugInformation);
                                    }} />
                                    <MenuItem text={"Diagnosis"} icon={IconNames.DIAGNOSIS} onClick={(e) => {
                                        showDebugDialog(datasets || [], dialogState, setDialogState);
                                    }} />
                                </>
                            }
                            {params.navigatorMode === NAVIGATOR_MODE.icons &&
                                <MenuItem text={"Lock"} icon={props.lock ? IconNames.UNLOCK : IconNames.LOCK} onClick={(e) => {
                                    if (props.notifyLock) {
                                        props.notifyLock(!props.lock);
                                    }
                                }} />

                            }
                            {(params.navigatorMode !== NAVIGATOR_MODE.dashboard || props.isEditing) &&
                                <MenuItem text={"Remove"} icon={IconNames.CROSS} onClick={(e) => {
                                    if (props.notifyWidgetEditAction) {
                                        props.notifyWidgetEditAction(WidgetEditAction.DELETE);
                                    }
                                }} />

                            }

                        </Menu>}
                >
                    <Button
                        aria-label="runbook-more-button"
                        icon={IconNames.MORE}
                        minimal
                        className="runbook-action-icon"
                        disabled={false}
                        onClick={(e) => { }}
                    />
                </Popover>
            </div>
            {params.navigatorMode === NAVIGATOR_MODE.original && <>
                <div className="w-5 ps-2 pe-0" style={{ display: "inline-table" }}>
                    <ReactFilterBox
                        autoCompleteHandler={customAutoComplete}
                        customRenderCompletionItem={customRenderCompletionItem}
                        options={autoCompleteOptions}
                        data={data}
                        query={queryString}
                        onParseOk={(expressions) => setExp(expressions)}
                        onChange={(query, result) => {
                            setFilterError(result.isError);
                            setQueryString(query);
                        }}
                    />
                </div>
                <Button style={{ marginBottom: "5px" }}
                    icon={IconNames.SMALL_CROSS}
                    minimal
                    disabled={filterError}
                    onClick={() => {
                        setQueryString("")
                        props.notifyFiltersetChange([]);
                    }} />
                <Button style={{ marginBottom: "5px" }}
                    icon={IconNames.SEARCH}
                    intent={"primary"}
                    disabled={filterError}
                    onClick={() => {
                        props.notifyFiltersetChange(exp);
                    }} />
            </>}
        </div>
        
        {widgetComponent}

        <NavigatorWidgetConfigDialog config={props.config} widgets={props.widgets} isOpen={isOpen}
            handleConfigChange={(config: NavigatorWidgetConfig) => {
                if (props.notifyWidgetEditAction) {
                    props.notifyWidgetEditAction(WidgetEditAction.CONFIG_CHANGE, config);
                }
            }}
            handleDialogClose={() => {
                setIsOpen(false);
            }}
        />
    </div>;
});

export default NavigatorWidget;

/** Updates the dataset rows with the entity that represents the row.
 *  @param {array} datasets the array of DataSets to add entities to. */
export function updateDatasetsWithEntities(datasets: DataSet[]): void {
    datasets.forEach((dataset) => {
        const keyDefs = dataset.keys;
        dataset.datapoints?.forEach((datapoint) => {
            // Assuming `datapoint.keys` can be directly accessed and modified.
            if (isDataPointKeys(datapoint.keys)) {
                const keys = datapoint.keys;
                let group: any = {};

                if (USE_DAL) {
                    // Populate the group object with the keys that are present in `FILTER_KEYS`
                    Object.entries(keys).forEach(([key, value]) => {
                        if (FILTER_KEYS.includes(key)) {
                            group[key] = value;
                        }
                    });    
                } else {
                    let keyHierarchy = convertKeysAndDataToOject(keyDefs as any as Column[], keys as Record<string, string>);
                    if (keyHierarchy?.npm_plus) {
                        // Need to remove the npm_plus in front of the keys.  We might need to revisit this later.
                        keyHierarchy = keyHierarchy.npm_plus;
                    }
                    for (const key in keyHierarchy) {
                        if (["network_host", "network_server", "network_client", "network_device"].includes(key)) {
                            group[key] = keyHierarchy[key].ipaddr;
                        } else if (["network_interface"].includes(key)) {
                            // As an object
                            //group[key] = {ipaddr: keyHierarchy[key].ipaddr, ifindex: keyHierarchy[key].ifindex};
                            // as a string ipaddress:ifindex
                            group[key] = keyHierarchy[key].ipaddr + ":" + keyHierarchy[key].ifindex;
                        } else if (["protocol", "dscp"].includes(key)) {
                            group[key] = keyHierarchy[key].number;
                            //group[key] = keyHierarchy[key].name;
                        } else if (["data_source"].includes(key)) {
                            if (keyHierarchy[key].id) {
                                group[key] = keyHierarchy[key].id;
                            }
                        } else if (["user_device"].includes(key)) {
                            if (keyHierarchy[key].device_name) {
                                group[key] = keyHierarchy[key].device_name;
                            }
                        } else {
                            if (keyHierarchy[key]?.name) {
                                group[key] = keyHierarchy[key].name;
                            } else if (typeof keyHierarchy[key] === "string") {
                                group[key] = keyHierarchy[key];
                            } else {
                                // This is not good, cannot handle process_name keys
                                group[key] = "unknown";
                            }
                        }
                    }
                    // This seems a little odd because we could just use the keys itself, but this allows 
                    // us to tack on additional information if we need to
                    group.keys = {...keys};
                }

                if (!keys.group) {
                    keys.group = group;
                }
            }
        });
    });
}

/** convert the keys and data to an object.  Here is an example of the format of the object for a host
 *  {network_host: {
 *      ipaddr: {type: "ipaddr", value: "1.1.1.1"},
 *      name: {type: "string", value: "www.riverbed.com"},
 *      location: {
 *          name: {type: "string", value: "Boston"}
 *      }
 *  }}
 *  @param keyDefs the definition of the keys.
 *  @param dataKeys the values for the keys in the data.
 *  @returns an object with the values. */
export function convertKeysAndDataToOject(keyDefs: Column[], dataKeys: Record<string, string>): any {
    const outputObject = {};
    for (const column of keyDefs) {
        const fieldNames: Array<string> = column.id.split(".");
        let objLocation = outputObject;
        for (let index = 0; index < fieldNames.length - 1; index++) {
            if (!objLocation[fieldNames[index]]) {
                objLocation[fieldNames[index]] = {};
            }
            objLocation = objLocation[fieldNames[index]];
        }
        objLocation[fieldNames[fieldNames.length -1]] = dataKeys[column.id];
    }
    return outputObject;
}


/** converts a filter into a query string.
 *  @param filters the array of FilterEntrys.
 *  @return a String with the query information. */
function getQueryString(filters: FilterEntry[]): string {
    let queryString: string = "";
    /* Filter Changes - Not Ready
    for (const filter of filters) {
        if (filter.type === FILTER_TYPE.keys) {
            const value = filter.values.length ? filter.values[0] : {};
            for (const filterKey in value) {

            }
        }
    }
    */
    /**/
    for (const filter of filters) {
        let operator: string = "equals";
        switch (filter.operator) {
            case FILTER_OPERATORS.EQUAL:
                operator = "equals";
                break;
            case FILTER_OPERATORS.STARTS_WITH:
                operator = "startsWith";
                break;
            case FILTER_OPERATORS.ENDS_WITH:
                operator = "endsWith";
                break;
        }
        let value = filter.values?.length ? filter.values[0] : "Unknown";
        if (value.includes(" ")) {
            value = "\"" + value + "\"";
        }
        queryString += (queryString.length !== 0 ? " AND " : "") + filter.type + " " + operator + " " + value;
    }
    /**/
    return queryString;
}

/** predicate type guard to determine what `keys` are, used in
 * `updateDatasetsWithEntities()` */
const isDataPointKeys = (
    keys: DataPointKeys | GraphqlDataValue[],
): keys is DataPointKeys => {
    return Array.isArray(keys) === false;
};

/** Creates a popup that displays the widget query information.  Right now the popup assumes there is only one 
 *      time range per widget and one data source per widget.  This can easily be expanded to the case where
 *      there is more than one time range or data source.
 *  @param datasets the Array of DataSets to use to obtain the DataSetInfo object with the information from the Data Ocean.
 *  @param dialogState the copied state object with the state setup to open the dialog.  The content
 *      needs to be appended and the title needs to be set in this function.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function.
 *  @param debug a boolean value, if true show debug information, if false, do not. */
function showInfoDialog(
    datasets: Array<DataSet>, dialogState: any, setDialogState: (dialogState: any) => void, debug: boolean
): void {
    const newDialogState = Object.assign({}, dialogState);
    newDialogState.title = STRINGS.runbookOutput.infoDialogTitle;
    newDialogState.showDialog = true;
    newDialogState.dialogContent = <InfoContent datasets={datasets} />;
    newDialogState.dialogFooter = <>
        <Button active={true} outlined={true}
            text={STRINGS.runbookOutput.okBtnText}
            onClick={async (evt) => {
                setDialogState(updateDialogState(newDialogState, false, false, []));
            }}
        />
    </>;
    setDialogState(newDialogState);
}

/** Creates a popup that displays the widget debug information.  The debug information is passed in the 
 *  dataset.
 *  @param datasets the Array of DataSets to use to obtain the DataSetInfo object with the debug information.
 *  @param dialogState the copied state object with the state setup to open the dialog.  The content
 *      needs to be appended and the title needs to be set in this function.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function. */
function showDebugDialog(
    datasets: Array<DataSet>, dialogState: any, setDialogState: (dialogState: any) => void
): void {
    let jsonOutput = {};
    if (datasets) {
        let dsIndex = 1;
        for (const dataset of datasets) {
            if (dataset) {
                const dsName = "Dataset: " + dsIndex++;
                if (dataset.debug?.length) {
                    jsonOutput[dsName] = {};
                    for (const debugOutput of dataset.debug) {
                        if (debugOutput.type === DEBUG_OUTPUT_TYPE.JSON) {
                            jsonOutput[dsName][debugOutput.name] = JSON.parse(debugOutput.value);
                        }
                    }
                }
            }
        }
    }

    const newDialogState = Object.assign({}, dialogState);
    newDialogState.title = STRINGS.runbookOutput.debugDialogTitle;
    newDialogState.showDialog = true;
    newDialogState.dialogContent = <JsonViewer json={jsonOutput} />;
    newDialogState.dialogFooter = <>
        <CopyToClipboard text={JSON.stringify(jsonOutput || {}, null, 4)}>
            <Button active={true} outlined={true}
                text={STRINGS.primaryIndicatorView.copyBtnText} onClick={() => {
                    setDialogState(updateDialogState(newDialogState, false, false, []));
                }}
            />
        </CopyToClipboard>
        <Button active={true} outlined={true}
            text={STRINGS.runbookOutput.okBtnText}
            onClick={async (evt) => {
                setDialogState(updateDialogState(newDialogState, false, false, []));
            }}
        />
    </>;
    setDialogState(newDialogState);
}

/** converts the widget's topBy configuration to the orderBy object required by DAL.
 *  @param topBy an object with the top by information for the query as stored in the widget's configuration.
 *  @returns the graphql order by expression. */
function getOrderByFromTopBy(topBy: {id: string, direction: "desc" | "asc"}[]): {key: string, order: "ASCENDING" | "DESCENDING"}[] {
    const orderBy: {key: string, order: "ASCENDING" | "DESCENDING"}[] = [];
    if (topBy?.length) {
        for (const topByItem of topBy) {
            orderBy.push({key: topByItem.id, order: topByItem.direction === "desc" ? "DESCENDING" : "ASCENDING"});
        }
    }
    return orderBy;
}
