/** This module contains the component for displaying the dashboard widget config dialog.  The dashboard widget config
 *      dialog allows the user to configure the widget runbook and visual configuration.
 *  @module
 */
import { useRef, useState } from "react";
import { DialogStep, MultistepDialog } from "@blueprintjs/core";
import { STRINGS } from "app-strings";
import { InteractionConfig } from "components/common/vis-framework/widget/Widget.type";
import CreateRunbookView from "pages/create-runbook/views/create-runbook/CreateRunbookView";
import { RunbookConfig } from "utils/services/RunbookApiService";
import WidgetConfigVisualizationStep, { WidgetConfigVisualizationStepRef } from "./WidgetConfigVisualizationStep";
import { WIDGET_TYPE } from "components/common/vis-framework/widget/Widget.type";
import { Resizable, HandleComponent } from 're-resizable';
import { Icon, IconNames } from "@tir-ui/react-components";
import DashboardNodeLibrary from "pages/create-runbook/views/create-runbook/dashboard_node_library.json";
import { NodeLibrary, NodeLibrarySpec } from "pages/create-runbook/views/create-runbook/NodeLibrary";
import InteractionConfigStep from "./InteractionConfigStep";
import { Variant } from "components/common/graph/types/GraphTypes";
import incidentRunbookExclusions from 'utils/runbooks/connection_exclusions.json';
import { DashboardWidgetConfig } from "pages/dashboards/Dashboard.type";
import "./DashboardWidgetConfigDialog.scss";

/** this interface defines the properties passed into the DashboardWidgetConfigDialog React component. */
export interface DashboardWidgetConfigDialogProps {
    /** a boolean value, which if true specifies that the dialog should be open. */
    isOpen: boolean;
    /** the configuration of this current dashboard widget. */
    config: DashboardWidgetConfig;
    /** the list of all configured widgets, this is needed to setup interactions. */
    widgets: DashboardWidgetConfig[];
    /** the handler for config change events. */
    handleConfigChange?: (config: DashboardWidgetConfig) => void;
    /** the handler for dialog close events. */
    handleDialogClose: () => void;
}

/** Renders the dashboard widget config dialog.
 *  @param props the properties passed in.
 *  @returns JSX with the dashboard widget config dialog component.*/
const DashboardWidgetConfigDialog = (props: DashboardWidgetConfigDialogProps): JSX.Element => {
    const [runbookConfig, setRunbookConfig] = useState<RunbookConfig | undefined>(props.config.runbookConfig);

    const [widgetName, setWidgetName] = useState<string>(props.config.name);
    const [widgetType, setWidgetType] = useState<WIDGET_TYPE>(props.config.widgetType);
    const [widgetOptions, setWidgetOptions] = useState<any>(props.config.options || {});

    const [interactions, setInteractions] = useState<InteractionConfig[]>(props.config.interactions || []);

    const nodeLibrary = useRef<NodeLibrary>(new NodeLibrary(DashboardNodeLibrary as NodeLibrarySpec));

    const visEditorRef = useRef<WidgetConfigVisualizationStepRef | undefined>(undefined);

    const handler: HandleComponent = {
        left: <div style={{
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 100
        }}><Icon className="drag-handle" icon={IconNames.DRAG_HANDLE_VERTICAL}/></div>
    };
    return <div id="multistep_dialog" style={{width: "1800px!important"}}>
        <MultistepDialog
            className="edit-dashboard-dialog main-content"
            onClose={() => {props.handleDialogClose()}/*clearValues*/}
            //finalButtonProps={finalButtonProps}
            title={STRINGS.viewDashboard.debugDialogTitle}
            autoFocus={true}
            canEscapeKeyClose={true}
            canOutsideClickClose={false}
            enforceFocus={true}
            isCloseButtonShown={true}
            isOpen={props.isOpen}
            resetOnClose={true}
            finalButtonProps={{
                onClick: (event) => {
                    if (props.handleConfigChange) {
                        let options = visEditorRef.current?.getOptions();
                        if (!options) {
                            options = widgetOptions;
                        }
                        props.handleConfigChange(
                            {
                                id: props.config.id, name: widgetName, widgetType, 
                                runbookConfig: runbookConfig, options: widgetOptions, interactions
                            }
                        );
                    }
                    props.handleDialogClose();
                }
            }}
            onClosed={() => {
                if (props.handleConfigChange) {
                    let options = visEditorRef.current?.getOptions();
                    if (!options) {
                        options = widgetOptions;
                    }
                    props.handleConfigChange(
                        {
                            id: props.config.id, name: widgetName, widgetType, 
                            runbookConfig: runbookConfig, options: widgetOptions, interactions
                        }
                    );
                }
                props.handleDialogClose();
            }}
            onChange={(newDialogStepId, prevDialogStepId, event) => {
                if (prevDialogStepId === "visualization-step") {
                    let options = visEditorRef.current?.getOptions();
                    if (options) {
                        setWidgetOptions(options);
                    }
                }
                console.log(newDialogStepId + ", " + prevDialogStepId);
            }}
        >
            <DialogStep
                id="runbook-step"
                className="edit-dashboard-dialog panel-content"
                panel={/*<div style={{position: "relative"}}>*/<>
                    <div className="main-layout d-flex flex-column h-100 container-fluid test" onClick={() => {}/*hideSidebarOnClick*/}>
                        <div className="row flex-grow-1 overflow-auto position-relative">
                            <div className="col d-flex p-0 h-100 flex-column container overflow-auto">
                                <CreateRunbookView key="create" editorHidden={false} inDialog={true}
                                    nodeLibrary={nodeLibrary.current}
                                    onPreview={(runbook: RunbookConfig) => {
                                    }} 
                                    onTest={(runbook: RunbookConfig) => {
                                    }}
                                    runbookConfig={runbookConfig}
                                    onChange={(runbook: RunbookConfig) => {
                                        setRunbookConfig(runbook);
                                    }}
                                    variant={Variant.INCIDENT}
                                    exclusions={incidentRunbookExclusions}
                                />
                            </div>
                            <Resizable
                                handleComponent={handler}
                                className="resizable-dialog-details-panel d-none"
                                minWidth="200px"
                                maxWidth="80%"
                                maxHeight="100%"
                                //defaultSize={{width: "200px", height: "100%"}}
                                style={{position: "absolute", top: "0", right: "0", bottom: "0", zIndex: "5"}}
                                enable={{top:false, right:false, bottom:false, left:true, topRight:false, bottomRight:false, bottomLeft:true, topLeft:true}}
                            >
                                <div id="dialog-details-pane" className="d-none flex-grow-0 p-0 bg-light shadow h-100 col-12 col-md-auto border-start resizable">
                                </div>
                            </Resizable>
                        </div>
                    </div>
                    {false && <div className="d-flex">
                        <CreateRunbookView 
                            nodeLibrary={nodeLibrary.current}
                            key="create" editorHidden={false} inDialog={true}
                            onPreview={(runbook: RunbookConfig) => {
                            }} 
                            onTest={(runbook: RunbookConfig) => {
                            }}
                            runbookConfig={runbookConfig}
                            onChange={(runbook: RunbookConfig) => {
                                setRunbookConfig(runbook);
                            }}
                            variant={Variant.INCIDENT}
                            exclusions={incidentRunbookExclusions}
                        />
                        <div id="dialog-details-pane" className="bg-light" style={{height: "810px", overflowY: "auto", backgroundColor: "rgb(248, 249, 250)", zIndex: 100}}></div>
                    </div>}
                </>/*</div>*/}
                title={STRINGS.viewDashboard.runbookStepTitle}
                nextButtonProps={{
                    disabled: false
                }}
            />
            <DialogStep
                id="visualization-step"
                className="edit-dashboard-dialog panel-content"
                panel={
                    <WidgetConfigVisualizationStep 
                        config={{
                            id: props.config.id, name: widgetName, widgetType, options: widgetOptions,
                            runbookConfig: runbookConfig, interactions
                        }} 
                        //key={"visualizations-step-" + Math.random()}
                        runbookConfig={runbookConfig}
                        onWidgetNameChange={(name: string) => {
                            setWidgetName(name);
                        }}
                        onWidgetTypeChange={(type: WIDGET_TYPE) => {
                            setWidgetType(type);
                        }}
                        ref={(element: WidgetConfigVisualizationStepRef) => visEditorRef.current = element}
                    />
                    /*<BasicDetailsPanel
                        onChangeGatewayName={
                            handleGatewayNameChange
                        }
                        onChangeGatewayType={
                            handleGatewayTypeChange
                        }
                        gatewayNameValue={gatewayName}
                        gatewayTypeValue={gatewayType}
                    />*/
                }
                title={STRINGS.viewDashboard.visualizationStepTitle}
                nextButtonProps={{
                    disabled: false
    /*
                        gatewayName === '' ||
                        gatewayType === '' ||
                        gatewayType ===
                            STRINGS.DATA_SOURCES
                                .addEdgeGatewayDialog.panels
                                .basicDetails.fields.chooseType,
    */
                }}
            />
            <DialogStep
                id="interaction-step"
                className="edit-dashboard-dialog panel-content"
                panel={
                    <InteractionConfigStep 
                        config={{
                            id: props.config.id, name: widgetName, widgetType, options: widgetOptions,
                            runbookConfig: runbookConfig, interactions
                        }} 
                        widgets={props.widgets}
                        onInteractionsChange={(passedInteractions: InteractionConfig[]) => {
                            setInteractions(passedInteractions || []);
                        }}
                    />
                }
                title={STRINGS.viewDashboard.interactionStepTitle}
                nextButtonProps={{
                    disabled: false
                }}
            />
        </MultistepDialog>
    </div>;
} 

export default DashboardWidgetConfigDialog;
