/** This module contains a component that represents a decision output block
 *  @module
 */
import React, { useState, useEffect, useRef } from 'react';
import { Button, Classes, IconName, PopoverPosition, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { STRINGS } from 'app-strings';
import { Icon } from '@tir-ui/react-components';
import { Radio, RadioGroup, InputGroup } from '@blueprintjs/core';
import { ConditionBlockOrchestratorOperationType, OptionSetByType } from 'components/hyperion/views/decision-branch/DecisionBranchUtils.ts';
import { DecisionBranchView } from 'components/hyperion/views/decision-branch/DecisionBranchView.tsx';
import "./DecisionNodeEditor.scss";

export enum OutputPassedDataOptions {
    MATCHED = "MATCHED",
    ALL     = "ALL",
}

export interface OutputBlockDefinition {
    id: string;
    passedData: OutputPassedDataOptions;
    expression: ConditionOrchestratorFormat | ConditionBlockOrchestratorFormat;
    label?: string;
}

export interface DefaultOutputDefinition {
    id: "default";
    label?: string;
}
export interface ConditionOrchestratorFormat {
    id: string;
    type: "condition";
    category: string;
    key: string;
    op: string;
    value?: any;
}

export interface ConditionBlockOrchestratorFormat {
    type: "block";
    operation: ConditionBlockOrchestratorOperationType;
    conditions: Array<ConditionOrchestratorFormat | ConditionBlockOrchestratorFormat>;
}

/** Renders the Decision output block UI.  The decision output block is one output in the decision branch.
 *  @param props - SimpleNodeEditor
 *  @returns the JSX with the decision output block React component. */
export function DecisionOutputBlock ({
    outputBlockDefinition, className = "", headingClassName = "", index = 0, titleIcon, 
    onDuplicateOutputClicked, onRemoveOutputClicked, handleOutputDefinitionChanged, metadata,
    showAdvanced = true, handleOutputLabelChange, duplicateOutputLabels, saveAndCloseBtnDisable
}: {
    outputBlockDefinition: OutputBlockDefinition, className?: string, headingClassName?: string,
    index?: number, titleIcon?: React.ReactNode, onDuplicateOutputClicked?: (id: string) => void,
    onRemoveOutputClicked?: (id: string) => void,
    handleOutputDefinitionChanged?: (definition: OutputBlockDefinition) => void,
    handleOutputLabelChange?: (label: string) => void,
    duplicateOutputLabels?: string[],
    saveAndCloseBtnDisable? : (buttonDisabled: boolean) => void,
    metadata?: {
        metricsAndKeys?: any,
        optionsConfig?: OptionSetByType
    },
    showAdvanced?: boolean
}): JSX.Element {
    const [advancedSectionExpanded, setAdvancedSectionExpanded] = useState(false);
    const [passedDataRadioValue, setPassedDataRadioValue] = useState(outputBlockDefinition.passedData);

    function onPassedDataOptionChanged (newVal: OutputPassedDataOptions) {        
        setPassedDataRadioValue(newVal);
        if (handleOutputDefinitionChanged) {
            handleOutputDefinitionChanged({
                ...outputBlockDefinition,
                passedData: newVal,
            });
        }
    }

    function onExpressionChanged (expression: any) {
        if (handleOutputDefinitionChanged) {
            handleOutputDefinitionChanged({
                ...outputBlockDefinition,
                expression: expression,
            });
        }
    }

    const foundDuplicateOutputLabels = useRef<boolean>(!!duplicateOutputLabels?.length);

    useEffect(() => {
        if (saveAndCloseBtnDisable && foundDuplicateOutputLabels.current !== !!duplicateOutputLabels?.length) {
            if (duplicateOutputLabels?.length) {
                saveAndCloseBtnDisable(true);
            } else {
                saveAndCloseBtnDisable(false);
            }
            foundDuplicateOutputLabels.current = !!duplicateOutputLabels?.length;
        }
    }, [saveAndCloseBtnDisable, duplicateOutputLabels]);

    return <div key={"out-" + outputBlockDefinition.id} className={"output-block " + className}>
        <div className={headingClassName}>
            <div className="d-inline-flex align-items-center output-block-title">
                {titleIcon}
                <Tooltip className={Classes.TOOLTIP_INDICATOR + " border-0"} intent="danger" 
                isOpen={outputBlockDefinition?.label && duplicateOutputLabels?.includes(outputBlockDefinition.label) ? true : false} 
                content={STRINGS.runbookEditor.nodeEditor.tooltips.duplicateLabel} position={PopoverPosition.LEFT} transitionDuration={50}>
                    <InputGroup type="text" className={outputBlockDefinition?.label && duplicateOutputLabels?.includes(outputBlockDefinition.label) ? "duplicate-label" : ""} 
                    maxLength={12} placeholder={`Output ${index + 1} label...`} defaultValue={outputBlockDefinition?.label ? outputBlockDefinition.label : ""} onChange={(e) => { 
                        if (handleOutputLabelChange) {
                            handleOutputLabelChange(e.target.value);
                        }
                    }} />
                </Tooltip>
            </div>
            <span>
                <Button
                    minimal
                    title={STRINGS.runbookEditor.nodeEditor.tooltips.cloneOutput}
                    icon={IconNames.DUPLICATE as IconName}
                    onClick={() => {
                        if (onDuplicateOutputClicked) {
                            onDuplicateOutputClicked(outputBlockDefinition.id);
                        }
                    }}
                />
                <Button
                    minimal
                    title={STRINGS.runbookEditor.nodeEditor.tooltips.removeOutput}
                    icon={IconNames.CROSS as IconName}
                    onClick={() => {
                        if (onRemoveOutputClicked) {
                            onRemoveOutputClicked(outputBlockDefinition.id)
                        }
                    }}
                />
            </span>
        </div>
        <DecisionBranchView
            metadata={metadata || {}}
            expression={outputBlockDefinition.expression}
            onExpressionChanged={onExpressionChanged}
        />
        {showAdvanced && <div className="condition-output-advanced-block text-end">
            <div
                className="heading d-inline-block clickable display-9 fw-500"
                onClick={() => setAdvancedSectionExpanded(!advancedSectionExpanded)}
            >
                {STRINGS.runbookEditor.nodeEditor.advancedToggleName}
                <Icon icon={advancedSectionExpanded ? IconNames.CARET_DOWN: IconNames.CARET_LEFT}/>
            </div>
            {
                advancedSectionExpanded && <div className="contents text-start">
                    <RadioGroup selectedValue={passedDataRadioValue} onChange={e => onPassedDataOptionChanged(e.currentTarget.value as OutputPassedDataOptions)}>
                        <Radio
                            value={OutputPassedDataOptions.ALL}
                            label={STRINGS.runbookEditor.nodeEditor.forwardAll}
                        />
                        <Radio
                            value={OutputPassedDataOptions.MATCHED}
                            label={STRINGS.runbookEditor.nodeEditor.forwardMatches}
                        />
                    </RadioGroup>
                </div>
            }
        </div>}
    </div>;
}
