/** This module defines the side menu React component.  The side menu is displayed at the left
 *      side of the page and has the menu items with all the pages the user can view.
 *  @module */
import { type IconName, Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { useNavigate, useLocation } from 'react-router-dom';
import { type AppRoute, type routeType, GetURLPath as getURLPath, GetFilteredRoutes as getFilteredRoutes } from "config/routes.ts";
import { IconNames as BPIcons } from "@blueprintjs/icons";
import { Icon, IconNames } from "@tir-ui/react-components";
import { AuthServiceProvider } from "utils/providers/AuthServiceProvider.ts";
import { STRINGS } from "app-strings";
import { useUserPreferences } from "utils/hooks/useUserPreferences.ts";
import "./side-menu.scss";

/** this constant refers to the auth service where you can get the tenant, user, etc. */
const AuthService = AuthServiceProvider.getService();

/** Renders the main side menu layout.
 *  @param props the properties passed in.
 *  @returns JSX with the main application side menu layout component.*/
function SideMenu(props: { links?: Array<string | routeType> | undefined }): JSX.Element {
    const userPreferences = useUserPreferences({
        listenOnlyTo: {
            menu: {
                showUserPreferences: false,
                showDashboards: false,
                showNpmPlus: false,
            },
        },
    });
    const location = useLocation();
    let navigate = useNavigate();

    function routeMapper(key, route) {
        if (route === 'MenuDivider') {
            return <MenuDivider key={key} className="opacity-2" />;
        } else if (route) {
            const href = getURLPath(route.key) + (route.parameters ? '?' + route.parameters : '');
            return (
                <MenuItem
                    key={key}
                    href={href}
                    active={location.pathname === route.path + (route.parameters ? '?' + route.parameters : '')}
                    text={route.title}
                    icon={<Icon icon={route.icon} />}
                    onClick={(event) => {
                        if (route.pathType !== 'absolute') {
                            event.preventDefault();
                            // If the user is in a page and clicks on the menu item for the same page,
                            // it means their intention is to perform kind of a reload of the page.
                            // To simulate a reload within our react single page app, we are going to
                            // switch to a blank route for a split second and back to the clicked route.
                            // This will cause all components in the page to unmount and then remount.
                            if (window.location.pathname === href) {
                                navigate(getURLPath('blank'));
                                setTimeout(() => {
                                    navigate(href);
                                }, 100);
                            } else {
                                navigate(href);
                            }
                        }
                    }}
                />
            );
        } else {
            console.warn('Encountered an Undefined Route definition (index ' + key + ')');
        }
    }
    const ROUTES: AppRoute = getFilteredRoutes(userPreferences);

    const generalLinks: Array<routeType | string | null> = [];
    let generalRoutes: string[] = [
        'map',
        'sites',
        'devices',
        'client-hosts',
        'site-config',
        'impact-dashboard',
        'incident-search',
        'runbook-outputs',
        'runbook-schedules',
    ];
    generalRoutes.forEach((routeName) => {
        showRoute(routeName, ROUTES) && generalLinks.push(ROUTES[routeName]);
    });
    generalLinks.push('MenuDivider');

    const automationLinks: Array<routeType | string | null> = [];
    const automationRoutes: string[] = [
        'mapping-configuration', 'incident-runbooks', 'lifecycle-runbooks', 'on-demand-runbooks', 'webhook-runbooks', 'subflows'
    ];
    automationRoutes.forEach((routeName) => {
        showRoute(routeName, ROUTES) && automationLinks.push(ROUTES[routeName]);
    });

    const explorerLinks: Array<routeType | string | null> = [];
    const explorerRoutes: string[] = [
        'explorer-devices',
        'explorer-interfaces',
        'explorer-locations',
        'explorer-applications',
        'explorer-properties',
        'explorer-tcpconnections',
    ];
    explorerRoutes.forEach((routeName) => {
        showRoute(routeName, ROUTES) && explorerLinks.push(ROUTES[routeName]);
    });

    const configurationLinks: Array<routeType | string | null> = [];
    if (AuthService.isUserProductAdmin('gelato')) {
        const configRoutes: string[] = [
            'analytics-configuration',
            'user-account-management',
            'edge-configuration',
            'third-party-authentication',
            'client-api-access',
            'health-monitor',
            'usage-monitor',
            'iq-assist-administration',
        ];
        configRoutes.forEach((routeName) => {
            showRoute(routeName, ROUTES) && configurationLinks.push(ROUTES[routeName]);
        });
    }

    const additionalLinks: Array<routeType | string | null> = [];
    showRoute('user-preferences', ROUTES) && additionalLinks.push(ROUTES['user-preferences']);
    showRoute('dashboards', ROUTES) && additionalLinks.push(ROUTES['dashboards']);
    showRoute('navigator', ROUTES) && additionalLinks.push(ROUTES['navigator']);

    const integrationsLinks: Array<routeType | string | null> = [];
    showRoute('integrations', ROUTES) && integrationsLinks.push(ROUTES['integrations']);

    const supportLinks: Array<routeType | string | null> = [];
    showRoute('support', ROUTES) && supportLinks.push(ROUTES['support']);

    additionalLinks.length > 0 && supportLinks.push('MenuDivider');

	const cloudimLinks: Array<routeType | string | null> = [];
    const cloudimRoutes: string[] = [
        'cloudim-theater', 'cloudim-assets', 'cloudim-rules'
    ];
    cloudimRoutes.forEach(routeName => {
        showRoute(routeName, ROUTES) && cloudimLinks.push(ROUTES[routeName]);
    });
	cloudimLinks.length > 0 && additionalLinks.push('MenuDivider');

	const generalMenuLinks = generalLinks
		.filter(Boolean)
		.concat(props.links || [])
		.map((r, i) => routeMapper(i, r));

    const configurationMenuLinks = configurationLinks
        .filter(Boolean)
        .concat(props.links || [])
        .map((r, i) => routeMapper(i, r));

    const automationMenuLinks = automationLinks
        .filter(Boolean)
        .concat(props.links || [])
        .map((r, i) => routeMapper(i, r));

    const explorerMenuLinks = explorerLinks
        .filter(Boolean)
        .concat(props.links || [])
        .map((r, i) => routeMapper(i, r));

    const integrationsMenuLinks = integrationsLinks
        .filter(Boolean)
        .concat(props.links || [])
        .map((r, i) => routeMapper(i, r));

    const supportMenuLinks = supportLinks
        .filter(Boolean)
        .concat(props.links || [])
        .map((r, i) => routeMapper(i, r));

    const additionalMenuLinks = additionalLinks
		.filter(Boolean)
		.concat(props.links || [])
		.map((r, i) => routeMapper(i, r));

	const cloudimMenuLinks = cloudimLinks
		.filter(Boolean)
		.concat(props.links || [])
		.map((r, i) => routeMapper(i, r));
 
    return (
        <Menu className="bg-transparent w-100" large={true}>
            {generalMenuLinks}
            {AuthService.userHasWriteAccess('gelato') && automationLinks.length > 0 && (
                <MenuItem key={'automation'} icon={BPIcons.OFFLINE as IconName} text={STRINGS.automation}>
                    {automationMenuLinks}
                </MenuItem>
            )}
            {explorerMenuLinks.length > 0 && (
                <MenuItem key={'explorer'} icon={BPIcons.AREA_OF_INTEREST as IconName} text={STRINGS.explorer}>
                    {explorerMenuLinks}
                </MenuItem>
            )}
            {integrationsMenuLinks}
            {AuthService.isUserProductAdmin('gelato') && configurationMenuLinks.length > 0 && (
					<MenuItem
						key={'administration'}
						icon={IconNames.COG}
						text={STRINGS.administration}
					>
						{configurationMenuLinks}
					</MenuItem>
			)}
			{supportMenuLinks}
			{additionalMenuLinks}
			{cloudimMenuLinks.length > 0 && 
				<MenuItem
          className='mx-0'
					icon={IconNames.CLOUD}
					text={STRINGS.cloudim.menuCategory}
					onClick={(event) => {
						event.preventDefault();
						if (window.location.pathname !== getURLPath(ROUTES['cloudim'].key)) {
							navigate(getURLPath(ROUTES['cloudim'].key));
						}
					}}
				>
				{cloudimMenuLinks}
            	</MenuItem>
			}
		</Menu>
	);
}

/** returns true if the specified route name should be displayed by performing checks on the environment and
 *      the user preferences.
 *  @param routeName a String with the route name.
 *  @param routes the dictionary with the routes.
 *  @returns a boolean value, true if the menu should be shown, false otherwise. */
function showRoute(routeName: string, routes: AppRoute): boolean {
    return routeName in routes;
}

export { SideMenu, showRoute };
