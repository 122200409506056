/** This module contains the component for displaying the navigator widget config dialog.  The navigator widget config
 *      dialog allows the user to configure the widget runbook and visual configuration.
 *  @module
 */
import { useRef, useState } from "react";
import { DialogStep, MultistepDialog } from "@blueprintjs/core";
import { STRINGS } from "app-strings";
import { InteractionConfig } from "components/common/vis-framework/widget/Widget.type.ts";
import { NavigatorWidgetConfig, QueryConfig } from "pages/navigator/Navigator.type.ts";
import WidgetConfigVisualizationStep, { WidgetConfigVisualizationStepRef } from "./WidgetConfigVisualizationStep.tsx";
import { WIDGET_TYPE } from "components/common/vis-framework/widget/Widget.type.ts";
import InteractionConfigStep from "./InteractionConfigStep.tsx";
import QueryConfigStep, { QueryConfigStepRef } from "./QueryConfigStep.tsx";
import "./NavigatorWidgetConfigDialog.scss";

/** this interface defines the properties passed into the NavigatorWidgetConfigDialog React component. */
export interface NavigatorWidgetConfigDialogProps {
    /** a boolean value, which if true specifies that the dialog should be open. */
    isOpen: boolean;
    /** the configuration of this current navigator widget. */
    config: NavigatorWidgetConfig;
    /** the list of all configured widgets, this is needed to setup interactions. */
    widgets: NavigatorWidgetConfig[];
    /** the handler for config change events. */
    handleConfigChange?: (config: NavigatorWidgetConfig) => void;
    /** the handler for dialog close events. */
    handleDialogClose: () => void;
}

/** Renders the navigator widget config dialog.
 *  @param props the properties passed in.
 *  @returns JSX with the navigator widget config dialog component.*/
const NavigatorWidgetConfigDialog = (props: NavigatorWidgetConfigDialogProps): JSX.Element => {
    //const [runbookConfig, setRunbookConfig] = useState<RunbookConfig | undefined>(props.config.runbookConfig);
    const [queryConfig, setQueryConfig] = useState<QueryConfig>(props.config.queryConfig);

    const [widgetName, setWidgetName] = useState<string>(props.config.name);
    const [widgetType, setWidgetType] = useState<WIDGET_TYPE>(props.config.widgetType);
    const [widgetOptions, setWidgetOptions] = useState<any>(props.config.options || {});

    const [interactions, setInteractions] = useState<InteractionConfig[]>(props.config.interactions || []);

    //const nodeLibrary = useRef<NodeLibrary>(new NodeLibrary(DashboardNodeLibrary as NodeLibrarySpec));

    const queryEditorRef = useRef<QueryConfigStepRef | undefined>(undefined);

    const visEditorRef = useRef<WidgetConfigVisualizationStepRef | undefined>(undefined);

    /*
    const handler: HandleComponent = {
        left: <div style={{
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 100
        }}><Icon className="drag-handle" icon={IconNames.DRAG_HANDLE_VERTICAL}/></div>
    };
    */
    return <div id="multistep_dialog" style={{width: "1800px!important"}}>
        <MultistepDialog
            className="edit-navigator-dialog main-content"
            onClose={() => {props.handleDialogClose()}/*clearValues*/}
            //finalButtonProps={finalButtonProps}
            title={STRINGS.navigator.debugDialogTitle}
            autoFocus={true}
            canEscapeKeyClose={true}
            canOutsideClickClose={false}
            enforceFocus={true}
            isCloseButtonShown={true}
            isOpen={props.isOpen}
            resetOnClose={true}
            finalButtonProps={{
                onClick: (event) => {
                    if (props.handleConfigChange) {
                        let newQueryConfig = queryEditorRef.current?.getQueryConfig();
                        if (!newQueryConfig) {
                            newQueryConfig = queryConfig;
                        }

                        let options = visEditorRef.current?.getOptions();
                        if (!options) {
                            options = widgetOptions;
                        }
                        props.handleConfigChange(
                            {
                                id: props.config.id, name: widgetName, widgetType, 
                                queryConfig: newQueryConfig/*runbookConfig: runbookConfig*/, options: widgetOptions, interactions
                            }
                        );
                    }
                    props.handleDialogClose();
                }
            }}
            onClosed={() => {
                if (props.handleConfigChange) {
                    let newQueryConfig = queryEditorRef.current?.getQueryConfig();
                    if (!newQueryConfig) {
                        newQueryConfig = queryConfig;
                    }

                    let options = visEditorRef.current?.getOptions();
                    if (!options) {
                        options = widgetOptions;
                    }
                    props.handleConfigChange(
                        {
                            id: props.config.id, name: widgetName, widgetType, 
                            queryConfig: newQueryConfig/*runbookConfig: runbookConfig*/, options: widgetOptions, interactions
                        }
                    );
                }
                props.handleDialogClose();
            }}
            onChange={(newDialogStepId, prevDialogStepId, event) => {
                if (prevDialogStepId === "query-step") {
                    let newQueryConfig = queryEditorRef.current?.getQueryConfig();
                    if (newQueryConfig) {
                        setQueryConfig(newQueryConfig);
                    }
                } else if (prevDialogStepId === "visualization-step") {
                    let options = visEditorRef.current?.getOptions();
                    if (options) {
                        setWidgetOptions(options);
                    }
                }
                console.log(newDialogStepId + ", " + prevDialogStepId);
            }}
        >
            {/*<DialogStep
                id="runbook-step"
                className="edit-navigator-dialog panel-content"
                panel={<>
                    <div className="main-layout d-flex flex-column h-100 container-fluid" onClick={() => {}}>
                        <Row className="flex-grow-1 overflow-auto position-relative">
                            <Col className="d-flex p-0 h-100 flex-column container overflow-auto">
                                <CreateRunbookView key="create" editorHidden={false} inDialog={true}
                                    nodeLibrary={nodeLibrary.current}
                                    onPreview={(runbook: RunbookConfig) => {
                                    }} 
                                    onTest={(runbook: RunbookConfig) => {
                                    }}
                                    runbookConfig={runbookConfig}
                                    onChange={(runbook: RunbookConfig) => {
                                        setRunbookConfig(runbook);
                                    }}
                                    variant={Variant.INCIDENT}
                                    exclusions={incidentRunbookExclusions}
                                />
                            </Col>
                            <Resizable
                                handleComponent={handler}
                                className="resizable-dialog-details-panel d-none"
                                minWidth="200px"
                                maxWidth="80%"
                                maxHeight="100%"
                                //defaultSize={{width: "200px", height: "100%"}}
                                //style={{width: "200px", height: "100%"}}
                                enable={{top:false, right:false, bottom:false, left:true, topRight:false, bottomRight:false, bottomLeft:true, topLeft:true}}
                            >
                                <Col id="dialog-details-pane" className="d-none flex-grow-0 p-0 bg-light shadow h-100 col-12 col-md-auto border-start resizable">
                                </Col>
                            </Resizable>
                        </Row>
                    </div>
                    {false && <div className="d-flex">
                        <CreateRunbookView 
                            nodeLibrary={nodeLibrary.current}
                            key="create" editorHidden={false} inDialog={true}
                            onPreview={(runbook: RunbookConfig) => {
                            }} 
                            onTest={(runbook: RunbookConfig) => {
                            }}
                            runbookConfig={runbookConfig}
                            onChange={(runbook: RunbookConfig) => {
                                setRunbookConfig(runbook);
                            }}
                            variant={Variant.INCIDENT}
                            exclusions={incidentRunbookExclusions}
                        />
                        <div id="dialog-details-pane" className="bg-light" style={{height: "810px", overflowY: "auto", backgroundColor: "rgb(248, 249, 250)", zIndex: 100}}></div>
                    </div>}
                </>}
                title={STRINGS.navigator.runbookStepTitle}
                nextButtonProps={{
                    disabled: false
                }}
            />*/}
            <DialogStep
                id="query-step"
                className="edit-navigator-dialog panel-content"
                panel={
                    <QueryConfigStep 
                        config={{
                            id: props.config.id, name: widgetName, widgetType, options: widgetOptions,
                            queryConfig: queryConfig /*runbookConfig: runbookConfig*/, interactions
                        }} 
                        //key={"visualizations-step-" + Math.random()}
                        queryConfig={queryConfig}
                        onQueryChange={(query: QueryConfig) => {
                            setQueryConfig(query);
                        }}
                        ref={(element: QueryConfigStepRef) => queryEditorRef.current = element}
                    />
                }
                title={STRINGS.navigator.queryStepTitle}
                nextButtonProps={{
                    disabled: false
                }}
            />
            <DialogStep
                id="visualization-step"
                className="edit-navigator-dialog panel-content"
                panel={
                    <WidgetConfigVisualizationStep 
                        config={{
                            id: props.config.id, name: widgetName, widgetType, options: widgetOptions,
                            queryConfig: queryConfig /*runbookConfig: runbookConfig*/, interactions
                        }} 
                        //key={"visualizations-step-" + Math.random()}
                        //runbookConfig={runbookConfig}
                        queryConfig={queryConfig}
                        onWidgetNameChange={(name: string) => {
                            setWidgetName(name);
                        }}
                        onWidgetTypeChange={(type: WIDGET_TYPE) => {
                            setWidgetType(type);
                        }}
                        ref={(element: WidgetConfigVisualizationStepRef) => visEditorRef.current = element}
                    />
                    /*<BasicDetailsPanel
                        onChangeGatewayName={
                            handleGatewayNameChange
                        }
                        onChangeGatewayType={
                            handleGatewayTypeChange
                        }
                        gatewayNameValue={gatewayName}
                        gatewayTypeValue={gatewayType}
                    />*/
                }
                title={STRINGS.navigator.visualizationStepTitle}
                nextButtonProps={{
                    disabled: false
    /*
                        gatewayName === '' ||
                        gatewayType === '' ||
                        gatewayType ===
                            STRINGS.DATA_SOURCES
                                .addEdgeGatewayDialog.panels
                                .basicDetails.fields.chooseType,
    */
                }}
            />
            <DialogStep
                id="interaction-step"
                className="edit-navigator-dialog panel-content"
                panel={
                    <InteractionConfigStep 
                        config={{
                            id: props.config.id, name: widgetName, widgetType, options: widgetOptions,
                            queryConfig: queryConfig /*runbookConfig: runbookConfig*/, interactions
                        }} 
                        widgets={props.widgets}
                        onInteractionsChange={(passedInteractions: InteractionConfig[]) => {
                            setInteractions(passedInteractions || []);
                        }}
                    />
                }
                title={STRINGS.navigator.interactionStepTitle}
                nextButtonProps={{
                    disabled: false
                }}
            />
        </MultistepDialog>
    </div>;
} 

export default NavigatorWidgetConfigDialog;
