import { useState, useEffect, ChangeEvent } from 'react';
import classNames from 'classnames';
import { Classes, Divider, Button, Switch, Intent } from '@blueprintjs/core';
import { TextAreaField } from 'components/common/form/TextAreaField.tsx';
import { SelectField } from 'components/common/form/SelectField.tsx';
import { InputField } from "components/common/form/InputField.tsx";
import { Form } from 'components/common/form/Form.tsx';
import { LangEN, STRINGS } from 'app-strings';
import * as yup from 'yup';
import { Icon, IconNames } from "@tir-ui/react-components";
import './TokenBasedAuthParamsPanel.scss';

interface RequestHeaderRow {
    name: string;
    value: string;
}

export interface TokenBasedAuthParamsPanelProps {
    isEdit: boolean;
    accessTokenRequestUrl: string;
    clientSecretPassingMethod: string | number;
    clientSecret: string | undefined;
    clientSecretBodyPlaceholder: string | undefined;
    requestBodyContent: string | undefined;
    responseBodyTokenName: string;
    tokenPassingMethod: string | number;
    clientSecretAuthenticationSchemeName: string | undefined;
    tokenCustomHeaderName: string | undefined;
    tokenAuthenticationSchemeName: string | undefined;
    encodeSecret: boolean | undefined;
    encodeToken: boolean | undefined;
    additionalRequestHeaders?: Array<{ name: string; value: string }>;
    clientSecretCustomHeaderName?: string | undefined; 
    onChangeAccessTokenRequestUrl: (event) => void;
    onChangeClientSecretPassingMethod: (event) => void;
    onChangeClientSecret: (event) => void;
    onChangeClientSecretBodyPlaceholder: (event) => void;
    onChangeRequestBodyContent: (event) => void;
    onChangeResponseBodyTokenName: (event) => void;
    onChangeTokenPassingMethod: (event) => void;
    onChangeClientSecretAuthenticationSchemeName: (event) => void;
    onChangeTokenCustomHeaderName: (event) => void;
    onChangeTokenAuthenticationSchemeName: (event) => void;
    onChangeEncodeSecret: (event) => void;
    onChangeEncodeToken: (event) => void;
    onChangeAdditionalRequestHeaders?: (requestHeaders) => void;
    onChangeClientSecretCustomHeaderName?: (event) => void;
}

const TokenBasedAuthParamsPanel = (props: TokenBasedAuthParamsPanelProps) => {
    const [clientSecretPassingMethod, setClientSecretPassingMethod] = useState<string | number>(props.clientSecretPassingMethod);
    const [tokenPassingMethod, setTokenPassingMethod] = useState<string | number>(props.tokenPassingMethod);

    const {
        isEdit,
        accessTokenRequestUrl,
        onChangeAccessTokenRequestUrl,
        onChangeClientSecretPassingMethod,
        clientSecret,
        onChangeClientSecret,
        clientSecretBodyPlaceholder,
        onChangeClientSecretBodyPlaceholder,
        requestBodyContent,
        onChangeRequestBodyContent,
        responseBodyTokenName,
        onChangeResponseBodyTokenName,
        onChangeTokenPassingMethod,
        clientSecretAuthenticationSchemeName,
        onChangeClientSecretAuthenticationSchemeName,
        tokenCustomHeaderName,
        onChangeTokenCustomHeaderName,
        tokenAuthenticationSchemeName,
        onChangeTokenAuthenticationSchemeName,
        encodeSecret,
        onChangeEncodeSecret,
        encodeToken,
        onChangeEncodeToken,
        additionalRequestHeaders,
        onChangeAdditionalRequestHeaders,
        clientSecretCustomHeaderName,
        onChangeClientSecretCustomHeaderName
    } = props;

    const clientSecretPassingMethods: Array<{
        id: number,
        name: string,
        value: string,
    }> = [
        {
            id: 0,
            name: "Authorization Header",
            value: "AuthorizationHeader",
        },
        {
            id: 1,
            name: "Request Header",
            value: "RequestHeader",
        },
        {
            id: 2,
            name: "Request Body",
            value: "RequestBodyField",
        },
    ];

    const tokenPassingMethods:Array<{
        id: number,
        name: string,
        value: string,
    }> = [
        {
            id: 0,
            name: "Authorization Header",
            value: "AuthorizationHeader",
        },
        {
            id: 1,
            name: "Request Header",
            value: "RequestHeader",
        }
    ];

    useEffect(() => {
        if (isEdit) {
            const editedClientSecretPassingMethod = clientSecretPassingMethods.find(item => item.id === clientSecretPassingMethod)?.value;
            const editedTokenPassingMethod = tokenPassingMethods.find(item => item.id === tokenPassingMethod)?.value;
            if (editedClientSecretPassingMethod) {
                setClientSecretPassingMethod(editedClientSecretPassingMethod);
            }
            if (editedTokenPassingMethod) {
                setTokenPassingMethod(editedTokenPassingMethod);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAddRequestHeaderRow = () => {
        if (onChangeAdditionalRequestHeaders) {
            const newRow: RequestHeaderRow = { name: "", value: "" };
            onChangeAdditionalRequestHeaders([
                ...(additionalRequestHeaders || []),
                newRow,
            ]);
        }
    };

    const handleRemoveRequestHeaderRow = (index: number) => {
        if (
            onChangeAdditionalRequestHeaders &&
            additionalRequestHeaders
        ) {
            onChangeAdditionalRequestHeaders(
                additionalRequestHeaders.filter(
                    (_row: RequestHeaderRow, i: number) => i !== index
                )
            );
        }
    };

    const handleRequestHeaderRowInputChange = (
        index: number,
        e: ChangeEvent<HTMLInputElement>
    ) => {
        if (
            onChangeAdditionalRequestHeaders &&
            additionalRequestHeaders
        ) {
            const { name, value } = e.target;
            onChangeAdditionalRequestHeaders(
                additionalRequestHeaders.map(
                    (row: RequestHeaderRow, i: number) =>
                        i === index ? { ...row, [name]: value } : row
                )
            );
        }
    };

    const translations: LangEN["thirdPartyIntegrations"]["addAuthProfile"] = 
        STRINGS.thirdPartyIntegrations.addAuthProfile;

    const validationSchema = yup.object().shape({
        access_token_request_url: yup
            .string()
            .required()
            .label(translations.panels.tokenBasedAuthParams.step1.fields.accessTokenRequestURL.label),
        send_secret_in: yup
            .string()
            .required()
            .label(translations.panels.tokenBasedAuthParams.step1.fields.clientSecretPassingMethod.label),
        field_containing_token_in_response_body: yup
            .string()
            .required()
            .label(translations.panels.tokenBasedAuthParams.step1.fields.responseBodyTokenName.label),
        send_token_in: yup
            .string()
            .required()
            .label(translations.panels.tokenBasedAuthParams.step2.fields.tokenPassingMethod.label),
        clientSecret: isEdit? yup
            .string() : 
            yup
            .string()
            .required()
            .label(translations.panels.tokenBasedAuthParams.step1.fields.clientSecret.label),
        replace_text_below_with_secret_in_request_body: yup
            .string()
            .required()
            .label(translations.panels.tokenBasedAuthParams.step1.fields.clientSecretBodyPlaceholder.label),
        request_body: yup
            .string()
            .required()
            .label(translations.panels.tokenBasedAuthParams.step1.fields.requestBodyContent.label),
        client_secret_authentication_scheme_name: yup
            .string()
            .required()
            .label(translations.panels.tokenBasedAuthParams.step1.fields.httpAuthenticationScheme.label),
        clientSecretCustomHeaderName: yup
            .string()
            .required()
            .label(translations.panels.tokenBasedAuthParams.step1.fields.requestHeaderName.label),
        token_authentication_scheme_name: yup
            .string()
            .required()
            .label(translations.panels.tokenBasedAuthParams.step2.fields.httpAuthenticationScheme.label),
        token_custom_header_name: yup
            .string()
            .required()
            .label(translations.panels.tokenBasedAuthParams.step2.fields.requestHeaderName.label),
    });

    return (
        <div className={classNames(Classes.DIALOG_BODY)}>
            <p>
                <b>
                    {
                        translations.panels.tokenBasedAuthParams.title
                    }
                </b>
            </p>
            <Divider />
            <Form
                className="token-based-auth-params-panel mt-2"
                initialValues={{
                    access_token_request_url: accessTokenRequestUrl,
                    send_secret_in: clientSecretPassingMethod,
                    field_containing_token_in_response_body: responseBodyTokenName,
                    send_token_in: tokenPassingMethod,
                    clientSecret: clientSecret,
                    replace_text_below_with_secret_in_request_body: clientSecretBodyPlaceholder,
                    request_body: requestBodyContent,
                    client_secret_authentication_scheme_name: clientSecretAuthenticationSchemeName,
                    clientSecretCustomHeaderName: clientSecretCustomHeaderName,
                    token_authentication_scheme_name: tokenAuthenticationSchemeName,
                    token_custom_header_name: tokenCustomHeaderName,
                }}
                validationSchema={validationSchema}
                loading={false}
            >
                <p className="mt-3 mb-3">
                    <Icon icon={ IconNames.CHEVRON_DOWN } className="mr-1" />
                    <b>
                        {
                            translations.panels.tokenBasedAuthParams.step1.title
                        }
                    </b>
                </p>
                <InputField
                    name="access_token_request_url"
                    type="text"
                    required={true}
                    label={translations.panels.tokenBasedAuthParams.step1.fields.accessTokenRequestURL.label}
                    placeholder={translations.panels.tokenBasedAuthParams.step1.fields.accessTokenRequestURL.placeholder}
                    onChange={onChangeAccessTokenRequestUrl}
                    value={accessTokenRequestUrl}
                />
                <SelectField 
                    name="send_secret_in"
                    label={
                        translations.panels.tokenBasedAuthParams.step1.fields.clientSecretPassingMethod.label
                    }
                    onChange={(event) => { 
                        onChangeClientSecretPassingMethod(event);
                        setClientSecretPassingMethod(event.target.value);
                    }}
                    value={clientSecretPassingMethod}
                    required={true}
                >
                        <option value="">
                            {translations.panels.tokenBasedAuthParams.step1.fields.clientSecretPassingMethod.placeholder}
                        </option>
                        {clientSecretPassingMethods?.map((item, index) => <option key={index} value={item.value}>{item.name}</option>)}
                </SelectField>
                {!!clientSecretPassingMethod && clientSecretPassingMethod !== clientSecretPassingMethods[1].value && <InputField
                    name="clientSecret"
                    type="password"
                    required={true}
                    label={
                        translations.panels.tokenBasedAuthParams.step1.fields.clientSecret.label
                    }
                    placeholder={
                        translations.panels.tokenBasedAuthParams.step1.fields.clientSecret.placeholder
                    }
                    onChange={onChangeClientSecret}
                    value={clientSecret}
                    showPasswordOption={true}
                />}
                {clientSecretPassingMethod === clientSecretPassingMethods[2].value && <>
                    <InputField
                        name="replace_text_below_with_secret_in_request_body"
                        type="text"
                        required={true}
                        label={translations.panels.tokenBasedAuthParams.step1.fields.clientSecretBodyPlaceholder.label}
                        placeholder={translations.panels.tokenBasedAuthParams.step1.fields.clientSecretBodyPlaceholder.placeholder}
                        onChange={onChangeClientSecretBodyPlaceholder}
                        value={clientSecretBodyPlaceholder}
                    />
                    <TextAreaField
                        name="request_body"
                        required={true}
                        label={translations.panels.tokenBasedAuthParams.step1.fields.requestBodyContent.label}
                        placeholder={translations.panels.tokenBasedAuthParams.step1.fields.requestBodyContent.placeholder}
                        onChange={onChangeRequestBodyContent}
                        value={requestBodyContent}
                        style={{resize: "vertical"}}
                    />
                </>}
                {clientSecretPassingMethod === clientSecretPassingMethods[0].value && <>
                    <InputField
                        name="client_secret_authentication_scheme_name"
                        type="text"
                        required={true}
                        label={translations.panels.tokenBasedAuthParams.step1.fields.httpAuthenticationScheme.label}
                        placeholder={translations.panels.tokenBasedAuthParams.step1.fields.httpAuthenticationScheme.placeholder}
                        onChange={onChangeClientSecretAuthenticationSchemeName}
                        value={clientSecretAuthenticationSchemeName}
                    />
                    <div>
                        <Switch
                            label={translations.panels.tokenBasedAuthParams.step1.fields.encodeSecret}
                            checked={encodeSecret}
                            onChange={onChangeEncodeSecret}
                        />
                    </div>
                </>}
                {clientSecretPassingMethod === clientSecretPassingMethods[1].value && <>
                    <p className="mt-3 mb-3">
                        <b>{translations.panels.tokenBasedAuthParams.step1.fields.requestHeaders}</b>
                    </p>
                    <div className="request-header-row">
                        <div className="request-header-input">
                            <InputField
                                name="clientSecretCustomHeaderName"
                                type="text"
                                required={true}
                                label={translations.panels.tokenBasedAuthParams.step1.fields.requestHeaderName.label}
                                placeholder={translations.panels.tokenBasedAuthParams.step1.fields.requestHeaderName.placeholder}
                                onChange={onChangeClientSecretCustomHeaderName}
                                value={clientSecretCustomHeaderName}
                            />
                        </div>
                        <div className="request-header-input">
                            <InputField
                                name="clientSecret"
                                type="password"
                                required={true}
                                label={translations.panels.tokenBasedAuthParams.step1.fields.requestHeaderSecret.label}
                                placeholder={translations.panels.tokenBasedAuthParams.step1.fields.requestHeaderSecret.placeholder}
                                onChange={onChangeClientSecret}
                                value={clientSecret}
                                showPasswordOption={true}
                            />
                        </div>
                        <div className="remove-request-header"></div>
                    </div>
                    {!!additionalRequestHeaders?.length &&
                        additionalRequestHeaders.map(
                            (
                                row: RequestHeaderRow,
                                index: number
                            ) => (
                        <div className="request-header-row" key={index}>
                            <div className="request-header-input">
                                <InputField
                                    name="name"
                                    type="text"
                                    required={true}
                                    label={translations.panels.tokenBasedAuthParams.step1.fields.requestHeaderName.label}
                                    placeholder={translations.panels.tokenBasedAuthParams.step1.fields.requestHeaderName.placeholder}
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) =>
                                        handleRequestHeaderRowInputChange(
                                            index,
                                            e
                                        )
                                    }
                                    value={row.name}
                                />
                            </div>
                            <div className="request-header-input">
                                <InputField
                                    name="value"
                                    type="password"
                                    required={true}
                                    label={translations.panels.tokenBasedAuthParams.step1.fields.requestHeaderValue.label}
                                    placeholder={translations.panels.tokenBasedAuthParams.step1.fields.requestHeaderValue.placeholder}
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) =>
                                        handleRequestHeaderRowInputChange(
                                            index,
                                            e
                                        )
                                    }
                                    value={row.value}
                                    showPasswordOption={true}
                                />
                            </div>
                            <div className="remove-request-header">
                                <Icon
                                    onClick={() =>
                                        handleRemoveRequestHeaderRow(
                                            index
                                        )
                                    }
                                    intent={Intent.NONE}
                                    icon={IconNames.CROSS}
                                />
                            </div>
                        </div>
                    ))}
                    <div className="add-request-header-control mb-3">
                        <Button
                            id="add_request_header"
                            minimal
                            className="font-weight-bold"
                            icon={IconNames.ADD}
                            text={translations.panels.tokenBasedAuthParams.step1.fields.addRequestHeader}
                            onClick={handleAddRequestHeaderRow}
                        />
                    </div>
                </>}
                <InputField
                    name="field_containing_token_in_response_body"
                    type="text"
                    required={true}
                    label={translations.panels.tokenBasedAuthParams.step1.fields.responseBodyTokenName.label}
                    placeholder={translations.panels.tokenBasedAuthParams.step1.fields.responseBodyTokenName.placeholder}
                    onChange={onChangeResponseBodyTokenName}
                    value={responseBodyTokenName}
                />
                <p className="mt-4 mb-3">
                    <Icon icon={ IconNames.CHEVRON_DOWN } className="mr-1" />
                    <b>
                        {
                            translations.panels.tokenBasedAuthParams.step2.title
                        }
                    </b>
                </p>
                <SelectField 
                    name="send_token_in"
                    label={
                        translations.panels.tokenBasedAuthParams.step2.fields.tokenPassingMethod.label
                    }
                    onChange={(event) => { 
                        onChangeTokenPassingMethod(event);
                        setTokenPassingMethod(event.target.value);
                    }}
                    value={tokenPassingMethod}
                    required={true}
                >
                        <option value="">
                            {translations.panels.tokenBasedAuthParams.step2.fields.tokenPassingMethod.placeholder}
                        </option>
                        {tokenPassingMethods?.map((item, index) => <option key={index} value={item.value}>{item.name}</option>)}
                </SelectField>
                {tokenPassingMethod === tokenPassingMethods[0].value && <>
                    <InputField
                        name="token_authentication_scheme_name"
                        type="text"
                        required={true}
                        label={translations.panels.tokenBasedAuthParams.step2.fields.httpAuthenticationScheme.label}
                        placeholder={translations.panels.tokenBasedAuthParams.step2.fields.httpAuthenticationScheme.placeholder}
                        onChange={onChangeTokenAuthenticationSchemeName}
                        value={tokenAuthenticationSchemeName}
                    />
                    <div>
                        <Switch
                            label={translations.panels.tokenBasedAuthParams.step1.fields.encodeToken}
                            checked={encodeToken}
                            onChange={onChangeEncodeToken}
                        />
                    </div>
                </>}
                {tokenPassingMethod === tokenPassingMethods[1].value && <InputField
                    name="token_custom_header_name"
                    type="text"
                    required={true}
                    label={translations.panels.tokenBasedAuthParams.step2.fields.requestHeaderName.label}
                    placeholder={translations.panels.tokenBasedAuthParams.step2.fields.requestHeaderName.placeholder}
                    onChange={onChangeTokenCustomHeaderName}
                    value={tokenCustomHeaderName}
                />}
            </Form>
        </div>
    );
};

export { TokenBasedAuthParamsPanel };