/** This module contains the wrapper for the cloudim react-flow graph.
 *  @module
 */
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactFlow, { MiniMap, Controls, type OnLoadParams, type Elements, ConnectionLineType } from "react-flow-renderer";
import { type CloudIMGraphDef, getElements } from "utils/cloudim/TopologyUtils.ts";
import { STRINGS } from "app-strings";
import { type IconName, Menu, MenuDivider, MenuItem, ContextMenu, type ContextMenuChildrenProps } from "@blueprintjs/core";
import { Icon, IconNames } from "@tir-ui/react-components";
import { IconNames as BPIconNames } from "@blueprintjs/icons";
import { PROVIDER_TYPES } from "components/enums/CloudIM.ts";
import { toPng, toJpeg, toSvg } from "html-to-image";
import { getURL } from "utils/hooks/useQueryParams.ts";
import { GetURLPath as getURLPath } from "config/routes.ts";
import { SEARCH_TYPE } from "components/reporting/facet/FacetUtils.ts";
import { FACET_FIELDS } from "utils/services/SearchApiService.ts";
import type { Region } from "pages/cloudim/views/cloudim-geomap/CloudIMGeoMapView.tsx";

import AWSNode from "components/common/graph/cloudim-react-flow/nodes/aws/AWSNode.tsx";
import AzureNode from "components/common/graph/cloudim-react-flow/nodes/azure/AzureNode.tsx";
import NetIMNode from "components/common/graph/cloudim-react-flow/nodes/netim/NetIMNode.tsx";
import NetIMEdge from "components/common/graph/cloudim-react-flow/edges/netim/NetIMEdge.tsx";
import LayoutControl from "components/common/graph/cloudim-react-flow/components/layout/LayoutControl.tsx";

// The default edge type to use wherever an edge is created
const defaultEdgeType: ConnectionLineType = ConnectionLineType.Bezier;

/** This interface defines the properties passed into the cloudim react-flow graph React component.*/
export interface CloudIMReactFlowGraphProps {
    /** the GraphDef object with the graph nodes and edges in it.*/
    graphDef: CloudIMGraphDef;
    regionDetail?: Region;
    showLayoutOptions?: boolean;
    showMinimap?: boolean;
    debug?: boolean;
}

/** this enum references the download types available.*/
enum DOWNLOADTYPES {
    /** Download as JSON */
    JSON = "JSON",
    /** Download as JPEG */
    JPEG = "JPEG",
    /** Download as PNG */
    PNG = "PNG",
    /** Download as SVG */
    SVG = "SVG",
}

/** Renders the cloudim react-flow graph component.
 *  @param props the properties passed in. These properties contain some of the meta data necessary to
 *      draw the graph, and the nodes and edges that should be drawn in the graph.
 *  @returns JSX with the cloudim react flow graph component.*/
const CloudIMReactFlowGraph = React.forwardRef(
    ({ graphDef, regionDetail, debug, ...props }: CloudIMReactFlowGraphProps, ref): JSX.Element => {
        const navigate = useNavigate();

        const [reactFlowInstance, setReactFlowInstance] = useState<OnLoadParams>();
        const passedElements = getElements(graphDef);
        const [elements, setElements] = useState<Elements>(passedElements);

        const rerenderData = useCallback(async () => {
            setElements(getElements(graphDef));
        }, [graphDef]);

        useEffect(() => {
            rerenderData().then(() => {
                if (reactFlowInstance) {
                    reactFlowInstance.fitView();
                }
            });
        }, [rerenderData, reactFlowInstance]);

        const onLoad = (reactFlowInstance: OnLoadParams) => {
            setReactFlowInstance(reactFlowInstance);
            reactFlowInstance.fitView();
        };

        // istanbul ignore next
        const downloadData = (type: DOWNLOADTYPES, data: any) => {
            const fileName = (regionDetail?.displayName ?? "data").replace(/ /g, "_");
            const link = document.createElement("a");

            switch (type) {
                case DOWNLOADTYPES.JSON:
                    const json = JSON.stringify(data, null, 2);
                    const blob = new Blob([json], { type: "application/json" });
                    const href = URL.createObjectURL(blob);
                    link.href = href;
                    link.download = `${fileName}.json`;
                    break;
                case DOWNLOADTYPES.JPEG:
                    link.href = data;
                    link.download = `${fileName}.jpeg`;
                    break;
                case DOWNLOADTYPES.PNG:
                    link.href = data;
                    link.download = `${fileName}.png`;
                    break;
                case DOWNLOADTYPES.SVG:
                    link.href = data;
                    link.download = `${fileName}.svg`;
                    break;
                default:
                    break;
            }

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };

        return (
            <ContextMenu
                content={
                    <Menu>
                        {regionDetail?.displayName && (
                            <>
                                <MenuDivider
                                    title={
                                        <span>
                                            <Icon className="me-1" icon={IconNames.MAP_MARKER} />
                                            <div className="d-inline-block">{regionDetail.displayName}</div>
                                        </span>
                                    }
                                />
                                <MenuDivider />
                            </>
                        )}
                        {regionDetail?.platform === PROVIDER_TYPES.ONPREM && (
                            <MenuItem
                                icon={BPIconNames.AREA_OF_INTEREST as IconName}
                                text={STRINGS.cloudim.topology.contextMenu.explorerView}
                                onClick={() => {
                                    navigate(
                                        getURL(
                                            getURLPath("explorer"),
                                            {
                                                searchType: SEARCH_TYPE.device,
                                                facets: {
                                                    [FACET_FIELDS.LOCATION_NAME]: [regionDetail.code],
                                                },
                                            },
                                            { replaceQueryParams: true },
                                        ),
                                    );
                                }}
                            />
                        )}
                        {debug && (
                            <MenuItem
                                icon={IconNames.DOCUMENT}
                                text={STRINGS.cloudim.topology.contextMenu.download + " " + DOWNLOADTYPES.JSON}
                                onClick={() => {
                                    downloadData(DOWNLOADTYPES.JSON, graphDef);
                                }}
                            />
                        )}
                        <MenuItem icon={IconNames.CAMERA} text={STRINGS.cloudim.topology.contextMenu.snapshot}>
                            <MenuItem
                                icon={IconNames.MEDIA}
                                text={DOWNLOADTYPES.JPEG}
                                onClick={() => {
                                    const reactFlowImage = document.querySelector(".react-flow__renderer");
                                    if (reactFlowImage !== null) {
                                        toJpeg(reactFlowImage as HTMLElement).then((data) => {
                                            downloadData(DOWNLOADTYPES.JPEG, data);
                                        });
                                    }
                                }}
                            />
                            <MenuItem
                                icon={IconNames.MEDIA}
                                text={DOWNLOADTYPES.PNG}
                                onClick={() => {
                                    const reactFlowImage = document.querySelector(".react-flow__renderer");
                                    if (reactFlowImage !== null) {
                                        toPng(reactFlowImage as HTMLElement).then((data) => {
                                            downloadData(DOWNLOADTYPES.PNG, data);
                                        });
                                    }
                                }}
                            />
                            <MenuItem
                                icon={IconNames.MEDIA}
                                text={DOWNLOADTYPES.SVG}
                                onClick={() => {
                                    const reactFlowImage = document.querySelector(".react-flow__renderer");
                                    if (reactFlowImage !== null) {
                                        toSvg(reactFlowImage as HTMLElement).then((data) => {
                                            downloadData(DOWNLOADTYPES.SVG, data);
                                        });
                                    }
                                }}
                            />
                        </MenuItem>
                    </Menu>
                }
            >
                {(ctxMenuProps: ContextMenuChildrenProps) => (
                    <ReactFlow
                        elements={elements as Elements<any>}
                        nodesConnectable={false}
                        nodeTypes={{
                            awsNode: AWSNode,
                            azureNode: AzureNode,
                            netimNode: NetIMNode,
                        }}
                        edgeTypes={{
                            netimEdge: NetIMEdge,
                        }}
                        connectionLineType={defaultEdgeType}
                        onLoad={onLoad}
                        minZoom={0}
                        maxZoom={2.5}
                        onPaneContextMenu={(e) => {
                            ctxMenuProps.onContextMenu(e as React.MouseEvent<HTMLElement, MouseEvent>);
                        }}
                    >
                        <LayoutControl
                            showLayoutOptions={props.showLayoutOptions}
                            elements={elements as Elements<any>}
                            updateElements={(elements) => setElements(elements)}
                            reactFlowInstance={reactFlowInstance}
                            debug={false}
                            ref={ref}
                        />
                        {ctxMenuProps.popover}
                        {props.showMinimap && <MiniMap nodeBorderRadius={20} />}
                        <Controls showZoom={true} showFitView={true} showInteractive={true} />
                    </ReactFlow>
                )}
            </ContextMenu>
        );
    },
);

export default CloudIMReactFlowGraph;
