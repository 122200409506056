query customPropertySearch($top: Int!, $skip: Int!, $count: Boolean!, $search: String, $searchFields: [CustomPropertySearchFieldEnum], $facets: [CustomPropertyRequestFacetType!], $filter: CustomPropertySearchFilterType, $order: [CustomPropertySearchSortInputType!]) {
    searchItems: searchCustomProperties(
        top: $top,
        skip: $skip,
        count: $count,
        facets: $facets,
        filter: $filter,
        order: $order,
        search: $search,
        searchFields: $searchFields
    ) {
        info {
            totalCount
            facets {
                key
                result {
                    count
                    items {
                        value
                        count
                    }
                }
            }
        }
        page {
            id
            name
            description
            type
            lastUpdatedAt
            taggedApplicationCount
            taggedLocationCount
            taggedNetworkDeviceCount
            taggedNetworkInterfaceCount
            user {
                id
                email
                name
            }
            userId
            validTypes {
                type
            }
            values {
                id
                name
            }
            permissions
        }
    }
}
