import { useEffect } from 'react'
import { Callout, Classes, Divider, Label } from '@blueprintjs/core'
import classNames from 'classnames'
import { SelectField } from 'components/common/form/SelectField.tsx';
import { Form } from 'components/common/form/Form.tsx';
import { STRINGS } from 'app-strings'
import { isEmpty } from "lodash";

interface ISelectRunbookProps {
    runbookId?: string;
    runbookList: any;
    setRunbookId: (any) => void;
    inputs: any;
    editInfos: any;
}

const StepSelect = (props: ISelectRunbookProps) => {
    const stepStrings = STRINGS.scheduleRunbook.wizard.panelSelect;
    
    useEffect(() => {
        if (props.inputs) {
            props.inputs.current = {};
        }
        if (props.editInfos) {
            props.setRunbookId(props.editInfos.runbook.id);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <div className={classNames(Classes.DIALOG_BODY)}>
            <p className='fw-bold'>{stepStrings.title}</p>
            <Divider className='ms-0' />
            <Form className='row pt-3' loading={false} initialValues={{ select_runbook: props.runbookId }}>
                <Label className='col-6 mt-2'>{stepStrings.labelRunbook}</Label>
                <div className='col-6 mb-3'>
                    <SelectField name="select_runbook"
                        disabled={isEmpty(props.runbookList) || props.editInfos}
                        onChange={e => props.setRunbookId(e.target.value)}
                        value={props.runbookId}
                        >
                        <option value="">Select Runbook</option>
                        {props.runbookList?.map(runbook => <option key={runbook.id} value={runbook.id}>{runbook.name}</option>)}
                    </SelectField>
                </div>
                <Callout intent='primary'>{stepStrings.note}</Callout>
            </Form>
        </div>
    )
}

export default StepSelect
