/** This module contains the store that holds the user preferences settings.
 *  @module
 */
import { cloneDeep, mergeWith, isEqual } from 'lodash';
import { UserPreferences } from "utils/services/UserPrefsTypes";
import { DEFAULT_USER_PREFS, UserPrefsService } from "utils/services/UserPrefsService";

let ready: Boolean = false,
    userPrefsService,
    preferences: UserPreferences,
    prevPreferences: UserPreferences,
    callbacks: Function[] = [];

const MAX_RETRIES = 3;
let retries = 0;

/** This asynchronous method performs the job of fetching user preferences from the API, storing the retrieved
 *  response in a local variable and also returning it. In case a successful sync was done already, this method
 *  will not do anything unless the `force` flag is passed as true.
 *  @returns a Promise that resolves to void. */
async function syncUserPreferencesFromAPI ({ force = false } = {}): Promise<void> {
    if (force || (!ready && retries < MAX_RETRIES)) {
        try {
            userPrefsService = userPrefsService || new UserPrefsService();
            await userPrefsService
                .get()
                .then(preferencesFromService => {
                    preferences = preferencesFromService;
                    ready = true;
                    retries = 0;
                })
                .catch(ex => {
                    preferences = DEFAULT_USER_PREFS;
                    // If user preference fetch fails again and again, stop attempting after MAX_RETRIES
                    if (MAX_RETRIES === ++retries) {
                        console.warn("Max retries reached for fetching user preferences");
                    }
                });
        } catch (ex) {
            console.error(ex);
        }
    }
};

/** Sync user preferences from API if not done already and then return a clone of the preferences object from local store
 *  @returns a Promise that resolves to the UserPreferences object. */
async function getUserPreferences({ noCache = false } = {}): Promise<UserPreferences> {
    await syncUserPreferencesFromAPI({ force: noCache });
    return cloneDeep(preferences);
}

/** Return whatever user preferences object is in the store. Remember that this will return undefined
 *  initially until the preferences have been loaded from API. You better know what you're doing if you
 *  use this method! You should always try to use the getUserPreferences asynchronous method instead.
 *  Using this method in other situations can lead to issues in the future!
 *  @returns the UserPrefences object with the cached user preferences. */
function getCachedUserPreferences(): UserPreferences |undefined {
    return ready ? cloneDeep(preferences) : undefined;
}

/** Internal method that will walk through an object and delete any keys that have it's value as null.  It wil
 *      recursively go through the object and all sub objects.
 *  @param input a dictionary with a key value map. */
function deleteKeysWithEmptyValue(input): void {
    for (const key in input) {
        if (input[key] === "" || input[key] === null) {
            delete input[key];
        } else if (typeof input[key] === "object") {
            deleteKeysWithEmptyValue(input[key]);
        }
    }
}

/** This method takes a partial `UserPreferences` object as input, merges it with existing data and
 *  updates the API. If you want to clear a specific user preference, it can be done so by passing it's
 *  value as null.
 *  @param input the partial set of UserPreferences to set.
 *  @returns a Promise that resolves to the current set of user preferences. */
async function setUserPreferences(input: Partial<UserPreferences>): Promise<UserPreferences> {
    const userPrefsCopy = await getUserPreferences();
    try {
        mergeWith(userPrefsCopy, input, (objValue, srcValue) => {
            if (Array.isArray(objValue) || Array.isArray(srcValue)) {
                // By default mergeWith will merge arrays, we want to replace the array's contents
                return srcValue;
            }
        });
        // If the user is clearing any of the preferences by providing a null value, delete those entries
        // We modified this to recursively go through the whole object.
        deleteKeysWithEmptyValue(userPrefsCopy);

        if (!isEqual(preferences, userPrefsCopy)) {
            // Update local state and trigger a change. Note that at this point, the API hasn't been
            // updated yet. This logic is here so that we don't have to always wait for the API call to
            // complete. The updated will be rolled back in case the API update fails. The promise returned
            // by this method will however complete only after the API gets updated. Only the listeners of
            // callbacks will be informed right away.
            const tempPrevPreferences = prevPreferences;
            prevPreferences = preferences;
            preferences = userPrefsCopy;
            onPreferencesChanged();
            
            try {
                const updatedPreferences = await userPrefsService.update(userPrefsCopy);
                // If API provided the updated preferences in it's response (Refer to the TBD below)
                if (updatedPreferences) {
                    // If `update` call executed successfully, and for some odd reason
                    // the output is different than what we already set in our local store
                    if (!isEqual(preferences, updatedPreferences)) {
                        prevPreferences = preferences;
                        preferences = updatedPreferences;
                        onPreferencesChanged();
                    }
                    return preferences;
                } else {
                    const tempPrevPreferences = preferences;
                    // TBD: User preferences API currently returns empty response after `update` is complete.
                    // Modify the API to return the updated user preferences object and once that's done, 
                    // we can remove the `noCache: true` from the below call. The value will instead be set
                    // directly from the response of `userPrefsService.update` call above.
                    const newPreferences = await getUserPreferences({ noCache: true });
                    // If `getUserPreferences` call executed successfully, and for some odd reason
                    // the output is different than what we already set in our local store
                    if (!isEqual(tempPrevPreferences, newPreferences)) {
                        // Move the previous preferences into `prevPreferences` variable. We are keeping it in a temp variable before calling
                        // `getUserPreferences` because that call will internally update the preferences variable and so by the time we
                        // reach here, it will be too late to read the previous value from `preferences`.
                        prevPreferences = tempPrevPreferences;
                        onPreferencesChanged();
                    }
                    return newPreferences;
                }
            } catch (ex) {
                // Rollback the change if it couldn't get updated in the API
                preferences = prevPreferences;
                prevPreferences = tempPrevPreferences;
                onPreferencesChanged();
                throw ex;
            }
        } else {
            return await getUserPreferences();
        }

    } catch (ex) {
        console.error("Unable to set user preference - " + JSON.stringify(input));
        console.error(ex);
        throw ex;
    }
}

/** This method deletes the user preference settings for current user.
 *  After deleting the user preference, it will trigger a sync which is designed
 *  to automatically re-create a default user preference object. If you wish to
 *  only delete it and not recreate it, then pass dontRecreate flag as true.
 *  Although keep in mind that the very next time user preference is fetched from
 *  the API for this user, a new entry will get created.
 *  @returns a Promise that resolves to the user preferences object. */
async function clearAllUserPreferences({ dontRecreate = false } = {}): Promise<UserPreferences> {
    await userPrefsService.clear();
    prevPreferences = preferences;
    if (dontRecreate) {
        return Promise.resolve({} as UserPreferences);
    } else {
        const newPrefs = await getUserPreferences({ noCache: true });
        onPreferencesChanged();
        return newPrefs;
    }
}

/** This method takes care of informing all the listening callbacks
 *  about a change in preferences. It will pass a clone of the new value so that 
 *  someone doesn't end up messing with the original value in the store. */
function onPreferencesChanged(): void {
    const newPreferences = cloneDeep(preferences);
    for (const callback of callbacks) {
        callback({
            newVal: newPreferences,
            prevVal: prevPreferences,
        });
    }
}

/** adds a listener for changes to the user preferences store.
 *  @param callback a function that will be called when the user preferences change. */
function addChangeCallback (callback: (event: {newVal: UserPreferences, prevVal: UserPreferences}) => void): void {
    callbacks.push(callback);
}

/** removes the specified listener for changes to the user preferences.
 *  @param callback a function that will be called when the user preferences change. */
function removeChangeCallback (callback?: (event: {newVal: UserPreferences, prevVal: UserPreferences}) => void): void {
    callbacks = callbacks.filter(c => c !== callback);
}

export { getUserPreferences, setUserPreferences, addChangeCallback, removeChangeCallback, clearAllUserPreferences, getCachedUserPreferences };
