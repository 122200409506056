import React, { useEffect, useState, useCallback } from 'react';
import * as yup from 'yup';
import { Alignment, Button, Checkbox, Classes, Dialog, Intent, PopoverPosition, Switch, Tooltip } from '@blueprintjs/core';
import { LoadingOverlay, ErrorToaster, SuccessToaster } from '@tir-ui/react-components';
import { useQuery } from 'utils/hooks/useQuery.ts';
import { Query } from 'reporting-infrastructure/types/Query.ts';
import { useMutation } from '@apollo/client';
import { InputField } from "components/common/form/InputField.tsx";
import { TextAreaField } from 'components/common/form/TextAreaField.tsx';
import { SelectField } from 'components/common/form/SelectField.tsx';
import { Form } from 'components/common/form/Form.tsx';
import type { CreateDataSourcesInput, SetDataSourcesInput } from '../views/EdgeGatewaysView.tsx';
import { dataSourceTypeOptions } from 'utils/services/DataSourceApiService.ts';
import { STRINGS } from 'app-strings';
import DATASOURCE from './../queries/datasource.graphql';
import EDGE_IP_ADDRESS from './../queries/edge-ip-address.graphql';
import CREATE_DATASOURCE_MUTATION from './../queries/create-datasource-mutation.graphql';
import SET_DATASOURCE_MUTATION from './../queries/set-datasource-mutation.graphql';
import './AddDataSourceModal.scss';

const HTTPS_PROTOCOL = 'https';

const pwdMask = '*********';

const defaultDataSourceValues = {
	gateway_ip: '',
	suggested_gateway_ip: '',
	source_name: '',
	source_etag: '',
	source_type: STRINGS.DATA_SOURCES.addDataSourceDialog.fields.chooseType,
	source_enabled: true,
	source_ingest_enabled: true,
	source_ssl_validation_enabled: false,
	source_hostname: '',
	source_proto: '',
	source_port: '',
	source_username: '',
	source_password: '',
};

const AddDataSourceModal = React.forwardRef((props: any, ref) => {
	React.useImperativeHandle(ref, () => ({
		setGatewayId(id: string) {
			if (id) {
				setGatewayId(id);
			}
		},
		setDataSourceId(id: string) {
			if (id) {
				setDataSourceId(id);
			}
		},
		setEdgeHasCertificateValidation(hasCertificateValidation: boolean) {
			setEdgeHasCertificateValidation(hasCertificateValidation)
		},
		setEdit(edit: boolean) {
			setEditMode(edit);
		},
		handleOpen() {
			setIsOpen(!isOpen);
		},
	}));

	const { dataRefresh } = props;
	const [editMode, setEditMode] = useState<boolean>(false);
	const [gatewayId, setGatewayId] = useState<string | undefined>(undefined);
	const [hasEdgeCertificateValidation, setEdgeHasCertificateValidation] = useState(false);
	const [dataSourceId, setDataSourceId] = useState<string | undefined>(
		undefined
	);
	const [editCredentials, setEditCredentials] = useState<boolean>(false);
	const [dataSourceValues, setDataSourceValues] = useState<any>(
		defaultDataSourceValues
	);
	const [isOpen, setIsOpen] = useState(false);

	const handleClose = useCallback(() => {
		setIsOpen(false);
		setEditCredentials(false);
		setDataSourceValues(defaultDataSourceValues);
	}, []);

	const [loading, setLoading] = useState(false);

	const dataSourceQuery = useQuery({
		query: new Query(DATASOURCE),
		name: 'dataSource',
		lazy: true,
	});

	const edgeIpQuery = useQuery({
		query: new Query(EDGE_IP_ADDRESS),
		name: 'edgeIpAddress',
		lazy: true,
	});

	const [createDataSource] = useMutation<any, CreateDataSourcesInput>(
		CREATE_DATASOURCE_MUTATION,
		{
			onCompleted: (_data) => {
				SuccessToaster({
					message:
						STRINGS.DATA_SOURCES.edgeGateways.messages.queryResponse
							.success,
				});
				handleClose();
			},
			onError: (err) => {
				ErrorToaster({
					message:
						STRINGS.DATA_SOURCES.edgeGateways.messages.queryResponse
							.error,
				});
				console.error(err?.message);
			},
		}
	);

	const [setDataSource] = useMutation<any, SetDataSourcesInput>(
		SET_DATASOURCE_MUTATION,
		{
			onCompleted: (_data) => {
				SuccessToaster({
					message:
						STRINGS.DATA_SOURCES.edgeGateways.messages.queryResponse
							.success,
				});
				handleClose();
			},
			onError: (err) => {
				ErrorToaster({
					message:
						STRINGS.DATA_SOURCES.edgeGateways.messages.queryResponse
							.error,
				});
				console.error(err?.message);
			},
		}
	);

	useEffect(() => {
		let suggested_gateway_ip;
		if (isOpen) {
			setLoading(true);
			edgeIpQuery
				.run({
					queryVariables: { id: String(gatewayId) },
					noCache: true,
				})
				.then((data) => {
					data && data.edgeIpAddress?.ipAddress
						? (suggested_gateway_ip =
								data['edgeIpAddress']['ipAddress'])
						: (suggested_gateway_ip = undefined);
				})
				.then(() => {
					if (editMode) {
						dataSourceQuery
							.run({
								queryVariables: { id: String(dataSourceId) },
								noCache: true,
							})
							.then((data) => {
								data &&
									setDataSourceValues({
										suggested_gateway_ip:
											suggested_gateway_ip,
										gateway_ip:
											data.dataSource.iot?.hostname || '',
										source_etag: data.dataSource.eTag,
										source_name: data.dataSource.name,
										source_type: data.dataSource.type,
										source_enabled:
											data.dataSource.queriesEnabled,
										source_ssl_validation_enabled: data.dataSource.sslValidationEnabled,
										source_ingest_enabled:
											data.dataSource
												.metricStreamingEnabled,
										source_hostname:
											data.dataSource.hostname,
										source_proto: data.dataSource.proto,
										source_port: data.dataSource.port,
										source_username:
											data.dataSource.authentication
												?.username || '',
										source_password: pwdMask,
									});
							})
							.finally(() => {
								setLoading(false);
							});
					} else {
						setDataSourceValues({
							...dataSourceValues,
							source_ssl_validation_enabled :hasEdgeCertificateValidation, // Set default value to what the edge gateway has set
							gateway_ip: suggested_gateway_ip,
						});
						setLoading(false);
					}
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editMode, dataSourceId, isOpen]);

	const validationSchema = yup.object().shape({
		gateway_ip: yup
			.string()
			.label(STRINGS.DATA_SOURCES.addDataSourceDialog.fields.gatewayIp)
			.required(),
		source_name: yup
			.string()
			.label(
				STRINGS.DATA_SOURCES.addDataSourceDialog.fields
					.sourceDescription
			)
			.required(),
		source_type: yup
			.string()
			.label(STRINGS.DATA_SOURCES.addDataSourceDialog.fields.sourceType)
			.notOneOf([
				STRINGS.DATA_SOURCES.addDataSourceDialog.fields.chooseType,
			]),
		source_hostname: yup
			.string()
			.label(
				STRINGS.DATA_SOURCES.addDataSourceDialog.fields.sourceHostname
			)
			.required(),
		source_protocol: yup
			.string()
			.label(
				STRINGS.DATA_SOURCES.addDataSourceDialog.fields.sourceProtocol
			)
			.required(),
		source_port: yup
			.number()
			.label(STRINGS.DATA_SOURCES.addDataSourceDialog.fields.sourcePort)
			.required(),
		source_username: yup
			.string()
			.label(
				STRINGS.DATA_SOURCES.addDataSourceDialog.fields.sourceUsername
			)
			.required(),
		source_password: yup
			.string()
			.label(
				STRINGS.DATA_SOURCES.addDataSourceDialog.fields.sourcePassword
			)
			.when([], {
				is: () => editMode === true && editCredentials === true,
				then: yup.string().notOneOf([pwdMask]).required(),
				otherwise: yup.string().required(),
			}),
	});

	/***
	 * onSubmit Handler function for the support formik form
	 * Resets the form after successful submission
	 * @param values
	 */
	const handleSubmit = (values, { setSubmitting }) => {
		(editMode
			? setDataSource({
					variables: {
						input: {
							id: String(dataSourceId),
							eTag: String(dataSourceValues['source_etag']),
							type: values.hasOwnProperty('source_type')
								? Object.keys(dataSourceTypeOptions)[
										Object.values(
											dataSourceTypeOptions
										).indexOf(values['source_type'])
								  ]
								: '',
							name: values.hasOwnProperty('source_name')
								? values['source_name'].toString().trim()
								: '',
							hostname: values.hasOwnProperty('source_hostname')
								? values['source_hostname'].toString().trim()
								: '',
							port: values.hasOwnProperty('source_port')
								? Number(
										values['source_port'].toString().trim()
								  )
								: Number(''),
							protocol: values.hasOwnProperty('source_protocol')
								? 'HTTPS'
								: '',
							sslValidationEnabled: values.hasOwnProperty(
								'source_ssl_validation_enabled'
							)
								? Boolean(values['source_ssl_validation_enabled'])
								: false,
							metricStreamingEnabled: values.hasOwnProperty(
								'source_ingest_enabled'
							)
								? Boolean(values['source_ingest_enabled'])
								: false,
							queriesEnabled: values.hasOwnProperty(
								'source_enabled'
							)
								? Boolean(values['source_enabled'])
								: false,
							iot: {
								deviceId: String(gatewayId),
								hostname: values.hasOwnProperty('gateway_ip')
									? values['gateway_ip'].toString().trim()
									: '',
								hubHostname: values.hasOwnProperty('gateway_ip')
									? values['gateway_ip'].toString().trim()
									: '',
							},
							authentication: {
								username:
									editCredentials &&
									values.hasOwnProperty('source_username')
										? values['source_username']
												.toString()
												.trim()
										: null,
								password:
									editCredentials &&
									values.hasOwnProperty('source_password')
										? values['source_password']
												.toString()
												.trim() !== pwdMask
											? values['source_password']
													.toString()
													.trim()
											: ''
										: null,
							},
						},
					},
			  })
			: createDataSource({
					variables: {
						input: {
							type: values.hasOwnProperty('source_type')
								? Object.keys(dataSourceTypeOptions)[
										Object.values(
											dataSourceTypeOptions
										).indexOf(values['source_type'])
								  ]
								: '',
							name: values.hasOwnProperty('source_name')
								? values['source_name'].toString().trim()
								: '',
							hostname: values.hasOwnProperty('source_hostname')
								? values['source_hostname'].toString().trim()
								: '',
							port: values.hasOwnProperty('source_port')
								? Number(
										values['source_port'].toString().trim()
								  )
								: Number(''),
							protocol: values.hasOwnProperty('source_protocol')
								? 'HTTPS'
								: '',
							sslValidationEnabled: values.hasOwnProperty(
								'source_ssl_validation_enabled'
							)
								? Boolean(values['source_ssl_validation_enabled'])
								: false,
							metricStreamingEnabled: values.hasOwnProperty(
								'source_ingest_enabled'
							)
								? Boolean(values['source_ingest_enabled'])
								: false,
							queriesEnabled: values.hasOwnProperty(
								'source_enabled'
							)
								? Boolean(values['source_enabled'])
								: false,
							iot: {
								deviceId: String(gatewayId),
								hostname: values.hasOwnProperty('gateway_ip')
									? values['gateway_ip'].toString().trim()
									: '',
								hubHostname: values.hasOwnProperty('gateway_ip')
									? values['gateway_ip'].toString().trim()
									: '',
							},
							authentication: {
								username: values.hasOwnProperty(
									'source_username'
								)
									? values['source_username']
											.toString()
											.trim()
									: null,
								password: values.hasOwnProperty(
									'source_password'
								)
									? values['source_password']
											.toString()
											.trim()
									: null,
							},
						},
					},
			  })
		)
			.then(() => {
				dataRefresh();
			})
			.finally(() => {
				setEditCredentials(false);
				setSubmitting(false);
			});
	};

	if (loading) {
		return <LoadingOverlay visible={true} />;
	} else
		return (
			<React.Fragment>
				<Dialog
					title={
						editMode
							? STRINGS.DATA_SOURCES.addDataSourceDialog.title
									.edit
							: STRINGS.DATA_SOURCES.addDataSourceDialog.title.add
					}
					isOpen={isOpen}
					autoFocus={true}
					canEscapeKeyClose={true}
					canOutsideClickClose={true}
					enforceFocus={true}
					usePortal={true}
					onClose={handleClose}
					style={{ width: '450px' }}
				>
					<div className="tir-data-source">
						<Form
							initialValues={{
								gateway_ip: dataSourceValues['gateway_ip']
									? dataSourceValues['gateway_ip']
									: '',
								source_name:
									dataSourceValues['source_name'] &&
									dataSourceValues['source_name'],
								source_type:
									dataSourceValues['source_type'] &&
									dataSourceTypeOptions[
										dataSourceValues['source_type']
									],
								source_enabled: dataSourceValues[
									'source_enabled'
								]
									? dataSourceValues['source_enabled']
									: '',
								source_ssl_validation_enabled: dataSourceValues[
									'source_ssl_validation_enabled'
								]
									? dataSourceValues['source_ssl_validation_enabled']:
									false,
								source_ingest_enabled: dataSourceValues[
									'source_ingest_enabled'
								]
									? dataSourceValues['source_ingest_enabled']
									: '',
								source_hostname:
									dataSourceValues['source_hostname'] &&
									dataSourceValues['source_hostname'],
								source_protocol: HTTPS_PROTOCOL,
								source_port:
									dataSourceValues['source_port'] &&
									dataSourceValues['source_port'],
								source_username:
									dataSourceValues['source_username'] &&
									dataSourceValues['source_username'],
								source_password:
									dataSourceValues['source_password'] &&
									dataSourceValues['source_password'],
							}}
							validationSchema={validationSchema}
							loading={false}
							onSubmit={handleSubmit}
							onSuccessMessage={
								editMode
									? STRINGS.DATA_SOURCES.addDataSourceDialog
											.messages.editedSuccessfully
									: STRINGS.DATA_SOURCES.addDataSourceDialog
											.messages.addedSuccessfully
							}
						>
							<InputField
								id="gateway_ip"
								name="gateway_ip"
								type="text"
								className="gateway-ip-field"
								label={
									STRINGS.DATA_SOURCES.addDataSourceDialog
										.fields.gatewayIp
								}
								onChange={(event) => {
									setDataSourceValues({
										...dataSourceValues,
										gateway_ip: event.currentTarget?.value,
									});
								}}
								helperText={
									STRINGS.DATA_SOURCES.addDataSourceDialog
										.fieldsHelp.gatewayIp +
									(dataSourceValues['gateway_ip'] &&
									dataSourceValues['suggested_gateway_ip']
										? dataSourceValues['gateway_ip'] !==
										  dataSourceValues[
												'suggested_gateway_ip'
										  ]
											? ' ' +
											  STRINGS.DATA_SOURCES
													.addDataSourceDialog
													.fieldsHelp.suggestedIP +
											  ': ' +
											  dataSourceValues[
													'suggested_gateway_ip'
											  ]
											: ''
										: '')
								}
								required={true}
								disabled={false}
							/>
							<Switch
								id="source_enabled"
								name="source_enabled"
								checked={dataSourceValues['source_enabled']}
								label={
									STRINGS.DATA_SOURCES.addDataSourceDialog
										.fields.sourceEnabled
								}
								onChange={(event) => {
									setDataSourceValues({
										...dataSourceValues,
										source_enabled:
											event.currentTarget?.checked,
										source_ingest_enabled:
											dataSourceValues['source_type'] &&
											dataSourceTypeOptions[
												dataSourceValues['source_type']
											] !== dataSourceTypeOptions.NET_IM
												? !event.currentTarget
														?.checked &&
												  event.currentTarget?.checked
												: event.currentTarget?.checked,
									});
								}}
								alignIndicator={Alignment.RIGHT}
								inline={true}
								disabled={false}
							/>
							{dataSourceTypeOptions[
								dataSourceValues['source_type']
							] === dataSourceTypeOptions.NET_IM &&
							dataSourceValues['source_ingest_enabled'] ? (
								<React.Fragment>
									<Tooltip
										className={
											Classes.TOOLTIP_INDICATOR +
											' border-0 d-inline'
										}
										usePortal={false}
										content={
											STRINGS.DATA_SOURCES
												.addDataSourceDialog.messages
												.operationNotAllowed
										}
										position={PopoverPosition.TOP}
										transitionDuration={50}
									>
										<Switch
											id="source_ingest_enabled"
											name="source_ingest_enabled"
											checked={
												dataSourceValues[
													'source_ingest_enabled'
												]
											}
											label={
												STRINGS.DATA_SOURCES
													.addDataSourceDialog.fields
													.sourceEnabledDataIngest
											}
											onChange={(event) => {
												dataSourceTypeOptions[
													dataSourceValues[
														'source_type'
													]
												] !==
													dataSourceTypeOptions.NET_IM &&
													setDataSourceValues({
														...dataSourceValues,
														source_ingest_enabled:
															event.currentTarget
																?.checked,
													});
											}}
											alignIndicator={Alignment.RIGHT}
											inline={true}
											disabled={
												!dataSourceValues[
													'source_enabled'
												]
											}
										/>
									</Tooltip>
								</React.Fragment>
							) : (
								<>
									<Switch
										id="source_ingest_enabled"
										name="source_ingest_enabled"
										checked={
											dataSourceValues[
												'source_ingest_enabled'
											]
										}
										label={
											STRINGS.DATA_SOURCES
												.addDataSourceDialog.fields
												.sourceEnabledDataIngest
										}
										onChange={(event) => {
											dataSourceTypeOptions[
												dataSourceValues['source_type']
											] !==
												dataSourceTypeOptions.NET_IM &&
												setDataSourceValues({
													...dataSourceValues,
													source_ingest_enabled:
														event.currentTarget
															?.checked,
												});
										}}
										alignIndicator={Alignment.RIGHT}
										inline={true}
										disabled={
											!dataSourceValues['source_enabled']
										}
									/>
								</>
							)}

							<Switch
								id="source_ssl_validation_enabled"
								name="source_ssl_validation_enabled"
								checked={dataSourceValues['source_ssl_validation_enabled']}
								label={
									STRINGS.DATA_SOURCES.addDataSourceDialog
										.fields.sourceSSLValidationEnabled
								}
								onChange={(event) => {
									setDataSourceValues({
										...dataSourceValues,
										source_ssl_validation_enabled: event.currentTarget?.checked,
									});
								}}
								alignIndicator={Alignment.RIGHT}
								inline={true}
								disabled={false}
							/>

							<SelectField
								id="source_type"
								name="source_type"
								label={
									STRINGS.DATA_SOURCES.addDataSourceDialog
										.fields.sourceType
								}
								className="source-field"
								required={true}
								options={[
									STRINGS.DATA_SOURCES.addDataSourceDialog
										.fields.chooseType,
								].concat([
									dataSourceTypeOptions.NET_PROFILER,
									dataSourceTypeOptions.NET_IM,
									dataSourceTypeOptions.APP_RESPONSE,
								])}
								onChange={(event) => {
									setDataSourceValues({
										...dataSourceValues,
										source_type: Object.keys(
											dataSourceTypeOptions
										)[
											Object.values(
												dataSourceTypeOptions
											).indexOf(event.currentTarget.value)
										],
										source_port:
											event.currentTarget?.value ===
											dataSourceTypeOptions.NET_IM
												? '8543'
												: '443',
										source_enabled:
											event.currentTarget?.value ===
											dataSourceTypeOptions.NET_IM
												? false
												: dataSourceValues[
														'source_enabled'
												  ],
										source_ingest_enabled:
											event.currentTarget?.value ===
											dataSourceTypeOptions.NET_IM
												? false
												: dataSourceValues[
														'source_ingest_enabled'
												  ],
									});
								}}
								value={
									dataSourceTypeOptions[
										dataSourceValues['source_type']
									]
								}
								disabled={editMode}
							/>
							<InputField
								id="source_hostname"
								name="source_hostname"
								type="text"
								required={true}
								className="hostname-field"
								onChange={(event) => {
									setDataSourceValues({
										...dataSourceValues,
										source_hostname:
											event.currentTarget?.value,
									});
								}}
								label={
									STRINGS.DATA_SOURCES.addDataSourceDialog
										.fields.sourceHostname
								}
								disabled={false}
							/>
							<div
								style={{
									display: 'flex',
								}}
							>
								<div style={{ display: 'inline-block' }}>
									<InputField
										id="source_protocol"
										name="source_protocol"
										type="text"
										required={true}
										className="protocol-field"
										onChange={(event) => {
											setDataSourceValues({
												...dataSourceValues,
												source_protocol:
													event.currentTarget?.value,
											});
										}}
										label={
											STRINGS.DATA_SOURCES
												.addDataSourceDialog.fields
												.sourceProtocol
										}
										disabled={true}
									/>
								</div>
								<div
									style={{
										display: 'inline-block',
										paddingLeft: '4%',
									}}
								>
									<InputField
										id="source_port"
										name="source_port"
										type="text"
										required={true}
										className="port-field"
										onChange={(event) => {
											setDataSourceValues({
												...dataSourceValues,
												source_port:
													event.currentTarget?.value,
											});
										}}
										label={
											STRINGS.DATA_SOURCES
												.addDataSourceDialog.fields
												.sourcePort
										}
										disabled={false}
										value={dataSourceValues['source_port']}
									/>
								</div>
							</div>
							{editMode && (
								<fieldset className="border p-2 fieldset--with-bg">
									<Checkbox
										id={'source_edit_credentials'}
										name={'source_edit_credentials'}
										key={'source_edit_credentials'}
										label={
											STRINGS.DATA_SOURCES
												.addDataSourceDialog.fields
												.editCredentials
										}
										onChange={(_event: any) => {
											setEditCredentials(
												!editCredentials
											);
										}}
										checked={editCredentials}
									/>
									<InputField
										id="source_username"
										name={'source_username'}
										label={
											STRINGS.DATA_SOURCES
												.addDataSourceDialog.fields
												.sourceUsername
										}
										className="username-field"
										onChange={(event) => {
											setDataSourceValues({
												...dataSourceValues,
												source_username:
													event.currentTarget?.value,
											});
										}}
										required={editCredentials}
										disabled={!editCredentials}
									/>
									<InputField
										id="source_password"
										name={'source_password'}
										label={
											STRINGS.DATA_SOURCES
												.addDataSourceDialog.fields
												.sourcePassword
										}
										type="password"
										className="password-field"
										onChange={(event) => {
											setDataSourceValues({
												...dataSourceValues,
												source_password:
													event.currentTarget?.value,
											});
										}}
										onFocus={(event) => {
											event.currentTarget.value = '';
										}}
										required={editCredentials}
										disabled={!editCredentials}
									/>
								</fieldset>
							)}
							{!editMode && (
								<>
									<InputField
										id="source_username"
										name={'source_username'}
										label={
											STRINGS.DATA_SOURCES
												.addDataSourceDialog.fields
												.sourceUsername
										}
										className="username-field"
										onChange={(event) => {
											setDataSourceValues({
												...dataSourceValues,
												source_username:
													event.currentTarget?.value,
											});
										}}
										required={true}
									/>
									<InputField
										id="source_password"
										name={'source_password'}
										label={
											STRINGS.DATA_SOURCES
												.addDataSourceDialog.fields
												.sourcePassword
										}
										type="password"
										className="password-field"
										onChange={(event) => {
											setDataSourceValues({
												...dataSourceValues,
												source_password:
													event.currentTarget?.value,
											});
										}}
										required={true}
									/>
								</>
							)}
							<TextAreaField
								id="source_name"
								name="source_name"
								data-testid="source-name"
								required={true}
								inline={false}
								className="name-field"
								onChange={(event) => {
									setDataSourceValues({
										...dataSourceValues,
										source_name: event.currentTarget?.value,
									});
								}}
								label={
									STRINGS.DATA_SOURCES.addDataSourceDialog
										.fields.sourceDescription
								}
								disabled={false}
							/>
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
								}}
							>
								<div style={{ display: 'inline-block' }}>
									<Button
										className="user-btn"
										onClick={() => {
											handleClose();
										}}
									>
										{
											STRINGS.DATA_SOURCES
												.addDataSourceDialog.buttons
												.cancelBtnText
										}
									</Button>
								</div>
								<div
									style={{
										display: 'inline-block',
										paddingLeft: '5px',
									}}
								>
									<Button
										type="submit"
										className="tir-ui-btn-submit"
										intent={Intent.PRIMARY}
									>
										{
											STRINGS.DATA_SOURCES
												.addDataSourceDialog.buttons
												.submitBtnText
										}
									</Button>
								</div>
							</div>
						</Form>
					</div>
				</Dialog>
			</React.Fragment>
		);
});

export { AddDataSourceModal };
