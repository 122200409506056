query runbooks($incidentIds: [String!], $runbookId: String, $startTime: String, $endTime: String) {
    runbooks: runbooks(
        filter: {incidentIds: $incidentIds, runbookIds: [$runbookId], time: {startTime: $startTime, endTime: $endTime}}
    ) {
        nodes {
            id
            incidentId
            timestamp
            entity {
                name
                kind
                attributes {
                    field
                    value
                }
                parent {
                    id
                    kind
                    ipAddress
                    name
                }
            }
            mapping {
                triggerType
            }
            template
            status
            priority
            priorityReasons {
                id
                priority
                text
            }
            executionInsights {
                id
                timestamp
                text
            }
            debug {
                error
                stackTrace
            }
            impactedApplications {
                name
            }
            impactedLocations {
                name
            }
            impactedUsers {
                name
                ipAddress
                deviceName
            }
            datasets {
                id
                type
                timeReference {
					name
					startTime
					endTime
				}
                keys {
                    id
                    label
                    type
                    unit
                    hidden
                }
                metrics {
                    id
                    label
                    type
                    unit
                    enum {
                        key
                        value
                        weight
                    }
                    absentMeansZero
                }
                info {
                    dataSources {
                        type
                        url
                        name
                    }
                    actualTimes {
                        startTime
                        endTime
                        granularities
                    }
                    actualFilters
                    error {
                        code
                        message
                        innerError {
                            trace
                            properties {
                                key
                                value
                            }
                        }
                        details {
                            code
                            message
                        }
                    }
                    warning {
                        code
                        message
                        innerError {
                            trace
                            properties {
                                key
                                value
                            }
                        }
                        details {
                            code
                            message
                        }
                    }

                }
                debug {
                    name
                    type
                    value
                }
                datapoints {
                    keys {
                        id
                        value
                    }
                    data {
                        timestamp
                        metrics {
                            id
                            value
                        }
                    }
                    granularity
                }
            }
            variableResults {
                primitiveVariables {
                    name
                    isReadOnly
                    type
                    unit
                    defaultValue
                    startValue
                    value
                }
                structuredVariables {
                    name
                    type
                    isTimeseries
                    value
                }
            }
            triggerInputs {
                requestBody
                requestPath
                requestHeaders {
                    field
                    values
                }
                requestQueryParameters {
                    field
                    values
                }
            }
            indicators {
                entityId
                metric
                sourceId
                kind
                isPrimary
                entity {
                    name
                    kind
                    attributes {
                        field
                        value
                    }
                    parent {
                        id
                        kind
                        ipAddress
                        name
                    }
                }
            }
            notes {
                id
                text
                timestamp
            }
            errors {
                code
                message
                innerError {
                    trace
                    properties {
                        key
                        value
                    }
                }
            }
            nodeRunStatus {
				id
				started
				ended
				status
				attributes
				{
					outputIndex
				}
			}
			expandedNodeRunStatus {
				id
				started
				ended
				status
				attributes
				{
					outputIndex
				}
			}
        }
    }
}
