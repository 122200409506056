/** This module contains the function React component for rendering the data ocean filters.  This component
 *  renders all the filters as well as the controls to add a filter.
 *  @module
 */
import React, { useState, useEffect, useContext } from 'react';
import { Button, Menu, MenuItem, Position, Popover } from '@blueprintjs/core';
import { IconNames } from '@tir-ui/react-components';
import { Node } from 'react-flow-renderer';
import { itemType } from 'components/common/select/SelectInput.tsx';
import { NodeLibrary, NodeLibraryNode } from 'pages/create-runbook/views/create-runbook/NodeLibrary.ts';
import { UniversalNode } from 'components/common/graph/UniversalNode.ts';
import { GraphDef, NodeDef, RunbookInfo, Variant } from 'components/common/graph/types/GraphTypes.ts';
import { DataOceanLimit } from 'components/common/graph/editors/data-ocean/DataOceanLimit.tsx';
import { DataOceanFilter, getOptions } from "components/common/graph/editors/data-ocean/DataOceanFilter.tsx";
import { HELP, STRINGS } from "app-strings";
import { ContextMode as ContextModeEnum, CONTEXT_MODE, VariableContextByScope } from 'utils/runbooks/RunbookContext.class.ts';
import { DataOceanUtils } from 'components/common/graph/editors/data-ocean/DataOceanUtils.ts';
import { InlineHelp } from 'components/common/layout/inline-help/InlineHelp.tsx';
import { VariableContext } from 'utils/runbooks/VariableContext.ts';
import { GLOBAL_SCOPE, INCIDENT_SCOPE, RUNTIME_SCOPE } from 'utils/runbooks/VariablesUtils.ts';
import { DataOceanMetric, DataOceanObjectType, QUERY_TYPE } from 'components/common/graph/editors/data-ocean/DataOceanMetadata.type.ts';
import { getTypes } from 'utils/stores/GlobalDataSourceTypeStore.ts';
import { CustomPropertyContext } from 'pages/create-runbook/views/create-runbook/CustomPropertyTypes.ts';

// Unit test is weirdly failing with ContextMode as being undefined even though
// it's just an imported enum. Adding this hack for now to get it to work.
const ContextMode = ContextModeEnum || {
    DIRECT_PARENT: "DIRECT_PARENT",
    CLOSEST_PARENT: "CLOSEST_PARENT",
    ANY_PARENT: "ANY_PARENT",
};

/** this constant specifies whether or not to show the user input option in the filters combo box. */
let ShowUserInputOption: boolean = false;
export function setShowUserInputOption(show: boolean, variant: Variant): void {
    ShowUserInputOption = show;
}

/** the value with the variable to specify that the filter value comes from the runbook trigger. */
export const TRIGGER_OPTION_VALUE       = "$trigger";
/** the value with the variable to specify that the filter value comes from the runbook trigger. */
export const SUBFLOW_INPUT_OPTION_VALUE = "$subflowInput";
/** the value with the variable to specify that the filter value comes from the runbook trigger. */
export const ON_DEMAND_INPUT_OPTION_VALUE = "$onDemandInput";
/** the value with the variable to specify that the filter value comes from a runtime, incident or global variable. */
export const VARIABLE_OPTION_VALUE      = "$variable";
/** the value with the variable to specify that the filter value comes from the runbook trigger.  This value is deprecated. */
export const TRIGGER_OPTION_VALUE_OLD   = "$runbook";
/** the value with the variable to specify that the filter value comes from the previous node. */
export const NODE_OPTION_VALUE_DP       = "$node";
/** the value with the variable to specify that the filter value comes from the closest parent node. */
export const NODE_OPTION_VALUE_CP       = "$closestAncestor";
/** the value with the variable to specify that the filter value comes from the any parent node. */
export const NODE_OPTION_VALUE_AP       = "$node";
/** the value with the variable to specify that the filter value comes from user input. */
export const USER_OPTION_VALUE          = "user-input";
/** the value with the variable to specify that the filter value comes from data source selection. */
export const DS_OPTION_VALUE            = "datasource";
/** the value with the variable to specify that the filter value comes from data source selection. */
export const ALL_DS_OPTION_VALUE        = "$all";

/** the local static filter metadata object map.  This contains the metadata for each key indexed by the key. */
export function getDataOceanFiltersMap(variant: Variant): any {
    return {
    "network_interface": getMetadataForFilter("network_interface", "networkInterface", variant),
    "connected_interface": getMetadataForFilter("connected_interface", "connectedInterface", variant),
    "application": getMetadataForFilter("application", "application", variant),
    "network_device": getMetadataForFilter("network_device", "networkDevice", variant),
    "connected_device": getMetadataForFilter("connected_device", "connectedDevice", variant),
    "network_host": getMetadataForFilter("network_host", "networkHost", variant),
    "network_client": getMetadataForFilter("network_client", "networkClient", variant),
    "network_server": getMetadataForFilter("network_server", "networkServer", variant),
    "location": getMetadataForFilter("location", "location", variant),
    "server_location": getMetadataForFilter("server_location", "serverLocation", variant),
    "client_location": getMetadataForFilter("client_location", "clientLocation", variant),
    "physical_location": getMetadataForFilter("physical_location", "physicalLocation", variant),
    "protocol": getMetadataForFilter("protocol", "protocol", variant),
    "protoport": getMetadataForFilter("protoport", "protoport", variant),
    "dscp": getMetadataForFilter("dscp", "dscp", variant),
    "user_device": getMetadataForFilter("user_device", "userDevice", variant),
    // This was part of cloudres and that was removed so commenting out
    //"expression": getMetadataForFilter("expression", "expression", variant),
    "data_source": getMetadataForFilter("data_source", "dataSource", variant),
    "keys": getMetadataForFilter("keys", "keys", variant),

    // Compound keys
    "network_client_server": getMetadataForFilter("network_client_server", "networkClientServer", variant),
    "client_server_location": getMetadataForFilter("client_server_location", "clientServerLocation", variant),
    "network_client_server_protoport": getMetadataForFilter("network_client_server_protoport", "networkClientServerProtoport", variant),

    // These are used in the tcp connections.  If those are ever used in runbooks this will need to be updated
    "app_name": getMetadataForFilter("app_name", "app_name", variant),
    "user_name": getMetadataForFilter("user_name", "user_name", variant),
    "process_id": getMetadataForFilter("process_id", "process_id", variant),
    "process_id_tcp": getMetadataForFilter("process_id", "process_id", variant),
    "process_name": getMetadataForFilter("process_name", "process_name", variant),
    "client_ip": getMetadataForFilter("client_ip", "client_ip", variant),
    "server_ip": getMetadataForFilter("server_ip", "server_ip", variant),
    "client_port": getMetadataForFilter("client_port", "client_port", variant),
    "server_port": getMetadataForFilter("server_port", "server_port", variant),
    "ip_protocol_type": getMetadataForFilter("ip_protocol_type", "ip_protocol_type", variant),
    "machine_id": getMetadataForFilter("machine_id", "machine_id", variant),
    "os_type": getMetadataForFilter("os_type", "os_type", variant),
    "os_version": getMetadataForFilter("os_version", "os_version", variant),
    "interface_name": getMetadataForFilter("interface_name", "interface_name", variant),
    };
};

/** this interface defines the filter metadata. */
export interface FilterMetadata {
    /** a string with the field type, for example: select, text, etc. */
    "fieldType": string;
    /** a string with the key, for example: networkInterface, networkDevice, etc. */
    "key": string;
    /** a string with the default value, for example: $trigger, $node, etc. */
    "defaultValue": string;
    /** for text fields, a string with the input placeholder that shows up until the user enters text. */
    "inputPlaceHolder"?: string;
    /** the FilterMetadataOptions object with the options for all the different types of filters in the select. */
    "options": FilterMetadataOptions;
}

/** this interface defines the metadata filter options. */
interface FilterMetadataOptions {
    /** the FilterMeatadataOptionsValue for the case where no filter is specified. */
    "optional"?: FilterMetadataOptionsValue;
    /** the FilterMeatadataOptionsValue for the case where the filter comes from the trigger. */
    "trigger"?: FilterMetadataOptionsValue;
    /** the FilterMeatadataOptionsValue for the case where the filter comes from the previous node. */
    "node"?: FilterMetadataOptionsValue;
    /** the FilterMeatadataOptionsValue for the case where the filter comes from user input. */
    "user"?: FilterMetadataOptionsValue;
}

/** this interface defines the values for the options. */
export interface FilterMetadataOptionsValue {
    /** the label that is displayed in the option. */
    "label": string;
    /** a string with the value of the option, for example: "$trigger", "$node.network_interface", "user-input", "", etc. */
    "value": string;
    /** a boolean value, true if the option is disabled, false otherwise. */
    "disabled": boolean;
}

/** This interface defines the properties passed into the data ocean filters React component.*/
export interface DataOceanFiltersProps {
    /** the current node type, can be either data_ocean or data_ocean_dynamic. */
    type: string;
    /** the metadata for the current object type. */
    selectedObjectType?: DataOceanObjectType;
    /** the keys object in the meta data API. */
    keys?: any;
    /** the currently selected node. */
    selectedNode?: UniversalNode;
    /** the current properties object with the value of all the controls in the editor. */
    currentProperties?: any;
    /** the parent node for this do node. */
    parentNode?: NodeDef;
    /** the runbook info object that has the runbook name, id, etc. */
    activeRunbook?:  RunbookInfo;
    /** the node library library node for this data ocean node. */
    libraryNode?: NodeLibraryNode;
    /** the GraphDef with the definition of the entire runbook. */
    graphDef: GraphDef;
    /** a callback function that should be called when the top by metric is set. */
    setTopByMetric: (id: string) => void;
    /** the handler for the duration change event. */
    onDurationChanged: () => void;
    /** the variant of runbook that is being edited. */
    variant?: Variant;
}

/** DataOceanFilters component to render required and optional filters
 *  @param props the properties passed into the react component.
 *  @returns JSX with the react data ocean filters component.*/
export function DataOceanFilters (props: DataOceanFiltersProps): JSX.Element {
    const { selectedObjectType, keys, selectedNode, currentProperties, parentNode, activeRunbook, setTopByMetric, graphDef, onDurationChanged } = props;
    const requiredFilters = selectedObjectType?.required_filters || [];
    const filters = selectedObjectType?.filters.filter(x => !requiredFilters.includes(x)) || [];
    const { uiAttrs } = props.libraryNode || {};
    const { primaryFilters } = uiAttrs || {};
    const nodeStrings = NodeLibrary.getNodeResourceStrings((selectedNode?.node as Node)?.data?.type || "", (selectedNode?.node as Node)?.data?.subType || "");
    const [limitRequired, setLimitRequired] = useState(false);
    const [addedSecondaryFilters, setAddedSecondaryFilters] = useState<Array<string>>([]);
    const metricsSectionEnabled = currentProperties['metrics'] !== undefined;

    const primaryFiltersSection:Array<{ label: string, filter: React.ReactNode }> = [];
    const secondaryFiltersSection:Array<{ label: string, filter: React.ReactNode }> = [];
    const moreAvailableFilters:Array<itemType> = [];

    const dataOcaeanFiltersMap = useState<any>(getDataOceanFiltersMap(props.variant || Variant.INCIDENT))[0];

    const {getVariables} = useContext(VariableContext);

    const customProperties = useContext(CustomPropertyContext);

    const checkIfLimitIsRequired = () => {
        let isLimitRequired = primaryFilters ? false : true;
        if (!isLimitRequired) {
            for (const filter of filters) {
                const isPrimaryFilter = primaryFilters?.includes(filter);
                if (isPrimaryFilter) {
                    const filterKey = dataOcaeanFiltersMap[filter]?.key || "-";
                    const filterValue = currentProperties["filters"][filterKey];
                    if (filterValue === undefined) {
                        // If any of the primary filters is missing the limit is required
                        isLimitRequired = true;
                    }
                }
            }
        }
        if (limitRequired !== isLimitRequired) {
            if (isLimitRequired === false) {
                delete currentProperties["limit"];
                delete currentProperties["topBy"];
            } else {
                const supportedMetrics: DataOceanMetric[] = selectedObjectType ? DataOceanUtils.getAvailableMetrics(
                    selectedObjectType.metrics, [], getTypes(), true, currentProperties["timeSeries"] ? QUERY_TYPE.time_series : QUERY_TYPE.summary
                ) : [];
                if (!currentProperties["limit"]) {
                    currentProperties["limit"] = 10;
                }
                if (!currentProperties["topBy"] && supportedMetrics?.length > 0) {
                    const defaultSort = DataOceanUtils?.dataOceanMetaData?.metrics[supportedMetrics[0].id]?.order_by_direction || "desc";
                    currentProperties["topBy"] = [{
                        id: supportedMetrics[0].id,
                        "direction": defaultSort
                    }];
                }
            }
            setTopByMetric(currentProperties["topBy"] ? currentProperties["topBy"][0].id : "");
            setLimitRequired(isLimitRequired);
        }
    }
    useEffect(() => {
        checkIfLimitIsRequired();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    for (const filter of requiredFilters) {
        primaryFiltersSection.push({
            label: keys[filter].label || filter,
            filter: renderFilter(
                { 
                    filterName: filter, keys, currentProperties, parentNode, activeRunbook, isRequired: true, selectedNode, graphDef, 
                    onChange: checkIfLimitIsRequired, variant: props.variant
                }, 
                dataOcaeanFiltersMap
            ),
        });
    }
    for (const filter of filters) {
        const primaryFilter = primaryFilters?.includes(filter);
        const filterKey = dataOcaeanFiltersMap[filter]?.key || "-";
        const fieldType = dataOcaeanFiltersMap[filter]?.fieldType;
        const filterValue = currentProperties["filters"][filterKey];

        const variables: VariableContextByScope = {
            runtime: getVariables(RUNTIME_SCOPE),
            incident: getVariables(INCIDENT_SCOPE),
            global: getVariables(GLOBAL_SCOPE)
        };

        let autoShowFilterDueToTriggerOrConnectedNode = false;
        if (!filterValue && fieldType && fieldType === "select") {
            const enabledOptions = getOptions(
                dataOcaeanFiltersMap[filter],
                parentNode,
                activeRunbook,
                true,
                filter,
                selectedNode?.getId() || "",
                graphDef,
                variables,
                customProperties,
                // Only consider exact matches when showing the default controls
                true
            ).filter(option => !option.disabled && hasTriggerOrConnectedNodeOrVariableOption(option.value));
            if (enabledOptions.length > 0) {
                autoShowFilterDueToTriggerOrConnectedNode = true;
            }
        }

        if (primaryFilter || filterValue || autoShowFilterDueToTriggerOrConnectedNode || addedSecondaryFilters.includes(filter)) {
            const targetSection = primaryFilter ? primaryFiltersSection : secondaryFiltersSection;
            targetSection.push({
                label: keys[filter].label || filter,
                filter: renderFilter(
                    { 
                        filterName: filter, keys, currentProperties, parentNode, activeRunbook, isRequired: false, selectedNode, 
                        graphDef, onChange: checkIfLimitIsRequired, variant: props.variant
                    }, 
                    dataOcaeanFiltersMap
                ),
            });
        } else {
            let hasOption = true;
            if (fieldType && fieldType === "select") {
                const allOptions = getOptions(
                    dataOcaeanFiltersMap[filter], parentNode, activeRunbook, true, filter, selectedNode?.getId() || "", graphDef, variables,
                    customProperties,
                    // Consider even in-exact matches when showing the default controls
                    false
                );
                hasOption = allOptions?.length > 0;
            }
            if (hasOption) {
                moreAvailableFilters.push({
                    label: keys[filter].label || filter,
                    value: filter,
                });    
            }
        }
    }

    const doHelpKey: string = props.type !== "data_ocean_dynamic" ? currentProperties.objType : "dynamic_traffic";

    return (
        <React.Fragment>
            <tr><td className="font-size-md-large fw-bold pt-2" colSpan={2}>
                <InlineHelp
                    helpMapping={HELP.RunbookNodeCategory.Dataquery[doHelpKey.replace('.', '_')]?.filters}
                >
                    {STRINGS.runbookEditor.nodeLibrary.nodes.data_ocean.labels.filtersSection}
                </InlineHelp>
            </td></tr>
            {
                primaryFiltersSection
                    .sort((a, b) => {
                        if(a.label < b.label) { return -1; }
                        if(a.label > b.label) { return 1; }
                        return 0;
                    })
                    .map(field => field.filter)
            }
            {
                secondaryFiltersSection
                    .sort((a, b) => {
                        if(a.label < b.label) { return -1; }
                        if(a.label > b.label) { return 1; }
                        return 0;
                    })
                    .map(field => field.filter)
            }
            {
                moreAvailableFilters.length > 0 && <tr><td colSpan={2}><Popover
                    usePortal={true}
                    popoverClassName="do-filter-popover"
                    position={Position.BOTTOM}
                    content={
                        <Menu>
                            {moreAvailableFilters.map(filter => <MenuItem
                                key={"available-filter-" + filter.value}
                                text={filter.label} defaultValue={filter.value}
                                onClick={() => {
                                    setAddedSecondaryFilters([...addedSecondaryFilters, String(filter.value)]);
                                }}
                            />)}
                        </Menu>
                    }
                >
                    <Button
                        role="button"
                        icon={IconNames.PLUS}
                        text={(primaryFiltersSection.length + secondaryFiltersSection.length > 0) ? "Add More Filters" : "Add A Filter"}
                        small
                        minimal
                    />
                </Popover></td></tr>
            }
            {
                selectedObjectType && limitRequired &&
                <DataOceanLimit
                    type={props.type}
                    currentProperties={ currentProperties }
                    selectedObjectType={selectedObjectType}
                    entityLabel={nodeStrings.output}
                    hideDurationControl={metricsSectionEnabled}
                    setTopByMetric={setTopByMetric}
                    onDurationChanged={onDurationChanged}
                />
            }
        </React.Fragment>
    );
}

/** renders one filter UI elements
 *  @param filterName the name of the filter to be rendered.  For example: network_server, network_device, etc.
 *  @param keys the keys object from the data ocean API metadata.  This is the generic keys object for all the object types.
 *  @param currentProperties the dictionary with all the properties currently being edited and their values. 
 *  @param parentNode the NodeDef closest parent DO node, if any.
 *  @param activeRunbook the RunbookInfo object with the definition of the currently edited runbook. 
 *  @param selectedNode the UniversalNode with the currently selected DO node.
 *  @param graphDef the GraphDef object with all the nodes and edges in the graph.
 *  @param isRequired a boolean value, true if the filter is a required filter.
 *  @param onChange the handler for property change events.
 *  @param variant the runbook Variant that is being edited.
 *  @returns the DataOceanFilter element that was created or null if it could not be created. */
const renderFilter = (
    { filterName, keys, currentProperties, parentNode, activeRunbook, selectedNode, graphDef, isRequired, onChange, variant }:
    { 
         filterName: string, keys: any, currentProperties: Record<string, any>, parentNode?: NodeDef, activeRunbook?: RunbookInfo, 
         selectedNode?: UniversalNode, graphDef: GraphDef, isRequired: boolean, onChange: (value) => void,
         variant?: Variant
    }, 
    dataOcaeanFiltersMap: any
): JSX.Element | null => {
    const filterProps = dataOcaeanFiltersMap[filterName];
    if (!filterProps) {
        return null;
    }
    return (
        <DataOceanFilter
            key={ "filters_" + filterProps.key }
            currentProperties={ currentProperties }
            label={ keys[filterName].label }
            filterProps={ filterProps }
            filterName={ filterName }
            parentNode={ parentNode }
            activeRunbook={ activeRunbook }
            doNodeId={ selectedNode?.getId() || "" }
            graphDef={ graphDef }
            isRequired={ isRequired }
            onChange={ onChange }
            variant={variant}
        />
    );
};

/** returns the metadata for the specified filter.
 *  @param filterName the name of the filter to be rendered.  For example: network_server, network_device, etc.
 *  @param key the key for the filter.  For example: networkServer, networkDevice, etc.
 *  @param variant the runbook Variant that is being edited.
 *  @returns the FilterMetadata object that describes the filter. */
function getMetadataForFilter(filterName: string, key: string, variant: Variant): FilterMetadata | undefined {
    // TBD: Seems like app logic is dependent on entries in en.ts strings file. This probably should be moved somewhere into node library
    const filterDisplayStrings = STRINGS.runbookEditor.nodeLibrary.nodes.data_ocean.filters[filterName];
    if (filterDisplayStrings) {
        let metadata: FilterMetadata = {
            fieldType: filterDisplayStrings.fieldType,
            key: key,
            defaultValue: "",
            options: {}
        };

        if (filterDisplayStrings.fieldType === "select") {
            metadata["defaultValue"] = TRIGGER_OPTION_VALUE;
            metadata["options"] = {};
            metadata["options"]["optional"] = {
                label: STRINGS.runbookEditor.nodeLibrary.propertyLabels.optionalLabel,
                value: "",
                disabled: false,
            };
            if (filterDisplayStrings.triggerOption) {
                metadata["options"]["trigger"] = {
                    label: filterDisplayStrings[
                        variant !== Variant.SUBFLOW && variant !== Variant.ON_DEMAND 
                            ? "triggerOption" 
                            : variant === Variant.SUBFLOW ? "subflowInputOption" : "onDemandInputOption"
                    ],
                    value: (variant !== Variant.SUBFLOW  && variant !== Variant.ON_DEMAND 
                        ? TRIGGER_OPTION_VALUE 
                        : variant === Variant.SUBFLOW ? SUBFLOW_INPUT_OPTION_VALUE + `.${ filterName }` : ON_DEMAND_INPUT_OPTION_VALUE + `.${ filterName }`
                    ),
                    disabled: !filterDisplayStrings.hasOwnProperty(
                        variant !== Variant.SUBFLOW && variant !== Variant.ON_DEMAND 
                            ? "triggerOption" 
                            : variant === Variant.SUBFLOW ? "subflowInputOption" : "onDemandInputOption"
                    ),
                };
            }
            if (filterDisplayStrings.connectedNode) {
                let label = "";
                let value = "";
                switch (CONTEXT_MODE) {
                    case ContextMode.DIRECT_PARENT:
                        label = filterDisplayStrings.connectedNode;
                        value = `${ NODE_OPTION_VALUE_DP }.${ filterName }`;
                        break;
                    case ContextMode.CLOSEST_PARENT:
                        label = filterDisplayStrings.connectedNodeClosestParent;
                        value = `${ NODE_OPTION_VALUE_CP }.${ filterName }`;
                        break;
                    case ContextMode.ANY_PARENT:
                        label = filterDisplayStrings.connectedNodeAnyNode;
                        value = `${ NODE_OPTION_VALUE_AP }:{0}.${ filterName }`;
                        break;
                }
                metadata["options"]["node"] = {
                    label: label,
                    value: value,
                    disabled: !filterDisplayStrings.hasOwnProperty("connectedNode"),
                };
            }
            if (filterDisplayStrings.variableOption) {
                metadata["options"]["variable"] = {
                    label: filterDisplayStrings.variableOption,
                    value: `${ VARIABLE_OPTION_VALUE }:{0}`,
                    disabled: !filterDisplayStrings.hasOwnProperty("variableOption"),
                };
            }
            if (ShowUserInputOption && filterDisplayStrings.userInput) {
                metadata["options"]["user"] = {
                    label: filterDisplayStrings.userInput,
                    value: USER_OPTION_VALUE,
                    disabled: !filterDisplayStrings.hasOwnProperty("userInput"),
                };
                metadata["inputPlaceHolder"] =
                    STRINGS.runbookEditor.nodeLibrary.nodes.data_ocean.filters[filterName].userInputPlaceHolder || "";
            }
            if (filterDisplayStrings.dataSourceSelect) {
                metadata["options"]["datasource"] = {
                    label: filterDisplayStrings.dataSourceSelect,
                    value: DS_OPTION_VALUE,
                    disabled: !filterDisplayStrings.hasOwnProperty("dataSourceSelect"),
                };
                metadata["inputPlaceHolder"] =
                    STRINGS.runbookEditor.nodeLibrary.nodes.data_ocean.filters[filterName].dataSourceSelectPlaceHolder || "";
            }
            if (filterDisplayStrings.allDataSource) {
                metadata["options"]["alldatasource"] = {
                    label: filterDisplayStrings.allDataSource,
                    value: ALL_DS_OPTION_VALUE,
                    disabled: !filterDisplayStrings.hasOwnProperty("allDataSource"),
                };
            }
        } else {
            metadata["inputPlaceHolder"] =
                STRINGS.runbookEditor.nodeLibrary.nodes.data_ocean.filters[filterName].userInputPlaceHolder || "";
        }
        return metadata;
    }
    return undefined;
}

/** returns true if the option value is a value consistent with a trigger or connected node that provides context. 
 *  @param value a String wit the value of the option.
 *  @returns a boolean which is true if the value is consistent with a trigger or connected node that provides context. */
export function hasTriggerOrConnectedNodeOrVariableOption(value: string): boolean {
    return value !== null && value !== undefined && (
        value === TRIGGER_OPTION_VALUE || value.startsWith(NODE_OPTION_VALUE_DP) || 
        value.startsWith(NODE_OPTION_VALUE_CP) || value.startsWith(NODE_OPTION_VALUE_AP) ||
        value.startsWith(VARIABLE_OPTION_VALUE)
    );
}
