/** This module contains the component for the graph definitions view
 *  @module
 */

import { useEffect, useRef, useState } from 'react';
import { Tab, TabbedSubPages } from 'components/common/layout/tabbed-sub-pages/TabbedSubPages';
import { STRINGS } from 'app-strings';
import RuntimeVariablesView from './RuntimeVariablesView';
import IncidentVariablesView, { VariablesOperationType } from './IncidentVariablesView';
import { Button, Intent } from '@blueprintjs/core';
import { BasicDialog, DialogState, updateDialogState } from 'components/common/basic-dialog/BasicDialog';
import { Icon, IconNames } from '@tir-ui/react-components';
import { InputType, Variant, VARIANTS_WITH_INCIDENT_VARS } from '../types/GraphTypes';
import './VariableDefinitionView.scss';

/** this constant has the base URI for the help. */
const ENV = import.meta.env.VITE_ENV_SHORT;

/** this constant contains the list of tenants where the incident vars should be displayed. */
const INCIDENT_VAR_ENVS: string[] = ["dev", "staging", "prod"];
/** this constant contains the list of tenants where the global vars should be displayed. */
const GLOBAL_VAR_ENVS: string[] = ["dev"];

type VariableDefinitionsProps = {
    onRuntimeOrSubflowVariableEdited?: (updatedVariablesList) => void,
    onIncidentVariableEdited?: (updatedVariablesList) => void,
    onVariableChanges?: (valueChanged) => void,
    onClose: () => void,
    runbookTriggerType?: string,
    isVariablePopoverOpen?: boolean,
    variant?: Variant
}

export default function VariableDefinitionView({ onRuntimeOrSubflowVariableEdited, onIncidentVariableEdited, onVariableChanges, onClose, runbookTriggerType, isVariablePopoverOpen, variant }: VariableDefinitionsProps) {
    const variablesContainer = useRef(null);
    const [triggerRuntimeVariablesSave, setTriggerRuntimeVariablesSave] = useState(0);
    const [triggerIncidentVariablesSave, setTriggerIncidentVariablesSave] = useState(0);
    const [triggerIncidentVariablesValidation, setTriggerIncidentVariablesValidation] = useState(0);
    const [saveDisabled, setSaveDisabled] = useState(true);
    const [incidentOperations, setIncidentOperations] = useState([]);
    const [runtimeOperations, setRuntimeOperations] = useState([]);
    const [showDialogForIncidentVars, setShowDialogForIncidentVars] = useState<{nrOfRunbooksThatWillFail: number, nrOfAutomationRulesThatWillFail: number} | null>(null);

    const [valueChanged, setValueChanged] = useState(false);

    let initDialogState: DialogState = { showDialog: false, title: "My Dialog", loading: false, dialogContent: null, dialogFooter: null };
    // A reference to the dialog state so we can check it during async dialog operations
    const dialogStateRef = useRef<DialogState>(initDialogState);
    // The current dialog state
    const [dialogState, setDialogStateUseState] = useState<DialogState>(initDialogState);
    // A wrapper around the useState set state function so we can record the dialogStateRef with the latest state.
    function setDialogState(dState: DialogState): void {
        dialogStateRef.current = dState;
        setDialogStateUseState(dState);
    };

    useEffect(() => {
        if (showDialogForIncidentVars && incidentOperations) {
            setDialogState({
                showDialog: true,
                title: STRINGS.runbookEditor.variableDefinitions.incidentVariablesDialog.incidentVariablesChangesTitle,
                dialogContent: <>
                    {STRINGS.runbookEditor.variableDefinitions.incidentVariablesDialog.incidentVarsWarning1}
                    {incidentOperations.map(item => {
                        if (item === VariablesOperationType.CHANGE_VARIABLE) {
                            return STRINGS.runbookEditor.variableDefinitions.incidentVariablesDialog.incidentVarsWarning2;
                        } else if (item === VariablesOperationType.DELETE_VARIABLE) {
                            return STRINGS.runbookEditor.variableDefinitions.incidentVariablesDialog.incidentVarsWarning3
                                + (showDialogForIncidentVars.nrOfRunbooksThatWillFail > 0
                                ? (' There ' + (showDialogForIncidentVars.nrOfRunbooksThatWillFail === 1 ? 'is ' : 'are ')
                                    + showDialogForIncidentVars.nrOfRunbooksThatWillFail + ' runbook'
                                    + (showDialogForIncidentVars.nrOfRunbooksThatWillFail === 1 ? '' : 's')
                                    + ' that use' +  (showDialogForIncidentVars.nrOfRunbooksThatWillFail === 1 ? 's' : '')
                                    + ' the deleted incident variables.')
                                : '')
                                + (showDialogForIncidentVars.nrOfAutomationRulesThatWillFail > 0
                                ? (' There ' + (showDialogForIncidentVars.nrOfAutomationRulesThatWillFail === 1 ? 'is ' : 'are ')
                                    + showDialogForIncidentVars.nrOfAutomationRulesThatWillFail + ' automation mapping'
                                    + (showDialogForIncidentVars.nrOfAutomationRulesThatWillFail === 1 ? '' : 's')
                                    + ' that use' +  (showDialogForIncidentVars.nrOfAutomationRulesThatWillFail === 1 ? 's' : '')
                                    + ' the deleted incident variables.')
                                : '');
                            }
                        return null;
                    })}
                    {STRINGS.runbookEditor.variableDefinitions.incidentVariablesDialog.incidentVarsWarning4}
                </>,
                closeable: false,
                dialogFooter: <div className="d-flex justify-content-between flex-grow-1">
                    <Button
                        className="ms-0"
                        text={STRINGS.runbookEditor.variableDefinitions.incidentVariablesDialog.discardAndCloseBtn}
                        intent={Intent.DANGER}
                        onClick={() => {
                            setDialogState({ ...dialogState, showDialog: false, closeable: true });
                            onClose();
                        }}
                    />
                    <Button
                        icon={IconNames.SAVED}
                        text={STRINGS.runbookEditor.variableDefinitions.incidentVariablesDialog.saveAndCloseBtn}
                        intent={Intent.SUCCESS}
                        onClick={() => {
                            if (runtimeOperations.length) {
                                setTriggerRuntimeVariablesSave(triggerRuntimeVariablesSave+1);
                            }
                            setTriggerIncidentVariablesSave(triggerIncidentVariablesSave+1);
                            setDialogState({ ...dialogState, showDialog: false, closeable: true });
                        }}
                    />
                </div>,
            } as DialogState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDialogForIncidentVars]);

    function saveVariablesEditor() {
        if (incidentOperations.length) {
            setTriggerIncidentVariablesValidation(triggerIncidentVariablesValidation+1);
        } else if (runtimeOperations.length) {
            setTriggerRuntimeVariablesSave(triggerRuntimeVariablesSave+1);
        }
    }

    return <>
        <BasicDialog className="save-variables-dialog" dialogState={dialogState} onClose={() => setDialogState(updateDialogState(dialogState, false, false, []))} />
        <div className='variables-container position-relative' ref={variablesContainer} onChange={() => {
                    if (!valueChanged) {
                        setValueChanged(true);
                        if (onVariableChanges) {
                            onVariableChanges(true);
                        }
                    }
                }}>
            {/* Close Dialog Button */}
            <Button 
                className="position-absolute" 
                style={{top: "8px", right: "8px", zIndex: 100}}
                minimal={true} 
                intent={Intent.NONE}
                icon={<Icon icon={IconNames.SMALL_CROSS}/>} 
                aria-label="Close Dialog"
                onClick={() => {
                    onClose();
                }}
            />
            <TabbedSubPages renderActiveTabPanelOnly={false} className='position-relative'>
                <Tab id="runtime" className='d-flex flex-column p-2' title={STRINGS.runbookEditor.variableDefinitions[variant === Variant.SUBFLOW ? "subflowScope" : variant === Variant.ON_DEMAND ? "onDemandScope" : "runtimeScope"].label}>
                    <RuntimeVariablesView
                        onClose={onClose}
                        triggerSave={triggerRuntimeVariablesSave}
                        setSaveDisabled={setSaveDisabled}
                        containerRef={variablesContainer}
                        onRuntimeOrSubflowVariableEdited={(event) => {
                            if (onRuntimeOrSubflowVariableEdited) {
                                onRuntimeOrSubflowVariableEdited(event)
                            }
                        }}
                        runbookTriggerType={runbookTriggerType}
                        isVariablePopoverOpen={isVariablePopoverOpen}
                        setRuntimeOperations={setRuntimeOperations}
                        variant={variant}
                    />
                </Tab>
                {INCIDENT_VAR_ENVS.includes(ENV) && runbookTriggerType !== InputType.WEBHOOK && VARIANTS_WITH_INCIDENT_VARS.includes(variant!) && <Tab id="incident" className='d-flex flex-column p-2' title={STRINGS.runbookEditor.variableDefinitions.incidentScope.label}>
                    <IncidentVariablesView
                        onClose={onClose}
                        setSaveDisabled={setSaveDisabled}
                        setIncidentOperations={setIncidentOperations}
                        setShowDialogForIncidentVars={setShowDialogForIncidentVars}
                        triggerSave={triggerIncidentVariablesSave}
                        triggerValidation={triggerIncidentVariablesValidation}
                        onIncidentVariableEdited={(event) => {
                            if (onIncidentVariableEdited) {
                                onIncidentVariableEdited(event)
                            }
                        }}
                        containerRef={variablesContainer} />
                </Tab>}
                {GLOBAL_VAR_ENVS.includes(ENV) && <Tab id="global" className='d-flex flex-column p-2' title={STRINGS.runbookEditor.variableDefinitions.globalScope.label}>
                    <VariablesTableHeader />
                    Global
                </Tab>}
            </TabbedSubPages>
            <div className="save-btn me-4 mb-2 d-flex flex-row align-items-center justify-content-end" style={{gap: "8px"}}> 
                <Button
                    className='d-flex flex-column align-self-end'
                    onClick={() => {
                        onClose();
                    }}
                    intent={Intent.NONE}>
                    {STRINGS.runbookEditor.variableDefinitions.cancel}
                </Button>
                <Button
                    className='d-flex flex-column align-self-end'
                    onClick={() => {
                        saveVariablesEditor();
                    }}
                    disabled={saveDisabled}
                    intent={Intent.PRIMARY}>
                    {STRINGS.runbookEditor.variableDefinitions.save}
                </Button>
            </div>
        </div>
    </>
}

export function VariablesTableHeader() {
    return (
        <div className='pb-1 variables-grid-header'>
            <span>{/* Grid padding */}</span>
            <span>{STRINGS.runbookEditor.variableDefinitions.variableTable.name}</span>
            <span>{STRINGS.runbookEditor.variableDefinitions.variableTable.type}</span>
            <span>{STRINGS.runbookEditor.variableDefinitions.variableTable.unit}</span>
            <span>{STRINGS.runbookEditor.variableDefinitions.variableTable.initValue}</span>
        </div>
    )
}
