/** This module defines a React component that displays the Health information defined
 *  by a severity object.
 *  @module
 */
import { STRINGS } from "app-strings";
import { Severity } from "types/types";

/** this interface defines the properties passed into the health info component. */
export interface HealthInfoProps {
    /** the Severity object that has the health information in it. */
    severity: Severity;
}

/** Creates the health information component.  Displays a severity in the search result.
 *  @param props an object with the properties passed to the CategorySelector Component.
 *  @returns JSX with the HealthInfo component.*/
export function HealthInfo({ severity }: HealthInfoProps) {
    return <>{
        (severity && severity.score !== null) &&
        <div className="ms-1 d-flex flex-column  justify-content-center">
            <div className="h3 align-self-center">
                {severity?.score?.toFixed(0)}
            </div>
            <div className="h7 align-self-center">
                {STRINGS.SEARCH.healthScore}
            </div>
        </div>
    }</>
}
