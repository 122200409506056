import Markdown from 'markdown-to-jsx';
import { Button, Icon, IconName } from '@blueprintjs/core';
import { SDWAN_ICONS } from 'components/sdwan/enums/icons.ts';
import { IconNames } from '@tir-ui/react-components';
import { BULLET_COLORS } from 'components/enums/Colors.ts';
import { NoteType } from 'components/enums/NotesActivity.ts';
import { PARAM_NAME } from 'components/enums/QueryParams.ts';
import { Mode } from 'components/modals/AddNotesModal.tsx';
import { computeWidgetContainerId, scrollToElement } from 'reporting-infrastructure/utils/commonUtils.ts';
import { TimeFormatter } from 'reporting-infrastructure/utils/formatters/time-formatter/TimeFormatter.tsx';
import { setQueryParams } from 'utils/hooks/useQueryParams.ts';
import { openModal } from '../modal/ModalRenderer.tsx';
import { Variant } from '../graph/types/GraphTypes.ts';
import { STRINGS } from 'app-strings';
import DOMPurify from 'dompurify';
import './FormattedNote.scss'

export interface FormattedNoteProps {
    /** a string with the unique id of the incident.*/
    incidentId?: string;
    //Unique identifier for a note
    noteId: string,
    // title string that will be in bold
    title: string,
    // Time in JavaScript Date format
    timestamp: Date | undefined,
    // description that is diplayed as a paragraph. Formatting has to be done by the consumer
    description?: JSX.Element | string,
    // this identifies a specific runbook execution. Primarly used for sharing link to a specific runbook
    runbookId?: string;
    // identifies the specific widget in the runbook execution result
    widgetId?: string;
    //
    noteType?: NoteType;
    //Callback when a note is deleted
    onDelete?: (noteId: string)=>void;
    // show/hide edit controls. default - false
    showControlTools?: boolean;
    // Call Back onEdit
    onEdit?: ()=> void;
    // A flag to indicate that this is the first note in the list of notes
    firstNote?: boolean;
    // user type
    userType?: Variant | undefined
};

// TODO get rid of this
enum NODE_CONTROLS_ACTIONS {
    edit = "edit",
    duplicate = "duplicate",
    delete = "delete",
}

/**
 * A data formatter component which displayed the provided data as a node with a title timestamp and descriptions
 * @param props Refer to DataNodePorps for details on member attributes.
 * @return  JSX component
 */
export function FormattedNote({
    title,
    timestamp,
    incidentId,
    description,
    runbookId,
    widgetId,
    noteType,
    noteId,
    onDelete,
    showControlTools = false,
    onEdit,
    firstNote = false,
    userType=Variant.INCIDENT
}: FormattedNoteProps) {
    const noop = "#";
    return <>
        <div className={"ps-4 d-flex flex-column formatted-note" + (firstNote ? "" : " show-border")}>
        <div className="d-flex row-padding">
            <div className="d-flex align-items-center title">
                {userType === Variant.LIFECYCLE.toUpperCase()
                    ? <Icon icon={SDWAN_ICONS.RUNBOOK} className="" size={14} color={BULLET_COLORS.DEFAULT} />
                    : <Icon icon="symbol-circle" className="" size={14} color={BULLET_COLORS.DEFAULT} />}
                <span className="ps-2 fw-bold">
                    {title}
                </span>
                <span className="ps-2 time-stamp display-9" aria-label='timestamp'>
                    <TimeFormatter time={timestamp} />
                </span>
            </div>
            {(showControlTools && onDelete) &&
                    <div className="note-controls w-1-2">
                        <Button data-testid={"FormattedNote-button-edit"} minimal icon={IconNames.EDIT} data-action={NODE_CONTROLS_ACTIONS.edit} onClick={e => {
                            openModal('addNotesModal',
                            {
                                noteId: noteId,
                                incidentId: incidentId,
                                content: description,
                                mode: Mode.EDIT,
                                onSuccess: () => {
                                    if (onEdit) {
                                        onEdit();
                                    }
                                },
                            });
                        }} />
                        <Button data-testid={"FormattedNote-button-delete"} minimal icon={IconNames.TRASH} data-action={NODE_CONTROLS_ACTIONS.delete} onClick={() => {
                            onDelete(noteId);
                        }} />
                    </div>
            }
            </div>
            <div className="ms-2 ps-3 desc flow-wrap" >
                <div className="mt-1">
                    <i><Markdown>{DOMPurify.sanitize(description?.toString() || "")}</Markdown></i>
                </div>
                {noteType === NoteType.PINNED &&
                    <div className="mt-2">
                        <Icon icon="pin" className="" size={14} color={BULLET_COLORS.DEFAULT} />
                        <a href={noop} className="ps-2" aria-label="scroll to pinned widget" onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            const contId = computeWidgetContainerId(runbookId, widgetId);
                            // console.log(`Goto Pinned: incidentid: \nrunbookid: ${runbookId}\ncharId: ${widgetId}`)
                            // If the target element is already visible then scroll to that element
                            scrollToElement(contId);
                            setQueryParams({ [PARAM_NAME.pageLocationId]: contId, [PARAM_NAME.runbookId]: runbookId }, true);
                        }}>
                            {STRINGS.addNotes.viewPinnedContent}
                        </a>
                    </div>
                }
            </div>
        </div>
    </>
}
