query EdgeConfig($startTime: String, $endTime: String) {
	edges(filter: { time: { startTime: $startTime, endTime: $endTime } }) {
		id
		name
		type
		connected
		ignoreInvalidCertificates
		dataSources {
			id
			name
			hostname
			type
			uri
			lastIngestedEndTime
			metricStreamingEnabled
			metricsSchemaVersion
			queriesEnabled
			productVersion
			protocol
			port
			metricStreaming {
				networkDevice
				networkInterface
				applicationLocation
			}
			info {
				status
				error {
					code
					message
					innerError {
						id
						trace
						properties {
							key
							value
						}
					}
					details {
						code
                        innerError {
                            id
                            trace
                            properties {
                                key
                                value
                            }
                        }
					}
				}
			}
			iot {
				deviceId
				hostname
				hubHostname
			}
			account {
				name
				id
			}
			authentication {
				username
			}
		}
	}
}
