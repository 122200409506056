/** This module defines a React component that displays the application search result.
 *  @module
 */
import type { SearchResultsItemProps } from "components/common/search/search-items/search-results-item/SearchResultsItem.tsx";
import { SearchItemHeader } from "components/common/search/search-item-header/SearchItemHeader.tsx";
import { type LinkInfo, LinksGroup, LinkType } from "components/common/search/search-links-group/LinksGroup.tsx";
import { SearchItemDesc } from "components/common/search/search-item-desc/SearchItemDesc.tsx";
import { STRINGS } from "app-strings";
import { useUserPreferences } from "utils/hooks/useUserPreferences.ts";
import { HIDDEN_STATES, INCIDENT_STATUS } from "components/enums/IncidentStatus.ts";
import { PRIORITY } from "components/enums/Priority.ts";
import { DEFAULT_SEARCH_PREF, type SearchPreference } from "utils/services/UserPrefsTypes.ts";
import { 
    getApplicationFacetForEngine, getFacetValue, getPriorityFacetForEngine, getStatusFacetForEngine 
} from "pages/incident-search/IncidentSearchUtils.ts";
import { FACET_FIELDS } from "utils/services/SearchApiService.ts";

/** Renders the search result for an application.
 *  @param data the properties passed in.
 *  @returns JSX with the application search result react component.*/
export function SearchApplicationItem({ data }: SearchResultsItemProps) {
    const userPreferences = useUserPreferences({
        listenOnlyTo: {search: {}}
    });
    const searchPreferences: SearchPreference = {...DEFAULT_SEARCH_PREF, ...userPreferences.search};

    const links: Array<LinkInfo> = [
        {
            path: "incident-search",
            label: STRINGS.SEARCH.viewIncidents,
            type: LinkType.PAGE,
            params: {
                    searchType: "incident",
                    facets: {
                        [getApplicationFacetForEngine(searchPreferences.srchEngine)]: [data.formattedData.name],
                        [getPriorityFacetForEngine(searchPreferences.srchEngine)]: [
                            getFacetValue(PRIORITY.CRITICAL, searchPreferences.srchEngine), getFacetValue(PRIORITY.HIGH, searchPreferences.srchEngine), 
                            getFacetValue(PRIORITY.MODERATE, searchPreferences.srchEngine), getFacetValue(PRIORITY.LOW, searchPreferences.srchEngine)
                        ],
                        [getStatusFacetForEngine(searchPreferences.srchEngine)]: [
                            INCIDENT_STATUS.NEW, INCIDENT_STATUS.INVESTIGATING, INCIDENT_STATUS.ON_HOLD, INCIDENT_STATUS.DISMISSED, INCIDENT_STATUS.CLOSED
                        ].filter(status => !HIDDEN_STATES.includes(status)).map(state => getFacetValue(state, searchPreferences.srchEngine))
                    }
                }
/*
        },
        {
            path: "on-demand-runbooks",
            label: STRINGS.SEARCH.runOnDemand,
            type: LinkType.PAGE,
            params: {
                [PARAM_NAME.rbConfigId]: data.id,
                [PARAM_NAME.rbConfigNm]: data?.formattedData?.name
            }
*/
        },
        {
            path: "incident-search",
            label: STRINGS.SEARCH.details,
            type: LinkType.PAGE,
            params: {
                searchType: "application",
                facets: {
                    [FACET_FIELDS.NAME]: [data.formattedData.title]
                },
                inspectorBladeOpen: true,
            }
        }
    ];
    return <div className="d-flex flex-column flex-grow-2">
        <SearchItemHeader type={data.type} title={data.formattedData.title ? data.formattedData.title : ""} />
        <SearchItemDesc>
            {data.application.name}
            {data.application?.location?.name ? " " +  STRINGS.SEARCH.locationText + " " + data.application.location.name : ""}
            {data.application?.reportedBy?.length ? " " +  STRINGS.SEARCH.seenOnText + " " + data.application.reportedBy.join(", ") : ""}
        </SearchItemDesc>
        <LinksGroup links={links} />
    </div>
}
