import { useCallback, useState } from 'react';
import { FILTER_TYPE_STRING, filterConfigToken, StaticFilterableTable, TableColumnDef } from '@tir-ui/react-components';
import { AuthServiceProvider } from "utils/providers/AuthServiceProvider";
import { TenantManagementService } from 'utils/services/TenantManagementService';
import { EditDeleteColumn } from './EditDeleteTableColumn';
import { extractRoleParams, MAPPED_ROLES } from './UserAccountManagement';
import { STRINGS } from 'app-strings';

/** this constant refers to the auth service where you can get the tenant, user, etc. */
const AuthService = AuthServiceProvider.getService();

const fetchUsers = (params, productKey, lastUpdated) => {
    const tenantId = AuthService.getTenantId();
    return TenantManagementService.get(
        `common/tenant/${tenantId}/users?product=${productKey}`
    );
};

let _filterConfig: filterConfigToken[] = [
    {
        key: 'displayName',
        display: STRINGS.USER_ACCOUNT_MANAGEMENT.COLUMN_LABEL.name,
        filterType: FILTER_TYPE_STRING,
        unique: true,
    },
    {
        key: 'mail',
        display: STRINGS.USER_ACCOUNT_MANAGEMENT.COLUMN_LABEL.email,
        filterType: FILTER_TYPE_STRING,
        unique: true,
    },
    {
        key: 'role.value',
        display: STRINGS.USER_ACCOUNT_MANAGEMENT.COLUMN_LABEL.role,
        unique: true,
        values: [],
    },
];

export const UserListView = (props) => {
    const { productKey, productPlatformRoleData, lastUpdated } = props;
    const [params, setParams] = useState(null);
    const productRoleInfo = productPlatformRoleData.products[productKey];

    const renderEditDeleteColumn = (cell) => {
        const rowId = cell.row.id;
        const userData = cell.data;

        //Dont show the edit/delete buttons for the logged in users record
        if (AuthService.getUserAccount().getUsername() === userData[rowId].mail) {
            return null;
        }
        return (
            <EditDeleteColumn
                cell={cell}
                setParams={setParams}
                rolesByProduct={{ [productKey]: productRoleInfo }}
                productKey={productKey}
                platformRoles={
                    productPlatformRoleData.platform
                        ? productPlatformRoleData.platform.roles
                        : []
                }
            />
        );
    };

    const columns: TableColumnDef[] = [
        {
            id: 'displayName',
            Header: STRINGS.USER_ACCOUNT_MANAGEMENT.COLUMN_LABEL.name,
            accessor: 'displayName',
            sortable: true,
        },
        {
            id: 'mail',
            Header: STRINGS.USER_ACCOUNT_MANAGEMENT.COLUMN_LABEL.email,
            accessor: 'mail',
            sortable: true,
        },
        {
            id: 'role.value',
            Header: STRINGS.USER_ACCOUNT_MANAGEMENT.COLUMN_LABEL.role,
            accessor: 'role.value',
            formatter: record => MAPPED_ROLES[record.role.value],
            sortable: true,
        },
        //TODO: We should have a common way  to create a column with button by providing an onclick function.
        {
            id: 'id',
            Header: '',
            accessor: 'id',
            Cell: renderEditDeleteColumn,
            className: 'action-buttons-column',
        },
    ];

    const fetchFn = useCallback(() => {
        //our function does not really need params,but it is to trigger refresh the easiest way possible
        return fetchUsers(params, productKey, lastUpdated);
    }, [params, productKey, lastUpdated]);

    _filterConfig[2].values = [];

    productRoleInfo.roles.forEach((role) => {
        //@ts-ignore
        _filterConfig[2].values.push(extractRoleParams(role, 'filter'));
    });

    return (
        <StaticFilterableTable
            key={productRoleInfo.id + '_table'}
            columns={columns}
            filterConfig={_filterConfig}
            fetchFn={fetchFn}
            pageSizeOptions={[10, 20, 30]}
        />
    );
};
