/** This module contains the DataOceanService that can be used to query the data ocean API
 *  @module
 */
import { FILTER_TYPE, FilterEntry } from 'pages/navigator/Navigator.type';
import { ApiService } from 'utils/services/ApiService';

// The URL for the API server.
export const DATA_OCEAN_BASE_URL = '/api/affogato/';

// The list of dynamic object types.  We could get this from the DO metadata rather than hard coding it
const DYNAMIC_OBJ_TYPES = [
    "npm_plus.traffic", "aternity.traffic", "aternity2.traffic", 
    "aternity_app_raw.traffic", "aternity_app_hourly.traffic", "aternity_app_daily.traffic", 
    "aternity_host_resource_hourly.traffic", "aternity_wifi_stats_hourly.traffic", "aternity_device_health_events_hourly.traffic"
];

const ONE_LEVEL_KEYS: FILTER_TYPE[] = [
    FILTER_TYPE.process_name, FILTER_TYPE.browser, FILTER_TYPE.application_identifier, FILTER_TYPE.calendar_date, 
    FILTER_TYPE.calendar_month, FILTER_TYPE.calendar_week, FILTER_TYPE.client_device_name, FILTER_TYPE.client_device_type, 
    FILTER_TYPE.corp_channel, FILTER_TYPE.corp_line_of_business, FILTER_TYPE.corp_market, FILTER_TYPE.corp_store_id, 
    FILTER_TYPE.corp_store_type, FILTER_TYPE.custom_attribute_1, FILTER_TYPE.custom_attribute_2, FILTER_TYPE.custom_attribute_3, 
    FILTER_TYPE.custom_attribute_4, FILTER_TYPE.custom_attribute_5, FILTER_TYPE.custom_attribute_6, FILTER_TYPE.custom_attribute_7, 
    FILTER_TYPE.custom_attribute_8, FILTER_TYPE.custom_attribute_9, FILTER_TYPE.custom_pilot_group, 
    FILTER_TYPE.custom_user_attribute_1, FILTER_TYPE.custom_user_attribute_2, FILTER_TYPE.custom_user_attribute_3, 
    FILTER_TYPE.custom_user_attribute_4, FILTER_TYPE.custom_user_attribute_5, FILTER_TYPE.custom_user_attribute_6, 
    FILTER_TYPE.custom_user_attribute_7, FILTER_TYPE.custom_user_attribute_8, FILTER_TYPE.custom_user_attribute_9, 
    FILTER_TYPE.data_center_business_location,FILTER_TYPE.device_cpu_cores, FILTER_TYPE.device_cpu_frequency, 
    FILTER_TYPE.device_cpu_generation, FILTER_TYPE.device_cpu_model, FILTER_TYPE.device_cpu_type, FILTER_TYPE.device_days_from_last_boot,
    FILTER_TYPE.device_domain, FILTER_TYPE.device_isp, FILTER_TYPE.device_image_build_number,FILTER_TYPE.device_ip_address, 
    FILTER_TYPE.device_manufacturer, FILTER_TYPE.device_memory, FILTER_TYPE.device_model, FILTER_TYPE.device_network_type, 
    FILTER_TYPE.device_power_plan, FILTER_TYPE.device_subnet, FILTER_TYPE.device_type, FILTER_TYPE.location_city, 
    FILTER_TYPE.location_country, FILTER_TYPE.location_on_site, FILTER_TYPE.location_on_vpn, FILTER_TYPE.location_region, 
    FILTER_TYPE.location_state, FILTER_TYPE.ms_office_license_type, FILTER_TYPE.ms_office_version, FILTER_TYPE.os_architecture, 
    FILTER_TYPE.os_disk_type, FILTER_TYPE.os_family, FILTER_TYPE.os_name, FILTER_TYPE.user_department, FILTER_TYPE.user_domain, 
    FILTER_TYPE.user_email_address, FILTER_TYPE.user_full_name, FILTER_TYPE.user_office, FILTER_TYPE.user_role, 
    FILTER_TYPE.user_title, FILTER_TYPE.virtualization, FILTER_TYPE.wifi_bssid, FILTER_TYPE.wifi_channel, FILTER_TYPE.wifi_ssid, 
    FILTER_TYPE.device_idle_this_hour, FILTER_TYPE.ms_stability_index, FILTER_TYPE.os_free_disk_space, FILTER_TYPE.physical_location, 
    FILTER_TYPE.connected_device, FILTER_TYPE.connected_interface, 
];

/** this class defines the DataSourceApiService. */
class DataOceanApiService extends ApiService {
    /** the constructor for the class. */
    constructor() {
        super(DATA_OCEAN_BASE_URL);
    }

    /** returns the base uri, this can be overridden in subclasses to allow the uri to change
     *      after construction.
     *  @returns a String with the base uri. */
     protected getBaseUri(): string {
        if (ApiService.USE_REGION) {
            const region = ApiService.AUTH_SERVICE.getRegion();
            return `/api/iq/${region}/`;    
        } else {
            return this.baseApiUri;
        }
    }

    /** runs the specified data ocean query and returns the DO data.
     *  @param query the DataOcean query with the data.
     *  @returns a Promise which resolves to the returned data. */
    runDataOceanQuery(query: any): Promise<any> {
/*        
        return new Promise((resolve, reject) => {
            setTimeout(
                () => {
                    let data: any = {searchItems: {page: []}};
                    if (query.groupBy.includes("user_name")) {
                        data = {searchItems: {page: [
                            {
                                keys: [{id: "user_name", value: "JSMITH"}],
                                data: [{timestamp: null, metrics: [{id: "cli2srv_ooo_bytes", value: "55.4"}, {id: "cli2srv_ooo_packets", value: "55.4"}, {id: "cli2srv_payload_bytes", value: "55.4"}]}]
                            },
                            {
                                keys: [{id: "user_name", value: "ASTONE"}],
                                data: [{timestamp: null, metrics: [{id: "cli2srv_ooo_bytes", value: "55.4"}, {id: "cli2srv_ooo_packets", value: "55.4"}, {id: "cli2srv_payload_bytes", value: "55.4"}]}]
                            },
                            {
                                keys: [{id: "user_name", value: "TEST"}],
                                data: [{timestamp: null, metrics: [{id: "cli2srv_ooo_bytes", value: "55.4"}, {id: "cli2srv_ooo_packets", value: "55.4"}, {id: "cli2srv_payload_bytes", value: "55.4"}]}]
                            }
                        ]}};    
                    } else if (query.groupBy.includes("app_name")) {
                        data = {searchItems: {page: [
                            {
                                keys: [{id: "user_name", value: "Excel"}],
                                data: [{timestamp: null, metrics: [{id: "cli2srv_ooo_bytes", value: "55.4"}, {id: "cli2srv_ooo_packets", value: "55.4"}, {id: "cli2srv_payload_bytes", value: "55.4"}]}]
                            },
                            {
                                keys: [{id: "user_name", value: "Exchange"}],
                                data: [{timestamp: null, metrics: [{id: "cli2srv_ooo_bytes", value: "55.4"}, {id: "cli2srv_ooo_packets", value: "55.4"}, {id: "cli2srv_payload_bytes", value: "55.4"}]}]
                            },
                            {
                                keys: [{id: "user_name", value: "Outlook"}],
                                data: [{timestamp: null, metrics: [{id: "cli2srv_ooo_bytes", value: "55.4"}, {id: "cli2srv_ooo_packets", value: "55.4"}, {id: "cli2srv_payload_bytes", value: "55.4"}]}]
                            }
                        ]}};    
                    }                    
                    resolve(data);
                },
                1 * 1000
            );
        });
*/
        /*
        const utid = ApiService.AUTH_SERVICE.getTenantId();
        return new Promise((resolve, reject) => {
            super
                .get<string>(`dataocean/1.0/tenants/${utid}/product_info/${id}`)
                .then(
                    (results) => {
                        resolve(results);
                    },
                    (err) => {
                        reject(err);
                        console.error(err);
                    }
                );
        });
        */
       /*
       const translatedQuery = {
        "obj_type": "network_host.traffic",
        "time_series": false,
        "limit": 10,
        "metrics": [
                  "in_throughput",
                  "out_throughput",
                  "sum_bytes",
                  "sum_in_bytes",
                  "sum_out_bytes",
                  "sum_packets"
        ],
        "filters": {"location": [{"name": "Default-Internet"}]},
        "time": {"start":"1709394951","end":"1709398551"}
      };
      */

        const filters: any = {};
        if (query.filter.filters) {
            for (const filter of query.filter.filters) {
                if ([FILTER_TYPE.network_host, FILTER_TYPE.network_client, FILTER_TYPE.network_server, FILTER_TYPE.network_device].includes((filter as FilterEntry).type)) {
                    filters[(filter as FilterEntry).type] = (filter as FilterEntry).values.map(value => {return {ipaddr: value};});
                } else if ([FILTER_TYPE.network_interface].includes((filter as FilterEntry).type)) {
                    // Value is ipaddr:ifindex
                    filters[(filter as FilterEntry).type] = (filter as FilterEntry).values.map(value => {
                        const ipaddr = value.substring(0, value.lastIndexOf(":"));
                        const ifindex = value.substring(value.lastIndexOf(":") + 1, value.length);
                        return {ipaddr, ifindex};
                    });
                } else if ([FILTER_TYPE.protocol, FILTER_TYPE.dscp].includes((filter as FilterEntry).type)) {
                    filters[(filter as FilterEntry).type] = (filter as FilterEntry).values.map(value => {return {number: value};});
                    //filters[(filter as FilterEntry).type] = (filter as FilterEntry).values.map(value => {return {name: value};});
                } else if ([FILTER_TYPE.data_source].includes((filter as FilterEntry).type)) {
                    filters[(filter as FilterEntry).type] = (filter as FilterEntry).values.map(value => {return {id: value};});
                } else if ([FILTER_TYPE.user_device].includes((filter as FilterEntry).type)) {
                    filters[(filter as FilterEntry).type] = (filter as FilterEntry).values.map(value => {return {device_name: value};});
                } else if (ONE_LEVEL_KEYS.includes((filter as FilterEntry).type)) {
                    //filters[(filter as FilterEntry).type] = (filter as FilterEntry).values.map(value => {return value;});
                    filters[FILTER_TYPE.keys] = (filter as FilterEntry).values.map(value => {return {[(filter as FilterEntry).type]: value};});
                } else {
                    filters[(filter as FilterEntry).type] = (filter as FilterEntry).values.map(value => {return {name: value};});
                }
            }
        }

        if (!DYNAMIC_OBJ_TYPES.includes(query.dataset) && query.dataSource) {
            filters[FILTER_TYPE.data_source] = [{id: query.dataSource}];
        }

        let objType = "network_host.traffic";
        let groupBy: string[] | undefined = undefined;
        if (DYNAMIC_OBJ_TYPES.includes(query.dataset)) {
            objType = "npm_plus.traffic";
            objType = query.dataset;
            // Need to remove this
            groupBy = query.groupBy;
        } else if (query.dataset === "app_response_and_profiler") {
            if (query.groupBy.includes("network_host")) {
                objType = "network_host.traffic";
            } else if (query.groupBy.includes("network_client")) {
                objType = "network_client.traffic";
            } else if (query.groupBy.includes("network_server")) {
                objType = "network_server.traffic";
            } else if (query.groupBy.includes("application")) {
                objType = "application.traffic";
            } else if (query.groupBy.includes("location")) {
                objType = "location.traffic";
            } else if (query.groupBy.includes("client_location")) {
                objType = "client_location.traffic";
            } else if (query.groupBy.includes("server_location")) {
                objType = "server_location.traffic";
            }    
            // Get data from AR11 by setting the location filter
            if (!filters.location) {
                filters.location = [];
            }
            filters.location.push({name: "Default-Internet"});
        } else if (query.dataset === "profiler_traffic") {
            if (query.groupBy.includes("network_interface")) {
                objType = "network_interface.traffic";
            } else if (query.groupBy.includes("network_device")) {
                objType = "network_device.traffic";
            } else if (query.groupBy.includes("network_host")) {
                objType = "network_host.traffic";
            } else if (query.groupBy.includes("network_client")) {
                objType = "network_client.traffic";
            } else if (query.groupBy.includes("network_server")) {
                objType = "network_server.traffic";
            } else if (query.groupBy.includes("application")) {
                objType = "application.traffic";
            } else if (query.groupBy.includes("location")) {
                objType = "location.traffic";
            } else if (query.groupBy.includes("client_location")) {
                objType = "client_location.traffic";
            } else if (query.groupBy.includes("server_location")) {
                objType = "server_location.traffic";
            } else if (query.groupBy.includes("dscp")) {
                objType = "dscp.traffic";
            } else if (query.groupBy.includes("protocol")) {
                objType = "protocol.traffic";
            }   
        } else {
            // This object type has no group by.  The group by included was fake.
            objType = query.dataset;
        }

        // Translate the DAL query into a DO query
        const translatedQuery = JSON.parse(JSON.stringify(query));
        translatedQuery.obj_type = objType; //translatedQuery.dataset;
        translatedQuery.time = {start: translatedQuery.filter.time.startTime, end: translatedQuery.filter.time.endTime};
        //if (query.dataset === "profiler_traffic") {
            // Profiler is not accepting the start and end format
            translatedQuery.time = {start: translatedQuery.time.start.split(".")[0], end: translatedQuery.time.end.split(".")[0]};
        //}
        translatedQuery.limit = translatedQuery.top;
        translatedQuery.filters = filters;
        if (!DYNAMIC_OBJ_TYPES.includes(query.dataset)) {
            if (query.dataset === "app_response_and_profiler") {
                translatedQuery.filters = {...translatedQuery.filters, location: [{name: "Default-Internet"}]};
                translatedQuery.filters = {...translatedQuery.filters, data_source: [{name: "oak-valloy40", id: "28133108-379d-4c32-b829-db6060d5170d"}]};    
            }
        } else {
            translatedQuery.group_by = groupBy;
        }
        delete translatedQuery.dataset;
        delete translatedQuery.filter;
        delete translatedQuery.order;
        delete translatedQuery.groupBy;
        delete translatedQuery.keys;
        delete translatedQuery.top;
        delete translatedQuery.skip;
        delete translatedQuery.dataSource;

        return new Promise((resolve, reject) => {
            const utid = ApiService.AUTH_SERVICE.getTenantId();
            super
                .post<any>(`dataocean/3.0/tenants/${utid}/query`, translatedQuery)
                .then(
                    (response: any) => {
                        let data = response.data;
                        let info = response.info;
                        if (["aternity.traffic", "aternity2.traffic"].includes(query.dataset)) {
                            data = JSON.parse(JSON.stringify(data));
                            for (const dataset of data) {
                                if (dataset.keys) {
                                    for (const key in dataset.keys) {
                                        let newKey = key.substring(9, key.length);
                                        switch (newKey) {
                                            case "process.name":
                                                newKey = "process_name";
                                                break;
                                            case "process.id":
                                                newKey = "process_id";
                                                break;
                                            case "process.username":
                                                newKey = "process_username";
                                                break;
                                        }
                                        dataset.keys[newKey] = dataset.keys[key];
                                        delete dataset.keys[key];
                                    }
                                }
                            }
                            if (info?.keys) {
                                for (const key of info.keys) {
                                    let newId = key.id.substring(9, key.id.length);
                                    switch (newId) {
                                        case "process.name":
                                            newId = "process_name";
                                            break;
                                        case "process.id":
                                            newId = "process_id";
                                            break;
                                        case "process.username":
                                            newId = "process_username";
                                            break;
                                    }
                                    key.id = newId;
                                }
                            }
                        }
                        resolve({searchItems: {page: data, info: info}});
                    },
                    (err) => {
                        reject(err.response);
                    }
                );
        });
    }
}

/** a constant with an instance of the DataOceanApiService. */
const DataOceanService = new DataOceanApiService();
export { DataOceanService };
