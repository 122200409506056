/** This module creates the component that displays the common content for all nodes.
 *  @module
 */
import React, { useState, useRef } from 'react';
import Markdown from 'markdown-to-jsx';
import type { NodeProps } from 'react-flow-renderer';
import { STRINGS } from "app-strings";
import { Icon, type IconName, IconNames } from '@tir-ui/react-components';
import { APP_ICONS, SDWAN_ICONS } from 'components/sdwan/enums/icons.ts';
import { ItemList } from 'components/common/layout/item-list/ItemList.tsx';
import { ItemListItem } from 'components/common/layout/item-list/ItemListItem.tsx';
import { useUserPreferences } from 'utils/hooks/useUserPreferences.ts';
import { chartNodes, priorityNodes, commentNodes, outputNodes, onDemandInputNodes, subflowInputNodes } from 'utils/runbooks/RunbookUtils.ts';
import { PRIORITY_TO_LABEL_MAP } from 'components/enums/Priority.ts';
import { splitName } from '../ReactFlowGraph.tsx';
import { Button, Intent, Menu, Popover, PopoverInteractionKind, Position } from '@blueprintjs/core';
import { getIntegrationIconFromSvg } from 'pages/integrations/IntegrationsLibraryPage.tsx';
import "./BaseNodeContent.scss";
import './default/DefaultNode.css';

/** this interface defines the properties passed into the BaseNodeContent component. */
export interface BaseNodeContentProps extends NodeProps {
    /** the name that is to be displayed in the node. */
    name?: string | JSX.Element;
    /** the icon that is to be displayed in the node. */
    defaultIcon?: string;
    /** specifies whether or not to show the tooltip. */
    showTooltip?: boolean;
    /** a boolean value, true if you should show the controls. */
    showControls?: boolean;
    /** Provide a count of ports for this node to use for height calculation */
    portCount?: number;
    /** the number of output handles. */
    numOutputHandles?: number;
    /** the list of input handles. */
    inputHandles?: any[];
    /** the list of output handles. */
    outputHandles?: any[];
}

/** this enum contains the actions for the quick controls menu. */
export enum NODE_CONTROLS_ACTIONS {
    /** the action which specifies opening the editor. */
    edit        = "edit",
    /** the action which specifies duplicating the node. */
    duplicate   = "duplicate",
    /** the action which specifies deleting the node. */
    delete      = "delete",
    /** the action which specifies adding the menu that shows the list of nodes that can be added. */
    showAddMenu = "showAddMenu",
    /** the action which specifies hiding the menu that shows the list of nodes that can be added. */
    hideAddMenu = "hideAddMenu"
}

// This constant is set at runtime and specifies whether or not to include runbook development only features
const INCLUDE_RUNBOOK_DEV = import.meta.env.VITE_INCLUDE_RUNBOOK_DEV === "true";

/** Renders the base node content component.
 *  @param props the properties passed in.
 *  @returns JSX with the base node content component.*/
export const BaseNodeContent = React.forwardRef(({
    name, defaultIcon, showTooltip = INCLUDE_RUNBOOK_DEV, showControls = true, portCount = 1, ...props
}: BaseNodeContentProps, ref: any): JSX.Element => {
    const data = props.data;
    let isTextCentered = true;
    const [menuItems, setMenuItems] = useState<Array<JSX.Element>>([]);
    const [showAdjacentAddMenu, setShowAdjacentAddMenu] = useState<boolean>(false);
    const showHandleMenus = useRef<boolean[]>([]);
    const userPreferences = useUserPreferences({listenOnlyTo: {
        subflows: {isMultipleOutputsEnabled: true},
    }});
    
    ref.current = {
        showMenu: (output: number) => {
            const newMenuItems: Array<JSX.Element> = onNodeControlClicked(undefined, NODE_CONTROLS_ACTIONS.showAddMenu, {showOutputs: false, output});
            setMenuItems(newMenuItems);

            const showMenus: boolean[] = new Array(props.outputHandles?.length || 0);
            showMenus.fill(false); 
            showMenus[output] = true;
            showHandleMenus.current = showMenus;   
            setShowAdjacentAddMenu(true);
        }
    };

    if (commentNodes.includes(data.type) && data.hasOwnProperty('properties')) {
        if (data.properties.hasOwnProperty('0') && data.properties[0].value) {
            isTextCentered = false;
            name = <Markdown>{data.properties[0].value}</Markdown>;
        } else {
            name = data.label;
        }
    } else {
        // Set the name to the translated name if an i18n keys exists, otherwise just use the name.
        name = name || (data.i18nNameKey ? STRINGS.defaultRunbooks[data.i18nNameKey] : data.label);
        if (name === null || name === undefined || name === "") {
            switch (data.type) {
                case "http in":
                    let method = "";
                    let url = "";
                    if (data.properties) {
                        for (const prop of data.properties) {
                            if (prop.key === "url") {
                                url = prop.value;
                            } else if (prop.key === "method") {
                                method = prop.value;
                            }
                        }
                    }
                    if (method || url) {
                        name = "[" + method + "]/" + url;
                    }
                    break;
                default:
                    name = data.type; //"Unknown: " + props.id;
            }
        }
    }
    if (chartNodes.includes(data.type) && data.hasOwnProperty('properties')) {
        for (const prop of data.properties) {
            if (prop.key === "row") {
                name = name + " (" + STRINGS.runbookEditor.nodeLibrary.propertyLabels.rowTerse + ": " + prop.value + ")";
                break;
            }
        }
    }
    if (priorityNodes.includes(data.type) && data.properties) {
        for (const prop of data.properties) {
            if (prop.key === "priority") {
                name = name + " (" + PRIORITY_TO_LABEL_MAP[prop.value.toUpperCase()] + ")";
                break;
            }
        }
    }
    if (onDemandInputNodes.includes(data.type) || subflowInputNodes.includes(data.type)) {
        for (const prop of data?.properties || []) {
            if (prop.key === "synthKeys") {
                const synthKeys = prop.value;
                let entityType = synthKeys?.length ? synthKeys[0].label : onDemandInputNodes.includes(data.type) ? "None" : "";
                name = name + (entityType ? " (" + entityType + ")" : "");
                break;
            }
        }
    }
    if (outputNodes.includes(data.type) && userPreferences.subflows?.isMultipleOutputsEnabled) {
        for (const prop of data.properties) {
            if (prop.key === "index") {
                name = name + " (" + (parseInt(prop.value) + 1) + ")";
                break;
            }
        }
    }

    const [nodeName, subText] = (!commentNodes.includes(data.type) && typeof name === 'string') ? splitName(name || "") : [name, ""];
    const nameElement = subText ? <div>{nodeName}<div className="display-10">{subText}</div></div> : name;

    const toolTipInfo = [] as Array<JSX.Element>;
    if (showTooltip && data?.properties) {
        toolTipInfo.push(
            <ItemListItem label="Name">{name}</ItemListItem>,
            <ItemListItem label="Type">{props.type}</ItemListItem>
        );
        for (const property of data.properties) {
            const key = property.key;
            let value = property.value;
            if (value !== null && value !== undefined && value !== "" && 
                key !== "notes" && key !== "notesPosition") {  //removing notes from tooltip
                if (typeof value === "object") {
                    value = JSON.stringify(value, null, 4);
                }
                toolTipInfo.push(<ItemListItem key={property.key} label={key}>
                    {
                        value.includes && value.includes("\n") ?
                        <pre className="display-9 w-max-4 h-max-4 overflow-auto bg-light border p-2">{value.toString()}</pre> :
                        value
                    }
                </ItemListItem>);
            }
        }
    }

    function onNodeControlClicked (e, action, options?: any): any {
        if (e) {
            e.preventDefault();
            e.stopPropagation();    
        }
        if (props.data.quickControlsCallback) {
            return props.data.quickControlsCallback(action, props.id, options);
        }
        return null;
    }

    const outputHandles: any[] = [];
    if (props.outputHandles) {
        for (let index = 0; index < props.outputHandles.length; index++) {
            outputHandles.push(
                <Popover
                    key={"output-handle-" + index} usePortal lazy
                    minimal={true}
                    position={Position.RIGHT} 
                    interactionKind={PopoverInteractionKind.CLICK} 
                    className="plus-span"
                    autoFocus={false}
                    enforceFocus={false}
                    hasBackdrop={true}
                    isOpen={showAdjacentAddMenu && showHandleMenus[index]}
                    canEscapeKeyClose={true}
                    onClosed={() => {props.data.quickControlsCallback(NODE_CONTROLS_ACTIONS.hideAddMenu, props.id)}}
                    content={<Menu>{menuItems}</Menu>}
                >
                    {<div className="py-2">{props.outputHandles[index]}</div>}
                </Popover>
            );
        }
    }

    const contents = <div key={"node-" + props.id} className={"contents" + (props.data.dragging ? " dragging" : "")}>
        {
            showControls &&
            <div className="position-relative">
                <div className={"position-absolute node-controls w-1-5"}>
                    <Button className="ctrl-0" minimal icon={IconNames.EDIT} data-action={NODE_CONTROLS_ACTIONS.edit} onClick={e => onNodeControlClicked(e, NODE_CONTROLS_ACTIONS.edit)}/>
                    <Button className="ctrl-1" minimal icon={IconNames.DUPLICATE} data-action={NODE_CONTROLS_ACTIONS.duplicate} onClick={e => onNodeControlClicked(e, NODE_CONTROLS_ACTIONS.duplicate)}/>
                    <Button className="ctrl-2" minimal icon={IconNames.TRASH} data-action={NODE_CONTROLS_ACTIONS.delete} onClick={e => onNodeControlClicked(e, NODE_CONTROLS_ACTIONS.delete)}/>
                </div>
            </div>
        }
        <div className="icon-and-label" style={{ minHeight: portCount * 10 }}>
            {
                (data?.icon || defaultIcon) &&
                <Icon
                    className="icon flex-grow-0 node-icon"
                    icon={
                        data.integrationInfo?.name ? 
                        getIntegrationIconFromSvg(
                            data.integrationInfo.icon, data.integrationInfo.name, "avatar", data.integrationInfo.secondaryColor
                        ) as any : 
                        getIconForNode(data.icon) || defaultIcon
                    }
                />
            }
            <span className={"name flex-grow-1" + (isTextCentered ? " text-center" : '')}>{nameElement}</span>
        </div>
        <div className='plus-container d-flex flex-column h-auto'>
            {outputHandles}
        </div>
    </div>;
    
    let wPos = "0", ePos = "0";
    const errorsAndWarnings: Array<JSX.Element> = [];
    if (props.data.errors) {
        for (const error of props.data.errors) {
            errorsAndWarnings.push(
                <ItemListItem key={"error" + (errorsAndWarnings.length + 1)}>{<>
                    <Icon icon={IconNames.ERROR} className="me-2 align-top" intent={Intent.DANGER}/><span dangerouslySetInnerHTML={{__html: error}} />
                </>}</ItemListItem>
            );
        }
    }
    if (props.data.warnings) {
        for (const warning of props.data.warnings) {
            errorsAndWarnings.push(
                <ItemListItem key={"warning" + (errorsAndWarnings.length + 1)}>{<>
                    <Icon icon={IconNames.WARNING_SIGN} className="me-2 align-top" intent={Intent.WARNING}/><span dangerouslySetInnerHTML={{__html: warning}} />
                </>}</ItemListItem>
            );
        }
    }
    if (props.data.warnings && props.data.errors) {
        wPos = "10px";
        ePos = "-10px";
    }
    
    return (<>
        {props.inputHandles}
        {(data.warnings || data.errors) && <div className="warning-and-error-icons d-flex justify-content-center">
            {data.warnings && <div className="position-relative" style={{right: wPos}}><Icon className="position-absolute" icon={IconNames.WARNING_SIGN} intent={Intent.WARNING}/></div>}
            {data.errors && <div className="position-relative" style={{right: ePos}}><Icon className="position-absolute" icon={IconNames.ERROR} intent={Intent.DANGER}/></div>}
        </div>}
        {
            toolTipInfo.length || errorsAndWarnings.length ?
            <Popover usePortal lazy
                content={<ItemList className="w-max-5 h-max-5 overflow-auto">
                    {errorsAndWarnings}
                    {toolTipInfo}
                </ItemList>}
                interactionKind="hover"
                className="w-100 d-inline"
                popoverClassName="opacity-8"
                hoverOpenDelay={errorsAndWarnings.length === 0 ? 1000 : 250}
                disabled={props.data.dragging || props.data.connecting}
                isOpen={props.data.dragging ? false : undefined}
                transitionDuration={150}
                autoFocus={false}
                enforceFocus={false}
            >
                {contents}
            </Popover> :
            contents
        }
    </>);
});

/** returns the icon for the node.
 *  @param iconKey the key for the icon.
 *  @returns the IconName or undefined if there is no icon. */
export function getIconForNode (iconKey?: string): IconName | undefined {
    const icon = iconKey && (SDWAN_ICONS[iconKey] || APP_ICONS[iconKey] || iconKey);
    return icon;
}
