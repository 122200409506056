/** This module contains utilities for editing transform nodes and validating transform nodes.
 *  @module
 */

import { STRINGS } from "app-strings";
import { GraphDef, NodeDef } from "components/common/graph/types/GraphTypes.ts";
import { NodeUtils } from "utils/runbooks/NodeUtil.ts";
import { getProperties } from "utils/runbooks/RunbookUtils.ts";
import { VariableContextByScope } from "utils/runbooks/RunbookContext.class.ts";
import { INCIDENT_SCOPE, RUNTIME_SCOPE, SUBFLOW_SCOPE } from "utils/runbooks/VariablesUtils.ts";


/** an enum with all of the valid transform node properties. */
export enum SET_SIMPLE_VARIABLES_NODE_EDIT_PROPS {
    /** the transform template property. */
    TRANSFORM_TEMPLATE = "transformTemplate",
    /** the variables property. */
    VARIABLES = "variables",
    /** the debug property */
    DEBUG = 'debug',
}

/** Utility class for set primitive variables node,*/
export class SetSimpleVariablesNodeUtils extends NodeUtils {
    /** the error messages for the set primitive variables node from the STRINGS file. */
    static errMsgs = STRINGS.runbookEditor.errors.variablesNode;

    /** Check if set primitive variables node is valid. Validates in the context of other nodes in the graph
     *  @param nodeId - node identifier
     *  @param graphDef - graph with info on all the nodes.
     *  @param variables the map of variables by scope.
     *  @returns  is node valid. */
    static isNodeValid(nodeId: string | undefined | null, graphDef: GraphDef, variables?: VariableContextByScope): boolean {
        if (!nodeId) {
            console.error(" isNodeValid: nodeId is undefined ");
            return false;
        }
        const errors = [];
        this.validateNode(nodeId, errors, graphDef, variables)
        return errors.length === 0;
    }

    /** Check if a set primitive variables node is valid. Validates in the context of other nodes in the graph.
     *       Populates the errors.
     *  @param nodeId - node identifier
     *  @param errors - IN-OUT argument the array his populated with error messages. Empty array if
     *       there are no errors
     *  @param graphDef - graph with info on all the nodes.
     *  @param variables the map of variables by scope. */
    static validateNode(nodeId: string, errors: string[], graphDef: GraphDef, variables?: VariableContextByScope): void {
        let curNode = graphDef.nodes.find((n) => {
            return nodeId === n.id
        });
        if (!curNode) {
            return;
        }
        // no-op currenty.
        super.validateNode(nodeId, errors, graphDef, variables);
        this.validateNodePropertiesFromGraphDef(curNode, getProperties(curNode), errors, curNode?.name, variables);
    }

    /** Validates the property values for a given set primitive variables node
     *  @param selectedNode the UniversalNode that wraps the react-flow node.
     *  @param currentProperties the key/value pairs with the node properties.
     *  @param errors the array of strings with any errors that have been encountered.
     *  @param label a string with the node label.
     *  @param variables the map of variables by scope.
     *  @returns an array of strings with all the errors that were found. */
    static validateNodePropertiesFromGraphDef(
        selectedNode: NodeDef | undefined, currentProperties: Record<string, any>, errors: Array<string>, label: string,
        variables?: VariableContextByScope
    ): Array<string> {
        if (!currentProperties.transformTemplate) {
            errors.push(SetSimpleVariablesNodeUtils.errMsgs.transformTemplate);
        }
        if (!currentProperties.variables?.length) {
            errors.push(SetSimpleVariablesNodeUtils.errMsgs.oneVariableDefined);
        }
        if (currentProperties?.variables?.length) {
            const primitiveVariables: string[] = NodeUtils.getPrimitiveVariablesList(variables);
            for (const variable of currentProperties.variables) {
                if (!variable.name) {
                    errors.push(SetSimpleVariablesNodeUtils.errMsgs.variableNameMissing);
                    break;
                }
            }
            for (const variable of currentProperties.variables) {
                if (variable.name && !primitiveVariables.includes(variable.name)) {
                    if (variable.name.includes(RUNTIME_SCOPE)) {
                        errors.push(SetSimpleVariablesNodeUtils.errMsgs.incompatibleRuntimeVariable);
                    } else if (variable.name.includes(INCIDENT_SCOPE)) {
                        errors.push(SetSimpleVariablesNodeUtils.errMsgs.incompatibleIncidentVariable);
                    } else if (variable.name.includes(SUBFLOW_SCOPE)) {
                        errors.push(SetSimpleVariablesNodeUtils.errMsgs.incompatibleSubflowVariable);
                    }
                    break;
                }
            }
        }
        return errors;
    }

    /** Validates the property values for a given set primitive variables node
     *  @param currentProperties the key/value pairs with the node properties.
     *  @param errors the array of strings with any errors that have been encountered.
     *  @param label a string with the node label.
     *  @returns an array of strings with all the errors that were found. */
    static validateNodeProperties(
        currentProperties: Record<string, any>, errors: Array<string>
    ): Array<string> {
        if (!currentProperties.transformTemplate) {
            errors.push(SetSimpleVariablesNodeUtils.errMsgs.transformTemplate);
        }
        if (!currentProperties.variables?.length) {
            errors.push(SetSimpleVariablesNodeUtils.errMsgs.oneVariableDefined);
        }
        if (currentProperties?.variables?.length) {
            for (const variable of currentProperties.variables) {
                if (!variable.name) {
                    errors.push(SetSimpleVariablesNodeUtils.errMsgs.variableNameMissing);
                    break;
                }
            }
        }
        return errors;
    }

}
