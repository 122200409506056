import { useCallback } from 'react';
import { Button, ButtonGroup, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { AuthService } from '@tir-ui/azure-auth';
import { ErrorToaster, SuccessToaster } from '@tir-ui/react-components';
import { TenantManagementService } from 'utils/services/TenantManagementService.ts';
import { openModal } from "components/common/modal/ModalRenderer.tsx";
import { openConfirm } from "components/common/modal/ConfirmModal.tsx";
import { STRINGS } from 'app-strings';

export const EditDeleteColumn = (props) => {
    const {
        cell,
        setParams,
        rolesByProduct,
        platformRoles,
        productKey,
    } = props;
    const rowId = cell.row.id;
    const userData = cell.data;
    const userId = userData[rowId].id;
    const name = userData[rowId].displayName;
    const role: string = userData[rowId].role.value;

    const deleteUser = useCallback(
        (userId, name, role) => {
            openConfirm({
                message: STRINGS.formatString(
                    STRINGS.USER_ACCOUNT_MANAGEMENT.DELETE_USER.confirmMsg,
                    <b>{name}</b>
                ),
                onConfirm: () => {
                    const tenantId = AuthService.getTenantId();
                    const queryString =
                        platformRoles && platformRoles.length && platformRoles[0].value === role
                            ? `superuser=${role}`
                            : `product=${productKey}`;
                    return TenantManagementService.delete(
                        `common/tenant/${tenantId}/user/${userId}?${queryString}`
                    ).then(
                        () => {
                            SuccessToaster({
                                message: STRINGS.USER_ACCOUNT_MANAGEMENT.DELETE_USER
                                        .successMessage
                            });
                            setParams({});
                        },
                        (error) => {
                            ErrorToaster({
                                message: error.message
                            });
                        }
                    );
                },
                intent: Intent.PRIMARY,
                icon: IconNames.TRASH,
            });
        },
        [setParams, productKey, platformRoles]
    );

    return (
        <ButtonGroup>
            <Button
                icon={IconNames.EDIT}
                onClick={() => {
                    openModal('editUserModal', {
                        rolesByProduct: rolesByProduct,
                        platformRoles: platformRoles,
                        userId: userId,
                        userRole: role,
                        productKey: productKey,
                        name: name,
                        onSuccess: () => {
                            SuccessToaster({
                                message: STRINGS.USER_ACCOUNT_MANAGEMENT.EDIT_ROLE
                                    .successMessage
                            });
                            setParams({});
                        },
                    });
                }}
            >
                {STRINGS.USER_ACCOUNT_MANAGEMENT.EDIT_ROLE.buttonText}
            </Button>
            <Button
                icon={IconNames.TRASH}
                onClick={() => deleteUser(userId, name, role)}
            >
                {STRINGS.USER_ACCOUNT_MANAGEMENT.DELETE_USER.buttonText}
            </Button>
        </ButtonGroup>
    );
};
