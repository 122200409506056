/** This module contains the functional React component for rendering the data ocean data type control.
 *  This control allows users to select the data ocean object type for queries where the object type can
 *  be set.  This is true of the swiss army knife node.
 *  @module
 */

import React from "react";
import { HELP, STRINGS } from "app-strings";
import { InlineHelp } from "components/common/layout/inline-help/InlineHelp.tsx";
import { HTMLSelect } from "@blueprintjs/core";

/** This interface defines the properties passed into the data ocean data type React component.*/
export interface DataOceanDataTypeProps {
    /** the current node type, can be either data_ocean or data_ocean_dynamic. */
    type: string;
    /** the current properties object with the value of all the controls in the editor. */
    currentProperties: any;
    /** the array of object types */
    objTypes: {label: string, value: string}[];
    /** the handler for the data type change event. */
    onDataTypeChanged: () => void;
}

/** Renders the component to render the data ocean metric toggle field.
 *  @param props the properties passed in.
 *  @returns JSX with the react data ocean metric toggle component.*/
export function DataOceanDataType(props: DataOceanDataTypeProps): JSX.Element | null {
    const { 
        currentProperties, objTypes, onDataTypeChanged
    } = props;

    if (!objTypes.length) {
        return null;
    }

    const doHelpKey: string = props.type !== "data_ocean_dynamic" ? currentProperties.objType : "dynamic_traffic";

    return (
        <React.Fragment>
            <tr><td className="font-size-md-large fw-bold pt-2" colSpan={2}>
                <InlineHelp
                    helpMapping={HELP.RunbookNodeCategory.Dataquery[doHelpKey.replace('.', '_')]?.dataType}
                >
                    {STRINGS.runbookEditor.nodeLibrary.nodes.data_ocean.labels.dataTypeSection}
                </InlineHelp>
            </td></tr>
            <tr>
                <td colSpan={2}>
                    <HTMLSelect
                        data-testid="obj-type-select"
                        name="obj-type-select"
                        fill={ true }
                        options={objTypes}
                        defaultValue={ currentProperties['objType'] }
                        onChange={ e => {
                            currentProperties['objType'] = e.target.value;
                            onDataTypeChanged();
                        } }
                    />
                </td>
            </tr>
        </React.Fragment>
    );
}
