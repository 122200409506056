/** This module contains the component for selecting an entity from the list of entities in the workspace 
 *  view.  The selected entity becomes the group by in the query.
 *  @module
 */

import React, { useState, useEffect, useMemo } from "react";
import { Icon, IconNames } from "@tir-ui/react-components";
import { APP_ICONS } from "components/sdwan/enums/icons.ts";
import { InputGroup, Button, HTMLSelect, Popover, Position, AnchorButton } from "@blueprintjs/core";
import { DataOceanUtils } from "components/common/graph/editors/data-ocean/DataOceanUtils.ts";
import { toLower } from 'lodash';
import { STRINGS } from "app-strings";
import { GenericKey, NodeUtils } from "utils/runbooks/NodeUtil.ts";
import { getArDataSources, getTypes } from 'utils/stores/GlobalDataSourceTypeStore.ts';
import PinnedWorkflows from 'components/common/vis-framework/components/PinnedWorkflows.json';
import PinnedWorkflow1 from 'components/common/vis-framework/components/PinnedWorkflow1.json';
import PinnedWorkflow2 from 'components/common/vis-framework/components/PinnedWorkflow2.json';
import PinnedWorkflow3 from 'components/common/vis-framework/components/PinnedWorkflow3.json';
import PinnedWorkflow4 from 'components/common/vis-framework/components/PinnedWorkflow4.json';
import { PinnedWorkflowConfig } from "pages/navigator/Navigator.type";
import { DataOceanObjectType, QUERY_TYPE } from "components/common/graph/editors/data-ocean/DataOceanMetadata.type";
import { dataSourceTypeOptions } from "utils/services/DataSourceApiService";
import { useUserPreferences } from "utils/hooks/useUserPreferences";
import "./EntityList.scss";

/** this interface defines the properties passed into the EntityList React component. */
export interface EntityListProps {
    /** a String with the object type whose entities are to be displayed. */
    objType: string;
    /** the handler for pinned workflow export events. */
    onPinnedWorkflowExport: () => void;
    /** the handler for pinned workflow save events. */
    onPinnedWorkflowSaveNew: (name: string) => void;
    /** the handler for pinned workflow delete events. */
    onPinnedWorkflowDelete: (id: string) => void;
    /** the handler for pinned workflow update events. */
    onPinnedWorkflowUpdate: (id: string, name: string) => void;
}

/** this interface defines the information that is in the pinned drag and drop data object. */
export interface DragPinnedData {
    /** a string with the id of the pinned workflow. */ 
    id: string;
    /** a string with the name of the pinned workflow. */
    name: string;
    /** a boolean value which specifies if this is a default pinned workflow. */
    isDefault: boolean;
}

/** this interface defines the information that is in the drag and drop data object. */
export interface DragData {
    /** a String with the object type in the DO metadata. */ 
    objType: string;
    /** a string with the data source id. */
    dataSource: string;
    /** a string with the id of the group by. */
    groupBy: string;
}

const EntityList = (props: EntityListProps): JSX.Element => {
    const userPreferences = useUserPreferences({listenOnlyTo: {navigator: {pinnedWorkspaces: []} }});
    const [pinnedCategoryOpen, setPinnedCategoryOpen] = useState<boolean>(true);
    const [dsCategoryOpen, setDsCategoryOpen] = useState<boolean>(true);
    const [vpCategoryOpen, setVpCategoryOpen] = useState<boolean>(true);
    const [listsCategoryOpen, setListsCategoryOpen] = useState<boolean>(true);
    const [pinnedFilterValue, setPinnedFilterValue] = useState<string>('');
    const [listsFilterValue, setListsFilterValue] = useState<string>('');
    const [entitiesByCategory, setEntitiesByCategory] = useState<Record<string, GenericKey[]>>({});
    const [pinnedWorkflows, setPinnedWorkflows] = useState<Array<{id: string, name: string, isDefault: boolean}> | undefined>([]);

    const [openCategories, setOpenCategories] = useState<Array<string>>([]);    
    const [categories, setCategories] = useState<Array<string>>([]);    
    const [isSaveOpen, setIsSaveOpen] = useState<boolean>(false);
    const [workflowName, setWorkflowName] = useState<string>("");

    const [objType, setObjType] = useState<string>(props.objType);
    const [vp, setVp] = useState<string>("NONE");

    useEffect(() => {
        let defGroupBys: GenericKey[] = [];
        if (DataOceanUtils.dataOceanMetaData.obj_types?.[objType]?.group_by_required) {
            const expandedKeys: GenericKey[] = NodeUtils.getExpandedKeysForAllGroupBys(DataOceanUtils.dataOceanMetaData, objType);
            defGroupBys = expandedKeys;
        } else if (DataOceanUtils.dataOceanMetaData.obj_types?.[objType]?.group_by?.length) {
            defGroupBys = (DataOceanUtils.dataOceanMetaData.obj_types[objType].group_by || []).map((key) => {
                return {id: key, ...DataOceanUtils.dataOceanMetaData.keys[key]} as GenericKey;
            });
        } else {
            // This is fake and is made up because there is no group by in this case.
            defGroupBys = [{id: objType, type: "string", label: DataOceanUtils.dataOceanMetaData.obj_types?.[objType].label}];
        }
        
        let entities: GenericKey[] = [];
        if (listsFilterValue) {
            entities = defGroupBys?.filter(groupByDef => toLower(groupByDef.label).includes(toLower(listsFilterValue)));
        } else {
            entities = defGroupBys;
        }

        const visibleCategories: string[] = entities.map((groupBy) => groupBy.category || "Unknown").filter((value, index, array) => array.indexOf(value) === index); 
        const entitiesByCategory: Record<string, GenericKey[]> = {};
        visibleCategories.forEach((category) => entitiesByCategory[category] = []);
        entities.forEach((groupByDef) => entitiesByCategory[groupByDef.category || "Unknown"].push(groupByDef));
        setCategories(visibleCategories);
        setEntitiesByCategory(entitiesByCategory);
        if (openCategories.length === 0 && visibleCategories.length) {
            setOpenCategories([visibleCategories[0]]);
        }

        const pinnedWorkflows: {id: string, name: string, isDefault: boolean}[] = [
            ...PinnedWorkflows.map(workflow => {
                return {id: workflow.id, name: workflow.name, isDefault: workflow.isDefault};
            }),
            ...(userPreferences?.navigator?.pinnedWorkspaces?.map(workflow => {
                return {id: workflow.id, name: workflow.name, isDefault: workflow.isDefault};
            }) || [])
        ] as {id: string, name: string, isDefault: boolean}[];
        if (pinnedFilterValue) {
            setPinnedWorkflows(pinnedWorkflows.filter(workflow => toLower(workflow.name).includes(toLower(pinnedFilterValue))));
        } else {
            setPinnedWorkflows(pinnedWorkflows);
        }

    }, [listsFilterValue, pinnedFilterValue, objType, userPreferences.navigator?.pinnedWorkspaces]);

    const handleListsFilterChange = (event) => {
        setListsFilterValue(event.target.value);
    };

    const handleListsFilterClear = () => {
        const inputField = document.getElementById('listsFilterInput') as HTMLInputElement;
        if (inputField) {
            inputField.value = '';
            setListsFilterValue('');
        }
    }

    const listsFilterClearIcon = (
        <Button
            icon={IconNames.SMALL_CROSS}
            minimal={true}
            onClick={handleListsFilterClear}
        />
    );

    const handlePinnedFilterChange = (event: React.FormEvent<HTMLInputElement>) => {
        setPinnedFilterValue(event.currentTarget.value);
    };

    const handlePinnedFilterClear = () => {
        const inputField = document.getElementById('pinnedFilterInput') as HTMLInputElement;
        if (inputField) {
            inputField.value = '';
            setPinnedFilterValue('');
        }
    }

    const pinnedFilterClearIcon = (
        <Button
            icon={IconNames.SMALL_CROSS}
            minimal={true}
            onClick={handlePinnedFilterClear}
        />
    );

    const dragPinned = (event: any, data: DragPinnedData) => {
        event.dataTransfer.setData("pinnedInfo", JSON.stringify(data));
    }

    const drag = (event: any, data: DragData) => {
        event.dataTransfer.setData("queryInfo", JSON.stringify(data));
    }

    const objTypes: {value: string, label: string}[] = useMemo(
        () => {
            const availableDataSources: dataSourceTypeOptions[] = getTypes();
            const objTypesTemp: {value: string, label: string}[] = [];
            for (const objType in DataOceanUtils.dataOceanMetaData.obj_types) {
                const objTypeConfig: DataOceanObjectType = DataOceanUtils.dataOceanMetaData.obj_types[objType];
                let dsSupported = true;
                if (objTypeConfig.supported_data_sources?.length) {
                    dsSupported = false;
                    if (availableDataSources?.length) {
                        for (const dataSource of availableDataSources) {
                            if (objTypeConfig.supported_data_sources.includes(dataSource)) {
                                dsSupported = true;
                            }
                        }    
                    }        
                }
                if (
                    dsSupported && !objTypeConfig.exclude_from_workflows && objTypeConfig.supported_query_types?.length && 
                    objTypeConfig.supported_query_types.includes(QUERY_TYPE.summary)
                ) {
                    objTypesTemp.push({value: objType, label: objTypeConfig.label});
                }
            }
            return objTypesTemp;    
        }, []
    ); 

    const arDatasources: {value: string, label: string}[] = useMemo(
        () => {
            let dataSources: {value: string, label: string}[] = [{label: "None", value: "NONE"}];
            if (DataOceanUtils.dataOceanMetaData.obj_types[objType].filters.includes("data_source")) {
                dataSources = dataSources.concat({label: "All", value: "ALL"}).concat((getArDataSources() || []).map(ds => {
                    return { label: ds.name, value: ds.id }
                }));
            }
            return dataSources;
        }, [objType]
    )

    return (<div className="entity-list p-2 pt-3 bg-light">
        <div className="entity-list-header display-7 pb-3">{STRINGS.navigator.entityListWidget.title}</div>
        <div className="entity-list-category mb-2 mt-4">
            <div className="d-flex justify-content-between">
                <div className="entity-list-category-name" onClick={() => setPinnedCategoryOpen(!pinnedCategoryOpen)} style={{ cursor: "pointer" }}>
                    <Icon icon={pinnedCategoryOpen ? APP_ICONS.SECTION_OPEN : APP_ICONS.SECTION_CLOSED} />
                    <span className="ps-1 fw-bold" >
                        {STRINGS.navigator.entityListWidget.pinnedListTitle}
                    </span>
                </div>
                <div>
                    <Popover position={Position.LEFT_TOP} 
                        isOpen={isSaveOpen}
                        content={<>
                            <div className={"p-2"} >
                                <label className="me-2">Workflow name:</label>
                                <InputGroup
                                    name="query-name"
                                    onChange={e => setWorkflowName(e.currentTarget.value)}
                                    value={workflowName}
                                    disabled={false}
                                />
                            </div>
                            <div className="d-flex justify-content-between w-100 p-2">
                                <Button onClick={() => {
                                    setIsSaveOpen(false);
                                    setWorkflowName("");
                                }}>{STRINGS.incidentSearch.facetCTA.cancelQuery}</Button>
                                <AnchorButton intent="primary"
                                    text={STRINGS.incidentSearch.facetCTA.saveQuery}
                                    className="display-9"
                                    disabled={workflowName.trim().length === 0}
                                    onClick={() => {
                                        if (props.onPinnedWorkflowSaveNew) {
                                            props.onPinnedWorkflowSaveNew(workflowName);
                                        }
                                        setIsSaveOpen(false);
                                        setWorkflowName("");
                                    }}
                                    data-testid="save-query-button"
                                />
                            </div>
                        </>} 
                    >
                        <Icon className="me-2" icon={IconNames.FLOPPY_DISK} onClick={() => {
                            setIsSaveOpen(!isSaveOpen);
                        }}/>
                    </Popover>
                    <button className="pin-entity"><Icon icon={APP_ICONS.ADD} onClick={() => {
                        if (props.onPinnedWorkflowExport) {
                            props.onPinnedWorkflowExport();
                        }
                    }}/></button>
                </div>
            </div>
            {pinnedCategoryOpen && 
                <div className="entity-list-items fw-normal pt-2">
                    <InputGroup
                        id={"pinnedFilterInput"}
                        leftIcon="search"
                        rightElement={pinnedFilterClearIcon}
                        onChange={handlePinnedFilterChange}
                        placeholder={STRINGS.incidents.impactSummaryView.search}
                        value={pinnedFilterValue}
                        className="mb-3 mt-2"
                    />
                    {pinnedWorkflows?.length ? pinnedWorkflows.map((workflow, index) => (
                        <div className="entity-list-item" draggable 
                            onDragStart={(event) => {
                                const data: DragPinnedData = {
                                    id: workflow.id,
                                    name: workflow.name,
                                    isDefault: workflow.isDefault
                                };
                                dragPinned(event, data); 
                            }} 
                            key={index}>
                            <Icon icon={APP_ICONS.DRAG_HANDLE_VERTICAL} />
                            <span>{workflow.name}</span>
                            {!workflow.isDefault && <>
                                <Icon icon={IconNames.TRASH} className="ms-2" 
                                    onClick={() => {
                                        if (props.onPinnedWorkflowDelete) {
                                            props.onPinnedWorkflowDelete(workflow.id);
                                        }
                                    }}
                                />
                                <Icon icon={IconNames.FLOPPY_DISK} className="ms-2" 
                                    onClick={() => {
                                        if (props.onPinnedWorkflowUpdate) {
                                            props.onPinnedWorkflowUpdate(workflow.id, workflow.name);
                                        }
                                    }}
                                />
                            </>}
                        </div>)) : (
                            <div className="entity-list-item display-9">{STRINGS.navigator.entityListWidget.noPinnedItemsFound}</div>
                        )}
                </div>
            }
        </div>
        <div className="entity-list-category mb-2 mt-4">
            <div className="entity-list-category-name" onClick={() => setDsCategoryOpen(!dsCategoryOpen)} style={{ cursor: "pointer" }}>
                <Icon icon={dsCategoryOpen ? APP_ICONS.SECTION_OPEN : APP_ICONS.SECTION_CLOSED} />
                <span className="ps-1 fw-bold" >{STRINGS.navigator.entityListWidget.dsListTitle}</span>
            </div>
            {dsCategoryOpen && 
                <div className="entity-list-items fw-normal pt-2">
                    <HTMLSelect
                        name="obj-types"
                        disabled={false}
                        options={objTypes}
                        value={objType}
                        onChange={(event) => {
                            setObjType(event.currentTarget.value);
                        }}
                    />
                </div>
            }
        </div>
        <div className="entity-list-category mb-2 mt-4">
            <div className="entity-list-category-name" onClick={() => setVpCategoryOpen(!vpCategoryOpen)} style={{ cursor: "pointer" }}>
                <Icon icon={vpCategoryOpen ? APP_ICONS.SECTION_OPEN : APP_ICONS.SECTION_CLOSED} />
                <span className="ps-1 fw-bold" >{STRINGS.navigator.entityListWidget.vpListTitle}</span>
            </div>
            {vpCategoryOpen && 
                <div className="entity-list-items fw-normal pt-2">
                    <HTMLSelect
                        name="vantage-points"
                        disabled={false}
                        options={arDatasources}
                        value={vp}
                        onChange={(event) => {
                            setVp(event.currentTarget.value);
                        }}
                    />
                </div>
            }
        </div>
        <div className="entity-list-category">
            <div onClick={() => setListsCategoryOpen(!listsCategoryOpen)} style={{ cursor: "pointer" }}>
                <Icon icon={listsCategoryOpen ? APP_ICONS.SECTION_OPEN : APP_ICONS.SECTION_CLOSED} />
                <span className="ps-1 fw-bold">{STRINGS.navigator.entityListWidget.listsListTitle}</span>
            </div>
            {listsCategoryOpen && 
                <div className="entity-list-items fw-normal pt-2">
                    <InputGroup
                        id={"listsFilterInput"}
                        leftIcon="search"
                        rightElement={listsFilterClearIcon}
                        onChange={handleListsFilterChange}
                        placeholder={STRINGS.incidents.impactSummaryView.search}
                        value={listsFilterValue}
                        className="mb-3 mt-2"
                    />
                    {categories.length ? 
                        categories.map((category) => {
                            return <>
                                <div onClick={() => setOpenCategories(openCategories.includes(category) ? openCategories.filter(newCat => newCat !== category) : [category, ...openCategories])} style={{ cursor: "pointer" }}>
                                    <Icon icon={openCategories.includes(category) ? APP_ICONS.SECTION_OPEN : APP_ICONS.SECTION_CLOSED} />
                                    <span className="ps-1 fw-bold">{category}</span>
                                </div>
                                {openCategories.includes(category) && entitiesByCategory[category].map(groupByDef => (
                                    <div className="entity-list-item ms-2" draggable key={category + "-" + groupByDef.id}
                                        onDragStart={(event) => {
                                            const data: DragData = {
                                                objType,
                                                dataSource: vp,
                                                groupBy: groupByDef.id
                                            };
                                            drag(event, data); 
                                        }}
                                    >
                                        <Icon icon={APP_ICONS.DRAG_HANDLE_VERTICAL} />
                                        <span>{groupByDef.label}</span>
                                    </div>
                                ))}
                            </>;
                        }) : 
                        <div className="entity-list-item ms-2 display-9">{STRINGS.navigator.entityListWidget.noListsFound}</div>
                    }
                </div>
            }
        </div>
    </div>);
}

export { EntityList };

/** returns a pinned workflow based on the id.
 *  @param id a String with the id.
 *  @returns the WorkflowConfig with the pinned workflow for the id. */
export function getPinnedWorkflow(id: string): PinnedWorkflowConfig | undefined {
    let workflow: PinnedWorkflowConfig | undefined = undefined;
    switch (id) {
        case "pinnedWorkflow1":
            workflow = PinnedWorkflow1 as any;
            break;
        case "pinnedWorkflow2":
            workflow = PinnedWorkflow2 as any;
            break;
        case "pinnedWorkflow3":
            workflow = PinnedWorkflow3 as any;
            break;
        case "pinnedWorkflow4":
            workflow = PinnedWorkflow4 as any;
            break;
    }
    return workflow;
}
