import { FormModal } from "components/common/modal/FormModal.tsx";
import { InputField } from "components/common/form/InputField.tsx";
import { SelectField } from 'components/common/form/SelectField.tsx';
import { Label } from "@blueprintjs/core";
import { STRINGS } from 'app-strings';
import * as yup from "yup";
import type { CloudIMDataSource } from 'utils/services/DataSourceApiService.ts';
import { CLOUD_PROVIDER_TYPE, CLOUD_PROVIDER_TYPE_TO_LABEL } from 'components/enums/CloudIM.ts';

const CloudIMDataSourceConfigModal = ((props) => {

    const validationSchema = yup.object().shape({
        data_source_name: yup
            .string()
            .label(STRINGS.DATA_SOURCES.cloudimDataSources.dialog.labels.dataSourceName)
            .required(),
        provider_type: yup
            .mixed()
            .label(STRINGS.DATA_SOURCES.cloudimDataSources.dialog.labels.providerType)
            .required(),
        auth_profile_id: yup
            .mixed()
            .label(STRINGS.DATA_SOURCES.cloudimDataSources.dialog.labels.authProfile)
            .required()
    });

    const formConfig = {
        initialValues: {
            data_source_name: props.data_source_name || '',
            provider_type: props.provider_type || CLOUD_PROVIDER_TYPE.AWS,
            auth_profile_id: props.auth_profile_id || (props.auth_profiles?.length ? props.auth_profiles[0]?.id : ''),
        },
        validationSchema: validationSchema,
        onSubmit: async (values: any) => {
            try {
                var ds: CloudIMDataSource = {
                    "dataSourceId": props.data_source_id ?? null,
                    "authProfileId": values.auth_profile_id,
                    "dataSourceName": values.data_source_name,
                    "provider": values.provider_type
                }
                await props.submit(ds);
            } catch (error) {
                console.error(error);
            }
        }
    }

    return (
        <FormModal
            title={STRINGS.DATA_SOURCES.cloudimDataSources.dialog.title}
            formikProps={formConfig}
            customLabels={{
                submit: STRINGS.DATA_SOURCES.cloudimDataSources.dialog.ctas.save,
                close: STRINGS.DATA_SOURCES.cloudimDataSources.dialog.ctas.cancel
            }}
            {...props}
        >
            <div className="container">
                <div className="row">
                    <Label className="label-width col-3">
                        {STRINGS.DATA_SOURCES.cloudimDataSources.dialog.labels.dataSourceName}
                    </Label>
                    <div className="col-8">
                        <InputField
                            data-testid="cloudim-datasource-modal-ds-name"
                            className="w-100"
                            placeholder={STRINGS.DATA_SOURCES.cloudimDataSources.dialog.placeholder.dataSourceName}
                            name={"data_source_name"}
                            required={true}
                        />
                    </div>
                </div>

                <div className="row">
                    <Label className="label-width col-3">
                        {STRINGS.DATA_SOURCES.cloudimDataSources.dialog.labels.providerType}
                    </Label>
                    <div className="col-8">
                        <SelectField name="provider_type" data-testid="cloudim-datasource-modal-provider-selector">
                            {Object.values(CLOUD_PROVIDER_TYPE).map
                                (provider => <option key={provider} value={provider}>{CLOUD_PROVIDER_TYPE_TO_LABEL[provider]}</option>)
                            }
                        </SelectField>
                    </div>
                </div>

                <div className="row">
                    <Label className="label-width col-3">
                        {STRINGS.DATA_SOURCES.cloudimDataSources.dialog.labels.authProfile}
                    </Label>
                    <div className="col-8">
                        <SelectField name="auth_profile_id" data-testid="cloudim-datasource-modal-authprofile-selector">
                            {props.auth_profiles?.map(ap =>
                                <option key={ap.id} value={ap.id}>{ap.name}</option>
                            )}
                        </SelectField>
                    </div>
                </div>
            </div>
        </FormModal>
    );
});

export default CloudIMDataSourceConfigModal;
