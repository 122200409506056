/** This module creates a new node for the react-flow  connection graph that represents the input, output and default node.
 *  @module
 */
import { APP_ICONS, SDWAN_ICONS } from 'components/sdwan/enums/icons.ts';
import { Popover } from '@blueprintjs/core';
import { Icon, type IconName } from '@tir-ui/react-components';
import { Handle, Position, type NodeProps, type Connection, type Edge } from 'react-flow-renderer';
import { ItemList } from '../layout/item-list/ItemList.tsx';
import { ItemListItem } from '../layout/item-list/ItemListItem.tsx';
import { STRINGS } from 'app-strings';

const onConnect = (params: Connection | Edge) => console.log('handle onConnect', params);

/** this interface defines the properties passed into the BaseNodeContent component. */
export interface IpNodeProps extends NodeProps {
    /** the name that is to be displayed in the node. */
    name?: string;
    /** the icon that is to be displayed in the node. */
    defaultIcon?: string;
    /** specifies whether or not to show the tooltip. */
    showTooltip?: boolean;
}

/** Renders the default node component.
 *  @param props the properties passed in.
 *  @returns JSX with the default node component.*/
export function IpNode ({
    // Position of handle as a target node from a different source
    targetPosition = Position.Left,
    // Position of handle as a source node
    sourcePosition = Position.Right,
    name,
    defaultIcon,
    showTooltip = true,
    ...props
}: IpNodeProps): JSX.Element {
    const isConnectable = props.isConnectable;

    // Create the input ports
    const inputPorts: Array<JSX.Element> = [];
    if (props.type === "output" || props.type === "default") {
        // Only one for now
        inputPorts.push(
            <Handle
                type="target"
                key={"input-handle-node-" + props.id}
                position={targetPosition}
                onConnect={onConnect} 
            />
        );
    }

    // Create the output ports
    const outputPorts: Array<JSX.Element> = [];
    if (props.type === "input" || props.type === "default") {
        outputPorts.push(
            <Handle
                type="source"
                key={"output-handle-node-" + props.id + "-"}
                position={sourcePosition}
                isConnectable={isConnectable} 
            />
        );
    }

    const data = props.data;

    // Set the name to the translated name if an i18n keys exists, otherwise just use the name.
    name = name || (data.i18nNameKey ? STRINGS.defaultRunbooks[data.i18nNameKey] : data.label);
    if (name === null || name === undefined || name === "") {
        name = data.type; //"Unknown: " + props.id;
    }

    const toolTipInfo = [] as Array<JSX.Element>;
    if (showTooltip && data?.properties) {
        for (const property of data.properties) {
            const key = property.key;
            let value = property.value;
            if (value !== null && value !== undefined && value !== "") {
                if (typeof value === "object") {
                    value = JSON.stringify(value, null, 4);
                }
                toolTipInfo.push(<ItemListItem key={property.key} label={key}>
                    {
                        value.includes && value.includes("\n") ?
                        <pre className="display-9 w-max-4 h-max-4 overflow-auto bg-light border p-2">{value.toString()}</pre> :
                        value
                    }
                </ItemListItem>);
            }
        }
    }

    const contents = <div className={"bg-light contents2" + (props.data.dragging ? " dragging" : "")}>
        {
            (data?.icon || defaultIcon) &&
            <div className="mb-2"><Icon
                className="icon2 flex2-grow-0"
                icon={getIconForNode(data.icon) || defaultIcon}
            /></div>
        }
        <div className="name2 flex2-grow-1 text2-center">{name}</div>
    </div>;

    return (<>
        {inputPorts}
        {showTooltip ?
        <Popover usePortal lazy
            content={<ItemList className="w-max-6 overflow-auto">
                <ItemListItem label="Name">{name}</ItemListItem>
                <ItemListItem label="Type">{props.type}</ItemListItem>
                {toolTipInfo}
            </ItemList>}
            interactionKind="hover"
            className="w-100"
            hoverOpenDelay={1000}
            disabled={props.data.dragging}
            isOpen={props.data.dragging ? false : undefined}
            transitionDuration={150}
        >
            {contents}
        </Popover> :
        contents}
        {outputPorts}
    </>);
};

/** returns the icon for the node.
 *  @param iconKey the key for the icon.
 *  @returns the IconName or undefined if there is no icon. */
 export function getIconForNode (iconKey?: string): IconName | undefined {
    const icon = iconKey && (SDWAN_ICONS[iconKey] || APP_ICONS[iconKey] || iconKey);
    return icon;
}
