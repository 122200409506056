query primaryIndicatorIncidentQuery($incidentId: [String!], $variant: Variant) {
    runbooks(filter: { incidentIds: $incidentId, variant: $variant }) {
        nodes {
            timestamp
            priority
        }
    }
    incidents: incidentDetails(filter: { incidentIds: $incidentId }) {
        nodes: page {
            id
            earliestIndicator
            latestIndicator
            trackingEntity {
                name
                kind
            }
            indicatorMappings {
                isPrimary
                isEarliest
                timestamp
                kind
                entityId
                metric
                sourceId
                entity {
                    id
                    name
                    kind
                    attributes {
                        field
                        value
                    }
                    parent {
                        id
                        kind
                        ipAddress
                        name
                        type
                    }
                }
            }
            indicators {
                isPrimary
                isEarliest
                timestamp
                kind
                metric
                startTime
                endTime
                details {
                    actualValue
                    expectedValue
                    acceptableHighValue
                    acceptableLowValue
                    granularity
                }
                entity {
                    id
                    name
                    kind
                    source {
                        id
                    }
                    attributes {
                        field
                        value
                    }
                    parent {
                        id
                        kind
                        ipAddress
                        name
                        type
                    }
                }
            }
        }
    }
}
