/** This file defines the ConnectionGraphView React component.  The ConnectionGraphView React component wraps a 
 *  connection graph component and includes the query to get the data.  The connection graph React component renders a 
 *  a connection graph or a topology.
 *  @module */

import { Query } from "reporting-infrastructure/types/Query.ts";
import { useQuery } from "utils/hooks/useQuery.ts";
import { FILTER_NAME } from "components/sdwan/enums/filters.ts";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade.tsx";
import { Column, BaseRunbookViewProps, DataSet } from "pages/riverbed-advisor/views/runbook-view/Runbook.type.ts";
import { ConnectionGraph } from "components/common/connection-graph/ConnectionGraph.tsx";
import { DIRECTION, EdgeDef, GraphDef, NodeDef } from "components/common/graph/types/GraphTypes.ts";
import { ReactFlowProvider } from "react-flow-renderer";
import SUMMARY_DATA_QUERY from '../table-view/summary-data-query.graphql';

/** an interface that describes the properties that can be passed in to the component.*/
export interface ConnectionGraphViewProps extends BaseRunbookViewProps {
    // Add any properties that are specific to this view
    /** Pass as true to render the chart with transparent background. */
    transparent?: boolean;
}

/** Creates the table view, which is a component that displays one table with analytics data.
 *  @param props an object with the properties passed to the table view.
 *  @returns JSX with the table component.*/
export const ConnectionGraphView = (props: ConnectionGraphViewProps): JSX.Element => {
    let primaryDataset: DataSet | undefined = undefined;
    if (props.datasets) {
        for (const dataset of props.datasets) {
            if (!dataset.isComparison) {
                primaryDataset =  dataset;
            }
        }
    }

    let {loading, data, error} = useQuery({
        query: new Query(SUMMARY_DATA_QUERY),
        requiredFilters: [FILTER_NAME.incidentId, FILTER_NAME.datasetId],
        filters: {
            [FILTER_NAME.incidentId]: props.incidentId,
            [FILTER_NAME.datasetId]: primaryDataset?.datapoints ? undefined : primaryDataset?.id
		    },
        skipGlobalFilters: true
    });

    const selectedMetric: string | undefined = props.widget?.options?.metric as string | undefined;

    const graphDef: GraphDef = {nodes: [], edges: []};

    const keyIds: Array<string> = [];
    if (primaryDataset?.keys) {
        for (const key in primaryDataset.keys) {
            keyIds.push(primaryDataset.keys[key].id);
        }
    }
    if (
        // Data Ocean is using the new compound key
        (keyIds.includes("network_client_server.network_client.ipaddr") && keyIds.includes("network_client_server.network_server.ipaddr")) ||
        // Data Ocean now has keys as objects, this logical expression captures that case
        (keyIds.includes("network_client.ipaddr") && keyIds.includes("network_server.ipaddr")) ||
        // Earlier Data ocean return keys as strings, this handles that case (this should not occur but is here just in case)
        (keyIds.includes("network_client") && keyIds.includes("network_server"))
    ) {
        //let columnDefs: Array<Column> = [];
        let columnDefsById: Record<string, Column> = {};
        if (primaryDataset?.keys) {
            //columnDefs = columnDefs.concat(primaryDataset.keys);
            for (const key in primaryDataset.keys) {
                columnDefsById[primaryDataset.keys[key].id] = primaryDataset.keys[key];
            }
        }
        if (primaryDataset?.metrics) {
            //columnDefs = columnDefs.concat(primaryDataset.metrics);
            for (const key in primaryDataset.metrics) {
                columnDefsById[primaryDataset.metrics[key].id] = primaryDataset.metrics[key];
            }
        }
        if (primaryDataset?.datapoints) {
            data = {};
            data.datapoints = primaryDataset.datapoints;
        }
        if (!loading) {
            if (data && data.datapoints && primaryDataset?.keys && primaryDataset?.metrics) {
                let connectionGraphColumn: Column | undefined = undefined;
                for (let metricIndex = 0; metricIndex < primaryDataset.metrics.length; metricIndex++) {
                    if ((selectedMetric && primaryDataset.metrics[metricIndex].id === selectedMetric) || (!selectedMetric && metricIndex === 0)) {
                        connectionGraphColumn = primaryDataset.metrics[metricIndex];
                    }
                }
                if (connectionGraphColumn) {
                    const minTh = 1, maxTh = 7;
                    let min = Number.MAX_SAFE_INTEGER, max = 0;
                    for (const datapoint of data.datapoints) {
                        if (datapoint.keys) {
                            const client = datapoint.keys["network_client_server.network_client.ipaddr"] || datapoint.keys["network_client.ipaddr"] || datapoint.keys["network_client"];
                            let clientNode: NodeDef | undefined = getNode(client, graphDef.nodes);
                            if (!clientNode) {
                                clientNode = createNode(client, graphDef.nodes);
                            }

                            const server = datapoint.keys["network_client_server.network_server.ipaddr"] || datapoint.keys["network_server.ipaddr"] || datapoint.keys["network_server"];
                            let serverNode: NodeDef | undefined = getNode(server, graphDef.nodes);
                            if (!serverNode) {
                                serverNode = createNode(server, graphDef.nodes);
                            }
                            const edge: EdgeDef = {fromNode: clientNode.id, toNode: serverNode.id};
                            if (datapoint.data && datapoint.data[connectionGraphColumn.id]) {
                                // We should only have one metric
                                const metricKey = datapoint.data[connectionGraphColumn.id];
                                const column = columnDefsById[metricKey];
                                let tv = datapoint.data[metricKey];
                                if (tv !== null && tv !== undefined && (column.type === "integer" || column.type === "float")) {
                                    try {
                                        if (typeof tv === "string") {
                                            // We have a string
                                            tv = Number(tv);
                                        }
                                        //tv = formatAndScaleMetricValue(tv, new Unit(column.unit || "")).formatted;
                                        edge.thickness = tv;
                                        min = Math.min(tv, min);
                                        max = Math.max(tv, max);
                                    } catch (error) {
                                        console.log("table data was not a number");
                                    }
                                }
                            }
                            graphDef.edges.push(edge);
                        }
                    }
                    for (const edge of graphDef.edges) {
                        edge.thickness = ((maxTh - minTh) / (max - min)) * (edge.thickness || min - min) + minTh;
                    }
                }
            }
        }
    }

    for (const node of graphDef.nodes) {
        let hasIn = false;
        let hasOut = false;

        for (const edge of graphDef.edges) {
            if (edge.fromNode === node.id) {
                hasOut = true;
            }
            if (edge.toNode === node.id) {
                hasIn = true;
            }
        }

        node.wires!.direction = (hasIn && hasOut ? DIRECTION.BOTH : (hasIn ? DIRECTION.IN : DIRECTION.OUT) );
    }

    return <DataLoadFacade loading={loading || props.loading} error={error || props.error} data={data}>
        <ReactFlowProvider><ConnectionGraph graphDef={graphDef} transparent={props.transparent} height={props.height}/></ReactFlowProvider>
    </DataLoadFacade>;
};

/** returns the node with the specified name.
 *  @param name the name to search for.
 *  @param nodes an array with the list of nodes.
 *  @returns the node or undefined if it was not found. */
function getNode(name: string, nodes: Array<NodeDef>): NodeDef | undefined {
    if (nodes) {
        for (const node of nodes) {
            if (node.name === name) {
                return node;
            }
        }    
    }
    return undefined;
}

/** creates a node for the graph.
 *  @param name a string with the name of the node.
 *  @param nodes an array with the list of nodes.
 *  @returns a NodeDef object with the node information. */
function createNode(name: string, nodes: Array<NodeDef>): NodeDef {
    const node = {
        "id": "ip" + (nodes.length + 1),
        "type": "data_ocean",
        "name": name,
        "info": "",
        "color": "#87a980",
        "icon": "DATABASE",
        "wires": {
            "direction": DIRECTION.NONE
        },
        "properties": []
    };
    nodes.push(node);
    return node;
}
