import React from "react";
import { ThemeManager as TirThemeManager } from "@tir-ui/react-components";
import { THEME } from "./constants";
import {
	getUserPreferences,
	setUserPreferences,
} from "utils/stores/UserPreferencesStore";

let initialized = false;
let theme: THEME;

/**
 * Handle theme related operations for the app.
 */
export class ThemeManager {
	/**
	 * This function should set the initial value of the theme and
	 * do any other loading required.
	 * @todo - when we get that API, we'll call it and set the theme there.
	 */
	static async initialize(): Promise<void> {
		this.setThemeValue(THEME.light);
		initialized = true;
		try {
			const preferences = await getUserPreferences();
			this.setThemeValue(preferences?.theme || theme);
		} catch (exception) {
			console.error(exception);
			console.error("Unable to get theme from user preferences");
		}
	}

	static getTheme(): THEME {
		if (!initialized) {
			throw new Error("not initialized");
		}
		return theme;
	}

	static async setTheme(newTheme: THEME): Promise<void> {
		if (!initialized) {
			throw new Error("not initialized");
		}
		this.setThemeValue(newTheme);
		await setUserPreferences({ theme });
	}

	static setThemeValue(themeValue: THEME) {
		if (
			theme !== themeValue &&
			(themeValue === THEME.light || themeValue === THEME.dark)
		) {
			theme = themeValue;
			// document.documentElement.setAttribute("theme", theme);
			document.documentElement.setAttribute("data-bs-theme", theme);
			TirThemeManager.setTheme(theme.toString());
		}
	}
}

export const ThemeContext = React.createContext<{
	theme: THEME;
	setTheme: Function;
}>({
	theme: initialized ? ThemeManager.getTheme() : THEME.light,
	setTheme: ThemeManager.setTheme,
});
