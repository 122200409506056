/** This file defines the GeoMapView React component.  The GeoMapView React component wraps a 
 *  map component and includes the query to get the data.  The Table React component renders a 
 *  the map.
 *  @module */
import { useState, useRef, useEffect } from "react";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade.tsx";
import type { SeverityScore, BaseRunbookViewProps } from "pages/riverbed-advisor/views/runbook-view/Runbook.type.ts";
import { Link as LinkBetweenSites } from "components/reporting/geomap/Link.tsx";
import { LocationMarker } from "components/reporting/geomap/LocationMarker.tsx";
import { INDEX_TO_SEVERITY_MAP, SEVERITY, SEVERITY_INDEX } from "components/enums/Severity.ts";
import { SIZE } from "components/enums/Sizes.ts";
import { getWorstStatus } from "reporting-infrastructure/utils/commonUtils";
import { IconNames } from "@tir-ui/react-components";
import { IconTitle } from "components/common/icon-title/IconTitle.tsx";
import { CustomIconNames, StatusLED } from "components/common/status-led/StatusLED.tsx";
import { STRINGS } from "app-strings";
import { RoundedLinkButton } from "components/common/rounded-link-button/RoundedLinkButton.tsx";
import { HealthSeverityToParamMap } from "components/common/health-summary-led/HealthSummaryLED.tsx";
import { HealthSummaryCombo } from "components/common/health-summary-combo/HealthSummaryCombo.tsx";
import type { Position } from 'geojson';
import { useQueryParams } from "utils/hooks/useQueryParams";
import _ from 'lodash';
import { Popover, PopoverInteractionKind, PopoverPosition } from "@blueprintjs/core";
import "./GeoMapView.scss";
import { overlaysQueryPerSiteOverlays, siteDetailsQueryPerSiteSiteApps, siteDetailsQueryPerSiteSiteHosts, siteDetailsQueryPerSiteSiteOverlays, siteListQuerySites } from "./GeoMapData.tsx";

interface LinkObj {
    id: string,
    site1: { id: number | string, name: string },
    site2: { id: number | string, name: string },
    overlays: Array<any>,
    worstState: string,
    countsByState: { [x: string]: number }
}

let _hideTooltipTimer;
let toolTipContext:{type: string, value: string}|null = null;
    
/** an interface that describes the properties that can be passed in to the component.*/
export interface GeoMapViewProps extends BaseRunbookViewProps {
    /** a String with the id of the column that is the trigger.*/
    triggerColumnId?: string;
    /** one of the enumerated severities with the severity of the trigger. */
    triggerSeverity?: SeverityScore;
    /** array of available columns. */
    columns?: any;
    /** sort column id and order. */
    sortBy?: Array<{ id: string; desc?: boolean;}>;
    /** a boolean value which, if true, specifies that selections should be be displayed. */
    showSelections?: boolean;
}

/** Creates the table view, which is a component that displays one table with analytics data.
 *  @param props an object with the properties passed to the table view.
 *  @returns JSX with the table component.*/
export const GeoMapView = (props: GeoMapViewProps): JSX.Element => {
    // These were all props
    const [selectedSite, setSelectedSite] = useState<any>("101");
    const [hoveredSite] = useState<string|number|null>(null);
    const [selectedLink, setSelectedLink] = useState<any>(null);
    const minLinkSeverityToShow: SEVERITY | undefined = SEVERITY.UNKNOWN;
    const centerToSite: string | undefined = "101";

    const { params, setQueryParams } = useQueryParams({
        listenOnlyTo: ["lat", "lng", "zoom"]
    });
    const paramsRef = useRef(params);
    paramsRef.current = params;

    const lastLatLngSetFromMap = useRef<any>(null);
    // Pass forceSyncBack to true only when we want to update the URL and have the map update as a result of this update.
    // In cases like syncing the action of user dragging the map to a new center point, we want it to be one-directional.
    // We don't want to trigger a fly-to on the map for the zoom and center values from the URL in that case.
    const updateZoomLatLongInURL = function (zoom, lat, lng, forceSyncBack = false) {
        const params = { zoom, lat, lng };
        if (forceSyncBack) {
            lastLatLngSetFromMap.current = null;
        } else {
            lastLatLngSetFromMap.current = params;
        }
        //alert("set query params");
        setQueryParams(params);
    }
    const getZoomLatLongFromURLifApplicable = function () {
        const centerFromQuery = params.lat && params.lng ? { lat: params.lat, lng: params.lng } : undefined,
            center:[number, number] | undefined = (centerFromQuery && centerFromQuery.lng && centerFromQuery.lat) ? [ Number(centerFromQuery.lng), Number(centerFromQuery.lat) ] : undefined,
            // If center value was available but no zoom, then default to 2. If both center and zoom aren't available, then set zoom to undefined
            zoomInQuery = params.zoom ? Number(params.zoom) : (center ? 2 : undefined);

        if (center && zoomInQuery) {
            if (lastLatLngSetFromMap.current === null || lastLatLngSetFromMap.current.zoom !== zoomInQuery || lastLatLngSetFromMap.current.lng !== center[0] || lastLatLngSetFromMap.current.lat !== center[1]) {
                return {
                    center: center,
                    zoom: zoomInQuery
                };
            } else {
                return undefined;
            }
        } else {
            return /*{zoom:2, center: [-69.675107, 42.917416]}*/ {
                useBounds: true
            };
        }
    }

    let sites = siteListQuerySites, /*siteListQuery.data && siteListQuery.data.sites ? siteListQuery.data.sites.nodes || [] : [],*/
        centerAndZoom: any = getZoomLatLongFromURLifApplicable(),
        center: any = centerAndZoom?.center,
        zoom = centerAndZoom?.zoom;

    const [sitesMap, setSitesMap] = useState({});
    const [links, setLinks] = useState<{[x:string]: LinkObj}>({});
    const [hoveredOverSiteID, setHoveredOverSiteID] = useState<string|number|null>(null);
    const [hoveredOverLinkID, setHoveredOverLinkID] = useState<string|null>(null);
    const [toolTipContent, setToolTipContent] = useState(<div/>);
    const [toolTipVisible, setToolTipVisible] = useState(false);
    const toolTipVisibleRef = useRef(toolTipVisible);
    toolTipVisibleRef.current = toolTipVisible;
    const [toolTipPosition, setToolTipPosition] = useState({left: "0px", top: "0px"});
    const linksRef = useRef(links);
    linksRef.current = links;

    const selectedSiteRef = useRef(selectedSite /*props.selectedSite*/);
    selectedSiteRef.current = selectedSite; //props.selectedSite;

    function getSite (siteID:string|number) {
        return sitesMap[siteID];
    }

    function getLink (linkKey:string) {
        return linksRef.current[linkKey];
    }

    function getSiteLocation (siteID:string|number):[number, number] | null {
        let siteLocation:any = null,
            site = sitesMap[siteID];
        if (site && site.location?.latitude && site.location?.longitude) {
            siteLocation = [site.location.longitude, site.location.latitude];
        }
        return siteLocation;
    }
    const getSiteLocationRef = useRef(getSiteLocation);
    getSiteLocationRef.current = getSiteLocation;

    useEffect(() => {
        if (centerToSite /*props.centerToSite*/) {
            // Get lat, long for the site to center to
            const coordinates = getSiteLocationRef.current(centerToSite /*props.centerToSite*/);
            if (coordinates) {
                const minZoomToShowSite = 8;
                const currentZoom = paramsRef.current.zoom ? Number(paramsRef.current.zoom) : null;
                // If current zoom is already greater than minZoomToShowSite value, don't zoom in further. If not, zoom to it.
                const newZoom = currentZoom && currentZoom > minZoomToShowSite ? currentZoom : minZoomToShowSite;
                setQueryParams({ zoom: newZoom, lng: coordinates[0], lat: coordinates[1] });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [centerToSite]);

    useEffect(() => {
        let siteMap = {};
        for (const siteObj of sites) {
            let site = Object.assign({}, siteObj);
            siteMap[site.id] = site;
            // if (site.hosts && site.hosts.length > 0) {
            //     const [firstHost] = site.hosts;
            //     if (firstHost.location && firstHost.location.longitude && firstHost.location.latitude) {
            //         site.location = [firstHost.location.longitude, firstHost.location.latitude];
            //     }
            // }
            site.links = {};
            site._linksRetrieved = false;
        }
        setSitesMap(siteMap);
        // If sites data got updated because of some reason such as time change, then clear the cached links.
        setLinks({});
        if (selectedSite /*props.selectedSite*/) {
            // Trigger a fresh fetch of overlay data if a site was in selected status so that the displayed overlays get updated.
            
            
            //fetchOverlayData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteListQuerySites]);

    useEffect(() => {
        // This was not in the original
        if (Object.keys(sitesMap)?.length === 0) {
            return;
        }
        const siteID = hoveredOverSiteID || selectedSite; //sitesDetails?.id;
        let siteMap = Object.assign({}, sitesMap),
            overlays: any[] = overlaysQueryPerSiteOverlays[siteID] /*overlaysQuery.data?.overlays?.nodes*/ || [],
            // Copy the 'links' object, add newly fetched link information and set it back in the state variable 
            // so that we don't throw away the previously fetched link information
            linksMap = Object.assign({}, links),
            siteMapUpdated = false,
            updatedSiteID;

        for (let overlay of overlays) {
            const localSite: any = overlay.local?.site,
                remoteSite: any = overlay.remote?.site,
                localSiteID = localSite?.id,
                remoteSiteID = remoteSite?.id;
            
            if (localSiteID && remoteSiteID) {
                const linkID = localSiteID > remoteSiteID ? remoteSiteID + "-" + localSiteID : localSiteID + "-" + remoteSiteID;
                if (linksMap[linkID] === undefined) {
                    linksMap[linkID] = {
                        id: linkID,
                        site1: localSite,
                        site2: remoteSite,
                        overlays: [],
                        worstState: SEVERITY.UNKNOWN,
                        countsByState: {}
                    }
                    if (siteMap[localSiteID]) {
                        siteMap[localSiteID].links[linkID] = linksMap[linkID];
                        if (!siteMap[localSiteID]._linksRetrieved) {
                            siteMapUpdated = true;
                            siteMap[localSiteID]._linksRetrieved = true;
                            updatedSiteID = localSiteID;
                        }
                    }
                    if (siteMap[remoteSiteID]) {
                        siteMap[remoteSiteID].links[linkID] = linksMap[linkID];
                    }
                }
                const link = linksMap[linkID];
                if (!link.overlays.find(o => overlay.id === o.id)) {
                    link.overlays.push(overlay);
                    const overlaySeverity = overlay.severity?.value || SEVERITY.UNKNOWN;
                    if (!link.countsByState[overlaySeverity]) {
                        link.countsByState[overlaySeverity] = 0;
                    }
                    link.countsByState[overlaySeverity]++;
                }
            }
        }

        for (const linkID in linksMap) {
            let link = linksMap[linkID],
                worstState = SEVERITY.UNKNOWN;

            for (const overlay of link.overlays) {
                if (
                    (overlay.severity?.value === SEVERITY.CRITICAL) ||
                    (overlay.severity?.value === SEVERITY.DEGRADED && worstState !== SEVERITY.CRITICAL) ||
                    (overlay.severity?.value === SEVERITY.NORMAL&& worstState !== SEVERITY.CRITICAL && worstState !== SEVERITY.DEGRADED)

                ) {
                    worstState = overlay.severity?.value;
                    if (overlay.severity?.value === SEVERITY.CRITICAL) {
                        break;
                    }
                }
            }
            link.worstState = worstState;
        }
        // Set links in it's state variable after processing all overlays data
        setLinks(linksMap);
        if (!_.isEqual(linksMap, links)) {
            setTimeout(() => { setToolTipVisible(true); setToolTipVisible(false); }, 100);
        }
        if (siteMapUpdated) {
            setSitesMap(sitesMap);
            populateTooltipForSite(updatedSiteID);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [/*overlaysQuery.data,*/ hoveredOverSiteID, selectedSite, sitesMap, setSitesMap, setLinks]);

    useEffect(() => {
        // This was down below
        const siteID = hoveredOverSiteID || selectedSite; //sitesDetails?.id;
        //const [sitesDetails]: any[] = siteDetailsQueryPerSiteSiteDetails[siteID] /*siteDetailsQuery?.data?.sitesDetails?.nodes*/ || [];
        // const siteID = sitesDetails?.id;
        if (siteID && sitesMap[siteID]) {
            // *************************** These queries were per site id
            const overlays: any[] = siteDetailsQueryPerSiteSiteOverlays[siteID] || [];//siteDetailsQuery?.data?.overlays?.nodes;
            const hosts: any[] = siteDetailsQueryPerSiteSiteHosts[siteID] || []; //siteDetailsQuery?.data?.hosts?.nodes;
            const apps: any[] = siteDetailsQueryPerSiteSiteApps[siteID] || []; //siteDetailsQuery?.data?.applications?.nodes;
            const details = {
                overlays: overlays && {
                    count: overlays.length,
                    status: getWorstStatus(overlays.map(item => item.severity))
                },
                devices: hosts && {
                    count: hosts.length,
                    status: getWorstStatus(hosts.map(item => item.severity))
                },
                apps: apps && {
                    count: apps.length,
                    status: getWorstStatus(apps.map(item => item.severity))
                },
            };
            let siteMap = Object.assign({}, sitesMap);
            siteMap[siteID].details = details;
            setSitesMap(sitesMap);
            populateTooltipForSite(siteID);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [/*siteDetailsQuery.data,*/ hoveredOverSiteID, selectedSite, sitesMap, setSitesMap]);

    function onMapUnmounted () {
        lastLatLngSetFromMap.current = null;
    }

    function onMapClicked () {
        //let preventDefault = false;
        // Links and markers are sometimes hard to click on. So I'm adding this logic to make it easier with a little margin for error.
        // When user hovers over an item, a tooltip will be displayed which will get hidden after a slight timeout delay.
        // This delay should be good enough to make sure the user is still intending to click on that element. We do that by
        // looking at the tooltip's context and triggering the respective click instead of a map click.
        if (toolTipVisibleRef.current && toolTipContext !== null) {
            if (toolTipContext.type === "site") {
                handleMarkerClick({ id: toolTipContext.value });
                //preventDefault = true;
            } else if (toolTipContext.type === "link") {
                handleLinkClick({ id: toolTipContext.value });
                //preventDefault = true;
            }
        }
        //if (!preventDefault && props.onMapClicked) {
        //    props.onMapClicked();
        //}
    }

    function isSameLink(link1, link2) {
        let same = false;
        if (link1 === link2) {
            same = true;
        } else if (link1 && link2) {
            const [link1site1, link1site2] = link1.split("-");
            const [link2site1, link2site2] = link2.split("-");
            if (link1site1 && link1site2 && link2site1 && link2site2 && link1site1 === link2site2 && link1site2 === link2site1) {
                same = true;
            }
        }
        return same;
    }

    function populateTooltipForSite (siteID?) {
        const site = getSite(siteID);
        if (site) {
            let worstConnectedSiteSeverity = SEVERITY.UNKNOWN;
            if (site._linksRetrieved && site.links) {
                worstConnectedSiteSeverity = getWorstStatus(Object.values(site.links).map((link:any) => {
                    const connectedSite = getSite(link.site2?.id);
                    return connectedSite?.severity || SEVERITY.UNKNOWN;
                }));
            }
            const content = <div className="text-nowrap text-muted d-inline-block w-min-2 mx-3 mt-3 mb-2">
                <IconTitle size={SIZE.s} icon={IconNames.SITE} status={SEVERITY[site.severity?.value]}
                    bold={true}
                    title={<></>/*<SiteLabel {...site}/>*/}
                />
                <StatusLED icon={CustomIconNames.roundedBar} status={worstConnectedSiteSeverity} className="display-9 d-block mt-2 ms-4"
                    label={ STRINGS.connected + " " + STRINGS.site_plural + " (" + 
                        (site._linksRetrieved ? Object.keys(site.links).length : STRINGS.loadingTextIndicator) + ")" }
                />
                <StatusLED icon={CustomIconNames.roundedBar} status={site.details?.overlays?.status || SEVERITY.UNKNOWN}
                    label={STRINGS.overlay_plural + " (" + (site.details?.overlays?.count || STRINGS.loadingTextIndicator) + ")"}
                    className="display-9 d-block mt-1 ms-4"/>
                <StatusLED icon={CustomIconNames.roundedBar} status={site.details?.devices?.status || SEVERITY.UNKNOWN}
                    label={STRINGS.device_plural + " (" + (site.details?.devices?.count || STRINGS.loadingTextIndicator) + ")"}
                    className="display-9 d-block mt-1 ms-4"/>
                <StatusLED icon={CustomIconNames.roundedBar} status={site.details?.apps?.status || SEVERITY.UNKNOWN}
                    label={STRINGS.apps + " (" + (site.details?.apps?.count || STRINGS.loadingTextIndicator) + ")"}
                    className="display-9 d-block mt-1 ms-4"/>
                <RoundedLinkButton className="mt-2" size={SIZE.s} text={STRINGS.viewDetails} onClick={() => handleMarkerClick({ id: siteID })}/>
            </div>;
            setToolTipContent(content);
        }
    }

    function populateTooltipForLink (linkID?) {
        const link:LinkObj = getLink(linkID);
        if (link) {
            const site1name = "add-back-site-formatter"; //getSiteName(link.site1);
            const site2name = "add-back-site-formatter"; //getSiteName(link.site2);
            const nameInfo = (selectedSite /*props.selectedSite*/ && link.site2.id === selectedSite /*props.selectedSite*/) ?
                site2name + " - " + site1name :
                site1name + " - " + site2name;

            let healthSummaryProps = {};
            for (const severity in link.countsByState) {
                healthSummaryProps[HealthSeverityToParamMap[severity]] = link.countsByState[severity];
            }
                            
            // TBD: Switch to using standard component
            const content = <div className="text-nowrap text-muted d-inline-block w-min-3 m-2">
                <IconTitle size={SIZE.s} icon={IconNames.OVERLAY} status={SEVERITY[link.worstState]}
                    bold={true}
                    title={link.overlays.length + " " + STRINGS.overlay_plural}
                    subText={nameInfo}
                />
                <HealthSummaryCombo size={SIZE.s} {...healthSummaryProps} className="my-2"/>
                <RoundedLinkButton size={SIZE.s} text={STRINGS.view + " " + STRINGS.overlay_plural} onClick={() => handleLinkClick(link)}/>
            </div>;
            setToolTipContent(content);
        }
    }

    function populateTooltipForSiteCluster (data) {
        if (data) {
            const properties = data?.clusterProperties || {};
            const healthSummaryProps = {
                [HealthSeverityToParamMap[SEVERITY.UNKNOWN]]: properties.unknownCount,
                [HealthSeverityToParamMap[SEVERITY.NORMAL]]: properties.normalCount,
                [HealthSeverityToParamMap[SEVERITY.DEGRADED]]: properties.degradedCount,
                [HealthSeverityToParamMap[SEVERITY.CRITICAL]]: properties.criticalCount,
            };
            // TBD: Switch to using standard component
            const content = <div className="text-nowrap text-muted d-inline-block w-min-2 m-2">
                <IconTitle size={SIZE.s} icon={IconNames.OVERLAY} status={SEVERITY[INDEX_TO_SEVERITY_MAP[properties.worstSeverity]] || SEVERITY.UNKNOWN}
                    bold={true}
                    title={(properties.point_count || STRINGS.loadingTextIndicator) + " " + STRINGS.site_plural}
                />
                <HealthSummaryCombo size={SIZE.s} {...healthSummaryProps} className="my-2"/>
            </div>;
            setToolTipContent(content);
        }
    }

    let minLat = 90,
        maxLat = -90,
        minLng = 180,
        maxLng = -180,
        bounds:any;
    const siteLocationMarkers = Object.keys(sitesMap).length > 0 ? sites.map(site => {
        const coordinate = getSiteLocation(site.id);
        if (coordinate) {
            let lat = Number(coordinate[1]);
            let lng = Number(coordinate[0]);
            if (minLat > lat) {
                minLat = lat;
            }
            if (maxLat < lat) {
                maxLat = lat;
            }
            if (minLng > lng) {
                minLng = lng;
            }
            if (maxLng < lng) {
                maxLng = lng;
            }
            // The AzureMapFeature within location marker is a memoized component. To trigger an update during a select/unselect operation,
            // I am updating the key. Looks like a change in value of properties object isn't good enough.
            const siteSelected = site.id === selectedSite /*props.selectedSite*/;
            const siteHovered = site.id === hoveredOverSiteID || (/*props.hoveredSite*/ hoveredSite !== undefined && site.id === hoveredSite /*props.hoveredSite*/);
            return <LocationMarker key={site.id} id={site.id} coordinate={coordinate} properties={{
                severity: SEVERITY[site.severity?.value] || SEVERITY.UNKNOWN,
                selected: siteSelected,
                hovered: siteHovered,
                important: true //site.importance === SITE_IMPORTANCE.PRIORITY,
            }}/>
        } else {
            return undefined;
        }
    }).filter(site => site !== undefined) : [];

    // If center or zoom didn't come from the URL, then pass bounds array to position the map to show all sites in view
    if (minLat !== 0 && maxLat !== 0 && minLng !== 0 && maxLng !== 0) {
        bounds = [
            minLng - 5 < -180 ? -180 : minLng - 5, // TBD: If menu is open, we might have to subtract menu width
            minLat - 5 < -90 ? -90 : minLat - 5,
            maxLng + 5 > 180 ? 180 : maxLng + 5,
            maxLat + 15 > 90 ? 90 : maxLat + 15 // Giving a bit more space on top for the floating cards
        ];
    }
    let linksOnMap:any = undefined;
    
    //const selectedSite = "100";
    if (selectedSite/*props.selectedSite*/ || minLinkSeverityToShow /*props.minLinkSeverityToShow*/) {
        let linksToShow = Object.values(links);
        let minLinkSeverityIndex = SEVERITY_INDEX[/*props.minLinkSeverityToShow*/ minLinkSeverityToShow || SEVERITY.UNKNOWN];
        if (selectedSite /*props.selectedSite*/) {
            //linksToShow = linksToShow.filter(link => (!props.selectedSite || props.selectedSite === link.site1.id || props.selectedSite === link.site2.id));
            linksToShow = linksToShow.filter(link => (!selectedSite || selectedSite === link.site1.id || selectedSite === link.site2.id));
        } else if (minLinkSeverityIndex > 0) {
            linksToShow = linksToShow.filter(link => ((SEVERITY_INDEX[link.worstState] || SEVERITY_INDEX.UNKNOWN) < minLinkSeverityIndex));
        }
        let maxOverlaysOnLink = 0;
        for (const link of linksToShow) {
            if (link.overlays.length > maxOverlaysOnLink) {
                maxOverlaysOnLink = link.overlays.length;
            }
        }
        linksOnMap = linksToShow.map(link => {
            const worstState = link.worstState,
                //selected = props.selectedSite !== undefined && (link.site1.id === props.selectedSite || link.site2.id === props.selectedSite),
                selected = selectedSite !== undefined && (link.site1.id === selectedSite || link.site2.id === selectedSite),
                thisLinkSelected = isSameLink(selectedLink /*props.selectedLink*/, link.id),
                linkRelativeThickness = Math.ceil(link.overlays.length / (maxOverlaysOnLink/4)),
                worstStateIndex = SEVERITY_INDEX[worstState] || SEVERITY_INDEX.UNKNOWN,
                // Show link if it is selected or if worst state of link is greater than min severity level
                //showLink = selected || (!props.selectedSite && worstStateIndex >= minLinkSeverityIndex),
                showLink = selected || (!selectedSite && worstStateIndex >= minLinkSeverityIndex),
                coordinateSite1 = getSiteLocation(link.site1.id),
                coordinateSite2 = getSiteLocation(link.site2.id),
                linkHovered = link.id === hoveredOverLinkID;

            let coordinates:Position[] = [];
            if (coordinateSite1 !== null) {
                coordinates.push(coordinateSite1);
            }
            if (coordinateSite2 !== null) {
                coordinates.push(coordinateSite2);
            }
            return showLink && coordinates.length === 2 && <LinkBetweenSites
                id={link.id}
                key={link.id}
                coordinates={coordinates}
                properties={{
                    severity: SEVERITY[worstState],
                    selected: thisLinkSelected,
                    hovered: linkHovered,
                    width: linkRelativeThickness
                }}
            />;
        }).filter(feature => !!feature)
    }
    
    const lastChangeWasUnique = useRef(false);
    const handlePositionChange = (data) => {
        const zoomChanged = data.zoom !== Number(paramsRef.current.zoom);
        const positionChanged = data.center?.lat !== Number(paramsRef.current.lat) && data.center?.lng !== Number(paramsRef.current.lng);
        if (zoomChanged && positionChanged && !lastChangeWasUnique.current) {
            lastChangeWasUnique.current = true;
        }
        // params.lat, params.lng, params.zoom
        updateZoomLatLongInURL(data.zoom, data.center.lat, data.center.lng, false);
    };

    const handleMarkerClick = (data) => {
/*
        if (props.onSelectedSiteChange) {
            props.onSelectedSiteChange(data?.id);
        }
*/
        setSelectedSite(data?.id);
    };

    const handleLinkClick = (data) => {
        // There are some rare instances when click on a link takes precedence over the marker when user attempts to click the marker.
        // To prevent this from happening, if a link click is detected while the site tooltip is currently being displayed,
        // then consider it as a site click instead.
        if (toolTipVisibleRef.current && toolTipContext?.type === "site") {
            handleMarkerClick({ id: toolTipContext.value });
        } else {
            /*
            if (props.onSelectedLinkChange) {
                let linkID = data?.id;
                if (linkID) {
                    // Arrange local-remote site IDs in order based on which site is currently selected
                    // because overlay query returns different severities while fetching same set of
                    // overlays with siteId filter passed as local site vs remote site.
                    const [site1, site2] = data?.id.split("-");
                    if (site1 && site2 && site2 === selectedSiteRef.current) {
                        linkID = site2 + "-" + site1;
                    }
                }
                props.onSelectedLinkChange(linkID);
            }
            */
            let linkID = data?.id;
            if (linkID) {
                // Arrange local-remote site IDs in order based on which site is currently selected
                // because overlay query returns different severities while fetching same set of
                // overlays with siteId filter passed as local site vs remote site.
                const [site1, site2] = data?.id.split("-");
                if (site1 && site2 && site2 === selectedSiteRef.current) {
                    linkID = site2 + "-" + site1;
                }
            }
            setSelectedLink(linkID);
        }
    };

    const handleClusterClick = (data) => {
        /*
        if (props.onSiteClusterClick && data.leaves && data.leaves.length > 0) {
            const siteIDs = data.leaves.map(siteLeaf => siteLeaf.properties.id);
            props.onSiteClusterClick(siteIDs);

            // Calculate center for the cluster of sites
            let avglat = 0,
                avglng = 0,
                count = 0;
            for (const leaf of data.leaves) {
                const [lng, lat] = leaf.coordinate || [];
                if (lng && lat) {
                    avglat += Number(lat);
                    avglng += Number(lng);
                    count++;
                }
            }
            if (count) {
                avglat = avglat / count;
                avglng = avglng / count;
            }
            if (avglat && avglng) {
                const newZoom = Number(paramsRef.current.zoom || 3) + 4;
                updateZoomLatLongInURL(newZoom, avglat, avglng, true);
            }
        }
        */
        //const siteIDs = data.leaves.map(siteLeaf => siteLeaf.properties.id);
        //props.onSiteClusterClick(siteIDs);

        // Calculate center for the cluster of sites
        let avglat = 0,
            avglng = 0,
            count = 0;
        for (const leaf of data.leaves) {
            const [lng, lat] = leaf.coordinate || [];
            if (lng && lat) {
                avglat += Number(lat);
                avglng += Number(lng);
                count++;
            }
        }
        if (count) {
            avglat = avglat / count;
            avglng = avglng / count;
        }
        if (avglat && avglng) {
            const newZoom = Number(paramsRef.current.zoom || 3) + 4;
            updateZoomLatLongInURL(newZoom, avglat, avglng, true);
        }
    }

    function onMouseEnterSite (data) {
        const siteID = data.id;
        const site = getSite(siteID);
        if (site) {
            setHoveredOverSiteID(siteID);
            populateTooltipForSite(siteID);
            setToolTipPosition({ left: (data.originalEvent.center.x + 5) + "px", top: (data.originalEvent.center.y + 10) + "px" });
            toolTipContext = { type: "site", value: siteID };
            showTooltip();
        }
    }

    function onMouseEnterSiteCluster (data) {
        const siteMarkerList = data?.leaves;
        if (siteMarkerList && siteMarkerList.length > 0) {
            populateTooltipForSiteCluster(data);
            setToolTipPosition({ left: (data.originalEvent.center.x + 5) + "px", top: (data.originalEvent.center.y + 10) + "px" });
            toolTipContext = null; // Set cluster information when cluster click becomes supported
            showTooltip();
        }
    }

    function onMouseEnterLink (data) {
        const linkID = data.id;
        const link = getLink(linkID);
        if (link) {
            setHoveredOverLinkID(linkID);
            populateTooltipForLink(linkID);
            setToolTipPosition({ left: (data.originalEvent.center.x + 5) + "px", top: (data.originalEvent.center.y + 10) + "px" });
            toolTipContext = { type: "link", value: linkID };
            showTooltip();
        }
    }

    function onMouseLeaveFeature (e) {
        setHoveredOverSiteID(null);
        setHoveredOverLinkID(null);
        _hideTooltipTimer = setTimeout(hideTooltip, 500);
    }

    function onMouseEnterTooltip () {
        if (_hideTooltipTimer) {
            clearTimeout(_hideTooltipTimer);
            _hideTooltipTimer = undefined;
        }
    }

    function onMouseLeaveTooltip () {
        hideTooltip();
    }

    function showTooltip () {
        if (_hideTooltipTimer) {
            clearTimeout(_hideTooltipTimer);
            _hideTooltipTimer = undefined;
        }
        setToolTipVisible(true);
    }

    function hideTooltip () {
        setToolTipContent(<div/>);
        toolTipContext = null;
        setToolTipVisible(false);
    }

    return <>
        <div className="position-fixed geomap-tooltip" style={toolTipPosition} onMouseEnter={onMouseEnterTooltip} onMouseLeave={onMouseLeaveTooltip}>
            <Popover usePortal={false} content={toolTipContent} isOpen={toolTipVisible} minimal={true} position={PopoverPosition.BOTTOM} interactionKind={PopoverInteractionKind.HOVER_TARGET_ONLY}>
                <div/>
            </Popover>
        </div>
        <DataLoadFacade loading={/*loading ||*/ props.loading} error={/*error ||*/ props.error} data={true /*|| data*/}>
    </DataLoadFacade></>;
};
