/** This module contains the SearchApiService that can be used to query the cognitive search
 *      service.
 *  @module
 */
import { ApiService } from 'utils/services/ApiService.ts';
import type { TIME_DURATION, TIME_RANGE } from "utils/stores/GlobalTimeStore.ts";

// The URL for the API server.
export const SEARCH_URL = '/api/affogato/search/1.0/';

/** this interface defines the object returned by a search or suggest query.  The Generic 
 *      search result object takes as a parameter the type of search result item is returned. */
export interface SearchResult<T> {
    /** a string with the odata context. */
    "@odata.context": string;
    /** a number with the odata count.  This is only available for search and not suggest.  This is in the cognitive search
     *  results. */
    "@odata.count"?: number;
    /** a number with the odata count.  This is only available for search and not suggest. */
    /** a number with the odata count.  This is only available for search and not suggest.  This is in the correlation search
     *  results. */
    count?: number;
    /** an object with the facets if faceting is enabled.  If it is not enabled it will not exist.  This is in the 
     *  cognitive search results. */
    "@search.facets"?: Record<string, Array<Facet>>;
    /** an object with the facets if faceting is enabled.  If it is not enabled it will not exist.  This is in the 
     *  correlation search results. */
    facets?: Record<string, FacetSummary>;
    /** an Array of SuggestItems with all the items returned by the suggest request.  This is in the cognitive search 
     *  results. */
    value?: Array<T>;
    /** an Array of SuggestItems with all the items returned by the suggest request.  This is in the correlation search 
     *  results. */
    items?: Array<T>;
}

/** this interface defines the properties in a facet. */
export interface Facet {
    /** a String with the facet value.  You might think of this as the label. */
    value: FacetValue;
    /** a number with the facet count. */
    count: number;
    /** a boolean that is true if the facet is currently selected. */
    selected: boolean;
    /** is part of the groupedFacet return type */
    isGroupedFacet?: boolean;
}

/** this interface defines the properties in a facet summary.  The facet summary is returned by the correlation engine. */
export interface FacetSummary {
    /** a number with the total number of facets in this category. */
    count: number;
    /** the array of Facets with the list of facets. */
    items: Facet[];
    /** is part of the groupedFacet return type */
    isGroupedFacet?: boolean;
}

/** this type defines the facet value type. */
export type FacetValue = string | number | boolean;

/** this interface defines that object that is the base for all the search items. */
export interface BaseSearchItem {
    /** a String with the id of the object returned. */
    id: string;
    /** a String with the type of object that has been returned. */
    type: string;
}

/** this interface defines the object that is returned in the search value array for a suggest
 *  search. */
export interface SuggestItem extends BaseSearchItem {
    /** a string with the search result with the search text visible. */
    "@search.text": string;
}

/** this interface defines the object that is returned in the search value array for a search
 *  search. */
 export interface SearchItem extends BaseSearchItem {
    /** a string with the search result with the search text visible. */
    "@search.score": number;
    /** a string that specifies the name of the search item. */
    name?: string;
    /** a string that specifies the description of the search item. */
    description?: string;
    /** a string with the entity uuid.  This should be the uuid from the metadata service. */
    uuid?: string,
    /** the ip address of the device or interface. */
    ipaddr?: string;
    /** a number with the interface ifindex. */
    ifIndex?: number;
    /** the ifDescription attribute from the interface metadata. */
    ifDescription?: string | null,
    /** the ifAlias attribute from the interface metadata. */
    ifAlias?: string | null,
    /** an array containing the reporting application name. */
    reportedBy?: Array<{dataSourceType: string}> | undefined;
    /** an object which has the location information. */
    location?: {
        name: string;
        id: string
    };
    /** an array with the users that were impacted. */
    impactedUsers?: Array<{
        name: string,
        ipAddr: string
    }>;
    /** an array with the applications that were impacted. */
    impactedApplications?: Array<{
        name: string
    }>;
    /** an array with the locations that were impacted. */
    impactedLocations?: Array<{
        name: string
    }>;
    /** the array of indicator mappings. */
    indicatorMappings?: Array<any>;
    /** the value for the incident event category field. */
    eventCategory?: string;
}

/** this interface is the base interface for all search requests. */
export interface BaseSearchRequest {
    /** a String with the search text. */
    search: string | RegExp;
    /** The list of comma-separated field names to search for the specified search text. Target fields must be listed in the 
     *  Suggesters definition in the index. */
    searchFields?: string | Array<FIELDS>;
    /** a number that specifies the number of results to return.  Right now it must be between 1 and 100.  For searches the max is 
     *  1000, if you put a number larger than 1000 it only return the first 1000 and paginates the rest. */
    top?: number;
    /** a String that specifies the markup to use to show the start of the search text. */
    highlightPostTag?: string;
    /** a String that specifies the markup to use to show the end of the search text. */
    highlightPreTag?: string;
    /** a string with the fields that are returned with the result.  If this is omitted all fields are returned.  Here is an example
     *      that returns the type, id and application fields "type, id, application". */
    select?: string | Array<FIELDS>;
    /** a string with the orderby clause of the type "field asc" or "field desc". */
    orderby?: string | OrderExpression;
}

/** this interface defines the suggest request. */
export interface SuggestRequest extends BaseSearchRequest {
    /** a boolean value, if true the search service will find matches that might have been the result of a typo, if 
     *  false it will only return exact substring matches.  For example if fuzzy is true and the user types "bos", then
     *  the word "bottom" and "boston" might be returned, but if fuzzy is false only "boston" will be returned. */
    fuzzy?: boolean;
    /** the filter which specifies which fields to search. */
    filter?: string | Array<FIELDS>;
}

/** Variables necessary to retrieve a facet, works with `facets` and
 * `groupedFacets` */
export type FacetVariables = {
    name: string;
    skip: number;
    top: number;
    count: boolean;
    groupBy?: string;
    key?: string;
};

export type DalOptions = {
    timeRange?: TIME_RANGE | Partial<TIME_RANGE & TIME_DURATION>;
    isGroupedFacet?: boolean;
    // TODO: may not be necessary
    hasParams?: {
        hasGroupedFacetParam: boolean;
        hasFacetParam: boolean;
        currentParams: any;
    };
};

type OrderBy = {
    /** field in which to order the search results by */
    field: string;
    /** either ascending or descending */
    order: ORDERS;
}

/** this interface defines the search request. */
export interface SearchRequest extends BaseSearchRequest {
    /** a boolean value, if true return a count of the results. */
    count?: boolean;
    /** Valid values are "simple" or "full". Defaults to "simple".
     *  "simple" interprets query strings using the simple query syntax that allows for symbols such as +, * and "". 
     *      Queries are evaluated across all searchable fields (or fields indicated in searchFields) in each document by default.
     *  "full" interprets query strings using the full Lucene query syntax which allows field-specific and weighted searches. Range 
     *      search in the Lucene query language is not supported in favor of $filter which offers similar functionality. */
    queryType?: QUERY_TYPE;
    /** the filter which specifies which fields to search. */
    filter?: string | Array<FIELDS>;
    /** Optional. Valid values are "any" or "all" Defaults to "any". Specifies whether any or all of the search terms must be matched 
     *  in order to count the document as a match. */
    searchMode?: SEARCH_MODE;
    /** Optional.  The array of facet fields.  These are the field on which faceting is enabled. */
    facets?: Array<FACET_FIELDS | string | FacetVariables>;
    /** the number of incidents to skip when paginating. */
    skip?: number;
    /** a string with the type of item that is being searched for. */
    type?: string;
    orderBy?: OrderBy;
    /** options for the graphql DAL queries, often used with the selected facets
     * on the explorer type pages */
    dalOptions?: DalOptions;
}

/** the enumerated values for the query types, examples are "simple" and "full". */
export enum QUERY_TYPE {
    /** the enumerated value for the simple query type, this is the default for cognitive search. */
    simple  = "simple",
    /** the enumerated value for the full query type. */
    full    = "full"
}

/** the enumerated values for the search mode. */
export enum SEARCH_MODE {
    /** the enumerated value for the any search mode, this is the default for cognitive search. */
    any = "any",
    /** the enumerated value for the all search mode. */
    all = "all"
}

/** this interface defines the order expression object. */
export interface OrderExpression {
    /** one of the enumerated fields that is used in the order expression. */
    field: FIELDS;
    /** one of the orders, asc or desc to specify whether the order is ascending or descending. */
    order: ORDERS;
}

/** this enum defines the valid search fields. */
export enum FIELDS {
    /** the enumerated value for the type field. */
    type                                = "type",
    /** the enumerated value for the id field. */
    id                                  = "id",
    /** the enumerated value for the name field. */
    name                                  = "name",
    /** the enumerated value for the application field. */
    application                         = "application",
    /** the enumerated value for the location field. */
    location                            = "location",
    /** the enumerated value for the network device field. */
    network_device                      = "network_device",
    /** the enumerated value for the network interface field. */
    network_interface                   = "network_interface",
    /** the enumerated value for the impactedUser field. */
    impactedUser                        = "impactedUser",
    /** the enumerated value for the impactedUser name field. */
    impactedUserName                    = "impactedUser/name",
    /** the enumerated value for the impactedUser ip address field. */
    impactedUserIpaddr                  = "impactedUser/ipaddr",
    /** the enumerated value for the impactedLocation field. */
    impactedLocation                    = "impactedLocation",
    /** the enumerated value for the impactedLocation name field. */
    impactedLocationName                = "impactedLocation/name",
    /** the enumerated value for the impactedApplication field. */
    impactedApplication                 = "impactedApplication",
    /** the enumerated value for the impactedApplication name field. */
    impactedApplicationName             = "impactedApplication/name",
    /** the enumerated value for the reportedBy field.  This field tells which point product reported the data. */
    devReportedBy                       = "network_device/reportedBy",
    /** the enumerated value for the device ip address. */
    devIpaddr                           = "network_device/ipaddr",
    /** the enumerated value for the device hostname. */
    devHostname                         = "network_device/hostname",
    /** the enumerated value for the device name. */
    devName                             = "network_device/name",
    /** the enumerated value for the device location name. */
    devLocName                          = "network_device/location/name",
    /** the enumerated value for the reportedBy field.  This field tells which point product reported the data. */
    ifcReportedBy                       = "network_interface/reportedBy",
    /** the enumerated value for the interface ip address. */
    ifcIpaddr                           = "network_interface/ipaddr",
    /** the enumerated value for the interface ifindex. */
    ifcIfindex                          = "network_interface/ifindex",
    /** the enumerated value for the interface hostname. */
    ifcHostname                         = "network_interface/hostname",
    /** the enumerated value for the interface name. */
    ifcName                             = "network_interface/name",
    /** the enumerated value for the interface location name. */
    ifcLocName                          = "network_interface/location/name",
    /** the enumerated value for the reportedBy field.  This field tells which point product reported the data. */
    appReportedBy                       = "application/reportedBy",
    /** the enumerated value for the application name. */
    appName                             = "application/name",
    /** the enumerated value for the reportedBy field.  This field tells which point product reported the data. */
    locReportedBy                       = "location/reportedBy",
    /** the enumerated value for the location name. */
    locName                             = "location/name",
    /** the enumerated value for the incident field. */
    incident                            = "incident",
    /** the enumerated value for the incident id field. */
    incidentId                          = "incident/id",
    /** the enumerated value for the incident description field. */
    incidentDesc                        = "incident/description",
    /** the enumerated value for the incident priority field. */
    incidentPriority                    = "incident/priority",
    /** the enumerated value for the incident status field. */
    incidentStatus                      = "incident/status",
    /** the enumerated value for the incident generated field. */
    incidentGen                         = "incident/generated",
    /** the enumerated value for the incident end time field. */
    incidentEndTime                     = "incident/endTime",
    /** the enumerated value for the incident last updated field. */
    incidentLastUpdated                 = "incident/lastUpdated",
    /** the enumerated value for the incident event category field. */
    incidentEventCategory               = "incident/eventCategory",
    /** the enumerated value for the incident triggeredOn field. */
    incidentTriggeredOn                 = "incident/triggeredOn",
    /** the enumerated value for the incident triggeredOn kind field. */
    incidentTriggeredOnKind             = "incident/triggeredOn/kind",
    /** the enumerated value for the incident triggeredOn interface name field. */
    incidentTriggeredOnIfcName          = "incident/triggeredOn/network_interface/name",
    /** the enumerated value for the incident triggeredOn interface ip address field. */
    incidentTriggeredOnIfcIpAddr        = "incident/triggeredOn/network_interface/ipaddr",
    /** the enumerated value for the incident triggeredOn interface ifindex field. */
    incidentTriggeredOnIfcIfindex       = "incident/triggeredOn/network_interface/ifindex",
    /** the enumerated value for the incident triggeredOn interface location name field. */
    incidentTriggeredOnIfcLocName       = "incident/triggeredOn/network_interface/location/name",
    /** the enumerated value for the incident triggeredOn device name field. */
    incidentTriggeredOnDevName          = "incident/triggeredOn/network_device/name",
    /** the enumerated value for the incident triggeredOn device ip address field. */
    incidentTriggeredOnDevIpAddr        = "incident/triggeredOn/network_device/ipaddr",
    /** the enumerated value for the incident triggeredOn device location name field. */
    incidentTriggeredOnDevLocName       = "incident/triggeredOn/network_device/location/name",
    /** the enumerated value for the incident triggeredOn application name field. */
    incidentTriggeredOnAppName          = "incident/triggeredOn/application/name",
    /** the enumerated value for the incident triggeredOn application name field. */
    incidentTriggeredOnAppLocAppName    = "incident/triggeredOn/application_location/application/name",
    /** the enumerated value for the incident triggeredOn application name field. */
    incidentTriggeredOnAppLocLocName    = "incident/triggeredOn/application_location/location/name",

    /** the enumerated value for the incident correlatedIndicators kind field. */
    incidentCorrIndKind                 = "incident/correlatedIndicators/kind",

    /** the enumerated value for the incident correlatedIndicators interface name field. */
    incidentCorrIndIfcName              = "incident/correlatedIndicators/network_interface/name",
    /** the enumerated value for the incident correlatedIndicators interface ip address field. */
    incidentCorrIndIfcIpAddr            = "incident/correlatedIndicators/network_interface/ipaddr",
    /** the enumerated value for the incident correlatedIndicators interface ifindex field. */
    incidentCorrIndIfcIfindex           = "incident/correlatedIndicators/network_interface/ifindex",
    /** the enumerated value for the incident correlatedIndicators interface ifalias field. */
    incidentCorrIndIfcIfAlias           = "incident/correlatedIndicators/network_interface/ifalias",
    /** the enumerated value for the incident correlatedIndicators interface ifdescr field. */
    incidentCorrIndIfcIfDescr           = "incident/correlatedIndicators/network_interface/ifdescr",
    /** the enumerated value for the incident correlatedIndicators interface location name field. */
    incidentCorrIndIfcLocName           = "incident/correlatedIndicators/network_interface/location/name",

    /** the enumerated value for the incident correlatedIndicators device name field. */
    incidentCorrIndDevName              = "incident/correlatedIndicators/network_device/name",
    /** the enumerated value for the incident correlatedIndicators device ip address field. */
    incidentCorrIndDevIpAddr            = "incident/correlatedIndicators/network_device/ipaddr",
    /** the enumerated value for the incident correlatedIndicators device is_gateway field. */
    incidentCorrIndDevGateway           = "incident/correlatedIndicators/network_device/is_gateway",
    /** the enumerated value for the incident correlatedIndicators device hostname field. */
    incidentCorrIndDevHostname          = "incident/correlatedIndicators/network_device/hostname",
    /** the enumerated value for the incident correlatedIndicators device location name field. */
    incidentCorrIndDevLocName           = "incident/correlatedIndicators/network_device/location/name",

    /** the enumerated value for the incident correlatedIndicators location name field. */
    incidentCorrIndLocName              = "incident/correlatedIndicators/location/name",
    /** the enumerated value for the incident correlatedIndicators location type field. */
    incidentCorrIndLocType              = "incident/correlatedIndicators/location/type",
    /** the enumerated value for the incident correlatedIndicators location geo city field. */
    incidentCorrIndLocGeoCity           = "incident/correlatedIndicators/location/geo/city",
    /** the enumerated value for the incident correlatedIndicators location geo state field. */
    incidentCorrIndLocGeoState          = "incident/correlatedIndicators/location/geo/state",
    /** the enumerated value for the incident correlatedIndicators location geo country field. */
    incidentCorrIndLocGeoCountry        = "incident/correlatedIndicators/location/geo/country",

    /** the enumerated value for the incident correlatedIndicators application_location application name field. */
    incidentCorrIndAppLocAppName        = "incident/correlatedIndicators/application_location/application/name",
    /** the enumerated value for the incident correlatedIndicators application_location location name field. */
    incidentCorrIndAppLocLocName        = "incident/correlatedIndicators/application_location/location/name",

    /** the enumerated value for the incident indicators kind field. */
    incidentIndKind                     = "incident/indicators/kind",
    /** the enumerated value for the incident indicators entity kind field. */
    incidentIndEntityKind               = "incident/indicators/entity/kind",

    /** the enumerated value for the incident indicators interface name field. */
    incidentIndEntityIfcName            = "incident/indicators/entity/network_interface/name",
    /** the enumerated value for the incident indicators interface ip address field. */
    incidentIndEntityIfcIpAddr          = "incident/indicators/entity/network_interface/ipaddr",
    /** the enumerated value for the incident indicators interface ifindex field. */
    incidentIndEntityIfcIfindex         = "incident/indicators/entity/network_interface/ifindex",
    /** the enumerated value for the incident indicators interface ifalias field. */
    incidentIndEntityIfcIfAlias         = "incident/indicators/entity/network_interface/ifalias",
    /** the enumerated value for the incident indicators interface ifdescr field. */
    incidentIndEntityIfcIfDescr         = "incident/indicators/entity/network_interface/ifdescr",
    /** the enumerated value for the incident indicators interface location name field. */
    incidentIndEntityIfcLocName         = "incident/indicators/entity/network_interface/location/name",

    /** the enumerated value for the incident indicators device name field. */
    incidentIndEntityDevName            = "incident/indicators/entity/network_device/name",
    /** the enumerated value for the incident indicators device ip address field. */
    incidentIndEntityDevIpAddr          = "incident/indicators/entity/network_device/ipaddr",
    /** the enumerated value for the incident indicators device is_gateway field. */
    incidentIndEntityDevGateway         = "incident/indicators/entity/network_device/is_gateway",
    /** the enumerated value for the incident indicators device hostname field. */
    incidentIndEntityDevHostname        = "incident/indicators/entity/network_device/hostname",
    /** the enumerated value for the incident indicators device location name field. */
    incidentIndEntityDevLocName         = "incident/indicators/entity/network_device/location/name",

    /** the enumerated value for the incident indicators application_location application name field. */
    incidentIndEntityAppLocAppName      = "incident/indicators/entity/application_location/application/name",
    /** the enumerated value for the incident indicators application_location location name field. */
    incidentIndEntityAppLocLocName      = "incident/indicators/entity/application_location/location/name",

    /** the enumerated value for the incident impactedApplications field. */
    incidentImpactApp                   = "incident/impactedApplications",
    /** the enumerated value for the incident impactedUsers field. */
    incidentImpactUser                  = "incident/impactedUsers",
    /** the enumerated value for the incident impactedLocations field. */
    incidentImpactLoc                   = "incident/impactedLocations",

    /** the enumerated value for the incident priority field. */
    incidentsPriority                   = "incidents/priority",
    /** the enumerated value for the incident status field. */
    incidentsDesc                       = "incidents/description",
    incidentsStatus                     = "incidents/status",
    incidentsIsOngoing                  = "incidents/isOngoing",
    incidentsEventCategory              = "incidents/eventCategory",
    incidentsIndicatorsDevName          = "incidents/incidentIndicators/indicator/networkDevice/name",
    incidentsIndicatorsDevIp            = "incidents/incidentIndicators/indicator/networkDevice/ipaddr",
    incidentsIndicatorsDevLocName       = "incidents/incidentIndicators/indicator/networkDevice/location/name",
    incidentsIndicatorsIfcName          = "incidents/incidentIndicators/indicator/networkInterface/name",
    incidentsIndicatorsIfcIp            = "incidents/incidentIndicators/indicator/networkInterface/ipaddr",
    incidentsIndicatorsIfcLocName       = "incidents/incidentIndicators/indicator/networkInterface/location/name",
    incidentsIndicatorsAppLocAppName    = "incidents/incidentIndicators/indicator/applicationLocation/application/name",
    incidentsIndicatorsAppLocLocName    = "incidents/incidentIndicators/indicator/applicationLocation/location/name",
    incidentsImpactedLocName            = "incidents/impactedLocations/name",
    incidentsImpactedAppName            = "incidents/impactedApplications/name",
    incidentsImpactedUserIp             = "incidents/impactedUsers/ipaddr",
    incidentsImpactedUserName           = "incidents/impactedUsers/name",

    DESCRIPTION                         = "DESCRIPTION",
    PRIORITY                            = "PRIORITY",
    STATUS                              = "STATUS",
    EVENT_CATEGORY                      = "EVENT_CATEGORY",
    INTERFACE_NAME                      = "INTERFACE_NAME",
    INTERFACE_IP_ADDRESS                = "INTERFACE_IP_ADDRESS",
    INTERFACE_LOCATION_NAME             = "INTERFACE_LOCATION_NAME",
    DEVICE_NAME                         = "DEVICE_NAME",
    DEVICE_IP_ADDRESS                   = "DEVICE_IP_ADDRESS",
    DEVICE_LOCATION_NAME                = "DEVICE_LOCATION_NAME",
    APPLICATION_NAME                    = "APPLICATION_NAME",
    APPLICATION_LOCATION_NAME           = "APPLICATION_LOCATION_NAME",
    IMPACTED_USER_NAME                  = "IMPACTED_USER_NAME",
    IMPACTED_LOCATION                   = "IMPACTED_LOCATION",
    IMPACTED_APPLICATION                = "IMPACTED_APPLICATION",

    NAME                                = "NAME",

    TYPE                                = "TYPE",
    CITY                                = "CITY",
    STATE                               = "STATE",
    COUNTRY                             = "COUNTRY",

    IP_ADDRESS                          = "IP_ADDRESS",
    LOCATION_NAME                       = "LOCATION_NAME",
    HOSTNAME                            = "HOSTNAME",

    IF_INDEX                            = "IF_INDEX",
    IF_ALIAS                            = "IF_ALIAS",
    IF_DESCRIPTION                      = "IF_DESCRIPTION",

    DEVICE_HOSTNAME                     = "DEVICE_HOSTNAME",
    DEVICE_TYPE                         = "DEVICE_TYPE",
    DEVICE_SERIAL_NUMBER                = "DEVICE_SERIAL_NUMBER",
    DEVICE_ELEMENT_TYPE                 = "DEVICE_ELEMENT_TYPE",
    DEVICE_OS_VERSION                   = "DEVICE_OS_VERSION",
    DEVICE_MODEL                        = "DEVICE_MODEL",
    DEVICE_VENDOR                       = "DEVICE_VENDOR",

    SERIAL_NUMBER                       = "SERIAL_NUMBER",
    ELEMENT_TYPE                        = "ELEMENT_TYPE",
    OS_VERSION                          = "OS_VERSION",
    MODEL                               = "MODEL",
    VENDOR                              = "VENDOR",

    VALUE_NAME                          = "VALUE_NAME",

    DATA_SOURCE_TYPE_NAME               = "DATA_SOURCE_TYPE_NAME",
    DATA_SOURCE_ENTITY_NAME             = "DATA_SOURCE_ENTITY_NAME",
    MERGED_APPLICATIONS_NAME            = "MERGED_APPLICATIONS_NAME",
    MERGED_LOCATIONS_NAME               = "MERGED_LOCATIONS_NAME",

    OS = "OS",

    //cloud assets
    SERVICE = "SERVICE",
    REGION = "REGION",
    PROVIDER = "PROVIDER",
    TAG = "TAG",
    OWNER = "OWNER",
    CLOUD_IM_ENTITY_KIND = "CLOUD_IM_ENTITY_KIND",
    CLOUD_IM_SOURCE_TYPE = "CLOUD_IM_SOURCE_TYPE",
    HEALTH = "HEALTH",
    LIVENESS = "LIVENESS",

    //cloud rules
    RULE_NAME = "RULE_NAME",
    RULE_ID = "RULE_ID",
    LAST_UPDATE = "LAST_UPDATED", 
    ENABLED = "ENABLED",
    RULE_TYPE = "RULE_TYPE",
    TAGS = "TAGS",
    USER = "USER",

    // runbook analyses
    RUNBOOK_NAME = "NAME",
    TITLE = "TITLE",
    ENTITY_TYPE = "ENTITY_TYPE",
    VARIANT = "VARIANT",
    EXECUTION_METHOD = "EXECUTION_METHOD",
    CREATED_BY = "CREATED_BY",

    // schedules for ondemand runbooks
    schedulesRunbook = "RUNBOOK",	
    schedulesRunbookName = "NAME",
    schedulesScheduleDescription = "SCHEDULES_DESCRIPTION",	
    schedulesSchedule = "SCHEDULES_SCHEDULE",	
    schedulesFrequency = "SCHEDULES_FREQUENCY",
    schedulesInputs	= "SCHEDULES_INPUTS",
    schedulesCreatedBy = "SCHEDULES_CREATED_BY",
    schedulesCreatedOn = "SCHEDULES_CREATED_ON",
    schedulesLastUpdatedBy = "SCHEDULES_LAST_UPDATED_BY",
    schedulesLastUpdated = "SCHEDULES_LAST_UPDATED",	
    schedulesNextRun = "nextRunUtc",
    schedulesEnabled = "SCHEDULES_ENABLED",

    /** the enumerated value for the triggeredOn field. */
    triggeredOn                         = "triggeredOn",
    /** the enumerated value for the triggeredOn kind field. */
    triggeredOnKind                     = "triggeredOn/kind",
    /** the enumerated value for the triggeredOn network_interface field. */
    triggeredOnIfc                      = "triggeredOn/network_interface",
    /** the enumerated value for the triggeredOn network_interface name field. */
    triggeredOnIfcName                  = "triggeredOn/network_interface/name",
    /** the enumerated value for the triggeredOn network_interface ipaddr field. */
    triggeredOnIfcIpaddr                = "triggeredOn/network_interface/ipaddr",
    /** the enumerated value for the triggeredOn network_interface location name field. */
    triggeredOnIfcLocName               = "triggeredOn/network_interface/location/name",
    /** the enumerated value for the triggeredOn network_device field. */
    triggeredOnDev                      = "triggeredOn/network_device",
    /** the enumerated value for the triggeredOn network_device name field. */
    triggeredOnDevName                  = "triggeredOn/network_device/name",
    /** the enumerated value for the triggeredOn network_device ipaddr field. */
    triggeredOnDevIpaddr                = "triggeredOn/network_device/ipaddr",
    /** the enumerated value for the triggeredOn network_device location name field. */
    triggeredOnDevLocName               = "triggeredOn/network_device/location/name",
    /** the enumerated value for the triggeredOn application field. */
    triggeredOnApp                      = "triggeredOn/application",
    /** the enumerated value for the triggeredOn application name field. */
    triggeredOnAppName                  = "triggeredOn/application/name",
    /** the enumerated value for the triggeredOn application field. */
    triggeredOnAppLoc                   = "triggeredOn/application_location",
    /** the enumerated value for the triggeredOn application name field. */
    triggeredOnAppLocAppName            = "triggeredOn/application_location/application/name",
    /** the enumerated value for the triggeredOn application name field. */
    triggeredOnAppLocLocName            = "triggeredOn/application_location/location/name",

    /** the enumerated value for the indicators kind field. */
    indicator                           = "indicator",
    /** the enumerated value for the indicators kind field. */
    indicatorKind                       = "indicator/kind",
    /** the enumerated value for the indicators entity kind field. */
    indicatorEntityKind                 = "indicator/entity/kind",
    /** the enumerated value for the indicators interface name field. */
    indicatorEntityIfcName              = "indicator/entity/network_interface/name",
    /** the enumerated value for the indicators interface ip address field. */
    indicatorEntityIfcIpAddr            = "indicator/entity/network_interface/ipaddr",
    /** the enumerated value for the indicators interface ifindex field. */
    indicatorEntityIfcIfindex           = "indicator/entity/network_interface/ifindex",
    /** the enumerated value for the indicators interface ifalias field. */
    indicatorEntityIfcIfAlias           = "indicator/entity/network_interface/ifalias",
    /** the enumerated value for the indicators interface ifdescr field. */
    indicatorEntityIfcIfDescr           = "indicator/entity/network_interface/ifdescr",
    /** the enumerated value for the indicators interface location name field. */
    indicatorEntityIfcLocName           = "indicator/entity/network_interface/location/name",

    /** the enumerated value for the indicators device name field. */
    indicatorEntityDevName              = "indicator/entity/network_device/name",
    /** the enumerated value for the indicators device ip address field. */
    indicatorEntityDevIpAddr            = "indicator/entity/network_device/ipaddr",
    /** the enumerated value for the indicators device is_gateway field. */
    indicatorEntityDevGateway           = "indicator/entity/network_device/is_gateway",
    /** the enumerated value for the indicators device hostname field. */
    indicatorEntityDevHostname          = "indicator/entity/network_device/hostname",
    /** the enumerated value for the indicators device location name field. */
    indicatorEntityDevLocName           = "indicator/entity/network_device/location/name",

    /** the enumerated value for the indicators application_location application name field. */
    indicatorEntityAppLocAppName        = "indicator/entity/application_location/application/name",
    /** the enumerated value for the indicators application_location location name field. */
    indicatorEntityAppLocLocName        = "indicator/entity/application_location/location/name",

    /** the enumerated value for the device ip address. */
    devsIpaddr                          = "networkDevices/ipaddr",
    /** the enumerated value for the device hostname. */
    devsHostname                        = "networkDevices/hostname",
    /** the enumerated value for the device name. */
    devsName                            = "networkDevices/name",
    devsType                            = "networkDevices/type",
    /** the enumerated value for the device location name. */
    devsLocName                         = "networkDevices/location/name",
    /** the enumerated value for the interface ip address. */
    ifcsIpaddr                          = "networkInterfaces/ipaddr",
    /** the enumerated value for the interface ifindex. */
    ifcsIfindex                         = "networkInterfaces/ifindex",
    /** the enumerated value for the interface name. */
    ifcsName                            = "networkInterfaces/name",
    /** the enumerated value for the interface location name. */
    ifcsLocName                         = "networkInterfaces/location/name",

    appsName                            = "applications/name",

    locsName                            = "locations/name",
    locsType                            = "locations/type",
    locsCity                            = "locations/city",
    locsState                           = "locations/state",
    locsCountry                         = "locations/country",

    propName                            = "customProperties/name",
    propDesc                            = "customProperties/description",
    propUser                            = "customProperties/userId",
    propValue                           = "customProperties/customPropertyValues/name",
    propType                            = "customProperties/customPropertyAssociations/type",
}

export enum FACET_FIELDS {
    /** the enumerated value for the incident priority field. */
    incidentPriority                    = "incident/priority",
    /** the enumerated value for the incident status field. */
    incidentStatus                      = "incident/status",
    /** the enumerated value for the incident event category field. */
    incidentEventCategory               = "incident/eventCategory",
    /** the enumerated value for the incident triggeredOn kind field. */
    incidentTriggeredOnKind             = "incident/triggeredOn/kind",
    /** the enumerated value for the triggeredOn network_device name field. */
    incidentTriggeredOnDevName          = "incident/triggeredOn/network_device/name",
    /** the enumerated value for the incident triggeredOn device ip address field. */
    incidentTriggeredOnDevIpAddr        = "incident/triggeredOn/network_device/ipaddr",
    /** the enumerated value for the incident triggeredOn device location name field. */
    incidentTriggeredOnDevLocName       = "incident/triggeredOn/network_device/location/name",
    /** the enumerated value for the triggeredOn network_interface name field. */
    incidentTriggeredOnIfcName          = "incident/triggeredOn/network_interface/name",
    /** the enumerated value for the incident triggeredOn interface ip address field. */
    incidentTriggeredOnIfcIpAddr        = "incident/triggeredOn/network_interface/ipaddr",
    /** the enumerated value for the incident triggeredOn interface ifindex field. */
    incidentTriggeredOnIfcIfindex       = "incident/triggeredOn/network_interface/ifindex",
    /** the enumerated value for the incident triggeredOn interface location name field. */
    incidentTriggeredOnIfcLocName       = "incident/triggeredOn/network_interface/location/name",
    /** the enumerated value for the incident triggeredOn application name field. */
    incidentTriggeredOnAppLocAppName    = "incident/triggeredOn/application_location/application/name",
    /** the enumerated value for the incident triggeredOn application name field. */
    incidentTriggeredOnAppLocLocName    = "incident/triggeredOn/application_location/location/name",

    /** the enumerated value for the incident correlatedIndicators kind field. */
    incidentCorrIndKind                 = "incident/correlatedIndicators/kind",

    /** the enumerated value for the incident correlatedIndicators interface name field. */
    incidentCorrIndIfcName              = "incident/correlatedIndicators/network_interface/name",
    /** the enumerated value for the incident correlatedIndicators interface ip address field. */
    incidentCorrIndIfcIpAddr            = "incident/correlatedIndicators/network_interface/ipaddr",
    /** the enumerated value for the incident correlatedIndicators interface ifindex field. */
    incidentCorrIndIfcIfindex           = "incident/correlatedIndicators/network_interface/ifindex",
    /** the enumerated value for the incident correlatedIndicators interface location name field. */
    incidentCorrIndIfcLocName           = "incident/correlatedIndicators/network_interface/location/name",

    /** the enumerated value for the incident correlatedIndicators device name field. */
    incidentCorrIndDevName              = "incident/correlatedIndicators/network_device/name",
    /** the enumerated value for the incident correlatedIndicators device ip address field. */
    incidentCorrIndDevIpAddr            = "incident/correlatedIndicators/network_device/ipaddr",
    /** the enumerated value for the incident correlatedIndicators device location name field. */
    incidentCorrIndDevLocName           = "incident/correlatedIndicators/network_device/location/name",

    /** the enumerated value for the incident correlatedIndicators application_location application name field. */
    incidentCorrIndAppLocAppName        = "incident/correlatedIndicators/application_location/application/name",
    /** the enumerated value for the incident correlatedIndicators application_location location name field. */
    incidentCorrIndAppLocLocName        = "incident/correlatedIndicators/application_location/location/name",

    /** the enumerated value for the incident indicators kind field. */
    incidentIndKind                     = "incident/indicators/kind",
    /** the enumerated value for the incident indicators entity kind field. */
    incidentIndEntityKind               = "incident/indicators/entity/kind",

    /** the enumerated value for the incident indicators interface name field. */
    incidentIndEntityIfcName            = "incident/indicators/entity/network_interface/name",
    /** the enumerated value for the incident indicators interface ip address field. */
    incidentIndEntityIfcIpAddr          = "incident/indicators/entity/network_interface/ipaddr",
    /** the enumerated value for the incident indicators interface ifindex field. */
    incidentIndEntityIfcIfindex         = "incident/indicators/entity/network_interface/ifindex",
    /** the enumerated value for the incident indicators interface location name field. */
    incidentIndEntityIfcLocName         = "incident/indicators/entity/network_interface/location/name",

    /** the enumerated value for the incident indicators device name field. */
    incidentIndEntityDevName            = "incident/indicators/entity/network_device/name",
    /** the enumerated value for the incident indicators device ip address field. */
    incidentIndEntityDevIpAddr          = "incident/indicators/entity/network_device/ipaddr",
    /** the enumerated value for the incident indicators device hostname field. */
    incidentIndEntityDevHostname        = "incident/indicators/entity/network_device/hostname",
    /** the enumerated value for the incident indicators device location name field. */
    incidentIndEntityDevLocName         = "incident/indicators/entity/network_device/location/name",

    /** the enumerated value for the incident indicators application_location application name field. */
    incidentIndEntityAppLocAppName      = "incident/indicators/entity/application_location/application/name",
    /** the enumerated value for the incident indicators application_location location name field. */
    incidentIndEntityAppLocLocName      = "incident/indicators/entity/application_location/location/name",

    /** the enumerated value for the incident priority field. */
    incidentsPriority                   = "incidents/priority",
    /** the enumerated value for the incident status field. */
    incidentsStatus                     = "incidents/status",
    incidentsIsOngoing                  = "incidents/isOngoing",
    incidentsEventCategory              = "incidents/eventCategory",
    incidentsIndicatorsDevName          = "incidents/incidentIndicators/indicator/networkDevice/name",
    incidentsIndicatorsDevIp            = "incidents/incidentIndicators/indicator/networkDevice/ipaddr",
    incidentsIndicatorsDevLocName       = "incidents/incidentIndicators/indicator/networkDevice/location/name",
    incidentsIndicatorsIfcName          = "incidents/incidentIndicators/indicator/networkInterface/name",
    incidentsIndicatorsIfcIp            = "incidents/incidentIndicators/indicator/networkInterface/ipaddr",
    incidentsIndicatorsIfcLocName       = "incidents/incidentIndicators/indicator/networkInterface/location/name",
    incidentsIndicatorsAppLocAppName    = "incidents/incidentIndicators/indicator/applicationLocation/application/name",
    incidentsIndicatorsAppLocLocName    = "incidents/incidentIndicators/indicator/applicationLocation/location/name",
    incidentsImpactedLocName            = "incidents/impactedLocations/name",
    incidentsImpactedAppName            = "incidents/impactedApplications/name",
    incidentsImpactedUserIp             = "incidents/impactedUsers/ipaddr",
    incidentsImpactedUserName           = "incidents/impactedUsers/name",

    DESCRIPTION                         = "DESCRIPTION",
    COMPLETION_STATUS                   = "COMPLETION_STATUS",
    PRIORITY                            = "PRIORITY",
    STATUS                              = "STATUS",
    EVENT_CATEGORY                      = "EVENT_CATEGORY",
    INTERFACE_NAME                      = "INTERFACE_NAME",
    INTERFACE_IP_ADDRESS                = "INTERFACE_IP_ADDRESS",
    INTERFACE_LOCATION_NAME             = "INTERFACE_LOCATION_NAME",
    DEVICE_NAME                         = "DEVICE_NAME",
    DEVICE_IP_ADDRESS                   = "DEVICE_IP_ADDRESS",
    DEVICE_LOCATION_NAME                = "DEVICE_LOCATION_NAME",
    APPLICATION_NAME                    = "APPLICATION_NAME",
    APPLICATION_LOCATION_NAME           = "APPLICATION_LOCATION_NAME",
    IMPACTED_USER_NAME                  = "IMPACTED_USER_NAME",
    IMPACTED_USER_IP_ADDRESS            = "IMPACTED_USER_IP_ADDRESS",
    IMPACTED_LOCATION                   = "IMPACTED_LOCATION",
    IMPACTED_APPLICATION                = "IMPACTED_APPLICATION",

    NAME                                = "NAME",

    TYPE                                = "TYPE",
    CITY                                = "CITY",
    STATE                               = "STATE",
    COUNTRY                             = "COUNTRY",

    IP_ADDRESS                          = "IP_ADDRESS",
    LOCATION_NAME                       = "LOCATION_NAME",
    HOSTNAME                            = "HOSTNAME",

    IF_INDEX                            = "IF_INDEX",
    IF_ALIAS                            = "IF_ALIAS",
    IF_DESCRIPTION                      = "IF_DESCRIPTION",
    INBOUND_SPEED                       = "INBOUND_SPEED",
    OUTBOUND_SPEED                      = "OUTBOUND_SPEED",

    DEVICE_HOSTNAME                     = "DEVICE_HOSTNAME",
    DEVICE_TYPE                         = "DEVICE_TYPE",
    DEVICE_SERIAL_NUMBER                = "DEVICE_SERIAL_NUMBER",
    DEVICE_ELEMENT_TYPE                 = "DEVICE_ELEMENT_TYPE",
    DEVICE_OS_VERSION                   = "DEVICE_OS_VERSION",
    DEVICE_MODEL                        = "DEVICE_MODEL",
    DEVICE_VENDOR                       = "DEVICE_VENDOR",
    DEVICE_IS_GATEWAY                   = "DEVICE_IS_GATEWAY",

    SERIAL_NUMBER                       = "SERIAL_NUMBER",
    ELEMENT_TYPE                        = "ELEMENT_TYPE",
    OS_VERSION                          = "OS_VERSION",
    MODEL                               = "MODEL",
    VENDOR                              = "VENDOR",
    IS_GATEWAY                          = "IS_GATEWAY",

    VALUE_NAME                          = "VALUE_NAME",
    ENTITY_KIND                         = "ENTITY_KIND",

    OS = "OS",

    //cloud assets
    SERVICE = "SERVICE",
    REGION = "REGION",
    PROVIDER = "PROVIDER",
    TAG = "TAG",
    OWNER = "OWNER",
    CLOUD_IM_ENTITY_KIND = "CLOUD_IM_ENTITY_KIND",
    CLOUD_IM_SOURCE_TYPE = "CLOUD_IM_SOURCE_TYPE",
    HEALTH = "HEALTH",
    LIVENESS = "LIVENESS",

    //cloud rules
    RULE_NAME = "RULE_NAME",
    RULE_ID = "RULE_ID",
    LAST_UPDATE = "LAST_UPDATED", 
    RULE_TYPE = "RULE_TYPE",
    TAGS = "TAGS",
    USER = "USER",

    // runbook analyses
    RUNBOOK_NAME = "NAME",
    TITLE = "TITLE",
    ENTITY_TYPE = "ENTITY_TYPE",
    VARIANT = "VARIANT",
    EXECUTION_METHOD = "EXECUTION_METHOD",
    CREATED_BY = "CREATED_BY",

    /** the enumerated value for the incident impactedApplications field. */
    incidentImpactApp                   = "incident/impactedApplications",
    /** the enumerated value for the incident impactedUsers field. */
    incidentImpactUser                  = "incident/impactedUsers",
    /** the enumerated value for the incident impactedLocations field. */
    incidentImpactLoc                   = "incident/impactedLocations",

    /** the enumerated value for the device ip address. */
    devIpaddr                           = "network_device/ipaddr",
    /** the enumerated value for the device hostname. */
    devHostname                         = "network_device/hostname",
    /** the enumerated value for the device name. */
    devName                             = "network_device/name",
    /** the enumerated value for the device location name. */
    devLocName                          = "network_device/location/name",
    /** the enumerated value for the interface ip address. */
    ifcIpaddr                           = "network_interface/ipaddr",
    /** the enumerated value for the interface ifindex. */
    ifcIfindex                          = "network_interface/ifindex",
    /** the enumerated value for the interface name. */
    ifcName                             = "network_interface/name",
    /** the enumerated value for the interface location name. */
    ifcLocName                          = "network_interface/location/name",




    /** the enumerated value for the device ip address. */
    devsIpaddr                          = "networkDevices/ipaddr",
    /** the enumerated value for the device hostname. */
    devsHostname                        = "networkDevices/hostname",
    /** the enumerated value for the device name. */
    devsName                            = "networkDevices/name",
    devsType                            = "networkDevices/type",
    /** the enumerated value for the device location name. */
    devsLocName                         = "networkDevices/location/name",
    /** the enumerated value for the interface ip address. */
    ifcsIpaddr                          = "networkInterfaces/ipaddr",
    /** the enumerated value for the interface ifindex. */
    ifcsIfindex                         = "networkInterfaces/ifindex",
    /** the enumerated value for the interface name. */
    ifcsName                            = "networkInterfaces/name",
    /** the enumerated value for the interface location name. */
    ifcsLocName                         = "networkInterfaces/location/name",

    appsName                            = "applications/name",

    /** the enumerated value for the location name. */
    locName                             = "location/name",
    locsName                            = "locations/name",
    locsType                            = "locations/type",
    locsCity                            = "locations/city",
    locsState                           = "locations/state",
    locsCountry                         = "locations/country",

    propName                            = "customProperties/name",
    propDesc                            = "customProperties/description",
    propUser                            = "customProperties/userId",
    propValue                           = "customProperties/customPropertyValues/name",
    propType                            = "customProperties/customPropertyAssociations/type",

    /** the enumarated valuse for schedules list */
    SCHEDULE                            = "SCHEDULE",
    FREQUENCY                           = "FREQUENCY",
    ENABLED                             = "ENABLED"
}

/** this enum defines the valid order values for an order expression. */
export enum ORDERS {
    /** the enumerated value for an ascending orderby. */
    asc     = "asc",
    /** the enumerated value for an descending orderby. */
    desc    = "desc",
}

export interface SearchLocation {
    name: string;
    uuid: string;
}

export interface SearchApplication {
    name: string;
    uuid: string;
}

export interface SearchDevice {
    name: string;
    hostname: string;
    ipaddr: string;
    location: SearchLocation;
    uuid: string;
}

export interface SearchInterface {
    name: string;
    hostname: string;
    ipaddr: string;
    ifindex: number;
    location: SearchLocation;
    uuid: string;
}

/** this class defines the SearchApiService. */
class SearchApiService extends ApiService {
    /** the constructor for the class. */
    constructor() {
        const baseUri = SEARCH_URL;
        super(baseUri)
    }

    /** returns the base uri, this can be overridden in subclasses to allow the uri to change
     *      after construction.
     *  @returns a String with the base uri. */
    protected getBaseUri(): string {
        if (ApiService.USE_REGION) {
            const region = ApiService.AUTH_SERVICE.getRegion();
            return `/api/iq/${region}/search/1.0/`;    
        } else {
            return this.baseApiUri;
        }
    }

    /** runs a suggest query using a post with the suggest request.
     *  @param suggestRequest the object with the suggest request.
     *  @returns a Promise which resolves to the returned SearchResult. */
    suggest(suggestRequest: SuggestRequest): Promise<SearchResult<SuggestItem>> {
        return new Promise((resolve, reject) => {
            const request: SuggestRequest = Object.assign({}, suggestRequest);

            // We have an array of fields so create the selected statement for the user.
            if (Array.isArray(request.select)) {
                request.select = request.select.join(", ");
            }

            // We have an array of fields so create the searchFields statement for the user.
            if (Array.isArray(request?.searchFields)) {
                request.searchFields = request.searchFields.join(", ");
            }

            // We have an order by object so create the order by statement for the user.
            if (typeof request?.orderby === "object") {
                request.orderby = `${request.orderby.field} ${request.orderby.order}`;
                //alert(request.orderby);
            }

            // We have a field so create the field statement for the user.
            if (Array.isArray(request.filter)) {
                request.filter = "search.in(type, '" + request.filter.join(",") + "', ',')";
                //alert(request.filter);
            }

            const tenantId = ApiService.AUTH_SERVICE.getTenantId();
            super.post<SearchResult<SuggestItem>>(`tenants/${tenantId}/suggest`, request).then((response: SearchResult<SuggestItem>) => {
                resolve(response);
            }, err => {
                reject(err);
                console.error(err);
            });
        });
    }

    /** runs a search query using a post with the search request.
     *  @param searchRequest the object with the search request.
     *  @returns a Promise which resolves to the returned SearchResult. */
    search(searchRequest: SearchRequest): Promise<SearchResult<SearchItem>> {
        return new Promise((resolve, reject) => {
            const request: SearchRequest = Object.assign({}, searchRequest);

            // We have an array of fields so create the selected statement for the user.
            if (Array.isArray(request.select)) {
                request.select = request.select.join(", ");
            }

            // We have an array of fields so create the searchFields statement for the user.
            if (Array.isArray(request?.searchFields)) {
                request.searchFields = request.searchFields.join(", ");
            }

            // We have an order by object so create the order by statement for the user.
            if (typeof request?.orderby === "object") {
                request.orderby = `${request.orderby.field} ${request.orderby.order}`;
                //alert(request.orderby);
            }

            // We have a field so create the field statement for the user.
            if (Array.isArray(request.filter)) {
                request.filter = "search.in(type, '" + request.filter.join(",") + "', ',')";
                //alert(request.filter);
            }

            const tenantId = ApiService.AUTH_SERVICE.getTenantId();
            super.post<SearchResult<SearchItem>>(`tenants/${tenantId}/search`, request).then((response: SearchResult<SearchItem>) => {
                resolve(response);
            }, err => {
                reject(err);
                console.error(err);
            });
        });
    }
}

const SearchService = new SearchApiService();
export { SearchService };
