query incidentImpactSummaryView($incidentId: [String!]) {
  incidents: incidentDetails(
    filter: {incidentIds: $incidentId}
  ) {
    nodes: page {
      id
      impactedLocations {
        name
      }
      impactedLocationsCount
      impactedApplications {
        name
      }
      impactedApplicationsCount
      impactedUsers {
        name
        ipAddress
      }
      impactedUsersCount
    }
  }
}
