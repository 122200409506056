/** This file defines the BubbleView React component.  The BubbleView React component wraps a 
 *  BubbleChart component and includes the query to get the data.  The BubbleChart React component renders a 
 *  a basic bubble chart with n-groups and one metric.  The bubble chart component can also render
 *  a donut chart.
 *  @module */

import { Query } from "reporting-infrastructure/types/Query.ts";
import { useQuery } from "utils/hooks/useQuery.ts";
import { FILTER_NAME } from "components/sdwan/enums/filters.ts";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade.tsx";
import type { Column, BaseRunbookViewProps, DataSet } from "pages/riverbed-advisor/views/runbook-view/Runbook.type.ts";
import { Unit } from "reporting-infrastructure/types/Unit.class.ts";
import { BubbleChart } from "components/common/bubble-chart/BubbleChart.tsx";
import type { BubbleData } from "components/common/bubble-chart/BubbleChart.tsx";
import { formatKeys } from "utils/runbooks/RunbookFormatter.tsx";
import { formatValue, getComparisonParameters, keysMatch } from "utils/runbooks/RunbookOutputUtils.ts";
import { LegendPosition } from "components/common/chart-base/ChartToolbar.tsx";
import { INCIDENT_DETAILS_STYLE } from "components/enums/QueryParams.ts";
import type { GroupMetricEvent } from "components/common/chart-base/ChartBase.tsx";
import SUMMARY_DATA_QUERY from '../table-view/summary-data-query.graphql';

/** an interface that describes the properties that can be passed in to the component.*/
export interface BubbleViewProps extends BaseRunbookViewProps {
    /** Pass as true to render the chart with transparent background. */
    transparent?: boolean;
}

/** Creates the bubble chart view, which is a component that displays one bubble chart with analytics data.
 *  @param props an object with the properties passed to the bubble chart view.
 *  @returns JSX with the bubble chart component.*/
export const BubbleView = (props: BubbleViewProps): JSX.Element => {
    let {comparisonSuffix = ""} = getComparisonParameters(props.widget?.options?.comparedTo as string);
    let primaryDataset: DataSet | undefined = undefined;
    let comparisonDataset: DataSet | undefined = undefined;
    if (props.datasets) {
        for (const dataset of props.datasets) {
            if (!dataset.isComparison) {
                primaryDataset =  dataset;
            } else {
                comparisonDataset = dataset;
            }
        }
    }

    let {loading, data, error} = useQuery({
        query: new Query(SUMMARY_DATA_QUERY),
        requiredFilters: [FILTER_NAME.incidentId, FILTER_NAME.datasetId],
        filters: {
            [FILTER_NAME.incidentId]: props.incidentId,
            [FILTER_NAME.datasetId]: primaryDataset?.datapoints ? undefined : primaryDataset?.id
		    },
        skipGlobalFilters: true
    });

    const showLegend = props.widget?.options?.showLegend !== undefined ? props.widget.options.showLegend as boolean : true;
    const legendPosition = props.widget?.options?.legendPosition ? props.widget?.options?.legendPosition as any : LegendPosition.top;
    const sizeMetric: string | undefined = props.widget?.options?.sizeMetric as string | undefined;
    const colorMetric: string | undefined = props.widget?.options?.colorMetric as string | undefined;

    let bubbleData: Array<BubbleData> = [];
    let sMetric: string = "";
    let sUnit: Unit = new Unit();
    let cMetric: string = "";
    let cUnit: Unit = new Unit();
    if (primaryDataset) {
        data = {};
        data.datapoints = primaryDataset.datapoints;
    }
    if (!loading) {
        if (data && data.datapoints && primaryDataset?.keys && primaryDataset?.metrics && primaryDataset?.metrics?.length > 0) {
            let sDataColumn: Column | undefined = undefined;
            let cDataColumn: Column | undefined = undefined;
            for (let metricIndex = 0; metricIndex < primaryDataset.metrics.length; metricIndex++) {
                if ((sizeMetric && primaryDataset.metrics[metricIndex].id === sizeMetric) || (!sizeMetric && metricIndex === 0)) {
                    sDataColumn = primaryDataset.metrics[metricIndex];
                }
                if ((colorMetric && primaryDataset.metrics[metricIndex].id === colorMetric)) {
                    cDataColumn = primaryDataset.metrics[metricIndex];
                }
            }
            if (sDataColumn) {
                sMetric = sDataColumn.label;
                sUnit = Unit.parseUnit(sDataColumn.unit || "");
                if (cDataColumn) {
                    cMetric = cDataColumn.label;
                    cUnit = Unit.parseUnit(cDataColumn.unit || "");    
                }

                for (const datapoint of data.datapoints) {
                    const label: string = formatKeys(primaryDataset.keys, datapoint.keys) as string;
                    const datum: BubbleData = {label: label, sizeValue: 0, group: datapoint.keys?.group};
                    let tv = formatValue(datapoint.data[sDataColumn.id], sDataColumn);
                    if (tv !== null && typeof tv === "number") {
                        datum.sizeValue = tv;
                    } else {
                        // We cannot show a null slice on the bubble chart, it should not be shown as 0
                        continue;
                    }

                    if (cDataColumn && datapoint.data[cDataColumn.id]) {
                        // Get the color value
                        let cv = formatValue(datapoint.data[cDataColumn.id], cDataColumn);
                        if (cv !== null && typeof cv === "number") {
                            datum.colorValue = cv;
                        }
                    }

                    // If there is comparison data add the comparison data
                    if (datapoint.keys && comparisonDataset?.datapoints) {
                        // There should only be one key so maybe we don't have to check this, let's do it anyway, it can't hurt
                        for (const checkDatapoint of comparisonDataset.datapoints) {
                            if (keysMatch(datapoint, checkDatapoint)) {
                                let compValue = formatValue(checkDatapoint.data[sDataColumn.id], sDataColumn);
                                if (compValue !== null && typeof compValue === "number") {
                                    datum.compSizeValue = compValue;
                                }
                                if (cDataColumn && checkDatapoint.data[cDataColumn.id]) {
                                    // Get the color value
                                    let cv = formatValue(checkDatapoint.data[cDataColumn.id], cDataColumn);
                                    if (cv !== null && typeof cv === "number") {
                                        datum.compColorValue = cv;
                                    }
                                }
                                break;
                            }
                        }
                    }                        
                    
                    bubbleData.push(datum);
                }
            }
        }
    }

    return <DataLoadFacade loading={loading || props.loading} error={error || props.error} data={data}>
        <BubbleChart bubbleData={bubbleData} sizeMetric={sMetric} sizeUnit={sUnit} colorMetric={cMetric} colorUnit={cUnit}
            settings={{
                showValue: (props.widget?.options?.showValue !== undefined ? props.widget.options.showValue as boolean : false),
                showLegend, legendPosition, 
                showMore: (props?.widget?.options?.showMore !== undefined ? props.widget.options.showMore as boolean : true), 
                showIconsOnHover: true
            }}
            transparent={props?.transparent} height={props.height}
            enableFullScreen={true}  
            comparisonSuffix={comparisonSuffix}
            fullScreenTitle={props.widget?.name} hideShadow={INCIDENT_DETAILS_STYLE === "noTableOneCardForEachWidget"}
            onGroupMetricSelection={(event: GroupMetricEvent) => {
                if (props.onGroupsAndMetricsSelected) {
                    props.onGroupsAndMetricsSelected([...(event.groups || [])], [...(event.metrics || [])]);
                }
            }}
        />
    </DataLoadFacade>;
};
