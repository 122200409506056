import React, { Ref, useCallback, useEffect, useRef } from 'react';
import _ from 'lodash'
import './RangeSlider.scss'
import { Icon, IconNames } from '@tir-ui/react-components';
export enum Mode {
    rangeSlider = 'RANGE_SLIDER',
    slider = 'SLIDER'
}
export enum Handle {
    start = "START_HANDLE",
    end = "END_HANDLE"
}
export type Steps = number;
export type PixelCount = number;
export type Percentage = number;
export interface Segment {
    id: string;
    label?: string | number | JSX.Element;
}
export type RangeSliderProps = {
    segments: Array<Segment>;
    stepsPerSegment: Steps;
    onNewRangeSelection: (arg0: RangeSliderPosition )=> void;
    onHandlePositionChange?: (arg0: RangeSliderPosition )=> void;
    startHandlePosition?: Steps;
    endHandlePosition?: Steps;
    maxEndHandlePosition?: Steps;
    startHandleLabel?: string
    endHandleLabel?: string
    showStepMarkers?: boolean
    id: string
    mode?: Mode
};

export interface RangeSliderPosition {
    leftPosition?: number;
    rightPosition?: number;
    position?: number;
    triggerHandleId?: string;
}
/**
 *
 * @param segments - An array of Segments. The size of the array determines the total number of segments the slider track will be broken into.
 * Each segment has a label associated with it that will diplayed if provide.
 * displayed if it is provided.
 * @param stepsPerSegment - Each segment can further be divided into steps. These are discreate points to which the slider handle can be moved to.
 * @param mode - Range slider operates in two modes. 'SLIDER' - only one handle is available, user selects a single value.
 * 'RANGE_SLIDER' - two handles are displayed and the user is able to select a range of values.
 * @param startHandlePosition - The position on the slider where the start handle needs to be placed. defaults to 0 if not specified.
 * @param endHandlePosition - The positon on the slider where the end handle needs to be placed. Ignored when used in 'Slider' mode.
 * @param maxEndHandlePosition - The max position the end handle can be moved.
 * @param startHandleLabel - Label to be displayed next to the start handle.
 * @param endHandleLabel - Label to be displayed next to the end handle.
 * @param showStepMarkers - Shows breaks in the tracks against the labels.
 * @param onNewRangeSelection - Callback funciton that will be called when a new range/value is selected.
 * @param onHandlePositionChange - Callback funciton that will be called when handle position changes. Use this to update handle labels etc.
 * @returns - JSX Element
 */
export function RangeSlider({
    segments, stepsPerSegment, onNewRangeSelection, showStepMarkers = true, mode = Mode.slider, startHandlePosition = 0, endHandlePosition, maxEndHandlePosition, id, startHandleLabel = "", endHandleLabel = "", onHandlePositionChange
}: RangeSliderProps) {
    // Minimum selectable range  ( in steps). This variable determines how close the two sliders can get to each other.
    const minRangeDif = 1;
    // position in computed steps. not pixels/percentage
    const handleStartPosRef = useRef<Steps>(startHandlePosition);
    const handleEndPosRef = useRef<Steps | undefined>(endHandlePosition);
    const track = useRef<HTMLDivElement>(null);
    const startHandleElement = useRef<HTMLDivElement>(null);
    const leftPit = useRef<HTMLDivElement>(null);
    const endHandleElement = useRef<HTMLDivElement>(null);
    const rightPit = useRef<HTMLDivElement>(null);
    const totalSteps = (segments.length - 1) * stepsPerSegment;
    const unitStepInPer = +(100 / (totalSteps)).toFixed(4);
    const listeners = useRef<Array<any>>([]);

    const setHandle = useCallback((handleId: Handle, posPer) => {
        let handleElement: Ref<HTMLDivElement> = (handleId === Handle.start) ? startHandleElement : endHandleElement;
        if (handleElement && handleElement.current) {
            let divEl: HTMLDivElement = handleElement?.current;
            divEl.style.left = `${posPer}%`;
            if (mode === Mode.rangeSlider) {
                if (handleId === Handle.start) {
                    const trackLeft: any = leftPit.current;
                    trackLeft.style.right = `${(100 - posPer)}%`;
                } else {
                    const trackRight: any = rightPit.current;
                    trackRight.style.left = `${(posPer)}%`;
                }
            }
        }
    }, [mode]);

    const removeAllListeners = useCallback(() => {
        listeners?.current.forEach((e) => {
            document.removeEventListener(e.eventType, e.handler);
        })
    }, [listeners]);

    useEffect(() => {
        handleStartPosRef.current = startHandlePosition;
        setHandle(Handle.start, handleStartPosRef.current * unitStepInPer);
        if (mode === Mode.rangeSlider) {
            handleEndPosRef.current = endHandlePosition;
            if (handleEndPosRef && handleEndPosRef.current !== undefined) {
                setHandle(Handle.end, handleEndPosRef.current * unitStepInPer);
            }
        }
    }, [startHandlePosition, endHandlePosition, segments, stepsPerSegment, mode, unitStepInPer, setHandle]);


    useEffect(() => {
        setHandle(Handle.start, handleStartPosRef.current * unitStepInPer);
        if (mode === Mode.rangeSlider) {
            if (handleEndPosRef && handleEndPosRef.current !== undefined) {
                setHandle(Handle.end, handleEndPosRef.current * unitStepInPer);
            }
        }
        return (() => {
            removeAllListeners();
        });
    }, [mode, removeAllListeners, setHandle, unitStepInPer]);


    function renderLabels() {
        const offsetUnit = 100 / (segments.length - 1);
        const labelsElements = segments.map((segment: Segment, i) => {
            const labelStyle = (i === segments.length - 1) ? { right: '0%'} : { left: `${offsetUnit * i}%` };
            const markerStyle = { left: `${offsetUnit * i}%` };
            return (
                <div key={i}>
                    <div aria-label="slider segment label" className="label" style={labelStyle}>
                        {segment.label !== undefined ? segment.label : ""}
                    </div>
                    {
                        showStepMarkers === true &&
                        <div className="label marker" style={markerStyle}></div>
                    }
                </div>
            );
        });
        return labelsElements;
    }


    const beginSelRangeMovement = (e: any) => {
        e.preventDefault();
        if (startHandleElement && startHandleElement.current) {
            let sHandleEl: HTMLDivElement = startHandleElement.current;
            let offsetLeftHandle: PixelCount = (e.clientX - sHandleEl.getBoundingClientRect().x);
            let rangeWidth = handleEndPosRef && handleEndPosRef.current !== undefined ? handleEndPosRef.current - handleStartPosRef.current : minRangeDif;
            let mouseMoveHandler = (event) => { procesSelRangeMovement(event, offsetLeftHandle, rangeWidth) };
            let mouseUpHandler = (event) => { endSelRangeMovement(event) };
            listeners?.current.push({ eventType: "mousemove", handler: mouseMoveHandler });
            listeners?.current.push({ eventType: "mouseup", handler: mouseUpHandler });
            document.addEventListener("mousemove", mouseMoveHandler);
            document.addEventListener("mouseup", mouseUpHandler);
        }

    }
    const endSelRangeMovement = _.debounce((e: MouseEvent) => {
        e.preventDefault();
        let result: RangeSliderPosition  = getCurrentHandlePositions();
        onNewRangeSelection(result);
        removeAllListeners();
    }, 5);

    const procesSelRangeMovement = _.debounce((e: MouseEvent, offsetStartHandle: PixelCount, rangeWidth: number) => {
        if (startHandleElement && startHandleElement.current) {
            let sHandleEl: HTMLDivElement = startHandleElement.current;
            if (sHandleEl.offsetParent !== undefined && sHandleEl.offsetParent !== null) {
                const sliderBeginPos: PixelCount = sHandleEl.offsetParent.getBoundingClientRect().x;
                const posStart = e.clientX - sliderBeginPos - offsetStartHandle;
                const posStartPer = (posStart / sHandleEl.offsetParent?.getBoundingClientRect().width) * 100;
                const normalizedPosStartPer = normalizePosition(posStartPer, Handle.start);
                const posEndPer = normalizedPosStartPer + convertStepsToPercentage(rangeWidth);
                // hack to work round percentage rounding errors. 
                if (posEndPer <= 101) {
                    updateHandlePosition(normalizedPosStartPer, Handle.start)
                    setHandle(Handle.start, normalizedPosStartPer)
                }
                const normalizedPosEndPer = normalizePosition(normalizedPosStartPer + convertStepsToPercentage(rangeWidth), Handle.end);
                updateHandlePosition(normalizedPosEndPer, Handle.end);
                setHandle(Handle.end, normalizedPosEndPer);
                if (onHandlePositionChange) {
                    onHandlePositionChange(getCurrentHandlePositions());
                }
            }
        }

    }, 5)
    const beginHandleMovement = (e: any) => {
        e.preventDefault();
        let handleId: Handle = e?.currentTarget.id;
        let mouseMoveHandler = (event) => { processHandleMovement(event, handleId) };
        let mouseUpHandler = (event) => { endHandleMovement(event, handleId) };
        listeners?.current.push({ eventType: "mousemove", handler: mouseMoveHandler });
        listeners?.current.push({ eventType: "mouseup", handler: mouseUpHandler });
        document.addEventListener("mousemove", mouseMoveHandler);
        document.addEventListener("mouseup", mouseUpHandler);
    }
    const endHandleMovement = _.debounce((e: any, handleId: Handle) => {
        e.preventDefault();
        let result: RangeSliderPosition  = getCurrentHandlePositions(handleId);
        onNewRangeSelection(result);
        removeAllListeners();
    }, 5)
    const processHandleMovement = _.debounce((e: MouseEvent, handleId: Handle) => {
        let handleElement: Ref<HTMLDivElement> = (handleId === Handle.start) ? startHandleElement : endHandleElement;
        if (handleElement && handleElement.current) {
            const divEl: HTMLDivElement = handleElement.current;
            // divEl.offsetLeft  = distance from the begining of slider to the cur position of the handle.
            // e.clientX = new mouse pointer position from the viewport x-axis
            // divEl.getBoundingClientRect().x = current position of the handler from the viewport x-axis
            // (e.clientX -divEl.getBoundingClientRect().x) = number of pixels the handle needs to move to keep up with the mouse pointer.
            // pos = new target position for the handler relative to the begining of slider.
            let pos: PixelCount = divEl.offsetLeft + (e.clientX - divEl.getBoundingClientRect().x);
            // divEl.offsetParent.getBoundingClientRect().width = total sliders width in px
            if (divEl.offsetParent) {
                const posPer = (pos / divEl.offsetParent.getBoundingClientRect().width) * 100;
                const normalizedPosPer = normalizePosition(posPer, handleId);
                updateHandlePosition(normalizedPosPer, handleId)
                setHandle(handleId, normalizedPosPer);
                if (onHandlePositionChange) {
                    onHandlePositionChange(getCurrentHandlePositions(handleId));
                }
            }
        }
    }, 5)

    function getCurrentHandlePositions(handleId?: Handle): RangeSliderPosition {
        return mode === Mode.slider ? { position: handleStartPosRef.current, triggerHandleId: handleId } : { leftPosition: handleStartPosRef.current, rightPosition: handleEndPosRef.current, triggerHandleId: handleId };
    }
    const normalizePosition = (curPosPer: number, handleId: Handle) => {
        curPosPer = +curPosPer.toFixed(4);
        if (mode === Mode.rangeSlider) {
            if (handleId === Handle.start && handleEndPosRef.current !== undefined) {
                curPosPer = curPosPer < 0 ? 0 : curPosPer;
                const maxValidPosition = (handleEndPosRef.current - minRangeDif) * unitStepInPer;
                if (curPosPer > maxValidPosition) {
                    curPosPer = maxValidPosition;
                }
            } else if (handleId === Handle.end) {
                curPosPer = curPosPer > 100 ? 100 : curPosPer;
                if (maxEndHandlePosition !== undefined) {
                    const maxEndHandlePosPer = convertStepsToPercentage(maxEndHandlePosition);
                    curPosPer = curPosPer > maxEndHandlePosPer ? maxEndHandlePosPer : curPosPer;
                }
                const minValidPosition = (handleStartPosRef.current + minRangeDif) * unitStepInPer;
                if (curPosPer < minValidPosition) {
                    curPosPer = minValidPosition;
                }
            }
        } else {
            curPosPer = curPosPer < 0 ? 0 : (curPosPer > 100 ? 100 : curPosPer);
        }
        let curSteps = Math.round(curPosPer / unitStepInPer);
        return +(curSteps * unitStepInPer).toFixed(4);
    }
    const updateHandlePosition = (posPer: Percentage, handleId: Handle) => {
        let steps = Math.round(posPer / unitStepInPer);
        if (handleId === Handle.start) {
            handleStartPosRef.current = steps;
        } else if (handleId === Handle.end) {
            handleEndPosRef.current = steps;
        }
    }
    const convertStepsToPercentage = (steps: Steps) => {
        return +(steps * unitStepInPer).toFixed(4);
    }

    const modeStyle: string = mode === Mode.rangeSlider ? "selected-range" : "track";
    return (
        <div className="range-slider" id={id}>
            <div aria-label="selected range" className={modeStyle} ref={track} style={{ left: "0%", right: "0%" }} onMouseDown={beginSelRangeMovement}></div>
            {mode === Mode.rangeSlider &&
                <>
                    <div className="pit" ref={leftPit} style={{ left: "0%", right: "40%" }}></div>
                    <div className="pit" ref={rightPit} style={{ left: "80%", right: "0%" }}></div>
                </>
            }
            {renderLabels()}
            {mode === Mode.rangeSlider ?
                <>
                    <div id={Handle.start} ref={startHandleElement} aria-label="slider start handle" className="handle" style={{ position: "absolute", left: "60%", top: "-20px" }} onMouseDown={beginHandleMovement} >
                        <div aria-label="slider start handle label" className="handle-label start">{startHandleLabel}</div>
                        <Icon className="offset-left" icon={IconNames.CARET_DOWN} size={25} />
                    </div>
                    <div id={Handle.end} ref={endHandleElement} aria-label="slider end handle" className="handle" style={{ position: "absolute", left: "80%", top: "-20px" }} onMouseDown={beginHandleMovement} >
                        <div aria-label="slider end handle label" className="handle-label end">{endHandleLabel}</div>
                        <Icon className="offset-left" icon={IconNames.CARET_DOWN} size={25} />
                    </div>
                </>
                :
                <div id={Handle.start} ref={startHandleElement} aria-label="slider handle" className="handle" style={{ position: "absolute", left: "60%", top: "-20px" }} onMouseDown={beginHandleMovement}>
                    <div aria-label="slider handle" className="handle-label">{startHandleLabel}</div>
                    <Icon className="circle-center" icon={IconNames.FULL_CIRCLE} size={17} />
                </div>
            }
        </div>
    )
}
