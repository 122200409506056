/** This module defines a hook that can be used to manage the user preferences and 
 *  get notified of user preferences changes.
 *  @module
 */
import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import type { UserPreferences } from 'utils/services/UserPrefsTypes.ts';
import { getUserPreferences, setUserPreferences, addChangeCallback, removeChangeCallback, getCachedUserPreferences } from 'utils/stores/UserPreferencesStore.ts';
import { DEFAULT_USER_PREFS } from 'utils/services/UserPrefsService.ts';

/** the properties that are passed into the user preferences hook. */
export interface useUserPreferencesProps {
    /** If provided, this hook will cause a re-render of consuming component only when the value of one of the 
     *  provided keys changes. */
    listenOnlyTo?: Partial<UserPreferences>
}

/** This hook is responsible for listening to changes in the user preferences and re-rendering the hook if 
 *  they change.
 *  @param props the UserPreferencesProps with the passed in properties.
 *  @returns the current user preferences or the default user preferences object if there aren't any. */
function useUserPreferences (props?: useUserPreferencesProps): UserPreferences {
    const [preferences, setPreferences] = useState<UserPreferences|undefined>(getCachedUserPreferences());

    useEffect(() => {
        // Load userPreferences (Remember that this fetch could be asynchronous)
        getUserPreferences().then(prefs => setPreferences(prefs));

        // As long as this hook is mounted and active, listen to changes happening in
        // the global user preferences singleton store
        addChangeCallback(onPreferencesChangedInStore);
        return () => {
            // Unsubscribe the callback when unmounting
            removeChangeCallback(onPreferencesChangedInStore);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /** finds the intersection of a preferences object and the listenTo object.
     *  @param inputObject a preferences object.
     *  @param referenceTree the list of keys in the listenTo object.
     *  @returns the intersection of the keys in the reference tree (listenTo) and the keys of the preferences object. */
    function intersection(inputObject, referenceTree) {
        if (referenceTree !== null && typeof referenceTree === "object" && !Array.isArray(referenceTree)) {
            const output = {};
            for (const key in referenceTree) {
                output[key] = intersection (inputObject && inputObject[key], referenceTree[key]);
            }
            return output;
        } else {
            return inputObject;
        }
    }

    /** the handler for user preferences store changes.
     *  @param param0 the object with the new value and the old value. */
    function onPreferencesChangedInStore({ newVal, prevVal }: { newVal: UserPreferences, prevVal: UserPreferences }) {
        // Whenever a user preference change happens, fetch a latest copy of the object
        // and update local state with it so that the consumer of this hook will get re-rendered
        const newPreferencesFiltered = props?.listenOnlyTo ? intersection(newVal, props.listenOnlyTo) : newVal;
        const oldPreferencesFiltered = props?.listenOnlyTo ? intersection(prevVal, props.listenOnlyTo) : prevVal;
        if (!isEqual(oldPreferencesFiltered, newPreferencesFiltered)) {
            setPreferences(newVal);
        }
    }

    if (preferences) {
        return preferences;
    } else {
        console.warn("Returning default user preferences from useUserPreferences hook");
        return DEFAULT_USER_PREFS;
    }
}
export { useUserPreferences, setUserPreferences };
