/** This module contains the store that holds the global time setting.
 *  @module
 */
import moment from "moment";
import { setQueryParam, getQueryParam, clearQueryParam, addQueryParamChangeListener } from '../hooks/useQueryParams';
import { isEqual } from 'lodash';
import { getUserPreferences, setUserPreferences } from "./UserPreferencesStore";

export enum DURATION {
    MIN_1   = "MIN_1",
    MIN_15  = "MIN_15",
    MIN_30  = "MIN_30",
    HOUR_1  = "HOUR_1",
    HOUR_2  = "HOUR_2",
    HOUR_6  = "HOUR_6",
    HOUR_12 = "HOUR_12",
    DAY_1   = "DAY_1",
    DAY_2   = "DAY_2",
    DAY_7   = "DAY_7",
    LAST_1_MONTH = "LAST_1_MONTH",
    LAST_6_MONTH = "LAST_6_MONTH",
    LAST_1_YEAR = "LAST_1_YEAR",
    CURRENT_MONTH = "CURRENT_MONTH",
    PREVIOUS_MONTH = "PREVIOUS_MONTH",
    BEFORE_PREVIOUS_MONTH = "BEFORE_PREVIOUS_MONTH"
}

// Used to subtract duration from current time using moment
const ISO_8601_DURATION_MAP: {[key in DURATION]?: string} = {
    MIN_1: "PT1M",
    MIN_15: "PT15M",
    MIN_30: "PT30M",
    HOUR_1: "PT1H",
    HOUR_2: "PT2H",
    HOUR_6: "PT6H",
    HOUR_12: "PT12H",
    DAY_1: "P1D",
    DAY_2: "P2D",
    DAY_7: "P7D",
    LAST_1_MONTH: "P1M",
    LAST_6_MONTH: "P6M",
    LAST_1_YEAR: "P1Y",
    CURRENT_MONTH: "CM",
    PREVIOUS_MONTH: "PM",
    BEFORE_PREVIOUS_MONTH: "BPM"
}

export enum ISO_DURATION {
    P1H = "PT1H",
    P6H = "PT6H",
    P2H = "PT2H",
    P1D = "P1D",
    P2D = "P2D",
    P3D = "P3D",
    P4D = "P4D"
};
export type TIME_DURATION_ISO = {
    duration : ISO_DURATION
}

export const QUERY_PARAM_KEY = "t";

export type TIME_RANGE = { startTime: number, endTime: number, interval?: string };
export type TIME_DURATION = { duration: DURATION, interval?: string };
export type TIME_OBJECT  = Partial<TIME_RANGE & TIME_DURATION>

/** the default time value which is the last 6 hours. */
const DEFAULT_TIME_VALUE = { duration: DURATION.HOUR_6 };

/** use this as a URL query param to cause the time not to be set from the user preferences. */
export const NULL_TIME_VALUE: TIME_RANGE = {startTime: 0, endTime: 0};

/** the minimum time difference to allow the time to be set. */
const MIN_TIME_DIFF_FOR_SET = 60 * 1000;

/** returns the time parsed from the URL query parameters.
 *  @returns the time parsed from the URL query parameters. */
function getTimeFromURL (): TIME_OBJECT | undefined {
    const timeObjStringFromURL: string | undefined = getQueryParam(QUERY_PARAM_KEY);
    let time;
    if (timeObjStringFromURL !== undefined) {
        try {
            time = JSON.parse(timeObjStringFromURL);
        } catch (ex) {
            console.warn("Unable to parse time from URL - " + timeObjStringFromURL);
        }
    }
    return time;
}

/** the time parsed from the URL. */
const timeParsedFromURL = getTimeFromURL();

let time: TIME_OBJECT = timeParsedFromURL || DEFAULT_TIME_VALUE,
    lastTime: TIME_OBJECT = time,
    lastAbsoluteTime: TIME_RANGE | null = null,
    callbacks: Array<(event: {newVal: TIME_OBJECT, prevVal: TIME_OBJECT}) => void> = [],
    // If there was a time set explicitly in URL, consider it as manually set.
    // This information will be consumed by controls like the global filters to
    // indicate when a custom time was manually set instead of using whatever is the default one.
    // (e.g. incident list will by default not use any time range unless a custom time frame was manually selected)
    timeSetManually = Boolean(timeParsedFromURL),
    nullTimePassed = false,
    userPrefsSyncComplete = false;

if (timeParsedFromURL && isEqual(NULL_TIME_VALUE, timeParsedFromURL)) {
    time = DEFAULT_TIME_VALUE;
    nullTimePassed = true;
    timeSetManually = false;
}

/** syncs the time value either to the user preferences or from the user preferences. */
async function syncWithUserPrefsOnInit () {
    if (!userPrefsSyncComplete) {
        userPrefsSyncComplete = true;
        if (timeParsedFromURL) {
            if (!nullTimePassed) {
                // Only save to the preferences if it is not the null value that was passed
                saveTimeToUserPrefs();
            }
        } else {
            await fetchTimeFromUserPrefs();
        }
    }
}

/** initializes the time store. */
export async function init() {
    await syncWithUserPrefsOnInit();
}

// When URL time query param changes by some action such pushing or popping history state
// by some JavaScript routing logic, check if active time information is in the URL and sync if needed.
addQueryParamChangeListener(() => {
    const timeFromURL = getTimeFromURL();
    // If there's a mismatch between store time and URL time
    if (!isEqual(timeFromURL, time)) {
        // If URL doesn't care about time and doesn't have any info about time
        if (!timeFromURL) {
            // Sync store time information if present to URL
            syncQueryParam();
            if (nullTimePassed) {
                // We had the null time passed in the URL, so we need to go back to the preferences
                fetchTimeFromUserPrefs();
                nullTimePassed = false;
            }        
        } else {
            lastTime = time;
            // Sync time from the URL to local store as there was a time specified in the URL during param change
            if (isEqual(NULL_TIME_VALUE, timeFromURL)) {
                //alert("Got the null value");
                time = DEFAULT_TIME_VALUE;
                nullTimePassed = true;
                timeSetManually = false;
            } else {
                time = Object.assign({}, timeFromURL);    
                if (nullTimePassed) {
                    fetchTimeFromUserPrefs();
                    nullTimePassed = false;
                }            
                lastAbsoluteTime = null;
                timeSetManually = true;    
            }
            triggerSync();
        }
    }
});

/** sets the time.
 *  @param newTime the TIME_OBJECT with the new time.
 *  @param doNotSync if this is true then do not sync the time with the query params.
 *  @returns a boolean value, true if the time was set, false otherwise. */
function setTime(newTime: TIME_OBJECT, doNotSync = false): boolean {
    // TBD: Another place where typescript is preventing me from reading the duartion
    // attribute. Must remove this typecast to any when we figure out a solution.
    const newTimeAny:any = newTime;
    let setTimeValue = true;
    // If time is of type TIME_DURATION and same value as before is being set, then
    // trigger a time change event only when the difference between last set and new set
    // exceeds MIN_TIME_DIFF. e.g. We don't want to keep fetching the same data over and
    // over within the same minute for millisecond time differences.
    if (isEqual(time, newTime) && newTimeAny.duration && lastAbsoluteTime !== null) {
        const absTimeRange = durationToTimeRange(newTimeAny.duration);
        const timeDifference = absTimeRange.endTime - lastAbsoluteTime.endTime;
        if (timeDifference < MIN_TIME_DIFF_FOR_SET) {
            setTimeValue = false;
        }
    }
    if (setTimeValue || timeSetManually === false) {
        lastTime = time;
        time = Object.assign({}, newTime);
        lastAbsoluteTime = null;
        timeSetManually = true;
        if (!doNotSync) {
            syncQueryParam();
            triggerSync();
        }
    }
    return setTimeValue;
}

/** syncs the time with the query parameters. */
function syncQueryParam(): void {
    if (!isEqual(getTimeFromURL(), time)) {
        if (isEqual(time, DEFAULT_TIME_VALUE) && timeSetManually === false) {
            clearQueryParam(QUERY_PARAM_KEY);
        } else {
            setQueryParam(QUERY_PARAM_KEY, time);
        }
    }
}

/** resets the time. */
const resetTime = function () {
    setTime(DEFAULT_TIME_VALUE, true);
    timeSetManually = false;
    clearQueryParam(QUERY_PARAM_KEY);
    triggerSync();
}

/** returns the time.
 *  @returns the TIME_OBJECT with the time. */
function getTime(): TIME_OBJECT {
    return Object.assign({}, time);
}

/** returns the time.
 *  @returns the TIME_OBJECT with the time. */
function getAbsoluteTime(forceRecalc = false): TIME_OBJECT {
    const timeCopy: any = Object.assign({}, time);
        let absoluteTime;
    if (timeCopy.duration) {
        if (forceRecalc || lastAbsoluteTime === null) {
            lastAbsoluteTime = durationToTimeRange(timeCopy.duration);
        }
        absoluteTime = Object.assign({}, lastAbsoluteTime);
        if (timeCopy.interval) {
            absoluteTime.interval = timeCopy.interval;
        }
    } else {
        absoluteTime = time;
    }
    return absoluteTime;
}

/** Use this method when converting duration to time range but you also want it to snap to 
 *      a certain unit of time (e.g. last minute, hour, day, etc). This will be useful in cases
 *      where you don't care or expect any changes in queried data in say under a minute from
 *      the last fetch.
 *  @param durationToConvert the DURATION object with the duration to convert. 
 *  @param roundTo the moment unit of time to round to. 
 *  @returns a TIME_OBJECT with the rounded time range. */
function durationToRoundedTimeRange(durationToConvert: DURATION, roundTo: moment.unitOfTime.StartOf = "minute"): TIME_RANGE {
    return durationToTimeRange(durationToConvert, roundTo);
}

/** A function that will convert a provided duration into absolute start and end times ending at 'now'.
 *      An optional roundTo parameter can be provided if you want the conversion to drop off some precision
 *      and round the end time to the start of 'roundTo' value which can be one of the moment's StartOf values.
 *  @param durationToConvert the DURATION object with the duration to convert. 
 *  @param roundTo the moment unit of time to round to. 
 *  @returns a TIME_OBJECT with the rounded time range. */
 function durationToTimeRange (durationToConvert: DURATION, roundTo?: moment.unitOfTime.StartOf): TIME_RANGE {
    let timeRange;
    const now = roundTo? moment().startOf(roundTo).utc() : moment().utc();
    let startTime, endTime;
    switch (durationToConvert) {
        case DURATION.CURRENT_MONTH:
            startTime = now.clone().startOf('month').valueOf();
            endTime = now.valueOf();
            timeRange = {
                startTime: startTime,
                endTime: endTime
            };
            return timeRange;
        case DURATION.PREVIOUS_MONTH:
            startTime = now.clone().subtract(1, 'month').startOf('month').valueOf();
            endTime = now.clone().subtract(1, 'month').endOf('month').valueOf();
            timeRange = {
                startTime: startTime,
                endTime: endTime
            };
            return timeRange;
        case DURATION.BEFORE_PREVIOUS_MONTH:
            startTime = now.clone().subtract(2, 'month').startOf('month').valueOf();
            endTime = now.clone().subtract(2, 'month').endOf('month').valueOf();
            timeRange = {
                startTime: startTime,
                endTime: endTime
            };
            return timeRange;
        default:
            startTime = now.clone().subtract(moment.duration(ISO_8601_DURATION_MAP[durationToConvert])).valueOf();
            endTime = now.valueOf();
            timeRange = {
                startTime: startTime,
                endTime: endTime
            };
            return timeRange;
    }
}

/** notifies all global filters listeners that the cache of global time filters has changed. */
function triggerSync(): void {
    for (const callback of callbacks) {
        callback({ newVal: time, prevVal: lastTime });
    }
    if (!nullTimePassed) {
        saveTimeToUserPrefs();
    }
}

/** adds a listener for changes to the global time filters.
 *  @param callback a function that will be called when the global time filters change. */
function addChangeCallback(callback: (event: {newVal: TIME_OBJECT, prevVal: TIME_OBJECT}) => void) {
    callbacks.push(callback);
}

/** removes the specified listener for changes to the global time filters.
 *  @param callback a function that will be called when the global time filters change. */
function removeChangeCallback(callback?: (event: {newVal: TIME_OBJECT, prevVal: TIME_OBJECT}) => void) {
    callbacks = callbacks.filter(c => c !== callback);
}

/** returns whether or not a custom time is set.  A custom time exists if the time was set via the 
 *  URL and it is not null. 
 *  @returns a boolean value, true if a custom time is set. */
function isCustomTimeSet(): boolean {
    return timeSetManually;
}

/** fetches the time from the user preferences. */
async function fetchTimeFromUserPrefs(): Promise<void> {
    try {
        const preferences = await getUserPreferences();
        if (preferences?.time) {
            setTime(JSON.parse(preferences.time));
        }
    } catch (ex) {
        console.error(ex);
        console.warn("Unable to fetch time from user preferences");
    }
}

/** saves the current time to the user preferences. */
function saveTimeToUserPrefs(): void {
    if (!isEqual(time, lastTime) && !isEqual(time, NULL_TIME_VALUE)) {
        setUserPreferences({
            time: isCustomTimeSet() ? JSON.stringify(time) : ""
        });
    }
}

export {
    getTime,
    setTime,
    resetTime,
    getAbsoluteTime,
    durationToTimeRange,
    durationToRoundedTimeRange,
    addChangeCallback,
    removeChangeCallback,
    DEFAULT_TIME_VALUE,
    isCustomTimeSet,
};
