query clientApplications {
    clientApplications {
        id
        name
        secrets {
            secretId
            hint
            description
            expiration
        }
    }
}
