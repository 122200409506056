/** This module contains the component for rendering the usage monitoring section.
 * It consist of two tabs.
 *  @module
 */
import React from "react";
import {
    TabbedSubPages,
    Tab,
} from "components/common/layout/tabbed-sub-pages/TabbedSubPages.tsx";
import { NodesMonitorView } from "./NodesMonitorView.tsx";
import { RunbooksMonitorView } from "./RunbooksMonitorView.tsx";

import { STRINGS } from "app-strings";

/** Renders the usage monitoring tabs view.
 *  @param props the properties passed in.
 *  @returns JSX with the usage monitoring tabs component.*/
const UsageMonitorTabsView = (): JSX.Element => {
    return (
        <React.Fragment>
            <TabbedSubPages className="pt-2">
                <Tab
                    id="node-usage"
                    title={STRINGS.USAGE_MONITORING_PAGE.tabs.nodeUsage.title}
                >
                    <NodesMonitorView />
                </Tab>
                <Tab
                    id="runbook-usage"
                    title={
                        STRINGS.USAGE_MONITORING_PAGE.tabs.runbookUsage.title
                    }
                >
                    <RunbooksMonitorView />
                </Tab>
            </TabbedSubPages>
        </React.Fragment>
    );
};

export { UsageMonitorTabsView };
