/** This file defines the output data block for the transform node editor.  An output data block
 *  allows the user to edit either the set of keys or the set of metrics.  Each key and metric is 
 *  edited in an OutputDataElement.  The OutputDataBlock contains a list of OutputDataElements.
 *  @module */

import { useCallback, useState } from 'react';
import { Icon, IconNames } from '@tir-ui/react-components';
import { Button, IconName } from '@blueprintjs/core';
import { OutputDataElement, OutputDataElementDefinition, OutputDataValueType } from 'components/common/graph/editors/transform/OutputDataElement.tsx';
import { generateRandomID } from 'components/common/condition-tree-builder/condition/ConditionUtils.ts';
import { STRINGS } from 'app-strings';
import { SectionType, UpdateEvent, UpdateEventType } from 'components/common/graph/editors/transform/TransformNodeEditor.tsx';

/** this interface defines the properties passed into the output data block component. */
export interface OutputDataBlockProps {
    /** the label for the section.  The section title is the join of the label and name. */
    sectionLabel?: string;
    /** a String with the name of the section. */
    sectionName?: string;
    /** a String with the name of the element. */
    elementName?: string;
    /** the list of suggested keys (properties) or metrics that match the data ocean keys and metrics. */
    suggestionsList?: Object;
    /** the onChange handler that is called whenever the keys and metrics change. */
    onChange: (event: UpdateEvent) => void;
    /** an array that contains the existing OutputDataElementDefinitions. */
    definitions: Array<OutputDataElementDefinition>;
    /** boolean set to true if definition method is set to loading from variable. */
    loadFromVar: boolean;
    /** boolean set to true if inside a subflow node editor panel */
    isSubflow?: boolean,
    /** boolean set to true if inside an on-demand runbook node editor panel */
    isOnDemandNodeEditor?: boolean,
}

export const OUTPUT_ELEMENTS_LIMIT = 300;

/** Component for editing the either the block of keys (properties) or metrics.
 *  @returns a JSX component with the output block editor. */
export function OutputDataBlock ({
    sectionLabel = STRINGS.runbookEditor.nodeEditor.outputDataSection,
    sectionName = "",
    elementName = "",
    suggestionsList = [],
    onChange,
    isSubflow,
    isOnDemandNodeEditor,
    ...props
}: OutputDataBlockProps): JSX.Element {
    const blockHeadingClassList = "display-8 fw-600 text-uppercase border-bottom mb-2 pb-2 d-flex justify-content-between align-items-center";
    const [outputDataList, setOutputDataList] = useState<Array<OutputDataElementDefinition>>(props.definitions.length > 0 ? [...props.definitions] : [{id: generateRandomID(), label: "", type: OutputDataValueType[0], unit: ""}]);
    const onRemoveOutputDataElementClicked = useCallback((outputId: string) => {
        const updatedOutputsList: any[] = [];
        if (outputDataList) {
            for (const output of outputDataList) {
                if (output.id !== outputId) {
                    updatedOutputsList.push(output);
                }
            }
        }
        setOutputDataList(updatedOutputsList);
        onChange({
            uniqueId: outputId,
            id: outputId,
            type: UpdateEventType.DELETED,
            value: ""
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div className="card h-min-1 p-2 mb-4">
        {!isOnDemandNodeEditor && <div className={blockHeadingClassList}>
            <div className="d-inline-flex align-items-center">
                <Icon className="drag-handle" icon={IconNames.DRAG_HANDLE_VERTICAL} />
                {isOnDemandNodeEditor ? STRINGS.runbookEditor.nodeEditor.onDemandInputDataPropertiesTitle : sectionLabel + sectionName}
            </div>
        </div>}
        <div>
            {
                outputDataList.map((item) =>
                    (   <OutputDataElement key={"id-" + item.id}
                            onRemoveOutputDataElementClicked={onRemoveOutputDataElementClicked}
                            onChange={onChange}
                            loadFromVar={props?.loadFromVar}
                            sectionName={ sectionName === SectionType.PROPERTY ? sectionName : ''}
                            outputDataElementDefinition={item}
                            suggestionsList={suggestionsList}
                            isFirstItem={outputDataList.indexOf(item) === 0}
                            isSubflow={isSubflow}
                            isOnDemandNodeEditor={isOnDemandNodeEditor}
                        />)
                    )
            } 
            {!isSubflow && <Button
                aria-label="add-output-data-elem"
                className="add-sub-condition-btn"
                icon={IconNames.PLUS as IconName}
                minimal
                text={STRINGS.runbookEditor.nodeEditor.addButton + elementName}
                disabled={outputDataList.length >= OUTPUT_ELEMENTS_LIMIT || props?.loadFromVar}
                onClick={() => {
                    setOutputDataList([...outputDataList, {id: generateRandomID(), label: "", type: OutputDataValueType[0], unit: ''}]);
                }}
            />}
        </div>
    </div>
};
