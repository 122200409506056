import { useState, type ReactNode } from "react";
import { Button, Checkbox, PopoverPosition, Popover } from "@blueprintjs/core";
import { IconNames } from "@tir-ui/react-components";

export interface HintProps {
    /** Style classes to apply on the hint container */
    className?: string;
    /** Content that will be wrapped in the hint popup */
    children: ReactNode;
    /** Contents to be displayed inside the hint */
    hint: ReactNode;
    /** If passed as true and if hint is of type string, it will be dangerously set as HTML */
    setStringAsMarkup?: boolean; 
    /** A flag to control if "Prev" button should be displayed for this hint */
    showPrevBtn?: boolean;
    /** A flag to control if "Next" button should be displayed for this hint */
    showNextBtn?: boolean;
    /** A flag to control if the "X" button should be displayed for this hint */
    showCloseBtn?: boolean;
    /** A flag to control if the control to allow the user to select
     * "Don't show this hint again" should be displayed for this hint */
    showDontShowAgainBtn?: boolean;
    /** Callback that will be called when "Prev" button is clicked */
    onPrevious?: () => void;
    /** Callback that will be called when "Prev" button is clicked */
    onNext?: () => void;
    /** Callback that will be called when the hint is closed */
    onClose?: (dontShowEver: boolean) => void;
}

export function Hint ({
    className,
    children,
    hint,
    setStringAsMarkup = false,
    showPrevBtn = false,
    showNextBtn = false,
    showCloseBtn = true,
    showDontShowAgainBtn = false,
    onPrevious,
    onNext,
    onClose,
}: HintProps): JSX.Element {
    const [dontShowEver, setDontShowEver] = useState(false);
    return <Popover
        popoverClassName={"opacity-8 wrap-in-hint" + (className ? " " + className : "")}
        content={<div className="w-max-4 p-1">
            {
                showCloseBtn &&
                <div className="d-flex align-items-center justify-content-end hint-header">
                    {
                        showDontShowAgainBtn && <Checkbox
                            className="m-0 me-1 display-10 dont-show-again"
                            label="Do not show these hints again"
                            checked={dontShowEver}
                            onChange={e => setDontShowEver(e.currentTarget.checked)}
                        />
                    }
                    <Button small minimal icon={IconNames.CROSS} onClick={() => {
                        if (onClose) {
                            onClose(dontShowEver);
                        }
                    }}/>
                </div>
            }
            {
                setStringAsMarkup === false || typeof hint !== "string" ?
                <div className={"px-1" + (typeof hint === "string" ? " text-center" : "")}>{hint}</div> :
                <div className="px-1" dangerouslySetInnerHTML={{__html: hint}}/>
            }
            {
                (showNextBtn || showPrevBtn) && 
                <div className="mt-1 d-flex justify-content-between">
                    <Button
                        className={showPrevBtn ? "" : "invisible"}
                        text="Prev"
                        small
                        minimal
                        icon={IconNames.DOUBLE_CHEVRON_LEFT}
                        onClick={() => {
                            if (onPrevious) {
                                onPrevious();
                            }
                        }}
                    />
                    <Button
                        className={showNextBtn ? "" : "invisible"}
                        text="Next"
                        small
                        minimal
                        rightIcon={IconNames.DOUBLE_CHEVRON_RIGHT}
                        onClick={() => {
                            if (onNext) {
                                onNext();
                            }
                        }}
                    />
                </div>
            }
        </div>}
        position={PopoverPosition.RIGHT_TOP}
        autoFocus={false}
        enforceFocus={false}
        isOpen={true}
    >{children}</Popover>;
}
