import { useRef, useState, useEffect, ReactNode } from 'react';
import { IconNames, type IconName } from "@tir-ui/react-components";
import { Button } from "@blueprintjs/core";
import { createPortal } from "react-dom";
import { SIZE, PANEL_SIZE } from 'components/enums/Sizes.ts';
import { IconTitle } from 'components/common/icon-title/IconTitle.tsx';
import './DetailsPanel.scss';

export interface DetailsPanelProps {
    children: any,
    floating?: boolean,
    className?: string,
    /** a string or React node with the title. */
    title?: string | number | ReactNode;
    /** the icon that is to be displayed in the title. */
    icon?: IconName | ReactNode;
    animate?: boolean,
    size?: SIZE.s | SIZE.m | SIZE.l | SIZE.xl,
    anchorElement?: string,
    resizableElement?: string,
    onCloseClicked?: () => void,
    notResizable?: boolean,
    visible?: boolean,
    persistSize?: boolean,
    /** Default: true, overlay the details panel, covers part of the screen. False, moves the screen to show panel. */
    overlay?: boolean,
}

export function DetailsPanel({
    children,
    floating,
    className,
    title,
    icon,
    animate,
    size,
    anchorElement,
    resizableElement,
    onCloseClicked,
    notResizable,
    visible = true,
    persistSize,
    overlay = true,
}: DetailsPanelProps) {
    const [panelVisible, setPanelVisible] = useState<boolean>(true);
    const [expand, setExpand] = useState<boolean>(false);

    const el = useRef<Element>();
    if (!el.current) {
        const elem = document.createElement("div");
        elem.className = "w-4 d-flex flex-column" + (className ? (" " + className) : "");
        el.current = elem;
    }

    useEffect(
        () => {
            if (el.current) {
                let resizable = document.getElementsByClassName(resizableElement || 'resizable-details-panel')[0] as HTMLElement;
                if (!resizable) {
                    return;
                }

                if (expand) {
                    resizable.style.position = "absolute";
                    resizable.style.width = "100%";
                    resizable.style.maxWidth = "100%";
                    resizable.style.zIndex = "115";
                    resizable.lastElementChild?.classList.add("d-none");
                } else {
                    if (size === SIZE.xl) {
                        resizable.style.width = PANEL_SIZE.xLarge;
                    } else if (size === SIZE.l) {
                        resizable.style.width = PANEL_SIZE.large;
                    } else if (size === SIZE.s) {
                        resizable.style.width = PANEL_SIZE.small;
                    } else {
                        resizable.style.width = PANEL_SIZE.medium;
                    }
                    resizable.style.position = "relative";
                    resizable.style.maxWidth = "80%";
                    // If the z-index value is greater than 10, such as the 115 set above, we want this to go back to 10
                    // DashboardPage uses z-index of 5 so by default it should stay as 5
                    if (parseInt(resizable.style.getPropertyValue("z-index")) > 10) {
                        resizable.style.zIndex = "10";
                    }
                    resizable.lastElementChild?.classList.remove("d-none");
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [expand]
    );

    useEffect(() => {
        const targetElement = anchorElement || "right-details-pane";
        let mount = document.getElementById(targetElement);
        let resizable = document.getElementsByClassName(resizableElement || 'resizable-details-panel')[0] as HTMLElement;
        if (!mount) {
            mount = document.createElement("div");
            mount.id = anchorElement || "right-details-pane";
            document.body.appendChild(mount);
        }
        if (mount) {
            mount.textContent = "";
            if (el.current) {
                mount.appendChild(el.current);
            }
        }
        if (floating) {
            mount?.classList.add("floating");
            mount?.classList.add("show");
            resizable?.classList.remove("d-none");
            mount?.classList.remove("d-none");
        } else {
            if (animate) {
                mount?.classList.remove("d-none");
                setTimeout(() => mount?.classList.add("show"), 5);
                setTimeout(() => resizable?.classList.remove("d-none"), 5);
                setTimeout(() => window.dispatchEvent(new Event("resize")), 350);
            } else {
                mount?.classList.add("show");
                resizable?.classList.remove("d-none");
                mount?.classList.remove("d-none");
                window.dispatchEvent(new Event("resize"));
            }
        }
        if (size === SIZE.xl) {
            mount?.classList.add("x-large");
            mount?.classList.remove("large", "medium", "small");
            if (resizable && !persistSize) {
                resizable.style.width = PANEL_SIZE.xLarge;
            }
        } else if (size === SIZE.l) {
            mount?.classList.add("large");
            mount?.classList.remove("x-large", "medium", "small");
            if (resizable && !persistSize) {
                resizable.style.width = PANEL_SIZE.large;
            }
        } else if (size === SIZE.s) {
            mount?.classList.add("small");
            mount?.classList.remove("x-large", "large", "medium");
            if (resizable && !persistSize) {
                resizable.style.width = PANEL_SIZE.small;
            }
        } else {
            mount?.classList.add("medium");
            mount?.classList.remove("large", "x-large", "small");
            if (resizable && !persistSize) {
                resizable.style.width = PANEL_SIZE.medium;
            }
        }

        // Hides the panel
        if (visible) {
            mount?.classList.add("show");
            resizable?.classList.remove("d-none");
        } else {
            mount?.classList.remove("show");
            resizable?.classList.add("d-none");
        }

        // Check if resizable element exist
        if (resizable) {
            // Hides the resize div
            if (notResizable) {
                resizable.lastElementChild?.classList.add("d-none");
            } else {
                resizable.lastElementChild?.classList.remove("d-none");
            }

            // Changes the position of the panel
            if (!overlay) {
                resizable.style.position = "relative";
            } else {
                resizable.style.position = "absolute";
            }
        }

        return () => {
            if (animate) {
                mount?.classList.remove("show");
                resizable?.classList.add("d-none");
            } else {
                if (mount) {
                    mount.classList.add("d-none");
                    mount.classList.remove("show");
                    mount.textContent = "";
                    if (resizable) {
                        resizable.classList.add("d-none");
                    }
                }
                window.dispatchEvent(new Event("resize"));
            }
            if (floating) {
                mount?.classList.remove("floating");
                resizable?.classList.add("d-none");
            } else if (animate) {
                setTimeout(() => {
                    window.dispatchEvent(new Event("resize"));
                    if (mount && mount.childElementCount === 0) {
                        mount.classList.add("d-none");
                    }
                }, 350);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [el.current]);

    return createPortal((title || icon ?
        <>{
            panelVisible &&
            <>
                <div className='p-3 w-min-3 shadow-sm'>
                    <div className='d-flex flex-row justify-content-between'>
                        <div className='titleContainer'>
                            {title && <IconTitle size={SIZE.m} bold={true} icon={icon} title={title} />}
                        </div>
                        <div>
                            {!notResizable && <Button className={expand ? "minimize-details-panel-button" : "maximize-details-panel-button"} icon={expand ? IconNames.MINIMIZE : IconNames.MAXIMIZE} onClick={() => {
                                setExpand(!expand);
                            }} minimal />}
                            <Button className="close-button" icon={IconNames.CROSS} onClick={() => {
                                setPanelVisible(false);
                                if (onCloseClicked) {
                                    onCloseClicked();
                                }
                            }} minimal />
                        </div>

                    </div>
                </div>
                <div className='border-top overflow-x-hidden h-100 details-panel-content'>
                    {children}
                </div>
            </>
        }
        </> : children), el.current);
};
