/** This file defines the cloudim rules card component. The card
 * is used to show information regarding the rules.
 *  @module */
import { useState } from "react";
import { TIME_FORMAT } from "components/enums/Time.ts";
import { STRINGS } from "app-strings";
import { formatToLocalTimestamp } from "reporting-infrastructure/utils/formatters/GeneralFormatter.ts";
import { SIZE } from 'components/enums/Sizes.ts';
import { Button, Menu, MenuDivider, MenuItem, Position, Tag, Popover } from "@blueprintjs/core";
import { Icon, IconNames } from "@tir-ui/react-components";
import { Card as CardContainer } from 'components/reporting/containers/card/Card.tsx';
import { JsonViewer } from "pages/incident-details/views/primary-indicator/JsonViewer.tsx";
import { BasicDialog, updateDialogState } from "components/common/basic-dialog/BasicDialog.tsx";
import { WrapInTooltip } from "components/common/wrap-in-tooltip/WrapInTooltip.tsx";

import "./RulesCard.scss";

export interface RulesCardProps {
    /** additional styles to the card. */
    className?: string,
    /** an optional parameter which specifies the height of the card. */
    height?: SIZE.xs | SIZE.s | SIZE.m | SIZE.l,
    /** the rules detail */
    rules: any;
}

const RulesCard = ({ className, height = SIZE.s, rules }: RulesCardProps): JSX.Element => {
    const [dialogState, setDialogState] = useState<any>({
        showDialog: false,
        loading: false,
        title: "",
        dialogContent: null,
        dialogFooter: null,
    });

    return <>
        <BasicDialog
            className="rules-dialog"
            dialogState={dialogState}
            onClose={() =>
                setDialogState(updateDialogState(dialogState, false, false, []))
            }
        />
        <CardContainer className={`rules-card card-height-${height}` + (className ? " " + className : "")}>
            <div className="d-flex flex-column justify-space-between p-3">
                <div className="d-flex justify-content-between align-items-center">
                    <Tag className={rules.enabled ? "rules-enabled" : "rules-disabled"}>
                        {rules.enabled ? STRINGS.cloudimRules.rulesCard.status.enabled : STRINGS.cloudimRules.rulesCard.status.disabled}
                    </Tag>
                    <Popover
                        lazy
                        autoFocus={false}
                        enforceFocus={false}
                        position={Position.LEFT_TOP}
                        content={<Menu>
                            <MenuItem icon={IconNames.EDIT} text={STRINGS.cloudimRules.rulesCard.menu.edit} disabled />
                            <MenuItem icon={IconNames.DUPLICATE} text={STRINGS.cloudimRules.rulesCard.menu.duplicate} disabled />
                            <MenuItem icon={IconNames.TRASH} text={STRINGS.cloudimRules.rulesCard.menu.delete} disabled />
                            <MenuDivider />
                            <MenuItem icon={IconNames.APPLICATION} text={STRINGS.cloudimRules.rulesCard.menu.view} onClick={() => {
                                setDialogState({
                                    showDialog: true,
                                    loading: false,
                                    title: rules.ruleName,
                                    dialogContent: <JsonViewer json={rules} />,
                                    dialogFooter: null
                                });
                            }} />
                            <MenuItem icon={IconNames.SETTINGS} text={rules.enabled ? STRINGS.cloudimRules.rulesCard.menu.disable : STRINGS.cloudimRules.rulesCard.menu.enable} disabled />
                        </Menu>}
                    >
                        <Button icon={IconNames.MENU} minimal />
                    </Popover>
                </div>
                <div className="rules-card-body">
                    <div className="rules-info">
                        <div className="rules-name-type">
                            <div className="rule-name">
                                {rules.ruleName}
                            </div>
                            <div className="text-secondary">
                                {rules.ruleType.rootElement.asset}: {rules.ruleType.rootElement.category}
                            </div>
                        </div>
                        <div className="rule-description">
                            {rules.description}
                        </div>
                    </div>
                    <div className="rule-tags">
                        {rules.tags.rootElement.map((tag: { [key: string]: number }, index: number) => {
                            return <div key={`rule-tag-${index}`} className={"d-inline-flex" + (index !== 0 ? " ms-2" : "")}>
                                {Object.keys(tag).map((key) => {
                                    return <div key={`rule-tag-${key}-${index}`} className="rule-tag">
                                        <span className="rule-tag-key">{key}</span>
                                        <span className="rule-tag-value">{tag[key]}</span>
                                    </div>
                                })}
                            </div>
                        })}
                    </div>
                </div>
                <div className="rules-card-footer">
                    <div>
                        <Icon className="me-1" icon={IconNames.USER} />
                        <span>
                            {rules.metaData.rootElement.author}
                        </span>
                    </div>
                    <WrapInTooltip tooltip={<>
                        <div>
                            {`${STRINGS.cloudimRules.rulesCard.lastUpdated} ${formatToLocalTimestamp(rules.timestamp, TIME_FORMAT.DISPLAY_TIME_FORMAT)}`}
                        </div>
                    </>}>
                        <Icon className="me-1" icon={IconNames.CALENDAR} />
                        <span>
                            {formatToLocalTimestamp(rules.lastUpdated, TIME_FORMAT.DISPLAY_TIME_FORMAT)}
                        </span>
                    </WrapInTooltip>
                </div>
            </div>
        </CardContainer>
    </>
};

export { RulesCard };
