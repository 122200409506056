query cloudImResSummaryByRegion($token: [String!]!) {
    regionSummary: cloudImResSummaryByRegion(
        filter: {
            token: $token
        }
    ) {
        awsResources {
            region
            entityKind
            count
        }
        netImDevices {
            deviceType
            countryCode
            city
            longitude
            latitude
            count
            locationName
        }
        azureResources {
            region
            entityKind
            count
        }
    }
}
 