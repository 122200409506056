query npmPlusDataSource {
    npmPlusDataSource {
        id
        queriesEnabled
        info {
            status
            error {
                code
                message
                innerError {
                    id
                    trace
                    properties {
                        key
                        value
                    }
                }
                details {
                    code
                    message
                    innerError {
                        id
                        trace
                        properties {
                            key
                            value
                        }
                    }
                }
            }
        }
        account {
            id
        }
    }
}
