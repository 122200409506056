import { SIZE } from 'components/enums/Sizes.ts';
import { Outline } from 'components/enums/General.ts';
import { Position, Popover, Classes } from '@blueprintjs/core';
import { Icon } from '@tir-ui/react-components';
import { APP_ICONS } from 'components/sdwan/enums/icons.ts';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { RoundedLinkButton } from 'components/common/rounded-link-button/RoundedLinkButton.tsx';
import { EventNames, trackEvent } from 'utils/appinsights/AppInsights.ts';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { AuthServiceProvider } from 'utils/providers/AuthServiceProvider.ts';
import { STRINGS } from 'app-strings';

const AuthService = AuthServiceProvider.getService();

export interface CopyLinkButtonProps {
	className?: string;
	outline?: Outline;
	size?: SIZE.xs | SIZE.s | SIZE.m;
	href: string;
}

const CopyLinkButton = (props: CopyLinkButtonProps): JSX.Element => {
	const { size, href } = props;
	const appInsightsContext = useAppInsightsContext();

	const popupContent = () => {
		return (
			<div className="w-2 p-2">
				<span>{STRINGS.share_help_text}</span>
			</div>
		);
	};

	const handleOpened = () => {
		reportMetrics();
	};

	// report metrics to App Insights
	const reportMetrics = () => {
        if (appInsightsContext) {
            const properties = {
                name: EventNames.SEARCH_INVOKE
            };
            trackEvent(appInsightsContext, AuthService, properties);
        }
    };

	return (
		<div>
			<Popover
				content={popupContent()}
				usePortal={true}
				position={Position.BOTTOM_LEFT}
				onOpened={handleOpened}
                interactionKind="hover"
			>
				<CopyToClipboard text={href}>
                    <RoundedLinkButton
                        className={Classes.POPOVER_DISMISS + ' copy-view-link'}
                        size={size ? size : SIZE.s}
                        outline={Outline.SHOW}
                        text={STRINGS.copy_view}
                        icon={<Icon icon={APP_ICONS.LINK} />}
                    />
                </CopyToClipboard>
			</Popover>
		</div>
	);
};

export { CopyLinkButton };
