import React from 'react';
import { FormGroup, HTMLSelect } from '@blueprintjs/core';
import { ErrorMessage, useField } from 'formik';

const optionsRenderer = (options) => {
    if (options) {
        return options.map(function (object: string, i: number) {
            return <option value={object} key={i}>{object}</option>;
        })
    } else {
        return null;
    }
};

function SelectField({label, required, helperText, options, children, ...props}: any) {

    const [field, meta] = useField(props);

    return (
        <FormGroup label={label} labelFor={field.name} labelInfo={required ? '* ' : ''} helperText={helperText}>
            <HTMLSelect fill id={field.name} {...field} intent={meta.touched && meta.error ? 'danger' : 'none'}{...props}>
                {
                    children ? children : optionsRenderer(options)
                }
            </HTMLSelect>
            <ErrorMessage name={field.name}/>
        </FormGroup>
    );
}

export { SelectField };
