import { useEffect, useState } from "react";
import { Button, Intent } from "@blueprintjs/core";
import {
    type DialogState,
    BasicDialog,
    updateDialogState,
} from "components/common/basic-dialog/BasicDialog.tsx";
import { MSALClientAuthErrorsMap } from "./constants.ts";
import "./MainAppErrorDialog.scss";

export const MainAppErrorDialog = ({ showDialog, errorData, onClose }) => {
    const [dialogState, setDialogState] = useState<DialogState>({
        showDialog: false,
        title: "",
        loading: false,
        errors: [] as Array<string> | undefined,
        dialogContent: <></>,
        dialogFooter: (
            <>
                <Button
                    className="ms-0"
                    text={"OK"}
                    intent={Intent.PRIMARY}
                    onClick={handleModalClose}
                />
            </>
        ),
    });

    useEffect(() => {
        if (showDialog) {
            const message = MSALClientAuthErrorsMap[errorData?.statusCode] ?  
                                MSALClientAuthErrorsMap[errorData?.statusCode] : 
                                'An error has occured during Authentication.';
            
            console.error(errorData);

            setDialogState(
                updateDialogState(
                    {
                        ...dialogState,
                        dialogContent: <>{message}</>,
                    },
                    true,
                    false,
                    []
                )
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDialog, errorData]);

    if (!showDialog) {
        return <></>;
    }

    return (
        <BasicDialog
            dialogState={dialogState}
            className="main-page--error-dialog"
            onClose={handleModalClose}
        />
    );

    function handleModalClose() {
        setDialogState(updateDialogState(dialogState, false, false, []));
        onClose();
    }
};
