/** This module contains the GenAiService that can be used to query the generative AI API
 *  @module
 */
import { ApiService } from 'utils/services/ApiService.ts';

/** This enum defines all the valid values for the GenAI request role. */
export enum GenAiRequestRole {
    /** the enumerated type for the system role. */
    system      = "system",
    /** the enumerated type for the user role. */
    user        = "user"
}

/** This enum defines all the valid values for the GenAi request content type. */
export enum GenAiRequestContentType {
    /** the enumerated value for text content. */
    text        = "text"
}

/** this interface defines the content for the GenAI request. */
export interface GenAiRequestContent {
    /** a GenAiRequestContentType enumerated value that specifies what type of content is in the request. */
    type: GenAiRequestContentType;
    /** a String with the request contnent. */
    text: string;
}
/** this interface defines the GenAI request message. */
export interface GenAiRequestMessage {
    /** the GenAiRequestRole enumerated value with the role. */
    role: GenAiRequestRole;
    /** the array of GenAiRequestContent objects. */
    content: GenAiRequestContent[];
}

/** this interface defines the genai request, that is used to run an AI query against the 
 *  AI service. */
export interface GenAiRequest {
    /** an array of GenAiRequestMessage objects. */
    messages: GenAiRequestMessage[];
}

/** This enum defines all the valid values for the type of response to a GenAI query. */
export enum GenAiResponseType {
    /** the enumerated value for text content. */
    text        = "text"
}

/** This enum defines all the valid values for the GenAi response role. */
export enum GenAiResponseRole {
    /** the enumerated value for response assistance role content. */
    assistant   = "assistant"
}

/** This enum defines all the valid values for the response conent type. */
export enum GenAiResponseContentType {
    /** the enumerated value for text content. */
    text        = "text"
}

/** this interface defines the content for the GenAI response. */
export interface GenAiResponseContent {
    /** a GenAiResponseContentType enumerated value that specifies what type of content is in the response. */
    type: GenAiResponseContentType;
    /** a String with the request contnent. */
    text: string;
}

/** this interface defines the GenAI response message. */
export interface GenAiResponseMessage {
    /** the GenAiResponseRole enumerated value with the role. */
    role: GenAiResponseRole;
    /** the array of GenAiResponseContent objects. */
    content: GenAiResponseContent[];
}

/** this interface defines the genai request that returns the result of a genai query. */
export interface GenAiResponse {
    /** an array of GenAiResponseMessage objects. */
    messages: GenAiResponseMessage[];
}

/** this interface defines the genai prompt request, that is used to improve the customer's
 *      prompt request. */
export interface GenAiPromptRequest {
    /** a String with the customer's initial prompt. */
    prompt: string;
}

/** this interface defines the genai prompt request that returns the suggested prompt
 *  based on the user's instructions. */
export interface GenAiPromptResponse {
    /** a String with the suggested prompt. */
    prompt: string;
}

// The URL for the API server.
export const GEN_AI_BASE_URL = '/api/affogato/';

/** this class defines the GenAiApiService. */
class GenAiApiService extends ApiService {
    /** the constructor for the class. */
    constructor() {
        super(GEN_AI_BASE_URL);
    }

    /** returns the base uri, this can be overridden in subclasses to allow the uri to change
     *      after construction.
     *  @returns a String with the base uri. */
     protected getBaseUri(): string {
        if (ApiService.USE_REGION) {
            const region = ApiService.AUTH_SERVICE.getRegion();
            return `/api/iq/${region}/`;    
        } else {
            return this.baseApiUri;
        }
    }

    /** runs the specified generative AI query and returns the generative AI response.
     *  @param request the GenAiRequest object with the question infomration.
     *  @returns a Promise which resolves to the returned data. */
    runAiRequest(request: GenAiRequest): Promise<GenAiResponse> {
        return new Promise((resolve, reject) => {
            const utid = ApiService.AUTH_SERVICE.getTenantId();
            super
                .post<any>(`genai/1.0/tenants/${utid}/chat`, request)
                .then(
                    (response: GenAiResponse) => {
                        resolve(response);
                    },
                    (err) => {
                        reject(err);
                    }
                );
        });
    }

    /** generate a prompt for the AI service.
     *  @param request the GenAiPrompRequest object with the user's initial prompt.
     *  @returns a Promise which resolves to the returned prompt. */
    generatePrompt(request: GenAiPromptRequest): Promise<GenAiPromptResponse> {
        return new Promise((resolve, reject) => {
            const utid = ApiService.AUTH_SERVICE.getTenantId();
            super
                .post<GenAiPromptRequest>(`genai/1.0/tenants/${utid}/enhance-prompt`, request)
                .then(
                    (response: GenAiPromptResponse) => {
                        resolve(response);
                    },
                    (err) => {
                        reject(err);
                    }
                );
        });
    }
}

/** a constant with an instance of the GenAiApiService. */
const GenAiService = new GenAiApiService();
export { GenAiService };
