/** This module contains a component that ....
 *  @module
 */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { isEqual } from 'lodash';
import { DefaultFieldRenderer } from "./condition/condition-value/BuiltinValueFields";
import { STRINGS } from "app-strings";
import "./WrapFieldInDisplayMode.scss";

type valueType = any;
const ENTER_KEY = 13;

/** this interface defines the properties passed into the WrapFieldInDisplayMode React component. */
export interface WrapFieldInDisplayModeProps {
    className?: string;
    initEditMode?: boolean;
    value?: valueType;
    onChange?: (newValue) => void;
    fieldRenderer?: (props: {
        value: valueType,
        onChange: (newValue: valueType) => valueType,
        onDone: () => void,
    }) => React.ReactNode;
    displayValueFormatter?: (props: { value: valueType }) => React.ReactNode;
}

/** Renders the field display mode wrapper.
 *  @param props the properties object passed in.
 *  @returns the JSX with the field display mode wrapper React component. */
export function WrapFieldInDisplayMode ({
    className,
    initEditMode = false,
    value,
    onChange,
    fieldRenderer = props => DefaultFieldRenderer({ value: props.value, onChange: props.onChange }),
    displayValueFormatter,
}: WrapFieldInDisplayModeProps): JSX.Element {
    const [editMode, setEditMode] = useState(initEditMode);
    const [currentValue, setCurrentValue] = useState(value);
    const onChangeRef = useRef(onChange);
    onChangeRef.current = onChange;
    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        }
    }, []);
    function onValueChangeHandler ({value}) {
        setCurrentValue(value);
    }
    const onEditDone = useCallback(() => {
        if (isMounted.current) {
            setEditMode(false);
            if (onChangeRef.current) {
                onChangeRef.current(currentValue);
            }
        }
    }, [setEditMode, currentValue]);
    // If value param changes and is different from current value, update local state
    useEffect(() => {
        if (value !== undefined && !isEqual(currentValue, value)) {
            setCurrentValue(value);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    if (editMode) {
        return <span
            className={"wrap-field-in-display-mode edit-mode" + (className ? " " + className : "")}
            // onBlur={onEditDone}
            onBlur={() => setTimeout(onEditDone, 30)}
            onKeyDown={e => {
                if (e.keyCode === ENTER_KEY) {
                    onEditDone();
                }
            }}
        >   
            {
                fieldRenderer({
                    value: currentValue,
                    onChange: onValueChangeHandler,
                    onDone: onEditDone,
                })
            }
        </span>
    } else {
        const formattedValue = displayValueFormatter ?
            displayValueFormatter({ value: currentValue }) :
            currentValue;
        return <span
            className={"wrap-field-in-display-mode display-mode clickable" + (formattedValue ? "" : " empty") + (className ? " " + className : "")}
            onClick={() => setEditMode(true)}
        >
            {!formattedValue && <span className="font-italic d-block" style={{marginTop: "2px"}}>{STRINGS.runbookEditor.nodeEditor.addValue}</span>}
            { formattedValue }
        </span>;
    }
}
