query Locations($filter: LocationFilterInputType) {
    locations(filter: $filter) {
        page {
            id
            name
            country
            countryCode
            city
            state
            type
            longitude
            latitude
            elementType
            customProperties {
                id
                name
                description
                type
                permissions
                assignedValue {
                    id
                    name
                }
                validTypes {
                    type
                }
                values {
                    id
                    name
                }
            }
            networkDevices {
                ipAddress
                id
                elementType
                name
                hostname
                type
                model
                serialNumber
                osVersion
                vendor
                isGateway
            }
            reportedBy {
                dataSourceType
                dataSourceId
            }
        }
    }
}
