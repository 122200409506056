mutation deleteClientApplicationSecret($appId: ID!, $secretId: ID!) {
  deleteClientApplicationSecret( 
		clientApplicationId: $appId
    clientApplicationSecret: { secretId: $secretId }
  ) {
		secretId
		description
		expiration
  }
}
