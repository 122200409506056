/** This file defines the CorrelationView React component.  The CorrelationView React component wraps either a
 *  CorrelationChart or MultiCorrelationChart component and includes the query to get the data.
 *  @module */

import { Query } from "reporting-infrastructure/types/Query.ts";
import { useQuery } from "utils/hooks/useQuery.ts";
import { FILTER_NAME } from "components/sdwan/enums/filters.ts";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade.tsx";
import { BaseRunbookViewProps, Column, DataSet } from "pages/riverbed-advisor/views/runbook-view/Runbook.type.ts";
import { Unit } from "reporting-infrastructure/types/Unit.class.ts";
import { CorrelationChart, CorrelationChartDatum } from "components/common/correlation-chart/CorrelationChart.tsx";
import { formatKeys } from "utils/runbooks/RunbookFormatter.tsx";
import { formatValue, getComparisonParameters, keysMatch } from "utils/runbooks/RunbookOutputUtils.ts";
import { LegendPosition } from "components/common/chart-base/ChartToolbar.tsx";
import { INCIDENT_DETAILS_STYLE } from "components/enums/QueryParams.ts";
import { GroupMetricEvent } from "components/common/chart-base/ChartBase.tsx";
import TIMESERIES_QUERY from '../timeseries-view/timeseries-query.graphql';

/** an interface that describes the properties that can be passed in to the component.*/
export interface CorrelationViewProps extends BaseRunbookViewProps {
    // Add any properties that are specific to this view
    /** Pass as true to render the chart with transparent background. */
    transparent?: boolean;
}

/** Creates the correlation chart view, which is a component that displays a chart with analytics data.
 *  @param props an object with the properties passed to the correlation series view.
 *  @returns JSX with the correlation series chart component.*/
export const CorrelationView = (props: CorrelationViewProps): JSX.Element => {
    let {comparisonOffset = 0, comparisonSuffix = ""} = getComparisonParameters(props.widget?.options?.comparedTo as string);
    let primaryDataset: DataSet | undefined = undefined;
    let comparisonDataset: DataSet | undefined = undefined;
    if (props.datasets) {
        for (const dataset of props.datasets) {
            if (!dataset.isComparison) {
                primaryDataset =  dataset;
            } else {
                comparisonDataset = dataset;
            }
        }
    }
    const showLegend = props.widget?.options?.showLegend !== undefined ? props.widget.options.showLegend as boolean : true;
    const legendPosition = props.widget?.options?.legendPosition ? props.widget?.options?.legendPosition as any : LegendPosition.top;
    const xMetric: string | undefined = props.widget?.options?.xMetric as string | undefined;
    const yMetric: string | undefined = props.widget?.options?.yMetric as string | undefined;

    let { loading, data, error } = useQuery({
        query: new Query(TIMESERIES_QUERY),
        requiredFilters: [FILTER_NAME.incidentId, FILTER_NAME.datasetId],
        filters: {
            [FILTER_NAME.incidentId]: props.incidentId,
            [FILTER_NAME.datasetId]: primaryDataset?.datapoints ? undefined : primaryDataset?.id
		    },
        skipGlobalFilters: true
    });

    const tsKeyData: Array<CorrelationChartDatum> = [];
    const compData: Array<CorrelationChartDatum> = [];
    if (primaryDataset?.metrics?.length && primaryDataset?.metrics?.length >= 2) {
        let xMetricIndex = -1;
        let yMetricIndex = -1;
        for (let metricIndex = 0; metricIndex < primaryDataset.metrics.length; metricIndex++) {
            if (primaryDataset.metrics[metricIndex].id === xMetric) {
                xMetricIndex = metricIndex;
            }
            if (primaryDataset.metrics[metricIndex].id === yMetric) {
                yMetricIndex = metricIndex;
            }
        }
        if (!loading && xMetricIndex > -1 && yMetricIndex > -1) {
            // We found both the x-axis and y-axis metric
            const xMetricDef: Column = primaryDataset.metrics[xMetricIndex];
            const yMetricDef: Column = primaryDataset.metrics[yMetricIndex];
            if (primaryDataset.datapoints) {
                data = {};
                data.datapoints = primaryDataset.datapoints;
            }
            if (data && data?.datapoints?.length > 0) {
                for (let groupIndex = 0; groupIndex < data.datapoints.length; groupIndex++) {
                    const dataPoint = data.datapoints[groupIndex];
                    const group: string = formatKeys(primaryDataset.keys || [], dataPoint.keys) as string;
                    let ts: Array<any> = [];
                    for (const timestamp in dataPoint.data) {
                        let xValue = formatValue(dataPoint.data[timestamp][xMetricDef.id], xMetricDef);
                        let yValue = formatValue(dataPoint.data[timestamp][yMetricDef.id], yMetricDef);
                        ts.push({ x: xValue, y: yValue });
                    }
                    tsKeyData.push({
                        groupName: group, groupId: group, 
                        xMetricName: primaryDataset!.metrics![xMetricIndex].label, xMetricId: primaryDataset!.metrics![xMetricIndex].id, 
                        xUnit: Unit.parseUnit(primaryDataset!.metrics![xMetricIndex].unit || ""), 
                        yMetricName: primaryDataset!.metrics![yMetricIndex].label, yMetricId: primaryDataset!.metrics![yMetricIndex].id, 
                        yUnit: Unit.parseUnit(primaryDataset!.metrics![yMetricIndex].unit || ""), 
                        data: ts,
                        group: dataPoint.keys?.group
                    });

                    // If there is comparison data add the comparison data
                    if (dataPoint?.keys && comparisonDataset?.datapoints) {
                        for (const checkDatapoint of comparisonDataset.datapoints) {
                            if (keysMatch(dataPoint, checkDatapoint)) {
                                let ts: Array<any> = [];
                                for (const timestamp in checkDatapoint.data) {
                                    let xValue = formatValue(checkDatapoint.data[timestamp][xMetricDef.id], xMetricDef);
                                    let yValue = formatValue(checkDatapoint.data[timestamp][yMetricDef.id], yMetricDef);
                                    ts.push({ x: xValue, y: yValue });
                                }
                                compData.push({
                                    groupName: group, groupId: group, 
                                    xMetricName: primaryDataset!.metrics![xMetricIndex].label, xMetricId: primaryDataset!.metrics![xMetricIndex].id, 
                                    xUnit: Unit.parseUnit(primaryDataset!.metrics![xMetricIndex].unit || ""), 
                                    yMetricName: primaryDataset!.metrics![yMetricIndex].label, yMetricId: primaryDataset!.metrics![yMetricIndex].id, 
                                    yUnit: Unit.parseUnit(primaryDataset!.metrics![yMetricIndex].unit || ""), 
                                    data: ts,
                                    group: dataPoint.keys?.group
                                });
                                break;
                            }
                        }
                    }
                }
            }
        }
    }

    return <DataLoadFacade loading={loading || props.loading} error={error || props.error} data={data}>
        <CorrelationChart
            loading={false} showChartSubtitle={false} primaryData={tsKeyData} compData={compData}
            transparent={props.transparent} height={props.height}
            enableFullScreen={true} settings={{
                showLegend,  legendPosition, 
                showMore: (props?.widget?.options?.showMore !== undefined ? props.widget.options.showMore as boolean : true), 
                showIconsOnHover: true
            }}
            comparisonOffset={comparisonOffset} comparisonSuffix={comparisonSuffix}
            fullScreenTitle={props.widget?.name}
            // Hack Alert: Fix for Bugs 10501, 10231
            reflowOnResize={true} hideShadow={INCIDENT_DETAILS_STYLE === "noTableOneCardForEachWidget"}
            onGroupMetricSelection={(event: GroupMetricEvent) => {
                if (props.onGroupsAndMetricsSelected) {
                    props.onGroupsAndMetricsSelected([...(event.groups || [])], [...(event.metrics || [])]);
                }
            }}
        />
    </DataLoadFacade>;
};

/** returns any enum metric text that should be added to a group name or metric name.
 *  @param column the Metric column to check to see if it is an enumeration.
 *  @returns a String with the text summarizing the enumeration or empty string if the column 
 *      is not an enumeration. */
export function getEnumText(column: Column): string {
    let enumText = "";
    if (column.enum) {
        const enumKeys = Object.keys(column.enum);
        const enumArray: Array<string> = [];
        for (const enumKey of enumKeys) {
            enumArray.push(enumKey + " = " + column.enum[enumKey]);
        }
        enumText = " (" + enumArray.join(", ") + ")";
    }
    return enumText;
}
