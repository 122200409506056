/** This module contains a react component that displays the debug content for the runbook output
 *  debug dialog.
 *  @module
 */

import { useState, useMemo } from "react";
import { STRINGS } from "app-strings";
import { PARAM_NAME } from "components/enums/QueryParams.ts";
import { useQueryParams } from "utils/hooks/useQueryParams.ts";
import { DebugOutput, DEBUG_OUTPUT_TYPE, RunbookOutput } from "pages/riverbed-advisor/views/runbook-view/Runbook.type";
import { getNode, processDataset } from "utils/runbooks/RunbookUtils";
import { JsonViewer } from "../primary-indicator/JsonViewer";
import { HTMLSelect } from "@blueprintjs/core";

/** if this is true see if a node is connected to a visualization widget and use it's title for the name.
 *  If false, then just use the node label and do not look for the vis title. */
const SHOW_VIS_TITLE: boolean = false;

/** This interface defines the properties passed into the debug dialog content component.*/
export interface DebugDialogContentProps {
    /** the data set that contains the DataSetInfo object that gives the following information: the actual times, data sources, filters, errors, etc. */
    runbook?: RunbookOutput;
    /** the handler for JSON Output changed events. */
    onJsonOutputChanged?: (jsonText: any) => void;
}

/** Renders the debug dialog content.
 *  @param props the properties passed in.
 *  @returns JSX with the debug dialog content React component.*/
export const DebugDialogContent = (props: DebugDialogContentProps): JSX.Element => {
    const { runbook, onJsonOutputChanged } = props;

    let { params } = useQueryParams({ listenOnlyTo: [PARAM_NAME.debug] });
    const showDebugInfo = params[PARAM_NAME.debug] === "true";
    if (showDebugInfo) {
        console.log("We will support debug mode in the future and show the stack traces");
    }

    const [selectedComponent, setSelectedComponent] = useState<string | undefined>(undefined);

    const { componentOptions, jsonOutput, currentSelection } = useMemo(() => {
        const jsonByComponentName: Record<string, Array<Array<DebugOutput>>> = {};
        if (runbook?.datasets) {
            const datasets = runbook.datasets;
            const template = runbook.template ? JSON.parse(runbook.template) : undefined;
            if (datasets?.length && template) {
                // Analyze the errors and warnings and categorize them as widget level errors or general errors
                for (const dataset of datasets) {
                    const processedDataset = processDataset(dataset, template.nodes);
                    if (processedDataset && SHOW_VIS_TITLE) {
                        if (processedDataset.widgets?.length && processedDataset?.debug) {
                            for (const widget of processedDataset.widgets) {
                                let widgetName = widget?.name && widget.name.trim() !== "" ? widget.name : STRINGS.runbookOutput.errorDialog.unknownWidgetText;
                                if (!jsonByComponentName[widgetName]) {
                                    jsonByComponentName[widgetName] = [];
                                }
                                if (processedDataset?.debug) {
                                    jsonByComponentName[widgetName].push(processedDataset.debug);
                                }
                            }
                        }
                    } else {
                        // This dataset is not associated with any widget, get the nodes name and associate it with
                        // the node itself.
                        let datasetId = dataset.id;
                        if (datasetId.includes(":")) {
                            const refText = dataset.timeReference?.name ? ":" + dataset.timeReference?.name : undefined;
                            if (refText && datasetId.endsWith(refText)) {
                                datasetId = datasetId.substring(0, datasetId.length - refText.length);
                            }
                            datasetId = datasetId.substring(0, datasetId.indexOf(":"));
                        }
                        const datasetNode = getNode(datasetId, template.nodes);
                        if (datasetNode) {
                            const dsName = datasetNode.label || "Unknown";
                            if (!jsonByComponentName[dsName]) {
                                jsonByComponentName[dsName] = [];
                            }
                            if (dataset.debug) {
                                jsonByComponentName[dsName].push(dataset.debug);
                            }
                        }
                    }
                }
            }
        }

        const components = Object.keys(jsonByComponentName);
        const currentSelection = selectedComponent || (components?.length ? components[0] : "Unknown");

        let jsonOutput = {};
        const componentOptions: Array<{ label: string, value: string }> = [];
        if (components?.length) {
            for (const component of components) {
                componentOptions.push({ label: component, value: component });
                if (component === currentSelection) {
                    for (let index = 0; index < jsonByComponentName[component].length; index++) {
                        const dsName = "Dataset: " + (index + 1);
                        jsonOutput[dsName] = {};
                        const dsErrors = jsonByComponentName[component][index];
                        for (const debugOutput of dsErrors) {
                            if (debugOutput.type === DEBUG_OUTPUT_TYPE.JSON) {
                                jsonOutput[dsName][debugOutput.name] = JSON.parse(debugOutput.value);
                            }
                        }
                    }
                }
            }
        }
        if (onJsonOutputChanged) {
            onJsonOutputChanged(jsonOutput);
        }
    
        return { jsonOutput, componentOptions, currentSelection };
    }, [runbook, selectedComponent, onJsonOutputChanged]);

    return <>
        <div><label>Component: </label>
            <HTMLSelect id="select-debug-component" name="select-debug-component"
                disabled={false}
                value={selectedComponent || currentSelection}
                onChange={(event) => setSelectedComponent(event.target.value)}
                options={componentOptions} className="select-debug-component ms-3"
            />
        </div>
        <div>
            <JsonViewer json={jsonOutput} />
        </div>
    </>;
}
