mutation createClientApplicationSecret($appId: ID!, $description: String!, $expiration: String!) {
  createClientApplicationSecret( 
		clientApplicationId: $appId
    clientApplicationSecret: { description: $description, expiration: $expiration}
  ) {
		secretId
		secretText
		description
		expiration
  }
}
