query CustomPropertyDetails($filter: CustomPropertyFilterInputType, $top: Int, $skip: Int, $order: [CustomPropertySortInput!]) {
    customProperties(
        filter: $filter, 
        top: $top, skip: 
        $skip,
        order: $order
        ) {
        page {
            lastUpdatedAt
            values {
                id
                name
            }
            user {
                id
                name
                email
            }
            id
            name
            description
            type
            validTypes {
                type
            }
        }
        info {
            totalCount
        }
    }
}
