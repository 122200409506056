import { GetRoutes as getRoutes } from 'config/routes.ts';
import { PageWithHeader } from 'components/sdwan/layout/page-with-header/PageWithHeader.tsx';
import { MappingConfigurationView } from './views/MappingConfigurationView.tsx';
import { HELP, STRINGS } from 'app-strings';

const MappingConfigurationPage = (): JSX.Element => {
    return (
        <PageWithHeader
            name={MappingConfigurationPage.name}
            addPadding={true}
            showTimeBar={false}
            showGlobalFilters={false}
            {...getRoutes()['mapping-configuration']}
            title={STRINGS.MAPPING_CONFIGURATION_PAGE.pageTitle}
            helpInfo={HELP.automationMap}
        >
            <MappingConfigurationView />
        </PageWithHeader>
    );
};

export { MappingConfigurationPage };
