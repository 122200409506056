/** This module contains the React component for the dashboard list page.
 *  @module
 */
import { STRINGS, HELP } from 'app-strings';
import { PageWithHeader } from 'components/sdwan/layout/page-with-header/PageWithHeader';
import { SDWAN_ICONS } from 'components/sdwan/enums/icons';
import { DashboardListView } from './views/dashboard-list/DashboardList';

/** Renders the dashboard list page.
 *  @param props the properties passed in.
 *  @returns JSX with the dashboard list page component.*/
const DashboardListPage = (props): JSX.Element => {
    return (
        <PageWithHeader name="DashboardListPage" title={STRINGS.dashboards.title} 
            icon={SDWAN_ICONS.RUNBOOK} addPadding={true} showTimeBar={false} helpInfo={HELP.runbookList}
        >
            <DashboardListView/>
        </PageWithHeader>
    );
};

export default DashboardListPage;
