import { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import 'highcharts/modules/no-data-to-display';
import 'highcharts/modules/timeline';
import 'highcharts/modules/exporting';
import 'highcharts/modules/export-data';
import { STRINGS } from 'app-strings';
import { EVENT_CATEGORY } from 'pages/incident-list/Incident.type.ts';
import { Button } from '@blueprintjs/core';

function TimelineChart(props) {
  const chartRef = useRef<HighchartsReact.RefObject>(null);

  useEffect(() => {
    if (props.dataSeries) {
      let count = 0;
      const intervalId = setInterval(() => {
        if (chartRef?.current?.chart) {
          chartRef.current.chart.redraw();
        }
        count++;
        if (count >= 2) {
          clearInterval(intervalId);
        }
      }, 100);
      return () => clearInterval(intervalId);
    }
  }, [props.dataSeries]);

    const { pick, defined, fireEvent } = Highcharts;
    /** Extends legend.getAllItems method to show legend items per series */
    Highcharts.wrap(Highcharts.Legend.prototype, 'getAllItems', function (this: any) {
        let allItems = [];
        this.chart.series.forEach(series => {
            const seriesOptions = series?.options;
            // Handle showInLegend. If the series is linked to another series, defaults to false.
            if (series && pick(seriesOptions.showInLegend, !defined(seriesOptions.linkedTo))) {
                // Use points or series for the legend item depending on legendType
                allItems = allItems.concat(series.legendItem?.labels || series);
            }
        });
        fireEvent(this, 'afterGetAllItems', { allItems });
        return allItems;
    });
    
    /** It replaces/adds specific keyword with a break */
    const editLabelStrings = (label) => {
        switch(props.incident.eventCategory) {
            case EVENT_CATEGORY.MULTI_DEVICE_DOWN_ISSUE:
                return `<span>${label.replace(/\b(?:unreachable)\b/g, x => x + `</br>`)}</span>`
            case EVENT_CATEGORY.SINGLE_LOC_MULTI_APP_PERFORMANCE_ISSUE:
                return `<span>${label.replace(/\b(?:exhibiting|experiencing)\b/g, x => x + `</br>`)}</span>`
            case EVENT_CATEGORY.SINGLE_APP_MULTI_LOC_PERFORMANCE_ISSUE:
                return `<span>${label.replace(/\b(?:in|experiencing)\b/g, x => x + `</br>`)}</span>`
            case EVENT_CATEGORY.DEVICE_DOWN_ISSUE:
                return `<span>${label.replace(/\b(?:is|has)\b/g, x => `</br>` + x)}</span>`
            case EVENT_CATEGORY.INTERFACE_DOWN_ISSUE:
            case EVENT_CATEGORY.INTERFACE_PERFORMANCE_ISSUE:
                return `<span>${label.replace(/\b(?:has)\b/g, x => `</br>` + x)}</span>`
            case EVENT_CATEGORY.APPLICATION_LOCATION_PERFORMANCE_ISSUE:
                return `<span>${label.replace(/\b(?:shows|exhibiting|degraded)\b/g, x => x + `</br>`)}</span>`
            default:
                return `<span>${label}</span>`
        }
    }

    const countEntityKinds = (entityKinds = []) => {
        if (!entityKinds.length) return;

        const counts = entityKinds.reduce((acc: { [key: string]: number }, kind: string) => {
            acc[kind] = (acc[kind] || 0) + 1;
            return acc;
        }, {});

        return Object.entries(counts)
            .map(([kind, count]) => {
                const label = STRINGS.incidents.entityKinds[kind]
                    ? `${STRINGS.incidents.entityKinds[kind]}${count > 1 ? "s" : ""}`
                    : count > 1 ? STRINGS.timelineOutputsView.labelVariants.entities
                        : STRINGS.timelineOutputsView.labelVariants.entity;

                return `${count} ${label}`;
            })
            .join(" and<br>");
    };
    
    const [isZoomed, setIsZoomed] = useState(false)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [options, setOptions] = useState({
        chart: {
            height: 350,
            backgroundColor: 'transparent',
            zooming: {
                type: 'x',
                resetButton: {
                    position: {
                        x: 0,
                        y: -100
                    },
                    theme: {
                        fill: 'white',
                        stroke: 'blue',
                        r: 15,
                        zIndex: 3,
                        states: {
                            hover: {
                                fill: 'lightgrey',
                            }
                        }
                    }
                }
            },
            panning: {
                enabled: true
            },
            panKey: 'shift',
            type: 'timeline',
            events: {
                load: function (this: any) {
                    const chart = this;
                    chart.series.forEach(series => {
                        series.points.forEach((point) => {
                            const div = point.dataLabel.div;
                            const element = point.dataLabel.element;
                            point.graphic.element.addEventListener('click', () => {
                                const isEnabled = point.dataLabel && point.dataLabel.element.style.display !== 'none';

                                if (isEnabled) {
                                    element.style.display = 'none';
                                    div.style.display = 'none';
                                } else {
                                    div.style.display = 'block';
                                    element.style.display = 'block'
                                }
                            });
                            if (point.dataLabel && div) {
                                div.addEventListener('click', () => {
                                    if (point.dataLabel.newOpacity === 1) {
                                        props.dialog(point)
                                    }
                                });
                            }
                        });
                    });
                }
            },
        },
        exporting: {
            enabled: false
        },
        time: {
            useUTC: true
        },
        xAxis: {
            type: 'datetime',
            visible: false,
            gridLineWidth: 0,
            events:{
                afterSetExtremes:function(this: any){
                  if(this.dataMin === this.min && this.dataMax === this.max) setIsZoomed(false)
                  else setIsZoomed(true)
                }
            }
        },
        yAxis: {
            gridLineWidth: 5,
            gridLineColor: 'lightgrey',
            title: null,
            labels: {
                enabled: false
            }
        },
        title: {
            text: undefined
        },
        tooltip: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            timeline: {
                showInLegend: true,
                symbolRadius: 0,
                legendSymbol: 'lineMarker',
            },
            series: {
                states: {
                    inactive: {
                        opacity: .3
                    }
                },
                lineWidth: 0,
                dataLabels: {
                    width: 300,
                    enabled: true,
                    useHTML: true,
                    allowOverlap: false,
                    connectorWidth: 2,
                    zIndex: 1,
                    connectorColor: 'silver',
                    formatter: function (this: any) {
                        const item = this.point.custom;
                        const { description } = props.incident
                        const template = item.template && JSON.parse(item.template)
                        const lifecycleTrigger = template && template?.nodes?.find(x => x.type === 'trigger')?.label;
                        
                        return `<span style="color:${this.series?.options?.marker?.fillColor}">● </span><span style="color: grey">${this.point.label}</span>
                                
                                ${item.eventType === 'primaryIndicator' ? `</br>${editLabelStrings(description)}` : ''}

                                ${!item.eventType || item.eventType === 'runbookIncident' 
                                ? `</br><span>${item.nameIQ}${template ? `:</span></br><span style="font-weight: bold">${template.name}</span>` : ''}` 
                                : ''}

                                ${item.eventType === 'lifecycleEvent' 
                                ? `</br><span style="font-weight: bold">${template && template.name}</span></br><span>${STRINGS.timelineOutputsView.labelVariants.triggeredBy}: </br><span style="font-weight: bold">${lifecycleTrigger}</span></span>` 
                                : ''}

                                ${item.eventType === 'correlatedIndicator' 
                                ? `</br><span>${countEntityKinds(item.allIndicators)} ${STRINGS.timelineOutputsView.labelVariants.impacted}</span>` 
                                : ''}

                                ${item?.eventType === 'primaryIndicator' || (item?.eventType === 'correlatedIndicator' && item.cluster === 1)
                                ? `</br><span class="cta-timeline" style="color: #007BFF; text-decoration: underline">${STRINGS.timelineOutputsView.ctas.indicator}</span>`
                                : item?.eventType === 'correlatedIndicator' && item.cluster > 1
                                    ? `</br><span class="cta-timeline" style="color: #007BFF; text-decoration: underline">${STRINGS.timelineOutputsView.ctas.list}</span>`
                                    : item?.eventType
                                        ? `</br><span class="cta-timeline" style="color: #007BFF; text-decoration: underline">${STRINGS.timelineOutputsView.ctas.runbook}</span>`
                                        : ''
                                }
                        `
                    }
                },
            }
        },
        series: props.dataSeries
    })

    return (
        <>
            {isZoomed &&
                <Button outlined minimal className="timeline-zoom-btn" onClick={() => {
                    chartRef.current?.chart.zoomOut();
                    setIsZoomed(false);
                }}>{STRINGS.timelineOutputsView.ctas.zoom}
                </Button>
            }
            <div className='timeline-chart'>
                <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />     
            </div>
        </>

    )
}

export default TimelineChart;

