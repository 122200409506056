import type React from 'react';
import { useState } from 'react';
import classNames from 'classnames';
import { Button, Callout, Card, Classes, Divider, Intent, Label, SpinnerSize } from '@blueprintjs/core';
import { type LangEN, STRINGS } from 'app-strings';
import type { AuthenticationMethodTypeEnum } from 'utils/services/ThirdPartyIntegrationApiService.ts';
import { Icon, IconNames, LoadingOverlay } from '@tir-ui/react-components';
import { formatUnixToLocalTimestamp } from 'reporting-infrastructure/utils/formatters/GeneralFormatter.ts';
import { TIME_FORMAT } from 'components/enums/Time.ts';
import { AuthenticationMethods, getAuthMethod } from './BasicAuthDetailsPanel.tsx';
import { EndpointTypeOptions } from './EndpointTypePanel.tsx';
import { getIntegrationReferences } from '../IntegrationLibraryUtils.tsx';
import { openConfirm } from 'components/common/modal/ConfirmModal.tsx';
import { uniqBy } from "lodash";

export interface IReviewAuthPanelProps {
	profileName: string;
	profileDescription: string;
	authMethod: AuthenticationMethodTypeEnum | undefined;
	edgeDeviceIds: string[] | undefined;
	verified: any;
	isConnector: boolean;
	isEdit: boolean;
	isConnectorExisting: boolean;
	integrationId?: string;
}


const ReviewAuthPanel: React.FC<IReviewAuthPanelProps> = (props) => {
	const [loading, setLoading] = useState(false);
	const translations: LangEN = STRINGS;
	
	/**
   	 * Check if the integration resources are in use
     */
	async function showAffectedResources(integrationId: string) {
		try {
			setLoading(true)
			const affectedResources = await getIntegrationReferences(integrationId);
			const uniqueResources = uniqBy(affectedResources, "id");
			setLoading(false)

			const confirmMessage = uniqueResources?.length > 0 ? (
				<>
					<p className="font-size-sm">
						{`${STRINGS.formatString(
							uniqueResources.length === 1
								? translations.INTEGRATIONS_PAGE.affectedResources.dialogReferencingResource
								: translations.INTEGRATIONS_PAGE.affectedResources.dialogReferencingResources,
							uniqueResources?.length
						)}`}
					</p>
					<div className="mt-2 integration-library__modal-referenced-resources">
						{uniqueResources.map((runbook) =>
							renderRunbook(runbook)
						)}
					</div>
				</>
			) : (
				`${translations.INTEGRATIONS_PAGE.affectedResources.dialogNoReferencingResources} \n`
			);

			openConfirm({
				message: confirmMessage,
				confirmButtonText: translations.INTEGRATIONS_PAGE.affectedResources.confirmBtn,
				hideCancelButton: true,
				onConfirm: () => {},
				icon: IconNames.EDIT,
				intent: Intent.PRIMARY,
			});
		} catch (error) {
			console.error(error)    
		}
	}

	
	function renderRunbook (runbook) {
		const runbookCard = <Card className="w-100" key={runbook?.id}>
			<h5 className="fw-bold">
				<a href={`create-runbook?fid=${runbook.id}&fname=${runbook.name}&variant=${runbook.variant}`} rel="noreferrer" target="_blank">
					{runbook.name}
				</a>
			</h5>
			<p className="font-italic fw-light">
				{runbook.description}
			</p>
			<p className="font-italic fw-normal">
				{STRINGS.formatString(translations.thirdPartyIntegrations.referencingRunbooks.runbookUpdate,
					{
						user: runbook.lastUpdatedUser,
						time: formatUnixToLocalTimestamp(runbook.lastUpdatedTime, TIME_FORMAT.DISPLAY_DATE_ONLY_FORMAT)
					}
				)}
			</p>
		</Card>

		return runbookCard;
	}

	function renderWarningMessage(integrationId: string | undefined) {
        if (!integrationId) {
            return;
        }

        return (
			<Callout intent={Intent.WARNING}>
				{ translations.INTEGRATIONS_PAGE.affectedResources.message }
				<br />
				<Button 
					intent={Intent.WARNING}
					small
					className='mt-2'
					text={<span>{ translations.INTEGRATIONS_PAGE.affectedResources.link }</span>} 
                    onClick={() => showAffectedResources(integrationId)} 
                />
            </Callout>
        );
    }

	return (
		<div className={classNames(Classes.DIALOG_BODY)}>
			<LoadingOverlay 
				visible={loading} 
				spinnerSize={SpinnerSize.SMALL} 
				loadingText={ translations.INTEGRATIONS_PAGE.affectedResources.checkInProgress} 
			/>
			<p>
				<b>
					{
						props.isConnector ? 
							translations.INTEGRATIONS_PAGE.integrationDetailsModal.title :
							translations.thirdPartyIntegrations.addAuthProfile.panels.review.title
					}
				</b>
			</p>
			<Divider />
			<br />
			<div>
				<div className="row">
					<Label className="fw-bold col-md-4">
					{
						props.isConnector ? 
							translations.INTEGRATIONS_PAGE.integrationDetailsModal.connectorNameFieldLabel :
							translations.thirdPartyIntegrations.addAuthProfile.panels.review.fields.profileName
					}
					</Label>
					<Label className="col-md-8 text-break">
						{props.profileName ? props.profileName : ''}
					</Label>
				</div>
				{!props.isConnector &&
				<div className="row">
					<Label className="fw-bold col-md-4">
						{
							translations.thirdPartyIntegrations.addAuthProfile.panels
								.review.fields.profileDescription
						}
						:
					</Label>
					<Label className="col-md-8 text-break">
						{props.profileDescription ? props.profileDescription : ''}
					</Label>
				</div>
				}
				<div className="row">
					<Label className="fw-bold col-md-4">
						{
							STRINGS.thirdPartyIntegrations.addAuthProfile.panels
								.review.fields.authMethod
						}
						:
					</Label>
					<Label className="col-md-8">
						{AuthenticationMethods[getAuthMethod(props.authMethod)] || " - "}
					</Label>
				</div>
				<div className="row">
					<Label className="fw-bold col-md-4">
						{
							STRINGS.thirdPartyIntegrations.addAuthProfile.panels
								.review.fields.endpoint
						}
						:
					</Label>
					<Label className="col-md-8">
						{props?.edgeDeviceIds?.length === 0 || !props?.edgeDeviceIds
							? (EndpointTypeOptions.public.charAt(0).toUpperCase() + EndpointTypeOptions.public.slice(1))
							: (EndpointTypeOptions.private.charAt(0).toUpperCase() + EndpointTypeOptions.private.slice(1))}
					</Label>
				</div>
				<div className="row">
					<Label className="fw-bold col-md-4">
						{
							STRINGS.thirdPartyIntegrations.addAuthProfile.panels
								.review.fields.verified
						}
						:
					</Label>
					<Label className="col-md-8">
						{props.verified?.verified !== undefined && props.verified?.verified !== null ?
							(props.verified.verified ?
								<Icon aria-label="profile-verified-icon" icon={IconNames.TICK} intent={Intent.SUCCESS} /> :
								<Icon aria-label="profile-verified-icon" icon={IconNames.DELETE} intent={Intent.DANGER} />) :
							<Icon aria-label="profile-verified-icon" icon={IconNames.HELP} />}
						&nbsp;
						{formatUnixToLocalTimestamp(props.verified.time / 1000, TIME_FORMAT.DISPLAY_TIME_FORMAT)}
					</Label>
				</div>
				{props?.isConnector && props.isEdit && props.isConnectorExisting && renderWarningMessage(props?.integrationId)}
			</div>
		</div>
	);
};

export { ReviewAuthPanel };
