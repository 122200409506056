query incidentSearch($top: Int!, $skip: Int!, $count: Boolean!, $search: String, $searchFields: [IncidentSearchFieldEnum], $facets: [IncidentRequestFacetType!], $filter: IncidentSearchFilterType, $order: [SearchIncidentSortInputType!]) {
    searchItems: searchIncidents(
        top: $top,
        skip: $skip,
        count: $count,
        facets: $facets,
        filter: $filter,
        order: $order,
        search: $search,
        searchFields: $searchFields
    ) {
        info {
            totalCount
            facets {
                key
                result {
                    count
                    items {
                        value
                        count
                    }
                }
            }
        }
        page {
            id
            description
            eventCategory
            priority
            status
            isOngoing
            createdAt
            endTime
            earliestIndicator
            latestIndicator
            latestRunbookStatus
            lastUpdatedAt
            lastUpdatedBy {
                email
                id
                name
            }
            impactedUsers {
                name
                ipAddress
            }
            impactedUsersCount
            impactedApplications {
                name
            }
            impactedApplicationsCount
            impactedLocations {
                name
            }
            impactedLocationsCount
        }
    }
}
