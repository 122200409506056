/** This module contains the component for that displays the individual impact summary
 *  cards in the details pane.
 *  @module
 */
import { useState, useEffect } from 'react';
import { InputGroup } from '@blueprintjs/core';
import { STRINGS } from 'app-strings';

/** the properties passed in to the React component. */
interface ImpactSummaryDetailPaneProps {
    /** the subtitle to show before the search. */
	subTitle?: string;
    /** a String array with the data. */
	data: string[];
}

/** Renders the details for an impact summary card.
 *  @param props the properties passed in.
 *  @returns JSX with the imparct summary details pane component.*/
const ImpactSummaryDetailsPane = (props: ImpactSummaryDetailPaneProps) => {
	const { subTitle, data } = { ...props };
	const [paneData, setPaneData] = useState(data);
	const [filterValue, setFilterValue] = useState('');

	useEffect(() => {
		setPaneData(Object.values(data));
		setFilterValue('');
	}, [data]);

	const filterData = (filter: string) => {
		const filterData = data.slice();
		if (filter) {
			return filterData.filter((site) => {
				return String(site)
					.toLowerCase()
					.includes(filter.toLowerCase());
			});
		} else {
			return filterData;
		}
	};

	const handleFilterChange = (event) => {
		const fValue = event.target.value;
		setFilterValue(fValue);
		setPaneData(filterData(fValue).sort());
	};

	return (
		<div>
			<InputGroup
				leftIcon="search"
				onChange={handleFilterChange}
				placeholder={STRINGS.incidents.impactSummaryView.search}
				value={filterValue}
			/>
			<div className="mx-1 mt-3 mb-2">
				<span className="fw-bold">{subTitle}</span>{' '}
				<span>({paneData.length})</span>
			</div>
			<div className="mx-1">
				{paneData.map((value, index) => {
					return (
						<div className="mb-1" key={index}>
							{String(value)}
						</div>
					);
				})}
			</div>
		</div>
	);
};

export { ImpactSummaryDetailsPane };
