/** This module contains the component for the navigator toolbar.  The navigator toolbar displays
 *      the toolbar that controls the navigator.
 *  @module
 */

import { STRINGS } from "app-strings";
import { IconNames } from "@tir-ui/react-components";
import { AnchorButton, Button } from "@blueprintjs/core";
import { WrapInTooltip } from "components/common/wrap-in-tooltip/WrapInTooltip.tsx";
import { LAYOUT_TYPE } from "components/common/vis-framework/layout/Layout.type.ts";
import "./NavigatorToolbar.scss";

/** this enum specifies all the supported toolbar action types.  The toolbar action type is passed
 *  to the handler for toolbar actions.*/
export enum ToolbarAction {
    /** this enumerated type specifies that the user clicked on the add widget button. */
    ADD_WIDGET          = "add_widget",
    /** this enumerated type specifies that the user clicked on one of the layout buttons. */
    SET_LAYOUT          = "set_layout",
    /** this enumerated type specifies that the user clicked the edit button. */
    EDIT                = "edit",
    /** this enumerated type specifies that the user clicked on the export button. */
    EXPORT              = "export",
    /** this enumerated type specifies that the user clicked on the import button. */
    IMPORT              = "import",
    /** this enumerated type specifies that the user clicked on the save button. */
    SAVE                = "save",
}

/** this interface defines the properties passed into the NavigatorToolbar React component. */
export interface NavigatorToolbarProps {
    /** a boolean value, true if the navigator is in edit mode, false if it is in view mode. */
    edit: boolean;
    /** notifies any listeners about a toolbar action. */
    notifyToolbarAction?: (type: ToolbarAction, value: any) => void;
}

/** Renders the navigator toolbar.
 *  @param props the properties passed in.
 *  @returns JSX with the navigator toolbar component.*/
const NavigatorToolbar = (props: NavigatorToolbarProps): JSX.Element => { 
    const sendNotification = function(action: ToolbarAction, value: any = null): void {
        if (props.notifyToolbarAction) {
            props.notifyToolbarAction(action, value);
        }
    }

    return <div className="navigator-toolbar d-flex flex-wrap justify-content-between mt-4 position-relative" style={{zIndex: 2}}>
        {props.edit ? <WrapInTooltip tooltip={STRINGS.navigator.toolbar.addWidgetButton.tooltip}>
            <Button aria-label="widget-new-button" icon={IconNames.ADD} minimal
                className="me-2" 
                onClick={(e) => { 
                    sendNotification(ToolbarAction.ADD_WIDGET);
                }}
                text={STRINGS.navigator.toolbar.addWidgetButton.text}
            />
        </WrapInTooltip> : <div></div>}
        <div>
            {props.edit && <WrapInTooltip tooltip={STRINGS.navigator.toolbar.saveButton.tooltip}>
                <AnchorButton icon={IconNames.FLOPPY_DISK} minimal={true}
                    onClick={() => {
                        sendNotification(ToolbarAction.SAVE);
                    }} 
                />
            </WrapInTooltip>}
            {props.edit && <WrapInTooltip tooltip={STRINGS.navigator.toolbar.importButton.tooltip}>
                <AnchorButton icon={IconNames.IMPORT} minimal={true}
                    onClick={() => {
                        sendNotification(ToolbarAction.IMPORT);
                    }} 
                />
            </WrapInTooltip>}
            {props.edit && <WrapInTooltip tooltip={STRINGS.navigator.toolbar.exportButton.tooltip}>
                <AnchorButton icon={IconNames.EXPORT} minimal={true} className="me-4"
                    onClick={() => {
                        sendNotification(ToolbarAction.EXPORT);
                    }} 
                />
            </WrapInTooltip>}
            <WrapInTooltip tooltip={props.edit ? STRINGS.navigator.toolbar.editButton.runTooltip : STRINGS.navigator.toolbar.editButton.tooltip}>
                <AnchorButton icon={props.edit ? IconNames.DASHBOARD : IconNames.EDIT} minimal={true} className="me-4"
                    onClick={() => {
                        sendNotification(ToolbarAction.EDIT);
                    }} 
                />
            </WrapInTooltip>
            <WrapInTooltip tooltip={STRINGS.navigator.toolbar.customLayoutButton.tooltip}>
                <AnchorButton icon={IconNames.CONTROL} minimal={true}
                    onClick={() => {
                        sendNotification(ToolbarAction.SET_LAYOUT, LAYOUT_TYPE.CUSTOM);
                    }} 
                />
            </WrapInTooltip>
            <WrapInTooltip tooltip={STRINGS.navigator.toolbar.horizontalLayoutButton.tooltip}>
                <AnchorButton icon={IconNames.DOUBLE_CARET_HORIZONTAL} minimal={true}
                    onClick={() => {
                        sendNotification(ToolbarAction.SET_LAYOUT, LAYOUT_TYPE.HORIZONTAL);
                    }} 
                />
            </WrapInTooltip>
            <WrapInTooltip tooltip={STRINGS.navigator.toolbar.verticalLayoutButton.tooltip}>
                <AnchorButton icon={IconNames.DOUBLE_CARET_VERTICAL} minimal={true}
                    onClick={() => {
                        sendNotification(ToolbarAction.SET_LAYOUT, LAYOUT_TYPE.VERTICAL);
                    }}
                />
            </WrapInTooltip>
            <WrapInTooltip tooltip={STRINGS.navigator.toolbar.flexGridLayoutButton.tooltip}>
                <AnchorButton icon={IconNames.GRID_VIEW} minimal={true} 
                    onClick={() => {
                        sendNotification(ToolbarAction.SET_LAYOUT, LAYOUT_TYPE.GRID);
                    }}
                />
            </WrapInTooltip>
        </div>
    </div>;
};
  
export default NavigatorToolbar;
