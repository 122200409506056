import { Page } from '@tir-ui/react-components';
import { IconNames } from '@blueprintjs/icons';
import { STRINGS } from 'app-strings';

export const NoAccessErrorPage = () => {
    return (
        <Page title={STRINGS.access.forbiddenTitle} icon={IconNames.INFO_SIGN}>
            <div>{STRINGS.access.forbiddenMessage}</div>
        </Page>
    );
};
