/** This module contains a component that represents a condition block.  A condition block
 *  is a set of condition expressions connected by an and or or.
 *  @module
 */
import React, { useCallback, useEffect, useState } from "react";
import {
    Condition,
    ConditionProps,
    RawConditionValueType,
} from "../condition/Condition";
import { Icon } from "@tir-ui/react-components";
import { Button, Popover } from "@blueprintjs/core";
import {
    applyIdsToItems,
    areConditionBlocksEqual,
    CONDITION_TREE_ICONS,
    generateRandomID,
} from "../condition/ConditionUtils";
import { ConditionBlockTypePicker } from "./ConditionBlockTypePicker";
import { STRINGS } from "app-strings";
import "./ConditionBlock.scss";

/** this enum defines the valid condition block types. */
export enum ConditionBlockType {
    /** the enumerated condition block type for the and operator. */
    AND = "AND",
    /** the enumerated condition block type for the or operator. */
    OR = "OR",
    /** the enumerated condition block type for the nand operator. */
    NOT_AND = "NAND",
    /** the enumerated condition block type for the nor operator. */
    NOT_OR = "NOR",
}

export const BLOCK_TYPE_PROPS = {
    AND: {
        className: "type-and",
        label: STRINGS.conditionTreeBuilder.conditionBlockType.AND,
    },
    OR: {
        className: "type-or",
        label: STRINGS.conditionTreeBuilder.conditionBlockType.OR,
    },
    NAND: {
        className: "type-not type-and",
        label: STRINGS.conditionTreeBuilder.conditionBlockType.NAND,
    },
    NOR: {
        className: "type-not type-or",
        label: STRINGS.conditionTreeBuilder.conditionBlockType.NOR,
    },
    NOT: {
        className: "type-not",
        label: STRINGS.conditionTreeBuilder.conditionBlockType.NOT,
    },
};
export interface ConditionRowValue extends RawConditionValueType {
    conditionProps?: Partial<ConditionProps>;
}

export type ConditionRowOrBlockValue = ConditionRowValue | ConditionBlockValue;

export type RawConditionBlockValueType = {
    id?: string;
    blockType?: ConditionBlockType;
    conditions: Array<ConditionRowOrBlockValue>;
};

export interface ConditionBlockValue extends RawConditionBlockValueType {
    conditionBlockProps?: Partial<ConditionBlockProps>;
}

/** this interface defines the properties passed into the decision block React component. */
export interface ConditionBlockProps {
    className?: string;
    id?: string;
    readOnly?: boolean;
    options?: Array<ConditionBlockType>;
    blockType?: ConditionBlockType;
    conditions: Array<ConditionRowOrBlockValue>;
    onChange?: (newConditionValue: ConditionBlockValue) => void;
    onRemoveBlock?: (blockId: string) => void;
    hideDeNestBlock?: boolean;
    onNestBlock?: (props: { condition: ConditionRowOrBlockValue }) => void;
    onDeNestBlock?: (block: ConditionBlockValue) => void;
    /** Props to be used by default for all conditions under this block.
     * This can be overridden for each individual condition as needed */
    defaultConditionProps?: Partial<ConditionProps>;
    defaultConditionBlockProps?: Partial<ConditionBlockProps>;
    getConditionBlockProps?: (props: {
        conditions: ConditionRowOrBlockValue[];
        currentProps: Partial<ConditionBlockProps>;
    }) => ConditionBlockProps;
    getConditionProps?: (props: {
        /** All sibling conditions (including self in the order displayed) */
        conditions: ConditionRowOrBlockValue[];
        /** This conditions current set of props as derived form other properties */
        currentProps: Partial<ConditionProps>;
    }) => ConditionProps;
    addSubConditionRenderer?: (props: {
        value: ConditionBlockValue;
        defaultConditionProps?: Partial<ConditionProps>;
        update: (conditions: Array<ConditionRowOrBlockValue>) => void;
    }) => React.ReactNode;
    hideBlockType?: boolean;
    removeDisabled?: boolean;
    deNestOnRemoveLastChild?: boolean;
    syncValueParamChanges?: boolean;
    generateChildID?: (props: {
        type: "block" | "condition";
        condition: any;
    }) => string;
}

/** Renders the condition block.  A condition block is a set of condition expressions combined using
 *      ands and ors.
 *  @param props the properties object passed in.
 *  @returns the JSX with the condition block React component. */
export function ConditionBlock({
    className,
    id = "cb" + generateRandomID(),
    readOnly = false,
    options = [
        ConditionBlockType.AND,
        ConditionBlockType.OR,
        ConditionBlockType.NOT_AND,
        ConditionBlockType.NOT_OR,
    ],
    blockType = options[0] || ConditionBlockType.AND,
    conditions,
    onChange,
    onRemoveBlock,
    hideDeNestBlock = false,
    onNestBlock,
    onDeNestBlock,
    addSubConditionRenderer,
    hideBlockType = false,
    removeDisabled = false,
    deNestOnRemoveLastChild = false,
    defaultConditionProps = {},
    defaultConditionBlockProps = {},
    getConditionProps,
    getConditionBlockProps,
    syncValueParamChanges = readOnly,
    generateChildID,
}: ConditionBlockProps) {
    const [conditionList, setConditionList] = useState<
        Array<ConditionRowOrBlockValue>
    >(applyIdsToItems(conditions));
    const [blockTypeState, setBlockTypeState] = useState(blockType);

    // If input param for conditionKey changes and is different from current value, update local state
    useEffect(() => {
        if (
            syncValueParamChanges &&
            !areConditionBlocksEqual(conditions, conditionList)
        ) {
            setConditionList(applyIdsToItems(conditions));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conditions]);
    useEffect(() => {
        if (syncValueParamChanges && blockType !== blockTypeState) {
            setBlockTypeState(blockType);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blockType]);

    const fireOnChangeEvent = useCallback(
        ({ conditions, blockType }) => {
            if (onChange) {
                onChange({
                    id,
                    blockType: blockType || blockTypeState,
                    conditions: conditions || conditionList,
                });
            }
        },
        [onChange, id, blockTypeState, conditionList],
    );

    useEffect(() => {
        if (!areConditionBlocksEqual(conditions, conditionList)) {
            fireOnChangeEvent({ conditions: conditionList });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onBlockTypeChanged = useCallback(() => {
        // setBlockTypeState(newBlockType);
        fireOnChangeEvent({ blockType: blockTypeState });
    }, [blockTypeState, fireOnChangeEvent]);

    const onConditionOrBlockChanged = useCallback(
        (newValue) => {
            // Updating the changed condition's definition directly instead of executing a state update for performance reasons.
            for (let i = 0; i < conditionList.length; i++) {
                const condition = conditionList[i];
                if (condition.id === newValue.id) {
                    conditionList[i] = {
                        ...condition,
                        ...newValue,
                    };
                    fireOnChangeEvent({ conditions: conditionList });
                    break;
                }
            }
            // const updatedConditionList:Array<ConditionRowOrBlockValue> = conditionList.map(condition => {
            //     if (condition.id === newValue.id) {
            //         return {
            //             ...condition,
            //             ...newValue,
            //         };
            //     } else {
            //         return condition;
            //     }
            // });
            // setConditionList(updatedConditionList);
            // fireOnChangeEvent({ conditions: updatedConditionList });
        },
        [conditionList, fireOnChangeEvent],
    );

    const onSubConditionAddCallback = useCallback(
        (updatedConditionList) => {
            setConditionList(updatedConditionList);
            fireOnChangeEvent({ conditions: updatedConditionList });
        },
        [setConditionList, fireOnChangeEvent],
    );

    const onAddSubConditionClicked = useCallback(() => {
        const newConditionID = generateChildID
            ? generateChildID({
                  type: "condition",
                  condition: defaultConditionProps,
              })
            : "c" + generateRandomID();
        const updatedConditionList: Array<ConditionRowOrBlockValue> = [
            ...conditionList,
            {
                id: newConditionID,
                conditionProps: { ...defaultConditionProps },
            },
        ];
        setConditionList(updatedConditionList);
        fireOnChangeEvent({ conditions: updatedConditionList });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conditionList, fireOnChangeEvent, defaultConditionProps]);

    const onNestConditionClicked = useCallback(
        (conditionID) => {
            const conditionItemToNest = conditionList.find(
                (c) => c.id === conditionID,
            );
            if (conditionItemToNest) {
                if (hideBlockType === false) {
                    const updatedConditionList: Array<ConditionRowOrBlockValue> =
                        conditionList.map((condition) => {
                            if (condition.id === conditionID) {
                                const newBlockID = generateChildID
                                    ? generateChildID({
                                          type: "block",
                                          condition,
                                      })
                                    : "cb" + generateRandomID();
                                return {
                                    id: newBlockID,
                                    blockType:
                                        blockTypeState ===
                                        ConditionBlockType.AND
                                            ? ConditionBlockType.OR
                                            : ConditionBlockType.AND,
                                    conditions: [condition],
                                };
                            } else {
                                return condition;
                            }
                        });
                    setConditionList(updatedConditionList);
                    fireOnChangeEvent({ conditions: updatedConditionList });
                }
                if (onNestBlock) {
                    onNestBlock({ condition: conditionItemToNest });
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [
            conditionList,
            fireOnChangeEvent,
            blockTypeState,
            hideBlockType,
            onNestBlock,
        ],
    );

    const onDeNestBlockClicked = useCallback(() => {
        let finalBlockType = blockTypeState;
        // If user clicks on a de-nest block control and the current block type is of NOT something, then reset it to AND.
        // When this is the top-most block on a tree and user clicks on De-Nest block, this logic will basically reset the
        // NOT operation and hide the block type.
        if (
            blockTypeState === ConditionBlockType.NOT_AND ||
            blockTypeState === ConditionBlockType.NOT_OR
        ) {
            finalBlockType = ConditionBlockType.AND;
            setBlockTypeState(finalBlockType);
        }
        if (onDeNestBlock) {
            onDeNestBlock({
                id,
                blockType: finalBlockType,
                conditions: conditionList,
            });
        }
    }, [conditionList, onDeNestBlock, id, blockTypeState]);

    const onRemoveConditionClicked = useCallback(
        (conditionID) => {
            const updatedConditionList: Array<ConditionRowOrBlockValue> =
                conditionList.filter(
                    (condition) => condition.id !== conditionID,
                );
            setConditionList(updatedConditionList);
            fireOnChangeEvent({ conditions: updatedConditionList });
            if (updatedConditionList.length === 0) {
                if (deNestOnRemoveLastChild) {
                    onDeNestBlockClicked();
                } else if (onRemoveBlock) {
                    onRemoveBlock(id);
                }
            }
        },
        [
            conditionList,
            fireOnChangeEvent,
            deNestOnRemoveLastChild,
            onDeNestBlockClicked,
            onRemoveBlock,
            id,
        ],
    );

    const onChildBlockRemoved = useCallback(
        (blockID) => {
            onRemoveConditionClicked(blockID);
        },
        [onRemoveConditionClicked],
    );

    const onChildBlockDeNested = useCallback(
        (removedChildBlock) => {
            const updatedConditionList: Array<ConditionRowOrBlockValue> = [];
            for (const condition of conditionList) {
                if (condition.id === removedChildBlock.id) {
                    for (const subCondition of removedChildBlock.conditions) {
                        updatedConditionList.push(subCondition);
                    }
                } else {
                    updatedConditionList.push(condition);
                }
            }
            setConditionList(updatedConditionList);
            fireOnChangeEvent({ conditions: updatedConditionList });
        },
        [conditionList, fireOnChangeEvent],
    );

    const conditionBlocksJSX: Array<React.ReactNode> = [];

    for (const condition of conditionList) {
        if ((condition as any).conditions === undefined) {
            const { conditionProps, ...conditionValue } =
                condition as ConditionRowValue;
            const mergedConditionProps: Partial<ConditionProps> = {
                ...defaultConditionProps,
                ...(conditionProps || {}),
                readOnly,
                onChange: onConditionOrBlockChanged,
            };
            const conditionPropsWithValuesPopulated = {
                ...mergedConditionProps,
                id: conditionValue.id,
                conditionCategory:
                    conditionValue.category ||
                    mergedConditionProps?.conditionCategory,
                conditionKey:
                    conditionValue.conditionKey ||
                    mergedConditionProps?.conditionKey,
                operation:
                    conditionValue.operation || mergedConditionProps?.operation,
                value: conditionValue.value || mergedConditionProps?.value,
            };
            const finalConditionProps = getConditionProps
                ? getConditionProps({
                      conditions: conditionList,
                      currentProps: conditionPropsWithValuesPopulated,
                  })
                : conditionPropsWithValuesPopulated;

            const key = "c-" + finalConditionProps.id;
            conditionBlocksJSX.push(
                <Condition key={key} {...finalConditionProps} />,
            );

            if (readOnly) {
                conditionBlocksJSX.push(<span key={key + "-ctrls"} />);
            } else {
                conditionBlocksJSX.push(
                    <div key={key + "-ctrls"} className="controls">
                        <Button
                            className="nest-block-btn"
                            disabled={finalConditionProps.cannotBeNested}
                            icon={CONDITION_TREE_ICONS.NEST_BLOCK}
                            minimal
                            onClick={() =>
                                onNestConditionClicked(conditionValue.id)
                            }
                            title={
                                STRINGS.runbookEditor.nodeEditor.tooltips
                                    .nestCondition
                            }
                        />
                        <Button
                            className="remove-block-btn"
                            disabled={removeDisabled}
                            icon={CONDITION_TREE_ICONS.DELETE}
                            minimal
                            onClick={() =>
                                onRemoveConditionClicked(conditionValue.id)
                            }
                            title={
                                STRINGS.runbookEditor.nodeEditor.tooltips
                                    .removeCondition
                            }
                        />
                    </div>,
                );
            }
        } else {
            const { conditionBlockProps, ...conditionBlockvalue } =
                condition as ConditionBlockValue;
            const mergedConditionBlockProps = {
                ...conditionBlockProps,
                ...defaultConditionBlockProps,
                defaultConditionProps: {
                    ...defaultConditionProps,
                    ...conditionBlockProps?.defaultConditionProps,
                },
                defaultConditionBlockProps: {
                    ...defaultConditionBlockProps,
                    ...conditionBlockProps?.defaultConditionBlockProps,
                },
                id: conditionBlockvalue.id,
                blockType: conditionBlockvalue.blockType,
                conditions: conditionBlockvalue.conditions,
                onChange: onConditionOrBlockChanged,
                onDeNestBlock: onChildBlockDeNested,
                onRemoveBlock: onChildBlockRemoved,
                readOnly,
                getConditionProps,
                getConditionBlockProps,
            };
            const finalConditionBlockProps = getConditionBlockProps
                ? getConditionBlockProps({
                      conditions: conditionList,
                      currentProps: mergedConditionBlockProps,
                  })
                : mergedConditionBlockProps;
            conditionBlocksJSX.push(
                <ConditionBlock
                    key={"cb-" + finalConditionBlockProps.id}
                    {...finalConditionBlockProps}
                />,
            );
        }
    }

    const canChangeBlockType = !readOnly && options.length > 1;
    const blockTypeProp =
        BLOCK_TYPE_PROPS[
            (blockTypeState === ConditionBlockType.NOT_AND ||
                blockTypeState === ConditionBlockType.NOT_OR) &&
            conditionList.length <= 1
                ? "NOT"
                : blockTypeState
        ];
    const blockTypeDisplayJsx = (
        <span className="content">
            <div className="label">{blockTypeProp.label}</div>
            {canChangeBlockType && (
                <Icon className="icon" icon={CONDITION_TREE_ICONS.DROPDOWN} />
            )}
        </span>
    );
    return (
        <div
            className={
                "condition-block " +
                blockTypeProp.className +
                (readOnly ? " read-only" : "") +
                (className ? " " + className : "")
            }
        >
            <div
                className={
                    "block-type-container " +
                    (hideDeNestBlock || readOnly
                        ? "cannot-denest"
                        : "can-denest")
                }
                style={{ gridRowEnd: conditionList.length + 2 }}
            >
                {hideBlockType === false && (
                    <>
                        {!(readOnly || hideDeNestBlock) && (
                            <Button
                                className="icon de-nest-block-btn"
                                icon={CONDITION_TREE_ICONS.DENEST_BLOCK}
                                minimal
                                onClick={() => onDeNestBlockClicked()}
                                title={
                                    STRINGS.runbookEditor.nodeEditor.tooltips
                                        .denestCondition
                                }
                            />
                        )}
                        {canChangeBlockType ? (
                            <Popover
                                className="block-type clickable"
                                lazy
                                popoverClassName="condition-block-type-change-popup"
                                autoFocus={false}
                                enforceFocus={false}
                                content={
                                    <ConditionBlockTypePicker
                                        value={blockTypeState}
                                        onChange={setBlockTypeState}
                                    />
                                }
                                onClose={() => onBlockTypeChanged()}
                            >
                                {blockTypeDisplayJsx}
                            </Popover>
                        ) : (
                            <div className="block-type">
                                {blockTypeDisplayJsx}
                            </div>
                        )}
                    </>
                )}
            </div>
            {conditionBlocksJSX}
            {!readOnly && (
                <div className="new-row-controls">
                    {addSubConditionRenderer ? (
                        addSubConditionRenderer({
                            value: {
                                id,
                                blockType: blockTypeState,
                                conditions: conditionList,
                            },
                            defaultConditionProps: defaultConditionProps,
                            update: onSubConditionAddCallback,
                        })
                    ) : (
                        <Button
                            className="add-sub-condition-btn"
                            icon={CONDITION_TREE_ICONS.ADD}
                            minimal
                            onClick={(e) => onAddSubConditionClicked()}
                        />
                    )}
                </div>
            )}
        </div>
    );
}
