/** This module contains the component for allowing the user to enter the search string.
 *  @module
 */

import { useRef, useState, useEffect } from "react";
import { Button, InputGroup } from "@blueprintjs/core";
import { STRINGS } from "app-strings";
import { Icon, IconNames } from "@tir-ui/react-components";
import { WrapInTooltip } from "components/common/wrap-in-tooltip/WrapInTooltip.tsx";

/** This interface defines the properties passed into the search control React component.*/
interface SearchControlProps {
    /** a String with the search text to display. */
    searchText: string;
    /** the classname to apply to the div for the component. */
    className?: string;
    /** a string with the current search type. */
    searchType?: string;
    /** if there is no searchType, can pass placeholder text directly  */
    placeholder?: string;
    /** tooltip help text to show when focusing, if different from the default */
    tooltip?: string;
    /** the handler for update events.  This is called when an update is successfully completed. */
    onChange?: (searchString: string) => void;
}

/** Renders the search control view.
 *  @param props the properties passed in.
 *  @returns JSX with the search control component.*/
const SearchControl = (props: SearchControlProps) => {
    const searchInputEl = useRef<any>(null);
    const [searchText, setSearchText] = useState<string>(props.searchText);

    useEffect(
        () => {
            if (props.searchText !== searchText) {
                setSearchText(props.searchText);
            }
        }, 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.searchText]
    );

    return <div className={"d-flex flex-nowrap" + (props.className ? " " + props.className : "")}>
        <WrapInTooltip tooltip={props.tooltip ? props.tooltip : STRINGS.incidentSearch.searchBar.tooltip} >
            <InputGroup aria-label="search box" id="search-input-text" data-testid="search-input-text"
                name="searchBox" type="text" maxLength={150} className="me-2"
                placeholder={props.searchType ? STRINGS.incidentSearch.searchBar.placeholderText[props.searchType] : (props.placeholder ? props.placeholder : "")}
                style={{width: "380px"}}
                ref={searchInputEl} value={searchText}
                // leftIcon={IconNames.SEARCH}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        if (props.onChange) {
                            props.onChange(searchText);
                        }
                    }
                }}
                onChange={(e) => {
                    setSearchText(e.currentTarget.value);
                }}
                rightElement={ !!searchText ? 
                    <Icon icon={IconNames.CROSS} size={24} style={{cursor: "pointer", color: "grey"}} className="mt-1" data-testid="search-input-clear-button" onClick={() => {
                        setSearchText("");
                    }}/> : undefined
                }
            />
        </WrapInTooltip>
        <Button title="Search" intent="primary" icon={IconNames.SEARCH} className="align-self-start" onClick={() => {
            if (props.onChange) {
                props.onChange(searchText);
            }
        }} type="button" data-testid="search-input-apply-button" >{STRINGS.incidentSearch.searchBar.searchBtnText}</Button>
    </div>;
};

export default SearchControl;
