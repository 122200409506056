/** This module contains some utilities for handling query parameters.
 *  @module
 */
import { NAVIGATOR_MODE } from "pages/navigator/Navigator.type";
import { getAllQueryParams } from "utils/hooks/useQueryParams.ts";

/** a dictionary with the parameter names for the URL query parameters.*/
export const PARAM_NAME = {
    rbConfigId:             "fid",
    rbConfigNm:             "fname",
    rbConfig:               "runbookConfiguration",
    rbOutput:               "runbookOutput",
    incidentId:             "incidentId",
    incidentIds:            "incidentIds",
    triggerId:              "triggerId",
    runbookId:              "runbookId",
    customPropertyId:       "customPropertyId",
    inspectorBladeOpen:     "inspectorBladeOpen",
    rbViewMode:             "mode",
    urlTrigger:             "url_trigger",
    debug:                  "debug",
    devel:                  "devel",
    pageSize:               "pageSize",
    pageIndex:              "pageIndex",
    sortColumn:             "sortColumn",
    sortOrder:              "sortOrder",
    //Uniquely identifies a subsection within a page.
    pageLocationId:         "plid",
    searchText:             "searchText",
    searchType:             "searchType",
    searchTime:             "searchTime",
    tableColumns:           "tableColumns",
    facets:                 "facets",
    groupedFacets:          "groupedFacets",

    //Flags that can be used to enable/disable dev-features using a query param flag
    showDetections:         "detections",
    showOldIncidentDetails: "oldIncidentDetails",
    showPrimaryIndicator:   "primary",
    showReason:             "reason",
    showDuplicates:         "duplicates",
    showSteps:              "steps",
    incidentDetailsStyle:   "incidentDetailsStyle",
    showContext:            "showContext",
    variant:                "variant",
    showJson:               "showJson",
    showJsonDo:             "showJsonDo",

    // Flags to determine the location of a runbook creation
    fromWizard:             "fromWizard",

    // Flags used in embedding
    embed:                  "embed",
    view:                   "view",
    createNew:              "createNew",
    runTestDataId:          "runTestDataId",
    createNewCP:            "createNewCP",
    triggerType:            "triggerType",
    fromExplorerEntity:     "fromExplorerEntity",

    // Flags used in custom properties
    allowMultiType:         "allowMultiType",

    // Flags used with NPM+
    navigatorMode:          "navigatorMode",
    
    // Flags used with On Demand Runbooks
    isRunbookOnDemand:      "ondemand",

    // Flags used with CloudIM
    detailsProvider:        "provider",
    detailsLocation:        "location",
    providerType:           "providerType",
    layoutOption:           "layoutOption",
    overlapRemoval:         "overlapRemoval",
};

// These are flags that can be turned ON by passing a &paramname=true in URL.
// They will stay active until a reload happens.
const queryParams = getAllQueryParams();

/** a boolean value, true if we want to allow custom properties to support multiple entity types. */
export const ALLOW_MULTI_TYPE = Boolean(queryParams[PARAM_NAME.allowMultiType]);

/** a boolean value, true if we want to show detections instead of triggers. */
export const SHOW_DETECTIONS = Boolean(queryParams[PARAM_NAME.showDetections]);
/** a boolean value, true if we want to show duplicate detections. */
export const SHOW_DUPLICATE_DETECTIONS = Boolean(queryParams[PARAM_NAME.showDuplicates]);
/** a string with the style to use for the incident details page.  It defaults to "noTableOneCardForRunbook". */
export const INCIDENT_DETAILS_STYLE: "table" | "noTableOneCardForRunbook" | "noTableOneCardForEachWidget" = getIncidentDetailStyle(queryParams);
/** a boolean value, true if we want to show the runbook context. */
export const SHOW_CONTEXT = Boolean(queryParams[PARAM_NAME.showContext]);
/** a boolean value, true if we want to show JSON variables and keys. */
export const SHOW_JSON = Boolean(queryParams[PARAM_NAME.showJson]);
/** a boolean value, true if we want to show JSON variables and keys as Data Ocean entities. */
export const SHOW_JSON_DO = Boolean(queryParams[PARAM_NAME.showJsonDo]);
/** a boolean value, true if the URL states that the UI is embedded. */
export const IS_EMBEDDED: boolean = queryParams[PARAM_NAME.embed] && queryParams[PARAM_NAME.embed].toLowerCase() === "true" ? true : false;
/** a boolean value, true if the URL states that the runbook is created from the automation wizard. */
export const IS_CREATED_FROM_WIZARD: boolean = queryParams[PARAM_NAME.fromWizard] && queryParams[PARAM_NAME.fromWizard].toLowerCase() === "true" ? true : false;
/** a boolean value, true if the URL states that the NPM+ navigator is interactive. */
export const NAV_MODE: string = queryParams[PARAM_NAME.navigatorMode] || NAVIGATOR_MODE.original;
/** a boolean value, true if the URL states that the runbook is on demand type. */
export const IS_ON_DEMAND: boolean = queryParams[PARAM_NAME.isRunbookOnDemand] && queryParams[PARAM_NAME.isRunbookOnDemand].toLowerCase() === "true" ? true : false;

/** returns the incident details style.  If no style is passed it returns "noTableOneCardForRunbook".
 *  @param queryParams the object that includes the passed query parameters.
 *  @return a string with the style.  If no style is passed it returns "noTableOneCardForRunbook". */
export function getIncidentDetailStyle(queryParams: {}): "table" | "noTableOneCardForRunbook" | "noTableOneCardForEachWidget" {
    const style = queryParams[PARAM_NAME.incidentDetailsStyle];
    if (style) {
        switch (style) {
            case "table":
                return "table";
            case "noTableOneCardForRunbook":
                return "noTableOneCardForRunbook";
            case "noTableOneCardForEachWidget":
                return "noTableOneCardForEachWidget";
        }
    }
    return "noTableOneCardForEachWidget";
}
