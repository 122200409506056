/** This module contains the component for rendering the PriorMonthTotal card.
 *  @module
 */
import React from "react";
import { SIZE } from "components/enums/Sizes.ts";
import { SummaryCard } from "components/common/layout/summary-card/SummaryCard.tsx";

import { STRINGS } from "app-strings";

/** Renders the PriorMonthTotal card.
 *  @returns JSX with the PriorMonthTotal card.*/
const PriorMonthTotalCard = (props: any): JSX.Element => {
    const { data } = props;

    return (
        <React.Fragment>
            <SummaryCard
                isSummary
                title={
                    <span>
                        {
                            STRINGS.USAGE_MONITORING_PAGE.cards.nodeUsage
                                .priorMonthTotal.title
                        }
                    </span>
                }
                size="flex"
                height={SIZE.m}
                className="w-min-2"
                footerContent={<React.Fragment></React.Fragment>}
            >
                {data && <div className="display-6">
                    <span className="rounded py-1 px-3 fw-normal text-nowrap">
                        {data &&
                            "totalWeightedCount" in data &&
                            new Intl.NumberFormat().format(
                                data["totalWeightedCount"],
                            )}
                    </span>
                </div>}
            </SummaryCard>
        </React.Fragment>
    );
};

export { PriorMonthTotalCard };
