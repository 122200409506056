import { useState } from 'react';
import { Modal } from '@tir-ui/react-components';
import { InputField } from 'components/common/form/InputField.tsx';
import { SelectField } from 'components/common/form/SelectField.tsx';
import { Form } from 'components/common/form/Form.tsx';
import * as yup from 'yup';
import { Classes, Intent, Tag } from '@blueprintjs/core';
import { STRINGS } from 'app-strings';
import "./ForecastingModal.scss";

const ForecastingModal = (props) => {
    const [forecastConfig, setForecastConfig] = useState({
        period: "6",
        minThreshold: 80,
        maxThreshold: 100
    });

    const validationSchema = yup.object().shape({
        minThreshold: yup
            .number()
            .typeError(STRINGS.predictionSummaryDashboard.forecastModal.errors.type)
            .min(0, STRINGS.predictionSummaryDashboard.forecastModal.errors.lower)
            .max(100, STRINGS.predictionSummaryDashboard.forecastModal.errors.larger)
            .label(STRINGS.predictionSummaryDashboard.forecastModal.errors.middle)
            .required()
            .test(
                "is-integer",
                STRINGS.predictionSummaryDashboard.forecastModal.errors.decimal,
                (value) => Number.isInteger(value)
            )
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setForecastConfig({
            ...forecastConfig,
            [name]: value,
        });
    };

    return (
        <Modal
            title={STRINGS.predictionSummaryDashboard.forecastModal.title}
            hideSubmit
            hideCancel
            buttons={[
                {
                    label: STRINGS.predictionSummaryDashboard.forecastModal.close,
                    action: () => (document.querySelector(`.${Classes.DIALOG_CLOSE_BUTTON}`) as HTMLSelectElement)?.click()

                },
                {
                    label: STRINGS.predictionSummaryDashboard.forecastModal.submit,
                    intent: Intent.PRIMARY,
                    action: () => {
                        props.handleShowForecast(forecastConfig);
                        (document.querySelector(`.${Classes.DIALOG_CLOSE_BUTTON}`) as HTMLSelectElement)?.click()
                    },
                    disabled: !validationSchema.isValidSync(forecastConfig)
                }
            ]}
            usePortal={false}
            {...props}
        >
            <Form className={"forecast-modal container"}
                initialValues={forecastConfig}
                validationSchema={validationSchema}
                loading={false} >
                <div className="row mt-3">
                    <div className="col-9 mt-1">{STRINGS.predictionSummaryDashboard.forecastModal.period}</div>
                    <div className="col-3">
                        <SelectField
                            name={"period"}
                            onChange={handleChange}>
                            <option value={"1"}>{STRINGS.predictionSummaryDashboard.forecastModal.m1}</option>
                            <option value={"3"}>{STRINGS.predictionSummaryDashboard.forecastModal.m3}</option>
                            <option value={"6"}>{STRINGS.predictionSummaryDashboard.forecastModal.m6}</option>
                        </SelectField>
                    </div>
                </div>
                <div className="row">
                    <div className="col-9 mt-1">
                        {STRINGS.predictionSummaryDashboard.forecastModal.threshold}
                    </div>
                    <div className="col-3">
                        <InputField
                            name={"minThreshold"}
                            rightElement={<Tag minimal>%</Tag>}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </Form>
        </Modal>
    )
}

export default ForecastingModal
