/** This module defines a hook that can be used to run graphql queries.
 *  @module
 */
import { useEffect, useRef, useState } from 'react';
import { useGlobalFilters } from './useGlobalFilters.ts';
import type { FILTERS_OBJECT } from "utils/stores/GlobalFiltersStore.ts";
import { FILTER_NAME } from "components/sdwan/enums/filters.ts";
import { useApolloClient, type FetchPolicy, ObservableQuery } from "@apollo/client";
import { Query } from '../../reporting-infrastructure/types/Query.ts';
import { TIME_RANGE } from 'utils/stores/GlobalTimeStore.ts';
import { useGlobalTime } from './useGlobalTime.ts';
import { getAllFilters } from 'utils/stores/GlobalFiltersStore.ts';
import { addSupportedFilters, removeSupportedFilters, getSupportedFilters } from 'utils/stores/GlobalSupportedFiltersStore.ts';
import { isEqual, cloneDeep } from 'lodash';
import { SubscriptionLike } from 'rxjs';
import { TIME_DURATION_ISO } from 'utils/stores/GlobalTimeStore.ts';
import { formatDateForDAL } from './useQueryFormatters.ts';

const DEBUG_QUERIES: boolean = import.meta.env.VITE_DEBUG_QUERIES === "true";

/** Query variables are inputs to the graphql query which do not match an exact defined FILTER_NAME.
Hence these have been defined separate from filters that are well-defined and we know the specific set of key and values
that are supported (eventhough for graphql both are the same). (e.g. A query could support a 'fetchMetrics' flag
which when passed can additionally fetch the values under metrics section of the query. But this flag is not
a known FILTER_NAME type) */
export interface QueryVariables {
    [x:string] : boolean | number | string | Object | string[]
}

/** this interface defines the base interface for pagination state objects. */
export interface PaginationState {
    /** a boolean value true if there is a next page. */
    hasNextPage: boolean;
}

/** This inteface defines properties that are specific to cursor-based pagination.
 *  When we start having queries in DAL that support page based pagination, we might
 *  implement a new interface that's specific to that type of pagination. */
interface PaginationProps {
    /** the initial pagination state to put in the query state object. */
    initPaginationState?: PaginationState;
    /** the useQuery hook will call this function to get the updated pagination state, using the current data and
     *  previous state. */
    getPaginationState?: (data: any, prevState: PaginationState) => PaginationState;
    /** the useQuery hook will call this function to get the pagination related query variables to place in the 
     *  query. */
    getQueryVariables?: (state: PaginationState) => QueryVariables;

    /** A method that merges the existing paginated data with the new data that just came in. */
    mergeNextPageData?: (existingData, newData) => any;
}

/** This interface defines all the parameters that the useQuery hook supports during initialization. */
export interface UseQueryProps {
    /** an optional String with the name of the query which is useful for debugging. */
    name?: string;
    /** the GraphQL query that is to be run. */
    query: Query;
    /** these will override the global filters.  These get merged in with the global filters and it will
     *  merge these on top. */
    filters?: FILTERS_OBJECT;
    /** this will ignore the global filters and the global filter notifications. */
    skipGlobalFilters?: boolean;
    /** these are additional GraphQL query variables.  The query variables will override the filters if there 
     *  is a conflict. This is there for variables that cannot be put in the filters, like limit=10. */
    queryVariables?: QueryVariables;
    /** if these filters are missing, then the query will not run.  These can be global filters or anything passed
     *  in via the filters parameter.  The function can be passed when you want to change the required filters 
     *  while the query is running. */
    requiredFilters?: FILTER_NAME[] | Function;
    /** If consumedFilters is passed, the query will only consume values for the listed filters.
     * It will also auto-update only when value changes for a filter listed here. By default, the query
     * will read all filter values and auto-update when a change happens for any and all of the filters. */
    consumedFilters?: FILTER_NAME[];
    /** If supportedFilters is passed, this query will inform the global filtering engine that it has
     * a filter that the user can input a value for. If not provided, 'consumedFilters' will be used by default.
     * Use this param only in special cases where the consumedFilters is for some reason different
     * from globally supported filters */
    supportedGlobalFilters?: FILTER_NAME[];
    /** The opposite of supportedGlobalFilters. For cases where we want to allow all filters except a few.*/
    excludedGlobalFilters?: FILTER_NAME[];
    /** When this flag is true, while reading filters from global filters store, we will only read the values
     * for filters that are considered as globally supported taking into consideration other active components
     * that are mounted in the page. Don't worry about this param except for special corner cases */
    onlyConsumeSupportedFilters?: boolean;
    /** this will override the global time.  This will get merged in even if time is not required. */
    time?: TIME_RANGE | TIME_DURATION_ISO;
    /** Pass this flag as true if you don't want us to fetch time from global time store and pass it */
    timeNotRequired?: boolean;
    /** The useQuery hook will by default execute the query as soon as it is mounted. Pass this flag
     * as true if you want to manually trigger the query later instead by calling the `run` method */
    lazy?: boolean;
    /** Special pagination-related props */
    paginationProps?: PaginationProps;
    /** A limit after which data in this query should be considered as outdated and a fresh fetch should happen.
     * This can also be leveraged in cases where data becomes outdated due to some other mutation or operation
     * and we want the query to run fresh on the next render. The value can be a specific timestamp, a duration
     * since the last successful run or a key in which case a change in the key will make the data obsolete */
    expiresAfter?: {
        timestamp?: number,
        duration?: number,
        key?: number | string
    };
    /** if you always want to run the query and go to the server, this will always run the query with no-cache. */
    noCache?: boolean;
}

/** Interface that defines the properties that can be passed when manually calling the
 *  `run` method that will be returned as an attribute on the useQuery instance. */
export interface UseQueryRunProps {
    /** these will override the global filters.  These get merged in with the global filters and it will
     *  merge these on top. */
    filters?: FILTERS_OBJECT;
    /** if this is true it will force the query to run even if the filters and time are the same as before.  Apollo
     *  Client will return data from the cache when the variables are the same, this will set the parameter to 
     *  prevent Apollo from doing this. */
    noCache?: Boolean;
    /** this allows you to control the Apollo client caching directly whereas noCache sets the fetch policy
     *  for you. */
	fetchPolicy?: FetchPolicy;
    /** this will ignore the global filters and the global filter notifications. */
    skipGlobalFilters?: boolean;
    /** these are additional GraphQL query variables.  The query variables will override the filters if there 
     *  is a conflict. This is there for variables that cannot be put in the filters, like limit=10. */
    queryVariables?: QueryVariables;
    /** this will override the global time.  This will get merged in even if time is not required. */
    time?: TIME_RANGE|TIME_DURATION_ISO;
    /** Pass this flag to turn a query lazy or not lazy anymore */
    lazy?: boolean;
}

/** Interface that defines the properties that can be passed when calling the
 * `FetchMore` method for fetching additional data in a cursor-based paginated query. */
export interface  UseQueryFetchMoreProps {
    queryVariables?: QueryVariables;
}

/** A timer object that keeps track of one query run.*/
export interface TIMER {
    /** the start time of the query in milliseconds since epoch.*/
    start: number;
    /** the end time of the query in milliseconds since epoch.*/
    end: number;
}

/** the default getPagination state function.
 *  @param data the data returned by the query.
 *  @param prevState the previous pagination state. 
 *  @returns the new PaginationState. */
function defaultGetPaginationState(data: any, prevState: PaginationState): PaginationState {
    let hasNextPage = false;
    let endCursor = "";
    if (data) {
        for (const key in data) {
            const primaryResultSet = data[key];
            hasNextPage = Boolean(primaryResultSet.pageInfo?.hasNextPage);
            endCursor = primaryResultSet.pageInfo?.endCursor || "";
            break;
        }
    }
    return {hasNextPage, endCursor} as PaginationState;
}

/** the default getQueryVariables implementation.
 *  @params state the current state.
 *  @returns the QueryVariables. */
function defaultGetQueryVariables(state: PaginationState): QueryVariables {
    return {
        cursor: (state as any).endCursor,
    };
}

/** the default merge function for the pagination functionality.
 *  @param existingData .
 *  @param newData .
 *  @returns the merged data from the pagination. */
function defaultMergeNextPageData(existingData, newData) {
    if (newData === null || existingData === null || typeof existingData !== typeof newData) {
        return newData;
    } else if (Array.isArray(existingData)) {
        return [...existingData, ...newData];
    } else if (typeof existingData === "object") {
        const mergedData = {};
        for (const key in newData) {
            mergedData[key] = defaultMergeNextPageData(existingData[key], newData[key]);
        }
        return mergedData;
    } else {
        return newData;
    }
}

/** this type defines the current query state. */
export type queryStateProps = {
    /** a boolean value, true if the query is running and querying for data. */
    loading: boolean,
    /** a boolean value, true if the fetch more query is running and querying for data. */
    loadingMore: boolean,
    /** the data from the last query. */
    data: any,
    /** the error from the last query. */
    error: any,
    /** the error from the last more query. */
    errorLoadingMore: any,
    /** the current pagination state. */
    paginationState: PaginationState,
};

/** this type defines the information returned by the useQuery hook call. */
export type UseQueryReturn = queryStateProps & {
    /** the function that is used to update the query. */
    updateQuery: (newQuery: Query) => void,
    /** the function that is used to run the query. */
    run: (runProps?: UseQueryRunProps) => Promise<any>,
    /** the function that is used to get another page. */
    fetchMore: () => Promise<any>
};

/** This hook is responsible for making the DAL calls to run the GraphQL queries.
 *  @returns An array with the first element being the SearchResponse and the second element is the search Function
 *      whose invocation triggers search operation*/
const useQuery = function ({
    query,
    lazy = false,
    paginationProps = {},
    ...initProps
}: UseQueryProps): UseQueryReturn {
    const { filters } = useGlobalFilters({
        listenOnlyTo: initProps.consumedFilters,
        exclusiveList: initProps.consumedFilters,
        excludeList: initProps.excludedGlobalFilters
    });

    const {
        initPaginationState = {hasNextPage: false},
        getPaginationState = defaultGetPaginationState,
        getQueryVariables = defaultGetQueryVariables,
        mergeNextPageData = defaultMergeNextPageData,
    } = paginationProps;

    // Default timeNotRequired to false on initProps
    initProps.timeNotRequired = Boolean(initProps.timeNotRequired);
    const { absoluteTime } = useGlobalTime({
        // If timeNotRequired was passed, then we don't want to re-run the query when time changes
        dontUpdateOnTimeChange: initProps.timeNotRequired
    });
    const [gqlQuery, setGqlQuery] = useState(query);

    const apolloClient = useApolloClient();
    const [queryState, setQueryState] = useState<queryStateProps>({
        loading: false,
        loadingMore: false,
        data: undefined,
        error: undefined,
        errorLoadingMore: undefined,
        paginationState: initPaginationState,
    });
    const queryStateRef = useRef(queryState);
    queryStateRef.current = queryState;

    const mountedStateRef = useRef(true);
    const lazyQuery = useRef(lazy);
    const runIndex = useRef(0);
    const graphqlWatchQuery = useRef<ObservableQuery>();
    const graphqlWatchQuerySubscription = useRef<SubscriptionLike>();
    const timers = useRef([{start: 0, end: 0}]);

    const lastRunVars = useRef<QueryVariables>();

    const lastRunTimeStamp = useRef<number>();
    const lastRunExpiresKey = useRef<number | string>();
    const expiresAfterTimer = useRef<null | number>(null);

    const fetchMore = function (): Promise<any> {
        return new Promise((resolve, reject) => {
            if (queryStateRef.current.paginationState.hasNextPage && graphqlWatchQuery.current) {
                const thisRunIndex = ++runIndex.current;
                setQueryState({
                    ...queryStateRef.current,
                    loadingMore: true,
                    errorLoadingMore: undefined,
                });
                const queryVariables = getQueryVariables(queryStateRef.current.paginationState);
                apolloClient.query({
                    query: gqlQuery.getGqlQuery(),
                    variables: {
                        ...lastRunVars.current,
                        ...queryVariables
                    },
                })
                .then(({data}) => {
                    if (mountedStateRef.current && thisRunIndex === runIndex.current) {
                        resolve(data);
                        const newPaginationState = getPaginationState(data, queryStateRef.current.paginationState);
                        setQueryState({
                            ...queryStateRef.current,
                            loadingMore: false,
                            data: mergeNextPageData(queryStateRef.current.data, data),
                            paginationState: newPaginationState,
                        });
                    }
                })
                .catch((error) => {
                    if (mountedStateRef.current && thisRunIndex === runIndex.current) {
                        reject(error);
                        setQueryState({
                            ...queryStateRef.current,
                            loadingMore: false,
                            errorLoadingMore: error,
                            paginationState: initPaginationState
                        });
                    }
                });
            } else {
                reject("FetchMore did not execute!")
            }
        });
    }

    const run = function (runProps?:UseQueryRunProps): Promise<any> {
        return new Promise((resolve, reject) => {
            let variables:QueryVariables = {},
                requiredFiltersReady = true,
                timeReady = false,
                ranTheQuery = false;
            
            const timestampForThisRun = new Date().getTime();
            if (lastRunTimeStamp.current && timestampForThisRun - lastRunTimeStamp.current < 5) {
                console.warn("Multiple back to back calls to 'Run' for " + (initProps.name ? initProps.name + " " : "") + "query! Streamline your logic to avoid this!");
            }

            // Handle filters (Both global, optional overrides passed when calling usQueryHub and optional ovrrides when calling run manually)
            // If you want to override only a few filters on run or during hook init, that has to be taken care of inside the component.
            if (runProps?.skipGlobalFilters || initProps.skipGlobalFilters) {
                variables = Object.assign(variables, runProps?.filters || initProps.filters || {});
            } else {
                let supportedGlobalFilters:FILTERS_OBJECT = {};
                // Only copy values for the supported filters if this flag is true
                if (initProps.onlyConsumeSupportedFilters) {
                    let globalFilters = getAllFilters();
                    for (const filter of getSupportedFilters()) {
                        if (globalFilters[filter]) {
                            supportedGlobalFilters[filter] = globalFilters[filter];
                        }
                    }
                } else {
                    supportedGlobalFilters = getAllFilters();
                }
                if (initProps.consumedFilters) {
                    for (const filterName in supportedGlobalFilters) {
                        if (!initProps.consumedFilters.includes(FILTER_NAME[filterName])) {
                            delete supportedGlobalFilters[filterName];
                        }
                    }
                }
                variables = Object.assign(variables, supportedGlobalFilters, runProps?.filters || initProps.filters || {});
            }
            
            // similar logic for time handling
            if (initProps.timeNotRequired === false || initProps.time || runProps?.time || lastRunVars.current?.time) {
                variables = Object.assign(variables, runProps?.time || initProps.time || absoluteTime);
            }

            // If query flags were passed, merge them on to variables object.
            if (runProps?.queryVariables || initProps?.queryVariables) {
                variables = Object.assign(variables, runProps?.queryVariables || initProps?.queryVariables);
            }

            // Add logic to look at requiredFilters array if it was passed in and fetch conditionally
            if (initProps.requiredFilters) {
                if (Array.isArray(initProps.requiredFilters)) {
                    for (const filter of initProps.requiredFilters) {
                        if (variables[filter] === undefined || variables[filter] === "") {
                            requiredFiltersReady = false;
                            break;
                        }
                    }
                } else {
                    // If a custom method was provided for calculating if all required filters are fulfilled,
                    // execute the method while passing the current variables object. If it returns true, then all filters are ready.
                    // If not, then do not run.
                    const areRequiredFiltersReady = initProps.requiredFilters(variables);
                    if (!areRequiredFiltersReady) {
                        requiredFiltersReady = false;
                    }
                }
            }
            if (initProps.timeNotRequired === true || (
                    variables.startTime && variables.endTime &&
                    (typeof variables.startTime === "string" || typeof variables.startTime === "number") &&
                    (typeof variables.endTime === "string" || typeof variables.endTime === "number")
                ) || variables.duration) {
                if (variables.startTime && (typeof variables.startTime === "string" || typeof variables.startTime === "number")) {
                    variables.startTime = formatDateForDAL(variables.startTime);
                }
                if (variables.endTime && (typeof variables.endTime === "string" || typeof variables.endTime === "number")) {
                    variables.endTime = formatDateForDAL(variables.endTime);
                }
                timeReady = true;
            }

            let cacheDataExpired = false;
            // Live query with expiresAfter is taken care of by using timers and by prop change detection. But for a lazy query,
            // we need to decide if data should be fetched from server or cache when user manually calls `run`.
            if (lazyQuery.current && initProps.expiresAfter) {
                if (
                    // If timestamp was provided and last run timestamp was older than the provided timestamp
                    (initProps.expiresAfter.timestamp && lastRunTimeStamp.current && initProps.expiresAfter.timestamp > lastRunTimeStamp.current) ||
                    // If duration was provided and the time elapsed since last run has exceeded the provided duration
                    (initProps.expiresAfter.duration && lastRunTimeStamp.current && (timestampForThisRun - lastRunTimeStamp.current) > initProps.expiresAfter.duration) ||
                    // If an expires key was provided and the key is different from previous run
                    (initProps.expiresAfter.key !== undefined && lastRunExpiresKey.current !== initProps.expiresAfter.key)
                ) {
                    cacheDataExpired = true;
                }
            }
            const noCache = (runProps?.fetchPolicy === "no-cache" || runProps?.fetchPolicy === 'network-only' || runProps?.noCache || cacheDataExpired) ? true : false;
            if (requiredFiltersReady && timeReady && mountedStateRef.current && (!isEqual(lastRunVars.current, variables) || noCache)) {
                const thisRunIndex = ++runIndex.current;
                // The user has run the query so reset the pagination state.
                queryStateRef.current.paginationState = initPaginationState;
                setQueryState({
                    ...queryStateRef.current,
                    loading: true,
                    loadingMore: false,
                    paginationState: initPaginationState,
                    error: undefined,
                });
                ranTheQuery = true;
                // Maintain the timestamp of when the query was ran last
                lastRunTimeStamp.current = timestampForThisRun;
                // If an expiry key was provided, update it to our local ref since a successful run is happening
                if (initProps.expiresAfter?.key) {
                    lastRunExpiresKey.current = initProps.expiresAfter.key;
                }
                if (runProps?.lazy !== undefined) {
                    lazyQuery.current = runProps.lazy;
                }
                
                // If user has provided an `expiresAfter` timestamp that is in a future time, set a timer
                // that will wait and do it automatically (only if it's a non-lazy query). But before doing that,
                // clear any previously set timers.
                if (expiresAfterTimer.current) {
                    window.clearTimeout(expiresAfterTimer.current);
                    expiresAfterTimer.current = null;
                }
                if (!lazyQuery.current && initProps.expiresAfter) {
                    if (initProps.expiresAfter.timestamp && initProps.expiresAfter.timestamp > lastRunTimeStamp.current) {
                       expiresAfterTimer.current = window.setTimeout(() => {
                           expiresAfterTimer.current = null;
                           run({ noCache: true });
                       }, (initProps.expiresAfter.timestamp - lastRunTimeStamp.current));
                    // If expiresAfter a duration in milliseconds was provided, simply expire the last fetched data after given duration
                    } else if (initProps.expiresAfter.duration) {
                        expiresAfterTimer.current = window.setTimeout(() => {
                            expiresAfterTimer.current = null;
                            run({ noCache: true });
                        }, (initProps.expiresAfter.duration));
                    }
                }

                let fPolicy: FetchPolicy = runProps?.fetchPolicy ? runProps?.fetchPolicy : (noCache ? "network-only" : "cache-first");
                lastRunVars.current = cloneDeep(variables);
                startTimer(timers.current, DEBUG_QUERIES);
                // If there's already an active watchQuery, unsubscribe it.
                if (graphqlWatchQuerySubscription.current) {
                    graphqlWatchQuerySubscription.current.unsubscribe();
                    graphqlWatchQuerySubscription.current = undefined;
                }
                graphqlWatchQuery.current = apolloClient.watchQuery({
                    query: gqlQuery.getGqlQuery(),
                    variables: variables,
                    fetchPolicy: fPolicy
                });
                // This subscription is just to resolve the run promise. Once resolved, we will unsubscribe it so that
                // any future `next` calls due to update to records in cache don't keep attempting to resolve
                // the promise over and over again.
                const runPromiseSubscription = graphqlWatchQuery.current.subscribe({
                    next: ({data}) => {
                        resolve(data);
                        runPromiseSubscription.unsubscribe()
                    },
                    error: error => {
                        reject(error);
                        runPromiseSubscription.unsubscribe();
                    },
                });                
                graphqlWatchQuerySubscription.current = graphqlWatchQuery.current.subscribe({
                    next: ({data}) => {
                        stopTimer(timers.current, gqlQuery, DEBUG_QUERIES);
                        if (mountedStateRef.current && thisRunIndex === runIndex.current) {
                            const newPaginationState = getPaginationState(data, queryState.paginationState);
                            setQueryState({
                                ...queryStateRef.current,
                                loading: false,
                                data,
                                error: undefined,
                                paginationState: newPaginationState,
                            });
                        }
                    },
                    error: (error) => {
                        stopTimer(timers.current, gqlQuery, DEBUG_QUERIES);
                        if (mountedStateRef.current && thisRunIndex === runIndex.current) {
                            setQueryState({
                                ...queryStateRef.current,
                                loading: false,
                                data: undefined,
                                error: error,
                                paginationState: initPaginationState
                            });
                        }
                    }
                });
            }
            if (!ranTheQuery) {
                resolve(ranTheQuery);
            }
        });
    }

    const updateQuery = function (newQuery:Query) {
        setGqlQuery(newQuery);
    }

    useEffect(() => {
        // We want this hook to call a run when time changes for a query that consumes global time
        // and isn't lazy. We only want to run this after the first run which would happen by
        // a different useEffect hook for all non-lazy queries. Using lastRunTimeStamp.current to
        // identify this case. This value will be set for subsequent runs.
        if (!initProps.timeNotRequired && !lazyQuery.current && lastRunTimeStamp.current) {
            if (initProps.noCache) {
                run({ noCache: true });
            } else {
                run();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ absoluteTime ]);

    // Detect if there are changes to the filters, if there are changes to the filters we want to re-run the query
    const initPropFilters = useRef<FILTERS_OBJECT | undefined>(cloneDeep(initProps.filters));
    if (!isEqual(initPropFilters.current, initProps.filters)) {
        initPropFilters.current = cloneDeep(initProps.filters);
    }

    // Detect if there are any changes to the query variables
    const initQueryVariables = useRef<QueryVariables | undefined>(cloneDeep(initProps.queryVariables));
    if (!isEqual(initQueryVariables.current, initProps.queryVariables)) {
        initQueryVariables.current = cloneDeep(initProps.queryVariables);
    }

    // Run immediately if lazy flag wasn't passed as true during initial load
    // Attempt a run when query changes. Filter and time change will trigger a re-render of this hook.
    useEffect(() => {
        if (!lazyQuery.current) {
            // If the component running the query provided an `expiresAfter` and this is not the first time
            // the query is running (lastRunTimeStamp will have a value on subsequent runs)
            if (initProps.expiresAfter && lastRunTimeStamp.current && 
                (
                    // If timestamp was provided and last run is older than the provided timestamp
                    (initProps.expiresAfter.timestamp && initProps.expiresAfter.timestamp > lastRunTimeStamp.current) ||
                    // If duration was provided and this is not the subsequent run, then the user has modified the duration.
                    // This will trigger an immediate run on the first change
                    initProps.expiresAfter.duration ||
                    // If an expiry key was provided and it's different from the last run
                    (initProps.expiresAfter.key && initProps.expiresAfter.key !== lastRunExpiresKey.current)
                )
            ) {
                // run the query with `noCache` as true to force-load from the server
                run({ noCache: true });
            } else if (initProps.noCache) {
                run({ noCache: true });
            } else {
                run();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ gqlQuery, filters, initPropFilters.current, initQueryVariables.current, initProps.expiresAfter?.timestamp, initProps.expiresAfter?.duration, initProps.expiresAfter?.key ]);

    useEffect(() => {
        if (initProps.supportedGlobalFilters || initProps.consumedFilters || initProps.excludedGlobalFilters) {
            addSupportedFilters(initProps.supportedGlobalFilters || initProps.consumedFilters || [], initProps.excludedGlobalFilters);
        }
        // Maintaining a state to indicate if this hook is mounted or not.
        // If there's an apolloClient query that completes after the unmount or is about to be ran,
        // check this flag and continue only if it is still mounted.
        return () => {
            mountedStateRef.current = false;
            if (graphqlWatchQuerySubscription.current) {
                graphqlWatchQuerySubscription.current.unsubscribe();
                graphqlWatchQuerySubscription.current = undefined;
            }
            if (initProps.supportedGlobalFilters || initProps.consumedFilters || initProps.excludedGlobalFilters) {
                removeSupportedFilters(initProps.supportedGlobalFilters || initProps.consumedFilters || [], initProps.excludedGlobalFilters);
            }
            // Clear the `expiresAfter` timer if it had been set when unmounting
            if (expiresAfterTimer.current) {
                window.clearTimeout(expiresAfterTimer.current);
                expiresAfterTimer.current = null;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        updateQuery,
        run,
        fetchMore,
        ...queryState
    };
}
export { useQuery }

/** starts the current timer when the debug flag is true, otherwise it does nothing.
 *  @param timers the array of timer objects with all the timings.
 *  @param debug a boolean value, true if we are debugging, false otherwise.*/
/* istanbul ignore next */
function startTimer(timers: Array<TIMER>, debug: boolean | undefined): void {
    if (debug) {
        const timer = timers[timers.length - 1];
        timer.start = new Date().getTime();
    }
}

/** stops the current timer and prints out any timings that exist.
 *  @param timers the array of timer objects with all the timings.
 *  @param query a reference to the graphql Query object.
 *  @param debug a boolean value, true if we are debugging, false otherwise.*/
/* istanbul ignore next */
function stopTimer(timers: Array<TIMER>, query: Query, debug: boolean | undefined): void {
    if (debug) {
        const name = (query && query?.gqlQuery?.definitions?.length > 0 && (query.gqlQuery.definitions[0] as any).name ? (query.gqlQuery.definitions[0] as any).name.value : "unknown");
        console.debug("Query stats for " + name);
        const timer = timers[timers.length - 1];
        timer.end = new Date().getTime();
        let total = 0;
        let min = Number.MAX_SAFE_INTEGER;
        let max = 0;
        for (const timer of timers) {
            const time = timer.end - timer.start;
            min = Math.min(min, time);
            max = Math.max(max, time);
            total += time;
            console.debug("    start: " + timer.start + ", end: " + timer.end + ", time: " + (timer.end - timer.start));
        }
        const avg = (total / (timers.length * 1.0));
        let sd = 0;
        for (const timer of timers) {
            const time = timer.end - timer.start;
            sd = Math.pow(time - avg, 2);
        }
        sd = Math.sqrt(sd / timers.length);
        console.debug("    avg: " + avg.toFixed(2) + ", sigma: " + sd.toFixed(2) + ", min: " + min + ", max: " + max);
        timers.push({start: 0, end: 0});
    }
}
