mutation createLocationGroup($entityIds: [UUID!]!) {
    createLocationGroup(input: { entityIds: $entityIds }) {
        id
        name
        locations {
            id
            name
            type
            elementType
            city
            state
            country
            countryCode
            latitude
            longitude
            reportedBy {
                entityId
                dataSourceId
                dataSourceType
            }
            customProperties {
                id
                name
                valueId
                valueName
            }
        }
    }
}
