/** This module defines a set of utilities for sending data to Azure application
 *  insights.
 *  @module */
import { ApplicationInsights, type ICustomProperties, type IEventTelemetry, type IExceptionTelemetry, type IMetricTelemetry, type IPageViewTelemetry, type ITraceTelemetry } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import type { AuthService } from 'utils/services/AppAuthService.ts';

const reactPlugin = new ReactPlugin();
const instrumentationKey = import.meta.env.VITE_INSTRUMENTATION_KEY

let appInsights: ApplicationInsights; 
if (instrumentationKey) {
	appInsights = new ApplicationInsights({
		config: {
			instrumentationKey: instrumentationKey,
			extensions: [reactPlugin],
            enableAutoRouteTracking: true,
		},
	});
	appInsights.loadAppInsights();
}

/** this constant defines a function that is used to track page views using application insights.
 *  @param aiContext the application insights context.
 *  @param authService a reference to the auth service that has the user and tenant information.
 *  @param properties the properties passed to the application insights. */
const trackPageView = (aiContext: ReactPlugin, authService: AuthService, properties: IPageViewTelemetry): void => {
    // Add some default properties to all page views
    const userName = authService.getUserAccount().getUsername();
    properties.properties = {
        ...properties?.properties || {},
        tenant: authService.getTenant().tenant_id,
        //username: userName,
        userid: authService.getUserAccount().getUserId(),
        domain: userName.includes("@") ? userName.substring(userName.indexOf("@") + 1, userName.length) : "Unknown",
    };
    // track the page view
    aiContext.trackPageView({ ...properties });
};

/** this constant defines a function that is used to track events using application insights.
 *  @param aiContext the application insights context.
 *  @param authService a reference to the auth service that has the user and tenant information.
 *  @param properties the properties passed to the application insights. */
const trackEvent = (aiContext: ReactPlugin, authService: AuthService, properties: IEventTelemetry): void => {
    // Add some default properties to all events
    const userName = authService.getUserAccount().getUsername();
    properties.properties = {
        ...properties?.properties || {},
        tenant: authService.getTenant().tenant_id,
        //username: userName,
        userid: authService.getUserAccount().getUserId(),
        domain: userName.includes("@") ? userName.substring(userName.indexOf("@") + 1, userName.length) : "Unknown",
    };
    // track the event
    aiContext.trackEvent({ ...properties });
};

/** this constant defines a function that is used to track metrics using application insights.
 *  @param aiContext the application insights context.
 *  @param properties the properties passed to the application insights.
 *  @param customProperties the custom properties. */
const trackMetric = (aiContext: ReactPlugin, properties: IMetricTelemetry, customProperties: ICustomProperties): void => {
    aiContext.trackMetric({ ...properties }, { ...customProperties });
};

/** this constant defines a function that is used to track exceptions using application insights.
 *  @param aiContext the application insights context.
 *  @param properties the properties passed to the application insights. */
const trackException = (aiContext: ReactPlugin, properties: IExceptionTelemetry): void => {
    aiContext.trackException({ ...properties });
};

/** this constant defines a function that is used to track traces using application insights.
 *  @param aiContext the application insights context.
 *  @param properties the properties passed to the application insights. */
const trackTrace = (aiContext: ReactPlugin, properties: ITraceTelemetry): void => {
    aiContext.trackTrace({ ...properties });
};

/* I cannot find this in the ReactPlugin
const trackDependency = (aiContext: ReactPlugin, properties): void => {
    aiContext.trackDependency({ ...properties });
};

const trackRequest = (aiContext: ReactPlugin, properties): void => {
    aiContext.trackRequest({ ...properties });
};
*/

/** this enum defines all the enumerated event names. */
export enum EventNames {
    /** the enumerated value for the notes added event. */
    NOTES_ADD                  = "notes_add",
    /** the enumerated value for the runbook update event. */
    RUNBOOK_UPDATE             = "runbook_update",
    /** the enumerated value for the runbook output event. */
    RUNBOOK_OUTPUT_VIEW        = "runbook_output_view",
    /** the enumerated value for the runbook validation error event. */
    RUNBOOK_VALIDATION_ERROR   = "runbook_validation_error",
    /** the enumerated value for the incident status change event. */
    INCIDENTS_STATUS_CHANGE    = "incidents_status_change",
    /** the enumerated value for the incident priority change event. */
    INCIDENTS_PRIORITY_CHANGE  = "incidents_priority_change",
    /** the enumerated value for the search invoked event. */
    SEARCH_INVOKE              = "search_invoke",
    /** the enumerated value for the search invoked event. */
    SEARCH_RESULT              = "search_result",
    /** the enumerated value for the faceted search invoked event. */
    FACETED_SEARCH             = "faceted_search",
    /** the enumerated value for the share link event. */
    SHARE_LINK_ACCESS          = "share_link_access",
    /** the enumerated value for the help invoked from the UI event.  This does not catch when help is called directly. */
    HELP_INVOKED               = "help_invoke",
    /** the enumerated value for the field level help popup invoked from the UI event. */
    HELP_POPUP_INVOKED         = "help_popup_invoke",
    /** the enumerated value for the try it invoked from the UI event. */
    TRY_IT_INVOKED             = "try_it_invoke",
    /** the enumerated value for the UA Video invoked from the UI event. */
    HELP_VIDEO_INVOKED         = "help_video_invoke",
    /** the enumerated value for the Automation Mapping UI events. */
    AUTOMATION_MAPPING_ACTION  = "automation_mapping_action",
    /** the enumerated value for the Custom Properties UI events. */
    CUSTOM_PROPERTIES_ACTION   = "custom_properties_action"
}

/** this enum defines all the enumerated help sources. */
export enum HelpSources {
    /** the enumerated value for the page level help. This is when the user opens help from the page icon. */
    PAGE        = "page",
    /** the enumerated value for the application level help.  This is when the user opens up help from the main menubar. */
    APP         = "application",
    /** the enumerated value for the field level help.  This is when the user opens up help from the field help icon. */
    FIELD       = "field",
    /** the enumerated value for the node level help.  This is when the user opens up help from the node help icon. */
    NODE        = "node",
}

export {
    reactPlugin,
    appInsights,
    trackPageView,
    trackEvent,
    trackMetric,
    trackException,
    trackTrace,
    // Cannot find these two in the ReactPlugin
    //trackRequest,
    //trackDependency,
};
