import { useMemo, useState } from "react";
import { ErrorToaster, IconNames, SuccessToaster } from "@tir-ui/react-components";
import { STRINGS } from "app-strings";
import { FormModal } from "components/common/modal/FormModal.tsx";
import { InputField } from "components/common/form/InputField.tsx";
import { TextAreaField } from 'components/common/form/TextAreaField.tsx';
import { SelectField } from 'components/common/form/SelectField.tsx';
import { Button, Divider, Icon, InputGroup, Label, Switch } from "@blueprintjs/core";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { useCustomProperties } from "utils/hooks/useCustomProperties.ts";
import { WrapInTooltip } from "components/common/wrap-in-tooltip/WrapInTooltip.tsx";
import { generateRandomID } from "components/common/condition-tree-builder/condition/ConditionUtils.ts";
import { PARAM_NAME } from "components/enums/QueryParams.ts";
import { clearQueryParam, useQueryParams } from "utils/hooks/useQueryParams.ts";
import CREATE_CUSTOM_PROPERTY_MUTATION from "./../queries/create-custom-property-mutation.graphql";
interface CreateCustomPropertyMutationInput {
    name: string;
    description: string;
    validTypes: any[];
    values: any[];
}

export default function AddCustomPropertyModal(props) {
    const [createCustomProperty] = useMutation<any, CreateCustomPropertyMutationInput>(
        CREATE_CUSTOM_PROPERTY_MUTATION, {
        onCompleted: (data) => {
            SuccessToaster({
                message: 'Success',
            });
        },
        onError: (err) => {
            ErrorToaster({
                message: 'Failure',
            });
            console.error(err?.message);
        },
    });

    const { params } = useQueryParams({ listenOnlyTo: [PARAM_NAME.allowMultiType, PARAM_NAME.fromExplorerEntity] });

    const customPropertiesQuery = useCustomProperties({});
    const customProperties = useMemo(() => {
        return customPropertiesQuery.data || [];
    }, [customPropertiesQuery.data]);

    const [locations, setLocations] = useState<boolean>(false);
    const [applications, setApplications] = useState<boolean>(false);
    const [devices, setDevices] = useState<boolean>(false);
    const [interfaces, setInterfaces] = useState<boolean>(false);

    const [values, setValues] = useState<Array<any>>([]);
    const [valuesToDelete, setValuesToDelete] = useState<Array<string>>([]);
    const [uniqueValueError, setUniqueValueError] = useState<boolean>(false);

    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .label('Name')
            .required()
            .test('IS_UNIQUE', STRINGS.CUSTOM_PROPERTIES_PAGE.addDialog.errors.unique, (el: any) => {
                return el?.length > 0 && !customProperties?.map(x => x.name).includes(el);
            }),
    });

    enum ValidTypes {
        APPLICATION = "APPLICATION",
        LOCATION = "LOCATION",
        NETWORK_DEVICE = "NETWORK_DEVICE",
        NETWORK_INTERFACE = "NETWORK_INTERFACE",
    }

    const multiTypes = {
        Applications: "APPLICATION",
        Locations: "LOCATION",
        Devices: "NETWORK_DEVICE",
        Interfaces: "NETWORK_INTERFACE",
    };
    const fromBulkTypes = {
        application: "APPLICATION",
        location: "LOCATION",
        device: "NETWORK_DEVICE",
        interface: "NETWORK_INTERFACE",
    };
    const [propertyType, setPropertyType] = useState(fromBulkTypes[params.fromExplorerEntity] || "");

    const handleValueDelete = (id: string) => {
        setValues(values.filter((value) => value.id !== id));
        if (!valuesToDelete.includes(id)) {
            setValuesToDelete([...valuesToDelete, id]);
        }
    };

    const handleValueAdd = () => {
        const id = "add-" + generateRandomID();
        setValues([...values, { id: id, name: "", action: "add" }]);
    };

    const handleValueUpdate = (id: string, name: string) => {
        const uniqueValue = values
            .map((value) => {
                if (value.name === name.trim()) {
                    return false;
                } else {
                    return true;
                }
            })
            .includes(false);

        setUniqueValueError(uniqueValue);

        setValues(
            values.map((value) => {
                if (value.id === id) {
                    return Object.assign({}, value, {
                        id: id,
                        name: name,
                        action: "edit",
                    });
                } else {
                    return Object.assign({}, value, value);
                }
            })
        );
    };

    const formConfig = {
        initialValues: {
            name: "",
            description: "",
            locations: locations,
            applications: applications,
            devices: devices,
            interfaces: interfaces,
            singleType: propertyType,
            values: values,
        },
        validationSchema: validationSchema,
        onSubmit: (submitData: any) => {
            let validTypes: Array<object> = [];
            applications && validTypes.push({ type: ValidTypes.APPLICATION });
            locations && validTypes.push({ type: ValidTypes.LOCATION });
            devices && validTypes.push({ type: ValidTypes.NETWORK_DEVICE });
            interfaces && validTypes.push({ type: ValidTypes.NETWORK_INTERFACE });
            
            !!propertyType && validTypes.push({ type: propertyType });

            const valuesToCreate = values.map((value) => {
                if (value.id.includes("add-") && value.action === "edit") {
                    return { name: value.name };
                } else return null;
            }).filter((value) => value !== null);

            clearQueryParam('fromExplorerEntity', false);
            
            return createCustomProperty({
                variables: {
                    name: submitData.name,
                    description: submitData.description,
                    validTypes: validTypes,
                    values: valuesToCreate
                },
            });
        },
    };

    return (
        <FormModal
            title={STRINGS.CUSTOM_PROPERTIES_PAGE.addDialog.title}
            formikProps={formConfig}
            customLabels={{
                submit: STRINGS.CUSTOM_PROPERTIES_PAGE.addDialog.buttons.submit,
                close: STRINGS.CUSTOM_PROPERTIES_PAGE.addDialog.buttons.cancel
            }}
            {...props}
            className="custom-property-addCProp-modal"
        >
            <div className="row">
                <Label className="label-width col-md-3">
                    {STRINGS.CUSTOM_PROPERTIES_PAGE.addDialog.name.label}
                </Label>
                <div className="col-md-8">
                    <InputField
                        className="w-100"
                        aria-label="custom-property-name-field"
                        placeholder={STRINGS.CUSTOM_PROPERTIES_PAGE.addDialog.name.placeholder}
                        name={"name"}
                        required={true}
                    />
                </div>
            </div>

            <div className="row">
                <Label className="col-md-3">
                    {STRINGS.CUSTOM_PROPERTIES_PAGE.addDialog.description.label}
                </Label>
                <div className="col-md-8">
                    <TextAreaField
                        aria-label="custom-property-description-field"
                        className="w-100"
                        placeholder={STRINGS.CUSTOM_PROPERTIES_PAGE.addDialog.description.placeholder}
                        name="description"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <Label>
                        {STRINGS.CUSTOM_PROPERTIES_PAGE.customPropertyBlade.applicableTo.label}
                    </Label>
                </div>
                <div className="col-md-4">
                    {params.allowMultiType === "true" ?
                        <>
                            <div className="row">
                                <div className="col-md-2">
                                    <Label>
                                        {STRINGS.CUSTOM_PROPERTIES_PAGE.customPropertyBlade.applicableTo.locations}
                                    </Label>
                                </div>
                                <div className="col-md-2">
                                    <Switch
                                        name="locations"
                                        onChange={e => setLocations(e.currentTarget.checked)} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <Label>
                                        {STRINGS.CUSTOM_PROPERTIES_PAGE.customPropertyBlade.applicableTo.applications}
                                    </Label>
                                </div>
                                <div className="col-md-2">
                                    <Switch
                                        name="applications"
                                        onChange={e => setApplications(e.currentTarget.checked)} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <Label>
                                        {STRINGS.CUSTOM_PROPERTIES_PAGE.customPropertyBlade.applicableTo.devices}
                                    </Label>
                                </div>
                                <div className="col-md-2">
                                    <Switch
                                        name="devices"
                                        onChange={e => setDevices(e.currentTarget.checked)} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <Label>
                                        {STRINGS.CUSTOM_PROPERTIES_PAGE.customPropertyBlade.applicableTo.interfaces}
                                    </Label>
                                </div>
                                <div className="col-md-2">
                                    <Switch
                                        name="interfaces"
                                        onChange={e => setInterfaces(e.currentTarget.checked)} />
                                </div>
                            </div>
                        </> :
                        <SelectField
                            name="singleType"
                            onChange={(e) => setPropertyType(e.currentTarget?.value)}
                            value={propertyType}
                        >
                            <option>{STRINGS.CUSTOM_PROPERTIES_PAGE.addDialog.singleType}</option>
                            {Object.entries(multiTypes).map((pType, i) => (<option key={i} value={pType[1]}>{pType[0]}</option>))}
                        </SelectField>

                    }
                </div>
            </div>
            <Divider />
            <div className="fw-bold display-8 mt-3 col-md-3 offset-md-3">
                <Label>
                    {STRINGS.CUSTOM_PROPERTIES_PAGE.customPropertyBlade.values.title}
                </Label>
            </div>
            <div className="mt-2 mb-2">
                {values && values.length > 0 && values.map((value, index) => {
                    return (
                        <div key={value.id + "-" + index} className="row">
                            <InputGroup id={value.id} className="col-md-8 offset-md-3"
                                name={"value"}
                                fill={false}
                                placeholder={STRINGS.CUSTOM_PROPERTIES_PAGE.customPropertyBlade.valuePlaceholder}
                                defaultValue={value.name}
                                onChange={e => handleValueUpdate(value.id, e.currentTarget.value)}
                            />
                            <Label className="col-md-1">
                                <span className="d-flex-inline align-items-center justify-content-between">
                                    <WrapInTooltip tooltip={STRINGS.CUSTOM_PROPERTIES_PAGE.customPropertyBlade.buttons.delete}>
                                        <Icon icon={IconNames.TRASH} onClick={() => handleValueDelete(value.id)} />
                                    </WrapInTooltip>
                                </span>
                            </Label>
                        </div>
                    );
                })}
                {uniqueValueError && (
                    <div className="col-md-9 offset-md-3">
                        <Label className="text-danger ms-1">
                            {STRINGS.CUSTOM_PROPERTIES_PAGE.customPropertyBlade.messages.uniqueValue}
                        </Label>
                    </div>
                )}
            </div>
            <div className="row">
                <div className="col-md-4 offset-md-3">
                    <Button icon={IconNames.PLUS} fill large
                        aria-label="add-value-element"
                        className="btn-dotted"
                        text={STRINGS.CUSTOM_PROPERTIES_PAGE.customPropertyBlade.values.addValue}
                        onClick={() => handleValueAdd()}
                    />
                </div>
            </div>
        </FormModal>
    );
}
