query DataIngest($startTime: String, $endTime: String) {
	statistics(
		filter: {
			time: {
				startTime: $startTime,
				endTime: $endTime,
				granularity: "P1H"
			}
		}
	) {
		dailyMetricsUsage {
			timestamp
			value
		}
	}
}
