query applicationSearch($top: Int!, $skip: Int!, $count: Boolean!, $search: String, $searchFields: [ApplicationSearchFieldEnum], $facets: [ApplicationRequestFacetType!], $filter: ApplicationSearchFilterType, $order: [ApplicationSearchSortInputType!]) {
    searchItems: searchApplications(
        top: $top,
        skip: $skip,
        count: $count,
        facets: $facets,
        filter: $filter,
        order: $order,
        search: $search,
        searchFields: $searchFields
    ) {
        info {
            totalCount
            facets {
                key
                result {
                    count
                    items {
                        value
                        count
                    }
                }
            }
            groupedFacets {
                key
                results {
                    group
                    count
                    items {
                        count
                        value
                    }
                }
            }
        }
        page {
            id
            name
            type
            reportedBy {
                dataSourceId
                dataSourceType
                entityId
                entityName
            }
            customProperties {
                id
                name
                assignedValue {
                    id
                    name
                }
            }
        }
    }
}
