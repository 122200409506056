query NetworkInterfaces($filter: NetworkInterfaceFilterInputType) {
    networkInterfaces(filter: $filter) {
        page {
            id
            name
            ifIpAddresses
            inboundSpeed
            outboundSpeed
            ifDescription
            ifAlias
            ifIndex
            location {
                id
                elementType
                name
                city
                state
                country
                countryCode
                latitude
                longitude
                type
            }
            locationId
            type
            ipAddress
            elementType            
            customProperties {
                id
                name
                description
                permissions
                assignedValue {
                    id
                    name
                }
                validTypes {
                    type
                }
                values {
                    id
                    name
                }
            }
            networkDevice {
                id
                name
                ipAddress
                vendor
                type
                model
                location {
                    id
                    elementType
                    name
                    city
                    state
                    country
                    countryCode
                    latitude
                    longitude
                    type
                }
                locationId
                serialNumber
                osVersion
                isGateway
                elementType
                hostname
            }
            reportedBy {
                dataSourceType
                dataSourceId
            }
        }
    }
}
