/** This file defines the DebugView React component.  The DebugView React component displays
 *  the debug output for a dataset..
 *  @module */

import { useMemo } from "react";
import { BaseRunbookViewProps, DEBUG_OUTPUT_TYPE } from "pages/riverbed-advisor/views/runbook-view/Runbook.type.ts";
import { JsonViewer } from "pages/incident-details/views/primary-indicator/JsonViewer.tsx";

/** an interface that describes the properties that can be passed in to the component.*/
export interface DebugViewProps extends BaseRunbookViewProps {
    // Add any properties that are specific to this view
    /** Pass as true to render the chart with transparent background. */
    transparent?: boolean;
}

/** Creates the debug view, which is a component that displays the debug information from a data set.
 *  @param props an object with the properties passed to the debug view
 *  @returns JSX with the debug view component.*/
export const DebugView = (props: DebugViewProps): JSX.Element => {
    const { jsonOutput } = useMemo(() => {
        let jsonOutput = {};
        if (props.datasets) {
            let dsIndex = 1;
            for (const dataset of props.datasets) {
                const dsName = "Dataset: " + dsIndex++;
                if (dataset.debug?.length) {
                    jsonOutput[dsName] = {};
                    for (const debugOutput of dataset.debug) {
                        if (debugOutput.type === DEBUG_OUTPUT_TYPE.JSON) {
                            jsonOutput[dsName][debugOutput.name] = JSON.parse(debugOutput.value);
                        }
                    }
                }
            }
        }    
        return { jsonOutput };
    }, [props.datasets]);

    return <div className="bg-light"><JsonViewer json={jsonOutput} height={props.height} /></div>;
};
