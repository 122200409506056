query analyticsPolicies {
	analyticsPolicies {
		entityKind
		isCustom
		granularity
		metrics
		enabled
		isSpecialized
		detector {
			type
			upper
			lower
			okValue
			percentile
			tolerance
			n
			m
		}
		matchFunctions {
			matchSource
			appliesTo
			operation
			matchTarget
		}
		defaultConfig {
			entityKind
			isCustom
			granularity
			metrics
			enabled
			detector {
				type
				upper
				lower
				okValue
				percentile
				tolerance
				n
				m
			}
			matchFunctions {
				matchSource
				appliesTo
				operation
				matchTarget
			}
		}
	}
}
