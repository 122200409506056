query cloudIMResourceSearch($top: Int!, $skip: Int!, $count: Boolean!, $search: String, $searchFields: [CloudIMSearchResourceFieldEnum], $facets: [CloudIMSearchResourceRequestFacetType!], $filter: CloudIMSearchResourceFilterType, $order: [CloudIMSearchResourceSortInputType!]) {
    searchItems: cloudIMSearchResource(
        top: $top,
        skip: $skip,
        count: $count,
        facets: $facets,
        filter: $filter,
        order: $order,
        search: $search,
        searchFields: $searchFields
    ) {
        info {
            totalCount
            facets {
                key
                result {
                    count
                    items {
                        value
                        count
                    }
                }
            }
        }
        page {
            rowNumber
            region
            provider
            ownerId
            cloudImEntityKind
            service
            tags
            cloudImSourceType
            instanceType
            liveness
            name
            health
        }
    }
}
