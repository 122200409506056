mutation RemoveCustomPropertyFromNetworkDevices($id: ID!, $filter: NetworkDeviceBulkFilterType, $search: String, $searchFields: [NetworkDeviceSearchFieldEnum]) {
    removeCustomPropertyFromNetworkDevices(
        id: $id
        filter: $filter
        search: $search
        searchFields: $searchFields
    ) {
        modifiedResourcesCount
        ignoredResourcesCount
    }
}
