/** This file defines the impacted services view react component.  The impacted services view
 *  react component displays one bar chart with the impacted services.
 *  @module */
import {useContext, useEffect, useRef} from "react";
import { Query } from "reporting-infrastructure/types/Query.ts";
import { FILTER_NAME } from "components/sdwan/enums/filters.ts";
import { useQuery } from "utils/hooks/useQuery.ts";
import { useGlobalFilters } from "utils/hooks/useGlobalFilters.ts";
import { BarChart, BarData } from "components/common/bar-chart/BarChart.tsx";
import { PRIORITY, PRIORITY_TO_LABEL_MAP } from "components/enums/Priority.ts";
import { PRIORITY_COLORS } from "components/enums/Colors.ts";
import { Unit } from "reporting-infrastructure/types/Unit.class.ts";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade.tsx";
import { STRINGS } from "app-strings";
import type { GroupMetricEvent } from "components/common/chart-base/ChartBase.tsx";
import { AutoUpdateContext } from "pages/network-summary/NetworkSummaryPage.tsx";
import { ChartToolbarControls } from "components/common/chart-base/ChartToolbar.tsx";
import IMPACTED_SERVICES from './impacted-services.graphql';

/** a boolean value, if true show the bars in stacked mode, if false show them in grouped mode, which shows them
 *  next to each other. */
 const stacked = false;

/** a boolean value, if true calculate the incident totals to be the sum of the displayed bars.  If false, use
 *  the total returned by DAL which is the global unfiltered total.  The bars will not add up to this value. */
const incidentTotalFromBars = true;
 
/** an interface that describes the properties that can be passed in to the impacted services view component.*/
export interface ImpactedServicesViewProps {
    /** the handler for the click event on the bar chart, it just forwards the event to the parent component. */
    onGroupMetricSelection?: (event: GroupMetricEvent) => void;
    /** Boolean to control if legend should be displayed or not */
    showLegend?: boolean;
}

/** Renders the impacted services react component.
 *  @param props the properties passed in.
 *  @returns JSX with the impacted services component.*/
export function ImpactedServicesView({ showLegend = false, ...props }: ImpactedServicesViewProps): JSX.Element {
    const { filters } = useGlobalFilters({});
    const priorityFilters: Array<string> = filters && filters[FILTER_NAME.priority] ? filters[FILTER_NAME.priority] as Array<string> : Object.keys(PRIORITY);
    const [lowIndex, modIndex, higIndex, criIndex] = getBarIndices(priorityFilters, !stacked);

    const autoUpdate = useContext(AutoUpdateContext);
    
    // Add the impacted locations graphql query
    let {loading, data, error, run} = useQuery({
        query: new Query(IMPACTED_SERVICES),
        name: "services",
        timeNotRequired: true,
        consumedFilters: [
            FILTER_NAME.priority,
            FILTER_NAME.incidentStatus,
            FILTER_NAME.completionStatus
        ],
    });

    const lastSequenceNumber = useRef(0);
    useEffect(
        () => {
            if (lastSequenceNumber.current !== autoUpdate.sequenceNumber) {
                lastSequenceNumber.current= autoUpdate.sequenceNumber;
                run({fetchPolicy: "no-cache"});
            }
        }, 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [autoUpdate]
    );

    // Store a map with the incident count by the service name
    const incidentsByService = useRef<Record<string, number>>({});

    let barData: Array<BarData> = [];
    let metrics: Array<string> = Array(priorityFilters!.length);
    let units: Array<Unit> = Array(priorityFilters!.length);

    if (data && data?.impactedServices?.incidentsApplications?.length > 0) {
        for (const location of data.impactedServices.incidentsApplications) {
            let barTotal = 0;
            const values = Array(priorityFilters!.length);
            values.fill(0, 0, values.length);
            if (location?.priorities.length > 0) {
                for (const priority of location.priorities) {
                    if (!priorityFilters.includes(priority.priority)) {
                        continue;
                    }
                    const count = priority.count;
                    switch (priority.priority) {
                        case PRIORITY.LOW:
                            values[lowIndex] = count;
                            break;
                        case PRIORITY.MODERATE:
                            values[modIndex] = count;
                            break;
                        case PRIORITY.HIGH:
                            values[higIndex] = count;
                            break;
                        case PRIORITY.CRITICAL:
                            values[criIndex] = count;
                            break;
                    }
                    barTotal += count;
                }
            }
            barData.push({label: location.name, values, group: location.name});
            incidentsByService.current[location.name] = incidentTotalFromBars ? barTotal : location.count;
        }
    }

    barData.sort((a, b) => {
        if (!stacked) {
            for (let i = 0; i < a.values.length; i++) {
                if (b.values[i] - a.values[i] !== 0) {
                    return b.values[i] - a.values[i];
                }
            }    
        } else {
            for (let i = a.values.length - 1; i >= 0; i--) {
                if (b.values[i] - a.values[i] !== 0) {
                    return b.values[i] - a.values[i];
                }
            }    
        }
        return 0;
    });

    const seriesColors: Array<string> = [];
    if (lowIndex > -1) {
        metrics[lowIndex] = PRIORITY_TO_LABEL_MAP[PRIORITY.LOW];
        units[lowIndex] = new Unit();
        seriesColors[lowIndex] = PRIORITY_COLORS[PRIORITY.LOW];
    }
    if (modIndex > -1) {
        metrics[modIndex] = PRIORITY_TO_LABEL_MAP[PRIORITY.MODERATE];
        units[modIndex] = new Unit();
        seriesColors[modIndex] = PRIORITY_COLORS[PRIORITY.MODERATE];
    }
    if (higIndex > -1) {
        metrics[higIndex] = PRIORITY_TO_LABEL_MAP[PRIORITY.HIGH];
        units[higIndex] = new Unit();
        seriesColors[higIndex] = PRIORITY_COLORS[PRIORITY.HIGH];
    }
    if (criIndex > -1) {
        metrics[criIndex] = PRIORITY_TO_LABEL_MAP[PRIORITY.CRITICAL];
        units[criIndex] = new Unit();    
        seriesColors[criIndex] = PRIORITY_COLORS[PRIORITY.CRITICAL];
    }

    let maxPointWidth: number | undefined = 10;
    let groupPadding = 0.2;
    // The group padding specifies the space between the groups of bars.  If this
    // space is too small the bars become really large, so progressively increase
    // the space as the number of bars decrease to hold the size of the bars 
    // relatively constant.
    if (!stacked) {
        maxPointWidth = undefined;
        groupPadding = 0.15;
        switch (priorityFilters.length) {
            case 3:
                groupPadding = 0.2;
                break;
            case 2:
                groupPadding = 0.3;
                break;
            case 1:
                groupPadding = 0.4;
                break;
        }    
    }

    const chartOptions: Highcharts.Options = {
        legend: {
            enabled: showLegend,
            align: "center",
            verticalAlign: "bottom",
        },
        yAxis: {
            labels: {
                enabled: true,
            },
            minTickInterval: 1,
        },
        xAxis: {
            labels: {
                formatter: function (this: any) {
                    const name = this && this.value ? this.value : STRINGS.networkDashboard.unknownBarLabel;
                    const count = name && incidentsByService?.current[name] ? incidentsByService.current[name] : 0;
                    return '<span class="fw-bold display-9"><b>' + name + '</b></span><br /><span>' + count + ' ' + STRINGS.networkDashboard.incidentsLabel + '</span>';
                }
            }
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    formatter: function (this: any) {
                        if (this.point && this.point.y && this.point.unit) {
                            return "";
                        }
                    },
                },
                groupPadding: groupPadding,
                pointPadding: 0,
                maxPointWidth: maxPointWidth,
                minPointLength: 3
            }
        },
    };

    // const dataAvailable = barData.length > 0;
    const hasZeroValues = barData.every(x => x.values.every(x => x === 0));
    return <DataLoadFacade loading={loading} error={error} data={data} showContentsWhenLoading={!hasZeroValues} transparent={!hasZeroValues}>
        { 
            !hasZeroValues ? 
            <BarChart barData={barData} metrics={metrics} metricIds={metrics} units={units} transparent={true} height="345px" options={chartOptions}
                controls={[ChartToolbarControls.fullScreen]} settings={{stacked, showMore: true}}
                seriesColors={seriesColors}
                onGroupMetricSelection={(event) => {
                    if (props.onGroupMetricSelection) {
                        props.onGroupMetricSelection(event);
                    }
                }}
                enableFullScreen={true}
                fullScreenTitle={STRINGS.networkDashboard.impactedServices}
            /> : 
            <div className="absolute-center">{STRINGS.no_data_to_display}</div>
        }
    </DataLoadFacade>;
};

/** return the indices to use in the bar data for the low, moderate, high, and critical priorities.
 *  @param priorityFilters the currently applied priority filters.
 *  @param descending a boolean value, if true order the bars in descending order Critical to Low.
 *  @returns an array with the indices of low, moderate, high, and critical in that order.  If a priority
 *      is not currently in the list of filters, its index is -1, which indicates it should be skipped.*/
 function getBarIndices(priorityFilters: Array<string>, descending: boolean): Array<number> {
    let index = 0, lowIndex = -1, modIndex = -1, higIndex = -1, criIndex = -1;
    if (descending) {
        criIndex = priorityFilters.includes(PRIORITY.CRITICAL) ? index++ : -1;
        higIndex = priorityFilters.includes(PRIORITY.HIGH) ? index++ : -1;
        modIndex = priorityFilters.includes(PRIORITY.MODERATE) ? index++ : -1;
        lowIndex = priorityFilters.includes(PRIORITY.LOW) ? index++ : -1;    
    } else {
        lowIndex = priorityFilters.includes(PRIORITY.LOW) ? index++ : -1;    
        modIndex = priorityFilters.includes(PRIORITY.MODERATE) ? index++ : -1;
        higIndex = priorityFilters.includes(PRIORITY.HIGH) ? index++ : -1;
        criIndex = priorityFilters.includes(PRIORITY.CRITICAL) ? index++ : -1;
    }
    return [lowIndex, modIndex, higIndex, criIndex];
}
