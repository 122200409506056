import { STRINGS } from "app-strings";
import { CustomIconNames, StatusLED } from "components/common/status-led/StatusLED.tsx";
import type { PRIORITY } from "components/enums/Priority.ts";
import { SIZE } from "components/enums/Sizes.ts";
import { PRIORITY_COLORS, PRIORITY_CLASS } from "components/enums/Colors.ts";
import { PopoverPosition, Tooltip } from "@blueprintjs/core";

export interface PriorityLEDFormatterProps {
    priority?: PRIORITY | "UNKNOWN";
    showStatusAsBackground?: boolean;
    isSummary?: boolean;
}

/** A simple wrapper that takes in priority and returns a Priority LED */
export function PriorityLEDFormatter ({
    priority = "UNKNOWN",
    showStatusAsBackground = false,
    isSummary = false
}: PriorityLEDFormatterProps) {
    const LEDColor = PRIORITY_COLORS[priority] || PRIORITY_COLORS.UNKNOWN;
    const priorityClass = PRIORITY_CLASS[priority] || PRIORITY_CLASS.UNKNOWN;
    const label = STRINGS.incidentPriorities[priority.toLowerCase()] || "";
    return showStatusAsBackground && !isSummary ?
        <div className={"priority-indicator " + priorityClass.bg + " d-inline-block rounded-0 display-9 p-1"}>{label}</div> : isSummary ? 
        <Tooltip
          usePortal={false} content={label} position={PopoverPosition.BOTTOM_RIGHT} transitionDuration={50}>
          <div style={{ padding: '.35rem' }} className={"priority-indicator " + priorityClass.bg + " d-inline-block rounded-circle display-9"}></div>
        </Tooltip> :
        <StatusLED
            color={LEDColor}
            size={SIZE.s}
            label={label}
            toolTip={priority}
            icon={CustomIconNames.roundedBar}
        />;
}
