/** This file defines the incident priority timeseries view react component.  The 
 *  incident priority time series view react component displays the time series 
 *  for each incident priority.
 *  @module */
import { useContext, useEffect, useRef } from "react";
import { Button, Menu, MenuItem, Position, Popover, Tooltip } from '@blueprintjs/core';
import { Query } from "reporting-infrastructure/types/Query.ts";
import { useQuery } from "utils/hooks/useQuery.ts";
import { DURATION } from 'utils/stores/GlobalTimeStore.ts';
import { FILTER_NAME } from "components/sdwan/enums/filters.ts";
import { parseTimeFromDAL } from "utils/hooks/useQueryFormatters.ts";
import { useQueryParams } from "utils/hooks/useQueryParams.ts";
import { Unit } from "reporting-infrastructure/types/Unit.class.ts";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade.tsx";
import { TimeChart, TimeChartDatum } from "components/common/time-chart/TimeChart.tsx";
import { IconNames } from "@tir-ui/react-components";
import { STRINGS } from "app-strings";
import { PRIORITY, PRIORITY_TO_LABEL_MAP } from "components/enums/Priority.ts";
import { HIDDEN_STATES, INCIDENT_STATUS, INCIDENT_STATUS_TO_LABEL_MAP } from "components/enums/IncidentStatus.ts";
import { PRIORITY_COLORS } from "components/enums/Colors.ts";
import type { GroupMetricEvent } from "components/common/chart-base/ChartBase.tsx";
import { AutoUpdateContext } from "pages/network-summary/NetworkSummaryPage.tsx";
import { ChartToolbarControls, LineStyle } from "components/common/chart-base/ChartToolbar.tsx";
import INCIDENT_PRIORITY from './incident-priority.graphql';

// If true show the full status text in the status combo box, if false, show a status of the format "x + 1"
const showFullStatusText = true;

// This constant has the array of options for the status combo box.
const statusOptions = [
    {label: INCIDENT_STATUS_TO_LABEL_MAP[INCIDENT_STATUS.NEW],              value: "Open",  status: INCIDENT_STATUS.NEW},
    {label: INCIDENT_STATUS_TO_LABEL_MAP[INCIDENT_STATUS.INVESTIGATING],    value: "Inv",   status: INCIDENT_STATUS.INVESTIGATING},
    {label: INCIDENT_STATUS_TO_LABEL_MAP[INCIDENT_STATUS.ON_HOLD],          value: "Hld",   status: INCIDENT_STATUS.ON_HOLD},
].filter(item => !HIDDEN_STATES.includes(item.status));

// This constant has the array of options for the time combo box.
const timeOptions = [
    {label: STRINGS.networkDashboard.twelveHrsTime,     value: "P12H"  /*DURATION.HOUR_12*/ },
    {label: STRINGS.networkDashboard.twentyFourHrsTime, value: "P24H"  /*DURATION.DAY_1*/ },
    {label: STRINGS.networkDashboard.twoDaysTime,       value: "P48H"  /*DURATION.DAY_2*/ },
    {label: STRINGS.networkDashboard.oneWeekTime,       value: "P168H" /*DURATION.DAY_7*/ }
];

/** an interface that describes the properties that can be passed in to the incident priority view component.*/
export interface IncidentPriorityTimeSeriesViewProps {
    /** the handler for the click event on the bar chart, it just forwards the event to the parent component. */
    onGroupMetricSelection?: (event: GroupMetricEvent) => void;
    /** Boolean to control if legend should be displayed or not */
    showLegend?: boolean;
}

/** Renders the incident priority time series chart react component.
 *  @param props the properties passed in.
 *  @returns JSX with the incident priority time series chart component.*/
export function IncidentPriorityTimeSeriesView({ showLegend = false, ...props }: IncidentPriorityTimeSeriesViewProps): JSX.Element {
    const { params, setQueryParams } = useQueryParams({ 
        listenOnlyTo: ["status", "interval"], 
        paramDefaults: {status: [INCIDENT_STATUS.NEW, INCIDENT_STATUS.INVESTIGATING].join(","), interval: DURATION.DAY_1} }
    );

    const autoUpdate = useContext(AutoUpdateContext);
    
    let status = [INCIDENT_STATUS.NEW, INCIDENT_STATUS.INVESTIGATING];
    if (params.status) {
        // Set the selected status based on the query parameters
        status = (params.status.split(",") as Array<INCIDENT_STATUS>);
    }

    let interval = timeOptions[1];
    if (params.interval) {
        // Set the selected time option based on the query parameters
        for (const timeOption of timeOptions) {
            if (params.interval === timeOption.value) {
                interval = timeOption;
                break;
            }
        }
    }

    const selectedStates = useRef<Array<INCIDENT_STATUS>>(status);

    // Create the query to retrieve the application time series data
    const { loading, data, error, run } = useQuery({
        query: new Query(INCIDENT_PRIORITY),
        name: "priority",
        queryVariables: {
            //...durationToRoundedTimeRange(interval.value),
            interval: interval.value,
            noCache: true,
        },
        filters: {
            [FILTER_NAME.incidentStatus]: status ? status : undefined
        },
        consumedFilters: [
            FILTER_NAME.priority,
            FILTER_NAME.completionStatus
        ],
    });

    const lastSequenceNumber = useRef(0);
    useEffect(
        () => {
            if (lastSequenceNumber.current !== autoUpdate.sequenceNumber) {
                lastSequenceNumber.current= autoUpdate.sequenceNumber;
                run({
                    fetchPolicy: "no-cache",
                    queryVariables: {
                        //...durationToRoundedTimeRange(interval.value),
                        interval: interval.value,
                        noCache: true,
                    }
                });
            }
        }, 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [autoUpdate]
    );

    const tsKeyData: Array<TimeChartDatum> = [
        {
            groupName: PRIORITY_TO_LABEL_MAP[PRIORITY.LOW], groupId: PRIORITY_TO_LABEL_MAP[PRIORITY.LOW], 
            metricName: STRINGS.networkDashboard.incidentsLabel, metricId: STRINGS.networkDashboard.incidentsLabel, 
            unit: new Unit(), 
            data: []
        },
        {
            groupName: PRIORITY_TO_LABEL_MAP[PRIORITY.MODERATE], groupId: PRIORITY_TO_LABEL_MAP[PRIORITY.MODERATE], 
            metricName: STRINGS.networkDashboard.incidentsLabel, metricId: STRINGS.networkDashboard.incidentsLabel, 
            unit: new Unit(), 
            data: []
        },
        {
            groupName: PRIORITY_TO_LABEL_MAP[PRIORITY.HIGH], groupId: PRIORITY_TO_LABEL_MAP[PRIORITY.HIGH], 
            metricName: STRINGS.networkDashboard.incidentsLabel, metricId: STRINGS.networkDashboard.incidentsLabel, 
            unit: new Unit(), 
            data: []
        },
        {
            groupName: PRIORITY_TO_LABEL_MAP[PRIORITY.CRITICAL], groupId: PRIORITY_TO_LABEL_MAP[PRIORITY.CRITICAL], 
            metricName: STRINGS.networkDashboard.incidentsLabel, metricId: STRINGS.networkDashboard.incidentsLabel, 
            unit: new Unit(), 
            data: []
        }
    ];
    let dataAvailable = false;

    if (data && data?.incidentPriority?.samples?.length > 0) {
        data.incidentPriority.samples.forEach(function (o, index) {
            let timeStamp = parseTimeFromDAL(o.timestamp)?.getTime();
            for (const priority of o.incidentsPriority) {
                dataAvailable = true;
                const count = priority.count;
                switch (priority.priority) {
                    case PRIORITY.LOW:
                        tsKeyData[0].data!.push({ x: timeStamp as any, y: count});
                        break;
                    case PRIORITY.MODERATE:
                        tsKeyData[1].data!.push({ x: timeStamp as any, y: count});
                        break;
                    case PRIORITY.HIGH:
                        tsKeyData[2].data!.push({ x: timeStamp as any, y: count});
                        break;
                    case PRIORITY.CRITICAL:
                        tsKeyData[3].data!.push({ x: timeStamp as any, y: count});
                        break;
                }
            }
        }, {});
    }

    const chartOptions: Highcharts.Options = {
        legend: {
            enabled: showLegend,
            align: "center",
            verticalAlign: "bottom",
            floating: false,
        },
    };

    // Create the status options menu items
    const statusMenuItems: Array<JSX.Element> = [];
    for (const statusOption of statusOptions) {
        const input = <>
            <input id={"status-checkbox-" + statusOption.value} type="checkbox" defaultChecked={status.includes(statusOption.status)} value={statusOption.label}
                onChange={(event) => {
/*
                    if (selectedStates.current.includes(statusOption.status)) {
                        selectedStates.current.splice(selectedStates.current.indexOf(statusOption.status), 1);
                    } else {
                        selectedStates.current.push(statusOption.status);
                    }
*/
                }}
            />
            <span style={{marginLeft: "10px"}}>{statusOption.label}</span>
        </>;
        statusMenuItems.push(
            <MenuItem text={input} key={statusOption.value} shouldDismissPopover={false} onClick={() => {
                const el: any = document.getElementById("status-checkbox-" + statusOption.value);
                if (selectedStates.current.includes(statusOption.status)) {
                    if (selectedStates.current.length > 1) {
                        selectedStates.current.splice(selectedStates.current.indexOf(statusOption.status), 1);
                        el.checked = false;
                    } else {
                        el.checked = true;
                    }
                } else {
                    selectedStates.current.push(statusOption.status);
                    el.checked = true;
                }
            }}/>
        );
    }
    //statusMenuItems.push(<MenuItem text={"Done"} key={"Done"} shouldDismissPopover={true} />);

    // Create the time options menu items
    const timeMenuItems: Array<JSX.Element> = [];
    for (const timeOption of timeOptions) {
        timeMenuItems.push(
            <MenuItem text={timeOption.label} active={interval.value === timeOption.value} key={timeOption.value}
                onClick={() => {
                    setQueryParams({ interval: timeOption.value }, true);
                }} 
            />
        );
    }

    return <div>
        <div className="d-flex justify-content-end">
            <div className="me-1">
                <Popover minimal position={Position.BOTTOM_RIGHT} content={
                    <Menu>{statusMenuItems}</Menu>
                } onClosed={() => {
                    setQueryParams({ status: selectedStates.current.join(",") }, true);
                }}>
                    <Tooltip content={getStatusTooltipText(status)} position="bottom" interactionKind="hover">
                            <Button rightIcon={IconNames.CHEVRON_DOWN} 
                                text={getStatusText(status)} 
                                className="text-nowrap" small={true}
                            />
                    </Tooltip>
                </Popover>
            </div>
            <div>
                <Popover minimal position={Position.BOTTOM_RIGHT} content={
                    <Menu>{timeMenuItems}</Menu>
                } >
                    <Tooltip content={STRINGS.networkDashboard.lastText + " " + interval.label} position="bottom" interactionKind="hover">
                        <Button rightIcon={IconNames.CHEVRON_DOWN} text={STRINGS.networkDashboard.lastText + " " + interval.label} 
                            className="text-nowrap" small={true}
                        />
                    </Tooltip>
                </Popover>
            </div>
        </div>
        <DataLoadFacade loading={loading} error={error} data={data} showContentsWhenLoading={dataAvailable} transparent={dataAvailable}>
            <TimeChart
                loading={false} showChartSubtitle={false} height="295px" options={chartOptions} primaryData={tsKeyData}
                seriesColors={[PRIORITY_COLORS[PRIORITY.LOW], PRIORITY_COLORS[PRIORITY.MODERATE], PRIORITY_COLORS[PRIORITY.HIGH], PRIORITY_COLORS[PRIORITY.CRITICAL]]}
                controls={[ChartToolbarControls.fullScreen]} settings={{style: LineStyle.bar, showMore: true}}
                transparent={true}
                onGroupMetricSelection={(event) => {
                    if (props.onGroupMetricSelection) {
                        props.onGroupMetricSelection(event);
                    }
                }}
                enableFullScreen={true}
                fullScreenTitle={STRINGS.networkDashboard.incidentPriority}
            />
        </DataLoadFacade>
    </div>;
};

/** returns the text for the status button control.
 *  @params states the array of INCIDENT_STATUS values with the current states.
 *  @returns a string with the button text. */
function getStatusText(states: Array<INCIDENT_STATUS>): string {
    if (showFullStatusText) {
        let text = "";
        for (const state of states) {
            text += (text.length > 0 ? ", " : "") + INCIDENT_STATUS_TO_LABEL_MAP[state];
        }
        return STRINGS.networkDashboard.statusText + " " + text;
    } else {
        return STRINGS.networkDashboard.statusText + " " + states[0] + (states.length > 1 ? " +" + (states.length - 1): "");
    }
}

/** returns the tooltip text for the status control.
 *  @params states the array of INCIDENT_STATUS values with the current states.
 *  @returns a string with the tooltip. */
function getStatusTooltipText(states: Array<INCIDENT_STATUS>): string {
    let tooltip = "";
    for (const state of states) {
        tooltip += (tooltip.length > 0 ? ", " : "") + INCIDENT_STATUS_TO_LABEL_MAP[state];
    }
    return STRINGS.networkDashboard.statusText + " " + tooltip;
}
