/** This module contains the SearchApiService that can be used to query the cognitive search
 *      service.
 *  @module
 */
import { ApiService } from 'utils/services/ApiService';
import { FIELDS, type OrderExpression, type SearchItem, type SearchRequest, type SearchResult } from '../SearchApiService.ts';
import { Query } from "reporting-infrastructure/types/Query.ts";
import type { ApolloClient } from "apollo-client-preset";
import {
	formatDateForDAL,
	parseTimeFromDAL,
} from "utils/hooks/useQueryFormatters.ts";
import { toCamelCase } from "pages/incident-search/IncidentSearchUtils.ts";

import INCIDENT_QUERY from "./incident-search.graphql";
import APPLICATION_QUERY from "./application-search.graphql";
import LOCATION_QUERY from "./location-search.graphql";
import INTERFACE_QUERY from "./interface-search.graphql";
import DEVICE_QUERY from "./device-search.graphql";
import CUSTOM_PROPERTY_QUERY from "./custom-property-search.graphql";
import RUNBOOKS_SCHEDULE_QUERY from "./search-runbooks-schedule-jobs.graphql";
import SEARCH_RUNBOOKS_QUERY from "./search-runbooks.graphql";
import CLOUD_ASSETS_SEARCH from "./cloud-assets-search.graphql";
import CLOUD_RULES_SEARCH from "./cloud-rules-search.graphql";

type FacetResultItem = {
    value: string;
    count: number;
};

type FacetResult = {
    count: number;
    items: FacetResultItem[];
};

type GroupedFacetResult = {
    count: number;
    items: FacetResultItem[];
    group?: string;
    isGroupedFacet?: boolean;
};

type GroupedFacet = {
    key: string;
    results: GroupedFacetResult[];
};

// NOTE: we make a check below in the `search()` method to determine what are
// custom properties. This is the check:
// `searchRequest.searchFields.includes(facetCategory)`
// The issue with this check is that some hardcoded facets are not `searchFields`.
// This `str[]` type is used as a catch all for other hardcoded
// facets. The intent is for the custom property check to conceptually make
// sense (is this facet hardcoded or a searchField? if not it's a custom property).
/** Used to determine which facets are dynamic or not */
export const arrayFacetsNotInSearchFields = [
    "IF_INDEX",
    "PRIORITY",
    "STATUS",
    "COMPLETION_STATUS",
    "EVENT_CATEGORY",
    "IMPACTED_USER_IP_ADDRESS",
    "ENTITY_KIND",
    "MODEL",
    "OS_VERSION",
    "VENDOR",
    "IS_GATEWAY",
    "SERIAL_NUMBER",
    "ELEMENT_TYPE",
    "INBOUND_SPEED",
    "OUTBOUND_SPEED",
    "DEVICE_IS_GATEWAY",
];

/** this class defines the SearchApiService. */
export class SearchGraphqlApiService extends ApiService {
    /** the ApolloClient that is used to run the GraphQl query. */
    public apolloClient: ApolloClient<any>;
    /** the search query that is used to do the incident search. */
    public incidentQuery: Query = new Query(INCIDENT_QUERY);
    /** the search query that is used to do the application search. */
    public applicationQuery: Query = new Query(APPLICATION_QUERY);
    /** the search query that is used to do the location search. */
    public locationQuery: Query = new Query(LOCATION_QUERY);
    /** the search query that is used to do the interface search. */
    public interfaceQuery: Query = new Query(INTERFACE_QUERY);
    public cloudAssetsQuery: Query = new Query(CLOUD_ASSETS_SEARCH);
    public cloudRulesQuery: Query = new Query(CLOUD_RULES_SEARCH);
    /** the search query that is used to do the device search. */
    public deviceQuery: Query = new Query(DEVICE_QUERY);
    /** the search query that is used to do the custom property search. */
    public customPropertyQuery: Query = new Query(CUSTOM_PROPERTY_QUERY);
    /** the search query that is used to do the runbook schedules search. */
    public runbookScheduleJobs: Query = new Query(RUNBOOKS_SCHEDULE_QUERY);
    
    /** the search query that is used to do the tcp connections search. */
    /* Moved to oldSrc
    public tcpConnectionQuery: Query = new Query(
        loader("./tcp-connection-search.graphql"),
    );
    */
    /** the search query that is used to do the search runbooks search. */
    public ondemandRunbooks: Query = new Query(SEARCH_RUNBOOKS_QUERY);

    /** the constructor for the class.
     *  @param apolloClient the ApolloClient that is used to run the GraphQL query. */
    constructor(apolloClient) {
        super("")
        this.apolloClient = apolloClient;
    }

    /** returns the base uri, this can be overridden in subclasses to allow the uri to change
     *      after construction.
     *  @returns a String with the base uri. */
    protected getBaseUri(): string {
        return "";
    }

    /** runs a search query using a post with the search request.
     *  @param searchRequest the object with the search request.
     *  @returns a Promise which resolves to the returned SearchResult. */
    search(searchRequest: SearchRequest): Promise<SearchResult<SearchItem>> {
        return new Promise(async (resolve, reject) => {
            const facets: {key: string, skip: number, top: number, count: boolean, groupBy?: any}[] = [];
            for (const facet of searchRequest.facets as any) {
                facets.push({top: facet.top, skip: facet.skip, count: facet.count, key: facet.name, groupBy: facet?.groupBy});
            }

            let dalOrder: any = {createdAt: "DESC"};
            if (searchRequest.orderby) {
                if (searchRequest.type === "NetworkDevice" && (searchRequest.orderby as OrderExpression).field === "locationName" as FIELDS) {
                    // The location order by on the device query is a little different than the other order bys, it is an object
                    // within an object.  I don't like special casing this, but for now this works and gets us passed the bug.  If we 
                    // have to do this a second time we should find a more generic way to do this.
                    dalOrder = {
                        location: {name: (searchRequest.orderby as OrderExpression).order.toUpperCase()}
                    };
                } else {
                    dalOrder = {
                        [(searchRequest.orderby as OrderExpression).field]: (searchRequest.orderby as OrderExpression).order.toUpperCase()
                    };
                }
            }

            const dalVariables = {
                top: searchRequest.top,
                skip: searchRequest.skip || 0,
                count: searchRequest.count,
                order: dalOrder,
                searchFields: searchRequest.searchFields,
                facets
            };
            if (searchRequest.search) {
                (dalVariables as any).search = searchRequest.search;
            }
            if ((searchRequest as any)?.dalOptions?.timeRange) {
                if (!(dalVariables as any).filter) {
                    (dalVariables as any).filter = {};
                }
                (dalVariables as any).filter.time = {
                    startTime: formatDateForDAL((searchRequest as any)?.dalOptions?.timeRange.startTime),
                    endTime: formatDateForDAL((searchRequest as any)?.dalOptions?.timeRange.endTime)
                };
            }

            if ((searchRequest as any)?.dalOptions?.facets) {
                if (!(dalVariables as any).filter) {
                    (dalVariables as any).filter = {};
                }
                (dalVariables as any).filter.filters = [];
                for (const facetCategory in (searchRequest as any).dalOptions
                    .facets) {
                    let values: string[] = (searchRequest as any).dalOptions
                        .facets[facetCategory];
                    if (
                        (searchRequest as any)?.searchFields.includes(
                            facetCategory,
                        ) ||
                        arrayFacetsNotInSearchFields.includes(facetCategory) ||
                        searchRequest.type === "Incident" ||
                        searchRequest.type === "CustomProperty" ||
                        searchRequest.type === "TcpConnection" ||
                        searchRequest.type === "OndemandRunbooks" ||
                        searchRequest.type === "RunbookSchedules"
                    ) {
                        (dalVariables as any).filter.filters.push({
                            type: facetCategory,
                            values,
                        });
                    } else {
                        (dalVariables as any).filter.filters.push({
                            type: "CUSTOM_PROPERTY_VALUE_NAME",
                            group: {
                                type: "CUSTOM_PROPERTY_NAME",
                                value: facetCategory,
                            },
                            values,
                        });
                    }
                }
            }

            try {
                let query: Query | undefined;
                switch (searchRequest.type) {
                    case "Incident":
                        query = this.incidentQuery;
                        break;
                    case "Application":
                        query = this.applicationQuery;
                        break;
                    case "Location":
                        query = this.locationQuery;
                        break;
                    case "NetworkInterface":
                        query = this.interfaceQuery;
                        break;
                    case "NetworkDevice":
                        query = this.deviceQuery;
                        break;
                    case "CloudAssets":
                        query = this.cloudAssetsQuery;
                        break;
                    case "CloudRules":
                        query = this.cloudRulesQuery;
                        break;
                    case "CustomProperty":
                        query = this.customPropertyQuery;
                        break;
                    /*
                    case "TcpConnection":
                        query = this.tcpConnectionQuery;
                        break;
                    */
                    case "OndemandRunbooks":
                        query = this.ondemandRunbooks;
                        break;
                    case "RunbookSchedules":
                        query = this.runbookScheduleJobs;
                }

                const processFacets = (
                    facets: any[],
                ): Record<string, FacetResult> => {
                    const facetResults: Record<string, FacetResult> = {};

                    facets.forEach((facet) => {
                        if (facet.key !== "CUSTOM_PROPERTY_VALUE_NAME") {
                            facetResults[facet.key] = {
                                count: facet.result.count,
                                items: facet.result.items || [],
                            };
                        }
                    });

                    return facetResults;
                };

                const processItems = (
                    items: any[],
                    type: string,
                ): SearchItem[] => {
                    return items.map((item) => {
                        switch (searchRequest.type) {
                            case "Incident":
                                return {
                                    ...item,
                                    priority: toCamelCase(item.priority),
                                    status: toCamelCase(item.status),
                                    createdAt: parseTimeFromDAL(item.createdAt)?.toISOString(),
                                    endTime: parseTimeFromDAL(item.endTime)?.toISOString(),
                                    earliestIndicator: parseTimeFromDAL(item.earliestIndicator)?.toISOString(),
                                    latestIndicator: parseTimeFromDAL(item.latestIndicator)?.toISOString(),
                                    lastUpdatedAt: parseTimeFromDAL(item.lastUpdatedAt)?.toISOString(),
                                    impactedUsers: item?.impactedUsers?.map(dItem => {
                                        return {name: dItem.name, ipAddr: dItem.ipAddress};
                                    })};
                            default:
                                return {
                                    ...item
                                };
                        }
                    });
                };

                const processGroupedFacets = (
                    groupedFacets: GroupedFacet[],
                ): Record<string, FacetResult> => {
                    const groupedResults: Record<string, GroupedFacetResult> =
                        {};

                    groupedFacets.forEach((groupedFacet) => {
                        if (groupedFacet.key === "CUSTOM_PROPERTY_VALUE_NAME") {
                            groupedFacet.results.forEach((result) => {
                                // rename 'group' to 'key' to match kvp from
                                // `facets`
                                const key = result.group;
                                if (key) {
                                    groupedResults[key] = {
                                        isGroupedFacet: true,
                                        count: result.count,
                                        items: result.items || [],
                                    };
                                }
                            });
                        }
                    });

                    return groupedResults;
                };

                if (query) {
                    const results = await this.apolloClient.query({
                        query: query.getGqlQuery(),
                        variables: dalVariables,
                        fetchPolicy: "no-cache"
                    });
                    const convertedResults: SearchResult<SearchItem> = {count: 0, facets: {}, items: [], "@odata.context": ""};

                    // process page items
                    if (results?.data?.searchItems?.page?.length) {
                        convertedResults.items = processItems(
                            results.data.searchItems.page,
                            searchRequest.type as any,
                        );
                    }

                    // process `info`
                    if (results?.data?.searchItems?.info) {
                        convertedResults.count =
                            results.data.searchItems.info.totalCount;
                        if (results.data.searchItems.info.facets?.length) {
                            (convertedResults.facets as any) = processFacets(
                                results.data.searchItems.info.facets,
                            );
                        }
                    }

                    // process `groupedFacets`
                    if (
                        results?.data?.searchItems?.info?.groupedFacets?.length
                    ) {
                        const groupedFacetResults = processGroupedFacets(
                            results.data.searchItems.info.groupedFacets,
                        );
                        // Merge groupedFacetResults into convertedResults.facets
                        (convertedResults.facets as any) = {
                            ...convertedResults.facets,
                            ...groupedFacetResults,
                        };
                    }

                    // after all mapping and restructuring
                    resolve(convertedResults);
                } else {
                    reject("Invalid type");
                }
            } catch (error) {
                console.error(error);
                reject(error);
            }
        });
    }
}
