import type React from 'react';
import { DataTile } from 'components/reporting/tiles/data-tile/DataTile.tsx';
import { STRINGS } from "app-strings";
import { IconNames, LoadingOverlay } from '@tir-ui/react-components';
import './DataLoadFacade.scss';
import { SIZE } from 'components/enums/Sizes.ts';
export interface DataLoadFacadeProps {
    /** Flag to show/hide the loading overlay */
    loading?: boolean;
    /** Any input in this property that is truthy will show the standard
     * error view and throw the passed error in Javascript console for reference */
    error?: any;
    /** When showContentsWhenLoading is false, the child components will be rendered
     * only when data prop has a value that will be truthy */
    data?: any;
    /** Child components/contents to be rendered when error is falsy and
     * loading is false or showContentsWhenLoading is true */
    children?: React.ReactNode;
    /** Flag to make the loading overlay cover the entire screen when displayed */
    fullScreen?: boolean;
    /** Controls the size of the displayed loading spinner */
    size?: SIZE;
    /** Text label to display below the spinner */
    loadingText?: string;
    /** When pased as true, children will be rendered whether loading overlay is
     * visible or not. (The error view however takes precedence) */
    showContentsWhenLoading?: boolean;
    /** CSS class to apply on the outer container element. When showContentsWhenLoading
     * is false and content has completed loading (loading prop is false), this will not apply.
     * Children will be rendered without a wrapper container */
    className?: string;
    /** Flag to make the overlay be transparent. Can be used when you want the overlay to exist
     * and loading indicator to show but not be as obstrusive as the standard dark overlay */
    transparent?: boolean;
}
const SIZE_MAP = { xs: 10, s: 20, m: 30, l: 40, xl: 50, xxl: 80 };
export function DataLoadFacade(props: DataLoadFacadeProps) {
    if (props.error) {
        console.error(props.error);
        return (
            <div className={"absolute-center " + (props.className ? props.className : "card-body")}>
                <DataTile data={STRINGS.ERRORS.defaultTitle + "!"} icon={IconNames.HEART_BROKEN} error={props.error} />
            </div>
        )
    } else if (props.showContentsWhenLoading || props.loading) {
        return (
            <div 
                aria-label="loading" 
                className={"data-load-facade p-0" + (props.fullScreen !== true ? " display-loading-in-context" : "") +
                    (props.showContentsWhenLoading ? " show-contents" : "") +
                    (props.transparent ? " transparent-bg" : "") +
                    (props.className ? " " + props.className : " card-body")} 
                style={{overflowY: "auto"}}>
                <LoadingOverlay
                    spinnerSize={SIZE_MAP[props.size || SIZE.l]}
                    visible={props.loading}
                    loadingText={props.loadingText === undefined ? (props.transparent ? "" : (STRINGS.loading + STRINGS.loadingTextIndicator)) : props.loadingText}
                />
                {props.showContentsWhenLoading && props.children}
            </div>
        )
    } else {
        return <>{props.data ? props.children : ""}</>;
    }
}
