/** This module contains the component for rendering the indicator generation card.
 *  @module
 */
import React from 'react';
import { SIZE } from 'components/enums/Sizes.ts';
import { SummaryCard } from 'components/common/layout/summary-card/SummaryCard.tsx';
import { TimeChart, type TimeChartDatum } from 'components/common/time-chart/TimeChart.tsx';
import { Unit } from 'reporting-infrastructure/types/Unit.class.ts';
import { LineStyle } from 'components/common/chart-base/ChartToolbar.tsx';
import { useQuery } from 'utils/hooks/useQuery.ts';
import { Query } from 'reporting-infrastructure/types/Query.ts';
import { DURATION, durationToRoundedTimeRange } from 'utils/stores/GlobalTimeStore.ts';
import { DataLoadFacade } from 'components/reporting/data-load-facade/DataLoadFacade.tsx';
import { STRINGS } from 'app-strings';

import ANALYTICS from './analytics.graphql';

/** Renders the analytics card.
 *  @returns JSX with the analytics card component.*/
const AnalyticsCard = (): JSX.Element => {
    const { loading, data, error } = useQuery({
        name: 'Analytics',
        query: new Query(ANALYTICS),
        // @ts-ignore
        queryVariables: {
            ...durationToRoundedTimeRange(DURATION.DAY_1),
        },
    });

    const tsKeyData: Array<TimeChartDatum> = [];

    if (!loading && data && 'monitor' in data && 'analytics' in data['monitor']) {
        const primaryData = [...data.monitor.analytics.total];

        if (primaryData && primaryData.length > 0) {
            let ts: Array<any> = [];
            primaryData.forEach((item) => {
                ts.push({
                    x: item['timestamp'] * 1000,
                    y: item['value'] ? item['value'] : null,
                });
            });
            tsKeyData.push({
                groupName: '0',
                groupId: 'total',
                metricName: '',
                metricId: 'total',
                unit: new Unit(),
                data: ts,
            });
        }
    }

    const chartOptions: Highcharts.Options = {
        legend: {
            enabled: false,
            align: 'center',
            verticalAlign: 'bottom',
            floating: false,
        },
        xAxis: {
            visible: false,
        },
        yAxis: {
            visible: false,
        },
        plotOptions: {
            series: {
                enableMouseTracking: false,
            },
        },
    };

	return (
		<React.Fragment>
			<DataLoadFacade
				key="analytics"
				loading={loading}
				error={error}
				data={data}
			>
				<SummaryCard
					title={
						<span className="text-black">
							{
								STRINGS.HEALTH_MONITORING_PAGE.cards.analytics
									.title
							}
						</span>
					}
					size="flex"
					height={SIZE.m}
					className="w-min-2"
				>
					{tsKeyData.length > 0 ? (
						<TimeChart
							loading={loading}
							showChartSubtitle={false}
							height="50px"
							options={chartOptions}
							primaryData={tsKeyData}
							settings={{
								style: LineStyle.area,
								showMore: false,
							}}
							transparent={true}
							enableFullScreen={false}
                            showMenu={false}
						/>
					) : (
						<div className="mt-3 align-middle">
							{STRINGS.no_data_to_display}
						</div>
					)}
				</SummaryCard>
			</DataLoadFacade>
		</React.Fragment>
	);
};

export { AnalyticsCard };
