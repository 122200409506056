import { GetRoutes as getRoutes } from 'config/routes.ts';
import { PageWithHeader } from 'components/sdwan/layout/page-with-header/PageWithHeader.tsx';
import { HELP, STRINGS } from 'app-strings';
import AssistAdminView  from './views/AssistAdminView.tsx';

const AssistAdminPage = (): JSX.Element => {
	return (
		<PageWithHeader
			name={AssistAdminPage.name}
			addPadding
			showTimeBar={false}
			showGlobalFilters={false}
			{...getRoutes()['iq-assist-administration']}
			title={STRINGS.IQ_ASSIST_ADMIN_PAGE.subTitle}
            helpInfo={HELP.iqAssistConfig}
		>
			<AssistAdminView />
		</PageWithHeader>
	);
};

export { AssistAdminPage };
