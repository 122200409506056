query indicatorSamples($indicatorMapping: [IndicatorMappingInput], $startTime: String, $endTime: String) {
    samples: indicators(
        filter: {indicatorMappings: $indicatorMapping, time: {startTime: $startTime, endTime: $endTime}, samplesTime: {startTime: $startTime, endTime: $endTime}}
        first: 1
    ) {
        nodes {
            details {
                samples {
                    value
                    timestamp
                }
            }
        }
    }
}