/** This file defines the set complex variable editor React component.
 *  @module */

import React, { useContext, useState } from "react";
import { HELP, STRINGS } from "app-strings";
import { SHOW_CONTEXT } from "components/enums/QueryParams.ts";
import { SimpleNodeEditorProps } from "components/common/graph/editors/simple/SimpleNodeEditor.tsx";
import { UniversalNode } from "components/common/graph/UniversalNode.ts";
import { SetVariableElement } from "components/common/graph/editors/set-variables-simple/SetVariableElement.tsx";
import { RunbookContextSummary } from "components/common/graph/editors/RunbookContextSummary.tsx";
import { SetComplexVariableNodeUtils, SET_COMPLEX_VARIABLE_NODE_EDIT_PROPS } from "components/common/graph/editors/set-variable-complex/SetComplexVariableNodeUtils.tsx";
import { StructuredVariable, INCIDENT_SCOPE, RUNTIME_SCOPE, GLOBAL_SCOPE } from "utils/runbooks/VariablesUtils.ts";
import { VariableContext } from "utils/runbooks/VariableContext.ts";
import { InlineHelp } from "components/common/layout/inline-help/InlineHelp.tsx";
import { NodeLibraryNode } from 'pages/create-runbook/views/create-runbook/NodeLibrary.ts';
import { Callout, Intent } from "@blueprintjs/core";
import { VARIANTS_WITH_GLOBAL_VARS, VARIANTS_WITH_INCIDENT_VARS } from "components/common/graph/types/GraphTypes.ts";

/** this interface defines the object that returns all the complex variable definitions for each scope. */
export interface StructuredVariablesByScope {
    /** the structured variables for the runtime scope. */
    [RUNTIME_SCOPE]: Array<StructuredVariable>;
    /** the structured variables for the incident scope. */
    [INCIDENT_SCOPE]: Array<StructuredVariable>;
    /** the structured variables for the global scope. */
    [GLOBAL_SCOPE]: Array<StructuredVariable>;
}
/** this interface defines the properties that are used to store the nodes configurable parameters. */
interface SetComplexVariableNodeProperties {
    /** an object with the variable that is being set by this node */
    variable: VariableUIProperties;
}

/** Interface that describes the variable's properties used in UI*/
interface VariableUIProperties {
    /** the id of the variable */
    id: string;
    /** the name of the variable */
    name: string;
}

/** Interface that describes the variable's properties from runbook JSON*/
interface VariableProperties {
    /** the name of the variable */
    name: string;
}

/** Component for editing the properties of a set structured variable node
 *  @param selectedNode - Currently selected active set structured variable node
 *  @param libraryNode- Selected set structured variable node's meta data.
 *  @param graphDef - Graphdef object that defines the entire runbook. Provides a way to access all the nodes in the graph 
 *  @returns a JSX component with the set structured variable node editor. */
export const SetComplexVariableEditor = React.forwardRef((
    { selectedNode, libraryNode, graphDef, variant }: SimpleNodeEditorProps, ref: any
): JSX.Element => {

    // Component states
    const [curProperties, setCurProperties] = useState<SetComplexVariableNodeProperties>({
        variable: selectedNode?.getProperty(SET_COMPLEX_VARIABLE_NODE_EDIT_PROPS.VARIABLE) ? { ...selectedNode?.getProperty(SET_COMPLEX_VARIABLE_NODE_EDIT_PROPS.VARIABLE), id: "complex_var" } as VariableUIProperties : { id: "complex_var", name: "" } as VariableUIProperties
    });
    const {getVariables} = useContext(VariableContext);

    const [structuredVariablesOptionList] = useState<StructuredVariablesByScope>({
        runtime: getVariables(RUNTIME_SCOPE).structuredVariables,
        incident: VARIANTS_WITH_INCIDENT_VARS.includes(variant) ? getVariables(INCIDENT_SCOPE).structuredVariables : [],
        global: VARIANTS_WITH_GLOBAL_VARS.includes(variant) ? getVariables(GLOBAL_SCOPE).structuredVariables : []
    } as StructuredVariablesByScope);

    /**
     * Update the selected node properties. This is invoked when done button is clicked
     * on the node editor dialog.
     * @param properties -  Properties in selected node that need to updated
     * @param selectedNode - node being editied
     * @param libraryNode - object that specifies all the editable properties for a given node.
     */
    function updateNode(properties: SetComplexVariableNodeProperties, selectedNode: UniversalNode | undefined, libraryNode: NodeLibraryNode | undefined) {
        if (!selectedNode || !libraryNode || !properties) {
            console.warn("updateNode has invalid inputs. Node update failed");
            return;
        }

        let variableSet: VariableProperties = curProperties?.variable ? { name: curProperties?.variable.name } : { name: "" };
        selectedNode.setProperty(SET_COMPLEX_VARIABLE_NODE_EDIT_PROPS.VARIABLE, variableSet);
    }

    function getNodeProperties(properties, selectedNode: UniversalNode | undefined, libraryNode: NodeLibraryNode | undefined) {
        if (!selectedNode || !libraryNode || !properties) {
            console.warn("updateNode has invlaid inputs. Node update failed");
            return;
        }

        const outputProperties = {};
        outputProperties[SET_COMPLEX_VARIABLE_NODE_EDIT_PROPS.VARIABLE] = curProperties?.variable;
        return outputProperties;
    }

    ref.current = {
        updateNode: () => {
            updateNode(curProperties, selectedNode, libraryNode);
        },
        validate: () => {
            const errorMessages = new Array<string>();
            SetComplexVariableNodeUtils.validateNodeProperties(
                curProperties,
                errorMessages,
            );
            return errorMessages;
        }
    };

    /**
     * Partially update the state of the current properties
     * @param partialProps -  set complex variable node properties that need to be updated
     */
    function syncProperties(partialProps: SetComplexVariableNodeProperties): void {
        const updatedProperties = { ...curProperties, ...partialProps };
        setCurProperties(updatedProperties);
    }

    return (
        <>
            <tr>
                <td className="pb-2" colSpan={2}>
                    <Callout intent={Intent.PRIMARY}>
                        {STRINGS.runbookEditor.nodeEditor.variables.structuredVariable.infoMessage}
                    </Callout>
                </td>
            </tr>
            <tr>
                <td className="font-size-md-large fw-bold pt-2" colSpan={2}>
                    <InlineHelp helpMapping={HELP.RunbookNodeCategory.Variables.set_dataset_variable.variableset}>
                        {STRINGS.runbookEditor.nodeEditor.variables.structuredVariable.variableToSet}
                    </InlineHelp>
                </td>
            </tr>
            <tr>
                <td colSpan={2}>
                    <SetVariableElement
                        simpleVariableElementDefinition={curProperties?.variable}
                        onChange={(event) => {
                            var variableToUpdate = curProperties?.variable;
                            variableToUpdate.name = event.name;
                            syncProperties({ variable: variableToUpdate });
                        }}
                        optionsList={structuredVariablesOptionList}
                        isFirstItem={true}
                        nodeType={libraryNode?.type}
                        variant={variant}
                    />
                </td>
            </tr>
            {SHOW_CONTEXT && <RunbookContextSummary
                currentProperties={getNodeProperties(curProperties, selectedNode, libraryNode)}
                node={graphDef.nodes.find(node => node.id === selectedNode?.getId())!} graphDef={graphDef}
                showOutputExample={true} showInputExample={true}
            />}
        </>
    )
});
