import { useState } from 'react';
import { Alert, AlertProps } from '@blueprintjs/core';
import classnames from 'classnames';
import { LoadingOverlay } from '@tir-ui/react-components';
import { STRINGS } from 'app-strings';
import './ConfirmModal.scss';
/**
 * It is possible to have instances of Alert anywhere in the application, for example modals can handle their own alerts
 * This one is just a generic one that can be used programmatically from anywhere in the application without planning where to render it
 */

type ConfirmModalProps = Omit<AlertProps, 'isOpen' | 'onConfirm'> & {
    message: string | Element | JSX.Element;
    onConfirm: Function;
    hideCancelButton?: boolean;
    onCancel?: Function;
};

type openConfirmFunctionType = (props: ConfirmModalProps) => void;

let openConfirm: openConfirmFunctionType = () => {
    // notify developer in case of misuse, since the real function gets initialized by the component instance
    console.error(
        'To use openModal openConfirm a ConfirmModal component has to be part of the layout'
    );
};

function ConfirmModal() {
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState<number>(0);
    const [alertProps, setAlertProps] = useState<ConfirmModalProps>({
        message: '',
        hideCancelButton: false,
        onConfirm: () => {},
        onCancel: () => {}
    });

    openConfirm = (props: ConfirmModalProps) => {
        setAlertProps(props);
        setIsOpen(true);
    };

    const handleCancel = () => {
        //usually do nothing, if needed add callback here too
        setIsOpen(false);
        setCount(count + 1);

        if (alertProps.onCancel) {
            alertProps.onCancel();
        }
    };

    async function handleConfirm() {
        setLoading(true);
        //the callback
        await alertProps.onConfirm();
        setIsOpen(false);
        setLoading(false);
        //todo handle errors
    }

    const cancelProps = alertProps.hideCancelButton ? {
        cancelButtonText: undefined,
        onCancel: undefined
    } : {
        cancelButtonText: alertProps.cancelButtonText || STRINGS.cancel,
        onCancel: handleCancel
    }

    if (!isOpen) {
        return null;
    }

    //todo add icon and intent as props
    return (
        <Alert
            {...alertProps}
            {...cancelProps}
            confirmButtonText={alertProps.confirmButtonText || STRINGS.confirm}
            isOpen={isOpen}
            onConfirm={handleConfirm}
            className={classnames('confirmation-modal', `confirmation-modal-${count}`, alertProps.className)}
        >
            <LoadingOverlay visible={loading} />
            <div className="message">{alertProps.message}</div>
        </Alert>
    );
}

export { ConfirmModal, openConfirm };
