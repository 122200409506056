import { Outline } from 'components/enums/General.ts';
import { SIZE } from 'components/enums/Sizes.ts';
import { Position, Popover, Classes } from '@blueprintjs/core';
import { Icon } from '@tir-ui/react-components';
import { APP_ICONS } from 'components/sdwan/enums/icons.ts';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { RoundedLinkButton } from 'components/common/rounded-link-button/RoundedLinkButton.tsx';
import { EventNames, trackEvent } from 'utils/appinsights/AppInsights.ts';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { AuthServiceProvider } from 'utils/providers/AuthServiceProvider.ts';
import { STRINGS } from 'app-strings';
import './ShareLinkButton.scss';

const AuthService = AuthServiceProvider.getService();

export interface ShareLinkButtonProps {
	className?: string;
	outline?: Outline;
	size?: SIZE.xs | SIZE.s | SIZE.m;
	href: string;
}

const ShareLinkButton = (props: ShareLinkButtonProps): JSX.Element => {
	const { className, outline=Outline.SHOW, size, href } = props;
	const appInsightsContext = useAppInsightsContext();

	const popupContent = () => {
		return (
			<div className="w-2 p-2">
				<span>{STRINGS.share_help_text}</span>
				<div className="py-2">
					<CopyToClipboard text={href}>
						<RoundedLinkButton
							className={Classes.POPOVER_DISMISS + ' copy-view-link'}
							size={size ? size : SIZE.s}
							outline={Outline.SHOW}
							text={STRINGS.copy_view}
							icon={<Icon icon={APP_ICONS.LINK} />}
						/>
					</CopyToClipboard>
				</div>
			</div>
		);
	};

	const handleOpened = () => {
		reportMetrics();
	};

	// report metrics to App Insights
	const reportMetrics = () => {
        if (appInsightsContext) {
            const properties = {
                name: EventNames.SEARCH_INVOKE
            };
            trackEvent(appInsightsContext, AuthService, properties);
        }
    };

	return (
		<div>
			<Popover
				content={popupContent()}
				usePortal={true}
				position={Position.BOTTOM}
				onOpened={handleOpened}
			>
				<RoundedLinkButton
					className={className ? className : ''}
					size={size ? size : SIZE.s}
					icon={<Icon icon={APP_ICONS.SHARE} />}
					text={STRINGS.share}
                    outline={outline}
                    aria-label ="share link"
				/>
			</Popover>
		</div>
	);
};

export { ShareLinkButton };
