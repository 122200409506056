import { useState, useEffect } from "react";
import { setUserPreferences } from "utils/stores/UserPreferencesStore.ts";
import { useUserPreferences } from "utils/hooks/useUserPreferences.ts";
import Legal from "components/modals/legal/Legal.tsx";
import { STRINGS } from "app-strings";
import { cloudIMLegal } from "./cloudim.json";

export const CloudIMLegal = (): JSX.Element => {
    const userPreferences = useUserPreferences({
        listenOnlyTo: {
            cloudIM: { legal: { acceptLegal: false, version: "0.0" } },
        },
    });
    const [showLegal, setShowLegal] = useState<boolean>(false);

    const newLegalVersion = cloudIMLegal.version || "0.0";
    const userLegalVersion = userPreferences.cloudIM?.legal?.version || "0.0";
    const userAcceptLegal = userPreferences.cloudIM?.legal?.acceptLegal;

    useEffect(() => {
        const newVersion = Number.parseFloat(newLegalVersion);
        const oldVersion = Number.parseFloat(userLegalVersion);
        if (newVersion > oldVersion || !userAcceptLegal) {
            setShowLegal(true);
        }
    }, [newLegalVersion, userLegalVersion, userAcceptLegal]);

    return (
        <Legal
            isOpen={showLegal}
            lastUpdated={cloudIMLegal.lastUpdated}
            // Copied legal text from cloudim.json into en.ts
            terms={STRINGS.cloudim.legal}
            onConfirm={() => {
                setShowLegal(false);
                setUserPreferences({
                    cloudIM: {
                        legal: { acceptLegal: true, version: cloudIMLegal.version },
                    },
                });
            }}
        />
    );
};
