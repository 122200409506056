query indicatorsList($indicatorMapping: [IndicatorMappingInput], $startTime: String, $endTime: String) {
	idsOnly: indicators(
		filter: { indicatorMappings: $indicatorMapping, time: { startTime: $startTime, endTime: $endTime } }
		order: { startTime: ASC }
	) {
		nodes {
			id
		}
	}
	indicators(
		filter: { indicatorMappings: $indicatorMapping, time: { startTime: $startTime, endTime: $endTime } }
		order: { startTime: ASC }
	) {
		nodes {
			metric
			entity {
				id
				name
				kind
                source {
                    id
                    name
                    host
                }
				attributes {
					field
					value
				}
                parent {
                    id
                    kind
                    ipAddress
                    name
                }
			}
			kind
			startTime
			endTime
			details {
				actualValue
				expectedValue
				acceptableHighValue
				acceptableLowValue
                granularity
			}
		}
	}
}
