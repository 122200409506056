/** This module contains the panel that displays the graph errors
 *  @module
 */

import React, { useReducer, useCallback, useRef } from 'react';
import { GraphDef, NodeDef, Variant } from 'components/common/graph/types/GraphTypes.ts';
import { Button, Intent, Tree, Menu, MenuItem, ContextMenu } from "@blueprintjs/core";
import { Icon, IconNames } from '@tir-ui/react-components';
import { clone } from "lodash";
import { STRINGS } from "app-strings";
import './GraphErrorPanel.scss';

/** This interface defines the properties passed into the graph error panel React component.*/
interface GraphErrorPanelProps {
    /** the variant of runbook that we are editing. */
    variant: Variant;
    /** the GraphDef object with the current graph nodes and edges. */
    graphDef: GraphDef;
    /** a boolean value which specifies whether node errors are currently visible. */
    showNodeErrors: boolean;
    /** the handler for hiding the node errors. */
    onChangeShowNodeErrors?: (show: boolean) => void;
}

type NodePath = number[];

type TreeAction =
    | { type: "SET_IS_EXPANDED"; payload: { path: NodePath; isExpanded: boolean } }
    | { type: "DESELECT_ALL" }
    | { type: "SET_IS_SELECTED"; payload: { path: NodePath; isSelected: boolean } }
    | { type: "ADD_NODE"; payload: { node: NodeDef, typeAndIndex: number } }
    | { type: "DELETE_NODE"; payload: { id: string, typeAndIndex: number } }
    | { type: "UPDATE_NODE"; payload: { node: NodeDef, typeAndIndex: number } }
    | { type: "UPDATE_GENERAL_WARNINGS_AND_ERRORS"; payload: { graphDef: GraphDef } };


/** executes the specified visit function for the specified set of nodes and their
 *      children.
 *  @param nodes the nodes for which the visit function is to be run.
 *  @param path the path.
 *  @param visitFn the visit function.*/
function forEachNode(nodes: any[] | undefined, visitFn: (node: any) => void) {
    if (nodes === undefined) {
        return;
    }

    for (const node of nodes) {
        visitFn(node);
        forEachNode(node.childNodes, visitFn);
    }
}

/** executes the specified callback for the tree node specified by the path.
 *  @param nodes the nodes in the tree.
 *  @param path the path.
 *  @param callback the callback function.*/
function forNodeAtPath(nodes: any[], path: NodePath, callback: (node: any) => void) {
    callback(Tree.nodeFromPath(path, nodes));
}

/** update the path for all nodes and their children.
 *  @param nodes the nodes to update the path.
 *  @param currentPath the current path for the current location in the tree.*/
function updatePath(nodes: any[], currentPath: Array<number>) {
    if (nodes) {
        for (let index = 0; index < nodes.length; index++) {
            nodes[index].path = ([] as Array<number>);
            nodes[index].path.push(index);
            if (nodes[index].childNodes && nodes[index].childNodes.length > 0) {
                updatePath(nodes[index].childNodes, nodes[index].path);
            }
        }
    }
}

/** the reducer function.
 *  @param state the current state of the tree.
 *  @param action the current action.
 *  @returns the new state of the tree after taking the specified action.*/
function treeReducer(state: any[], action: TreeAction) {
    switch (action.type) {
        case "DESELECT_ALL": {
            const newState = clone(state);
            forEachNode(newState, node => (node.isSelected = false));
            return newState;
        }
        case "SET_IS_EXPANDED": {
            const newState = clone(state);
            forNodeAtPath(newState, action.payload.path, node => (node.isExpanded = action.payload.isExpanded));
            return newState;
        }
        case "SET_IS_SELECTED": {
            const newState = clone(state);
            forNodeAtPath(newState, action.payload.path, node => {
                if (node) {
                    node.isSelected = action.payload.isSelected
                }
            });
            return newState;
        }
        case "ADD_NODE": {
            const newState = clone(state);
            // type 0 is a standard node and type 1 is a config node
            const type = action.payload.typeAndIndex;
            const node = action.payload.node;
            const nodesNode = newState[type];
            if (!nodesNode.childNodes) {
                nodesNode.childNodes = [];
            }
            const path = [type, nodesNode.childNodes.length];
            const treeNode = {
                id: node.id, key: node.id, depth: 1, path: path, 
                label: (node.name ? node.name : STRINGS.runbookEditor.graphData.NoNameText + " (id=" + node.id + ")")
            };
            addRunbookNodeWarningsAndErrorsToTreeNode(node, treeNode, path);
            nodesNode.childNodes.push(treeNode);
            return newState;
        }
        case "DELETE_NODE": {
            const newState = clone(state);
            // type 0 is a standard node and type 1 is a config node
            const type = action.payload.typeAndIndex;
            const nodeId = action.payload.id;
            const nodesNode = newState[type];
            if (nodesNode.childNodes) {
                for (let index = 0; index < nodesNode.childNodes.length; index++) {
                    const nodeNode = nodesNode.childNodes[index];
                    if (nodeNode.id === nodeId) {
                        nodesNode.childNodes.splice(index, 1);
                        if (nodesNode.childNodes.length === 0) {
                            delete nodesNode.childNodes;
                        }
                        break;
                    }
                }
            }
            updatePath(newState, []);
            return newState;
        }
        case "UPDATE_NODE": {
            const newState = clone(state);
            // type 0 is a standard node and type 1 is a config node
            const type = action.payload.typeAndIndex;
            const node = action.payload.node;
            const nodesNode = newState[type];
            if (nodesNode && nodesNode.childNodes) {
                for (const nodeNode of nodesNode.childNodes) {
                    if (nodeNode.id === node.id) {
                        nodeNode.label = node.name;
                        addRunbookNodeWarningsAndErrorsToTreeNode(node, nodeNode, nodeNode.path);
                    }
                }
            }
            return newState;
        }
        case "UPDATE_GENERAL_WARNINGS_AND_ERRORS": {
            const newState = clone(state);

            const graphDef = action.payload.graphDef;
            const graphDefNode = newState[GENERAL_INDEX];
            addGeneralWarningsAndErrorsToTreeNode(graphDef, graphDefNode, graphDefNode.path);
            return newState;
        }
    }
}

const GENERAL_INDEX: number = 0;
const NODE_INDEX: number = 1;

/** Renders the graph data error panel component.
 *  @param props the properties passed in.
 *  @returns JSX with the graph error panel component.*/
export default function GraphErrorPanel(props: GraphErrorPanelProps): JSX.Element  {
    const INITIAL_STATE: Array<any> = [];
    INITIAL_STATE.push({id: "General", key:"General", label: STRINGS.runbookEditor.graphErrors.generalText, isExpanded: true});
    INITIAL_STATE.push({id: "Nodes", key:"Nodes", label: STRINGS.formatString(STRINGS.runbookEditor.graphErrors.nodesText, {variant: STRINGS.runbookEditor.runbookTextForVariantUc[props.variant]}), isExpanded: true});
    const [nodes, dispatch] = useReducer(treeReducer, INITIAL_STATE);

    const handleNodeClick = useCallback(
        (node: any, nodePath: any, e: React.MouseEvent<HTMLElement>) => {
            const originallySelected = node.isSelected;
            if (!e.shiftKey) {
                dispatch({ type: "DESELECT_ALL" });
            }
            dispatch({
                payload: { path: nodePath, isSelected: originallySelected == null ? true : !originallySelected },
                type: "SET_IS_SELECTED",
            });
        },
        [],
    );

    const handleNodeCollapse = useCallback((_node: any, nodePath: NodePath) => {
        dispatch({
            payload: { path: nodePath, isExpanded: false },
            type: "SET_IS_EXPANDED",
        });
    }, []);

    const handleNodeExpand = useCallback((_node: any, nodePath: NodePath) => {
        dispatch({
            payload: { path: nodePath, isExpanded: true },
            type: "SET_IS_EXPANDED",
        });
    }, []);

    const oldGraphDef = useRef<GraphDef>();
    if (oldGraphDef.current !== props.graphDef) {
        // The graph data in the parent is cloned whenever it changes to we can count on the reference
        // changing each time it is modified
        oldGraphDef.current = props.graphDef;

        // Clean the tree first
        const existingNodesWithErrors: Array<string> = [];
        for (const node of props.graphDef.nodes) {
            if (node.warnings || node.errors) {
                existingNodesWithErrors.push(node.id);
            }
        }
        if (nodes[NODE_INDEX].childNodes) {
            for (const node of nodes[NODE_INDEX].childNodes) {
                if (!existingNodesWithErrors.includes(node.id)) {
                    dispatch({
                        payload: { id: node.id, typeAndIndex: NODE_INDEX },
                        type: "DELETE_NODE",
                    });        
                }
            }
        }

        dispatch({
            payload: { graphDef: props.graphDef },
            type: "UPDATE_GENERAL_WARNINGS_AND_ERRORS",
        });    
        for (const node of props.graphDef.nodes) {
            if (node.warnings || node.errors) {
                let treeNode;
                if (nodes[NODE_INDEX].childNodes) {
                    for (const checkTreeNode of nodes[NODE_INDEX].childNodes) {
                        if (node.id === checkTreeNode.id) {
                            treeNode = checkTreeNode;
                            break;
                        }
                    }
                }
                if (treeNode) {
                    dispatch({
                        payload: { node: node, typeAndIndex: NODE_INDEX },
                        type: "UPDATE_NODE",
                    });    
                } else {
                    dispatch({
                        payload: { node: node, typeAndIndex: NODE_INDEX },
                        type: "ADD_NODE",
                    });    
                }    
            }
        }
    }

    return (
        <div>
            {/*<h1 className="graph-data-header">Graph Data</h1>*/}
            <div className="graph-error-content display-8">
                <div className="mb-4">
                    <span>{STRINGS.formatString(STRINGS.runbookEditor.graphErrors.suggestText, {variant: STRINGS.runbookEditor.runbookTextForVariant[props.variant]})}</span>
                    <Button minimal style={{paddingLeft: "0"}} text={
                            <u className="display-8">{props.showNodeErrors ? STRINGS.runbookEditor.graphErrors.hideNodeErrors : STRINGS.runbookEditor.graphErrors.showNodeErrors}</u>
                        } 
                        onClick={() => {
                            if (props.onChangeShowNodeErrors) {
                                props.onChangeShowNodeErrors(!props.showNodeErrors);
                            }
                    }}/>
                </div>
                <Tree contents={nodes} onNodeClick={handleNodeClick} onNodeCollapse={handleNodeCollapse} 
                    onNodeExpand={handleNodeExpand} 
                />
            </div>
        </div>
    );
}

/** adds the general warnings and errors to the specified tree node.
 *  @param graphDef the GraphDef to pull the warnings and errors from.
 *  @param treeNode the tree node to put the warnings and errors in. 
 *  @param currentPath the path of the parent that this node is being added to.*/
function addGeneralWarningsAndErrorsToTreeNode(graphDef: GraphDef, treeNode: any, currentPath: Array<number>): void {
    treeNode.childNodes = [] as Array<any>;
    
    let pathIndex = 0;
    let path: Array<number> = [];

    if (graphDef.warnings || graphDef.errors) {
        if (graphDef.warnings) {
            for (const warning of graphDef.warnings) {
                path = ([] as Array<number>).concat(currentPath);
                path.push(pathIndex++);
                const warningComp =
                <ContextMenu
                    content={
                        <Menu>
                            <MenuItem icon={IconNames.DUPLICATE} text={STRINGS.copy} onClick={() => { return navigator.clipboard.writeText(warning); }} />
                        </Menu>
                    }
                >
                    {<div className="d-flex icon-and-label-div"><Icon icon={IconNames.WARNING_SIGN} className="me-2" intent={Intent.WARNING}/><span dangerouslySetInnerHTML={{__html: warning}} /></div>}
                </ContextMenu>
                const warningNode = {id: warning, key: warning, className: "warning-error-label", depth: 2, path: path, label: warningComp};
                treeNode.childNodes.push(warningNode);
            }    
        }
        if (graphDef.errors) {
            for (const error of graphDef.errors) {
                path = ([] as Array<number>).concat(currentPath);
                path.push(pathIndex++);
                const errorComp =
                <ContextMenu
                    content={
                        <Menu>
                            <MenuItem icon={IconNames.DUPLICATE} text={STRINGS.copy} onClick={() => { return navigator.clipboard.writeText(error); }} />
                        </Menu>
                    }
                >
                    {<div className="d-flex icon-and-label-div"><Icon icon={IconNames.ERROR} className="me-2" intent={Intent.DANGER}/><span dangerouslySetInnerHTML={{__html: error}} /></div>}
                </ContextMenu>;
                const errorNode = {id: error, key: error, className: "warning-error-label", depth: 2, path: path, label: errorComp};
                treeNode.childNodes.push(errorNode);
            }
        }
    }
}

/** adds the warnings and errors from the runbook node to the specified tree node.
 *  @param graphNode the GraphNode to pull the warnings and errors from.
 *  @param treeNode the tree node to put the warnings and errors in. 
 *  @param currentPath the path of the parent that this node is being added to.*/
 function addRunbookNodeWarningsAndErrorsToTreeNode(graphNode: NodeDef, treeNode: any, currentPath: Array<number>): void {
    treeNode.childNodes = [] as Array<any>;
    
    let pathIndex = 0;
    let path: Array<number> = [];

    if (graphNode.warnings || graphNode.errors) {
        if (graphNode.warnings) {
            for (const warning of graphNode.warnings) {
                path = ([] as Array<number>).concat(currentPath);
                path.push(pathIndex++);
                const warningComp =
                <ContextMenu
                    content={
                        <Menu>
                            <MenuItem icon={IconNames.DUPLICATE} text={STRINGS.copy} onClick={() => { return navigator.clipboard.writeText(warning); }} />
                        </Menu>
                    }
                >
                    {<div className="d-flex icon-and-label-div"><Icon icon={IconNames.WARNING_SIGN} className="me-2" intent={Intent.WARNING}/><span dangerouslySetInnerHTML={{__html: warning}} /></div>}
                </ContextMenu>;
                const warningNode = {id: warning, key: warning, className: "warning-error-label", depth: 2, path: path, label: warningComp};
                treeNode.childNodes.push(warningNode);
            }    
        }
        if (graphNode.errors) {
            for (const error of graphNode.errors) {
                path = ([] as Array<number>).concat(currentPath);
                path.push(pathIndex++);
                const errorComp =
                <ContextMenu
                    content={
                        <Menu>
                            <MenuItem icon={IconNames.DUPLICATE} text={STRINGS.copy} onClick={() => { return navigator.clipboard.writeText(error); }} />
                        </Menu>
                    }
                >
                    {<div className="d-flex icon-and-label-div"><Icon icon={IconNames.ERROR} className="me-2" intent={Intent.DANGER}/><span dangerouslySetInnerHTML={{__html: error}} /></div>}
                </ContextMenu>;
                const errorNode = {id: error, key: error, className: "warning-error-label", depth: 2, path: path, label: errorComp};
                treeNode.childNodes.push(errorNode);
            }
        }
    }
}
