import type React from 'react';
import { useRef, useState } from 'react';
import RichTextEditor from 'react-rte';
import { STRINGS } from 'app-strings';
import { setNativeValue } from 'reporting-infrastructure/utils/commonUtils.ts';
import { SelectField } from 'components/common/form/SelectField.tsx';
import { Form } from 'components/common/form/Form.tsx';
import { AnchorButton } from '@blueprintjs/core';
import { IconNames } from '@tir-ui/react-components';
import './TextEditor.scss';

const textFormat = {
	html: 'html',
	markdown: 'markdown',
};

interface ITextEditorProps {
	initialValue: string;
	placeholder: string;
	onChange: (event) => void;
	includeVariables?: boolean;
	variables?: any;
}

const TextEditor: React.FC<ITextEditorProps> = (props) => {
	const initValue = props.initialValue
		? RichTextEditor.createValueFromString(
				props.initialValue,
				textFormat.html
		  )
		: RichTextEditor.createEmptyValue();

	const [editorValue, setEditorValue] = useState(initValue);
	const hiddenNativeTextBox = useRef<HTMLInputElement>(null);
	const [showVarsControl, setShowVarsControl] = useState(false);

	const handleChange = (value) => {
		setEditorValue(value);
		const newValue = value.toString(textFormat.html);
		if (editorValue.toString(textFormat.html) !== newValue) {
			if (props.onChange) {
				props.onChange(newValue);
			}
			if (hiddenNativeTextBox.current) {
				setNativeValue(hiddenNativeTextBox.current, newValue);
			}
		}
	};

	const toolbarConfig = {
		// Optionally specify the groups to display (displayed in the order listed).
		display: [
			'INLINE_STYLE_BUTTONS',
			'BLOCK_TYPE_BUTTONS',
			'LINK_BUTTONS',
			'BLOCK_TYPE_DROPDOWN',
		],
		INLINE_STYLE_BUTTONS: [
			{
				label: STRINGS.TextEditor.toolbar.bold,
				style: 'BOLD',
				className: 'custom-css-class',
			},
			{
				label: STRINGS.TextEditor.toolbar.italic,
				style: 'ITALIC',
			},
			{
				label: STRINGS.TextEditor.toolbar.underline,
				style: 'UNDERLINE',
			},
		],
		BLOCK_TYPE_DROPDOWN: [
			{
				label: STRINGS.TextEditor.toolbar.normal,
				style: 'unstyled',
			},
			{
				label: STRINGS.TextEditor.toolbar.headingLarge,
				style: 'header-one',
			},
			{
				label: STRINGS.TextEditor.toolbar.headingMedium,
				style: 'header-two',
			},
			{
				label: STRINGS.TextEditor.toolbar.headingSmall,
				style: 'header-three',
			},
		],
		BLOCK_TYPE_BUTTONS: [
			{
				label: STRINGS.TextEditor.toolbar.ul,
				style: 'unordered-list-item',
			},
			{
				label: STRINGS.TextEditor.toolbar.ol,
				style: 'ordered-list-item',
			},
		],
		LINK_BUTTONS: [
			{
				label: STRINGS.TextEditor.toolbar.link,
				style: 'unstyled',
				className: 'rte-button rte-button-link',
			},
			{
				label: STRINGS.TextEditor.toolbar.removeLink,
				style: 'unstyled',
				className: 'rte-button rte-button-link-remove',
			},
		],
	};

	return (
		<>
			<RichTextEditor
				value={editorValue}
				onChange={handleChange}
				placeholder={props.placeholder}
				toolbarConfig={toolbarConfig}
				className="rich-text-editor texteditor-size"
				customControls={[
					props.includeVariables &&
						props.variables &&
						props.variables.length > 0 &&
						(() => {
							const options = [STRINGS.TextEditor.insertVariable];
							props.variables.forEach((item) => {
								options.push(item['name']);
							});
							return (
								<div key="variable-control">
									<AnchorButton
										title={
											STRINGS.TextEditor.variableTooltip
										}
										className={
											showVarsControl
												? 'variables-checked'
												: ''
										}
										id="variables-icon"
										icon={IconNames.VARIABLE}
										onClick={() =>
											setShowVarsControl(!showVarsControl)
										}
									/>
									{showVarsControl && (
										<Form
											key={'vars'}
											initialValues={{
												variables: '',
											}}
											loading={false}
										>
											<div className="d-flex">
												<SelectField
													name="variables"
													options={options}
													onChange={(event) => {
														const newValue =
															RichTextEditor.createValueFromString(
																editorValue
																	.toString(
																		textFormat.markdown
																	)
																	.concat(' ')
																	.concat(
																		'{{variables["' +
																			event
																				.target
																				.value +
																			'"]}}'
																	)
																	.replaceAll(
																		'\\',
																		''
																	),
																textFormat.html
															);
														handleChange(newValue);
													}}
													disabled={
														!props.variables ||
														props.variables
															.length === 0
													}
													value={''}
												/>
											</div>
										</Form>
									)}
								</div>
							);
						}),
				]}
			/>
			<input
				type="text"
				className="d-none"
				ref={hiddenNativeTextBox}
				defaultValue={editorValue.toString(textFormat.html)}
			/>
		</>
	);
};

export { TextEditor };
