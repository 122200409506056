query automation {
    automationMappings {
        id
        name
        description
        enabled
        order
        triggerType
        lastUpdatedBy
        lastUpdatedOn
        runbookId
        runbookName
        isFactoryRunbook
        lastHitOn
        expression
        variant
    }
}
