import { SIZE } from 'components/enums/Sizes.ts';
import React from 'react';

export interface ItemListItemProps {
    label?: JSX.Element | string | number | null;
    children?: string | JSX.Element;
    textSize?: SIZE.s | SIZE.m;
    className?: string;
}

export function ItemListItem({ children, label, textSize = SIZE.m, className }: ItemListItemProps) {
    return (
        <tr className={(textSize === SIZE.s ? 'display-9' : 'display-8') + (className ? ' ' + className : '')}>
            {label && <td className="text-muted px-3">{label}</td>}
            <td className="fw-500 p-2">{children}</td>
        </tr>
    );
}
