mutation AddCustomPropertyValueToLocations($valueId: ID!, $filter: LocationBulkFilterType, $search: String, $searchFields: [LocationSearchFieldEnum]) {
    addCustomPropertyValueToLocations(
        valueId: $valueId
        filter: $filter
        search: $search
        searchFields: $searchFields
    ) {
        modifiedResourcesCount
        ignoredResourcesCount
    }
}
