import { useCallback, useState } from "react";
import { ErrorToaster, IconNames, LoadingOverlay, Modal, SuccessToaster } from "@tir-ui/react-components";
import { DialogProps, Intent, Radio, RadioGroup } from "@blueprintjs/core";
import { STRINGS } from "app-strings";
import { useMutation } from "@apollo/client";
import { openConfirm } from "components/common/modal/ConfirmModal.tsx";
import { runbookService } from "utils/runbooks/RunbookUtils.ts";
import { Variant } from "components/common/graph/types/GraphTypes.ts";
import DELETE_CUSTOM_PROPERTIES_MUTATION from "../queries/delete-custom-properties-mutation.graphql";
import "./DeleteCustomPropertiesModal.scss";

type DeleteOptions = "selected" | "all";

interface DeleteCustomModalProps extends DialogProps {
    selectedIds: Array<string>,
    totalCount: number,
    currentSearchQuery: {
        search: string,
        searchFields: Array<"NAME"|"DESCRIPTION">,
        descriptions: Array<string>,
        names: Array<string>,
        userIds: Array<string>,
        values: Array<string>,
    },
    markItemsAsDeleted: (itemIds: Array<string>) => void;
}


type FilterTypes = "ID" | "NAME" | "DESCRIPTION" | "USER_ID" | "VALUE_NAME";

interface DeleteCustomPropertiesMutationInput {
        filter: {
          filters: [
            {
              type: FilterTypes,
              values: Array<string>
            }
          ],
          time: {
            endTime: string
          }
        },
        search: string,
        searchFields: "NAME" | "DESCRIPTION"
}

export const DeleteCustomPropertiesModal = (props: DeleteCustomModalProps) => {
    const [deleteCustomProperties] =
        useMutation<DeleteCustomPropertiesMutationInput>(
            DELETE_CUSTOM_PROPERTIES_MUTATION,
            {
                onCompleted: () => {
                    SuccessToaster({
                        message:
                            STRINGS.CUSTOM_PROPERTIES_PAGE.toastMessages.successDeleteCustomProperties,
                    });
                },
                onError: (_err) => {
                    ErrorToaster({
                        message:
                            STRINGS.CUSTOM_PROPERTIES_PAGE.toastMessages.errorDeleteCustomProperties,
                    });
                },
            }
        );

    const [isRunningCustomPropUseCheck, setIsRunningCustomPropCheck] = useState(false);
    const { selectedIds, allIds, markItemsAsDeleted, currentSearchQuery, totalCount = 0 } = props;
    const hasNoSelectedItems = selectedIds.length === 0;
    const [selectedOption, setSelectedOption] = useState<DeleteOptions>(hasNoSelectedItems ? "all" : "selected");
    const itemsToDelete = selectedOption === 'all' ? allIds : selectedIds;
    const onDeleteCustomProperties = useCallback(
        async (itemIds: Array<string>) => {
            setIsRunningCustomPropCheck(true);
            const customPropsInRunbooks = await getCustomPropertiesInRunbooks();
            const selectedPropsInUse = itemIds.filter(itemId => customPropsInRunbooks.includes(`.custom.${itemId}`));
            const isInUse = selectedPropsInUse.length > 0;

            setIsRunningCustomPropCheck(false);

            if (isInUse) {
                let message = selectedPropsInUse.length > 1 ? 
                              STRINGS.CUSTOM_PROPERTIES_PAGE.deleteModal.confirmCustomPropertiesInUseDeleteMessageMultiple :
                              STRINGS.CUSTOM_PROPERTIES_PAGE.deleteModal.confirmCustomPropertiesInUseDeleteMessageSingle;
                    message += STRINGS.formatString(STRINGS.CUSTOM_PROPERTIES_PAGE.deleteModal.confirmCustomPropertiesInUseDeleteMessage, selectedPropsInUse.length);

                openConfirm({
                    message: message,
                    onConfirm: () => {
                        return handleDelete(selectedOption === 'all' ? allIds : selectedIds)
                    },
                    icon: IconNames.TRASH,
                    intent: Intent.PRIMARY,
                });
            } else {
                return handleDelete(selectedOption === 'all' ? allIds : selectedIds)
            }

            async function getCustomPropertiesInRunbooks() {
                const items = await runbookService.getRunbooks(Variant.INCIDENT);
                const customPropsInRunbooks = JSON.stringify(items.filter(el => {
                    return JSON.stringify(el.nodes).includes('.custom.');
                }));
                
                return customPropsInRunbooks;
            }
        }, 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [allIds, selectedIds, selectedOption]
    );

    return (
        <Modal
            {...props}
            title={"Delete Custom Properties"}
            customLabels={{
                submit: "Delete",
                close: "Cancel"
            }}
            onSubmit={() => onDeleteCustomProperties(itemsToDelete)}
            usePortal={false}
        >
            <div className="delete-custom-properties-loading-overlay">
                <LoadingOverlay visible={isRunningCustomPropUseCheck} loadingText={STRINGS.CUSTOM_PROPERTIES_PAGE.deleteModal.checkInUseLoadingMessage}/>
            </div>
            <div className="container">
                <RadioGroup
                    name="delete-options"
                    onChange={e => {
                        const updatedValue = e.currentTarget.value as DeleteOptions;
                        setSelectedOption(updatedValue);
                    }}
                    selectedValue={selectedOption}
                    inline={true}
                    className="d-flex flex-column"
                >
                    <Radio
                        label={STRINGS.formatString(STRINGS.CUSTOM_PROPERTIES_PAGE.deleteDialog.selectedLabel, selectedIds.length)}
                        value={"selected"}
                        className="my-2"
                        disabled={hasNoSelectedItems}
                    />
                    <Radio
                        label={STRINGS.formatString(STRINGS.CUSTOM_PROPERTIES_PAGE.deleteDialog.allLabel, totalCount)}
                        value={"all"}
                        className="my-2"
                    />
                </RadioGroup>
            </div>
        </Modal>
    );

    /**
     * Handles the deletion of given items
     *
     * @param items - an array with items marked for deletion
     *
     * @returns {Promise}
     */
    function handleDelete(items: Array<string>) {
        const isDeleteAll = selectedOption === 'all';
        let filters: Array<{ type: string; values: Array<any>; }> = getFiltersFromSearch();

        const queryVariables = isDeleteAll ? {
            variables: {
                filter: {
                    filters: [...filters],
                },
                searchFields: currentSearchQuery.searchFields,
                search: currentSearchQuery.search
            }
        } : {
            variables: {
                filter: {
                    filters: [{
                        type: "ID",
                        values: items
                    }]
                }
            }
        };

        return deleteCustomProperties(queryVariables).then(() => {
            markItemsAsDeleted(items);
        }).catch((error) => {
            ErrorToaster({
                message: error.message,
            });
        });
    }

    function getFiltersFromSearch() {
        let filters: Array<{ type: FilterTypes; values: Array<any>; }> = [];

        if (currentSearchQuery.names?.length > 0) {
            filters.push({
                type: "NAME",
                values: currentSearchQuery.names
            });
        }

        if (currentSearchQuery.descriptions?.length > 0) {
            filters.push({
                type: "DESCRIPTION",
                values: currentSearchQuery.descriptions
            });
        }

        if (currentSearchQuery.userIds?.length > 0) {
            filters.push({
                type: "USER_ID",
                values: currentSearchQuery.userIds
            });
        }

        if (currentSearchQuery.values?.length > 0) {
            filters.push({
                type: "VALUE_NAME",
                values: currentSearchQuery.values
            });
        }

        return filters;
    }
};
