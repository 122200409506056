query userImpactIncidents($priority: [Priority], $incidentStatus: [Status], $completionStatus: CompletionStatus, $limit: Int = 10) {
  impactedUsers: summary(
    first: $limit,
    filter: {priorities: $priority, status: $incidentStatus, completionStatus: $completionStatus}
  ) {
      incidentsUsers {
        incidentId
        count
        priority
        description
      }
  }
}
