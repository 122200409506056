/** This module defines the status LED React component.  The status LED displays a color for 
 *      each of the enumerated SEVERITY or PRIORITY enums.
 *  @module */
import React from "react";
import { type IconName, IconNames, Icon } from "@tir-ui/react-components";
import { STATUS_COLORS, PRIORITY_COLORS } from "components/enums/Colors.ts";
import type { PRIORITY } from "components/enums/Priority.ts";
import { SEVERITY } from "components/enums/Severity.ts";
import { SIZE } from "components/enums/Sizes.ts";
import { Button } from "@blueprintjs/core";
import "./StatusLED.scss";

const CUSTOM_ICONS = {
    "circle": IconNames.FULL_CIRCLE,
    "square": IconNames.STOP,
    "roundedBar": <svg xmlns="http://www.w3.org/2000/svg" className="icon-rounded-bar" width="6" height="18" version="1.1"><rect width="6" height="100%" rx="3" ry="3" fill="inherit"/></svg>
}

export enum CustomIconNames {
    circle = "circle",
    square = "square",
    roundedBar = "roundedBar"
}
export enum  Mode {
    button = "button",
    icon = "icon"
}

/** This interface defines the properties passed into the status LED React component.*/
export type StatusLEDProps = {
    /** an optional parameter with either the SEVERITY or PRIORITY.  If this is present the background will have 
     *      a color that corresponds to one of the enumerated SEVERITYs or PRIORITYs. */
    status?: SEVERITY | PRIORITY;
    /** this component is used on pages that use both the SEVERITY enum for its status and the PRIORITY
     *      enum for its status.  Be default we assume the SEVERITY is being displayed.  Set this to 
     *      true if the component is displaying the PRIORITY enum. */
    isPriority?: boolean;
    /** the icon that is to be displayed in the status LED. */
    icon?: CustomIconNames | IconName | string | "none";
    /** an optional size for the LED, if none is set, it defaults to medium. */
    size?: SIZE;
    /** a string or JSX with the label. */
    label?: JSX.Element|string | number | null;
    /** a string with the tooltip text. */
    toolTip?: string;
    /** a boolean, if true show the label first. */
    labelFirst?: boolean;
    /** an optional className that can be used to further refine the css for this component. */
    className?: string;
    /** CSS class name to apply on the icon sub-component. */
    iconClassName?: string;
    /** the background color for the LED. */
    color?: string;
    /** the handler for click events. */
    onClick?: (SEVERITY) => void;
    /** the mode. */
    mode?: Mode;
};

/** Renders the status LED view.
 *  @param props the properties passed in.
 *  @returns JSX with the status LED component.*/
export function StatusLED({
    status = SEVERITY.UNKNOWN,
    isPriority = false,
    size = SIZE.m,
    label = null,
    toolTip,
    labelFirst = false,
    className,
    iconClassName,
    color,
    icon = IconNames.FULL_CIRCLE,
    onClick,
    mode = Mode.icon
}: StatusLEDProps): JSX.Element {
    let showLED = true;
    let iconColor = color;
    if (!iconColor) {
        if (!isPriority) {
            iconColor = STATUS_COLORS[status] || STATUS_COLORS.UNKNOWN;
        } else {
            iconColor = PRIORITY_COLORS[status] || PRIORITY_COLORS.UNKNOWN;
        }
    }
    const labelElem = label !== null && (
            <label aria-label="label-elem" className={"vertical-align-item-center text-wrap  m-0 " + (labelFirst ? "pe-1" : "ps-1")}>
                {label}
            </label>
        );
    const SIZE_MAP = {
        xs: 5,
        s: 10,
        m: 15,
        l: 20,
        xl: 30,
        xxl: 35,
    };
    // const TEXT_CLASS_MAP = {
    //     xs: "font-size-x-small",
    //     s: "font-size-small",
    //     m: "font-size-medium",
    //     l: "font-size-x-large",
    //     xl: "font-size-x-large",
    //     xxl: "font-size-xx-large",
    // };

    let iconToUse:any = icon;
    if (typeof icon === "string") {
        if (icon === "none") {
            showLED = false;
        } else if (CUSTOM_ICONS[icon]) {
            iconToUse = CUSTOM_ICONS[icon];
            if (typeof iconToUse !== "string") {
                iconToUse = React.cloneElement(iconToUse, {
                    fill: iconColor
                });
            }
        }
    }
    function handleOnClick(status: SEVERITY | PRIORITY) {
        if (onClick) {
            onClick(status);
        }
    }
    const ledFragment = <>
        {label !== null && labelFirst ? labelElem : ""}
        {showLED && <Icon icon={iconToUse} color={iconColor} size={SIZE_MAP[size]} className={"vertical-align-item-center" + (iconClassName ? " " + iconClassName : "")}/>}
        {label !== null && labelFirst === false ? labelElem : ""}
    </>;
    return (
        <span data-testid={status} data-value={status}
        className={"status-led align-items-center " + (className ? " " + className : "")}
        title={toolTip} >
            {
                mode === Mode.button ?
                    <Button role="button" minimal={true} onClick={() => handleOnClick(status)}>
                        {ledFragment}
                    </Button>
                    :
                    <span className="status-icon text-nowrap">
                        {ledFragment}
                    </span>
            }

         </span>

    );
}
