query autocompleteData($startTime: String, $endTime: String, $priority: [Priority], $incidentStatus: [Status]) {
    incidents(
        filter: {time: {startTime: $startTime, endTime: $endTime}, priorities: $priority, status: $incidentStatus}
        order: {lastUpdated: DESC}
        first: 100
    ) {
        nodes: page {
            id
        }
    }
}
