query priorityReasonRunbookList($incidentId: String!, $variant: Variant) {
    runbooks: runbooks(
        filter: {incidentIds: [$incidentId], variant: $variant}
    ) {
        nodes {
            id
            incidentId
            timestamp
            status
            priority
            priorityReasons {
                id
                text
                priority
            }
        }
    }
}
