/** This file defines the a bunch of types used in the user assistance.
 *  @module */

/** this interface defines a UserAssistanceValue. */
export interface UserAssistanceValue {
    /** a String with any comments describing the mapping.  This is not used by the UI. */
    comment?: string;
    /** a String with either the text for the help summary, or the path into the strings file of the format
     *  "en.fieldHelpText.QueryNode.application_traffic.Header". */
    helpSummary: string;
    /** a boolean value, if true show the more link that will allow the user to jump into the help. */
    showMore?: boolean;
    /** a String with the help URL not including the root of the path which is different in each of the 
     *  main tenants.  Here is an example: "/Content/runbook_node_data_query_Applications.htm". */
    url?: string;
}

/** this interface defines the user assistance value for the what's new entry. */
export interface WhatsNewUserAssistanceValue extends UserAssistanceValue {
    /** a String with the current version of the whats new content. */
    version: string;
    /** the array of user assistance features. */
    features: UserAssistanceFeature[];
}

/** this interface defines a user assistance feature. */
export interface UserAssistanceFeature {
    /** a String with the name of the feature. */
    name?: string;
    /** a String  with the blueprint.js icon name to show to the right of the feature name, for example "star-empty".  This has to be 
     *  an official bluepring.js icon name. */
    icon?: string;
    /** a String with the description of the feature. */
    description?: string;
    /** an optional array of try it links that are placed at the then end of the description text. */
    tryIt?: UserAssistanceTryIt[];
    /** an optional image specification of type UserAssistanceImg that is displayed right below the description. */
    img?: UserAssistanceImg;
    /** sometimes a feature gets delayed and it's what new content might need to be hidden.  If hidden is set to true, then the 
     *      what's new content will not be displayed. */
    hidden?: boolean;
}

/** this interface defines the try it configuration for a user assistance feature. */
export interface UserAssistanceTryIt {
    /** a String with the text to display in the try it link. */
    text: string;
    /** a string with the React route for the page, for example "create-runbook". */
    route?: string;
    /** an optional string with any parameters to be tacked onto the URL excluding the "?". */
    params?: string;
    /** the type of action to take, open a new page in a new tab or just replace the URL in the current tab. */
    type?: TryItActionType;
}

/** this enum specifies the valid try it action types. */
export enum TryItActionType {
    /** the enumerated value for opening a new page in the single page app in a new tab. */
    NewPage     = "new_page",
    /** the enumerated value for updating the URL in the current single page instance. */
    UrlUpdate   = "url_update",
    /** the enumerated value for opening a help page. The UI will insert the correct path to the help for that tenant. */
    HelpPage    = "help_page",
    /** the enumerated value for opening a page on the internet. */
    InetPage    = "inet_page",
}

/** this interface defines the information passed into the image below the feature description. */
export interface UserAssistanceImg {
    /** the type of image, help or internet.  If help is specified then the URL is relative to the 
     *  help base URL, if internet is choosen then the URL is absolute such as "https://www.riverbed.com". */
    type: UserAssistanceImgType;
    /** a String with the URL. */
    url: string;
} 

/** this enum specifies the valid user assistance img types. */
export enum UserAssistanceImgType {
    /** the enumerated value for an image in the help system. */
    help = "help",
    /** the enumerated value for an image on the internet.. */
    internet = "internet"
}

/** this interface defines the properties for a user assistance mapping. */
export interface UserAssistanceMapping {
    /** any nested additional UserAssistanceMappings. */
    [anyKey: string]: UserAssistanceMapping | UserAssistanceValue;
}
