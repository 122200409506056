import React, { useCallback, useRef, useState, useEffect } from "react";
import {
	type ButtonProps,
	DialogStep,
	Intent,
	MultistepDialog,
} from "@blueprintjs/core";
import StepSelect from "./StepSelect.tsx";
import StepSpecify from "./StepSpecify.tsx";
import StepSchedule from "./StepSchedule.tsx";
import StepReview from "./StepReview.tsx";
import { SuccessToaster, ErrorToaster } from "@tir-ui/react-components";
import { STRINGS } from "app-strings";
import { useMutation } from "@apollo/client";
import { validIP } from "utils/validators/Validators.ts";
import { runbookService } from "utils/runbooks/RunbookUtils.ts";
import { Variant } from "components/common/graph/types/GraphTypes.ts";
import { sortBy } from "lodash";
import { SchedulerService } from "utils/services/SchedulerApiService.ts";
import CREATE_RUNBOOK_SCHEDULE_MUTATION from "./create-schedule-mutation.graphql";
import UPDATE_RUNBOOK_SCHEDULE_MUTATION from "./edit-schedule-mutation.graphql";

import "./ScheduleRunbookModal.scss";

export const convertIfIndexToString = (jsonObj) =>
	JSON.stringify(jsonObj, (key, value) =>
		key === "ifindex" ? String(value) : value,
	);

export const formatPostDate = (dateValue) => {
	if (typeof dateValue === "string") {
		return String(dateValue);
	} else {
		// convert from Date to nanosecs string
		const asMillisecs = new Date(dateValue).getTime();
		return String(asMillisecs / 1000);
	}
};

export const getInputs = (inputs) => {
	const variableOverrides = inputs?.variableOverrides ?? {};
	const variableTypes = inputs.variableTypes ?? {};
	return Object.entries(variableOverrides).map(([name, value]) => ({
		name,
		value,
		type: variableTypes[name],
	}));
};

const ScheduleRunbookModal = React.forwardRef((props: any, ref) => {
	React.useImperativeHandle(ref, () => ({
		handleOpen(editInfos) {
			setIsOpen(!isOpen);
			setEditInfos(editInfos);
		},
	}));
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [editInfos, setEditInfos] = useState<any>(null);
	const isWizardSubmit = useRef<boolean>(false);
	const handleClose = useCallback(() => {
		setIsOpen(false);
		setRunbookID("");
		setSchedule({
			name: "",
			startOn: new Date(),
			repeat: "",
			every: 0,
			daysOfWeek: [],
		});
		setScheduleType("isSaved");
		inputs.current = {};
		setDescription("");
		setEditInfos(null);
		setSummary("");
		setCanEdit(false);
		setHasValidInputs(true);
		setLoadingSchedules(true);
	}, []);
	const wizardStrings = STRINGS.scheduleRunbook.wizard.queryMessages;

	// Mutation create schedule
	// const [CreateRunbookSchedule] = useMutation<any, IScheduleInputFields>(
	const [CreateRunbookSchedule] = useMutation<any, any>(
		CREATE_RUNBOOK_SCHEDULE_MUTATION,
		{
			onCompleted: (data) => {
				SuccessToaster({
					message: isWizardSubmit.current
						? wizardStrings.successCreateJob
						: wizardStrings.successCreate,
				});
				isWizardSubmit.current = false;
			},
			onError: (err) => {
				ErrorToaster({
					message: isWizardSubmit.current
						? wizardStrings.errorCreateJob
						: wizardStrings.errorCreate,
				});
				console.error(err?.message);
				isWizardSubmit.current = false;
			},
		},
	);

	const [UpdateRunbookSchedule] = useMutation<any, any>(
		UPDATE_RUNBOOK_SCHEDULE_MUTATION,
		{
			onCompleted: (data) => {
				SuccessToaster({
					message: isWizardSubmit.current
						? !editInfos
							? wizardStrings.successCreateJob
							: wizardStrings.successEditJob
						: wizardStrings.successEdit,
				});
				isWizardSubmit.current = false;
			},
			onError: (err) => {
				ErrorToaster({
					message: isWizardSubmit.current
						? !editInfos
							? wizardStrings.errorCreateJob
							: wizardStrings.errorEditJob
						: wizardStrings.errorEdit,
				});
				console.error(err?.message);
				isWizardSubmit.current = false;
			},
		},
	);

	const [runbookID, setRunbookID] = useState<string>("");
	const inputs = useRef<any>({});

	const [schedule, setSchedule] = useState({
		name: "",
		startOn: new Date(),
		repeat: "",
		every: 0,
		daysOfWeek: [],
	});
	const [description, setDescription] = useState<string>("");
	const [summary, setSummary] = useState("");
	const [scheduleType, setScheduleType] = useState("isSaved");
	const [canEdit, setCanEdit] = useState(false);
	const [hasValidInputs, setHasValidInputs] = useState<boolean>(true);
	const [loadingSchedules, setLoadingSchedules] = useState<boolean>(true);

	useEffect(() => {
		if (editInfos && isOpen) {
			const { name, startOn, repeat, every, daysOfWeek } = editInfos.details;
			setSchedule({ name, startOn, repeat, every, daysOfWeek });
			setDescription(editInfos.schedule.description);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen, editInfos]);

	const handleSubmit = async () => {
		isWizardSubmit.current = true;

		if (
			inputs.current?.detection?.entity?.attributes?.ifindex &&
			inputs.current?.detection?.entity?.attributes?.metadata?.ifindex
		) {
			inputs.current.detection.entity.attributes.ifindex = String(
				inputs.current.detection.entity.attributes.ifindex,
			);
			inputs.current.detection.entity.attributes.metadata.ifindex = String(
				inputs.current.detection.entity.attributes.metadata.ifindex,
			);
		}

		try {
			let payload;
			if (editInfos || scheduleType === "isSaved") {
				payload = {
					daysOfWeek: schedule.daysOfWeek?.map((day) => {
						return parseDaysOfWeek[day];
					}),
					description: description,
					every: Number(schedule.every),
					name: schedule.name,
					repeat: parseRepeat[schedule.repeat] || schedule.repeat,
					startDate: convertTimestamp(schedule.startOn),
					runbook: {
						detection: inputs.current?.detection,
						incidentId: inputs.current?.incidentId,
						inputs: parseInputs(
							inputs.current?.variableOverrides,
							inputs.current?.variableTypes,
						),
						runbookId: inputs.current?.runbookId,
						utid: inputs.current?.utid,
					},
				};
			} else {
				payload = {
					daysOfWeek: schedule.daysOfWeek?.map((day) => parseDaysOfWeek[day]),
					description: description,
					every: Number(schedule.every),
					name: schedule.name,
					repeat: parseRepeat[schedule.repeat] || schedule.repeat,
					startDate: schedule.startOn,
					runbook: {
						detection: inputs.current?.detection,
						incidentId: inputs.current?.incidentId,
						inputs: parseInputs(
							inputs.current?.variableOverrides,
							inputs.current?.variableTypes,
						),
						runbookId: inputs.current?.runbookId,
						utid: inputs.current?.utid,
					},
				};
			}

			editInfos || scheduleType === "isSaved"
				? await SchedulerService.updateSchedule(payload)
				: await SchedulerService.saveSchedule(payload);

			SuccessToaster({
				message: isWizardSubmit.current
					? !editInfos
						? wizardStrings.successCreateJob
						: wizardStrings.successEditJob
					: wizardStrings.successEdit,
			});
			isWizardSubmit.current = false;
		} catch (error: any) {
			if (scheduleType !== "isSaved") {
				ErrorToaster({
					message: isWizardSubmit.current
						? wizardStrings.errorCreateJob
						: wizardStrings.errorCreate,
				});
			} else {
				ErrorToaster({
					message: isWizardSubmit.current
						? !editInfos
							? wizardStrings.errorCreateJob
							: wizardStrings.errorEditJob
						: wizardStrings.errorEdit,
				});
			}
			console.error(error?.message);
			isWizardSubmit.current = false;
		}

		handleClose();
		if (props.refreshSearch) {
			props.refreshSearch();
		}
	};

	const finalButtonProps: Partial<ButtonProps> = {
		intent: Intent.PRIMARY,
		loading: false,
		disabled: false,
		onClick: handleSubmit,
	};

	const validateInputs = (inputs) => {
		if (inputs?.variableOverrides && inputs?.variableTypes) {
			let hasInvalidBoolean = false;
			let hasInvalidIpAddress = false;
			for (const [key, value] of Object.entries(inputs.variableOverrides)) {
				if (
					inputs.variableTypes[key] === "boolean" &&
					value &&
					!["true", "false"].includes(value as string)
				) {
					hasInvalidBoolean = true;
				} else if (
					inputs.variableTypes[key] === "ipaddr" &&
					value &&
					!validIP(value as string)
				) {
					hasInvalidIpAddress = true;
				}
			}
			if (hasInvalidBoolean || hasInvalidIpAddress || inputs.errors?.length) {
				return false;
			}
			return true;
		}
		return true;
	};

	const [runbookList, setRunbookList] = useState<any[]>([]);

	const fetchRunbooks = () => {
		runbookService
			.getRunbooks(Variant.ON_DEMAND)
			.then((runbooks) => {
				setRunbookList(
					sortBy(runbooks, (runbook) => runbook.name?.toLowerCase()?.trim()),
				);
			})
			.catch((error) => setRunbookList([]));
	};
	useEffect(fetchRunbooks, []);

	return (
		<>
			<div data-testid="ScheduleRunbook-modal" id="schedule-runbook-modal">
				<MultistepDialog
					className="schedule-runbook-modal"
					title="Schedule Runbook"
					autoFocus
					canEscapeKeyClose
					enforceFocus
					isCloseButtonShown
					resetOnClose
					canOutsideClickClose={false}
					isOpen={isOpen}
					onClose={handleClose}
					finalButtonProps={finalButtonProps}
				>
					<DialogStep
						id="select"
						title="Select Runbook"
						panel={
							<StepSelect
								runbookId={runbookID}
								runbookList={runbookList}
								setRunbookId={setRunbookID}
								inputs={inputs}
								editInfos={editInfos}
							/>
						}
						nextButtonProps={{ disabled: !runbookID }}
					></DialogStep>
					<DialogStep
						id="specify"
						title="Specify Inputs"
						panel={
							<StepSpecify
								id={runbookID}
								onJsonInputChange={(json) => {
									if (json) {
										inputs.current = json;
										setHasValidInputs(validateInputs(json));
									}
								}}
								currentInputs={inputs}
								editInfos={editInfos}
							/>
						}
						nextButtonProps={{ disabled: !hasValidInputs }}
					></DialogStep>
					<DialogStep
						id="schedule"
						title="Schedule"
						panel={
							<StepSchedule
								canEdit={canEdit}
								setCanEdit={setCanEdit}
								runbookID={runbookID}
								inputs={inputs.current}
								description={description}
								scheduleType={scheduleType}
								setScheduleType={setScheduleType}
								setScheduleSummary={setSummary}
								schedule={schedule}
								setSchedule={setSchedule}
								editInfos={editInfos}
								loadingSchedules={loadingSchedules}
								setLoadingSchedules={setLoadingSchedules}
							/>
						}
						backButtonProps={{ disabled: canEdit }}
						nextButtonProps={{
							disabled:
								!Object.values(schedule).every(
									(value) => value || [true, false].includes(value as any),
								) ||
								canEdit ||
								(editInfos && loadingSchedules) ||
								(schedule.repeat === "WEEK" &&
									schedule.daysOfWeek.length === 0),
						}}
					></DialogStep>
					<DialogStep
						id="review"
						title="Review"
						panel={
							<StepReview
								inputs={inputs}
								summary={summary}
								description={description}
								setDescription={setDescription}
							/>
						}
					></DialogStep>
				</MultistepDialog>
			</div>
		</>
	);
});

export const parseRepeat = {
	MINUTE: 0,
	HOUR: 1,
	DAY: 2,
	WEEK: 3,
};

export const parseRepeatReverse = {
	0: "MINUTE",
	1: "HOUR",
	2: "DAY",
	3: "WEEK",
};

export const parseDaysOfWeek = {
	MONDAY: 1,
	TUESDAY: 2,
	WEDNESDAY: 3,
	THURSDAY: 4,
	FRIDAY: 5,
	SATURDAY: 6,
	SUNDAY: 7,
};

export const parseDaysOfWeekReverse = {
	1: "MONDAY",
	2: "TUESDAY",
	3: "WEDNESDAY",
	4: "THURSDAY",
	5: "FRIDAY",
	6: "SATURDAY",
	7: "SUNDAY",
};

export function convertTimestamp(input) {
	if (typeof input === "string") {
		if (!isNaN(input as any)) {
			input = parseFloat(input);
		} else {
			try {
				const date = new Date(input);
				if (!isNaN(date.getTime())) {
					return input;
				}
			} catch {}
		}
	}

	if (typeof input === "number") {
		const timestampInMilliseconds = Math.floor(input * 1000);
		const date = new Date(timestampInMilliseconds);
		if (!isNaN(date.getTime())) {
			return date.toISOString();
		}
	}

	return input;
}

export function parseInputs(variableOverrides, variableTypes) {
	const result: any = [];
	for (const key in variableOverrides) {
		result.push({
			isReadonly: false,
			name: key,
			type: variableTypes[key],
			value: variableOverrides[key],
		});
	}
	return result;
}

export const convertDayToUTC = (dayNumber, localDateString) => {
    const localDate = new Date(localDateString);
    const utcDate = new Date(localDate.toISOString());

    const localDay = localDate.getDay() || 7;
    const utcDay = utcDate.getUTCDay() || 7;

    const dayDifference = utcDay - localDay;

    let utcDayNumber = dayNumber + dayDifference;
    if (utcDayNumber < 1) {
        utcDayNumber += 7;
    } else if (utcDayNumber > 7) {
        utcDayNumber -= 7;
    }
    return utcDayNumber;
};

export { ScheduleRunbookModal };
