/** This module defines the page header React component.  The page header is displayed at the top
 *      of each page of the hyperion app and shows the title of the page, an optional icon, controls, 
 *      and a help icon.
 *  @module */
import React from 'react';
import { type IconName, IconNames, PageTopLevelHelp } from "@tir-ui/react-components";
import type { PRIORITY } from 'components/enums/Priority.ts';
import type { SEVERITY } from 'components/enums/Severity.ts';
import { IconTitle } from 'components/common/icon-title/IconTitle.tsx';
import { SubHeader } from "components/common/layout/sub-header/SubHeader.tsx";
import { AnchorButton } from '@blueprintjs/core';
import { HelpSources, EventNames, trackEvent } from 'utils/appinsights/AppInsights.ts';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { AuthServiceProvider } from 'utils/providers/AuthServiceProvider.ts';
import type { UserAssistanceValue } from 'types/UserAssistance.ts';
import { STRINGS } from 'app-strings';
import { IconVideo } from 'components/common/icon-video/IconVideo.tsx';

/** this constant has the base URI for the help. */
const HELP_URI = import.meta.env.VITE_HELP_URI ? import.meta.env.VITE_HELP_URI : { HELP_URI: '' };

/** this constant refers to the auth service where you can get the tenant, user, etc. */
const AuthService = AuthServiceProvider.getService();

/** This interface defines the properties passed into the page header React component.*/
export interface PageHeaderProps {
    /** a String or React component with the title of the page. */
    title?: string | React.ReactNode;
    /** a boolean value, if true hide the title. */
    hideTitle?: boolean;
    /** the children to display in the component. */
    children?: React.ReactNode;
    /** the React with the left aligned controls. */
    leftAlignedControls?: React.ReactNode;
    /** the React with the right aligned controls. */
    rightAlignedControls?: React.ReactNode;
    /** the React with any controls that should be displayed to the left of the title. */
    controlsToLeftOfTitle?: React.ReactNode;
    /** the icon to display ahead of the title. */
    icon?: IconName | React.ReactNode;
    /** an optional parameter with either the SEVERITY or PRIORITY.  If this is present the background will have 
     *      a color that corresponds to one of the enumerated SEVERITYs or PRIORITYs. */
    status?: SEVERITY | PRIORITY;
     /** this component is used on pages that use both the SEVERITY enum for its status and the PRIORITY
      *      enum for its status.  Be default we assume the SEVERITY is being displayed.  Set this to 
      *      true if the component is displaying the PRIORITY enum. */
    isPriority?: boolean;
    /** the React with the sub header. */
    subHeader?: React.ReactNode;
    /** the help information to show if any. */
    helpInfo?: UserAssistanceValue;
    /** a boolean value, if true show the debug icon. */
    showDebug?: boolean;
    /** the handler for the debug action. */
    onDebug?: () => void;
}

/** Renders the page header component.
 *  @param props the properties passed in.
 *  @returns JSX with the page header React component.*/
function PageHeader (props: PageHeaderProps): JSX.Element {
    const appInsightsContext = useAppInsightsContext();
    const videoURL = {"Introduction_to_Runbooks": STRINGS.incidents.tooltips.videoDialogTitle};

    return (
        <React.Fragment>
            <div className="page-header bg-white ps-4 pe-4 pt-2 pb-2">
                <div className="page-title-holder d-flex justify-content-between align-items-center">
                    {
                        props.controlsToLeftOfTitle &&
                        <>
                            <div className="m-2 d-flex flex-wrap align-items-center">
                                { props.controlsToLeftOfTitle }
                            </div>
                        </>
                    }
                    <div className="left-controls m-2 d-flex flex-wrap justify-content-between" style={{width: '67%'}}>
                        {
                            props.title && !props.hideTitle &&
                            <IconTitle icon={props.icon} title={props.title} status={props.status} isPriority={props.isPriority ? true : false}/>
                        }
                        {
                            props.leftAlignedControls && <div>{props.leftAlignedControls}</div>
                        }
                    </div>
                    { props.rightAlignedControls && <div className="d-flex flex-wrap justify-content-end flex-grow-1">
                        {props.rightAlignedControls}
                    </div>}
                    {props.title === STRINGS.runbooks.incidentRunbooksTitle && <IconVideo videoData={videoURL} />}
                    <div>
                        {
                            props.helpInfo &&
                            <PageTopLevelHelp
                                comment={props.helpInfo.comment}
                                helpSummary={props.helpInfo.helpSummary}
                                showMore={Boolean(props.helpInfo.showMore)}
                                url={HELP_URI + props.helpInfo.url}
                                onHelpAction={(action, url) => {
                                    // report metrics to App Insights
                                    if (appInsightsContext) {
                                        const properties = {
                                            name: action === "launch" ? EventNames.HELP_INVOKED : EventNames.HELP_POPUP_INVOKED, 
                                            properties: {
                                                topic: props.helpInfo?.url || "Unknown",
                                                type: HelpSources.PAGE
                                            }
                                        };
                                        trackEvent(appInsightsContext, AuthService, properties);
                                    }
                                }}
                            />
                        }
                    </div>
                    {props.showDebug && <div>
                        <AnchorButton minimal={true} icon={IconNames.DIAGNOSIS} style={{cursor: "pointer", zIndex: 0}}
                            className="header-debug-button"
                            onClick={() => {
                                if (props.onDebug) {
                                    props.onDebug();
                                }}
                            }                        
                        />
                    </div>}
                </div>
            </div>
            {
                //Show subheader controls or global filter within subheader itself
                (props.subHeader || props.children) &&
                <SubHeader>
                    {
                        props.subHeader ||
                        <div className="header-controls">
                            { props.children }
                        </div>
                    }
                </SubHeader>
            }
        </React.Fragment>

    );

}

export { PageHeader };
