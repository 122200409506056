/** This module contains the component for rendering the usage monitoring section.
 * It consist of three cards and the graph.
 *  @module
 */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { CardsHolder } from "components/common/layout/cards-holder/CardsHolder";
import { MonthToDateTotalCard } from "../cards/runbooks/MonthToDateTotalCard.tsx";
import { MonthlyProjectionCard } from "../cards/runbooks/MonthlyProjectionCard.tsx";
import { PriorMonthTotalCard } from "../cards/runbooks/PriorMonthTotalCard.tsx";
import {
    dateRangeEnum,
    UsageMonitorService,
} from "utils/services/UsageMonitorApiService.ts";
import { RunbooksTotalGraph } from "../graphs/RunbooksTotalGraph.tsx";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade.tsx";
import { Form } from "components/common/form/Form.tsx";
import { SelectField } from "components/common/form/SelectField.tsx";

import { STRINGS } from "app-strings/index.ts";

/** Renders the usage monitoring top view.
 *  @param props the properties passed in.
 *  @returns JSX with the usage monitoring top component.*/
const RunbooksMonitorView = (): JSX.Element => {
    const [loading, setLoading] = useState<boolean>(true);
    const [currentMonthData, setCurrentMonthData] = useState<any>();
    const [priorMonthData, setPriorMonthData] = useState<any>();
    const [projectedData, setProjectedData] = useState<any>();
    const [percentageData, setPercentageData] = useState<any>();
    const [dateRange, setDateRange] = useState<string>("currentmonth");
    const [graphData, setGraphData] = useState<any>();

    useEffect(() => {
        const fetchData = async () => {
            const currentMonthResults: Array<any> =
                await UsageMonitorService.getRunbookUse("currentmonth");
            setCurrentMonthData(currentMonthResults);
            const priorMonthResults: Array<any> =
                await UsageMonitorService.getRunbookUse("lastmonth");
            setPriorMonthData(priorMonthResults);
            setProjectedData(calculateProjectedUse(currentMonthResults));
            setPercentageData(
                calculatePercentageChange(
                    currentMonthResults,
                    priorMonthResults,
                ),
            );
            setGraphData(currentMonthResults);
        };

        fetchData();
        setLoading(false);
    }, []);

    const calculateProjectedUse = (currentData: any) => {
        const averageCurrent =
            currentData.items.filter((item) => {
                return item.runbookCount !== null;
            }).length !== 0
                ? currentData.totalCount /
                  currentData.items.filter((item) => {
                      return item.runbookCount !== null;
                  }).length
                : currentData.totalCount;
        return (
            currentData.totalCount +
            averageCurrent * (moment().daysInMonth() - moment().utc().date())
        );
    };

    const calculatePercentageChange = (currentData: any, priorData: any) => {
        const averageCurrent = currentData.items.filter((item) => {
            return item.runbookCount !== null;
        }).length
            ? currentData.totalCount /
              currentData.items.filter((item) => {
                  return item.runbookCount !== null;
              }).length
            : 0;
        const averagePrior =
            priorData.items.filter((item) => {
                return item.runbookCount !== null;
            }).length !== 0
                ? priorData.totalCount /
                  priorData.items.filter((item) => {
                      return item.runbookCount !== null;
                  }).length
                : 0;
        const percentageChange =
            averagePrior !== 0
                ? 100 - (averagePrior / averageCurrent) * 100
                : 100;
        return averageCurrent > averagePrior
            ? percentageChange
            : percentageChange * -1;
    };

    const handleDateRangeUpdate = async (value: string) => {
        setLoading(true);
        setDateRange(value);
        const graphData: Array<any> =
            await UsageMonitorService.getRunbookUse(value);
        setGraphData(graphData);
        setLoading(false);
    };

    const dateRangeOptions: Array<{ label: string; value: string }> = [
        {
            label: String(dateRangeEnum.currentmonth),
            value: "currentmonth",
        },
        {
            label: String(dateRangeEnum.lastmonth),
            value: "lastmonth",
        },
    ];

    return (
        <React.Fragment>
            <DataLoadFacade
                key="runbooks_monitor"
                loading={loading}
                error={""}
                data={[]}
            >
                <CardsHolder className="pb-3 w-max-9" title={""}>
                    <MonthToDateTotalCard
                        data={currentMonthData}
                        percentage={percentageData}
                    />
                    <MonthlyProjectionCard data={projectedData} />
                    <PriorMonthTotalCard data={priorMonthData} />
                </CardsHolder>
                <div className="card p-3">
                    <div className="row">
                        <div className="col-sm d-flex justify-content-start fs-4 text-black">
                            {
                                STRINGS.USAGE_MONITORING_PAGE.graphs
                                    .runbooksTotal.title
                            }
                        </div>
                        <div className="col-sm d-flex justify-content-center">
                            <Form
                                key={"runbooks-date-range"}
                                initialValues={{
                                    runbooks_date_range: dateRange,
                                }}
                                loading={false}
                            >
                                <div className="d-flex">
                                    <SelectField
                                        name="runbooks_date_range"
                                        onChange={(event) => {
                                            handleDateRangeUpdate(
                                                event.target.value,
                                            );
                                        }}
                                        value={dateRange}
                                    >
                                        {dateRangeOptions.map((option, i) => (
                                            <option
                                                key={i}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        ))}
                                    </SelectField>
                                </div>
                            </Form>
                        </div>
                        <div className="col-sm"></div>
                    </div>
                    <RunbooksTotalGraph data={graphData} loading={loading} />
                </div>
                <div className="pt-2">
                    {STRINGS.USAGE_MONITORING_PAGE.graphs.basedOnUTC}
                </div>
            </DataLoadFacade>
        </React.Fragment>
    );
};

export { RunbooksMonitorView };
