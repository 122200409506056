import { useState } from 'react';
import { ApolloProvider } from '@apollo/client';

import { TIRAuthentication, TIRAuthorization } from '@tir-ui/azure-auth';
import { LoadingOverlay } from '@tir-ui/react-components';

import { Main } from 'components/app/main/Main.tsx';
import { ErrorPage } from 'pages-unrouted/error/ErrorPage.tsx';

import { client } from 'utils/services/GraphqlService.ts';
import { embeddedClient } from 'utils/services/GraphqlEmbedService.ts';

import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'utils/appinsights/AppInsights.ts';
import { IS_EMBEDDED } from 'components/enums/QueryParams.ts';
import { MainAppErrorDialog } from 'components/modals/MainAppErrorDialog/MainAppErrorDialog.tsx';
import EmbedAuthWrapper from 'components/app/embed-auth-wrapper/EmbedAuthWrapper.tsx';

import 'styles/main.scss';

const ENV = import.meta.env.VITE_ENV_SHORT;

/** Renders the application.
 *  @param props the properties passed in.
 *  @returns JSX with the application component.*/
function App(): JSX.Element {
    const [showDialog, setShowDialog] = useState(false);
    const [errorData, setErrorData] = useState({});

    //extract tenant from uri
    const isLocalHost = window.location.host.split('.')[0] === 'localhost:3000';
    const isRunbookViewRoute = window.location.pathname.includes('runbook-details');
    const isOnRouteWithReverseProxyAuth = IS_EMBEDDED && isRunbookViewRoute;

    /**
     * the app is currently tied by default to the "developers" tenant of the dev environment when ran locally
     */
    if (import.meta.env.VITE_REACT_APP_INTEGRATION_TESTING) {
        return (
            <AppInsightsContext.Provider value={reactPlugin}>
                <ApolloProvider client={client}>
                    <Main />
                </ApolloProvider>
            </AppInsightsContext.Provider>
        );
    } else if (isOnRouteWithReverseProxyAuth) {
        // Should bypass MSAL Auth when is embedded in iFrame
        return (
            <AppInsightsContext.Provider value={reactPlugin}>
                <MainAppErrorDialog showDialog={showDialog} errorData={errorData} onClose={onCloseErrorModal} />
                <ApolloProvider client={embeddedClient}>
                    <EmbedAuthWrapper environment={ENV}>
                        <Main shouldSkipGlobalDataStores={true} />
                    </EmbedAuthWrapper>
                </ApolloProvider>
            </AppInsightsContext.Provider>
        );
    } else {
        return (
            <AppInsightsContext.Provider value={reactPlugin}>
                <MainAppErrorDialog showDialog={showDialog} errorData={errorData} onClose={onCloseErrorModal} />
                <TIRAuthentication
					errorComponent={ErrorPage}
					loadingComponent={LoadingOverlay}
					env={ENV as  "dev" | "staging" | "prod"}
					forceTenant={
						isLocalHost
							? import.meta.env?.VITE_LOCALHOST_TENANT
							: undefined
					}
					isEmbedded={IS_EMBEDDED}
					triggerDialog={(data) => {
                        showErrorDialog(data);
                    }}
				>
					<TIRAuthorization
						loadingComponent={LoadingOverlay}
						errorComponent={ErrorPage}
                        env={ENV}
						product="gelato"
					>
						<ApolloProvider client={client}>
							<Main/>
						</ApolloProvider>
					</TIRAuthorization>
				</TIRAuthentication>
            </AppInsightsContext.Provider>
        );
    }

    function onCloseErrorModal() {
        setShowDialog(false);
        setErrorData({});
    }

    function showErrorDialog(data: any) {
        setErrorData(data);
        setShowDialog(true);
    }
}

export default App;
