/** This module contains the component for displaying the interaction configuration step in the widget config dialog.  The
 *      interaction configuration step configures which interactions this widget produces.
 *  @module
 */

import { useRef } from "react";
import { STRINGS } from "app-strings";
import { MultiSelectInput } from "components/common/multiselect/MultiSelectInput.tsx";
import { INTERACTION_TYPE, InteractionConfig } from "components/common/vis-framework/widget/Widget.type.ts";
import { NavigatorWidgetConfig } from "pages/navigator/Navigator.type.ts";

/** this interface defines the properties passed into the InteractionConfigStep React component. */
export interface InteractionConfigStepProps {
    /** the configuration of this current navigator widget. */
    config: NavigatorWidgetConfig;
    /** the list of all configured widgets, this is needed to setup interactions. */
    widgets: NavigatorWidgetConfig[];
    /** the handler for interaction change events. */
    onInteractionsChange?: (interactions: InteractionConfig[]) => void;
}

/** Renders the interaction configuration step.
 *  @param props the properties passed in.
 *  @returns JSX with the interaction configuration step component.*/
const InteractionConfigStep = (props: InteractionConfigStepProps, ref: any): JSX.Element => { 
    const interactions = useRef<InteractionConfig[]>(props.config.interactions || []);

    const groupInteraction: InteractionConfig | undefined = interactions.current.find((interaction) => {return interaction.type === INTERACTION_TYPE.GROUP});
    const selectedGroupInteractions: any[] = (groupInteraction?.widgetIds || []).map((widgetId) => {
        if (widgetId !== props.config.id) {
            // Do not pass an interaction from a widget to itself
            const widgetConf: NavigatorWidgetConfig | undefined = props.widgets.find((widgetConfig) => widgetConfig.id === widgetId);
            return { display: widgetConf?.name || "Unknown", value: widgetConf?.id || "0"}
        }
        return undefined;
    });

    const metricInteraction: InteractionConfig | undefined = interactions.current.find((interaction) => {return interaction.type === INTERACTION_TYPE.METRIC});
    const selectedMetricInteractions: any[] = (metricInteraction?.widgetIds || []).map((widgetId) => {
        if (widgetId !== props.config.id) {
            // Do not pass an interaction from a widget to itself
            const widgetConf: NavigatorWidgetConfig | undefined = props.widgets.find((widgetConfig) => widgetConfig.id === widgetId);
            return { display: widgetConf?.name || "Unknown", value: widgetConf?.id || "0"}
        }
        return undefined;
    });

    return <div className="h-100"><table><tbody>
        <tr>
            <td className="p-1"><label>{STRINGS.viewDashboard.widget.interactionStep.groupLabel}</label></td> 
            <td className="p-1"><MultiSelectInput sortable disabled={false}
                items={props.widgets.filter((widget) => {return widget.id !== props.config.id}).map((widget) => { return { display: widget.name, value: widget.id } })}
                selectedItems={selectedGroupInteractions}
                onChange={(updatedValues) => {
                    const newGroupInteraction = {type: INTERACTION_TYPE.GROUP, widgetIds: updatedValues.map((widget) => widget.value) || []};
                    const newInteractions: InteractionConfig[] = [newGroupInteraction];
                    for (const interaction of interactions.current) {
                        if (interaction.type !== INTERACTION_TYPE.GROUP) {
                            newInteractions.push(interaction);
                            break;
                        }
                    }
                    interactions.current = newInteractions;
                    if (props.onInteractionsChange) {
                        props.onInteractionsChange(interactions.current);
                    }
                }}
                placeholder={STRINGS.viewDashboard.widget.interactionStep.groupPlaceholder}
            /></td>
        </tr>
        <tr>
            <td className="p-1"><label>{STRINGS.viewDashboard.widget.interactionStep.metricLabel}</label></td> 
            <td className="p-1"><MultiSelectInput sortable disabled={false}
                items={props.widgets.filter((widget) => {return widget.id !== props.config.id}).map((widget) => { return { display: widget.name, value: widget.id } })}
                selectedItems={selectedMetricInteractions}
                onChange={(updatedValues) => {
                    const newMetricInteraction = {type: INTERACTION_TYPE.METRIC, widgetIds: updatedValues.map((widget) => widget.value) || []};
                    const newInteractions: InteractionConfig[] = [newMetricInteraction];
                    for (const interaction of interactions.current) {
                        if (interaction.type !== INTERACTION_TYPE.METRIC) {
                            newInteractions.push(interaction);
                            break;
                        }
                    }
                    interactions.current = newInteractions;
                    if (props.onInteractionsChange) {
                        props.onInteractionsChange(interactions.current);
                    }
                }}
                placeholder={STRINGS.viewDashboard.widget.interactionStep.metricPlaceholder}
            /></td>
        </tr>
    </tbody></table></div>;
};
  
export default InteractionConfigStep;
