query aternityDataSource {
    aternityDataSource {
        id
        metricStreamingEnabled
        queriesEnabled
        lastIngestedEndTime
        info {
            status
            error {
                code
                message
                innerError {
                    id
                    trace
                    properties {
                        key
                        value
                    }
                }
                details {
                    code
                    message
                    innerError {
                        id
                        trace
                        properties {
                            key
                            value
                        }
                    }
                }
            }
        }
        hostname
        account {
            id
            name
            domain
            connectorName
            connectorId
        }
    }
}
