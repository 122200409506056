/** This module contains the IQAssistAdminService
 *  @module
 */
import { ApiService } from 'utils/services/ApiService.ts';

/** this type defines a IQAssistAdminConfig. */
export enum assistAdminvalues {
	enabled = 'enabled',
	disabled = 'disabled',
	requestEnabled = 'requestEnabled',
	requestDisabled = 'requestDisabled',
}
export type IQAssistRequest = {
    value: assistAdminvalues,
    error?: Error,
};
// Restricting value to only 2 options
export type LimitedRequests = Omit<IQAssistRequest, "value"> & { value: assistAdminvalues.enabled | assistAdminvalues.disabled };

// The URL for the API server.
export const IQ_ASSIST_ADMIN_CONFIG_BASE_URL = '/api/affogato/';

/** this class defines the IQAssistAdminApiService. */
class IQAssistApiService extends ApiService {
	/** the constructor for the class. */
	constructor() {
		super(IQ_ASSIST_ADMIN_CONFIG_BASE_URL);
	}

	/** returns the base uri, this can be overridden in subclasses to allow the uri to change
	 *      after construction.
	 *  @returns a String with the base uri. */
	protected getBaseUri(): string {
		if (ApiService.USE_REGION) {
			const region = ApiService.AUTH_SERVICE.getRegion();
			return `/api/iq/${region}/`;
		} else {
			return this.baseApiUri;
		}
	}

	/** returns IQ Assist Admin Status.
     *  @returns a Promise which resolves to the returned status. */
    getAssistStatus(): Promise<any> {
        const utid = ApiService.AUTH_SERVICE.getTenantId();
        return new Promise((resolve, reject) => {
            super.get<IQAssistRequest>(`genai/1.0/tenants/${utid}/feature_enabled`).then(
                (results) => {
                    resolve(results);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    /** updates a IQ Assist Admin Status.
	 * @param value an Enum/string <assistAdminvalues> with the value requested. */
    updateAssistStatus(statusUpdateRequest: LimitedRequests): Promise<IQAssistRequest> {
		const utid = ApiService.AUTH_SERVICE.getTenantId();
		return new Promise((resolve, reject) => {
			super.put<IQAssistRequest>(`genai/1.0/tenants/${utid}/feature_enabled`, statusUpdateRequest)
				.then(
					(results) => {
						resolve(results);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}
}


/** a constant with an instance of the IQAssistAdminApiService. */
const IQAssistService = new IQAssistApiService();
export { IQAssistService };
