import { AvailableIntegration, InstalledIntegrationStatus, IntegrationAvatarVariants } from '../types/IntegrationTypes';
import { getIntegrationIcon } from '../IntegrationsLibraryPage';
import { LangEN, STRINGS } from 'app-strings';
import { Button, Intent, Spinner, SpinnerSize } from '@blueprintjs/core';
import { Icon, IconNames } from '@tir-ui/react-components';
import { canChangeIntegrations, getIntegrationBadge } from '../IntegrationLibraryUtils';

/** The generic type used by integrations in the cards */
export type IntegrationCardData = {
    id: string;
    name: string;
    version: string;
    description: string; // The short description in the API
    icons: Array<{ type: IntegrationAvatarVariants; svg: string }>;
    isBeta: boolean; // Flag returned from the API
    isUpgradable: boolean; // We need to check if there is a matching available integration with a larger version
    upgradableToVersion?: string; // The newer version
    isInstalled: boolean; // We need to check if there is an installed integration
    status: InstalledIntegrationStatus | null;
};

type Props = {
    integration: IntegrationCardData,
    availableIntegrations: AvailableIntegration[],
    handleOpenIntegrationConfigurationModal: (
        integration: IntegrationCardData,
        isUpgradable: boolean,
        isInstalled: boolean
    ) => void,
    handleOpenIntegrationErrorModal: (integrationId: string, integrationName: string) => void
}

const IntegrationCard = ({ integration, availableIntegrations, handleOpenIntegrationConfigurationModal, handleOpenIntegrationErrorModal }: Props) => {
    const translations: LangEN['INTEGRATIONS_PAGE'] = STRINGS.INTEGRATIONS_PAGE;
    const brandingIcon = getIntegrationIcon(
        integration.icons,
        integration.name,
        "large"
    );
    const hasAdminRights = canChangeIntegrations();

    const availableIntegration = availableIntegrations.find(
        (availableIntegration) => availableIntegration.id === integration.id
    );

    if (!availableIntegration) {
        console.error(
            `The integration ${integration.id} does not have a matching available integration`
        );
        return <></>;
    }

    // Install/Uninstall with errors
    const statusWithErrors =
        !!integration.status &&
        [
            InstalledIntegrationStatus.InstallationCompletedWithErrors,
            InstalledIntegrationStatus.UninstallCompletedWithErrors,
            InstalledIntegrationStatus.UpdateInstallationCompletedWithErrors
        ].includes(integration.status);

    const cardErrorMessage = (statusWithErrors && integration?.status) ? getCardErrorMessage(integration?.status) : "";

    // Install/Uninstall in progress
    const statusInProgress =
        !!integration.status &&
        [
            InstalledIntegrationStatus.UpdateInstallationInProgress,
            InstalledIntegrationStatus.InstallationInProgress,
            InstalledIntegrationStatus.UninstallInProgress,
        ].includes(integration.status);

    const controlButtonText = getControlButtonContent(
        statusInProgress,
        integration
    );

    /**
     *
     * @param {boolean} statusInProgress - true/false if the integration install/uninstall is in progress
     * @param {IntegrationCardData} integration - the integration data
     *
     * @returns {JSX.Element}
     */
    function getControlButtonContent(
        statusInProgress: boolean,
        integration: IntegrationCardData
    ) {
        const getStatusText = (status: InstalledIntegrationStatus) => {
            if (status === InstalledIntegrationStatus.InstallationInProgress) {
                return translations.controls.installInProgress;
            }

            if (status === InstalledIntegrationStatus.UninstallInProgress) {
                return translations.controls.uninstallInProgress;
            }

            return translations.controls.updateInProgress;
        };

        return statusInProgress ? (
            <span className="d-flex align-items-center" style={{ gap: "8px" }}>
                <Spinner size={SpinnerSize.SMALL}></Spinner>
                {getStatusText(integration.status as InstalledIntegrationStatus)}
            </span>
        ) : integration.isInstalled ? (
            translations.controls.configure
        ) : (
            translations.controls.install
        );
    }

    /**
     * 
     * @param {InstalledIntegrationStatus} status 
     * @returns 
     */
    function getCardErrorMessage(status: InstalledIntegrationStatus) {
        return status ===
            InstalledIntegrationStatus.InstallationCompletedWithErrors
                ? translations.card.installedWithError
                : status === InstalledIntegrationStatus.UpdateInstallationCompletedWithErrors
                    ? translations.card.updatedInstalledIntegrationWithError
                    : translations.card.uninstalledWithError;
    }

    return (
        <div
            key={integration.id}
            className={`card integrations-library__card p-4 ${statusWithErrors && "integrations-library__card--error"
                }`}
        >
            <div className="integrations-library__card-badges">
                {integration.isBeta && getIntegrationBadge('beta')}
                {integration.isUpgradable && getIntegrationBadge('upgradeAvailable')}
            </div>
            <div className="integrations-library__card-logo">
                {brandingIcon}
            </div>
            <div className="integrations-library__card-description">
                <h3 className="mt-4 mb-2 fw-bold font-size-medium">
                    {integration.name}
                </h3>
                <h4 className="ms-auto font-size-x-small mt-n2">
                    {integration.version}
                </h4>
                <p>{integration.description}</p>
            </div>

            {statusWithErrors && (
                <div
                    className="integrations-library__card-error mt-n3 font-size-small"
                    role="button"
                    onClick={() => {
                        handleOpenIntegrationErrorModal(integration.id, integration.name);
                    }}
                    title={translations.card.seeDetails}
                >
                    <Icon
                        icon={IconNames.ERROR}
                        className="me-2"
                        intent={Intent.DANGER}
                    />
                    <span> {cardErrorMessage} </span>
                </div>
            )}

            {hasAdminRights && (
                <>
                    <Button
                        className="mt-2 integration-library__card-control-btn"
                        intent={Intent.PRIMARY}
                        disabled={ statusInProgress }
                        icon={
                            integration.isInstalled ? null : IconNames.PLUS
                        }
                        text={controlButtonText}
                        onClick={() =>
                            handleOpenIntegrationConfigurationModal(
                                integration,
                                integration.isUpgradable,
                                integration.isInstalled
                            )
                        }
                    ></Button>
                </>
            )}
        </div>
    );
}

export default IntegrationCard
