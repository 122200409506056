/** This module contains utilities that are used for the incident search.
 *  @module
 */

import { SEARCH_ENGINE } from "utils/services/UserPrefsTypes.ts";

/** returns the correct string for the createdAt id that is used the table.
 *  @param engine the current SEARCH_ENGINE or undefined.
 *  @returns a String with the key for the createdAt id that is used the table. */
export function getCreatedAtIdForEngine(
    engine: SEARCH_ENGINE | undefined,
): string {
    if (engine) {
        switch (engine) {
            case SEARCH_ENGINE.correlation_direct:
                return "incidents/createdAt";
            case SEARCH_ENGINE.correlation_dal:
                return "createdAt";
        }
    }
    return "";
}

/** returns the correct string for the description id that is used the table.
 *  @param engine the current SEARCH_ENGINE or undefined.
 *  @returns a String with the key for the description id that is used the table. */
export function getDescriptionIdForEngine(
    engine: SEARCH_ENGINE | undefined,
): string {
    if (engine) {
        switch (engine) {
            case SEARCH_ENGINE.correlation_direct:
                return "incidents/description";
            case SEARCH_ENGINE.correlation_dal:
                return "description";
        }
    }
    return "";
}

/** returns the correct string for the priority id that is used the table.
 *  @param engine the current SEARCH_ENGINE or undefined.
 *  @returns a String with the key for the preated id that is used the table. */
export function getPriorityIdForEngine(
    engine: SEARCH_ENGINE | undefined,
): string {
    if (engine) {
        switch (engine) {
            case SEARCH_ENGINE.correlation_direct:
                return "incidents/priority";
            case SEARCH_ENGINE.correlation_dal:
                return "priority";
        }
    }
    return "";
}

/** returns the correct string for the status id that is used the table.
 *  @param engine the current SEARCH_ENGINE or undefined.
 *  @returns a String with the key for the status id that is used the table. */
export function getStatusIdForEngine(
    engine: SEARCH_ENGINE | undefined,
): string {
    if (engine) {
        switch (engine) {
            case SEARCH_ENGINE.correlation_direct:
                return "incidents/status";
            case SEARCH_ENGINE.correlation_dal:
                return "status";
        }
    }
    return "";
}

/** returns the correct string for the device name id that is used the table.
 *  @param engine the current SEARCH_ENGINE or undefined.
 *  @returns a String with the key for the device name id that is used the table. */
export function getDeviceSearchNameIdForEngine(
    engine: SEARCH_ENGINE | undefined,
): string {
    if (engine) {
        switch (engine) {
            case SEARCH_ENGINE.correlation_direct:
                return "networkDevices/name";
            case SEARCH_ENGINE.correlation_dal:
                return "name";
        }
    }
    return "";
}

/** returns the correct string for the device IP address id that is used the table.
 *  @param engine the current SEARCH_ENGINE or undefined.
 *  @returns a String with the key for the device IP address id that is used the table. */
export function getDeviceSearchIpAddressIdForEngine(
    engine: SEARCH_ENGINE | undefined,
): string {
    if (engine) {
        switch (engine) {
            case SEARCH_ENGINE.correlation_direct:
                return "networkDevices/ipaddr";
            case SEARCH_ENGINE.correlation_dal:
                return "ipAddress";
        }
    }
    return "";
}

/** returns the correct string for the device location id that is used the table.
 *  @param engine the current SEARCH_ENGINE or undefined.
 *  @returns a String with the key for the device location id that is used the table. */
export function getDeviceSearchLocationIdForEngine(
    engine: SEARCH_ENGINE | undefined,
): string {
    if (engine) {
        switch (engine) {
            case SEARCH_ENGINE.correlation_direct:
                return "networkDevices/location/name";
            case SEARCH_ENGINE.correlation_dal:
                return "locationName";
        }
    }
    return "";
}

/** returns the correct string for the interface name id that is used the table.
 *  @param engine the current SEARCH_ENGINE or undefined.
 *  @returns a String with the key for the interface name id that is used the table. */
export function getInterfaceSearchNameIdForEngine(
    engine: SEARCH_ENGINE | undefined,
): string {
    if (engine) {
        switch (engine) {
            case SEARCH_ENGINE.correlation_direct:
                return "networkInterfaces/name";
            case SEARCH_ENGINE.correlation_dal:
                return "name";
        }
    }
    return "";
}

/** returns the correct string for the interface IP address id that is used the table.
 *  @param engine the current SEARCH_ENGINE or undefined.
 *  @returns a String with the key for the interface IP address id that is used the table. */
export function getInterfaceSearchIpAddressIdForEngine(
    engine: SEARCH_ENGINE | undefined,
): string {
    if (engine) {
        switch (engine) {
            case SEARCH_ENGINE.correlation_direct:
                return "networkInterfaces/ipaddr";
            case SEARCH_ENGINE.correlation_dal:
                return "ipAddress";
        }
    }
    return "";
}

/** returns the correct string for the interface IF index id that is used the table.
 *  @param engine the current SEARCH_ENGINE or undefined.
 *  @returns a String with the key for the interface IF index id that is used the table. */
export function getInterfaceSearchIfIndexIdForEngine(
    engine: SEARCH_ENGINE | undefined,
): string {
    if (engine) {
        switch (engine) {
            case SEARCH_ENGINE.correlation_direct:
                return "networkInterfaces/ifindex";
            case SEARCH_ENGINE.correlation_dal:
                return "ifIndex";
        }
    }
    return "";
}

/** returns the correct string for the interface location id that is used the table.
 *  @param engine the current SEARCH_ENGINE or undefined.
 *  @returns a String with the key for the interface location id that is used the table. */
export function getInterfaceSearchLocationIdForEngine(
    engine: SEARCH_ENGINE | undefined,
): string {
    if (engine) {
        switch (engine) {
            case SEARCH_ENGINE.correlation_direct:
                return "networkInterfaces/location/name";
            case SEARCH_ENGINE.correlation_dal:
                return "locationName";
        }
    }
    return "";
}

/** returns the correct string for the application name id that is used the table.
 *  @param engine the current SEARCH_ENGINE or undefined.
 *  @returns a String with the key for the application name id that is used the table. */
export function getApplicationSearchNameIdForEngine(
    engine: SEARCH_ENGINE | undefined,
): string {
    if (engine) {
        switch (engine) {
            case SEARCH_ENGINE.correlation_direct:
                return "applications/name";
            case SEARCH_ENGINE.correlation_dal:
                return "name";
        }
    }
    return "";
}

/** returns the correct string for the location name id that is used the table.
 *  @param engine the current SEARCH_ENGINE or undefined.
 *  @returns a String with the key for the location name id that is used the table. */
export function getLocationSearchNameIdForEngine(
    engine: SEARCH_ENGINE | undefined,
): string {
    if (engine) {
        switch (engine) {
            case SEARCH_ENGINE.correlation_direct:
                return "locations/name";
            case SEARCH_ENGINE.correlation_dal:
                return "name";
        }
    }
    return "";
}

/** returns the correct string for the location type id that is used the table.
 *  @param engine the current SEARCH_ENGINE or undefined.
 *  @returns a String with the key for the location type id that is used the table. */
export function getLocationSearchTypeIdForEngine(
    engine: SEARCH_ENGINE | undefined,
): string {
    if (engine) {
        switch (engine) {
            case SEARCH_ENGINE.correlation_direct:
                return "locations/type";
            case SEARCH_ENGINE.correlation_dal:
                return "type";
        }
    }
    return "";
}

/** returns the correct string for the location city id that is used the table.
 *  @param engine the current SEARCH_ENGINE or undefined.
 *  @returns a String with the key for the location city id that is used the table. */
export function getLocationSearchCityIdForEngine(
    engine: SEARCH_ENGINE | undefined,
): string {
    if (engine) {
        switch (engine) {
            case SEARCH_ENGINE.correlation_direct:
                return "locations/city";
            case SEARCH_ENGINE.correlation_dal:
                return "city";
        }
    }
    return "";
}

/** returns the correct string for the location state id that is used the table.
 *  @param engine the current SEARCH_ENGINE or undefined.
 *  @returns a String with the key for the location state id that is used the table. */
export function getLocationSearchStateIdForEngine(
    engine: SEARCH_ENGINE | undefined,
): string {
    if (engine) {
        switch (engine) {
            case SEARCH_ENGINE.correlation_direct:
                return "locations/state";
            case SEARCH_ENGINE.correlation_dal:
                return "state";
        }
    }
    return "";
}

/** returns the correct string for the location country id that is used the table.
 *  @param engine the current SEARCH_ENGINE or undefined.
 *  @returns a String with the key for the location country id that is used the table. */
export function getLocationSearchCountryIdForEngine(
    engine: SEARCH_ENGINE | undefined,
): string {
    if (engine) {
        switch (engine) {
            case SEARCH_ENGINE.correlation_direct:
                return "locations/country";
            case SEARCH_ENGINE.correlation_dal:
                return "country";
        }
    }
    return "";
}

/** returns the correct string for the custom property name id that is used the table.
 *  @param engine the current SEARCH_ENGINE or undefined.
 *  @returns a String with the key for the custom property name id that is used the table. */
export function getCustomPropertySearchNameIdForEngine(
    engine: SEARCH_ENGINE | undefined,
): string {
    if (engine) {
        switch (engine) {
            case SEARCH_ENGINE.correlation_direct:
                return "customProperties/name";
            case SEARCH_ENGINE.correlation_dal:
                return "name";
        }
    }
    return "";
}

/** returns the correct string for the custom property description id that is used the table.
 *  @param engine the current SEARCH_ENGINE or undefined.
 *  @returns a String with the key for the custom property description id that is used the table. */
export function getCustomPropertySearchDescriptionIdForEngine(
    engine: SEARCH_ENGINE | undefined,
): string {
    if (engine) {
        switch (engine) {
            case SEARCH_ENGINE.correlation_direct:
                return "customProperties/description";
            case SEARCH_ENGINE.correlation_dal:
                return "description";
        }
    }
    return "";
}

/** returns the correct string for the custom property last updated at id that is used the table.
 *  @param engine the current SEARCH_ENGINE or undefined.
 *  @returns a String with the key for the custom property last updated at id that is used the table. */
export function getCustomPropertySearchLastUpdatedAtIdForEngine(
    engine: SEARCH_ENGINE | undefined,
): string {
    if (engine) {
        switch (engine) {
            case SEARCH_ENGINE.correlation_direct:
                return "customProperties/lastUpdatedAt";
            case SEARCH_ENGINE.correlation_dal:
                return "lastUpdatedAt";
        }
    }
    return "";
}

/** returns the correct string for the priority facet that is used in the faceted search.
 *  @param engine the current SEARCH_ENGINE or undefined.
 *  @returns a String with the key for the priority facet. */
export function getPriorityFacetForEngine(
    engine: SEARCH_ENGINE | undefined,
): string {
    if (engine) {
        switch (engine) {
            case SEARCH_ENGINE.correlation_direct:
                return "incidents/priority";
            case SEARCH_ENGINE.correlation_dal:
                return "PRIORITY";
        }
    }
    return "";
}

/** returns the correct string for the status facet that is used in the faceted search.
 *  @param engine the current SEARCH_ENGINE or undefined.
 *  @returns a String with the key for the status facet. */
export function getStatusFacetForEngine(
    engine: SEARCH_ENGINE | undefined,
): string {
    if (engine) {
        switch (engine) {
            case SEARCH_ENGINE.correlation_direct:
                return "incidents/status";
            case SEARCH_ENGINE.correlation_dal:
                return "STATUS";
        }
    }
    return "";
}

/** returns the correct string for the application facet that is used in the faceted search.
 *  @param engine the current SEARCH_ENGINE or undefined.
 *  @returns a String with the name of the facet category for an application name indicator. */
export function getInterfaceFacetForEngine(
    engine: SEARCH_ENGINE | undefined,
): string {
    if (engine) {
        switch (engine) {
            case SEARCH_ENGINE.correlation_direct:
                return "incidents/incidentIndicators/indicator/networkInterface/name";
            case SEARCH_ENGINE.correlation_dal:
                return "INTERFACE_NAME";
        }
    }
    return "";
}

/** returns the correct string for the device facet that is used in the faceted search.
 *  @param engine the current SEARCH_ENGINE or undefined.
 *  @returns a String with the name of the facet category for an application name indicator. */
export function getDeviceFacetForEngine(
    engine: SEARCH_ENGINE | undefined,
): string {
    if (engine) {
        switch (engine) {
            case SEARCH_ENGINE.correlation_direct:
                return "incidents/incidentIndicators/indicator/networkDevice/name";
            case SEARCH_ENGINE.correlation_dal:
                return "DEVICE_NAME";
        }
    }
    return "";
}

/** returns the correct string for the application facet that is used in the faceted search.
 *  @param engine the current SEARCH_ENGINE or undefined.
 *  @returns a String with the name of the facet category for an application name indicator. */
export function getApplicationFacetForEngine(
    engine: SEARCH_ENGINE | undefined,
): string {
    if (engine) {
        switch (engine) {
            case SEARCH_ENGINE.correlation_direct:
                return "incidents/incidentIndicators/indicator/applicationLocation/application/name";
            case SEARCH_ENGINE.correlation_dal:
                return "APPLICATION_NAME";
        }
    }
    return "";
}

/** returns the correct string for the impacted application facet that is used in the faceted search.
 *  @param engine the current SEARCH_ENGINE or undefined.
 *  @returns a String with the name of the facet category for an impacted application facet. */
export function getImpactedApplicationFacetForEngine(
    engine: SEARCH_ENGINE | undefined,
): string {
    if (engine) {
        switch (engine) {
            case SEARCH_ENGINE.correlation_direct:
                return "incidents/impactedApplications/name";
            case SEARCH_ENGINE.correlation_dal:
                return "IMPACTED_APPLICATION";
        }
    }
    return "";
}

/** returns the correct string for the impacted location facet that is used in the faceted search.
 *  @param engine the current SEARCH_ENGINE or undefined.
 *  @returns a String with the name of the facet category for an impacted location name. */
export function getImpactedLocationFacetForEngine(
    engine: SEARCH_ENGINE | undefined,
): string {
    if (engine) {
        switch (engine) {
            case SEARCH_ENGINE.correlation_direct:
                return "incidents/impactedLocations/name";
            case SEARCH_ENGINE.correlation_dal:
                return "IMPACTED_LOCATION";
        }
    }
    return "";
}

/** returns the correct string for the impacted user facet that is used in the faceted search.
 *  @param engine the current SEARCH_ENGINE or undefined.
 *  @returns a String with the name of the facet category for an impacted user name indicator. */
export function getImpactedUserFacetForEngine(
    engine: SEARCH_ENGINE | undefined,
): string {
    if (engine) {
        switch (engine) {
            case SEARCH_ENGINE.correlation_direct:
                return "incidents/impactedUsers/name";
            case SEARCH_ENGINE.correlation_dal:
                return "IMPACTED_USER_NAME";
        }
    }
    return "";
}

/** returns the facet value with the correct case.  When using cognitive search the status and 
 *      priority values were in camel case, for DAL they are uppercase.
 *  @param text the facet value.
 *  @param engine the search engine that is being used.
 *  @returns a String with the facet value in the correct case. */
export function getFacetValue(text: string, engine: SEARCH_ENGINE | undefined): string {
    if (text) {
        if (engine !== SEARCH_ENGINE.correlation_dal) {
            return toCamelCase(text);
        } else {
            return text.toUpperCase();
        }
    }
    return text;
}

/** The priority and status fields from search are in camelcase so when filtering the new
 *  search page we need to send the filters as camelcase.  However in the old search page
 *  we need the DAL all uppercase enums.
 *  @param name a String with the status or priority name.
 *  @returns a String with the camelcase text. */
export function toCamelCase(text: string): string {
    return text && text.length > 0 ? text.substring(0, 1) + text.toLowerCase().substring(1, text.length) : text;
}

/** returns an object with the initial facets.
 *  @param srchEngine the SEARCH_ENGINE that currently is in use.
 *  @returns an object with the facets. */
export function getInitialSearchParamsFacets(srchEngine: SEARCH_ENGINE | undefined): any {
    if (srchEngine) {
        switch (srchEngine) {
            case SEARCH_ENGINE.correlation_direct:
                return JSON.stringify(
                    {
                        "incidents/isOngoing": [true], 
                        "incidents/status": ["New", "Investigating"], 
                        "incidents/priority": ["Critical", "High"]
                    }
                );
            case SEARCH_ENGINE.correlation_dal:
                return JSON.stringify(
                    {
                        "COMPLETION_STATUS": ["ONGOING"], 
                        "STATUS": ["NEW", "INVESTIGATING"], 
                        "PRIORITY": ["CRITICAL", "HIGH"]
                    }
                );    
        }
    }
    return {};
}
