/** This module contains the data panel that shows data about the graph
 *  @module
 */

import React, { useReducer, useCallback, useRef } from 'react';
import { GraphDef, NodeDef, EdgeDef } from './types/GraphTypes.ts';
import { Classes, Tree } from "@blueprintjs/core";
import { clone } from "lodash";
import { STRINGS } from "app-strings";
import './GraphDataPanel.css';

/** This interface defines the properties passed into the graph data panel React component.*/
interface GraphDataPanelProps {
    /** the GraphDef object with the current graph nodes and edges. */
    GraphDef: GraphDef;
    /** the array of global nodes. */
    globalNodes: Array<NodeDef>;
}

type NodePath = number[];

type TreeAction =
    | { type: "SET_IS_EXPANDED"; payload: { path: NodePath; isExpanded: boolean } }
    | { type: "DESELECT_ALL" }
    | { type: "SET_IS_SELECTED"; payload: { path: NodePath; isSelected: boolean } }
    | { type: "ADD_NODE"; payload: { node: NodeDef, typeAndIndex: number } }
    | { type: "ADD_EDGE"; payload: { edge: EdgeDef, fromNode: NodeDef, toNode: NodeDef } }
    | { type: "DELETE_NODE"; payload: { id: string, typeAndIndex: number } }
    | { type: "DELETE_EDGE"; payload: { id: string } }
    | { type: "UPDATE_NODE"; payload: { node: NodeDef, typeAndIndex: number } };


/** executes the specified visit function for the specified set of nodes and their
 *      children.
 *  @param nodes the nodes for which the visit function is to be run.
 *  @param path the path.
 *  @param visitFn the visit function.*/
function forEachNode(nodes: any[] | undefined, visitFn: (node: any) => void) {
    if (nodes === undefined) {
        return;
    }

    for (const node of nodes) {
        visitFn(node);
        forEachNode(node.childNodes, visitFn);
    }
}

/** executes the specified callback for the tree node specified by the path.
 *  @param nodes the nodes in the tree.
 *  @param path the path.
 *  @param callback the callback function.*/
function forNodeAtPath(nodes: any[], path: NodePath, callback: (node: any) => void) {
    callback(Tree.nodeFromPath(path, nodes));
}

/** update the path for all nodes and their children.
 *  @param nodes the nodes to update the path.
 *  @param currentPath the current path for the current location in the tree.*/
function updatePath(nodes: any[], currentPath: Array<number>) {
    if (nodes) {
        for (let index = 0; index < nodes.length; index++) {
            nodes[index].path = ([] as Array<number>);
            nodes[index].path.push(index);
            if (nodes[index].childNodes && nodes[index].childNodes.length > 0) {
                updatePath(nodes[index].childNodes, nodes[index].path);
            }
        }
    }
}

/** the reducer function.
 *  @param state the current state of the tree.
 *  @param action the current action.
 *  @returns the new state of the tree after taking the specified action.*/
function treeReducer(state: any[], action: TreeAction) {
    switch (action.type) {
        case "DESELECT_ALL": {
            const newState = clone(state);
            forEachNode(newState, node => (node.isSelected = false));
            return newState;
        }
        case "SET_IS_EXPANDED": {
            const newState = clone(state);
            forNodeAtPath(newState, action.payload.path, node => (node.isExpanded = action.payload.isExpanded));
            return newState;
        }
        case "SET_IS_SELECTED": {
            const newState = clone(state);
            forNodeAtPath(newState, action.payload.path, node => {
                if (node) {
                    node.isSelected = action.payload.isSelected
                }
            });
            return newState;
        }
        case "ADD_NODE": {
            const newState = clone(state);
            // type 0 is a standard node and type 1 is a config node
            const type = action.payload.typeAndIndex;
            const node = action.payload.node;
            const nodesNode = newState[type];
            if (!nodesNode.childNodes) {
                nodesNode.childNodes = [];
            }
            const path = [type, nodesNode.childNodes.length];
            const treeNode = {
                id: node.id, key: node.id, depth: 1, path: path, 
                label: (node.name ? node.name : STRINGS.runbookEditor.graphData.NoNameText + " (id=" + node.id + ")")
            };
            addPropertiesToNode(node, treeNode, path);
            nodesNode.childNodes.push(treeNode);
            return newState;
        }
        case "ADD_EDGE": {
            const newState = clone(state);
            const edge = action.payload.edge;
            const fromNode = action.payload.fromNode;
            const fromNodeName = (fromNode.name ? fromNode.name : STRINGS.runbookEditor.graphData.NoNameText + " (id=" + fromNode.id + ")");
            const toNode = action.payload.toNode;
            const toNodeName = (toNode.name ? toNode.name : STRINGS.runbookEditor.graphData.NoNameText + " (id=" + toNode.id + ")");
            const edgesNode = newState[EDGE_INDEX];
            const id = fromNode.id + (edge.fromPort ? ":" + edge.fromPort : "") + "-" + toNode.id  + (edge.toPort ? ":" + edge.toPort : "");
            const name = fromNodeName + (edge.fromPort ? ":" + edge.fromPort : "") + " to " + toNodeName  + (edge.toPort ? ":" + edge.toPort : "");
            if (!edgesNode.childNodes) {
                edgesNode.childNodes = [];
            }
            edgesNode.childNodes.push({
                id, key: id, depth: 1, path: [EDGE_INDEX, edgesNode.childNodes.length], label: name
            });
            return newState;
        }
        case "DELETE_NODE": {
            const newState = clone(state);
            // type 0 is a standard node and type 1 is a config node
            const type = action.payload.typeAndIndex;
            const nodeId = action.payload.id;
            const nodesNode = newState[type];
            if (nodesNode.childNodes) {
                for (let index = 0; index < nodesNode.childNodes.length; index++) {
                    const nodeNode = nodesNode.childNodes[index];
                    if (nodeNode.id === nodeId) {
                        nodesNode.childNodes.splice(index, 1);
                        if (nodesNode.childNodes.length === 0) {
                            delete nodesNode.childNodes;
                        }
                        break;
                    }
                }
            }
            updatePath(newState, []);
            return newState;
        }
        case "DELETE_EDGE": {
            const newState = clone(state);
            const edgeId = action.payload.id;
            const edgesNode = newState[EDGE_INDEX];
            if (edgesNode.childNodes) {
                for (let index = 0; index < edgesNode.childNodes.length; index++) {
                    const edgeNode = edgesNode.childNodes[index];
                    if (edgeNode.id === edgeId) {
                        edgesNode.childNodes.splice(index, 1);
                        if (edgesNode.childNodes.length === 0) {
                            delete edgesNode.childNodes;
                        }
                        break;
                    }
                }
            }
            updatePath(newState, []);
            return newState;
        }
        case "UPDATE_NODE": {
            const newState = clone(state);
            // type 0 is a standard node and type 1 is a config node
            const type = action.payload.typeAndIndex;
            const node = action.payload.node;
            const nodesNode = newState[type];
            if (nodesNode && nodesNode.childNodes) {
                for (const nodeNode of nodesNode.childNodes) {
                    if (nodeNode.id === node.id) {
                        nodeNode.label = node.name;
                        addPropertiesToNode(node, nodeNode, nodeNode.path);
                    }
                }
            }
            return newState;
        }
    }
}

const NODE_INDEX: number = 0;
const EDGE_INDEX: number = 1;
const CONFIG_INDEX: number = 2;

const INITIAL_STATE: Array<any> = [];
INITIAL_STATE.push({id: "Nodes", key:"Nodes", label: STRINGS.runbookEditor.graphData.nodesText, isExpanded: false});
INITIAL_STATE.push({id: "Edges", key:"Edges", label: STRINGS.runbookEditor.graphData.edgesText, isExpanded: false});
INITIAL_STATE.push({id: "Config_Nodes", key:"Config_Nodes", label: STRINGS.runbookEditor.graphData.configNodesText, isExpanded: false});

/** Renders the graph data panel component.
 *  @param props the properties passed in.
 *  @returns JSX with the graph data panel component.*/
export default function GraphDataPanel(props: GraphDataPanelProps): JSX.Element  {
    const [nodes, dispatch] = useReducer(treeReducer, INITIAL_STATE);

    const handleNodeClick = useCallback(
        (node: any, nodePath: any, e: React.MouseEvent<HTMLElement>) => {
            const originallySelected = node.isSelected;
            if (!e.shiftKey) {
                dispatch({ type: "DESELECT_ALL" });
            }
            dispatch({
                payload: { path: nodePath, isSelected: originallySelected == null ? true : !originallySelected },
                type: "SET_IS_SELECTED",
            });
        },
        [],
    );

    const handleNodeCollapse = useCallback((_node: any, nodePath: NodePath) => {
        dispatch({
            payload: { path: nodePath, isExpanded: false },
            type: "SET_IS_EXPANDED",
        });
    }, []);

    const handleNodeExpand = useCallback((_node: any, nodePath: NodePath) => {
        dispatch({
            payload: { path: nodePath, isExpanded: true },
            type: "SET_IS_EXPANDED",
        });
    }, []);

    const oldGraphDef = useRef<GraphDef>();
    const oldConfigNodes = useRef<Array<NodeDef>>();
    if (oldGraphDef.current !== props.GraphDef || oldConfigNodes.current !== props.globalNodes) {
        // The graph data in the parent is cloned whenever it changes to we can count on the reference
        // changing each time it is modified
        oldGraphDef.current = props.GraphDef;
        oldConfigNodes.current = props.globalNodes;

        // Clean the tree first
        const existingNodes: Array<string> = [];
        const existingConfigNodes: Array<string> = [];
        const existingEdges: Array<string> = [];
        for (const node of props.GraphDef.nodes) {
            existingNodes.push(node.id);
        }
        for (const node of props.globalNodes) {
            existingConfigNodes.push(node.id);
        }
        for (const edge of props.GraphDef.edges) {
            const edgeId = edge.fromNode + (edge.fromPort ? ":" + edge.fromPort : "") + "-" + edge.toNode  + (edge.toPort ? ":" + edge.toPort : "");
            existingEdges.push(edgeId);
        }
        if (nodes[NODE_INDEX].childNodes) {
            for (const node of nodes[NODE_INDEX].childNodes) {
                if (!existingNodes.includes(node.id)) {
                    dispatch({
                        payload: { id: node.id, typeAndIndex: NODE_INDEX },
                        type: "DELETE_NODE",
                    });        
                }
            }
        }
        if (nodes[CONFIG_INDEX].childNodes) {
            for (const node of nodes[CONFIG_INDEX].childNodes) {
                if (!existingConfigNodes.includes(node.id)) {
                    dispatch({
                        payload: { id: node.id, typeAndIndex: CONFIG_INDEX },
                        type: "DELETE_NODE",
                    });        
                }
            }
        }
        if (nodes[EDGE_INDEX].childNodes) {
            for (const edge of nodes[EDGE_INDEX].childNodes) {
                if (!existingEdges.includes(edge.id)) {
                    dispatch({
                        payload: { id: edge.id },
                        type: "DELETE_EDGE",
                    });        
                }
            }
        }

        for (const node of props.GraphDef.nodes) {
            let treeNode;
            if (nodes[NODE_INDEX].childNodes) {
                for (const checkTreeNode of nodes[NODE_INDEX].childNodes) {
                    if (node.id === checkTreeNode.id) {
                        treeNode = checkTreeNode;
                        break;
                    }
                }
            }
            if (treeNode) {
                dispatch({
                    payload: { node: node, typeAndIndex: NODE_INDEX },
                    type: "UPDATE_NODE",
                });    
            } else {
                dispatch({
                    payload: { node: node, typeAndIndex: NODE_INDEX },
                    type: "ADD_NODE",
                });    
            }
        }

        for (const node of props.globalNodes) {
            let treeNode;
            if (nodes[CONFIG_INDEX].childNodes) {
                for (const checkTreeNode of nodes[CONFIG_INDEX].childNodes) {
                    if (node.id === checkTreeNode.id) {
                        treeNode = checkTreeNode;
                        break;
                    }
                }
            }
            if (treeNode) {
                dispatch({
                    payload: { node: node, typeAndIndex: CONFIG_INDEX },
                    type: "UPDATE_NODE",
                });
            } else {
                dispatch({
                    payload: { node: node, typeAndIndex: CONFIG_INDEX },
                    type: "ADD_NODE",
                });
            }
        }

        for (const edge of props.GraphDef.edges) {
            let fromNode;
            let toNode;
            for (const node of props.GraphDef.nodes) {
                if (node.id === edge.fromNode) {
                    fromNode = node;
                }
                if (node.id === edge.toNode) {
                    toNode = node;
                }
                if (fromNode && toNode) {
                    break;
                }
            }
            const edgeId = edge.fromNode + (edge.fromPort ? ":" + edge.fromPort : "") + "-" + edge.toNode  + (edge.toPort ? ":" + edge.toPort : "");
            let treeNode;
            if (nodes[EDGE_INDEX].childNodes) {
                for (const checkTreeNode of nodes[EDGE_INDEX].childNodes) {
                    if (edgeId === checkTreeNode.id) {
                        treeNode = checkTreeNode;
                        break;
                    }
                }
            }
            if (!treeNode) {
                // Edges don't change so we have not update operation
                dispatch({
                    payload: { edge: edge, fromNode: fromNode, toNode: toNode},
                    type: "ADD_EDGE",
                });
            }
        }
    }

    return (
        <div>
            {/*<h1 className="graph-data-header">Graph Data</h1>*/}
            <div className="graph-data-content display-8">
                <div className="mb-4">
                    <span>{STRINGS.runbookEditor.graphData.suggestText}</span>
                </div>
                <Tree contents={nodes} onNodeClick={handleNodeClick} onNodeCollapse={handleNodeCollapse} 
                    onNodeExpand={handleNodeExpand} className={Classes.ELEVATION_0}
                />
            </div>
        </div>
    );
}

/** adds the properties from the node to the specified tree node.
 *  @param graphNode the GraphNode to pull the properties from.
 *  @param treeNode the tree node to put the properties in. 
 *  @param currentPath the path of the parent that this node is being added to.*/
function addPropertiesToNode(graphNode: NodeDef, treeNode: any, currentPath: Array<number>): void {
    treeNode.childNodes = [] as Array<any>;
    
    let pathIndex = 0;
    let id = "";
    let path: Array<number> = [];

    if (graphNode.properties) {
        for (const property of graphNode.properties) {
            if (property.value === null || property.value === undefined || property.value === "") {
                continue;
            }
            id = graphNode.id + "-property-" + property.key;
            path = ([] as Array<number>).concat(currentPath);
            path.push(pathIndex++);
            let valueText = property.value;
            if (typeof valueText === "object") {
                valueText = JSON.stringify(valueText);
            }
            const text = property.key + ": " + valueText;
            const propertyNode = {id: id, key: id, depth: 2, path: path, label: text};
            treeNode.childNodes.push(propertyNode);
        }
    }

    // This only occurs for subflows
    if (graphNode.env) {
        for (const setting of graphNode.env) {
            if (setting.value === null || setting.value === undefined || setting.value === "") {
                continue;
            }
            id = graphNode.id + "-env-" + setting.name;
            path = ([] as Array<number>).concat(currentPath);
            path.push(pathIndex++);
            let valueText = setting.value;
            if (typeof valueText === "object") {
                valueText = JSON.stringify(valueText);
            }
            const text = setting.name + ": " + valueText;
            const settingNode = {id: id, key: id, depth: 2, path: path, label: text};
            treeNode.childNodes.push(settingNode);
        }
    }

    // Add the type for debugging purposes
    id = graphNode.id + "-property-type";
    path = ([] as Array<number>).concat(currentPath);
    path.push(pathIndex++);
    treeNode.childNodes.push({id: id, key: id, depth: 2, path: path, label: "type: " + graphNode.type});

    // Add the id for debugging purposes
    id = graphNode.id + "-property-id";
    path = ([] as Array<number>).concat(currentPath);
    path.push(pathIndex++);
    treeNode.childNodes.push({id: id, key: id, depth: 2, path: path, label: "id: " + graphNode.id});
}
