/** This module contains a view for displaying the decision branch for a mapping configuration.
 *  @module
 */
import { useRef } from "react";
import { type ConditionTree, ConditionTreeBuilder } from "components/common/condition-tree-builder/ConditionTreeBuilder.tsx";
import { ConditionRowOrBlockValue, ConditionRowValue } from "components/common/condition-tree-builder/condition-block/ConditionBlock.tsx";
import { findItemInTree, getValueForItem } from "components/common/condition-tree-builder/condition/ConditionUtils.ts";
import { STRINGS } from "app-strings";
import { scaleMetric } from "reporting-infrastructure/utils/formatters/MetricFormatter.ts";
import { Button, IconName, Menu, MenuItem, Popover } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { 
    DefaultMultiFieldRenderer, NoEditFieldRenderer, NumberFieldRenderer, OptionFieldRenderer 
} from "components/common/condition-tree-builder/condition/condition-value/BuiltinValueFields.tsx";
import { Unit } from "reporting-infrastructure/types/Unit.class.ts";
import { ValueAndUnitWrapper } from "components/common/graph/editors/decision/ValueAndUnitWrapper.tsx";
import { MetricBaselineOperators } from "components/common/graph/editors/LogicOperators.ts";
import { MetricWithAggregatorWrapper } from "components/common/graph/editors/decision/MetricWithAggregatorWrapper.tsx";
import { 
    AGGREAGATION_SEPARATOR, ConditionType, HTTP_PREFIX, KEY_PREFIX, METRIC_PREFIX, OptionSetByType, 
    TRIGGER_GENERIC_METRIC_PREFIX, 
    TRIGGER_METRIC_PREFIX, TRIGGER_PREFIX, VARIABLE_PREFIX, convertExpressionToTreeBuilderFormat, convertTreeBuilderFormatToOrchestratorFormat 
} from "components/hyperion/views/decision-branch/DecisionBranchUtils.ts";
import { CustomProperty } from "pages/create-runbook/views/create-runbook/CustomPropertyTypes.ts";
import { WrapFieldInDisplayMode } from "components/common/condition-tree-builder/WrapFieldInDisplayMode.tsx";

/** this interface defines the properties passed into the mapping configuration decision branch React component. */
export interface DecisionBranchViewProps {
    /** the decision branch expression in RBO format. */
    expression: {
        [x: string]: any;
        type?: string | undefined;
    };
    metadata: {
        metricsAndKeys?: any,
        optionsConfig?: OptionSetByType
    };
    /** the handler for expression changes.  The output is in RBO format. */
    onExpressionChanged: (expression: any) => void;
    /** a boolean value if true  then the view is readonly, if false or missing it is editable. */
    readonly?: boolean;
    /** a string to add a css class name. */
    className?: string;
}

/** Renders the mapping configuration decision branch view.
 *  @param props the properties passed in.
 *  @returns JSX with the mapping configuration decision branch view component.*/
export const DecisionBranchView = (props: DecisionBranchViewProps): JSX.Element =>{
    const metadata = props.metadata;

    // Meta data about the object types, list of metrics and keys.
    const expressionTree = props.expression && convertExpressionToTreeBuilderFormat(props.expression);
    const expressionTreeRef = useRef(expressionTree);

    function onExpressionChanged (expression: ConditionTree) {
        const rboExpression = convertTreeBuilderFormatToOrchestratorFormat(expression);
        props.onExpressionChanged(rboExpression);
    }

    return <ConditionTreeBuilder
        readonly={props.readonly === true}
        conditionTree={expressionTree}
        className={props.className}
        onChange={onExpressionChanged}
        defaultConditionProps={{
            conditionCategory: ConditionType.METRIC,
            conditionKeyProps: {
                categoryFormatter: (category) => {
                    if (category === ConditionType.METRIC) {
                        return STRINGS.runbookEditor.nodeEditor.conditionCategory.metricDisp;
                    } else if (category === ConditionType.KEY) {
                        return STRINGS.runbookEditor.nodeEditor.conditionCategory.keyDisp;
                    } else if (category === ConditionType.COUNTS) {
                        return STRINGS.runbookEditor.nodeEditor.conditionCategory.rowsDisp;
                    } else if (category === ConditionType.INDICATOR_COUNTS) {
                        return STRINGS.runbookEditor.nodeEditor.conditionCategory.indRowsDisp;
                    } else if (category === ConditionType.TRIGGER_KEY) {
                        return STRINGS.runbookEditor.nodeEditor.conditionCategory.triggerDisp;
                    } else if (category === ConditionType.TRIGGER_METRIC) {
                        return STRINGS.runbookEditor.nodeEditor.conditionCategory.triggerMetricDisp;
                    } else if (category === ConditionType.TRIGGER_GENERIC_METRIC) {
                        return STRINGS.runbookEditor.nodeEditor.conditionCategory.triggerGenericMetricDisp;
                    } else if (category === ConditionType.HTTP) {
                        return STRINGS.runbookEditor.nodeEditor.conditionCategory.httpDisp;
                    } else if (category === ConditionType.VARIABLE) {
                        return STRINGS.runbookEditor.nodeEditor.conditionCategory.variableDisp;
                    }
                    else {
                        return category;
                    }
                },
                keyDisplayFormatter: ({ value }) => {
                    if (value !== undefined) {
                        if (typeof value === "string") {
                            return value;
                        } else if (Array.isArray(value)) {
                            return value.join(",");
                        } else if (value.raw?.key?.label && value.raw?.prop?.label) {
                            if (value.raw.key.label === value.raw.prop.label) {
                                return value.raw.key.label;
                            } else {
                                return value.raw.key.label + "." + value.raw.prop.label.replace(value.raw.key.label, "").trim();
                            }
                        } else {
                            return (value as any).display || value;
                        }
                    }
                },
                className: "text-nowrap",
            },
            operationProps: {
                className: "text-nowrap",
            },
            valueProps: {
                displayValueFormatter: ({ value }) => {
                    if (Array.isArray(value)) {
                        return value.join(",");
                    } else if (value === "" || isNaN(value)) { 
                        return value;
                    } else {
                        return scaleMetric(Number(value)).formatted;
                    }
                },
                className: "text-nowrap",
            },
        }}
        defaultConditionBlockProps={{
            addSubConditionRenderer: ({ value: { id, conditions }, update}) => {
                let topLevelControl = true;
                if (id) {
                    // Calculating the depth of the add control being clicked because we want to restrict adding count based
                    // conditions only to the top level (depth 0)
                    const { depth } = findItemInTree(expressionTreeRef.current, id) || {};
                    if (depth) {
                        topLevelControl = false;
                    }
                }
                const options: {
                    item: string,
                    value: Partial<ConditionRowValue>,
                }[] = [                    
                    ...(
                        metadata?.optionsConfig?.inputMetrics?.lhsOptions && metadata.optionsConfig.inputMetrics.lhsOptions.length > 0 ?
                        [{
                            item: STRINGS.runbookEditor.nodeEditor.conditionCategory.metric,
                            value: { category: ConditionType.METRIC }
                        }] : []
                    ),
                    ...(
                        metadata?.optionsConfig?.inputKeys?.lhsOptions && metadata.optionsConfig.inputKeys.lhsOptions.length > 0 ?
                        [{
                            item: STRINGS.runbookEditor.nodeEditor.conditionCategory.key,
                            value: { category: ConditionType.KEY }
                        }] : []
                    ),
                    ...(
                        metadata?.optionsConfig?.inputHttpKeys?.lhsOptions && metadata.optionsConfig.inputHttpKeys.lhsOptions.length > 0 ?
                        [{
                            item: STRINGS.runbookEditor.nodeEditor.conditionCategory.http,
                            value: { category: ConditionType.HTTP }
                        }] : []
                    ),
                    ...(
                        metadata?.optionsConfig?.inputVariables?.lhsOptions && metadata.optionsConfig.inputVariables.lhsOptions.length > 0 ?
                        [{
                            item: STRINGS.runbookEditor.nodeEditor.conditionCategory.variable,
                            value: { category: ConditionType.VARIABLE }
                        }] : []
                    ),
                    ...(
                        (metadata?.optionsConfig?.inputTriggerKeys?.lhsOptions && metadata.optionsConfig.inputTriggerKeys.lhsOptions.length > 0)
                        || metadata?.optionsConfig?.inputTriggerKeys?.userProvidesKey ?
                        [{
                            item: metadata?.optionsConfig?.inputTriggerKeys?.userProvidesKey 
                                ? STRINGS.runbookEditor.nodeEditor.conditionCategory.urlParam
                                : STRINGS.runbookEditor.nodeEditor.conditionCategory.trigger,
                            value: { category: ConditionType.TRIGGER_KEY }
                        }] : []
                    ),
                    ...(
                        metadata?.optionsConfig?.inputTriggerMetrics?.lhsOptions && metadata.optionsConfig.inputTriggerMetrics.lhsOptions.length > 0 ?
                        [{
                            item: STRINGS.runbookEditor.nodeEditor.conditionCategory.triggerMetric,
                            value: { category: ConditionType.TRIGGER_METRIC }
                        }] : []
                    ),
                    ...(
                        metadata?.optionsConfig?.inputTriggerGenericMetrics?.lhsOptions && metadata.optionsConfig.inputTriggerGenericMetrics.lhsOptions.length > 0 ?
                        [{
                            item: STRINGS.runbookEditor.nodeEditor.conditionCategory.triggerGenericMetric,
                            value: { category: ConditionType.TRIGGER_GENERIC_METRIC }
                        }] : []
                    ),
                    ...(
                        topLevelControl && metadata?.optionsConfig?.inputRowCount?.lhsOptions && metadata.optionsConfig.inputRowCount.lhsOptions.length > 0 ? 
                        [{
                            item: STRINGS.runbookEditor.nodeEditor.conditionCategory.rows,
                            value: { category: ConditionType.COUNTS, conditionKey: "count", operation: "GT", value: 10 }
                        }] : []
                    ),
                    ...(
                        topLevelControl && metadata?.optionsConfig?.indicatorRowCount?.lhsOptions && metadata.optionsConfig.indicatorRowCount.lhsOptions.length > 0 ? 
                        [{
                            item: STRINGS.runbookEditor.nodeEditor.conditionCategory.rows,
                            value: { category: ConditionType.INDICATOR_COUNTS, conditionKey: "count", operation: "GT", value: 10 }
                        }] : []
                    ),
                ];
                const onOptionClicked = (value) => {
                    const newConditionToAdd = {
                        ...value,
                        id: "c-" + new Date().getTime(),
                    }
                    const updatedConditions:ConditionRowOrBlockValue[] = [];
                    let reachedPointOfCountConditions = false;
                    // If user is adding a count based condition, then it can be appended to the end.
                    // If user adds a condition of any other type, we should insert it in the end but
                    // right before any count based conditions. This logic is for doing just that.
                    for (const condition of conditions) {
                        if (value.category !== ConditionType.COUNTS && value.category !== ConditionType.INDICATOR_COUNTS &&
                            reachedPointOfCountConditions === false &&
                            ((condition as ConditionRowValue).category === ConditionType.COUNTS || (condition as ConditionRowValue).category === ConditionType.INDICATOR_COUNTS)) {
                                updatedConditions.push(newConditionToAdd);
                                reachedPointOfCountConditions = true;
                        }
                        updatedConditions.push(condition);
                    }
                    if (reachedPointOfCountConditions === false) {
                        updatedConditions.push(newConditionToAdd);
                    }
                    update(updatedConditions);
                }
                return <Popover
                    lazy
                    autoFocus={false}
                    enforceFocus={false}
                    content={<Menu>{
                        options.map(({item, value}, index) => {
                            return <MenuItem
                                key={"option-" + index}
                                text={item}
                                onClick={() => {
                                    onOptionClicked(value);
                                }}
                            />;
                        })
                    }</Menu>}
                >
                    <Button
                        className="add-sub-condition-btn"
                        icon={IconNames.PLUS as IconName}
                        minimal
                        text={expressionTreeRef.current.conditions.length === 0 ? STRINGS.runbookEditor.nodeEditor.addConditionLabel : ""}
                    />
                </Popover>
            }
        }}
            getConditionProps={({ currentProps }) => {
                if (
                    currentProps.conditionCategory === ConditionType.COUNTS || currentProps.conditionCategory === ConditionType.INDICATOR_COUNTS
                ) {
                    const lhsOptions = metadata?.optionsConfig?.inputRowCount?.lhsOptions || metadata?.optionsConfig?.indicatorRowCount?.lhsOptions;
                    return {
                        ...currentProps,
                        operation: currentProps.operation,
                        operationList: metadata?.optionsConfig?.inputRowCount?.operators || metadata?.optionsConfig?.indicatorRowCount?.operators,
                        conditionKeyList: lhsOptions,
                        conditionKey: currentProps.conditionKey || (lhsOptions?.length === 1 ? lhsOptions[0] : undefined),
                        valueProps: {
                            fieldRenderer: NumberFieldRenderer,
                        },
                        cannotBeNested: false,
                    };
                } else if (
                    currentProps.conditionCategory === ConditionType.METRIC || currentProps.conditionCategory === ConditionType.TRIGGER_METRIC
                ) {
                    let lhsOptions: Array<any> = [];
                    let operations: Array<any> = [];
                    let triggerMetrics: Array<{value: string, label: string} | string> = [];
                    let excludedTriggerMetrics: Array<{value: string, label: string}> = [];
                    let metricPrefix: string = METRIC_PREFIX;

                    if (currentProps.conditionCategory === ConditionType.METRIC) {
                        lhsOptions = metadata?.optionsConfig?.inputMetrics?.lhsOptions || [];
                        operations = metadata?.optionsConfig?.inputMetrics?.operators || [];
                        triggerMetrics = metadata?.optionsConfig?.inputMetrics?.metrics || [];
                    } else {
                        lhsOptions = metadata?.optionsConfig?.inputTriggerMetrics?.lhsOptions || [];
                        operations = metadata?.optionsConfig?.inputTriggerMetrics?.operators || [];
                        triggerMetrics = metadata?.optionsConfig?.inputTriggerMetrics?.triggerMetrics || [];
                        excludedTriggerMetrics = metadata?.optionsConfig?.inputTriggerMetrics?.excludedTriggerMetrics || [];
                        metricPrefix = TRIGGER_METRIC_PREFIX;
                        if (
                            excludedTriggerMetrics && lhsOptions && currentProps.conditionKey && 
                            lhsOptions?.find((item) => item.value === currentProps.conditionKey) === undefined
                        ) {
                            lhsOptions = [...lhsOptions];
                            excludedTriggerMetrics.forEach((metric) => {
                                if ((metricPrefix + "" + metric.value) === currentProps.conditionKey) {
                                    lhsOptions?.push({display: metric.label, value: currentProps.conditionKey});
                                }
                            });
                        }    
                    }
                    triggerMetrics = triggerMetrics.map(metricOption => (metricOption as {value: string, label: string}).value);

                    return {
                        ...currentProps,
                        operation: currentProps.operation || (operations && operations.length > 0 && operations[0]),
                        operationList: operations,
                        conditionKeyList: lhsOptions,
                        conditionKey: currentProps.conditionKey || (lhsOptions?.length === 1 ? lhsOptions[0] : undefined),
                        getOperationFieldProps: ({condition, currentProps}) => {
                            const conditionKey = condition.conditionKey && getValueForItem(condition.conditionKey);
                            if (conditionKey) {
                                const [metric] = conditionKey.replace(metricPrefix, "").split(AGGREAGATION_SEPARATOR);
                                let filteredOperations: Array<any> = [...operations];
                                filteredOperations = filteredOperations.filter(operation => {
                                    if (operation.raw.triggerMetricReqd && !triggerMetrics.includes(metric)) {
                                        return false;
                                    }
                                    return true;
                                });
                                return {
                                    ...currentProps,
                                    displayValueFormatter: ({ value }) => {
                                        if (value && (value as any).value === MetricBaselineOperators.normal?.alt) {
                                            return STRINGS.runbookEditor.operators.normalTerse;
                                        } else if (value && (value as any).value === MetricBaselineOperators.nnormal?.alt) {
                                            return STRINGS.runbookEditor.operators.nnormalTerse;
                                        } else if (value && (value as any).value === MetricBaselineOperators.expectedhighgt?.alt) {
                                            return STRINGS.runbookEditor.operators.expectedhighgtTerse;
                                        } else if (value && (value as any).value === MetricBaselineOperators.expectedlowlt?.alt) {
                                            return STRINGS.runbookEditor.operators.expectedlowltTerse;
                                        } else {
                                            return (value as any)?.display;
                                        }
                                    },
                                    options: filteredOperations
                                };
                            }
                            return {...currentProps};
                        },
                        getKeyFieldProps: ({ currentProps }) => {
                            return {
                                ...currentProps,
                                customRenderer: ({ value, onChange }) => {
                                    const [metric, aggregator] = (getValueForItem(value || "")).split(AGGREAGATION_SEPARATOR);
                                    return <MetricWithAggregatorWrapper
                                        metric={metric}
                                        aggregator={aggregator}
                                        metrics={lhsOptions}
                                        onChange={({ metric, aggregator }) => {
                                            if (onChange) {
                                                onChange(metric + (aggregator ? (AGGREAGATION_SEPARATOR + aggregator) : ""));
                                            }
                                        }}
                                    />
                                },
                            }
                        },
                        getValueFieldProps: ({ condition, currentProps }) => {
                            const conditionKey = condition.conditionKey && getValueForItem(condition.conditionKey);
                            if (conditionKey) {
                                const [metric] = conditionKey.replace(metricPrefix, "").split(AGGREAGATION_SEPARATOR);
                                let metricDefinition = metadata?.optionsConfig?.inputMetrics?.lhsOptions.map((metricData) => metricData.raw).find((metricDef) => metricDef.id === metric);
                                if (!metricDefinition) {
                                    // If the metric definition is not in the options, try and get it from the data ocean
                                    metricDefinition = metadata?.metricsAndKeys?.metrics[metric];
                                }
                                const operation = operations?.find(op => op.value === condition.operation);
                                /*
                                if (
                                    operation?.value === MetricBaselineOperators.expectedlowlt?.alt || 
                                    operation?.value === MetricBaselineOperators.expectedlowgt?.alt || 
                                    operation?.value === MetricBaselineOperators.expectedhighlt?.alt || 
                                    operation?.value === MetricBaselineOperators.expectedhighgt?.alt 
                                ) {
                                    let options: Array<{label: string, value: string}> = [{value: "", label: "Select An Option"}];
                                    options = options.concat([{value: "true", label: "True"}, {value: "false", label: "False"}]);
                                    return {
                                        ...currentProps,
                                        displayValueFormatter: ({ value }) => {
                                            const option = options.find((option) => option.value === value);
                                            const label = option ? option.label : "Select An Option";
                                            return label;
                                        },
                                        fieldRenderer: ({value, onChange}) => {
                                            return <OptionFieldRenderer value={value} onChange={onChange} options={options} />
                                        }
                                    };
                                } else 
                                */
                                if (metricDefinition !== undefined || operation?.raw?.comparisonReqd) {
                                    // If this is comprison value related operation, then the value is in percentage increase, decrease or change
                                    const unit = Unit.parseUnit(operation?.raw?.comparisonReqd ? "%" : metricDefinition.unit);
                                    let unitOptions= [];
                                    if(unit && unit.unit && unit.isScalable()) {
                                        unitOptions = unit.getScaledUnitOptions();
                                    }
                                    return {
                                        ...currentProps,
                                        displayValueFormatter: ({ value }) => {
                                            if (
                                                operation?.value === MetricBaselineOperators.normal?.alt || operation?.value === MetricBaselineOperators.nnormal?.alt ||
                                                operation?.value === MetricBaselineOperators.expectedhighgt?.alt || operation?.value === MetricBaselineOperators.expectedhighlt?.alt ||
                                                operation?.value === MetricBaselineOperators.expectedlowgt?.alt || operation?.value === MetricBaselineOperators.expectedlowlt?.alt
                                            ) {
                                                return STRINGS.runbookEditor.values.normal;
                                            } else if (value === null || value === "" || isNaN(Number(value))) {
                                                return value;
                                            } else {
                                                return scaleMetric(Number(value), unit).formatted;
                                            }
                                        },
                                        fieldRenderer: ({
                                            onChange,
                                            value,
                                        }) => {
                                            if (
                                                operation?.value === MetricBaselineOperators.normal?.alt || operation?.value === MetricBaselineOperators.nnormal?.alt ||
                                                operation?.value === MetricBaselineOperators.expectedhighgt?.alt || operation?.value === MetricBaselineOperators.expectedhighlt?.alt ||
                                                operation?.value === MetricBaselineOperators.expectedlowgt?.alt || operation?.value === MetricBaselineOperators.expectedlowlt?.alt
                                            ) {
                                                return <NoEditFieldRenderer value={value} onChange={onChange} label={STRINGS.runbookEditor.values.normal} />
                                            } else {
                                                const baseUnit = unit;
                                                const scaledMetric = scaleMetric(Number(value), baseUnit);
                                                return <ValueAndUnitWrapper
                                                    value={value ? scaledMetric.value : value}
                                                    unit={scaledMetric.unit}
                                                    unitOptions={unitOptions}
                                                    onChange={({ value, unit }) => {
                                                        const targetUnit = Unit.parseUnit(unit);
                                                        if (baseUnit && targetUnit) {
                                                            const convertedValue = targetUnit.convert(value, baseUnit);
                                                            onChange({ value: convertedValue });
                                                        } else {
                                                            onChange({ value });
                                                        }
                                                    }}
                                                />;
                                            }
                                        },
                                    };
                                } else {
                                    return currentProps;
                                }
                            } else {
                                return currentProps;
                            }
                        }
                    };
                } else if (
                    currentProps.conditionCategory === ConditionType.KEY || 
                    currentProps.conditionCategory === ConditionType.HTTP ||
                    currentProps.conditionCategory === ConditionType.VARIABLE ||
                    currentProps.conditionCategory === ConditionType.TRIGGER_KEY
                ) {
                    let lhsOptions: Array<any> | undefined = [];
                    let operations: Array<any> = [];
                    let metricPrefix: string = KEY_PREFIX;
                    const triggerMetrics = metadata?.optionsConfig?.inputTriggerKeys?.triggerMetrics;
                    let triggerEntityTypes: Array<{value: string, label: string}> = metadata?.optionsConfig?.inputTriggerKeys?.triggerEntityTypes || [];
                    let analysisTypes: Array<{value: string, label: string}> = metadata?.optionsConfig?.inputTriggerKeys?.analysisTypes || [];
                    let customProperties: CustomProperty[] = metadata?.optionsConfig?.inputKeys?.customProperties || [];
                    let dataSources: any[] = metadata?.optionsConfig?.inputKeys?.dataSources || [];

                    const conditionCategory = currentProps.conditionCategory;
                    if (currentProps.conditionCategory === ConditionType.KEY) {
                        lhsOptions = metadata?.optionsConfig?.inputKeys?.lhsOptions || [];
                        operations = metadata?.optionsConfig?.inputKeys?.operators || [];
                        metricPrefix = KEY_PREFIX;
                    } else if (currentProps.conditionCategory === ConditionType.VARIABLE) {
                        lhsOptions = metadata?.optionsConfig?.inputVariables?.lhsOptions || [];
                        operations = metadata?.optionsConfig?.inputVariables?.operators || [];
                        metricPrefix = VARIABLE_PREFIX;
                    } else if (currentProps.conditionCategory === ConditionType.HTTP) {
                        lhsOptions = metadata?.optionsConfig?.inputHttpKeys?.lhsOptions || [];
                        operations = metadata?.optionsConfig?.inputHttpKeys?.operators || [];
                        metricPrefix = HTTP_PREFIX;
                    } else if (currentProps.conditionCategory === ConditionType.TRIGGER_KEY) {
                        lhsOptions = metadata?.optionsConfig?.inputTriggerKeys?.lhsOptions || [];
                        operations = metadata?.optionsConfig?.inputTriggerKeys?.operators || [];
                        metricPrefix = TRIGGER_PREFIX;
                        if (metadata?.optionsConfig?.inputTriggerKeys.userProvidesKey) {
                            lhsOptions = undefined;
                        }
                    }
                    return {
                        ...currentProps,
                        conditionKeyProps: {
                            ...currentProps.conditionKeyProps,
                            categoryFormatter: (category) => {
                                if (category === ConditionType.METRIC) {
                                    return STRINGS.runbookEditor.nodeEditor.conditionCategory.metricDisp;
                                } else if (category === ConditionType.KEY) {
                                    return STRINGS.runbookEditor.nodeEditor.conditionCategory.keyDisp;
                                } else if (category === ConditionType.COUNTS) {
                                    return STRINGS.runbookEditor.nodeEditor.conditionCategory.rowsDisp;
                                } else if (category === ConditionType.INDICATOR_COUNTS) {
                                    return STRINGS.runbookEditor.nodeEditor.conditionCategory.indRowsDisp;
                                } else if (category === ConditionType.TRIGGER_KEY) {
                                    return metadata?.optionsConfig?.inputTriggerKeys?.userProvidesKey 
                                        ? STRINGS.runbookEditor.nodeEditor.conditionCategory.urlParamDisp
                                        : STRINGS.runbookEditor.nodeEditor.conditionCategory.triggerDisp;
                                } else if (category === ConditionType.TRIGGER_METRIC) {
                                    return STRINGS.runbookEditor.nodeEditor.conditionCategory.triggerMetricDisp;
                                } else if (category === ConditionType.TRIGGER_GENERIC_METRIC) {
                                    return STRINGS.runbookEditor.nodeEditor.conditionCategory.triggerGenericMetricDisp;
                                } else if (category === ConditionType.HTTP) {
                                    return STRINGS.runbookEditor.nodeEditor.conditionCategory.httpDisp;
                                } else if (category === ConditionType.VARIABLE) {
                                    return STRINGS.runbookEditor.nodeEditor.conditionCategory.variableDisp;
                                }
                                else {
                                    return category;
                                }
                            }            
                        },
                        getKeyFieldProps: ({ currentProps }) => {
                            // For the webhook URL parameters we want to allow the user to enter the key in a text box
                            // the user does not know the prefix that RBO needs so add a custom renderer for the key and 
                            // add the prefix when the value changes and remove the prefix when rendering the text.
                            const newProps = {...currentProps} as any;
                            if (lhsOptions === undefined) {
                                newProps.customRenderer = ({ value, onChange }) => {
                                    const prefix = TRIGGER_PREFIX + "requestQueryParameters.";
                                    const valueWithoutPrefix = getValueForItem(value || "").replace(prefix, "");
                                    return <WrapFieldInDisplayMode
                                        value={valueWithoutPrefix}
                                        onChange={(newValue) => {
                                            if (onChange) {
                                                onChange(prefix + newValue);
                                            }
                                        }}
                                        displayValueFormatter={undefined}
                                    />;
                                }
                            }
                            return newProps;
                        },
                        operation: currentProps.operation || (operations && operations.length > 0 && operations[0]),
                        operationList: operations,
                        conditionKeyList: lhsOptions,
                        conditionKey: currentProps.conditionKey || (lhsOptions?.length === 1 ? lhsOptions[0] : undefined),
                        getOperationFieldProps: ({condition, currentProps}) => {
                            let conditionKey = condition.conditionKey && getValueForItem(condition.conditionKey);
                            conditionKey = conditionKey?.replace(metricPrefix, "");
                            if (conditionKey) {
                                let type = "string";
                                lhsOptions?.forEach(option => {
                                    if (option.raw.id === conditionKey) {
                                        type = option.raw.type;
                                    }
                                });
                                let filteredOperations: Array<any> = [...operations];
                                filteredOperations = filteredOperations.filter(operation => {
                                    if (!operation.raw.types.includes(type)) {
                                        return false;
                                    }
                                    if (
                                        operation.raw.excludedConditionTypes && 
                                        operation.raw.excludedConditionTypes.includes(conditionCategory)
                                    ) {
                                        return false;
                                    }
                                    if (conditionKey === "data_source.id" && !["seq", "sneq", "exist", "nexist"].includes(operation.raw.id)) {
                                        return false;
                                    }
                                    return true;
                                });
                                return {
                                    ...currentProps,
                                    options: filteredOperations
                                };
                            }
                            return {...currentProps};
                        },
                        getValueFieldProps: ({ condition, currentProps }) => {
                            let conditionKey = condition.conditionKey && getValueForItem(condition.conditionKey);
                            conditionKey = conditionKey?.replace(metricPrefix, "");
                            const currentOperation = operations?.find(op => getValueForItem(op) === condition.operation);
                            const multiInput = Boolean(currentOperation?.raw?.multiInput);
                            if (multiInput) {
                                return {
                                    ...currentProps,
                                    fieldRenderer: (props) => {
                                        const finalRenderer = currentProps.fieldRenderer || DefaultMultiFieldRenderer;
                                        return <div>
                                            {finalRenderer(props)}
                                            <div className="display-10 text-center">{STRINGS.runbookEditor.nodeEditor.multipleValuesHint}</div>
                                        </div>;
                                    }
                                }
                            } else if (conditionKey) {
                                const operation = operations?.find(op => op.value === condition.operation);
                                const metric = conditionKey.replace(metricPrefix, "");
                                const keysWithOptions = [
                                    "kind", "primaryIndicator.data.indicator.kind", "primaryIndicator.data.indicator.metric",
                                    "location.type", "runbookStatus", "status", "oldStatus"
                                ];
                                let type = "string";
                                let unitText = "";
                                lhsOptions?.forEach(option => {
                                    if (option.raw.id === conditionKey) {
                                        type = option.raw.type;
                                        unitText = option.raw.unit || "";
                                    }
                                });

                                if (
                                    (
                                        (keysWithOptions.includes(metric) || metric.includes("custom.")) && 
                                        (operation?.value === "EQ" || operation?.value === "NOT-EQ")
                                    ) || 
                                    operation?.value === "EXISTS" || operation?.value === "NOT-EXIST"
                                ) {
                                    let options: Array<{label: string, value: string}> = [{value: "", label: "Select An Option"}];
                                    if (operation.value === "EQ" || operation.value === "NOT-EQ") {
                                        switch (metric) {
                                            case "kind":
                                                options = options.concat(triggerEntityTypes || []);
                                                break;
                                            case "primaryIndicator.data.indicator.kind":
                                                options = options.concat(analysisTypes || []);
                                                break;
                                            case "primaryIndicator.data.indicator.metric":
                                                options = options.concat(triggerMetrics || []);
                                                break;
                                            case "location.type":
                                                options = options.concat([
                                                    {value: "physical", label: "Physical"}, 
                                                    {value: "business", label: "Business"}
                                                ]);
                                                break;
                                            case "runbookStatus":
                                                options = options.concat([
                                                    //{value: "InProgress", label: "In Progress"}, 
                                                    {value: "Succeeded", label: "Succeeded"},
                                                    {value: "SucceededWithErrors", label: "Succeeded With Errors"},
                                                    {value: "Failed", label: "Failed"},
                                                ]);
                                                break;
                                            case "status":
                                            case "oldStatus":
                                                options = options.concat([
                                                    {value: "New", label: "New"}, 
                                                    {value: "Investigating", label: "Investigating"}, 
                                                    {value: "Closed", label: "Closed"}
                                                ]);
                                                break;
                                        }
                                        const propId = conditionKey.replace(metricPrefix, "");
                                        if (propId.includes("custom.") && propId.endsWith(".id")) {
                                            const id = propId.substring(propId.indexOf("custom.") + 7, propId.indexOf(".id"));
                                            for (const property of customProperties) {
                                                if (property.id === id) {
                                                    const values = property.values;
                                                    if (values) {
                                                        for (const value of values) {
                                                            options.push({value: value.id, label: value.name});
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    } else if (operation.value === "EXISTS" || operation.value === "NOT-EXIST") {
                                        options = options.concat([{value: "true", label: "True"}, {value: "false", label: "False"}]);
                                    }
                                    return {
                                        ...currentProps,
                                        displayValueFormatter: ({ value }) => {
                                            const option = options.find((option) => option.value === value);
                                            //const label = option ? option.label : options?.length ? options[0].label : "Unkown";
                                            const label = option ? option.label : "Select An Option";
                                            return label;
                                        },
                                        fieldRenderer: ({value, onChange}) => {
                                            return <OptionFieldRenderer value={value} onChange={onChange} options={options} />
                                        }
                                    };
                                } else if (metric === "data_source.id") {
                                    let options: Array<{label: string, value: string}> = [{value: "", label: "Select An Option"}];
                                    options = options.concat(dataSources.map((datasource) => {return {value: datasource.id, label: datasource.name};}));
                                    return {
                                        ...currentProps,
                                        displayValueFormatter: ({ value }) => {
                                            const option = options.find((option) => option.value === value);
                                            //const label = option ? option.label : options?.length ? options[0].label : "Unkown";
                                            const label = option ? option.label : "Select An Option";
                                            return label;
                                        },
                                        fieldRenderer: ({value, onChange}) => {
                                            return <OptionFieldRenderer value={value} onChange={onChange} options={options} />
                                        }
                                    };
                                } else if (
                                    type === "boolean" 
                                    // if we want to support both cases at some point
                                    //|| type === "Boolean"
                                ) {
                                    let options: Array<{label: string, value: string}> = [{value: "", label: "Select An Option"}];
                                    options = options.concat([{value: "true", label: "True"}, {value: "false", label: "False"}]);
                                    return {
                                        ...currentProps,
                                        displayValueFormatter: ({ value }) => {
                                            const option = options.find((option) => option.value === value);
                                            //const label = option ? option.label : options?.length ? options[0].label : "Unkown";
                                            const label = option ? option.label : "Select An Option";
                                            return label;
                                        },
                                        fieldRenderer: ({value, onChange}) => {
                                            return <OptionFieldRenderer value={value} onChange={onChange} options={options} />
                                        }
                                    };
                                } else if (type === "float" || type === "integer") {
                                    const unit = Unit.parseUnit(unitText);
                                    let unitOptions= [];
                                    if(unit && unit.unit && unit.isScalable()) {
                                        unitOptions = unit.getScaledUnitOptions();
                                    }
                                    return {
                                        ...currentProps,
                                        displayValueFormatter: ({ value }) => {
                                            if (value === null || value === "" || isNaN(Number(value))) {
                                                // return an empty string if it is not a number
                                                return isNaN(value) ? "" : value;
                                            } else {
                                                return scaleMetric(Number(value), unit).formatted;
                                            }
                                        },
                                        fieldRenderer: ({onChange, value}) => {
                                            const baseUnit = unit;
                                            const scaledMetric = scaleMetric(Number(value), baseUnit);
                                            return <ValueAndUnitWrapper
                                                value={value ? scaledMetric.value : value}
                                                unit={scaledMetric.unit}
                                                unitOptions={unitOptions}
                                                onChange={({ value, unit }) => {
                                                    const targetUnit = Unit.parseUnit(unit);
                                                    if (baseUnit && targetUnit) {
                                                        const convertedValue = targetUnit.convert(value, baseUnit);
                                                        onChange({ value: convertedValue });
                                                    } else {
                                                        onChange({ value });
                                                    }
                                                }}
                                            />;
                                        },
                                    };
                                } else if (type === "string") {
                                    return {
                                        ...currentProps,
                                        displayValueFormatter: ({ value }) => {
                                            // Don't try to format strings as numbers, the default formatter does that
                                            return value;
                                        }
                                    };        
                                }
                            }
                            return currentProps;
                        }
                    };
                } else if (currentProps.conditionCategory === ConditionType.TRIGGER_GENERIC_METRIC) {
                    const lhsOptions = metadata?.optionsConfig?.inputTriggerGenericMetrics?.lhsOptions;
                    const operations = metadata?.optionsConfig?.inputTriggerGenericMetrics?.operators;
                    let triggerMetrics = metadata?.optionsConfig?.inputTriggerGenericMetrics?.triggerMetrics;
                    let excludedTriggerMetrics = metadata?.optionsConfig?.inputTriggerGenericMetrics?.excludedTriggerMetrics;
                    if (
                        excludedTriggerMetrics && triggerMetrics && currentProps.value && 
                        triggerMetrics?.find((item) => item.value === currentProps.value) === undefined
                    ) {
                        const excludedMetric = excludedTriggerMetrics?.find((item) => item.value === currentProps.value);
                        if (excludedMetric) {
                            triggerMetrics = [...triggerMetrics];
                            triggerMetrics?.push({label: excludedMetric.label, value: excludedMetric.value});
                        }
                    }
                    let triggerEntityTypes: Array<{value: string, label: string}> = metadata?.optionsConfig?.inputTriggerGenericMetrics?.triggerEntityTypes || [];
                    let analysisTypes: Array<{value: string, label: string}> = metadata?.optionsConfig?.inputTriggerGenericMetrics?.analysisTypes || [];
                    return {
                        ...currentProps,
                        operation: currentProps.operation || (operations && operations.length > 0 && operations[0]),
                        operationList: operations,
                        conditionKeyList: lhsOptions,
                        conditionKey: currentProps.conditionKey || (lhsOptions?.length === 1 ? lhsOptions[0] : undefined),
                        getValueFieldProps: ({ condition, currentProps }) => {
                            const conditionKey = condition.conditionKey && getValueForItem(condition.conditionKey);
                            if (conditionKey) {
                                const operation = operations?.find(op => op.value === condition.operation);
                                if (operation?.value === "EQ" || operation?.value === "NOT-EQ" || operation?.value === "EXISTS" || operation?.value === "NOT-EXIST") {
                                    let options: Array<{label: string, value: string}> = [{value: "", label: "Select An Option"}];
                                    const metric = conditionKey.replace(TRIGGER_GENERIC_METRIC_PREFIX, "");
                                    if (operation.value === "EQ" || operation.value === "NOT-EQ") {
                                        switch (metric) {
                                            case "$INDICATOR.KEY.metric":
                                                options = options.concat(triggerMetrics || []);
                                                break;
                                            case "$INDICATOR.KEY.kind":
                                                options = options.concat(analysisTypes || []);
                                                break;
                                            case "$ENTITY.KEY.kind":
                                                options = options.concat(triggerEntityTypes || []);
                                                break;
                                        }
                                    } else if (operation.value === "EXISTS" || operation.value === "NOT-EXIST") {
                                        options = options.concat([{value: "true", label: "True"}, {value: "false", label: "False"}]);
                                    }
                                    return {
                                        ...currentProps,
                                        displayValueFormatter: ({ value }) => {
                                            const option = options.find((option) => option.value === value);
                                            //const label = option ? option.label : options?.length ? options[0].label : "Unkown";
                                            const label = option ? option.label : "Select An Option";
                                            return label;
                                        },
                                        fieldRenderer: ({value, onChange}) => {
                                            return <OptionFieldRenderer value={value} onChange={onChange} options={options} />
                                        }
                                    };
                                } else if (operation?.value === "NORMAL" || operation?.value === "NOT-NORMAL") {
                                    return {
                                        ...currentProps,
                                        displayValueFormatter: ({ value }) => {
                                            return STRINGS.runbookEditor.values.normal;
                                        },
                                        fieldRenderer: ({value, onChange}) => {
                                            return <NoEditFieldRenderer value={value} onChange={onChange} label={STRINGS.runbookEditor.values.normal} />
                                        }
                                    };
                                }
                            }
                            return currentProps;
                        }
                    };
                } else {
                    return currentProps;
                }
            }}
    />;
};


