/** This module contains utilities for editing on demand input nodes and validating on-demand input nodes.
 *  @module
 */

import { STRINGS } from "app-strings";
import { GraphDef, NodeDef } from "components/common/graph/types/GraphTypes.ts";
import { GenericKey, NodeUtils } from "utils/runbooks/NodeUtil.ts";
import { getProperties } from "utils/runbooks/RunbookUtils.ts";
import { OUTPUT_ELEMENTS_LIMIT } from "components/common/graph/editors/transform/OutputDataBlock.tsx";
import { VariableContextByScope } from "utils/runbooks/RunbookContext.class.ts";
import { DataOceanUtils } from "components/common/graph/editors/data-ocean/DataOceanUtils.ts";
import { DataOceanKey } from "components/common/graph/editors/data-ocean/DataOceanMetadata.type.ts";
import { KeyDefinition } from "components/common/graph/editors/transform/OutputDataElement.tsx";

export type KeysMetadata = {
    /** the keys that are supported by the Data Ocean. */
    keys: Record<string, DataOceanKey>;
}

/** an enum with all of the valid on demand input node properties. */
export enum ON_DEMAND_INPUT_NODE_EDIT_PROPS {
    TRIGGER_TYPE        = "triggerType",
    /** the output data format property. */
    OUTPUT_DATA_FORMAT  = "outputDataFormat",
    /** the synthetic metrics property. */
    SYNTH_METRICS       = "synthMetrics",
    /** the synthetic keys property. */
    SYNTH_KEYS          = "synthKeys",
    /** the incoming variables. */
    INCOMING_VARIABLES  = "inputVariables",
    /** debug property */
    DEBUG               = 'debug',
}

/** this interface defines a on demand input key, which extends a generic key and adds the 
 *  additional attributes needed by the on demand input input node.  This basically matches the 
 *  transform node so we can think of unifying everywhere we define this. */
export interface SubflowKey extends GenericKey {
    /** the data ocean id if this key is tied to a data ocean key.  If it is not tied to the data ocean then
     *  this will be undefined. */
    dataOceanId?: string;
    /** if this key is tied to a data ocean key, this property contains the expanded list of data ocean 
     *  subkeys that are in the data ocean meta data. */
    dataOceanKeys?: Array<GenericKey>;
    /** a boolean value, true if this is a required key, false otherwise. */
    required?: boolean;
    /** a boolean value, true if this is a synthetic key, false otherwise. */
    synthetic?: boolean;
}

/** Utility class for on demand input node,*/
export class OnDemandInputNodeUtils extends NodeUtils {
    /** the error messages for the transform node from the STRINGS file. */
    static errMsgs = STRINGS.runbookEditor.errors.onDemandInputNode;
    
    /** Check if transform node is valid. Validates in the context of other nodes in the graph
     *  @param nodeId - node identifier
     *  @param graphDef - graph with info on all the nodes.
     *  @returns  is node valid. */
    static isNodeValid(nodeId: string | undefined | null, graphDef: GraphDef): boolean {
        if (!nodeId) {
            console.error(" isNodeValid: nodeId is undefined ");
            return false;
        }
        const errors = [];
        this.validateNode(nodeId, errors, graphDef)
        return errors.length === 0;
    }

    /** Check if a transform node is valid. Validates in the context of other nodes in the graph.
     *       Populates the errors.
     *  @param nodeId - node identifier
     *  @param errors - IN-OUT argument the array his populated with error messages. Empty array if
     *       there are no errors
     *  @param graphDef - graph with info on all the nodes.
     *  @param variables the map of variables by scope. */
    static validateNode(nodeId: string, errors: string[], graphDef: GraphDef, variables?: VariableContextByScope): void {
        let curNode = graphDef.nodes.find((n) => {
            return nodeId === n.id
        });
        if (!curNode) {
            return;
        }
        // no-op currenty.
        super.validateNode(nodeId, errors, graphDef);
        OnDemandInputNodeUtils.validateNodePropertiesFromGraphDef(curNode, getProperties(curNode), errors, curNode?.name, variables);
    }

    /** Validates the property values for a given transform node
     *  @param selectedNode the UniversalNode that wraps the react-flow node.
     *  @param currentProperties the key/value pairs with the node properties.
     *  @param errors the array of strings with any errors that have been encountered.
     *  @param label a string with the node label.
     *  @param variables the map of variables by scope.
     *  @returns an array of strings with all the errors that were found. */
    static validateNodePropertiesFromGraphDef(
        selectedNode: NodeDef | undefined, currentProperties: Record<string, any>, errors: Array<string>, label: string,
        variables?: VariableContextByScope
    ): Array<string> {
        if (!currentProperties.outputDataFormat) {
            errors.push(OnDemandInputNodeUtils.errMsgs.outputDataFormat);
        }

        /* Damien would like it to be pssible to have no inputs
        if (currentProperties.synthKeys?.length === 0) {
            errors.push(OnDemandInputNodeUtils.errMsgs.oneKeyOrMetric);
        }
        */
        
        if (currentProperties.synthKeys?.length > OUTPUT_ELEMENTS_LIMIT) {
            errors.push(OnDemandInputNodeUtils.errMsgs.tooManyKeysOrMetrics);
        }
        if (currentProperties.synthKeys?.length > 0) {
            const error = OnDemandInputNodeUtils.validateNewLabelsNotInDataOceanLabels(currentProperties.synthKeys);
            if (error) {
                errors.push(error);
            }
        }

        return errors;
    }

    /** Validates the property values for a given transform node
     *  @param currentProperties the key/value pairs with the node properties.
     *  @param errors the array of strings with any errors that have been encountered.
     *  @param label a string with the node label.
     *  @returns an array of strings with all the errors that were found. */
    static validateNodeProperties(
        currentProperties: Record<string, any>, errors: Array<string>
    ): Array<string> {
        if (!currentProperties.outputDataFormat) {
            errors.push(OnDemandInputNodeUtils.errMsgs.outputDataFormat);
        }

        /* Damien would like it to be pssible to have no inputs
        if (currentProperties.outputDataProperties?.length === 0) {
            errors.push(OnDemandInputNodeUtils.errMsgs.oneKeyOrMetric);
        }
        */

        if (currentProperties.outputDataProperties?.length > OUTPUT_ELEMENTS_LIMIT) {
            errors.push(OnDemandInputNodeUtils.errMsgs.tooManyKeysOrMetrics);
        }
        if (currentProperties.outputDataProperties?.length > 0) {
            const error = OnDemandInputNodeUtils.validateNewLabelsNotInDataOceanLabels(currentProperties.outputDataProperties);
            if (error) {
                errors.push(error);
            }
        }

        return errors;
    }

    /** Validates that the new added labels for keys or metrics do not match any existent data ocean label
     *  @param properties the output data keys configured for node
     *  @returns an error message if applicable. */
    static validateNewLabelsNotInDataOceanLabels(properties) {
        const allLabels = OnDemandInputNodeUtils.getAllDataOceanLabels();
        for (const data of properties) {
            if (!data.dataOceanId) {
                if (allLabels.includes(data.label)) {
                    return OnDemandInputNodeUtils.errMsgs.labelInDataOcean;
                }
            }
        }
    }

    /** Function to get a list with all the labels existend in data ocean metadata
     *  @returns a list of strings with all data ocean labels. */
    static getAllDataOceanLabels() {
        const objMetaData = DataOceanUtils.dataOceanMetaData;
        var dataOceanLabels: Array<string> = [];
        for (const element in objMetaData.keys) {
            dataOceanLabels.push(objMetaData.keys[element].label);
            const objKeys = objMetaData.keys as Object;
            let nestedElements = OnDemandInputNodeUtils.getCompleteExpandedKeys(objKeys as KeysMetadata, element);
            for (const nestedElement of nestedElements) {
                dataOceanLabels.push(nestedElement.label);
            }
        }
        return dataOceanLabels;
    }
    
    /** Creates a key of the form network_host.ipaddr and network_host.location.name from the data ocean
     *      properties for the key.  Once the key is generated, the column def is created.  This function
     *      is called recursively.
     *  @param objMetricMetaData the JSON with the Data Ocean API details
     *  @param inputType the object type that is currently being displayed in the logic node.
     *  @returns an array with all the key definitions. */
    static getCompleteExpandedKeys(objMetricMetaData: KeysMetadata, inputType: string): Array<KeyDefinition> {
        const expandedKeys: Array<KeyDefinition> = [];
        if (objMetricMetaData && inputType) {
                const keyDef = objMetricMetaData[inputType];
                if (keyDef?.type === "object") {
                    OnDemandInputNodeUtils.expandCompleteKeyProperties(keyDef, inputType, expandedKeys);
                } else {
                    expandedKeys.push({
                        "label": objMetricMetaData[inputType]?.label,
                        "id": inputType,
                        "type": objMetricMetaData[inputType]?.type,
                        "unit": objMetricMetaData[inputType]?.unit ? objMetricMetaData[inputType].unit : "none",
                        "required": objMetricMetaData[inputType]?.primary_key ? objMetricMetaData[inputType].primary_key : false
                    });
                }
        }
        return expandedKeys;
    }

    /** Creates a key of the form network_host.ipaddr and network_host.location.name from the data ocean
     *      properties for the key.  Once the key is generated, the column def is created.  This function
     *      is called recursively.
     *  @param keyDef the Data Ocean key definition or one of the property definitions.
     *  @param parents a string with the parent information that should be appended to the key id.
     *  @param expandedKeys an array with all the keys in it for example {id: network_server.ipaddr, label: "Host", type: "string"}. */
    static expandCompleteKeyProperties(keyDef: DataOceanKey, parents: string, expandedKeys: Array<KeyDefinition>): void {
        if (!keyDef.properties) {
            expandedKeys.push({ id: parents, label: keyDef.label, type: keyDef.type, unit: keyDef.unit ? keyDef.unit : "none", required: keyDef.primary_key ? keyDef.primary_key : false});
        } else {
            for (const property in keyDef.properties) {
                OnDemandInputNodeUtils.expandCompleteKeyProperties(keyDef.properties[property], parents + "." + property, expandedKeys);
            }
        }
    }
}
