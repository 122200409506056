/** This module contains the store that holds the global filter setting.
 *  @module
 */
import { getQueryParam, clearQueryParam, setQueryParam, addQueryParamChangeListener } from 'utils/hooks/useQueryParams.ts';
import { isEqual, isEmpty } from 'lodash';
import type { FILTER_NAME } from 'components/sdwan/enums/filters.ts';
import type { IndicatorMapping } from 'pages/incident-list/Incident.type.ts';

/** this type defines a filter value. */
export type GENERIC_FILTER_VALUE = number | string | boolean | undefined | IndicatorMapping;
/** this type defines the possible types of filter values, either an array or a single value. */
export type SUPPORTED_FILTER_VALUE_TYPES = GENERIC_FILTER_VALUE | GENERIC_FILTER_VALUE[] | { [index: string]: GENERIC_FILTER_VALUE } | { [index: string]: GENERIC_FILTER_VALUE }[];

/** this type defines the format of a filters object. */
export type FILTERS_OBJECT = {
    [key in FILTER_NAME]?: SUPPORTED_FILTER_VALUE_TYPES | undefined
};

/** a constant with the key for the filters parameter. */
export const FILTERS_QUERY_PARAM_KEY = "f";

/** the FILTERS_OBJECT with the current set of filters. */
let filters: FILTERS_OBJECT = _getParsedFilters() || {};
/** the FILTERS_OBJECT with the last set of filters. */
let lastFilters: FILTERS_OBJECT = {};
/** the array of callback functions that are notified whenever the filters change. */
let callbacks: Array<(event: {newVal: FILTERS_OBJECT, prevVal: FILTERS_OBJECT}) => void> = [];

/** returns the filters parsed from the URL query parameters.
 *  @returns the filters parsed from the URL query parameters. */
function _getParsedFilters () {
    let filtersObjStringFromURL: string | undefined = getQueryParam(FILTERS_QUERY_PARAM_KEY);
    if (filtersObjStringFromURL !== undefined) {
        try {
            return JSON.parse(filtersObjStringFromURL);
        } catch (ex) {
            console.warn("Unable to parse filters from URL - " + filtersObjStringFromURL);
        }
    }
    return null;
}

// If query param changes by a push/pop event or through some other logic,
// add a listener to check if the filters have changed. If they have and
// aren't in sync anymore, updated our local filters with the updated ones.
addQueryParamChangeListener(() => {
    const filtersFromQueryParam = _getParsedFilters();
    if (!isEqual(filters, filtersFromQueryParam)) {
        lastFilters = filters;
        filters = filtersFromQueryParam;
        triggerSync();
    }
});

/** returns the FILTERS_OBJECT with the current set of global filters.
 *  @returns the current set of filters.*/
function getAllFilters(): FILTERS_OBJECT {
    return Object.assign({}, filters);
}

/** sets the current set of global filters.
 *  @param newFilters the FILTERS_OBJECT with the new set of global filters.
 *  @returns the new filters object. */
function setAllFilters (newFilters: FILTERS_OBJECT): FILTERS_OBJECT {
    lastFilters = filters;
    filters = newFilters;
    if (isEmpty(filters, true)) {
        clearQueryParam(FILTERS_QUERY_PARAM_KEY);
    } else {
        setQueryParam(FILTERS_QUERY_PARAM_KEY, filters);
    }
    triggerSync();
    return filters;
}

/** notifies all global filters listeners that the cache of global filters has changed. */
function triggerSync(): void {
    for (const callback of callbacks) {
        callback({ newVal: filters, prevVal: lastFilters });
    }
}

/** adds a listener for changes to the global filters.
 *  @param callback a function that will be called when the global filters change. */
function addChangeCallback(callback: (event: {newVal: FILTERS_OBJECT, prevVal: FILTERS_OBJECT}) => void): void {
    callbacks.push(callback);
}

/** removes the specified listener for changes to the global filters.
 *  @param callback a function that will be called when the global filters change. */
 function removeChangeCallback (callback?: (event: {newVal: FILTERS_OBJECT, prevVal: FILTERS_OBJECT}) => void): void {
    callbacks = callbacks.filter(c => c !== callback);
}
    
export { getAllFilters, setAllFilters, addChangeCallback, removeChangeCallback };
