import { useEffect, useState } from "react";
import { Query } from "reporting-infrastructure/types/Query.ts";
import { useQuery } from "utils/hooks/useQuery.ts";
import { Tab, Tabs } from "@blueprintjs/core";
import { Icon, type IconName, IconNames } from "@tir-ui/react-components";
import { AWS_TYPES_TO_LABEL_MAP, AWSSupportedTypes, AZURE_TYPES_TO_LABEL_MAP, AzureSupportedTypes, type CloudIMDataInfo, type CloudIMSupportedTypes, NETIM_TYPES_TO_LABEL_MAP, type NetIMSupportedDeviceTypes, type NetIMSupportedLinkTypes } from "utils/cloudim/TopologyUtils.ts";
import { FILTER_NAME } from "components/sdwan/enums/filters.ts";
import { TIME_FORMAT } from "components/enums/Time.ts";
import { PROVIDER_TYPES } from "components/enums/CloudIM.ts";
import { STRINGS } from "app-strings";
import { getElapsedTimeInfo } from "reporting-infrastructure/utils/formatters/elapsed-time-formatter/elapsed-time-formatter.tsx";
import { formatToLocalTimestamp } from "reporting-infrastructure/utils/formatters/GeneralFormatter.ts";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade.tsx";
import { WrapInTooltip } from "components/common/wrap-in-tooltip/WrapInTooltip.tsx";
import { getFilteredPropertiesData } from "utils/cloudim/TopologyUtils.ts";
import AttributesTab from "components/common/graph/cloudim-react-flow/components/properties/tabs/attributes/AttributesTab.tsx";
import MetricsTab from "components/common/graph/cloudim-react-flow/components/properties/tabs/metrics/MetricsTab.tsx";

import AWS_PROPERTIES from "components/common/graph/cloudim-react-flow/components/properties/graphql/aws-properties.graphql";
import AZURE_PROPERTIES from "components/common/graph/cloudim-react-flow/components/properties/graphql/azure-properties.graphql";
import NETIM_PROPERTIES from "components/common/graph/cloudim-react-flow/components/properties/graphql/netim-properties.graphql";

import './CloudIMPropertiesContainer.scss';

/* To Use CloudIMPropertiesContainer
Without Query (AWS/Azure/NetIM):
    <CloudIMPropertiesContainer
        iconProperties={
            size: 40,
            icon: iconToUse
        },
        filter={{
            provider: PROVIDER_TYPES.AWS / PROVIDER_TYPES.AZURE / PROVIDER_TYPES.ONPREM,
            type: data.type,
            id: data.label,
        }}
        nodeData={data}
    />
With Query (AWS/ Azure):
    <CloudIMPropertiesContainer
        iconProperties={
            size: 40,
            icon: iconToUse
        },
        filter={{
            provider: PROVIDER_TYPES.AWS / PROVIDER_TYPES.AZURE,
            type: data.type,
            id: data.label,
            region: data?.attributes?.Region,
            tokens: [data.label, data.type],
        }}
        useQueryData={true}
    />
With Query (NetIM):
    <CloudIMPropertiesContainer
        iconProperties={
            size: 40,
            icon: iconToUse
        },
        filter={{
            provider: PROVIDER_TYPES.ONPREM,
            type: data.type,
            id: data.label,
            latitude: data?.attributes?.geo?.latitude,
            longitude: data?.attributes?.geo?.longitude,
            tokens: [data.label],
        }},
        useQueryData={true}
    />
*/

export interface PropertiesFilters {
    provider: PROVIDER_TYPES;
    type: AWSSupportedTypes | AzureSupportedTypes | NetIMSupportedDeviceTypes | NetIMSupportedLinkTypes | CloudIMSupportedTypes;
    id: string;
    tokens?: string[];
    region?: string;
    latitude?: number;
    longitude?: number;
}

export interface IconProperties {
    size: number;
    className?: string;
    icon: string | IconName;
}

export interface PropertiesProps {
    className?: string;
    iconProperties: IconProperties;
    filter: PropertiesFilters;
    useQueryData?: boolean;
    loading?: boolean;
    nodeData?: CloudIMDataInfo;
}

const CloudIMPropertiesContainer = ({
    className,
    iconProperties,
    filter,
    useQueryData = false,
    loading = false,
    nodeData
}: PropertiesProps) => {
    const [data, setData] = useState<CloudIMDataInfo | undefined>(nodeData);
    const [isLoading, setIsLoading] = useState<boolean>(loading);

    // TODO: Modify this query for a more precise one once it is available
    const awsQuery = useQuery({
        query: new Query(AWS_PROPERTIES),
        name: "Filtered AWS Resource",
        requiredFilters: [FILTER_NAME.regions],
        consumedFilters: [FILTER_NAME.token],
        filters: {
            [FILTER_NAME.regions]: filter.region,
            [FILTER_NAME.token]: filter.tokens || [""]
        },
        queryVariables: {
            unique: true
        },
        timeNotRequired: true,
        skipGlobalFilters: true,
        lazy: true
    });

    // TODO: Modify this query for a more precise one once it is available
    const azureQuery = useQuery({
        query: new Query(AZURE_PROPERTIES),
        name: "Filtered Azure Resource",
        requiredFilters: [FILTER_NAME.regions],
        consumedFilters: [FILTER_NAME.token],
        filters: {
            [FILTER_NAME.regions]: filter.region,
            [FILTER_NAME.token]: filter.tokens || [""]
        },
        queryVariables: {
            unique: true
        },
        timeNotRequired: true,
        skipGlobalFilters: true,
        lazy: true
    });

    // TODO: Modify this query for a more precise one once it is available
    const netImQuery = useQuery({
        query: new Query(NETIM_PROPERTIES),
        name: "Filtered NetIM Resource",
        requiredFilters: [FILTER_NAME.latitude, FILTER_NAME.longitude],
        consumedFilters: [FILTER_NAME.token],
        filters: {
            [FILTER_NAME.latitude]: filter.latitude,
            [FILTER_NAME.longitude]: filter.longitude,
            [FILTER_NAME.token]: filter.tokens || [""]
        },
        timeNotRequired: true,
        skipGlobalFilters: true,
        lazy: true
    });

    let typeLabel: string = "";
    let metrics: boolean = false;
    let headerSublabel: string = "";

    useEffect(
        () => {
            if (useQueryData) {
                setIsLoading(true);
                if (filter.provider === PROVIDER_TYPES.AWS) {
                    awsQuery.run({
                        fetchPolicy: "no-cache",
                        filters: { [FILTER_NAME.regions]: filter.region, [FILTER_NAME.token]: filter.tokens || [""] }
                    }).then((queryData) => {
                        return getFilteredPropertiesData(queryData?.resources || [], filter.provider, filter.type, filter.id);
                    }).then((filteredData) => {
                        setData(filteredData);
                    }).catch(error => console.error(error));
                } else if (filter.provider === PROVIDER_TYPES.AZURE) {
                    azureQuery.run({
                        fetchPolicy: "no-cache",
                        filters: { [FILTER_NAME.regions]: filter.region, [FILTER_NAME.token]: filter.tokens || [""] }
                    }).then((queryData) => {
                        return getFilteredPropertiesData(queryData?.resources || [], filter.provider, filter.type, filter.id);
                    }).then((filteredData) => {
                        setData(filteredData);
                    }).catch(error => console.error(error));
                } else if (filter.provider === PROVIDER_TYPES.ONPREM) {
                    netImQuery.run({
                        fetchPolicy: "no-cache",
                        filters: { [FILTER_NAME.latitude]: filter.latitude, [FILTER_NAME.longitude]: filter.longitude, [FILTER_NAME.token]: filter.tokens || [""] }
                    }).then((queryData) => {
                        return getFilteredPropertiesData(queryData?.resources || [], filter.provider, filter.type, filter.id);
                    }).then((filteredData) => {
                        setData(filteredData);
                    }).catch(error => console.error(error));
                }
                setIsLoading(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [useQueryData, filter]
    );

    // Update based on prop changes (for properties modal)
    useEffect(
        () => {
            setData(nodeData);
            setIsLoading(loading);
        },
        [nodeData, loading]
    );

    if (filter.provider === PROVIDER_TYPES.AWS) {
        typeLabel = AWS_TYPES_TO_LABEL_MAP[filter.type];
        if (filter.type === AWSSupportedTypes.COMPUTEINSTANCE) {
            metrics = true;
            headerSublabel = data?.attributes?.InstanceType ? `${STRINGS.cloudim.topology.modal.subLabels.instanceType} ${data.attributes?.InstanceType}` : "";
        }
    } else if (filter.provider === PROVIDER_TYPES.AZURE) {
        typeLabel = AZURE_TYPES_TO_LABEL_MAP[filter.type];
        if (filter.type === AzureSupportedTypes.VIRTUALMACHINE) {
            headerSublabel = data?.attributes?.vmSize ? `${STRINGS.cloudim.topology.modal.subLabels.vmSize} ${data.attributes?.vmSize}` : "";
        }
    } else if (filter.provider === PROVIDER_TYPES.ONPREM) {
        typeLabel = NETIM_TYPES_TO_LABEL_MAP[filter.type];
    }

    return (
        <DataLoadFacade className="d-flex flex-wrap" loading={awsQuery.loading || netImQuery.loading || isLoading} loadingText={STRINGS.loading + STRINGS.loadingTextIndicator} error={awsQuery.error || netImQuery.error} data={data && !isLoading}>
            {data &&
                <div className={"propertiesContainer" + (className ? " " + className : "")}>
                    <div className="header">
                        <div className="profile">
                            <div className={"icon" + (filter.provider ? " " + filter.provider : "")} title={typeLabel}>
                                <Icon
                                    size={iconProperties.size}
                                    className={iconProperties.className}
                                    icon={iconProperties.icon}
                                />
                            </div>
                            <div className="details">
                                <span>
                                    {data.label}
                                </span>
                                <span className="text-secondary display-9 fw-500">
                                    {typeLabel}
                                </span>
                            </div>
                        </div>
                        <div className="subLabels">
                            {headerSublabel &&
                                <span>
                                    {headerSublabel}
                                </span>
                            }
                            {/* Modifying from UTC to local time */}
                            {data?.timestamp &&
                                <WrapInTooltip tooltip={<>
                                    <div>
                                        {`${STRINGS.cloudim.topology.modal.lastCollected} ${formatToLocalTimestamp(new Date(data.timestamp + "Z"), TIME_FORMAT.DISPLAY_TIME_FORMAT)}`}
                                    </div>
                                    <div>
                                        {`${STRINGS.cloudim.topology.modal.lastCollectedInfo}`}
                                    </div>
                                </>}
                                >
                                    <Icon className="me-1" icon={IconNames.TIME} />
                                    <span>
                                        {`${STRINGS.cloudim.topology.modal.lastCollected} ${getElapsedTimeInfo(new Date(data.timestamp + "Z"), "standard")} ${STRINGS.cloudim.topology.modal.timeAgo}`}
                                    </span>
                                </WrapInTooltip>
                            }
                        </div>
                    </div>
                    <hr />
                    <div className="body">
                        {metrics ?
                            <Tabs>
                                <Tab id={STRINGS.cloudim.topology.modal.tabs.metrics} title={STRINGS.cloudim.topology.modal.tabs.metrics} panel={<MetricsTab type={filter.type as AWSSupportedTypes} attributes={data.attributes} />} />
                                <Tab id={STRINGS.cloudim.topology.modal.tabs.attributes} title={STRINGS.cloudim.topology.modal.tabs.attributes} panel={<AttributesTab attributes={data.attributes} />} />
                            </Tabs>
                            :
                            <AttributesTab attributes={data.attributes} />
                        }
                    </div>
                </div>
            }
        </DataLoadFacade>
    );
};

export default CloudIMPropertiesContainer;
