import { useState } from "react";
import { ErrorToaster, SuccessToaster } from "@tir-ui/react-components";
import { STRINGS } from "app-strings";
import { FormModal } from "components/common/modal/FormModal.tsx";
import { InputField } from "components/common/form/InputField.tsx";
import { HTMLSelect, Label } from "@blueprintjs/core";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import moment from "moment";
import { validityOptionsMapping } from "../constants.ts";
import CREATE_CLIENT_APPLICATION_SECRET_MUTATION from './../queries/create-client-application-secret-mutation.graphql';

interface CreateClientApplicationSecretMutationInput {
    appId: string;
    description: string;
    expiration: string;
}

export const AddClientSecretModal = (props) => {
    const maximumExpirationDays = 2 * 365; // 2 Years
    const { clientId, clientName, handleClientSecretCreated } = props;
    const DEFAULT_VALIDITY = 3; // months
    const [validity, setValidity] = useState(DEFAULT_VALIDITY);
    const [createClientApplicationSecret] = useMutation<
        any,
        CreateClientApplicationSecretMutationInput
    >(CREATE_CLIENT_APPLICATION_SECRET_MUTATION, {
        onCompleted: (data) => {
            SuccessToaster({
                message:
                    STRINGS.apiAccess.toastMessages.successCreateSecret,
            });
            handleClientSecretCreated(data);
        },
        onError: (err) => {
            ErrorToaster({
                message: STRINGS.apiAccess.toastMessages.errorCreateSecret,
            });
            console.error(err?.message);
        },
    });

    const validationSchema = yup.object().shape({
        description: yup
            .string()
            .label(STRINGS.apiAccess.addClientSecretModal.description)
            .required(),
        expirationTime: yup.string().required(),
        daysExpirationTime: yup.number().when([], {
            is: () => validity === 0,
            then: yup.number()
                .min(1, STRINGS.apiAccess.validationMessages.minDays)
                .max(maximumExpirationDays, STRINGS.apiAccess.validationMessages.maxDays)
                .required(STRINGS.apiAccess.validationMessages.daysRequired),
        }),
    });

    const formConfig = {
        initialValues: {
            description: "",
            expirationTime: moment().add("3", "months").unix(),
            daysExpirationTime: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values: any) => {
            return createClientApplicationSecret({
                variables: {
                    appId: clientId,
                    description: values.description,
                    expiration:
                        validity !== 0
                            ? moment().add(validity, "months").unix().toString()
                            : moment()
                                  .add(values.daysExpirationTime, "days")
                                  .unix()
                                  .toString(),
                },
            });
        },
    };

    return (
        <FormModal
            title={STRINGS.apiAccess.modalTitles.addSecret}
            formikProps={formConfig}
            customLabels={{
                submit: STRINGS.apiAccess.modalButtons.submitAddSecret,
            }}
            {...props}
        >
            <section className="add-client-secret-modal">
                <div className="row">
                    <div className="col-md-3">
                        {STRINGS.apiAccess.addClientSecretModal.clientName}
                    </div>
                    <div className="col-md-9 fw-bold">
                        {clientName}
                    </div>
                </div>

                <div className="row mt-4">
                    <Label className="col-md-3 mb-0">
                        {STRINGS.apiAccess.addClientSecretModal.description}
                    </Label>
                    <div className="col-md-9">
                        <InputField
                            placeholder={
                                STRINGS.apiAccess.addClientSecretModal
                                    .descriptionPlaceholder
                            }
                            name={"description"}
                            required={true}
                        />
                    </div>
                </div>

                <div className="row">
                    <Label className="col-md-3">
                        {STRINGS.apiAccess.validity}
                    </Label>
                    <div className="col-md-9 d-flex-main">
                        <HTMLSelect
                            fill={true}
                            name={"expirationTime"}
                            options={validityOptionsMapping}
                            onChange={(event) => {
                                setValidity(+event?.currentTarget.value);
                            }}
                        />
                        <InputField
                            className={validity !== 0 ? "hide" : ""}
                            name={"daysExpirationTime"}
                            type="number"
                            required={true}
                        />
                        <p className={validity !== 0 ? "hide mt-1" : "mt-1"}>
                            {STRINGS.apiAccess.unitToCountOn}
                        </p>
                    </div>
                </div>
            </section>
        </FormModal>
    );
};
