/** This module contains utilities for editing logic nodes and validating logic nodes.
 *  @module
 */

import { STRINGS } from "app-strings";
import { GraphDef, NodeDef } from "components/common/graph/types/GraphTypes.ts";
import { NodeUtils } from "utils/runbooks/NodeUtil.ts";
import { KEY_PREFIX, METRIC_PREFIX } from "components/common/graph/editors/logical/LogicNodeEditor.tsx";
import { DataOceanUtils } from "components/common/graph/editors/data-ocean/DataOceanUtils.ts";
import { dataOceanNodes } from "utils/runbooks/RunbookUtils.ts";
import { VariableContextByScope } from "utils/runbooks/RunbookContext.class.ts";

/** an enum with all of the valid logic node properties. */
export enum LOGIC_NODE_EDIT_PROPS {
    EXPRESSION  = "expression",
    DATA_TYPE   = "dataType",
    PASSED_DATA = "passedData",
    INPUT_TYPE  = "inputType"
}

/** an enum with all of the valid metric data types. */
export enum MetricDataType {
    SUMMARY     = "SUMMARY",
    TIMESERIES  = "TIMESERIES"
}

/** This is a Utility class for the logic node.   This class extends the NodeUtils class. */
export class LogicNodeUtil extends NodeUtils {
    /** a static member variable with the error strings for the logic node. */
    static errMsgs = STRINGS.runbookEditor.errors.logicNode;
    
    /** Check if a logic node is valid. Validates in the context of other nodes in the graph
     *  @param nodeId - node identifier
     *  @param graphDef - graph with info on al the nodes. 
     *  @param variables the map of variables by scope.
     *  @return  is node valid. */
    static isNodeValid(nodeId: string | undefined | null, graphDef: GraphDef, variables?: VariableContextByScope): boolean {
        if(!nodeId) {
            console.error(" isNodeValid: nodeId is undefined ");
            return false;
        }
        const errors = [];
        LogicNodeUtil.validateNode(nodeId, errors, graphDef, variables)
        return errors.length === 0;
    }

    /** Check if a logic node is valid. Validates in the context of other nodes in the graph.
     *   Populates the errors.
     *   @param nodeId - node identifier
     *   @param errors - IN-OUT argument the array his populated with error messages. Empty array if
     *   there are no errors
     *   @param graphDef - graph with info on al the nodes.
     *   @param variables the map of variables by scope. */
    static validateNode(nodeId: string, errors: string[], graphDef: GraphDef, variables?: VariableContextByScope): void {
        let curNode = graphDef.nodes.find((n) => {
            return nodeId === n.id
        });

        // no-op currenty.
        super.validateNode(nodeId, errors, graphDef, variables);

        if (!NodeUtils.getPropertyFromNode(LOGIC_NODE_EDIT_PROPS.INPUT_TYPE, curNode)?.value) {
            errors.push(LogicNodeUtil.errMsgs.inputError);
        }
        
        if (!NodeUtils.getPropertyFromNode(LOGIC_NODE_EDIT_PROPS.EXPRESSION, curNode)?.value) {
            errors.push(LogicNodeUtil.errMsgs.expError);
        } else {
            const expression = NodeUtils.getPropertyFromNode(LOGIC_NODE_EDIT_PROPS.EXPRESSION, curNode)?.value;
            const expCount = (expression.keys?.length ? expression.keys?.length : 0) +
                (expression.metrics?.length ? expression.metrics?.length : 0)
            if (!expCount) {
                errors.push(LogicNodeUtil.errMsgs.expError);
            }
            let invalidExp = false;
            expression.keys?.forEach(exp => {
                if (!exp.id || !exp.op || !exp.value) {
                    invalidExp = true;
                }
            });
            expression.metrics?.forEach(exp => {
                if (!exp.id || !exp.op || exp.value === null || exp.value === undefined || exp.value === "") {
                    invalidExp = true;
                }
                if (NodeUtils.getPropertyFromNode(LOGIC_NODE_EDIT_PROPS.DATA_TYPE, curNode)?.value === MetricDataType.TIMESERIES && !exp.ts_op) {
                    invalidExp = true;
                }
            });
            if (invalidExp) {
                errors.push(LogicNodeUtil.errMsgs.invalidExp)
            }
        }

        // Check compatibility with the parent DO node
        LogicNodeUtil.validateDONodeCompatiblity(curNode, errors, graphDef);
    }

    /** Validates the logic node against a parent data ocean node verifies if the input type and metric type matches
    *   Populates the errors.
    *   @param nodeId - node identifier
    *   @param errors - IN-OUT argument the array his populated with error messages. Empty array if
    *   @param graphDef - graph with info on al the nodes. */
    static validateDONodeCompatiblity(node: NodeDef | undefined, errors: string[], graphDef: GraphDef): void {
        if (!node) {
            return;
        }
        const pNode = NodeUtils.getParentNode(node.id, graphDef, dataOceanNodes);
        if (!pNode) {
            errors.push(LogicNodeUtil.errMsgs.noParentDONode);
        }
        const doMetricDataType = Boolean(NodeUtils.getPropertyFromNode("timeSeries", pNode)?.value);
        const logicMetricDataType = NodeUtils.getPropertyFromNode(LOGIC_NODE_EDIT_PROPS.DATA_TYPE, node)?.value === MetricDataType.TIMESERIES;
        if (doMetricDataType !== logicMetricDataType) {
            errors.push(LogicNodeUtil.errMsgs.incompatibleDataType);
        }
        const doInputType = NodeUtils.getPropertyFromNode("objType", pNode)?.value;
        const logicInputType = NodeUtils.getPropertyFromNode(LOGIC_NODE_EDIT_PROPS.INPUT_TYPE, node)?.value;
        if (pNode && node.editedByUser && (!doInputType || !logicInputType || doInputType !== logicInputType)) {
            errors.push(LogicNodeUtil.errMsgs.incompatibleInput);
        }

        // Check to make sure the expression does not contain keys and metrics that are not in the DO node.
        const expression = NodeUtils.getPropertyFromNode(LOGIC_NODE_EDIT_PROPS.EXPRESSION, node)?.value;
        if (expression && pNode) {
            const doMetrics = NodeUtils.getPropertyFromNode("metrics", pNode)?.value || [];
            const expandedKeys: Array<string> = LogicNodeUtil.getListOfExpandedKeys(DataOceanUtils.dataOceanMetaData, doInputType);
            if (expression.keys) {
                for (const exp of expression.keys) {
                    const keyId = exp.id.replace(KEY_PREFIX, '');
                    if (!expandedKeys.includes(keyId)) {
                        errors.push(LogicNodeUtil.errMsgs.incompatibleKey);
                        break;
                    }
                }
            }
            if (expression.metrics) {
                for (const exp of expression.metrics) {
                    const metricId = exp.id.replace(METRIC_PREFIX, '');
                    if (!doMetrics.includes(metricId)) {
                        errors.push(LogicNodeUtil.errMsgs.incompatibleMetric);
                        break;
                    }
                }
            }
        }
    }
}
