/** This module defines a React component that displays the impacted location search result.
 *  @module
 */
import { SearchItem, SearchResultsItemProps } from "components/common/search/search-items/search-results-item/SearchResultsItem.tsx";
import { LinkInfo, LinkType } from "components/common/search/search-links-group/LinksGroup.tsx";
import { STRINGS } from "app-strings";
import { SearchStandardItem } from "components/common/search/search-items/search-standard-item/SearchStandardItem.tsx";

/** Renders the search result for the impacted location.
 *  @param data the properties passed in.
 *  @returns JSX with the impacted location search result react component.*/
export function SearchCustomPropertyItem({ data }: SearchResultsItemProps) {
    let modifiedData: SearchItem = data;
    let additionalInfo: JSX.Element | undefined = undefined;
    const impacts: Array<string> = [];
    try {
        modifiedData = JSON.parse(JSON.stringify(data));
        const customProperty: any = data.customProperty;
        if (customProperty) {
            modifiedData.formattedData.description = data.customProperty?.description;
        }    
        if (data.customProperty?.taggedLocationCount) {
            impacts.push(`Tagged Locations: ${data.customProperty.taggedLocationCount}`);
        }
        if (data.customProperty?.taggedNetworkDeviceCount) {
            impacts.push(`Tagged Devices: ${data.customProperty.taggedNetworkDeviceCount}`);
        }
        if (data.customProperty?.taggedNetworkInterfaceCount) {
            impacts.push(`Tagged Interfaces: ${data.customProperty.taggedNetworkInterfaceCount}`);
        }
        additionalInfo = <>{impacts.map((item, index) => <div key={"impact-" + index} className={index > 0 ? "mt-2" : ""}>{item}</div>)}</>;
    } catch (error) {
        console.error(error);
    } 

    const links: Array<LinkInfo> = [
        {
            path: "explorer",
            label: STRINGS.SEARCH.details,
            type: LinkType.PAGE,
            params: {
                searchText: data.customProperty.name,
                searchType: "properties",
                customPropertyId: data.customProperty.id
            }
        }
    ];

    return <SearchStandardItem data={modifiedData} links={links} showCharts={false} showHealth={false} additionalInfo={impacts.length ? additionalInfo : undefined} />;
}
