// TODO: large portion of exported functions are not hooks and need to be moved
// elsewhere
import { useEffect, useState, useRef } from 'react';
import _ from 'lodash';

window.history.pushState = new Proxy(window.history.pushState, {
    apply: (target: any, thisArg: any, argArray?: any) => {
        var e = new CustomEvent("pushState", { detail: { args: argArray } });
        var result = target.apply(thisArg, argArray);
        window.dispatchEvent(e);
        return result;
    }
});
window.history.replaceState = new Proxy(window.history.replaceState, {
    apply: (target: any, thisArg: any, argArray?: any) => {
        var e = new CustomEvent("replaceState", { detail: { args: argArray } });
        var result = target.apply(thisArg, argArray);
        window.dispatchEvent(e);
        return result;
    }
});

if (!window.onpopstate) { window.onpopstate = function () {} }
window.onpopstate = new Proxy(window.onpopstate, {
    apply: (target: any, thisArg: any, argArray?: any) => {
        var e = new CustomEvent("popState", { detail: { args: argArray } });
        var result = target.apply(thisArg, argArray);
        window.dispatchEvent(e);
        return result;
    }
});

export interface paramsObj { [x:string]: string | number | null | undefined | object }

function setAllQueryParams (params:paramsObj, addEntryInHistory:boolean = false) {
    var targetURL = window.location.origin + getURL(undefined, params, { replaceQueryParams: true });
    if (addEntryInHistory) {
        window.history.pushState({}, window.document.title, targetURL);
    } else {
        window.history.replaceState({}, window.document.title, targetURL);
    }
}

function getURL (
        /** Target URL path to replace to. Can be passed as undefined if this method
         * is being used only to replace the query params */
        updatedPath?:string,
        /** Params to be overwritten. Can be passed as undefined if this method is being
         * used only to replace the pathname. */
        updatedQueryParamsObj:paramsObj = {},
        options?: {
            /** When this flag is passed as true, all existing query parameters will be
             * dropped and replaced with new ones. When false, the params will be merged
             * with the existing ones. Defaults to false. */
            replaceQueryParams?:boolean,
            /** Pass this flag as true if you want only the last segment to be replaced
             * e.g. changing "/hello/planet/earth" to "/hello/planet/mars" as opposed to just "/mars" */
            replaceLastSegmentOnly?:boolean
        }
    ) {
    const replaceQueryParams = options?.replaceQueryParams || false;
    const replaceLastSegmentOnly = options?.replaceLastSegmentOnly || false;
    const finalQueryParams = replaceQueryParams ? updatedQueryParamsObj : {...getAllQueryParams(), ...updatedQueryParamsObj}
    const queryString = convertParamsObjToQueryString(finalQueryParams);
    let finalPath = window.location.pathname;
    if (updatedPath !== undefined) {
        if (updatedPath[0] === "/") {
            updatedPath = updatedPath.substr(1);
        }
        if (replaceLastSegmentOnly) {
            let pathSplit = finalPath.split("/");
            pathSplit.pop();
            pathSplit.push(updatedPath);
            finalPath = pathSplit.join("/");
        } else {
            finalPath = "/" + updatedPath;
        }
    }
    const finalURL = finalPath + (queryString ? "?" + queryString : "") + window.location.hash;
    return finalURL;
}

function convertParamsObjToQueryString(params: paramsObj = {}) {
    let queryString = "";
    for (const key in params) {
        const value = params[key];
        queryString +=
            (queryString === "" ? "" : "&") +
            key +
            "=" +
            encodeURIComponent(
                typeof value === "object"
                    ? JSON.stringify(value)
                    : value === undefined
                    ? ""
                    : String(value),
            );
    }
    return queryString;
}

function setQueryParams (paramOverrides:paramsObj, addEntryInHistory?:boolean) {
    let currentParams = Object.assign({}, getAllQueryParams()),
        diffParams = {};
    
    // Build a map of params that are different from existing values
    for (const key in paramOverrides) {
        // If we need to clear this param
        if (paramOverrides[key] === undefined || paramOverrides[key] === null || paramOverrides[key] === "") {
            // Consider it a change only if it was already present in the URL
            if (currentParams[key] !== undefined) {
                diffParams[key] = paramOverrides[key];
            }
        } else if (currentParams[key] !== paramOverrides[key]) {
            diffParams[key] = paramOverrides[key];
        }
    }
    // If there's at least one param for which the value has changed, then do the update
    if (Object.keys(diffParams).length > 0) {
        currentParams = Object.assign(currentParams, diffParams);
        for (const key in currentParams) {
            const value = currentParams[key];
            if (value === undefined || value === null || value === "") {
                delete currentParams[key];
            }
        }
        setAllQueryParams(currentParams, addEntryInHistory);
    }
}

function setQueryParam (paramName:string, value:any, addEntryInHistory?:boolean) {
    setQueryParams({ [paramName]: value }, addEntryInHistory);
}
function clearQueryParam (paramName:string, addEntryInHistory?:boolean) {
    setQueryParams({ [paramName]: "" }, addEntryInHistory);
}
function clearQueryParams (paramNames:string[], addEntryInHistory?:boolean) {
    let clearObject = {};
    for (const key of paramNames) {
        clearObject[key] = "";
    }
    setQueryParams(clearObject, addEntryInHistory);
}

function getAllQueryParams () {
    return convertQueryParamsToObj();
}

function getQueryParam (key:string) {
    return getAllQueryParams()[key];
}
function getQueryParams (keys:string[]) {
    let allQueryParams = getAllQueryParams(),
        requestedParams:{[x:string]:string} = {};
    
    for (const key of keys) {
        requestedParams[key] = allQueryParams[key];
    }
    return requestedParams;
}

function convertQueryParamsToObj(): { [key: string]: string } {
    let search = window.location.search;
    if (search) {
        search = search.replace(/\+/g, "%2B");
    }

    const params = new URLSearchParams(search);
    let paramsObj: { [key: string]: string } = {};

    const entries = Array.from(params.entries());
    for (const [key, value] of entries) {
        try {
            paramsObj[key] = decodeURIComponent(value);
        } catch (e) {
            paramsObj[key] = value;
        }
    }

    return paramsObj;
}

function addQueryParamChangeListener (listener:() => void) {
    window.addEventListener("pushState", listener);
    window.addEventListener("replaceState", listener);
    window.addEventListener("popState", listener);
}

function removeQueryParamChangeListener (listener) {
    window.removeEventListener("pushState", listener);
    window.removeEventListener("replaceState", listener);
    window.removeEventListener("popState", listener);
}

interface queryParamsObj {
    [x:string]: string
};
export interface useQueryParamsHookProps {
    // When list of URL query param keys are passed, the component using this
    // hook will re-render only when a change is detected in one of those params
    listenOnlyTo?: string[],
    // Clear these params automatically from URL when this component unmounts
    clearOnUnmount?: string[],
    // Pass a list of params that you want to be set in the URL by default.
    // This will happen only when a value did not exist in the URL already.
    paramDefaults?: queryParamsObj
}
function useQueryParams (props?: useQueryParamsHookProps) {
    if (props?.paramDefaults) {
        let missingParamsWithDefaults = {};
        const allQueryParams = getAllQueryParams();
        for (const key in props.paramDefaults) {
            if (allQueryParams[key] === undefined) {
                missingParamsWithDefaults[key] = props.paramDefaults[key];
            }
        }
        if (Object.keys(missingParamsWithDefaults).length > 0) {
            setQueryParams(missingParamsWithDefaults);
        }
    }
    let [params, setParams] = useState<queryParamsObj>(getQueryParamsObj());
    let paramsRef = useRef(params);
    paramsRef.current = params;


    function getQueryParamsObj () {
        const allParams = convertQueryParamsToObj();
        let paramsObj = allParams;
        if (props?.listenOnlyTo) {
            paramsObj = {};
            for (const key of props.listenOnlyTo) {
                paramsObj[key] = allParams[key];
            }
        }
        return paramsObj;
    }

    useEffect(() => {
        function syncQueryParams () {
            const currentQueryParams = getQueryParamsObj();
            if (!_.isEqual(currentQueryParams, paramsRef.current)) {
                setParams(currentQueryParams);
            }
        }
        addQueryParamChangeListener(syncQueryParams);
        return () => {
            removeQueryParamChangeListener(syncQueryParams);
            if (props?.clearOnUnmount) {
                clearQueryParams(props?.clearOnUnmount);
            }
        };
        // Disabling the warning which wants us to add 'params' to dependency array. That will cause cyclic execution.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        params,
        setQueryParam,
        setQueryParams,
        setAllQueryParams,
        clearQueryParam,
        clearQueryParams,
    };
};

export {
    useQueryParams,
    getURL,
    setQueryParam,
    setQueryParams,
    setAllQueryParams,
    getQueryParam,
    getQueryParams,
    getAllQueryParams,
    clearQueryParam,
    clearQueryParams,
    addQueryParamChangeListener,
    removeQueryParamChangeListener,
    convertQueryParamsToObj,
};
