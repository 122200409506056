/** This module contains the component for displaying the ongoing incidents filter.
 *  @module
 */
import { useCallback, useEffect } from 'react';
import { Switch } from '@blueprintjs/core';
import { useGlobalFilters } from "utils/hooks/useGlobalFilters.ts";
import { FILTER_NAME } from "components/sdwan/enums/filters.ts";
import type { FILTER_IN_USER_PREFS_KEY } from 'utils/services/UserPrefsTypes';
import { getFiltersFromUserPreference, saveFiltersToUserPreference } from 'utils/stores/UserPreferencesUtils';
import { STRINGS } from 'app-strings';

/** this enum specifies the valid options for the ongoing filter. */
export enum CompletionStatus {
    /** only show incidents that are ongoing endtime=0. */
    ONGOING     = "ONGOING",
    /** only show incidents that are not ongoing endtime!=0. */
    COMPLETED   = "COMPLETED",
    /** show all incidents. */
    ANY         = "ANY"
}

/** the default filter value that should be displayed if there are no preferences. */
const DEFAULT_FILTER_VALUE = CompletionStatus.ONGOING;
 
/** This interface defines the properties passed into the ongoing incidents filter React component.*/
export interface OngoingIncidentsFilterProps {
    /** Additional CSS classes to apply on outermost DOM elem of this component */
    className?: string;
    /** If this flag is true and global filters doens't have a value for priority
     * when this component is mounted, then it will be automatically set to the
     * default priority filter values */
    applyDefaults?: boolean;
     /** A key that can be used to decide which set of user preferences keys this
     * control's state should be stored under. Do not pass this value if you don't
     * want it to be stored. */
    userPrefsKey?: FILTER_IN_USER_PREFS_KEY;
}
 
/** Renders the Ongoing Incidents Only toggle filter React component.
 *  @param props the props passed in to the component.
 *  @returns JSX with the incident status filter component.*/
export function OngoingIncidentsFilter ({
    className,
    applyDefaults = true,
    userPrefsKey,
}: OngoingIncidentsFilterProps): JSX.Element {
    const { filters, setFilter } = useGlobalFilters({
        listenOnlyTo: [FILTER_NAME.completionStatus]
    });
    const ongoing: boolean = Boolean(filters.completionStatus === CompletionStatus.ONGOING);

    useEffect(() => {
        if (!filters[FILTER_NAME.completionStatus] && userPrefsKey) {
            // Get filters from user preferences
            getFiltersFromUserPreference(userPrefsKey).then(filtersObj => {
                // If filters in user preferences had ongoing incident filter
                let ongoingFlagFromUserPref = filtersObj[FILTER_NAME.completionStatus];
                if (ongoingFlagFromUserPref) {
                    setFilter(FILTER_NAME.completionStatus, ongoingFlagFromUserPref);
                } else if (applyDefaults) {
                    setFilter(FILTER_NAME.completionStatus, DEFAULT_FILTER_VALUE);
                }
            }).catch(() => {
                // If user preferences is not available and applyDefaults flag was true
                if (applyDefaults) {
                    setFilter(FILTER_NAME.completionStatus, DEFAULT_FILTER_VALUE);
                }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
 
    const toggleOngoing = useCallback(() => {
        const updatedFilters = setFilter(FILTER_NAME.completionStatus, ongoing ? CompletionStatus.ANY : CompletionStatus.ONGOING);
        if (userPrefsKey) {
            // Save the updated filter to user preferences as the user has manually modified it
            saveFiltersToUserPreference(updatedFilters, undefined, userPrefsKey);
        }
    }, [ongoing, setFilter, userPrefsKey]);
 
     return <div className={"incident-ongoing-filter" + (className ? " " + className : "")}>
        <label className="d-flex">
            {STRINGS.incidents.ongoingFilterLabel}
            <Switch
                className="ms-1 mb-1"
                checked={ongoing}
                onChange={toggleOngoing}
            />
        </label>
    </div>;
}
 
