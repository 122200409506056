mutation RemoveCustomPropertyFromLocations($id: ID!, $filter: LocationBulkFilterType, $search: String, $searchFields: [LocationSearchFieldEnum]) {
    removeCustomPropertyFromLocations(
        id: $id
        filter: $filter
        search: $search
        searchFields: $searchFields
    ) {
        modifiedResourcesCount
        ignoredResourcesCount
    }
}
