query notes($incidentId: String!) {
    notes(filter: {incidentId: $incidentId}, order: {timestamp: DESC}) {
    nodes {
        id
        type
        timestamp
        incidentId
        widgetId
        runbookId
        userType
        user {
            id
            name
            email
        }
        content
    }
    }
}
