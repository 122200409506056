query incidentSummaryForHeader($incidentId: [String!]) {
  incidents: incidentDetails(
    filter: {incidentIds: $incidentId}
  ) {
    nodes: page {
      id
      description
      earliestIndicator
      latestIndicator
      priority
      status
      lastUpdatedAt
      createdAt
      endTime
      eventCategory
      isOngoing
      trackingEntity {
        name
        kind
      }
    }
  }
}
