import { useEffect, useRef, useState } from 'react';
import { Intent, SpinnerSize } from '@blueprintjs/core';
import { Query } from 'reporting-infrastructure/types/Query.ts';
import { useQuery } from "utils/hooks/useQuery.ts";
import { useApolloClient, useMutation } from '@apollo/client';
import {
	ErrorToaster,
	SuccessToaster,
	useStateSafePromise,
} from '@tir-ui/react-components';
import { IconNames } from '@tir-ui/react-components';
import { DataLoadFacade } from 'components/reporting/data-load-facade/DataLoadFacade.tsx';
import { PageWithHeader } from 'components/sdwan/layout/page-with-header/PageWithHeader.tsx';
import { UpdatePolicySettingsModal } from './modals/UpdatePolicySettingsModal.tsx';
import type { DataOceanMetadata } from 'components/common/graph/editors/data-ocean/DataOceanMetadata.type.ts';
import { DataOceanUtils } from 'components/common/graph/editors/data-ocean/DataOceanUtils.ts';
import DevicesView from './views/DevicesView.tsx';
import InterfacesView from './views/InterfacesView.tsx';
import ApplicationsView from './views/ApplicationsView.tsx';
import { LoadingOverlay } from '@tir-ui/react-components';
import POLICIES_QUERY from './queries/policies.graphql';
import UPDATE_POLICY_MUTATION from './queries/policies-mutation.graphql';
import { HELP, STRINGS } from 'app-strings';

import './AnalyticsConfigurationPage.scss';
import { openConfirm } from 'components/common/modal/ConfirmModal.tsx';

/** an enum with all of the valid entity types. */
export enum ENTITY_TYPES {
	APPLICATION_LOCATION = 'APPLICATION_LOCATION',
	NETWORK_DEVICE = 'NETWORK_DEVICE',
	NETWORK_INTERFACE = 'NETWORK_INTERFACE',
}

export enum DETECTOR_TYPES {
	NONE = 'NONE',
	THRESHOLD = 'THRESHOLD',
	ALWAYS_INCREASING = 'ALWAYS_INCRESING',
	CHANGE = 'CHANGE',
	FAULT = 'FAULT',
	DYNAMIC_THRESHOLD = 'DYNAMIC_THRESHOLD',
	BASELINE = 'BASELINE',
}

/** an enum with all of the valid entity types. */
export enum DETECTOR_TYPE_STRINGS {
	THRESHOLD = STRINGS.ANALYTICS_CONFIGURATION_PAGE.detectorTypes.threshold,
	ALWAYS_INCREASING = STRINGS.ANALYTICS_CONFIGURATION_PAGE.detectorTypes
		.alwaysIncreasing,
	CHANGE = STRINGS.ANALYTICS_CONFIGURATION_PAGE.detectorTypes.change,
	FAULT = STRINGS.ANALYTICS_CONFIGURATION_PAGE.detectorTypes.fault,
	DYNAMIC_THRESHOLD = STRINGS.ANALYTICS_CONFIGURATION_PAGE.detectorTypes
		.dynamicThreshold,
	BASELINE = STRINGS.ANALYTICS_CONFIGURATION_PAGE.detectorTypes.baseline,
}

export interface SetPolicyInput {
	input: [
		{
			detector: {
				lower: number;
				m: number;
				n: number;
				okValue: number;
				percentile: number;
				tolerance: number;
				type: string;
				upper: number;
			};
			enabled: boolean;
			entityKind: string;
			granularity: [number];
			matchFunctions: [
				{
					appliesTo: string;
					matchSource: string;
					matchTarget: string;
					operation: string;
				}
			];
			metrics: [string];
		}
	];
}

export const getOperation = (policy: any) => {
	if (policy.detector.upper !== null) {
		return '>';
	} else {
		return '<';
	}
};

export const cleanTypename = (policy: any) => {
	const policyCleaned = JSON.parse(JSON.stringify(policy));
	policyCleaned &&
		policyCleaned.__typename &&
		delete policyCleaned.__typename;
	policyCleaned.detector &&
		policyCleaned.detector.__typename &&
		delete policyCleaned.detector.__typename;
	policyCleaned.defaultConfig &&
		policyCleaned.defaultConfig.__typename &&
		delete policyCleaned.defaultConfig.__typename;
	policyCleaned.detector &&
		policyCleaned.detector.__typename &&
		delete policyCleaned.detector.__typename;
	policyCleaned.matchFunctions.map((item) => {
		item && item.__typename && delete item.__typename;
		return item;
	});

	return policyCleaned;
};

/** Renders the  analytics configuration page.
 *  @param props the properties passed in.
 *  @returns JSX with the device list page.*/
const AnalyticsConfigurationPage = (): JSX.Element => {
	const [allPolicies, setAllPolicies] = useState<any>([]);
	const [devicePolicies, setDevicePolicies] = useState<any>([]);
	const [interfacePolicies, setInterfacePolicies] = useState<any>([]);
	const [applicationPolicies, setApplicationPolicies] = useState<any>([]);
	const [updating, setUpdating] = useState<boolean>(false);
	const [showWarning, setShowWarning] = useState<boolean>(true);

	const { loading, data, error } = useQuery({
		name: 'analyticsPolicies',
		query: new Query(POLICIES_QUERY),
	});

	const [executeSafely] = useStateSafePromise();
	const [objMetricMetaData, setObjMetricMetaData] =
		useState<DataOceanMetadata>();
    
    const client = useApolloClient();

	useEffect(() => {
		executeSafely(DataOceanUtils.init()).then(
			(response: DataOceanMetadata) => {
				setObjMetricMetaData(response);
			},
			(error) => {
				console.error(error);
			}
		);
	}, [executeSafely]);

	useEffect(() => {
		let isSpecialized: boolean = false;
		const policies: Array<any> = [];
		const applications: Array<any> = [];
		const devices: Array<any> = [];
		const interfaces: Array<any> = [];
		if (data && data.hasOwnProperty('analyticsPolicies')) {
			data.analyticsPolicies.forEach((item) => {
				if (item.detector.type !== DETECTOR_TYPES.NONE) {
					switch (item.entityKind) {
						case ENTITY_TYPES.APPLICATION_LOCATION:
							applications.push(item);
							break;
						case ENTITY_TYPES.NETWORK_DEVICE:
							devices.push(item);
							break;
						case ENTITY_TYPES.NETWORK_INTERFACE:
							interfaces.push(item);
							break;
					}
					policies.push(cleanTypename(item));
				}
				if (item.isSpecialized) {
					isSpecialized = true;
				}
			});
		}
		setApplicationPolicies(applications);
		setDevicePolicies(devices);
		setInterfacePolicies(interfaces);
		setAllPolicies(policies);
		if (isSpecialized && showWarning) {
			openConfirm({
				style: {zIndex: 100},
				message: (
					<div
						className="px-2 py-2"
						dangerouslySetInnerHTML={{
							__html: STRINGS.ANALYTICS_CONFIGURATION_PAGE
								.isSpecializedMessage,
						}}
					></div>
				),
				onConfirm: () => {
					setShowWarning(false);
				},
				icon: IconNames.INFO_SIGN,
				intent: Intent.PRIMARY,
				confirmButtonText: STRINGS.runbooks.okBtnText,
			});
		}
	}, [data, showWarning]);

	const [updatePolicy] = useMutation<any, SetPolicyInput>(
		UPDATE_POLICY_MUTATION,
		{
			onCompleted: async () => {
                // This ensures the updating spinner and the successToaster hides/shows after the refetch
                try {
                  await client.refetchQueries({ include: ["analyticsPolicies"] });
                  SuccessToaster({
                    message: STRINGS.ANALYTICS_CONFIGURATION_PAGE.updateSuccessMessage,
                  });
                  setUpdating(false);
                } catch (err) {
                  ErrorToaster({
                    message: STRINGS.ANALYTICS_CONFIGURATION_PAGE.updateErrorMessage,
                  });
                  console.error(err);
                  setUpdating(false);
                }
              },
			onError: (err) => {
				ErrorToaster({
					message: STRINGS.ANALYTICS_CONFIGURATION_PAGE.updateErrorMessage,
				});
				console.error(err?.message);
                setUpdating(false);
			},
		}
	);

	const updatePolicyHandler = (policies) => {
		setUpdating(true);
		const payload = {
			variables: {
				input: policies,
			},
		};
		return updatePolicy(payload);
	};

	const updatePolicySettingsModal = useRef();

	const thresholdEditHandler = (policy) => {
		if (updatePolicySettingsModal.current) {
			// @ts-ignore
			updatePolicySettingsModal.current.setPolicies(allPolicies);
			// @ts-ignore
			updatePolicySettingsModal.current.setPolicy(policy);
			// @ts-ignore
			updatePolicySettingsModal.current.setUnit(
				objMetricMetaData?.metrics[policy.metrics[0]].unit
			);
			// @ts-ignore
			updatePolicySettingsModal.current.setMetricName(
				objMetricMetaData?.metrics[policy.metrics[0]].label
			);
			// @ts-ignore
			updatePolicySettingsModal.current.handleOpen();
		}
	};

	if (!objMetricMetaData) {
		return <DataLoadFacade loading={loading} error={error} data={data} />;
	} else {
		return (
			<DataLoadFacade loading={loading} error={error} data={data}>
				<PageWithHeader
					name={AnalyticsConfigurationPage.name}
					title={STRINGS.ANALYTICS_CONFIGURATION_PAGE.title}
					icon={IconNames.CHART}
					addPadding={true}
					showTimeBar={false}
					helpInfo={HELP.analyticsConfiguration}
				>
					<div>{STRINGS.ANALYTICS_CONFIGURATION_PAGE.subTitle}</div>
					<div className="mt-4">
						<LoadingOverlay
							visible={updating}
							spinnerSize={SpinnerSize.STANDARD}
						/>
						<DevicesView
							objMetricMetaData={objMetricMetaData}
							allPolicies={allPolicies}
							devicePolicies={devicePolicies}
							updatePolicy={updatePolicyHandler}
						/>
						<InterfacesView
							objMetricMetaData={objMetricMetaData}
							allPolicies={allPolicies}
							interfacePolicies={interfacePolicies}
							updatePolicy={updatePolicyHandler}
							editThreshold={thresholdEditHandler}
						/>
						<ApplicationsView
							objMetricMetaData={objMetricMetaData}
							allPolicies={allPolicies}
							applicationPolicies={applicationPolicies}
							updatePolicy={updatePolicyHandler}
							editThreshold={thresholdEditHandler}
						/>
					</div>
					<UpdatePolicySettingsModal
						ref={updatePolicySettingsModal}
					/>
				</PageWithHeader>
			</DataLoadFacade>
		);
	}
};

export default AnalyticsConfigurationPage;
