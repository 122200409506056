import { useRef } from "react";
import { ErrorToaster, SuccessToaster } from "@tir-ui/react-components";
import { STRINGS } from "app-strings";
import { FormModal } from 'components/common/modal/FormModal.tsx';
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { FileUploadField } from "components/common/form/FileUploadField.tsx";
import { ALLOW_MULTI_TYPE } from "components/enums/QueryParams.ts";
import CSVParserService from "utils/services/CSVParserService.ts";
import CREATE_CUSTOM_PROPERTIES_MUTATION from "./../queries/create-custom-properties-mutation.graphql";

interface CreateCustomPropertyMutationInput {
    name: string;
    description: string;
    values?: Array<{name: string}>;
    validTypes?: Array<{type: string}>;
}
interface CreateCustomPropertiesMutationInput {
    customProperties: Array<CreateCustomPropertyMutationInput>
}

export default function ImportCustomPropertiesModal(props) {
    const [createCustomProperties] = useMutation<
        any,
        CreateCustomPropertiesMutationInput
    >(CREATE_CUSTOM_PROPERTIES_MUTATION, {
        onCompleted: () => {
            SuccessToaster({
                message: "Success",
            });
        },
        onError: (err) => {
            ErrorToaster({
                message: "Failure",
            });
            console.error(err?.message);
        },
    });
    const fileFieldRef = useRef(null);
    const { existingProperties } = props;
    const MAX_FILE_SIZE = (1024 * 1024 * 1024); // Max size for a CSV file (1GB)
    const ACCEPTED_FILE_TYPES = `.csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/plain`;

    const validationSchema = yup.object().shape({
        attachment: yup
            .mixed()
            .label("File")
            .required()
            .test("FILE_SIZE", "Uploaded file is too big", () => {
                if (fileFieldRef.current === null) {
                    return true;
                }

                const file = getFileFromInput();

                if (file && file.size > MAX_FILE_SIZE) {
                    return false;
                }

                return true;
            }),
    });

    const formConfig = {
        initialValues: {
            attachment: null,
        },
        validationSchema: validationSchema,
        onSubmit: async () => {
            // The values are read from the input ref
            /* istanbul ignore next */
            const promise = new Promise((res, rej) => {
                try {
                    const file = getFileFromInput();
                    if (!file) {
                        rej(
                            STRINGS.CUSTOM_PROPERTIES_PAGE.importModal.errors
                                .FILE_ERROR
                        );

                        return;
                    }

                    const fileReader = new FileReader();
                    fileReader.onload = async function () {
                        const text: string = fileReader.result as string;

                        if (text && text.length > 0) {
                            const parsedProps =
                                parsePropertiesFromCSV(text);

                            if (parsedProps.toAdd.length > 0) {
                                try {
                                    const queryResult = await addProperties(parsedProps.toAdd);
                                    // Handle query errrors
                                    if (queryResult?.errors) {
                                        console.error(queryResult?.errors);
                                        rej(STRINGS.CUSTOM_PROPERTIES_PAGE.importModal.errors.GENERIC);
                                    }

                                    const wereAllPropertiesAdded = parsedProps.total === parsedProps.toAdd.length;

                                    wereAllPropertiesAdded ?
                                        res(STRINGS.CUSTOM_PROPERTIES_PAGE.importModal.success.all) :
                                        res(STRINGS.formatString(STRINGS.CUSTOM_PROPERTIES_PAGE.importModal.success.partial, parsedProps.total, parsedProps.toAdd.length))
                                } catch (error) {
                                    console.error(error);
                                    rej(STRINGS.CUSTOM_PROPERTIES_PAGE.importModal.errors.GENERIC);
                                }
                            } else {
                                rej(STRINGS.CUSTOM_PROPERTIES_PAGE.importModal.errors.NO_NEW);
                            }
                        } else {
                            rej(STRINGS.CUSTOM_PROPERTIES_PAGE.importModal.errors.GENERIC);
                        }
                    };
                    fileReader.readAsText(file);
                } catch (err) {
                    console.error(err);
                    rej(STRINGS.CUSTOM_PROPERTIES_PAGE.importModal.errors.GENERIC);
                }
            });

            try {
                const result = await promise;

                SuccessToaster({
                    message: result,
                });
            } catch (err) {
                return ErrorToaster({
                    message: err,
                });
            }
        },
    };

    return (
        <FormModal
            title={STRINGS.CUSTOM_PROPERTIES_PAGE.importModal.title}
            formikProps={formConfig}
            customLabels={{
                submit: STRINGS.CUSTOM_PROPERTIES_PAGE.importModal.buttons.submit,
                close: STRINGS.CUSTOM_PROPERTIES_PAGE.importModal.buttons.cancel,
            }}
            {...props}
        >
            <div>{STRINGS.CUSTOM_PROPERTIES_PAGE.importModal.helpText}</div>
            <div className="mt-2">
                <FileUploadField
                    fileRef={fileFieldRef}
                    name="attachment"
                    accepts={ACCEPTED_FILE_TYPES}
                />
            </div>
        </FormModal>
    );

    /* istanbul ignore next */
    /**
     * Parse properties from the CSV File
     *
     * @param text {string}
     *
     * @returns CustomProperties[]
     */
    function parsePropertiesFromCSV(text: string) {
        const properties = CSVParserService.convertFromCSV(text);

        const propertiesToAdd = properties.filter((el) => {
            const isExisting = existingProperties.includes(el.name);

            if (!isExisting) {
                return el;
            }

            return false;
        });

        return {
            total: properties.length,
            toAdd: propertiesToAdd
        };
    }

    //TODO Add cypress test that loads data from a file
    /* istanbul ignore next */
    /**
     * Add custom properties from the CSV file
     *
     * @param properties
     */
    async function addProperties(properties) {
        if (!properties || !properties.length) {
            return;
        }

        /**
         * 
         * @param property a line in the parsed CSV file
         * 
         * @returns CreateCustomPropertyMutationInput
         */
        function getQueryVariables(property) {
            const queryVariables: CreateCustomPropertyMutationInput = {
                name: property.name,
                description: property.description || '',
                validTypes: [],
                values: [],
            }

            // Handle Applicable To
            if (property.validTypes.includes('|')) {
                const validTypes = property.validTypes.split('|').map((prop) => {
                    return {
                        type: prop
                    }
                });

                queryVariables.validTypes = ALLOW_MULTI_TYPE ? validTypes : validTypes[0]
            }

            // One string value
            else if (property.validTypes.length > 0) {
                queryVariables.validTypes = [{ type: property.validTypes }]
            }

            // Handle Values
            if (property.values.includes('|')) {
                queryVariables.values = property.values.split('|').map((prop) => {
                    return {
                        name: prop
                    }
                });
            }
            // One string value
            else if (property.values.length > 0) {
                queryVariables.values = [{ name: property.values }]
            }

            return queryVariables;
        }

        const result = await createCustomProperties({
            variables: {
                customProperties: properties?.length ? properties.map(property => getQueryVariables(property)) : []
            }
        })

        return result;
    }

    /* istanbul ignore next */
    /**
     * Get the file element from the input
     *
     * @returns File
     */
    function getFileFromInput() {
        if (fileFieldRef.current === null) {
            return null;
        }

        const inputRef = fileFieldRef.current as HTMLInputElement;
        const file = inputRef.files && inputRef.files[0];

        return file;
    }
}
