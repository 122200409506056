/** This module defines a React component that displays the location search result.
 *  @module
 */
import type { SearchResultsItemProps } from "components/common/search/search-items/search-results-item/SearchResultsItem.tsx";
import { SearchItemHeader } from "components/common/search/search-item-header/SearchItemHeader.tsx";
import { LinksGroup, LinkType } from "components/common/search/search-links-group/LinksGroup.tsx";
import type { LinkInfo } from "components/common/search/search-links-group/LinksGroup.tsx";
import { SearchItemDesc } from "components/common/search/search-item-desc/SearchItemDesc.tsx";
import { STRINGS } from "app-strings";
import { FILTER_NAME } from 'components/sdwan/enums/filters.ts';
import { HIDDEN_STATES, INCIDENT_STATUS } from "components/enums/IncidentStatus.ts";
import { PRIORITY } from "components/enums/Priority.ts";
import { CompletionStatus } from "components/hyperion/controls/ongoing-incidents-filter/OngoingIncidentsFilter.tsx";
import { NULL_TIME_VALUE } from "utils/stores/GlobalTimeStore.ts";

/** Renders the search result for an location.
 *  @param data the properties passed in.
 *  @returns JSX with the location search result react component.*/
export function SearchLocationItem({ data }: SearchResultsItemProps) {
    const links: Array<LinkInfo> = [
        {
            path: "incidents",
            label: STRINGS.SEARCH.viewIncidents,
            type: LinkType.PAGE,
            params: {
                f: {
                    // Set the filter for the location
                    [FILTER_NAME.locationName]: data.formattedData.name, // + ";" + data.id
                    // Set all other filters, otherwise the preferences set the filters and you might not see the incident, I 
                    // don't like this, it means that every time a new filter is added this needs to be updated!  Cliford 
                    // added the feature that if a filter is absent it defaults to what is in the user preferences and if the 
                    // filter is specified in the URL it overrides the user preferences, so we must put every filter in the 
                    // URL to make sure they all override the user preferences.
                    [FILTER_NAME.priority]: [PRIORITY.CRITICAL, PRIORITY.HIGH, PRIORITY.MODERATE, PRIORITY.LOW],
                    [FILTER_NAME.incidentStatus]: [INCIDENT_STATUS.NEW, INCIDENT_STATUS.INVESTIGATING, INCIDENT_STATUS.ON_HOLD, INCIDENT_STATUS.DISMISSED, INCIDENT_STATUS.CLOSED].filter(status => !HIDDEN_STATES.includes(status)),
                    [FILTER_NAME.completionStatus]: CompletionStatus.ANY
                },
                t: NULL_TIME_VALUE
        }
/*
        },
        {
            path: "on-demand-runbooks",
            label: STRINGS.SEARCH.runOnDemand,
            type: LinkType.PAGE,
            params: {
                [PARAM_NAME.rbConfigId]: data.id,
                [PARAM_NAME.rbConfigNm]: data?.formattedData?.name
            }
*/
        }
    ];
    return <div className="d-flex flex-column flex-grow-2">
        <SearchItemHeader type={data.type} title={data.formattedData.title ? data.formattedData.title : ""} />
        <SearchItemDesc>
            {data.location.name}
            {data.location?.application?.name ? " " +  STRINGS.SEARCH.applicationText + " " + data.location.application.name : ""}
            {data.location?.reportedBy?.length ? " " +  STRINGS.SEARCH.seenOnText + " " + data.location.reportedBy.join(", ") : ""}
        </SearchItemDesc>
        <LinksGroup links={links} />
    </div>
}
 
