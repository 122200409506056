/** This module contains the component for displaying the user preferences page
 *  @module
 */

import { useState, useEffect } from "react";
import type React from "react";
import { AnchorButton, Button, Checkbox, HTMLSelect, InputGroup, Intent } from "@blueprintjs/core";
import { ErrorToaster, IconNames, SuccessToaster } from "@tir-ui/react-components";
import { STRINGS, type LangEN } from "app-strings";
import { BasicDialog, updateDialogState } from "components/common/basic-dialog/BasicDialog.tsx";
import { PageWithHeader } from "components/sdwan/layout/page-with-header/PageWithHeader.tsx";
import { JsonViewer } from "pages/incident-details/views/primary-indicator/JsonViewer.tsx";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useQueryParams } from "utils/hooks/useQueryParams.ts";
import { useUserPreferences } from "utils/hooks/useUserPreferences.ts";
import { PARAM_NAME } from "components/enums/QueryParams.ts";
import { clearAllUserPreferences, setUserPreferences } from "utils/stores/UserPreferencesStore.ts";
import { ThemeContext } from "utils/themes/manager/ThemeManager.tsx";
import { THEME } from "utils/themes/manager/constants.ts";
import { 
    DEFAULT_INCIDENT_PREF,
    DEFAULT_SEARCH_PREF, FACET_MODE, SEARCH_ENGINE, type SearchPreference, type UserPreferences 
} from "utils/services/UserPrefsTypes.ts";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade.tsx";
import './UserPreferencesPage.scss';

/** this constant has the environment that the UI is being executed in: development, staging, production. */
//const { ENV } = window["runConfig"] || {};
const ENV = import.meta.env.VITE_ENV_SHORT;

/** the environments on which subflow preferences should be exposed. */
const SUBFLOW_ENVS: string[] = ["dev", "staging"];
/** the environments on which NPM Plus preferences should be exposed. */
const NPM_PLUS_ENVS: string[] = ["dev", "staging"];

/** Renders the user preferences page.
 *  @param props the properties passed in.
 *  @returns JSX which shows the user preferences.*/
const UserPreferencesPage = (props): JSX.Element | null => {
    const userPreferences = useUserPreferences({listenOnlyTo: {
        theme: THEME.light,  
        search: {serverSideSortingAndPagination: false}, 
        subflows: {}, 
        menu: {showUserPreferences: false, showDashboards: false, showNpmPlus: false}}
    });
    const translations: LangEN = STRINGS;
    const [displayedPreferences, setDisplayedPreferences] = useState<Partial<UserPreferences>>({...userPreferences});
    
    useEffect(
        () => {
            setDisplayedPreferences(userPreferences);
        }, [userPreferences]
    );

    let { params } = useQueryParams({ listenOnlyTo: [ PARAM_NAME.debug ] });
    const showDebugInformation = params[PARAM_NAME.debug] === "true";
    const [dialogState, setDialogState] = useState<any>({showDialog: false, loading: false, title: translations.userPreferences.debugDialogTitle, dialogContent: null, dialogFooter: null});
    const [loading, setLoading] = useState<boolean>(false);

    const searchPrefs: SearchPreference = {...DEFAULT_SEARCH_PREF, ...displayedPreferences.search};
    const incidentPrefs = {...DEFAULT_INCIDENT_PREF, ...displayedPreferences.incidentPage};

    return (<>
        <BasicDialog dialogState={dialogState} className="user-preferences-debug-dialog" onClose={() => {
            setDialogState(updateDialogState(dialogState, false, false, []));
        }} />
        <PageWithHeader name="UserPreferencesPage"
            title={translations.userPreferences.title} icon={IconNames.USER} addPadding={true}
            showTimeBar={false}
            rightAlignedControls={showDebugInformation && <AnchorButton icon={IconNames.DIAGNOSIS} onClick={() => {
                showDebugDialog({preferences: userPreferences}, dialogState, setDialogState);
            }} minimal={true} />}

        >
            <div className="user-preferences-toolbar d-flex justify-content-start mb-2">
                <Button aria-label="user-preferences-reset-button" icon={IconNames.RESET} minimal
                    className="me-2" onClick={async (e) => { 
                        setLoading(true);
                        //setUserPrefClearInProgress(true);
                        try {
                            await clearAllUserPreferences();
                            setDisplayedPreferences({});
                            SuccessToaster({
                                message: translations.USER_PROFILE_PAGE.clearPrefsSuccess
                            });
                        } catch (ex) {
                            console.error(ex);
                            ErrorToaster({
                                message: translations.USER_PROFILE_PAGE.clearPrefsError
                            });
                        }
                        //setUserPrefClearInProgress(false);
                        setLoading(false);
                    }}
                    text={translations.userPreferences.resetButtonText}
                />
                <Button aria-label="user-preferences-whats-new-reset-button" icon={IconNames.RESET} minimal
                    className="me-2" onClick={async (e) => { 
                        setLoading(true);
                        //setUserPrefClearInProgress(true);
                        try {
                            await setUserPreferences({whatsNew: {version: "0.0", dontShowAgain: false}});
                            SuccessToaster({
                                message: translations.USER_PROFILE_PAGE.clearPrefsSuccess
                            });
                        } catch (ex) {
                            console.error(ex);
                            ErrorToaster({
                                message: translations.USER_PROFILE_PAGE.clearPrefsError
                            });
                        }
                        //setUserPrefClearInProgress(false);
                        setLoading(false);
                    }}
                    text={translations.userPreferences.resetWhatsNewButtonText}
                />
            </div>
            <DataLoadFacade loading={loading} error={undefined} data={{}} showContentsWhenLoading={true}>
            <div className="user-preferences" style={{width: "500px"}}>
                <div className="user-preferences-section my-2 px-4 pb-4" >
                    <div className="user-preferences-section-title mb-4 display-7">
                        <span>{translations.userPreferences.themeSection.title}</span>
                        <ThemeContext.Consumer>
                            {ctx => (
                                <Button aria-label="user-preferences-theme-reset-button" icon={IconNames.RESET} minimal className="ms-2" onClick={async (e) => { 
                                    setLoading(true);
                                    //setUserPrefClearInProgress(true);
                                    try {
                                        await setUserPreferences({theme: THEME.light});
                                        ctx.setTheme(THEME.light);
                                        SuccessToaster({
                                            message: translations.userPreferences.themeSection.clearSuccessText
                                        });
                                    } catch (ex) {
                                        console.error(ex);
                                        ErrorToaster({
                                            message: translations.userPreferences.themeSection.clearErrorText
                                        });
                                    }
                                    //setUserPrefClearInProgress(false);
                                    setLoading(false);
                                    }}
                                />
                            )}
                        </ThemeContext.Consumer>
                    </div>
                    <div className="user-preferences-section-settings display-8">
                        <label className="me-3 text-nowrap">{translations.userPreferences.themeSection.themeControl.label}</label>
                        <HTMLSelect aria-label="theme selection" className="standard-text-control-width"
                            value={displayedPreferences.theme || THEME.light}
                            options={[
                                {value: THEME.light, label: translations.userPreferences.themeSection.themeControl.lightOptionText}, 
                                {value: THEME.dark, label: translations.userPreferences.themeSection.themeControl.darkOptionText}
                            ]} 
                            onChange={(e) => {
                                const value = e.currentTarget.value;
                                const newPrefs = JSON.parse(JSON.stringify(displayedPreferences));
                                newPrefs.theme = value as THEME;
                                setDisplayedPreferences(newPrefs);
                            }
                        } />
                    </div>
                </div>
                <div className="user-preferences-section my-2 px-4 pb-4" >
                    <div className="user-preferences-section-title mb-4 display-7">
                        <span>{translations.userPreferences.searchSection.title}</span>
                        <Button aria-label="user-preferences-search-reset-button" icon={IconNames.RESET} minimal className="ms-2" onClick={async (e) => { 
                            setLoading(true);
                            //setUserPrefClearInProgress(true);
                            try {
                                await setUserPreferences({search: null});
                                SuccessToaster({
                                    message: translations.userPreferences.searchSection.clearSuccessText
                                });
                            } catch (ex) {
                                console.error(ex);
                                ErrorToaster({
                                    message: translations.userPreferences.searchSection.clearErrorText
                                });
                            }
                            //setUserPrefClearInProgress(false);
                            setLoading(false);
                            }}
                        />
                    </div>
                    <div className="user-preferences-section-settings display-8">
                        <table><tbody>
                            <tr>
                                <td><label className="me-3 text-nowrap">{translations.userPreferences.searchSection.facetControl.label}</label></td>
                                <td>
                                    <HTMLSelect aria-label="facet mode selection" className="standard-text-control-width"
                                        value={searchPrefs.facetMode}
                                        options={[
                                            {value: FACET_MODE.initial, label: translations.userPreferences.searchSection.facetControl.initialOptionText}, 
                                            {value: FACET_MODE.replace, label: translations.userPreferences.searchSection.facetControl.replaceOptionText}
                                        ]} 
                                        onChange={(e) => {
                                            const value = e.currentTarget.value;
                                            const newPrefs = JSON.parse(JSON.stringify(displayedPreferences));
                                            if (!newPrefs.search) {
                                                newPrefs.search = {};
                                            }
                                            newPrefs.search.facetMode = value as FACET_MODE;
                                            setDisplayedPreferences(newPrefs);
                                        }
                                    } />
                                </td>
                            </tr>
                            <tr>
                                <td><label className="me-3 text-nowrap">{translations.userPreferences.searchSection.pageSizeControl.label}</label></td>
                                <td>
                                    <InputGroup aria-label="page size entry" className="standard-text-control-width"
                                        type="number"
                                        value={String(searchPrefs.pageSize)}
                                        onChange={(e) => {
                                            const value = e.currentTarget.value;
                                            const newPrefs = JSON.parse(JSON.stringify(displayedPreferences));
                                            if (!newPrefs.search) {
                                                newPrefs.search = {};
                                            }
                                            try {
                                                newPrefs.search.pageSize = parseInt(value);
                                            } catch (error) {
                                                newPrefs.search.pageSize = -1;
                                            }
                                            newPrefs.search.pageSize = newPrefs.search.pageSize > 0 && newPrefs.search.pageSize <= 10000 ? newPrefs.search.pageSize : 10000;
                                            setDisplayedPreferences(newPrefs);
                                        }
                                    } />
                                </td>
                            </tr>
                            <tr>
                                <td><label className="me-3 text-nowrap">{translations.userPreferences.searchSection.searchLimitControl.label}</label></td>
                                <td>
                                    <InputGroup aria-label="max result entry" className="standard-text-control-width"
                                        type="number"
                                        value={String(searchPrefs.maxResultLimit)}
                                        onChange={(e) => {
                                            const value = e.currentTarget.value;
                                            const newPrefs = JSON.parse(JSON.stringify(displayedPreferences));
                                            if (!newPrefs.search) {
                                                newPrefs.search = {};
                                            }
                                            try {
                                                newPrefs.search.maxResultLimit = parseInt(value);
                                            } catch (error) {
                                                newPrefs.search.maxResultLimit = -1;
                                            }
                                            newPrefs.search.maxResultLimit = newPrefs.search.maxResultLimit > 0 && newPrefs.search.maxResultLimit <= 5000 ? newPrefs.search.maxResultLimit : 1000;
                                            setDisplayedPreferences(newPrefs);
                                        }
                                    } />
                                </td>
                            </tr>
                            <tr>
                                <td className="p-1" colSpan={2}><Checkbox type="checkbox" 
                                    label={translations.userPreferences.searchSection.searchLoadPagesOnDemandControl.label}
                                    checked={searchPrefs.serverSideSortingAndPagination === true}
                                    onChange={(event: any) => {
                                        const value = event.target.checked;
                                        const newPrefs = JSON.parse(JSON.stringify(displayedPreferences));
                                        if (!newPrefs.search) {
                                            newPrefs.search = {};
                                        }
                                        newPrefs.search.serverSideSortingAndPagination = value;
                                        setDisplayedPreferences(newPrefs);
                                    }}
                                /></td>
                            </tr>
                            <tr>
                                <td className="p-1" colSpan={2}><Checkbox type="checkbox" 
                                    label={translations.userPreferences.searchSection.allowBulkChangesControl.label}
                                    checked={searchPrefs.allowBulkChanges === true}
                                    onChange={(event: any) => {
                                        const value = event.target.checked;
                                        const newPrefs = JSON.parse(JSON.stringify(displayedPreferences));
                                        if (!newPrefs.search) {
                                            newPrefs.search = {};
                                        }
                                        newPrefs.search.allowBulkChanges = value;
                                        setDisplayedPreferences(newPrefs);
                                    }}
                                /></td>
                            </tr>
                            <tr>
                                <td className="p-1" colSpan={2}><Checkbox type="checkbox" 
                                    label={translations.userPreferences.searchSection.showSearchQueriesControl.label}
                                    checked={searchPrefs.showQueries === true}
                                    onChange={(event: any) => {
                                        const value = event.target.checked;
                                        const newPrefs = JSON.parse(JSON.stringify(displayedPreferences));
                                        if (!newPrefs.search) {
                                            newPrefs.search = {};
                                        }
                                        newPrefs.search.showQueries = value;
                                        setDisplayedPreferences(newPrefs);
                                    }}
                                /></td>
                            </tr>
                            <tr>
                                <td className="p-1" colSpan={2}><Checkbox type="checkbox" 
                                    label={translations.userPreferences.searchSection.showSearchTypeControl.label}
                                    checked={searchPrefs.showTypeControl === true}
                                    onChange={(event: any) => {
                                        const value = event.target.checked;
                                        const newPrefs = JSON.parse(JSON.stringify(displayedPreferences));
                                        if (!newPrefs.search) {
                                            newPrefs.search = {};
                                        }
                                        newPrefs.search.showTypeControl = value;
                                        setDisplayedPreferences(newPrefs);
                                    }}
                                /></td>
                            </tr>
                            <tr>
                                <td className="p-1" colSpan={2}><Checkbox type="checkbox" 
                                    label={translations.userPreferences.searchSection.sortFacetsControl.label}
                                    checked={searchPrefs.sortFacets === true}
                                    onChange={(event: any) => {
                                        const value = event.target.checked;
                                        const newPrefs = JSON.parse(JSON.stringify(displayedPreferences));
                                        if (!newPrefs.search) {
                                            newPrefs.search = {};
                                        }
                                        newPrefs.search.sortFacets = value;
                                        setDisplayedPreferences(newPrefs);
                                    }}
                                /></td>
                            </tr>
                            <tr>
                                <td className="p-1" colSpan={2}><Checkbox type="checkbox" 
                                    label={translations.userPreferences.searchSection.saveLastSearchQueryControl.label}
                                    checked={searchPrefs.saveLastSearchToPreferences === true}
                                    onChange={(event: any) => {
                                        const value = event.target.checked;
                                        const newPrefs = JSON.parse(JSON.stringify(displayedPreferences));
                                        if (!newPrefs.search) {
                                            newPrefs.search = {};
                                        }
                                        newPrefs.search.saveLastSearchToPreferences = value;
                                        setDisplayedPreferences(newPrefs);
                                    }}
                                /></td>
                            </tr>
                            <tr>
                                <td><label className="me-3 text-nowrap">{translations.userPreferences.searchSection.searchEngineControl.label}</label></td>
                                <td>
                                    <HTMLSelect aria-label="search engine selection" className="standard-text-control-width"
                                        value={searchPrefs.srchEngine}
                                        options={[
                                            {value: SEARCH_ENGINE.correlation_direct, label: translations.userPreferences.searchSection.searchEngineControl.correlationDirectText},
                                            {value: SEARCH_ENGINE.correlation_dal, label: translations.userPreferences.searchSection.searchEngineControl.correlationDalText}
                                        ]} 
                                        onChange={(e) => {
                                            const value = e.currentTarget.value;
                                            const newPrefs = JSON.parse(JSON.stringify(displayedPreferences));
                                            if (!newPrefs.search) {
                                                newPrefs.search = {};
                                            }
                                            newPrefs.search.srchEngine = value as SEARCH_ENGINE;
                                            setDisplayedPreferences(newPrefs);
                                        }
                                    } />
                                </td>
                            </tr>
                            <tr>
                                <td className="p-1" colSpan={2}><Checkbox type="checkbox" 
                                    label={translations.userPreferences.searchSection.showAdvancedSystemProperties.label}
                                    checked={searchPrefs.showAdvancedSystemProperties === true}
                                    onChange={(event: any) => {
                                        const value = event.target.checked;
                                        const newPrefs = JSON.parse(JSON.stringify(displayedPreferences));
                                        if (!newPrefs.search) {
                                            newPrefs.search = {};
                                        }
                                        newPrefs.search.showAdvancedSystemProperties = value;
                                        setDisplayedPreferences(newPrefs);
                                    }}
                                /></td>
                            </tr>
                        </tbody></table>
                    </div>
                </div>
                {SUBFLOW_ENVS.includes(ENV) && renderSubflowsConfigSection()}
                <div className="user-preferences-section my-2 px-4 pb-4" >
                    <div className="user-preferences-section-title mb-4 display-7">
                        <span>{translations.userPreferences.menuSection.title}</span>
                        <Button aria-label="user-preferences-menu-reset-button" icon={IconNames.RESET} minimal className="ms-2" onClick={async (e) => { 
                            setLoading(true);
                            //setUserPrefClearInProgress(true);
                            try {
                                await setUserPreferences({menu: null});
                                SuccessToaster({
                                    message: translations.userPreferences.menuSection.clearSuccessText
                                });
                            } catch (ex) {
                                console.error(ex);
                                ErrorToaster({
                                    message: translations.userPreferences.menuSection.clearErrorText
                                });
                            }
                            //setUserPrefClearInProgress(false);
                            setLoading(false);
                            }}
                        />
                    </div>
                    <div className="user-preferences-section-settings display-8">
                        <Checkbox type="checkbox" 
                            label={translations.userPreferences.menuSection.menuUserPreferencesControl.label}
                            checked={displayedPreferences.menu?.showUserPreferences === true}
                            onChange={(event: any) => {
                                const value = event.target.checked;
                                const newPrefs = JSON.parse(JSON.stringify(displayedPreferences));
                                if (!newPrefs.menu) {
                                    newPrefs.menu = {};
                                }
                                newPrefs.menu.showUserPreferences = value;
                                setDisplayedPreferences(newPrefs);
                            }}
                        />
                        <Checkbox type="checkbox" 
                            label={translations.userPreferences.menuSection.menuDashboardsControl.label}
                            checked={displayedPreferences.menu?.showDashboards === true}
                            onChange={(event: any) => {
                                const value = event.target.checked;
                                const newPrefs = JSON.parse(JSON.stringify(displayedPreferences));
                                if (!newPrefs.menu) {
                                    newPrefs.menu = {};
                                }
                                newPrefs.menu.showDashboards = value;
                                setDisplayedPreferences(newPrefs);
                            }}
                        />
                        {NPM_PLUS_ENVS.includes(ENV) && <Checkbox type="checkbox" 
                            label={translations.userPreferences.menuSection.menuNpmPlusControl.label}
                            checked={displayedPreferences.menu?.showNpmPlus === true}
                            onChange={(event: any) => {
                                const value = event.target.checked;
                                const newPrefs = JSON.parse(JSON.stringify(displayedPreferences));
                                if (!newPrefs.menu) {
                                    newPrefs.menu = {};
                                }
                                newPrefs.menu.showNpmPlus = value;
                                setDisplayedPreferences(newPrefs);
                            }}
                        />}
                        <Checkbox type="checkbox" 
                            label={translations.userPreferences.menuSection.menuCloudIMDemoModeControl.label}
                            checked={displayedPreferences.menu?.showCloudIMDemoMode === true}
                            onChange={(event: any) => {
                                const value = event.target.checked;
                                const newPrefs = JSON.parse(JSON.stringify(displayedPreferences));
                                if (!newPrefs.menu) {
                                    newPrefs.menu = {};
                                }
                                newPrefs.menu.showCloudIMDemoMode = value;
                                setDisplayedPreferences(newPrefs);
                            }}
                        />
                    </div>
                </div>
                <div className="user-preferences-section my-2 px-4 pb-4" >
                    <div className="user-preferences-section-title mb-4 display-7">
                        <span>{translations.userPreferences.runbookInputsSection.title}</span>
                        <Button aria-label="user-preferences-menu-reset-button" icon={IconNames.RESET} minimal className="ms-2" onClick={async (e) => { 
                            setLoading(true);
                            //setUserPrefClearInProgress(true);
                            try {
                                await setUserPreferences({runbookInputs: null});
                                SuccessToaster({
                                    message: translations.userPreferences.runbookInputsSection.clearSuccessText
                                });
                            } catch (ex) {
                                console.error(ex);
                                ErrorToaster({
                                    message: translations.userPreferences.runbookInputsSection.clearErrorText
                                });
                            }
                            //setUserPrefClearInProgress(false);
                            setLoading(false);
                            }}
                        />
                    </div>
                    <div className="user-preferences-section-settings display-8">
                        {displayedPreferences.runbookInputs ? "Set" : "Unset"}
                    </div>
                </div>
                <div className="user-preferences-section my-2 px-4 pb-4" >
                    <div className="user-preferences-section-title mb-4 display-7">
                        <span>{translations.userPreferences.navigatorSection.title}</span>
                        <Button aria-label="user-preferences-menu-reset-button" icon={IconNames.RESET} minimal className="ms-2" onClick={async (e) => { 
                            setLoading(true);
                            //setUserPrefClearInProgress(true);
                            try {
                                await setUserPreferences({navigator: null});
                                SuccessToaster({
                                    message: translations.userPreferences.navigatorSection.clearSuccessText
                                });
                            } catch (ex) {
                                console.error(ex);
                                ErrorToaster({
                                    message: translations.userPreferences.navigatorSection.clearErrorText
                                });
                            }
                            //setUserPrefClearInProgress(false);
                            setLoading(false);
                            }}
                        />
                    </div>
                    <div className="user-preferences-section-settings display-8">
                        {displayedPreferences.navigator ? "Set" : "Unset"}
                    </div>
                </div>
                {/* Runbook Export Method */}
                <div className="user-preferences-section my-2 px-4 pb-4" >
                    <div className="user-preferences-section-title mb-4 display-7">
                        <span>{translations.userPreferences.runbookExportSection.title}</span>
                        <Button 
                            aria-label="user-preferences-export-method" 
                            icon={IconNames.RESET} 
                            minimal 
                            className="ms-2" 
                            onClick={async () => { 
                                setLoading(true);

                                try {
                                    await setUserPreferences({runbookExportMethod: {useSimpleExportRunbook: false}});
                                    SuccessToaster({
                                        message: translations.userPreferences.runbookExportSection.clearSuccessText
                                    });
                                } catch (ex) {
                                    console.error(ex);
                                    ErrorToaster({
                                        message: translations.userPreferences.runbookExportSection.clearErrorText
                                    });
                                }
                                setLoading(false);
                            }}
                        />
                    </div> 
                    <div className="user-preferences-section-settings display-8">
                        <Checkbox 
                            type="checkbox" 
                            data-testid="userPrefs-runbookExportSection"
                            label={translations.userPreferences.runbookExportSection.control.label}
                            checked={displayedPreferences.runbookExportMethod?.useSimpleExportRunbook === true}
                            onChange={(event: any) => {
                                const value = event.target.checked;
                                const newPrefs = JSON.parse(JSON.stringify(displayedPreferences));
        
                                if (!newPrefs?.runbookExportMethod) {
                                    newPrefs.runbookExportMethod = {};
                                }
        
                                newPrefs.runbookExportMethod.useSimpleExportRunbook = value;
                                setDisplayedPreferences(newPrefs);
                            }} 
                        />
                    </div>
                </div>
                <div className="user-preferences-section my-2 px-4 pb-4" >
                    <div className="user-preferences-section-title mb-4 display-7">
                        <span>{translations.userPreferences.cloudIMSection.title}</span>
                        <Button aria-label="user-preferences-menu-reset-button" icon={IconNames.RESET} minimal className="ms-2" onClick={async (e) => { 
                            setLoading(true);
                            try {
                                await setUserPreferences({cloudIM: null});
                                SuccessToaster({
                                    message: translations.userPreferences.cloudIMSection.clearSuccessText
                                });
                            } catch (ex) {
                                console.error(ex);
                                ErrorToaster({
                                    message: translations.userPreferences.cloudIMSection.clearErrorText
                                });
                            }
                            setLoading(false);
                            }}
                        />
                    </div>
                    <div className="user-preferences-section-settings display-8">
                        <Checkbox type="checkbox" 
                            label={translations.userPreferences.cloudIMSection.legalAccepted}
                            checked={displayedPreferences.cloudIM?.legal?.acceptLegal === true}
                            onChange={(event: any) => {
                                const value = event.target.checked;
                                const newPrefs = JSON.parse(JSON.stringify(displayedPreferences));
                                if (!newPrefs.cloudIM) {
                                    newPrefs.cloudIM = {};
                                }
                                newPrefs.cloudIM.legal.acceptLegal = value;
                                setDisplayedPreferences(newPrefs);
                            }}
                        />
                    </div>
                    <div className="user-preferences-section-settings display-8">
                        <label className="mr-3 text-nowrap">{translations.userPreferences.cloudIMSection.maxZoom}</label>
                        <InputGroup className="small-text-control-width"
                            type="number"
                            value={String(displayedPreferences.cloudIM?.maxZoom)}
                            onChange={(e) => {
                                const value = e.currentTarget.value;
                                const newPrefs = JSON.parse(JSON.stringify(displayedPreferences));
                                if (!newPrefs.cloudIM) {
                                    newPrefs.cloudIM = {};
                                }
                                try {
                                    newPrefs.cloudIM.maxZoom = parseFloat(value);
                                } catch (error) {
                                    newPrefs.cloudIM.maxZoom = -1;
                                }
                                newPrefs.cloudIM.maxZoom = newPrefs.cloudIM.maxZoom >= 1 && newPrefs.cloudIM.maxZoom <= 5000 ? newPrefs.cloudIM.maxZoom : 16;
                                setDisplayedPreferences(newPrefs);
                            }
                        } />
                    </div>
                </div>
                <div className="user-preferences-section my-2 px-4 pb-4" >
                    <div className="user-preferences-section-title mb-4 display-7">
                        <span>{translations.userPreferences.incidentPageSection.title}</span>  
                        <Button aria-label="user-preferences-menu-reset-button" icon={IconNames.RESET} minimal className="ms-2" onClick={async (e) => { 
                            setLoading(true);
                            try {
                                await setUserPreferences({incidentPage: null});
                                SuccessToaster({
                                    message: translations.userPreferences.incidentPageSection.clearSuccessText
                                });
                            } catch (err) {
                                console.error(err);
                                ErrorToaster({
                                    message: translations.userPreferences.incidentPageSection.clearErrorText
                                });
                            }
                            setLoading(false);
                            }}
                        />
                    </div>
                    <div className="user-preferences-section-settings display-8">
                        <Checkbox type="checkbox" 
                            label={'Enable Timeline Chart'}
                            checked={incidentPrefs.timelineChart === true}
                            onChange={(event: any) => {
                                const value = event.target.checked;
                                const newPrefs = JSON.parse(JSON.stringify(displayedPreferences));
                                if (!newPrefs.incidentPage) {
                                    newPrefs.incidentPage = {};
                                }
                                newPrefs.incidentPage.timelineChart = value;
                                setDisplayedPreferences(newPrefs);
                            }}
                        />
                        <Checkbox type="checkbox" 
                            label={'Hide Incident Source Chart'}
                            checked={incidentPrefs.incidentSources === true}
                            onChange={(event: any) => {
                                const value = event.target.checked;
                                const newPrefs = JSON.parse(JSON.stringify(displayedPreferences));
                                if (!newPrefs.incidentPage) {
                                    newPrefs.incidentPage = {};
                                }
                                newPrefs.incidentPage.incidentSources = value;
                                setDisplayedPreferences(newPrefs);
                            }}
                        />
                    </div>
                </div>
                <br /><br />
                <div className="user-preferences-button-bar d-flex justify-content-end">
                    <ThemeContext.Consumer>
                        {ctx => (
                            <Button aria-label="user-preferences-apply-button" 
                                text={translations.userPreferences.applyButtonText} 
                                intent={Intent.PRIMARY}
                                className="w-1"
                                onClick={(event) => {
                                    saveUserPreferences(displayedPreferences, userPreferences, ctx, setLoading);
                                }} 
                            />
                        )}
                    </ThemeContext.Consumer>
                </div>
            </div>
            </DataLoadFacade>
        </PageWithHeader>
    </>);

    /**
     * Render the subflow configuration section
     * @returns JSX
     */
    function renderSubflowsConfigSection(): React.ReactElement {
        return <div className="user-preferences-section my-2 px-4 pb-4">
            <div className="user-preferences-section-title mb-4 display-7">
                <span>{translations.userPreferences.subflowsSection.title}</span>
                <Button aria-label="user-preferences-subflows-reset-button" icon={IconNames.RESET} minimal className="ms-2" onClick={async (e) => {
                    setLoading(true);
                    //setUserPrefClearInProgress(true);
                    try {
                        await setUserPreferences({ subflows: null });
                        SuccessToaster({
                            message: translations.userPreferences.subflowsSection.clearSuccessText
                        });
                    } catch (ex) {
                        console.error(ex);
                        ErrorToaster({
                            message: translations.userPreferences.subflowsSection.clearErrorText
                        });
                    }
                    //setUserPrefClearInProgress(false);
                    setLoading(false);
                } } />
            </div>
            <div className="user-preferences-section-settings display-8 mt-2">
                <Checkbox
                    type="checkbox"
                    label={translations.userPreferences.subflowsSection.multipleOutputs.label}
                    checked={displayedPreferences.subflows?.isMultipleOutputsEnabled === true}
                    onChange={(event: any) => {
                        const value = event.target.checked;
                        const newPrefs = JSON.parse(JSON.stringify(displayedPreferences));

                        if (!newPrefs.subflows) {
                            newPrefs.subflows = {};
                        }

                        newPrefs.subflows.isMultipleOutputsEnabled = value;
                        setDisplayedPreferences(newPrefs);
                    }} 
                />
                <Checkbox type="checkbox" 
                    label={translations.userPreferences.subflowsSection.addMenuIntegrationsControl.label}
                    checked={displayedPreferences.subflows?.showAddMenuIntegrationFolder === true}
                    onChange={(event: any) => {
                        const value = event.target.checked;
                        const newPrefs = JSON.parse(JSON.stringify(displayedPreferences));
                        if (!newPrefs.subflows) {
                            newPrefs.subflows = {};
                        }
                        newPrefs.subflows.showAddMenuIntegrationFolder = value;
                        setDisplayedPreferences(newPrefs);
                    }}
                />
                <Checkbox type="checkbox" 
                    label={translations.userPreferences.subflowsSection.addMenuVersionsControl.label}
                    checked={displayedPreferences.subflows?.showAddMenuVersions === true}
                    onChange={(event: any) => {
                        const value = event.target.checked;
                        const newPrefs = JSON.parse(JSON.stringify(displayedPreferences));
                        if (!newPrefs.subflows) {
                            newPrefs.subflows = {};
                        }
                        newPrefs.subflows.showAddMenuVersions = value;
                        setDisplayedPreferences(newPrefs);
                    }}
                />
            </div>
        </div>;
    }
}

export default UserPreferencesPage;

/** saves the user preferences to the back-end
 *  @param updatedPreference the reference with the updated preferences.
 *  @param currentUserPreferences the UserPreferences object with the current user preferences.
 *  @param ctx the theme context.
 *  @param setLoading the function to update the loading state.
 *  @returns a promise that resolves to void. */
async function saveUserPreferences(
    updatedPreferences: UserPreferences, currentUserPreferences: UserPreferences, ctx: any, setLoading: (loading: boolean) => void
): Promise<void> {
    try {
        setLoading(true);
        await setUserPreferences(updatedPreferences);
        updatedPreferences = {theme: updatedPreferences.theme};
        if (currentUserPreferences.theme !== updatedPreferences.theme) {
            ctx.setTheme(updatedPreferences.theme);
        }
        setLoading(false);    
    } catch (error) {
        setLoading(false);    
        console.error("Error saving user preferences");
    }
}

/** Creates a popup that displays the user preferences in the debug dialog.
 *  @param userPreferences the user preferences in JSON format.
 *  @param dialogState the copied state object with the state setup to open the dialog.  The content
 *      needs to be appended and the title needs to be set in this function.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function. */
function showDebugDialog(
    userPreferences: Record<string, any>, dialogState:any, setDialogState: (dialogState: any) => void
): void {
    const newDialogState = Object.assign({}, dialogState);
    const translations: LangEN = STRINGS;

    newDialogState.showDialog = true;
    newDialogState.dialogContent = <JsonViewer json={userPreferences} />;
    newDialogState.dialogFooter = <>
        <CopyToClipboard text={JSON.stringify(userPreferences || {}, null, 4)}>
            <Button active={true} outlined={true} 
                text={translations.primaryIndicatorView.copyBtnText} onClick={() => {
                    setDialogState(updateDialogState(newDialogState, false, false, []));
                }}
            />
        </CopyToClipboard>
        <Button active={true} outlined={true}
            text={translations.userPreferences.okBtnText}
            onClick={async (evt) => {
                setDialogState(updateDialogState(newDialogState, false, false, []));
            }}
        />
    </>;
    setDialogState(newDialogState);
}

