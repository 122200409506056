/** This module contains the component for displaying the incident details sub-header.
 *  @module
 */

import { Alignment, Classes, NavbarGroup } from "@blueprintjs/core";
import { SelectedListItemStyles, SelectInput } from "components/common/select/SelectInput.tsx";
import classNames from "classnames";
import { getIncidentStatusItemsListForDropdown } from "components/enums/IncidentStatus.ts";
import { SIZE } from "components/enums/Sizes.ts";
import { GENERAL_COLORS } from "components/enums/Colors.ts";
import { ElapsedTimeFormatter } from "reporting-infrastructure/utils/formatters/elapsed-time-formatter/elapsed-time-formatter.tsx";
import { parseTimeFromDAL } from "utils/hooks/useQueryFormatters.ts";
import { STRINGS } from "app-strings";
import { formatDurationToString } from 'reporting-infrastructure/utils/formatters/GeneralFormatter.ts';
import { StatusLED } from 'components/common/status-led/StatusLED.tsx';
import { SHOW_DETECTIONS } from 'components/enums/QueryParams.ts';
import type { Incident } from 'pages/incident-list/Incident.type.ts';
import './IncidentDetailSubHeader.scss';

/** the properties passed in to the React component. */
type IncidentDetailSubHeaderProps = {
    /** the handler for incident status changes. */
    onIncidentStatusChange: (item: any) => void;
    /** the Incident that is being displayed. */
    incident: Incident;
    /** the current status of the incident. */
    status?: string;
};

/** Renders the incident details subheader.
 *  @param props the properties passed into the component.
 *  @returns JSX with the incident details subheader component.*/
export const IncidentDetailSubHeader = (props: IncidentDetailSubHeaderProps) => {
    const startTime = parseTimeFromDAL(props.incident.createdAt);
    const endTime = props.incident.endTime && parseTimeFromDAL(props.incident.endTime);
    const durationString = startTime && endTime && formatDurationToString((endTime.getTime() - startTime.getTime()) / 1000, true);
    return (
        <NavbarGroup align={ Alignment.LEFT } className="sub-header-left-section">
            <SelectInput
                className={ classNames(Classes.MINIMAL) }
                items={ getIncidentStatusItemsListForDropdown() }
                selectedItemStyle={ SelectedListItemStyles.TICK }
                selectedValue={ props.status }
                onItemSelect={ item => {
                    props.onIncidentStatusChange(item);
                } }
            />
            <ElapsedTimeFormatter
                time={ startTime }
                showOriginal
                showOriginalFirst
                prefixForOriginal={ STRINGS.incidents.headerStartedPrefix }
                suffix={ STRINGS.incidents.elapsedSuffix }
            />
            {
                startTime && endTime ?
                <ElapsedTimeFormatter
                    time={ endTime }
                    compareToTime={ startTime }
                    showOriginal
                    showOriginalFirst
                    prefixForOriginal={ STRINGS.incidents.headerEndedPrefix }
                    prefix={ STRINGS.incidents.lastedPrefix }
                    subText={durationString && ("(" + durationString + ")")}
                /> :
                <StatusLED size={SIZE.s} label={STRINGS.incidents.ongoing} color={GENERAL_COLORS.success} className="me-3" iconClassName="throb"/>
            }
            {
                SHOW_DETECTIONS && props.incident.createdAt !== props.incident.lastUpdatedAt &&
                <ElapsedTimeFormatter
                    time={ parseTimeFromDAL(props.incident.lastUpdatedAt) }
                    showOriginal
                    showOriginalFirst
                    prefixForOriginal={ STRINGS.incidents.headerUpdatedPrefix }
                    suffix={ STRINGS.incidents.elapsedSuffix }
                />
            }
        </NavbarGroup>
    );
}
