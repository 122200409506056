/** This module defines the header with help information React component.  The page header is displayed at the top
 *      of each page of the hyperion app and shows the title of the page, an optional icon, controls, 
 *      and a help icon.
 *  @module */
import type React from 'react';
import { useCallback } from 'react';
import { Button, PopoverPosition, Classes, Popover } from '@blueprintjs/core';
import { Icon, IconNames } from '@tir-ui/react-components';
import { STRINGS } from 'app-strings';
import { AuthServiceProvider } from 'utils/providers/AuthServiceProvider.ts';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { HelpSources, EventNames, trackEvent } from 'utils/appinsights/AppInsights.ts';
import type { UserAssistanceValue } from 'types/UserAssistance.ts';
import DOMPurify from 'dompurify';

/** this constant refers to the auth service where you can get the tenant, user, etc. */
const AuthService = AuthServiceProvider.getService();

/** This interface defines the properties passed into the header React component.*/
export interface InlineHelpProps {
    /** Header element that will have the help popover appended to */
    children: React.ReactNode | Function
    /** the help information to show if any. */
    helpInfo?: string;
    /** URL for documentation redirect */
    helpUrl?: string;
    /** The user assistence mapping providing both the text and the URL 
     * Using a mapping no longer requires setting the helpInfo or the helpUrl
    */
    helpMapping?: UserAssistanceValue;
    /** props passed to popover */
    popoverProps?: any;
    /** the class to add to the main div for the inline help. */
    className?: any;
}

const HELP_URI = import.meta.env.VITE_HELP_URI ? import.meta.env.VITE_HELP_URI : { HELP_URI: '' };

/** Renders the page header component.
 *  @param props the properties passed in.
 *  @returns JSX with the page header React component.*/
export function InlineHelp(props: InlineHelpProps) {
    const appInsightsContext = useAppInsightsContext();

    const convertStringPathToText = useCallback((objPath: string) => {
        objPath = objPath.substring(3); // TODO REMOVE after removing "en." from strings
        const res = objPath.split('.').reduce(function (o, k) {
            return o && o[k];
        }, STRINGS)
        return res;
    }, []);

    let text = props.helpMapping ? convertStringPathToText(props.helpMapping.helpSummary) : props.helpInfo;
    if (text && text.indexOf("http") < 0) {
        text = text.replaceAll('href="',`href="${HELP_URI}/IQ/Content/`);
    }
    const url = props.helpMapping ? props.helpMapping.url : props.helpUrl;

    const popoverContent = (
        <div className="d-flex flex-column p-2 w-max-3">
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) ?? '' }} />
            {url &&
                <Button
                    className="p-1 ms-auto"
                    icon="more"
                    minimal
                    onClick={
                        () => {
                            window.open(`${HELP_URI}${url}`);
                            if (appInsightsContext) {
                                const properties = {
                                    name: EventNames.HELP_INVOKED,
                                    properties: {
                                        topic: url,
                                        type: HelpSources.FIELD
                                    }
                                };
                                trackEvent(appInsightsContext, AuthService, properties);
                            }
                        }
                    } />
            }
        </div>);

    return <div
            className={"position-relative d-flex align-items-center" + props.className ? props.className : ''}
        >
            {props.children}
            {text && <Popover
                className={Classes.TOOLTIP_INDICATOR + " border-0"}
                usePortal={true}
                content={popoverContent}
                position={PopoverPosition.RIGHT}
                transitionDuration={50}
                onOpened={() => {
                    if (appInsightsContext) {
                        const properties = {
                            name: EventNames.HELP_POPUP_INVOKED,
                            properties: {
                                topic: url,
                                type: HelpSources.FIELD
                            }
                        };
                        trackEvent(appInsightsContext, AuthService, properties);
                    }
                }}
                {...props.popoverProps}
            >
                <Icon className='d-flex ps-1 text-secondary position-relative' icon={IconNames.HELP} style={{top: "2px"}}/>
            </Popover>}
        </div>
}
