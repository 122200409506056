import React from "react";
import moment from "moment";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import {
    TimeChart,
    TimeChartDatum,
} from "components/common/time-chart/TimeChart";
import { LineStyle } from "components/common/chart-base/ChartToolbar";
import { Unit } from "reporting-infrastructure/types/Unit.class";

import { STRINGS } from "app-strings";

/** the properties passed into the React component. */
interface NodesTotalGraphProps {
    loading: boolean;
    data: Array<any> | undefined;
}

/** Renders the Nodes Total graph.
 *  @param props the properties passed in.
 *  @returns JSX with the graph.*/
export const NodesTotalGraph = (props: NodesTotalGraphProps): JSX.Element => {
    const { data, loading } = props;
    const tsKeyData: Array<TimeChartDatum> = [];

    if (!loading && data && "items" in data) {
        const primaryData = data["items"].sort((a: any, b: any) => {
            return a.timestamp - b.timestamp;
        });

        if (primaryData && primaryData.length > 0) {
            let ts: Array<any> = [];
            for (let i = 0; i < primaryData.length; i++) {
                if (primaryData[i]) {
                    ts.push({
                        x: primaryData[i]["timestamp"] * 1000,
                        y:
                            primaryData[i]["weightedCount"] ||
                            primaryData[i]["weightedCount"] === 0
                                ? primaryData[i]["weightedCount"]
                                : null,
                    });
                }
            }

            tsKeyData.push({
                groupName:
                    STRINGS.USAGE_MONITORING_PAGE.graphs.nodesTotal.metricName,
                groupId: "nodes-usage",
                metricName:
                    STRINGS.USAGE_MONITORING_PAGE.graphs.nodesTotal.metricName,
                metricId: "nodes-usage",
                unit: new Unit("count"),
                data: ts,
            });
        }
    }

    const chartOptions: Highcharts.Options = {
        chart: {},
        legend: {
            enabled: false,
            align: "left",
            verticalAlign: "bottom",
            floating: true,
        },
        plotOptions: {
            series: {
                connectNulls: false,
            },
        },
        tooltip: {
            formatter: function (this: any) {
                let toolTip = moment.utc(this.x).format("MMM Do").toString();
                for (let i in this.points) {
                    const symbol = "&#9632;";
                    toolTip +=
                        '<div><span style="font-size:16px;color:' +
                        this.points[i].color +
                        '">' +
                        symbol +
                        "</span>" +
                        "<b><span> " +
                        this.points[i].series.name +
                        "</span></b>: <b>" +
                        new Intl.NumberFormat().format(this.points[i].y);
                }
                +"</b></div>";
                return toolTip;
            },
        },
        xAxis: {
            labels: {
                formatter: function () {
                    return moment.utc(this.value).format("MMM Do").toString();
                },
                step: 0,
                enabled: true,
            },
        },
    };

    return (
        <React.Fragment>
            <DataLoadFacade
                loading={loading}
                data={data}
                showContentsWhenLoading={true}
                transparent={true}
            >
                <TimeChart
                    loading={loading}
                    showChartSubtitle={false}
                    height="350px"
                    options={chartOptions}
                    primaryData={tsKeyData}
                    settings={{ style: LineStyle.bar, showMore: false }}
                    transparent={true}
                    enableFullScreen={false}
                />
            </DataLoadFacade>
        </React.Fragment>
    );
};
