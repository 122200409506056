/** This module contains the main ui React Component which is invoked in App.tsx
 *  @module
 */
import { useEffect, useState, useMemo } from 'react';
import { MainLayout } from '../layouts/main-layout/MainLayout.tsx';
import { LoadingOverlay } from '@tir-ui/react-components';
import { GetRoutes as getRoutes } from 'config/routes.ts';
import { SideMenu } from 'components/app/layouts/side-menu/SideMenu.tsx';
import { ErrorPage, type ErrorProps } from 'pages-unrouted/error/ErrorPage.tsx';
import { ThemeManager, ThemeContext } from 'utils/themes/manager/ThemeManager.tsx';
import { THEME } from 'utils/themes/manager/constants.ts';
import { init as globalTimeStoreInit } from 'utils/stores/GlobalTimeStore.ts';
import { init as globalEventMappingsStoreInit } from 'utils/stores/GlobalEventMappingsStore.ts';
import { retrieveTenantFeatureFlags } from 'utils/stores/FeatureFlagStore.ts';
import { setDataOceanMetadata } from 'utils/stores/GlobalUnitsStore.ts';
import { init as globalDataSourceTypeStoreInit } from 'utils/stores/GlobalDataSourceTypeStore.ts';
import { DataOceanUtils } from 'components/common/graph/editors/data-ocean/DataOceanUtils.ts';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';

// TODO: may not be necessary to import these here instead of `App.tsx`
//import 'utils/themes/default/default.scss';
//import "styles/main.scss";

interface Props {
    shouldSkipGlobalDataStores?: boolean;
}

/** Renders the main UI component under Tiramisu.  All post-authentication initializations are handled here
 *  @param props the properties passed in.
 *  @returns JSX with the main UI component under the Tiramisu components.*/
function Main({ shouldSkipGlobalDataStores = false }: Props): JSX.Element {
    const ROUTES = getRoutes();
    
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<ErrorProps>();
    const [theme, setTheme] = useState<THEME>(THEME.light);

    const router = useMemo(() => {
        const routeElements = Object.entries(ROUTES).map(([key, route]) => ({
            path: route.path,
            element: route.component ? <route.component /> : undefined,
            handle: {
                title: route.title,
                icon: route.icon,
                featureFlag: route.featureFlag,
                bannedPermissions: route.bannedPermissions,
            },
        }));

      return createBrowserRouter([
        {
          element: <MainLayout defaultRoute={ROUTES["impact-dashboard"]} sideMenu={<SideMenu />} />,
          children: [
            {
              path: "/",
              element: <Navigate to={ROUTES["impact-dashboard"].path} />,
            },
            ...routeElements,
            {
              path: "*",
              element: <Navigate to={ROUTES["impact-dashboard"].path} />,
            },
          ],
        },
      ]);
    }, [ROUTES]);

    useEffect(() => {
        //able to handle multiple parallel initializations in the future without code rewrite
        const initPromises: Promise<object | void>[] = [ThemeManager.initialize()];

        //initialize the configuration service
        //initPromises.push(ConfigurationService.init());

        // Initialize the global time store after the main component is mounted
        initPromises.push(globalTimeStoreInit());

        if (!shouldSkipGlobalDataStores) {
            // initialize feature flag retrieval
            initPromises.push(retrieveTenantFeatureFlags());

            // Initialize the global data source types store after the main component is mounted
            initPromises.push(globalDataSourceTypeStoreInit());

            // Initialize the global event mappings store after the main component is mounted
            initPromises.push(globalEventMappingsStoreInit());

            // Initialize the units store
            //initPromises.push(globalUnitsStoreInit());
            // I wanted to do it like above but a bunch of unit tests failed because the units store
            // referenced the DataOceanUtils, so do it this way now.
            initPromises.push(
                new Promise((resolve, reject) => {
                    DataOceanUtils.init().then((data) => {
                        setDataOceanMetadata(data);
                        resolve(data);
                    });
                }),
            );
        }

        //verify all went well
        Promise.all(initPromises).then(
            () => {
                setTheme(ThemeManager.getTheme());
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                setError(error);
            },
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return <LoadingOverlay visible={true} />;
    }

    if (error) {
        return <ErrorPage error={error} />;
    }

    function setNewTheme(theme: THEME) {
        ThemeManager.setTheme(theme);
        setTheme(ThemeManager.getTheme());
    }

    return (
        <ThemeContext.Provider
            value={{
                theme: theme,
                setTheme: setNewTheme,
            }}
        >
            <RouterProvider router={router} />
        </ThemeContext.Provider>
    );
}

export { Main };
