/** This module contains the component for the graph definitions view
 *  @module
 */

import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { HELP, STRINGS } from 'app-strings'
import { Sortable } from 'components/common/sortable/Sortable.tsx';
import { IconNames } from '@tir-ui/react-components';
import { Button, Menu, MenuItem, Popover } from '@blueprintjs/core';
import VariableField from './VariableField.tsx';
import { setNativeValue } from 'reporting-infrastructure/utils/commonUtils.ts';
import { type StructuredVariable, StructuredVariableType, type PrimitiveVariable, PrimitiveVariableType, type VariableCollection, getTypeKeys, INCIDENT_SCOPE_BUILTIN_VARIABLES, INCIDENT_SCOPE, EMPTY_PRIMITIVE_VARIABLE, EMPTY_KEYMETRIC_VARIABLE, RUNTIME_SCOPE, GLOBAL_SCOPE, sanitizeIncidentKeys, EXCLUDED_INCIDENT_KEYS } from 'utils/runbooks/VariablesUtils.ts';
import { VariableContext } from 'utils/runbooks/VariableContext.ts';
import { InlineHelp } from 'components/common/layout/inline-help/InlineHelp.tsx';
import { VariablesTableHeader } from 'components/common/graph/variables/VariableDefinitionView.tsx';
import { ScrollableErrorList } from 'components/common/error/ScrollableErrorList.tsx';
import { PropertyOrMetricOperationType, VariableEntry } from 'components/common/graph/variables/RuntimeVariablesView.tsx';
import { runbookService } from 'utils/runbooks/RunbookUtils.ts';
import RunbookNodeLibrary from "pages/create-runbook/views/create-runbook/node_library.json";
import { NodeLibrary, type NodeLibrarySpec } from 'pages/create-runbook/views/create-runbook/NodeLibrary.ts';
import { getGraphDefFromRunbookConfig, updateGraphDefWithErrors } from 'pages/create-runbook/views/create-runbook/CreateRunbookView.tsx';
import type { VariableContextByScope } from 'utils/runbooks/RunbookContext.class.ts';
import { AdditionalInfoOptions } from 'utils/runbooks/RunbookValidationUtils.tsx';
import { Variant } from 'components/common/graph/types/GraphTypes.ts';
import { Query } from "reporting-infrastructure/types/Query.ts";
import AUTOMATION from 'pages/mapping-configuration/automation.graphql';
import { useQuery } from 'utils/hooks/useQuery.ts';
import { CustomPropertyContext } from 'pages/create-runbook/views/create-runbook/CustomPropertyTypes.ts';
import "./VariableDefinitionView.scss";

type IncidentVariablesProps = {
    containerRef: any,
    onClose: () => void,
    triggerSave?,
    triggerValidation?,
    setSaveDisabled: (formInvalid) => void,
    setIncidentOperations: (executedOperations) => void,
    setShowDialogForIncidentVars: (failureInfo: {nrOfRunbooksThatWillFail: number, nrOfAutomationRulesThatWillFail: number} | null) => void
    onIncidentVariableEdited?: (updatedVariablesList) => void,
}

export enum VariablesOperationType {
    'ADD_VARIABLE',
    'DELETE_VARIABLE',
    'CHANGE_VARIABLE',
}

export default function IncidentVariablesView({ containerRef, onClose, triggerSave, triggerValidation, setSaveDisabled, setIncidentOperations, setShowDialogForIncidentVars, onIncidentVariableEdited}: IncidentVariablesProps) {
    const [currentVariables, setCurrentVariables] = useState<Array<VariableEntry>>([]);
    const { getVariables, setVariables } = useContext(VariableContext);
    const [formErrors, setFormErrors] = useState({});
    // formInvalid true in this case to have the Save button disabled if no changes are performed
    const [formInvalid, setFormInvalid] = useState(true);
    const [uniquenessError, setUniquenessError] = useState(false);
    const [executedOperations, setExecutedOperations] = useState<Array<VariablesOperationType>>([]);
    const [runbooks, setRunbooks] = useState<any[]>();
    const nodeLibrary = useRef<NodeLibrary>(new NodeLibrary(RunbookNodeLibrary as NodeLibrarySpec));
    const [nrOfExistingFailedRunbooks, setNrOfExistingFailedRunbooks] = useState(0);
    const [failureInfo, setFailureInfo] = useState<{nrOfRunbooksThatWillFail: number, nrOfAutomationRulesThatWillFail: number} | null>(null);
    const [incidentVariablesToSave, setIncidentVariablesToSave] = useState<VariableCollection>();
    
    const customProperties = useContext(CustomPropertyContext);

    // graphql data query
    const { data } = useQuery({
        query: new Query(AUTOMATION),
        name: 'automation',
    });

    useEffect(() => {
        fetchRunbooks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchRunbooks = () => {
        runbookService.getRunbooks(Variant.INCIDENT).then(
            (result) => {
                setRunbooks(result);
                let failedRunbooksCount = 0;
                for (const runbook of result) {
                    if (validateRunbook(runbook, getVariables(INCIDENT_SCOPE))) {
                        failedRunbooksCount = failedRunbooksCount + 1;
                    }
                }
                setNrOfExistingFailedRunbooks(failedRunbooksCount);
            },
            (error) => {
                setRunbooks([]);
            }
        );
    }

    useEffect(() => {
        if (triggerSave) {
            handleSave();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerSave]);

    useEffect(() => {
        if (triggerValidation) {
            handleValidation();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerValidation]);

    useEffect(() => {
        setSaveDisabled(formInvalid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formInvalid]);

    useEffect(() => {
        const uniqueOperations = new Set([...executedOperations].map(item => item));
        setIncidentOperations(Array.from(uniqueOperations));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executedOperations]);

    useEffect(() => {
        setShowDialogForIncidentVars(failureInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [failureInfo]);

    useEffect(() => {
        const variables = getVariables(INCIDENT_SCOPE);
        const variableEntryList: VariableEntry[] = [];

        if (variables.primitiveVariables) {
            for (let variable of variables.primitiveVariables) {
                variableEntryList.push({ variable: { ...variable, name: stripIncidentPrefix(variable.name) }, structured: false, isTimeseries: false, index: variableEntryList.length });
            }
        }
        if (variables.structuredVariables) {
            for (let variable of variables.structuredVariables) {
                variableEntryList.push({ variable: { ...variable, name: stripIncidentPrefix(variable.name) }, structured: true, isTimeseries: variable.isTimeseries, index: variableEntryList.length });
            }
        }
        setCurrentVariables(variableEntryList);
    }, [getVariables])

    const elem = useRef(null);

    function addVariable(type: any, structured = false, isTimeseries = false) {
        let variable: any = { ...EMPTY_PRIMITIVE_VARIABLE, type: type };
        if (structured) {
            variable = { ...variable, keys: [EMPTY_KEYMETRIC_VARIABLE], metrics: [EMPTY_KEYMETRIC_VARIABLE] };
        }
        setCurrentVariables([...currentVariables, { variable: { ...variable }, structured: structured, isTimeseries: isTimeseries, index: currentVariables.length }]);
        setExecutedOperations([...executedOperations, VariablesOperationType.ADD_VARIABLE]);
        // Initially the variable will not have any name so the form is invalid
        setFormInvalid(true);
        //@ts-ignore
        setTimeout(() => {elem?.current?.previousElementSibling?.children[elem?.current?.previousElementSibling?.children.length - 2].scrollIntoView({behaviour: 'smooth'})}, 300);
    }

    const hiddenValueInput = useRef<HTMLInputElement>(null);
    function triggerNativeOnChange() {
        if (hiddenValueInput.current) {
            setNativeValue(hiddenValueInput.current, new Date().getTime());
        }
    }

    const handleVariableChange = (index, event?, structuredVariable?, isProperty?, propertyOrMetricOperation?, propertyOrMetricIndex?) => {
        if (event) {
            const { name, value } = event.target;
            const changedVariables = [...currentVariables];
            if (changedVariables[index].structured && name === 'type') {
                // Remove existing metrics and keys for structured variable type change
                changedVariables[index] = {
                    ...changedVariables[index],
                    variable: {
                        ...changedVariables[index].variable,
                        metrics: value === StructuredVariableType.CUSTOM ? [EMPTY_KEYMETRIC_VARIABLE] : [],
                        keys: value === StructuredVariableType.CUSTOM ? [EMPTY_KEYMETRIC_VARIABLE] : sanitizeIncidentKeys(getTypeKeys(value, customProperties)),
                        type: value
                    } as StructuredVariable,
                };
            }
            const isChangeToInteger = name === "type" && value === PrimitiveVariableType.INTEGER;

            if (isChangeToInteger) {
                // Replace default value for integer type
                changedVariables[index] = {
                    ...changedVariables[index],
                    variable: {
                        ...changedVariables[index].variable,
                       defaultValue: isNaN(value) ? '0' : value
                    } as PrimitiveVariable,
                };
            }
            
            changedVariables[index] = { ...changedVariables[index], variable: { ...changedVariables[index].variable, [name]: value } };
            // Enable save on type change
            if (name === 'type') {
                setSaveDisabled(false);
            }
            setCurrentVariables(changedVariables);
            setExecutedOperations([...executedOperations, VariablesOperationType.CHANGE_VARIABLE]);
        }
        else if (structuredVariable && propertyOrMetricIndex !== undefined) {
            const changedVariables = [...currentVariables];
            let newStructuredVariable;
            if (propertyOrMetricOperation === PropertyOrMetricOperationType.DELETE) {
                let newKeysOrMetrics = isProperty ? [...(changedVariables[index].variable as StructuredVariable).keys] : [...(changedVariables[index].variable as StructuredVariable).metrics];
                newKeysOrMetrics.splice(propertyOrMetricIndex, 1);
                newStructuredVariable = isProperty ? { ...changedVariables[index].variable, keys: newKeysOrMetrics } :
                    { ...changedVariables[index].variable, metrics: newKeysOrMetrics };
            } else if (propertyOrMetricOperation === PropertyOrMetricOperationType.ADD ||
                propertyOrMetricOperation === PropertyOrMetricOperationType.CHANGE) {
                newStructuredVariable = isProperty ? { ...changedVariables[index]?.variable, keys: structuredVariable } :
                    { ...changedVariables[index]?.variable, metrics: structuredVariable };
            }
            changedVariables[index] = { ...changedVariables[index], variable: newStructuredVariable };
            setCurrentVariables(changedVariables);
            setExecutedOperations([...executedOperations, VariablesOperationType.CHANGE_VARIABLE]);
        }
    };

    function handleDelete(index) {
        const newCurrentVariables = currentVariables.filter(variable => variable.index !== index);
        setCurrentVariables(newCurrentVariables);
        setExecutedOperations([...executedOperations, VariablesOperationType.DELETE_VARIABLE]);
        // Remove variables errors
        errorsListener(index, null);
    }

    function handleValidation() {
        // Add id for custom properties and metrics and remove empty properties and metrics
        for (const variable of currentVariables.filter(variable => variable.structured)) {
            for (const key of (variable.variable as StructuredVariable).keys ) {
                if (!key.id && key.label) {
                    key.id = key.label.replace(/ /g, "_");
                } else if (!key.label) {
                    (variable.variable as StructuredVariable).keys = (variable.variable as StructuredVariable).keys.filter(item => {return item !== key;});
                }
            }
            for (const metric of (variable.variable as StructuredVariable).metrics ) {
                if (!metric.id && metric.label) {
                    metric.id = metric.label.replace(/ /g, "_");
                } else if (!metric.label) {
                    (variable.variable as StructuredVariable).metrics = (variable.variable as StructuredVariable).metrics.filter(item => {return item !== metric;});
                }
            }
        };
        // Create the new variables object with the runtime prefix appended
        const newIncidentVariables: VariableCollection = {
            primitiveVariables: currentVariables.filter(variable => !variable.structured).map(variable => {
                return { ...(variable.variable as PrimitiveVariable), name: addIncidentPrefix(variable.variable.name) }
            }) || [],
            structuredVariables: currentVariables.filter(variable => variable.structured).map(variable => {
                return { ...(variable.variable as StructuredVariable), isTimeseries: variable.isTimeseries, name: addIncidentPrefix(variable.variable.name) }
            }) || [],
        };
        // Check for name uniqueness
        const unique = new Set([...newIncidentVariables.primitiveVariables, ...newIncidentVariables.structuredVariables].map(item => item.name));
        let nameUniqueness = Array.from(unique).length === [...newIncidentVariables.primitiveVariables, ...newIncidentVariables.structuredVariables].length;
        if (nameUniqueness && runbooks) {
            let nrOfRunbooksThatWillFail = 0;
            let nrOfAutomationRulesThatWillFail = 0;
            let failedRunbooksCount = 0;
            // Iterate through all runbooks and check for incident variables errors
            for (const runbook of runbooks) {
                if (validateRunbook(runbook, newIncidentVariables)) {
                    failedRunbooksCount = failedRunbooksCount + 1;
                }
            }
            setIncidentVariablesToSave(newIncidentVariables);
            nrOfRunbooksThatWillFail = failedRunbooksCount - nrOfExistingFailedRunbooks >= 0 ? failedRunbooksCount - nrOfExistingFailedRunbooks : 0;

            if (data.automationMappings) {
                const incidentVariableNames: string[] = [];
                if (newIncidentVariables?.primitiveVariables?.length) {
                    for (const variable of newIncidentVariables.primitiveVariables) {
                        incidentVariableNames.push(variable.name);
                    }
                }
                for (const mapping of data.automationMappings) {
                    if (mapping.expression) {
                        try {
                            const expression = JSON.parse(mapping.expression);
                            const conditions = expression.conditions;
                            if (conditionsMissingIncidentVariable(conditions, incidentVariableNames)) {
                                nrOfAutomationRulesThatWillFail++;
                            }
                            console.log(expression);
                        } catch (error) {
                            console.error("Error parsing mapping expression");
                        }
                    }
                }
            }
            setFailureInfo({nrOfRunbooksThatWillFail, nrOfAutomationRulesThatWillFail});    
        } else {
            setFormInvalid(true);
            setUniquenessError(true);
        }
    }

    function handleSave() {
        setVariables(INCIDENT_SCOPE, incidentVariablesToSave);
        if (onIncidentVariableEdited) {
            onIncidentVariableEdited(incidentVariablesToSave);
        }
        onClose();
    }

    function validateRunbook(runbook, incidentVariables) {
        const variables: VariableContextByScope = {
            [RUNTIME_SCOPE]: runbook.runtimeVariables || { primitiveVariables: [], structuredVariables: [] },
            [INCIDENT_SCOPE]: incidentVariables,
            [GLOBAL_SCOPE]: { primitiveVariables: [], structuredVariables: [] }
        };
        const graphDef = getGraphDefFromRunbookConfig(nodeLibrary.current, runbook);
        updateGraphDefWithErrors(nodeLibrary.current, graphDef, runbook, variables, customProperties, [], Variant.INCIDENT);
        return graphDef.additionalInformation !== undefined && graphDef.additionalInformation === AdditionalInfoOptions.INCIDENT_VARIABLE_ERROR;
    }

    // Strip the incident. prefix for variable name
    function stripIncidentPrefix(name: string) {
        if (name.includes(INCIDENT_SCOPE)) {
            return name.slice(INCIDENT_SCOPE.length + 1);
        }
        return name;
    }

    // Add the incident. prefix for variable name
    function addIncidentPrefix(name: string) {
        return INCIDENT_SCOPE + '.' + name;
    }

    function errorsListener(key, errors) {
        const newFormState = { ...formErrors, [key]: errors }
        let hasErrors = false;

        for (let state in newFormState) {
            if (newFormState[state] && newFormState[state].length) {
                hasErrors = true;
            }
        }

        setFormErrors(newFormState);
        setFormInvalid(hasErrors);
    }

    const builtinVariables = useMemo(() => {
        return INCIDENT_SCOPE_BUILTIN_VARIABLES.map((variable) => {
            return <VariableField
                key={variable.name}
                variable={variable}
                structured={false}
                timeseries={false}
                builtin={true}></VariableField>
        });
    }, []);

    return (
        <>
            <div className="add-variable-control m-2">
                <Popover
                    fill={true}
                    usePortal={false}
                    content={<Menu>
                        {Object.keys(PrimitiveVariableType).filter((key) => {
                            return !EXCLUDED_INCIDENT_KEYS.includes(key);
                        }).map((key) => {
                            return <MenuItem key={PrimitiveVariableType[key]} text={STRINGS.runbookEditor.variableDefinitions.primitiveVariable.types[key]}
                                onClick={() => addVariable(PrimitiveVariableType[key])} />
                        })}
                        <MenuItem key={STRINGS.runbookEditor.variableDefinitions.structuredVariable.variableType.summarized}
                            text={STRINGS.runbookEditor.variableDefinitions.structuredVariable.variableType.summarized}
                            onClick={() => addVariable(StructuredVariableType.CUSTOM, true, false)} />
                        <MenuItem key={STRINGS.runbookEditor.variableDefinitions.structuredVariable.variableType.timeseries}
                            text={STRINGS.runbookEditor.variableDefinitions.structuredVariable.variableType.timeseries}
                            onClick={() => addVariable(StructuredVariableType.CUSTOM, true, true)} />
                    </Menu>}
                    placement="left-start"
                >
                    <InlineHelp
                        helpMapping={HELP.RunbookNodeCategory.variableDefinitions.incidentScope.addVariable}>
                            <Button
                                minimal
                                id="add-variable"
                                className='fw-bold'
                                icon={IconNames.ADD}
                                text={STRINGS.runbookEditor.variableDefinitions.incidentScope.addVariable.label}
                                disabled={false}
                            />
                    </InlineHelp>
                </Popover>
                <p className='fw-light'>
                    {STRINGS.runbookEditor.variableDefinitions.incidentScope.addVariable.info}
                </p>
            </div>
            {uniquenessError && <ScrollableErrorList items={[STRINGS.runbookEditor.errors.uniqueVariableNameError]}/> }
            <div className='variable-list pe-2 mb-2'>
                <input type="text" className="d-none" ref={hiddenValueInput} defaultValue={new Date().getTime()} />
                <InlineHelp
                    helpMapping={HELP.RunbookNodeCategory.variableDefinitions.incidentScope.userDefined}>
                        <label className='display-7 fw-bold'>{STRINGS.runbookEditor.variableDefinitions.incidentScope.userDefinedLabel}</label>
                </InlineHelp>
                <VariablesTableHeader />
                {currentVariables.length ? <Sortable
                    dragOnlyUsingHandles
                    items={currentVariables.map((variable) => {
                        return {
                            record: variable,
                            contents: <VariableField
                                key={variable.index + variable.variable.type}
                                index={variable.index}
                                variable={variable.variable}
                                structured={variable.structured}
                                timeseries={variable.isTimeseries}
                                handleVariableChange={handleVariableChange}
                                errorsListener={errorsListener}
                                handleDelete={handleDelete}
                                draggable></VariableField>
                        }
                    })}
                    onChange={updatedVariablesList => {
                        setCurrentVariables([
                            ...updatedVariablesList.map(item => item.record),
                        ]);
                        triggerNativeOnChange();
                    }}
                    onDragStart={() => containerRef.current?.classList.add("reorder-in-progress")}
                    onDragEnd={() => containerRef.current?.classList.remove("reorder-in-progress")}
                /> : <p className='d-flex justify-content-center text-muted display-7'>{STRINGS.runbookEditor.variableDefinitions.incidentScope.noVariables}</p>}
                <div style={{ float:"left", clear: "both" }}
                    ref={elem}>
                </div>
                <InlineHelp
                    helpMapping={HELP.RunbookNodeCategory.variableDefinitions.incidentScope.builtin}>
                        <label className='display-7 fw-bold'>{STRINGS.runbookEditor.variableDefinitions.incidentScope.builtin.label}</label>
                </InlineHelp>
                <VariablesTableHeader />
                {builtinVariables}
            </div>
        </>
    )
}

/** recursively traverse the conditions to see if any are missing the incident variable.
 *  @param conditions the conditions to check.
 *  @param incidentVariableNames the incident variable names to search for.
 *  @returns a boolean value, true if the expression has the incident variable, false otherwise. */
function conditionsMissingIncidentVariable(conditions: any, incidentVariableNames: string[]): boolean {
    if (conditions?.length) {
        for (const condition of conditions) {
            if (condition.key) {
                const key: string = condition.key;
                switch (condition.category) {
                    case "variable": {
                        const varName = key.substring(10, key.length);
                        if (!incidentVariableNames.includes(varName)) {
                            return true;
                        }
                        break;
                    }
                }
            }
            if (condition.conditions && conditionsMissingIncidentVariable(condition.conditions, incidentVariableNames)) {
                return true;
            }
        }
    }
    return false;
}
