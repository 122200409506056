import classNames from 'classnames'
import { Classes, Divider, Label, TextArea } from '@blueprintjs/core'
import { STRINGS } from 'app-strings'
import { PrimitiveVariableType } from 'utils/runbooks/VariablesUtils.ts'

interface ISelectRunbookProps {
    description?: string;
    setDescription: (any) => void;
    summary?: string;
    inputs?: any;
}

const StepReview = (props: ISelectRunbookProps) => {
    const reviewWizardStrings = STRINGS.scheduleRunbook.wizard.panelReview;

    return (
        <div className={classNames({
            [Classes.DIALOG_BODY]: true,
            "overflow-scroll": true
        })}>
            <p className='fw-bold'>{reviewWizardStrings.title}</p>
            <Divider className='ms-0' />
            <div className="row">
                <div className='col-10'>
                    <Label className='mt-3 mb-2'>{reviewWizardStrings.labelDesc}</Label>
                    <TextArea name="description" style={{ height: '100px', resize: 'none' }} fill
                        onChange={e => props.setDescription(e.target.value)}
                        value={props.description}
                    />
                </div>
            </div>

            {/* Wait until we have all values and 'next scheduled date' */}
            {!!props.summary && <div className="row">
                <div className='col-10'>
                    <p className='mt-3'>{props.summary}</p>
                    <ul className={`${Classes.LIST} ${Classes.LIST_UNSTYLED} my-4`}>
                        {/* Need to map values with var ids */}
                        {props.inputs.current?.variableOverrides && Object.entries(props.inputs.current?.variableOverrides).map(([key, value]) => {
                            const variableType = props.inputs.current.variableTypes[key];
                            let friendlyAuthOrEdgeValue: string = "";
                            if (variableType === PrimitiveVariableType.AUTH_PROFILE) {
                                friendlyAuthOrEdgeValue = props.inputs.current?.edgesAndAuthProfilesForScheduled?.authProfiles?.find(item => item.id === value)?.name;
                            }
                            if (variableType === PrimitiveVariableType.ALLUVIO_EDGE) {
                                friendlyAuthOrEdgeValue = props.inputs.current?.edgesAndAuthProfilesForScheduled?.edges?.find(item => item.id === value)?.name;
                            }
                            return <li key={key} className="d-flex"><span>{key.split('.')[1]}: </span> <span className="text-break ms-1">{friendlyAuthOrEdgeValue || value}</span></li>
                        })}
                    </ul>
                    {/* <Callout className='mb-5' intent='success' icon={IconNames.TIME}>{reviewWizardStrings.callOutNext} Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab, omnis?</Callout> */}
                </div>
            </div>}
        </div>
    )
}

export default StepReview
