/** This module contains the component for the incident details page.  The incident details page displays
 *      the runbooks that were run for a particular incident and the indicators that were used to generate
 *      the incident.
 *  @module
 */
import { useEffect, useRef, useState } from "react";
import { Query } from "reporting-infrastructure/types/Query.ts";
import { FILTER_NAME } from "components/sdwan/enums/filters.ts";
import { parseTimeFromDALToUnix } from "utils/hooks/useQueryFormatters.ts";
import { useGlobalFilters } from "utils/hooks/useGlobalFilters.ts";
import { useQuery } from "utils/hooks/useQuery.ts";
import { useUserPreferences } from "utils/hooks/useUserPreferences.ts";
import { SDWAN_ICONS } from "components/sdwan/enums/icons.ts";
import { getQueryParam, getURL, useQueryParams } from "utils/hooks/useQueryParams.ts";
import { IconNames, LoadingOverlay, ErrorToaster, SuccessToaster } from "@tir-ui/react-components";
import { PageWithHeader } from "components/sdwan/layout/page-with-header/PageWithHeader.tsx";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade.tsx";
import { AUTOUPDATE_INTERVAL } from "components/enums/Time.ts";
import { Outline } from "components/enums/General.ts";
import { SIZE } from "components/enums/Sizes.ts";
import { INCIDENT_STATUS_TO_LABEL_MAP } from "components/enums/IncidentStatus.ts";
import { getPriorityItemsListForDropdown, PRIORITY } from "components/enums/Priority.ts";
import { IconTitle } from "components/common/icon-title/IconTitle.tsx";
import { OneColumnContainer } from "components/common/layout/containers/one-column-container/OneColumnContainer.tsx";
import { CopyLinkButton } from "components/common/copy-link-button/CopyLinkButton.tsx";
import { SelectedListItemStyles, SelectInput } from "components/common/select/SelectInput.tsx";
import { useMutation } from "@apollo/client";
import type { IncidentKeyFieldsMutationInput } from "pages/incident-list/views/update-incidents-view/UpdateIncidentsView.tsx";
import { IncidentDetailSubHeader } from "./IncidentDetailSubHeader.tsx";
import { IncidentImpactSummaryView } from "pages/incident-details/views/impact-summary/IncidentImpactSummaryView.tsx";
import { STRINGS, HELP } from "app-strings";
import { BladeContainer } from "components/common/layout/containers/blade-container/BladeContainer.tsx";
import { Alignment, Button, NavbarGroup, Position, Toaster, type IconName, Intent } from "@blueprintjs/core";
import { Card } from "components/reporting/containers/card/Card.tsx";
import { DetailsPanel } from "components/reporting/containers/details-panel/DetailsPanel.tsx";
import { ActivityLogView, getActivityInfo } from "components/hyperion/views/activity-log/ActivityLogView.tsx";
import { RoundedLinkButton } from "components/common/rounded-link-button/RoundedLinkButton.tsx";
import { PARAM_NAME, SHOW_DETECTIONS, INCIDENT_DETAILS_STYLE } from "components/enums/QueryParams.ts";
import { EventNames, trackEvent } from 'utils/appinsights/AppInsights.ts';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { AuthServiceProvider } from 'utils/providers/AuthServiceProvider.ts'
import { NotesView } from "components/hyperion/views/notes/NotesView.tsx";
import { NotesForm } from "components/hyperion/views/notes/NotesForm.tsx";
import { openModal } from "components/common/modal/ModalRenderer.tsx";
import { NoteType } from "components/enums/NotesActivity.ts";
import { ContentOutdatedIndicator } from "components/hyperion/controls/content-outdated-indicator/ContentOutdatedIndicator.tsx";
import { EntryType, PriorityReasonsView } from "./views/priority-reason/PriorityReasonsView.tsx";
import { annotateTimestamp, PrimaryIndicatorView } from "./views/primary-indicator/PrimaryIndicatorView.tsx";
import { BasicDialog, updateDialogState } from "components/common/basic-dialog/BasicDialog.tsx";
import { JsonViewer } from "pages/incident-details/views/primary-indicator/JsonViewer.tsx";
import { CopyToClipboard } from "react-copy-to-clipboard";
import cloneDeep from "lodash/cloneDeep";
import type { Incident, Trigger } from "pages/incident-list/Incident.type.ts";
import { RunbookOutputsTabView } from "pages/incident-details/views/runbook-outputs-tab/RunbookOutputsTabView.tsx";
import { ImpactSummaryDetailsPane } from "pages/incident-details/views/impact-summary/panes/ImpactSummaryDetailsPane.tsx";
import Markdown from "markdown-to-jsx";
import { formatVariables, showTitleIcons } from "utils/runbooks/RunbookUtils.ts";
import type { LogEntry, PriorityReason, RunbookOutput } from "pages/riverbed-advisor/views/runbook-view/Runbook.type.ts";
import { INCIDENT_SCOPE_BUILTIN_VARIABLES, RUNBOOK_SCOPE_BUILTIN_VARIABLES, removeTypename } from "utils/runbooks/VariablesUtils.ts";
import TimelineOutputsView from "pages/incident-details/views/timeline-outputs-view/TimelineOutputsView.tsx";
import { DEFAULT_INCIDENT_PREF, type IncidentPreference } from "utils/services/UserPrefsTypes.ts";
import INCIDENT_HEADER_QUERY from "pages/incident-details/incident-header-query.graphql";
import ACTIVITY_LOG_QUERY from "components/hyperion/views/activity-log/activity-log-query.graphql";
import INCIDENT_VARIABLES_QUERY from "utils/hooks/incident-variables-query.graphql";
import INCIDENT_KEY_FIELD_UPDATE_QUERY from "pages/incident-list/views/update-incidents-view/incident-key-field-update-query.graphql";
import NOTES_QUERY from "components/hyperion/views/notes/notes-query.graphql";
import './IncidentDetailsPage.scss';

const AuthService = AuthServiceProvider.getService();

const RUN_ACTIVITY_CHECK = true;

/** Calls the graphql mutation function passed as updateFunction to update the incident with property 
 *  values set in the updateObject.
 *  @param updateFunction
 *  @param incident
 *  @param updateObject .*/
const updateIncident = (updateFunction , incident, updateObject) => {
    updateFunction({
        variables: {
            incidents: [{
                id: incident.id,
                ...updateObject
            }]
        }
    })
}

/** this enum creates an enumeration for all the potential details blade contents */
enum BladeContent {
    /** the constant for the notes blade. */
    NOTES               = "NOTES",
    /** the constant for the activity log blade. */
    ACTIVITY_LOG        = "ACTIVITY_LOG",
    /** the constant for the impact summary blade. */
    IMPACT_SUMMARY      = "IMPACT_SUMMARY",
    /** the constant for the priority reasons blade. */
    PRIORITY_REASONS    = "PRIORITY_REASONS"
}

/** the toaster that shows new activities. */
const ActivitiesToaster = Toaster.create({
    position: Position.TOP,
    maxToasts: 5,
});

/** Renders the incident details page.
 *  @param props the properties passed in.
 *  @returns JSX with the incident details page component.*/
export default function IncidentDetailsPage(props): JSX.Element {
    const appInsightsContext = useAppInsightsContext();
    const { filters, setFilter, clearFilter } = useGlobalFilters();
    const [ priority, setPriority ] = useState<string>();
    const [ status, setStatus ] = useState<string>();
    const [ lastUpdated, setLastUpdatedState ] = useState<number>(new Date().getTime());
    const [ runbook, setRunbook] = useState<RunbookOutput>();
    const [incidentVariables, setIncidentVariables] = useState<any>();
    const lastActivityTimestamp = useRef<null|number>(null);
    const lastActivityTimestampDuringLastRender = useRef<null|number>(null);
    function setLastUpdated (timestamp: number) {
        setLastUpdatedState(timestamp);
        // We will remember the last seen most recent activity's timestamp when we reload
        // this page's contents by updating the 'lastUpdated' timestamp. The reason we are
        // doing this circus is because we can't directly compare activity's timestamp with
        // lastUpdated value. Activity timestamp is set in the backend but lastUpdated is a
        // local timestamp that's generated in the UI & hence could be out of sync based on user's location.
        lastActivityTimestampDuringLastRender.current = lastActivityTimestamp.current;
    }
    const [ showReloadControl, setShowReloadControl ] = useState(false);
    const [showBlade, setShowBlade] = useState<boolean>(false);
    // Primarily used for forcing a re-render of NotesView component when a new note is added
    const [latestNoteId, setLatestNoteId] = useState<string>("");
    const [bladeContent, setBladeContent] = useState<BladeContent>(BladeContent.NOTES);
    const PANEL_ANCHOR_ELEMENT_ID = "panel-anchor-element";
    useEffect(() => {
        return () => { setTimeout(() => clearFilter(FILTER_NAME.incidentId), 10) } // Give a little time to unmount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // Set the Incident ID filter in URL if it's not already set after component mounts using the useEffect hook
    useEffect(() => {
        const incidentIDinURL = getQueryParam(PARAM_NAME.incidentId);
        if (incidentIDinURL && !filters[FILTER_NAME.incidentId]) {
            setFilter(FILTER_NAME.incidentId, incidentIDinURL);
        }
    });

    const incidentHeaderQuery = useQuery({
        name: "IncidentHeader",
        query: new Query(INCIDENT_HEADER_QUERY),
        requiredFilters: [FILTER_NAME.incidentId],
        consumedFilters: [FILTER_NAME.incidentId],
        timeNotRequired: true,
        expiresAfter: { timestamp: lastUpdated },
    });

    const incidentActivityLog = useQuery({
        name: "ActivityList",
        query: new Query(ACTIVITY_LOG_QUERY),
        requiredFilters: [FILTER_NAME.incidentId],
        consumedFilters: [FILTER_NAME.incidentId],
        lazy: true,
    });

    const incidentVariablesQuery = useQuery({
		name: 'IncidentVariables',
		query: new Query(INCIDENT_VARIABLES_QUERY),
	});

    useEffect(() => {
        setIncidentVariables(removeTypename(incidentVariablesQuery.data));
        // API Fetch for incident and global variables
    }, [incidentVariablesQuery.data]);

    const variables = (runbook?.variableResults ?
        (runbook.variableResults.hasOwnProperty('primitiveVariables') ?
            runbook.variableResults.primitiveVariables.concat(RUNBOOK_SCOPE_BUILTIN_VARIABLES) :
            RUNBOOK_SCOPE_BUILTIN_VARIABLES) : RUNBOOK_SCOPE_BUILTIN_VARIABLES)
                .concat([...incidentVariables?.incidentVariables?.variables?.primitiveVariables || [],
                ...INCIDENT_SCOPE_BUILTIN_VARIABLES]);

    const mostRecentActivity = useRef<number>();
    const mostRecentActivityCheckTimer = useRef<number|null>(null);
    /* istanbul ignore next */
    function checkForNewActivity (silent = false) {
        incidentActivityLog.run({
            noCache: true,
            queryVariables: { limit: 5 },
        }).then(data => {
            const activities = data?.activities?.nodes;
            if (activities && activities.length > 0) {
                let mostRecentUpdateTimestamp = mostRecentActivity.current;
                // If this request is happening later than last time the main incident details query ran, use that timestamp instead.
                if (lastActivityTimestamp.current &&
                    (!mostRecentUpdateTimestamp || lastActivityTimestamp.current > mostRecentUpdateTimestamp)) {
                    mostRecentUpdateTimestamp = lastActivityTimestamp.current;
                }
                if (mostRecentUpdateTimestamp && !silent) {
                    const myUserId = AuthService.getUserAccount().getUserId();
                    // Filter down to only activities that happened after the previous time we checked.
                    // One list is for activities initiated by active user and the second is for the remaining ones.
                    const newActivitiesByActiveUser = activities.filter(activity => {
                        return mostRecentActivity.current &&
                            Number(activity.timestamp) > mostRecentActivity.current &&
                            activity.user?.id === myUserId;
                    });
                    const newActivitiesByOtherSources = activities.filter(activity => {
                        return mostRecentActivity.current &&
                            Number(activity.timestamp) > mostRecentActivity.current &&
                            activity.user?.id !== myUserId &&
                            (SHOW_DETECTIONS || activity.type !== "DETECTION" /*ACTIVITY_TYPE.DETECTION*/);
                    });
                    const activityMessages = newActivitiesByOtherSources.map(activity => (getActivityInfo(activity)?.description || null)).filter(description => description !== null);
                    if (activityMessages.length > 0) {
                        activityMessages.forEach(message => {
                            ActivitiesToaster.show({
                                message,
                                timeout: 5000,
                                action: {
                                    text: STRINGS.incidents.reload,
                                    onClick: () => {
                                        setLastUpdated(new Date().getTime());
                                        setShowReloadControl(false);
                                    }
                                }
                            });
                        });
                    }
                    if (newActivitiesByOtherSources.length > 0 || (
                            newActivitiesByActiveUser.length > 0 &&
                            lastActivityTimestampDuringLastRender.current !== lastActivityTimestamp.current
                        )) {
                        setShowReloadControl(true);
                    }
                }
                mostRecentActivity.current = Number(activities[0].timestamp);
            }
        });
    }
    useEffect(() => {
        if (RUN_ACTIVITY_CHECK) {
            checkForNewActivity();
            mostRecentActivityCheckTimer.current = window.setInterval(() => {
                if (!document.hidden) {
                    checkForNewActivity();
                }
            }, AUTOUPDATE_INTERVAL);
            return () => {
                if (mostRecentActivityCheckTimer.current) {
                    window.clearTimeout(mostRecentActivityCheckTimer.current);
                    mostRecentActivityCheckTimer.current = null;
                }
            }    
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [incident]: Array<Incident> = incidentHeaderQuery.data?.incidents?.nodes || [];

    useEffect(() => {
        if(incident) {
            setPriority(incident.priority);
            setStatus(incident.status);
            if (incident.lastUpdatedAt) {
                const incidentLastUpdateTimeStamp = parseTimeFromDALToUnix(incident.lastUpdatedAt);
                // Keep a note of the last updated timestamp from backend when the query runs and incident object in response data change
                if (incidentLastUpdateTimeStamp && (lastActivityTimestamp.current === null || incidentLastUpdateTimeStamp > lastActivityTimestamp.current)) {
                    lastActivityTimestamp.current = incidentLastUpdateTimeStamp;
                }
            }
        }
    }, [incident]);

    // We are using a separate query to fetch triggers for the active incident right now.
    // In the future, triggers list will come from incident query itself.
    /*
    const triggerListQuery = useQuery({
        name: "TriggerListForIncident",
        query: new Query(TRIGGER_LIST_QUERY),
        consumedFilters: [FILTER_NAME.incidentId],
        requiredFilters: [FILTER_NAME.incidentId],
        lazy: true,
        timeNotRequired: true,
    });

    useEffect(() => {
        if ((incident as any)?.triggers?.length) {
            triggerListQuery.run({
                filters: {
                    // Map triggers from [{ id: "ABC" }, { id: "DEF" }] to ["ABC", "DEF"]
                    //[FILTER_NAME.detectionId]: (incident as any)?.triggers.map(triggerObj => triggerObj.id)
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [incidentHeaderQuery.data])
    */

    //const {triggerListViewJsx} = useMemo(() => {
    //    const triggers: Array<Trigger> = triggerListQuery.data?.triggers?.nodes || [];
    //    return {
    //        triggerListViewJsx: <DataLoadFacade
    //                loading={triggerListQuery.loading}
    //                data={triggerListQuery.data}
    //                error={triggerListQuery.error}
    //                showContentsWhenLoading={true}
    //            >{
    //                triggers.length > 0 ?
    //                <TriggerListView
    //                    filters={{ [FILTER_NAME.incidentId]: incident.id }}
    //                    enableRerunRunbook={true /*PM asked to always enable this incident.isOngoing === true*/}
    //                    expiresAfter={lastUpdated}
    //                /> :
    //                <div className="p-5 text-black text-center">{STRINGS.no_data_to_display}</div>
    //            }</DataLoadFacade>
    //    };
    //}, [triggerListQuery.data, triggerListQuery.loading, triggerListQuery.error, /*incident?.isOngoing, */incident?.id, lastUpdated]);

    let entity;
    if (incident?.trackingEntity?.name && incident?.trackingEntity?.kind) {
        entity = {
            type: incident.trackingEntity.kind,
            icon: SDWAN_ICONS.RUNBOOK,
            label: "N/A",
            name: incident.trackingEntity.name,
        };
        if (entity.type === "network_interface") {
            entity.icon = SDWAN_ICONS.INTERFACE;
            entity.label = STRINGS.interface;
        }
    }

    const getCopyLinkURL = () => { 
        return window.location.origin + getURL(undefined, { "incidentId": incident.id }, { replaceQueryParams: false });
    };
    const refreshOpenBlades = ()=> {
        if (showBlade) {
            // Hide any open blades. This is to make sure no stale data is displayed on open dialogs after the update.
            setShowBlade(false);
            setShowBlade(true);
        }
    }
    const [setIncidentKeyFields, { loading }] = useMutation<any, IncidentKeyFieldsMutationInput>(INCIDENT_KEY_FIELD_UPDATE_QUERY, {
            onCompleted: (data) => {
                SuccessToaster({
                    message: STRINGS.incidents.updateSuccess,
                    action: {
                        onClick: () => {
                            openModal('addNotesModal', {
                                incidentId: data.setIncidents[0].id,
                                content: STRINGS.formatString(
                                    STRINGS.incidents.defaultStatusUpdateNotesDesc,
                                    INCIDENT_STATUS_TO_LABEL_MAP[data.setIncidents[0].status]
                                ),
                                notesType: NoteType.STANDARD
                            });
                        },
                        text: STRINGS.addNotes.title
                    },
                });
                setLastUpdated(new Date().getTime());
                refreshOpenBlades()
            },
            onError: (err) => {
                ErrorToaster({
                    message: "There was an error updating the incident. Try again"
                });
                console.error(err?.message);
            }
        }
    );

    const notesQueryResult = useQuery({
        name: "NotesList",
        query: new Query(NOTES_QUERY),
        filters: incident?.id ? { incidentId: incident.id } : undefined,
        requiredFilters: [FILTER_NAME.incidentId],
        consumedFilters: [FILTER_NAME.incidentId],
        // TBD: Use a timestamp from incident's lastUpdated property to expire notes query data because
        // using refreshNotes local state will not work when a different user adds a note to the incident.
        // Unfortunately lastUpdated on the incident is not updating when notes get added or modified.
        // So that needs to be fixed in backend first before switching to lastUpdated
        expiresAfter: latestNoteId ? { key: latestNoteId } : undefined,
    });

    const { data } = notesQueryResult;

    const notesCount = data?.notes?.nodes.length || 0;

    const copyLink = incident ? <CopyLinkButton href={getCopyLinkURL()} className="sub-header-props" /> : '';
    const subHeaderRightMenu = <NavbarGroup align={Alignment.RIGHT}>
        <RoundedLinkButton className="align-self-center header-text sub-header-props" size={SIZE.s} outline={Outline.SHOW_ON_HOVER} text={`${STRINGS.notes} (${notesCount})`} icon={IconNames.COMMENT} minimal={true} 
        onClick={(e) => {
            if (bladeContent === BladeContent.NOTES) {
                setShowBlade(showBlade ? false : true);
            }else {
                setShowBlade(true);
                setBladeContent(BladeContent.NOTES);
            }
        }}/>
        <RoundedLinkButton className="align-self-center header-text sub-header-props" size={SIZE.s} text={STRINGS.activityLog.label} icon={SDWAN_ICONS.TRIGGER as IconName } 
            minimal={true} outline={Outline.SHOW_ON_HOVER} onClick={(e) => {
            if (bladeContent === BladeContent.ACTIVITY_LOG) {
                setShowBlade(showBlade ? false : true);
            }else {
                setShowBlade(true);
                setBladeContent(BladeContent.ACTIVITY_LOG);
            }
        }}/>
        <div className="align-self-center header-text" onClick={()=> {setShowBlade(false)}}>
            {copyLink}
        </div>
        </NavbarGroup>;

    const [impactSummaryDetails, setImpactSummaryDetails] = useState<{icon: string, title: string, subTitle: string, data: string[]} | undefined>(undefined);
    const [priorityReasonsDetails, setPriorityReasonsDetails] = useState<{icon: string, title: string, data: PriorityReason[]} | undefined>(undefined);

    let { params } = useQueryParams({ listenOnlyTo: [ PARAM_NAME.debug ] });
    const showDebugInformation = params[PARAM_NAME.debug] === "true";
    const initDialogState = {showDialog: false, title: STRINGS.primaryIndicatorView.debugDialogTitle, loading: false, dialogContent: <></>, dialogFooter: <></>};
    const [dialogState, setDialogState] = useState<any>(initDialogState);

    const userPreferences = useUserPreferences({ listenOnlyTo: { incidentPage: { timelineChart: false, incidentSources: false } } });
    const incidentPrefs: IncidentPreference = {...DEFAULT_INCIDENT_PREF, ...userPreferences.incidentPage};

    return (<>
        <BasicDialog dialogState={dialogState} className={"incident-details-view-dialog"} onClose={() => setDialogState(updateDialogState(dialogState, false, false, []))} />
        <PageWithHeader
            name="IncidentDetailsPage"
            showTimeBar={false}
            title={incident?.description ? incident.description : STRINGS.incidents.detailsPageTitle}
            //icon={SDWAN_ICONS.INCIDENT}
            hiddenGlobalFilters={[FILTER_NAME.incidentId]}
            additionalSupportedFilters={[FILTER_NAME.incidentId]}
            status={PRIORITY[priority || PRIORITY.UNKNOWN]}
            isPriority={true}
            leftAlignedControls={
                incident && false &&
                <>
                    {/*<IncidentWatchedIcon incidentId={incident.id} className="ms-2"/>*/}
                </>
            }
            controlsToLeftOfTitle={
                incident &&
                <>
                    <SelectInput
                        items={ getPriorityItemsListForDropdown() }
                        selectedItemStyle={ SelectedListItemStyles.TICK }
                        selectedValue={ priority }
                        // PM does not want the end user to be able to update the priority.
                        disableSelection={true}
                        onItemSelect={ item => {
                            setPriority(item.value);
                            updateIncident(setIncidentKeyFields, incident, {
                                priority: item?.value,
                                // Passing existing status as well till backend bug gets fixed. Status will get reset if not passed
                                status: incident.status,
                            });
                            // report metrics to App Insights
                            if (appInsightsContext) {
                                const properties = {
                                    name: EventNames.INCIDENTS_PRIORITY_CHANGE, 
                                    properties: {
                                        priority: item.value
                                    }
                                };
                                trackEvent(appInsightsContext, AuthService, properties);
                            }
                        }}
                    />
                </>
            }
            subHeader={
                incident &&
                <>
                <IncidentDetailSubHeader
                    onIncidentStatusChange={ (item) => {
                        setStatus(item.value);
                        updateIncident(setIncidentKeyFields, incident, {
                            status: item?.value,
                            // Passing existing priority as well till backend bug gets fixed. Priority will get reset if not passed
                            priority: incident.priority,
                        });
                        // report metrics to App Insights
                        if (appInsightsContext) {
                            const properties = {
                                name: EventNames.INCIDENTS_STATUS_CHANGE, 
                                properties: {
                                    status: item.value
                                }
                            };
                            trackEvent(appInsightsContext, AuthService, properties);
                        }
                    } }
                    status={ status }
                    incident={ incident }
                />
                {subHeaderRightMenu}
                </>
            }
            helpInfo={HELP.incidentDetails}
            rightAlignedControls={showReloadControl && lastActivityTimestamp.current !== null &&
                <ContentOutdatedIndicator
                    onReloadClicked={() => {
                        setShowReloadControl(false);
                        setLastUpdated(new Date().getTime());
                    }}
                />
            }
            showDebug={showDebugInformation}
            onDebug={() => {
                showDebugDialog(
                    getDebugInformation(incidentHeaderQuery?.data?.incidents?.nodes, []/*triggerListQuery?.data?.triggers?.nodes*/), 
                    dialogState, setDialogState
                );    
            }}
        >
            <div className="position-absolute w-100 p-0 d-flex flex-row justify-content-end absolute-scrollbar-space">
                <div className={"d-none bg-light shadow border-start incident-details-side-panel" + (showBlade ? " d-flex" : "")}
                    id={PANEL_ANCHOR_ELEMENT_ID}>
                </div>
            </div>
            {showBlade &&
                <DetailsPanel floating={true} anchorElement={PANEL_ANCHOR_ELEMENT_ID} visible={false}>
                        {bladeContent === BladeContent.NOTES &&
                            <BladeContainer
                                className=""
                                status={PRIORITY[priority || PRIORITY.UNKNOWN]}
                                isPriority={true}
                                icon={ IconNames.COMMENT}
                                title={<div className="">{STRINGS.notesBlade.title}</div>}
                                onCloseClicked={() => {
                                    setShowBlade(false);
                                }}
                                size={SIZE.s}
                                showIconWithoutBg={true}
                                footerContent={<NotesForm incidentId={incident.id} afterAddingNote={(newNoteId)=> setLatestNoteId(newNoteId)} />}
                                noContentPadding
                            >
                                <NotesView incidentId={incident.id} notesQueryResult={notesQueryResult} />
                            </BladeContainer>
                        }
                        {bladeContent === BladeContent.ACTIVITY_LOG &&
                            <BladeContainer
                                className="activity-log-blade"
                                status={PRIORITY[priority || PRIORITY.UNKNOWN]}
                                isPriority={true}
                                icon={SDWAN_ICONS.TRIGGER}
                                title={<div className="">{STRINGS.activityLog.title}</div>}
                                onCloseClicked={() => {
                                    setShowBlade(false);
                                }}
                                size={SIZE.s}
                                showIconWithoutBg={true}
                                noContentPadding
                            >
                                <ActivityLogView incidentId={incident.id} refreshLog={showBlade} />
                            </BladeContainer>
                        }
                        {bladeContent === BladeContent.IMPACT_SUMMARY &&
                            <BladeContainer
                                className="impact-summary-blade"
                                status={PRIORITY[priority || PRIORITY.UNKNOWN]}
                                isPriority={true}
                                icon={impactSummaryDetails?.icon}
                                title={<div className="">{impactSummaryDetails?.title}</div>}
                                onCloseClicked={() => {
                                    setShowBlade(false);
                                }}
                                size={SIZE.s}
                                showIconWithoutBg={true}
                                noContentPadding
                            >
                                <div className="p-4"><ImpactSummaryDetailsPane subTitle={impactSummaryDetails?.subTitle || ""} 
                                    data={impactSummaryDetails?.data || []} 
                                /></div>
                            </BladeContainer>
                        }
                        {bladeContent === BladeContent.PRIORITY_REASONS &&
                            <BladeContainer
                                className="priority-reasons-blade"
                                status={PRIORITY[priority || PRIORITY.UNKNOWN]}
                                isPriority={true}
                                icon={priorityReasonsDetails?.icon}
                                title={<div className="">{priorityReasonsDetails?.title}</div>}
                                onCloseClicked={() => {
                                    setShowBlade(false);
                                }}
                                size={SIZE.s}
                                showIconWithoutBg={true}
                                noContentPadding
                            >
                                {priorityReasonsDetails?.data?.length &&
                                    <div className="display-8 p-4">
                                        <ul className={"priority-reasons-list" + (incident?.priority ? " " + incident.priority.toLocaleLowerCase() : "")}>
                                            { priorityReasonsDetails.data.map((item, index) => {
                                                return <li className={"mb-3 priority-reason" +
                                                        (item.priority ? " " + item.priority.toLocaleLowerCase() : '')}
                                                        key={"priority-reason-" + index}>
                                                        <Markdown>{formatVariables(item.text, variables)}</Markdown>
                                                    </li>;
                                            })}
                                        </ul></div>}
                            </BladeContainer>
                        }
                </DetailsPanel>
            }
            <LoadingOverlay visible={ incidentHeaderQuery.loading || loading} />
            <DataLoadFacade data={incidentHeaderQuery.data} error={incidentHeaderQuery.error}>
            <div className="mt-3">{
                incident ? 
                <OneColumnContainer>
                    <div className="d-flex flex-wrap" >
                        <IncidentImpactSummaryView showTitleIcons={showTitleIcons} incidentId={incident.id} expiresAfter={lastUpdated}
                            onDetails={(icon: string, title: string, subTitle: string, data: string[]) => {
                                setShowBlade(true);
                                setBladeContent(BladeContent.IMPACT_SUMMARY);
                                setImpactSummaryDetails({icon, title, subTitle, data});
                            }}
                        />
                        {INCIDENT_DETAILS_STYLE !== "table" && <div className="flex-grow-1" style={{width: "500px"}}>
                            <PriorityReasonsView
                                type={EntryType.PRIORITY}
                                showTitleIcons={showTitleIcons}
                                incidentId={incident.id}
                                runbookOutput={runbook}
                                onDetails={(icon: string, title: string, data: PriorityReason[] | LogEntry[]) => {
                                    setShowBlade(true);
                                    setBladeContent(BladeContent.PRIORITY_REASONS);
                                    setPriorityReasonsDetails({icon, title, data: data as PriorityReason[]});
                                }}
                            />
                        </div>}
                    </div>
                    {incidentPrefs?.timelineChart && 
                        <TimelineOutputsView incidentHeaderQuery={incidentHeaderQuery} incident={incident} />
                    }

                    {INCIDENT_DETAILS_STYLE !== "table" && <>
                        {!incidentPrefs.incidentSources && <PrimaryIndicatorView showTitleIcons={showTitleIcons} incident={incident} />}
                        <IconTitle icon={showTitleIcons ? SDWAN_ICONS.TRIGGER : undefined} title={STRINGS.incidents.runbookTitle} size={SIZE.m} className="mb-2 fw-500" />
                        {INCIDENT_DETAILS_STYLE !== "noTableOneCardForEachWidget" && <Card>
                            <RunbookOutputsTabView
                                key={"runbook-outputs" + (lastUpdated ? "-" + lastUpdated : "")}
                                incidentId={incident.id}
                                enableRerunRunbook={true /*PM asked to always enable this incident.isOngoing === true*/}
                                setRunbook={(runbook) => {setRunbook(runbook)} }
                            />
                        </Card>}
                        {INCIDENT_DETAILS_STYLE === "noTableOneCardForEachWidget" &&
                            <RunbookOutputsTabView
                                key={"runbook-outputs" + (lastUpdated ? "-" + lastUpdated : "")}
                                incidentId={incident.id}
                                enableRerunRunbook={true /*PM asked to always enable this incident.isOngoing === true*/}
                                setRunbook={(runbook) => {setRunbook(runbook)} }
                        />
                    }
                    </>}
                    {INCIDENT_DETAILS_STYLE === "table" && <>
                        <IconTitle icon={showTitleIcons ? SDWAN_ICONS.TRIGGER : undefined} title={SHOW_DETECTIONS ? STRINGS.incidents.detectionsTitle : STRINGS.incidents.incident_sources} size={SIZE.m} className="fw-bold"/>
                        {/*triggerListViewJsx*/}
                    </>}
                </OneColumnContainer> :
                <div className="text-center py-5 px-3 display-7">{STRINGS.no_data_to_display}</div>
            }</div>
            </DataLoadFacade>
        </PageWithHeader>
    </>);
};

/** Creates a popup that displays the debug information.
 *  @param json the JSON object with the debug information.
 *  @param dialogState the copied state object with the state setup to open the dialog.  The content
 *      needs to be appended and the title needs to be set in this function.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function. */
/* istanbul ignore next */
function showDebugDialog(
    json: Record<string, any>, dialogState: any, setDialogState: (dialogState: any) => void
): void {
    const newDialogState = Object.assign({}, dialogState);
    newDialogState.showDialog = true;
    newDialogState.dialogContent = <JsonViewer json={json} />;
    newDialogState.dialogFooter = <>
        <Button outlined 
            text={STRINGS.primaryIndicatorView.okBtnText}
            onClick={async (evt) => {
                setDialogState(updateDialogState(newDialogState, false, false, []));
            }}
        />
        <CopyToClipboard text={JSON.stringify(json || {}, null, 4)}>
            <Button intent={Intent.PRIMARY}
                text={STRINGS.primaryIndicatorView.copyBtnText} onClick={() => {
                    setDialogState(updateDialogState(newDialogState, false, false, []));
                }}
            />
        </CopyToClipboard>
    </>;
    setDialogState(newDialogState);
}

/** This function outputs the debug information for the debug dialog.
 *  @param incidents the Array of incidents queried, there should only be one returned.
 *  @param triggers the triggers for the incident.
 *  @returns an object with the debug information. */
function getDebugInformation(incidents: Array<Incident>, triggers: Array<Trigger>): Record<string, any> {
    if (incidents.length) {
        incidents = cloneDeep(incidents);
        for (const incident of incidents) {
            annotateTimestamp(incident, "createdAt");
            annotateTimestamp(incident, "lastUpdatedAt");
            annotateTimestamp(incident, "endTime");
            annotateTimestamp(incident, "earliestIndicator");
            annotateTimestamp(incident, "latestIndicator");
        }
    }

    if (triggers?.length) {
        triggers = cloneDeep(triggers);
        for (const trigger of triggers) {
            annotateTimestamp(trigger, "generated");
            annotateTimestamp(trigger, "earliestIndicator");
            annotateTimestamp(trigger, "latestIndicator");
        }
    }

    return {incidents, triggers}
}
