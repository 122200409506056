/** This module contains the component for the graph definitions view
 *  @module
 */

import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { HELP, STRINGS } from 'app-strings'
import { Sortable } from 'components/common/sortable/Sortable.tsx';
import { IconNames } from '@tir-ui/react-components';
import { Button, Menu, MenuItem, Popover } from '@blueprintjs/core';
import VariableField from 'components/common/graph/variables/VariableField.jsx';
import { setNativeValue } from 'reporting-infrastructure/utils/commonUtils.ts';
import { 
    StructuredVariable, StructuredVariableType, getRuntimeBuiltinVariables, RUNBOOK_SCOPE_BUILTIN_VARIABLES, RUNTIME_SCOPE, 
    PrimitiveVariable, PrimitiveVariableType, VariableCollection, getTypeKeys, EMPTY_PRIMITIVE_VARIABLE, EMPTY_KEYMETRIC_VARIABLE, 
    SUBFLOW_SCOPE, AUTH_AND_EDGE_KEYS, CONNECTOR_KEYS
} from 'utils/runbooks/VariablesUtils.ts';
import { VariableContext } from 'utils/runbooks/VariableContext.ts';
import { InlineHelp } from 'components/common/layout/inline-help/InlineHelp.tsx';
import { VariablesTableHeader } from 'components/common/graph/variables/VariableDefinitionView.tsx';
import { ScrollableErrorList } from 'components/common/error/ScrollableErrorList.tsx';
import { VariablesOperationType } from 'components/common/graph/variables/IncidentVariablesView.tsx';
import { 
    Variant, VARIANTS_WITH_AUTH_AND_EDGE_VARS, VARIANTS_WITH_CONNECTOR_VARS, VARIANTS_WITH_RUNTIME_BUILTIN_VARS 
} from 'components/common/graph/types/GraphTypes.ts';
import { CustomPropertyContext } from 'pages/create-runbook/views/create-runbook/CustomPropertyTypes.ts';
import "./VariableDefinitionView.scss";

type RuntimeVariablesProps = {
    containerRef: any,
    onRuntimeOrSubflowVariableEdited?: (updatedVariablesList) => void,
    onClose: () => void,
    runbookTriggerType?: string,
    triggerSave?,
    setSaveDisabled: (formInvalid) => void,
    isVariablePopoverOpen?: boolean,
    setRuntimeOperations: (executedOperations) => void
    variant?: Variant
}

export type VariableEntry = {
    index: number;
    variable: PrimitiveVariable | StructuredVariable,
    structured: boolean,
    isTimeseries: boolean
}

export enum PropertyOrMetricOperationType {
    'ADD',
    'DELETE',
    'CHANGE'
}

export default function RuntimeVariablesView(
    { 
        containerRef, onRuntimeOrSubflowVariableEdited, onClose, runbookTriggerType, triggerSave, setSaveDisabled, 
        isVariablePopoverOpen, setRuntimeOperations, variant
    }: RuntimeVariablesProps
) {
    const [currentVariables, setCurrentVariables] = useState<Array<VariableEntry>>([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { getVariables, syncRuntimeOrSubflowVariables } = useContext(VariableContext);
    const [formErrors, setFormErrors] = useState({});
    // formInvalid true in this case to have the Save button disabled if no changes are performed
    const [formInvalid, setFormInvalid] = useState(true);
    const [nameUniquenessError, setNameUniquenessError] = useState(false);
    const [propertyUniquenessError, setPropertyUniquenessError] = useState(false);
    const [metricUniquenessError, setMetricUniquenessError] = useState(false);
    const [executedOperations, setExecutedOperations] = useState<Array<VariablesOperationType>>([]);
    const scope: string = variant === Variant.SUBFLOW ? "subflowScope" : "runtimeScope";
    
    const customProperties = useContext(CustomPropertyContext);

    useEffect(() => {
        if (triggerSave) {
            handleSave();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerSave]);

    useEffect(() => {
        setSaveDisabled(formInvalid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formInvalid]);

    useEffect(() => {
        const uniqueOperations = new Set([...executedOperations].map(item => item));
        setRuntimeOperations(Array.from(uniqueOperations));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executedOperations]);

    useEffect(() => {
        const variables = getVariables(RUNTIME_SCOPE);
        const variableEntryList: VariableEntry[] = [];

        if (variables.primitiveVariables) {
            for (let variable of variables.primitiveVariables) {
                variableEntryList.push({ variable: { ...variable, name: stripRuntimeOrSubflowPrefix(variable.name, variant) }, structured: false, isTimeseries: false, index: variableEntryList.length });
            }
        }
        if (variables.structuredVariables) {
            for (let variable of variables.structuredVariables) {
                variableEntryList.push({ variable: { ...variable, name: stripRuntimeOrSubflowPrefix(variable.name, variant) }, structured: true, isTimeseries: variable.isTimeseries, index: variableEntryList.length });
            }
        }

        setCurrentVariables(variableEntryList);
    }, [getVariables, isVariablePopoverOpen, variant])

    const elem = useRef(null);

    function addVariable(type: any, structured = false, isTimeseries = false) {
        let variable: any = { ...EMPTY_PRIMITIVE_VARIABLE, type: type };
        if (structured) {
            variable = { ...variable, keys: [EMPTY_KEYMETRIC_VARIABLE], metrics: [EMPTY_KEYMETRIC_VARIABLE] };
        }
        setCurrentVariables([...currentVariables, { variable: { ...variable }, structured: structured, isTimeseries: isTimeseries, index: currentVariables.length }]);
        setExecutedOperations([...executedOperations, VariablesOperationType.ADD_VARIABLE]);
        // Initially the variable will not have any name so the form is invalid
        setFormInvalid(true);
        //@ts-ignore
        setTimeout(() => {elem?.current?.previousElementSibling?.children[elem?.current?.previousElementSibling?.children.length - 2].scrollIntoView({behaviour: 'smooth'})}, 300);
    }

    const hiddenValueInput = useRef<HTMLInputElement>(null);
    function triggerNativeOnChange() {
        if (hiddenValueInput.current) {
            setNativeValue(hiddenValueInput.current, new Date().getTime());
        }
    }

    const handleVariableChange = (index, event?, structuredVariable?, isProperty?, propertyOrMetricOperation?, propertyOrMetricIndex?) => {
        if (event) {
            const { name, value } = event.target;
            const changedVariables = [...currentVariables];
            if (changedVariables[index].structured && name === 'type') {
                // Remove existing metrics and keys for structured variable type change
                changedVariables[index] = {
                    ...changedVariables[index],
                    variable: {
                        ...changedVariables[index].variable,
                        metrics: value === StructuredVariableType.CUSTOM ? [EMPTY_KEYMETRIC_VARIABLE] : [],
                        keys: value === StructuredVariableType.CUSTOM ? [EMPTY_KEYMETRIC_VARIABLE] : getTypeKeys(value, customProperties),
                        type: value
                    } as StructuredVariable,
                };
            }

            const isChangeToInteger = name === "type" && value === PrimitiveVariableType.INTEGER;

            if (isChangeToInteger) {
                // Replace default value for integer type
                changedVariables[index] = {
                    ...changedVariables[index],
                    variable: {
                        ...changedVariables[index].variable,
                       defaultValue: isNaN(value) ? '0' : value
                    } as PrimitiveVariable,
                };
            }

            changedVariables[index] = { ...changedVariables[index], variable: { ...changedVariables[index].variable, [name]: value } };
            setCurrentVariables(changedVariables);
            setExecutedOperations([...executedOperations, VariablesOperationType.CHANGE_VARIABLE]);
            // Enable save on type change
            if (['type', 'defaultValue'].includes(name)) {
                setSaveDisabled(false);
            }
        }
        else if (structuredVariable) {
            const changedVariables = [...currentVariables];
            let newStructuredVariable;
            if (propertyOrMetricOperation === PropertyOrMetricOperationType.DELETE) {
                let newKeysOrMetrics = isProperty ? [...(changedVariables[index].variable as StructuredVariable).keys] : [...(changedVariables[index].variable as StructuredVariable).metrics];
                newKeysOrMetrics.splice(propertyOrMetricIndex, 1);
                newStructuredVariable = isProperty ? { ...changedVariables[index].variable, keys: newKeysOrMetrics } :
                    { ...changedVariables[index].variable, metrics: newKeysOrMetrics };
            } else if (propertyOrMetricOperation === PropertyOrMetricOperationType.ADD ||
                propertyOrMetricOperation === PropertyOrMetricOperationType.CHANGE) {
                newStructuredVariable = isProperty ? { ...changedVariables[index].variable, keys: structuredVariable } :
                    { ...changedVariables[index].variable, metrics: structuredVariable };
            }
            changedVariables[index] = { ...changedVariables[index], variable: newStructuredVariable };
            setCurrentVariables(changedVariables);
            setExecutedOperations([...executedOperations, VariablesOperationType.CHANGE_VARIABLE]);
        }
    };

    function handleDelete(index) {
        const newCurrentVariables = currentVariables.filter(variable => variable.index !== index);
        const newRuntimeVariables: VariableCollection = {
            primitiveVariables: newCurrentVariables.filter(variable => !variable.structured).map(variable => variable.variable as PrimitiveVariable),
            structuredVariables: newCurrentVariables.filter(variable => variable.structured).map(variable => variable.variable as StructuredVariable)
        };
        // Remove variables errors
        errorsListener(index, null);
        syncRuntimeOrSubflowVariables(newRuntimeVariables);
        setExecutedOperations([...executedOperations, VariablesOperationType.DELETE_VARIABLE]);
    }

    function handleSave() {
        // Add id for custom properties and metrics and remove empty properties and metrics
        for (const variable of currentVariables.filter(variable => variable.structured)) {
            for (const key of (variable.variable as StructuredVariable).keys ) {
                if (!key.id && key.label) {
                    key.id = key.label.replace(/ /g, "_");
                } else if (!key.label) {
                    (variable.variable as StructuredVariable).keys = (variable.variable as StructuredVariable).keys.filter(item => {return item !== key;});
                }
            }
            for (const metric of (variable.variable as StructuredVariable).metrics ) {
                if (!metric.id && metric.label) {
                    metric.id = metric.label.replace(/ /g, "_");
                } else if (!metric.label) {
                    (variable.variable as StructuredVariable).metrics = (variable.variable as StructuredVariable).metrics.filter(item => {return item !== metric;});
                }
            }
        };
        // Save the variables with the runtime or subflow prefix appended
        const newRuntimeVariables: VariableCollection = {
            primitiveVariables: currentVariables.filter(variable => !variable.structured).map(variable => {
                return { ...(variable.variable as PrimitiveVariable), name: addRuntimeOrSubflowPrefix(variable.variable.name, variant) }
            }),
            structuredVariables: currentVariables.filter(variable => variable.structured).map(variable => {
                return { ...(variable.variable as StructuredVariable), isTimeseries: variable.isTimeseries, name: addRuntimeOrSubflowPrefix(variable.variable.name, variant) }
            }),
        };

        const unique = new Set([...newRuntimeVariables.primitiveVariables, ...newRuntimeVariables.structuredVariables].map(item => item.name));
        let nameUniqueness = Array.from(unique).length === [...newRuntimeVariables.primitiveVariables, ...newRuntimeVariables.structuredVariables].length;
        let foundDuplicateProperty = false;
        let foundDuplicateMetric = false;
        const customStructuredVariables = currentVariables?.filter(item => item?.variable?.type === StructuredVariableType.CUSTOM);
        if (customStructuredVariables?.length) {
            for (const customStructuredVariable of customStructuredVariables) {
                const unique = new Set([...(customStructuredVariable.variable as StructuredVariable).keys].map(item => item.label));
                const propertyUniqueness = Array.from(unique).length === [...(customStructuredVariable.variable as StructuredVariable).keys].length;
                if (!propertyUniqueness) {
                    foundDuplicateProperty = true;
                    break;
                }
            }
            for (const customStructuredVariable of customStructuredVariables) {
                const unique = new Set([...(customStructuredVariable.variable as StructuredVariable).metrics].map(item => item.label));
                const metricUniqueness = Array.from(unique).length === [...(customStructuredVariable.variable as StructuredVariable).metrics].length;
                if (!metricUniqueness) {
                    foundDuplicateMetric = true;
                    break;
                }
            }
        }
        if (nameUniqueness && !foundDuplicateProperty && !foundDuplicateMetric) {
            newRuntimeVariables.primitiveVariables.forEach((variable, index) => {
                if (variable.type === "boolean" && variable.defaultValue === "") {
                    newRuntimeVariables.primitiveVariables[index].defaultValue = "false";
                }
            });
            syncRuntimeOrSubflowVariables(newRuntimeVariables);
            if (onRuntimeOrSubflowVariableEdited) {
                onRuntimeOrSubflowVariableEdited(newRuntimeVariables);
            }
            onClose();
        } else {
            setFormInvalid(true);
            setNameUniquenessError(!nameUniqueness);
            setPropertyUniquenessError(foundDuplicateProperty);
            setMetricUniquenessError(foundDuplicateMetric);
        }
    }

    // Strip the runtime. prefix for variable name
    function stripRuntimeOrSubflowPrefix(name: string, variant: Variant | undefined) {
        const scope: string = variant && variant === Variant.SUBFLOW ? SUBFLOW_SCOPE : RUNTIME_SCOPE; 
        if (name.includes(scope + ".")) {
            return name.slice(scope.length + 1);
        }
        return name;
    }

    // Add the runtime. prefix for variable name
    function addRuntimeOrSubflowPrefix(name: string, variant: Variant | undefined) {
        return (variant && variant === Variant.SUBFLOW ? SUBFLOW_SCOPE : RUNTIME_SCOPE) + '.' + name;
    }

    function errorsListener(key, errors) {
        const newFormState = { ...formErrors, [key]: errors }
        let hasErrors = false;

        for (let state in newFormState) {
            if (newFormState[state] && newFormState[state].length) {
                hasErrors = true;
            }
        }

        setFormErrors(newFormState);
        setFormInvalid(hasErrors);
    }

    const builtinVariables = useMemo(() => {
        return [...RUNBOOK_SCOPE_BUILTIN_VARIABLES, ...getRuntimeBuiltinVariables(runbookTriggerType)].map((variable) => {
            return <VariableField
                key={variable.name}
                variable={variable}
                structured={false}
                timeseries={false}
                builtin={true}
                variant={variant}></VariableField>
        });
    }, [runbookTriggerType, variant]);

    const errorsOnSave = useRef<string[]>([]);

    useEffect(() => {
        if (nameUniquenessError && !errorsOnSave.current.includes(STRINGS.runbookEditor.errors.uniqueVariableNameError)) {
            errorsOnSave.current.push(STRINGS.runbookEditor.errors.uniqueVariableNameError);
        }
    
        if (propertyUniquenessError && !errorsOnSave.current.includes(STRINGS.runbookEditor.errors.uniquePropertyNameError)) {
            errorsOnSave.current.push(STRINGS.runbookEditor.errors.uniquePropertyNameError);
        }

        if (metricUniquenessError && !errorsOnSave.current.includes(STRINGS.runbookEditor.errors.uniqueMetricNameError)) {
            errorsOnSave.current.push(STRINGS.runbookEditor.errors.uniqueMetricNameError);
        }
    
        if (!nameUniquenessError) {
            errorsOnSave.current = [...errorsOnSave.current].filter(
                item => item !== STRINGS.runbookEditor.errors.uniqueVariableNameError
            );
        }

        if (!propertyUniquenessError) {
            errorsOnSave.current = [...errorsOnSave.current].filter(
                item => item !== STRINGS.runbookEditor.errors.uniquePropertyNameError
            );
        }

        if (!metricUniquenessError) {
            errorsOnSave.current = [...errorsOnSave.current].filter(
                item => item !== STRINGS.runbookEditor.errors.uniqueMetricNameError
            );
        }
    }, [nameUniquenessError, propertyUniquenessError, metricUniquenessError]);

    return (
        <>
            <div className="add-variable-control m-2">
                <Popover
                    fill={true}
                    usePortal={true}
                    content={<Menu>
                        {Object.keys(PrimitiveVariableType).filter((key) => {
                            return VARIANTS_WITH_AUTH_AND_EDGE_VARS.includes(variant!) || (!AUTH_AND_EDGE_KEYS.includes(key));
                        }).filter((key) => {
                            return VARIANTS_WITH_CONNECTOR_VARS.includes(variant!) || !CONNECTOR_KEYS.includes(key);
                        }).map((key) => {
                            return <MenuItem key={PrimitiveVariableType[key]} text={STRINGS.runbookEditor.variableDefinitions.primitiveVariable.types[key]}
                                onClick={() => addVariable(PrimitiveVariableType[key])} />
                        })}
                        <MenuItem key={STRINGS.runbookEditor.variableDefinitions.structuredVariable.variableType.summarized}
                            text={STRINGS.runbookEditor.variableDefinitions.structuredVariable.variableType.summarized}
                            onClick={() => addVariable(StructuredVariableType.CUSTOM, true, false)} />
                        <MenuItem key={STRINGS.runbookEditor.variableDefinitions.structuredVariable.variableType.timeseries}
                            text={STRINGS.runbookEditor.variableDefinitions.structuredVariable.variableType.timeseries}
                            onClick={() => addVariable(StructuredVariableType.CUSTOM, true, true)} />
                    </Menu>}
                    placement="left-start"
                >
                    <InlineHelp
                        helpMapping={HELP.RunbookNodeCategory.variableDefinitions.runtimeScope.addVariable}>
                            <Button
                                minimal
                                id="add-variable"
                                className='fw-bold'
                                icon={IconNames.ADD}
                                text={STRINGS.runbookEditor.variableDefinitions[scope].addVariable.label}
                                disabled={false}
                            />
                    </InlineHelp>                
                </Popover>
                <p className='fw-light'>
                    {STRINGS.runbookEditor.variableDefinitions[scope].addVariable.info}
                </p>
            </div>
            {errorsOnSave.current.length > 0 && <ScrollableErrorList items={errorsOnSave.current}/> }
            <div className='variable-list pe-2 mb-2'>
                <input type="text" className="d-none" ref={hiddenValueInput} defaultValue={new Date().getTime()} />
                <InlineHelp
                    helpMapping={HELP.RunbookNodeCategory.variableDefinitions.runtimeScope.userDefined}>
                        <label className='display-7 fw-bold'>{STRINGS.runbookEditor.variableDefinitions[scope].userDefinedLabel}</label>
                </InlineHelp>
                <VariablesTableHeader />
                {currentVariables.length ? <Sortable
                    dragOnlyUsingHandles
                    items={currentVariables.map((variable) => {
                        return {
                            record: variable,
                            contents: <VariableField
                                key={variable.index + variable.variable.type}
                                index={variable.index}
                                variable={variable.variable}
                                structured={variable.structured}
                                timeseries={variable.isTimeseries}
                                handleVariableChange={handleVariableChange}
                                errorsListener={errorsListener}
                                handleDelete={handleDelete}
                                draggable
                                variant={variant}></VariableField>
                        }
                    })}
                    onChange={updatedVariablesList => {
                        setCurrentVariables([
                            ...updatedVariablesList.map(item => item.record),
                        ]);
                        triggerNativeOnChange();
                    }}
                    onDragStart={() => containerRef.current?.classList.add("reorder-in-progress")}
                    onDragEnd={() => containerRef.current?.classList.remove("reorder-in-progress")}
                /> : <p className='d-flex justify-content-center text-muted display-7'>{STRINGS.runbookEditor.variableDefinitions[scope].noVariables}</p>}
                <div style={{ float:"left", clear: "both" }}
                    ref={elem}>
                </div>
                {VARIANTS_WITH_RUNTIME_BUILTIN_VARS.includes(variant!) && <>
                    <InlineHelp helpMapping={HELP.RunbookNodeCategory.variableDefinitions.runtimeScope.builtin}>
                        <label className='display-7 fw-bold'>{STRINGS.runbookEditor.variableDefinitions[scope].builtin.label}</label>
                    </InlineHelp>
                    <VariablesTableHeader />
                    {builtinVariables}
                </>}
            </div>
        </>
    )
}
