/** This file defines the ai node editor React component. The ai node editor allows you 
 *  to configure the instructions and query for the AI node.
 *  @module */

import React, { useContext, useMemo, useState } from "react";
import { SimpleNodeEditorProps } from "components/common/graph/editors/simple/SimpleNodeEditor.tsx";
import { HELP, STRINGS } from "app-strings";
import { UniversalNode } from "components/common/graph/UniversalNode.ts";
import { VARIANTS_WITH_GLOBAL_VARS, VARIANTS_WITH_INCIDENT_VARS } from "components/common/graph/types/GraphTypes.ts";
import { Button, HTMLSelect, Intent, TextArea } from "@blueprintjs/core";
import { AiNodeUtils, AI_NODE_EDIT_PROPS } from "components/common/graph/editors/ai/AiNodeUtils.ts";
import { SHOW_CONTEXT } from "components/enums/QueryParams.ts";
import { RunbookContextSummary } from "components/common/graph/editors/RunbookContextSummary.tsx";
import { NodeLibraryNode } from "pages/create-runbook/views/create-runbook/NodeLibrary.ts";
import { VariableContext } from 'utils/runbooks/VariableContext.ts';
import { VariableContextByScope } from "utils/runbooks/RunbookContext.class.ts";
import { AI_PRIM_VAR_TYPES, GLOBAL_SCOPE, INCIDENT_SCOPE, RUNTIME_SCOPE } from "utils/runbooks/VariablesUtils.ts";
import { InlineHelp } from "components/common/layout/inline-help/InlineHelp.tsx";
import { GenerateInstructionsDialog } from "components/common/graph/editors/ai/GenerateInstructionsDialog.tsx";
import { TestDialog } from "components/common/graph/editors/ai/TestDialog.tsx";

/** this interface defines the properties that are used to store the nodes configurable parameters. */
interface AiNodeProperties {
    /** a String with the instructions location. */
    instructionsLocation?: string;
    /** a String with the instructions content. */
    instructions?: string;
    /** a String with the query location. */
    queryLocation?: string;
    /** a String with the query content. */
    query?: string;

    instructionsVariables?: string;
    queryVariables?: string;
}

/** Component for editing the properties of a ai node
 *  @param selectedNode - Currently selected active ai node
 *  @param libraryNode- Selected ai node's meta data.
 *  @param graphDef - Graphdef object that defines the entire runbook. Provides a way to access all the nodes in the graph 
 *  @returns a JSX component with the ai node editor. */
export const AiNodeEditor = React.forwardRef(({ selectedNode, libraryNode, graphDef, ...props }: SimpleNodeEditorProps, ref: any): JSX.Element => {
    const {getVariables} = useContext(VariableContext);

    let instructionsLoc = selectedNode?.getProperty(AI_NODE_EDIT_PROPS.INSTRUCTIONS_LOC) ? selectedNode?.getProperty(AI_NODE_EDIT_PROPS.INSTRUCTIONS_LOC) : undefined;
    let instructionsVar = "none";
    if (instructionsLoc !== undefined && !["none", "$trigger", "$parent", "enter", ""].includes(instructionsLoc)) {
        instructionsVar = instructionsLoc;
        instructionsLoc = "$variable";
    }

    let queryLoc = selectedNode?.getProperty(AI_NODE_EDIT_PROPS.QUERY_LOC) ? selectedNode?.getProperty(AI_NODE_EDIT_PROPS.QUERY_LOC) : undefined;
    let queryVar = "none";
    if (queryLoc !== undefined && !["none", "$trigger", "$parent", "enter", ""].includes(queryLoc)) {
        queryVar = queryLoc;
        queryLoc = "$variable";
    }

    const [curProperties, setCurProperties] = useState<AiNodeProperties>({
        instructionsLocation: instructionsLoc,
        instructionsVariables: instructionsVar,
        instructions: selectedNode?.getProperty(AI_NODE_EDIT_PROPS.INSTRUCTIONS) ? selectedNode?.getProperty(AI_NODE_EDIT_PROPS.INSTRUCTIONS) : undefined,
        queryLocation: queryLoc,
        queryVariables: queryVar,
        query: selectedNode?.getProperty(AI_NODE_EDIT_PROPS.QUERY) ? selectedNode?.getProperty(AI_NODE_EDIT_PROPS.QUERY) : undefined,
    });

    const [isGeneratingInstr, setIsGeneratingInstr] = useState<boolean>(false);
    const [isTesting, setIsTesting] = useState<boolean>(false);

    /** create a reference to the genai strings. */
    const genAiResources: Required<Partial<typeof STRINGS.runbookEditor.nodeLibrary.nodes.genai>> = STRINGS.runbookEditor.nodeLibrary.nodes.genai;
    
    /** Update the selected node properties. This is invoked when done button is clicked
     *      on the node editor dialog.
     *  @param properties -  Properties in selected node that need to updated
     *  @param selectedNode - node being editied
     *  @param libraryNode - object that specifies all the editable properties for a given node. */
    function updateNode(properties: AiNodeProperties, selectedNode: UniversalNode | undefined, libraryNode: NodeLibraryNode | undefined) {
        if (!selectedNode || !libraryNode || !properties) {
            console.warn("updateNode has invlaid inputs. Node update failed");
            return;
        }

        let instructionsLoc: string | undefined = curProperties?.instructionsLocation;
        if (instructionsLoc === "$variable") {
            const varName: string | undefined = curProperties.instructionsVariables;
            instructionsLoc = varName;
        }
        selectedNode.setProperty(AI_NODE_EDIT_PROPS.INSTRUCTIONS_LOC, instructionsLoc);
        selectedNode.setProperty(AI_NODE_EDIT_PROPS.INSTRUCTIONS, curProperties?.instructions);
        
        let queryLoc: string | undefined = curProperties?.queryLocation;
        if (queryLoc === "$variable") {
            const varName: string | undefined = curProperties.queryVariables;
            queryLoc = varName;
        }
        selectedNode.setProperty(AI_NODE_EDIT_PROPS.QUERY_LOC, queryLoc);
        selectedNode.setProperty(AI_NODE_EDIT_PROPS.QUERY, curProperties?.query);
    }

    ref.current = {
        updateNode: () => {
            updateNode(curProperties, selectedNode, libraryNode);
        },
        validate: () => {
            const errorMessages = new Array<string>();

            let instructionsLoc: string | undefined = curProperties?.instructionsLocation;
            if (instructionsLoc === "$variable") {
                const varName: string | undefined = curProperties.instructionsVariables;
                instructionsLoc = varName;
            }
            
            let queryLoc: string | undefined = curProperties?.queryLocation;
            if (queryLoc === "$variable") {
                const varName: string | undefined = curProperties.queryVariables;
                queryLoc = varName;
            }
    
            AiNodeUtils.validateNodeProperties(
                {
                    [AI_NODE_EDIT_PROPS.INSTRUCTIONS_LOC]: instructionsLoc,
                    [AI_NODE_EDIT_PROPS.INSTRUCTIONS]: curProperties?.instructions,
                    [AI_NODE_EDIT_PROPS.QUERY_LOC]: queryLoc,
                    [AI_NODE_EDIT_PROPS.QUERY]: curProperties?.query
                },
                errorMessages,
            );
            return errorMessages;
        }
    };

    const variant = props.variant;
    const variableOptions: {label: string, value: string}[] = useMemo<{label: string, value: string}[]>(() => {
        // The options need to be set only if the filter field type is a select drop-down
        const variables: VariableContextByScope = {
            runtime: getVariables(RUNTIME_SCOPE),
            incident: !VARIANTS_WITH_INCIDENT_VARS.includes(variant!)
                ? {primitiveVariables: [], structuredVariables: []} 
                : getVariables(INCIDENT_SCOPE),
            global: !VARIANTS_WITH_GLOBAL_VARS.includes(variant!) 
                ? {primitiveVariables: [], structuredVariables: []} 
                : getVariables(GLOBAL_SCOPE)
        };
        const options: any[] = [{label: genAiResources.selectVarOption, value: "none"}];
        for (const scope in variables) {
            const varCollection = variables[scope];
            if (varCollection.primitiveVariables) {
                for (const variable of varCollection.primitiveVariables) {
                    if (AI_PRIM_VAR_TYPES.includes(variable.type)) {
                        options.push({
                            label: variable.name, 
                            value: variable.name, 
                            //disabled: !optionsValidity[optionKey]
                        });
                    }
                }
            }
            if (varCollection.structuredVariables) {
                for (const variable of varCollection.structuredVariables) {
                    options.push({
                        label: variable.name, 
                        value: variable.name, 
                        //disabled: !optionsValidity[optionKey]
                    });
                }
            }
        }
        return options;
    },[getVariables, variant]);

    const optionsForInstructionsDropDown: {label: string, value: string}[] = useMemo<{label: string, value: string}[]>(() => {
        const options: any[] = [{label: genAiResources.instructionsSection.options.selectItem, value: "none"}];
        options.push({label: genAiResources.instructionsSection.options.enterInstr, value: "enter"});
        if (variableOptions.length > 1) {
            options.push({label: genAiResources.instructionsSection.options.fromVariable, value: "$variable"});
        }
        return options;
    },[variableOptions]);

    const optionsForQueryDropDown: {label: string, value: string}[] = useMemo<{label: string, value: string}[]>(() => {
        const options: any[] = [{label: genAiResources.querySection.options.selectItem, value: "none"}];
        options.push({label: genAiResources.querySection.options.enterQuery, value: "enter"});
        if (variableOptions.length > 1) {
            options.push({label: genAiResources.querySection.options.fromVariable, value: "$variable"});
        }
        options.push({label: genAiResources.querySection.options.fromParent, value: "$parent"});
        options.push({label: genAiResources.querySection.options.fromTrigger, value: "$trigger"});
        return options;
    },[variableOptions]);

    const showInstructionsEntry: boolean = curProperties[AI_NODE_EDIT_PROPS.INSTRUCTIONS_LOC] === "enter";
    const showInstructionsVariables: boolean = curProperties[AI_NODE_EDIT_PROPS.INSTRUCTIONS_LOC] === "$variable";
    const showQueryEntry: Boolean = curProperties[AI_NODE_EDIT_PROPS.QUERY_LOC] === "enter";
    const showQueryVariables: boolean = curProperties[AI_NODE_EDIT_PROPS.QUERY_LOC] === "$variable";
    
    return (
        <>
            <GenerateInstructionsDialog open={isGeneratingInstr} 
                prompt={curProperties[AI_NODE_EDIT_PROPS.INSTRUCTIONS] || ""}
                onClose={(prompt: string) =>  {
                    setCurProperties({ ...curProperties, [AI_NODE_EDIT_PROPS.INSTRUCTIONS]: prompt });
                    setIsGeneratingInstr(false);
                }}
            />
            <TestDialog open={isTesting} instructions={curProperties?.instructions || ""}
                query={curProperties?.query || ""} onClose={() => setIsTesting(false)}
            />
            <tr><td className="font-size-md-large fw-bold pt-2" colSpan={2}>
                <InlineHelp helpMapping={HELP.RunbookNodeCategory.Ai.AiNode.Instructions}>
                    {genAiResources.instructionsSection.label}
                </InlineHelp>
            </td></tr>
            <tr><td className="display-9 pt-2" colSpan={2}>
                {genAiResources.instructionsSection.instructions}
            </td></tr>
            <tr>
                <td className="p-1" colSpan={2}>
                    <HTMLSelect
                        data-testid={ "instructions-prompt" }
                        required={ true }
                        key={ "instructions-prompt" }
                        name={ "instructions-prompt" }
                        fill={ true }
                        options={ optionsForInstructionsDropDown }
                        value={ curProperties[AI_NODE_EDIT_PROPS.INSTRUCTIONS_LOC] }
                        onChange={ e => {
                            const newInstructionsType = e.target.value;
                            setCurProperties({ ...curProperties, [AI_NODE_EDIT_PROPS.INSTRUCTIONS_LOC]: newInstructionsType });
                        } }
                    />
                </td>
            </tr>
            {showInstructionsEntry && <tr>
                <td className="pt-1">
                    <TextArea
                        required={ true }
                        value={ curProperties[AI_NODE_EDIT_PROPS.INSTRUCTIONS] || ""}
                        name={ "instructions-content" }
                        growVertically={ true }
                        fill={ true }
                        placeholder={genAiResources.instructionsSection.placeholder}
                        style={{minHeight: "90px"}}
                        onChange={ e => {
                            setCurProperties({ ...curProperties, [AI_NODE_EDIT_PROPS.INSTRUCTIONS]: e.target.value });
                        } }
                    />
                    <br />
                    <div className="w-100 d-flex justify-content-end pt-2">
                        <Button intent={Intent.SUCCESS} aria-label="generate" disabled={false} type="button"
                            onClick={() => setIsGeneratingInstr(true)}
                        >
                            { genAiResources.instructionsSection.generateBtn }
                        </Button>
                    </div>
                </td>
            </tr>}
            {showInstructionsVariables && <tr>
                <td className="p-1" colSpan={2}>
                    <HTMLSelect
                        data-testid={ "instructions-variables-prompt" }
                        required={ true }
                        key={ "instructions-variables-prompt" }
                        name={ "instructions-variables-prompt" }
                        fill={ true }
                        options={ variableOptions }
                        value={ curProperties[AI_NODE_EDIT_PROPS.INSTRUCTIONS_VARS] }
                        onChange={ e => {
                            const newInstructionsType = e.target.value;
                            setCurProperties({ ...curProperties, [AI_NODE_EDIT_PROPS.INSTRUCTIONS_VARS]: newInstructionsType });
                        } }
                    />
                </td>
            </tr>}
            <tr><td className="font-size-md-large fw-bold pt-2" colSpan={2}>
                <InlineHelp helpMapping={HELP.RunbookNodeCategory.Ai.AiNode.Query}>
                    {genAiResources.querySection.label}
                </InlineHelp>
            </td></tr>
            <tr><td className="display-9 pt-2" colSpan={2}>
                {genAiResources.querySection.instructions}
            </td></tr>
            <tr>
                <td className="p-1" colSpan={2}>
                    <HTMLSelect
                        data-testid={ "query-prompt" }
                        required={ true }
                        key={ "query-prompt" }
                        name={ "query-prompt" }
                        fill={ true }
                        options={ optionsForQueryDropDown }
                        value={ curProperties[AI_NODE_EDIT_PROPS.QUERY_LOC] }
                        onChange={ e => {
                            const newQueryType = e.target.value;
                            setCurProperties({ ...curProperties, [AI_NODE_EDIT_PROPS.QUERY_LOC]: newQueryType });
                        } }
                    />
                </td>
            </tr>
            {showQueryEntry && <tr>
                <td className="pt-1">
                    <TextArea
                        required={ true }
                        defaultValue={ curProperties[AI_NODE_EDIT_PROPS.QUERY] || ""}
                        name={ "query-content" }
                        growVertically={ true }
                        fill={ true }
                        placeholder={genAiResources.querySection.placeholder}
                        style={{minHeight: "90px"}}
                        onBlur={ e => {
                            setCurProperties({ ...curProperties, [AI_NODE_EDIT_PROPS.QUERY]: e.target.value });
                        } }
                    />
                    <br />
                    <div className="w-100 d-flex justify-content-end pt-1">
                        <Button intent={Intent.SUCCESS} aria-label="test" disabled={curProperties.instructionsLocation === "$variable"} type="button"
                            name="test-button"
                            onClick={() => setIsTesting(true)}
                        >
                            { genAiResources.querySection.testBtn }
                        </Button>
                    </div>
                </td>
            </tr>}
            {showQueryVariables && <tr>
                <td className="p-1" colSpan={2}>
                    <HTMLSelect
                        data-testid={ "query-variables-prompt" }
                        required={ true }
                        key={ "query-variables-prompt" }
                        name={ "query-variables-prompt" }
                        fill={ true }
                        options={ variableOptions }
                        value={ curProperties[AI_NODE_EDIT_PROPS.QUERY_VARS] }
                        onChange={ e => {
                            const newInstructionsType = e.target.value;
                            setCurProperties({ ...curProperties, [AI_NODE_EDIT_PROPS.QUERY_VARS]: newInstructionsType });
                        } }
                    />
                </td>
            </tr>}
            <RunbookContextSummary 
                currentProperties={JSON.parse(JSON.stringify(curProperties))} 
                node={graphDef.nodes.find(node => node.id === selectedNode?.getId())!} graphDef={graphDef} 
                showOutputExample={true} showInputExample={SHOW_CONTEXT}
            />
        </>
    )
});
