/** This module creates a new node for the react-flow graph that represents an Azure node.
 *  @module
 */
import { type CSSProperties } from "react";
import { useNavigate } from "react-router-dom";
import { STRINGS } from 'app-strings';
import { type IconName, Menu, MenuDivider, MenuItem, ContextMenu } from "@blueprintjs/core";
import { IconNames as BPIconNames } from "@blueprintjs/icons";
import { Icon, IconNames } from "@tir-ui/react-components";
import { PROVIDER_TYPES } from "components/enums/CloudIM.ts";
import { PARAM_NAME } from "components/enums/QueryParams.ts";
import { getURL, setQueryParams } from 'utils/hooks/useQueryParams.ts';
import { openModal } from "components/common/modal/ModalRenderer.tsx";
import { GetURLPath as getURLPath } from 'config/routes.ts';
import { SEARCH_TYPE } from "components/reporting/facet/FacetUtils.ts";
import { Handle, type NodeProps, Position } from "react-flow-renderer";
import { AzureSupportedTypes, AZURE_TYPES_TO_LABEL_MAP } from "utils/cloudim/TopologyUtils.ts";
import type { PropertiesModalProps } from "components/common/graph/cloudim-react-flow/components/properties/modal/PropertiesModal.tsx";

import './AzureNode.scss';

export default function AzureNode({ data }: NodeProps) {
    const navigate = useNavigate();

    const handleStyle: CSSProperties = { visibility: "hidden" };
    const icons = {
        [AzureSupportedTypes.TENANT]: IconNames.AZURE_LOGO,
        [AzureSupportedTypes.SUBSCRIPTION]: IconNames.AZURE_SUBSCRIPTION,
        [AzureSupportedTypes.RESOURCEGROUP]: IconNames.AZURE_RESOURCE_GROUP,
        [AzureSupportedTypes.VIRTUALNETWORK]: IconNames.AZURE_VIRTUAL_NETWORK,
        [AzureSupportedTypes.SUBNET]: IconNames.AZURE_SUBNET,
        [AzureSupportedTypes.VIRTUALMACHINE]: IconNames.AZURE_VIRTUAL_MACHINE,
        [AzureSupportedTypes.FUNCTIONAPP]: IconNames.AZURE_FUNCTION_APP,
        [AzureSupportedTypes.ADX]: IconNames.AZURE_ADX,
        [AzureSupportedTypes.STORAGEACCOUNT]: IconNames.AZURE_STORAGE_ACCOUNT,
        [AzureSupportedTypes.SQLSERVER]: IconNames.AZURE_SQL_SERVER,
        [AzureSupportedTypes.POSTGRESQLSERVER]: IconNames.AZURE_POSTGRESQL_SERVER,
    }

    let iconToUse: any = IconNames.BLANK;
    if (icons[data.type]) {
        iconToUse = icons[data.type];
    }

    return (
        <ContextMenu
            content={
                <Menu>
                    <MenuDivider title={
                        <div className="azureContextMenu">
                            <div className="icon" title={AZURE_TYPES_TO_LABEL_MAP[data.type]}>
                                <Icon size={24} icon={iconToUse} />
                            </div>
                            <div className="details">
                                <span>
                                    {data.label}
                                </span>
                                <span className="text-secondary display-9 fw-500">
                                    {AZURE_TYPES_TO_LABEL_MAP[data.type]}
                                </span>
                            </div>
                        </div>
                    } />
                    <MenuItem icon={IconNames.SEARCH} text={STRINGS.cloudim.topology.contextMenu.search} onClick={() => {
                        setQueryParams({ [PARAM_NAME.searchText]: data.label }, true);
                    }} />
                    <MenuItem icon={BPIconNames.MODAL as IconName} text={STRINGS.cloudim.topology.contextMenu.properties} onClick={() => {
                        openModal('cloudIMPropertiesModal', {
                            iconProperties: {
                                size: 40,
                                icon: iconToUse
                            },
                            filter: {
                                provider: PROVIDER_TYPES.AZURE,
                                type: data.type,
                                id: data.label,
                            },
                            nodeData: data,
                            onClose: () => {},
                        } as PropertiesModalProps);
                    }} />
                    <MenuItem icon={BPIconNames.APPLICATIONS as IconName} text={STRINGS.cloudim.topology.contextMenu.assetsView} onClick={() => {
                        navigate(
                            getURL(getURLPath('explorer'), {
                                searchType: SEARCH_TYPE.cloudassets,
                                searchText: data.label,
                            }, { replaceQueryParams: true })
                        );
                    }} />
                    <MenuDivider />
                    <MenuItem icon={IconNames.DUPLICATE} text={STRINGS.cloudim.topology.contextMenu.copy} onClick={() => {
                        navigator.clipboard.writeText(data.label);
                    }} />
                </Menu>
            }
        >
            <div className="azureNode" onClick={(e) => {
                const hasShowText = e.currentTarget.classList.contains("showText");
                hasShowText ? e.currentTarget.classList.remove("showText") : e.currentTarget.classList.add("showText");
            }}>
                <div className="icon" title={AZURE_TYPES_TO_LABEL_MAP[data.type]}>
                    <Icon size={24} icon={iconToUse} />
                </div>
                <Handle
                    type="target"
                    position={Position.Left}
                    style={handleStyle}
                />
                <Handle
                    type="source"
                    position={Position.Right}
                    style={handleStyle}
                />
                <div className="labelContainer">
                    <div className="details">
                        <span>
                            {data.label}
                        </span>
                    </div>
                </div>
            </div>
        </ContextMenu>
    );
}
