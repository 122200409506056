/** This module defines a React component that displays the search results.
 *  @module
 */
import { SearchItemType } from "components/common/search/search-results-table/SearchResultsTable.tsx";
/* hidding per [#6657, #6658]
import { SearchSiteItem } from "../search-site-item/SearchSiteItem";
import { SearchAppItem } from "../search-app-item/SearchAppItem";
import { SearchDeviceItem } from "../search-device-item/SearchDeviceItem";
*/
import { SearchRunbookItem } from "components/common/search/search-items/search-runbook-item/SearchRunbookItem.tsx";
import { SearchIncidentItem } from "components/common/search/search-items/search-incident-item/SearchIncidentItem.tsx";
import { SearchNetworkDeviceItem } from "components/common/search/search-items/search-network-device-item/SearchNetworkDeviceItem.tsx";
import { SearchNetworkInterfaceItem } from "components/common/search/search-items/search-network-interface-item/SearchNetworkInterfaceItem.tsx";
import { SearchApplicationItem } from "components/common/search/search-items/search-application-item/SearchApplicationItem.tsx";
import { SearchImpactedUserItem } from "components/common/search/search-items/search-impacted-user-item/SearchImpactedUserItem.tsx";
import { SearchImpactedApplicationItem } from "components/common/search/search-items/search-impacted-application-item/SearchImpactedApplicationItem.tsx";
import { SearchImpactedLocationItem } from "components/common/search/search-items/search-impacted-location-item/SearchImpactedLocationItem.tsx";
import { SearchLocationItem } from "components/common/search/search-items/search-location-item/SearchLocationItem.tsx";
import { SearchCustomPropertyItem } from "components/common/search/search-items/search-custom-property-item/SearchCustomPropertyItem.tsx";

/** this interface defines a search result item. */
export interface SearchItem {
    /** the id of the item. */
    id: string;
    /** the SearchItemType with the type of search result. */
    type: SearchItemType;
    /** the data for the search result item. */
    formattedData: {
        /** a String with the title. */
        title?: string;
        /** a string with the name of the search item. */
        name?: string;
        /** a String with the description of the search item. */
        description?: string;
    };
    /** the meta object with the score. */
    meta?: {
        /** the score of the search item. */
        fuzzyScore?: number;
    };
    /* hidding per [#6657, #6658]
    site?: any;
    app?: any;
    device?: any;
    */
    /** the runbook information. */
    runbook?: any;
    /** the incident information. */
    incident?: any;
    /** the device information returned by search. */
    device?: any;
    /** the interface information returned by search. */
    interface?: any;
    /** the application information returned by search. */
    application?: any;
    /** the location information returned by search. */
    location?: any;
    /** the impacted user information.  */
    impactedUser?: any;
    /** the impacted application information.  */
    impactedApplication?: any;
    /** the impacted location information.  */
    impactedLocation?: any;
    /** the custom property information.  */
    customProperty?: any;
}

/** this interface defines the properties passed into the search results item component. */
export interface SearchResultsItemProps {
    /** the SearchItem with the data to be used to display the search results for the item. */
    data: SearchItem;
    /** the row index of the search result in the table. */
    rowIndex: number;
}

/** Creates the the search results item component which displays the search result.
 *  @param props an object with the properties passed to the search results item component.
 *  @returns JSX with the search results item component.*/
export function SearchResultsItem({ data, rowIndex }: SearchResultsItemProps) {
    let searchItem = <></>;
    switch (data.type) {
        /*  hidding per [#6657, #6658]
        case SearchItemType.SITE:
            searchItem = <SearchSiteItem data={data} />
            break;
        case SearchItemType.APP:
            searchItem = <SearchAppItem data={data} />
            break;
        */
        case SearchItemType.DEVICE:
            searchItem = <SearchNetworkDeviceItem data={data} rowIndex={rowIndex} />
            break;
        case SearchItemType.INTERFACE:
            searchItem = <SearchNetworkInterfaceItem data={data} rowIndex={rowIndex} />
            break;
        case SearchItemType.APPLICATION:
            searchItem = <SearchApplicationItem data={data} rowIndex={rowIndex} />
            break;
        case SearchItemType.LOCATION:
            searchItem = <SearchLocationItem data={data} rowIndex={rowIndex} />
            break;
        case SearchItemType.IMPACTED_USER:
            searchItem = <SearchImpactedUserItem data={data} rowIndex={rowIndex} />
            break;
        case SearchItemType.IMPACTED_APPLICATION:
            searchItem = <SearchImpactedApplicationItem data={data} rowIndex={rowIndex} />
            break;
        case SearchItemType.IMPACTED_LOCATION:
            searchItem = <SearchImpactedLocationItem data={data} rowIndex={rowIndex} />
            break;
        case SearchItemType.CUSTOM_PROPERTY:
            searchItem = <SearchCustomPropertyItem data={data} rowIndex={rowIndex} />
            break;
        case SearchItemType.RUNBOOK:
            searchItem = <SearchRunbookItem data={data} rowIndex={rowIndex} />
            break;
        case SearchItemType.INCIDENT:
            searchItem = <SearchIncidentItem data={data} rowIndex={rowIndex} />
            break;
        default:
            console.warn(`Invalid search result type found: ${data.type}`);
    }
    return <div className="d-flex flex-row mt-4 w-100">
        {searchItem}
    </div>
}

// TODO Can be removed after the demo.
export const USE_MOCK_DATA = false;
