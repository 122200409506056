/** This module contains the component for a container that holds two columns.  This container
 *  is used in most of the pages.
 *  @module
 */

import { Button } from '@blueprintjs/core';
import { SIZE } from 'components/enums/Sizes.ts';
import { IconTitle, type IconTitleProps } from 'components/common/icon-title/IconTitle.tsx';
import React, { type ReactNode } from 'react';
import { IconNames } from '@tir-ui/react-components';

/** This interface defines the properties that are passed into the two column container React component. */
export interface TwoColumnContainerProps extends IconTitleProps {
    showBackButton?: boolean;
    onBackClicked?: Function;
    children?: ReactNode;
    noPaddingOnFirstColumn?: boolean;
    noPaddingOnSecondColumn?: boolean;
    /** the width of the first column. */
    firstColumnSize?: SIZE.s | SIZE.m | SIZE.l;
    /** Toggles left sidebar. */
    hideLeftColumn?: boolean;
    /** if this is set the column width will not be set on the first column. */
    doNotSetColumnWidth?: boolean;
    /* Pass titleContent if you wish to show a full lengt title
    which can also be a complex JSX */
    titleContent?: ReactNode;
}

/** Renders the two column container react component.
 *  @param props the properties passed in.
 *  @returns JSX with the two column container component.*/
function TwoColumnContainer ({
    showBackButton = false,
    onBackClicked,
    noPaddingOnFirstColumn,
    noPaddingOnSecondColumn,
    firstColumnSize = SIZE.m,
    hideLeftColumn = false,
    doNotSetColumnWidth = false,
    titleContent,
    ...props
}: TwoColumnContainerProps) {
    const [firstColumnContent, secondColumnContent] = React.Children.toArray(props.children);
    const mainContent = <div className="two-column-tab d-flex flex-column flex-lg-row h-100">
        <div className={(hideLeftColumn ? "d-none " : "") + "left-column flex-column" + (
            doNotSetColumnWidth ? "" : firstColumnSize === SIZE.l ? " flex-grow-1 w-50" : (firstColumnSize === SIZE.s ? " w-min-3" :  " w-min-5")
        )}>
            { (props.icon || props.title || showBackButton) && <div className="flex-grow-0">
                { showBackButton && <Button className="back-button" role="navigate back" icon={IconNames.ARROW_LEFT} minimal={true} onClick={(e) => onBackClicked && onBackClicked(e)}/> }
                {(props.icon || props.title) && <IconTitle size={SIZE.m} icon={props.icon} status={props.status} title={props.title} bold={true} className="m-2"/>}
            </div>}
            <div className={"display-8 fw-bold flex-grow-1" + (noPaddingOnFirstColumn ? "" : " m-4")}>
                {firstColumnContent}
            </div>
        </div>
        
        <div className={"flex-grow-1 overflow-auto" + (noPaddingOnSecondColumn ? "" : " ps-2 pe-3") + (firstColumnSize === SIZE.l ? " w-50" : "")}>
            {secondColumnContent}
        </div>
    </div>;
    if (titleContent) {
        return <div className="d-flex flex-column">
            <div className="flex-grow-0">{titleContent}</div>
            {mainContent}
        </div>
    } else {
        return mainContent;
    }
}

export { TwoColumnContainer };
