/** This module contains the component for the graph definitions view
 *  @module
 */

import { useEffect, useState, useContext } from 'react';
import { STRINGS } from 'app-strings';
import { Button, HTMLSelect, Icon, Intent } from '@blueprintjs/core';
import { IconNames } from '@tir-ui/react-components';
import { Form } from 'components/common/form/Form.tsx';
import * as yup from 'yup';
import { Unit } from 'reporting-infrastructure/types/Unit.class.ts';
import { 
    type StructuredVariable, StructuredVariableType, type PrimitiveVariable, PrimitiveVariableType, getTypeKeys, 
    type ElementForStructuredVariable, getMetricUnitAndType, EMPTY_KEYMETRIC_VARIABLE 
} from 'utils/runbooks/VariablesUtils.ts';
import { InputFieldWithErrorTooltip } from 'components/common/form/InputFieldWithErrorTooltip.tsx';
import StructuredVariableField from 'components/common/graph/variables/StructuredVariableField.tsx';
import { PropertyOrMetricOperationType } from 'components/common/graph/variables/RuntimeVariablesView.tsx';
import { MultiSelectInput } from 'components/common/multiselect/MultiSelectInput.tsx';
import { DataOceanUtils } from 'components/common/graph/editors/data-ocean/DataOceanUtils.ts';
import { validIP } from 'utils/validators/Validators.ts';
import { CustomPropertyContext } from 'pages/create-runbook/views/create-runbook/CustomPropertyTypes.ts';
import { type Variant, VARIANTS_WITH_AUTH_AND_EDGE_VARS, VARIANTS_WITH_CONNECTOR_VARS } from 'components/common/graph/types/GraphTypes.ts';
import { getArDataSources } from 'utils/stores/GlobalDataSourceTypeStore.ts';
import './VariableDefinitionView.scss';

type VariableFieldProps = {
    index?: number
    variable: PrimitiveVariable | StructuredVariable,
    structured: boolean,
    timeseries: boolean,
    draggable?: boolean,
    handleVariableChange?,
    errorsListener?,
    handleDelete?,
    builtin?: boolean,
    variant?: Variant
}

export default function VariableField({ index, variable, structured, timeseries, draggable, handleVariableChange, errorsListener, handleDelete, builtin, ...props }: VariableFieldProps) {

    const [showStructured, setShowStructured] = useState(true);
    const [typeProperties, setTypeProperties] = useState<ElementForStructuredVariable[]>([]);
    const [metrics, setMetrics] = useState<any[]>([]);
    const [selectedMetrics, setSelectedMetrics] = useState<any[]>((variable as StructuredVariable)?.metrics);
    const [metricsUpdated, setMetricsUpdated] = useState(false);
    
    const customProperties = useContext(CustomPropertyContext);

    useEffect(() => {
        if (structured && variable.type !== StructuredVariableType.CUSTOM) {
            setTypeProperties(getTypeKeys(variable.type, customProperties) as ElementForStructuredVariable[]);
            const allMetrics = DataOceanUtils.dataOceanMetaData?.obj_types;
            let currentTypeMetrics: any[] = [];
            for (let metric in allMetrics) {
                if (metric.split('.')[0] === variable.type) {
                    currentTypeMetrics.push(...allMetrics[metric].metrics);
                }
            }
            // Get the available metrics from a list that does not have duplicates
            let availableMetrics = DataOceanUtils.getMetricsDisplayData(Array.from(new Set(currentTypeMetrics.map(item => item)).values()));
            availableMetrics = availableMetrics.map((metric) => {
                const metricWithUnitAndType = {...metric, ...getMetricUnitAndType(metric.value)};
                return metricWithUnitAndType;
            })
            setMetrics(availableMetrics);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variable.type]);

    useEffect(() => {
        if (metricsUpdated && structured && variable.type !== StructuredVariableType.CUSTOM) {
            addPropertyOrMetric(false, selectedMetrics, true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [metricsUpdated]);

    useEffect(() => {
        if (structured && variable.type !== StructuredVariableType.CUSTOM) {
            addPropertyOrMetric(true, typeProperties);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typeProperties]);

    function addPropertyOrMetric(isProperty = true, propertiesOrMetrics?, empty = false) {
        if (structured && variable.type !== StructuredVariableType.CUSTOM && propertiesOrMetrics) {
            handleVariableChange(index, null, propertiesOrMetrics, isProperty, PropertyOrMetricOperationType.ADD, empty ? -1 : undefined);
            setMetricsUpdated(false);
        } else {
            let newVariable = isProperty ? [...((variable as StructuredVariable)?.keys || [])] : [...((variable as StructuredVariable)?.metrics || [])];
            newVariable?.push(EMPTY_KEYMETRIC_VARIABLE);
            handleVariableChange(index, null, newVariable, isProperty, PropertyOrMetricOperationType.ADD, empty ? -1 : undefined);
            setMetricsUpdated(false);
        }
    }

    function deletePropertyOrMetric(structuredIndex, isProperty = true) {
        errorsListener(index, null);
        handleVariableChange(index, null, true, isProperty, PropertyOrMetricOperationType.DELETE, structuredIndex);
    }

    function changePropertyOrMetric(event, isProperty, structuredIndex) {
        const { name, value } = event.target;
        let newVariable = isProperty ? [...(variable as StructuredVariable).keys] : [...(variable as StructuredVariable).metrics];
        newVariable[structuredIndex] = { ...newVariable[structuredIndex], [name]: value };
        handleVariableChange(index, null, newVariable, isProperty, PropertyOrMetricOperationType.CHANGE, structuredIndex);
    }

    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .label(STRINGS.runbookEditor.variableDefinitions.variableTable.name)
            .required()
            .matches(/^([a-zA-Z0-9_]|-)*$/, 'No space or special characters supported for variable names'),
        type: yup
            .string()
            .label(STRINGS.runbookEditor.variableDefinitions.variableTable.type)
            .required(),
        unit: yup
            .string()
            .label(STRINGS.runbookEditor.variableDefinitions.variableTable.unit)
            .oneOf(Unit.VAR_UNITS),
        defaultValue: yup
            .string()
            .label(STRINGS.runbookEditor.variableDefinitions.variableTable.initValue)
            .when([], {
                is: () => variable.type === PrimitiveVariableType.STRING && typeof(variable.value) !== "undefined",
                then: yup.string().nullable(true),
                otherwise: yup.string().test(
                    'defaultValue',
                    STRINGS.runbookEditor.variableDefinitions.primitiveVariable.typeValidationError,
                    function (value) {
                        if (typeof(value) === "undefined") return true;
                        if(!!value) {
                            let schema;
                            switch(variable.type) {
                                case PrimitiveVariableType.INTEGER:
                                    return /^[-+]?[0-9]+$/.test(value);
                                case PrimitiveVariableType.BOOLEAN:
                                    schema = yup.boolean();
                                    return schema.isValidSync(value);
                                case PrimitiveVariableType.FLOAT:
                                    return /^[-+]?[0-9]+\.[0-9]+$/.test(value);
                                case PrimitiveVariableType.TIMESTAMP:
                                    return !isNaN(new Date(+value).getTime());
                                case PrimitiveVariableType.IPADDR:
                                    return validIP(value);
                                case PrimitiveVariableType.STRING:
                                    return yup.string().nullable();
                                case PrimitiveVariableType.AUTH_PROFILE:
                                    return yup.string();
                                case PrimitiveVariableType.ALLUVIO_EDGE:
                                    return yup.string();
                                case PrimitiveVariableType.JSON:
                                    return yup.string().nullable();
                                case PrimitiveVariableType.CONNECTOR:
                                    return yup.string().nullable();
                            }
                        }
                        return false;
                    }
                )
            })
    });

    function getTooltipContent(key: ElementForStructuredVariable) {
        var tooltipContent = "";
        if (key.label) {
            tooltipContent += "<b>" + key.label + "</b>";
        }
        if (key.id) {
            tooltipContent += "<br>" + STRINGS.runbookEditor.nodeEditor.tooltips.id + key.id;
        }
        if (key.type) {
            tooltipContent += "<br>" + STRINGS.runbookEditor.nodeEditor.tooltips.type + key.type.charAt(0).toUpperCase() + key.type.slice(1);
        }
        if (key.unit) {
            tooltipContent += "<br>" + STRINGS.runbookEditor.nodeEditor.tooltips.unit + key.unit.charAt(0).toUpperCase() + key.unit.slice(1);
        }
        return tooltipContent;
    }

    return (<><Form
        initialValues={{
            name: variable.name,
            type: variable.type,
            unit: structured ? 'N/A' : (variable as PrimitiveVariable).unit || Unit.VAR_UNITS.slice(-1)[0],
            defaultValue: structured ? undefined : (variable as PrimitiveVariable).defaultValue || ''
        }}
        loading={false}
        validationSchema={validationSchema}
    >
        <div className='variables-grid pb-2'>
            <span className='d-flex align-items-center justify-content-center'>
                {draggable ? <Icon className="drag-handle"
                    icon={IconNames.DRAG_HANDLE_VERTICAL} /> : null}
            </span>
            <span>
                <InputFieldWithErrorTooltip
                    name="name"
                    placeholder={STRINGS.runbookEditor.variableDefinitions.variableTable.name}
                    value={variable.name || ""}
                    fill={true}
                    onChange={e => handleVariableChange(index, e)}
                    notifyErrors={(name, errors) => errorsListener(index, errors)}
                    disabled={builtin}>
                </InputFieldWithErrorTooltip>
            </span>
            <span>
                <HTMLSelect
                    fill={true}
                    name="type"
                    disabled={builtin}
                    options={structured ?
                        Object.keys(StructuredVariableType).filter((key) => {
                            return key !== "DATA_SOURCE" || (getArDataSources() || []).length;
                        }).map((key) => {
                            let label = timeseries ? STRINGS.runbookEditor.variableDefinitions.structuredVariable.types[key] + ' ' + STRINGS.runbookEditor.variableDefinitions.structuredVariable.timeseriesType :
                            STRINGS.runbookEditor.variableDefinitions.structuredVariable.types[key];
                            if (!timeseries && ((variable as any)?.keys?.length || (variable as any)?.metrics?.length) ) {
                                label = `${STRINGS.runbookEditor.variableDefinitions.structuredVariable.types[key]} ${STRINGS.runbookEditor.variableDefinitions.structuredVariable.variableType.summarized}`;
                            }
                            return { label, value: StructuredVariableType[key] }
                        }) :
                        Object.keys(PrimitiveVariableType).map((key) => {
                            return { label: STRINGS.runbookEditor.variableDefinitions.primitiveVariable.types[key], value: PrimitiveVariableType[key] }
                        }).filter((option) => {
                            return VARIANTS_WITH_AUTH_AND_EDGE_VARS.includes(props.variant!) || ![PrimitiveVariableType.ALLUVIO_EDGE, PrimitiveVariableType.AUTH_PROFILE].includes(option.value);
                        }).filter((option) => {
                            return VARIANTS_WITH_CONNECTOR_VARS.includes(props.variant!) || ![PrimitiveVariableType.CONNECTOR].includes(option.value);
                        })}
                    value={variable.type}
                    onChange={e => handleVariableChange(index, e)}
                />
            </span>
            <span>
                <HTMLSelect
                    disabled={builtin || (variable.type !== PrimitiveVariableType.INTEGER && variable.type !== PrimitiveVariableType.FLOAT)}
                    fill={true}
                    name="unit"
                    options={Unit.VAR_UNITS}
                    value={(variable as PrimitiveVariable).unit || Unit.VAR_UNITS[Unit.VAR_UNITS.length - 1]}
                    onChange={e => handleVariableChange(index, e)} />
            </span>
            {variable.type === PrimitiveVariableType.BOOLEAN && 
                <span>
                    <HTMLSelect
                        fill={true}
                        placeholder={STRINGS.runbookEditor.variableDefinitions.variableTable.initValue}
                        name="defaultValue"
                        disabled={builtin || structured}
                        options={[{label: 'Select', value: ""}, {label: 'True', value: "true"}, {label: 'False', value: "false"}]}
                        value={(variable as PrimitiveVariable).defaultValue || ""}
                        onChange={e => handleVariableChange(index, e)} />
                </span>
            }
            {variable.type !== PrimitiveVariableType.BOOLEAN && 
                <span>
                    <InputFieldWithErrorTooltip
                        placeholder={STRINGS.runbookEditor.variableDefinitions.variableTable.initValue}
                        name="defaultValue"
                        disabled={builtin || structured || variable.type === PrimitiveVariableType.AUTH_PROFILE || variable.type === PrimitiveVariableType.ALLUVIO_EDGE}
                        value={(variable as PrimitiveVariable).defaultValue || ""}
                        notifyErrors={(name, errors) => errorsListener(index, errors)}
                        onChange={e => handleVariableChange(index, e)} />
                </span>
            }
            <span className='d-flex align-items-center justify-content-center'>
                <Icon onClick={() => {
                    if (!builtin) {
                        handleDelete(index);
                    }
                }}
                    className={builtin ? "d-none" : ''}
                    intent={Intent.NONE}
                    icon={IconNames.CROSS} />
            </span>
        </div>
    </Form>
        {structured ?
            <>
                <p className='m-2 fw-bold'>
                    {STRINGS.runbookEditor.variableDefinitions.structuredVariable.metricsAndProperties}
                    <Icon icon={showStructured ? IconNames.CHEVRON_UP : IconNames.CHEVRON_DOWN}
                        onClick={() => {
                            setShowStructured(!showStructured);
                        }} />
                </p>

                {showStructured ?
                    <>
                        <div className="properties properties-background ms-2 p-2 mb-2">
                            {variable.type === StructuredVariableType.CUSTOM ?
                                <>
                                    {(variable as StructuredVariable).keys.map(
                                        (property, propertyIndex) => <StructuredVariableField
                                            key={'var' + index + 'property' + propertyIndex}
                                            index={propertyIndex}
                                            variable={property}
                                            isProperty={true}
                                            builtin={false}
                                            handleDelete={deletePropertyOrMetric}
                                            handleVariableChange={changePropertyOrMetric}
                                            errorsListener={errorsListener}
                                        />
                                    )}
                                    <Button
                                        role="button"
                                        icon={IconNames.PLUS}
                                        text={STRINGS.runbookEditor.variableDefinitions.structuredVariable.addProperty}
                                        onClick={() => addPropertyOrMetric(true, null, true)}
                                        small
                                        minimal
                                    />
                                </>
                                : <>
                                    <p>{STRINGS.runbookEditor.variableDefinitions.structuredVariable.properties}</p>
                                    <MultiSelectInput
                                        key={'Properties' + index + typeProperties[0]?.label}
                                        items={typeProperties?.map(key => {
                                            return { display: key.label, value: key.id }
                                        })}
                                        selectedItems={typeProperties?.map(key => {
                                            return { display: key.label, value: key.id, tooltip: getTooltipContent(key) }
                                        })}
                                        disabled={true}
                                    />
                                </>
                            }
                        </div>
                        <div className="properties properties-background ms-2 p-2 mb-2">
                            {variable.type === StructuredVariableType.CUSTOM ?
                                <>
                                    {(variable as StructuredVariable).metrics.map(
                                        (metric, metricIndex) => <StructuredVariableField
                                            key={'var' + index + 'metric' + metricIndex}
                                            index={metricIndex}
                                            variable={metric}
                                            isProperty={false}
                                            builtin={false}
                                            handleDelete={deletePropertyOrMetric}
                                            handleVariableChange={changePropertyOrMetric}
                                            errorsListener={() => { }}
                                        />
                                    )}
                                    <Button
                                        role="button"
                                        icon={IconNames.PLUS}
                                        text={STRINGS.runbookEditor.variableDefinitions.structuredVariable.addMetric}
                                        onClick={() => addPropertyOrMetric(false, null, true)}
                                        small
                                        minimal
                                    />
                                </> : <>
                                    <p>{STRINGS.runbookEditor.variableDefinitions.structuredVariable.metrics}</p>
                                    <MultiSelectInput
                                        // Using type properties name for rerendering since the metrics usually have the same name
                                        key={'Metrics' + index + typeProperties[0]?.label} 
                                        items={metrics.map(key => {
                                            return { display: key.label, value: key.value, unit: key.unit, type: key.type }
                                        })}
                                        selectedItems={ selectedMetrics?.map(key => {
                                            return { display: key.label, value: key.id, unit: key.unit, type: key.type }
                                        }) }
                                        onChange={(selectedItems) => {
                                            let variableMetrics: ElementForStructuredVariable[] = [];
                                            for (let item of selectedItems) {
                                                if (item.value !== '') {
                                                    variableMetrics.push({
                                                        id: item.value,
                                                        label: item.display,
                                                        type: item.type,
                                                        unit: item.unit
                                                    });    
                                                }
                                            }
                                            setSelectedMetrics(variableMetrics);
                                            setMetricsUpdated(true);
                                        }}
                                    />
                                </>}
                        </div></> : null}
            </> : null}</>)
}
