/** This file defines a node to wrap the various graph provider nodes.
 *  @module 
 */

import { Node } from 'react-flow-renderer';
import { NodeDef, NodeEnvSetting, NodeProperty, NodeWiresSetting } from "./types/GraphTypes.ts";
import { STRINGS } from "app-strings";
 
/** this class encapsulates the functionality for a node that wraps all graph vendor's nodes. */
export class UniversalNode {
    /** a String with the vendor name */
    public vendor: "react-flow" | "config";
    /** the node object. */
    public node: Node | NodeDef | undefined;
 
    /** creates a new instance of universal node.
     *  @param node the node object.
     *  @param vendor a String with the vendor name.*/
    public constructor(node: Node | NodeDef | undefined, vendor: "react-flow" | "config") {
        this.node = node;
        this.vendor = vendor;
    }

    /** gets the id out of the node.
     *  @returns the value for the id.*/
     public getId(): string | null | undefined {
        switch (this.vendor) {
            case "react-flow":
                return (this.node as Node)?.id;
            case "config":
                return (this.node as NodeDef)?.id;
        }
        return null;
    }

    /** gets the type out of the node.
     *  @returns the value for the type.*/
     public getType(): string | null | undefined {
        switch (this.vendor) {
            case "react-flow":
                return (this.node as Node)?.data?.type;
            case "config":
                return (this.node as NodeDef)?.type;
        }
        return null;
    }

    /** gets the color out of the node.
     *  @returns the value for the color.*/
     public getColor(): string | null | undefined {
        switch (this.vendor) {
            case "react-flow":
                return (this.node as Node)?.data?.color;
            case "config":
                return (this.node as NodeDef)?.color;
        }
        return null;
    }

    /** gets the wires setting out of the node.
     *  @returns the value for the wires setting.*/
     public getWires(): NodeWiresSetting | null | undefined {
        switch (this.vendor) {
            case "react-flow":
                return (this.node as Node)?.data?.wires;
            case "config":
                return (this.node as NodeDef)?.wires;
        }
        return null;
    }

    /** sets the wires setting out of the node.
     *  @param wires the value for the wires setting.*/
     public setWires(wires: NodeWiresSetting): void {
        switch (this.vendor) {
            case "react-flow":
                if ((this.node as Node)?.data) {
                    (this.node as Node).data.wires = wires;
                }
                break;
            case "config":
                break;
        }
    }

    /** gets the name out of the node..  Note that if there is an i18n string defined then
     *      this will override the name property.
     *  @returns the value for the name.*/
     public getName(): string | null | undefined {
        switch (this.vendor) {
            case "react-flow": {
                const name = (this.node as Node)?.data?.i18nNameKey ? 
                    STRINGS.defaultRunbooks[(this.node as Node)?.data?.i18nNameKey] : (this.node as Node)?.data?.label;
                return name;
            }
            case "config":
                return (this.node as NodeDef)?.name;
        }
        return null;
    }

    /** sets the name of the node.
     *  @param the value for the name.*/
     public setName(name: string): void {
        switch (this.vendor) {
            case "react-flow":
                if ((this.node as Node)?.data) {
                    const origName = (this.node as Node).data.i18nNameKey ? 
                        STRINGS.defaultRunbooks[(this.node as Node)?.data?.i18nNameKey] : (this.node as Node)?.data?.label;
                    if (name !== origName) {
                        delete (this.node as Node).data.i18nNameKey;
                    }
                    (this.node as Node).data.label = name;
                }
                break;
            case "config":
                (this.node as NodeDef).name = name;
                break;
        }
    }

    /** gets the info property out of the node.  Note that if there is an i18n string defined then
     *      this will override the info property.
     *  @returns the value for the info property.*/
     public getInfo(): string | null | undefined {
        switch (this.vendor) {
            case "react-flow": {
                const info = (this.node as Node)?.data?.i18nInfoKey ? 
                    STRINGS.defaultRunbooks[(this.node as Node)?.data?.i18nInfoKey] : (this.node as Node)?.data?.info;
                return info;
            }
            case "config":
                return (this.node as NodeDef)?.info;
        }
        return null;
    }

    /** sets the info property on the node.
     *  @param the value for the info property.*/
     public setInfo(info: string): void {
        switch (this.vendor) {
            case "react-flow":
                if ((this.node as Node)?.data) {
                    const origInfo = (this.node as Node).data.i18nInfoKey ? 
                        STRINGS.defaultRunbooks[(this.node as Node)?.data?.i18nInfoKey] : (this.node as Node)?.data?.info;
                    if (info !== origInfo) {
                        delete (this.node as Node).data.i18nInfoKey;
                    }
                    (this.node as Node).data.info = info;
                }
                break;
            case "config":
                (this.node as NodeDef).info = info;
                break;
        }
    }

    /** gets a property out of the node.
     *  @returns the value for the key.*/
    public getProperties(): Array<NodeProperty> {
        switch (this.vendor) {
            case "react-flow":
                return (this.node as Node)?.data?.properties;
            case "config":
                return (this.node as NodeDef)?.properties || [] as Array<NodeProperty>;
        }
        return [] as Array<NodeProperty>;
    }

    /** gets a property out of the node.
     *  @param key the property key.
     *  @returns the value for the key.*/
    public getProperty(key: string): any | null | undefined {
        let properties: Array<NodeProperty>;
        switch (this.vendor) {
            case "react-flow":
                properties = (this.node as Node)?.data?.properties;
                break;
            case "config":
                properties = (this.node as NodeDef)?.properties ||[] as Array<NodeProperty>;
                break;
        }

        if (properties) {
            for (const property of properties) {
                if (property.key === key) {
                    return property.value;
                }
            }
        }
        return null;
    }

    /** sets a property in the node.
     *  @param key the property key.
     *  @param value the property value.
     *  @returns the value for the key.*/
     public setProperty(key: string, value: any): void {
        let properties: Array<NodeProperty> | undefined;
        switch (this.vendor) {
            case "react-flow":
                properties = (this.node as Node)?.data?.properties;
                if (!properties && (this.node as Node).data) {
                    (this.node as Node).data.properties = properties = ([] as Array<NodeProperty>);
                }
                break;
            case "config":
                properties = (this.node as NodeDef)?.properties;
                if (!properties) {
                    (this.node as NodeDef).properties = properties = ([] as Array<NodeProperty>);
                }
                break;
        }
        if (properties) {
            let set = false;
            for (const property of properties) {
                if (property.key === key) {
                    property.value = value;
                    set = true;
                    break;
                }
            }
            if (!set) {
                properties.push({key, value});
            }
        }
    }

    /** removes a property out of the node.
     *  @param key the property key.
     *  @returns nothing.*/
    public removeProperty(key: string): any | null | undefined {
        let properties: Array<NodeProperty>;
        switch (this.vendor) {
            case "react-flow":
                properties = (this.node as Node)?.data?.properties;
                if (properties) {
                    // @ts-ignore
                    this.node.data.properties = properties.filter( el => {
                        return el.key !== key;
                    });
                }

                break;
            case "config":
                properties = (this.node as NodeDef)?.properties ||[] as Array<NodeProperty>;
                if (properties) {
                    // @ts-ignore
                    this.node.properties = properties.filter( el => {
                        return el.key !== key;
                    });
                }
                break;
        }
        return null;
    }

    /** gets the array of pass through properties out of the node.
     *  @returns the array of pass through properties or null.*/
     public getPassThroughProperties(): Array<NodeProperty> | null | undefined {
        switch (this.vendor) {
            case "react-flow":
                return (this.node as Node)?.data.passThruProperties;
            case "config":
                return (this.node as NodeDef)?.passThruProperties;
        }
        return null;
    }

    
    /** gets a environement setting out of the node.
     *  @param key the setting key.
     *  @returns the value for the key.*/
     public getEnv(key: string): any | null | undefined {
        let settings: Array<NodeEnvSetting>;
        switch (this.vendor) {
            case "react-flow":
                settings = (this.node as Node)?.data?.env;
                break;
            case "config":
                settings = (this.node as NodeDef)?.env || [] as Array<NodeEnvSetting>;
                break;
        }
        if (settings) {
            for (const setting of settings) {
                if (setting.name === key) {
                    return setting.value;
                }
            }
        }
        return null;
    }

    /** gets a environement setting out of the node.
     *  @returns the Array of node env settings.*/
     public getEnvSettings(): Array<NodeEnvSetting> {
        let settings: Array<NodeEnvSetting>;
        switch (this.vendor) {
            case "react-flow":
                settings = (this.node as Node)?.data?.env;
                break;
            case "config":
                settings = (this.node as NodeDef)?.env || [] as Array<NodeEnvSetting>;
                break;
        }
        return settings || ([] as Array<NodeEnvSetting>);
    }

    /** sets an environment setting in the node.
     *  @param newSettings the array of new settings.*/
     public setEnv(newSettings: Array<NodeEnvSetting>): void {
        let settings: Array<NodeEnvSetting> = ([] as Array<NodeEnvSetting>);
        if (newSettings) {
            settings = settings.concat(newSettings);
        }
        switch (this.vendor) {
            case "react-flow":
                if ((this.node as Node)?.data) {
                    (this.node as Node).data.env = settings;
                }
                break;
            case "config":
                (this.node as NodeDef).env = settings;
                break;
        }
    }

    /** gets the name out of the node..  Note that if there is an i18n string defined then
     *      this will override the name property.
     *  @returns the value for the name.*/
    public getData(): { [x:string]: any } | undefined {
        return (this.node as Node)?.data;
    }

    /** sets data attributes in the node.
     *  @param key the data key.
     *  @param value the data value.
     *  @returns the value for the key.*/
    public setData(key: string, value: any): void {
        let data: Object;
        data = (this.node as Node)?.data;
        if (data) {
            let set = false;
            Object.keys(data).map((dataKey) => {
                if (dataKey === key) {
                    set = true;
                    if (value.length) {
                        data[dataKey] = value;
                    } else {
                        delete data[dataKey];
                    }
                    return null;
                }
                return null;
            });
            if (!set && value) {
                data[key] = value;
            }
        }
    }
}
