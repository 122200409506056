/** This module contains the component for rendering the MonthToDateTotal card.
 *  @module
 */
import React from "react";
import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { SIZE } from "components/enums/Sizes.ts";
import { SummaryCard } from "components/common/layout/summary-card/SummaryCard.tsx";
import { Unit } from "reporting-infrastructure/types/Unit.class";
import { scaleMetric } from "reporting-infrastructure/utils/formatters/MetricFormatter";

import { STRINGS } from "app-strings";

/** Renders the MonthToDateTotal card.
 *  @returns JSX with the MonthToDateTotal card.*/
const MonthToDateTotalCard = (props: any): JSX.Element => {
    const { data, percentage } = props;

    return (
        <React.Fragment>
            <SummaryCard
                isSummary
                title={
                    <span>
                        {
                            STRINGS.USAGE_MONITORING_PAGE.cards.nodeUsage
                                .monthToDateTotal.title
                        }
                    </span>
                }
                size="flex"
                height={SIZE.m}
                className="w-min-2"
                footerContent={
                    <div className="d-flex justify-content-center fs-7">
                        {percentage ? (
                            percentage > 0 ? (
                                <span>
                                    <Icon icon={IconNames.ARROW_UP} />
                                </span>
                            ) : (
                                <span>
                                    <Icon icon={IconNames.ARROW_DOWN} />
                                </span>
                            )
                        ) : null}
                        <span>
                            {
                                scaleMetric(
                                    Math.abs(percentage),
                                    new Unit("pct"),
                                ).formatted
                            }
                        </span>
                        &nbsp;
                        <span>
                            {
                                STRINGS.USAGE_MONITORING_PAGE.cards.nodeUsage
                                    .monthToDateTotal.sinceLastMonth
                            }
                        </span>
                    </div>
                }
            >
                {data && "totalWeightedCount" in data && (
                    <div className="display-6">
                        <span className="rounded py-1 px-3 fw-normal text-nowrap">
                            {data &&
                                "totalWeightedCount" in data &&
                                new Intl.NumberFormat().format(
                                    data["totalWeightedCount"],
                                )}
                        </span>
                    </div>
                )}
            </SummaryCard>
        </React.Fragment>
    );
};

export { MonthToDateTotalCard };
