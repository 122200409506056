mutation setAutomationMapping($mapping: AutomationMappingInput!, $expression: ExpressionInput) {
    setAutomationMapping(mapping: $mapping, expression: $expression) {
        id
        name
        order
        description
        enabled
        triggerType
        lastUpdatedBy
        lastUpdatedOn
        runbookId
        runbookName
        isFactoryRunbook
    }
}
