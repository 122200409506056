query NetworkDevices($filter: NetworkDeviceFilterInputType) {
    networkDevices(filter: $filter) {
        page {
            id
            name
            ipAddress
            vendor
            type
            model
            location {
                id
                elementType
                name
                city
                state
                country
                countryCode
                latitude
                longitude
                type
            }
            serialNumber
            osVersion
            isGateway
            elementType
            hostname
            locationId
            customProperties {
                id
                name
                description
                lastUpdatedAt
                permissions
                assignedValue {
                    id
                    name
                }
                values {
                    id
                    name
                }
                validTypes {
                    type
                }
            }
            networkInterfaces {
                id
                name
                ifIpAddresses
                inboundSpeed
                outboundSpeed
                ifDescription
                ifAlias
                ifIndex
                location {
                    id
                    elementType
                    name
                    city
                    state
                    country
                    countryCode
                    latitude
                    longitude
                    type
                }
                type
                ipAddress
                elementType
                locationId
            }
            reportedBy {
                dataSourceType
                dataSourceId
            }
        }
    }
}
