/** This file defines the CardsView React component.  The CardsView React component wraps a 
 *  metric mini component and includes the query to get the data but can also get the data 
 *  passed in from the runbook view.  This component is used within a runbook view.
 *  @module */

import { CardsHolder } from "components/common/layout/cards-holder/CardsHolder.tsx";
import { MetricMiniCard } from "components/common/layout/metric-mini-card/MetricMiniCard.tsx";
import { Query } from "reporting-infrastructure/types/Query.ts";
import { useQuery } from "utils/hooks/useQuery.ts";
import { FILTER_NAME } from "components/sdwan/enums/filters.ts";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade.tsx";
import type { DataSet, BaseRunbookViewProps } from "pages/riverbed-advisor/views/runbook-view/Runbook.type.ts";
import { Unit } from "reporting-infrastructure/types/Unit.class.ts";
import { formatKeys } from "utils/runbooks/RunbookFormatter.tsx";
import { formatValue, getComparisonParameters, getTimestamp, keysMatch } from "utils/runbooks/RunbookOutputUtils.ts";
import { STRINGS } from "app-strings";
import { INCIDENT_DETAILS_STYLE } from "components/enums/QueryParams.ts";
import SUMMARY_DATA_QUERY from '../table-view/summary-data-query.graphql';
import TIMESERIES_QUERY from '../timeseries-view/timeseries-query.graphql';
import "./CardsView.css";

/** Creates the cards view, which is a component that displays n cards with analytics data.
 *  @param props an object with the properties passed to the cards view.
 *  @returns JSX with the cards component.*/
export const CardsView = (props: BaseRunbookViewProps): JSX.Element => {
    let { comparisonOffset = 0, comparisonSuffix = "" } = getComparisonParameters(props.widget?.options?.comparedTo as string);
    const dataset: DataSet | undefined = props.datasets?.length > 0 ? props.datasets[0] : undefined;
    const comparisonDataset: DataSet | undefined = props.datasets?.length > 1 ? props.datasets[1] : undefined;
    const tsDataset: DataSet | undefined = props.datasets?.length > 2 ? props.datasets[2] : undefined;
    const tsComparisonDataset: DataSet | undefined = props.datasets?.length > 3 ? props.datasets[3] : undefined;

    const summaryQuery = useQuery({
        query: new Query(SUMMARY_DATA_QUERY),
        requiredFilters: [FILTER_NAME.incidentId, FILTER_NAME.datasetId],
        filters: {
            [FILTER_NAME.incidentId]: props.incidentId,
            [FILTER_NAME.datasetId]: dataset?.datapoints ? undefined : dataset?.id
        },
        skipGlobalFilters: true
    });
    let summaryLoading = summaryQuery.loading, data = summaryQuery.data, summaryError = summaryQuery.error;

    const tsQuery = useQuery({
        query: new Query(TIMESERIES_QUERY),
        requiredFilters: [FILTER_NAME.incidentId, FILTER_NAME.datasetId],
        filters: {
            [FILTER_NAME.incidentId]: props.incidentId,
            [FILTER_NAME.datasetId]: tsDataset?.datapoints ? undefined : tsDataset?.id
        },
        skipGlobalFilters: true
    });
    let tsLoading = tsQuery.loading, tsData = tsQuery.data;//, tsError = tsQuery.error; 

    const selectedMetrics: Array<string> | undefined = props.widget?.options?.metrics as Array<string> | undefined;

    let cardsJsx: Array<JSX.Element> = [];
    if (dataset?.datapoints) {
        data = {};
        data.datapoints = dataset.datapoints;
    }
    if (tsDataset?.datapoints) {
        tsData = {};
        tsData.datapoints = tsDataset.datapoints;
    }
    if (data && data.datapoints && dataset) {
        for (let rowIndex = 0; rowIndex < data.datapoints.length; rowIndex++) {
            const cardRow: Array<JSX.Element> = [];
            const keyDefs = dataset.keys;
            const name: JSX.Element | string = formatKeys(keyDefs || [], data.datapoints[rowIndex].keys, true);
            const metricDefs = dataset.metrics;
            if (metricDefs) {
                for (let metricIndex = 0; metricIndex < metricDefs.length; metricIndex++) {
                    if (selectedMetrics?.length && !selectedMetrics.includes(metricDefs[metricIndex].id)) {
                        continue;
                    }

                    const card = metricDefs[metricIndex];

                    let value = formatValue(data.datapoints[rowIndex].data[card.id], card);

                    // Get comparison if any
                    let compareValue: string | number | null = null;
                    if (data.datapoints[rowIndex].keys && comparisonDataset?.datapoints) {
                        for (const checkDatapoint of comparisonDataset.datapoints) {
                            if (keysMatch(data.datapoints[rowIndex], checkDatapoint)) {
                                compareValue = formatValue(checkDatapoint.data[card.id], card);
                                break;
                            }
                        }
                    }

                    // Get the time series data, if any
                    let ts: Array<any> = [];
                    if (data.datapoints[rowIndex].keys && tsData && tsData.datapoints) {
                        for (const checkDatapoint of tsData.datapoints) {
                            if (keysMatch(data.datapoints[rowIndex], checkDatapoint)) {
                                for (const timestamp in checkDatapoint.data) {
                                    const time = getTimestamp(timestamp);
                                    let value = formatValue(checkDatapoint.data[timestamp][card.id], card);
                                    ts.push({
                                        x: new Date(parseInt(time)),
                                        y: value
                                    });
                                }
                                break;
                            }
                        }
                    }

                    // Get the time comparisons if any
                    let compTs: Array<any> = [];
                    if (data.datapoints[rowIndex].keys && tsComparisonDataset?.datapoints) {
                        for (const checkDatapoint of tsComparisonDataset.datapoints) {
                            if (keysMatch(data.datapoints[rowIndex], checkDatapoint)) {
                                for (const timestamp in checkDatapoint.data) {
                                    const time = getTimestamp(timestamp);
                                    let value = formatValue(checkDatapoint.data[timestamp][card.id], card);
                                    compTs.push({
                                        x: new Date(parseInt(time) + comparisonOffset * 1000),
                                        y: value
                                    });
                                }
                                break;
                            }
                        }
                    }

                    const opts: Record<string, any> = (ts?.length > 0 ? { chartData: ts } : {});
                    if (compTs?.length) {
                        opts.chartCompData = compTs;
                        opts.comparisonOffset = comparisonOffset;
                        opts.comparisonSuffix = comparisonSuffix;
                    }
                    const cardJsx = <MetricMiniCard key={card.id} title={card.label} unit={Unit.parseUnit(card.unit || "")}
                        value={value !== undefined && value !== null ? value : undefined}
                        compareValue={compareValue !== undefined && compareValue !== null ? compareValue : undefined} 
                        titlePadding={false}
                        hideShadow={INCIDENT_DETAILS_STYLE === "noTableOneCardForEachWidget"}
                        {...opts} comparisonSuffix={comparisonSuffix}
                    />;
                    cardRow.push(cardJsx);
                }
            }
            // Sorting 'cardRow' based on the order of metrics
            let sortedCards = selectedMetrics?.map(metricName => cardRow.find(card => card.key === metricName));
            cardsJsx.push(<div key={"card-row-" + rowIndex} className="runbook-card-row">
                <div className="display-7 fw-bold d-inline-block ms-1 mt-3 mb-2">
                    {name}
                </div>
                <CardsHolder>{sortedCards || cardRow}</CardsHolder>
            </div>);
        }
    } else {
        return <div key={"card-row-no-data"} className="runbook-card-row">
            {STRINGS.runbookOutput.noCardSummaryData}
        </div>;
    }
    return <DataLoadFacade loading={(summaryLoading && tsLoading) || props.loading} error={summaryError || props.error} data={data}>
        {cardsJsx}
    </DataLoadFacade>;
};
