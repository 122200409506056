/** This module contains utilities for editing logic nodes and validating decision nodes.
 *  @module
 */

import { STRINGS } from "app-strings";
import { GraphDef, InputType, NodeDef } from "components/common/graph/types/GraphTypes.ts";
import { GenericKey, NodeUtils } from "utils/runbooks/NodeUtil.ts";
import { 
    aggregatorNodes, aiNodes,
    dataOceanNodes, getParentsFromGraphDef, getTriggerMetricIds, httpNodes, isAiNodeFromGraphDef, isDataNodeFromGraphDef, 
    isDataOceanNodeFromGraphDef, isHttpNodeFromGraphDef, isSubflowNodeFromGraphDef, 
    isTransformNodeFromGraphDef, isTriggerNodeFromGraphDef, isVariablesNode, subflowNodes, transformNodes, triggerNodes
} from "utils/runbooks/RunbookUtils.ts";
import { DataOceanUtils } from "components/common/graph/editors/data-ocean/DataOceanUtils.ts";
import { Context, RunbookContext, VariableContextByScope } from "utils/runbooks/RunbookContext.class.ts";
import { INCIDENT_SCOPE, RUNTIME_SCOPE, SUBFLOW_SCOPE } from "utils/runbooks/VariablesUtils.ts";
import { getArDataSources, getTypes } from "utils/stores/GlobalDataSourceTypeStore.ts";
import { 
  HTTP_PREFIX, KEY_PREFIX, METRIC_PREFIX, TRIGGER_METRIC_PREFIX, TRIGGER_PREFIX, VARIABLE_PREFIX 
} from "components/hyperion/views/decision-branch/DecisionBranchUtils.ts";
import { CustomProperty } from "pages/create-runbook/views/create-runbook/CustomPropertyTypes.ts";

export enum Operations {
    gt = "GREATER_THAN",
    lt = "LESS_THAN",
    gteq = "GREATER_THAN_OR_EQUAL",
    lteq = "LESS_THAN_OR_EQUAL",
    eq = "EQUAL",
    neq = "NOT_EQUAL",
    eqAny = "EQUAL_ANY",
    neqAny = "NOT_EQUAL_ANY",
    oneOf = "ONE_OF",
    nOneOf = "NOT_ONE_OF",
    cont = "CONTAINS",
    nCont = "NOT_CONTAIN",
    regex = "REGEX",
    max = "MAX",
    min = "MIN",
    avg = "AVERAGE",
    changegt = "CHANGE_GREATER_THAN",
    changelt = "CHANGE_LESS_THAN",
    incgt = "INCREASE_GREATER_THAN",
    inclt = "INCREASE_LESS_THAN",
    decgt = "DECREASE_GREATER_THAN",
    declt = "DECREASE_LESS_THAN",
    exist = "EXISTS",
    nexist = "NOT_EXIST",
    normal = "NORMAL",
    nnormal = "NOT_NORMAL",
    expectedhighlt = "EXPECTED_HIGH_LESS_THAN",
    expectedhighgt = "EXPECTED_HIGH_GREATER_THAN",
    expectedlowlt = "EXPECTED_LOW_LESS_THAN",
    expectedlowgt = "EXPECTED_LOW_GREATER_THAN"
}

export enum Ops {
    "GT" = "gt",
    "LT" = "lt",
    "GT-EQ" = "gteq",
    "LT-EQ" = "lteq",
    "EQ" = "eq",
    "NOT-EQ" = "neq",
    "EQ-ANY" = "eqAny",
    "NOT-EQ-ANY" = "neqAny",
    "ONE-OF" = "oneOf",
    "NOT-ONE-OF" = "nOneOf",
    "CONTAINS" = "cont",
    "NOT-CONTAIN" = "nCont",
    "REGEX" = "regex",
    "CHANGE-GT" = "changegt",
    "CHANGE-LT" = "changelt",
    "INCREASE-GT" = "incgt",
    "INCREASE-LT" = "inclt",
    "DECREASE-GT" = "decgt",
    "DECREASE-LT" = "declt",
    "EXISTS" = "exist",
    "NOT-EXIST" = "nexist",
    "NORMAL" = "normal",
    "NOT-NORMAL" = 'nnormal',
    "EXPECTED-HIGH-LT" = "expectedhighlt",
    "EXPECTED-HIGH-GT" = "expectedhighgt",
    "EXPECTED-LOW-GT" = "expectedlowgt",
    "EXPECTED-LOW-LT" = "expectedlowlt",
}

/** an enum with all of the valid decision node properties. */
export enum DECISION_NODE_EDIT_PROPS {
    OUTPUT_CASES="outputs",
    DATA_TYPE = "dataType",
    PASSED_DATA = "passedData",
    INPUT_TYPE = "inputType"
}

/** an enum with all of the valid metric data types. */
export enum MetricDataType {
    SUMMARY = "SUMMARY",
    TIMESERIES = "TIMESERIES"
}

/** This is a Utility class for the decision node.   This class extends the NodeUtils class. */
export class DecisionNodeUtil extends NodeUtils {
    /** a static member variable with the error strings for the decision node. */
    static errMsgs = STRINGS.runbookEditor.errors.decisionNode;

    /** Check if a decision node is valid. Validates in the context of other nodes in the graph
     *  @param nodeId - node identifier
     *  @param graphDef - graph with info on al the nodes. 
     *  @param variables the map of variables by scope.
     *  @param customProperties the array of CustomProperty objects with the custom properties for 
     *       all the entity types. 
     *  @returns  is node valid. */
    static isNodeValid(
        nodeId: string | undefined | null, graphDef: GraphDef, variables: VariableContextByScope,
        customProperties: CustomProperty[]
    ): boolean {
        if(!nodeId) {
            console.error(" isNodeValid: nodeId is undefined ");
            return false;
        }
        const errors = [];
        DecisionNodeUtil.validateNode(nodeId, errors, graphDef, variables, customProperties)
        return errors.length === 0;
    }

    /** Check if a decision node is valid. Validates in the context of other nodes in the graph.
    *   Populates the errors.
    *   @param nodeId - node identifier
    *   @param errors - IN-OUT argument the array his populated with error messages. Empty array if
    *   there are no errors
    *   @param graphDef - graph with info on al the nodes. 
    *   @param variables the map of variables by scope. 
    *   @param customProperties the array of CustomProperty objects with the custom properties for 
    *       all the entity types. */
    static validateNode(
        nodeId: string, errors: string[], graphDef: GraphDef, variables: VariableContextByScope,
        customProperties: CustomProperty[]
    ): void {
        let curNode = graphDef.nodes.find((n) => {
            return nodeId === n.id
        });

        // no-op currenty.
        super.validateNode(nodeId, errors, graphDef, variables, customProperties);

        // if (!NodeUtils.getPropertyFromNode(DECISION_NODE_EDIT_PROPS.INPUT_TYPE, curNode)?.value) {
        //     errors.push(DecisionNodeUtil.errMsgs.inputError);
        // }
        
        const expressions = NodeUtils.getPropertyFromNode(DECISION_NODE_EDIT_PROPS.OUTPUT_CASES, curNode)?.value;
        if (!expressions?.length) {
            // There are no expressions flag it as an error
            errors.push(DecisionNodeUtil.errMsgs.expError);
        } else {
            // There are expressions, check the expression to make sure all required fields are set.
            DecisionNodeUtil.checkExpressionsForErrors(expressions, errors);
        }
        
        // Check compatibility with the parent DO node        
        DecisionNodeUtil.validateNodeCompatiblity(curNode, errors, graphDef, variables, customProperties);
    }

    /** Validates the logic node against a parent data ocean node verifies if the input type and metric type matches
    *   Populates the errors.
    *   @param nodeId - node identifier
    *   @param errors - IN-OUT argument the array his populated with error messages. Empty array if
    *   @param graphDef - graph with info on al the nodes. 
    *   @param variables the map of variables by scope.
    *   @param customProperties the array of CustomProperty objects with the custom properties for 
    *       all the entity types. */
    static validateNodeCompatiblity(
        node: NodeDef | undefined, errors: string[], graphDef: GraphDef, variables: VariableContextByScope, 
        customProperties: CustomProperty[]
    ): void {
        if (!node) {
            return;
        }

        const parents = getParentsFromGraphDef(node, graphDef);
        if (parents?.length) {
            if (parents.length !== 1) {
                errors?.push(DecisionNodeUtil.errMsgs.onlyOneParentError);
                return;
            }
            if (
                !isTriggerNodeFromGraphDef(parents[0]) && !isDataNodeFromGraphDef(parents[0]) && 
                !isHttpNodeFromGraphDef(parents[0]) && !isTransformNodeFromGraphDef(parents[0]) && 
                !isVariablesNode(parents[0]) && !isSubflowNodeFromGraphDef(parents[0]) &&
                !isAiNodeFromGraphDef(parents[0])
            ) {
                errors.push(DecisionNodeUtil.errMsgs.incompatibleParentNode);
                // Don't check anything else
                return;
            }
        }

        const primitiveRuntimeVariables: string[] = NodeUtils.getPrimitiveVariablesList(variables);

        let runbookContext = new RunbookContext(node, graphDef, DataOceanUtils.dataOceanMetaData, customProperties);
        const nodeContexts: Array<Context> = runbookContext.getNodeContexts();
        let expandedKeys: Array<GenericKey | string> = nodeContexts?.length ? nodeContexts[nodeContexts.length - 1].expandedKeys : [];
        expandedKeys = (expandedKeys as Array<GenericKey>).map((keyDef) => keyDef.id);
        let metrics: Array<GenericKey | string> = nodeContexts?.length ? nodeContexts[nodeContexts.length - 1].metrics : [];
        metrics = metrics.map(metric => (metric as GenericKey).id);
        const availableAr11s: string[] = (getArDataSources() || []).map(ds => ds.id);
        let keyError = DecisionNodeUtil.errMsgs.incompatibleKey, metricError = DecisionNodeUtil.errMsgs.incompatibleMetric;
        let runtimeVariableError = DecisionNodeUtil.errMsgs.incompatibleRuntimeVariable;
        let incidentVariableError = DecisionNodeUtil.errMsgs.incompatibleIncidentVariable;
        let subflowVariableError = DecisionNodeUtil.errMsgs.incompatibleSubflowVariable;
        let dataSourceError = DecisionNodeUtil.errMsgs.incompatibleDataSource;

        let pNode = NodeUtils.getParentNode(node.id, graphDef, [
            ...dataOceanNodes, ...httpNodes, ...transformNodes, ...aggregatorNodes, ...triggerNodes, ...subflowNodes, ...aiNodes
        ]);
        if (pNode && isTriggerNodeFromGraphDef(pNode)) {
            // We are hooked directly to a trigger node
            //const triggerType: InputType = runbookContext.getTriggerContext()?.keys?.length ? runbookContext.getTriggerContext()?.keys[0] as InputType : InputType.INTERFACE;
            const triggerType: InputType = NodeUtils.getPropertyFromNode("triggerType", pNode)?.value;
            expandedKeys = RunbookContext.getExpandedKeysForTrigger(triggerType, customProperties, "", true);
            expandedKeys = expandedKeys.map((keyDef) => (keyDef as GenericKey).id);
            metrics = getTriggerMetricIds(triggerType, [], DataOceanUtils.dataOceanMetaData, getTypes());    
            keyError = DecisionNodeUtil.errMsgs.incompatibleTriggerKey;
            metricError = DecisionNodeUtil.errMsgs.incompatibleTriggerMetric;
        } else if (pNode && isHttpNodeFromGraphDef(pNode)) {
            // We are hooked directly to an HTTP node
            keyError = DecisionNodeUtil.errMsgs.incompatibleHttpKey;
            metricError = DecisionNodeUtil.errMsgs.incompatibleHttpMetric;
        } else if (pNode && isSubflowNodeFromGraphDef(pNode)) {
            // We are hooked directly to an HTTP node
            keyError = DecisionNodeUtil.errMsgs.incompatibleSubflowKey;
            metricError = DecisionNodeUtil.errMsgs.incompatibleSubflowMetric;
        } else if (pNode && isTransformNodeFromGraphDef(pNode)) {
            // There is a transform node as an ancestor
            const xformMetricDataType = Boolean(NodeUtils.getPropertyFromNode("outputDataFormat", pNode)?.value === "timeseries");
            const decisionMetricDataType = NodeUtils.getPropertyFromNode(DECISION_NODE_EDIT_PROPS.DATA_TYPE, node)?.value === MetricDataType.TIMESERIES;
            if (xformMetricDataType !== decisionMetricDataType) {
                errors.push(DecisionNodeUtil.errMsgs.incompatibleTransformDataType);
            }
            keyError = DecisionNodeUtil.errMsgs.incompatibleTransformKey;
            metricError = DecisionNodeUtil.errMsgs.incompatibleTransformMetric;
        } else if (pNode && isDataOceanNodeFromGraphDef(pNode)) {
            // There is a data node as an ancestor
            const doMetricDataType = Boolean(NodeUtils.getPropertyFromNode("timeSeries", pNode)?.value);
            const decisionMetricDataType = NodeUtils.getPropertyFromNode(DECISION_NODE_EDIT_PROPS.DATA_TYPE, node)?.value === MetricDataType.TIMESERIES;
            if (doMetricDataType !== decisionMetricDataType) {
                errors.push(DecisionNodeUtil.errMsgs.incompatibleDoDataType);
            }
            
            const doInputType = NodeUtils.getPropertyFromNode("objType", pNode)?.value;
            const decisionInputType = NodeUtils.getPropertyFromNode(DECISION_NODE_EDIT_PROPS.INPUT_TYPE, node)?.value;
            if (pNode && node.editedByUser && (!doInputType || !decisionInputType || doInputType !== decisionInputType)) {
                errors.push(DecisionNodeUtil.errMsgs.incompatibleDoInput);
            }
            keyError = DecisionNodeUtil.errMsgs.incompatibleDoKey;
            metricError = DecisionNodeUtil.errMsgs.incompatibleDoMetric;
        } else if (pNode) {
            keyError = DecisionNodeUtil.errMsgs.incompatibleDoKey;
            metricError = DecisionNodeUtil.errMsgs.incompatibleDoMetric;
        }

        // Check to make sure the expression does not contain keys and metrics that are not in the DO node.
        const expressions = NodeUtils.getPropertyFromNode(DECISION_NODE_EDIT_PROPS.OUTPUT_CASES, node)?.value;
        if (expressions?.length) {
            const decisionMetrics: Array<string> = [];
            const decisionKeys: Array<string> = [];
            const decisionVariables: Array<string> = [];
            const dataSources: Array<string> = [];
            DecisionNodeUtil.getMetricsAndKeysFromExpressions(expressions, decisionMetrics, decisionKeys, decisionVariables, dataSources);
            for (const keyId of decisionKeys) {
                if (!expandedKeys.includes(keyId)) {
                    errors.push(keyError);
                    break;
                }
            }
            for (const metricId of decisionMetrics) {
                if (!metrics.includes(metricId)) {
                    errors.push(metricError);
                    break;
                }
            }
            for (const varName of decisionVariables) {
                if (!primitiveRuntimeVariables.includes(varName)) {
                    if (varName.includes(RUNTIME_SCOPE)) {
                        errors.push(runtimeVariableError);
                    } else if (varName.includes(INCIDENT_SCOPE)) {
                        errors.push(incidentVariableError);
                    } else if (varName.includes(SUBFLOW_SCOPE)) {
                        errors.push(subflowVariableError);
                    }
                    break;
                }
            }
            if (dataSources?.length) {
                const notFoundAr11s = dataSources.filter(id => {
                    return !availableAr11s.includes(id);
                });
                if (notFoundAr11s.length) {
                    errors.push(dataSourceError);
                }
            }
        }                
    }

    /** validates the specified expression and puts any errors in the error array.
     *  @param expressions the expressions to be validated.
     *  @param errors the array of errors to be displayed in the UI. */
    static checkExpressionsForErrors(expressions: any, errors: string[]): void {
        if (expressions?.length) {
            for (const expression of expressions) {
                const conditions = expression?.expression?.conditions;
                if (expression?.id !== "default" && !conditions?.length) {
                    errors.push(DecisionNodeUtil.errMsgs.emptyOutput);
                } else {
                    DecisionNodeUtil.checkConditionsForErrors(conditions, errors);
                }
            }
        }
    }

    /** validates the specified conditions and puts any errors in the error array.
     *  @param conditions the conditions to be validated.
     *  @param errors the array of errors to be displayed in the UI. */
     static checkConditionsForErrors(conditions: any, errors: string[]): void {
        if (conditions?.length) {
            for (const condition of conditions) {
                if (condition.type === 'condition') {
                    let hasConditionError: boolean = false;
                    if (
                        !errors.includes(DecisionNodeUtil.errMsgs.missingCategory) &&
                        (condition.category === undefined || condition.category === null || condition.category === "")
                    ) {
                        errors.push(DecisionNodeUtil.errMsgs.missingCategory);
                        hasConditionError = true;
                    }
                    if (
                        !errors.includes(DecisionNodeUtil.errMsgs.missingOperator) &&
                        (condition.op === undefined || condition.op === null || condition.op === "")
                    ) {
                        errors.push(DecisionNodeUtil.errMsgs.missingOperator);
                        hasConditionError = true;
                    }
                    if (
                        !errors.includes(DecisionNodeUtil.errMsgs.missingValue) &&
                        (condition.value === undefined || condition.value === null || condition.value === "")
                    ) {
                        errors.push(DecisionNodeUtil.errMsgs.missingValue);
                        hasConditionError = true;
                    }
                    if (
                        !hasConditionError &&
                        !errors.includes(DecisionNodeUtil.errMsgs.missingKey) &&
                        (condition.key === undefined || condition.key === null || condition.key === "")
                    ) {
                        errors.push(DecisionNodeUtil.errMsgs.missingKey);
                    }
                }
                if (condition.conditions) {
                    DecisionNodeUtil.checkConditionsForErrors(condition.conditions, errors);
                }
            }
        }
    }

    /** recursively traverse the expressions and their conditions to find any metrics and keys that 
     *      are referred to.
     *  @param expressions the expressions to check for metrics and keys.
     *  @param metrics a string array with the metric ids that were encountered.
     *  @param keys a string array with the keys that were encountered. 
     *  @param variables a string array with the list of variables that were encountered.
     *  @param dataSources a string array with the list of data source ids. */
    static getMetricsAndKeysFromExpressions(
        expressions: any, metrics: Array<string>, keys: Array<string>, variables: Array<string>, dataSources: Array<string>
    ): void {
        if (expressions?.length) {
            for (const expression of expressions) {
                const conditions = expression?.expression?.conditions;
                DecisionNodeUtil.getMetricsAndKeysFromConditions(conditions, metrics, keys, variables, dataSources);
            }
        }
    }

    /** recursively traverse the conditions to find any metrics and keys that are referred to.
     *  @param conditions 
     *  @param metrics a string array with the metric ids that were encountered.
     *  @param keys a string array with the keys that were encountered. 
     *  @param variables a string array with the list of variables that were encountered.
     *  @param dataSources a string array with the list of data source ids. */
    static getMetricsAndKeysFromConditions(
        conditions: any, metrics: Array<string>, keys: Array<string>, variables: Array<string>, dataSources: Array<string>
    ): void {
        if (conditions?.length) {
            for (const condition of conditions) {
                if (condition.key) {
                    switch (condition.category) {
                        case "input.metric": {
                            const metricId = condition.key.replace(METRIC_PREFIX, '');
                            metrics.push(metricId);
                            break;
                        }
                        case "input.key": {
                            const keyId = condition.key.replace(KEY_PREFIX, '');
                            keys.push(keyId);
                            if (keyId === "data_source.id") {
                                dataSources.push(condition.value);
                            }
                            break;
                        }
                        case "trigger":
                        case "entity": {
                            if (condition.key.startsWith(TRIGGER_PREFIX)) {
                                const keyId = condition.key.replace(TRIGGER_PREFIX, ''); 
                                if (keyId !== "kind") {
                                    keys.push(keyId);
                                }
                                if (keyId === "data_source.id") {
                                    dataSources.push(condition.value);
                                }
                            } else if (condition.key.startsWith(TRIGGER_METRIC_PREFIX)) {
                                const metricId = condition.key.replace(TRIGGER_METRIC_PREFIX, ''); 
                                metrics.push(metricId);
                            }
                            break;
                        }
                        case "http": {
                            const keyId = condition.key.replace(HTTP_PREFIX, '');
                            keys.push(keyId);
                            break;
                        }
                        case "variable": {
                            const keyId = condition.key.replace(VARIABLE_PREFIX, '');
                            variables.push(keyId);
                            break;
                        }
                    }
                }
                if (condition.conditions) {
                    DecisionNodeUtil.getMetricsAndKeysFromConditions(condition.conditions, metrics, keys, variables, dataSources);
                }
            }
        }
    }
}
