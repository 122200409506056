import { useState } from 'react';
import DOMPurify from 'dompurify';
import { useMutation, useQuery, useApolloClient } from '@apollo/client';
import { Callout, Icon, Intent, Label, Switch } from '@blueprintjs/core';
import { ErrorToaster, IconNames, SuccessToaster } from '@tir-ui/react-components';
import { DATASOURCE_STATUS } from './DataSourceStatus.tsx';
import { DataLoadFacade } from 'components/reporting/data-load-facade/DataLoadFacade.tsx';
import { STRINGS } from 'app-strings';

import NPM_PLUS_SET_DATASOURCE_MUTATION from '../queries/set-npm-plus-datasource-mutation.graphql';
import NPM_PLUS_DATASOURCE from '../queries/npm-plus-datasource.graphql';

interface SetNPMplusDataSourcesInput {
    input: {
        queriesEnabled: boolean;
    };
}

export default function NPMplusView() {
    const apolloClient = useApolloClient();
    const [sourceToggleDisabled, setSourceToggleDisabled] = useState(false);
    const translations = STRINGS.DATA_SOURCES.npmPlusConfig;

    const { loading: loadingData, data, error } = useQuery(NPM_PLUS_DATASOURCE, {
        pollInterval: 1 * 60 * 1000,
    });

    const dataSource = data?.npmPlusDataSource || {};

    const [setNpmPlusDataSource] = useMutation<any, SetNPMplusDataSourcesInput>(NPM_PLUS_SET_DATASOURCE_MUTATION, {
        onCompleted: () => {
            SuccessToaster({
                message: translations.queryResponse.success,
            });
        },
        onError: (err) => {
            ErrorToaster({
                message: translations.queryResponse.error,
            });
            console.error(err?.message);
        },
    });

    function renderAccountInfo() {
        const accountData = dataSource?.account;
        return (
            <div className="row">
                <div className="col-auto">
                    <Label className="me-3 fw-bold">{translations.labels.accountId}</Label>
                </div>
                <div className="col-auto">
                    <span>{accountData?.id}</span>
                </div>
            </div>
        )
    }

    function renderDataSourceStatus() {
        function renderQueryStatus() {
            return (
                <>
                    {dataSource?.info?.status === DATASOURCE_STATUS.OK ? (
                        <div className="col-auto">
                            <Icon intent="success" icon={IconNames.TICK_CIRCLE} />
                        </div>
                    ) : dataSource?.info?.status === DATASOURCE_STATUS.DISABLED ? (
                        <div className="col-auto">
                            <Icon intent={Intent.DANGER} icon={IconNames.DISABLE} />
                        </div>
                    ) : (
                        <div className="col-8 d-inline">
                            <Icon intent={Intent.DANGER} icon={IconNames.ERROR} />
                            {dataSource?.info?.status === DATASOURCE_STATUS.DISABLED && (
                                <span className="ms-2" data-testid="integrationStatus">
                                    {translations.status.disabled}
                                </span>
                            )}
                            {dataSource?.info?.status === DATASOURCE_STATUS.FAILED &&
                                dataSource?.info?.error?.details.map((error, i) => (renderApiError(i, error)))
                            }
                        </div>
                    )}
                </>
            );
        }

        function renderApiError(i: any, error: any) {
            return <Callout key={i} intent={Intent.WARNING} icon={null} className="my-3">
                <div
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                            STRINGS.formatString(
                                STRINGS.runbookOutput.errorsAndWarnings[
                                !error.code || !Object.keys(STRINGS.runbookOutput.errorsAndWarnings).includes(error.code) ?
                                    "GENERAL_ERROR" :
                                    error?.code
                                ],
                                Object.fromEntries(
                                    [dataSource?.info?.error?.innerError?.properties.map((x) => [x.key, x.value]),
                                    ...error?.innerError?.properties.map((x) => [x.key, x.value]
                                    )]
                                )
                            )
                        ),
                    }} />
            </Callout>;
        }

        return (
            <div className="row">
                <div className="col-auto">
                    <Label className="me-3 fw-bold">{translations.labels.status}</Label>
                </div>
                {loadingData &&
                    <div className="col-8 d-inline">
                        <span data-testid="integrationStatus">
                            {translations.labels.lastIngestedNull}
                        </span>
                    </div>
                }
                {renderQueryStatus()}
            </div>
        );

    }

    return (
        <DataLoadFacade key="categories" className={"basic"} loading={loadingData} data={data} error={error} showContentsWhenLoading>
            <div className="aternity-config my-3 p-3 h-min-5">
                {
                    <div className="container-fluid">
                        {renderAccountInfo()}
                        <div className="row">
                            <div className="col-auto">
                                <Label className="me-3 fw-bold"> {translations.labels.enableDataSource} </Label>
                            </div>
                            <div className="col-auto">
                                <Switch
                                    className="d-inline"
                                    labelElement={dataSource.queriesEnabled ? translations.labels.toggleOn : translations.labels.toggleOff}
                                    checked={dataSource.queriesEnabled}
                                    disabled={sourceToggleDisabled}
                                    onChange={async () => {
                                        try {
                                            setSourceToggleDisabled(true);
                                            await setNpmPlusDataSource({
                                                variables: {
                                                    input: {
                                                        queriesEnabled: !dataSource.queriesEnabled,
                                                    },
                                                },
                                            });
                                            await apolloClient.refetchQueries({
                                                include: 'active',
                                            });
                                        } catch (error) {
                                            ErrorToaster({ message: translations.toggleError })
                                        } finally {
                                            setSourceToggleDisabled(false);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        {renderDataSourceStatus()}
                    </div>
                }
            </div>
        </DataLoadFacade>
    );
}
