import type React from 'react';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { IconNames, LoadingOverlay } from '@tir-ui/react-components';
import { Button, Classes, Divider, Label, Switch } from '@blueprintjs/core';
import { SelectField } from 'components/common/form/SelectField.tsx';
import { Form } from 'components/common/form/Form.tsx';
import type { RunbookNode } from 'utils/services/RunbookApiService.ts';
import { ExternalTriggerOptions, type IncidentTriggerOptions, LifecycleTriggerOptions } from './TriggerPanel.tsx';
import { TRIGGER_TYPE_MAP } from 'pages/riverbed-advisor/views/runbook-view/Runbook.type.ts';
import { STRINGS } from 'app-strings';
import { PARAM_NAME } from 'components/enums/QueryParams.ts';
import { Variant } from 'components/common/graph/types/GraphTypes.ts';

export interface IRunbookPanelProps {
    triggerValue: IncidentTriggerOptions | string;
    runbooks: RunbookNode[] | undefined;
    executeRunbookValue: boolean;
    runbookValue: string;
    onRunbooksRefresh: (variant: Variant) => void;
    onRunbookValueChange: (event: React.FormEvent<HTMLInputElement>) => void;
    onExecuteRunbookValueChange: (
        event: React.FormEvent<HTMLInputElement>
    ) => void;
}    
    
const RunbookPanel: React.FC<IRunbookPanelProps> = (props) => {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(false);
    }, [props.runbooks]);
        
    const runbook =
        props.runbookValue &&
        props.runbooks?.filter((item) => {
            return item.name === props.runbookValue;
        });
            
    const description =
        runbook && runbook?.hasOwnProperty(0)
            ? runbook['0']['description']
            : undefined;

    const variant: Variant = Object.values(LifecycleTriggerOptions).includes(
        // @ts-ignore
        props.triggerValue
    )
        ? Variant.LIFECYCLE
        : Object.values(ExternalTriggerOptions).includes(
            // @ts-ignore
            props.triggerValue
        ) ? Variant.EXTERNAL : Variant.INCIDENT;

    return (
        <div className={classNames(Classes.DIALOG_BODY)}>
            <p>
                <b>
                    {STRINGS.MAPPING_CONFIGURATION_PAGE.addMappingModal.panels
                        .runbook.subTitle + ':'}
                </b>
            </p>
            <Divider />
            <br />
            <Form
                initialValues={{
                    execute_runbook: props.executeRunbookValue,
                    runbook: props.runbookValue,
                }}
                loading={false}
            >
                <div className="mt-1 d-flex">
                    <div className="w-50">
                        <Switch
                            name="execute_runbook"
                            className="mt-1 mb-1"
                            label={
                                STRINGS.MAPPING_CONFIGURATION_PAGE
                                    .addMappingModal.panels.runbook.fields
                                    .executeRunbook.label
                            }
                            checked={props.executeRunbookValue}
                            onChange={props.onExecuteRunbookValueChange}
                        />
                    </div>
                    <div>
                        <SelectField
                            className="w-100"
                            name="runbook"
                            required={true}
                            options={
                                props.runbooks && [
                                    STRINGS.MAPPING_CONFIGURATION_PAGE
                                        .addMappingModal.panels.runbook
                                        .selectRunbook,
                                    ...Object.values(props.runbooks)
                                        .filter((runbook) => {
                                            return (
                                                runbook.isReady &&
                                                TRIGGER_TYPE_MAP[
                                                props.triggerValue
                                                ] === runbook.triggerType
                                            );
                                        })
                                        .map((runbook) => {
                                            return runbook.name;
                                        }).sort((a, b) => {
                                            return (a as string).localeCompare(
                                                (b as string),
                                                undefined,
                                                { sensitivity: 'base' }
                                            )
                                        }
                                        ),
                                ]
                            }
                            disabled={!props.executeRunbookValue}
                            onChange={props.onRunbookValueChange}
                            value={props.runbookValue}
                        />
                    </div>
                    <div className="ms-2">
                        <Button
                            icon={IconNames.REFRESH}
                            minimal={true}
                            onClick={() => {
                                setLoading(true);
                                props.onRunbooksRefresh(variant);
                            }}
                        />
                    </div>
                </div>
                {loading && <LoadingOverlay visible={true} />}
                <div className="mt-2 me-4 text-end">
                    {' '}
                    <p className="fw-bold">
                        <a
                            href={`create-runbook?${PARAM_NAME.variant}=${variant}&${PARAM_NAME.triggerType}=${TRIGGER_TYPE_MAP[props.triggerValue]}&fromWizard=true`}
                            rel="noreferrer"
                            target="_blank"
                        >
                            {
                                STRINGS.MAPPING_CONFIGURATION_PAGE
                                    .addMappingModal.panels.runbook.fields
                                    .createRunbook.label
                            }
                        </a>
                    </p>
                </div>
                {description && (
                    <div>
                        <div className="mt-2 fw-bold">
                            <Label>
                                {
                                    STRINGS.MAPPING_CONFIGURATION_PAGE
                                        .addMappingModal.panels.runbook.fields
                                        .runbookDescription.label
                                }
                            </Label>
                        </div>
                        <div className="mt-3">{description}</div>
                    </div>
                )}
            </Form>
        </div>
    );
};

export { RunbookPanel };
