/** This module contains the functional React component for rendering the multi-select input control.
 *  This control allows users to select an item from a list of items and puts a tag in the input for
 *  each item selected as items are selected they are removed from the popup with the list and as items
 *  are de-selected they are put back into the popup.
 *  @module
 */
import React, { useCallback, useRef, useState, useEffect, useContext } from 'react';
import { ItemPredicate, ItemRenderer, MultiSelect } from "@blueprintjs/select";
import { Button, Classes, Intent, MenuItem, PopoverPosition, Tooltip } from "@blueprintjs/core";
import { IconNames } from "@tir-ui/react-components";
import classNames from "classnames";
import { setNativeValue } from 'reporting-infrastructure/utils/commonUtils';
import { VariableContext } from "utils/runbooks/VariableContext";
import { RUNTIME_SCOPE } from "utils/runbooks/VariablesUtils";
import "./MultiSelectInput.scss";

/** this defines the specific type for the items in the list. */
type itemType = {
    /** a String with the display label for the item. */
    display: string;
    /** a String with the value for the item. */
    value: string;
    /** a String with the tooltip for the item or undefined if no tooltip should be displayed. */
    tooltip?: string | undefined;
};

/** This interface defines the properties passed into the multi-select input React component.*/
export type MultiSelectInputProps = {
    /** all the items in the list. */
    items: Array<itemType>,
    /** the items in the list that are currently selected. */
    selectedItems?: Array<itemType>,
    /** the onChange handler that is called when the state changes. */
    onChange?: (selectedItem: any) => void,
    /** the item selection handler. */
    onItemSelect?: (selectedItem: any) => void,
    /** the item removed handler. */
    onRemove?: (removedItem: any) => void,
    /** an optional String with the placeholder text. */
    placeholder?: string,
    /** an optional String with the className to add to the MultiSuggestInput. */
    className?: string,
    /** .*/
    noResults?: JSX.Element,
    /** a boolean value, true if the input is disabled, false otherwise. */
    disabled?: boolean,
    /** a boolean value, true if the input is sortable, false otherwise. */
    sortable?: boolean,
    /** an optional array which contains the subflow static list values that are being edited. */
    valuesForEdit?: Array<any>,
    /** an optional object that containts the lists of static subflow input values. */
    staticInputValuesLists?: any,
    /** an optional string which is the name of subflow input node editor static list variable that is being edited. */
    variableName?: string,
    /** the handler for exiting the option edit mode inside a subflow input node editor static list. */
    exitOptionEditMode?: (variableName: string) => void,
    /** an array containing descriptions for context values defined inside the subflow input node editor. */
    inputOrOutputValuesDescriptions?: any;
};

/** the MultiSelect control with the specific itemType. */
const MultiSuggestInput = MultiSelect.ofType<itemType>();

/** Renders the component to render the multi-selected list.
 *  @param props the properties passed in.
 *  @returns JSX with the react multi-select component.*/
export function MultiSelectInput (props: MultiSelectInputProps): JSX.Element {
    const [selectedItems, setSelectedItemsState] = useState(props.selectedItems || []);
    function setSelectedItems (value) {
        setSelectedItemsState(value);
        if (hiddenValueInput.current) {
            setNativeValue(hiddenValueInput.current, JSON.stringify(value));
        }
    }
    const {items, ...multiSelectProps} = props;
    const hiddenValueInput = useRef<HTMLInputElement>(null);

    const classes = classNames("multi-select-input", props.className || "");

    const [multiSelectItems, setMultiSelectItems] = useState(items.filter(x => !selectedItems.find(y => (y.value === x.value))));
    useEffect(
        () => {
            setMultiSelectItems(items.filter(x => !selectedItems.find(y => (y.value === x.value))));
        }, 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [items]
    );

    const reorderList = useCallback(function (fromIndex:number, toIndex:number) {
        if (fromIndex !== toIndex && toIndex !== fromIndex + 1) {
            const sourceItem = selectedItems[fromIndex];
            const goingUp = toIndex > fromIndex;
            const selectedItemsCopy = [...selectedItems];
            selectedItemsCopy.splice(fromIndex, 1);
            selectedItemsCopy.splice(toIndex + (goingUp ? -1 : 0), 0, sourceItem);
            setSelectedItems(selectedItemsCopy);
            if (multiSelectProps.onChange) {
                multiSelectProps.onChange(selectedItemsCopy);
            }
        }
    }, [selectedItems, multiSelectProps]);
    const dragState = useRef<{
        draggedElement?: Element,
        lastDraggedOverElement?: Element,
    }>({});

    const {getVariables} = useContext(VariableContext);

    const runtimeVariables = getVariables(RUNTIME_SCOPE, false);

    return (<>
        <MultiSuggestInput
            fill={ true }
            resetOnSelect={true}
            itemRenderer={ renderItem }
            itemPredicate={ filterItem }
            tagInputProps={ {
                rightElement:
                    selectedItems && selectedItems.length && !multiSelectProps.disabled
                        ?
                            <Button icon={ IconNames.CROSS } minimal={ true }
                                onClick={() => {
                                    if (multiSelectProps.onChange) {
                                        multiSelectProps.onChange([]);
                                    }
                                    setSelectedItems([]);
                                    setMultiSelectItems(items);
                                }}
                            />
                        :   undefined,
                tagProps: {
                    intent: Intent.PRIMARY,
                    ...(props.sortable && !props.disabled ? {
                        className: "draggable-tag",
                        icon: IconNames.DRAG_HANDLE_VERTICAL,
                        draggable: props.disabled ? false : true,
                        onDragStart: e => {
                            e.currentTarget.classList.add("dragging");
                            e.dataTransfer.effectAllowed = "move";
                            dragState.current.draggedElement = e.currentTarget;
                        },
                        onDragEnd: e => {
                            e.currentTarget.classList.remove("dragging");
                            if (dragState.current?.lastDraggedOverElement) {
                                dragState.current.lastDraggedOverElement.classList.remove("dragged-over");
                                if (dragState.current.draggedElement) {
                                    const sourceTagIndex = Number(dragState.current.draggedElement.getAttribute("data-tag-index"));
                                    const targetTagIndex = Number(dragState.current.lastDraggedOverElement.getAttribute("data-tag-index"));
                                    reorderList(sourceTagIndex, targetTagIndex);
                                }
                            }
                            dragState.current = {};

                            // logic for the subflow input node editor
                            if (props.exitOptionEditMode && props.variableName) {
                                props.exitOptionEditMode(props.variableName);
                            }
                        },
                        onDragEnter: e => {
                            e.dataTransfer.dropEffect = "move";
                            // If dragged over element is not the element being dragged
                            if (dragState.current?.draggedElement !== e.currentTarget) {
                                e.currentTarget.classList.add("dragged-over");
                                if (dragState.current?.lastDraggedOverElement && dragState.current.lastDraggedOverElement !== e.currentTarget) {
                                    dragState.current.lastDraggedOverElement.classList.remove("dragged-over");
                                }
                                dragState.current.lastDraggedOverElement = e.currentTarget;
                            }
                        },
                        onDragOver: e => {
                            // Prevent ghost copy from flying back after drop is completed.
                            // TBD: Doesn't work when user drags over an element but then drags it away and drops it. It would be nice to fix this when possible
                            e.preventDefault();
                        },
                    } : {})
                },
                disabled: multiSelectProps.disabled,
            } }
            tagRenderer={(item) => {
                if (item.tooltip) {
                    return <Tooltip
                                key={"tooltip-" + item.value}
                                    className={Classes.TOOLTIP_INDICATOR + " border-0 d-inline"}
                                    content={<div className="px-2 py-2" dangerouslySetInnerHTML={{ __html: item.tooltip}}></div>}
                                    position={PopoverPosition.AUTO} transitionDuration={50}>
                                    <span>{item.display}</span>
                            </Tooltip>;
                } else {
                    // logic for the subflow input node editor
                    let shouldHighlight = false;
                    if (props.staticInputValuesLists && props.valuesForEdit?.length && props.variableName) {
                        const index = props.staticInputValuesLists[props.variableName].findIndex(value => value.display === item.display);
                        shouldHighlight = props.valuesForEdit.find(value => value.index === index && value.name === props.variableName);
                    }
                    let required = props.inputOrOutputValuesDescriptions?.find(
                        (description: { valueName: string, description: string }) => description.valueName === item.value
                    )?.required;
                    required = required !== undefined ? required : false;
                    const defaultValue = runtimeVariables?.primitiveVariables?.find(item2 => item2.name === item.display)?.defaultValue;
                    let description = 
                        props.inputOrOutputValuesDescriptions?.find(
                            (description: { valueName: string, description: string }) => description.valueName === item.value
                        )?.description || "";
                    if (defaultValue) {
                        description += description ? "<br></br>Default: " + defaultValue : "Default: " + defaultValue;
                    }
                    if (required) {
                        description = "<b>Required</b>. " + description;
                    } else {
                        description = "<b>Optional</b>. " + description;
                    }
                    if (shouldHighlight) {
                        return (
                            description?.trim() ? 
                            <Tooltip
                                key={"tooltip-" + item.value}
                                className={Classes.TOOLTIP_INDICATOR + " border-0 d-inline"}
                                content={<div className="px-2 py-2" dangerouslySetInnerHTML={{ __html: description}}></div>}
                                position={PopoverPosition.AUTO} transitionDuration={50}>
                                <div className="highlight-item">{item.display}</div>
                            </Tooltip> : 
                            <div className="highlight-item">{item.display}</div>
                        )
                    }

                    return (
                        description?.trim() ? 
                        <Tooltip
                            key={"tooltip-" + item.value}
                            className={Classes.TOOLTIP_INDICATOR + " border-0 d-inline"}
                            content={<div className="px-2 py-2" dangerouslySetInnerHTML={{ __html: description}}></div>}
                            position={PopoverPosition.AUTO} transitionDuration={50}>
                            <span>{item.display}</span>
                        </Tooltip> : 
                        item.display
                    );
                }
            }
            }
            noResults={ <MenuItem disabled={ true } text="No results."/> }
            { ...multiSelectProps }
            // itemDisabled={(item, index) => Boolean(multiSelectProps.disabled)}
            selectedItems={ selectedItems }
            onItemSelect={ (item) => {
                // @ts-ignore
                const modifiedValues = selectedItems.concat(item);
                setSelectedItems(modifiedValues);
                if (multiSelectProps.onItemSelect) {
                    multiSelectProps.onItemSelect(item);
                }
                if (multiSelectProps.onChange) {
                    multiSelectProps.onChange(modifiedValues);
                }

                setMultiSelectItems(multiSelectItems.filter( ( el ) => item.value !== el.value ));
            } }
            onRemove={(item) => {
                const modifiedValues = selectedItems.filter( (selectedItem:itemType) => {
                    return selectedItem.value !== item.value;
                });
                setSelectedItems(modifiedValues);
                if (multiSelectProps.onRemove) {
                    multiSelectProps.onRemove(item);
                }
                if (multiSelectProps.onChange) {
                    multiSelectProps.onChange(modifiedValues);
                }

                let hasValue = items.find((checkItem) => checkItem.value === item.value);
                if (hasValue) {
                    setMultiSelectItems(multiSelectItems.concat(item));
                }
            }}
            items={multiSelectItems}
            popoverProps={{
                usePortal: false,
                minimal: true,
                popoverClassName: "h-max-3 overflow-auto",
            }}
            className={classes}
        />
        <input type="text" className="d-none" ref={hiddenValueInput} defaultValue={JSON.stringify(selectedItems)}/>
    </>);
}

/** function that filters the list.
 *  @param query the search query.
 *  @param item the item.
 *  @param _index .
 *  @param exactMatch .
 *  @returns . */
export const filterItem: ItemPredicate<itemType> = (query, item, _index, exactMatch) => {//future: allow override
    let normalizedName = item.display;
    normalizedName = normalizedName.toLowerCase()
    const normalizedQuery = query.toLowerCase();

    if(exactMatch) {
        return normalizedName === normalizedQuery;
    } else {
        return normalizedName.indexOf(normalizedQuery) >= 0;
    }
};

/** highlights the specified characters in the text.
 *  @param text a String with the text to highlight.
 *  @param query a String with the query.
 *  @returns an array with the tokens. */
function highlightText(text: string, query: string) {
    let lastIndex = 0;
    const words = query
        .split(/\s+/)
        .filter(word => word.length > 0)
        .map(escapeRegExpChars);
    if(words.length === 0) {
        return [text];
    }
    const regexp = new RegExp(words.join('|'), 'gi');
    const tokens: React.ReactNode[] = [];
    while (true) {
        const match = regexp.exec(text);
        if(!match) {
            break;
        }
        const length = match[0].length;
        const before = text.slice(lastIndex, regexp.lastIndex - length);
        if(before.length > 0) {
            tokens.push(before);
        }
        lastIndex = regexp.lastIndex;
        tokens.push(<strong key={ lastIndex }>{ match[0] }</strong>);
    }
    const rest = text.slice(lastIndex);
    if(rest.length > 0) {
        tokens.push(rest);
    }
    return tokens;
}

/** escapes all RegExp characters.
 *  @param text a String with the text.
 *  @returns a String with the regex chars escaped in text. */
function escapeRegExpChars(text: string): string {
    // eslint-disable-next-line no-useless-escape
    return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
}

/** renders the specified item.
 *  @param item . 
 *  @returns . */
export const renderItem: ItemRenderer<itemType> = (item, { handleClick, modifiers, query }) => {
    if(!modifiers.matchesPredicate) {
        return null;
    }

    return (
        <MenuItem
            active={ modifiers.active }
            disabled={ modifiers.disabled }
            key={ item.value}
            onClick={ handleClick }
            text={ highlightText(item.display, query) }
            shouldDismissPopover={ false }
        />
    );
};

