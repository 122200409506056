query customProperties($top: Int, $skip: Int, $filter: CustomPropertyFilterInputType) {
    customProperties(top: $top, skip: $skip, filter: $filter, order: []) {
        info {
            totalCount
        }
        page {
            id
            name
            type
            permissions
            description
            lastUpdatedAt
            user {
                email
                id
                name
            }
            validTypes {
                type
            }
            values {
                id
                name
            }
        }
    }
}
