mutation RemoveCustomPropertyFromApplications($id: ID!, $filter: ApplicationBulkFilterType, $search: String, $searchFields: [ApplicationSearchFieldEnum]) {
    removeCustomPropertyFromApplications(
        id: $id
        filter: $filter
        search: $search
        searchFields: $searchFields
    ) {
        modifiedResourcesCount
        ignoredResourcesCount
    }
}
