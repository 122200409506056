import { Label } from '@blueprintjs/core';
import { AuthService } from '@tir-ui/azure-auth';
import { TenantManagementService } from 'utils/services/TenantManagementService.ts';
import { FormModal } from 'components/common/modal/FormModal.tsx';
import {
    initializePlatformAndProductRoleFields,
    RolesSection,
} from './RolesSection.tsx';
import { STRINGS } from 'app-strings';
import * as yup from "yup";

export const EditUserModal = (props) => {
    const {
        userId,
        name,
        userRole,
        rolesByProduct,
        platformRoles,
        productKey,
        ...modalProps
    } = props;

    const initialPlatformRole =
        platformRoles && platformRoles.length && platformRoles[0].value === userRole;

    const [roleValidationSchema, initialRoleValues] = initializePlatformAndProductRoleFields(
        rolesByProduct,
        platformRoles,
        initialPlatformRole.toString(),
        !initialPlatformRole ? {
            [productKey]: {
                roles: [userRole]
            }
        } : undefined
    );

    const validationSchema = yup.object().shape({
        ...roleValidationSchema,
    });

    const formConfig = {
        initialValues: {
            ...initialRoleValues,
        },
        validationSchema: validationSchema,
        onSubmit: (values: any) => {
            const tenantId = AuthService.getTenantId();
            const payloadData = {};
            if (values.superuser && values.superuser !== 'false') {
                payloadData['superuser'] = values.superuser;
            } else {
                payloadData['products'] = values.products;
            }
            return TenantManagementService.put(
                `common/tenant/${tenantId}/user/${userId}`,
                payloadData
            );
        },
    };

    return (
        <FormModal
            title={STRINGS.USER_ACCOUNT_MANAGEMENT.EDIT_ROLE.title}
            formikProps={formConfig}
            {...modalProps}
        >
            <Label>
                {STRINGS.USER_ACCOUNT_MANAGEMENT.COLUMN_LABEL.name}: &nbsp;
                <b>{name}</b>
            </Label>
            <RolesSection type="edit"/>
        </FormModal>
    );
};
