/** This module contains utilities for editing and validating table nodes.
 *  @module
 */

import { STRINGS } from 'app-strings';
import { UniversalNode } from 'components/common/graph/UniversalNode.ts';
import { GraphDef, NodeDef } from 'components/common/graph/types/GraphTypes.ts';
import { getNodeFromGraphDef } from 'utils/runbooks/RunbookUtils.ts';
import { GenericKey, NodeUtils } from 'utils/runbooks/NodeUtil.ts';
import { DataOceanMetadata } from 'components/common/graph/editors/data-ocean/DataOceanMetadata.type.ts';
import { Context, RunbookContext } from 'utils/runbooks/RunbookContext.class.ts';
import { CustomProperty } from 'pages/create-runbook/views/create-runbook/CustomPropertyTypes.ts';

/** this interface defines the object that is passed to the key and metric selection control with the list
 *  of keys and metrics.*/
export interface KeyOption {
    /** a String with the value of the option. */
    value: string;
    /** a String with the label to display for the otpion. */
    label: string;
}

/** This is a Utility class for the logic node.   This class extends the NodeUtils class. */
export class TableNodeUtils {

	/** Validates given table node
	 *  @param selectedNode the node to validate.
	 *  @param currentProperties the current properties for the node.
	 *  @param errors the array of errors.
	 *  @param graphDef the GraphDef object with the whole graph model.
	 *  @returns an array of strings with all the errors encountered. */
	static validateNode(
        selectedNode: UniversalNode | undefined, currentProperties: Record<string, any>,
		errors: Array<string>, graphDef: GraphDef
    ): Array<string> {
		NodeUtils.validateNode(
            String(selectedNode?.getId()), errors, graphDef, 
            {runtime: {primitiveVariables: [], structuredVariables: []}, incident: {primitiveVariables: [], structuredVariables: []}, global: {primitiveVariables: [], structuredVariables: []}}, []
        );
		if (currentProperties.columns.length === 0) {
			errors.push(STRINGS.runbookEditor.errors.tableNode.noColumn);
		}
		if (!currentProperties.sortColumn) {
			errors.push(STRINGS.runbookEditor.errors.tableNode.noSortColumn);
		} else if (!currentProperties.sortOrder) {
			errors.push(STRINGS.runbookEditor.errors.tableNode.noSortOrder);
		}
		return errors;
	}

	/** Function to update the properties in selectedNode with values of corresponding property values in updatedProperties
 	 *      param.
 	 *  It will also delete the properties in selectedNode if they are missing in the updatedProperties
 	 *  @param updatedProperties - local key value pair object
 	 *  @param selectedNode - Object of type UniversalNode set when selecting the node from runbook
 	 *  @param libraryNode - metadata of the selected node category
 	 *  @returns undefined. */
	static updateNode(updatedProperties, selectedNode, libraryNode, graphDef) {
		libraryNode.properties.forEach((prop) => {
			if (updatedProperties.hasOwnProperty(prop.name)) {
				selectedNode.setProperty(prop.name, updatedProperties[prop.name]);
			} else {
				selectedNode.removeProperty(prop.name);
			}
		});

		const node = getNodeFromGraphDef(selectedNode.getId(), graphDef);
		if (node) {
			node.editedByUser = true;
		}
	};

	/** Get node metrics and keys
	 *  @param objMetricMetaData the meta data for the data ocean.
     *  @param customProperties the array of CustomProperty objects with the list of custom properties 
     *      for all entities.
	 *  @param node the visualization node that is going to display the metrics.
     *  @param graphDef the GraphDef with the whole graph.
	 *  @returns an array of metrics. */
	static getMetricsAndKeys(
        objMetricMetaData: DataOceanMetadata, customProperties: CustomProperty[], node: NodeDef | null, graphDef: GraphDef
    ): Array<KeyOption> {
        let metricsAndKeys: Array<KeyOption> = [];

        if (node) {
            let rbContext = new RunbookContext(node, graphDef, objMetricMetaData, customProperties);
            const context: Array<Context> = rbContext.getNodeContexts();
            if (context.length > 0) {
                const expandedKeys: Array<GenericKey> = context[context.length - 1].expandedKeys || [];
                metricsAndKeys = expandedKeys.filter((key) => {
                    return !key.hidden; 
                }).map(key => {
                    return {value: key.id, label: key.label};
                });
            }
            metricsAndKeys = metricsAndKeys.concat(TableNodeUtils.getMetrics(objMetricMetaData, customProperties, node, graphDef));
        }

		return metricsAndKeys;
	}

	/** Get node metrics
	 *  @param objMetricMetaData the meta data for the data ocean.
     *  @param customProperties the array of CustomProperty objects with the list of custom properties 
     *      for all entities.
	 *  @param node the visualization node that is going to display the metrics.
     *  @param graphDef the GraphDef with the whole graph.
	 *  @returns an array of metrics. */
    static getMetrics(objMetricMetaData: DataOceanMetadata, customProperties: CustomProperty[], node: NodeDef | null, graphDef: GraphDef): Array<KeyOption> {
        let metrics: Array<KeyOption> = [];

        if (node) {
            let rbContext = new RunbookContext(node, graphDef, objMetricMetaData, customProperties);
            const context: Context | undefined = TableNodeUtils.getContext(rbContext);
            if (context) {
                let contextMetrics = context.metrics;
                contextMetrics = contextMetrics.filter((metric) => {
                    return (metric as any).included === undefined || (metric as any).included === null || (metric as any).included === true;
                });
                metrics = contextMetrics.map((metric) => {
                    return { value: metric.id, label: metric.label };
                });
            }
        }

		return metrics;
	}

	/** Get node keys
	 *  @param objMetricMetaData the meta data for the data ocean.
     *  @param customProperties the array of CustomProperty objects with the list of custom properties 
     *      for all entities.
	 *  @param node the visualization node that is going to display the metrics.
     *  @param graphDef the GraphDef with the whole graph.
	 *  @returns an array of keys. */
	static getKeys(objMetricMetaData: DataOceanMetadata, customProperties: CustomProperty[], node: NodeDef | null, graphDef: GraphDef): Array<KeyOption> {
        let keys: Array<KeyOption> = [];
        if (node) {
            let rbContext = new RunbookContext(node, graphDef, objMetricMetaData, customProperties);
            const context: Context | undefined = TableNodeUtils.getContext(rbContext);
            if (context) {
                const expandedKeys: Array<GenericKey> = context.expandedKeys || [];
                keys = expandedKeys.filter((key) => {
                    return !key.hidden; 
                }).map(key => {
                    return {value: key.id, label: key.label};
                });
            }
        }

		return keys;
	}

    /** returns the node or trigger context where the metrics and keys are stored.
     *  @param runbookContext the RunbookContext instance with all the context information.
     *  @returns the node or trigger context where the metrics and keys are stored. */
    static getContext(runbookContext: RunbookContext): Context | undefined {
        const context: Array<Context> = runbookContext.getNodeContexts();
        if (context.length > 0) {
            // We want the first context
            return context[context.length - 1];
        } else {
            const tContext: Context | undefined = runbookContext.getTriggerContext();
            return tContext;
        }
    }
}
