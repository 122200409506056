query dataSource($id: ID!) {
	dataSource(id: $id) {
		id
		eTag
		name
		type
		uri
		hostname
		lastIngestedEndTime
		sslValidationEnabled
		metricStreamingEnabled
		queriesEnabled
		productVersion
		protocol
		port
		metricStreaming {
			networkDevice
			networkInterface
			applicationLocation
		}
		info {
			status
			error {
				code
				message
				innerError {
					id
					trace
					properties {
						key
						value
					}
				}
				details {
					code
					message
					innerError {
						id
						trace
						properties {
							key
							value
						}
					}
				}
			}
		}
		iot {
			deviceId
			hostname
			hubHostname
		}
		account {
			name
			id
		}
		authentication {
			username
		}
	}
}
