query getIncidentVariables {
    incidentVariables {
        variables {
            primitiveVariables {
                name
                type
                defaultValue
                isReadOnly
                unit
            }
            structuredVariables {
                name
                type
                isTimeseries
                isReadOnly
                keys {
                    id
                    label
                    type
                    unit
                    hidden
                }
                metrics {
                    id
                    label
                    type
                    unit
                }
            }
        }
    }
}
