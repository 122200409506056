import { GetRoutes as getRoutes } from "config/routes.ts";
import { PageWithHeader } from "components/sdwan/layout/page-with-header/PageWithHeader.tsx";
import { OneColumnContainer } from "components/common/layout/containers/one-column-container/OneColumnContainer.tsx";
import { UsageMonitorTabsView } from "pages/usage-monitor/views/UsageMonitorTabsView.tsx";
import { HELP, STRINGS } from "app-strings";

const UsageMonitorPage = (): JSX.Element => {
    return (
        <PageWithHeader
            name={UsageMonitorPage.name}
            addPadding={true}
            showTimeBar={false}
            showGlobalFilters={false}
            {...getRoutes()["usage-monitor"]}
            title={STRINGS.USAGE_MONITORING_PAGE.pageTitle}
            helpInfo={HELP.monitoringUsage}
        >
            <OneColumnContainer key="tabs">
                <UsageMonitorTabsView />
            </OneColumnContainer>
        </PageWithHeader>
    );
};

export { UsageMonitorPage };
