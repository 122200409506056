/** This module contains the component for displaying the query configuration step in the widget config dialog.  The
 *      query config step configures the query that will be used to retrieve the data.
 *  @module
 */

import React, { useRef, useEffect, useMemo, useState } from "react";
import { FILTER_OPERATORS, FILTER_TYPE, NavigatorWidgetConfig, QueryConfig } from "pages/navigator/Navigator.type.ts";
import { GraphDef, NodeProperty, RunbookInfo, Variant } from "components/common/graph/types/GraphTypes.ts";
import { UniversalNode } from "components/common/graph/UniversalNode.ts";
import { Node } from "react-flow-renderer";
import rvbdLibraryJSON from "pages/create-runbook/views/create-runbook/node_library.json";
import { RunbookConfig } from "utils/services/RunbookApiService.ts";
import { getGraphDefFromRunbookConfig } from "pages/create-runbook/views/create-runbook/CreateRunbookView.tsx";
import { NavigatorUtils } from "pages/navigator/NavigatorUtils.ts";
import { NodeLibrary, NodeLibraryCategory, NodeLibraryNode, NodeLibrarySpec } from "pages/create-runbook/views/create-runbook/NodeLibrary.ts";
import RunbookNodeLibrary from "pages/create-runbook/views/create-runbook/node_library.json";
import { DataOceanNodeEditor } from "components/common/graph/editors/data-ocean/DataOceanNodeEditor.tsx";
import { DataOceanUtils } from "components/common/graph/editors/data-ocean/DataOceanUtils.ts";
import { STARTING_OBJ_TYPE } from "pages/navigator/NavigatorPage.tsx";
import { HTMLSelect } from "@blueprintjs/core";
import { InlineHelp } from "components/common/layout/inline-help/InlineHelp.tsx";
import { HELP, STRINGS } from "app-strings";

/** Node type to editor mapping. */
const NodeEditors = {
    "data_ocean": DataOceanNodeEditor
};

/** this interface defines the properties passed into the QueryConfigStepProps React component. */
export interface QueryConfigStepProps {
    /** the configuration of this current dashboard widget. */
    config: NavigatorWidgetConfig;
    /** the current runbook configuration. */
    //runbookConfig?: RunbookConfig;
    /** the query configuration that descripts what data is being queried.  This object has all the changes
     *  incorporated into it from the multi-step dialog. */
    queryConfig?: QueryConfig;
    /** the handler for query change events. */
    onQueryChange?: (query: QueryConfig) => void;
    /** the handler for widget name change events. */
    //onWidgetNameChange?: (name: string) => void;
    /** the handler for widget type change events. */
    //onWidgetTypeChange?: (type: WIDGET_TYPE) => void;
}

/** this interface defines the functions available in the QueryConfigStep widget ref. */
export interface QueryConfigStepRef {
    /** returns the options from the node editor. */
    getQueryConfig: () => any;
}

/** Renders the query configuration step of the widget settings dialog.
 *  @param props the properties passed in.
 *  @returns JSX with the query configuration stepcomponent.*/
const QueryConfigStep = React.forwardRef<QueryConfigStepRef, QueryConfigStepProps>((props: QueryConfigStepProps, ref: any): JSX.Element => { 
    const [objType, setObjType] = useState<string>(props.queryConfig?.properties?.objType || STARTING_OBJ_TYPE);
    // The vantage point is a filter in DO so for now use the filter in the Runbook editor
    //const [vp, setVp] = useState<string>("all");

    // The dashboards have their own node library, but here we use the runbooks node library because we are 
    // taking the dashboard runbook and creating a standard runbook from it with a visual node in it.  The 
    // dashboard runbooks don't have visualization nodes.
    const nodeLibrary = useRef<NodeLibrary>(new NodeLibrary(RunbookNodeLibrary as NodeLibrarySpec));
    let libraryNode: NodeLibraryNode | undefined = getNodeLibraryNode("data_ocean");

    let queryConfig = JSON.parse(JSON.stringify(props.queryConfig || {}));
    if (queryConfig.properties.filters.length) {
        // We are using a different filter format in the navigator as compared to the format we use in 
        // the navigator
        // DO format: {filters: {dataSource: ["$id:id"]}}
        // Navigator Format: {filters: [{type: "data_source", values: [{id: id}], operator: "equals"}]}
        const filters: any = {};
        for (const filter of queryConfig.properties.filters) {
            if (filter.type === FILTER_TYPE.data_source) {
                filters.dataSource = [`$id:${filter.values[0]}`];
            }
        }
        queryConfig.properties.filters = filters;
    }
    const mockSelectedNode = useRef<UniversalNode>(NavigatorUtils.createSelectedQueryNodeForNodeEditor());
    NavigatorUtils.updateSelectedNodeProperties(mockSelectedNode.current, libraryNode, queryConfig?.properties);

    if (queryConfig && objType !== queryConfig?.properties.objType) {
        // Choose some default metrics
        const defaultMetrics = DataOceanUtils.dataOceanMetaData.obj_types[objType].metrics;
        const firstDefaultMetric = defaultMetrics?.length ? defaultMetrics[0] : undefined;

        // The object type has changed, update the query config
        queryConfig!.properties.objType = objType;
        queryConfig!.properties.groupBy = [];
        queryConfig!.properties.topBy = [{id: firstDefaultMetric || "", direction: "desc"}];
        queryConfig!.properties.metrics = firstDefaultMetric ? [firstDefaultMetric] : [];

        mockSelectedNode.current = NavigatorUtils.createSelectedQueryNodeForNodeEditor();
        NavigatorUtils.updateSelectedNodeProperties(mockSelectedNode.current, libraryNode, queryConfig!.properties);
    }

    // Create a graph def that hooks up the chart node to the data ocean node.
    //let runbookConfig: RunbookConfig = NavigatorUtils.appendVisualizationNodeToRunbookConfig(props.runbookConfig, widgetType);
    let runbookConfig: RunbookConfig = NavigatorUtils.createDoNodeFromQuery(queryConfig);
    const graphDef: GraphDef = getGraphDefFromRunbookConfig(nodeLibrary.current, runbookConfig, []);

    const EditorComponent = NodeEditors["data_ocean"];
    const editorRef = useRef<any>(undefined);
    const editorProps = {
        selectedNode: mockSelectedNode.current,
        libraryNode: libraryNode,
        globalNodes: [],
        activeRunbook: {} as RunbookInfo,
        graphDef: graphDef,
        nodeLibrary: nodeLibrary.current
    };

    useEffect(() => {
            ref({
                getQueryConfig: () => {
                    let options: any = undefined;
                    const editor = editorRef.current;
                    if (editor) {
                        options = {};
                        let errorMessages = [];
                        if (editor.validate) {
                            errorMessages = editor.validate();
                        }
                        if (errorMessages && errorMessages.length) {
                            //this.setState({errorMessages: errorMessages});
                            editor.updateNode();
                            if (mockSelectedNode.current?.node) {
                                for (const prop of ((mockSelectedNode.current?.node as Node)?.data?.properties || []) as NodeProperty[]) {
                                    options[prop.key] = prop.value;
                                }
                            }
                        } else {
                            editor.updateNode();
                            if (mockSelectedNode.current?.node) {
                                for (const prop of ((mockSelectedNode.current?.node as Node)?.data?.properties || []) as NodeProperty[]) {
                                    options[prop.key] = prop.value;
                                }
                            }
                        }
                    }
                    if (options.filters?.dataSource?.length) {
                        // We are using a different filter format in the navigator as compared to the format we use in 
                        // the navigator
                        // DO format: {filters: {dataSource: ["$id:id"]}}
                        // Navigator Format: {filters: [{type: "data_source", values: [{id: id}], operator: "equals"}]}
                        const id = options.filters.dataSource[0].split(":")[1];
                        options.filters = [
                            {type: FILTER_TYPE.data_source, operator: FILTER_OPERATORS.EQUAL, values: [id]}
                        ];
                    }  
                    return {properties: options};                
                }
            });
        }, 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const objTypes: {value: string, label: string}[] = useMemo(
        () => {
            const objTypesTemp: {value: string, label: string}[] = [];
            for (const objType in DataOceanUtils.dataOceanMetaData.obj_types) {
                objTypesTemp.push({value: objType, label: DataOceanUtils.dataOceanMetaData.obj_types[objType].label});
            }
            return objTypesTemp;    
        }, []
    ); 

    /* Again we are going to use the filters in the DO node editor rather than the external filter here
    const arDatasources: {value: string, label: string}[] = useMemo(
        () => {
            if (DataOceanUtils.dataOceanMetaData.obj_types[objType].filters.includes("data_source")) {
                return (getArDataSources() || []).map(ds => {
                    return { label: ds.name, value: ds.id }
                });
            } else {
                return [{label: "None", value: ""}];
            }
        }, [objType]
    );
    */

    return <div className="h-100"><table><tbody>
        <tr><td className="font-size-md-large fw-bold pt-2" colSpan={2}>
            <InlineHelp
                helpMapping={HELP.RunbookNodeCategory.Dataquery[objType.replace('.', '_')]?.dataset}
            >
                {STRINGS.runbookEditor.nodeLibrary.nodes.data_ocean.labels.datasetSelection}
            </InlineHelp>
        </td></tr>
        <tr>
            <td colSpan={2}>
                <div data-testid="data_ocean_obj_types">
                    <HTMLSelect
                        name="obj-types"
                        disabled={false}
                        options={objTypes}
                        value={objType}
                        onChange={(event) => {
                            setObjType(event.currentTarget.value);
                        }}
                    />
                </div>
            </td>
        </tr>
        {/* don't need the vantage point filter right now because it is supported as a standard DO filter*/}
        {/*<tr><td className="font-size-md-large fw-bold pt-2" colSpan={2}>
            <InlineHelp
                helpMapping={HELP.RunbookNodeCategory.Dataquery[objType.replace('.', '_')]?.vantagePoint}
            >
                {STRINGS.runbookEditor.nodeLibrary.nodes.data_ocean.labels.vantagePointSelection}
            </InlineHelp>
        </td></tr>
        <tr>
            <td colSpan={2}>
                <div data-testid="data_ocean_obj_types">
                    <HTMLSelect
                        name="vantage-points"
                        disabled={false}
                        options={arDatasources}
                        value={vp}
                        onChange={(event) => {
                            setVp(event.currentTarget.value);
                        }}
                    />
                </div>
            </td>
        </tr>*/}
        <EditorComponent {...editorProps} ref={editorRef} variant={Variant.INCIDENT} subflows={[]} />
    </tbody></table></div>;
});
  
export default QueryConfigStep;

/** returns the library node for the specified type.
 *  @param nodetype the node type whose node is requested.
 *  @returns the NodeLibraryNode for the node type or undefined. */
export function getNodeLibraryNode(nodeType: string): NodeLibraryNode | undefined {
    if (rvbdLibraryJSON?.categories?.length) {
        const category: NodeLibraryCategory | undefined = (rvbdLibraryJSON as NodeLibrarySpec)?.categories?.find((category) => category.name === "dataOcean");
        if (category?.nodes?.length) {
            const node = category.nodes?.find((node) => {
                return nodeType === node.type;
            });
            if (node) {
/*
                const propsToRemove = ["title", "row", "notes", "notesPosition"];
                for (let propIndex = (node.properties || []).length -1; propIndex >= 0; propIndex--) {
                    if (propsToRemove.includes(node.properties![propIndex].name)) {
                        node.properties?.splice(propIndex, 1);
                    }
                }
*/
                return node;
            }
        }        
    }
    return undefined;
}
