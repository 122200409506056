mutation CreateRunbookSchedule($input: CreateRunbookScheduleInput!) {
  createRunbookSchedule(input: $input)
}


# {
#   "input": {
#     "description": string,
#     "every": Int!,
#     "name": string,
#     "repeat": !, (ZERO, ONE, TWO, THREE, FOUR, FIVE)
#     "runbook": {
#       "detection": {
#         "id": string!,
#         "entity": !String
#       },
#       "incidentId": string!,
#       "inputs": ![
#         {
#           "isReadonly": Bool,
#           "name": string,
#           "type": string,
#           "value": string
#         }
#       ],
#       "runbookId": string!
#     },
#     "startDate": string!
#   }
# }
