import { RunbookContext, VariableContextByScope } from 'utils/runbooks/RunbookContext.class.ts';
import { StructuredVariableType } from 'utils/runbooks/VariablesUtils';

/** this interface defines a context source.  A context source is a node that provides context to the 
 *      specified node whose context is being generated. */
export interface VariableContextSource {
    /** the name of the variable providing the context. */
    name: string;
    /** the type of variable providing the context. */
    scope: string;
}

/** return the list of ContextSources that can provide the specified filter.
 *  @param filterKey a string with the filter key.
 *  @param variables the dictionary of variables by scope that have been defined.
 *  @param exactMatch a boolean value which specifies whether we require an exact match.  If false 
 *      an exact match is not required and the compatible keys are checked. 
 *  @returns the list of ContextSources that can provide the specified filter. */
export function getFilterVariableSources(filterKey: string, variables: VariableContextByScope, exactMatch: boolean): VariableContextSource[] {
    const sources: VariableContextSource[] = [];
    if (variables) {
        for (const scope in variables) {
            const varCollection = variables[scope];
            if (varCollection.structuredVariables) {
                for (const variable of varCollection.structuredVariables) {
                    if (
                        variable.type === filterKey || (variable.type === StructuredVariableType.CUSTOM && filterKey === "keys") ||
                        (!exactMatch && RunbookContext.areFiltersCompatible(variable.type, filterKey))) {
                        sources.push({name: variable.name, scope: scope});
                    }
                }
            }
        }
    }
    return sources;
}
