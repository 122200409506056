import React from 'react';
import * as yup from 'yup';
import { Icon, ErrorToaster, SuccessToaster } from '@tir-ui/react-components';
import { FormModal } from 'components/common/modal/FormModal.tsx';
import { STRINGS } from 'app-strings';
import { TextAreaField } from 'components/common/form/TextAreaField.tsx';
import { BULLET_COLORS } from 'components/enums/Colors.ts';
import { PARAM_NAME } from 'components/enums/QueryParams.ts';
import { computeWidgetContainerId } from 'reporting-infrastructure/utils/commonUtils.ts';
import { EventNames, trackEvent } from 'utils/appinsights/AppInsights.ts';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { AuthServiceProvider } from 'utils/providers/AuthServiceProvider.ts';
import { setQueryParams } from 'utils/hooks/useQueryParams.ts';
import { useMutation } from '@apollo/client';
import { NoteType } from 'components/enums/NotesActivity.ts';

import ADD_WIDGET_NOTE_MUTATION from './add-widget-note-mutation.graphql';
import EDIT_NOTE_MUTATION from './edit-note-mutation.graphql';

const AuthService = AuthServiceProvider.getService();
interface WidgetNoteMutationInput {
    note: {
        incidentId: string
        content: string
        type: string
        runbookId: string
        widgetId: string
    }
}
interface EditNoteMutationInput {
    note: {
        id: string, 
        incidentId: string,
        content: string
    }
}

export enum  Mode{
    "ADD" =  "ADD",
    "EDIT" = "EDIT"
}
export interface AddNotesModalProps {
    /** a string with the unique id of the incident.*/
    incidentId?: string;
    /** a string with the unique id of a note.*/
    noteId?: string;
    /** this identifies a specific runbook execution. Primarly used for sharing link to a specific runbook */
    runbookId?: string;
    /** identifies the specific widget in the runbook execution result */
    widgetId?: string;
    /** pass through props passed along to FormModal component */
    [key: string]: any;
    /** Dialog can be opened in add/Edit mode */
    mode?: Mode 
    /** content of the note */
    content?: string;

    /** Type of notes*/
    notesType?: NoteType
}

/**
 * Form Modal for adding Notes.
 * @param props Refer to AddNotesModalProps for details on member attribute.
 * @return  JSX
 */
export const AddNotesModal = ({ incidentId, runbookId, widgetId, noteId, notesType = NoteType.PINNED, content = "", mode = Mode.ADD,  ...passThruProps }: AddNotesModalProps) => {
    const appInsightsContext = useAppInsightsContext();
    const [addNote] = useMutation< any, WidgetNoteMutationInput >(ADD_WIDGET_NOTE_MUTATION, {
            onCompleted: (data) => {
                SuccessToaster({
                    message: STRINGS.addNotes.successMsgAdd
                });
            },
            onError: (err) => {
                ErrorToaster({
                    message: STRINGS.addNotes.errorMsgAdd
                });
                console.error( err?.message);
            }
        });

    const [setNote] = useMutation< any, EditNoteMutationInput>(EDIT_NOTE_MUTATION, {
            onCompleted: (data) => {
                SuccessToaster({
                    message: STRINGS.addNotes.successMsgEdit
                });
            },
            onError: (err) => {
                ErrorToaster({
                    message: STRINGS.addNotes.errorMsgEdit
                });
                console.error( err?.message);
            }
        });
    const validationSchema = yup.object().shape({
        notes: yup
            .string()
            .label(STRINGS.addNotes.notes)
            .required(),
    });
    const noop = "#"
    const formConfig = {
        initialValues: {
            notes: content 
        },
        validationSchema: validationSchema,
        onSubmit: (values: any, { resetForm }) => {
            if(mode === Mode.ADD) {
                if(values.notes?.trim() && incidentId) {
                    return addNote({
                        variables: {
                            note:{
                                incidentId: incidentId,
                                content: values.notes,
                                runbookId: runbookId || "",
                                widgetId: widgetId || "",
                                type: notesType || NoteType.PINNED
                            }
                        }
                    });
                }
            } else if(mode === Mode.EDIT){
                if(values.notes?.trim() && incidentId && noteId) {
                    return setNote({
                        variables: {
                            note:{
                                id: noteId,
                                incidentId: incidentId,
                                content: values.notes
                            }
                        }
                    });
                }
            }
            reportMetrics();
            resetForm();
        },
    };

    // report metrics to App Insights
    const reportMetrics = () => {
		if (appInsightsContext) {
			const properties = {
				name: EventNames.NOTES_ADD
			};
			trackEvent(appInsightsContext, AuthService, properties);
		}
	};
    const title = mode === Mode.ADD ? STRINGS.addNotes.title : STRINGS.addNotes.editNoteTitle;
    return (
        <FormModal
            title={title}
            formikProps={formConfig}
            {...passThruProps}
        >
            <br />
            {
                <React.Fragment>
                    <TextAreaField
                        placeholder={STRINGS.addNotes.placeHolder}
                        aria-label="notes text"
                        name="notes"
                        required={true}
                    />
                    <div className="mt-2">
                        <Icon icon="pin" className="" size={14} color={BULLET_COLORS.DEFAULT} />
                        <a href={noop} className="ps-2" aria-label="scroll to pinned content link" onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            const contId = computeWidgetContainerId(runbookId, widgetId);
                            // console.log(`PinnedContent : incidentid: ${incidentId}\nrunbookid: ${runbookId}\ncharId: ${widgetId}`)
                            setQueryParams({ [PARAM_NAME.pageLocationId]: contId }, true);
                        }}>
                            {STRINGS.addNotes.pinnedContent}
                        </a>
                    </div>
                </React.Fragment>
            }
        </FormModal>
    );
};
