/** This file defines the subflow input editor React component.  The subflow input editor allows you 
 *  to define the inputs that the subflow is expecting.
 *  @module */

import React, { useCallback, useContext, useEffect, useState } from "react";
import type { SimpleNodeEditorProps } from "../simple/SimpleNodeEditor.tsx";
import { HELP, STRINGS } from "app-strings";
import { Callout, AnchorButton, Intent, HTMLSelect, Radio, RadioGroup, InputGroup, Button, MenuItem, Switch, Classes } from "@blueprintjs/core";
import { Suggest, ItemRenderer } from "@blueprintjs/select";
import { OutputDataBlock } from "components/common/graph/editors/transform/OutputDataBlock.tsx";
import { UniversalNode } from "components/common/graph/UniversalNode.ts";
import { useStateSafePromise } from "utils/hooks/useStateSafePromise.ts";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade.tsx";
import { type OutputDataElementDefinition, OutputDataValueType } from "components/common/graph/editors/transform/OutputDataElement.tsx";
import { generateRandomID } from "components/common/condition-tree-builder/condition/ConditionUtils.ts";
import { RunbookContextSummary } from "components/common/graph/editors/RunbookContextSummary.tsx";
import { type GenericKey, NodeUtils } from "utils/runbooks/NodeUtil.ts";
import type { DataOceanMetadata } from "components/common/graph/editors/data-ocean/DataOceanMetadata.type.ts";
import { SHOW_CONTEXT } from "components/enums/QueryParams.ts";
import { DataOceanUtils } from "components/common/graph/editors/data-ocean/DataOceanUtils.ts";
import { Unit } from "reporting-infrastructure/types/Unit.class.ts";
import type { NodeLibraryNode } from 'pages/create-runbook/views/create-runbook/NodeLibrary.ts';
import { BasicDialog, DialogState, updateDialogState } from "components/common/basic-dialog/BasicDialog.tsx";
import { SUBFLOW_NODE_EDIT_PROPS, SubflowInputNodeUtils } from "components/common/graph/editors/subflow-input/SubflowInputNodeUtils.ts";
import { InputType } from "components/common/graph/types/GraphTypes.ts";
import { MultiSelectInput } from "components/common/multiselect/MultiSelectInput.tsx";
import type { VariableContextByScope } from "utils/runbooks/RunbookContext.class.ts";
import { RunbookContextUtils } from "utils/runbooks/RunbookContextUtils.class.ts";
import { RUNTIME_SCOPE, PrimitiveVariableType, StructuredVariableType } from "utils/runbooks/VariablesUtils.ts";
import { VariableContext } from "utils/runbooks/VariableContext.ts";
import { Variant } from 'components/common/graph/types/GraphTypes.ts';
import { ON_DEMAND_INPUT_NODE_EDIT_PROPS, OnDemandInputNodeUtils } from "components/common/graph/editors/on-demand-input/OnDemandInputNodeUtils.ts";
import { getTypes } from "utils/stores/GlobalDataSourceTypeStore.ts";
import { IntegrationLibraryService } from "utils/services/IntegrationLibraryApiService.ts";
import type { RunbookIntegrationDetails } from "pages/integrations/types/IntegrationTypes.ts";
import { IconNames, ErrorToaster } from '@tir-ui/react-components';
import { InlineHelp } from "components/common/layout/inline-help/InlineHelp.tsx";
import { Tab, TabbedSubPages } from "components/common/layout/tabbed-sub-pages/TabbedSubPages.tsx";
import _ from 'lodash';
import "./SubflowInputNodeEditor.scss";

/** this interface defines the properties that are used to store the nodes configurable parameters. */
interface InputNodeProperties {
    /** a string with the type of trigger, such as network_interface or network_device. */
    triggerType?: string;
    /** an enum with the output data format which is one of summarized or timeseries. */
    outputDataFormat?: OutputDataFormats;
    /** the array of OutputDataFields with the list of properties. */
    outputDataProperties?: OutputDataField[];
    /** the array of OutputDataFields with the list of metrics. */
    outputDataMetrics?: OutputDataField[];
    /** the array of input variables. */
    inputVariables?: string[];
    /** the array of output variables. */
    outputVariables?: string[];
    /** a String with the optional integration id. */
    integrationId?: string;
    /** the tag ids that go with the connectors. */
    tagIds?: any;
    /** an object containing the lists of static values for subflow runtime variables. */
    staticInputValuesLists?: any;
    /** an array containing descriptions for context values defined inside the subflow input node editor. */
    inputOrOutputValuesDescriptions?: any;
    valueTypes?: any;
}

/** an enum that specifies the possible output formats. */
export enum OutputDataFormats {
    /** the enumerated value for summary or average data values. */
    SUMMARIZED = "summary",
    /** the enumerated value for timeseries data values. */
    TIMESERIES = "timeseries",
}

/** this enum defines the possible update event types that might be fired by the output data block and output data element. */
export enum UpdateEventType {
    /** the enumerated value for a name change. */
    NAME_CHANGED = "NAME_CHANGED",
    /** the enumerated value for a value type change. */
    VALUE_TYPE_CHANGED = "VALUE_TYPE_CHANGED",
    /** the enumerated value for a unit change. */
    UNIT_CHANGED = "UNIT_CHANGED",
    /** the enumerated value for the selection of a default (one of the existing DO ids) key or metric. */
    DEFAULT_SELECTED = "DEFAULT_SELECTED",
    /** the enumerated value for the deletion of a key or metric. */
    DELETED = "DELETED",
}

/** this enum defines the possible element types, which are keys (aka properties) and metrics. */
export enum ElementType {
    /** the enumerated value for a property element. */
    PROPERTY = "Property",
    /** the enumerated value for a metric element. */
    METRIC = "Metric"
}

/** this enum defines the possible section types, which are keys (aka properties) and metrics. */
export enum SectionType {
    /** the enumerated value for a property section. */
    PROPERTY = "PROPERTIES",
    /** the enumerated value for a metric section. */
    METRIC = "METRICS"
}

/** Describe UpdateEvent, triggered when user edits one of the fields */
export interface UpdateEvent {
    /** a string with the unique id for the element. */
    uniqueId: string | undefined;
    /** a boolean value true if the id is a default DO id and false or undefined otherwise. */
    isDoId?: boolean | undefined;
    /** the id for the element. */
    id: string;
    /** the value for the update. */
    value: string;
    /** the type of update. */
    type: UpdateEventType;
    /** the element type for the update. */
    element?: ElementType
}

/** Interface that describes Output Data */
export interface OutputDataField {
    /** the id of the key or metric. */
    id: string;
    /** the display label for the key or metric. */
    label: string;
    /** the type of value: string, float, integer, ipaddr, etc. */
    type: string;
    /** a string with the unit. */
    unit: string;
    /** a string with the id of one of the data ocean keys or metrics. */
    dataOceanId?: string;
}

interface SubflowContextValueDescription { 
    valueName: string;
    description: string; 
    required: boolean | undefined; 
    advanced: boolean | undefined; 
    validateWithRegex: string | undefined; 
    minValue: number | undefined;
    maxValue: number | undefined; 
    valueType: string | undefined;
}

/** Component for editing the properties of a subflow input node.
 *  @param selectedNode - Currently selected active subflow input node.
 *  @param libraryNode- Selected transform node's meta data.
 *  @param graphDef - Graphdef object that defines the entire runbook. Provides a way to access all the nodes in the graph.
 *  @param variant - The runbook variant that is being edited.
 *  @returns a JSX component with the subflow input node editor. */
export const SubflowInputNodeEditor = React.forwardRef(({ selectedNode, libraryNode, graphDef, variant }: SimpleNodeEditorProps, ref: any): JSX.Element => {
    const [objMetricMetaData, setObjMetricMetaData] = useState<DataOceanMetadata>();
    const [availableConnectorTags, setAvailableConnectorTags] = useState<Array<{ label: string, value: string}>>([]);
    const [integrationConnectors, setIntegrationConnectors] = useState<any>([]);
    const [count, setCount] = useState<number>(0);

    const hasIntegrationConnectors = integrationConnectors?.length > 0;
    const [executeSafely] = useStateSafePromise();
    const {getVariables} = useContext(VariableContext);

    const variables: VariableContextByScope = {
        runtime: getVariables(RUNTIME_SCOPE, false),
        incident: {primitiveVariables: [], structuredVariables: []},
        global: {primitiveVariables: [], structuredVariables: []}
    };

    const primitiveVariables: any[] = variables.runtime.primitiveVariables.map((variable) => {
        return {display: variable.name, value: variable.name, type: variable.type };
    });

    const structuredVariables: any[] = variables.runtime.structuredVariables.map((variable) => {
        return {display: variable.name, value: variable.name, type: variable.type };
    });
    const variablesForMultiSelect: any[] = primitiveVariables.concat(structuredVariables);

    const NODE_EDIT_PROPS = variant === Variant.ON_DEMAND ? ON_DEMAND_INPUT_NODE_EDIT_PROPS : SUBFLOW_NODE_EDIT_PROPS;
    const inputType = variant === Variant.ON_DEMAND ? InputType.ON_DEMAND : InputType.SUBFLOW;

    const componentStates: InputNodeProperties = {
        triggerType: selectedNode?.getProperty(NODE_EDIT_PROPS.TRIGGER_TYPE) ? selectedNode?.getProperty(NODE_EDIT_PROPS.TRIGGER_TYPE) : inputType,
        outputDataFormat: selectedNode?.getProperty(NODE_EDIT_PROPS.OUTPUT_DATA_FORMAT) as OutputDataFormats,
        outputDataMetrics: (selectedNode?.getProperty(NODE_EDIT_PROPS.SYNTH_METRICS) as Array<OutputDataField>)?.map(metric => { return { ...metric, id: generateRandomID() }; }),
        outputDataProperties: (selectedNode?.getProperty(NODE_EDIT_PROPS.SYNTH_KEYS) as Array<OutputDataField>)?.map(key => { return { ...key, id: generateRandomID() }; }),
        inputVariables: selectedNode?.getProperty(NODE_EDIT_PROPS.INCOMING_VARIABLES) ? selectedNode?.getProperty(NODE_EDIT_PROPS.INCOMING_VARIABLES)/*.map(variable => variable.name)*/ : [],
        ...(variant === Variant.SUBFLOW && { outputVariables: selectedNode?.getProperty(SUBFLOW_NODE_EDIT_PROPS.OUTGOING_VARIABLES) ? selectedNode?.getProperty(SUBFLOW_NODE_EDIT_PROPS.OUTGOING_VARIABLES)/*.map(variable => variable.name)*/ : []}),
        ...(variant === Variant.SUBFLOW && { staticInputValuesLists: selectedNode?.getProperty(SUBFLOW_NODE_EDIT_PROPS.STATIC_INPUT_VALUES_LISTS) ? selectedNode?.getProperty(SUBFLOW_NODE_EDIT_PROPS.STATIC_INPUT_VALUES_LISTS) : {} }),
        ...(variant === Variant.SUBFLOW && { inputOrOutputValuesDescriptions: selectedNode?.getProperty(SUBFLOW_NODE_EDIT_PROPS.INPUT_OR_OUTPUT_VALUES_DESCRIPTIONS) ? selectedNode?.getProperty(SUBFLOW_NODE_EDIT_PROPS.INPUT_OR_OUTPUT_VALUES_DESCRIPTIONS) : [] }),
        integrationId: undefined,
        tagIds: selectedNode?.getProperty(SUBFLOW_NODE_EDIT_PROPS.TAG_IDS) ? selectedNode?.getProperty(SUBFLOW_NODE_EDIT_PROPS.TAG_IDS) : {}
    };
    const integrationIds = selectedNode?.getProperty(SUBFLOW_NODE_EDIT_PROPS.INTEGRATION_IDS) ? selectedNode?.getProperty(SUBFLOW_NODE_EDIT_PROPS.INTEGRATION_IDS) : {};
    if (integrationIds) {
        for (const key in integrationIds) {
            const value = integrationIds[key];
            if (value) {
                // Right now we only permit one integration so if we found a value that is the integration id, no need to go further
                componentStates.integrationId = value;

                break;
            }
        }
    }
    
    // Component states
    const [curProperties, setCurProperties] = useState<InputNodeProperties>(componentStates);

    const [dialogState, setDialogState] = useState<any>({showDialog: false, loading: true, title: STRINGS.viewRunbooks.inputDialogTitle, dialogContent: null, dialogFooter: null});
    const [contextValuesDescriptionsDialogState, setContextValuesDescriptionsDialogState] = useState<any>({showDialog: false, loading: false, title: "", dialogContent: null, dialogFooter: null});
    const [inputOrOutputValuesDescriptions, setInputOrOutputValuesDescriptions] = useState<Array<SubflowContextValueDescription>>(curProperties.inputOrOutputValuesDescriptions);
    
    // State variables used by the static value list feature
    const [staticInputValuesLists, setStaticInputValuesLists] = useState(curProperties.staticInputValuesLists);

    // State variables used by the subflow variables descriptions feature
    const [textareaValues, setTextareaValues] = useState({});

    useEffect(() => {
        const textareaValues = {};
        if (Array.isArray(inputOrOutputValuesDescriptions)) {
            inputOrOutputValuesDescriptions.forEach((item, _index) => {
                textareaValues[item.valueName] = item.description;
            });
        }
        setTextareaValues(textareaValues);
    }, [inputOrOutputValuesDescriptions]);

    useEffect(() => {
        curProperties.staticInputValuesLists = staticInputValuesLists;

        // Retrieve the integration connectors list;
        const connectorsAsVariables = variables.runtime.primitiveVariables.filter(variable => variable.type === PrimitiveVariableType.CONNECTOR)
        const connectorsReceivedFromCaller = Object.keys(curProperties?.staticInputValuesLists || {}).filter(el => connectorsAsVariables.find(connector => connector.name === el));

        setIntegrationConnectors(connectorsReceivedFromCaller);    
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [curProperties, staticInputValuesLists]);

    useEffect(() => {
        curProperties.inputOrOutputValuesDescriptions = inputOrOutputValuesDescriptions;
    }, [curProperties, inputOrOutputValuesDescriptions]);
    
    const fetchData = useCallback(
        () => {
            return executeSafely(DataOceanUtils.init()).then((response: any) => {
                let metaData: DataOceanMetadata = response;
                if (metaData?.keys) {
                    const onDemandRunbooksSupportedEntities = ["network_interface", "network_device", "application", "location", "network_host", "network_client", "network_server", "client_location", "server_location"];
                    metaData = {...metaData, keys: {...metaData.keys}};
                    for (const [key] of Object.entries(response.keys) as any) {
                        if (variant === Variant.ON_DEMAND && !onDemandRunbooksSupportedEntities.includes(key)) {
                            delete metaData.keys[key];
                        }
                    }
                }
                setObjMetricMetaData(metaData);
            }, error => {
                console.error(error);
            });
        },
        [executeSafely, variant]
    );

    const [keyDefinitions, setKeyDefinitions] = useState<Array<OutputDataElementDefinition>>(curProperties?.outputDataProperties?.map((keyDef) => {
        return { id: keyDef.id, label: keyDef.label, type: keyDef.type, unit: keyDef.unit, dataOceanId: keyDef.dataOceanId }
    }) || []);

    /* Used in the metric definition which is now commented out
    const [metricDefinitions, setMetricDefinitions] = useState<Array<OutputDataElementDefinition>>(curProperties?.outputDataMetrics?.map((metricDef) => {
        return { id: metricDef.id, label: metricDef.label, type: metricDef.type, unit: metricDef.unit, dataOceanId: metricDef.dataOceanId }
    }) || []);
    */

    useEffect(() => {
        // Fetch Meta data on load.
        fetchData();
    }, [fetchData]);

    const [integrations, setIntegrations] = useState<RunbookIntegrationDetails[] | undefined>(undefined);
    useEffect(() => {
        const integrationsPromise = new Promise<RunbookIntegrationDetails[]>(async (resolve, reject) => {
            try {
                const newIntegrations = await IntegrationLibraryService.getRunbookIntegrations();

                resolve(newIntegrations as RunbookIntegrationDetails[]);    
            } catch (error) {
                reject(error);
            }
        });
        executeSafely(integrationsPromise).then(
            async (integrations) => {
                setIntegrations(integrations);
            }, 
            () => {
                setIntegrations([]);
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /** Handle integration tags */
    useEffect(() => {
        async function setTags(integrations: any) {
            if (!curProperties?.integrationId || !integrations) {
                return;
            }

            try {
                const tags = await retrieveTagsForIntegration(integrations, curProperties?.integrationId);
                const options = (tags || []).map(el => { return {label: el.value, value: el.key}}).concat([{label: "Not Set", value: ""}]);

                setAvailableConnectorTags(options);   
            } catch (error) {
                ErrorToaster(
                    {
                        message: STRINGS.runbookEditor.nodeEditor.variables.tags.fetchError,
                        timeout: 5000
                    }
                );
                console.error(error);                
            }
        }

        // Retrieve the integration tags
        if (curProperties?.integrationId) { 
           setTags(integrations);
        }
    }, [curProperties?.integrationId, integrations])
    
    /**
     * Partially update the state of the current properties
     * @param partialProps -  transform node properties that need to be updated
     */
    function syncProperties(partialProps: InputNodeProperties): void {
        const updatedProperties = { ...curProperties, ...partialProps };
        setCurProperties(updatedProperties);
    }

    /**
     * Update the selected node properties. This is invoked when done button is clicked
     * on the node editor dialog.
     * @param properties -  Properties in selected node that need to updated
     * @param selectedNode - node being edited
     * @param libraryNode - object that specifies all the editable properties for a given node.
     */
    function updateNode(properties: InputNodeProperties, selectedNode: UniversalNode | undefined, libraryNode: NodeLibraryNode | undefined) {
        if (!selectedNode || !libraryNode || !properties) {
            console.warn("updateNode has invalid inputs. Node update failed");
            return;
        }
        selectedNode.setProperty(NODE_EDIT_PROPS.TRIGGER_TYPE, curProperties?.triggerType);
        selectedNode.setProperty(NODE_EDIT_PROPS.OUTPUT_DATA_FORMAT, curProperties?.outputDataFormat);

        const synthMetrics = curProperties?.outputDataMetrics;
        synthMetrics?.forEach((metric) => {
            metric.id = metric.dataOceanId ? metric.dataOceanId : metric.label.replace(/ /g, "_");
        });
        selectedNode.setProperty(NODE_EDIT_PROPS.SYNTH_METRICS, synthMetrics);

        const synthKeys = curProperties?.outputDataProperties;
        synthKeys?.forEach((key) => {
            key.id = key.dataOceanId ? key.dataOceanId : key.label.replace(/ /g, "_");
        });
        selectedNode.setProperty(NODE_EDIT_PROPS.SYNTH_KEYS, synthKeys);

        const inputVariables = curProperties?.inputVariables || [];
        selectedNode.setProperty(NODE_EDIT_PROPS.INCOMING_VARIABLES, inputVariables);
/* This is if we want to include the full variable def in the properties, I think we only need the name
        selectedNode.setProperty(SUBFLOW_NODE_EDIT_PROPS.INCOMING_VARIABLES, inputVariables.map((variable) => {
            for (const primitiveVariable of variables.runtime.primitiveVariables) {
                if (primitiveVariable.name === variable) {
                    return {name: primitiveVariable.name, type: primitiveVariable.type, unit: primitiveVariable.unit};
                }
            }
            for (const structuredVariable of variables.runtime.structuredVariables) {
                if (structuredVariable.name === variable) {
                    return {name: structuredVariable.name, type: structuredVariable.type, unit: "none", isTimeseries: structuredVariable.isTimeseries};
                }
            }
            return {name: variable};
        }));
*/
        if (variant === Variant.SUBFLOW) {
            const outputVariables: string[] = curProperties?.outputVariables || [];
            selectedNode.setProperty(SUBFLOW_NODE_EDIT_PROPS.OUTGOING_VARIABLES, outputVariables);

            const inputVariables: string[] = curProperties?.inputVariables || [];
            const integrationId = curProperties?.integrationId;
            let integrationIds = {};
            if (integrationId && inputVariables) {
                for (const variableName of inputVariables) {
                    for (const variable of variables.runtime.primitiveVariables) {
                        if (variableName === variable.name && variable.type === PrimitiveVariableType.CONNECTOR) {
                            integrationIds[variable.name] = integrationId;
                            break;
                        }    
                    }
                }
            }

            const staticInputValuesLists = curProperties?.staticInputValuesLists || {};
            const inputOrOutputValuesDescriptions = curProperties?.inputOrOutputValuesDescriptions || [];
            selectedNode.setProperty(SUBFLOW_NODE_EDIT_PROPS.STATIC_INPUT_VALUES_LISTS, staticInputValuesLists);
            selectedNode.setProperty(SUBFLOW_NODE_EDIT_PROPS.INPUT_OR_OUTPUT_VALUES_DESCRIPTIONS, inputOrOutputValuesDescriptions);
            selectedNode.setProperty(SUBFLOW_NODE_EDIT_PROPS.INTEGRATION_IDS, integrationIds); 
            selectedNode.setProperty(SUBFLOW_NODE_EDIT_PROPS.TAG_IDS, curProperties?.tagIds || {});       
        }
/* This is if we want to include the full variable def in the properties, I think we only need the name
        selectedNode.setProperty(SUBFLOW_NODE_EDIT_PROPS.OUTGOING_VARIABLES, outputVariables.map((variable) => {
            for (const primitiveVariable of variables.runtime.primitiveVariables) {
                if (primitiveVariable.name === variable) {
                    return {name: primitiveVariable.name, type: primitiveVariable.type, unit: primitiveVariable.unit};
                }
            }
            for (const structuredVariable of variables.runtime.structuredVariables) {
                if (structuredVariable.name === variable) {
                    return {name: structuredVariable.name, type: structuredVariable.type, unit: "none", isTimeseries: structuredVariable.isTimeseries};
                }
            }
            return {name: variable};
        }));
*/
    }

    /** istanbul ignore next */
    function getNodeProperties(properties: InputNodeProperties, selectedNode: UniversalNode | undefined, libraryNode: NodeLibraryNode | undefined) {
        if (!selectedNode || !libraryNode || !properties) {
            console.warn("updateNode has invalid inputs. Node update failed");
            return;
        }
        const outputProperties = {};
        outputProperties[NODE_EDIT_PROPS.TRIGGER_TYPE] = curProperties?.triggerType;

        let synthMetrics: Array<OutputDataField> = curProperties?.outputDataMetrics || [];
        synthMetrics = synthMetrics?.map((metric) => {
            return { ...metric, id: metric.dataOceanId ? metric.dataOceanId : metric.label.replace(/ /g, "_") };
        });

        let synthKeys: Array<OutputDataField> = curProperties?.outputDataProperties || [];
        synthKeys = synthKeys?.map((key) => {
            return { ...key, id: key.dataOceanId ? key.dataOceanId : key.label.replace(/ /g, "_") };
        });

        outputProperties[NODE_EDIT_PROPS.SYNTH_METRICS] = synthMetrics;
        outputProperties[NODE_EDIT_PROPS.SYNTH_KEYS] = synthKeys;

        outputProperties[NODE_EDIT_PROPS.OUTPUT_DATA_FORMAT] = curProperties?.outputDataFormat;

        outputProperties[NODE_EDIT_PROPS.INCOMING_VARIABLES] = curProperties?.inputVariables;
        if (variant === Variant.SUBFLOW) {
            const outputVariables: string[] | undefined = curProperties?.outputVariables;
            outputProperties[SUBFLOW_NODE_EDIT_PROPS.OUTGOING_VARIABLES] = outputVariables;
            outputProperties[SUBFLOW_NODE_EDIT_PROPS.STATIC_INPUT_VALUES_LISTS] = curProperties?.staticInputValuesLists;
            outputProperties[SUBFLOW_NODE_EDIT_PROPS.INPUT_OR_OUTPUT_VALUES_DESCRIPTIONS] = curProperties?.inputOrOutputValuesDescriptions;
            const inputVariables: string[] = curProperties?.inputVariables || [];
            const integrationId = curProperties?.integrationId;
            let integrationIds: any = undefined;
            if (integrationId && inputVariables) {
                integrationIds = {};
                for (const variableName of inputVariables) {
                    for (const variable of variables.runtime.primitiveVariables) {
                        if (variableName === variable.name && variable.type === PrimitiveVariableType.CONNECTOR) {
                            integrationIds[variable.name] = integrationId;
                            break;
                        }    
                    }
                }
            }
            selectedNode.setProperty(SUBFLOW_NODE_EDIT_PROPS.INTEGRATION_IDS, integrationIds);    
            selectedNode.setProperty(SUBFLOW_NODE_EDIT_PROPS.TAG_IDS, curProperties?.tagIds);
        }

        return outputProperties;
    }

    ref.current = {
        updateNode: () => {
            updateNode(curProperties, selectedNode, libraryNode);
        },
        validate: () => {
            const errorMessages = new Array<string>();
            if (variant === Variant.SUBFLOW) {
                SubflowInputNodeUtils.validateNodeProperties(
                    curProperties,
                    errorMessages,
                );
            } else {
                OnDemandInputNodeUtils.validateNodeProperties(
                    curProperties,
                    errorMessages,
                );
            }
            return errorMessages;
        }
    };

    function handleOutputDataKeyChange(event: UpdateEvent) {
        let synthKeys: Array<OutputDataField> = curProperties?.outputDataProperties || [];
        synthKeys = ([] as Array<OutputDataField>).concat(synthKeys);
        const uniqueId = event.uniqueId;
        //const id = event.id;
        const type = event.type;
        const value = event.value;

        let keyDef: any = synthKeys.find((key) => key.id === uniqueId);
        if (!keyDef) {
            // We are defining a new metric
            keyDef = {
                id: uniqueId,
                label: '',
                type: Object.keys(OutputDataValueType)?.map(k => OutputDataValueType[k as any])[0],
                unit: ''
            };
            synthKeys.push(keyDef);
        }

        switch (type) {
            case UpdateEventType.NAME_CHANGED:
                //metricDef.id = value.replace(/ /g, "_");
                keyDef.label = value;
                if (keyDef.dataOceanId) {
                    keyDef.type = Object.keys(OutputDataValueType)?.map(k => OutputDataValueType[k as any])[0];
                    keyDef.unit = Unit.BASE_UNITS[0];
                }
                delete keyDef.dataOceanId;
                delete keyDef.dataOceanKeys;
                break;
            case UpdateEventType.UNIT_CHANGED:
                keyDef.unit = value;
                break;
            case UpdateEventType.VALUE_TYPE_CHANGED:
                keyDef.type = value;
                break;
            case UpdateEventType.DEFAULT_SELECTED:
                keyDef.dataOceanId = event.isDoId ? event.id : null;
                keyDef.label = event.value;
                keyDef.type = "object";
                let expandedKeys: Array<GenericKey> = [];
                const keyId = keyDef.dataOceanId;
                const doKeyDef = objMetricMetaData?.keys[keyId];
                if (doKeyDef) {
                    NodeUtils.expandKeyProperties(doKeyDef, keyId, expandedKeys);
                }
                keyDef.dataOceanKeys = expandedKeys;
                break;
            case UpdateEventType.DELETED:
                for (let i = 0; i < synthKeys.length; i++) {
                    if (synthKeys[i].id === uniqueId) {
                        synthKeys.splice(i, 1);
                        break;
                    }
                }
                break;
        }

        syncProperties({ outputDataProperties: synthKeys });
        setKeyDefinitions(synthKeys);
    }

    /* Used in the metric definition which is now commented out
    function handleOutputDataMetricChange(event: UpdateEvent) {
        let synthMetrics: Array<OutputDataField> = curProperties?.outputDataMetrics || [];
        synthMetrics = ([] as Array<OutputDataField>).concat(synthMetrics);
        const uniqueId = event.uniqueId;
        //const id = event.id;
        const type = event.type;
        const value = event.value;

        let metricDef: any = synthMetrics.find((metric) => metric.id === uniqueId);
        if (!metricDef) {
            // We are defining a new metric
            metricDef = {
                id: uniqueId,
                label: '',
                type: Object.keys(OutputDataValueType)?.map(k => OutputDataValueType[k as any])[0],
                unit: ''
            };
            synthMetrics.push(metricDef);
        }

        switch (type) {
            case UpdateEventType.NAME_CHANGED:
                //metricDef.id = value.replace(/ /g, "_");
                metricDef.label = value;
                if (metricDef.dataOceanId) {
                    metricDef.type = Object.keys(OutputDataValueType)?.map(k => OutputDataValueType[k as any])[0];
                    metricDef.unit = Unit.BASE_UNITS[0];
                }
                delete metricDef.dataOceanId;
                break;
            case UpdateEventType.UNIT_CHANGED:
                metricDef.unit = value;
                break;
            case UpdateEventType.VALUE_TYPE_CHANGED:
                metricDef.type = value;
                break;
            case UpdateEventType.DEFAULT_SELECTED:
                metricDef.dataOceanId = event.isDoId ? event.id : null;
                metricDef.label = event.value;
                metricDef.type = objMetricMetaData?.metrics[event.id].type;
                metricDef.unit = objMetricMetaData?.metrics[event.id].unit;
                break;
            case UpdateEventType.DELETED:
                for (let i = 0; i < synthMetrics.length; i++) {
                    if (synthMetrics[i].id === uniqueId) {
                        synthMetrics.splice(i, 1);
                        break;
                    }
                }
                break;
        }
        syncProperties({ outputDataMetrics: synthMetrics });
        setMetricDefinitions(synthMetrics);
    }
    */

    const descriptions = {
        startingEntityDesc: STRINGS.runbookEditor.nodeEditor.subflowInputStartingEntityDesc,
        contextValuesReceivedFromCallerDesc: STRINGS.runbookEditor.nodeEditor.subflowInputStartingEntityDesc
    }

    if (variant === Variant.ON_DEMAND) {
        descriptions.startingEntityDesc = STRINGS.runbookEditor.nodeEditor.onDemandInputStartingEntityDesc;
        descriptions.contextValuesReceivedFromCallerDesc = STRINGS.runbookEditor.nodeEditor.onDemandInputToCallerContextDesc;
    }

    // Wait for api call to complete before rendering the editor
    if (!objMetricMetaData || !integrations) {
        return <tr><td colSpan={2}><DataLoadFacade loading /></td></tr>;
    }

    const connectorOptions = [{label: STRINGS.runbookEditor.nodeEditor.subflowVarMapping.selectConnectorText, value: ""}].concat(
        integrations.map((integration: RunbookIntegrationDetails) => {
            return {label: integration.name, value: integration.id};
        })
    );


    /**
     * Render the integration connector/tags selection area
     * 
     * @returns {JSX.Element}
     */
    function renderIntegrationConfiguration() {
        // availableConnectorTags is always 1, as it includes the 'Not Set' option
        if (!hasIntegrationConnectors || !availableConnectorTags || availableConnectorTags?.length < 2) {
            return <></>
        }

        return <>
            <tr className="pt-2">
                <td colSpan={2} className="fw-bold">
                    {STRINGS.runbookEditor.nodeEditor.variables.tags.title}
                </td>
            </tr>
            {integrationConnectors.map(connector => {
                return (
                    <tr key={connector}>
                        <td className="font-size-md-small fw-500">{connector}</td>
                        <td className="pt-2">
                            <HTMLSelect
                                data-testid="subflow-input-tag-select"
                                className="selector-min-width"
                                fill={true}
                                options={availableConnectorTags}
                                defaultValue={(curProperties?.tagIds?.[connector] && curProperties?.tagIds?.[connector][0]) || ""}
                                onChange={
                                    async (event) => {
                                        const value = event.currentTarget.value;
                                        if (!curProperties?.tagIds) {
                                            curProperties.tagIds = {};
                                        }

                                        // Unset Value
                                        if (curProperties.tagIds[connector] && (!value || value.length === 0)) {
                                            delete curProperties.tagIds[connector];

                                            return;
                                        }

                                        // Set Value
                                        curProperties.tagIds[connector] = [value];
                                    }   
                                }
                            />
                        </td>
                    </tr>
                );
            })}
        </>;
    }

    return (
        <>
            <BasicDialog dialogState={dialogState} className="transform-template-dialog" onClose={() => {
                setDialogState(updateDialogState(dialogState, false, false, []));
            }} />
            <BasicDialog dialogState={contextValuesDescriptionsDialogState} className="edit-descriptions-dialog" onClose={() => {
                setContextValuesDescriptionsDialogState(updateDialogState(contextValuesDescriptionsDialogState, false, false, []));
            }} />
            <tr><td className="font-size-md-large fw-bold pt-2" colSpan={2}>
                <InlineHelp 
                    helpMapping={
                        HELP.RunbookNodeCategory[variant === Variant.ON_DEMAND ? "Trigger" : "SubflowInput"][variant === Variant.ON_DEMAND ? "InputNode" : "SubflowInputNode"].StartingEntity
                    }
                >
                    {STRINGS.runbookEditor.nodeEditor.subflowInputStartingEntity}
                </InlineHelp>
            </td></tr>
            <tr><td className="pb-3" colSpan={2}>
                <Callout intent={Intent.PRIMARY}>{descriptions.startingEntityDesc}</Callout>
            </td></tr>
            <tr>
                <td className="font-size-md-large fw-bold pt-2" colSpan={2}>
                    <OutputDataBlock key={keyDefinitions.length + generateRandomID()}
                        sectionLabel={STRINGS.runbookEditor.nodeEditor.inputDataSection}
                        sectionName={SectionType.PROPERTY}
                        elementName={ElementType.PROPERTY}
                        suggestionsList={RunbookContextUtils.getAvailableKeysForKeyMap(objMetricMetaData.keys, [], getTypes())}
                        onChange={(e: UpdateEvent) => { handleOutputDataKeyChange(e) }}
                        definitions={keyDefinitions}
                        loadFromVar={false}
                        isSubflow={true}
                        isOnDemandNodeEditor={variant === Variant.ON_DEMAND ? true : false}
                    />
                    {/* not supporting metrics right now <OutputDataBlock key={metricDefinitions.length + generateRandomID()}
                        sectionLabel={STRINGS.runbookEditor.nodeEditor.inputDataSection}
                        sectionName={SectionType.METRIC}
                        elementName={ElementType.METRIC}
                        suggestionsList={objMetricMetaData.metrics as Object}
                        onChange={(e: UpdateEvent) => { handleOutputDataMetricChange(e) }}
                        definitions={metricDefinitions}
                        loadFromVar={false}
                    />*/}
                </td>
            </tr>
            <tr className={variant === Variant.ON_DEMAND ? "d-none" : ""}>
                <td colSpan={2}>
                    <div className="d-flex flex-row pt-3">
                        <label className="mb-0">{STRINGS.runbookEditor.nodeEditor.inputDataFormat}</label>
                        <RadioGroup
                            name="outputDataFormat"
                            disabled={false}
                            onChange={e => {
                                const updatedPartialProperties = {
                                    outputDataFormat: e.currentTarget.value as OutputDataFormats,
                                }
                                syncProperties(updatedPartialProperties);
                            }}
                            selectedValue={curProperties?.outputDataFormat}
                            inline={true}
                            className="ps-4 align-self-center"
                        >
                            <Radio
                                label={STRINGS.runbookEditor.nodeLibrary.propertyLabels.summarized}
                                value={OutputDataFormats.SUMMARIZED}
                                className="mb-0"
                            />
                            <Radio
                                label={STRINGS.runbookEditor.nodeLibrary.propertyLabels.timeSeries}
                                value={OutputDataFormats.TIMESERIES}
                                className="mb-0"
                            />
                        </RadioGroup>
                    </div>
                </td>
            </tr>
            <tr><td className="font-size-md-large fw-bold pt-2" colSpan={2}>
                <InlineHelp 
                    helpMapping={
                        HELP.RunbookNodeCategory[variant === Variant.ON_DEMAND ? "Trigger" : "SubflowInput"][variant === Variant.ON_DEMAND ? "InputNode" : "SubflowInputNode"].ContextValuesReceivedFromCaller
                    }
                >
                    {variant === Variant.ON_DEMAND 
                        ? STRINGS.runbookEditor.nodeEditor.onDemandInputAdditionalParametersTitle 
                        : STRINGS.runbookEditor.nodeEditor.subflowInputFromCallerContext
                    }
                </InlineHelp>
            </td></tr>
            <tr><td className="pb-3" colSpan={2}>
                <Callout intent={Intent.PRIMARY}>{descriptions.contextValuesReceivedFromCallerDesc}</Callout>
            </td></tr>
            <tr>
                <td className="pt-2" colSpan={2}>
                    {variant === Variant.ON_DEMAND && <p className="display-9 fw-500 mb-2 p-0">{STRINGS.runbookEditor.nodeEditor.onDemandInputTypeVariableName}</p>}
                    <MultiSelectInput
                        className="display-8"
                        sortable
                        items={variablesForMultiSelect?.filter(variable => (variant !== Variant.ON_DEMAND && variable) || (variant === Variant.ON_DEMAND && variable.type !== StructuredVariableType.CUSTOM)) /*[{ display: "Application", value: "Application" }] metrics.map(metric => {
                            return { display: metric.label, value: metric.value }
                        })*/ }
                        selectedItems={ 
                            curProperties?.inputVariables?.map((variable) => {
                                return { display: variable, value: variable }
                            }) || []
                            
                            /*[{ display: "Application", value: "Application" }] selectedMetrics.map(metric => {
                            return { display: metric.label, value: metric.value }
                        })*/ }
                        onChange={ updatedValues => {
                            curProperties.inputVariables = updatedValues ? updatedValues.map(item => {
                                return item.value
                            }) : [];
                            if (updatedValues?.length) {
                                const newStaticInputValuesLists = {};
                                updatedValues.forEach(updatedValue => {
                                    if ((primitiveVariables || [])?.find(variable => variable.display === updatedValue.value)) {
                                        newStaticInputValuesLists[updatedValue.value] = staticInputValuesLists[updatedValue.value] || [];
                                    }
                                });

                                const connectorsAsVariables = variables.runtime.primitiveVariables.filter(variable => variable.type === PrimitiveVariableType.CONNECTOR)
                                const connectorsReceivedFromCaller = Object.keys(newStaticInputValuesLists).filter(el => connectorsAsVariables.find(connector => connector.name === el));
    
                                setIntegrationConnectors(connectorsReceivedFromCaller);
                                setStaticInputValuesLists(newStaticInputValuesLists);
                            } else {
                                setIntegrationConnectors([]);
                                setStaticInputValuesLists({});
                            }
                            /*
                            currentProperties['metrics'] = updatedValues ? updatedValues.map(item => {
                                return item.value
                            }) : [];
                            onMetricsChanged();
                            */
                        } }
                        placeholder={variant !== Variant.ON_DEMAND ? STRINGS.runbookEditor.nodeEditor.subflowInputFromCallerContextPlaceHolder : "" }
                        disabled={false /*required && metrics.length === 1 && selectedMetrics.length > 0*/}
                        inputOrOutputValuesDescriptions={curProperties?.inputOrOutputValuesDescriptions}
                    />
                    {variant === Variant.SUBFLOW && !!curProperties?.inputVariables?.length &&
                    <div className="enter-context-values-descriptions pb-0 text-md-right">
                        <Button minimal onClick={
                            () => openContextValuesDescriptionsDialog(
                                "input", 
                                curProperties.inputVariables, 
                                contextValuesDescriptionsDialogState, 
                                setContextValuesDescriptionsDialogState, 
                                setInputOrOutputValuesDescriptions, 
                                curProperties.inputOrOutputValuesDescriptions,
                                textareaValues, 
                                setTextareaValues,
                                getVariables,
                                curProperties,
                            )
                        }>
                            <span>{STRINGS.runbookEditor.nodeEditor.subflowInputNodeEditor.enterParameters}</span>
                        </Button>
                    </div>}
                    {variant === Variant.ON_DEMAND && <AnchorButton text={STRINGS.runbookEditor.nodeEditor.onDemandInputCreateEditVariable} intent="primary" minimal={true} className="on-demand-create-variable p-0 fw-normal bg-transparent" small={true} onClick={() => {
                        const variableIcon: HTMLButtonElement | null = document.querySelector("#variables-icon");
                        if (variableIcon) {
                            variableIcon.click();
                        }
                    }}/>}
                </td>
            </tr>
            {(variant === Variant.SUBFLOW) && <>
                <tr><td className="font-size-md-large fw-bold pt-2" colSpan={2}>
                    <InlineHelp helpMapping={HELP.RunbookNodeCategory.SubflowInput.SubflowInputNode.ContextValuesSentBackToCaller}>
                        {STRINGS.runbookEditor.nodeEditor.subflowInputToCallerContext}
                    </InlineHelp>
                </td></tr>
                <tr><td className="pb-3" colSpan={2}>
                    <Callout intent={Intent.PRIMARY}>{STRINGS.runbookEditor.nodeEditor.subflowInputToCallerContextDesc}</Callout>
                </td></tr>
                <tr>
                    <td className="fw-bold pt-2" colSpan={2}>
                        <MultiSelectInput
                            sortable
                            items={variablesForMultiSelect?.filter(variable => variable.type !== PrimitiveVariableType.AUTH_PROFILE && variable.type !== PrimitiveVariableType.ALLUVIO_EDGE) /*[{ display: "Status", value: "Status" }] metrics.map(metric => {
                                return { display: metric.label, value: metric.value }
                            })*/ }
                            selectedItems={ 
                                curProperties?.outputVariables?.map((variable) => {
                                    return { display: variable, value: variable }
                                }) || []

                                /*[{ display: "Status", value: "Status" }] selectedMetrics.map(metric => {
                                return { display: metric.label, value: metric.value }
                            })*/ }
                            onChange={ updatedValues => {
                                curProperties.outputVariables = updatedValues ? updatedValues.map(item => {
                                    return item.value
                                }) : [];
                                setCount(count+1);
                                /*
                                currentProperties['metrics'] = updatedValues ? updatedValues.map(item => {
                                    return item.value
                                }) : [];
                                onMetricsChanged();
                                */
                            } }
                            placeholder={ STRINGS.runbookEditor.nodeEditor.subflowInputToCallerContextPlaceHolder }
                            disabled={false /*required && metrics.length === 1 && selectedMetrics.length > 0*/}
                            inputOrOutputValuesDescriptions={curProperties?.inputOrOutputValuesDescriptions}
                        />
                    </td>
                </tr>
                {variant === Variant.SUBFLOW && !!curProperties?.outputVariables?.length &&
                    <div className="enter-context-values-descriptions pb-0 text-md-right">
                        <Button minimal onClick={
                            () => openContextValuesDescriptionsDialog(
                                "output", 
                                curProperties.outputVariables, 
                                contextValuesDescriptionsDialogState, 
                                setContextValuesDescriptionsDialogState, 
                                setInputOrOutputValuesDescriptions,
                                curProperties.inputOrOutputValuesDescriptions,
                                textareaValues, 
                                setTextareaValues,
                                getVariables,
                                curProperties,
                            )
                        }>
                            <span>{STRINGS.runbookEditor.nodeEditor.subflowInputNodeEditor.enterParameters}</span>
                        </Button>
                    </div>}
                {hasIntegrationConnectors && <>
                    <tr><td className="font-size-md-large fw-bold pt-2" colSpan={2} data-testid="integration-configuration-header">{STRINGS.runbookEditor.nodeEditor.subflowConnectorContext}</td></tr>
                    <tr><td className="pb-3" colSpan={2}><Callout intent={Intent.PRIMARY}>{STRINGS.runbookEditor.nodeEditor.subflowConnectorContextDesc}</Callout></td></tr>
                    <tr>
                        <td className="font-size-md-large fw-bold pt-2" colSpan={2}>
                            <HTMLSelect
                                data-testid="subflow-input-connector-select"
                                className="selector-min-width"
                                fill={true}
                                options={connectorOptions}
                                defaultValue={curProperties.integrationId || ""}
                                onChange={
                                    async (event) => {
                                        const value = event.currentTarget.value;
                                        const tags = await retrieveTagsForIntegration(integrations, value);
                                        const options = (tags || []).map(el => { return {label: el.value, value: el.key}}).concat(
                                            [{label: STRINGS.runbookEditor.nodeEditor.variables.tags.notSetOption, value: ""}]
                                        );

                                        setAvailableConnectorTags(options);
                                        curProperties.integrationId = value || "";
                                    }
                                }
                            />
                        </td>
                    </tr>
                    { renderIntegrationConfiguration() }
                </>}
            </>}
            {SHOW_CONTEXT && <RunbookContextSummary
                currentProperties={getNodeProperties(curProperties, selectedNode, libraryNode)}
                node={graphDef.nodes.find(node => node.id === selectedNode?.getId())!} graphDef={graphDef}
                showOutputExample={true} showInputExample={true}
            />}
        </>
    )
});

interface ContextValuesDescriptionsDialogProps {
    /** the context value type (input or output). */
	contextValueType: string;
    /** an array containing the runtime variables used for input or output context. */
    contextValues: string[] | undefined;
    /** change handler for the textarea element. */
    onChange: (newDescriptions: Array<SubflowContextValueDescription>) => void;
    /** the current state value for the input or output runtime variables descriptions. */
    currentInputOrOutputValuesDescriptions: Array<SubflowContextValueDescription>;
    /** the values displayed inside the textarea elements which hold the input or output variables descriptions. */
    textareaValues: any;
    /** the handler which set the state values for the values of the input or output variables descriptions. */
    setTextareaValues: Function;
    /** function used to get the object which contains the list of runtime variables. */
    getVariables: Function;
    /** object which holds the properties that are used to store the nodes configurable parameters. */
    curProperties: InputNodeProperties;
}

export enum ALLOWED_VALUES {
	"ANY" = "any",
	"FROM_REGULAR_EXPRESSION" = "from-regular-expression",
    "BETWEEN" = "between",
    "FROM_LIST" = "from-list",
}

const ContextValuesDescriptionsDialog = ({ 
    contextValueType, 
    contextValues, 
    onChange, 
    currentInputOrOutputValuesDescriptions,
    textareaValues, 
    setTextareaValues,
    getVariables,
    curProperties,
}: ContextValuesDescriptionsDialogProps): JSX.Element => {

    const [selectValue, setSelectValue] = useState<string>("");

    const [valueIsRequired, setValueIsRequired] = useState<Array<{
        valueName: string, 
        valueIsRequired: boolean | undefined
    }>>(currentInputOrOutputValuesDescriptions?.map(item => ({
        valueName: item.valueName, 
        valueIsRequired: item.required
    })) || []);

    const [valueIsAdvanced, setValueIsAdvanced] = useState<Array<{
        valueName: string, 
        valueIsAdvanced: boolean | undefined
    }>>(currentInputOrOutputValuesDescriptions?.map(item => ({
        valueName: item.valueName, 
        valueIsAdvanced: item.advanced
    })) || []);

    const [validateWithRegex, setValidateWithRegex] = useState<Array<{
        valueName: string, 
        validateWithRegex: string | undefined
    }>>(currentInputOrOutputValuesDescriptions?.map(item => ({
        valueName: item.valueName, 
        validateWithRegex: item.validateWithRegex
    })) || []);

    const [minValue, setMinValue] = useState<Array<{
        valueName: string, 
        minValue: number | undefined
    }>>(currentInputOrOutputValuesDescriptions?.map(item => ({
        valueName: item.valueName, 
        minValue: item.minValue
    })) || []);
    
    const [maxValue, setMaxValue] = useState<Array<{
        valueName: string, 
        maxValue: number | undefined
    }>>(currentInputOrOutputValuesDescriptions?.map(item => ({
        valueName: item.valueName, 
        maxValue: item.maxValue
    })) || []);

    const [valueType, setValueType] = useState<Array<{
        valueName: string, 
        valueType: string | undefined
    }>>(currentInputOrOutputValuesDescriptions?.map(item => ({
        valueName: item.valueName, 
        valueType: item.valueType
    })) || []);

    const [descriptions, setDescriptions] = 
        useState<Array<SubflowContextValueDescription>>(currentInputOrOutputValuesDescriptions);

    const [staticInputValuesLists, setStaticInputValuesLists] = useState(curProperties.staticInputValuesLists);

    const [newLabels, setNewLabels] = useState({});
    const [newValues, setNewValues] = useState({});
    const [staticValueAdded, setStaticValueAdded] = useState({});
    const [isChangeValue, setIsChangeValue] = useState<string[]>([]);
    const [valuesForEdit, setValuesForEdit] = useState<Array<{name: string, index: number}>>([]);
    const [isValidRegex, setIsValidRegex] = useState<boolean>(true);
    const [activeTab, setActiveTab] = useState<string>("description");
    const [invalidBetween, setInvalidBetween] = useState<boolean>(false);

    const runtimeVariables = getVariables(RUNTIME_SCOPE, false)
    const selectedValueType = runtimeVariables?.primitiveVariables?.find(item => item.name === selectValue)?.type;

    useEffect(() => {
        if (selectValue?.length) {
            const existingOptional = valueIsRequired?.find(item => item.valueName === selectValue)?.valueIsRequired;
            const existingAdvanced = valueIsAdvanced?.find(item => item.valueName === selectValue)?.valueIsAdvanced;
            const existingRegex = validateWithRegex?.find(item => item.valueName === selectValue)?.validateWithRegex;
            const existingMinValue = minValue?.find(item => item.valueName === selectValue)?.minValue;
            const existingMaxValue = maxValue?.find(item => item.valueName === selectValue)?.maxValue;
            const existingValueType = valueType?.find(item => item.valueType === selectValue)?.valueType;

            if (!existingOptional) {
                setValueIsRequired([...valueIsRequired, {
                    valueName: selectValue, 
                    valueIsRequired: false
                }]);
            }
            if (!existingAdvanced) {
                setValueIsAdvanced([...valueIsAdvanced, {
                    valueName: selectValue, 
                    valueIsAdvanced: false
                }]);
            }
            if (!existingRegex) {
                setValidateWithRegex([...validateWithRegex, {
                    valueName: selectValue, 
                    validateWithRegex: ""
                }]);
            }
            if (!existingMinValue) {
                setMinValue([...minValue, {
                    valueName: selectValue, 
                    minValue: undefined
                }]);
            }
            if (!existingMaxValue) {
                setMaxValue([...maxValue, {
                    valueName: selectValue, 
                    maxValue: undefined
                }]);
            }
            if (!existingValueType) {
                setValueType([...valueType, {
                    valueName: selectValue, 
                    valueType: "any"
                }]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectValue]);

    useEffect(() => {
        let newDescriptions = [...descriptions];
        const updateIndex = newDescriptions?.findIndex(item => item.valueName === selectValue);
        if (updateIndex > -1) {
            newDescriptions[updateIndex] = { 
                valueName: selectValue, 
                description: newDescriptions[updateIndex].description,
                required: valueIsRequired?.find(item => item.valueName === selectValue)?.valueIsRequired, 
                advanced: valueIsAdvanced?.find(item => item.valueName === selectValue)?.valueIsAdvanced, 
                validateWithRegex: validateWithRegex?.find(item => item.valueName === selectValue)?.validateWithRegex,
                minValue: minValue?.find(item => item.valueName === selectValue)?.minValue,
                maxValue: maxValue?.find(item => item.valueName === selectValue)?.maxValue,
                valueType: valueType?.find(item => item.valueName === selectValue)?.valueType,
            }
            setDescriptions(newDescriptions);
            onChange(newDescriptions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueIsRequired, valueIsAdvanced, validateWithRegex, minValue, maxValue, valueType]);

    useEffect(() => {
        curProperties.staticInputValuesLists = staticInputValuesLists;
    }, [curProperties, staticInputValuesLists]);

    const onFieldChange = (event, keysToUpdate?) => {
        const newTextareaValues = textareaValues;
        if (event.currentTarget.tagName.toLowerCase() === "textarea") {
            newTextareaValues[selectValue] = event.currentTarget.value;
            setTextareaValues(prev => { return {...prev, ...newTextareaValues}});
        }
        const valueDescriptionExistingIndex = descriptions.findIndex(item => item.valueName === selectValue);
        const valueDescriptionExisting: any = descriptions.find(item => item.valueName === selectValue) || "";
        let newDescriptions = [...descriptions];
        const filteredArray = descriptions.filter(item => item.valueName !== selectValue);
        const updateArray = valueDescriptionExistingIndex > -1 ? filteredArray : descriptions;
        if (valueDescriptionExistingIndex > -1 && event.currentTarget.tagName.toLowerCase() === "textarea") {
            newDescriptions[valueDescriptionExistingIndex].description = event.currentTarget.value;
        }
        newDescriptions = [...updateArray, { 
            valueName: selectValue, 
            description: event.currentTarget.tagName.toLowerCase() === "textarea" ? event.currentTarget.value : valueDescriptionExisting.description,
            required: keysToUpdate?.newValueIsRequired || valueIsRequired?.find(item => item.valueName === selectValue)?.valueIsRequired, 
            advanced: keysToUpdate?.newValueIsAdvanced || valueIsAdvanced?.find(item => item.valueName === selectValue)?.valueIsAdvanced, 
            validateWithRegex: keysToUpdate?.newValidateWithRegex || validateWithRegex?.find(item => item.valueName === selectValue)?.validateWithRegex,
            minValue: keysToUpdate?.newMinValue || minValue?.find(item => item.valueName === selectValue)?.minValue,
            maxValue: keysToUpdate?.newMaxValue || maxValue?.find(item => item.valueName === selectValue)?.maxValue,
            valueType: keysToUpdate?.newValueType || valueType?.find(item => item.valueName === selectValue)?.valueType,
        }];
        if (keysToUpdate?.newValueIsRequired) {
            setValueIsRequired(keysToUpdate.newValueIsRequired);
        }
        if (keysToUpdate?.newValueIsAdvanced) {
            setValueIsAdvanced(keysToUpdate.newValueIsAdvanced);
        }
        if (keysToUpdate?.newValidateWithRegex) {
            setValidateWithRegex(keysToUpdate.newValidateWithRegex);
        }
        if (keysToUpdate?.newMinValue) {
            setMinValue(keysToUpdate.newMinValue);
        }
        if (keysToUpdate?.newMaxValue) {
            setMaxValue(keysToUpdate.newMaxValue);
        }
        if (keysToUpdate?.newValueType) {
            setValueType(keysToUpdate.newValueType);
        }
        setDescriptions(newDescriptions);
        onChange(newDescriptions);
    };

    const handleLabelChange = (variableName, value) => {
        setNewLabels({
            ...newLabels,
            [variableName]: value,
        });
    };
    
    const handleValueChange = (variableName, value) => {
        setNewValues({
            ...newValues,
            [variableName]: value,
        });
    };
    
    const exitOptionEditMode = (variableName) => {
        const newLabel = newLabels[variableName]?.trim();
        const newValue = newValues[variableName]?.trim();
        if (newLabel && newValue) {
            setNewLabels({
                ...newLabels,
                [variableName]: "",
            });
            setNewValues({
                ...newValues,
                [variableName]: "",
            });
        }
        setStaticValueAdded({
            ...staticValueAdded,
            [variableName]: staticValueAdded[variableName] !== undefined ? staticValueAdded[variableName]+1 : 0,
        });
        setIsChangeValue(isChangeValue.filter((item) => item !== variableName));
        setValuesForEdit(
            valuesForEdit.filter((item) => item.name !== variableName),
        );
    };
    
    const handleAddOption = (variableName) => {
        const newLabel = newLabels[variableName]?.trim();
        const newValue = newValues[variableName]?.trim();
        if (newLabel && newValue) {
            const existingValue = valuesForEdit
                ? valuesForEdit.find((item) => item.name === variableName)
                : null;
            const existingValueIndex = existingValue
                ? existingValue.index
                : staticInputValuesLists?.[variableName]?.findIndex(
                      (item) => item.label === newLabel,
                  );
            if (existingValueIndex > -1) {
                staticInputValuesLists[variableName][existingValueIndex].display =
                    `${newLabel} | ${newValue}`;
                staticInputValuesLists[variableName][existingValueIndex].label =
                    newLabel;
                staticInputValuesLists[variableName][existingValueIndex].value =
                    newValue;
                setStaticInputValuesLists({
                    ...staticInputValuesLists,
                    [variableName]: [...staticInputValuesLists[variableName]],
                });
            } else {
                const newOptionObject = {
                    display: `${newLabel} | ${newValue}`,
                    label: newLabel,
                    value: newValue,
                    type: "string",
                };
                setStaticInputValuesLists({
                    ...staticInputValuesLists,
                    [variableName]: [
                        ...(staticInputValuesLists[variableName] || []),
                        newOptionObject,
                    ],
                });
            }
        }
        exitOptionEditMode(variableName);
    };
    
    const renderSuggestItem: ItemRenderer<{ label: string; value: number }> = (item, { handleClick, modifiers }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                key={item.value}
                onClick={handleClick}
                text={item.label}
            />
        );
    };

    const checkRegexIfValid = (str: string): boolean => {
        try {
            new RegExp(str);
            return true;
        } catch (e) {
            return false;
        }
    };

    useEffect(() => {
        if (contextValues?.length) {
            setSelectValue(contextValues[0]);
        }
    }, [contextValues]);

    useEffect(() => {
        if (!!staticInputValuesLists[selectValue]?.length) {
            setValueType([...valueType, {
                valueName: selectValue, 
                valueType: "from-list"
            }]);
            const newValueType = [...valueType];
            const updateIndex = newValueType?.findIndex(item => item.valueName === selectValue);
            if (updateIndex > -1) {
                newValueType[updateIndex].valueType = "from-list";
                onFieldChange({
                    currentTarget: {
                        value: "from-list",
                        tagName: "radio"
                    }
                }, { newValueType });
            }
        }
        // eslint-disable-next-line
    },[selectValue]);

    useEffect(() => {
        const betweenLeft = minValue?.find(item => item.valueName === selectValue)?.minValue;
        const betweenRight = maxValue?.find(item => item.valueName === selectValue)?.maxValue;
        const regex = validateWithRegex?.find(item => item.valueName === selectValue)?.validateWithRegex;
    
        if (betweenLeft && betweenRight && Number(betweenLeft) > Number(betweenRight)) {
            setInvalidBetween(true);
        } else {
            setInvalidBetween(false);
        }

        if (regex && !checkRegexIfValid(regex)) {
            setIsValidRegex(false);
        } else {
            setIsValidRegex(true);
        }

    }, [minValue, maxValue, selectValue, valueType, validateWithRegex]);

    useEffect(() => {
        const saveAndCloseBtn: HTMLButtonElement | null = document.querySelector(`.edit-descriptions-dialog .${Classes.INTENT_SUCCESS}`);
        if (
            ((valueType?.find(item => item.valueName === selectValue)?.valueType === ALLOWED_VALUES.FROM_REGULAR_EXPRESSION) && !isValidRegex) || 
            ((valueType?.find(item => item.valueName === selectValue)?.valueType === ALLOWED_VALUES.BETWEEN) && invalidBetween)
        ) {
            if (saveAndCloseBtn) {
                saveAndCloseBtn.disabled = true;
            }
        } else if (saveAndCloseBtn) {
            saveAndCloseBtn.disabled = false;
        }
    }, [isValidRegex, invalidBetween, selectValue, valueType]);

    return <>
        <div className="d-flex align-items-md-center mb-3">
            <div className="me-3">{
                STRINGS.formatString(STRINGS.runbookEditor.nodeEditor.subflowInputNodeEditor.enterDescriptionsModal.enterForSelected, 
                contextValueType)
            }</div>
            <div>
                <HTMLSelect
                    className="selector-min-width"
                    fill={true}
                    options={
                        [{label: STRINGS.formatString(
                            STRINGS.runbookEditor.nodeEditor.subflowInputNodeEditor.enterDescriptionsModal.select, 
                            _.startCase(contextValueType)
                        ), value: ""}].concat(
                            contextValues?.map((value) => {return {label: value, value};}) || []
                        )
                    }
                    value={selectValue}
                    onChange={
                        (event) => {
                            setSelectValue(event.target.value);
                            setActiveTab("description");
                        }
                    }
                    disabled={
                        ((valueType?.find(item => item.valueName === selectValue)?.valueType === ALLOWED_VALUES.BETWEEN) && invalidBetween) ||
                        ((valueType?.find(item => item.valueName === selectValue)?.valueType === ALLOWED_VALUES.FROM_REGULAR_EXPRESSION) && !isValidRegex)
                    }
                />
            </div>
        </div>
        {selectValue && <TabbedSubPages 
            className="subflow-input-node-editor-tabs" 
            selectedTabId={activeTab}
            onTabChange={setActiveTab}
        >
            <Tab id="description" title="Description" className="pt-3">
                <div className="row">
                    <div className="col-md-12">
                        <textarea 
                            className="w-100 bg-white text-black" 
                            placeholder={STRINGS.formatString(
                                STRINGS.runbookEditor.nodeEditor.subflowInputNodeEditor.enterDescriptionsModal.placeholder, 
                                contextValueType
                            )}
                            value={textareaValues?.[selectValue] || ""} 
                            onChange={(event) => {
                                onFieldChange(event);
                            }}
                            disabled={!selectValue}
                        />
                    </div>
                </div>
            </Tab>
            {contextValueType === "input" && <Tab id="value" title="Value" className="pt-3">
                <div className="row mt-2 mb-3">
                    <div className="col-md-3">
                        <Switch
                            label={STRINGS.runbookEditor.nodeEditor.subflowInputNodeEditor.enterDescriptionsModal.required}
                            inline={true}
                            className='mb-0'
                            checked={valueIsRequired?.find(item => item.valueName === selectValue)?.valueIsRequired}
                            onChange={(event) => {
                                const newValueIsRequired = [...valueIsRequired];
                                const updateIndex = newValueIsRequired?.findIndex(item => item.valueName === selectValue);
                                if (updateIndex > -1) {
                                    newValueIsRequired[updateIndex].valueIsRequired = event.currentTarget.checked;
                                    onFieldChange(event, { newValueIsRequired });
                                }
                            }}
                            disabled={valueIsAdvanced?.find(item => item.valueName === selectValue)?.valueIsAdvanced}
                        />
                    </div>
                    <div className="col-md-3">
                        <Switch
                            label={STRINGS.runbookEditor.nodeEditor.subflowInputNodeEditor.enterDescriptionsModal.advanced}
                            inline={true}
                            className='mb-0'
                            checked={!valueIsRequired?.find(item => item.valueName === selectValue)?.valueIsRequired && valueIsAdvanced?.find(item => item.valueName === selectValue)?.valueIsAdvanced}
                            onChange={(event) => {
                                const newValueIsAdvanced = [...valueIsAdvanced];
                                const updateIndex = newValueIsAdvanced?.findIndex(item => item.valueName === selectValue);
                                if (updateIndex > -1) {
                                    newValueIsAdvanced[updateIndex].valueIsAdvanced = event.currentTarget.checked;
                                    onFieldChange(event, { newValueIsAdvanced });
                                }
                            }}
                            disabled={valueIsRequired?.find(item => item.valueName === selectValue)?.valueIsRequired}
                        />
                    </div>
                </div>
                {["string", "integer", "float", "ipaddr", "boolean"].includes(selectedValueType) && <p className="mt-4 mb-2">{STRINGS.runbookEditor.nodeEditor.subflowInputNodeEditor.enterDescriptionsModal.allowedValues}</p>}
                <RadioGroup
                    name="allowedValues"
                    onChange={event => {
                        const newValueType = [...valueType];
                        const updateIndex = newValueType?.findIndex(item => item.valueName === selectValue);
                        if (updateIndex > -1) {
                            newValueType[updateIndex].valueType = event.currentTarget.value;
                            onFieldChange(event, { newValueType });
                        }
                    }}
                    selectedValue={valueType?.find(item => item.valueName === selectValue)?.valueType || "any"}
                    className="align-self-center"
                >
                    <Radio
                        label={STRINGS.runbookEditor.nodeEditor.subflowInputNodeEditor.enterDescriptionsModal.any}
                        value={ALLOWED_VALUES.ANY}
                        className={"mb-2" + (["string", "integer", "float", "ipaddr", "boolean"].includes(selectedValueType) ? "" : " d-none")}
                    />
                    <Radio
                        label={STRINGS.runbookEditor.nodeEditor.subflowInputNodeEditor.enterDescriptionsModal.fromRegularExpression}
                        value={ALLOWED_VALUES.FROM_REGULAR_EXPRESSION}
                        className={"mb-2 me-2" + (["string", "ipaddr"].includes(selectedValueType) ? " d-inline" : " d-none")}
                    />
                    {["string", "ipaddr"].includes(selectedValueType) && <div className="d-inline-flex mb-2"><input 
                        style={{maxWidth: "100%", width: "305px"}} 
                        type="text"
                        onChange={(event) => {
                            const newValidateWithRegex = [...validateWithRegex];
                            const updateIndex = newValidateWithRegex?.findIndex(item => item.valueName === selectValue);
                            if (updateIndex > -1) {
                                newValidateWithRegex[updateIndex].validateWithRegex = event.currentTarget.value;
                                onFieldChange(event, { newValidateWithRegex });
                            }
                            setIsValidRegex(checkRegexIfValid(event.currentTarget.value));
                        }}
                        value={validateWithRegex?.find(item => item.valueName === selectValue)?.validateWithRegex || ""}
                    ></input></div>}
                    <Radio
                        label={STRINGS.runbookEditor.nodeEditor.subflowInputNodeEditor.enterDescriptionsModal.between}
                        value={ALLOWED_VALUES.BETWEEN}
                        className={"mb-2 me-2" + (["integer", "float"].includes(selectedValueType) ? " d-inline" : " d-none")}
                    />
                    {["integer", "float"].includes(selectedValueType) && <div className="d-inline-flex mb-2"><input 
                        style={{maxWidth: "70px", textAlign: "right"}} 
                        onChange={(event) => {
                            const newMinValue = [...minValue];
                            const updateIndex = newMinValue?.findIndex(item => item.valueName === selectValue);
                            if (updateIndex > -1) {
                                newMinValue[updateIndex].minValue = +event.currentTarget.value;
                                onFieldChange(event, { newMinValue });
                            }
                        }}
                        type="number"
                        className="me-2"
                        value={minValue?.find(item => item.valueName === selectValue)?.minValue ?? ""}
                    ></input><div className="me-2">and</div><input 
                        style={{maxWidth: "70px", textAlign: "right"}} 
                        onChange={(event) => {
                            const newMaxValue = [...maxValue];
                            const updateIndex = newMaxValue?.findIndex(item => item.valueName === selectValue);
                            if (updateIndex > -1) {
                                newMaxValue[updateIndex].maxValue = +event.currentTarget.value;
                                onFieldChange(event, { newMaxValue });
                            }
                        }}
                        type="number"
                        value={maxValue?.find(item => item.valueName === selectValue)?.maxValue ?? ""}
                    ></input></div>}
                    <Radio
                        label={STRINGS.runbookEditor.nodeEditor.subflowInputNodeEditor.enterDescriptionsModal.fromList}
                        value={ALLOWED_VALUES.FROM_LIST}
                        className={"mb-2" + (["integer", "float", "string", "ipaddr", "boolean"].includes(selectedValueType) ? "" : " d-none")}
                    />
                    {["integer", "float", "string", "ipaddr", "boolean"].includes(selectedValueType) && <><div className="static-input-values">
                        <div className="d-flex pb-2">
                            <Suggest
                                key={staticValueAdded[selectValue]}
                                className="me-2"
                                items={
                                    staticInputValuesLists[selectValue]?.length 
                                    ? staticInputValuesLists[selectValue].map(item => ({ label: item.label, value: item.label })) 
                                    : []
                                }
                                itemRenderer={renderSuggestItem}
                                onItemSelect={(item: any)=>{
                                    const label = item.label;
                                    const existingLabelIndex = staticInputValuesLists[selectValue]?.findIndex(item => item.label === label);
                                    handleLabelChange(selectValue, label);
                                    const newValuesForEdit = [...valuesForEdit];
                                    const findIndex = newValuesForEdit.findIndex(item => item.name === selectValue);
                                    if (findIndex > -1) {
                                        newValuesForEdit[findIndex].index = existingLabelIndex;
                                        setValuesForEdit(newValuesForEdit);
                                    } else {
                                        setValuesForEdit([...valuesForEdit, {
                                            name: selectValue,
                                            index: existingLabelIndex,
                                        }]);
                                    }
                                    handleValueChange(selectValue, staticInputValuesLists[selectValue].find(item => item.label === label).value);
                                    setIsChangeValue(prevState => [...prevState, selectValue]);
                                }}
                                inputValueRenderer={() => newLabels[selectValue] || ""}
                                inputProps={{
                                    placeholder: STRINGS.runbookEditor.nodeEditor.subflowInputNodeEditor.enterLabel
                                }}
                                onQueryChange={(query) => {
                                    handleLabelChange(selectValue, query);
                                }}
                                popoverProps={{
                                    usePortal: false
                                }}
                            />
                            {(selectedValueType !== "boolean") && <InputGroup
                                className="flex-grow-1 me-2"
                                placeholder={STRINGS.runbookEditor.nodeEditor.subflowInputNodeEditor.enterValue}
                                value={newValues[selectValue] || ""}
                                onChange={(e) => handleValueChange(selectValue, e.target.value)}
                                type={["integer", "float"].includes(selectedValueType) ? "number" : "text"}
                            />}
                            {(selectedValueType === "boolean") && <select
                                className="flex-grow-1 me-2"
                                value={newValues[selectValue] || ""}
                                onChange={(e) => handleValueChange(selectValue, e.target.value)}
                            >
                                <option value="">Select</option>
                                <option value="true" disabled={staticInputValuesLists[selectValue]?.find(item => item.value === "true") ? true : false}>true</option>
                                <option value="false" disabled={staticInputValuesLists[selectValue]?.find(item => item.value === "false") ? true : false}>false</option>
                            </select>}
                            <Button 
                                intent="primary" 
                                disabled={!newLabels[selectValue] || !newValues[selectValue]} 
                                text={
                                    isChangeValue.includes(selectValue) 
                                    ? STRINGS.runbookEditor.nodeEditor.subflowInputNodeEditor.changeValue 
                                    : STRINGS.runbookEditor.nodeEditor.subflowInputNodeEditor.addValue
                                } 
                                style={{marginLeft: "auto"}} 
                                onClick={() => { 
                                    handleAddOption(selectValue);
                                }} />
                        </div>
                        {!!staticInputValuesLists[selectValue]?.length && <div className="pb-2 add-subflow-static-value">
                            <MultiSelectInput
                                key={staticValueAdded[selectValue]}
                                sortable
                                items={staticInputValuesLists[selectValue]}
                                selectedItems={staticInputValuesLists[selectValue]}
                                onChange={ updatedValues => {
                                    setStaticInputValuesLists({
                                        ...staticInputValuesLists,
                                        [selectValue]: updatedValues,
                                    });
                                    if (!updatedValues?.length) {
                                        exitOptionEditMode(selectValue);
                                    }
                                } }
                                placeholder={STRINGS.runbookEditor.nodeEditor.subflowInputNodeEditor.addedValues}
                                valuesForEdit={valuesForEdit}
                                staticInputValuesLists={staticInputValuesLists}
                                variableName={selectValue}
                                exitOptionEditMode={exitOptionEditMode}
                            />
                        </div>}
                    </div></>}
                </RadioGroup>               
                <div className="text-danger validation-error mt-2">
                    {(valueType?.find(item => item.valueName === selectValue)?.valueType === ALLOWED_VALUES.BETWEEN) && invalidBetween && STRINGS.runbookEditor.nodeEditor.subflowInputNodeEditor.enterDescriptionsModal.invalidBetween}
                    {(valueType?.find(item => item.valueName === selectValue)?.valueType === ALLOWED_VALUES.FROM_REGULAR_EXPRESSION) && !isValidRegex && STRINGS.runbookEditor.nodeEditor.subflowInputNodeEditor.enterDescriptionsModal.invalidRegularExpression}
                </div>
            </Tab>}
        </TabbedSubPages>}
    </>
};

/**
 * Get the list of available connector tags for the given integration
 * 
 * @param integrations 
 * @param integration 
 * @returns 
 */
async function retrieveTagsForIntegration(integrations: RunbookIntegrationDetails[], integration: string) {
    if (!integrations) {
        return []
    }

    const installedIntegration = integrations.find(el => el.id === integration);
    const integrationDetails = await IntegrationLibraryService.getAvailableIntegration(installedIntegration?.id, installedIntegration?.version);
    const tags = integrationDetails?.connector?.tags;

    return tags;
}

/** Function for opening the enter descriptions dialog.
 *  @param contextValueType - the context value type (input or output).
 *  @param contextValues - an array containing the runtime variables used for input or output context.
 *  @param contextValuesDescriptionsDialogState - the descriptions dialog state value.
 *  @param setContextValuesDescriptionsDialogState - the descriptions dialog state setter. 
 *  @param setInputOrOutputValuesDescriptions - state setter for the input or output runtime variables descriptions. 
 *  @param currentInputOrOutputValuesDescriptions - the current state value for the input or output runtime variables descriptions.
 *  @param textareaValues - the values displayed inside the textarea elements which hold the input or output variables descriptions.
 *  @param setTextareaValues - the handler which set the state values for the values of the input or output variables descriptions.
 *  @param getVariables - function used to get the object which contains the list of runtime variables. 
 *  @param curProperties - object which holds the properties that are used to store the nodes configurable parameters. */
function openContextValuesDescriptionsDialog(
    contextValueType: string, 
    contextValues: string[] | undefined, 
    contextValuesDescriptionsDialogState: any, 
    setContextValuesDescriptionsDialogState: React.Dispatch<any>, 
    setInputOrOutputValuesDescriptions: React.Dispatch<React.SetStateAction<SubflowContextValueDescription[]>>, 
    currentInputOrOutputValuesDescriptions: Array<SubflowContextValueDescription>,
    textareaValues, 
    setTextareaValues,
    getVariables,
    curProperties,
): void {
    const defaultValue = null;
    let descriptions: Array<SubflowContextValueDescription> = defaultValue || [];
    setContextValuesDescriptionsDialogState({
      showDialog: true,
      doNotAllowOutsideClick: true,
      title: STRINGS.formatString(
        STRINGS.runbookEditor.nodeEditor.subflowInputNodeEditor
          .enterDescriptionsModal.title,
        _.startCase(contextValueType),
      ),
      dialogContent: (
        <>
          <ContextValuesDescriptionsDialog
            contextValueType={contextValueType}
            contextValues={contextValues}
            currentInputOrOutputValuesDescriptions={currentInputOrOutputValuesDescriptions}
            onChange={(value: Array<SubflowContextValueDescription>) => {
                descriptions = value;
            }}
            textareaValues={textareaValues}
            setTextareaValues={setTextareaValues}
            getVariables={getVariables}
            curProperties={curProperties}
          />
        </>
      ),
      closeable: true,
      dialogFooter: (
        <div className="d-flex justify-content-end flex-grow-1">
          <Button
            className="ms-0"
            text={STRINGS.runbookEditor.discardAndCloseBtn}
            intent={Intent.DANGER}
            onClick={() => {
              setContextValuesDescriptionsDialogState({
                ...contextValuesDescriptionsDialogState,
                showDialog: false,
                closeable: true,
              });
            }}
          />
          <Button
            icon={IconNames.SAVED}
            text={STRINGS.runbookEditor.saveAndCloseBtn}
            intent={Intent.SUCCESS}
            onClick={() => {
                if (descriptions?.length) {
                    descriptions.forEach((item, _index) => {
                        if (!item?.valueType || item?.valueType === ALLOWED_VALUES.ANY) {
                            if (curProperties?.staticInputValuesLists?.[item.valueName]) {
                                curProperties.staticInputValuesLists[item.valueName] = [];
                            }
                            
                        }
                    });
                }
                setInputOrOutputValuesDescriptions(descriptions);
                setContextValuesDescriptionsDialogState(
                    { ...contextValuesDescriptionsDialogState, showDialog: false, closeable: true }
                );
            }}
          />
        </div>
      ),
    } as DialogState);
  }
