import { useState } from 'react';
import { PageHeader as PageHeaderLayout } from 'components/common/layout/page-header/PageHeader';
import type { PageHeaderProps as PageHeaderLayoutProps } from 'components/common/layout/page-header/PageHeader';
import { GlobalFilters } from 'components/sdwan/controls/global-filters/GlobalFilters';
import { TimeRangeSelector } from 'components/common/time-range-selector/TimeRangeSelector';
import type { FILTER_NAME } from "components/sdwan/enums/filters.ts";
import { Button, Intent } from '@blueprintjs/core';
import { Icon, IconNames } from '@tir-ui/react-components';

export interface PageHeaderProps extends PageHeaderLayoutProps {
    /** This flag controls if time bar should be displayed in header (defaults to true) */
    showTimeBar?: boolean;
    /** This flag controls if global filtering controls should be present in header (defaults to true) */
    showGlobalFilters?: boolean;
    showAlerts?: boolean;
    disabledGlobalFilters?: FILTER_NAME[];
    additionalSupportedFilters?: FILTER_NAME[];
    hiddenGlobalFilters?: FILTER_NAME[];
}

function PageHeader ({
    showTimeBar = true,
    showGlobalFilters = false,
    showAlerts = false,
    disabledGlobalFilters,
    additionalSupportedFilters,
    hiddenGlobalFilters,
    rightAlignedControls,
    leftAlignedControls,
    controlsToLeftOfTitle,
    ...headerLayoutProps
}: PageHeaderProps) {
    const rightAlignComponents = <>
        { showTimeBar && <TimeRangeSelector/> }
        {/* {showAlerts && <Alerts className="ms-3"/>} Enable this when alerts becomes available */}
    </>;

    const [hasUsableFilters, setHasUsableFilters] = useState(false);
    const [showFilterRow, setShowFilterRow] = useState(false);
    const [activeFilterCount, setActiveFilterCount] = useState(0);
    const hasFilters = activeFilterCount > 0;

    const filterButton = showGlobalFilters && hasUsableFilters && <Button
            small
            active={showFilterRow}
            icon={<Icon icon={IconNames.FILTER}/>}
            className={"filter-toggle-button ms-2" + (hasFilters ? " has-filters" : "")}
            text={hasFilters && activeFilterCount}
            intent={hasFilters ? Intent.SUCCESS : Intent.NONE}
            onClick={() => setShowFilterRow(!showFilterRow)}
        />;
    return (<PageHeaderLayout {...headerLayoutProps}
        controlsToLeftOfTitle={controlsToLeftOfTitle}
        rightAlignedControls={rightAlignComponents && rightAlignedControls ? <>{rightAlignedControls}{rightAlignComponents}</> : (rightAlignedControls || rightAlignComponents)}
        leftAlignedControls={filterButton && leftAlignedControls ? <>{filterButton}{leftAlignedControls}</> : (leftAlignedControls || filterButton)}
    >
        {showGlobalFilters && 
            <GlobalFilters
                className="mt-2 ps-1"
                hidden={!showFilterRow}
                disabledFilters={disabledGlobalFilters}
                additionalSupportedFilters={additionalSupportedFilters}
                hiddenFilters={hiddenGlobalFilters}
                onFilterCountChange={setActiveFilterCount}
                onUsableFiltersChange={setHasUsableFilters}
            />
        }
    </PageHeaderLayout>);
}

export { PageHeader };
