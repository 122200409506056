import type React from 'react';
import classNames from 'classnames';
import { Classes, Divider } from '@blueprintjs/core';
import { InputField } from "components/common/form/InputField.tsx";
import { SelectField } from 'components/common/form/SelectField.tsx';
import { Form } from 'components/common/form/Form.tsx';
import { gatewayTypeOptions } from 'utils/services/DataSourceApiService.ts';
import { STRINGS } from 'app-strings';

export interface IBasicDetailsPanelProps {
	gatewayNameValue: string;
	gatewayTypeValue: string;
	onChangeGatewayName: (event: React.FormEvent<HTMLInputElement>) => void;
	onChangeGatewayType: (event: React.FormEvent<HTMLInputElement>) => void;
}

const BasicDetailsPanel: React.FC<IBasicDetailsPanelProps> = (props) => {
	return (
		<div className={classNames(Classes.DIALOG_BODY)}>
			<p>
				<b>
					{
						STRINGS.DATA_SOURCES.addEdgeGatewayDialog.panels
							.basicDetails.title
					}
				</b>
			</p>
			<Divider />
			<br />
			<Form
				initialValues={{
					gateway_name: props.gatewayNameValue,
					gateway_type: props.gatewayTypeValue,
				}}
				loading={false}
			>
				<InputField
					name="gateway_name"
					type="text"
					required={true}
					className="input-field"
					label={
						STRINGS.DATA_SOURCES.addEdgeGatewayDialog.panels
							.basicDetails.fields.gatewayName
					}
					onChange={props.onChangeGatewayName}
					disabled={false}
				/>
				<SelectField
					label={
						STRINGS.DATA_SOURCES.addEdgeGatewayDialog.panels
							.basicDetails.fields.gatewayType
					}
					name="gateway_type"
					required={true}
					options={[STRINGS.DATA_SOURCES.addEdgeGatewayDialog.panels
						.basicDetails.fields.chooseType].concat(Object.values(gatewayTypeOptions))}
					onChange={props.onChangeGatewayType}
					value={props.gatewayTypeValue && props.gatewayTypeValue}
				/>
			</Form>
		</div>
	);
};

export { BasicDetailsPanel };
