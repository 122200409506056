/** This module contains a data structure that is used to defined the variable react 
 *  context.  This is used to share variables amongst the React components.
 *  @module
 */

import { createContext } from "react";
import { VariableCollection } from "utils/runbooks/VariablesUtils.ts";

export const VariableContext = createContext(
    {
        getVariables: (scope, builtIn?): VariableCollection => {return {primitiveVariables: [], structuredVariables: []}}, 
        setVariables: (scope, data?) => {}, 
        syncRuntimeOrSubflowVariables: (scope, data?) => {}
    }
);
