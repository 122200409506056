mutation AddCustomPropertyValueToNetworkDevices($valueId: ID!, $filter: NetworkDeviceBulkFilterType, $search: String, $searchFields: [NetworkDeviceSearchFieldEnum]
) {
    addCustomPropertyValueToNetworkDevices(
        valueId: $valueId
        filter: $filter
        search: $search
        searchFields: $searchFields
    ) {
        modifiedResourcesCount
        ignoredResourcesCount
    }
}
