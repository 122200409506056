query OnDemandRunbooks(
    $filter: OnDemandRunbookFilterInputType
    $top: Int
    $skip: Int
    $order: [OnDemandRunbookSortInput!]
) {
    onDemandRunbooks(filter: $filter, top: $top, skip: $skip, order: $order) {
        page {
            id
            name
        }
        info {
            totalCount
        }
    }
}
