/** This module contains the component for displaying a dashboard widget.  The dashboard widget displays
 *      any visualization that is to be displayed in the dashboard.
 *  @module
 */

import React, { useState, useEffect, useRef, useContext } from "react";
import { Icon, IconNames } from "@tir-ui/react-components";
import { INVOCATION_TYPE, RunbookConfig, RunbookInputs, RunbookNode } from "utils/services/RunbookApiService";
import { Button } from "@blueprintjs/core";
import { STRINGS } from "app-strings";
import CreateRunbookView from "pages/create-runbook/views/create-runbook/CreateRunbookView";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BasicDialog, updateDialogState } from "components/common/basic-dialog/BasicDialog";
import { INTERACTION_TYPE, WIDGET_TYPE, Widget } from "components/common/vis-framework/widget/Widget.type";
import { createWidget } from "components/common/vis-framework/widget/WidgetFactory";
import { LAYOUT_TYPE } from "components/common/vis-framework/layout/Layout.type";
import {
    COLUMN_TYPE, Column, DEBUG_OUTPUT_TYPE, DataPointKeys, DataSet, DebugOutput, RUNBOOK_STATUS, RunbookOutput 
} from "pages/riverbed-advisor/views/runbook-view/Runbook.type";
import { GenericKey, NodeUtils } from "utils/runbooks/NodeUtil";
import { createDataset, getLimitFromFilters, getMaxLimitByType, getMetrics } from "utils/runbooks/RunbookFakeDataUtils";
import apiSpec from "components/common/graph/editors/data-ocean/data_ocean_ui_library_mock.json";
import { DataOceanMetadata } from "components/common/graph/editors/data-ocean/DataOceanMetadata.type";
import DashboardWidgetConfigDialog from "./DashboardWidgetConfigDialog";
import { DashboardUtils } from "pages/dashboards/DashboardUtils";
import DashboardNodeLibrary from "pages/create-runbook/views/create-runbook/dashboard_node_library.json";
import { NodeLibrary, NodeLibrarySpec } from "pages/create-runbook/views/create-runbook/NodeLibrary";
import { 
    dataOceanNodes, getFirstParentOfType, getTriggerNodes, isAggregatorNode, isDataOceanNode, isTransformNode, 
    processDataset, runbookService, transformNodes 
} from "utils/runbooks/RunbookUtils";
import { Query } from "reporting-infrastructure/types/Query";
import { useQuery } from "utils/hooks/useQuery.ts";
import { useQueryParams } from "utils/hooks/useQueryParams.ts";
import { FILTER_NAME } from "components/sdwan/enums/filters.ts";
import { PARAM_NAME } from "components/enums/QueryParams";
import { AuthService } from "@tir-ui/azure-auth";
import { getUuidV4, getUuidV5 } from "utils/unique-ids/UniqueIds";
import { SEVERITY } from "components/enums/Severity.ts";
import { translateDatasetSchema } from "pages/riverbed-advisor/views/runbook-view/RunbookView";
import { InputType, Variant } from "components/common/graph/types/GraphTypes";
import { NAMESPACE_MAP } from "pages/runbook-invocations/views/runbook-invocations-list/RunbookInputsForm";
import { AutoUpdateContext } from "pages/dashboards/DashboardsPage";
import { AggregateNodeUtil } from "components/common/graph/editors/aggregator/AggregatorNodeUtils";
import { TransformNodeUtils } from "components/common/graph/editors/transform/TransformNodeUtils";
import { InfoContent } from "pages/riverbed-advisor/views/runbook-view-container/InfoContent";
import { JsonViewer } from "pages/incident-details/views/primary-indicator/JsonViewer";
import type { DashboardWidgetConfig } from "pages/dashboards/Dashboard.type";
import RUNBOOKS_QUERY from 'pages/riverbed-advisor/views/runbook-view/runbooks.graphql';
import "./DashboardWidget.scss";

// The height of the widget card
const CARD_WIDTH: string | undefined = "700px";
// The height of the widget card
const CARD_HEIGHT: string | undefined = "400px";

// This is the time to wait between status checks in milliseconds, if you want to know the total length of time 
// that we will wait for a runbook to generate it is maxStatusChecks*statusTimeIncrement.
const statusTimeIncrement = 2 * 1000;

// The number of times to check to see if the runbook has completed
const initMaxStatusChecks = 150;

/** this type defines an internal status object that is used to display debug information below the runbook. */
type RunbookStatus = {
    /** a string with the current status of the runbook run. */
    status: string;
    /** an enum with the current state. */
    statusValue: STATUS_VALUE;
    /** a string with the information passed back from the orchestrator when the runbook runs. */
    orchestratorInfo: string | null;
    /** a string with any errors encountered while invoking the orchestrator or null if none. */
    orchestratorError: string | null;
}

/** an enum with the supported status values. */
enum STATUS_VALUE {
    STARTING = "STARTING",
    INITIALIZING = "INITIALIZING",
    RUNNING = "RUNNING",
    ERROR = "ERROR",
    SUCCEEDED = "SUCCEEDED",
    NO_DATA = "NO_DATA",
    TIMEOUT = "TIMEOUT"
}

/** this interface defines the properties passed into the DashboardWidget React component. */
export interface DashboardWidgetProps {
    /** the configuration of this current dashboard widget. */
    config: DashboardWidgetConfig;
    /** the list of all configured widgets, this is needed to setup interactions. */
    widgets: DashboardWidgetConfig[];
    /** the current DASHBOARD_TYPE. */
    layout: LAYOUT_TYPE;
    /** a boolean value, true if the dashboard is being edited. */
    isEditing: boolean;
    /** notifies any listeners about a widget edit action. */
    notifyWidgetEditAction?: (type: WidgetEditAction, value?: any) => void;
    /** the handler for interaction events. */
    notifyInteraction?: (type: INTERACTION_TYPE, data: any[]) => void;
}

/** this interface defines the functions available in the dashboard widget ref. */
export interface DashboardWidgetRef {
    /** toggles the edit widget functionality. */
    editWidget: (edit: boolean) => void;
    /** the notification for when new runbook input is received. */
    notifyNewRunbookInput: (entities: any[]) => void;
    /** returns the id of the widget. */
    getId: () => string;
}

/** this enum specifies all the supported widget edit action types.  The widget edit action type is passed
 *  to the handler for widget edit actions.*/
export enum WidgetEditAction {
    /** this enumerated type specifies that the user modified the widget's configuration. */
    CONFIG_CHANGE = "config_change",
    /** this enumerated type specifies that the user deleted the widget. */
    DELETE = "delete_widget",
}

/** Renders the dashboard widget.
 *  @param props the properties passed in.
 *  @returns JSX with the dashboard widget component.*/
const DashboardWidget = React.forwardRef<DashboardWidgetRef, DashboardWidgetProps>((props: DashboardWidgetProps, ref: any): JSX.Element => {
    let { params } = useQueryParams({ listenOnlyTo: [PARAM_NAME.debug] });
    const showDebugInformation = params[PARAM_NAME.debug] === "true";

    const initDialogState = { showDialog: false, title: STRINGS.viewDashboard.debugDialogTitle, loading: false, dialogContent: <></>, dialogFooter: <></> };
    const [dialogState, setDialogState] = useState<any>(initDialogState);
    const [passedEntities, setPassedEntities] = useState<any[]>([]);
    //const [edit, setEdit] = useState<boolean>(false);
    //const editCached = useRef<boolean>(edit);

    useEffect(() => {
        ref({
            editWidget: (edit: boolean): void => {
                //editCached.current = !editCached.current;
                //setEdit(editCached.current);
            },
            notifyNewRunbookInput: (entities: any[]): void => {
                setPassedEntities(entities);
                if (runbookQueryParams.current) {
                    runbookQueryParams.current = undefined;
                    setStatusCount(0);
                    setStatus({ status: STRINGS.viewRunbooks.startingStatus, statusValue: STATUS_VALUE.STARTING, orchestratorInfo: null, orchestratorError: null });
                }
                setLoadingState(!props.isEditing);
            },
            getId: (): string => {
                return props.config.id;
            }
        });
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const [status, setStatus] = useState<RunbookStatus>({ status: STRINGS.viewRunbooks.startingStatus, statusValue: STATUS_VALUE.STARTING, orchestratorInfo: null, orchestratorError: null });
    const [loading, setLoadingState] = useState<boolean>(false);
    const runbookQueryParams = useRef<any>(undefined);

    const autoUpdate = useContext(AutoUpdateContext);

    // We want to create one runbook using the runbook API per widget
    const creatingQuery = useRef<boolean>(false);
    useEffect(() => {
        const queryRunbook = async () => {
            try {
                const runbooks = await runbookService.getRunbooks(Variant.INCIDENT, true);
                const runbooksByName: Record<string, RunbookConfig> = {};
                for (const runbook of runbooks) {
                    runbooksByName[runbook.name || "Unknown"] = runbook;
                }
                if (props.config?.runbookConfig) {
                    //setLoadingState(true);

                    // Create the updated runbook
                    const runbookToRun: RunbookConfig = DashboardUtils.appendVisualizationNodeToRunbookConfig(
                        { ...props.config.runbookConfig, name: "Dashboard-" + props.config.id }, props.config.widgetType,
                        props.config.options
                    );
                    // The runbook orchestrator does not know about input nodes so change it to a trigger node, they
                    // are essentially the same.
                    if (runbookToRun?.nodes?.length) {
                        for (const node of runbookToRun.nodes) {
                            if (node.type === "input") {
                                //alert("Updating type");
                                node.type = "trigger";
                                break;
                            }
                        }
                    }

                    if (!runbooksByName["Dashboard-" + props.config?.id]) {
                        const response = await runbookService.saveRunbook(runbookToRun);
                        runbooksByName["Dashboard-" + props.config?.id] = response;
                    } else {
                        const id = runbooksByName["Dashboard-" + props.config?.id].id || "";
                        const eTag = runbooksByName["Dashboard-" + props.config?.id].eTag;
                        const response = await runbookService.updateRunbook(id, runbookToRun, eTag);
                        runbookToRun.id = id;
                        runbookToRun.eTag = (response as any).eTag;
                        runbooksByName["Dashboard-" + props.config?.id] = runbookToRun;
                    }


                    if (runbooksByName["Dashboard-" + props.config?.id]) {
                        // Run the runbook
                        const runbook: RunbookConfig = runbooksByName["Dashboard-" + props.config?.id];
                        let tenantId = "";
                        try {
                            tenantId = AuthService.getTenantId();
                        } catch (error) {
                            tenantId = "";
                        }
                        const input: RunbookInputs = {
                            "utid": tenantId,
                            "runbookId": runbook.id,
                            "incidentId": getUuidV4(),
                            "triggerId": getUuidV4(),
                            "triggerMode": {
                                "manualRun": true,
                                "source": INVOCATION_TYPE.test,
                                "feature": "dashboard"
                            },
                            "detection": {
                                "id": getUuidV4(),
                                // Put back
                                //"entity": entity,
                                //"inputs": inputs,
                                //"object": object
                                // put back
                                //primaryIndicator
                            },
                            "duration": "900.000000000"
                        };
                        if (autoUpdate.time) {
                            input.endTime = (autoUpdate.time / 1000).toString();
                        }

                        const triggerNodes = getTriggerNodes(runbook.nodes);
                        if (triggerNodes?.length === 1) {
                            const triggerType = triggerNodes[0].properties.triggerType;
                            switch (triggerType) {
                                case InputType.INTERFACE:
                                    const ifc = passedEntities.length ? passedEntities[0].network_interface : triggerNodes[0].properties.entities.network_interface;
                                    if (ifc) {
                                        input.detection.entity = { kind: "network_interface", attributes: ifc };
                                    }
                                    break;
                                case InputType.DEVICE:
                                    const device = passedEntities.length ? passedEntities[0].network_device : triggerNodes[0].properties.entities.network_device;
                                    if (device) {
                                        input.detection.entity = { kind: "network_device", attributes: device };
                                    }
                                    break;
                                case InputType.APPLICATION_LOCATION: {
                                    const application = passedEntities.length ? passedEntities[0].application : triggerNodes[0].properties.entities.application;
                                    const location = triggerNodes[0].properties.entities.location;
                                    if (application && location) {
                                        let uuid = "";
                                        if (application?.name && location?.name) {
                                            let tenantId = "";
                                            try {
                                                tenantId = AuthService.getTenantId();
                                            } catch (error) {
                                                tenantId = "";
                                            }
                                            const identifier = [tenantId, application.name, location.name].join("_");
                                            uuid = getUuidV5(identifier, NAMESPACE_MAP["application_location"]);
                                        }
                                        input.detection.entity = {
                                            kind: "application_location",
                                            attributes: { application: application.name, metadata: { uuid, application, location } }
                                        };
                                    }
                                    break;
                                }
                                case InputType.LOCATION: {
                                    const location = passedEntities.length ? passedEntities[0].location : triggerNodes[0].properties.entities.location;
                                    if (location) {
                                        input.detection.entity = { kind: "location", attributes: { ...location, metadata: location } };
                                    }
                                    break;
                                }
                                case InputType.APPLICATION: {
                                    const application = passedEntities.length ? passedEntities[0].application : triggerNodes[0].properties.entities.application;
                                    if (application) {
                                        input.detection.entity = { kind: "application", attributes: { ...application, metadata: application } };
                                    }
                                    break;
                                }
                            }
                        }

                        let data = await (runbookService as any).runRunbook(input);
                        //setQueryParams({ [PARAM_NAME.incidentId]: input.incidentId }, true);
                        runbookQueryParams.current = { [PARAM_NAME.incidentId]: input.incidentId };
                        //setLoadingState(true);
                        const newStatus = Object.assign({}, status);
                        newStatus.status = STRINGS.viewRunbooks.runningStatus;
                        newStatus.statusValue = STATUS_VALUE.RUNNING;
                        newStatus.orchestratorInfo = JSON.stringify(data, null, 4);
                        setStatus(newStatus);
                    }
                }
                creatingQuery.current = false;
            } catch (error) {
                creatingQuery.current = false;
                console.log("Could not create the dashboard list");
            }
        };
        if (!props.isEditing && runbookQueryParams.current === undefined && !creatingQuery.current) {
            creatingQuery.current = true;
            setLoadingState(true);
            queryRunbook();
        }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    [props.isEditing, props.config?.id, props.config.runbookConfig, props.config.options, props.config.widgetType, loading, status, passedEntities]);

    const [statusCount, setStatusCount] = useState<number>(0);
    const maxStatusChecks = useRef<number>(initMaxStatusChecks);
    const errorMsg = useRef<string | undefined>(undefined);
    const stackTrace = useRef<Array<string>>([]);
    const queryParams = useQuery({
        query: new Query(RUNBOOKS_QUERY),
        requiredFilters: [FILTER_NAME.incidentIds],
        filters: {
            [FILTER_NAME.incidentIds]: (runbookQueryParams.current && runbookQueryParams.current[PARAM_NAME.incidentId] ? [runbookQueryParams.current[PARAM_NAME.incidentId]] : undefined)
        },
        skipGlobalFilters: true,
        timeNotRequired: true,
        lazy: true,
    });
    const queryParamsCache = useRef<any>(queryParams);
    queryParamsCache.current = queryParams;
    const timeoutId = useRef<number>(0);
    const runbookStatus = useRef<RUNBOOK_STATUS>(RUNBOOK_STATUS.UNKNOWN);
    useEffect(
        () => {
            if (loading && runbookQueryParams.current && statusCount < maxStatusChecks.current) {
                queryParamsCache.current.run({
                    filters: {
                        [FILTER_NAME.incidentIds]: [runbookQueryParams.current[PARAM_NAME.incidentId]]
                    },
                    // Always fetch runbook data fresh because it could've changed since the last pull
                    fetchPolicy: "network-only",
                });
                timeoutId.current = setTimeout(() => {
                    timeoutId.current = 0;
                    let hasData = false, hasFailure = false;
                    if (queryParamsCache.current.error) {
                        hasFailure = true;
                        errorMsg.current = undefined;
                        stackTrace.current = [];
                    } else if (!queryParamsCache.current.loading && queryParamsCache.current.data && queryParamsCache.current.data?.runbooks?.nodes?.length > 0) {
                        for (const runbook of queryParamsCache.current.data.runbooks.nodes) {
                            if (runbook.status) {
                                if (
                                    runbook.status === RUNBOOK_STATUS.DONE || runbook.status === RUNBOOK_STATUS.SUCCEEDED ||
                                    runbook.status === RUNBOOK_STATUS.SUCCEEDED_WITH_ERRORS ||
                                    runbook.status === RUNBOOK_STATUS.FAILED || runbook.status === RUNBOOK_STATUS.CANCELED
                                ) {
                                    runbookStatus.current = runbook.status;
                                    hasData = true;
                                    break;
                                } else if (runbook.status === RUNBOOK_STATUS.FAILED || runbook.status === RUNBOOK_STATUS.CANCELED) {
                                    hasFailure = true;
                                    if (runbook.debug) {
                                        errorMsg.current = runbook.debug.error ? runbook.debug.error : undefined;
                                        stackTrace.current = runbook.debug.stackTrace ? [runbook.debug.stackTrace] : [];
                                    }
                                    break;
                                }
                            }
                        }
                    }
                    setStatusCount(statusCount + 1);
                    if (statusCount >= (maxStatusChecks.current - 1) || hasData || hasFailure) {
                        const newStatus = Object.assign({}, status);
                        newStatus.status = STRINGS.viewRunbooks.timeoutStatus;
                        newStatus.statusValue = STATUS_VALUE.TIMEOUT;
                        if (hasData) {
                            newStatus.status = STRINGS.viewRunbooks.dataReceivedStatus;
                            newStatus.statusValue = STATUS_VALUE.SUCCEEDED;
                            // Would be nice if the orchestrator returned us the runbook id, so if there is 
                            // more than one runbook we know which one is the one we need to look at.
                            //setQueryParams({ [PARAM_NAME.runbookId]: queryParamsCache.current.data.runbooks.nodes[0].id }, true);
                        } else if (hasFailure) {
                            newStatus.status = STRINGS.viewRunbooks.errorStatus;
                            newStatus.statusValue = STATUS_VALUE.ERROR;
                        }
                        setStatus(newStatus);
                        setLoadingState(false);
                    }
                }, statusTimeIncrement) as any;
            }
            return () => {
                clearAutoUpdateTimeout(timeoutId);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loading, statusCount, status]
    );

    const lastSequenceNumber = useRef(0);
    useEffect(
        () => {
            if (lastSequenceNumber.current !== autoUpdate.sequenceNumber) {
                lastSequenceNumber.current = autoUpdate.sequenceNumber;
                if (!loading) {
                    runbookQueryParams.current = undefined;
                    setStatusCount(0);
                    setStatus({ 
                        status: STRINGS.viewRunbooks.startingStatus, statusValue: STATUS_VALUE.STARTING, orchestratorInfo: null, orchestratorError: null 
                    });
                    if (!props.isEditing) {
                        setLoadingState(true);
                    }
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [autoUpdate]
    );

    useEffect(
        () => {
            if (runbookQueryParams.current) {
                runbookQueryParams.current = undefined;
                setStatusCount(0);
                setStatus({ 
                    status: STRINGS.viewRunbooks.startingStatus, statusValue: STATUS_VALUE.STARTING, orchestratorInfo: null, orchestratorError: null 
                });
            }
            setLoadingState(!props.isEditing);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.isEditing]
    );

    let dataNode: RunbookNode | undefined = DashboardUtils.getDataNode(props.config?.runbookConfig);

    let datasets: Array<DataSet> = [];
    const datasetId = "xxxx";
    let isComparison = false;

    if (props.isEditing) {
        // This block is very similar to RunbookFakeDataUtils
        if (dataNode && isDataOceanNode(dataNode)) {
            let limit = dataNode?.properties?.limit ? dataNode.properties.limit : Number.MAX_SAFE_INTEGER;
            limit = dataNode ? Math.min(limit, getLimitFromFilters(dataNode)) : limit;
            const isTimeSeries = dataNode?.properties?.timeSeries !== undefined ? dataNode.properties.timeSeries : props.config.widgetType === WIDGET_TYPE.TIMESERIES;
            const isMultiMetric = false; //doNode.properties?.metrics?.length > 1;
            isComparison = dataNode?.properties ? dataNode.properties.comparedTo : false;
            const numDataPoints = isTimeSeries && isMultiMetric ? 1 : Math.min(limit, (dataNode ? getMaxLimitByType(dataNode) : 10));
            const keyDefs: Array<GenericKey | Column> = dataNode ? NodeUtils.getExpandedKeys(apiSpec as DataOceanMetadata, dataNode.properties.objType) : [{ id: "application.name", type: COLUMN_TYPE.STRING, label: "Application", unit: "none" }];
            const metricDefs = dataNode ? getMetrics(dataNode.properties.objType, dataNode.properties.metrics, apiSpec as DataOceanMetadata) : [{ id: "throughput", type: COLUMN_TYPE.FLOAT, label: "Throughput", unit: "bps" }];
            const debug: Array<DebugOutput> = []; //DEBUG_DATA[fakeRunbookOutput.datasets.length % DEBUG_DATA.length];
            /*
                        let limit = doNode.properties.limit ? doNode.properties.limit : Number.MAX_SAFE_INTEGER;
                        limit = Math.min(limit, getLimitFromFilters(doNode));
                        const isTimeSeries = doNode.properties.timeSeries;
                        const isMultiMetric = doNode.properties?.metrics?.length > 1;
                        //const isComparison = doNode.properties?.comparedTo;
                        const numDataPoints = isTimeSeries && isMultiMetric ? 1 : Math.min(limit, getMaxLimitByType(doNode));
                        const keyDefs: Array<GenericKey | Column> = NodeUtils.getExpandedKeys(apiSpec, doNode.properties.objType);
                        const metricDefs = getMetrics(doNode.properties.objType, doNode.properties.metrics, apiSpec);
                        const debug: Array<DebugOutput> = DEBUG_DATA[fakeRunbookOutput.datasets.length % DEBUG_DATA.length];
            */
            const dataset: DataSet = createDataset(
                datasetId, isTimeSeries, dataNode?.properties?.duration ? dataNode.properties.duration : 3600, numDataPoints, []/*getFilterInfo(dataNode)*/, keyDefs as Array<Column>,
                metricDefs, debug
            );
            datasets.push(dataset);
            if (isComparison && dataNode) {
                let comparisonOffset: number = 0;
                switch (dataNode.properties.comparedTo) {
                    case "yesterday":
                        comparisonOffset = 24 * 60 * 60;
                        break;
                    case "last_week":
                        comparisonOffset = 7 * 24 * 60 * 60;
                        break;
                    case "4_weeks_ago":
                        comparisonOffset = 4 * 7 * 24 * 60 * 60;
                        break;
                }
                //const debug: Array<DebugOutput> = DEBUG_DATA[fakeRunbookOutput.datasets.length % DEBUG_DATA.length];
                const dataset: DataSet = createDataset(
                    datasetId, isTimeSeries, dataNode?.properties?.duration ? dataNode.properties.duration : 3600, numDataPoints, []/*getFilterInfo(dataNode)*/, keyDefs as Array<Column>,
                    metricDefs, debug, comparisonOffset
                );
                dataset.isComparison = true;
                // We usually call process data set before pushing it on the list
                datasets.push(dataset);
            }
        } else if (dataNode && isAggregatorNode(dataNode)) {
            const synthMetrics = dataNode.properties.synthMetrics;
            if (synthMetrics) {
                let limit = /*doNode && doNode.properties.limit ? doNode.properties.limit :*/ 2;
                const groupByAll = dataNode.properties.groupBy && dataNode.properties.groupBy.length > 0 &&
                    dataNode.properties.groupBy[0] ? false : true;
                // Group by needs to be added as one of the column
                const parentDoOrTransformNode: RunbookNode | null = getFirstParentOfType(
                    dataNode, props.config?.runbookConfig?.nodes || [], [...dataOceanNodes, ...transformNodes]
                );
                const synthKeys = parentDoOrTransformNode ? parentDoOrTransformNode.properties.synthKeys || [] : [];
                let keySet: Array<Column | GenericKey> = AggregateNodeUtil.getExpandedKeysForGroupBy(
                    apiSpec as DataOceanMetadata, synthKeys, [], dataNode.properties.groupBy
                ) || [];
                let metricSet: Array<any> = synthMetrics.map((item) => {
                    if (item.included) {
                        let dataSetMetric = Object.assign({}, item);
                        delete dataSetMetric.included;
                        delete dataSetMetric.originalField;
                        dataSetMetric.originalMetric = item.originalField.id;
                        dataSetMetric.synthetic = true;
                        if (item.originalField.isKey) {
                            delete dataSetMetric.unit;
                        }
                        return dataSetMetric;
                    }
                    return undefined;
                }).filter((item) => item) || [];
                const numDataPoints = groupByAll ? 1 : limit;
                const debug: Array<DebugOutput> = []; //DEBUG_DATA[fakeRunbookOutput.datasets.length % DEBUG_DATA.length];
                const dataset: DataSet = createDataset(
                    datasetId, false, 0, numDataPoints, "", keySet as Array<Column>, metricSet, debug
                );
                datasets.push(dataset);
            }
        } else if (dataNode && isTransformNode(dataNode)) {
            const synthKeys = dataNode.properties.synthKeys || [];
            const synthMetrics = dataNode.properties.synthMetrics;
            if (synthKeys || synthMetrics) {
                let limit = 10;
                let keySet: Array<Column | GenericKey> = TransformNodeUtils.getExpandedKeysForSynthKeys(apiSpec as DataOceanMetadata, [], synthKeys);
                let metricSet: Array<any> = synthMetrics.map((item) => {
                    let dataSetMetric = Object.assign({}, item);
                    delete dataSetMetric.included;
                    delete dataSetMetric.originalField;
                    dataSetMetric.synthetic = true;
                    return dataSetMetric;
                }).filter((item) => item);
                const isTimeSeries = dataNode.properties.outputDataFormat === "timeseries";
                const isMultiMetric = metricSet.length > 1;
                const numDataPoints = isTimeSeries && isMultiMetric ? 1 : Math.min(limit, 10 /*getMaxLimitByType(doNode)*/);
                const debug: Array<DebugOutput> = []; //DEBUG_DATA[fakeRunbookOutput.datasets.length % DEBUG_DATA.length];
                const dataset: DataSet = createDataset(
                    datasetId, isTimeSeries, 900, numDataPoints, "", keySet as Array<Column>, metricSet, debug
                );
                datasets.push(dataset);
            }
        }
    }

    if (!props.isEditing && !loading && status.statusValue === STATUS_VALUE.SUCCEEDED) {
        const data = queryParams.data;
        if (data && data?.runbooks?.nodes?.length > 0) {
            const runbookIndex = 0;
            let unprocessedRunbook: RunbookOutput | null = data.runbooks.nodes[runbookIndex];
            let runbook: RunbookOutput | null = JSON.parse(JSON.stringify(unprocessedRunbook));
            if (!runbook) {
                runbook = { id: "0", targetId: "1", name: "", severity: { value: SEVERITY.CRITICAL }, datasets: [] };
            }
            runbook.targetId = "1";
            runbook.severity = { value: SEVERITY.CRITICAL };
            runbook.datasets = [];

            const dsFromQuery = data.runbooks.nodes[runbookIndex]!.datasets;

            if (typeof dsFromQuery === "string") {
                // This was from the first version of the Aternity demo, the datasets were passed as a string
                // keeping for a few days in case we need to go back.
                datasets = JSON.parse(dsFromQuery as string) as Array<DataSet>;
            } else if (typeof dsFromQuery === "object") {
                // This is the second version where the datasets are passed as an object but the schema is 
                // different than the schema in the DB.  We have two places where the runbook is viewed one
                // in test mode which gets the data directly from the runbook and is in the exact same format
                // as the DB and another when we get the data from the DB through graphql which has a format 
                // that can be supported by the graphql schema.  Since there are two formats modify the graphql
                // format to match the format we get directly from the API and leave all downstream code untouched.
                datasets = translateDatasetSchema(dsFromQuery);
            }

            let template = JSON.parse(data.runbooks.nodes[runbookIndex]!.template);
            if (template && template.label) {
                runbook.name = template.label;
            }

            if (datasets && datasets.length > 0 && template) {
                for (const dataset of datasets) {
                    const processedDataset = processDataset(dataset, template.nodes);
                    if (processedDataset) {
                        runbook.datasets.push(processedDataset);
                    }
                }
                datasets = runbook.datasets;
                updateDatasetsWithEntities(datasets);
            }
        }
    }

    const widget = { id: props.config.id, name: props.config.name, options: props.config.options } as Widget;
    if (isComparison && widget.options && dataNode) {
        widget.options.comparedTo = dataNode.properties.comparedTo;
    }
    let widgetComponent: JSX.Element = createWidget(
        props.config.widgetType, widget, datasets, loading, undefined, props.notifyInteraction
    );

    const [isOpen, setIsOpen] = useState<boolean>(false);

    return <div
        style={props.layout !== LAYOUT_TYPE.CUSTOM ? {
            width: (props.layout !== LAYOUT_TYPE.VERTICAL ? CARD_WIDTH : "98%"), height: CARD_HEIGHT,
            borderRadius: "14px"
        } : {}} className={props.layout !== LAYOUT_TYPE.CUSTOM ? "m-4 bg-light overflow-auto" : ''}>

        <BasicDialog dialogState={dialogState} className="dashboards-page-dialog" onClose={() => setDialogState(updateDialogState(dialogState, false, false, []))} />
        <div className={`${props.layout === LAYOUT_TYPE.CUSTOM ? "widget-headings" : ''} bg-light d-flex flex-row justify-content-between p-2`} style={{width: "100%"}} >
            <span>{props.config.name}</span>
            {props.isEditing && <div>
                <Icon icon={IconNames.COG} style={{ cursor: "pointer" }} onClick={(event) => {
                    //showRunbookEditorDialog(runbookConfig, dialogState, setDialogState);
                    setIsOpen(true);
                }} />
                <Icon icon={IconNames.CROSS} style={{ cursor: "pointer" }} className="ms-2" onClick={(event) => {
                    if (props.notifyWidgetEditAction) {
                        props.notifyWidgetEditAction(WidgetEditAction.DELETE);
                    }
                }} />
            </div>}
            {!props.isEditing && <div>
                <Icon icon={IconNames.INFO_SIGN} style={{ cursor: "pointer" }} onClick={(event) => {
                    showInfoDialog(datasets || [], dialogState, setDialogState, showDebugInformation);
                }} />
                <Icon icon={IconNames.DIAGNOSIS} style={{ cursor: "pointer" }} className="ms-2" onClick={(event) => {
                    showDebugDialog(datasets || [], dialogState, setDialogState);
                }} />
            </div>}
        </div>
        {widgetComponent}
        <DashboardWidgetConfigDialog config={props.config} widgets={props.widgets} isOpen={isOpen}
            handleConfigChange={(config: DashboardWidgetConfig) => {
                if (props.notifyWidgetEditAction) {
                    props.notifyWidgetEditAction(WidgetEditAction.CONFIG_CHANGE, config);
                }
            }}
            handleDialogClose={() => {
                setIsOpen(false);
            }}
        />
    </div>;
});

export default DashboardWidget;

/** Updates the dataset rows with the entity that represents the row.
 *  @param datasets the array of DataSets to add entities to. */
function updateDatasetsWithEntities(datasets: DataSet[]): void {
    if (datasets?.length) {
        for (const dataset of datasets) {
            if (dataset.datapoints) {
                for (const datapoint of dataset.datapoints) {
                    if (datapoint.keys) {
                        if (datapoint.keys["application.name"]) {
                            (datapoint.keys as DataPointKeys).group = {
                                application: {
                                    name: datapoint.keys["application.name"]
                                }
                            };
                        } else if (datapoint.keys["network_device.ipaddr"]) {
                            (datapoint.keys as DataPointKeys).group = {
                                network_device: {
                                    ipaddr: datapoint.keys["network_device.ipaddr"],
                                    name: datapoint.keys["network_device.name"]
                                }
                            };
                        } else if (datapoint.keys["network_interface.ipaddr"]) {
                            (datapoint.keys as DataPointKeys).group = {
                                network_interface: {
                                    ipaddr: datapoint.keys["network_interface.ipaddr"],
                                    ifindex: datapoint.keys["network_interface.ifindex"],
                                    name: datapoint.keys["network_interface.name"]
                                }
                            };
                        } else if (datapoint.keys["location.name"]) {
                            (datapoint.keys as DataPointKeys).group = {
                                location: {
                                    name: datapoint.keys["location.name"]
                                }
                            };
                        }
                    }
                }
            }
        }
    }
}

/** Creates a popup that displays the runbook editor information.
 *  @param dialogState the copied state object with the state setup to open the dialog.  The content
 *      needs to be appended and the title needs to be set in this function.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function. */
export function showRunbookEditorDialog(
    runbookConfigRef: { current: RunbookConfig | undefined }, dialogState: any, setDialogState: (dialogState: any) => void
): void {
    const json: Record<string, any> = { test: "test" };
    const nodeLibrary = new NodeLibrary(DashboardNodeLibrary as NodeLibrarySpec);
    const newDialogState = Object.assign({}, dialogState);
    newDialogState.showDialog = true;
    //newDialogState.dialogContent = <JsonViewer json={json} />;
    newDialogState.dialogContent = <div style={{ height: "780px" }}><CreateRunbookView
        nodeLibrary={nodeLibrary}
        key="create" editorHidden={false}
        onPreview={(runbook: RunbookConfig) => {
        }}
        onTest={(runbook: RunbookConfig) => {
        }}
        runbookConfig={runbookConfigRef.current}
        onChange={(runbook: RunbookConfig) => {
            runbookConfigRef.current = runbook;
        }}
        variant={Variant.INCIDENT}
    /></div>;
    newDialogState.dialogFooter = <>
        <CopyToClipboard text={JSON.stringify(json || {}, null, 4)}>
            <Button active={true} outlined={true}
                text={STRINGS.primaryIndicatorView.copyBtnText} onClick={() => {
                    setDialogState(updateDialogState(newDialogState, false, false, []));
                }}
            />
        </CopyToClipboard>
        <Button active={true} outlined={true}
            text={STRINGS.primaryIndicatorView.okBtnText}
            onClick={async (evt) => {
                setDialogState(updateDialogState(newDialogState, false, false, []));
            }}
        />
    </>;
    setDialogState(newDialogState);
}

/** Creates a popup that displays the widget query information.  Right now the popup assumes there is only one 
 *      time range per widget and one data source per widget.  This can easily be expanded to the case where
 *      there is more than one time range or data source.
 *  @param datasets the Array of DataSets to use to obtain the DataSetInfo object with the information from the Data Ocean.
 *  @param dialogState the copied state object with the state setup to open the dialog.  The content
 *      needs to be appended and the title needs to be set in this function.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function.
 *  @param debug a boolean value, if true show debug information, if false, do not. */
function showInfoDialog(
    datasets: Array<DataSet>, dialogState: any, setDialogState: (dialogState: any) => void, debug: boolean
): void {

    const newDialogState = Object.assign({}, dialogState);
    newDialogState.title = STRINGS.runbookOutput.infoDialogTitle;
    newDialogState.showDialog = true;
    newDialogState.dialogContent = <InfoContent datasets={datasets} />;
    newDialogState.dialogFooter = <>
        <Button active={true} outlined={true}
            text={STRINGS.runbookOutput.okBtnText}
            onClick={async (evt) => {
                setDialogState(updateDialogState(newDialogState, false, false, []));
            }}
        />
    </>;
    setDialogState(newDialogState);
}

/** Creates a popup that displays the widget debug information.  The debug information is passed in the 
 *  dataset.
 *  @param datasets the Array of DataSets to use to obtain the DataSetInfo object with the debug information.
 *  @param dialogState the copied state object with the state setup to open the dialog.  The content
 *      needs to be appended and the title needs to be set in this function.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function. */
/* istanbul ignore next */
function showDebugDialog(
    datasets: Array<DataSet>, dialogState: any, setDialogState: (dialogState: any) => void
): void {
    let jsonOutput = {};
    if (datasets) {
        let dsIndex = 1;
        for (const dataset of datasets) {
            if (dataset) {
                const dsName = "Dataset: " + dsIndex++;
                if (dataset.debug?.length) {
                    jsonOutput[dsName] = {};
                    for (const debugOutput of dataset.debug) {
                        if (debugOutput.type === DEBUG_OUTPUT_TYPE.JSON) {
                            jsonOutput[dsName][debugOutput.name] = JSON.parse(debugOutput.value);
                        }
                    }
                }
            }
        }
    }

    const newDialogState = Object.assign({}, dialogState);
    newDialogState.title = STRINGS.runbookOutput.debugDialogTitle;
    newDialogState.showDialog = true;
    newDialogState.dialogContent = <JsonViewer json={jsonOutput} />;
    newDialogState.dialogFooter = <>
        <CopyToClipboard text={JSON.stringify(jsonOutput || {}, null, 4)}>
            <Button active={true} outlined={true}
                text={STRINGS.primaryIndicatorView.copyBtnText} onClick={() => {
                    setDialogState(updateDialogState(newDialogState, false, false, []));
                }}
            />
        </CopyToClipboard>
        <Button active={true} outlined={true}
            text={STRINGS.runbookOutput.okBtnText}
            onClick={async (evt) => {
                setDialogState(updateDialogState(newDialogState, false, false, []));
            }}
        />
    </>;
    setDialogState(newDialogState);
}

/** clears the timeout id.
 *  @param timeoutId the object returned by useRef that contains the timeout id. */
function clearAutoUpdateTimeout(timeoutId: { current: number }): void {
    if (timeoutId.current) {
        clearTimeout(timeoutId.current);
        timeoutId.current = 0;
    }
}
