query incidentDistribution($priority: [Priority], $completionStatus: CompletionStatus) {
  summary(
    filter: {priorities: $priority, status: [NEW, INVESTIGATING], completionStatus: $completionStatus}
  ) {
    incidentsPriority {
      priority
      count
      status {
        status
        count
      }
    }
  }
}
