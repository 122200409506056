mutation createClientApplication($name: String!, $expiration: String!) {
    createClientApplication(
        clientApplication: { name: $name, expiration: $expiration }
    ) {
        id
        name
        secrets {
            secretId
            secretText
            hint
            description
            expiration
        }
    }
}
