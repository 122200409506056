import moment from "moment";
import { TIME_FORMAT } from 'components/enums/Time.ts';
import { STRINGS } from "app-strings";
import type { TIME_OBJECT } from "utils/stores/GlobalTimeStore.ts";

/**Converts start/end time in milliseconds to human readable Date/Time string
 * @param startTime time in milliseconds
 * @param endTime time in milliseconds
 * @return An object with human readable date time string. Ex "Today 11:40 AM - >  12:40 PM", "May 5th 2021, 12:41 PM ->  Today 12:41 PM"
 * "May 5th 2021, 12:41 PM -> 6:41 PM", "May 4th 2021, 12:42 PM  May 5th 2021, 12:42 PM"
 * The function needs to be enhanced once we start supporting configurable date formats
 */
export function formatTimeRange(
    startTime: number|Date,
    endTime: number|Date,
    format: string = TIME_FORMAT.DISPLAY_TIME_FORMAT
): { startDateTime: string, endDateTime: string } {
    const curDate = moment().format('LL');
    const formattedDateTime = {
        startDateTime: moment(startTime).format(format),
        endDateTime: moment(endTime).format(format)
    }
    const startDateMoment = moment(startTime);
    const endDateMoment = moment(endTime);
    const startDate = startDateMoment.format('LL');
    const endDate = endDateMoment.format('LL');
    if (startDate === endDate) {
        const timeSpansEntireDay = startDateMoment.hours() === 0 && startDateMoment.minutes() === 0 && endDateMoment.hours() === 23 && endDateMoment.minutes() === 59;
        const startsAndEndsInSameMeridiem = (startDateMoment.hours() < 12 && endDateMoment.hours () < 12) || (startDateMoment.hours() >= 12 && endDateMoment.hours () >= 12);
        if (startDate === curDate) {
            if (timeSpansEntireDay) {
                formattedDateTime.startDateTime = STRINGS.today;
                formattedDateTime.endDateTime = "";
            } else {
                let startTimeText = startDateMoment.format('LT');
                if (startsAndEndsInSameMeridiem) {
                    startTimeText = startTimeText.replace(/ AM| PM/, "");
                }
                formattedDateTime.startDateTime = `${STRINGS.today} ${startTimeText}`;
                formattedDateTime.endDateTime = endDateMoment.format('LT');
            }
        } else {
            if (timeSpansEntireDay) {
                formattedDateTime.startDateTime = startDateMoment.format(TIME_FORMAT.DISPLAY_DATE_ONLY_FORMAT);
                formattedDateTime.endDateTime = "";
            } else {
                if (startsAndEndsInSameMeridiem) {
                    formattedDateTime.startDateTime = formattedDateTime.startDateTime.replace(/ AM| PM/, "");
                }
                formattedDateTime.endDateTime = moment(endTime).format('LT');
            }
        }
    } else {
        if (endDate === curDate) {
            formattedDateTime.endDateTime = `${STRINGS.today} ${moment(endTime).format('LT')}`;
        }
    }
    return formattedDateTime;
}

export function formatTimeToUserFriendlyString (timeRange: TIME_OBJECT, format?: string): string {
    let output = "";
    if (timeRange.duration) {
        output = STRINGS.TIME_RANGE_SELECTOR[timeRange.duration]?.displayText || timeRange.duration;
    } else if (timeRange.startTime && timeRange.endTime) {
        const formattedTimeRange = formatTimeRange(timeRange.startTime, timeRange.endTime, format);
        output = formattedTimeRange.startDateTime + (formattedTimeRange.endDateTime ? (" - " + formattedTimeRange.endDateTime) : "");
    }
    return output;
}
