/** This file defines the GaugesView React component.  The GaugesView React component wraps a 
 *  GaugeChart component and includes the query to get the data.  The GaugeChart React component renders a 
 *  a row of highcharts gauges with one metric.
 *  @module */

import { Query } from "reporting-infrastructure/types/Query.ts";
import { useQuery } from "utils/hooks/useQuery.ts";
import { FILTER_NAME } from "components/sdwan/enums/filters.ts";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade.tsx";
import type { BaseRunbookViewProps, DataSet } from "pages/riverbed-advisor/views/runbook-view/Runbook.type.ts";
import { Unit } from "reporting-infrastructure/types/Unit.class.ts";
import { GaugeChart } from "components/common/gauge-chart/GaugeChart.tsx";
import { formatKeys } from "utils/runbooks/RunbookFormatter.tsx";
import { CardsHolder } from "components/common/layout/cards-holder/CardsHolder.tsx";
import { Card } from "components/reporting/containers/card/Card.tsx";
import { INCIDENT_DETAILS_STYLE } from "components/enums/QueryParams.ts";
import SUMMARY_DATA_QUERY from '../table-view/summary-data-query.graphql';

/** an interface that describes the properties that can be passed in to the component.*/
export interface GaugesViewProps extends BaseRunbookViewProps {
    // Add any properties that are specific to this view
    showIndividualGaugeCards?: boolean;
}

/** Creates the gauges view, which is a component that displays n gauges with analytics data.
 *  @param props an object with the properties passed to the gauges view.
 *  @returns JSX with the gauges component.*/
export const GaugesView = ({
    showIndividualGaugeCards = false,
    ...props
}: GaugesViewProps): JSX.Element => {
    let primaryDataset: DataSet | undefined = undefined;
    if (props.datasets) {
        for (const dataset of props.datasets) {
            if (!dataset.isComparison) {
                primaryDataset =  dataset;
            }
        }
    }

    let {loading, data, error} = useQuery({
        query: new Query(SUMMARY_DATA_QUERY),
        requiredFilters: [FILTER_NAME.incidentId, FILTER_NAME.datasetId],
        filters: {
            [FILTER_NAME.incidentId]: props.incidentId,
            [FILTER_NAME.datasetId]: primaryDataset?.datapoints ? undefined : primaryDataset?.id
		},
        skipGlobalFilters: true
    });

    const selectedMetrics: Array<string> | undefined = props.widget?.options?.metrics as Array<string> | undefined;

    const gaugeRowsJsx: Array<JSX.Element> = [];
    if (primaryDataset?.datapoints) {
        data = {};
        data.datapoints = primaryDataset.datapoints;
    }
    if (data && data.datapoints && primaryDataset) {
        for (let rowIndex = 0; rowIndex < data.datapoints.length;  rowIndex++) {
            const gaugeRow: Array<JSX.Element> = [];
            const keyDefs = primaryDataset.keys;
            const name: JSX.Element | string = formatKeys(keyDefs || [], data.datapoints[rowIndex].keys, true);
            const metricDefs = primaryDataset.metrics;
            if (metricDefs) {
                for (let metricIndex = 0; metricIndex < metricDefs.length; metricIndex++) {
                    if (selectedMetrics?.length && !selectedMetrics.includes(metricDefs[metricIndex].id)) {
                        continue;
                    }
        
                    const gauge = metricDefs[metricIndex];
        
                    let value = data.datapoints[rowIndex].data[gauge.id];
                    if (value !== null && value !== undefined && value.length > 0 && (gauge.type === "integer" || gauge.type === "float")) {
                        value = Number(value);
                    }

                    //const opts = (ts?.length > 0 ? {chartData: ts} : {})
                    const gaugeChartJsx = <GaugeChart key={gauge.id + "-chart"} gaugeData={{minValue: 0, maxValue: 100, value: value, metric: gauge.label, unit: Unit.parseUnit(gauge.unit || "")}} enableFullScreen={true}/>;
                    const gaugeChartContainerJsx = showIndividualGaugeCards ? <Card key={gauge.id}>{gaugeChartJsx}</Card> : gaugeChartJsx;
                    gaugeRow.push(gaugeChartContainerJsx);
                    
                }
            }    
            gaugeRowsJsx.push(<div key={"gauge-row-" + rowIndex}>
                <div className="display-7 fw-bold d-inline-block ms-1 mt-3 mb-3">
                    {name}
                </div>
                {
                    showIndividualGaugeCards ?
                    <CardsHolder>{gaugeRow}</CardsHolder> :
                    <Card hideShadow={INCIDENT_DETAILS_STYLE === "noTableOneCardForEachWidget"}>{gaugeRow}</Card>
                }
            </div>);
        }
    }

    return <DataLoadFacade loading={loading || props.loading} error={error || props.error} data={data}>
            {gaugeRowsJsx}
    </DataLoadFacade>;
};
