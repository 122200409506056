/** This module contains a set of utilities to do translations of enums.
 *  @module
 */
import type { HealthFilterStatus } from "components/common/table/filters/health-filter/HealthFilter";
import { INCIDENT_STATUS } from "components/enums/IncidentStatus.ts";
import { PRIORITY } from "components/enums/Priority.ts";
import { SEVERITY } from "components/enums/Severity.ts";
import type { METRIC_NAME } from "components/sdwan/enums/metrics.ts";
import { RUNBOOK_STATUS } from "pages/riverbed-advisor/views/runbook-view/Runbook.type.ts";

/** this type defines the format of the health filter query parameter. */
export type HealthFilterQueryParam = {
    n?: string | number | undefined
    d?: string | number | undefined
    c?: string | number | undefined
    i?: string | number | undefined
    u?: string | number | undefined
};

/** The following functions optimize the strings being stored in the query string praams.
 *  This one translates a health filter object to a query parameter.
 *  @param filter the HealthFilterStatus to translate to a query parameter.
 *  @returns the health filter as a query parameter.*/
export function healthFilterToQueryParam(filter: HealthFilterStatus) {
    const healthQueryParam: HealthFilterQueryParam = {}
    if (filter) {
        if (filter[SEVERITY.NORMAL]) {
            healthQueryParam.n = 1;
        }
        if (filter[SEVERITY.DEGRADED]) {
            healthQueryParam.d = 1;
        }
        if (filter[SEVERITY.CRITICAL]) {
            healthQueryParam.c = 1;
        }
        if (filter[SEVERITY.INITIALIZING]) {
            healthQueryParam.i = 1;
        }
        if (filter[SEVERITY.UNKNOWN]) {
            healthQueryParam.u = 1;
        }
    }
    return healthQueryParam;
}

/** This function translates the health filter query parameter to a HealthFilterStatus object
 *  @param param the query parameter that needs to be translated.
 *  @returns the health filter status object.*/
export function queryParamToHealthFilter(param?: string | null) {
    let paramObj: HealthFilterQueryParam = {};
    if (param) {
        try {
            paramObj = JSON.parse(param);
        } catch (e) {
            console.warn("Unable to parse query param to health filter - " + param);
        }
    }
    const healthFilter = {
        [SEVERITY.NORMAL]: paramObj.n === 1 ? true : false,
        [SEVERITY.DEGRADED]: paramObj.d === 1 ? true : false,
        [SEVERITY.CRITICAL]: paramObj.c === 1 ? true : false,
        [SEVERITY.INITIALIZING]: paramObj.i === 1 ? true : false,
        [SEVERITY.UNKNOWN]: paramObj.u === 1 ? true : false
    }
    return healthFilter;
}

/** This function translates a metric filter object to a query parameter.
 *  @param filter the Array of metrics to translate to a query parameter.
 *  @param metricTypes the Array of metric types.
 *  @returns the metric filter as a query parameter.*/
export function metricFilterToQueryParam(filter: Array<METRIC_NAME>, metricsTypes: Array<string> = []) {
    const MetricFilter = {};
    if (filter) {
        for(const metric of filter){
            if(metricsTypes.includes(metric)){
                MetricFilter[metric] = 1;
            }
        }
    }
    return MetricFilter;
}

/** This function translates the metric filter query parameter to an Array of metrics.
 *  @param param the query parameter that needs to be translated.
 *  @param metricTypes the Array of metric types.
 *  @returns the array of metrics or null.*/
export function queryParamToMetricFilter(param?: string | null, metricsTypes: Array<string> = []) {
    if (param) {
        let paramObj;
        let metricSel : Array<string> = [];
        try {
            paramObj = JSON.parse(param)
        } catch (e) {
            return null;
        }
        for(const p in paramObj){
            if(metricsTypes.includes(p) && paramObj[p]){
                metricSel.push(p);
            }
        }
        return metricSel;
    }
    return null;
}

/** translates the status string to the INCIDENT_STATUS enum with the states returned by DAL.
 *  DAL uses snake case with all caps whereas the activity log uses camel case.
 *  @param status the status string.
 *  @returns the INCIDENT_STATUS enum that corresponds to the status string. */
export function translateIncidentStatus(status: string = ""): INCIDENT_STATUS {
    return Object.keys(INCIDENT_STATUS).find((statEnum) => {
        return statEnum.toLowerCase().replace(/_/g, '') === status.toLowerCase()
    }) as INCIDENT_STATUS;
}

/** translates the priority string to the PRIORITY enum with the priorities returned by DAL.
 *  DAL uses snake case with all caps whereas the activity log uses camel case.
 *  @param priority the status string.
 *  @returns the PRIORITY enum that corresponds to the priority string. */
export function translateIncidentPriority(priority: string = ""): PRIORITY {
    return Object.keys(PRIORITY).find((priEnum) => {
        return priEnum.toLowerCase() === priority.toLowerCase()
    }) as PRIORITY;
}

/** translates the runbook status string to the RUNBOOK_STATUS enum with the states returned by DAL.
 *  DAL uses snake case with all caps whereas the activity log uses camel case.
 *  @param status the status string.
 *  @returns the RUNBOOK_STATUS enum that corresponds to the runbook status string. */
export function translateRunbookStatus(status: string = ""): RUNBOOK_STATUS | undefined {
    if (status) {
        return Object.keys(RUNBOOK_STATUS).find((statEnum) => {
            return statEnum.toLowerCase().replace(/_/g, '') === status.toLowerCase();
        }) as RUNBOOK_STATUS;
    }
}
