import { useCallback, useEffect } from "react";
import {
  useBlocker,
  type BlockerFunction,
  type Location,
} from "react-router-dom";

type NavigationPromptProps = {
  when?: boolean;
  message: string;
}

const isDifferentPath = (a: Location, b: Location) => {
  return a.pathname !== b.pathname;
}

/**
 * A replacement for the old <Prompt> in React Router v5.
 * @param when     - If true, navigation will be blocked and user will see a confirm dialog
 * @param message  - The message to display in the confirm dialog
 */
export const Prompter =({ when = true, message }: NavigationPromptProps) => {
  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) =>
      when && isDifferentPath(currentLocation, nextLocation),
    [when]
  );

  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (blocker.state === "blocked") {
      const response = window.confirm(message || "Are you sure you want to leave this page?");
      response ? blocker.proceed?.() : blocker.reset?.();
    }
  }, [blocker, message]);

  return null;
}
