query Applications($filter: ApplicationFilterInputType) {
    applications(filter: $filter) {
        page {
            id
            name
            type
            customProperties {
                assignedValue {
                    id
                    name
                }
                values {
                    id
                    name
                }
                id
                name
                description
                lastUpdatedAt
                type
                permissions
                validTypes {
                    type
                }
            }
            reportedBy {
                dataSourceType
                dataSourceId
            }
        }
    }
}
