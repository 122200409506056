/** This module contains the a control that allows you to set the end time of a runbook run.
 *  @module
 */
import { useState } from "react";
import { DateTimeSelector } from "components/common/date-time-selector/DateTimeSelector.tsx";
import { formatToLocalTimestamp } from "reporting-infrastructure/utils/formatters/GeneralFormatter.ts";
import { TIME_FORMAT } from "components/enums/Time.ts";
import { STRINGS } from "app-strings";

// if show more is true then this component will control showing the time and there will be a more button in
// the date picker to bring up the date control, if show more is false, the date control will show a button with
// the time.
const showMoreButton = false;

/** This interface defines the properties passed into the runbook time control React component.*/
interface RunbookTimeControlProps {
    /** the initial time to display in milliseconds. */
    defaultTime?: number;
    /** the handler for time change events. */
    onTimeChange?: (time: number | undefined) => void;
}

/** Renders the runbook time control.  This control allows the user to enter the end time for running
 *      the runbook.
 *  @param props the properties passed in.
 *  @returns JSX with the view runbook time control.*/
export default function RunbookTimeControl (props: RunbookTimeControlProps): JSX.Element {
    const [time, setTime] = useState<number>(0);

    return <span id="runbook-end-time" data-time={time/1000}>
        {showMoreButton && <span className="me-2">{time ? formatToLocalTimestamp(new Date(time), TIME_FORMAT.DISPLAY_TIME_FORMAT) : STRINGS.viewRunbooks.inputEndTimeNowText}</span>}
        <DateTimeSelector
            className={showMoreButton ? "mb-1" : ""}
            showMoreButton={showMoreButton}
            showInline={false}
            timeSelectionHandler={(time: number | undefined) => {
                setTime(time ? time : 0);
                if (props.onTimeChange) {
                    props.onTimeChange(time);
                }
            }}
            time={props.defaultTime}
        />
    </span>;
}
