/** This module contains a react component that creates an Azure Monitor icon based on two SVGs from
 *  Azure.  This should only be used for the AzureMonitor demo and then we should decide what to do
 *  about creating an icon for GA.
 *  @module
 */
import React from "react";
import { Icon, IconNames } from "@tir-ui/react-components";
import './RunbookAzureMonitorIcon.scss';

/** This interface defines the properties passed into the runbook azure monitor icon component.*/
export interface RunbookAzureMonitorIconProps {
    /** if true show the Azure icon. */
    showAzIcon: boolean;
    /** if true show the Azure Monitor Gauge icon. */
    showGauge: boolean;
    /** any text that should be displayed with the icon. */
    iconText?: string | JSX.Element;
}

/** Renders the runbook azure monitor icon.
 *  @param props the properties passed in.
 *  @returns JSX with the runbook azure monitor icon React component.*/
export const RunbookAzureMonitorIcon = (props: RunbookAzureMonitorIconProps): JSX.Element => {
    return <>
        {(props.showAzIcon || props.showGauge) && <>
            {props.showAzIcon && <span style={{position: "relative", "zIndex": 5, verticalAlign: "text-top"}}><Icon icon={IconNames.AZURE_LOGO} size={32} className="icon flex-grow-0" /></span>}
            {props.showGauge && <span style={props.showAzIcon ? {position: "relative", "right": "15px", verticalAlign: "text-top"} : {verticalAlign: "text-top"}}><Icon icon={IconNames.AZURE_MONITOR} size={32} className="icon flex-grow-0 ic-azure-monitor" /></span>}
            <span style={props.showGauge && props.showAzIcon ? {position: "relative", "left": "-15px"} : {}}>{props.iconText || ""} </span>
        </>}
        {!props.showAzIcon && !props.showGauge && <>{props.iconText || ""}</>}
    </>;
};
