import { IconNames } from "@tir-ui/react-components";
import { HELP, STRINGS } from "app-strings";
import { PageWithHeader } from "components/sdwan/layout/page-with-header/PageWithHeader";
import { ThirdPartyAuthenticationListView } from "./views/ThirdPartyAuthenticationListView/ThirdPartyAuthenticationListView";

/** Renders the device list page.
 *  @param props the properties passed in.
 *  @returns JSX with the device list page.*/
const ThirdPartyAuthenticationPage = (props): JSX.Element => {
    return (
        <PageWithHeader name="ThirdPartyAuthenticationPage"
            title={STRINGS.thirdPartyIntegrations.title}
            icon={IconNames.KEY} addPadding={true}
            showTimeBar={false} helpInfo={HELP.thirdPartyAuth}>
            <ThirdPartyAuthenticationListView/>
        </PageWithHeader>
    );
};

export default ThirdPartyAuthenticationPage;
