import type React from "react";
import { useEffect, useState } from "react";
import { useUserPreferences } from "utils/hooks/useUserPreferences.ts";
import type { HINTS_HIDDEN_USER_PREFS_KEY } from "utils/services/UserPrefsTypes.ts";

export interface HintInfo {
    /** A key to identify this specific hint */
    hintKey: string;
    /** Contents to be displayed in the hint */
    hint: React.ReactNode;
    /** If passed as true and if hint is of type string, it will be dangerously set as HTML */
    setStringAsMarkup?: boolean;
    /** A callback that will be called right before displaying this hint */
    beforeShow?: () => void;
    /** A callback that will be called right after displaying this hint */
    onShow?: () => void;
    /** A callback that will be called right before hiding this hint either to
     * show another hint or when user closes guided hints altogether */
    beforeHide?: () => void;
    /** A callback that will be called right after hiding this hint */
    onHide?: () => void;
}

/** The contents of this interface would be consumed by the WrapInGuidedHint
 * component. You the consumer mostly wouldn't have to know about these.
 */
export interface HintStoreData {
    /** hintKey for the currently active Hint */
    active: string;
    /** List of the hints array for this store instance */
    hints: Array<HintInfo>;
    /** Flag that indicates if the current hint is the first hint */
    first: boolean;
    /** Flag that indicates if the current hint is the last hint */
    last: boolean;
    /** Passing the hint story user pref key in data object to WrapInGuidedHint */
    showHintStoryUserPreferenceKey?: HINTS_HIDDEN_USER_PREFS_KEY;
}

export type GuidedHintsStoreInstance = {
    data: HintStoreData,
    /** The record of the hint that is currently active */
    activeHint?: HintInfo,
    /** This method can be used to show a specific hint by
     * passing either the hint's hintKey string or index */
    showHint: (hintKeyOrIndex: string | number) => void,
    /** Method to trigger showing the next hint */
    showNextHint: () => void,
    /** Method to trigger showing the previous hint */
    showPreviousHint: () => void,
    /** Reset the storyboard and hide any open hint */
    resetHints: () => void,
}

export interface useGuidedHintsStoreProps {
    /** The hintKey of the hint to show activated by default when component initializes */
    initActiveHint?: string;
    /** A list of hints and their hint key values in the order in which the hints should be displayed  */
    hints: Array<HintInfo>;
    /** The user preference key to be used to control if user has chosen not to show this guided hints story anymore.
     * WrapInGuidedHint component will automatically show the "Don't show these hints" control when this this preference is present */
    showHintStoryUserPreferenceKey?: HINTS_HIDDEN_USER_PREFS_KEY;
}

/** This store is to be used in conjunction with WrapInGuidedHint component to have a list of
 * hints that will be traversed against by using the controls provided by that component.
 * For an example of how to use, scroll to the bottom of WrapInGuidedHint.tsx
 */
export function useGuidedHintsStore (props: useGuidedHintsStoreProps): GuidedHintsStoreInstance {
    // If a user preferrences key was provided, have hideHints as true to begin with and set it to false based on response from user preference
    const [hideHints, setHideHints] = useState(props.showHintStoryUserPreferenceKey ? true : false);
    const [hintStoreData, setHintStoreData] = useState<HintStoreData>({
        active: props.initActiveHint || "",
        hints: props.hints,
        first: Boolean(props.initActiveHint && props.hints.length > 0 && props.hints[0].hintKey === props.initActiveHint),
        last: Boolean(props.initActiveHint && props.hints.length > 0 && props.hints[props.hints.length - 1].hintKey === props.initActiveHint),
        showHintStoryUserPreferenceKey: props.showHintStoryUserPreferenceKey,
    });

    const preferences = useUserPreferences({
        listenOnlyTo: {
            ...(props.showHintStoryUserPreferenceKey ? { [props.showHintStoryUserPreferenceKey]: "" } : {})
        }
    })

    useEffect(() => {
        if (props.showHintStoryUserPreferenceKey) {
            const hide = Boolean(props.showHintStoryUserPreferenceKey && preferences[props.showHintStoryUserPreferenceKey] === "false");
            if (hide !== hideHints) {
                setHideHints(hide);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preferences]);

    const { active, hints } = hintStoreData;
    const [activeHint, setActiveHint] = useState<HintInfo|undefined>(active !== "" ? hints.find(hint => hint.hintKey === active) : undefined);

    function showHint (hintKeyOrIndex: string | number) {
        if (hints.length > 0) {
            const currentHintIndex = hints.findIndex(hint => hint.hintKey === active);
            const currentHint = hints[currentHintIndex];
            const nextHintIndex = typeof hintKeyOrIndex === "number" ? hintKeyOrIndex : hints.findIndex(hint => hint.hintKey === hintKeyOrIndex);
            const nextHint = hints[nextHintIndex];
            if (nextHint !== undefined) {
                if (currentHint?.beforeHide) {
                    currentHint.beforeHide();
                }
                if (nextHint.beforeShow) {
                    nextHint.beforeShow();
                }
                setHintStoreData({
                    ...hintStoreData,
                    active: nextHint.hintKey,
                    first: nextHintIndex === 0,
                    last: (nextHintIndex + 1) === hints.length,
                });
                setActiveHint(nextHint);
                if (nextHint.onShow) {
                    nextHint.onShow();
                }
                if (currentHint?.onHide) {
                    currentHint.onHide();
                }
            }
        }
    }

    function showNextHint () {
        if (hints.length > 0 && hideHints === false) {
            if (active === "") {
                const nextHint = hints[0];
                if (nextHint.beforeShow) {
                    nextHint.beforeShow();
                }
                setHintStoreData({
                    ...hintStoreData,
                    active: nextHint.hintKey,
                    first: true,
                    last: (hints.length === 1),
                });
                setActiveHint(nextHint);
                if (nextHint.onShow) {
                    nextHint.onShow();
                }
            } else {
                const currentHintIndex = hints.findIndex(hint => hint.hintKey === active);
                if (currentHintIndex !== -1) {
                    const currentHint = hints[currentHintIndex];
                    const nextHintIndex = currentHintIndex + 1;
                    const nextHint = hints[nextHintIndex];
                    if (nextHint !== undefined) {
                        if (currentHint.beforeHide) {
                            currentHint.beforeHide();
                        }
                        if (nextHint.beforeShow) {
                            nextHint.beforeShow();
                        }
                        setHintStoreData({
                            ...hintStoreData,
                            active: nextHint.hintKey,
                            first: false,
                            last: (nextHintIndex + 1) === hints.length,
                        });
                        setActiveHint(nextHint);
                        if (nextHint.onShow) {
                            nextHint.onShow();
                        }
                        if (currentHint.onHide) {
                            currentHint.onHide();
                        }
                    }
                }
            }
        }
    }

    function showPreviousHint () {
        if (hints.length > 0 && active !== "") {
            const currentHintIndex = hints.findIndex(hint => hint.hintKey === active);
            if (currentHintIndex !== -1) {
                const prevHintIndex = currentHintIndex - 1;
                const prevHint = hints[prevHintIndex];
                if (prevHint !== undefined) {
                    if (prevHint.beforeShow) {
                        prevHint.beforeShow();
                    }
                    setHintStoreData({
                        ...hintStoreData,
                        active: prevHint.hintKey,
                        first: prevHintIndex === 0,
                        last: false,
                    });
                    setActiveHint(prevHint);
                    if (prevHint.onShow) {
                        prevHint.onShow();
                    }
                }
            }
        }
    }

    function resetHints () {
        const currentHint = active !== "" ? hints.find(hint => hint.hintKey === active) : undefined;
        if (currentHint?.beforeHide) {
            currentHint.beforeHide();
        }
        setHintStoreData({
            ...hintStoreData,
            active: "",
            first: false,
            last: false,
        });
        setActiveHint(undefined);
        if (currentHint?.onHide) {
            currentHint.onHide();
        }
    }

    return {
        data: hintStoreData,
        activeHint: hideHints === true ? undefined : activeHint,
        showHint,
        showNextHint,
        showPreviousHint,
        resetHints,
    };
}
