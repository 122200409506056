query DataSources {
	dataSources {
		id
		type
		queriesEnabled
		info {
			status
			error {
				code
				message
				details {
					code
				}
			}
		}
		authentication {
			username
		}
		name
		hostname
		lastIngestedEndTime
		account {
			name
			id
		}
	}
}
