query CloudIMAWSResourceDetails($regions: [String], $token: [String], $unique: Boolean) {
    resources: cloudImResourceVpc(
        regions: $regions,
        tokens: $token,
        unique: $unique
    ) {
        entityAttributes {
            rootElement
        }
        entityKind
        timestamp
        utid
        source {
            rootElement
        }
    }
}
