/** This file defines the incident user impact view react component.  The 
 *  incident user impact view react component displays the impacted user 
 *  counts in a table.
 *  @module */
import {useContext, useEffect, useRef} from "react";
import { Query } from "reporting-infrastructure/types/Query.ts";
import { FILTER_NAME } from "components/sdwan/enums/filters.ts";
import { useQuery } from "utils/hooks/useQuery.ts";
import { Table } from "@tir-ui/react-components";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade.tsx";
import { STRINGS } from "app-strings";
import { PriorityLEDFormatter } from "reporting-infrastructure/utils/formatters/priority-led-formatter/PriorityLEDFormatter.tsx";
import { AutoUpdateContext } from "pages/network-summary/NetworkSummaryPage.tsx";
import { PRIORITY_INDEX } from "components/enums/Priority.ts";
import { PopoverPosition, Tooltip, Classes } from "@blueprintjs/core";
import USER_IMPACT from './user-impact.graphql';
import "./IncidentUserImpactView.scss";

/** the limit to use when querying the top incidents by user impact */
const limit: number = 10;

/** an interface that describes the properties that can be passed in to the user impact view component.*/
export interface IncidentUserImpactViewProps {
    /** the handler for the click event on the bar chart, it just forwards the event to the parent component. */
    onClickEvent?: (id: string| undefined) => void;
}

/** Renders the impacted services react component.
 *  @param props the properties passed in.
 *  @returns JSX with the impacted services component.*/
export function IncidentUserImpactView(props: IncidentUserImpactViewProps): JSX.Element {
    const autoUpdate = useContext(AutoUpdateContext);
    let {loading, data, error, run} = useQuery({
        query: new Query(USER_IMPACT),
        name: "users",
        queryVariables: { limit: limit },
        consumedFilters: [
            FILTER_NAME.priority,
            FILTER_NAME.incidentStatus,
            FILTER_NAME.completionStatus
        ],
        timeNotRequired: true,
    });

    const lastSequenceNumber = useRef(0);
    useEffect(
        () => {
            if (lastSequenceNumber.current !== autoUpdate.sequenceNumber) {
                lastSequenceNumber.current= autoUpdate.sequenceNumber;
                run({
                    fetchPolicy: "no-cache",
                    queryVariables: { limit: limit }
                });
            }
        }, 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [autoUpdate]
    );

    const columns = [
        {
            id: "priority",
            // Header: STRINGS.incidentPriorities.label,
            Header: '',
            accessor: "priority",
            formatter: (record) => <PriorityLEDFormatter isSummary priority={record.priority} showStatusAsBackground/>,
            className: 'px-0 ',
        },
        {
            id: "description",
            Header: STRINGS.incidents.incident,
            accessor: "description",
            formatter: (record) => <Tooltip className={Classes.TOOLTIP_INDICATOR + " border-0 d-inline"}
                usePortal={false} content={record.description} position={PopoverPosition.BOTTOM} transitionDuration={50}>
                    <span className="overflow-elipsis-table-cell position-relative d-block overflow-hidden text-nowrap">
                        {record.description}
                    </span>
                </Tooltip>,
            className: 'summary-user-widget-max-width'
        },
        {
            id: "count",
            Header: STRINGS.networkDashboard.impactedUsers,
            accessor: "count",
            formatter: (record) => {
                return <span className="fw-bold">{record.count}</span>
            }
        },
    ];
    let userData: Array<any> = [];
    if (data && data?.impactedUsers?.incidentsUsers?.length > 0) {
        let count = 0;
        for (const incident of data.impactedUsers.incidentsUsers) {
            userData.push(
                {id: incident.incidentId, count: incident.count, priority: incident.priority, description: incident.description}
            );
            if (++count === limit) {
                // We can't show more than 5 in this table, DAL needs to give us an order by and a limit
                break;
            }
        }
    }

    // Sort the data by count and then by priority
    userData.sort((a, b) => {
        if (!a.priority || !b.priority) {
            return 0;
        }
        if (PRIORITY_INDEX[a.priority] > PRIORITY_INDEX[b.priority]) {
            return 1;
        } else if (PRIORITY_INDEX[a.priority] < PRIORITY_INDEX[b.priority]) {
            return -1;
        } else {
            return 0;
        }
    }).sort((a, b) => {
        if (a.count === undefined || a.count === null || b.count === undefined || b.count === null) {
            return 0;
        }
        return a.count > b.count ? 1 : a.count < b.count ? -1 : 0;
    }).reverse();
    
    const dataAvailable = userData.length > 0;
    return <DataLoadFacade loading={loading} error={error} data={data} showContentsWhenLoading={dataAvailable} transparent={dataAvailable}>
        {
            dataAvailable ?
            <Table className="user-impact-view" id="usersList" columns={columns} data={userData || []} interactive={true} defaultPageSize={10}
                bordered={false}
                compact={true}
                onRowClick={(e) => {
                    if (props.onClickEvent) {
                        props.onClickEvent(!e.ctrlKey ? e.id : null);
                    }
                }}
                transparent
            /> :
            <div className="absolute-center">{STRINGS.no_data_to_display}</div>
        }
    </DataLoadFacade>;
};
 
