import { SEVERITY } from "components/enums/Severity.ts";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade.tsx";
import { TwoColumnContainer } from "components/common/layout/containers/two-column-container/TwoColumnContainer.tsx";
import { PageWithHeader } from "components/sdwan/layout/page-with-header/PageWithHeader.tsx";
import { RiverbedAdvisorContainer } from "components/sdwan/layout/riverbed-advisor/RiverbedAdvisorContainer.tsx";
import { RiverbedAdvisorCard } from "components/sdwan/layout/riverbed-advisor/RiverbedAdvisorCard.tsx";
import { Query } from "reporting-infrastructure/types/Query.ts";
import { useQuery } from "utils/hooks/useQuery.ts";
import { useQueryParams } from "utils/hooks/useQueryParams.ts";
import { getURL } from "utils/hooks/useQueryParams.ts";
import { RunbookView } from "pages/riverbed-advisor/views/runbook-view/RunbookView.tsx";
import { IconNames } from "@tir-ui/react-components";
import { PARAM_NAME } from "components/enums/QueryParams.ts";
import ANALYTICS_INCIDENTS from 'pages/riverbed-advisor/analytics-incidents.graphql';

/** Renders the riverbed advisor page.
 *  @param props the properties passed in.
 *  @returns JSX with the riverbed advisor page component.*/
const RiverbedAdvisorPage = (props) => {
    //const [issue, setIssueToDisplay] = useState("");
    const { params } = useQueryParams({
		listenOnlyTo: [PARAM_NAME.incidentId]
    });
    const incidentId = (params[PARAM_NAME.incidentId] ? params[PARAM_NAME.incidentId] : "");
    const {loading, data, error} = useQuery({
        name: "analyticsIncidentsQuery",
        query: new Query(ANALYTICS_INCIDENTS)
    });

    let incidents: Array<any> = [];
    if (!loading) {
        if (data) {
            incidents = data?.incidents?.nodes || [];
        }
    }

    let incidentsJsx;
    for (let i = 0; i < incidents.length; i++) {
        const incidentSpec = incidents[i];
        const incidentJsx = <RiverbedAdvisorCard key={incidentSpec.id + "-card"} status={incidentSpec.severity.value}
            showActionControl={true}
            actionLabel={"view details"}
            actionURL={handleViewDetailsClicked(incidentId, incidentSpec)}
            //onActionButtonClicked={() => {
            ////    (issue !== issueSpec.id ? setIssueToDisplay(issueSpec.id) : setIssueToDisplay(-1))
            //    (issue !== issueSpec.id ? setQueryParams({i: issueSpec.id, m: ""}) : setQueryParams({i: "", m: ""}))
            //}}
        >
            <div dangerouslySetInnerHTML={{__html: incidentSpec.text}} />
        </RiverbedAdvisorCard>;
        incidentsJsx = (i !== 0 ? <>{incidentsJsx} {incidentJsx}</> : incidentJsx);
    }

    return (<>
        <PageWithHeader name={RiverbedAdvisorPage.name} title={"Riverbed Advisor"} status={SEVERITY.CRITICAL} icon={IconNames.DEVICES} showAlerts={false}>
            <TwoColumnContainer>
                <RiverbedAdvisorContainer>
                    <DataLoadFacade loading={loading} error={error} data={data}>
                        {incidentsJsx}
                    </DataLoadFacade>
                </RiverbedAdvisorContainer>
                <div>
                    {incidentId !== "" && <RunbookView key={incidentId} incidentId={incidentId} runbookId="0"/> }
                </div>
            </TwoColumnContainer>
        </PageWithHeader>
    </>);
};

export default RiverbedAdvisorPage;

/** handles the view details link being clicked.
 *  @param incidentId the id of the currently visible incident.
 *  @param incidentSpec the specification for the incident the user has clicked on.
 *  @returns the URL that should be loaded.*/
function handleViewDetailsClicked(incidentId, incidentSpec): string {
    const queryParams = (incidentId !== incidentSpec.id ? {[PARAM_NAME.incidentId]: incidentSpec.id} : {[PARAM_NAME.incidentId]: ""});
    return getURL(undefined, queryParams);
}
