/** This module defines a React component that displays the impacted application search result.
 *  @module
 */
import type { SearchItem, SearchResultsItemProps } from "components/common/search/search-items/search-results-item/SearchResultsItem.tsx";
import { LinkInfo, LinkType } from "components/common/search/search-links-group/LinksGroup.tsx";
import { STRINGS } from "app-strings";
import { useUserPreferences } from "utils/hooks/useUserPreferences.ts";
import { SearchStandardItem } from "components/common/search/search-items/search-standard-item/SearchStandardItem.tsx";
import { HIDDEN_STATES, INCIDENT_STATUS } from "components/enums/IncidentStatus.ts";
import { PRIORITY } from "components/enums/Priority.ts";
import { DEFAULT_SEARCH_PREF } from "utils/services/UserPrefsTypes.ts";
import type { SearchPreference } from "utils/services/UserPrefsTypes.ts";
import { 
    getFacetValue, getImpactedApplicationFacetForEngine, getPriorityFacetForEngine, getStatusFacetForEngine
} from "pages/incident-search/IncidentSearchUtils.ts";

/** Renders the search result for the impacted application.
 *  @param data the properties passed in.
 *  @returns JSX with the impacted application search result react component.*/
export function SearchImpactedApplicationItem({ data }: SearchResultsItemProps) {
    const userPreferences = useUserPreferences({
        listenOnlyTo: {search: {}}
    });
    const searchPreferences: SearchPreference = {...DEFAULT_SEARCH_PREF, ...userPreferences.search};

    let modifiedData: SearchItem = data;
    try {
        modifiedData = JSON.parse(JSON.stringify(data));
        const impactedApplication: any = data.impactedApplication;
        if (impactedApplication) {
            modifiedData.formattedData.description = STRINGS.formatString(STRINGS.SEARCH.descImpactedApplication, {impactedApplication: impactedApplication.name});
        }    
    } catch (error) {
        console.error(error);
    } 

    const links: Array<LinkInfo> = [
        {
            path: "incident-search",
            label: STRINGS.SEARCH.viewIncidents,
            type: LinkType.PAGE,
            params: {
                searchType: "incident",
                facets: {
                    [getImpactedApplicationFacetForEngine(searchPreferences.srchEngine)]: [data.formattedData.name],
                    [getPriorityFacetForEngine(searchPreferences.srchEngine)]: [
                        getFacetValue(PRIORITY.CRITICAL, searchPreferences.srchEngine), getFacetValue(PRIORITY.HIGH, searchPreferences.srchEngine), 
                        getFacetValue(PRIORITY.MODERATE, searchPreferences.srchEngine), getFacetValue(PRIORITY.LOW, searchPreferences.srchEngine)
                    ],
                    [getStatusFacetForEngine(searchPreferences.srchEngine)]: [
                        INCIDENT_STATUS.NEW, INCIDENT_STATUS.INVESTIGATING, INCIDENT_STATUS.ON_HOLD, INCIDENT_STATUS.DISMISSED, INCIDENT_STATUS.CLOSED
                    ].filter(status => !HIDDEN_STATES.includes(status)).map(state => getFacetValue(state, searchPreferences.srchEngine))
                }
            }
        }
    ];
    return <SearchStandardItem data={modifiedData} links={links} showCharts={false} showHealth={false} />;
}
