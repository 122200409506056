/** This file defines the TextView React component.  The TextView React component displays 
 *  text passed in from the runbook view.  This component is used within a runbook view.
 *  @module 
 */

import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade.tsx";
import { DataSet, BaseRunbookViewProps } from "pages/riverbed-advisor/views/runbook-view/Runbook.type.ts";

/** Creates the text view, which is a component that displays text with analytics data.
 *  @param props an object with the properties passed to the text view.
 *  @returns JSX with the text component.*/
export const TextView = (props: BaseRunbookViewProps): JSX.Element => {
    const dataset: DataSet | undefined = props.datasets?.length > 0 ? props.datasets[0] : undefined;

    return <DataLoadFacade loading={props.loading} error={undefined} data={dataset}>
        {dataset && dataset.text && <div key={"text-row"} className="runbook-card-row">
            <div className="display-7 fw-bold d-inline-block ms-1 mt-3 mb-2">
                {dataset.text}
            </div>
        </div>}
    </DataLoadFacade>;
};
