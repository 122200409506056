import { Button } from '@blueprintjs/core';
import { IconNames } from '@tir-ui/react-components';
import React, { ReactNode } from 'react';

export interface OneColumnContainerProps {
    showBackButton?: boolean;
    backText?: string | JSX.Element;
    onBackClicked?: Function;
    className?: string;
    noPadding?: boolean;
    children?: ReactNode;
}

function OneColumnContainer({ showBackButton, onBackClicked, className, children, noPadding = false, ...props }: OneColumnContainerProps) {
    return <div className={"one-column-tab" + (noPadding ? "" : " pt-3 pe-5 ps-5") + (className ? " " + className : "")}>
        {showBackButton && <>
            <Button className={"back-button" + (props.backText ? " align-text-bottom" : "")} role="navigate back"
                icon={IconNames.ARROW_LEFT} minimal={true} onClick={(e) => onBackClicked && onBackClicked(e)}
            />
            {props.backText && <span className="display-6 ms-1">{props.backText}</span>}
        </>}
        {children}
    </div>;
}

export { OneColumnContainer };
