import { Modal } from '@tir-ui/react-components';
import { getIndicatorSummary } from 'pages/incident-details/views/primary-indicator/PrimaryIndicatorView.tsx';
import { IndicatorDetailedView } from 'components/hyperion/views/indicator-detailed-view/IndicatorDetailedView.tsx';
import { isBaselineIndicatorType } from 'components/enums/IndicatorTypes.ts';
import { IndicatorsListView } from 'components/hyperion/views/indicators-list-view/IndicatorsListView.tsx';
import { RunbookPathTraversal } from 'pages/incident-details/views/runbook-path-traversal/RunbookPathTraversal.tsx';
import { useQuery } from 'utils/hooks/useQuery.ts';
import { FILTER_NAME } from "components/sdwan/enums/filters.ts";
import { Query } from 'reporting-infrastructure/types/Query.ts';
import { STRINGS } from 'app-strings';
import './TimelineModal.scss';

import RUNBOOKS from "../../../pages/riverbed-advisor/views/runbook-view/runbooks.graphql";

export default function TimelineModal(props) {
    const runbooks = useQuery({
        query: new Query(RUNBOOKS),
        requiredFilters: [FILTER_NAME.incidentIds, FILTER_NAME.runbookId],
        filters: {
            [FILTER_NAME.incidentIds]: props.incidentId ? [props.incidentId] : props.runbookId ? [] : undefined,
            [FILTER_NAME.runbookId]: props.runbookId
        },
        skipGlobalFilters: true,
        timeNotRequired: true,
    });

    return (
        <Modal
            title={STRINGS.timelineOutputsView.dialogTitle}
            hideCancel
            onClose={() => props.onClose()}
            onSubmit={() => props.onClose()}
            customLabels={{
                submit: STRINGS.primaryIndicatorView.okBtnText,
            }}>
            <div className='timeline-dialog'>
                {['runbookIncident', 'lifecycleEvent'].includes(props.eventType) &&
                    <RunbookPathTraversal runbook={JSON.parse(props.template)} runbookOutput={runbooks?.data?.runbooks?.nodes[0]} />
                }

                {props.eventType === 'primaryIndicator' && (
                    <>
                        {props.objMetricMetaData &&
                            getIndicatorSummary(props.objMetricMetaData, (props.earliestIndicator?.entity || props.primaryIndicator?.entity), props.incident, (props.earliestIndicator || props.primaryIndicator))
                        }
                        <IndicatorDetailedView
                            className="flex-grow-1"
                            indicatorMapping={props.indicatorMapping}
                            indicatorTimeRange={props.timeRange}
                            isBaselineType={isBaselineIndicatorType(props.indicatorMapping.kind)}
                            annotationIndicator={props.earliestIndicator || props.primaryIndicator}
                            annotationIncident={props.incident}
                            incidentTimeRange={props.timeRange}
                            isPrimaryIndicator
                            runbooks={props.runbook}
                        />
                    </>
                )}

                {props.eventType === 'correlatedIndicator' &&
                    <IndicatorsListView
                        indicatorMappings={props.mappedIds.map(indicator => ({
                            entityId: indicator.entity.id,
                            metric: indicator.metric,
                            kind: indicator.kind,
                            sourceId: indicator.entity.source.id
                        }))}
                        indicatorsTimeRange={props.timeRange}
                        primaryEntityId={props.entity.id}
                        primaryIndicator={props.earliestIndicator ||props.primaryIndicator}
                        incident={props.incident}
                        runbooks={props.runbook}
                    />
                }
            </div>

        </Modal>
    )
}
