import { uniqBy } from 'lodash';
import { STRINGS } from 'app-strings';
import { RunbookNode } from 'utils/services/RunbookApiService.ts';

type Props = {
    runbooks: RunbookNode[];
}

const RunbooksContainingSubflowTable = ({ runbooks }: Props) => {
    return (
        <div style={{maxHeight: "350px", overflowY: "auto"}}>
            <table className="table mt-2">
                <thead>
                    <tr>
                        <th>{STRINGS.runbookEditor.saveDialog.confirmationModal.nameColumn}</th>
                        <th>{STRINGS.runbookEditor.saveDialog.confirmationModal.createdByColumn}</th>
                    </tr>
                </thead>
                <tbody>
                    {uniqBy(runbooks, 'id').map((runbook) => <tr>
                        <td>
                            <a href={`create-runbook?fid=${runbook.id}&fname=${runbook.name}&variant=${runbook.variant}`} rel="noreferrer" target="_blank">
                                {runbook.name}
                            </a>
                        </td>
                        <td>{runbook.createdByUser}</td>
                    </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default RunbooksContainingSubflowTable
