query HealthMonitor($startTime: String, $endTime: String) {
	monitor(filter: {time: {startTime: $startTime, endTime: $endTime}}) {
		analytics {
			byEntity {
				timestamp
				values {
					name
					value
				}
			}
			byMetric {
				timestamp
				values {
					name
					value
				}
			}
		}
		incidentGeneration {
			byType {
				timestamp
				values {
					name
					value
				}
			}
			rate {
				timestamp
				values {
					name
					value
				}
			}
		}
		runbookExecution {
			successRate {
				timestamp
				values {
					name
					value
				}
			}
			requestTime {
				timestamp
				values {
					name
					value
				}
			}
			usage {
				timestamp
				values {
					name
					value
				}
			}
		}
		nodeStatus {
			usage {
				timestamp
				value
			}
		}
	}
	statistics(
        filter: {time: {startTime: $startTime, endTime: $endTime, granularity: "P1H"}}
    ) {
		dailyMetricsUsage {
			timestamp
			value
		}
	}
}
