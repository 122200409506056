/** This module creates a new node for the react-flow graph that represents a switch node.
 *  @module
 */

import { CSSProperties, useRef } from 'react';
import { Handle, Position, NodeProps, Connection, Edge } from 'react-flow-renderer';
import { BaseNodeContent } from 'components/common/graph/react-flow/nodes/BaseNodeContent.tsx';
import { defaultDirection } from 'components/common/graph/react-flow/ReactFlowGraph.tsx';
import './SwitchNode.css';

// The base style for all the handles
const baseHandleStyle: CSSProperties = { background: '#b1b1b7' };

const onConnect = (params: Connection | Edge) => console.log('handle onConnect', params);

/** Renders the switch node component.
 *  @param props the properties passed in.
 *  @returns JSX with the switch node component.*/
export default function SwitchNode({
    sourcePosition = Position.Left,
    targetPosition = Position.Right,
    ...props
}: NodeProps): JSX.Element {
    const isConnectable = props.isConnectable;

    // Create the input ports
    const inputPorts: Array<JSX.Element> = [];
    const numPorts = 1;
    const increment = Math.floor(100 / numPorts);
    const cssPos = (defaultDirection === "LR" ? "top" : "left");
    const position = (defaultDirection === "LR" ? Position.Left : Position.Top);
    {
        // Only one for now
        const index = 0;
        const handleStyle = {...baseHandleStyle, [cssPos]: (Math.floor(increment / 2) + index * increment) + '%'};
        inputPorts.push(
            <Handle key={"input-handle-node-" + props.id + "-" + index} type="target" position={position} 
                id={index.toString()} style={handleStyle} onConnect={onConnect} 
            />
        );
    }

    // Create the output ports
    const className = "source-plus-handle";
    const outputPorts: Array<JSX.Element> = [];
    if (props.data?.properties?.length > 0) {
        let rules = [];
        for (const prop of props.data?.properties) {
            if (prop.key === "rules") {
                rules = rules.concat(prop.value);
            }
        }
        const numPorts = rules.length;
        const increment = Math.floor(100 / numPorts);
        const cssPos = (defaultDirection === "LR" ? "top" : "left");
        const position = (defaultDirection === "LR" ? Position.Right : Position.Bottom);
        for (let index = 0; index < numPorts; index++) {
            const handleStyle = {...baseHandleStyle, [cssPos]: (Math.floor(increment / 2) + index * increment) + '%'};
            outputPorts.push(
                <Handle key={"output-handle-node-" + props.id + "-" + index} type="source" position={position} 
                    id={index.toString()} style={handleStyle} 
                    isConnectable={isConnectable} 
                    className={className} 
                    onMouseUp={() => nodeRef.current.showMenu(index)}
                />
            );
        }
    }

    const nodeRef = useRef<any>();

    return (<>
        <BaseNodeContent ref={nodeRef} sourcePosition={sourcePosition} targetPosition={targetPosition} {...props}
            numOutputHandles={outputPorts.length}
            inputHandles={inputPorts} 
            outputHandles={outputPorts}
        />
    </>);
};
