import { useEffect, useState } from 'react';
import { Alert, Button, ButtonGroup, Callout, Classes, Intent } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { STRINGS } from 'app-strings';
import { IQAssistService, IQAssistRequest, assistAdminvalues, LimitedRequests } from 'utils/services/IQAssistAdminService'
import './AssistAdminView.scss'
import { ErrorToaster } from '@tir-ui/react-components';
import { getErrorsFromApiResponse } from 'utils/errors/ErrorUtils';

const AssistAdminView = () => {
    const LINK_URL = "https://www.riverbed.com/trust-center";
    const [status, setStatus] = useState<IQAssistRequest>({} as IQAssistRequest);
    const [alertIsOpen, setAlertIsOpen] = useState<boolean>(false);
    const [hasError, setHasError] = useState<string>("")

    useEffect(() => {
        IQAssistService.getAssistStatus().then(payload => {
            setStatus(payload);
        }).catch(error => {
            console.log('error', error);
            setHasError(error.response.data.error.message);
            ErrorToaster({
                message: getErrorsFromApiResponse(
                    error.response.data.error, STRINGS.IQ_ASSIST_ADMIN_PAGE.errors,
                    error.response.data.error.message
                )
            });
        })
    }, []);

    const updateStatus = (requestData: LimitedRequests) => {
        IQAssistService.updateAssistStatus(requestData).then((payload: IQAssistRequest) => {
            setStatus(payload);
        }).catch(error => {
            console.log('error', error);
            setHasError(error.response.data.error.message);
            ErrorToaster({
                message: getErrorsFromApiResponse(
                    error.response.data.error, STRINGS.IQ_ASSIST_ADMIN_PAGE.errors,
                    error.response.data.error.message
                )
            });
        })
    }

    return (
        <div className='toggle-iq-assist-admin'>
            <h5 className='fw-bold'>{STRINGS.IQ_ASSIST_ADMIN_PAGE.sectionTitle}</h5>
            <p>{STRINGS.formatString(STRINGS.IQ_ASSIST_ADMIN_PAGE.intro,
                <a href={LINK_URL} target='_blank'>{STRINGS.IQ_ASSIST_ADMIN_PAGE.link}</a>, <br />)}
            </p>

            {status.value === assistAdminvalues.disabled &&
                <Button intent={Intent.PRIMARY} icon={IconNames.DirectionRight}
                    onClick={() => updateStatus({ value: assistAdminvalues.enabled })}
                >{STRINGS.IQ_ASSIST_ADMIN_PAGE.requestCTA}
                </Button>
            }

            {(status.value === assistAdminvalues.requestDisabled || status.value === assistAdminvalues.requestEnabled) &&
                <Callout intent={Intent.WARNING} className={'d-flex justify-content-between rounded align-items-center ' + Classes.ELEVATION_2}>
                    {
                        status.value === assistAdminvalues.requestEnabled ? <span>{STRINGS.IQ_ASSIST_ADMIN_PAGE.requestEnabledPanel}</span> : 
                        status.value === assistAdminvalues.requestDisabled ? <span>{STRINGS.IQ_ASSIST_ADMIN_PAGE.requestDisabledPanel}</span> : null
                    }
                    <ButtonGroup>
                        <Button icon={IconNames.CROSS} onClick={() => {
                            if (status.value === assistAdminvalues.requestDisabled) {
                                updateStatus({ value: assistAdminvalues.enabled })
                            } else if (status.value === assistAdminvalues.requestEnabled) {
                                updateStatus({ value: assistAdminvalues.disabled })}
                            }
                        }>
                            {STRINGS.IQ_ASSIST_ADMIN_PAGE.cancelCTA}
                        </Button>
                    </ButtonGroup>
                </Callout>
            }
            {status.value === assistAdminvalues.enabled &&
                <Callout intent={Intent.SUCCESS} className={'d-flex justify-content-between rounded align-items-center ' + Classes.ELEVATION_2}>
                    <span>{STRINGS.IQ_ASSIST_ADMIN_PAGE.enabledPanel}</span>
                    <ButtonGroup>
                        <Button icon={IconNames.CROSS} onClick={() => setAlertIsOpen(true)}>
                            {STRINGS.IQ_ASSIST_ADMIN_PAGE.disableCTA}
                        </Button>
                    </ButtonGroup>
                </Callout>
            }
            {!!hasError &&
                <Callout intent={Intent.DANGER} className={'d-flex rounded align-items-center ' + Classes.ELEVATION_2}>
                    <span>{STRINGS.IQ_ASSIST_ADMIN_PAGE.errors.Generic}</span>
                </Callout>
            }
            <Alert intent={Intent.PRIMARY} icon={IconNames.WARNING_SIGN} isOpen={alertIsOpen}
                className='iq-assist-admin-alert'
                cancelButtonText={STRINGS.IQ_ASSIST_ADMIN_PAGE.alertCancel}
                confirmButtonText={STRINGS.IQ_ASSIST_ADMIN_PAGE.alertOK}
                onCancel={() => setAlertIsOpen(false)}
                onConfirm={() => {
                    updateStatus({ value: assistAdminvalues.disabled });
                    setAlertIsOpen(false);
                }}
                canEscapeKeyCancel
            >
                <p>{STRINGS.formatString(STRINGS.IQ_ASSIST_ADMIN_PAGE.warning, <br/>)}</p>
            </Alert>
        </div>
    )
}

export default AssistAdminView
