query deviceSearch($top: Int!, $skip: Int!, $count: Boolean!, $search: String, $searchFields: [NetworkDeviceSearchFieldEnum], $facets: [NetworkDeviceRequestFacetType!], $filter: NetworkDeviceSearchFilterType, $order: [NetworkDeviceSearchSortInputType!]) {
    searchItems: searchNetworkDevices(
        top: $top,
        skip: $skip,
        count: $count,
        facets: $facets,
        filter: $filter,
        order: $order,
        search: $search,
        searchFields: $searchFields
    ) {
        info {
            totalCount
            facets {
                key
                result {
                    count
                    items {
                        value
                        count
                    }
                }
            }
            groupedFacets {
                key
                results {
                    group
                    count
                    items {
                        count
                        value
                    }
                }
            }
        }
        page {
            id
            name
            ipaddr
            hostname
            location {
                id
                name
                city
                state
                country
                countryCode
                latitude
                longitude
            }
            networkInterfaces {
                id
                name
                ipaddr
                ifIndex
            }
            locationId
            type
            elementType
            isGateway
            model
            osVersion
            serialNumber
            vendor
            reportedBy {
                dataSourceId
                dataSourceType
                entityId
            }
            customProperties {
                id
                name
                assignedValue {
                    id
                    name                                            
                }
            }
        }
    }
}
