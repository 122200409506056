//ALL THE MODAL COMPONENTS
import { InviteUserModal } from 'components/modals/InviteUserModal.tsx';
import { EditDomainsModal } from 'components/modals/EditDomainsModal.tsx';
import { EditUserModal } from 'components/modals/EditUserModal.tsx';
import { AddNotesModal } from 'components/modals/AddNotesModal.tsx';
import { AddClientSecretModal } from 'components/modals/api-access/AddClientSecretModal/AddClientSecretModal.tsx';
import GenerateClientSecretsStepOneModal from 'components/modals/api-access/GenerateClientSecretsStepOneModal/GenerateClientSecretsStepOneModal.tsx';
import { GenerateClientSecretsStepTwoModal } from 'components/modals/api-access/GenerateClientSecretsStepTwoModal/GenerateClientSecretsStepTwoModal.tsx';
import { ManageClientSecretsModal } from 'components/modals/api-access/ManageClientSecretsModal/ManageClientSecretsModal.tsx';
import { DeleteCustomPropertiesModal } from 'components/modals/custom-properties/DeleteCustomPropertiesModal/DeleteCustomPropertiesModal.tsx';
import AddCustomPropertyModal from 'components/modals/explorer/custom-properties/AddCustomPropertyModal/AddCustomPropertyModal.tsx';
import ImportCustomPropertiesModal from 'components/modals/explorer/custom-properties/ImportCustomPropertiesModal/ImportCustomPropertiesModal.tsx';
import { SetCustomPropertiesModal } from 'components/modals/explorer/custom-properties/SetCustomPropertiesModal/SetCustomPropertiesModal.tsx';
import { DeleteRunbookOutputsModal } from 'components/modals/DeleteRunbookOutputsModal/DeleteRunbookOutputsModal.tsx';
import TimelineModal from 'components/modals/timeline/TimelineModal.tsx';
import CloudIMDataSourceConfigModal from 'components/modals/administration/CloudIMDataSource/CloudIMDataSourceConfigModal.tsx';
import { CloudIMLegal } from 'pages/cloudim/views/cloudim-legal/CloudIMLegal.tsx';
import PropertiesModal from 'components/common/graph/cloudim-react-flow/components/properties/modal/PropertiesModal.tsx';
import ForecastingModal from 'components/modals/forecasting/ForecastingModal.tsx';

export const MODALS: { [index: string]: React.ComponentType<any> } = {
    inviteUserModal: InviteUserModal,
    editDomainsModal: EditDomainsModal,
    editUserModal: EditUserModal,
    addNotesModal: AddNotesModal,
    // Client Secrets
    generateClientSecretsStepOneModal: GenerateClientSecretsStepOneModal,
    generateClientSecretsStepTwoModal: GenerateClientSecretsStepTwoModal,
    addClientSecretModal: AddClientSecretModal,
    manageClientSecretsModal: ManageClientSecretsModal,
    // Custom Properties
    deleteCustomPropertiesModal: DeleteCustomPropertiesModal,
    addCustomPropertyModal: AddCustomPropertyModal,
    importCustomPropertiesModal: ImportCustomPropertiesModal,
    setCustomPropertiesModal: SetCustomPropertiesModal,
    // Runbook Analyses
    deleteRunbookOutput: DeleteRunbookOutputsModal,
    // Timeline Modal
    timelineModal: TimelineModal,
    // CloudIM Modals
    cloudIMDataSourceModal: CloudIMDataSourceConfigModal,
    cloudIMLegal: CloudIMLegal,
    cloudIMPropertiesModal: PropertiesModal,
    // Forecasting Modal
    forecastingModal: ForecastingModal
};

