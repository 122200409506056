/** This module contains the component for creating the visualization framework layout.  The visualzation framework layout displays
 *  visualization framework widgets in either a horizontal, vertical or grid layout.
 *  @module
 */

import React, { ReactNode } from "react";
import { LayoutConfig, LAYOUT_TYPE } from "./Layout.type.ts";
import ReactGridLayout from "./ReactGridLayout.tsx";


/** this interface defines the properties passed into the Layout React component. */
export interface LayoutProps {
    /** the current LayoutConfig to use. */
    layout: LayoutConfig;
    /** the handler for layout changes. */
    onLayoutChanged?: (layout: any) => void;
    /** the widgets that are displayed in the layout. */
    children?: ReactNode;
}

/** Renders the visualization framework layout.
 *  @param props the properties passed in.
 *  @returns JSX with the visualization framework layout component.*/
const Layout = (props: LayoutProps): JSX.Element => {
    return props.layout.type !== LAYOUT_TYPE.CUSTOM ? <div
        className={
            "d-flex justify-content-start align-content-start" +
            (props.layout.type === LAYOUT_TYPE.GRID ? " flex-wrap" : "") +
            (props.layout.type === LAYOUT_TYPE.VERTICAL ? " flex-column" : "")
        }
    >
        {React.Children.toArray(props.children)}
    </div> : 
    <ReactGridLayout widgets={React.Children.toArray(props.children)} layout={props.layout}
        onLayoutChanged={(layout: any) => {
            if (props.onLayoutChanged) {
                props.onLayoutChanged(layout);
            }
        }}
    />;
};

export default Layout;
