/** This module contains the component for the runbook list page.
 *  @module
 */

import { STRINGS, HELP } from 'app-strings';
import { PageWithHeader } from 'components/sdwan/layout/page-with-header/PageWithHeader.tsx';
import { SDWAN_ICONS } from 'components/sdwan/enums/icons.ts';
import { RunbookListView } from 'pages/runbook-list/views/runbook-list/RunbookList.tsx';
import { Variant } from 'components/common/graph/types/GraphTypes.ts';

/** Renders the incident runbook list page.
 *  @param props the properties passed in.
 *  @returns JSX with the runbook list page component.*/
const IncidentRunbookListPage = (): JSX.Element => {
    return (
        <PageWithHeader 
            name="IncidentRunbookListPage" title={STRINGS.runbooks.incidentRunbooksTitle} 
            icon={SDWAN_ICONS.RUNBOOK} addPadding={true} showTimeBar={false} helpInfo={HELP.runbookList} 
        >
            <RunbookListView variant={Variant.INCIDENT}/>
        </PageWithHeader>
    );
};

export default IncidentRunbookListPage;
