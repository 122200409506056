query searchRunbooks($top: Int!, $skip: Int!, $count: Boolean!, $filter: RunbookSearchFilterType, $search: String, $searchFields: [RunbookSearchFieldEnum], $facets: [RunbookRequestFacetType!], $order: [SearchRunbookOutputSortInputType!]) {
      searchItems: searchRunbookOutputs(
        top: $top,
        skip: $skip,
        count: $count,
        filter: $filter,
        search: $search,
        searchFields: $searchFields,
        facets: $facets,
        order: $order
      ) {
        info {
            totalCount
            facets {
                key
                result {
                    count
                    items {
                        count
                        value
                    }
                }
            }
        }
        page {
            id
            name
            title
            variant
            entityType
            executionMethod
            timestamp
            status
            createdBy
        }
      }
    }
