query AlluvioEdges($startTime: String, $endTime: String) {
	edges(filter: { time: { startTime: $startTime, endTime: $endTime } }) {
		name
		type
		connected
		dataSources {
			name
			type
			hostname
			productVersion
		}
	}
}
