/** This module contains the component for editing a navigator widget's columns or other configuration
 *      properties.
 *  @module
 */
import { useState, useEffect, useMemo } from "react";
import { STRINGS } from "app-strings";
import { clone } from "lodash";
import { InputGroup, Button, Checkbox, Intent, NumericInput, HTMLSelect } from "@blueprintjs/core";
import { Icon, IconNames } from "@tir-ui/react-components";
import { APP_ICONS } from 'components/sdwan/enums/icons.ts';
import { DataOceanUtils } from "components/common/graph/editors/data-ocean/DataOceanUtils.ts";
import { TOP_BY_DIRECTION } from "components/common/graph/editors/data-ocean/DataOceanMetadata.type";
import { WIDGET_TYPE } from "../../widget/Widget.type";
import "./WidgetEditor.scss";

/** this interface defines the properties passed into WidgetEditor React component. */
export interface WidgetEditorProps {
    /** a string with the name of the widget whose properties are being edited. */
    widgetName: string;
    /** the WIDGET_TYPE with the selected widget type so the blade can be customized. */
    widgetType: WIDGET_TYPE;
    /** the object type to use when creating the new widget. */
    objType: string;
    /** a string array with the ids of the selected metric columns. */
    selectedColumns: Array<string>;
    /** a string array with the ids of all the columns that are available to be added to the widget. */
    displayColumns: Array<string>;
    /** an integer with the top limit. */
    limit: number;
    /** the id of the metric to be used in the top by. */
    topMetric: string;
    /** a String with the direction of the top by desc or asc. */
    topDirection: string;
    /** an integer with the duration of the query in minutes. */
    duration: number;
    /** the handler for settings change events. */
    settingsChange: (name: string, columns: Array<string>, limit: number, topMetric: string, topDirection: string, duration: number) => void;
    /** the handler for the blade close event. */
    bladeClose: () => void;
}

const group_column = {
    group_column: {
        id: "group_column",
        label: "Name & Details",
        type: "string",
        category: "Group column",
    },
};

const WidgetEditor = (props: WidgetEditorProps): JSX.Element => {
    const metrics = useMemo<any>(() => {
        return { ...(props.widgetType === WIDGET_TYPE.TABLE ? group_column : {}), ...DataOceanUtils.dataOceanMetaData.metrics };
    }, [props.widgetType]);

    const [widgetName, setWidgetName] = useState<string>(props.widgetName);
    const [columns, setColumns] = useState<Array<string>>(props.displayColumns);
    const [selectedColumns, setSelectedColumns] = useState<Array<string>>(props.selectedColumns || []);
    const [columnFilterValue, setColumnFilterValue] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const categories = Object.values(metrics)
        .map((metric: any) => {
            return metric.category;
        })
        .filter((value, index, array) => array.indexOf(value) === index);

    const [limit, setLimit] = useState<number>(props.limit || 10);
    const [topMetric, setTopMetric] = useState<string>(props.topMetric || "");
    const [topDirection, setTopDirection] = useState<string>(props.topDirection || TOP_BY_DIRECTION.desc);
    const [duration, setDuration] = useState<number>(props.duration || 15);
    
    const [openCategories, setOpenCategories] = useState<Array<string>>(categories.filter((category) => {
        let categoryHasSelectedMetric = false;
        Object.keys(metrics).map((metric) => {
            if (
                metrics[metric].category === category &&
                selectedColumns.includes(metric)
            ) {
                categoryHasSelectedMetric = true;
            }
            return undefined;
        });
        return categoryHasSelectedMetric;
     }));


    useEffect(() => {
        const columns = props.displayColumns;
        if (columnFilterValue) {
            setColumns(
                columns?.filter((columnId) => {
                    const name =
                        columnId === "group_column"
                            ? group_column.group_column.label
                            : metrics[columnId]?.label;
                    return name
                        .toLocaleLowerCase()
                        .includes(columnFilterValue.toLowerCase());
                })
            );
        } else {
            setColumns(columns);
        }
    }, [columnFilterValue, metrics, props.displayColumns]);

    const columnFilterClearIcon = (
        <Button
            icon={IconNames.SMALL_CROSS}
            minimal={true}
            onClick={() => {
                setColumnFilterValue("");
            }}
        />
    );

    const handleListsFilterChange = (event: any) => {
        setColumnFilterValue(event.target.value);
    };

    const handleColumnsChange = (event: any) => {
        if (!event.target.checked) {
            const columns = selectedColumns.filter(function (column) {
                return column !== event.target.id;
            });
            setSelectedColumns(columns);
        } else {
            setSelectedColumns((selectedColumns) => [
                ...selectedColumns,
                event.target.id,
            ]);
        }
        // Let's not send a columns change notification for each selection event because that causes the queries
        // to launch which is costly.
        //props.columnsChange(selectedColumns);
    };

    return (
        <div className="widget-editor p-2 pt-3">
            <div className="d-flex flex-column mb-2" >
                <label className="mb-0">{ STRINGS.navigators.widgetEditor.name.label }</label>
                <InputGroup
                    id={"widget-editor-name-field"}
                    onChange={(event: any) => {
                        setWidgetName(event.target.value);
                    }}
                    placeholder={STRINGS.navigators.widgetEditor.name.placeholder}
                    value={widgetName}
                    className="mb-3 mt-2"
                />
            </div>
            {[WIDGET_TYPE.TABLE, WIDGET_TYPE.BAR].includes(props.widgetType) && <>
                <span className="ps-1 display-9 fw-bold">{STRINGS.navigators.widgetEditor.columns}</span>
                <div className="widget-editor-list-items fw-normal pt-2">
                    <InputGroup 
                        id={"columnsFilterInput"} leftIcon="search" rightElement={columnFilterClearIcon}
                        placeholder={STRINGS.incidents.impactSummaryView.search} className="mb-3 mt-2"
                        value={columnFilterValue}
                        onChange={handleListsFilterChange}
                    />
                    {categories.length ? (
                        categories.map((category, index) => {
                            let showCategory = false;
                            Object.keys(metrics).map((metric) => {
                                if ( metrics[metric].category === category && columns.includes(metric) ) {
                                    showCategory = true;
                                }
                                return undefined;
                            });
                            if (showCategory) {
                                return (
                                    <div key={index} className="widget-editor-category mb-1" >
                                        <div key={"category-" + index} style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                if (!openCategories.includes(category)) {
                                                    setOpenCategories((columnCategoryOpen) => [...columnCategoryOpen, category]);
                                                } else {
                                                    let openedCategories = clone(openCategories);
                                                    openedCategories.splice(openedCategories.indexOf(category), 1);
                                                    setOpenCategories(openedCategories);
                                                }
                                            }}
                                        >
                                            <Icon icon={openCategories.includes(category) ? APP_ICONS.SECTION_OPEN : APP_ICONS.SECTION_CLOSED} />
                                            <span className="ps-1 fw-bold">{category}</span>
                                        </div>
                                        {openCategories.includes(category) &&
                                            Object.keys(metrics).map((metric) => {
                                                if (columns.includes(metric)) {
                                                    return (
                                                        metrics[metric].category === category && (
                                                            <div key={"column-" + metric} className="widget-editor-item display-9 mt-2 ms-3">
                                                                <Checkbox type="checkbox" id={metric}
                                                                    label={
                                                                        category === group_column.group_column.category
                                                                            ? group_column.group_column.label
                                                                            : DataOceanUtils.dataOceanMetaData.metrics[metric]?.label
                                                                    }
                                                                    checked={selectedColumns.includes(metric)}
                                                                    onChange={handleColumnsChange}
                                                                />
                                                            </div>
                                                        )
                                                    );
                                                } else return null;
                                            })}
                                    </div>
                                );
                            } else return null;
                        })
                    ) : (
                        <div className="widget-editor-item display-9">
                            {STRINGS.navigators.widgetEditor.noColumnsToEdit}
                        </div>
                    )}
                </div>
            </>}
            {[WIDGET_TYPE.PIE, WIDGET_TYPE.TIMESERIES].includes(props.widgetType) && <div className="d-flex flex-column mb-2" >
                <label className="mb-0">{ STRINGS.navigators.widgetEditor.metric.label }</label>
                <HTMLSelect
                    data-testid="edit-widget-select-one-metric"
                    name="edit-widget-select-one-metric"
                    fill={ true }
                    options={ DataOceanUtils.dataOceanMetaData.obj_types?.[props.objType]?.metrics.map((metricId) => {
                        const metric = DataOceanUtils.dataOceanMetaData.metrics[metricId];
                        return {label: metric.label, value: metricId};
                    }) }
                    defaultValue={selectedColumns?.length ? selectedColumns[0] : ""}
                    onChange={ e => {
                        const metric = e.target.value;
                        setSelectedColumns([metric]);
                    } }
                />
            </div>}
            {[WIDGET_TYPE.BUBBLE, WIDGET_TYPE.CORRELATION].includes(props.widgetType) && <div className="d-flex flex-column mb-2" >
                <label className="mb-0">
                    { props.widgetType === WIDGET_TYPE.CORRELATION ? STRINGS.navigators.widgetEditor.xMetric.label : STRINGS.navigators.widgetEditor.sizeMetric.label }
                </label>
                <HTMLSelect
                    data-testid="edit-widget-select-two-metrics-first"
                    name="edit-widget-select-two-metrics-first"
                    fill={ true }
                    options={ DataOceanUtils.dataOceanMetaData.obj_types?.[props.objType]?.metrics.map((metricId) => {
                        const metric = DataOceanUtils.dataOceanMetaData.metrics[metricId];
                        return {label: metric.label, value: metricId};
                    }) }
                    defaultValue={selectedColumns?.length ? selectedColumns[0] : ""}
                    onChange={ e => {
                        const metric = e.target.value;
                        setSelectedColumns([metric, selectedColumns?.length > 1 ? selectedColumns[1] : ""]);
                    } }
                />
                <label className="mb-0">
                    { props.widgetType === WIDGET_TYPE.CORRELATION ? STRINGS.navigators.widgetEditor.yMetric.label : STRINGS.navigators.widgetEditor.colorMetric.label }
                </label>
                <HTMLSelect
                    data-testid="edit-widget-select-two-metrics-second"
                    name="edit-widget-select-two-metrics-second"
                    fill={ true }
                    options={ DataOceanUtils.dataOceanMetaData.obj_types?.[props.objType]?.metrics.map((metricId) => {
                        const metric = DataOceanUtils.dataOceanMetaData.metrics[metricId];
                        return {label: metric.label, value: metricId};
                    }) }
                    defaultValue={selectedColumns?.length > 1 ? selectedColumns[1] : ""}
                    onChange={ e => {
                        const metric = e.target.value;
                        setSelectedColumns([selectedColumns?.length ? selectedColumns[0] : "", metric]);
                    } }
                />
            </div>}
            <div key="advanced" className="widget-editor-category mb-1">
                <div key={"category-advanced"}
                    onClick={() => {
                        if (!openCategories.includes("advanced")) {
                            setOpenCategories((columnCategoryOpen) => [...columnCategoryOpen, "advanced"]);
                        } else {
                            let openedCategories = clone(openCategories);
                            openedCategories.splice(openedCategories.indexOf("advanced"), 1);
                            setOpenCategories(openedCategories);
                        }
                    }}
                    style={{ cursor: "pointer" }}
                >
                    <Icon icon={openCategories.includes("advanced") ? APP_ICONS.SECTION_OPEN : APP_ICONS.SECTION_CLOSED} />
                    <span className="ps-1 fw-bold">{"advanced"}</span>
                </div>
            </div>
            {(openCategories.includes("advanced")) && <div className="add-widget-control-list-items fw-normal pb-4">
                <div className="d-flex flex-column mb-2" >
                    <label className="mb-0">{ STRINGS.runbookEditor.nodeLibrary.propertyLabels.top }</label>
                    <NumericInput
                        data-testid="edit-widget-top"
                        className="d-inline-block"
                        defaultValue={ limit }
                        name="limit"
                        style={ { width: "70px" } }
                        buttonPosition="none"
                        onBlur={ e => {
                            if (e.target.value) {
                                const limit = parseInt(e.target.value);
                                setLimit(limit);
                            }
                        } }
                    />
                </div>
                <div className="d-flex flex-column mb-2" >
                    <label className="mb-0">{ STRINGS.runbookEditor.nodeLibrary.propertyLabels.by }</label>
                    <HTMLSelect
                        data-testid="edit-widget-top-metric"
                        name="topBy.id"
                        fill={ true }
                        options={ DataOceanUtils.dataOceanMetaData.obj_types?.[props.objType]?.metrics.map((metricId) => {
                            const metric = DataOceanUtils.dataOceanMetaData.metrics[metricId];
                            return {label: metric.label, value: metricId};
                        }) }
                        defaultValue={ props.topMetric || "" }
                        onChange={ e => {
                            const metric = e.target.value;
                            setTopMetric(metric);
                        } }
                    />
                    <HTMLSelect
                        data-testid="edit-widget-top-direction"
                        name="topBy.direction"
                        fill={ true }
                        options={ [
                            {label: "Descending", value: TOP_BY_DIRECTION.desc}, 
                            {label: "Ascending", value: TOP_BY_DIRECTION.asc}
                        ] }
                        defaultValue={ props.topDirection || "" }
                        onChange={ e => {
                            const direction = e.target.value;
                            setTopDirection(direction);
                        } }
                    />
                </div>
                <div className="d-flex flex-column mb-2" >
                    <label className="mb-0">{ "Duration" }</label>
                    <NumericInput
                        data-testid="add-widget-duration"
                        className="d-inline-block"
                        defaultValue={ duration }
                        name="duration"
                        style={ { width: "70px" } }
                        buttonPosition="none"
                        onBlur={ e => {
                            if (e.target.value) {
                                const duration = parseInt(e.target.value);
                                setDuration(duration);
                            }
                        } }
                    />
                </div>
            </div>}
            <div className="mt-2">
                <Button className="me-2" aria-label="cancel" type="button"
                    onClick={() => {
                        props.bladeClose();
                    }}
                >
                    {STRINGS.navigators.widgetEditor.buttons.close}
                </Button>
                <Button
                    intent={Intent.SUCCESS} aria-label="submit" disabled={false} type="submit" loading={loading}
                    onClick={() => {
                        setLoading(true);
                        props.settingsChange(widgetName, selectedColumns, limit, topMetric, topDirection, duration);
                        setTimeout(() => {
                            setLoading(false);
                        }, 500);
                    }}
                >
                    {STRINGS.navigators.widgetEditor.buttons.apply}
                </Button>
            </div>
        </div>
    );
};

export { WidgetEditor };
