/** This module creates a mock version of the AppAuthService which is a wrapper around
 *      the TirAuthService
 *  @module
 */
import { AuthService as TirAuthService, ITenant } from '@tir-ui/azure-auth'
import { AppUserAccount, AuthService } from "utils/services/AppAuthService";

/** this class is an implementation of the auth service. */
export class EmbedAppAuthService implements AuthService {
    /** a boolean value, true if the user is logged in.  We are simulating the auth service so start with true. */
    private isLoggedIn = true;
    isEmbed = true;
    
    public static tenant: ITenant;

    static async init() {
        if (EmbedAppAuthService.tenant) {
            //block anybody from getting away with double initialization
            console.error("Initializing EmbeddedAuthService more than once!");
            return;
        }
    }

    protected async headers(_isPublic = false, etag): Promise<object> {
        if (etag) {
            return {
                "If-Match": etag,
            };
        }

        return {};
    }

    /** logs out the user. */
    logout(): void {
        this.isLoggedIn = false;
        console.debug("you logged out");
    }

    /** returns the auth token.
     *  @returns a promise with the token. */
    async getToken(): Promise<string> {
        return "noToken";
    }

    /** returns the tenant object with the tenant information.
     *  @returns the tenant object with the tenant information. */
    getTenant(): ITenant {
        const tenantId = this.getTenantId();

        return {
            tenant_id: tenantId,
            halt: false,
        };
    }

    /** returns the tenant id.
     *  @returns a String with the tenant id. */
    getTenantId() {
        return sessionStorage.getItem('tenantId') || "tenantid";
    }

    /** returns a string with the region.
     *  @returns a String with the region. */
    getRegion(): string {
        return sessionStorage.getItem('region') || "region";
    }

    /** returns a string with the subscription status.
     *  @returns a String with the subscription status. */
    getSubscription(): boolean {
        return false;
    }

    /** returns the user account.
     *  @returns a reference to the user account object. */
    getUserAccount(): AppUserAccount {
        return {
            getUsername: () => "embeddeduser@riverbed.com",
            getName: () => "embedded user",
            getUserId: () => "embeddeduserid",
        };
    }

    /** returns whether or not the user is authenticated.
     *  @returns a boolean value, true if we are authenticated, false otherwise. */
    isAuthenticated(): boolean {
        return this.isLoggedIn;
    }

    /** returns whether or not the user is an administrator of the whole portfoloio of products.
     *  @returns a boolean value, true if the user is admistrator of the whole portfolio of products. */
    isUserPortfolioAdmin(): boolean {
        return false;
    }

    /** returns whether or not the user is an administrator of the specified product.
     *  @param product a String that uniquely identifies the product.
     *  @returns a boolean value, true if the user is admistrator of the specified product. */
    isUserProductAdmin(product: string): boolean {
        let aprole = this.getCookieValue("aprole");
        return product === "gelato" && aprole === "admin";
    }

    /** returns whether or not the user has write access to the specified product.
     *  @param product a String that uniquely identifies the product.
     *  @returns a boolean value, true if the user is has write access to the specified product. */
    userHasWriteAccess(product: string): boolean {
        let aprole = this.getCookieValue("aprole");
        return (
            product === "gelato" && (aprole === "write" || aprole === "admin")
        );
    }

    /**
     * Finds multiple permissionss assigned to a given user.
     */
	userHasMultiplePermissions(permissionss: string[]): boolean {
		return TirAuthService.userHasMultiplePermissions(permissionss);
	}

    /**
     * Determines if a user only has one single permission. If you need to
     * find multiple permissionss, or a user is assigned multiple permissionss, refer to
     * `userHasMultiplePermissionss()` static method.
     *
     * WARN: if `permission` array is greater than one, will return `false`.
     * Please refer to `userHasMultiplePermissions` instead if user is assigned
     * multiple permissionss.
     */
	userHasSinglePermission(permission: string[]): boolean {
		return TirAuthService.userHasSinglePermission(permission);
	}

    /** Get cookie values
     * @param matchkey a string value of the key for the cookie pair. */
    getCookieValue(matchkey: string): string {
        let str_cookie = document.cookie;
        if (!str_cookie) return "";
        let obj_cookies: any = decodeURIComponent(str_cookie)
            .split(";")
            .reduce((cookies, cookie) => {
                const [name, value] = cookie.split("=").map((c) => c.trim());
                return { ...cookies, [name]: value };
            }, {});
        return obj_cookies[matchkey] ? obj_cookies[matchkey] : "";
    }
}
