/** This module contains the component that displays a dialog that shows the trend analysis chart
 *  for the capacity planning demo.  This version of the dialog just displays the trending chart.
 *  There is another version of the dialog that allows users to configure the thresholds and then 
 *  display the chart.  That dialog is called the CapacityPlanningModal.
 *  @module
 */
import React, { useState, useCallback } from "react";
import { Classes, Dialog } from "@blueprintjs/core";
import classNames from "classnames";
import AnalysisChart from "pages/incident-search/modals/capacity-planning/AnalysisChart.tsx";
import { STRINGS } from "app-strings";

/** Renders the capacity planning dialog React component.
 *  @param props the properties passed in.
 *  @returns JSX with the cpacity planning dialog component.*/
const CapacityPlanningDialog = React.forwardRef((props: any, ref): JSX.Element => {
	const [isOpen, setIsOpen] = useState(false);
	const handleClose = useCallback(() => setIsOpen(false), []);
    const [rowData, setRowData] = useState<any>(null);

    React.useImperativeHandle(ref, () => ({
        handleOpen(rowData) {
            setIsOpen(!isOpen);
            setRowData(rowData);
        },
    }));

    return (
		<>
			<Dialog title={STRINGS.capacityPlanning.wizard.title} 
				style={{ width: 0.75 * window.innerWidth }}
                isOpen={isOpen} 
                autoFocus 
                canEscapeKeyClose
				canOutsideClickClose 
                enforceFocus 
                usePortal 
                onClose={handleClose}
			>
                <div className={classNames(Classes.DIALOG_BODY)}>
                    <AnalysisChart height={480} rowData={rowData} />
                </div>
			</Dialog>
		</>
	);
});

export default CapacityPlanningDialog;

