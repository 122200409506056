/** This file defines the cloudim rules view react component.
 *  @module */
import { STRINGS } from "app-strings";
import { SIZE } from 'components/enums/Sizes.ts';
import FacetPanel, { FacetPanelChildrenProps } from "components/reporting/facet/facet-panel/FacetPanel.tsx";
import { SEARCH_TYPE } from "components/reporting/facet/FacetUtils.ts";
import { RulesCard } from "pages/cloudim/views/cloudim-rules/rules/RulesCard.tsx";

import "./CloudIMRulesView.scss";

const CloudIMRulesView = (): JSX.Element => {
    return <FacetPanel
        searchType={SEARCH_TYPE.cloudrules}
        className="cloudim-rules-page"
    >
        {(facetProps: FacetPanelChildrenProps) => (
            facetProps.dataPoints.length === 0 ?
                <div className="noRules">
                    {STRINGS.cloudimRules.noRules}
                </div>
                :
                <div className="rules-card-holder m-3">
                    {facetProps.dataPoints.map((rules: any, index: number) => {
                        return <RulesCard
                            key={`rule-${rules.ruleId}-${index}`}
                            height={SIZE.m}
                            rules={rules}
                        />
                    })}
                </div>
        )}
    </FacetPanel>
};

export { CloudIMRulesView };
