
/** This module defines a React component that displays the search results in a table.
 *  @module
 */
import React, { useEffect } from "react";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import { NumericPagination } from "components/common/numeric-pagination/NumericPagination.tsx";
import { SearchResultsItem } from 'components/common/search/search-items/search-results-item/SearchResultsItem.tsx'

/** Types or search Items */
export enum SearchItemType {
    /* hidding per [#6657, #6658]
    SITE                    = 'SITE',
    APP                     = 'APP',
    DEVICE                  = 'DEVICE',
    */
    /* the enumerated type for a runbook search. */
    RUNBOOK                 = 'RUNBOOK',
    /* the enumerated type for an incident search. */
    INCIDENT                = "INCIDENT",
    /* the enumerated type for a triggered on device search. */
    DEVICE                  = "DEVICE",
    /* the enumerated type for a triggered on interface search. */
    INTERFACE               = "INTERFACE",
    /* the enumerated type for a triggered on application search. */
    APPLICATION             = "APPLICATION",
    /* the enumerated type for a triggered on location search. */
    LOCATION                = "LOCATION",
    /* the enumerated type for a impacted user search. */
    IMPACTED_USER           = "IMPACTED_USER",
    /* the enumerated type for a impacted application search. */
    IMPACTED_APPLICATION    = "IMPACTED_APPLICATION",
    /* the enumerated type for a impacted location search. */
    IMPACTED_LOCATION       = "IMPACTED_LOCATION",
    /* the enumerated type for a custom properties search. */
    CUSTOM_PROPERTY       = "CUSTOM_PROPERTY"
}

/** Types of search Categories in addition  SearchItemType  that is specific to the UI*/
export enum MetaCategrories {
    ALL = 'ALL'
}

/** All Search Categories that will be displayed on the search page */
export type SearchCategories = SearchItemType | MetaCategrories;

/** this interface defines the filter for the search category. */
export interface SearchCategoryFilter {
    /** either a SearchItemType or MetricCategory.  This defines which category is being displayed. */
    category: SearchCategories;
}

/** Individual search result object.*/
export interface SearchResultObject {
    /** Unique identifier */
    id: string;
    /** Type of search object. Is used to categorizing in the global filter */
    type: SearchItemType;
    /** Actual data as returned from the service.  */
    formattedData: any;
}

/** Wrapper object that represents each row in the search table.*/
export interface SearchResultItem {
    /** the SearchResultObject with the results to be displayed in the row. */
    resultItem: SearchResultObject;
}

/** this interface defines the properties passed into the SearchResultsTable component. */
export interface SearchResultsTableProps {
    /** Active category. Filters the rows in the table by this category. Category 'ALL' shows all the rows  */
    searchCategory: SearchCategories;
    /** the Array of SearchResultItems that are to be displayed in the table. */
    data: Array<SearchResultItem>;
    /** an integer with the number of rows that should be displayed in the table. */
    rowsPerPage?: number;
}

/** Search results table component. Displays list search result times.
 *  @param props an object with the properties passed to the SearchResultsTablej.
 *  @returns JSX for SearchResultsTable component.*/
export function SearchResultsTable({ searchCategory, data, rowsPerPage = 10 }: SearchResultsTableProps) {
    const columns = React.useMemo(
        () => [
            {
                id: 'searchItem',
                accessor: 'resultItem',
                Cell: (props) => {
                    let rowIndex = 0;
                    for (rowIndex = 0; rowIndex < props.rows.length; rowIndex++) {
                        if (props.row.id === props.rows[rowIndex].id) {
                            break;
                        }
                    }
                    return <SearchResultsItem data={props.value} rowIndex={rowIndex} ></SearchResultsItem>
                }
            }
        ],
        []);

    /** Filters rows based on the search category selected */
    const filterRows = function (rows: Array<any>, columnIds: Array<String>, globalFilterValue: SearchCategoryFilter) {
        let filteredRows: Array<any> = [];
        if (globalFilterValue.category === "ALL") {
            filteredRows = rows;
        } else {
            filteredRows = rows.filter((item) => {
                return item.values["searchItem"].type === globalFilterValue.category
            })
        }
        return filteredRows;
    };

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        page, // Displays only rows that are visible page.
        pageCount,
        gotoPage,
        state: { pageIndex },
        setGlobalFilter
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: rowsPerPage, globalFilter: { category: searchCategory } },
            globalFilter: filterRows
        },
        useGlobalFilter,
        usePagination,
    )

    useEffect(() => {
        setGlobalFilter({ category: searchCategory });
    }, [searchCategory, setGlobalFilter])

    function handlePagination(val: number) {
        gotoPage(val);
    }

    // Render the UI for your table
    return (
        <>
            <table {...getTableProps()} className="w-100">
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div className="my-4 d-flex justify-content-center">
                <NumericPagination onClick={handlePagination} totalPages={pageCount} activePageNumber={pageIndex} />
            </div>
        </>
    )
}
