query RunbooksExecution($startTime: String, $endTime: String) {
	monitor(filter: { time: { startTime: $startTime, endTime: $endTime } }) {
		runbookExecution {
			total {
				timestamp
				value
			}
		}
	}
}
