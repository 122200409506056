query activityLog($incidentId: String!, $limit: Int) {
	activityLogs(filter: { incidentId: $incidentId }, top: $limit) {
		info {
			totalCount
			currentToken
			nextToken
		}
		page {
			type
			timestamp
			incidentId
			runbook {
				id
				errors {
					code
					message
					innerError {
						id
						trace
						properties {
							key
							value
						}
					}
					details {
						code
						message
						innerError {
							id
							trace
							properties {
								key
								value
							}
						}
					}
				}
			}
			noteId
			user {
				id
			}
			oldValue
			newValue
		}
	}
}
