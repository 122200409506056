mutation SetCustomProperty(
    $id: ID!
    $customProperty: CustomPropertyUpdateDtoInput!
    $valuesToCreate: [CustomPropertyValueCreateDtoInput]
    $valuesToSet: [CustomPropertyValueItemDtoInput]
    $valuesToDelete: [ID]
) {
    setCustomProperty(
        id: $id
        customProperty: $customProperty
        valuesToCreate: $valuesToCreate
        valuesToSet: $valuesToSet
        valuesToDelete: $valuesToDelete
    ) {
        id
    }
}
