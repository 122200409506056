import { Icon, type IconName } from '@blueprintjs/core';
import { BULLET_COLORS } from 'components/enums/Colors.ts';
import { SDWAN_ICONS } from 'components/sdwan/enums/icons.ts';
import { TimeFormatter } from 'reporting-infrastructure/utils/formatters/time-formatter/TimeFormatter.tsx';
import { parseTimeFromDAL } from 'utils/hooks/useQueryFormatters.ts';
import { Variant } from '../graph/types/GraphTypes.ts';
import './DataNode.scss';

export interface DataNodeProps {
    // style class to apply on the outermost element
    className?: string;
    // title string
    title: string;
    // timestamp in string format that is displayed as-is. Formatting has to be done by the consumer
    timestamp: string;
    // description that is diplayed as a paragraph. Formatting has to be done by the consumer
    description?: JSX.Element | string;
    // show/hide description. Defaults to true
    showDescription?: boolean;
    // A flag to indicate that this is the first node in the list of nodes
    firstNode?: boolean;
    //node type
    nodeType?: Variant | undefined;
}
/**
 * A data formatter component which displayed the provided data as a node with a title timestamp and descriptions
 * @param props Refer to DataNodePorps for details on member attributes.
 * @return  JSX component
 */
export function DataNode({
    className,
    title,
    timestamp,
    description,
    showDescription = true,
    firstNode = false,
    nodeType = Variant.INCIDENT,
}: DataNodeProps) {
    const parsedTime = parseTimeFromDAL(timestamp);
    return <>
        <div className={"data-node ps-4 d-flex flex-column" + (firstNode ? "" : " show-border") + (className ? " " + className : "")}>
            <div className="d-flex align-items-center title">
                {nodeType === Variant.LIFECYCLE
                    ? <Icon icon={SDWAN_ICONS.RUNBOOK as IconName} className="" size={14} color={BULLET_COLORS.DEFAULT} />
                    : <Icon icon="symbol-circle" className="" size={14} color={BULLET_COLORS.DEFAULT} />}
                <div className="ps-2 fw-bold title-text text-truncate" title={title}>
                    {title}
                </div>
                <span className="ps-2 time-stamp" aria-label='timestamp'>
                    <TimeFormatter time={parsedTime} />
                </span>
            </div>
            {showDescription &&
                <div className="ms-2 ps-3 pb-4 desc flow-wrap" >
                    <div className="mt-1">
                        {description}
                    </div>
                    <span className="ps-2 time-stamp" aria-label="timestamp">
                        <TimeFormatter time={parsedTime} />
                    </span>
                </div>
            }
        </div>
    </>
}
