/** This file defines the NavigatorUtils class.  The NavigatorUtils class contains several utilities
 *      that are used across the navigator components.
 *  @module */

import { UniversalNode } from "components/common/graph/UniversalNode.ts";
import { NodeEnvSetting, NodeProperty } from "components/common/graph/types/GraphTypes.ts";
import { NodeLibraryNode } from "pages/create-runbook/views/create-runbook/NodeLibrary.ts";
import { WIDGET_TYPE, WidgetOptions } from "components/common/vis-framework/widget/Widget.type.ts";
import { Node } from "react-flow-renderer";
import { RunbookConfig } from "utils/services/RunbookApiService.ts";
import { QueryConfig } from "pages/navigator/Navigator.type.ts";

/** this class encapsulates the functionality for the navigator utilities. */
export class NavigatorUtils {
    public static VIS_NODE_UUID: string = "0bd14a4a-0bc0-48cc-bb57-970c54b272ae";
    public static DO_NODE_UUID: string = "xxd14a4a-0bc0-48cc-bb57-970c54b272ae";

    /** returns the data node from the runbook configuration.
     *  @param runbook the RunbookConfig object with the runbook configuration.
     *  @returns the RunbookNode with the data node or undefined if it is not found. */
/*
    public static getDataNode(runbook: RunbookConfig | undefined): RunbookNode | undefined {
        let dataNode: RunbookNode | undefined = undefined;
        if (runbook?.nodes?.length) {
            // Find a leaf node, for now assume there is only one
            let leafNode: RunbookNode | undefined = undefined;
            for (const node of runbook.nodes) {
                if (!node.wires?.length) {
                    leafNode = node;
                    break;
                }
            }
            if (leafNode) {
                dataNode = getFirstParentNodeOfType(
                    leafNode, [...dataOceanNodes, ...aggregatorNodes, ...transformNodes], runbook.nodes, false
                );
            }
        }
        return dataNode;
    }
*/

    /** creates the UniveralNode object that is used to represent the selected node in the runbook editor.
     *  @param widgetType the WIDGET_TYPE which specifies the visualization that is being displayed.
     *  @returns the UniversalNode object that is used in the runbook editor. */
    public static createSelectedQueryNodeForNodeEditor(): UniversalNode {
        const selectedNode = { 
            "vendor": "react-flow", "node": { 
                "id": NavigatorUtils.DO_NODE_UUID,
                "type": "default", "position": { "x": 92, "y": 72 },
                "data": { 
                    "type": "data_ocean", "label": "Data Ocean", "info": "", "color": "#A8D3C7",
                    "wires": { "direction": "both", "supportsMultipleOutputs": false },
                    "icon": "FUNCTION",
                    "properties": [], 
                    "dragging": true,
                    "isValid": false 
                } 
            } 
        } as UniversalNode;

        selectedNode['setProperty'] = (key: string, value: any) => {
            let found = false;
            if (!(((selectedNode.node as Node)?.data?.properties || []) as NodeProperty[])) {
                (selectedNode.node as Node).data.properties = [];
            }
            for (const prop of ((selectedNode.node as Node)?.data?.properties || []) as NodeProperty[]) {
                if (prop.key === key) {
                    prop.value = value;
                    found = true;
                    break;
                }
            }
            if (!found) {
                (selectedNode.node as Node).data.properties.push({key, value})
            }
        };
        selectedNode['removeProperty'] = (key: string) => {
            for (let index = 0; index < ((selectedNode.node as Node)?.data?.properties?.length || 0); index++) {
                if ((selectedNode.node as Node).data.properties[index].key === key) {
                    ((selectedNode.node as Node).data.properties as []).splice(index, 1);
                    break;
                }
            }
        };
        selectedNode['getId'] = () => { return NavigatorUtils.DO_NODE_UUID };
        selectedNode['getName'] = () => { return "TCP Connections" };
        selectedNode['getProperty'] = (key: string ) => { 
            for (const prop of ((selectedNode.node as Node)?.data?.properties || []) as NodeProperty[]) {
                if (prop.key === key) {
                    return prop.value;
                }
            }
            return undefined;
        };
        selectedNode['getProperties'] = () => { return ((selectedNode.node as Node)?.data?.properties || []) as NodeProperty[]; };
        selectedNode['setEnv'] = () => { };
        selectedNode['getEnv'] = (key) => {
            for (const prop of ((selectedNode.node as Node)?.data?.env || []) as NodeEnvSetting[]) {
                if (prop.name === key) {
                    return prop.value;
                }
            }
            return undefined;
        };
        selectedNode['getEnvSettings'] = () => { return ((selectedNode.node as Node)?.data?.env || []) as NodeEnvSetting[]; };
    
        return selectedNode;
    }

    /** creates the UniveralNode object that is used to represent the selected node in the runbook editor.
     *  @param widgetType the WIDGET_TYPE which specifies the visualization that is being displayed.
     *  @returns the UniversalNode object that is used in the runbook editor. */
    public static createSelectedNodeForNodeEditor(widgetType: WIDGET_TYPE): UniversalNode {
        const selectedNode = { 
            "vendor": "react-flow", "node": { 
                "id": NavigatorUtils.VIS_NODE_UUID,
                "type": "default", "position": { "x": 92, "y": 72 },
                "data": { 
                    "type": NavigatorUtils.getRunbookNodeTypeForWidget(widgetType), "label": "Chart", "info": "", "color": "#A8D3C7",
                    "wires": { "direction": "both", "supportsMultipleOutputs": false },
                    "icon": "FUNCTION",
                    "properties": [], 
                    "dragging": true,
                    "isValid": false 
                } 
            } 
        } as UniversalNode;

        selectedNode['setProperty'] = (key: string, value: any) => {
            let found = false;
            if (!(((selectedNode.node as Node)?.data?.properties || []) as NodeProperty[])) {
                (selectedNode.node as Node).data.properties = [];
            }
            for (const prop of ((selectedNode.node as Node)?.data?.properties || []) as NodeProperty[]) {
                if (prop.key === key) {
                    prop.value = value;
                    found = true;
                    break;
                }
            }
            if (!found) {
                (selectedNode.node as Node).data.properties.push({key, value})
            }
        };
        selectedNode['getId'] = () => { return NavigatorUtils.VIS_NODE_UUID };
        selectedNode['getProperty'] = (key: string ) => { 
            for (const prop of ((selectedNode.node as Node)?.data?.properties || []) as NodeProperty[]) {
                if (prop.key === key) {
                    return prop.value;
                }
            }
            return undefined;
        };
        selectedNode['getProperties'] = () => { return ((selectedNode.node as Node)?.data?.properties || []) as NodeProperty[]; };
        selectedNode['setEnv'] = () => { };
        selectedNode['getEnv'] = (key) => {
            for (const prop of ((selectedNode.node as Node)?.data?.env || []) as NodeEnvSetting[]) {
                if (prop.name === key) {
                    return prop.value;
                }
            }
            return undefined;
        };
        selectedNode['getEnvSettings'] = () => { return ((selectedNode.node as Node)?.data?.env || []) as NodeEnvSetting[]; };
    
        return selectedNode;
    }

    /** updates the selectedNode in UniversalNode format with the initial node properties or edited properties.
     *  @param selectedNode the UniveralNode with the selected node that is being edited.
     *  @param libraryNode the NodeLibraryNode with the initial configuration for the node.
     *  @param options the WidgetOptions with any saved widget options. */
    public static updateSelectedNodeProperties(
        selectedNode: UniversalNode, libraryNode: NodeLibraryNode | undefined, options: {[xxx: string]: any} | undefined
    ): void {
        if (libraryNode?.properties?.length && (selectedNode.node as Node).data!.properties?.length === 0) {
            if (options && Object.keys(options).length > 0) {
                for (const optionKey in options) {
                    (selectedNode.node as Node).data!.properties.push({key: optionKey, value: options[optionKey]});
                }
                // The library node might have changed if we switched the widget type so add any missing properties
                for (const property of libraryNode.properties) {
                    if (property.default !== undefined && property.default !== null && options[property.name] === undefined) {
                        (selectedNode.node as Node).data!.properties.push({key: property.name, value: property.default});
                    }
                }    
            } else {
                for (const property of libraryNode.properties) {
                    if (property.default !== undefined && property.default !== null) {
                        (selectedNode.node as Node).data!.properties.push({key: property.name, value: property.default});
                    }
                }    
            }
        }    
    }

    /** Creates a runbook by adding the visualization node to the existing runbook that the user configured.
     *  @param runbookConfig the RunbookConfig the user configured in the runbook editor.
     *  @param widgetType the WIDGET_TYPE which specifies the visualization that is being displayed.
     *  @returns a new RunbookConfig object with the visualization node appended to the runbook nodes. */
/*    
    public static appendVisualizationNodeToRunbookConfig(
        runbookConfig: RunbookConfig | undefined, widgetType: WIDGET_TYPE, options?: WidgetOptions | undefined
    ): RunbookConfig {
        let newRunbookConfig = runbookConfig ? JSON.parse(JSON.stringify(runbookConfig)) : {};
        if (!runbookConfig) {
            // We don't want this to ever happen, we always want to come in with a runbook config
            newRunbookConfig = {
                "name": "New",
                "description": "",
                "triggerType": "network_interface" as any,
                "nodes": [
                    {
                        "id": "75da6764-037a-4128-a2cd-acfbc690b855",
                        "type": "data_ocean",
                        "label": "Applications",
                        "wires": [],
                        "description": "",
                        "properties": {
                            "x": 104,
                            "y": 360,
                            "objType": "application.traffic",
                            "timeSeries": false,
                            "limit": 10,
                            "duration": 900,
                            "filters": {},
                            "metrics": ["throughput", "in_throughput", "out_throughput"],
                            "debug": false
                        }
                    }
                ],
                "configs": [],
                "runtimeVariables": {
                    "primitiveVariables": [],
                    "structuredVariables": []
                },
                "isReady": false,
                "isFactory": false
            } as RunbookConfig;
        }

        if (newRunbookConfig.nodes.length) {
            for (const node of newRunbookConfig.nodes) {
                // Find the node with no children
                if (node.wires === undefined || node.wires.length === 0) {
                    node.wires.push([NavigatorUtils.VIS_NODE_UUID]);
                    newRunbookConfig.nodes.push(
                        {
                            "id": NavigatorUtils.VIS_NODE_UUID,
                            "type": NavigatorUtils.getRunbookNodeTypeForWidget(widgetType) || "",
                            "label": "Chart",
                            "wires": [],
                            "description": "",
                            "properties": {
                                "x": 104,
                                "y": 360,
                                ...(options || {})
                            }
                        }
                    );
                    break;
                }
            }
        }
        return newRunbookConfig;
    }
*/

    /** Creates a runbook by adding the visualization node to the existing runbook that the user configured.
     *  @param runbookConfig the RunbookConfig the user configured in the runbook editor.
     *  @param widgetType the WIDGET_TYPE which specifies the visualization that is being displayed.
     *  @returns a new RunbookConfig object with the visualization node appended to the runbook nodes. */
    public static createDoNodeFromQuery(
        query: QueryConfig | undefined
    ): RunbookConfig {
        let newRunbookConfig: RunbookConfig = {
            "name": "New",
            "description": "",
            "triggerType": "network_interface" as any,
            "nodes": [
                {
                    "id": NavigatorUtils.DO_NODE_UUID,
                    "type": "data_ocean",
                    "label": "Applications",
                    "wires": [],
                    "description": "",
                    "properties": {
                        "x": 104,
                        "y": 360,
                        ...(
                            query?.properties || {
                                objType: query?.properties.objType,
                                //objType: query?.properties.objType || DEFAULT_OBJ_TYPE,
                                timeSeries: false,
                                limit: 10,
                                duration: 900,
                                filters: {},
                                metrics: [],
                                groupBy: []
                            }
                        ),
/*                        
                        "objType": "application.traffic",
                        "timeSeries": false,
                        "limit": 10,
                        "duration": 900,
                        "filters": {},
                        "metrics": ["throughput", "in_throughput", "out_throughput"],
*/
                        "debug": false
                    }
                }
            ],
            "configs": [],
            "runtimeVariables": {
                "primitiveVariables": [],
                "structuredVariables": []
            },
            "isReady": false,
            "isFactory": false
        } as RunbookConfig;

        return newRunbookConfig;
    }

    /** Creates a runbook by adding the visualization node to the existing runbook that the user configured.
     *  @param runbookConfig the RunbookConfig the user configured in the runbook editor.
     *  @param widgetType the WIDGET_TYPE which specifies the visualization that is being displayed.
     *  @returns a new RunbookConfig object with the visualization node appended to the runbook nodes. */
    public static createGraphDefFromQueryAndWidgetInfo(
        query: QueryConfig | undefined, widgetType: WIDGET_TYPE, options?: WidgetOptions | undefined
    ): RunbookConfig {
        let newRunbookConfig: RunbookConfig = this.createDoNodeFromQuery(query);

        if (newRunbookConfig?.nodes?.length) {
            for (const node of newRunbookConfig.nodes) {
                // Find the node with no children
                if (node.wires === undefined || node.wires.length === 0) {
                    node.wires.push([NavigatorUtils.VIS_NODE_UUID]);
                    newRunbookConfig.nodes.push(
                        {
                            "id": NavigatorUtils.VIS_NODE_UUID,
                            "type": NavigatorUtils.getRunbookNodeTypeForWidget(widgetType) || "",
                            "label": "Chart",
                            "wires": [],
                            "description": "",
                            "properties": {
                                "x": 104,
                                "y": 360,
                                ...(options || {})
                            }
                        }
                    );
                    break;
                }
            }
        }
        return newRunbookConfig;
    }

    /** returns a String with the node type for the specified WIDGET_TYPE.
     *  @returns a String with the node type. */
    public static getRunbookNodeTypeForWidget(widgetType: WIDGET_TYPE): string | undefined {
        switch (widgetType) {
            case WIDGET_TYPE.BAR:
                return "rvbd_ui_bar_chart";
            case WIDGET_TYPE.PIE:
                return "rvbd_ui_pie_chart";
            case WIDGET_TYPE.TIMESERIES:
                return "rvbd_ui_time_chart";
            case WIDGET_TYPE.BUBBLE:
                return "rvbd_ui_bubble_chart";
            case WIDGET_TYPE.CORRELATION:
                return "rvbd_ui_correlation_chart";
            case WIDGET_TYPE.TABLE:
                return "rvbd_ui_table";
            case WIDGET_TYPE.CARDS:
                return "rvbd_ui_cards";
            case WIDGET_TYPE.GAUGES:
                return "rvbd_ui_gauges";
            case WIDGET_TYPE.CONNECTION_GRAPH:
                return "rvbd_ui_connection_graph";
            case WIDGET_TYPE.DEBUG:
                return "rvbd_ui_debug";
            case WIDGET_TYPE.TEXT:
                return "rvbd_ui_text";
            case WIDGET_TYPE.GEO_MAP:
                return "rvbd_ui_table";
        }
        return undefined;
    }
}
