mutation AddCustomPropertyValueToNetworkInterfaces($valueId: ID!, $filter: NetworkInterfaceBulkFilterType, $search: String, $searchFields: [NetworkInterfaceSearchFieldEnum]) {
    addCustomPropertyValueToNetworkInterfaces(
        valueId: $valueId
        filter: $filter
        search: $search
        searchFields: $searchFields
    ) {
        modifiedResourcesCount
        ignoredResourcesCount
    }
}
