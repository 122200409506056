query incidentPriority($priority: [Priority], $incidentStatus: [Status], $completionStatus: CompletionStatus, $interval: Interval) {
  incidentPriority: summary(
    filter: {
        priorities: $priority, status: $incidentStatus, completionStatus: $completionStatus, time: {interval: $interval}
    },
    order: {incidentsCount: DESC}
  ) {
        samples {
            timestamp
            incidentsPriority {
                priority
                count
            }
        }
    }
}
 