import { useState, useEffect, useRef } from 'react';
import { Button, Position, Menu, MenuItem, ButtonGroup, Popover, PopoverInteractionKind } from '@blueprintjs/core';
import { ThirdPartyIntegrationService } from 'utils/services/ThirdPartyIntegrationApiService.ts';
import { Table, IconNames, type TableColumnDef } from '@tir-ui/react-components';
import { openModal } from "components/common/modal/ModalRenderer.tsx";
import { openConfirm } from "components/common/modal/ConfirmModal.tsx";
import type { ProfileInterface } from "utils/services/ThirdPartyIntegrationApiService.ts";
import { DataSourceService, type CloudIMDataSource } from 'utils/services/DataSourceApiService.ts';
import { DataLoadFacade } from 'components/reporting/data-load-facade/DataLoadFacade.tsx';
import { CLOUD_PROVIDER_TYPE, CLOUD_PROVIDER_TYPE_TO_LABEL } from 'components/enums/CloudIM.ts';
import { STRINGS } from 'app-strings';

import './CloudIMDataSourceView.scss';

type CloudIMDataSourceDisplay = {
    dataSourceId?: string,
    dataSourceName: string,
    authProfileId: string,
    authProfileName: string,
    provider: CLOUD_PROVIDER_TYPE
}

export default function CloudIMDataSourceView() {

    const auth_profiles = useRef<ProfileInterface[]>([]);
    const [displayDataSources, set_displayDataSources] = useState<CloudIMDataSourceDisplay[]>([]);
    const [loading, set_loading] = useState<boolean>(true);
    const [error, set_error] = useState<boolean>(false);

    useEffect(() => {
        const initialize = async () => {
            try {
                set_loading(true);
                auth_profiles.current = await ThirdPartyIntegrationService.getRunbookAndIntegrationAuthProfiles()
                await refreshDataSources();
            } catch (err) {
                console.error(err);
                set_error(true);
            }
        }
        initialize()
    }, []);

    const refreshDataSources = async () => {
        set_loading(true);
        var dataSources = await DataSourceService.getCloudIMDataSources()
        var displayDataSources = dataSources.map((ds) => {
            let pi = auth_profiles.current.find(ap => ap.id === ds.authProfileId)
            return {
                ...ds,
                authProfileName: pi ? pi.name : "N/A",
                provider: ds.provider,
            };
        });
        set_displayDataSources(displayDataSources);
        set_loading(false);
    }

    const getColumns = (): Array<TableColumnDef> => {
        return [
            {
                id: 'data_source_name',
                Header: STRINGS.DATA_SOURCES.cloudimDataSources.columns.dataSource,
                accessor: 'data_source_name',
                className: 'ds-table-column',
            },
            {
                id: 'provider',
                Header: STRINGS.DATA_SOURCES.cloudimDataSources.columns.providerType,
                accessor: 'provider',
                className: 'ds-table-column',
            },
            {
                id: 'auth_profile_id',
                Header: STRINGS.DATA_SOURCES.cloudimDataSources.columns.authProfile,
                accessor: 'auth_profile_id',
                className: 'ds-table-column',
            },
            {
                id: 'more',
                Header: '',
                accessor: 'more',
                className: 'ds-table-column',
            }
        ];
    };

    const getMoreMenuItems = (
        dds: CloudIMDataSourceDisplay
    ): Array<JSX.Element> => {
        return [
            <MenuItem
                disabled={false}
                data-testid={`${dds.dataSourceId}-popover-edit-button`}
                text={STRINGS.DATA_SOURCES.cloudimDataSources.menuItems.edit}
                active={false}
                key={'edit_cloudim_datasource'}
                onClick={() => {
                    openModal('cloudIMDataSourceModal', {
                        "auth_profiles": auth_profiles.current,
                        "data_source_name": dds.dataSourceName,
                        "provider_type": dds.provider,
                        "auth_profile_id": dds.authProfileId,
                        "data_source_id": dds.dataSourceId,
                        "submit": ((newDataSource: CloudIMDataSource) => {
                            DataSourceService.updateCloudIMDataSource(newDataSource).then(() => {
                                refreshDataSources();
                            })
                                .catch((error) => {
                                    console.error(error);
                                })
                        })
                    });
                }}
            />,
            <MenuItem
                disabled={false}
                data-testid={`${dds.dataSourceId}-popover-delete-button`}
                text={STRINGS.DATA_SOURCES.cloudimDataSources.menuItems.delete}
                active={false}
                key={'delete_cloudim_datasource'}
                onClick={() => {
                    openConfirm({
                        message: STRINGS.DATA_SOURCES.cloudimDataSources.messages.deleteConfirmMsg,
                        onConfirm: async () => {
                            DataSourceService.deleteCloudIMDataSource(dds)
                                .then(() => {
                                    refreshDataSources()
                                })
                                .catch((err) => {
                                    console.error(err);
                                    set_error(true);
                                })
                        },
                        icon: IconNames.TRASH,
                    });

                }}
            />,
        ];
    };

    const openDataSourceConfigModal = async () => {
        openModal(
            'cloudIMDataSourceModal',
            {
                "auth_profiles": auth_profiles.current,
                "submit": ((ds: CloudIMDataSource) => {
                    DataSourceService.createCloudIMDataSource(ds)
                        .then(() => {
                            refreshDataSources()
                        })
                        .catch((err) => {
                            console.error(err);
                            set_error(true);
                        });
                })
            }
        );
    }

    /** used to create the nested items when a menu is toggled to open */
    const TabularMenuCell = (dds: CloudIMDataSourceDisplay) => {
        return (
            <div onClick={(e) => { e.stopPropagation() }} data-testid={`${dds.dataSourceId}-cell`}>
                <Popover
                    position={Position.BOTTOM_RIGHT}
                    interactionKind={PopoverInteractionKind.CLICK}
                    content={
                        <Menu>
                            {getMoreMenuItems(dds)}
                        </Menu>
                    }
                >
                    <Button
                        data-testid={`${dds.dataSourceId}-more-button`}
                        icon={IconNames.MORE}
                        minimal
                        className="cloudim-data-sources-action-icon"
                        disabled={false}
                    />
                </Popover>
            </div>
        );
    };

    const getDisplayData = (data: CloudIMDataSourceDisplay[]): Array<any> => {
        const displayData =
            data && data.length > 0
                ? data.map((dds) => {
                    return {
                        data_source_name: dds.dataSourceName,
                        provider: CLOUD_PROVIDER_TYPE_TO_LABEL[dds.provider],
                        auth_profile_id: dds.authProfileName,
                        more: (
                            <TabularMenuCell {...dds} />
                        )
                    };
                })
                : [];
        return displayData;
    };

    return (
        <div>
            <DataLoadFacade loading={loading} key="cloudim_data_sources" showContentsWhenLoading={true} error={error}>
                <ButtonGroup className='mt-3'>
                    <Button
                        data-testid={'add-cloudim-datasource-button'}
                        // minimal
                        icon={IconNames.ADD}
                        // className="user-btn"
                        onClick={openDataSourceConfigModal}
                    >
                        {STRINGS.DATA_SOURCES.cloudimDataSources.dialog.button}
                    </Button>
                </ButtonGroup>

                <Table
                    id="placeholder-id"
                    columns={getColumns()}
                    data={getDisplayData(displayDataSources)}
                    enablePagination={false}
                    bordered={true}
                />
            </DataLoadFacade>

        </div>
    );
}
