query analyticsIncidents($incidentId: String, $openIssues: Boolean, $startTime: String!, $endTime: String!) {
  incidents(
    filter: {incidentIds: [$incidentId], openIssues: $openIssues, time: {startTime: $startTime, endTime: $endTime}}
  ) {
    nodes {
      id
      description
      text
      severity {
        value
        score
      }
    }
  }
}
