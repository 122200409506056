/** This file defines the MetricMiniCard React component.  The MetricMiniCard React component
 *  displays a card that can display a value, its percentage change and a time series sparkline.
 *  @module */
import type { ReactNode } from 'react';
import { SummaryCard, type SummaryCardProps } from 'components/common/layout/summary-card/SummaryCard.tsx';
import { SIZE } from 'components/enums/Sizes.ts';
import { SparklineTile } from 'components/reporting/tiles/sparkline-tile/SparklineTile.tsx';
import { TimeseriesTile, type TimeseriesTileProps } from 'components/reporting/tiles/timeseries-tile/TimeseriesTile.tsx';
import { getStatusAndInfoForMetric, precise, type FormattedUnitValue, formatAndScaleMetricValue } from 'reporting-infrastructure/utils/formatters/MetricFormatter.ts';
import { formatTimeSeriesTooltip } from 'reporting-infrastructure/utils/formatters/GeneralFormatter.ts';
import type { timeSeriesTooltipFormatter, timeSeriesTooltipFormatterParams } from 'components/reporting/utils/Types.ts';
import { Unit } from 'reporting-infrastructure/types/Unit.class.ts';
import { Icon, IconNames } from '@tir-ui/react-components';
import { STRINGS } from 'app-strings';
import { PopoverPosition, Classes, Tooltip } from '@blueprintjs/core';
import { calculatePercentChange } from 'utils/runbooks/RunbookOutputUtils.ts';
import './MetricMiniCard.scss';

/** This interface defines the properties passed into the metric mini card component.*/
export interface MetricMiniCardProps extends SummaryCardProps {
    /** the value to display in the card. */
    value?: string | number;
    /** the comparison value to display in the card. */
    compareValue?: string | number;
    /** the current unit that applies to both the value and comparison value. */
    unit?: Unit;
    /** the status information to show in the card. */
    info?: string;
    /** the child components to show in the card. */
    children?: ReactNode;
    /** the type of time chart: sparkline or timeseries */
    chartType?: 'sparkline' | 'timeseries';
    /** the data to display in the chart. */
    chartData?: TimeseriesTileProps['chartData'];
    /** the min and max values to show when displaying data in the chart. */
    chartDataMinMax?: { min?: number; max?: number };
    /** the data to display in the chart. */
    chartCompData?: TimeseriesTileProps['chartData'];
    /** the thresholds to display in the chart. */
    thresholds?: Array<number>;
    /** the suffix to use in the legend when displaying comparison data. */
    comparisonSuffix?: string;
    /** the tooltip formatter for the chart widget, not for the summary data. */
    tooltipFormatter?: timeSeriesTooltipFormatter;
}

/** Renders the metric mini card React component.
 *  @param cardProps the properties passed in.
 *  @returns JSX with the metric mini card React component.*/
export function MetricMiniCard(cardProps: MetricMiniCardProps): JSX.Element {
    let {
        size = SIZE.s,
        value,
        compareValue,
        unit,
        info,
        children,
        chartType = 'sparkline',
        chartDataMinMax,
        chartData,
        chartCompData,
        thresholds,
        className,
        status,
        tooltipFormatter,
        ...props
    } = cardProps;
    const metricValues = chartData?.map((d) => {
        if (typeof d === 'number') {
            return d;
        } else if (d && d['y']) {
            return d['y'];
        } else {
            return 0;
        }
    });
    const statusAndInfo = getStatusAndInfoForMetric(metricValues, thresholds);
    if ((value === null || value === undefined) && metricValues && metricValues.length > 0) {
        value = metricValues[metricValues.length - 1];
    }

    const metricTooltipFormatter = tooltipFormatter
        ? tooltipFormatter
        : (data: timeSeriesTooltipFormatterParams) => formatTimeSeriesTooltip(data, unit);
    const ChartComponent = chartType === 'sparkline' ? SparklineTile : TimeseriesTile;
    const minY = chartDataMinMax !== undefined ? chartDataMinMax?.min : unit?.unit === '%' ? 0 : undefined;
    const maxY = chartDataMinMax !== undefined ? chartDataMinMax?.max : unit?.unit === '%' ? 101 : undefined;

    let scaledValue = value;
    let scaledUnit = unit?.unit || '';
    if (value !== null && value !== undefined && typeof value === 'number') {
        const formattedValueDetails: FormattedUnitValue = formatAndScaleMetricValue(value, unit);
        scaledValue = formattedValueDetails.value;
        scaledUnit = formattedValueDetails.unit;
    }

    let pctChange: number | undefined = undefined;
    if (
        value !== undefined &&
        value !== null &&
        compareValue !== undefined &&
        compareValue !== null &&
        typeof value === 'number' &&
        typeof compareValue === 'number' &&
        !Number.isNaN(value) &&
        !Number.isNaN(compareValue)
    ) {
        pctChange = calculatePercentChange(value, compareValue);
    }

    return (
        <SummaryCard
            size={size}
            ariaLabel="metric mini card"
            className={'metric-mini-card' + (className ? ' ' + className : '')}
            status={status || statusAndInfo.severity}
            {...props}
        >
            {pctChange === undefined && (
                <div className="display-7 text-black fw-600 card-value">
                    {scaledValue !== null && scaledValue !== undefined ? (
                        <span>
                            {scaledValue}
                            {scaledUnit ? ' ' + scaledUnit : ''}
                        </span>
                    ) : (
                        <span>&#8212;</span>
                    )}
                </div>
            )}
            {pctChange !== undefined && (
                <div className="display-7 text-black fw-600 card-value mt-1">
                    <Tooltip
                        className={Classes.TOOLTIP_INDICATOR + ' border-0'}
                        content={
                            <div className="px-2 py-2">
                                <table>
                                    <tbody>
                                        <tr key="current">
                                            <td className="text-end pe-2">
                                                {STRINGS.incidents.runbookOutputs.currentValueLabel}
                                            </td>
                                            <td>
                                                {value !== undefined && typeof value === 'number'
                                                    ? formatAndScaleMetricValue(value, Unit.parseUnit(unit?.unit || ''))
                                                          .formatted
                                                    : value}
                                            </td>
                                        </tr>
                                        <tr key="previous">
                                            <td className="text-end pe-2">{props.comparisonSuffix}</td>
                                            <td>
                                                {compareValue !== undefined && typeof compareValue === 'number'
                                                    ? formatAndScaleMetricValue(
                                                          compareValue,
                                                          Unit.parseUnit(unit?.unit || ''),
                                                      ).formatted
                                                    : compareValue}
                                            </td>
                                        </tr>
                                        <tr key="change">
                                            <td className="text-end pe-2">
                                                {STRINGS.incidents.runbookOutputs.changeValueLabel}
                                            </td>
                                            <td>
                                                {pctChange > 1000 && <span>&gt; </span>}
                                                {pctChange < -1000 && <span>&lt; </span>}
                                                {precise(
                                                    pctChange > 0
                                                        ? Math.min(pctChange, 1000)
                                                        : Math.max(pctChange, -1000),
                                                ) + ' %'}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        }
                        position={PopoverPosition.RIGHT}
                        transitionDuration={50}
                    >
                        <>
                            <div className="display-7 text-black fw-600 card-value">
                                {scaledValue}
                                {scaledUnit ? ' ' + scaledUnit : ''}
                            </div>
                            <Icon
                                icon={
                                    pctChange > 0
                                        ? IconNames.ARROW_UP
                                        : pctChange < 0
                                          ? IconNames.ARROW_DOWN
                                          : IconNames.MINUS
                                }
                                size={20}
                                className="me-1 mb-1"
                            />
                            {(pctChange > 1000 || pctChange < -1000) && <span>&gt; </span>}
                            <span>
                                {pctChange > 0
                                    ? precise(Math.min(pctChange, 1000))
                                    : precise(Math.min(-1.0 * pctChange, 1000))}{' '}
                                %
                            </span>
                        </>
                    </Tooltip>
                </div>
            )}
            <span className="display-9">{info || statusAndInfo.info}</span>
            {children}
            {chartData && (
                <ChartComponent
                    chartData={chartData}
                    thresholds={thresholds}
                    leadingMarker={false}
                    tooltipFormatter={metricTooltipFormatter}
                    yMin={minY}
                    yMax={maxY}
                    chartCompData={chartCompData}
                />
            )}
        </SummaryCard>
    );
}
