/** This module contains all the types for the data ocean metadata.
 *  @module
 */

import { dataSourceTypeOptions } from "utils/services/DataSourceApiService.ts";

/** defines the DataOceanMetadata type.*/
export type DataOceanMetadata = {
    /** the object types that correspond to the data ocean queries. */
    obj_types: Record<string, DataOceanObjectType>;
    /** the metrics that are supported by the Data Ocean. */
    metrics: Record<string, DataOceanMetric>;
    /** the keys that are supported by the Data Ocean. */
    keys: Record<string, DataOceanKey>;
}

/** defines DataOceanObjectType type . */
export type DataOceanObjectType = {
    /** a String with the label for the object type. */
    label: string;
    /** the allowed group bys, if any.  If there are no group bys this will be undefined. */
    group_by?: Array<string>;
    /** a boolean value, true if a group by is required, false otherwise.  If omitted, false is implied. */
    group_by_required?: boolean;
    /** an array of strings that has the expanded keys for the group by.  These keys will be of the form 
     *  "application.name", where the key's definition will be found in the keys section, but you have to 
     *  use the expanded key as a json path to find the definition in the keys. */
    expanded_keys?: Array<string>;
    /** the array of string key ids, where the keys come from the keys list. */
    keys: Array<string>;
    /** the array of string metric ids, where the metrics come from the metrics list. */
    metrics: Array<string>;
    /** the array of string filter ids, where the filters come from the keys list. */
    filters: Array<string>;
    /** the array of required filter ids, where the filters come from the keys list. */
    required_filters: Array<string>;
    /** the array of supported query types. */
    supported_query_types: QUERY_TYPE[];
    /** a boolean value, if true the object type is deprecated and should not be allowed to be added to a new node.  The
     *  default value is false. */
    deprecated?: boolean;
    /** the list of supported data sources. */
    supported_data_sources?: dataSourceTypeOptions[];
    /** this object contains the default timeframe, the default metrics and default top by. */
    defaults?: DataOceanObjectTypeDefaults;
    /** this boolean specifies that this object type should be excluded from workspaces. */
    exclude_from_workflows?: boolean;
}

/** this enumeration specifies the valid query types. */
export enum QUERY_TYPE {
    /** the enumerated value that specifies a time series query. */
    "time_series"   = "time_series",
    /** the enumerated value that specifies a summary query. */
    "summary"       = "summary"
}

/** defines DataOceanObjectTypeDefaults type . */
export type DataOceanObjectTypeDefaults = {
    /** an integer value that specifies the default duration in minutes. */
    duration?: number;
    /** a list of metric ids with the default set of metrics to query. */
    metrics?: string[];
    /** the list of top by metrics and directions. */
    top_by?: TopBy[];
}

/** this enumeration specifies the valid query types. */
export enum TOP_BY_DIRECTION {
    /** the enumerated value that specifies a descending top by. */
    "desc"  = "desc",
    /** the enumerated value that specifies an ascending top by. */
    "asc"   = "asc"
}

/** defines the TopBy type.  This type is used to represent the top by that is sent to the data ocean. */
export type TopBy = {
    /** a string with the id of the top by metric. */
    id: string, 
    /** the TOP_BY_DIRECTION which specifies whether the top by is ascending or descending. */
    direction: TOP_BY_DIRECTION
};

/** defines DataOceanMetric type. */
export type DataOceanMetric = {
    /** a String with the id of the metric. */
    id: string;
    /** a String with the label for the metric. */
    label: string;
    /** a String with the type of metric. */
    type: string;
    /** a String with the unit for the metric where "none" signifies no unit. */
    unit?: string;
    /** the default sort order to use when sorting the metric.  The default, if not specified, is assumed to be desc. */
    order_by_direction?: SortOrder;
    /** the sort orders, if not specified it is assumed to be desc and asc.  An empty array means no sort orders are specified.
     *  This is not implemented in either the data ocean or the UI but it is to handle the future case where we show a combo
     *  box with the sorting and allow the user to pick. */
    supported_sort_orders?: Array<SortOrder>;
    /** if the metric is an enum this section will have the enumerated values. */
    enum?: {[enumName: string]: string};
    /** the order by weight is optional and it is used to tell the UI how to sort the enum.  If this is not 
     *  specified then it is sorted by its integer value. */
    order_by_weight?: {[x: string]: number};
    /** a boolean value, if true the metric is deprecated and should not be allowed to be added to a new node.  The
     *  default value is false. */
    deprecated?: boolean;
    /** the array of supported query types. */
    supported_query_types: QUERY_TYPE[];
    /** the list of supported data sources. */
    supported_data_sources?: dataSourceTypeOptions[];
    /** the environments the metric is supported on.  If undefined then all is assumed. */
    // TODO: make this a strict string enum based on environment modes
    supported_envs?: string[];
    /** a boolean value that specifies whether the metric supports toppingg.  A true value specifies
     *  that the metric supports topping a false value specifies that it does not. */
    supports_top?: boolean;
    /** a string with the metric category.  This can be used for sorting the metrics. */
    category?: string;
    /** a string with the operation, for example "avg" or "sum". */
    operation?: string;
}

/** this enumeration specifies the valid sort orders. */
export enum SortOrder {
    /** the enumerated value that specifies sort descending. */
    desc    = "desc",
    /** the enumerated value that specifies sort ascending. */
    asc     = "asc"
}

/** defines DataOceanKey type . */
export type DataOceanKey = {
    /** a String with the label for the key. */
    label: string;
    /** a String with the type of key. */
    type: string;
    /** a String with the unit for the key where "none" signifies no unit. */
    unit?: string;
    /** a boolean value, true if this is one of the primary keys. */
    primary_key?: boolean;
    /** an object that contains any subkeys for the key. */
    properties?: Record<string, DataOceanKey>;
    /** a string with the category for the key.  This can be used for sorting and organizing the keys. */
    category?: string;
    /** a boolean value, if true the key should be hidden from the user in tables and charts. */
    hidden?: boolean;
    /** this is used only for the use case of generating the lifecycle runbook context
     *  for Liquid.  In this case the top level key has a special name that is used in the 
     *  Liquid variable.  This field holds that name. */
    liquid_key?: string;
    /** a boolean value, if true the metric is deprecated and should not be allowed to be added to a new node.  The
     *  default value is false. */
    deprecated?: boolean;
    /** the list of supported data sources. */
    supported_data_sources?: dataSourceTypeOptions[];
    /** the environments the metric is supported on.  If undefined then all is assumed. */
    supported_envs?: string[];
}
