import { STRINGS } from "app-strings";
import { SummaryCard } from "components/common/layout/summary-card/SummaryCard";
import { SEVERITY } from "components/enums/Severity.ts";
import React, { ReactNode } from "react";
import { BrowserRouter, Link } from "react-router-dom";
import { RoundedLinkButton } from "components/common/rounded-link-button/RoundedLinkButton";
import { SIZE } from "components/enums/Sizes";

export const BEM_CARD_CLASS_NAME = "RiverbedAdvisorCard-severity-status";

export interface RiverbedAdvisorCardProps {
    title?: string | ReactNode;
    status?: SEVERITY;
    showActionControl?: boolean;
    actionLabel?: string;
    actionURL?: string;
    onActionButtonClicked?: () => void;
    className?: string;
    children: ReactNode;
}

const RiverbedAdvisorCard = ({
    title,
    status,
    showActionControl,
    actionLabel,
    actionURL,
    onActionButtonClicked,
    className,
    children,
}: RiverbedAdvisorCardProps) => {
    const severityFromProp =
        SEVERITY[status || SEVERITY.UNKNOWN] || SEVERITY.UNKNOWN;

    const severity =
        severityFromProp === SEVERITY.DEGRADED ||
        severityFromProp === SEVERITY.CRITICAL
            ? severityFromProp
            : undefined;

    const titleBgClass =
        "bg-" +
        (severity
            ? severity.toLocaleLowerCase()
            : SEVERITY.UNKNOWN.toLowerCase());

    const summaryCardTitle =
        STRINGS.riverbedAdvisor.titles[
            severity ? severity.toLocaleLowerCase() : "default"
        ];

    let actionAreaJSX: React.ReactNode;

    if (showActionControl && actionLabel) {
        if (actionURL) {
            actionAreaJSX = (
                <BrowserRouter>
                    <Link
                        to={actionURL}
                        className="mt-2 mb-1 show-details-button d-inline-block float-end"
                    >
                        {actionLabel}
                    </Link>
                </BrowserRouter>
            );
        } else {
            actionAreaJSX = (
                <RoundedLinkButton
                    className="float-end"
                    size={SIZE.s}
                    text={actionLabel}
                    onClick={onActionButtonClicked}
                />
            );
        }
    }
    return (
        <SummaryCard
            status={severity}
            alignTitle="center"
            title={
                <span
                    data-testid={BEM_CARD_CLASS_NAME}
                    className={
                        "w-min-1-5 rounded p-1 text-center text-capitalize d-inline-block display-9 fw-bold text-light " +
                        titleBgClass
                    }
                >
                    {summaryCardTitle}
                </span>
            }
            footerContent={actionAreaJSX}
            className={className}
        >
            {title ? (
                <>
                    <div className="display-8 fw-500 mb-2 text-black">
                        {title}
                    </div>
                    <span className="display-9">{children}</span>
                </>
            ) : (
                children
            )}
        </SummaryCard>
    );
};

export { RiverbedAdvisorCard };
