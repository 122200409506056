mutation AddCustomPropertyValueToApplications($valueId: ID!, $filter: ApplicationBulkFilterType, $search: String, $searchFields: [ApplicationSearchFieldEnum]) {
    addCustomPropertyValueToApplications(
        valueId: $valueId
        filter: $filter
        search: $search
        searchFields: $searchFields
    ) {
        modifiedResourcesCount
        ignoredResourcesCount
    }
}
