import type React from 'react';
import { type IconName, IconNames } from "@tir-ui/react-components";
import { IconNames as BPIcons } from "@blueprintjs/icons";
import { STRINGS } from 'app-strings';
import { Support } from 'pages/support/Support.tsx';
import { SDWAN_ICONS } from "components/sdwan/enums/icons.ts";
import { UserProfile } from 'pages/profile/UserProfile.tsx';
import RiverbedAdvisorPage from "pages/riverbed-advisor/RiverbedAdvisorPage.tsx";
import IncidentRunbookListPage from 'pages/runbook-list/IncidentRunbookListPage.tsx';
import LifecycleRunbookListPage from 'pages/runbook-list/LifecycleRunbookListPage.tsx';
import OnDemandRunbookListPage from 'pages/runbook-list/OnDemandRunbookListPage.tsx';
import WebhookRunbookListPage from 'pages/runbook-list/WebhookRunbookListPage.tsx';
import ViewRunbookPage from "pages/view-runbook/ViewRunbookPage.tsx";
import CreateRunbookPage from "pages/create-runbook/CreateRunbookPage.tsx";
import IncidentDetailsPage from 'pages/incident-details/IncidentDetailsPage.tsx';
import SearchPage from 'pages/search/SearchPage.tsx';
import NetworkSummaryPage from 'pages/network-summary/NetworkSummaryPage.tsx';
import { EdgeConfigPage } from 'pages/edge-configuration/EdgeConfigPage.tsx';
import { UserAccountManagement } from "pages/user-account-management/UserAccountManagement.tsx";
import { HealthMonitorPage } from 'pages/health-monitor/HealthMonitorPage.tsx';
import { UsageMonitorPage } from 'pages/usage-monitor/UsageMonitorPage.tsx';
import { MappingConfigurationPage} from 'pages/mapping-configuration/MappingConfigurationPage.tsx';
import ThirdPartyAuthenticationPage from 'pages/integrations/ThirdPartyAuthenticationPage.tsx';
import UserPreferencesPage from 'pages/user-preferences/UserPreferencesPage.tsx';
import IncidentSearchPage from 'pages/incident-search/IncidentSearchPage.tsx';
import DashboardsPage from 'pages/dashboards/DashboardsPage.tsx';
import DashboardListPage from 'pages/dashboard-list/DashboardListPage.tsx';
import SubflowRunbookListPage from 'pages/runbook-list/SubflowRunbookListPage.tsx';
import ApiAccessPage from 'pages/api-access/ApiAccessPage.tsx';
import RunbookDetailsPage from 'pages/runbook-details/RunbookDetailsPage.tsx';
import NavigatorPage from 'pages/navigator/NavigatorPage.tsx';
import CloudIMPage from 'pages/cloudim/CloudIMPage.tsx';
import CloudIMRulesPage from 'pages/cloudim/CloudIMRulesPage.tsx';
import IntegrationsLibraryPage from 'pages/integrations/IntegrationsLibraryPage.tsx';
import AnalyticsConfigurationPage from 'pages/analytics-configuration/AnalyticsConfigurationPage.tsx';
import type { UserPreferences } from 'utils/services/UserPrefsService.ts';
import { AuthServiceProvider } from "utils/providers/AuthServiceProvider.ts";
import { tenantFF } from "utils/stores/FeatureFlagStore.ts";
import { cloneDeep, isEmpty } from "lodash";
import type { FeatureFlagUnions } from "utils/services/FeatureFlagService.ts";
import PredictionSummaryPage from "pages/prediction-summary/PredictionSummaryPage.tsx";
import { AssistAdminPage } from "pages/assist-admin/AssistAdminPage.tsx";
import { Blank } from "./Blank.tsx";
import type { RouteObject } from "react-router-dom";

/** this constant has the environment that the UI is being executed in: development, staging, production. */
let ENV = import.meta.env.VITE_ENV_SHORT;

/** this type defines a route for the IQ application. */
export type AppRoute = {
    [index: string]: routeType
}

/** this type defines a route. */
export type routeType = RouteObject & {
    key?: string;
    path: string;
    parameters?: string;
    title: string;
    icon: IconName;
    /** string union literal of all feature flags */
    featureFlag?: FeatureFlagUnions;
    pathType?: "absolute" | "relative";
    envs?: string[];
    menuUserPrefAttribute?: string;
    component?: React.FunctionComponent | React.ComponentClass;
    /** roles which aren't allowed to view the route. */
    bannedPermissions?: string[];
};

const ROUTES: AppRoute = {
    'support': {
        key: 'support',
        path: 'https://support.riverbed.com/content/support.html',
        pathType: 'absolute',
        title: STRINGS.SUPPORT_PAGE.title,
        icon: IconNames.LIFESAVER,
        component: Support,
    },
    'profile': {
        key: 'profile',
        path: '/profile',
        title: STRINGS.USER_PROFILE_PAGE.title,
        icon: IconNames.USER,
        component: UserProfile,
    },
    'search': {
        key: 'search',
        path: '/search',
        title: STRINGS.SEARCH_PAGE.title,
        icon: SDWAN_ICONS.SEARCH,
        component: SearchPage,
    },
    'riverbed-advisor': {
        key: 'riverbed-advisor',
        path: '/riverbed-advisor',
        title: 'Riverbed Advisor',
        icon: IconNames.DEVICES,
        component: RiverbedAdvisorPage,
    },
    'incident-runbooks': {
        key: 'incident-runbooks',
        path: '/incident-runbooks',
        title: ["dev", "staging", "prod"].includes(ENV) ? 'Incident Runbooks' : 'Runbooks',
        icon: SDWAN_ICONS.RUNBOOK,
        component: IncidentRunbookListPage,
    },
    'lifecycle-runbooks': {
        key: 'lifecycle-runbooks',
        path: '/lifecycle-runbooks',
        title: 'Lifecycle Runbooks',
        icon: SDWAN_ICONS.RUNBOOK,
        component: LifecycleRunbookListPage,
    },
    "on-demand-runbooks": {
        key: "on-demand-runbooks",
        path: "/on-demand-runbooks",
        title: "On-Demand Runbooks",
        icon: SDWAN_ICONS.RUNBOOK,
        component: OnDemandRunbookListPage,
    },
    "webhook-runbooks": {
        key: "webhook-runbooks",
        path: "/webhook-runbooks",
        title: "External Runbooks",
        icon: SDWAN_ICONS.RUNBOOK,
        component: WebhookRunbookListPage,
    },
    "runbook-outputs": {
        key: "runbook-outputs",
        path: "/runbook-outputs",
        title: "Runbook Analyses",
        parameters: "searchType=ondemandrunbooks",
        icon: SDWAN_ICONS.RUNBOOK,
        component: IncidentSearchPage,
    },
    'runbook-details': {
        key: 'runbook-details',
        path: '/runbook-details',
        title: 'Runbook Details',
        icon: SDWAN_ICONS.RUNBOOK,
        component: RunbookDetailsPage,
    },
    'view-runbook': {
        key: 'view-runbook',
        path: '/view-runbook',
        title: 'View Runbook',
        icon: SDWAN_ICONS.RUNBOOK,
        component: ViewRunbookPage,
    },
    'create-runbook': {
        key: 'create-runbook',
        path: '/create-runbook',
        title: 'Create Runbooks',
        icon: SDWAN_ICONS.RUNBOOK,
        component: CreateRunbookPage,
    },
    'incident': {
        key: 'incident',
        path: '/incident',
        title: '',
        icon: SDWAN_ICONS.INCIDENT,
        component: IncidentDetailsPage,
    },
    'impact-dashboard': {
        key: 'impact-dashboard',
        path: '/impact-dashboard',
        title: 'Impact Dashboard',
        icon: IconNames.GLOBE_NETWORK,
        component: NetworkSummaryPage,
    },
    'ai-prediction-dashboard': {
        key: 'ai-prediction-dashboard',
        path: '/ai-prediction-dashboard',
        title: STRINGS.predictionSummaryDashboard.title,
        icon: IconNames.GLOBE_NETWORK,
        component: PredictionSummaryPage,
    },
    'user-account-management': {
        key: 'user-account-management',
        path: '/users',
        title: STRINGS.USER_ACCOUNT_MANAGEMENT.title,
        icon: IconNames.USER,
        component: UserAccountManagement
    },
    'health-monitor': {
        key: 'health-monitor',
        path: '/health-monitor',
        title: STRINGS.HEALTH_MONITORING_PAGE.menuTitle,
        icon: IconNames.SYMBOL_CROSS,
        component: HealthMonitorPage
    },
    'usage-monitor': {
        key: 'usage-monitor',
        path: '/usage-monitor',
        title: STRINGS.USAGE_MONITORING_PAGE.menuTitle,
        icon: IconNames.DASHBOARD,
        component: UsageMonitorPage
    },
    'edge-configuration': {
        key: 'edge-configuration',
        path: '/edge-configuration',
        title: STRINGS.DATA_SOURCES.menuTitle,
        icon: IconNames.FEED,
        component: EdgeConfigPage
    },
    'mapping-configuration': {
        key: 'mapping-configuration',
        path: '/mapping-configuration',
        title: ["dev", "staging", "prod"].includes(ENV) ? STRINGS.MAPPING_CONFIGURATION_PAGE.menuTitle : STRINGS.MAPPING_CONFIGURATION_PAGE.menuTitleOld,
        icon: BPIcons.LIGHTNING,
        component: MappingConfigurationPage
    },
    // NOTE: Hiding per BUG 20727, will add back later
    // 'subscriptions': {
    //     key: 'subscriptions',
    //     path: '/subscriptions',
    //     title: STRINGS.SUBSCRIPTIONS_PAGE.menuTitle,
    //     icon: IconNames.FEED_SUBSCRIBED,
    //     component: SubscriptionsPage
    // },
    'third-party-authentication': {
        key: 'third-party-authentication',
        path: '/third-party-authentication',
        title: STRINGS.thirdPartyIntegrations.title,
        icon: IconNames.KEY,
        component: ThirdPartyAuthenticationPage,
    },
    'client-api-access': {
        key: 'client-api-access',
        path: '/client-api-access',
        title: STRINGS.apiAccess.title,
        icon: IconNames.KEY,
        component: ApiAccessPage,
    },
    'user-preferences': {
        key: 'user-preferences',
        path: '/user-preferences',
        title: STRINGS.userPreferences.title,
        icon: IconNames.USER,
        menuUserPrefAttribute: 'showUserPreferences',
        component: UserPreferencesPage,
    },
    'incident-search': {
        key: 'incident-search',
        path: '/incident-search',
        parameters: 'searchType=incident',
        title: STRINGS.incidentSearch.types.incident.title,
        icon: STRINGS.incidentSearch.types.incident.icon,
        component: IncidentSearchPage,
    },
    'explorer': {
        key: 'explorer',
        path: '/explorer',
        parameters: 'searchType=incident',
        title: STRINGS.incidentSearch.types.incident.title,
        icon: STRINGS.incidentSearch.types.incident.icon,
        component: IncidentSearchPage,
    },
    'explorer-devices': {
        key: 'explorer',
        path: '/explorer',
        parameters: 'searchType=device',
        title: STRINGS.incidentSearch.types.device.title,
        icon: STRINGS.incidentSearch.types.device.icon,
        component: IncidentSearchPage,
    },
    'explorer-interfaces': {
        key: 'explorer',
        path: '/explorer',
        parameters: 'searchType=interface',
        title: STRINGS.incidentSearch.types.interface.title,
        icon: STRINGS.incidentSearch.types.interface.icon,
        component: IncidentSearchPage,
    },
    'explorer-locations': {
        key: 'explorer',
        path: '/explorer',
        parameters: 'searchType=location',
        title: STRINGS.incidentSearch.types.location.title,
        icon: STRINGS.incidentSearch.types.location.icon,
        component: IncidentSearchPage,
    },
    'explorer-applications': {
        key: 'explorer',
        path: '/explorer',
        parameters: 'searchType=application',
        title: STRINGS.incidentSearch.types.application.title,
        icon: STRINGS.incidentSearch.types.application.icon,
        component: IncidentSearchPage,
    },
    'explorer-tcpconnections': {
        key: 'explorer',
        path: '/explorer',
        parameters: 'searchType=tcpconnection',
        title: STRINGS.incidentSearch.types.tcpconnection.title,
        icon: STRINGS.incidentSearch.types.tcpconnection.icon,
        envs: ["dev", /*"staging", "prod"*/],
        component: IncidentSearchPage,
    },
    'explorer-properties': {
        key: 'explorer',
        path: '/explorer',
        parameters: 'searchType=properties',
        title: STRINGS.incidentSearch.types.properties.title,
        icon: STRINGS.incidentSearch.types.properties.icon,
        component: IncidentSearchPage,
    },
    'runbook-schedules': {
        key: 'runbook-schedules',
        path: '/runbook-schedules',
        parameters: 'searchType=runbookschedules',
        title: STRINGS.incidentSearch.types.runbookschedules.title,
        icon: STRINGS.incidentSearch.types.runbookschedules.icon,
        envs: ["dev", "staging", "prod"],
        bannedPermissions: ["read"],
        component: IncidentSearchPage,
    },
    'view-dashboard': {
        key: 'view-dashboard',
        path: '/view-dashboard',
        title: STRINGS.dashboards.title,
        icon: IconNames.DASHBOARD,
        component: DashboardsPage,
    },
    'dashboards': {
        key: 'dashboards',
        path: '/dashboards',
        title: STRINGS.dashboards.title,
        icon: IconNames.DASHBOARD,
        menuUserPrefAttribute: 'showDashboards',
        component: DashboardListPage,
    },
    'navigator': {
        key: 'navigator',
        path: '/navigator',
        title: STRINGS.navigators.title,
        icon: IconNames.DASHBOARD,
        envs: ["dev", "staging"/*, "prod"*/],
        menuUserPrefAttribute: 'showNpmPlus',
        component: NavigatorPage,
    },
    'subflows': {
        key: 'subflows',
        path: '/subflows',
        title: STRINGS.subflows.title,
        icon: SDWAN_ICONS.RUNBOOK,
        component: SubflowRunbookListPage,
    },
    'cloudim': {
        key: 'cloudim',
        path: '/cloudim',
        title: STRINGS.cloudim.menuCategory,
        icon: IconNames.CLOUD,
        featureFlag: "cloudim",
        component: CloudIMPage,
    },
    'cloudim-theater': {
        key: 'cloudim',
        path: '/cloudim',
        title: STRINGS.cloudim.menuTitle,
        icon: IconNames.MAP,
        featureFlag: "cloudim",
        component: CloudIMPage,
    },
    'cloudim-assets': {
        key: 'explorer',
        path: '/explorer',
        parameters: 'searchType=cloudassets',
        title: STRINGS.incidentSearch.types.cloudassets.title,
        icon: STRINGS.incidentSearch.types.cloudassets.icon,
        featureFlag: "cloudim",
        component: IncidentSearchPage,
    },
    'cloudim-rules': {
        key: 'cloudim-rules',
        path: '/cloudim-rules',
        title: STRINGS.cloudimRules.menuTitle,
        icon: IconNames.DOCUMENT,
        menuUserPrefAttribute: 'showCloudIMDemoMode',
        featureFlag: "cloudim",
        component: CloudIMRulesPage,
    },
    'integrations': {
        key: 'integrations',
        path: '/integrations',
        title: STRINGS.INTEGRATIONS_PAGE.title,
        icon: IconNames.CUBE_ADD,
        envs: ["dev", "staging", "prod"],
        component: IntegrationsLibraryPage,
    },
    'analytics-configuration': {
        key: 'analytics-configuration',
        path: '/analytics-configuration',
        title: STRINGS.ANALYTICS_CONFIGURATION_PAGE.title,
        icon: IconNames.CHART,
        envs: ["dev", "staging", "prod"],
        component: AnalyticsConfigurationPage,
    },
    'iq-assist-administration': {
        key: 'iq-assist-administration',
        path: '/iq-assist-administration',
        title: STRINGS.IQ_ASSIST_ADMIN_PAGE.subTitle,
        envs: ["dev", "staging", "prod"],
        icon: IconNames.AI_BIS_1,
        component: AssistAdminPage,
    },
    'blank': {
        key: 'blank',
        path: '/blank',
        title: '',
        icon: IconNames.BLANK,
        component: Blank,
    }
};

export function GetURLPath(routeName, absolute = false) {
    if (ROUTES[routeName]) {
        if (ROUTES[routeName]?.pathType === 'absolute' || absolute) {
            return ROUTES[routeName].path;
        } else {
            return import.meta.env.VITE_PUBLIC_URL + ROUTES[routeName]?.path;
        }
    } else {
        console.error("Missing route definition for route name " + routeName);
        return "/";
    }
};

/**
 * Returns all the defined routes.
 * @returns an object with all the defined routes. */
export function GetRawRoutes(): AppRoute {
    return ROUTES;
};

/**
 * Returns all the defined routes.
 * @returns an object with all the defined routes. */
export function GetRoutes(): AppRoute {
    const routes: AppRoute = cloneDeep(ROUTES);

    for (const routeName in routes) {
        const route = routes[routeName];
        route.path = GetURLPath(routeName);
    }

    return routes;
};

/**
 * returns the list of routes filtered by those routes that should be hidden
 * based on feature flags, environment and the user preferences.
 * @param userPreferences the UserPreferences object.
 * @returns the list of filtered routes. */
export function GetFilteredRoutes(userPreferences?: UserPreferences): AppRoute {
    const AuthService = AuthServiceProvider.getService();
    const featureFlags = tenantFF();

    const routes: AppRoute = cloneDeep(ROUTES);

    for (const routeName in routes) {
        const route = routes[routeName];
        route.path = GetURLPath(routeName);
        if (
            Object.prototype.hasOwnProperty.call(route, "featureFlag") &&
            route.featureFlag !== undefined
        ) {
            if (
                isEmpty(featureFlags) ||
                featureFlags[route.featureFlag] === false ||
                featureFlags[route.featureFlag] === undefined
            ) {
                delete routes[routeName];
            }
        }
        if (
            (route.envs && !route.envs.includes(ENV)) ||
            (route.menuUserPrefAttribute &&
                !userPreferences?.menu?.[route.menuUserPrefAttribute])
        ) {
            delete routes[routeName];
        }
        if (
            Object.prototype.hasOwnProperty.call(route, "bannedPermissions") &&
            route.bannedPermissions !== undefined &&
            route.bannedPermissions.length >= 1
        ) {
            const roles = route.bannedPermissions;
            const userHasReadAccess = AuthService.userHasSinglePermission(roles);

            if (userHasReadAccess) {
                delete routes[routeName];
            }
        }
    }

    return routes;
}

// WARN: is this necessary? vite has access to env
/** This should only be used by the unit tests.  It is used to set the environment for the unit test harness.
*  @param env a String with the environment to set. */
export function setEnv(env: string): void {
    ENV = env;
}
