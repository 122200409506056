/** This module contains utilities for editing logic nodes and validating http nodes.
 *  @module
 */

import { STRINGS } from "app-strings";
import { NodeUtils } from "utils/runbooks/NodeUtil";
import { 
    getParentsFromGraphDef, isDataNodeFromGraphDef, isHttpNodeFromGraphDef, isSubflowNodeFromGraphDef, 
    isTriggerNodeFromGraphDef, isVariablesNodeFromGraphDef 
} from "utils/runbooks/RunbookUtils.ts";
import { GraphDef, NodeDef } from "components/common/graph/types/GraphTypes.ts";
import { HTTP_NODE_EDIT_PROPS } from "components/common/graph/editors/http/HttpNodeEditor.tsx";
import { VariableContextByScope } from "utils/runbooks/RunbookContext.class.ts";

 /** This is a Utility class for the HTTP node. */
export class HttpNodeUtil extends NodeUtils{
    /** a static member variable with the error strings for the logic node. */
    static errMsgs = STRINGS.runbookEditor.errors.httpNode;

    /** Parse an Object containing keys and values to string
     *  @param headers - headers object
     *  @return  headers string. */
    static parseHttpHeadersToString(headers: object) {
        if (!headers) {
            return '';
        }
		let headersString = '';
        Object.entries(headers).forEach(([key, value]) => {
            headersString += `${key}: ${value}\n`
        });
		return headersString;
	}
	
    /** Parse a string to an Object containing keys and values for headers
     *  @param headers - headers string
     *  @return  headers object. */
	static parseStringToHttpHeaders(headers: string) {
		const lines = headers ? headers.split('\n') : [];
		const headersObj = {};
		lines.forEach(line => {
			const keyValuePairs = line.split(':');
			if (keyValuePairs.length > 1) {
				const key = keyValuePairs[0].trim();
				const value = keyValuePairs.splice(1).join().trim();
				if (!headersObj[key]) {
					headersObj[key] = value;
				} else {
					headersObj[key] = headersObj[key] + ', ' + value;
				}
			}
		});
		return headersObj;
	}

    
    /** Check if a logic node is valid. Validates in the context of other nodes in the graph
     *  @param nodeId - node identifier
     *  @param graphDef - graph with info on al the nodes. 
     *  @param variables the map of variables by scope.
     *  @return  is node valid. */
    static isNodeValid(nodeId: string | undefined | null, graphDef: GraphDef, variables?: VariableContextByScope): boolean {
        if(!nodeId) {
            console.error(" isNodeValid: nodeId is undefined ");
            return false;
        }
        const errors = [];
        HttpNodeUtil.validateNode(nodeId, errors, graphDef, variables)
        return errors.length === 0;
    }

    /** Check if a logic node is valid. Validates in the context of other nodes in the graph.
     *  Populates the errors.
     *  @param nodeId - node identifier
     *  @param errors - IN-OUT argument the array his populated with error messages. Empty array if
     *  there are no errors
     *  @param graphDef - graph with info on al the nodes. 
     *  @param variables the map of variables by scope.*/
    static validateNode(nodeId: string, errors: string[], graphDef: GraphDef, variables?: VariableContextByScope): void {
        let curNode = graphDef.nodes.find((n) => {
            return nodeId === n.id
        });

        // no-op currenty.
        super.validateNode(nodeId, errors, graphDef, variables);

        if (!NodeUtils.getPropertyFromNode(HTTP_NODE_EDIT_PROPS.SYNC_CALL, curNode)?.value.request) {
            errors.push(HttpNodeUtil.errMsgs.nodeNotConfigured);
        } else {
            let fieldErrors = HttpNodeUtil.checkMandatoryFields(NodeUtils.getPropertyFromNode(HTTP_NODE_EDIT_PROPS.SYNC_CALL, curNode)?.value);
            for (const fieldError of fieldErrors) {
                errors.push(fieldError);
            }
        }

        // Check compatibility with the parent DO node
        HttpNodeUtil.validateDONodeCompatiblity(curNode, errors, graphDef);
    }

    static checkMandatoryFields(httpNodeProperties, authProfiles?) {
        const errors = new Array<string>();
        const hasEmptyKeyOrValue = (obj) => {
            for (const key in obj) {
                if (key === '' || obj[key] === '') {
                    return true;
                }
            }
            return false;
        };
        if (httpNodeProperties.request) {
            if (
                !errors.includes(HttpNodeUtil.errMsgs.urlEmptyUserInput) &&
                (!httpNodeProperties.request.endpointTemplate)
            ) {
                errors.push(HttpNodeUtil.errMsgs.urlEmptyUserInput);
            }
            if (
                !errors.includes(HttpNodeUtil.errMsgs.contentTypeEmptyUserInput) &&
                (!httpNodeProperties.request.headers.contentType)
            ) {
                errors.push(HttpNodeUtil.errMsgs.contentTypeEmptyUserInput);
            }
            if (
                !errors.includes(HttpNodeUtil.errMsgs.acceptEmptyUserInput) &&
                (!httpNodeProperties.request.headers.accept)
            ) {
                errors.push(HttpNodeUtil.errMsgs.acceptEmptyUserInput);
            }
            if (httpNodeProperties.request.authenticationProfileId) {
                if (authProfiles) {
                    const selectedProfile = authProfiles?.find(item => item.id === httpNodeProperties.request.authenticationProfileId);
                    if (selectedProfile?.edgeDeviceIds?.length && !httpNodeProperties.request.edgeDeviceId) {
                        errors.push(HttpNodeUtil.errMsgs.alluvioEdgeInput);
                    }
                }
            }
            if (httpNodeProperties.request.retryOptions && httpNodeProperties.request.retryOptions.totalWaitTime
                    && !httpNodeProperties.request.retryOptions.statusCheckTemplate
                    && !errors.includes(HttpNodeUtil.errMsgs.liquidTemplateEmptyUserInput))  {
                errors.push(HttpNodeUtil.errMsgs.liquidTemplateEmptyUserInput);
            }
            if (httpNodeProperties.request.retryOptions && !httpNodeProperties.request.retryOptions.totalWaitTime
                && httpNodeProperties.request.retryOptions.statusCheckTemplate
                && !errors.includes(HttpNodeUtil.errMsgs.timeEmptyUserInput))  {
                errors.push(HttpNodeUtil.errMsgs.timeEmptyUserInput);
            }
            if (httpNodeProperties.request.retryOptions
                && httpNodeProperties.request.retryOptions.totalWaitTime !== undefined
                && httpNodeProperties.request.retryOptions.totalWaitTime !== null
                && !errors.includes(HttpNodeUtil.errMsgs.timeValueLimits))  {
                    // validate that totalWait time is between 1 minute and 7 days
                    if (httpNodeProperties.request.retryOptions.timeMeasurementUnit === 0 &&
                            (httpNodeProperties.request.retryOptions.totalWaitTime < 60 || httpNodeProperties.request.retryOptions.totalWaitTime > 604800)) {
                                errors.push(HttpNodeUtil.errMsgs.timeValueLimits);
                    }
                    if (httpNodeProperties.request.retryOptions.timeMeasurementUnit === 1 &&
                            (httpNodeProperties.request.retryOptions.totalWaitTime < 1 || httpNodeProperties.request.retryOptions.totalWaitTime > 10080)) {
                                errors.push(HttpNodeUtil.errMsgs.timeValueLimits);
                    }
                    if (httpNodeProperties.request.retryOptions.timeMeasurementUnit === 2 &&
                            (httpNodeProperties.request.retryOptions.totalWaitTime < 1 || httpNodeProperties.request.retryOptions.totalWaitTime > 168)) {
                                errors.push(HttpNodeUtil.errMsgs.timeValueLimits);
                    }
                    if (httpNodeProperties.request.retryOptions.timeMeasurementUnit === 3 &&
                            (httpNodeProperties.request.retryOptions.totalWaitTime < 1 || httpNodeProperties.request.retryOptions.totalWaitTime > 7)) {
                                errors.push(HttpNodeUtil.errMsgs.timeValueLimits);
                    }
            }
            if (
                !errors.includes(HttpNodeUtil.errMsgs.additionalHeaderWithEmptyKeyOrValue) &&
                (httpNodeProperties.request?.headers?.additional && hasEmptyKeyOrValue(httpNodeProperties.request?.headers?.additional))
            ) {
                errors.push(HttpNodeUtil.errMsgs.additionalHeaderWithEmptyKeyOrValue);
            }
        } else {
            errors.push(HttpNodeUtil.errMsgs.unexpectedError);
        }
        return errors;
    }

    /** Validates the logic node against a parent data ocean node verifies if the input type and metric type matches
    *   Populates the errors.
    *   @param nodeId - node identifier
    *   @param errors - IN-OUT argument the array his populated with error messages. Empty array if
    *   @param graphDef - graph with info on al the nodes. */
    static validateDONodeCompatiblity(node: NodeDef | undefined, errors: string[], graphDef: GraphDef): void {
        const parents = getParentsFromGraphDef(node!, graphDef);
        // Only check the parents if there is at least one parent, if there are no parents we caught that error above 
        // in the general parents check.
        if (parents && parents.length > 0) {
            // Http nodes can only have one parent and that has to be a data node or trigger
            if (parents.length !== 1) {
                // We might want to relax this in the future
                errors.push(HttpNodeUtil.errMsgs.httpNodeOneParent);
            } else if (
                !isHttpNodeFromGraphDef(parents[0]) && !isTriggerNodeFromGraphDef(parents[0]) && 
                !isDataNodeFromGraphDef(parents[0]) && !isVariablesNodeFromGraphDef(parents[0]) &&
                !isSubflowNodeFromGraphDef(parents[0])
            ) {
                errors.push(HttpNodeUtil.errMsgs.httpNodeNonTransformParent);
            }
        }
    }
    
    /**
     * Validate the URL in the HTTP Node Editor
     * 
     * @param value { string }
     */
    static validateURL(value: any, variables: VariableContextByScope) {
        let errors: Array<string> = [];
        const VARIABLES_REGEX = /\{{variables\["(.*?)"]}}/g;
        const URL_REGEX = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/;

        if (value.includes('{{')) {
            const variablesInUrl = [...value.matchAll(VARIABLES_REGEX)].map(el => el[1]);
            
            errors = [...errors, ...getVariablesRelatedErrors(variablesInUrl)];
        }
            
        // Validate the URL format:
        const valueToTest = value.replace(VARIABLES_REGEX, "variable")

        if (!URL_REGEX.test(valueToTest)) {
            errors.push(HttpNodeUtil.errMsgs.invalidUrl);
        }

        /**
         * Check if the variables used in the URL are valid and return the errors
         * 
         * @param variablesInURL 
         * 
         * @returns An array containing the errors related to the variables used in the URL
         */
        function getVariablesRelatedErrors(variablesInURL) {
            const variablesText = JSON.stringify(variables);
            let variableRelatedErrors: Array<string> = []

            variablesInURL.forEach((variable: any) => {
                // Using the quotes "" to catch the entire name of the variable
                if (!variablesText.includes(`"${variable}"`)) {
                    variableRelatedErrors.push(STRINGS.formatString(HttpNodeUtil.errMsgs.invalidUrlVariable, variable));
                }
            });

            return variableRelatedErrors;
        }
        
        return errors;
    }    
}
 