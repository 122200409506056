query runbookList($incidentId: String!, $variant: Variant) {
    runbooks: runbooks(
        filter: {incidentIds: [$incidentId], variant: $variant}
    ) {
        nodes {
            id
            incidentId
            timestamp
            entity {
                name
                kind
            }
            template
            status
            priority
            errors {
                code
                message
                innerError {
                    trace
                    properties {
                        key
                        value
                    }
                }
            }
            variant
        }
    }
}
