/** This module contains the functional React component for rendering the data ocean metric control.
 *  This control allows users to toggle whether or not the user can set the metric list.
 *  @module
 */

import React, { useEffect, useState } from 'react';
import { STRINGS } from "app-strings";
import { MultiSelectInput } from "components/common/multiselect/MultiSelectInput.tsx";
import { Intent, Radio, RadioGroup, Switch } from "@blueprintjs/core";
import { DataOceanUtils } from "components/common/graph/editors/data-ocean/DataOceanUtils.ts";
import { Icon, IconNames } from "@tir-ui/react-components";
import { DataOceanDuration } from 'components/common/graph/editors/data-ocean/DataOceanDuration.tsx';
import { DataOceanComparison } from 'components/common/graph/editors/data-ocean/DataOceanComparison.tsx';
import { DataOceanMetric as DataOceanMetricDef, QUERY_TYPE } from 'components/common/graph/editors/data-ocean/DataOceanMetadata.type.ts';
import { getTypes } from 'utils/stores/GlobalDataSourceTypeStore.ts';

/** This interface defines the properties passed into the data ocean metric React component.*/
export interface DataOceanMetricProps {
    /** the current node type, can be either data_ocean or data_ocean_dynamic. */
    type: string;
    /** the current properties object with the value of all the controls in the editor. */
    currentProperties: any;
    /** the list of metrics for the current object type. */
    selectedTypeMetrics: string[] | undefined;
    /** the list of data ocean metrics. */
    metricsMap: Record<string, DataOceanMetricDef>;
    /** a boolean value, true if a metric is required. */
    required: boolean;
    /** the handler for the metrics toggled control being clicked. */
    onMetricsToggled: (enabled: boolean) => void;
    /** the handler for the metrics change event. */
    onMetricsChanged: () => void;
    /** the handler for the query type change event. */
    onQueryTypeChanged: () => void;
    /** the handler for the duration change event. */
    onDurationChanged: () => void;
    /** an array of the supported query types.  This array can have either of summary or timeseries or both. */
    supportedQueryTypes: Array<string>;
    /** a string with the id of the current top by metric. */
    topByMetric?: string;
}

/** Renders the component to render the data ocean metric toggle field.
 *  @param props the properties passed in.
 *  @returns JSX with the react data ocean metric toggle component.*/
export function DataOceanMetric(props: DataOceanMetricProps): JSX.Element | null {
    const { 
        currentProperties, selectedTypeMetrics, metricsMap, required = false, onMetricsToggled = (enabled) => {}, 
        supportedQueryTypes, topByMetric, onMetricsChanged, onQueryTypeChanged, onDurationChanged
    } = props;
    const [metrics, setMetrics] = useState(new Array<{ label: string, value: string }>());
    const [selectedMetrics, setSelectedMetrics] = useState(new Array<{ label: string, value: string }>());
    const [timeSeries, setTimeSeries] = useState(currentProperties["timeSeries"]);
    const [showMetrics, setShowMetrics] = useState(Boolean(required === true || currentProperties['metrics']?.length));
    const [sequence, setSequence] = useState<number>(0);
    useEffect(() => {
        // @ts-ignore
        const supportedSelectedTypeMetrics: DataOceanMetricDef[] = DataOceanUtils.getAvailableMetrics(
            selectedTypeMetrics || [], currentProperties['metrics'] || [], getTypes(), false, timeSeries ? QUERY_TYPE.time_series : QUERY_TYPE.summary
        );
        const availableMetrics: {value: string, label: string}[] = DataOceanUtils.getMetricsDisplayData(supportedSelectedTypeMetrics.map(metric => metric.id));
        setMetrics(availableMetrics);

        if (currentProperties['metrics'] && currentProperties['metrics'].length) {
            setSelectedMetrics(DataOceanUtils.getMetricsDisplayData(currentProperties["metrics"]));
            setShowMetrics(true);
            onMetricsToggled(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProperties, currentProperties.objType, metricsMap, timeSeries]);


    if (!metrics.length) {
        return null;
    }
    function toggleMetricsSection (enabled = true) {
        if (enabled) {
            setShowMetrics(true);

            if(topByMetric) {
                setSelectedMetrics(DataOceanUtils.getMetricsDisplayData([topByMetric]));
                currentProperties['metrics'] = [topByMetric];
            }

        } else {
            delete currentProperties['metrics'];
            delete currentProperties['comparedTo'];
            currentProperties['timeSeries'] = false;
            setShowMetrics(false);
            setTimeSeries(false);
            setSelectedMetrics([]);
        }
        onMetricsToggled(enabled);
        onMetricsChanged();
    }

    const doHelpKey: string = props.type !== "data_ocean_dynamic" ? currentProperties.objType : "dynamic_traffic";

    return (
        <React.Fragment>
            <tr>
                <td colSpan={ 2 }>
                    <label className="mb-0 mt-2 do-required-label">
                        { STRINGS.runbookEditor.nodeLibrary.nodes.data_ocean.labels.metricsToggle }
                        <Switch
                            data-testid="data_ocean_metrics_switch"
                            className="mb-0 ms-1"
                            checked={showMetrics}
                            disabled={required}
                            onChange={() => {
                                toggleMetricsSection(!showMetrics);
                            }}
                        />
                        { required && <Icon className="vertical-align" icon={ IconNames.ASTERISK } intent={ Intent.DANGER } size={ 8 }/> }
                    </label>
                </td>
            </tr>
            {showMetrics && <tr>
                <td>
                    <label className="mb-0 pb-1">{STRINGS.runbookEditor.nodeEditor.dataFormat}</label>
                    {
                        renderOutputDataFormatSection(
                            currentProperties,
                            setTimeSeries,
                            timeSeries,
                            supportedQueryTypes,
                            sequence,
                            setSequence,
                            onQueryTypeChanged
                        )
                    }
                </td>
            </tr>}
            <tr>
                <td colSpan={2}>
                    {
                        showMetrics && 
                        <div data-testid="data_ocean_metrics">
                            <MultiSelectInput
                                sortable
                                items={ metrics.map(metric => {
                                    return { display: metric.label, value: metric.value }
                                }) }
                                selectedItems={ selectedMetrics.map(metric => {
                                    return { display: metric.label, value: metric.value }
                                }) }
                                onChange={ updatedValues => {
                                    currentProperties['metrics'] = updatedValues ? updatedValues.map(item => {
                                        return item.value
                                    }) : [];
                                    onMetricsChanged();
                                } }
                                placeholder={ STRINGS.runbookEditor.nodeLibrary.propertyLabels.metricPlaceHolder }
                                disabled={required && metrics.length === 1 && selectedMetrics.length > 0}
                            />
                        </div>
                    }
                </td>
            </tr>
            {showMetrics && <>
                    <DataOceanDuration
                        label={STRINGS.runbookEditor.nodeEditor.durationUnderMetrics}
                        currentProperties={currentProperties} sequence={sequence} onDurationChanged={onDurationChanged}
                    />
                    <DataOceanComparison
                        currentProperties={currentProperties}
                    />
                </>
            }
        </React.Fragment>
    );
}

/** Renders the Query/Output data format based on supportQueryType
 *  @param currentProperties
 *  @param setTimeSeries
 *  @param timeSeries
 *  @param supportedQueryTypes . 
 *  @param sequence . 
 *  @param setSequence .
 *  @returns the react component that sets the data format. */
const renderOutputDataFormatSection = (
    currentProperties: Record<string, any>, setTimeSeries, timeSeries, supportedQueryTypes, sequence, setSequence,
    onQueryTypeChanged: () => void
): JSX.Element => {
    // Any other condition show both as radio buttons.
    // Default behavior is too show both to avoid any script failure
    return (
        <RadioGroup
            name="timeSeries"
            onChange={ e => {
                if(e.currentTarget.value === 'timeSeries') {
                    setTimeSeries(true);
                    currentProperties['timeSeries'] = true;
                    currentProperties["duration"] = 3600;
                    setSequence(sequence + 1);
                } else {
                    setTimeSeries(false);
                    currentProperties['timeSeries'] = false;
                    currentProperties["duration"] = 900;
                    setSequence(sequence + 1);
                }
                onQueryTypeChanged();
            } }
            selectedValue={ timeSeries ? 'timeSeries' : 'summarized' }
            inline={ false }
            disabled={ supportedQueryTypes.length === 1 }
        >
            <Radio
                label={ STRINGS.runbookEditor.nodeLibrary.propertyLabels.summarized }
                value="summarized"
            />
            <Radio
                data-testid="data_ocean_time_series"
                label={ STRINGS.runbookEditor.nodeLibrary.propertyLabels.timeSeries }
                value="timeSeries"
            />
        </RadioGroup>
    );
}
