import { useState, useEffect, useRef, type ChangeEvent } from 'react';
import classNames from 'classnames';
import { type LangEN, STRINGS } from 'app-strings';
import { Button, Divider, Switch, Icon, Intent, MenuItem, FormGroup  } from '@blueprintjs/core';
import * as yup from 'yup';
import { Classes, IconNames } from '@tir-ui/react-components';
import { scrollToElement } from 'reporting-infrastructure/utils/commonUtils.ts';
import { SelectField } from 'components/common/form/SelectField.tsx';
import { InputField } from "components/common/form/InputField.tsx";
import { TextAreaField } from 'components/common/form/TextAreaField.tsx';
import { Form } from 'components/common/form/Form.tsx';
import type { OAuth2GrantType } from 'utils/services/ThirdPartyIntegrationApiService.ts';
import { WrapInTooltip } from 'components/common/wrap-in-tooltip/WrapInTooltip.tsx';
import { generateRandomID } from 'components/common/condition-tree-builder/condition/ConditionUtils.ts';
import OptionalClientCertificateFields from './OptionalClientCertificateFields.tsx';
import { isEmpty } from 'lodash';
import type { FormikProps } from 'formik';
import { Suggest } from '@blueprintjs/select';
import "./Oauth2ParamsPanel.scss";

export interface OAuth2ParamsPanelProps {
    isEdit: boolean;
    showClientCertificateFields: boolean;
    onToggleClientCertificateFields: (event) => void;
    hasClientCertificateInCloud: boolean;
    oauth2GrantType: string | undefined;
    oauth2ClientId?: string;
    oauth2ClientSecret?: string;
    oauth2AuthUri?: string;
    oauth2Scope?: string;
    oauth2Username?: string;
    oauth2Password?: string;
    oA2Data?: any;
    jwtClaims?: Array<{ name: string; value: string; isSecret: boolean; }>;
    jwtPrivateKey?: string;
    serverUri?: string;
    onChangeOA2?: (cP: any) => void;
    onChangeOauth2GrantType: (event: any) => void;
    onChangeOauth2ClientId?: (event: any) => void;
    onChangeOauth2ClientSecret?: (event: any) => void;
    onChangeOauth2AuthUri?: (event: any) => void;
    onChangeOauth2Scope?: (event: any) => void;
    onChangeUsername?: (event: any) => void;
    onChangePassword?: (event: any) => void;
    clientCertificateInPemFormat?: string;
    onChangeClientCertificateInPemFormatFromTextField?: (event) => void;
    onChangeClientCertificateInPemFormatFromFile: (event) => void;
    clientPrivateKey?: string,
    onChangeClientPrivateKeyFromTextField?: (event) => void;
    onChangeClientPrivateKeyFromFile?: (event) => void;
    passphraseForPemFormat?: string;
    onChangePassphraseForPemFormat?: (event) => void;
    onChangeJWTClaims?: (jwtClaims) => void;
    onChangeJWTPrivateKey?: (event) => void;
    onChangeJWTPrivateKeyFromFile?: (event) => void;
    onChangeServerUri?: (event) => void;
}

export enum OAuth2GrantTypeEnum {
    'ClientCredentials' = 0,
    'Password' = 1,
    'jwt' = 2,
}

export function getGrantType(grantType) {
    if (grantType && typeof grantType === 'string') {
        return grantType;
    } else if (typeof grantType === 'number') {
        return OAuth2GrantTypeEnum[grantType] as OAuth2GrantType;
    } else {
        return undefined;
    }
}

export enum OAuth2CustomParamsSendVia {
   "RequestBody" = 0,
   "RequestHeader" = 1,
   "RequestUrl" = 2
}

interface JWTClaimRow {
    name: string;
    value: string;
    isSecret: boolean;
}

const OAuth2ParamsPanel = (props: OAuth2ParamsPanelProps) => {
    const {
        oA2Data,
        onChangeOA2,
        isEdit,
        oauth2GrantType,
        oauth2ClientId,
        oauth2ClientSecret,
        oauth2AuthUri,
        oauth2Scope,
        oauth2Username,
        oauth2Password,
        serverUri,
        onChangeOauth2GrantType,
        onChangeOauth2ClientId,
        onChangeOauth2ClientSecret,
        onChangeOauth2AuthUri,
        onChangeOauth2Scope,
        onChangeUsername,
        onChangePassword,
        clientCertificateInPemFormat,
        clientPrivateKey,
        passphraseForPemFormat,
        showClientCertificateFields,
        jwtClaims,
        onChangeJWTClaims,
        jwtPrivateKey,
        onChangeJWTPrivateKey,
        onChangeJWTPrivateKeyFromFile,
        onChangeServerUri,
    } = props;
    const translations:LangEN["thirdPartyIntegrations"]["addAuthProfile"] = STRINGS.thirdPartyIntegrations.addAuthProfile;

    const initialOptionalParam = () => ({
        key: "",
        value: "",
        sendVia: "RequestHeader",
        id: generateRandomID(),
    })
    const initialRequests = [
        { label: "Request Header", value: "RequestHeader" },
        { label: "Request URL", value: "RequestUrl" },
        { label: "Request Body", value: "RequestBody" }
    ];
    const [showCustomParametersFields, setShowCustomParametersFields] = useState(!isEmpty(oA2Data));
    const [customParams, setCustomParams] = useState<Array<any>>(oA2Data.map(x => ({ ...x, id: generateRandomID() })) || []);
    const grantTypes: Array<{ label: string, value: OAuth2GrantType }> = [{
        label: 'Client Credentials',
        value: 'ClientCredentials'
    }, {
        label: 'Password',
        value: 'Password'
    }, {
        label: 'JWT Bearer',
        value: 'jwt'
    }];

    const SUGGESTED_JWT_CLAIMS = [
        "iat",
        "jti",
        "name",
        "nbf",
        "scope",
        "sub",
    ];

    const [suggestedJWTclaims, setSuggestedJWTclaims] = useState<Array<string>>(SUGGESTED_JWT_CLAIMS);
    const [revealPrivateKeyField, setRevealPrivateKeyField] = useState<boolean>(false);
    const hiddenUploadPrivateKeyFileInput = useRef<HTMLInputElement>(null);

    const validationSchema = yup.object().shape({
        grant_type: yup.string(),
        client_id: yup
            .string()
            .when([], {
                is: () => oauth2GrantType === 'ClientCredentials',
                then: yup.string().required()
            })
            .max(1024)
            .label(translations.panels
                .oAuth2Details.fields.clientId.label),
        client_secret: yup
            .string()
            .when([], {
                is: () => oauth2GrantType === 'ClientCredentials' && !props.isEdit,
                then: yup.string().required()
            })
            .max(1024)
            .label(translations.panels
                .oAuth2Details.fields.clientSecret.label),
        auth_uri: yup
            .string()
            .required()
            .max(1024)
            .label(translations.panels
                .oAuth2Details.fields.authUri.label),
        scope: yup
            .string()
            .max(1024)
            .label(translations.panels
                .oAuth2Details.fields.scope.label),
        profile_username: yup
            .string()
            .required()
            .max(2048)
            .label(translations.panels
                .basicAuthParams.fields.profileUsername.label),
        profile_password: props.isEdit ? 
            yup
                .string()
                .max(2048)
                .label(translations.panels
                    .basicAuthParams.fields.profilePassword.label):
            yup
                .string()
                .max(2048)
                .required()
                .label(translations.panels
                    .basicAuthParams.fields.profilePassword.label),
        client_certificate_in_pem_format:  
            yup.string()
                .required()
                .label(translations.panels.clientCertificateAuthParams.fields.clientCertificateUsingPemFormat.label),
        client_private_key: yup.string().test(
            `isPrivateKeyValid`, 
            STRINGS.formatString(
                translations.panels.optionalClientCertificate.fieldError, 
                translations.panels.clientCertificateAuthParams.fields.clientPrivateKey.label
            ), 
            () => {
                if (props.isEdit && props.hasClientCertificateInCloud) {
                    return true;
                }

                return ((props.clientPrivateKey || '').length > 0);
            }
        ),
        certificate_and_private_key_passphrase: yup
            .string()
            .label(translations.panels.clientCertificateAuthParams.fields.passphrase.label),
        private_key_for_signature: yup
            .string()
            .required()
            .label(translations.panels.oAuth2Details.fields.privateKeyForSignature.label),
        jwt_server_uri: yup
            .string()
            .required()
            .label(translations.panels.oAuth2Details.fields.jwtServerUri.label),
    });

    const handleCParamsChange = (index: number, field: string, value: any) => {
        const newInputGroups = [...customParams];
        newInputGroups[index][field] = value;
        setCustomParams(() => newInputGroups);
    };

    const OA2strings = translations.panels.oAuth2Details.fields.optParams;

    function getCustomParamSendViaMethod(method: string | number) {
        if (method && typeof method === "string") {
            return method;
        } 
        
        if (typeof method === "number") {
            return OAuth2CustomParamsSendVia[method];
        }

        return undefined;
    }

    const handleAddJWTClaimRow = () => {
        if (onChangeJWTClaims) {
            const newRow: JWTClaimRow = { name: "", value: "", isSecret: false };
            onChangeJWTClaims([
                ...(props.jwtClaims || []),
                newRow,
            ]);
        }
    };

    useEffect(() => {
        if (oauth2GrantType === "jwt" && onChangeJWTClaims && !jwtClaims?.length) {
            onChangeJWTClaims([
                ...(props.jwtClaims || []),
                { name: "iss", value: "", isSecret: false },
                { name: "exp", value: "auto", isSecret: false },
                { name: "aud", value: "", isSecret: false },
            ]);
        }
    }, [oauth2GrantType]);

    useEffect(() => {
        if (oauth2GrantType === "jwt" && onChangeJWTClaims) {
            setSuggestedJWTclaims(SUGGESTED_JWT_CLAIMS.filter(item => !jwtClaims?.find(claim => claim.name === item)));
        }
    }, [jwtClaims]);

    const reorderJWTClaims = (jwtClaims: Array<{ name: string; value: string; isSecret: boolean; }>) => {
        const order = ['iss', 'exp', 'aud'];
        const orderedClaims: Array<{ name: string; value: string; isSecret: boolean; }> = [];
      
        order.forEach(name => {
          const claim = jwtClaims.find(claim => claim.name === name);
          if (claim) {
            orderedClaims.push(claim);
          }
        });

        jwtClaims.forEach(claim => {
          if (!order.includes(claim.name)) {
            orderedClaims.push(claim);
          }
        });
      
        return orderedClaims;
    };

    const shouldReorderJWTClaims = (jwtClaims: Array<{ name: string; value: string; isSecret: boolean; }>)  => {
        return jwtClaims[0].name !== "iss" || jwtClaims[1].name !== "exp" || jwtClaims[2].name !== "aud"; 
    };

    useEffect(() => {
        if (
            isEdit && 
            oauth2GrantType === "jwt" && 
            onChangeJWTClaims && 
            jwtClaims?.length && 
            shouldReorderJWTClaims(jwtClaims)
        ) {
            onChangeJWTClaims(reorderJWTClaims(jwtClaims));
        }
    }, [jwtClaims]);

    const handleRemoveJWTClaimRow = (index: number) => {
        if (
            onChangeJWTClaims &&
            jwtClaims
        ) {
            onChangeJWTClaims(
                jwtClaims.filter(
                    (_row: JWTClaimRow, i: number) => i !== index
                )
            );
        }
    };

    const handleJWTClaimRowInputChange = (
        index: number,
        e: any
    ) => {
        if (
            onChangeJWTClaims &&
            jwtClaims
        ) {
            let { name, value } = e.target;
            if (e.target.name === "isSecret") {
                name = "isSecret";
                value = e.target.checked;
            }
            onChangeJWTClaims(
                jwtClaims.map(
                    (row: JWTClaimRow, i: number) => {
                        if (i === index)  {
                            const newRow = { ...row, [name]: value };
                            if (name === "name" && ["iat","nbf"].includes(value)) {
                                newRow.value = "auto";
                            }
                            if (name === "name" && !["iat","nbf"].includes(value) && newRow.value === "auto") {
                                newRow.value = "";
                            }
                            return newRow;
                        }
                        return row;
                    }
                )
            );
        }
    };

    const handleItemSelection = (item: string, index: number) => {
        handleJWTClaimRowInputChange(index, {
            target: {
                name: "name",
                value: item,
            }
        });
    };

    const createNewItemFromQuery = (query) => query;
    
    const createNewItemRenderer = (query, active, handleClick) => (
        <MenuItem
            text={`Click here to use custom claim "${query}"`}
            active={active}
            onClick={handleClick}
            shouldDismissPopover={false}
        />
    );

    const AutocompleteSuggest = Suggest.ofType<string>();

    /**
     * Render the fields for ClientCredentials grant type
     * 
     * @returns {JSX}
     */
    function renderFieldsForClientCredentials() {
        return (<>
            <InputField
                name="client_id"
                type="text"
                required={true}
                label={
                    translations.panels
                        .oAuth2Details.fields.clientId.label
                }
                placeholder={
                    translations.panels
                        .oAuth2Details.fields.clientId.placeholder
                }
                onChange={onChangeOauth2ClientId}
                value={oauth2ClientId}
                disabled={false}
            />
            <InputField
                name="client_secret"
                type="password"
                required={!isEdit}
                label={
                    translations.panels
                        .oAuth2Details.fields.clientSecret.label
                }
                placeholder={
                    isEdit ?
                        translations.panels.oAuth2Details.fields.clientSecret.placeholderForEdit:
                        translations.panels.oAuth2Details.fields.clientSecret.placeholder
                }
                onChange={(e: any) => {
                    onChangeOauth2ClientSecret && onChangeOauth2ClientSecret(e);
                }}
                value={oauth2ClientSecret}
                disabled={false}
                showPasswordOption={true}
            />
            <InputField
                name="auth_uri"
                type="text"
                required={true}
                label={
                    translations.panels
                        .oAuth2Details.fields.authUri.label
                }
                placeholder={
                    translations.panels
                        .oAuth2Details.fields.authUri.placeholder
                }
                onChange={onChangeOauth2AuthUri}
                value={oauth2AuthUri}
                disabled={false}
            />
            <InputField
                name="scope"
                type="text"
                required={false}
                label={
                    translations.panels
                        .oAuth2Details.fields.scope.label
                }
                placeholder={
                    translations.panels
                        .oAuth2Details.fields.scope.placeholder
                }
                onChange={onChangeOauth2Scope}
                value={oauth2Scope}
                disabled={false}
            />
        </>)
    }

    /**
     * Render the fields for Password grant type
     * 
     * @returns {JSX}
     */
    function renderFieldsForPassword(formProps: FormikProps<object>) {
        return <>
            <InputField
                name="profile_username"
                type="text"
                required={true}
                label={
                    translations.panels
                        .basicAuthParams.fields.profileUsername.label
                }
                placeholder={
                    translations.panels
                        .basicAuthParams.fields.profileUsername.placeholder
                }
                onBlur={(event) => {
                    const value = event.target.value?.trim();

                    formProps.handleBlur(event);
                    props.onChangeUsername && props.onChangeUsername({target: {
                        value: value
                    }});
                }}
                onChange={onChangeUsername}
                value={oauth2Username}
                disabled={false}
            />
            <InputField
                name="profile_password"
                type="password"
                required={!isEdit}
                label={
                    translations.panels
                        .basicAuthParams.fields.profilePassword.label
                }
                placeholder={
                    isEdit ?
                        translations.panels.basicAuthParams.fields.profilePassword.placeholderForEdit :
                        translations.panels.basicAuthParams.fields.profilePassword.placeholder
                }
                onBlur={(event) => {
                    const value = event.target.value?.trim();

                    formProps.handleBlur(event);

                    onChangePassword && onChangePassword({target: {
                        value: value
                    }});
                }}
                onChange={(e: any) => {
                    onChangePassword && onChangePassword(e)
                }}
                value={oauth2Password}
                disabled={false}
                showPasswordOption={true}
            />
            <InputField
                name="auth_uri"
                type="text"
                required={true}
                label={
                    translations.panels
                        .oAuth2Details.fields.authUri.label
                }
                placeholder={
                    translations.panels
                        .oAuth2Details.fields.authUri.placeholder
                }
                onBlur={(event) => {
                    const value = event.target.value?.trim();
                    
                    formProps.handleBlur(event);
                    onChangeOauth2AuthUri && onChangeOauth2AuthUri({target: {
                        value: value
                    }});
                }}
                onChange={onChangeOauth2AuthUri}
                value={oauth2AuthUri}
                disabled={false}
            />
            <InputField
                name="scope"
                type="text"
                required={false}
                label={
                    translations.panels
                        .oAuth2Details.fields.scope.label
                }
                placeholder={
                    translations.panels
                        .oAuth2Details.fields.scope.placeholder
                }
                onBlur={(event) => {
                    const value = event.target.value?.trim();

                    formProps.handleBlur(event);
                    onChangeOauth2Scope && onChangeOauth2Scope({target: {
                        value: value
                    }});
                }}
                onChange={onChangeOauth2Scope}
                value={oauth2Scope}
                disabled={false}
            />
            <InputField
                name="client_id"
                type="text"
                required={oauth2GrantType === 'ClientCredentials'}
                label={
                    translations.panels
                        .oAuth2Details.fields.clientId.label
                }
                placeholder={
                    translations.panels
                        .oAuth2Details.fields.clientId.placeholder
                }
                onBlur={(event) => {
                    const value = event.target.value?.trim();

                    formProps.handleBlur(event);
                    onChangeOauth2ClientId && onChangeOauth2ClientId({target: {
                        value: value
                    }});
                }}
                onChange={onChangeOauth2ClientId}
                value={oauth2ClientId}
                disabled={false}
            />
            <InputField
                name="client_secret"
                type="password"
                required={!isEdit && oauth2GrantType === 'ClientCredentials'}
                label={
                    translations.panels
                        .oAuth2Details.fields.clientSecret.label
                }
                placeholder={
                    isEdit ?
                        translations.panels.oAuth2Details.fields.clientSecret.placeholderForEdit:
                        translations.panels.oAuth2Details.fields.clientSecret.placeholder
                }
                onBlur={(event) => {
                    const value = event.target.value?.trim();

                    formProps.handleBlur(event);
                    onChangeOauth2ClientSecret && onChangeOauth2ClientSecret({target: {
                        value: value
                    }});
                }}
                onChange={(e: any) => {
                    onChangeOauth2ClientSecret && onChangeOauth2ClientSecret(e);
                }}
                value={oauth2ClientSecret}
                disabled={false}
                showPasswordOption={true}
            />
        </>
    }

    /**
     * Render the fields for the custom params in token request fields
     * @returns {JSX}
     */
    function renderCustomParametersFields() {
        return <fieldset className='mt-2 mb-4 border p-2 fieldset--with-bg'>
            <div className='advanced-grid fw-500 mb-2'>
                <span>{OA2strings.key}</span>
                <span>{OA2strings.value}</span>
                <span>{OA2strings.send}</span>
            </div>
            {isEmpty(customParams) &&
                <div className='text-secondary text-center pt-3 pb-2'>
                    {OA2strings.empty}
                </div>}
            {customParams.map((cParam, i) => (
                <div key={cParam.id}>
                    <div className='advanced-grid position-relative'>
                        <InputField name={"key" + i}
                            defaultValue={cParam.key}
                            onChange={(e) => {
                                handleCParamsChange(i, "key", e.currentTarget.value);
                                onChangeOA2?.([...customParams]);
                            } } />
                        <InputField name={"value" + i}
                            defaultValue={cParam.value}
                            onChange={(e) => {
                                handleCParamsChange(i, "value", e.currentTarget.value);
                                onChangeOA2?.([...customParams]);
                            } } />
                        <SelectField name={"via" + i}
                            defaultValue={getCustomParamSendViaMethod(cParam.sendVia)}
                            onChange={e => {
                                handleCParamsChange(i, "sendVia", e.currentTarget.value);
                                onChangeOA2?.([...customParams]);
                            } }>
                            {initialRequests.map((via, i) => <option key={i} value={getCustomParamSendViaMethod(via.value)}>{via.label}</option>)}
                        </SelectField>
                        <WrapInTooltip tooltip={OA2strings.tooltip}>
                            <Button minimal
                                icon={IconNames.SMALL_CROSS}
                                className='mt-3'
                                onClick={() => {
                                    const newParams = customParams.filter(item => item.id !== cParam.id);
                                    setCustomParams([...newParams]);
                                    onChangeOA2?.([...newParams]);
                                } } />
                        </WrapInTooltip>
                    </div>
                    {Object.values(cParam).some(field => field === "") && <span className="field-error">{OA2strings.error}</span>}
                </div>
            ))}
            <Button fill large
                id="add-custom-parameters-btn"
                icon={IconNames.PLUS}
                className="btn-dotted mt-3"
                onClick={() => {
                    const newParams = [...customParams, initialOptionalParam()];
                    setCustomParams([...newParams]);
                    onChangeOA2?.([...newParams]);
                } }>
                {OA2strings.addParam}
            </Button>
        </fieldset>;
    }

    /**
     * Render the fields for JWT Bearer grant type
     * 
     * @returns {JSX}
     */

    function renderJWTBearerFields(formProps: FormikProps<object>) {
        return <div className="jwt-bearer-params">
            <p className="mb-2"><b>{OA2strings.jwtClaimSet}</b></p>
            <div className="row w-100">
                <div className="col-md-3 mb-2">{OA2strings.claim}</div>
                <div className="col-md-6 mb-2">{OA2strings.value}</div>
                <div className="col-md-2 mb-2">{OA2strings.isSecret}</div>
                <div className="col-md-1 mb-2"></div>
            </div>
            <div className="row w-100">
                <div className="col-md-3">
                    <InputField
                        name="name"
                        type="text"
                        value={"iss"}
                        style={{"pointerEvents": "none"}}
                    />
                </div>
                <div className="col-md-6">
                    <InputField
                        name="value"
                        type="text"
                        value={jwtClaims?.[0]?.value}
                        onChange={(
                            e: ChangeEvent<HTMLInputElement>
                        ) =>
                            handleJWTClaimRowInputChange(
                                0,
                                e
                            )
                        }
                        onBlur={(event) => {
                            const value = event.target.value?.trim();
                            handleJWTClaimRowInputChange(
                                0,
                                {
                                    target: {
                                        name: "value",
                                        value: value,
                                    }
                                }
                            )
                        }}
                        placeholder={isEdit && jwtClaims?.find(item => item.name === "iss")?.isSecret ? OA2strings.changeFieldToUpdateValue : ""}
                    />
                </div>
                <div className="col-md-2">
                    <Switch
                        checked={jwtClaims?.[0]?.isSecret}
                        onChange={(
                            e: ChangeEvent<HTMLInputElement>
                        ) =>
                            handleJWTClaimRowInputChange(
                                0,
                                e
                            )
                        }
                        name="isSecret"
                        className="is-secret-toggle"
                    />
                </div>
                <div className="col-md-1"></div>
            </div>
            <div className="row w-100">
                <div className="col-md-3">
                    <InputField
                        name="name"
                        type="text"
                        value={"exp"}
                        style={{"pointerEvents": "none"}}
                    />
                </div>
                <div className="col-md-6">
                    <InputField
                        name="value"
                        type="text"
                        value={"auto"}
                        disabled={true}
                    />
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-1"></div>
            </div>
            <div className="row w-100">
                <div className="col-md-3">
                    <InputField
                        name="name"
                        type="text"
                        value={"aud"}
                        style={{"pointerEvents": "none"}}
                    />
                </div>
                <div className="col-md-6">
                    <InputField
                        name="value"
                        type="text"
                        onChange={(
                            e: ChangeEvent<HTMLInputElement>
                        ) =>
                            handleJWTClaimRowInputChange(
                                2,
                                e
                            )
                        }
                        value={jwtClaims?.[2]?.value}
                        onBlur={(event) => {
                            const value = event.target.value?.trim();
                            handleJWTClaimRowInputChange(
                                2,
                                {
                                    target: {
                                        name: "value",
                                        value: value,
                                    }
                                }
                            )
                        }}
                        placeholder={isEdit && jwtClaims?.find(item => item.name === "aud")?.isSecret ? OA2strings.changeFieldToUpdateValue : ""}
                    />
                </div>
                <div className="col-md-2">
                    <Switch
                        checked={jwtClaims?.[2]?.isSecret}
                        onChange={(
                            e: ChangeEvent<HTMLInputElement>
                        ) =>
                            handleJWTClaimRowInputChange(
                                2,
                                e
                            )
                        }
                        name="isSecret"
                        className="is-secret-toggle"
                    />
                </div>
                <div className="col-md-1"></div>
            </div>
            {!!jwtClaims?.length && jwtClaims.length > 3 &&
                jwtClaims.slice(3).map(
                    (
                        row: JWTClaimRow,
                        index: number
                    ) => (
            <div className="row w-100" key={index}>
                <div className="col-md-3">
                    <FormGroup>
                        <AutocompleteSuggest
                            key={`autocomplete-${index}-${row.name}`}
                            inputProps={{
                                placeholder: "",
                                name: "name",
                            }}
                            defaultSelectedItem={row.name}
                            resetOnSelect={true}
                            closeOnSelect={true}
                            resetOnClose={true}
                            popoverProps={{
                                minimal: true,
                                rootBoundary: "viewport",
                            }}
                            items={suggestedJWTclaims}
                            itemRenderer={renderAutocompleteItem}
                            itemPredicate={(query, item) => {
                                const queryLowerCase =
                                    query?.toLowerCase() || "";
                                return item
                                    .toLowerCase()
                                    .includes(queryLowerCase)
                                    ? true
                                    : false;
                            }}
                            fill={true}
                            inputValueRenderer={(item) => item}
                            onItemSelect={item => handleItemSelection(item, index+3)}
                            noResults={
                                <MenuItem
                                    disabled={true}
                                    text={
                                        STRINGS.globalFilters.empty
                                    }
                                />
                            }
                            createNewItemFromQuery={
                                createNewItemFromQuery
                            }
                            createNewItemRenderer={
                                createNewItemRenderer
                            }
                        />
                    </FormGroup>
                </div>
                <div className="col-md-6">
                    <InputField
                        name="value"
                        type="text"
                        required={true}
                        onChange={(
                            e: ChangeEvent<HTMLInputElement>
                        ) =>
                            handleJWTClaimRowInputChange(
                                index+3,
                                e
                            )
                        }
                        value={row.value}
                        disabled={["iat","nbf"].includes(row.name)}
                        onBlur={(event) => {
                            const value = event.target.value?.trim();
                            handleJWTClaimRowInputChange(
                                index+3,
                                {
                                    target: {
                                        name: "value",
                                        value: value,
                                    }
                                }
                            )
                        }}
                        placeholder={isEdit && jwtClaims?.find(item => item.name === row.name)?.isSecret ? OA2strings.changeFieldToUpdateValue : ""}
                    />
                </div>
                <div className="col-md-2">
                    {row.value !== "auto" && <Switch
                        checked={row.isSecret}
                        onChange={(
                            e: ChangeEvent<HTMLInputElement>
                        ) =>
                            handleJWTClaimRowInputChange(
                                index+3,
                                e
                            )
                        }
                        name="isSecret"
                        className="is-secret-toggle"
                    />}
                </div>
                <div className="remove-request-header col-md-1">
                    <Icon
                        onClick={() =>
                            handleRemoveJWTClaimRow(
                                index+3
                            )
                        }
                        intent={Intent.NONE}
                        icon={IconNames.CROSS}
                    />
                </div>
            </div>)
            )}
            <div className="add-request-header-control mb-4">
                <Button
                    id="add_request_header"
                    minimal
                    className="fw-bold"
                    icon={IconNames.ADD}
                    text={OA2strings.addClaim}
                    onClick={handleAddJWTClaimRow}
                />
            </div>
            <div className="authentication-parameters-field-wrap client-private-key mt-4">
                {(!isEdit || revealPrivateKeyField) && <TextAreaField
                    name="private_key_for_signature"
                    required={true}
                    label={translations.panels.oAuth2Details.fields.privateKeyForSignature.label}
                    placeholder={translations.panels.oAuth2Details.fields.privateKeyForSignature.placeholder}
                    onChange={onChangeJWTPrivateKey}
                    value={jwtPrivateKey}
                    style={{resize: "vertical"}}
                    onBlur={(event) => {
                        const value = event.target.value?.trim();
                        formProps.handleBlur(event);
                        onChangeJWTPrivateKey && onChangeJWTPrivateKey({
                            target: {
                                value: value,
                            },
                        });
                    }}
                />}
                {isEdit && !revealPrivateKeyField && 
                    <div className="privateKeyClearedHeading">
                        {translations.panels.oAuth2Details.fields.privateKeyForSignature.label}
                        <span><Icon icon="tick-circle" size={17} intent="success" /> {translations.panels.oAuth2Details.fields.privateKeyForSignature.uploaded}</span>
                        <Button intent="primary" onClick={() => {
                            setRevealPrivateKeyField(true);
                        }} type="button">{translations.panels.oAuth2Details.fields.privateKeyForSignature.clear}</Button>
                    </div>
                }
                <div className="authentication-parameters-upload-field-wrap">
                    {(!isEdit || revealPrivateKeyField) && <Button intent="primary" onClick={() => { hiddenUploadPrivateKeyFileInput.current?.click(); }} type="button">{translations.panels.oAuth2Details.fields.privateKeyForSignature.upload}</Button>}
                    <input type="file" ref={hiddenUploadPrivateKeyFileInput} onChange={onChangeJWTPrivateKeyFromFile} />
                </div>
            </div>
            <InputField
                name="jwt_server_uri"
                type="text"
                required={true}
                onChange={onChangeServerUri}
                value={serverUri}
                label={translations.panels.oAuth2Details.fields.jwtServerUri.label}
                placeholder={translations.panels.oAuth2Details.fields.jwtServerUri.placeholder}
                onBlur={(event) => {
                    const value = event.target.value?.trim();
                    formProps.handleBlur(event);
                    onChangeServerUri && onChangeServerUri({
                        target: {
                            value: value,
                        },
                    });
                }}
            />
        </div>;
    }

    return (
        <div className={classNames(Classes.DIALOG_BODY)}>
            <p>
                <b>{translations.panels.oAuth2Details.title}</b>
            </p>
            <Divider />
            <Form
                className="mt-2"
                initialValues={{
                    client_id: oauth2ClientId,
                    client_secret: oauth2ClientSecret,
                    auth_uri: oauth2AuthUri,
                    profile_username: oauth2Username,
                    profile_password: oauth2Password,
                    custom: customParams,
                    client_certificate_in_pem_format:
                        clientCertificateInPemFormat,
                    client_private_key: clientPrivateKey,
                    passphrase_for_pem_format: passphraseForPemFormat,
                    private_key_for_signature: jwtPrivateKey,
                    jwt_server_uri: serverUri,
                }}
                validationSchema={validationSchema}
                loading={false}
            >
                {(formProps: FormikProps<object>) => (
                    <>
                        <SelectField
                            name="grantType"
                            label={
                                translations.panels.oAuth2Details.fields
                                    .grantType.label
                            }
                            onChange={onChangeOauth2GrantType}
                            defaultValue={oauth2GrantType}
                        >
                            <option value="">
                                {
                                    translations.panels.oAuth2Details.fields
                                        .grantType.placeholder
                                }
                            </option>
                            {grantTypes?.map((type, i) => (
                                <option key={i} value={type.value}>
                                    {type.label}
                                </option>
                            ))}
                        </SelectField>
                        {oauth2GrantType === "Password" &&
                            renderFieldsForPassword(formProps)}
                        {oauth2GrantType === "ClientCredentials" &&
                            renderFieldsForClientCredentials()}
                        {oauth2GrantType === "jwt" && 
                            renderJWTBearerFields(formProps)}

                        {/* Custom Parameters Fields */}
                        {oauth2GrantType && oauth2GrantType !== 'jwt' &&(
                            <Switch
                                defaultChecked={!isEmpty(oA2Data)}
                                onChange={(e) => {
                                    setShowCustomParametersFields(
                                        !showCustomParametersFields
                                    );
                                    onChangeOA2?.([]);

                                    if (!showCustomParametersFields) {
                                        setCustomParams([]);
                                        scrollToElement(
                                            "add-custom-parameters-btn"
                                        );
                                    }
                                }}
                            >
                                {OA2strings.customParametersToggle}
                            </Switch>
                        )}
                        {showCustomParametersFields &&
                            renderCustomParametersFields()}

                        {/* Client Certificate Fields */}
                        {oauth2GrantType && oauth2GrantType !== 'jwt' && (
                            <Switch
                                checked={showClientCertificateFields}
                                onChange={(e) => {
                                    props.onToggleClientCertificateFields(e);

                                    if (props.showClientCertificateFields) {
                                        scrollToElement(
                                            "client_certificate_in_pem_format"
                                        );
                                    }
                                }}
                            >
                                {OA2strings.clientCertificateToggle}
                            </Switch>
                        )}
                        {showClientCertificateFields && (
                            <OptionalClientCertificateFields
                                formProps={formProps}
                                clientCertificateInPemFormat={
                                    props.clientCertificateInPemFormat
                                }
                                clientPrivateKey={props.clientPrivateKey}
                                passphraseForPemFormat={
                                    props.passphraseForPemFormat
                                }
                                hasClientCertificateInCloud={
                                    props.hasClientCertificateInCloud
                                }
                                showClientCertificateFields={
                                    props.showClientCertificateFields
                                }
                                onChangeClientCertificateInPemFormatFromTextField={
                                    props.onChangeClientCertificateInPemFormatFromTextField
                                }
                                onChangeClientCertificateInPemFormatFromFile={
                                    props.onChangeClientCertificateInPemFormatFromFile
                                }
                                onChangeClientPrivateKeyFromTextField={
                                    props.onChangeClientPrivateKeyFromTextField
                                }
                                onChangeClientPrivateKeyFromFile={
                                    props.onChangeClientPrivateKeyFromFile
                                }
                                onChangePassphraseForPemFormat={
                                    props.onChangePassphraseForPemFormat
                                }
                            />
                        )}
                    </>
                )}
            </Form>
        </div>
    );
};

const renderAutocompleteItem = (
    item: string,
    { handleClick, modifiers, query }
): JSX.Element => {
    return (
        <MenuItem
            active={modifiers.active}
            key={"ac-" + item}
            onClick={handleClick}
            text={<span>{item}</span>}
        />
    );
};

export { OAuth2ParamsPanel };
