query indicatorDetails($indicatorMapping: [IndicatorMappingInput], $startTime: String, $endTime: String) {
	indicators(
		filter: {indicatorMappings: $indicatorMapping, time: {startTime: $startTime, endTime: $endTime}}
		order: {startTime: ASC}
	) {
		nodes {
			metric
			kind
			startTime
			endTime
			details {
				actualValue
				expectedValue
				acceptableHighValue
				acceptableLowValue
                granularity
			}
		}
	}
}
