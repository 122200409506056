import type React from "react";
import { Icon } from "@tir-ui/react-components";
import { INDICATOR_TO_ICON_MAP, INDICATOR_TO_LABEL_MAP, INDICATOR_TYPE } from "components/enums/IndicatorTypes.ts";

interface IndicatorKindProps {
    kind: INDICATOR_TYPE;
    prefix?: React.ReactNode;
    className?: string;
}

export function IndicatorKind ({ kind, prefix, className }: IndicatorKindProps): JSX.Element {
    let icon = INDICATOR_TO_ICON_MAP[kind];
    return <span className={"indicator-kind" + (className ? " " + className : "")}>
        {icon && <Icon icon={icon.icon} intent={icon.intent} className={"me-1 " + icon.className || ""}/>}
        {prefix ? prefix + " " : ""}
        {INDICATOR_TO_LABEL_MAP[kind] || kind || ""}
    </span>;
}
