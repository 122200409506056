import {
    Facet, FACET_FIELDS, FacetSummary, FacetValue, FIELDS, ORDERS, SearchRequest
} from "utils/services/SearchApiService.ts";
import { DEFAULT_SEARCH_PREF, SEARCH_ENGINE, SearchPreference, SearchQueryPreference } from "utils/services/UserPrefsTypes.ts";
import { Version } from "utils/Version.class.ts";
import { PARAM_NAME } from "components/enums/QueryParams.ts";
import type { TIME_RANGE } from 'utils/stores/GlobalTimeStore.ts';
import { durationToRoundedTimeRange, TIME_DURATION } from "utils/stores/GlobalTimeStore.ts";
import { STRINGS } from "app-strings";

/** this constant specifies the default facet limit. */
export const DEFAULT_FACET_LIMIT: number = 10;

export type CustomPropertyGroupKey = "CUSTOM_PROPERTY_VALUE_NAME";

export type GroupByTypes = "CUSTOM_PROPERTY_NAME";

export type FacetVariables = any;

/** this enum defines the valid search types */
export enum SEARCH_TYPE {
    /** the enumerated value for the incident search type. */
    incident = "incident",
    /** the enumerated value for the device search type. */
    device = "device",
    /** the enumerated value for the interface search type. */
    interface = "interface",
    /** the enumerated value for the application search type. */
    application = "application",
    /** the enumerated value for the location search type. */
    location = "location",
    /** the enumerated value for cloud assets search type */
    cloudassets = "cloudassets",
    /** the enumerated value for the custom properties search type. */
    properties = "properties",
    /** the enumerated value for the tcp connections search type. */
    tcpconnection = "tcpconnection",
    /** the enumerated value for the on-demand runbooks search type */
    ondemandrunbooks = "ondemandrunbooks",
    /** the enumerated value for the runbooks schedules search type */
    runbookschedules = "runbookschedules",
    /** the enumerated value for the cloudim rules search type. */
    cloudrules = "cloudrules",
}

/** this interface defintes the parameters that are used to generate a search request. */
export interface SearchParams {
    /** a boolean value, true if we are running an initial query to get the list of facets that will be maintained across sub-queries,
     *  false otherwise. */
    runInitialSearchRequest: boolean;
    /** the search type specified by one of the enumerated SEARCH_TYPEs. */
    searchType: SEARCH_TYPE;
    /** a String with the search text. */
    searchText: string;
    /** the specified time range or undefined if there is no time range. */
    timeRange: Partial<TIME_RANGE & TIME_DURATION> | undefined;
    /** the selected facets. */
    selectedFacets: Record<string, Array<FacetValue>>;
    /** the sort column. */
    sortColumn?: FIELDS;
    /** the sort order. */
    sortOrder?: ORDERS;
    /** the optional page size. */
    pageSize?: number;
    /** the information for a paginated search request if there are additional pages. */
    paginatedSearchRequest: SearchRequest | undefined;
}

/** this interface defines the search stats for a search request. */
export interface SearchStats {
    /** a number with the value with the number of results at which the pagination stopped.  */
    truncatedAt: number;
    /** the total number of results for the current search. */
    totalCount: number;
    /** the sub count when not in the replace facet mode. */
    subCount: number;
}

/** get the search preferences object for the current filter and fuzzy search.
 *  @param searchType the SEARCH_TYPE which specifies whether this is an incident, interface, device search ...
 *  @param searchText the current fuzzy search text.
 *  @param timeRange the currently selected time range.
 *  @param selectedFacets the current list of facets.
 *  @param version the Version of the saved queries.
 *  @param sortBy the sort by specification.
 *  @param pageSize the page size value.
 *  @returns the SearchQueryPreference object with the current search query. */
/* istanbul ignore next */
export function getSearchQueryPreferences(
    searchType: SEARCH_TYPE, searchText: string, timeRange: any, selectedFacets: Record<string, Array<FacetValue>>,
    version: Version, sortBy?: { id: string, desc: boolean }, pageSize?: number
): SearchQueryPreference | null {
    let searchPrefs: SearchQueryPreference | null = null;
    if (searchText || timeRange || Object.keys(selectedFacets).length) {
        searchPrefs = {
            type: searchType,
            [PARAM_NAME.searchText]: null, [PARAM_NAME.searchTime]: null, [PARAM_NAME.facets]: null,
            [PARAM_NAME.groupedFacets]: null,
            version: version.toString(),
            [PARAM_NAME.sortColumn]: null, [PARAM_NAME.sortOrder]: null, [PARAM_NAME.pageSize]: null
        };
        if (searchText) {
            searchPrefs[PARAM_NAME.searchText] = searchText;
        }
        if (timeRange) {
            searchPrefs[PARAM_NAME.searchTime] = timeRange;
        }
        if (Object.keys(selectedFacets).length) {
            searchPrefs[PARAM_NAME.facets] = JSON.stringify(selectedFacets);
            searchPrefs[PARAM_NAME.groupedFacets] = JSON.stringify(selectedFacets);
        }
        if (sortBy) {
            searchPrefs[PARAM_NAME.sortColumn] = sortBy.id;
            searchPrefs[PARAM_NAME.sortOrder] = sortBy.desc ? "desc" : "asc";
        }
        if (pageSize && pageSize > 0) {
            searchPrefs[PARAM_NAME.pageSize] = pageSize;
        }
    }
    return searchPrefs;
}

/** creates the SearchRequest object from the specified input fields.
 *  @param searchText a String with the search text that the customer entered.
 *  @param limitsByFacetCategory the limit on the number of facet values, indexed by facet category.
 *  @param timeRange the time range the user has entered or undefined if no time range has been entered.
 *  @param selectedFacets the selected facets.
 *  @param type the type of search that the page is displaying.
 *  @param topLimit the top limit which is basically a number that specifies the page size.
 *  @param searchPreferences the search preferences.
 *  @param sortColumn a String with the id of the sort column.
 *  @param sortOrder an ORDERS value with the sort order.
 *  @returns the SearchRequest object that can be submitted to the search service. */
export function createSearchRequest(
    searchText: string, limitsByFacetCategory: Record<string, number>, timeRange: Partial<TIME_RANGE & TIME_DURATION> | undefined,
    selectedFacets: Record<string, Array<FacetValue>> | undefined, type: SEARCH_TYPE,
    topLimit: number = 10000, searchPreferences: SearchPreference | undefined, sortColumn?: FIELDS, sortOrder?: ORDERS,
): SearchRequest {
    let searchRequest: SearchRequest | undefined;

    const searchPrefs: SearchPreference = { ...DEFAULT_SEARCH_PREF, ...searchPreferences };
    const { serverSideSortingAndPagination } = searchPrefs;

    let filter: string = "";
    if (type === SEARCH_TYPE.incident) {
        switch (searchPrefs?.srchEngine) {
            case SEARCH_ENGINE.correlation_direct:
                searchRequest = {
                    type: "Incident",
                    search: getSearchString(searchText, searchPrefs), top: topLimit,
                    // This shows the total count, not the search result count 
                    count: true,
                    //highlightPreTag: "<b>", highlightPostTag: "</b>", 
                    //orderby: {field: FIELDS.devIpaddr, order: ORDERS.asc},
                    searchFields: [
                        FIELDS.incidentsDesc,
                        // Search incidents with device name, ip address and location
                        FIELDS.incidentsIndicatorsIfcName, FIELDS.incidentsIndicatorsIfcIp, FIELDS.incidentsIndicatorsIfcLocName,
                        // Search incidents with interface name, ip address, and location
                        FIELDS.incidentsIndicatorsDevName, FIELDS.incidentsIndicatorsDevIp, FIELDS.incidentsIndicatorsDevLocName,
                        // Search incidents with application name and location name
                        FIELDS.incidentsIndicatorsAppLocAppName, FIELDS.incidentsIndicatorsAppLocLocName,
                        FIELDS.incidentsImpactedAppName, FIELDS.incidentsImpactedLocName, FIELDS.incidentsImpactedUserName
                    ],
                    select: [FIELDS.type, FIELDS.incident],
                    facets: [
                        getFacetRequestObject(FACET_FIELDS.incidentsPriority, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.incidentsStatus, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.incidentsIsOngoing, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.incidentsEventCategory, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.incidentsIndicatorsDevName, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.incidentsIndicatorsDevIp, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.incidentsIndicatorsDevLocName, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.incidentsIndicatorsIfcName, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.incidentsIndicatorsIfcIp, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.incidentsIndicatorsIfcLocName, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.incidentsIndicatorsAppLocAppName, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.incidentsIndicatorsAppLocLocName, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.incidentsImpactedLocName, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.incidentsImpactedAppName, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.incidentsImpactedUserName, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.incidentsImpactedUserIp, limitsByFacetCategory)
                    ]
                    //filter: [FIELDS.incident, FIELDS.network_device, FIELDS.network_interface, FIELDS.application, FIELDS.impactedUser, FIELDS.impactedApplication, FIELDS.impactedLocation]
                };
                break;
            case SEARCH_ENGINE.correlation_dal:
                searchRequest = {
                    type: "Incident",
                    search: getSearchString(searchText, searchPrefs), top: topLimit,
                    // This shows the total count, not the search result count 
                    count: true,
                    //highlightPreTag: "<b>", highlightPostTag: "</b>", 
                    //orderby: {field: FIELDS.devIpaddr, order: ORDERS.asc},
                    searchFields: [
                        FIELDS.DESCRIPTION,
                        // Search incidents with device name, ip address and location
                        FIELDS.INTERFACE_NAME, FIELDS.INTERFACE_IP_ADDRESS, FIELDS.INTERFACE_LOCATION_NAME,
                        // Search incidents with interface name, ip address, and location
                        FIELDS.DEVICE_NAME, FIELDS.DEVICE_IP_ADDRESS, FIELDS.DEVICE_LOCATION_NAME,
                        // Search incidents with application name and location name
                        FIELDS.APPLICATION_NAME, FIELDS.APPLICATION_LOCATION_NAME,
                        FIELDS.IMPACTED_APPLICATION, FIELDS.IMPACTED_LOCATION, FIELDS.IMPACTED_USER_NAME
                    ],
                    select: [FIELDS.type, FIELDS.incident],
                    facets: [
                        getFacetRequestObject(FACET_FIELDS.PRIORITY, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.STATUS, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.COMPLETION_STATUS, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.EVENT_CATEGORY, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.DEVICE_NAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.DEVICE_IP_ADDRESS, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.DEVICE_LOCATION_NAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.INTERFACE_NAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.INTERFACE_IP_ADDRESS, limitsByFacetCategory),
                        /*New*/
                        getFacetRequestObject(FACET_FIELDS.INTERFACE_LOCATION_NAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.APPLICATION_NAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.APPLICATION_LOCATION_NAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.IMPACTED_LOCATION, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.IMPACTED_APPLICATION, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.IMPACTED_USER_NAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.IMPACTED_USER_IP_ADDRESS, limitsByFacetCategory)
                    ]
                    //filter: [FIELDS.incident, FIELDS.network_device, FIELDS.network_interface, FIELDS.application, FIELDS.impactedUser, FIELDS.impactedApplication, FIELDS.impactedLocation]
                };
                break;
        }

        filter = "";

        if (selectedFacets && selectedFacets["incident/duration"]?.length) {
            // Handle the duration facet which is something that we created and is not returned with the facets
            let durationFilter = "";
            for (const value of selectedFacets["incident/duration"]) {
                if (value === STRINGS.incidentSearch.facetView.facets["incident/duration"].ongoingValue) {
                    // Add the ongoing filter
                    durationFilter += (durationFilter.length > 0 ? " or " : "") + "incident/endTime eq null";
                }
                if (value === STRINGS.incidentSearch.facetView.facets["incident/duration"].endedValue) {
                    // Add the ongoing filter
                    durationFilter += (durationFilter.length > 0 ? " or " : "") + "incident/endTime ne null";
                }
            }
            if (durationFilter.length) {
                filter += (filter.length ? " and " : "") + "(" + durationFilter + ")";
            }
        }
    } else if (type === SEARCH_TYPE.device) {
        switch (searchPrefs?.srchEngine) {
            case SEARCH_ENGINE.correlation_direct:
                searchRequest = {
                    type: "NetworkDevice",
                    search: getSearchString(searchText, searchPrefs), top: topLimit,
                    // This shows the total count, not the search result count 
                    count: true,
                    //highlightPreTag: "<b>", highlightPostTag: "</b>", 
                    //orderby: {field: FIELDS.devIpaddr, order: ORDERS.asc},
                    searchFields: [
                        FIELDS.devsName, FIELDS.devsIpaddr, FIELDS.devsHostname, FIELDS.devsType, FIELDS.devsLocName
                    ],
                    select: [FIELDS.type, FIELDS.network_device],
                    facets: [
                        getFacetRequestObject(FACET_FIELDS.devsIpaddr, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.devsHostname, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.devsName, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.devsType, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.devsLocName, limitsByFacetCategory),
                    ]
                };
                break;
            case SEARCH_ENGINE.correlation_dal:
                searchRequest = {
                    type: "NetworkDevice",
                    search: getSearchString(searchText, searchPrefs), top: topLimit,
                    // This shows the total count, not the search result count 
                    count: true,
                    //highlightPreTag: "<b>", highlightPostTag: "</b>", 
                    //orderby: {field: FIELDS.devIpaddr, order: ORDERS.asc},
                    searchFields: [
                        FIELDS.NAME, FIELDS.IP_ADDRESS, FIELDS.HOSTNAME, FIELDS.TYPE, FIELDS.LOCATION_NAME
                    ],
                    select: [FIELDS.type, FIELDS.network_device],
                    facets: [
                        getFacetRequestObject(FACET_FIELDS.IP_ADDRESS, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.HOSTNAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.NAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.TYPE, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.LOCATION_NAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.SERIAL_NUMBER, limitsByFacetCategory),
                        //getFacetRequestObject(FACET_FIELDS.ELEMENT_TYPE, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.OS_VERSION, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.MODEL, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.VENDOR, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.IS_GATEWAY, limitsByFacetCategory),
                        getFacetRequestObject(
                            "CUSTOM_PROPERTY_VALUE_NAME",
                            limitsByFacetCategory,
                            "CUSTOM_PROPERTY_NAME",
                        ),
                    ]
                };
                break;
        }
        filter = "";
    } else if (type === SEARCH_TYPE.interface) {
        switch (searchPrefs?.srchEngine) {
            case SEARCH_ENGINE.correlation_direct:
                searchRequest = {
                    type: "NetworkInterface",
                    search: getSearchString(searchText, searchPrefs), top: topLimit,
                    // This shows the total count, not the search result count 
                    count: true,
                    //highlightPreTag: "<b>", highlightPostTag: "</b>", 
                    //orderby: {field: FIELDS.devIpaddr, order: ORDERS.asc},
                    searchFields: [
                        FIELDS.ifcsName, FIELDS.ifcsIpaddr, /*FIELDS.ifcIfindex,*/ FIELDS.ifcsLocName
                    ],
                    select: [FIELDS.type, FIELDS.network_interface],
                    facets: [
                        getFacetRequestObject(FACET_FIELDS.ifcsName, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.ifcsIpaddr, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.ifcsIfindex, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.ifcsLocName, limitsByFacetCategory)
                    ]
                };
                break;
            case SEARCH_ENGINE.correlation_dal:
                searchRequest = {
                    type: "NetworkInterface",
                    search: getSearchString(searchText, searchPrefs), top: topLimit,
                    // This shows the total count, not the search result count 
                    count: true,
                    //highlightPreTag: "<b>", highlightPostTag: "</b>", 
                    //orderby: {field: FIELDS.devIpaddr, order: ORDERS.asc},
                    searchFields: [
                        FIELDS.NAME, FIELDS.IP_ADDRESS, FIELDS.TYPE, FIELDS.LOCATION_NAME, FIELDS.IF_ALIAS, FIELDS.IF_DESCRIPTION,
                        FIELDS.DEVICE_NAME, FIELDS.DEVICE_IP_ADDRESS, FIELDS.DEVICE_HOSTNAME, FIELDS.DEVICE_TYPE,
                        FIELDS.DEVICE_SERIAL_NUMBER, FIELDS.DEVICE_OS_VERSION, FIELDS.DEVICE_MODEL,
                        //FIELDS.DEVICE_ELEMENT_TYPE, 
                        FIELDS.DEVICE_VENDOR
                    ],
                    select: [FIELDS.type, FIELDS.network_interface],
                    facets: [
                        getFacetRequestObject(FACET_FIELDS.NAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.IP_ADDRESS, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.IF_INDEX, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.LOCATION_NAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.TYPE, limitsByFacetCategory),
                        //getFacetRequestObject(FACET_FIELDS.ELEMENT_TYPE, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.IF_ALIAS, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.IF_DESCRIPTION, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.INBOUND_SPEED, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.OUTBOUND_SPEED, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.DEVICE_NAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.DEVICE_IP_ADDRESS, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.DEVICE_HOSTNAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.DEVICE_TYPE, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.DEVICE_SERIAL_NUMBER, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.DEVICE_OS_VERSION, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.DEVICE_MODEL, limitsByFacetCategory),
                        //getFacetRequestObject(FACET_FIELDS.DEVICE_ELEMENT_TYPE, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.DEVICE_VENDOR, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.DEVICE_IS_GATEWAY, limitsByFacetCategory),
                        getFacetRequestObject(
                            "CUSTOM_PROPERTY_VALUE_NAME",
                            limitsByFacetCategory,
                            "CUSTOM_PROPERTY_NAME",
                        ),
                    ]
                };
                break;
        }
        filter = "";
    } else if (type === SEARCH_TYPE.application) {
        switch (searchPrefs?.srchEngine) {
            case SEARCH_ENGINE.correlation_direct:
                searchRequest = {
                    type: "Application",
                    search: getSearchString(searchText, searchPrefs), top: topLimit,
                    // This shows the total count, not the search result count 
                    count: true,
                    //highlightPreTag: "<b>", highlightPostTag: "</b>", 
                    //orderby: {field: FIELDS.devIpaddr, order: ORDERS.asc},
                    searchFields: [
                        FIELDS.appsName
                    ],
                    select: [FIELDS.type, FIELDS.application],
                    facets: [
                        getFacetRequestObject(FACET_FIELDS.appsName, limitsByFacetCategory),
                    ]
                };
                break;
            case SEARCH_ENGINE.correlation_dal:
                searchRequest = {
                    type: "Application",
                    search: getSearchString(searchText, searchPrefs), top: topLimit,
                    // This shows the total count, not the search result count 
                    count: true,
                    //highlightPreTag: "<b>", highlightPostTag: "</b>", 
                    //orderby: {field: FIELDS.devIpaddr, order: ORDERS.asc},
                    searchFields: [
                        FIELDS.NAME, FIELDS.DATA_SOURCE_ENTITY_NAME, FIELDS.MERGED_APPLICATIONS_NAME
                    ],
                    select: [FIELDS.type, FIELDS.NAME],
                    facets: [
                        getFacetRequestObject(FACET_FIELDS.NAME, limitsByFacetCategory),
                        getFacetRequestObject(
                            "CUSTOM_PROPERTY_VALUE_NAME",
                            limitsByFacetCategory,
                            "CUSTOM_PROPERTY_NAME",
                        ),
                    ]
                };
                break;
        }
        filter = "";
    } else if (type === SEARCH_TYPE.location) {
        switch (searchPrefs?.srchEngine) {
            case SEARCH_ENGINE.correlation_direct:
                searchRequest = {
                    type: "Location",
                    search: getSearchString(searchText, searchPrefs), top: topLimit,
                    // This shows the total count, not the search result count 
                    count: true,
                    //highlightPreTag: "<b>", highlightPostTag: "</b>", 
                    //orderby: {field: FIELDS.devIpaddr, order: ORDERS.asc},
                    searchFields: [
                        FIELDS.locsName, FIELDS.locsType, FIELDS.locsCity, FIELDS.locsState, FIELDS.locsCountry
                    ],
                    select: [FIELDS.type, FIELDS.location],
                    facets: [
                        getFacetRequestObject(FACET_FIELDS.locsName, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.locsType, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.locsCity, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.locsState, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.locsCountry, limitsByFacetCategory),
                    ]
                };
                break;
            case SEARCH_ENGINE.correlation_dal:
                searchRequest = {
                    type: "Location",
                    search: getSearchString(searchText, searchPrefs), top: topLimit,
                    // This shows the total count, not the search result count 
                    count: true,
                    //highlightPreTag: "<b>", highlightPostTag: "</b>", 
                    //orderby: {field: FIELDS.devIpaddr, order: ORDERS.asc},
                    searchFields: [
                        FIELDS.NAME, FIELDS.TYPE, FIELDS.CITY, FIELDS.STATE, FIELDS.COUNTRY, FIELDS.DATA_SOURCE_ENTITY_NAME, FIELDS.MERGED_LOCATIONS_NAME
                    ],
                    select: [FIELDS.type, FIELDS.location],
                    facets: [
                        getFacetRequestObject(FACET_FIELDS.NAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.TYPE, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.CITY, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.STATE, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.COUNTRY, limitsByFacetCategory),
                        getFacetRequestObject(
                            "CUSTOM_PROPERTY_VALUE_NAME",
                            limitsByFacetCategory,
                            "CUSTOM_PROPERTY_NAME",
                        ),
                    ]
                };
                break;
        }
        filter = "";
    } else if (type === SEARCH_TYPE.cloudassets) {
        switch (searchPrefs?.srchEngine) {
            case SEARCH_ENGINE.correlation_direct:
            case SEARCH_ENGINE.correlation_dal:
                searchRequest = {
                    type: "CloudAssets",
                    search: getSearchString(searchText, searchPrefs), top: topLimit,
                    // This shows the total count, not the search result count 
                    count: true,
                    searchFields: [
                        FIELDS.NAME, FIELDS.SERVICE, FIELDS.REGION, FIELDS.TAG, FIELDS.OWNER, FIELDS.CLOUD_IM_SOURCE_TYPE, FIELDS.CLOUD_IM_ENTITY_KIND
                    ],
                    facets: [
                        //getFacetRequestObject(FACET_FIELDS.NAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.SERVICE, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.REGION, limitsByFacetCategory),
                        // getFacetRequestObject(FACET_FIELDS.PROVIDER, limitsByFacetCategory),
                        //getFacetRequestObject(FACET_FIELDS.TAG, limitsByFacetCategory),
                        //getFacetRequestObject(FACET_FIELDS.OWNER, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.CLOUD_IM_SOURCE_TYPE, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.CLOUD_IM_ENTITY_KIND, limitsByFacetCategory),
                        // getFacetRequestObject(FACET_FIELDS.HEALTH, limitsByFacetCategory),
                        // getFacetRequestObject(FACET_FIELDS.LIVENESS, limitsByFacetCategory),
                    ]
                };
                break;
        }
        filter = "";
    } else if (type === SEARCH_TYPE.properties) {
        switch (searchPrefs?.srchEngine) {
            case SEARCH_ENGINE.correlation_direct:
                // Only supported by correlation engine
                searchRequest = {
                    type: "CustomProperty",
                    search: getSearchString(searchText, searchPrefs), top: topLimit,
                    // This shows the total count, not the search result count 
                    count: true,
                    //highlightPreTag: "<b>", highlightPostTag: "</b>", 
                    //orderby: {field: FIELDS.devIpaddr, order: ORDERS.asc},
                    searchFields: [
                        FIELDS.propName, FIELDS.propDesc
                    ],
                    select: [FIELDS.type, FIELDS.location],
                    facets: [
                        getFacetRequestObject(FACET_FIELDS.propName, limitsByFacetCategory),
                        // Damien and Jeff asked to remove this
                        //getFacetRequestObject(FACET_FIELDS.propDesc, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.propUser, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.propValue, limitsByFacetCategory),
                        //getFacetRequestObject(FACET_FIELDS.propType, limitsByFacetCategory),
                    ]
                };
                break;
            case SEARCH_ENGINE.correlation_dal:
                // Only supported by correlation engine
                searchRequest = {
                    type: "CustomProperty",
                    search: getSearchString(searchText, searchPrefs), top: topLimit,
                    // This shows the total count, not the search result count 
                    count: true,
                    //highlightPreTag: "<b>", highlightPostTag: "</b>", 
                    //orderby: {field: FIELDS.devIpaddr, order: ORDERS.asc},
                    searchFields: [
                        FIELDS.NAME, FIELDS.DESCRIPTION
                    ],
                    select: [FIELDS.type, FIELDS.location],
                    facets: [
                        getFacetRequestObject(FACET_FIELDS.ENTITY_KIND, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.NAME, limitsByFacetCategory),
                        // Damien and Jeff asked to remove this
                        //getFacetRequestObject(FACET_FIELDS.DESCRIPTION, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.VALUE_NAME, limitsByFacetCategory),
                    ]
                };
                break;
        }
        filter = "";
    } else if (type === SEARCH_TYPE.tcpconnection) {
        switch (searchPrefs?.srchEngine) {
            case SEARCH_ENGINE.correlation_dal:
                searchRequest = {
                    type: "TcpConnection",
                    search: getSearchString(searchText, searchPrefs),
                    top: topLimit,
                    // This shows the total count, not the search result count
                    count: true,
                    searchFields: [FIELDS.APPLICATION_NAME, FIELDS.OS],
                    select: [FIELDS.APPLICATION_NAME, FIELDS.OS],
                    facets: [
                        getFacetRequestObject(
                            FACET_FIELDS.OS,
                            limitsByFacetCategory,
                        ),
                        getFacetRequestObject(
                            FACET_FIELDS.APPLICATION_NAME,
                            limitsByFacetCategory,
                        ),
                    ],
                };
                break;
        }
    } else if (type === SEARCH_TYPE.ondemandrunbooks) {
        switch (searchPrefs?.srchEngine) {
            case SEARCH_ENGINE.correlation_direct:
                searchRequest = {
                    type: "OndemandRunbooks",
                    search: getSearchString(searchText, searchPrefs),
                    top: topLimit,
                    // This shows the total count, not the search result count
                    count: true,
                    searchFields: [
                        FIELDS.TITLE,
                    ],
                    select: [
                        FIELDS.TITLE,
                    ],
                    facets: [
                        getFacetRequestObject(FACET_FIELDS.RUNBOOK_NAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.ENTITY_TYPE, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.STATUS, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.EXECUTION_METHOD, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.CREATED_BY, limitsByFacetCategory),
                    ],
                };
                break;
            case SEARCH_ENGINE.correlation_dal:
                searchRequest = {
                    type: "OndemandRunbooks",
                    search: getSearchString(searchText, searchPrefs),
                    top: topLimit,
                    // This shows the total count, not the search result count
                    count: true,
                    searchFields: [
                        FIELDS.TITLE,
                    ],
                    select: [
                        FIELDS.TITLE,
                    ],
                    facets: [
                        getFacetRequestObject(FACET_FIELDS.RUNBOOK_NAME, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.ENTITY_TYPE, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.STATUS, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.EXECUTION_METHOD, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.CREATED_BY, limitsByFacetCategory),
                    ],
                };
                break;
        }
    } else if (type === SEARCH_TYPE.runbookschedules) {
        switch (searchPrefs?.srchEngine) {
            case SEARCH_ENGINE.correlation_direct:
                searchRequest = {
                    type: "RunbookSchedules",
                    search: getSearchString(searchText, searchPrefs), top: topLimit,
                    // This shows the total count, not the search result count 
                    count: true,
                    orderby: { field: FIELDS.schedulesNextRun, order: ORDERS.asc },
                    searchFields: [
                        FIELDS.schedulesRunbookName
                    ],
                    // TODO: will need to change the searchFields, select, and
                    // facet properties once DAL end point is live.
                    select: [
                        FIELDS.schedulesRunbookName
                    ],
                    facets: [],
                };
                break;
            case SEARCH_ENGINE.correlation_dal:
                searchRequest = {
                    type: "RunbookSchedules",
                    search: getSearchString(searchText, searchPrefs), top: topLimit,
                    // This shows the total count, not the search result count 
                    count: true,
                    orderby: { field: FIELDS.schedulesNextRun, order: ORDERS.asc },
                    searchFields: [
                        FIELDS.schedulesRunbookName
                    ],
                    // TODO: will need to change the searchFields, select, and
                    // facet properties once DAL end point is live.
                    select: [
                        FIELDS.schedulesRunbookName
                    ],
                    facets: [],
                };
                break;
        }
    } else if (type === SEARCH_TYPE.cloudrules) {
        switch (searchPrefs?.srchEngine) {
            case SEARCH_ENGINE.correlation_direct:
            case SEARCH_ENGINE.correlation_dal:
                searchRequest = {
                    type: "CloudRules",
                    search: getSearchString(searchText, searchPrefs), top: topLimit,
                    // This shows the total count, not the search result count 
                    count: true,
                    searchFields: [
                        FIELDS.RULE_NAME, FIELDS.DESCRIPTION, FIELDS.ENABLED, FIELDS.RULE_TYPE, FIELDS.TAGS, FIELDS.USER
                    ],
                    facets: [
                        getFacetRequestObject(FACET_FIELDS.RULE_NAME, limitsByFacetCategory),
                        // getFacetRequestObject(FACET_FIELDS.RULE_ID, limitsByFacetCategory),
                        // getFacetRequestObject(FACET_FIELDS.LAST_UPDATE, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.ENABLED, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.RULE_TYPE, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.TAGS, limitsByFacetCategory),
                        getFacetRequestObject(FACET_FIELDS.USER, limitsByFacetCategory)
                    ]
                };
                break;
        }
        filter = "";
    }

    if (timeRange && [SEARCH_TYPE.incident, SEARCH_TYPE.tcpconnection, SEARCH_TYPE.ondemandrunbooks].includes(type)) {
        let actualTimeRange = timeRange.duration ? durationToRoundedTimeRange(timeRange.duration) : timeRange;
        filter += (filter.length ? " and " : "") + `(incidents/createdAt ge ${new Date(actualTimeRange.startTime as number).toISOString()} and incidents/createdAt le ${new Date(actualTimeRange.endTime as number).toISOString()})`;
        if (searchPrefs?.srchEngine === SEARCH_ENGINE.correlation_dal) {
            (searchRequest as any).dalOptions = { timeRange: actualTimeRange };
        }
    }

    if (selectedFacets) {
        for (const category in selectedFacets) {
            if (category === "incident/duration") {
                // This filter is hard coded.
                continue;
            }
            let facetFilter = "";
            for (const value of selectedFacets[category]) {
                if (category.includes("entity")) {
                    facetFilter += `${facetFilter.length > 0 ? " or " : ""}incident/indicators/any(indicator: (indicator/${category.substring(20, category.length)} eq ${getFacetValue(value)}))`;
                } else if (category.includes("incidentIndicators")) {
                    facetFilter += `${facetFilter.length > 0 ? " or " : ""}incidents/incidentIndicators/any(ii: (ii/${category.substring(29, category.length)} eq ${getFacetValue(value)}))`;
                } else if (category.includes("impacted")) {
                    const tokens = category.split("/");
                    facetFilter += `${facetFilter.length > 0 ? " or " : ""}${tokens[0]}/${tokens[1]}/any(ii: (ii/${tokens[2]} eq ${getFacetValue(value)}))`;
                } else if (category.includes("customPropertyValues")) {
                    facetFilter += `${facetFilter.length > 0 ? " or " : ""}customProperties/customPropertyValues/any(ii: (ii/${category.substring(38, category.length)} eq ${getFacetValue(value)}))`;
                } else {
                    facetFilter += `${facetFilter.length > 0 ? " or " : ""}${category} eq ${getFacetValue(value)}`;
                }
            }
            if (facetFilter !== "") {
                filter += `${filter.length > 0 ? " and " : ""}(${facetFilter})`;
            }
        }
        if (filter !== "") {
            searchRequest!.filter = filter;
        }
        if (searchPrefs.srchEngine === SEARCH_ENGINE.correlation_dal) {
            (searchRequest as any).dalOptions = {
                ...(searchRequest as any)?.dalOptions,
                facets: selectedFacets,
                hasParams: hasWhichParams(),
            };
        }
    }

    if (serverSideSortingAndPagination && searchPrefs?.srchEngine) {
        // If we are doing server side sorting, add the order by expression
        const initSortBy = getInitialSortBy(type, searchPrefs.srchEngine);
        searchRequest!.orderby = {
            field: (sortColumn || initSortBy.id) as FIELDS,
            order: sortOrder || (initSortBy.desc ? ORDERS.desc : ORDERS.asc)
        };
    }

    return searchRequest!;
}

/** returns a String with the initial sort column.
 *  @param searchType the SEARCH_TYPE which specifies whether this is an incident, interface, device search, etc
 *  @param engine the SEARCH_ENGINE that is in use.
 *  @returns a String with the initial sort column to use. */
export function getInitialSortBy(searchType: SEARCH_TYPE, engine: SEARCH_ENGINE): { id: string, desc: boolean } {
    switch (engine) {
        case SEARCH_ENGINE.correlation_direct:
            switch (searchType) {
                case SEARCH_TYPE.incident:
                    return { id: "incidents/createdAt", desc: true };
                case SEARCH_TYPE.device:
                    return { id: FIELDS.devsName, desc: false };
                case SEARCH_TYPE.interface:
                    return { id: FIELDS.ifcsName, desc: false };
                case SEARCH_TYPE.application:
                    return { id: FIELDS.appsName, desc: false };
                case SEARCH_TYPE.location:
                    return { id: FIELDS.locsName, desc: false };
                case SEARCH_TYPE.cloudassets:
                    return { id: FIELDS.name, desc: false };
                case SEARCH_TYPE.properties:
                    return { id: FIELDS.propName, desc: false };
                case SEARCH_TYPE.ondemandrunbooks:
                    return { id: FIELDS.RUNBOOK_NAME, desc: false };
                case SEARCH_TYPE.runbookschedules:
                    return { id: FIELDS.schedulesNextRun, desc: false };
                case SEARCH_TYPE.cloudrules:
                    return { id: FIELDS.LAST_UPDATE, desc: false };
            }
            break;
        case SEARCH_ENGINE.correlation_dal:
            switch (searchType) {
                case SEARCH_TYPE.incident:
                    return { id: "createdAt", desc: true };
                case SEARCH_TYPE.device:
                    return { id: "name", desc: false };
                case SEARCH_TYPE.interface:
                    return { id: "name", desc: false };
                case SEARCH_TYPE.application:
                    return { id: "name", desc: false };
                case SEARCH_TYPE.location:
                    return { id: "name", desc: false };
                case SEARCH_TYPE.cloudassets:
                    return { id: "name", desc: false };
                case SEARCH_TYPE.properties:
                    return { id: "name", desc: false };
                case SEARCH_TYPE.tcpconnection:
                    return { id: "time", desc: false };
                case SEARCH_TYPE.ondemandrunbooks:
                    return { id: "timestamp", desc: true };
                case SEARCH_TYPE.runbookschedules:
                    return { id: FIELDS.schedulesNextRun, desc: false };
                case SEARCH_TYPE.cloudrules:
                    return { id: "lastUpdated", desc: false };
            }
            break;
    }
    return { id: "", desc: true };
}

/** returns the search string that should be used based on whether wildcards are enabled or not.
 *  @param searchText a String with the search text.
 *  @param searchPreferences the SearchPreference object with the user and default search preferences.
 *  @returns a string with the new search text or the original string if no changes are necessary. */
function getSearchString(
    searchText: string,
    searchPreferences: SearchPreference,
): string {
    return searchText;
}

/** Used for the `dalOptions` object we send to our `SearchGraphqlApiService.ts` */
type HasParams = {
    hasGroupedFacetParam: boolean;
    hasFacetParam: boolean;
};

// TODO: may not be necessary
/**
 * Predicate function that uses current window's `location.search` to determine
 * if a `groupedFacet` has been selected. Inference done with the url search
 * params.
 * @returns {HasParams}
 */
const hasWhichParams: () => HasParams = () => {
    const currentParams = new URLSearchParams(window.location.search);
    const hasGroupedFacetParam = currentParams.has("groupedFacets");
    const hasFacetParam = currentParams.has("facets");
    const hasParams = {
        hasGroupedFacetParam,
        hasFacetParam,
    };
    return hasParams;
};

export const mergeGroupedFacets = (
    facetCache: React.MutableRefObject<Record<string, Array<Facet>>>,
    comparisonObject: any,
) => {
    Object.entries(comparisonObject).forEach(([key, data]) => {
        if ((data as any).isGroupedFacet) {
            const existingFacets = facetCache.current[key] || [];

            const updatedFacets = (data as any).items.map((newFacet) => {
                /** if facet exists in cache, return its index */
                const existingFacetIndex = existingFacets.findIndex(
                    (f) => f.value === newFacet.value,
                );

                if (existingFacetIndex >= 0) {
                    // Update existing facet
                    return {
                        ...existingFacets[existingFacetIndex],
                        isGroupedFacet: true,
                    };
                }
                return undefined;
            });

            // Combine updated facets with the existing ones, excluding the old versions of updated facets
            facetCache.current[key] = [
                ...existingFacets.filter(
                    (f) => !updatedFacets.some((uf) => uf.value === f.value),
                ),
                ...updatedFacets,
            ];
        }
    });
};

/** returns a string with the facet value for the odata query.
 *  @param value the value for the facet as a string or number.  This might need to expand to other types.
 *  @returns a quoted string for a string value and a unquoted string for a number. */
function getFacetValue(value: FacetValue): string {
    return typeof value === "string" ? `'${value}'` : `${value}`;
}

/** This function returns the facet object for the specified facet category and facet value.
 *  @param facetCategory a String with the facet category.
 *  @param facetValue a String or number with the facet value.
 *  @param facetMap the facet map.
 *  @param isCorrelation this specifies whether or not the result is from the correlation engine using 
 *      the FacetSummary format.
 *  @returns a reference to the Facet that was found or undefined. */
export function getFacet(
    facetCategory: string, facetValue: FacetValue, facetMap: Record<string, Facet[] | FacetSummary> | undefined, isCorrelation?: boolean
): Facet | undefined {
    if (facetCategory && facetMap && facetMap[facetCategory]) {
        const facetList: Facet[] = isCorrelation ? (facetMap[facetCategory] as FacetSummary).items : facetMap[facetCategory] as Facet[];
        for (const facet of facetList) {
            if (facetValue === facet.value) {
                return facet;
            }
        }
    }
    return undefined;
}

/** returns the string to be used in the facets parameter of the search request.
 *  @param facetCategory a String with the facet category.
 *  @param limitsByFacetCategory the facet value limits indexed by facet category. 
 *  @returns the string to be used in the facets parameter of the search request. */
function getFacetRequestString(facetCategory: string, limitsByFacetCategory: Record<string, number>): string { // eslint-disable-line
    return facetCategory + ",count:" + (limitsByFacetCategory[facetCategory] || DEFAULT_FACET_LIMIT);
}

/** returns the object to be used in the facets parameter of the search request.
 *  @param facetCategory a String with the facet category.
 *  @param limitsByFacetCategory the facet value limits indexed by facet category. 
 *  @returns the object to be used in the facets parameter of the search request. */
function getFacetRequestObject(facetCategory: string | CustomPropertyGroupKey, limitsByFacetCategory: Record<string, number>, groupBy?: GroupByTypes): FacetVariables {
    return { name: facetCategory, skip: 0, top: (limitsByFacetCategory[facetCategory] || DEFAULT_FACET_LIMIT), count: true, groupBy, };
}

/** returns the string with the query parameter that contains the selected facets.
 *  @param facets the current facet cache.
 *  @param isGrouped a boolean value, if true we want the selected grouped facets and if false we want the selected
 *      facets that are not grouped.
 *  @returns a String with the query parameter. */
export function getFacetQueryParam(facets: Record<string, Array<Facet>>, isGrouped: boolean): string {
    const selectedFacets: Record<string, Array<FacetValue>> = {};
    for (const category in facets) {
        for (const facetValue of facets[category]) {
            if (facetValue.selected && (isGrouped === (facetValue.isGroupedFacet || false))) {
                if (!selectedFacets[category]) {
                    selectedFacets[category] = [];
                }
                selectedFacets[category].push(facetValue.value);
            }
        }
    }
    return Object.keys(selectedFacets).length ? JSON.stringify(selectedFacets) : "";
}

/** returns the dictionary with the query facets as parsed from the facets query parameter.
 *  @param {string} facetQueryParam the string with the facets from the query parameter.
 *  @param {string} groupedFacetQueryParam
 *  @returns the dictionary with the selected query facets indexed by facet category. */
export function getSelectedFacetsFromQueryParams(facetQueryParam: string, groupedFacetQueryParam?: string): Record<string, Array<string>> {
    let selectedFacets: Record<string, Array<string>> = {};
    if (facetQueryParam?.length > 0) {
        try {
            selectedFacets = JSON.parse(facetQueryParam);
        } catch (error) {
            console.error(error);
        }
    }
    if (groupedFacetQueryParam && groupedFacetQueryParam?.length > 0) {
        try {
            const groupedFacets = JSON.parse(groupedFacetQueryParam);
            selectedFacets = { ...selectedFacets, ...groupedFacets };
        } catch (error) {
            console.error(error);
        }
    }
    return selectedFacets;
}
