/** This module contains the component for the navigator page.  The navigator page displays
 *      and edits the NPM Plus data viewer.
 *  @module
 */

import React, { useRef, useState, useEffect } from "react";
import { STRINGS } from "app-strings";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade.tsx";
import { SDWAN_ICONS } from "components/sdwan/enums/icons.ts";
import { PageWithHeader } from "components/sdwan/layout/page-with-header/PageWithHeader.tsx";
import NavigatorToolbar, { ToolbarAction } from "./views/toolbar/NavigatorToolbar.tsx";
import Layout from "components/common/vis-framework/layout/Layout.tsx";
import { LAYOUT_TYPE, LayoutConfig } from "components/common/vis-framework/layout/Layout.type.ts";
import { INTERACTION_TYPE, InteractionConfig, WIDGET_TYPE } from "components/common/vis-framework/widget/Widget.type.ts";
import { DashboardConfig } from "pages/dashboards/Dashboard.type.ts";
import NavigatorWidget, { NavigatorWidgetRef, WidgetEditAction, filterOperator } from "./views/widget/NavigatorWidget.tsx";
import { 
    FILTER_KEYS, FILTER_TYPE, FilterEntry, NAVIGATOR_MODE, NavigatorConfig, NavigatorWidgetConfig, PinnedWorkflowConfig, QueryConfig 
} from "./Navigator.type.ts";
import { getUuidV4 } from "utils/unique-ids/UniqueIds.ts";
import { BasicDialog, DialogState, updateDialogState } from "components/common/basic-dialog/BasicDialog.tsx";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { AnchorButton, Button, InputGroup, TextArea, Intent, ButtonGroup } from "@blueprintjs/core";
import { setQueryParam, useQueryParams } from "utils/hooks/useQueryParams.ts";
import { PARAM_NAME } from "components/enums/QueryParams.ts";
import { getFakeDashboard } from "pages/dashboard-list/views/dashboard-list/DashboardList.tsx";
import AutoUpdateControl, { AutoUpdateState, TimeType } from "components/common/auto-update/AutoUpdateControl.tsx";
import { WrapInTooltip } from "components/common/wrap-in-tooltip/WrapInTooltip.tsx";
import { TwoColumnContainer } from "components/common/layout/containers/two-column-container/TwoColumnContainer.tsx";
import { DragData, DragPinnedData, EntityList, getPinnedWorkflow } from "components/common/vis-framework/components/EntityList.tsx";
import { WidgetEditor } from "components/common/vis-framework/components/widget-editor/WidgetEditor.tsx";
import { DetailsPanel } from "components/reporting/containers/details-panel/DetailsPanel.tsx";
import { BladeContainer } from "components/common/layout/containers/blade-container/BladeContainer.tsx";
import { SIZE } from "components/enums/Sizes.ts";
import { ErrorToaster, Icon, IconNames, SuccessToaster, useStateSafePromise } from "@tir-ui/react-components";
import { DataOceanUtils } from "components/common/graph/editors/data-ocean/DataOceanUtils.ts";
import AddNewWidgetControl from "./views/controls/AddNewWidgetControl.tsx";
import { GenericKey, NodeUtils } from "utils/runbooks/NodeUtil.ts";
import { TOP_BY_DIRECTION, TopBy } from "components/common/graph/editors/data-ocean/DataOceanMetadata.type.ts";
import { useUserPreferences } from "utils/hooks/useUserPreferences.ts";
import { UserPreferences } from "utils/services/UserPrefsTypes.ts";
import { setUserPreferences } from "utils/stores/UserPreferencesStore.ts";
import "./NavigatorPage.scss";

const initAutoUpdate: AutoUpdateState = {
    enabled: true,
    interval: 1,
    lastUpdate: 60* Math.floor(new Date().getTime() / (60 * 1000)),
    sequenceNumber: 0
};
export const AutoUpdateContext = React.createContext(initAutoUpdate);

export let USE_DAL: boolean = false;
export let STARTING_OBJ_TYPE: string = "npm_plus.traffic"; //"app_response_and_profiler"; //"profiler_traffic"; //"network_host.traffic"; // "tcp_connections"
export function updateConstantsForTesting(useDal, objType): void {
    USE_DAL = useDal;
    STARTING_OBJ_TYPE = objType;
}

/** Renders the navigator page.
 *  @param props the properties passed in.
 *  @returns JSX with the navigator page component.*/
const NavigatorPage = (props): JSX.Element => {
    const { params } = useQueryParams({ listenOnlyTo: [PARAM_NAME.rbConfigId, PARAM_NAME.rbConfigNm, PARAM_NAME.devel, PARAM_NAME.navigatorMode] });
    const userPreferences = useUserPreferences({listenOnlyTo: {navigator: {pinnedWorkspaces: []} }});
    //const showDebugInformation = params[PARAM_NAME.debug] === "true";
    const [loadNavigator, setLoadNavigator] = useState<boolean>(true);

    const loading = useRef<boolean>(false);

    const [navigatorName, setNavigatorName] = useState<string>("New Navigator");
    const [navigatorDesc, setNavigatorDesc] = useState<string>("");

    const [layout, setLayout] = useState<LayoutConfig>({type: params?.navigatorMode === NAVIGATOR_MODE.icons ? LAYOUT_TYPE.GRID : LAYOUT_TYPE.VERTICAL});

    const [edit, setEdit] = useState<boolean>(params.navigatorMode === NAVIGATOR_MODE.dashboard);

    // Let's initialize the DO metadata for all the components within the Navigator so they can use it freely
    const [doUtilsInitialized, setDOUtilsInitialized] = useState(false);
    const [executeSafely] = useStateSafePromise();
    useEffect(() => {
        executeSafely(DataOceanUtils.init()).then(() => {
            setDOUtilsInitialized(true);
        });
    }, [setDOUtilsInitialized, executeSafely]);

    let firstGroupBy: string[] = [];
    if (DataOceanUtils.dataOceanMetaData.obj_types?.[STARTING_OBJ_TYPE]?.group_by_required) {
        const expandedKeys: GenericKey[] = NodeUtils.getExpandedKeysForAllGroupBys(DataOceanUtils.dataOceanMetaData, STARTING_OBJ_TYPE);
        firstGroupBy = expandedKeys.length > 0 ? [expandedKeys[0].id] : [];
    } else if (DataOceanUtils.dataOceanMetaData.obj_types?.[STARTING_OBJ_TYPE]?.group_by?.length) {
        firstGroupBy = [DataOceanUtils.dataOceanMetaData.obj_types![STARTING_OBJ_TYPE].group_by![0]];
    }

    const [mainWidget, setMainWidget] = useState<NavigatorWidgetConfig | undefined>(
        params?.navigatorMode === NAVIGATOR_MODE.icons ? createNewWidget(WIDGET_TYPE.TABLE, [], STARTING_OBJ_TYPE, firstGroupBy) : undefined
    );
    const [widgets, setWidgets] = useState<NavigatorWidgetConfig[]>(params?.navigatorMode === NAVIGATOR_MODE.icons ? [] : [
        createNewWidget(WIDGET_TYPE.TABLE, [], STARTING_OBJ_TYPE, firstGroupBy)
    ]);

    const [showEntityList, setShowEntityList] = useState<boolean>(true);

    const [editWidgetId, setEditWidgetId] = useState<string | undefined>(undefined);
    const [showNewBlade, setShowNewBlade] = useState<boolean>(false);
    const [showNewBladeFilters, setShowNewBladeFilters] = useState<FilterEntry[]>([]);
    const [showNewOrEditBladeObjType, setShowNewOrEditBladeObjType] = useState<string>(STARTING_OBJ_TYPE);
    const lastDropData = useRef<DragData>();
    const [lock, setLock] = useState<boolean>(false);

    const widgetRefs = useRef<Array<NavigatorWidgetRef>>([]);

    const [dialogState, setDialogState] = useState<any>({showDialog: false, title: "My Dialog", loading: false, dialogContent: null, dialogFooter: null});

    useEffect(
        () => {
            /* istanbul ignore next */
            async function retrieveNavigator() {
                try {
                    let navigator: DashboardConfig | NavigatorConfig | undefined = getFakeDashboard(params[PARAM_NAME.rbConfigId]); // await runbookService.getRunbook(currentRunbook.current.id);
                    if (navigator) {
                        /*************************************************************************************/
                        //Do a conversion from one format to another right Here
                        /*********************************************************************************** */
                        //resetHistory();
                        setEdit(false);
                        setNavigatorName(navigator.name);
                        setNavigatorDesc(navigator.description || "");
                        if (navigator?.widgets?.length) {
                            setWidgets(navigator.widgets as NavigatorWidgetConfig[]);
                        }
                        if (navigator?.layout) {
                            setLayout(((navigator as any) as NavigatorConfig).layout);
                        }
                        if (navigator.endTime) {
                            const newAutoUpdate = Object.assign({}, autoUpdate);
                            newAutoUpdate.lastUpdate = 60* Math.floor(new Date().getTime() / (60 * 1000));
                            newAutoUpdate.sequenceNumber++;
                            newAutoUpdate.time = navigator.endTime;
                            newAutoUpdate.enabled = false;
                            setAutoUpdate(newAutoUpdate);
                        }
                    }
                    //setLoading(false);
                } catch (error) {
                    console.error(error);
                    //setLoading(false);
                }
            }
            /* istanbul ignore if */
            if (loadNavigator) {
                setLoadNavigator(false);
                if (params[PARAM_NAME.rbConfigId]) {
                    retrieveNavigator();
                } else {
                    console.log("need to handle this");
                }
                //setRunbookModified(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loadNavigator]
    );

    const [autoUpdate, setAutoUpdate] = useState<any>(initAutoUpdate);

    const handleSettingsChange = (name: string, columns: Array<string>, limit: number, topMetric: string, topDirection: string, duration: number) => {
        if (editWidgetId) {
            let isMain = false;
            let editWidget: NavigatorWidgetConfig | undefined = undefined;
            if (editWidgetId === mainWidget?.id) {
                isMain = true;
                editWidget = mainWidget;
            }
            for (const widget of widgets) {
                if (editWidgetId === widget.id) {
                    editWidget = widget;
                    break;
                }
            }    
            if (editWidget) {
                // Update the widget name
                editWidget.name = name;

                // Create a new queryConfig object to make sure all useEffect hooks looking for
                // queryConfig changes know the queryConfig has changed, right now the data gets 
                // cleared whenever the blade settings are applied so we need to redo the query 
                // whether or not there are query changes.  If we don't clear the data we can
                // move this into the if statement
                editWidget.queryConfig = {...editWidget.queryConfig};

                // We need to update the query as well
                if (editWidget.queryConfig?.properties) {
                    const metrics = [...columns];
                    if (metrics.includes("group_column")) {
                        metrics.splice(metrics.indexOf("group_column"), 1);
                    }
                    editWidget.queryConfig.properties.metrics = metrics;

                    if (limit > 0) {
                        editWidget.queryConfig.properties.limit = limit;
                    }

                    if (topMetric && topDirection) {
                        editWidget.queryConfig.properties.topBy = [{id: topMetric, direction: topDirection}];
                    }

                    if (duration > 0) {
                        editWidget.queryConfig.properties.duration = duration * 60;
                    }
                }
                if (editWidget.options) {
                    let metrics = [...columns];
                    if (metrics.includes("group_column")) {
                        metrics.splice(metrics.indexOf("group_column"), 1);
                    }                    
                    switch (editWidget.widgetType) {
                        case WIDGET_TYPE.TABLE:
                            editWidget.options.columns = columns;
                            editWidget.options!.sortColumn = topMetric || "";
                            editWidget.options!.sortOrder = topDirection || "";                    
                            break;
                        case WIDGET_TYPE.PIE:
                            editWidget.options!.metric = metrics[0];
                            break;
                        case WIDGET_TYPE.BAR:
                            //widget.options!.metric = metrics[0];
                            break;
                        case WIDGET_TYPE.BUBBLE:
                            editWidget.options!.sizeMetric = metrics?.length ? metrics[0] : undefined;
                            editWidget.options!.colorMetric = metrics?.length > 1 ? metrics[1] : undefined;
                            break;
                        case WIDGET_TYPE.TIMESERIES:
                            editWidget.options!.metrics = metrics?.length ? [metrics[0]] : [];
                            break;
                        case WIDGET_TYPE.CORRELATION:
                            editWidget.options!.xMetric = metrics?.length ? metrics[0] : undefined;
                            editWidget.options!.yMetric = metrics?.length > 1 ? metrics[1] : undefined;
                            break;
                    }
                }
                if (isMain) {
                    setMainWidget({...editWidget});
                } else {
                    setWidgets([...widgets]);
                }
            }
        }
    };

    const handleEditBladeClose = () => {
        setEditWidgetId(undefined);
        setShowNewOrEditBladeObjType(STARTING_OBJ_TYPE);
    };

    const handleNewBladeClose = () => {
        setShowNewBlade(false);
        setShowNewBladeFilters([]);
        setShowNewOrEditBladeObjType(STARTING_OBJ_TYPE);
    };

    const notifyToolbarAction = (action: string, value: any): void => {
        switch (action) {
            case ToolbarAction.ADD_WIDGET:
                const newWidgets: NavigatorWidgetConfig[] = [...widgets];
                if (value?.dropIndex) {
                    newWidgets.splice(value.dropIndex, 0, createNewWidget(WIDGET_TYPE.TABLE, widgets, value.objType, value.groupBy ? [value.groupBy] : undefined));
                } else if (value?.droppedOnWidgetId && value?.groupBy) {
                    if (params?.navigatorMode === NAVIGATOR_MODE.icons && mainWidget && mainWidget?.id === value.droppedOnWidgetId) {
                        updateWidgetConfigAfterDrop(mainWidget, value);
                    } else {
                        const droppedOnIndex = newWidgets.findIndex(widget => widget.id === value.droppedOnWidgetId);
                        // Create a new object so its reference is not the same, this will cause a new query to go out.
                        //newWidgets[droppedOnIndex].queryConfig = {...newWidgets[droppedOnIndex].queryConfig} as QueryConfig;
                        //newWidgets[droppedOnIndex].queryConfig!.properties.groupBy = [value.groupBy];
                        //newWidgets[droppedOnIndex].queryConfig!.properties.objType = value.objType;
                        updateWidgetConfigAfterDrop(newWidgets[droppedOnIndex], value);
                    }
                  } else {
                    newWidgets.push(createNewWidget(WIDGET_TYPE.TABLE, widgets, value?.objType || STARTING_OBJ_TYPE, value?.groupBy ? [value.groupBy] : undefined));
                }
                setWidgets(newWidgets);
                break;
            case ToolbarAction.SET_LAYOUT:
                setLayout({type: value});
                break;
            case ToolbarAction.EDIT:
                setEdit(!edit);
/*
                for (const widgetRef of widgetRefs.current) {
                    widgetRef.editWidget(edit);
                }
*/
                break;
            case ToolbarAction.IMPORT:
                widgetRefs.current = [];
                importNavigator(setDialogState, setWidgets, setLayout, setNavigatorName, setNavigatorDesc, setAutoUpdate, autoUpdate);
                break;
            case ToolbarAction.EXPORT:
                const navigatorState: NavigatorConfig = {
                    id: getUuidV4(),
                    name: navigatorName || "My Navigator",
                    description: navigatorDesc || "this is my new navigator",
                    layout, widgets
                };
                if (autoUpdate.time) {
                    navigatorState.endTime = autoUpdate.time;
                }
                exportNavigator(navigatorState, setDialogState);
                break;
            case ToolbarAction.SAVE:
                saveNavigator(dialogState, setDialogState);
                break;
        }
    };

    const onDrop = (event: any) => {
        event.preventDefault();
        if (event.dataTransfer.getData("queryInfo")) {
            const stringifiedQueryInfo: string = event.dataTransfer.getData("queryInfo");
            if (stringifiedQueryInfo) {
                const queryInfo: DragData = JSON.parse(stringifiedQueryInfo);
                lastDropData.current = queryInfo;
                //alert("queryInfo: " + JSON.stringify(queryInfo));
                const objType: string = queryInfo.objType;
                const dataSource: string = queryInfo.dataSource;
                const groupBy: string = queryInfo.groupBy;
                const droppedOnExistingWidget = event.target.closest('.widget-item');
                const dropIndex = Array.from(event.target.children).findIndex(
                    // for a vertical layout find the index of the dropped child element
                    (child: any) => child.getBoundingClientRect().bottom > event.clientY
                );
                if (droppedOnExistingWidget) {
                    notifyToolbarAction(ToolbarAction.ADD_WIDGET, {objType, dataSource, groupBy, droppedOnWidgetId: droppedOnExistingWidget.id, dropIndex: undefined});
                } else if (dropIndex !== -1) {
                    // dropping between widgets
                    notifyToolbarAction(ToolbarAction.ADD_WIDGET, {objType, dataSource, groupBy, droppedOnWidgetId: "", dropIndex});
                } else {
                    notifyToolbarAction(ToolbarAction.ADD_WIDGET, {objType, dataSource, groupBy, droppedOnWidgetId: undefined, dropIndex: undefined});
                }    
            }    
        } else if (event.dataTransfer.getData("pinnedInfo")) {
            const stringifiedPinnedInfo: string = event.dataTransfer.getData("pinnedInfo");
            if (stringifiedPinnedInfo) {
                const pinnedInfo: DragPinnedData = JSON.parse(stringifiedPinnedInfo);
                let workflow: PinnedWorkflowConfig | undefined = undefined;
                if (pinnedInfo.isDefault) {
                    workflow = getPinnedWorkflow(pinnedInfo.id);
                } else {
                    workflow = userPreferences.navigator?.pinnedWorkspaces?.find((wData) => wData.id === pinnedInfo.id);
                }
                if (workflow) {
                    widgetRefs.current = [];
                    if (workflow.tableConfig) {
                        setMainWidget(workflow.tableConfig as NavigatorWidgetConfig);
                    }
                    if (workflow?.name) {
                        setNavigatorName(workflow.name);
                    }
                    setNavigatorDesc(workflow.description || "");
                    if (workflow?.widgetsConfig?.widgets?.length) {
                        setWidgets(workflow.widgetsConfig.widgets as NavigatorWidgetConfig[]);
                    }
                    if (workflow.widgetsConfig?.layout) {
                        setLayout(workflow.widgetsConfig.layout);
                    }
                    if (workflow?.endTime) {
                        const newAutoUpdate = Object.assign({}, autoUpdate);
                        newAutoUpdate.lastUpdate = 60* Math.floor(new Date().getTime() / (60 * 1000));
                        newAutoUpdate.sequenceNumber++;
                        newAutoUpdate.time = workflow.endTime;
                        newAutoUpdate.enabled = false;
                        setAutoUpdate(newAutoUpdate);
                    }
                    if (workflow.mode !== params?.navigatorMode) {
                        setQueryParam("navigatorMode", workflow.mode, true);
                    }
                    if (workflow.mode === NAVIGATOR_MODE.dashboard) {
                        setShowEntityList(false);
                    }
                    setLock(false);
                }
            }
        }
    };

    /** adds a new widget from the specified icon.
     *  @param widgetType the WIDGET_TYPE with the type of widget. */
    const addWidgetFromIcon = (widgetType: WIDGET_TYPE) => {
        const objType = showNewOrEditBladeObjType;
        const selectedGroupBys = lastDropData.current?.groupBy ? [lastDropData.current?.groupBy] : firstGroupBy;
        const metrics = DataOceanUtils.dataOceanMetaData.obj_types[showNewOrEditBladeObjType]?.defaults?.metrics || [];
        const filters = showNewBladeFilters;
        let limit = 5;
        const topMetric =  
            DataOceanUtils.dataOceanMetaData.obj_types[showNewOrEditBladeObjType]?.defaults?.top_by?.length ? 
                DataOceanUtils.dataOceanMetaData.obj_types[showNewOrEditBladeObjType].defaults.top_by[0].id : 
                "";
        const duration = DataOceanUtils.dataOceanMetaData.obj_types[showNewOrEditBladeObjType]?.defaults?.duration || 15;

        let selectedColumns = ["group_column"];
        switch (widgetType) {
            case WIDGET_TYPE.TABLE:
                selectedColumns = selectedColumns.concat(metrics);
                break;
            case WIDGET_TYPE.PIE:
                if (metrics?.length) {
                    selectedColumns.push(metrics[0]);
                }
                break;
            case WIDGET_TYPE.BAR:
                if (metrics?.length) {
                    selectedColumns = selectedColumns.concat(metrics);
                }
                break;
            case WIDGET_TYPE.BUBBLE:
                if (metrics?.length) {
                    selectedColumns.push(metrics[0]);
                    if (metrics?.length > 1) {
                        selectedColumns.push(metrics[1]);
                    }
                }
                break;
            case WIDGET_TYPE.TIMESERIES:
                limit = 1;
                if (metrics?.length) {
                    selectedColumns.push(metrics[0]);
                }
                break;
            case WIDGET_TYPE.CORRELATION:
                limit = 1;
                if (metrics?.length > 1) {
                    selectedColumns.push(metrics[0]);
                    selectedColumns.push(metrics[1]);
                }
                break;
        }
        
        const newWidgets: NavigatorWidgetConfig[] = [...widgets];
        newWidgets.push(createNewWidget(widgetType, widgets, objType, selectedGroupBys, selectedColumns, filters, limit, topMetric, duration));
        setWidgets(newWidgets);        
    };

    return <PageWithHeader
        name="Navigator"
        title={
            //<div>{navigatorName + " (Beta - for demos only)"}</div>
            <div className="fw-bold w-100 text-center d-none d-sm-block workspaces">
                {navigatorName + " (Alpha)"}
                <span style={{ verticalAlign: "text-bottom" }}>
                <WrapInTooltip tooltip="edit">
                    <AnchorButton minimal icon={IconNames.EDIT} onClick={() => {
                        showNavigatorSettings(navigatorName, navigatorDesc, setNavigatorName, setNavigatorDesc, dialogState, setDialogState);
                    }}/>
                </WrapInTooltip>
                </span>
            </div>
        }
        icon={SDWAN_ICONS.INCIDENT} showTimeBar={false}
        rightAlignedControls={<AutoUpdateControl autoUpdate={autoUpdate} timeType={TimeType.DURATION}
            showPlayPauseButton={true} onAutoUpdateChanged={(autoUpdate) => {
                setAutoUpdate(autoUpdate);
            }}
            showRefreshButton={!autoUpdate.enabled} showTimeControl
            className="d-none d-md-block"
        />}
    >
        <BasicDialog dialogState={dialogState} onClose={() => setDialogState(updateDialogState(dialogState, false, false, []))}/>   
        <AutoUpdateContext.Provider value={autoUpdate}>
        {params.navigatorMode === NAVIGATOR_MODE.dashboard && <NavigatorToolbar
            edit={edit}
            notifyToolbarAction={notifyToolbarAction}
        />}
        <DataLoadFacade loading={loading.current || !doUtilsInitialized} error={undefined /*data.error*/} data={undefined /*data.data*/} showContentsWhenLoading={true} className="npm-plus-column-container">
            <TwoColumnContainer hideLeftColumn={!showEntityList} firstColumnSize={SIZE.s} noPaddingOnFirstColumn={true} noPaddingOnSecondColumn={true} doNotSetColumnWidth={false}>
                {doUtilsInitialized && <EntityList objType={STARTING_OBJ_TYPE} 
                    onPinnedWorkflowExport={() => {
                        const navigatorState: NavigatorConfig = {
                            id: getUuidV4(),
                            name: navigatorName || "My Navigator",
                            description: navigatorDesc || "this is my new navigator",
                            layout, widgets
                        };
                        const pinnedState: PinnedWorkflowConfig = {
                            id: getUuidV4(),
                            name: navigatorName || "My Pinned Workflow",
                            description: navigatorDesc || "this is a new pinned workflow",
                            mode: params?.navigatorMode as NAVIGATOR_MODE,
                            tableConfig: mainWidget,
                            widgetsConfig: navigatorState
                        };
                        if (autoUpdate.time) {
                            pinnedState.endTime = autoUpdate.time;
                        }
                        exportNavigator(pinnedState, setDialogState);        
                    }}
                    onPinnedWorkflowSaveNew={async (name: string) => {
                        const navigatorState: NavigatorConfig = {
                            id: getUuidV4(),
                            name: navigatorName || "My Navigator",
                            description: navigatorDesc || "this is my new navigator",
                            layout, widgets
                        };
                        const pinnedState: PinnedWorkflowConfig = {
                            id: getUuidV4(),
                            name: name || navigatorName || "My Pinned Workflow",
                            description: navigatorDesc || "this is a new pinned workflow",
                            mode: params?.navigatorMode as NAVIGATOR_MODE,
                            tableConfig: mainWidget,
                            widgetsConfig: navigatorState
                        };
                        if (autoUpdate.time) {
                            pinnedState.endTime = autoUpdate.time;
                        }
                        if (pinnedState) {
                            const prefs: UserPreferences = {
                                navigator: { ...userPreferences.navigator },
                            };
                            if (!prefs.navigator!.pinnedWorkspaces) {
                                prefs.navigator!.pinnedWorkspaces = [];
                            }
                            prefs.navigator!.pinnedWorkspaces = [...prefs.navigator!.pinnedWorkspaces, pinnedState];
                            try {
                                await setUserPreferences(prefs);
                                SuccessToaster({
                                    message: STRINGS.navigator.savePreferencesSuccess
                                });
                            } catch (error) {
                                console.error("Error saving user preferences");
                                ErrorToaster({
                                    message: STRINGS.navigator.savePreferencesError
                                });
                            }
                        }
                    }}
                    onPinnedWorkflowUpdate={async (id: string, name: string) => {
                        const navigatorState: NavigatorConfig = {
                            id: getUuidV4(),
                            name: navigatorName || "My Navigator",
                            description: navigatorDesc || "this is my new navigator",
                            layout, widgets
                        };
                        const pinnedState: PinnedWorkflowConfig = {
                            id: id,
                            name: name,
                            description: navigatorDesc || "this is a new pinned workflow",
                            mode: params?.navigatorMode as NAVIGATOR_MODE,
                            tableConfig: mainWidget,
                            widgetsConfig: navigatorState
                        };
                        if (autoUpdate.time) {
                            pinnedState.endTime = autoUpdate.time;
                        }
                        if (pinnedState) {
                            const prefs: UserPreferences = { navigator: { pinnedWorkspaces: [...(userPreferences.navigator?.pinnedWorkspaces || [])] } };
                            prefs.navigator!.pinnedWorkspaces = prefs.navigator!.pinnedWorkspaces!.filter((workspace) => workspace.id !== id);
                            prefs.navigator!.pinnedWorkspaces.push(pinnedState);
                            try {
                                await setUserPreferences(prefs);
                                SuccessToaster({
                                    message: STRINGS.navigator.savePreferencesSuccess
                                });
                            } catch (error) {
                                console.error("Error saving user preferences");
                                ErrorToaster({
                                    message: STRINGS.navigator.savePreferencesError
                                });
                            }
                        }
                    }}
                    onPinnedWorkflowDelete={async (id: string) => {
                        const prefs: UserPreferences = { navigator: { pinnedWorkspaces: [...(userPreferences.navigator?.pinnedWorkspaces || [])] } };
                        prefs.navigator!.pinnedWorkspaces = prefs.navigator!.pinnedWorkspaces!.filter((workspace) => workspace.id !== id);
                        try {
                            await setUserPreferences(prefs);
                            SuccessToaster({
                                message: STRINGS.navigator.savePreferencesSuccess
                            });
                        } catch (error) {
                            console.error("Error saving user preferences");
                            ErrorToaster({
                                message: STRINGS.navigator.savePreferencesError
                            });
                        }
                    }}
                />}
                {doUtilsInitialized && <div className={showEntityList ? "widget-area pb-40 h-100" : "widget-area pb-40 entity-list-hidden h-100"}
                    onDrop={onDrop}
                    onDragOver={(e) => {
                        e.preventDefault();
                    }}
                >
                    <div>
                        <WrapInTooltip tooltip={"Show/hide filter sidebar"}>
                            <Button icon={showEntityList ? IconNames.DOUBLE_CHEVRON_LEFT : IconNames.DOUBLE_CHEVRON_RIGHT}
                                className={"filter-toggle-button me-2"} intent={Intent.NONE}
                                onClick={() => {
                                    setShowEntityList(!showEntityList);
                                }}
                            />
                        </WrapInTooltip>
                        <span className="fw-bold">{showEntityList ? "Hide Filters" : "Show Filters"}</span>
                    </div>
                    {params?.navigatorMode === NAVIGATOR_MODE.icons && mainWidget && <>
                    <Layout layout={{type: LAYOUT_TYPE.VERTICAL}} onLayoutChanged={(layout: any) => {}}>
                        <NavigatorWidget
                            key="main table"
                            config={mainWidget}
                            widgets={widgets}
                            layout={LAYOUT_TYPE.VERTICAL}
                            isEditing={edit}
                            lock={lock}
                            notifyWidgetEditAction={(type: WidgetEditAction, value?: any) => {
                                switch (type) {
                                    case WidgetEditAction.COLUMNS_CHANGE: {
                                        setEditWidgetId(mainWidget.id);
                                        setShowNewOrEditBladeObjType(mainWidget.queryConfig?.properties.objType || STARTING_OBJ_TYPE);
                                        break;
                                    }
                                    case WidgetEditAction.CONFIG_CHANGE: {
                                        const config: NavigatorWidgetConfig = value as NavigatorWidgetConfig;
                                        const newWidgets: NavigatorWidgetConfig[] = [...widgets];
                                        for (let index = 0; index < newWidgets.length; index++) {
                                            if (newWidgets[index].id === config.id) {
                                                newWidgets[index] = config;
                                                break;
                                            }
                                        }
                                        setWidgets(newWidgets);
                                        break;
                                    }
                                    case WidgetEditAction.DELETE: {
                                        const remainingWidgets: NavigatorWidgetConfig[] = [];
                                        for (let index = 0; index < widgets.length; index++) {
                                            if (widgets[index].id !== mainWidget.id) {
                                                remainingWidgets.push(widgets[index]);
                                            }
                                        }
                                        setWidgets(remainingWidgets);
                                        break;
                                    }
                                }
                            }}
                            notifyInteraction={(type: INTERACTION_TYPE,  data: any[]) => {
                                //alert(JSON.stringify(entities));
                                if (type === INTERACTION_TYPE.GROUP) {
                                    const groupInteraction: InteractionConfig | undefined = (mainWidget.interactions || []).find(interactionConfig => interactionConfig.type === INTERACTION_TYPE.GROUP);
                                    if (groupInteraction) {
                                        widgetRefs.current.forEach((widget) => {
                                            if (groupInteraction.widgetIds.includes(widget.getId())) {
                                                widget.notifyNewRunbookInput(data);
                                            }
                                        });
                                    } else if (params.navigatorMode !== NAVIGATOR_MODE.dashboard) {
                                        // Use the interactions to get the filters for the add new bar
                                        // We should do this in a better way, but this should work for now.
                                        const filters: FilterEntry[] = [];
                                        if (data?.length) {
                                            for (const filterKey in data[0]) {
                                                /* Filter Changes - Not Ready*/
                                                /**/
                                                if (filterKey === "keys") {
                                                    continue;
                                                }
                                                /**/
                                                if (FILTER_KEYS.includes(filterKey)) {
                                                    filters.push({type: filterKey as FILTER_TYPE, values: [data[0][filterKey]]});
                                                }
                                            }
                                        }
                                        if (lock) {
                                            widgetRefs.current.forEach((widget) => {
                                                if (widget.getId() !== mainWidget.id) {
                                                    widget.notifyNewRunbookInput(data);
                                                }
                                            });    
                                        } else {
                                            setShowNewBlade(true);
                                            setShowNewBladeFilters(filters);
                                            setShowNewOrEditBladeObjType(mainWidget.queryConfig?.properties?.objType || STARTING_OBJ_TYPE);    
                                        }
                                    }
                                }
                            }}
                            notifyFiltersetChange={(filterSet: any[]) => {
                                const filters: FilterEntry[] = [];
                                const newFiltered = filterSet.map(xp => {
                                    return {
                                        type: FILTER_TYPE[xp.category],
                                        values: [xp.value],
                                        operator: filterOperator[xp.operator]
                                    }
                                });
                                filters.push(...newFiltered);
                                for (const widget of widgets) {
                                    if (mainWidget.id === widget.id) {
                                        // We need to update the query as well
                                        if (widget.queryConfig?.properties) {
                                            widget.queryConfig.properties.filters = filters;
                                        }
                                        setWidgets([...widgets]);
                                        break;
                                    }
                                }                            
                            }}
                            notifyLock={(lock: boolean) => {
                                setLock(lock);
                            }}
                            ref={(element: NavigatorWidgetRef) => {
                                const ids = widgetRefs.current.map(widget => widget.getId());
                                if (!ids.includes(element.getId())) {
                                    widgetRefs.current.push(element);
                                } else {
                                    const widgetId = element.getId();
                                    for (let widgetIndex = 0; widgetIndex < widgetRefs.current.length; widgetIndex++) {
                                        if (widgetRefs.current[widgetIndex].getId() === widgetId) {
                                            widgetRefs.current[widgetIndex] = element;
                                            break;
                                        }
                                    }
                                }
                            }}
                        />
                    </Layout>
                    <div className="d-flex flex-row justify-content-between">
                        <ButtonGroup>
                            <Button icon={IconNames.TH} style={{ cursor: "pointer" }} className="me-2" onClick={(event) => {
                                addWidgetFromIcon(WIDGET_TYPE.TABLE);
                            }} />
                            <Button icon={IconNames.TIMELINE_LINE_CHART} style={{ cursor: "pointer" }} className="me-2" onClick={(event) => {
                                addWidgetFromIcon(WIDGET_TYPE.TIMESERIES);
                            }} />
                            <Button icon={IconNames.DOUGHNUT_CHART} style={{ cursor: "pointer" }} className="me-2" onClick={(event) => {
                                addWidgetFromIcon(WIDGET_TYPE.PIE);
                            }} />
                            <Button icon={IconNames.SCATTER_PLOT} style={{ cursor: "pointer" }} className="me-2" onClick={(event) => {
                                addWidgetFromIcon(WIDGET_TYPE.CORRELATION);
                            }} />
                            <Button icon={IconNames.TIMELINE_BAR_CHART} style={{ cursor: "pointer" }} className="me-2" onClick={(event) => {
                                addWidgetFromIcon(WIDGET_TYPE.BAR);
                            }} />
                            <Button icon={IconNames.HEATMAP} style={{ cursor: "pointer" }} className="me-2" onClick={(event) => {
                                addWidgetFromIcon(WIDGET_TYPE.BUBBLE);
                            }} />
                        </ButtonGroup>
                        <ButtonGroup>
                            <Button icon={IconNames.DOUBLE_CARET_HORIZONTAL} style={{ cursor: "pointer" }} className="me-2" onClick={(event) => {
                                setLayout({type: LAYOUT_TYPE.HORIZONTAL});
                            }} />
                            <Button icon={IconNames.DOUBLE_CARET_VERTICAL} style={{ cursor: "pointer" }} className="me-2" onClick={(event) => {
                                setLayout({type: LAYOUT_TYPE.VERTICAL});
                            }} />
                            <Button icon={IconNames.GRID_VIEW} style={{ cursor: "pointer" }} className="me-2" onClick={(event) => {
                                setLayout({type: LAYOUT_TYPE.GRID});
                            }} />
                        </ButtonGroup>
                    </div></>}
                    <Layout layout={layout} onLayoutChanged={(layout: any) => setLayout(layout)}>
                        {widgets.map(widgetConfig => {
                            return <NavigatorWidget
                                key={widgetConfig.id}
                                config={widgetConfig}
                                widgets={widgets}
                                layout={layout.type}
                                isEditing={edit}
                                lock={lock}
                                notifyWidgetEditAction={(type: WidgetEditAction, value?: any) => {
                                    switch (type) {
                                        case WidgetEditAction.COLUMNS_CHANGE: {
                                            setEditWidgetId(widgetConfig.id);
                                            setShowNewOrEditBladeObjType(widgetConfig.queryConfig?.properties.objType || STARTING_OBJ_TYPE);
                                            break;
                                        }
                                        case WidgetEditAction.CONFIG_CHANGE: {
                                            const config: NavigatorWidgetConfig = value as NavigatorWidgetConfig;
                                            const newWidgets: NavigatorWidgetConfig[] = [...widgets];
                                            for (let index = 0; index < newWidgets.length; index++) {
                                                if (newWidgets[index].id === config.id) {
                                                    newWidgets[index] = config;
                                                    break;
                                                }
                                            }
                                            setWidgets(newWidgets);
                                            break;
                                        }
                                        case WidgetEditAction.DELETE: {
                                            const remainingWidgets: NavigatorWidgetConfig[] = [];
                                            for (let index = 0; index < widgets.length; index++) {
                                                if (widgets[index].id !== widgetConfig.id) {
                                                    remainingWidgets.push(widgets[index]);
                                                }
                                            }
                                            setWidgets(remainingWidgets);
                                            break;
                                        }
                                    }
                                }}
                                notifyInteraction={(type: INTERACTION_TYPE,  data: any[]) => {
                                    //alert(JSON.stringify(entities));
                                    if (type === INTERACTION_TYPE.GROUP) {
                                        const groupInteraction: InteractionConfig | undefined = (widgetConfig.interactions || []).find(interactionConfig => interactionConfig.type === INTERACTION_TYPE.GROUP);
                                        if (groupInteraction) {
                                            widgetRefs.current.forEach((widget) => {
                                                if (groupInteraction.widgetIds.includes(widget.getId())) {
                                                    widget.notifyNewRunbookInput(data);
                                                }
                                            });
                                        } else if (params.navigatorMode !== NAVIGATOR_MODE.dashboard) {
                                            // Use the interactions to get the filters for the add new bar
                                            // We should do this in a better way, but this should work for now.
                                            const filters: FilterEntry[] = [];
                                            if (data?.length) {
                                                for (const filterKey in data[0]) {
                                                    /* Filter Changes - Not Ready*/
                                                    /**/
                                                    if (filterKey === "keys") {
                                                        continue;
                                                    }
                                                    /**/
                                                    if (FILTER_KEYS.includes(filterKey)) {
                                                        filters.push({type: filterKey as FILTER_TYPE, values: [data[0][filterKey]]});
                                                    }
                                                }
                                            }
                                            if (params?.navigatorMode === NAVIGATOR_MODE.icons && lock) {
                                                widgetRefs.current.forEach((widget) => {
                                                    if (widget.getId() !== widgetConfig.id) {
                                                        widget.notifyNewRunbookInput(data);
                                                    }
                                                });    
                                            } else {
                                                setShowNewBlade(true);
                                                setShowNewBladeFilters(filters);
                                                setShowNewOrEditBladeObjType(widgetConfig.queryConfig?.properties?.objType || STARTING_OBJ_TYPE);    
                                            }
                                        }
                                    }
                                }}
                                notifyFiltersetChange={(filterSet: any[]) => {
                                    const filters: FilterEntry[] = [];
                                    const newFiltered = filterSet.map(xp => {
                                        return {
                                            type: FILTER_TYPE[xp.category],
                                            values: [xp.value],
                                            operator: filterOperator[xp.operator]
                                        }
                                    });
                                    filters.push(...newFiltered);
                                    for (const widget of widgets) {
                                        if (widgetConfig.id === widget.id) {
                                            // We need to update the query as well
                                            if (widget.queryConfig?.properties) {
                                                widget.queryConfig.properties.filters = filters;
                                            }
                                            setWidgets([...widgets]);
                                            break;
                                        }
                                    }                            
                                }}
                                notifyLock={(lock: boolean) => {
                                    setLock(lock);
                                }}
                                ref={(element: NavigatorWidgetRef) => {
                                    const ids = widgetRefs.current.map(widget => widget.getId());
                                    if (!ids.includes(element.getId())) {
                                        widgetRefs.current.push(element);
                                    } else {
                                        const widgetId = element.getId();
                                        for (let widgetIndex = 0; widgetIndex < widgetRefs.current.length; widgetIndex++) {
                                            if (widgetRefs.current[widgetIndex].getId() === widgetId) {
                                                widgetRefs.current[widgetIndex] = element;
                                                break;
                                            }
                                        }
                                    }
                                }}
                            />;
                        })}
                    </Layout>
                </div>}
            </TwoColumnContainer>
            {editWidgetId && <DetailsPanel size={SIZE.s} anchorElement={undefined} resizableElement={undefined}>
                <BladeContainer className="edit-widget-blade" showIconWithoutBg={true}
                    title={
                        <div className={"text-nowrap text-truncate ms-2"}>{STRINGS.navigators.widgetEditor.title}</div>
                    }
                    onCloseClicked={() => {
                        setEditWidgetId(undefined);
                    }}
                    noContentPadding
                >
                    <div className="p-2">
                        <WidgetEditor 
                            objType={[...widgets, ...(mainWidget ? [mainWidget] : [])].find((widget) => widget.id === editWidgetId)?.queryConfig!.properties.objType || ""}
                            widgetName={[...widgets, ...(mainWidget ? [mainWidget] : [])].find((widget) => widget.id === editWidgetId)?.name || ""}
                            widgetType={[...widgets, ...(mainWidget ? [mainWidget] : [])].find((widget) => widget.id === editWidgetId)?.widgetType || WIDGET_TYPE.TABLE}
                            selectedColumns={getEditWidgetMetrics([...widgets, ...(mainWidget ? [mainWidget] : [])].find((widget) => widget.id === editWidgetId))}
                            displayColumns={["group_column", ...DataOceanUtils.dataOceanMetaData.obj_types[showNewOrEditBladeObjType].metrics]} 
                            limit={[...widgets, ...(mainWidget ? [mainWidget] : [])].find((widget) => widget.id === editWidgetId)?.queryConfig!.properties.limit || 21}
                            topMetric={
                                [...widgets, ...(mainWidget ? [mainWidget] : [])].find((widget) => widget.id === editWidgetId)?.queryConfig!.properties.topBy?.length ?
                                [...widgets, ...(mainWidget ? [mainWidget] : [])].find((widget) => widget.id === editWidgetId)?.queryConfig!.properties.topBy[0].id || "" :
                                    ""
                            }
                            topDirection={
                                [...widgets, ...(mainWidget ? [mainWidget] : [])].find((widget) => widget.id === editWidgetId)?.queryConfig!.properties.topBy?.length ?
                                [...widgets, ...(mainWidget ? [mainWidget] : [])].find((widget) => widget.id === editWidgetId)?.queryConfig!.properties.topBy[0].direction || TOP_BY_DIRECTION.desc :
                                    TOP_BY_DIRECTION.desc
                            }
                            duration={
                                [...widgets, ...(mainWidget ? [mainWidget] : [])].find((widget) => widget.id === editWidgetId)?.queryConfig!.properties.duration ?
                                    parseInt([...widgets, ...(mainWidget ? [mainWidget] : [])].find((widget) => widget.id === editWidgetId)?.queryConfig!.properties.duration! + "") / 60 || 15 :
                                    15
                            }
                            settingsChange={handleSettingsChange} bladeClose={handleEditBladeClose} 
                        />
                    </div>
                </BladeContainer>
            </DetailsPanel>}
            {showNewBlade && <DetailsPanel size={SIZE.s} anchorElement={undefined}
                resizableElement={undefined}
            >
                <BladeContainer className="new-widget-blade" icon={IconNames.ADD_ROW_BOTTOM} showIconWithoutBg={true}
                    title={<>
                        <div className={"text-nowrap text-truncate"}>{STRINGS.navigator.addNewWidgetControl.title}</div>
                        <div className="display-8">{STRINGS.navigator.addNewWidgetControl.subTitle}</div>
                    </>}
                    onCloseClicked={() => {
                        setShowNewBlade(false);
                        setShowNewBladeFilters([]);
                        setShowNewOrEditBladeObjType(STARTING_OBJ_TYPE);
                    }}
                    noContentPadding
                >
                    <div className="p-2">
                        <AddNewWidgetControl
                            objType={showNewOrEditBladeObjType}
                            displayColumns={["group_column", ...DataOceanUtils.dataOceanMetaData.obj_types[showNewOrEditBladeObjType].metrics]} 
                            filters={showNewBladeFilters}
                            limit={5}
                            topMetric={ 
                                DataOceanUtils.dataOceanMetaData.obj_types[showNewOrEditBladeObjType]?.defaults?.top_by?.length ? 
                                    DataOceanUtils.dataOceanMetaData.obj_types[showNewOrEditBladeObjType].defaults.top_by[0].id : 
                                    "" 
                            }
                            topDirection={ 
                                DataOceanUtils.dataOceanMetaData.obj_types[showNewOrEditBladeObjType]?.defaults?.top_by?.length ? 
                                    DataOceanUtils.dataOceanMetaData.obj_types[showNewOrEditBladeObjType].defaults.top_by[0].direction : 
                                    TOP_BY_DIRECTION.desc
                            }
                            duration={DataOceanUtils.dataOceanMetaData.obj_types[showNewOrEditBladeObjType]?.defaults?.duration || 15}
                            onAddNewWidget= {(
                                widgetType: WIDGET_TYPE, widgetName: string, objType: string, selectedColumns: string[], selectedGroupBys: string[], 
                                limit: number, topMetric: string, duration: number, filters: FilterEntry[]
                            ) => {
                                setShowNewBlade(false);
                                const newWidgets: NavigatorWidgetConfig[] = [...widgets];
                                const newWidget = createNewWidget(
                                    widgetType, widgets, objType, selectedGroupBys, selectedColumns, filters, limit, topMetric, duration
                                );
                                if (widgetName?.trim()?.length > 0) {
                                    newWidget.name = widgetName;
                                }
                                newWidgets.push(newWidget);
                                setWidgets(newWidgets);        
                            }}
                            bladeClose={handleNewBladeClose} 
                        />
                    </div>
                </BladeContainer>
            </DetailsPanel>}
        </DataLoadFacade>
        </AutoUpdateContext.Provider>
    </PageWithHeader>;
};

export default NavigatorPage;

/** creates the specification for a new widget, including the query settings.
 *  @param widgetType the WIDGET_TYPE with the type of widget that is to be created.
 *  @param widgets the current set of widget configuration.  This is only used to make sure the name of the 
 *      new widget is unique.
 *  @param objType a string with the object type.
 *  @param groupBy the group bys to use if any.
 *  @param columns the array of string column ids, if any.
 *  @param filter the array of FilterEntry objects, if any.
 *  @param limit the top by limit as a number greater than 0.
 *  @param topMetric a string with the id of the top metric.
 *  @param duration a number greater than 0 with the query duration in minutes.
 *  @returns a NavigatorWidgetConfig object with the widget configuration and query configuration. */
function createNewWidget(
    widgetType: WIDGET_TYPE, widgets: NavigatorWidgetConfig[], objType: string, groupBys?: string[],
    columns?: string[], filters?: FilterEntry[], limit?: number, topMetric?: string, duration?: number
): NavigatorWidgetConfig {
    let maxWidgetIndex = 0;
    for (const widget of widgets) {
        if (widget.name.startsWith("New Widget ")) {
            try {
                const widgetIndex: number = parseInt(widget.name.substring("New Widget ".length, widget.name.length));
                if (!Number.isNaN(widgetIndex)) {
                    maxWidgetIndex = Math.max(maxWidgetIndex, widgetIndex);
                }
            } catch (error) {}
        }
    }

    // Choose some default metrics
    let defaultMetrics: string[] = DataOceanUtils.dataOceanMetaData.obj_types[objType].metrics;

    // The duration in minutes
    let defaultTopBy: TopBy[] | undefined = undefined;
    let newDuration: number = duration || 15;
    if (DataOceanUtils.dataOceanMetaData.obj_types[objType].defaults) {
        newDuration = 60 * (newDuration ? newDuration : DataOceanUtils.dataOceanMetaData.obj_types[objType].defaults.duration || 15);
        if (DataOceanUtils.dataOceanMetaData.obj_types[objType].defaults.metrics?.length) {
            defaultMetrics = DataOceanUtils.dataOceanMetaData.obj_types[objType].defaults.metrics;
        }
        if (DataOceanUtils.dataOceanMetaData.obj_types[objType].defaults.top_by) {
            defaultTopBy = DataOceanUtils.dataOceanMetaData.obj_types[objType].defaults.top_by;
        }
    }

    const firstDefaultMetric = defaultMetrics?.length ? defaultMetrics[0] : undefined;
    const secondDefaultMetric = defaultMetrics?.length > 1 ? defaultMetrics[1] : undefined;
    const thirdDefaultMetric = defaultMetrics?.length > 2 ? defaultMetrics[2] : undefined;

    let widgetOptions = {};
    const displayColumns = [...(columns || ["group_column", firstDefaultMetric || ""])];
    if (secondDefaultMetric && !displayColumns.includes(secondDefaultMetric)) {
        displayColumns.push(secondDefaultMetric);
    }
    if (thirdDefaultMetric && !displayColumns.includes(thirdDefaultMetric)) {
        displayColumns.push(thirdDefaultMetric);
    }
    
    let metrics = [...displayColumns];
    if (metrics.includes("group_column")) {
        metrics.splice(metrics.indexOf("group_column"), 1);
    }

    let newLimit = limit !== undefined && limit > 0 ? limit : 5;
    const topBy = topMetric ? [{id: topMetric, direction: "desc"}] : defaultTopBy || [{id: firstDefaultMetric || "", direction: "desc"}];

    switch (widgetType) {
        case WIDGET_TYPE.TABLE:
            widgetOptions = {
                sortColumn: topBy[0].id,
                sortOrder: topBy[0].direction,
                columns: displayColumns
            };
            break;
        case WIDGET_TYPE.PIE:
            widgetOptions = {
                "metric": metrics?.length > 0 ? metrics[0] : firstDefaultMetric,
                "style": "donut",
                "showPercentage": false,
                "showValue": false,
                "showLegend": false,
                "legendPosition": "top",
                "showMore": false
            };
            metrics = [metrics?.length > 0 ? metrics[0] : firstDefaultMetric || ""];
            break;
        case WIDGET_TYPE.BAR:
            widgetOptions = {
                "showBarLabels": false,
                "showLegend": true,
                "legendPosition": "bottom",
                "showMore": false
            };
            break;
        case WIDGET_TYPE.BUBBLE:
            widgetOptions = {
                "showMore": false,
                "sizeMetric": metrics?.length > 0 ? metrics[0] : firstDefaultMetric,
                "colorMetric": metrics?.length > 1 ? metrics[1] : undefined
            };
            break;
        case WIDGET_TYPE.TIMESERIES:
            limit = 1;
            widgetOptions = {
                "showLegend": false,
                "legendPosition": "top",
                "metrics": [metrics?.length > 0 ? metrics[0] : firstDefaultMetric],
                "showMore": false
            };
            metrics = [metrics?.length > 0 ? metrics[0] : firstDefaultMetric || ""];
            break;
        case WIDGET_TYPE.CORRELATION:
            limit = 1;
            widgetOptions = {
                "showLegend": false,
                "legendPosition": "top",
                "xMetric": metrics?.length > 0 ? metrics[0] : firstDefaultMetric,
                "yMetric": metrics?.length > 1 ? metrics[1] : secondDefaultMetric,
                "showMore": false
            };
            metrics = [
                metrics?.length > 0 ? metrics[0] : firstDefaultMetric || "", 
                metrics?.length > 1 ? metrics[1] : secondDefaultMetric || ""
            ];
            break;
    }
    const timeSeries = widgetType === WIDGET_TYPE.TIMESERIES || widgetType === WIDGET_TYPE.CORRELATION;
    return {
        id: getUuidV4(), name: "New Widget " + (maxWidgetIndex + 1), widgetType: widgetType, options: widgetOptions as any,
        queryConfig: {properties: {
            objType: objType,
            timeSeries: timeSeries,
            topBy: topBy,
            limit: newLimit,
            duration: newDuration,
            filters: filters || [],
            metrics: metrics,
            groupBy: groupBys ? groupBys : []
        }}                    
    };
}

/** this function updates the widget configuration when a drop happens
 *  @param widgetConfig the NavigatorWidgetConfig object with the current configuration of the widget.
 *  @param value the value with the information being passed in by the drop. */
function updateWidgetConfigAfterDrop(widgetConfig: NavigatorWidgetConfig, value: any): void {
    const columns = undefined;

    // Choose some default metrics
    let defaultMetrics: string[] = DataOceanUtils.dataOceanMetaData.obj_types[value.objType].metrics;

    // The duration in minutes
    let defaultTopBy: TopBy[] | undefined = undefined;
    let duration: number = 15;
    if (value.objType && DataOceanUtils.dataOceanMetaData.obj_types[value.objType].defaults) {
        duration = 60 * (DataOceanUtils.dataOceanMetaData.obj_types[value.objType].defaults!.duration || duration);
        if (DataOceanUtils.dataOceanMetaData.obj_types[value.objType].defaults!.metrics?.length) {
            defaultMetrics = DataOceanUtils.dataOceanMetaData.obj_types[value.objType].defaults!.metrics!;
        }
        if (DataOceanUtils.dataOceanMetaData.obj_types[value.objType].defaults!.top_by) {
            defaultTopBy = DataOceanUtils.dataOceanMetaData.obj_types[value.objType].defaults!.top_by;
        }
    }

    const firstDefaultMetric = defaultMetrics?.length ? defaultMetrics[0] : undefined;
    const secondDefaultMetric = defaultMetrics?.length > 1 ? defaultMetrics[1] : undefined;
    const thirdDefaultMetric = defaultMetrics?.length > 2 ? defaultMetrics[2] : undefined;

    let widgetOptions = {};
    const displayColumns = [...(columns || ["group_column", firstDefaultMetric || ""])];
    if (secondDefaultMetric) {
        displayColumns.push(secondDefaultMetric);
    }
    if (thirdDefaultMetric) {
        displayColumns.push(thirdDefaultMetric);
    }
    let metrics: string[] = [...displayColumns];
    if (metrics.includes("group_column")) {
        metrics.splice(metrics.indexOf("group_column"), 1);
    }

    if (value.dataSource === "ALL") {
        displayColumns.push("data_source.name");
    }

    const topBy = defaultTopBy || [{id: firstDefaultMetric || "", direction: "desc"}];

    widgetConfig.queryConfig = {...widgetConfig.queryConfig} as QueryConfig;
    widgetConfig.queryConfig!.properties.groupBy = [value.groupBy];
    widgetConfig.queryConfig!.properties.objType = value.objType;
    widgetConfig.queryConfig!.properties.topBy = topBy;
    widgetConfig.queryConfig!.properties.duration = duration;
    widgetConfig.queryConfig!.properties.metrics = metrics;
    widgetConfig.queryConfig!.properties.dataSource = value.dataSource === "NONE" ? undefined : value.dataSource;

    widgetConfig.options!.columns = displayColumns;
    if (widgetConfig.widgetType === WIDGET_TYPE.TABLE) {
        widgetConfig.options!.sortColumn = topBy[0].id;
        widgetConfig.options!.sortOrder = topBy[0].direction;
    }
}

/** gets the edit widget metrics from the widget configuration.
 *  @param widgetConfig the NavigatorWidgetConfig object with the widget configuration or undefined if no config was found.
 *  @returns a String array with the list of metric ids that should be displayed in the edit widget blade. */
function getEditWidgetMetrics(widgetConfig: NavigatorWidgetConfig | undefined): string[] {
    switch (widgetConfig?.widgetType) {
        case WIDGET_TYPE.TABLE:
            return widgetConfig?.options?.columns as string[] || [];
        case WIDGET_TYPE.PIE:
            return widgetConfig?.options?.metric ? [widgetConfig.options.metric as string] : [];
        case WIDGET_TYPE.BAR:
            return widgetConfig?.queryConfig?.properties?.metrics ? widgetConfig.queryConfig.properties.metrics as string[] : [];
        case WIDGET_TYPE.BUBBLE:
            const bubbleMetrics: string[] = [];
            widgetConfig?.options?.sizeMetric && bubbleMetrics.push(widgetConfig.options.sizeMetric as string);
            widgetConfig?.options?.colorMetric && bubbleMetrics.push(widgetConfig.options.colorMetric as string);
            return bubbleMetrics;
        case WIDGET_TYPE.TIMESERIES:
            return widgetConfig?.options?.metrics ? widgetConfig.options.metrics as string[] : [];
        case WIDGET_TYPE.CORRELATION:
            return widgetConfig?.options?.xMetric && widgetConfig?.options?.yMetric ? 
                [widgetConfig.options.xMetric as string, widgetConfig.options.yMetric as string] : [];
        }
    return [];
}

/** Creates a popup that imports a template into the template viewer.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function.
 *  @param setWidgets sets the array of WidgetConfig objects.
 *  @param setLayout sets the template layout.
 *  @param setNavigatorName function to set the template name.
 *  @param setNavigatorDescr function to set the template description.
 *  @param setAutoUpdate sets the autoupate state.
 *  @param autoUpdate the current AutoUpdate state. */
function importNavigator(
    setDialogState: (dialogState: DialogState) => void,
    setWidgets: (widgetConfigs: NavigatorWidgetConfig[]) => void,
    setLayout: (layout: LayoutConfig) => void,
    setNavigatorName: (name: string) => void,
    setNavigatorDescr: (name: string) => void,
    setAutoUpdate: (autoUpdate: AutoUpdateState) => void,
    autoUpdate: AutoUpdateState
): void {
    const newDialogState: any = {showDialog: true, title: STRINGS.navigator.importDialog.title};
    newDialogState.dialogContent = <>
        <div className="mb-3"><span>{STRINGS.navigator.importDialog.preText}</span></div>
        <input id="runbook-file" type="file" />
    </>;
    newDialogState.dialogFooter = <Button active={true} outlined={true} onClick={async (evt) => {
        const fileList = (document.getElementById("runbook-file") as any).files;
        if (fileList && fileList.length === 1) {
            const fileReader = new FileReader();
            fileReader.onload = async function () {
                const text: string = fileReader.result as string;
                if (text && text.length > 0) {
                    let errors: Array<string> = [];
                    try {
                        setDialogState(updateDialogState(newDialogState, true, true, []));
                        const importedNavigator: NavigatorConfig = JSON.parse(text);
                        const returnedNavigator = await saveImportedNavigator(importedNavigator);
                        if (returnedNavigator?.name) {
                            setNavigatorName(returnedNavigator.name);
                        }
                        setNavigatorDescr(returnedNavigator.description || "");
                        if (returnedNavigator?.widgets?.length) {
                            setWidgets(returnedNavigator.widgets as NavigatorWidgetConfig[]);
                        }
                        if (returnedNavigator?.layout) {
                            setLayout(returnedNavigator.layout);
                        }
                        if (returnedNavigator?.endTime) {
                            const newAutoUpdate = Object.assign({}, autoUpdate);
                            newAutoUpdate.lastUpdate = 60* Math.floor(new Date().getTime() / (60 * 1000));
                            newAutoUpdate.sequenceNumber++;
                            newAutoUpdate.time = returnedNavigator.endTime;
                            newAutoUpdate.enabled = false;
                            setAutoUpdate(newAutoUpdate);
                        }
                    } catch (error) {
                        const errorMsg = (error as any)?.response?.status === 422 && (error as any)?.response?.data?.details[0]?.code
                            ? STRINGS.runbookEditor.errors.codes[(error as any)?.response?.data?.details[0]?.code]
                            : STRINGS.formatString(STRINGS.runbooks.importDialog.errorText, {variant: "dashboard"}) + '<br/>' + error;
                        errors.push(errorMsg);
                    }
                    setDialogState(updateDialogState(newDialogState, errors?.length > 0, false, errors || []));
                }
            };
            fileReader.readAsText(fileList[0]);
        }
    }} text={STRINGS.runbooks.importDialog.btnText} />;
    setDialogState(newDialogState);
}

/** saves a new navigator from the contents of the import file.
 *  @param navigator the JSON object with the contents of the import file.
 *  @returns a Promise which resolves to the navigator that was saved. */
async function saveImportedNavigator(navigator: NavigatorConfig): Promise<NavigatorConfig> {
    // This is just dummy code we need to figure out what to do with a save and get a back-end service to do it
    return Promise.resolve(navigator);
}

/** Creates a popup that saves the navigator to Azure.
 *  @param newDialogState the copied state object with the state setup to open the dialog.  The content
 *      needs to be appended and the title needs to be set in this function.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function.
 *  @returns a Promise. */
/* istanbul ignore next */
async function saveNavigator(
    newDialogState: DialogState, setDialogState: (dialogState: DialogState) => void
): Promise<any> {
    const origName = "My Navigator", origInfo = "My Description";
    newDialogState.title = STRINGS.navigator.saveDialog.title;
    function SaveDialogContent(props: {showErrorSection?: boolean, showErrorButton: boolean, warnings?: number, errors?: number}): JSX.Element {
        return (<>
            <div className="mb-3"><span>{STRINGS.navigator.saveDialog.text}</span></div>
            <table><tbody>
                <tr>
                    <td className="p-1"><label>{STRINGS.navigator.saveDialog.nameLabel}</label></td>
                    <td className="p-1"><InputGroup id="runbook-flow-name" type="text"
                        defaultValue={origName} style={{width: "300px"}}
                    /></td>
                </tr>
                <tr>
                    <td className="p-1"><label>{STRINGS.navigator.saveDialog.descLabel}</label></td>
                    <td className="p-1"><TextArea id="runbook-flow-desc" defaultValue={origInfo}
                        style={{width: "300px", height: "200px"}}
                    /></td>
                </tr>
            </tbody></table>
        </>);
    }
    newDialogState.dialogContent = <SaveDialogContent showErrorButton={false}/>;
    function SaveDialogFooter(props: {showErrorButton: boolean, disabled?: boolean, forceEtag?: boolean}) {
        return <>
            <Button active={true} outlined={true} disabled={Boolean(props.disabled)}
                text={STRINGS.navigator.saveDialog.saveBtnText}
                onClick={async (evt) => {
                    setDialogState(updateDialogState(newDialogState, false, false, []));
                }}
            />
        </>;
    }
    newDialogState.dialogFooter = <SaveDialogFooter showErrorButton={false} disabled={false} />;
    setDialogState(updateDialogState(newDialogState, true, true, []));
}

/** exports the currently displayed navigator.
 *  @param navigator the object with the navigator configuration.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function. */
function exportNavigator(navigator: NavigatorConfig | PinnedWorkflowConfig, setDialogState: (dialogState: DialogState) => void): void {
    const newDialogState: any = {showDialog: true, loading: true, title: STRINGS.navigator.exportDialog.title};
    newDialogState.dialogContent = <div className="mb-3"><span>{STRINGS.navigator.exportDialog.preText}</span></div>;
    newDialogState.dialogFooter = undefined;
    setDialogState(newDialogState);

    try {
        const newDialogState: any = {showDialog: true, title: STRINGS.navigator.exportDialog.title};
        newDialogState.dialogContent = <>
            <div className="mb-3"><span>{STRINGS.navigator.exportDialog.preText}</span></div>
            <textarea defaultValue={JSON.stringify(navigator, null, 4)} style={{width: "470px", height: "350px"}} disabled={true} />
        </>;
        newDialogState.dialogFooter = <CopyToClipboard text={JSON.stringify(navigator)}>
            <Button active={true} outlined={true}
                text={STRINGS.navigator.exportDialog.btnText} onClick={() => {
                    setDialogState(updateDialogState(newDialogState, false, false, []));
                }}
            />
        </CopyToClipboard>;
        setDialogState(newDialogState);
    } catch (error) {
        const afterDialogState: any = updateDialogState(newDialogState, true, false, [STRINGS.navigator.exportDialog.errorText]);
        setDialogState(afterDialogState);
    }
}

/** Creates a popup that shows the navigator settings.
 *  @param name the current navigator name.
 *  @param decription the current navigator description.
 *  @param setNavigatorName function to set the navigator name.
 *  @param setNavigatorDescr function to set the navigator description.
 *  @param dialogState the copied state object with the state setup to open the dialog.  The content
 *      needs to be appended and the title needs to be set in this function.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function.
 *  @returns a Promise. */
async function showNavigatorSettings(
    name: string, description: string,
    setNavigatorName: (name: string) => void,
    setNavigatorDescr: (name: string) => void,
    dialogState: DialogState, setDialogState: (dialogState: DialogState) => void
): Promise<any> {
    const newDialogState = updateDialogState(dialogState, true, false, [], []);

    newDialogState.title = STRINGS.navigators.settingsDialog.title;
    function SaveDialogContent(props: {showErrorSection?: boolean, showErrorButton: boolean, warnings?: number, errors?: number}): JSX.Element {
        return (<>
            <div className="mb-3"><span>{STRINGS.navigators.settingsDialog.settingsText}</span></div>
            <table><tbody>
                <tr>
                    <td className="p-1"><label>{STRINGS.runbookEditor.saveDialog.syncNameLabel}</label></td>
                    <td className="p-1"><InputGroup id="runbook-flow-name" type="text"
                        defaultValue={name} style={{width: "300px"}}
                    /></td>
                </tr>
                <tr>
                    <td className="p-1"><label>{STRINGS.runbookEditor.saveDialog.syncDescLabel}</label></td>
                    <td className="p-1"><TextArea id="runbook-flow-desc" defaultValue={description}
                        style={{width: "300px", height: "200px"}}
                    /></td>
                </tr>
            </tbody></table>
        </>);
    }
    newDialogState.dialogContent = <SaveDialogContent showErrorButton={false}/>;

    function SaveDialogFooter(props: {showErrorButton: boolean, disabled?: boolean, forceEtag?: boolean}) {
        return <>
            <Button active={true} outlined={true} disabled={Boolean(props.disabled)}
                text={STRINGS.navigators.settingsDialog.saveBtnText}
                onClick={async (evt) => {
                    const name = (document.getElementById("runbook-flow-name") as HTMLInputElement).value;
                    const desc = (document.getElementById("runbook-flow-desc") as HTMLInputElement).value;
                    setNavigatorName(name);
                    setNavigatorDescr(desc);
                    setDialogState(updateDialogState(newDialogState, false, false, []));
                }}
            />
        </>;
    }
    newDialogState.dialogFooter = <SaveDialogFooter showErrorButton={false} disabled={false} />;
    newDialogState.loading = false;
    setDialogState(newDialogState);
}
