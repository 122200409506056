/** This file defines the auto-update react component.  The auto-update React component 
 *  keeps track of the auto-update clock and allows the user to modify the auto-update settings.
 *  @module */

import { useEffect, useRef, useReducer } from "react";
import { IconNames } from "@tir-ui/react-components";
import { Button, Classes, Position, Switch, Popover } from "@blueprintjs/core";
import moment from "moment";
import { STRINGS } from "app-strings";
import { DateTimeSelector } from "components/common/date-time-selector/DateTimeSelector.tsx";
import './AutoUpdateControl.scss';

/** an interface that describes the properties that can be passed in to the auto-complete component.*/
export interface AutoUpdateControlProps {
    /** the current auto-update state. */
    autoUpdate: AutoUpdateState;
    /** a boolean value, if true show the auto-update on/off switch. */
    showAutoUpdateSwitch?: boolean;
    /** a boolean value, if true show the refresh button. */
    showRefreshButton?: boolean;
    /** a boolean value, if true show the pause/play button. */
    showPlayPauseButton?: boolean;
    /** the handler for auto-update changes. */
    onAutoUpdateChanged: (autoUpdateState: AutoUpdateState) => void;
    /** the type of time string. */
    timeType?: TimeType;
    /** a string with the time format for moment. */
    timeFormat?: TimeType;
    /** CSS style class that should be applied to the outermost element of this component */
    className?: string;
    /** a boolean value, if true show the time control. */
    showTimeControl?: boolean;
}

/** this interface describes the auto-update state. */
export interface AutoUpdateState {
    /** specifies whether or not auto-update is enabled. */
    enabled: boolean;
    /** the auto-update interval in minutes. */
    interval: number;
    /** the time of the last auto-update in seconds from unix epoch. */
    lastUpdate: number;
    /** an integer with a unique value for each auto update. */
    sequenceNumber: number;
    /** if the time is being set manually, this is the time set by the user, otherwise it will be undefined. */
    time?: number | undefined;
}

/** the type of time string to show, we can show a date or a duration like 10 minutes ago */
export enum TimeType {
    /** show last update times as a date. */
    DATE        = "DATE",
    /** show last update times as durations from now. */
    DURATION    = "DURATION"
}

const showMoreButton: boolean = false;
 
/** Renders the auto-update control.
 *  @param props the properties passed in.
 *  @returns JSX with the auto-update control react component.*/
export default function AutoUpdateControl(props: AutoUpdateControlProps): JSX.Element {
    const { autoUpdate, onAutoUpdateChanged, timeFormat = "MMM Do, YYYY HH:mm", timeType = TimeType.DATE} = props;

    const timeoutId = useRef<number>(0);
    useEffect(() => {
        if (autoUpdate.enabled && autoUpdate.interval > 0) {
            // If user enables auto-update in the middle of an interval period (e.g. at 40th second on a 1 minute interval),
            // then we should perform the update after whatever time is left (20s in this example) so that the data stays updated.
            // If we don't do that and the user keeps pausing and unpausing between auto-updates, the UI will enver update
            // as it will always wait for the entire interval to complete from when the last time auto-update was enabled (1 minute)
            const performUpdateAfter = getSecondsLeftForNextUpdate(autoUpdate.interval);
            timeoutId.current = setTimeout(() => {
                timeoutId.current = 0;
                const newAutoUpdate = Object.assign({}, autoUpdate);
                newAutoUpdate.lastUpdate = 60 * Math.floor(new Date().getTime() / (60 * 1000));
                newAutoUpdate.sequenceNumber++;
                newAutoUpdate.time = undefined;
                onAutoUpdateChanged(newAutoUpdate);
            }, performUpdateAfter * 1000) as any;
        }
        return () => {
            clearAutoUpdateTimeout(timeoutId);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoUpdate, onAutoUpdateChanged]);

    const forceUpdate = useReducer(x => x + 1, 0)[1];
    const labelIntervalId = useRef<number | null>(null);
    useEffect(() => {
        if (timeType === TimeType.DURATION && labelIntervalId.current === null) {
            labelIntervalId.current = setInterval(() => {
                forceUpdate();
            }, 60 * 1000) as any;
        } else if (labelIntervalId.current !== null) {
            clearInterval(labelIntervalId.current);
            labelIntervalId.current = null;
        }
        return () => {
            if (labelIntervalId.current !== null) {
                clearInterval(labelIntervalId.current);
                labelIntervalId.current = null;
            }
        };
    }, [timeType, autoUpdate, forceUpdate]);

    function getSecondsLeftForNextUpdate (intervalInSeconds) {
        const updateIntervalInSeconds = intervalInSeconds * 60;
        const nextUpdateSlot = autoUpdate.lastUpdate + updateIntervalInSeconds;
        const timeRightNow = Math.floor(new Date().getTime() / 1000);
        const secondsLeftForNextUpdateSlot = nextUpdateSlot - timeRightNow;
        return secondsLeftForNextUpdateSlot > 0 ? secondsLeftForNextUpdateSlot : 0;
    }

    return (<div className={"auto-update-control" + (props.className ? " " + props.className : "")}>
        {props.showAutoUpdateSwitch && <div>
            <Switch label="Auto-Update" inline={true} defaultChecked={autoUpdate.enabled} onChange={(event) => {
                clearAutoUpdateTimeout(timeoutId);
                const newAutoUpdate = Object.assign({}, autoUpdate);
                newAutoUpdate.enabled = !newAutoUpdate.enabled;
                onAutoUpdateChanged(newAutoUpdate);
            }}/>
            <Popover minimal position={Position.BOTTOM_RIGHT} content={<div className="pt-4 pb-4 pe-4 ps-4">
                <div className="pb-4" >
                    <span className="me-2">{STRINGS.autoUpdate.autoUpdatePrefix}</span>
                    <input id="auto-update-interval" defaultValue={autoUpdate.interval} style={{width: "50px"}} className="me-2"/> 
                    {STRINGS.autoUpdate.autoUpdateSuffix}
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button text={STRINGS.autoUpdate.okayBtnText} active={true} outlined={true} className={Classes.POPOVER_DISMISS} onClick={() => {
                            clearAutoUpdateTimeout(timeoutId);
                            const value: number = parseInt((document?.getElementById("auto-update-interval") as HTMLInputElement).value);
                            const newAutoUpdate = Object.assign({}, autoUpdate);
                            newAutoUpdate.interval = value !== null && value !== undefined && value > 0 && !Number.isNaN(value) ? value : 0;
                            newAutoUpdate.time = undefined;
                            onAutoUpdateChanged(newAutoUpdate);
                        }}/>
                        <Button text={STRINGS.autoUpdate.cancelBtnText} active={true} outlined={true} className={Classes.POPOVER_DISMISS}/>
                    </div>
                </div>
            </div>} >
                <Button minimal icon={IconNames.COG} onClick={() => {}}/>
            </Popover>
        </div>}
        <div>
            <span className="last-update-time me-2 display-9">{getLastUpdateText(timeType, autoUpdate, timeFormat)}</span>
            {props.showRefreshButton && <Button minimal icon={IconNames.RESET} onClick={() => {
                clearAutoUpdateTimeout(timeoutId);
                const newAutoUpdate = Object.assign({}, autoUpdate);
                newAutoUpdate.lastUpdate = 60* Math.floor(new Date().getTime() / (60 * 1000));
                newAutoUpdate.sequenceNumber++;
                newAutoUpdate.time = undefined;
                onAutoUpdateChanged(newAutoUpdate);
            }}/>}
            {props.showPlayPauseButton && <Button minimal icon={autoUpdate.enabled ? IconNames.PAUSE : IconNames.PLAY} onClick={() => {
                clearAutoUpdateTimeout(timeoutId);
                const newAutoUpdate = Object.assign({}, autoUpdate);
                newAutoUpdate.enabled = !newAutoUpdate.enabled;
                newAutoUpdate.time = undefined;
                onAutoUpdateChanged(newAutoUpdate);
            }}/>}
            {props.showTimeControl && <DateTimeSelector
                className={showMoreButton ? "mb-1" : ""}
                showMoreButton={showMoreButton}
                showInline={false}
                timeSelectionHandler={(newTime: number | undefined) => {
                    clearAutoUpdateTimeout(timeoutId);
                    const newAutoUpdate = Object.assign({}, autoUpdate);
                    newAutoUpdate.lastUpdate = 60* Math.floor(new Date().getTime() / (60 * 1000));
                    newAutoUpdate.sequenceNumber++;
                    newAutoUpdate.time = newTime;
                    newAutoUpdate.enabled = false;
                    onAutoUpdateChanged(newAutoUpdate);
                }}
                controlledTime={autoUpdate.time}
            />}
        </div>
    </div>);
};

/** returns the last updated text.
 *  @param timeType the type of time string to show a date or a duration (10 minutes ago).
 *  @param autoUpdate the current auto update state. 
 *  @param timeFormat the format string for moment. */
function getLastUpdateText(timeType: TimeType, autoUpdate: AutoUpdateState, timeFormat: string): string {
    const pausedPrefix = (autoUpdate.enabled ? "" : STRINGS.autoUpdate.pausedPrefix + " - ");
    if (timeType === TimeType.DATE) {
        return pausedPrefix + STRINGS.autoUpdate.lastUpdatePrefix + " " + (timeType === TimeType.DATE ? moment(autoUpdate.lastUpdate * 1000).format(timeFormat) : "10 minutes")
    } else {
        let duration = Math.floor(new Date().getTime() / 1000 - autoUpdate.lastUpdate);
        let key;
        if (duration > 24 * 60 * 60) {
            duration = Math.floor(duration / (24 * 60 * 60));
            key = duration > 1 ? "lastUpdatedDays" : "lastUpdatedDay";
        } else if (duration > 60 * 60) {
            duration = Math.floor(duration / (60 * 60));
            key = duration > 1 ? "lastUpdatedHours" : "lastUpdatedHour";
        } else if (duration > 60) {
            duration = Math.floor(duration / (60));
            key = duration > 1 ? "lastUpdatedMinutes" : "lastUpdatedMinute";
        } else {
            return pausedPrefix + STRINGS.autoUpdate.lastUpdatedReallySmall;
        }
        return pausedPrefix + STRINGS.formatString(STRINGS.autoUpdate[key], duration.toString()) as string;
    }
}

/** clears the timeout id.
 *  @param timeoutId the object returned by useRef that contains the timeout id. */
function clearAutoUpdateTimeout(timeoutId: {current: number}): void {
    if (timeoutId.current) {
        clearTimeout(timeoutId.current);
        timeoutId.current = 0;
    }
}
