import type React from 'react';
import classNames from 'classnames';
import { Classes, Divider, Switch } from '@blueprintjs/core';
import { InputField } from "components/common/form/InputField.tsx";
import { Form } from 'components/common/form/Form.tsx';
import { STRINGS } from 'app-strings';

export interface IConnectionConfigPanelProps {
    proxyEnabled: boolean;
    proxyHostname: string;
    proxyProtocol: string;
    proxyPort: string;
    proxyAuthEnabled: boolean;
    proxyUsername: string;
    proxyPassword: string;
    websocketsEnabled: boolean;
    onChangeProxyEnabled: (event: React.FormEvent<HTMLInputElement>) => void;
    onChangeWebsocketsEnabled: (
        event: React.FormEvent<HTMLInputElement>
    ) => void;
    onChangeProxyHostname: (event: React.FormEvent<HTMLInputElement>) => void;
    onChangeProxyProtocol: (event: React.FormEvent<HTMLInputElement>) => void;
    onChangeProxyPort: (event: React.FormEvent<HTMLInputElement>) => void;
    onChangeProxyAuthEnabled: (event: React.FormEvent<HTMLInputElement>) => void;
    onChangeProxyUsername: (event: React.FormEvent<HTMLInputElement>) => void;
    onChangeProxyPassword: (event: React.FormEvent<HTMLInputElement>) => void;
}

const ConnectionConfigPanel: React.FC<IConnectionConfigPanelProps> = (
    props
) => {
    return (
        <div className={classNames(Classes.DIALOG_BODY)} style={{overflowY: "auto", overflowX: "hidden"  }}>
            <p>
                <b>
                    {
                        STRINGS.DATA_SOURCES.addEdgeGatewayDialog.panels
                            .connectionConfig.title
                    }
                </b>
            </p>
            <Divider />
            <br />
            <Form
                initialValues={{
                    proxy_enabled: props.proxyEnabled,
                    proxy_hostname: props.proxyHostname,
                    proxy_protocol: props.proxyProtocol,
                    proxy_port: props.proxyPort,
                    proxy_auth_enabled: props.proxyAuthEnabled,
                    proxy_username: props.proxyUsername,
                    proxy_password: props.proxyPassword,
                    websockets_enabled: props.websocketsEnabled,
                }}
                loading={false}
            >
                <Switch
                    name="proxy_enabled"
                    checked={props.proxyEnabled}
                    label={
                        STRINGS.DATA_SOURCES.addEdgeGatewayDialog.panels
                            .connectionConfig.fields.proxy
                    }
                    onChange={props.onChangeProxyEnabled}
                />
                <div className="row">
                    <div className="col-md-5">
                        <InputField
                            name="proxy_hostname"
                            type="text"
                            required={true}
                            className="input-field"
                            label={
                                STRINGS.DATA_SOURCES.addEdgeGatewayDialog.panels
                                    .connectionConfig.fields.proxyHostname
                            }
                            onChange={props.onChangeProxyHostname}
                            disabled={!props.proxyEnabled}
                        />
                        <div className="row">
                            <div className="col-md-8">
                                <InputField
                                    name="proxy_protocol"
                                    type="text"
                                    required={true}
                                    className="input-field"
                                    label={
                                        STRINGS.DATA_SOURCES
                                            .addEdgeGatewayDialog.panels
                                            .connectionConfig.fields
                                            .proxyProtocol
                                    }
                                    onChange={props.onChangeProxyProtocol}
                                    disabled={!props.proxyEnabled}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField
                                    name="proxy_port"
                                    type="text"
                                    required={true}
                                    className="input-field"
                                    label={
                                        STRINGS.DATA_SOURCES
                                            .addEdgeGatewayDialog.panels
                                            .connectionConfig.fields.proxyPort
                                    }
                                    onChange={props.onChangeProxyPort}
                                    disabled={!props.proxyEnabled}
                                />
                            </div>
                        </div>
                        <Switch
                            name="proxy_auth_enabled"
                            checked={props.proxyAuthEnabled}
                            label={
                                STRINGS.DATA_SOURCES.addEdgeGatewayDialog.panels
                                    .connectionConfig.fields.proxyAuth
                            }
                            onChange={props.onChangeProxyAuthEnabled}
                            disabled={!props.proxyEnabled}
                        />
                        <InputField
                            name="proxy_username"
                            type="text"
                            className="input-field"
                            label={
                                STRINGS.DATA_SOURCES
                                    .addEdgeGatewayDialog.panels
                                    .connectionConfig.fields.proxyUsername
                            }
                            onChange={props.onChangeProxyUsername}
                            disabled={!props.proxyAuthEnabled}
                        />
                        <InputField
                            name="proxy_password"
                            type="password"
                            className="input-field"
                            label={
                                STRINGS.DATA_SOURCES
                                    .addEdgeGatewayDialog.panels
                                    .connectionConfig.fields.proxyPassword
                            }
                            onChange={props.onChangeProxyPassword}
                            disabled={!props.proxyAuthEnabled}
                            showPasswordOption={true}
                        />
                    </div>
                </div>
                <Switch
                    name="websockets_enabled"
                    checked={props.websocketsEnabled}
                    label={
                        STRINGS.DATA_SOURCES.addEdgeGatewayDialog.panels
                            .connectionConfig.fields.websockets
                    }
                    onChange={props.onChangeWebsocketsEnabled}
                />
            </Form>
        </div>
    );
};

export { ConnectionConfigPanel };
