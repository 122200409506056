/** This module defines the top navigation React component.  The top navigation bar is displayed at the top
 *      of the page and has the hamburger icon the search control, the user information, etc.
 *  @module */

import { useEffect, useState } from 'react';
import {
  Button,
  Classes,
  Navbar,
  NavbarDivider,
  NavbarGroup,
  NavbarHeading,
  MenuItem,
  Popover,
  Menu,
  type IconName,
} from "@blueprintjs/core";
import { AuthServiceProvider } from "utils/providers/AuthServiceProvider.ts";
import { Icon, RoutedMenuItem } from "@tir-ui/react-components";
import { ThemeContext } from "utils/themes/manager/ThemeManager.tsx";
import { THEME } from "utils/themes/manager/constants.ts";
import { GetURLPath as getURLPath, GetRoutes as getRoutes } from "config/routes.ts";
import { STRINGS } from "app-strings";
import logo from "assets/images/branding/logo-public.svg";
import { APP_ICONS } from "components/sdwan/enums/icons.ts";
import { useQueryParams } from "utils/hooks/useQueryParams.ts";
import { InputGroup } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { getURL } from "utils/hooks/useQueryParams.ts";
import { HelpSources, EventNames, trackEvent } from "utils/appinsights/AppInsights.ts";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { WrapInTooltip } from "components/common/wrap-in-tooltip/WrapInTooltip.tsx";
import { WhatsNew } from "./WhatsNew.tsx";
import { IconVideo } from "components/common/icon-video/IconVideo.tsx";
import { useNavigate } from "react-router-dom";
import "./TopNavigation.scss";

/** this constant refers to the auth service where you can get the tenant, user, etc. */
const AuthService = AuthServiceProvider.getService();

const HELP_URI = import.meta.env.VITE_HELP_URI;

/** Renders the top navigation menu.
 *  @param props the properties passed in.
 *  @returns JSX with the top navigation menu component.*/
export function TopNavigation(props: {
    sidebarOpen: boolean;
    sidebarDocked: boolean;
    toggleSidebarOpen: () => void;
    dontCheckWhatsNew?: boolean;
}): JSX.Element {
    const ROUTES = getRoutes();
    const { params } = useQueryParams({ listenOnlyTo: ['searchText'] });
    const [searchText, setSearchText] = useState(params?.searchText ? params.searchText : '');
    const navigate = useNavigate();
    const appInsightsContext = useAppInsightsContext();

    useEffect(() => {
        setSearchText(params.searchText ? params.searchText : "");
    }, [params]);

    function onSearch(e) {
        if (e.key === 'Enter') {
            navigate(
                getURL(
                    getURLPath('search'),
                    {
                        searchText: e.target.value,
                    },
                    { replaceQueryParams: true },
                ),
            );
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [navBarClasses, setNavBarClasses] = useState("");
    const videoList = {
        'User_Assistance_Intro': STRINGS.topNavigation.videoDialogTitle,
        'Runbook_Editor_Tour': STRINGS.runbookEditor.toolbar.tooltips.videoDialogTitle,
        'Introduction_to_Runbooks': STRINGS.incidents.tooltips.videoDialogTitle
    }

    return (
        <>
        <Navbar className={"main-navbar text-secondary d-flex justify-content-between " + navBarClasses} fixedToTop={false}>
            <NavbarGroup>
                <Button
                    aria-label="Menu Button"
                    className={Classes.MINIMAL + " text-secondary hamburger-btn"}
                    icon={<Icon icon={props.sidebarOpen ? APP_ICONS.SIDEBAR_OPEN : APP_ICONS.SIDEBAR_CLOSED}/>}
                    onClick={() => props.toggleSidebarOpen()}
                    disabled={localStorage.getItem("sidebarDocked") ? !props.sidebarDocked : false}
                />
                <NavbarDivider className=''/>
                <NavbarHeading>
                    <img className="navbar-logo mb-2 p-1" src={logo} alt={STRINGS.companyName} />
                </NavbarHeading>
                {/* <NavbarHeading className="d-none d-sm-block">{STRINGS.appTitle}</NavbarHeading> */}
            </NavbarGroup>
            <NavbarGroup className="w-4 me-2 d-none d-lg-flex">
                <InputGroup id="searchBox"
                    name="searchBox"
                    fill={true}
                    leftIcon={IconNames.SEARCH as IconName}
                    onChange={(e) => {
                        setSearchText(e.currentTarget.value);
                    }}
                    value={searchText}
                    type="search"
                    onKeyDown={onSearch}
                    placeholder={STRINGS.SEARCH.inputPlaceholder}
                    className="editor-input-standard dark-background" />
            </NavbarGroup>
            <NavbarGroup>
                <IconVideo videoData={videoList} className="text-secondary" asList={true} />
                {HELP_URI && <WhatsNew dontCheckWhatsNew={props.dontCheckWhatsNew}>
                    <WrapInTooltip tooltip="Help">
                        <Button minimal icon={<Icon icon={APP_ICONS.HELP}/>} aria-label="Help Button" className="text-secondary"
                            onClick={(event) => {
                                window.open(HELP_URI, '_blank');
                                event.preventDefault();
                                // report metrics to App Insights
                                if (appInsightsContext) {
                                    const properties = {
                                        name: EventNames.HELP_INVOKED, 
                                        properties: {
                                            topic: HELP_URI || "Unknown",
                                            type: HelpSources.APP
                                        }
                                    };
                                    trackEvent(appInsightsContext, AuthService, properties);
                                }                            
                            }}
                        />
                    </WrapInTooltip>
                </WhatsNew>}
                <Popover className={Classes.MINIMAL} minimal={true} usePortal={true}
                    content={
                        <Menu>
                            <MenuItem text={ROUTES.profile.title} icon={<Icon icon={ROUTES.profile.icon} />}
                                onClick={() => {
                                    navigate(getURL(getURLPath('profile'), {}, { replaceQueryParams: true } ) );
                                }}
                            />
                            {/*
                            <RoutedMenuItem className="d-sm-none" {...ROUTES.profile} key="profile-small" title={AuthService.getUserAccount().getName()}/>
                            <RoutedMenuItem className="d-none d-sm-flex" {...ROUTES.profile}/>
                            */}
                            <ThemeContext.Consumer>
                                {(ctx) => (
                                    <MenuItem
                                        aria-label="User Theme Mode Link"
                                        key={ctx.theme}
                                        text={ctx.theme === THEME.light ? STRINGS.darkTheme : STRINGS.lightTheme}
                                        icon={<Icon icon={ctx.theme === THEME.light ? APP_ICONS.DARK_THEME : APP_ICONS.LIGHT_THEME} />}
                                        onClick={() => {
                                            ctx.setTheme(ctx.theme === THEME.light ? THEME.dark : THEME.light);
                                        }}
                                    />
                              )}
                            </ThemeContext.Consumer>
                            <MenuItem text={STRINGS.logout} icon={<Icon icon={APP_ICONS.LOG_OUT} />}
                                onClick={() => {
                                    AuthService.logout();
                                }}
                            />
                        </Menu>
                    }
                >
                    <span>
                        <Button
                            minimal={true}
                            icon={<Icon icon={APP_ICONS.USER_MENU} />}
                            aria-label="User Menu Button"
                            className="text-secondary"
                        >
                            <span className="d-none d-sm-block">{AuthService.getUserAccount().getName()}</span>
                        </Button>
                    </span>
                </Popover>
            </NavbarGroup>

        </Navbar>
        </>
    );
}
