import { ApiService } from "utils/services/ApiService";

export type FFResponseType = {
    etag: string;
    key: string;
    /** combination of tenant id and feature flag `####-##-###/ff` */
    label: string;
    content_type: string;
    /** the feature flag value */
    value: string;
    tags: Record<string, any>;
    locked: boolean;
    last_modified: string;
};

export type FFResultType = {
    items: FFResponseType[];
};

/**
 * WARN: all feature flags need to be lowercased
 * NOTE: please add additional feature flags as required to assist with
 * autocomplete when using LSPs.
 * Hardcoded record of feature flags for all tenants.
 * Type generated as a string literal union
 */
export type FeatureFlagUnions =
    /** hides the cloudim features, `/cloudim` */
    "cloudim";

type CombinedFFRecord = { [key in FeatureFlagUnions]: boolean };

/** key represents feature flag name, value is the ff setting */
export type TenantCombinedFFRecord = Partial<CombinedFFRecord>;

export type TenantCombinedFFType = {
    /** feature flag name */
    featureFlag: string;
    /** the feature flag value, `false = hide` feature or `true = show feature`  */
    value: boolean;
};

/**
 * Extracts the feature flag for a given label
 *
 * @param {string} input - The input string to process
 * @returns {string} The substring after the last '/' character, represents the
 * labels given feature flag
 *
 * @example
 * getLabelFF('e6bc-cb-sd230/poolparty') // returns str `poolparty`
 */
export const getLabelFF = (input: string): string => {
    const ff = input.split("/");
    return ff[ff.length - 1].toLowerCase();
};

export const getFFBool = (input: string): boolean => {
    const lowerCase = input.toLowerCase();
    if (lowerCase === "disable" || lowerCase === "disabled") {
        return false;
    }
    if (lowerCase === "enable" || lowerCase === "enabled") {
        return true;
    }
    return false;
};

// NOTE: may need to include default values for all feature flags as they are
// created.
/** assume empty object unless populated with call */
export const defaultFFObj: TenantCombinedFFRecord = {};

const FF_BASE_URL = "featureflags";

/*
 * Template string function to provide correct URL path for feature flags
 * @param {string} tenantid - retrieved from the `ApiService.getTenantId()`
 * @param {string} ff - feature flag, provided when invoked
 */
const SINGLE_FF_URL_PARAMS = (tenantid: string, ff: string) =>
    `featureflags/kv?key=${tenantid}/${ff}`;
const ALL_FF_URL_PARAM = (tenantid: string) =>
    `featureflags/kv?key=${tenantid}/*`;

// NOTE: may need additional methods as needs arise
/** Feature flag service extended from `ApiService`. Used in `FeatureFlagStore.ts`
 */
class FFService extends ApiService {
    /** the constructor for the class. */
    constructor() {
        super(FF_BASE_URL);
    }

    /** returns the base uri, this can be overridden in subclasses to allow the uri to change
     *  after construction.
     *  @returns a String with the base uri. */
    protected getBaseUri(): string {
        if (ApiService.USE_REGION) {
            const region = ApiService.AUTH_SERVICE.getRegion();
            return `/api/iq/${region}/`;
        } else {
            return this.baseApiUri;
        }
    }

    /** runs the specified query via `Azure Front Door` and returns the FF
     * boolean. If ff is `true`, feature is `enabled` meaning allowed. If ff is
     * `false`, feature is `disabled` meaning not allowed.
     *  @param {string} - feature flag to query
     *  @returns a Promise which resolves to `true` or `false` */
    getFFSource(ff: FeatureFlagUnions): Promise<boolean> {
        const utid = ApiService.AUTH_SERVICE.getTenantId();
        return new Promise((resolve, reject) => {
            super.get<FFResultType>(`${SINGLE_FF_URL_PARAMS(utid, ff)}`).then(
                // NOTE: if we need to return an object, or additonal data
                // please refer to the `FFResultType` for what is available
                (results) => {
                    if (
                        !results ||
                        !results.items ||
                        results.items.length === 0
                    ) {
                        return resolve(false);
                    }
                    if (results.items.length === 1) {
                        const resObj = results.items[0];
                        const ffValue = resObj.value.toLowerCase();
                        if (ffValue === "disable" || ffValue === "disabled") {
                            return resolve(false);
                        }
                        if (ffValue === "enable" || ffValue === "enabled") {
                            return resolve(true);
                        }
                    }
                },
                (err) => {
                    reject(err);
                    console.error(err);
                },
            );
        });
    }

    /** runs the specified query via `Azure Front Door`, retrieves all
     * feature flag info (will always return one value)
     * @param {string} - feature flag to query
     *  @returns a Promise which resolves to `FFResultType` results */
    getFFResults(): Promise<any> {
        const utid = ApiService.AUTH_SERVICE.getTenantId();
        return new Promise((resolve, reject) => {
            super.get<FFResultType>(`${ALL_FF_URL_PARAM(utid)}`).then(
                (results) => {
                    if (
                        !results ||
                        !results.items ||
                        results.items.length === 0
                    ) {
                        return resolve(defaultFFObj);
                    }
                    const ffMapObj: TenantCombinedFFRecord =
                        results.items.reduce((acc, item) => {
                            const label = getLabelFF(item.key);
                            acc[label] = getFFBool(item.value);
                            return acc;
                        }, {} as TenantCombinedFFRecord);
                    resolve(ffMapObj);
                },
                (err) => {
                    reject(err);
                },
            );
        });
    }
}

/** Class instance to provide methods to retrieve FF info */
export const FeatureFlagService = new FFService();
