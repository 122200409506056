import './AttributesTab.scss';

export interface AttributesTabProps {
    attributes: any;
}

const AttributesTab = ({ attributes }: AttributesTabProps) => {
    const tranverseObject = (obj: any) => {
        const getValue = (value: any) => {
            const acceptableValues = new Set([null, undefined, "number", "boolean", "string"]);

            if (acceptableValues.has(value) || acceptableValues.has(typeof value)) {
                return value + "";
            } else if (typeof value === "object") {
                return tranverseObject(value);
            }
            return "";
        }

        return (
            <ul>
                {Object.entries(obj).map(([key, value]) => (
                    <li key={key}>
                        <span className="key">{key}: </span>
                        <span className="value">{getValue(value)}</span>
                    </li>
                ))}
            </ul>
        )
    }

    return (
        <div className="attributes">
            {attributes && tranverseObject(attributes)}
        </div>
    );
};

export default AttributesTab;
