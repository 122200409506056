query CloudIMNetIMResourceDetails($latitude: [Float], $longitude: [Float], $token: [String]) {
    resources: cloudImResourceNetImDevice(
        latitudeRange: $latitude,
        longitudeRange: $longitude,
        tokens: $token
    ) {
        entityAttributes {
            rootElement
        }
        networkInterface {
            entityKind
            netImEntityId
            entityAttributes {
                rootElement
            }
        }
        netImEntityId
        entityKind
        timestamp
        utid
    }
}
