import React, { useCallback, useEffect, useState } from 'react';
import { ItemPredicate, Select, SelectProps } from "@blueprintjs/select";
import { Button, MenuItem, OptionProps } from "@blueprintjs/core";
import { Classes, Icon, IconNames } from "@tir-ui/react-components";
import classNames from "classnames";
import "./SelectInput.scss";

export type itemType = Omit<OptionProps, "label"> & {
    label: string
    color?: string
    icon?: string
    className?: string
};

export enum SelectedListItemStyles {
    TICK = "tick",
    DEFAULT = "default"
}

/***
 * Renders the icons to be displayed in the menu item list in the drop-down
 * @param icon
 * @param style
 * @param modfiers
 */
const renderIcons = (icon, style, modfiers): React.ReactElement => {
    let iconComponent = <Icon icon={ icon }/>;
    let styleIconComponent;
    // If tick style is selected then show the tick icon for selected item and no icon for the rest.
    // We still need horizontally align all items equally hence none icon is needed
    // if the style icon is not tick then just return only the default icon that is associated  with the item
    if(style === SelectedListItemStyles.TICK) {
        styleIconComponent = <Icon icon={undefined} />;
        if(modfiers.active) {
            styleIconComponent = <Icon icon={ IconNames.TICK }/>
        }
        iconComponent = <>{ iconComponent }{ styleIconComponent }</>
    }
    return iconComponent;
}

/***
 * Properties type for SelectInput component
 */
export type SelectInputType = {
    selectedValue?: string
    placeholder?: string,
    selectedItemStyle?: SelectedListItemStyles.TICK | SelectedListItemStyles.DEFAULT,
    onItemSelect?: Function,
    subText?: string
    disableSelection?: boolean
} & Omit<SelectProps<itemType>, "itemRenderer" | "onItemSelect">;

const SuggestInput = Select.ofType<itemType>();

/***
 * Filtering the list in the dropdown based on the query provided by the user
 * @param query
 * @param item
 * @param _index
 * @param exactMatch
 */
export const filterItem: ItemPredicate<itemType> = (query, item, _index, exactMatch): boolean => {//future: allow override
    let normalizedName = item.label;
    normalizedName = normalizedName.toLowerCase()
    const normalizedQuery = query.toLowerCase();

    if(exactMatch) {
        return normalizedName === normalizedQuery;
    } else {
        return normalizedName.indexOf(normalizedQuery) >= 0;
    }
};

/***
 * Highlight the query string in the items that are filtered and displayed in the drop-down
 * @param text
 * @param query
 */
function highlightText(text: string, query: string): Array<React.ReactNode> {
    let lastIndex = 0;
    const words = query
        .split(/\s+/)
        .filter(word => word.length > 0)
        .map(escapeRegExpChars);
    if(words.length === 0) {
        return [text];
    }
    const regexp = new RegExp(words.join('|'), 'gi');
    const tokens: React.ReactNode[] = [];
    while (true) {
        const match = regexp.exec(text);
        if(!match) {
            break;
        }
        const length = match[0].length;
        const before = text.slice(lastIndex, regexp.lastIndex - length);
        if(before.length > 0) {
            tokens.push(before);
        }
        lastIndex = regexp.lastIndex;
        tokens.push(<strong key={ lastIndex }>{ match[0] }</strong>);
    }
    const rest = text.slice(lastIndex);
    if(rest.length > 0) {
        tokens.push(rest);
    }
    return tokens;
}

/***
 * @param text
 */
function escapeRegExpChars(text: string) {
    // eslint-disable-next-line no-useless-escape
    return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
}

/***
 * Return the style that needs to be applied to the item selected from the drop-down
 * @param item
 */
const selectedItemStyles = (item): { background: string, backgroundColor: string} | undefined => {
    if(item && item.color) {
        return {
            background: "none",
            backgroundColor: item.color
        }
    }
};

/***
 * Single select drop-down component
 * @param props
 * @constructor
 */
export const SelectInput = (props: SelectInputType) => {
    const [activeItem, setActiveItem] = useState<itemType>(props.items[0]);
    const disableSelection: boolean =  props.disableSelection ?  true: false;
    // Using selectedValue, filter the item from items list and set as activeItem
    useEffect(() => {

        const filteredItems = props.items.filter(item => {
            return item.value === props.selectedValue
        });
        if(filteredItems.length) {
            setActiveItem(filteredItems[0]);
        }

    }, [props.items, props.selectedValue]);

    const buttonClasses = classNames(
        props.className,
        "selected-item-display-btn",
        activeItem && activeItem.className,
        { "icon-align-top": props.subText }
    );

    // Render function for each item in the list
    const renderItem = useCallback((item: itemType, { handleClick, modifiers, query }) => {
        const menuItemClasses = classNames("dropdown-item", { "with-icon": SelectedListItemStyles.TICK === props.selectedItemStyle });
        if(!modifiers.matchesPredicate) {
            return null;
        }

        return (
            <MenuItem
                icon={ renderIcons(item.icon, props.selectedItemStyle, modifiers) }
                active={ SelectedListItemStyles.TICK === props.selectedItemStyle ? false : modifiers.active }
                disabled={ modifiers.disabled }
                key={ item.value }
                onClick={ handleClick }
                text={ highlightText(item.label, query) }
                shouldDismissPopover={ false }
                className={ menuItemClasses }
            />
        );
    }, [props.selectedItemStyle]);
    let dropDownIcon = <Icon icon={ IconNames.CHEVRON_DOWN }/>
    if(disableSelection){
        dropDownIcon = <></>;
    }

    return (
        <SuggestInput
            itemPredicate={ filterItem }
            noResults={ <MenuItem disabled={ true } text="No results."/> }
            { ...props }
            filterable={ !!props.filterable }
            activeItem={ activeItem }
            itemRenderer={ renderItem }
            onActiveItemChange={ (item, isCreateNewItem) => {
                if(props.onActiveItemChange) {
                    props.onActiveItemChange(item, isCreateNewItem);
                }
            } }
            onItemSelect={ (item) => {
                setActiveItem(item);
                if(props.onItemSelect) {
                    props.onItemSelect(item);
                }
            } }
            inputProps={ {
                placeholder: props.placeholder || "Filter..."
            } }
            popoverProps={ {
                usePortal: false
            } }
            className="select-input"
            disabled={disableSelection}
        >
            <Button
                fill={ props.fill }
                data-testid="select-dropdown-button-display"
                className={ buttonClasses }
                style={ selectedItemStyles(activeItem) }
                rightIcon={ dropDownIcon }
            >
                { activeItem.label }
                {
                    props.subText &&
                    <React.Fragment>
                        <div className={ classNames(Classes.TEXT_SMALL, Classes.TEXT_DISABLED) }>
                            { props.subText }
                        </div>
                    </React.Fragment>
                }
            </Button>

        </SuggestInput>
    );
};
