/** This module contains the a control that allows you to select a date time range either
 *  using an inline control or a button that launches the control as a popup.
 *  @module
 */

import { useEffect, useState, useCallback } from "react";
import { TimePrecision } from "@blueprintjs/datetime";
import moment from "moment";
import { Button, Classes, Popover, MenuItem, IconName, Intent } from "@blueprintjs/core";
import { Icon, IconNames } from "@tir-ui/react-components";
import { STRINGS } from "app-strings";
import { TIME_OPTION, TIME_OPTION_VALUES } from "components/common/time-range-selector/TimeRangeSelector";
import { DURATION, durationToTimeRange, TIME_OBJECT, TIME_RANGE } from "utils/stores/GlobalTimeStore";
import { DURATION_SHOW_SCREEN_SIZE } from "../time-period-view-last/TimePeriodViewLast";
import { DateRangePicker3 } from "@blueprintjs/datetime2";
import "./TimePeriodMoreView.scss";

/** This interface defines the properties passed into the time range selected React component.*/
export interface TimePeriodMoreViewProps {
    /** a boolean value, if true show the calendar inline, if false show a ... button that launches the calenadr. */
    showInline?: boolean;
    /** if true show the controls side by side. */
    sideBySideLayout?: boolean;
    /** The time option can be an absolute start-to-end or a duration object. It is preferrable to
     * pass this as the input because based on the value here, we will both perform time range selection
     * in calendar and auto-selection of a duration menu item if applicable  */
    timeOption?: TIME_OBJECT;
    /** This is an absolute start-to-end time range that can be passed in to make selections
     * on the calendar & start and end time controls */
    timeRange?: TIME_RANGE;
    selectedOption?: TIME_OPTION;
    /** a boolean value, if true show the time period options like "last week", if false do not show them. */
    showTimePeriodOptions?: Array<TIME_OPTION>;
    durationSelectionHandler?: (
        selecteTimeOption: TIME_OPTION,
        timeRange?: TIME_RANGE
    ) => void;
    /** Callback that will be executed when the cancel button is clicked in the view.
     * Useful when displaying this content inline somewhere and handling cancel
     * operation from parent's end */
    onCancelClicked?: () => void;
    /** the icon that should be used for the button that is used to show the calendar popup. */
    moreIcon?: IconName;
    /** when inline the options appear and disappear based on screen size.  If this is true then the options 
     *  won't be filtered. */
    dontFilterOptionsBasedOnScreenSize?: boolean;
}

/** Renders the date/time range control.  This control allows the user to select a time range.
 *  @param props the properties passed in.
 *  @returns JSX with the date/time range control.*/
export function TimePeriodMoreView ({
    showInline = false,
    sideBySideLayout = false,
    timeOption,
    selectedOption = timeOption?.duration,
    timeRange = timeOption ? (timeOption.duration ? durationToTimeRange(timeOption.duration) as any : timeOption) : null,
    durationSelectionHandler,
    showTimePeriodOptions,
    onCancelClicked,
    moreIcon = IconNames.MORE,
    dontFilterOptionsBasedOnScreenSize = false
}: TimePeriodMoreViewProps) {
    const highlightMoreButton = selectedOption === TIME_OPTION_VALUES.CUSTOM;

    /** Custom timeRange */
    const [customTimeRange, setCustomTimeRange] = useState({
        startDate: timeRange?.startTime ? new Date(timeRange.startTime) : null,
        endDate: timeRange?.endTime ? new Date(timeRange.endTime) : null,
    });
    const [prevEndDate, setPrevEndDate] = useState(timeRange?.endTime ? new Date(timeRange.endTime) : null);

    const [timePeriodMorePopverOpen, setTimePeriodMorePopverOpen] = useState(false);
    const [disableApplyButton, setDisableApplyButton] = useState(false);
    const [selectedTimeOption, setSelectedTimeOption] = useState(
        selectedOption
    );

    /** Max DateRangePicker allowed Date.  */
    const maxDateValue = new Date(Date.now());

    /* Function closes Popover if clicked outside popover by setting isOpen false. */
    function handleClickOutside(e) {
        if (e) {
            setTimePeriodMorePopverOpen(false);
        }
    }

    /** Handle DateRangePicker date change */
    function handleDatePickerChange(selectedDates) {
        const newDateRange = {
            startDate: selectedDates[0],
            endDate: selectedDates[1],
        }
        // If user has clicked on an end date range now (i.e. completed selection of a time range) and either
        // the selected end date's time puts it before start time or if user is selecting a new range in which case
        // prevEndDate will be null when user clicks on a start date, set end date's time to 11:59 PM
        if (newDateRange.endDate !== null && (newDateRange.endDate <= newDateRange.startDate || prevEndDate === null)) {
            newDateRange.endDate = new Date(newDateRange.endDate.setHours(23,59));
        }
        setPrevEndDate(newDateRange.endDate);
        setSelectedTimeOption(TIME_OPTION_VALUES.CUSTOM);
        setCustomTimeRange(newDateRange);
    }
    /**
     * This function generates the end date based on the start date.
     * @param startDate : The start date that will be used to generate the end date
     * @returns  Date which is composed of date derived from startDate and time derived from previousEndDate.
     */
    const getGeneratedEndDate = useCallback((startDate: Date) => {
        let eDate: Date = moment(startDate).startOf('day').toDate();
        if (prevEndDate) {
            eDate = new Date(eDate.setHours(prevEndDate.getHours(), prevEndDate.getMinutes()));
        }
        if (eDate <= startDate) {
            eDate = new Date(eDate.setHours(23,59));
        }
        return eDate;
    }, [prevEndDate]);

    /** Disable ApplyButton if date range invalid. */
    useEffect(() => {
        if (customTimeRange.startDate) {
            let eDate: Date = customTimeRange.endDate ? customTimeRange.endDate : getGeneratedEndDate(customTimeRange.startDate);
            if (
                !customTimeRange.startDate ||
                !eDate ||
                eDate <= customTimeRange.startDate
            ) {
                setDisableApplyButton(true);
            } else {
                setDisableApplyButton(false);
            }
        } else {
            setDisableApplyButton(true);
        }
    }, [customTimeRange.startDate, customTimeRange.endDate, getGeneratedEndDate] );

    /** Handle custom time selection callback handler */
    function handleSelectCustomTimeOptions(time_period_option) {
        if (TIME_OPTION_VALUES.CUSTOM === time_period_option && durationSelectionHandler && customTimeRange.startDate) {
            // Following hack alows users to just pick one date as startDate & endDate. (A limitation of blueprint js, it requires users
            // to click the start date twice.)
            const computedEndDate = customTimeRange.endDate ? customTimeRange.endDate: getGeneratedEndDate(customTimeRange.startDate);
            const startTime = moment(customTimeRange.startDate).utc().valueOf();
            const endTime = moment(computedEndDate).utc().valueOf();
            if (startTime && endTime) {
                const result: TIME_RANGE = {
                    startTime: startTime,
                    endTime: endTime,
                };
                durationSelectionHandler(time_period_option, result);
            }
        }
    }

    /** Function submits custom selected date range and also validates date range. */
    function submitCustomDateRange(e) {
        setTimePeriodMorePopverOpen(false);
        handleSelectCustomTimeOptions(TIME_OPTION_VALUES.CUSTOM);
        e.stopPropagation();
    }

    /** Creates more drop down select options. */
    function createSelectOptions() {
        const tickMarkerIcon = <Icon icon={IconNames.TICK}/>;
        let selectOption;
        if (showTimePeriodOptions) {
            selectOption = Array.from(new Set(showTimePeriodOptions)).filter(key => key !== TIME_OPTION_VALUES.CUSTOM).map(
                (key) => {
                    const selected = selectedTimeOption === key;
                    return (
                        <MenuItem
                            key={key}
                            shouldDismissPopover={showInline === false}
                            aria-label={"time-period-more-" + (selected ? "selected" : "view") + "-" + key}
                            className={
                                !dontFilterOptionsBasedOnScreenSize && showInline === false && DURATION_SHOW_SCREEN_SIZE[key] ? ` d-${DURATION_SHOW_SCREEN_SIZE[key]}-none` : ""
                            }
                            active={selected}
                            onClick={() => {
                                if (durationSelectionHandler) {
                                    durationSelectionHandler(
                                        TIME_OPTION_VALUES[key]
                                    );
                                }
                                if (DURATION[key]) {
                                    const timeRange =  durationToTimeRange(DURATION[key]);
                                    setCustomTimeRange({
                                        startDate: new Date(timeRange.startTime),
                                        endDate: new Date(timeRange.endTime),
                                    });
                                }
                                setSelectedTimeOption(key);
                                setTimePeriodMorePopverOpen(false);
                            }}
                            text={
                                STRINGS.TIME_RANGE_SELECTOR[key] && STRINGS.TIME_RANGE_SELECTOR[key].displayText ?
                                STRINGS.TIME_RANGE_SELECTOR[key].displayText :
                                key
                            }
                            labelElement={selected ? tickMarkerIcon : ""}
                        />
                    );
                }
            );
        }
        
        const customTimeRangeSelected = selectedTimeOption === TIME_OPTION_VALUES.CUSTOM;
        const finalOptions = (
            <div className={"timePeriodMoreMenu text-nowrap p-0 h-max-4 " + Classes.MENU + (sideBySideLayout ? " d-flex" : "")}>
                {
                    sideBySideLayout ?
                    <div className="quick-time-selection-options border-end pe-2 pt-2 w-min-1-5">{selectOption}</div> :
                    selectOption
                }
                {
                    sideBySideLayout === false &&
                    <MenuItem
                        aria-label="time-period-more-view-selectDateRange"
                        key="Custom"
                        onClick={(e) => {
                            setSelectedTimeOption(TIME_OPTION_VALUES.CUSTOM);
                            setCustomTimeRange({
                                startDate: new Date(timeRange.startTime),
                                endDate: new Date(timeRange.endTime),
                            });
                            e.stopPropagation();
                        }}
                        text={STRINGS.TIME_RANGE_SELECTOR.custom}
                        active={customTimeRangeSelected}
                        labelElement={customTimeRangeSelected ? tickMarkerIcon : ""}
                    />
                }
                { (customTimeRangeSelected || sideBySideLayout) && (
                    <div
                        className="time-period-select-date-range"
                        aria-label="time-period-more-view-dateRangePicker"
                    >
                        { sideBySideLayout === false && <div className="time-period-horizontal-line" /> }

                        <DateRangePicker3
                            className="time-period-date-range-picker"
                            // Blueprint's date range picker is having a bug. Unless we force it to re-render by using a unique key,
                            // it's internal time picker's values are not getting updated when passed value changes
                            key={"key-" + customTimeRange?.startDate?.getTime() + "-" + customTimeRange?.endDate?.getTime()}
                            value={customTimeRange?.startDate ? [customTimeRange.startDate, customTimeRange?.endDate || null] : undefined}
                            onChange={(selectedDates) =>
                                handleDatePickerChange(selectedDates)
                            }
                            timePrecision={customTimeRange?.startDate ? TimePrecision.MINUTE : undefined}
                            singleMonthOnly={true}
                            maxDate={maxDateValue}
                            shortcuts={false}
                            allowSingleDayRange={true}
                            timePickerProps={customTimeRange?.startDate ? {
                                useAmPm: true,
                                selectAllOnFocus: true,
                                showArrowButtons: false,
                                defaultValue: customTimeRange?.startDate ? new Date(2022, 0, 1, 23, 59, 0) : undefined
                            } : undefined}
                        />
                        <div className="float-end">
                            <Button
                                type="button"
                                aria-label="time-period-more-view-cancel-button"
                                outlined
                                small
                                className={`m-2`}
                                onClick={(e) => {
                                    setTimePeriodMorePopverOpen(false);
                                    if (onCancelClicked) {
                                        onCancelClicked();
                                    }
                                    e.stopPropagation();
                                }}
                                text={STRINGS.TIME_RANGE_SELECTOR.cancel}
                            />
                            <Button
                                type="button"
                                aria-label="time-period-more-view-apply-button"
                                intent={Intent.PRIMARY}
                                small
                                className={`time-period-more-apply-button ${
                                    disableApplyButton && Classes.DISABLED
                                } m-2`}
                                onClick={(e) => submitCustomDateRange(e)}
                                text={STRINGS.TIME_RANGE_SELECTOR.apply}
                                disabled={disableApplyButton}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
        return finalOptions;
    }

    /** set correct time period selection on popover open. */
    function handelPopoverOpen(e) {
        if (selectedOption !== selectedTimeOption) {
            setSelectedTimeOption(selectedOption);
        }
        if (timeRange && selectedTimeOption === TIME_OPTION_VALUES.CUSTOM) {
            setCustomTimeRange({
                startDate: new Date(timeRange.startTime),
                endDate: new Date(timeRange.endTime),
            });
        }
        setTimePeriodMorePopverOpen(true);
        e.stopPropagation();
    }
    return (
        <div className="time-period-more-view">
            {
                showInline ?
                createSelectOptions() : <>
                <Popover
                    popoverClassName="time-period-more-view-popover"
                    content={<span className="time-period-more-view-popover-content">{createSelectOptions()}</span>}
                    position={"bottom-right"}
                    isOpen={timePeriodMorePopverOpen}
                    onClose={(e) => handleClickOutside(e)}
                >
                    <Button
                        text={STRINGS.TIME_RANGE_SELECTOR.dateRange}
                        intent={Intent.NONE}
                        outlined
                        aria-label="time-period-more-view-button"
                        minimal
                        small
                        className={`time-period-more-option-button ${timePeriodMorePopverOpen || highlightMoreButton
                                ? `time-period-more-button-selected ${Classes.ACTIVE}`
                                : ""
                            } ${highlightMoreButton ? "fw-bold" : ""}
                    `}
                        icon={moreIcon as IconName}
                        rightIcon={<Icon icon={IconNames.CARET_DOWN} />}
                        onClick={(e) => handelPopoverOpen(e)}
                    />
                </Popover>
            </>
            }
        </div>
    );
}
