query cloudIMRulesSearch($top: Int!, $skip: Int!, $count: Boolean!, $search: String, $searchFields: [CloudIMSearchRuleFieldEnum], $facets: [CloudIMSearchRuleRequestFacetType!], $filter: CloudIMSearchRuleFilterType, $order: [CloudIMSearchRuleSortInputType!]) {
    searchItems: cloudIMSearchRule(
        top: $top,
        skip: $skip,
        count: $count,
        facets: $facets,
        filter: $filter,
        order: $order,
        search: $search,
        searchFields: $searchFields
    ) {
        info {
            totalCount
            facets {
                key
                result {
                    count
                    items {
                        value
                        count
                    }
                }
            }
        }
        page {
            enabled
            user
            lastUpdated
            tags {
                rootElement
            }
            ruleName
            ruleId
            timestamp
            description
            ruleType {
                rootElement
            }
            metaData {
                rootElement
            }
        }
    }
}
