/** This module contains the a control that allows you to select a date time either
 *  using an inline control or a button that launches the control as a popup.
 *  @module
 */

import { useEffect, useState, useRef } from "react";
import { TimePrecision } from "@blueprintjs/datetime";
import { Button, Classes, Popover, InputGroup, type PopoverPosition, Intent } from "@blueprintjs/core";
import { IconNames } from "@tir-ui/react-components";
import { STRINGS } from "app-strings";
import { TIME_FORMAT } from "components/enums/Time.ts";
import { formatToLocalTimestamp } from "reporting-infrastructure/utils/formatters/GeneralFormatter.ts";
import { DatePicker3 } from "@blueprintjs/datetime2";
import "./DateTimeSelector.scss";

/** This interface defines the properties passed into the date/time selector React component.*/
export interface DateTimeSelectorProps {
    /** a boolean value, if true show the calendar inline, if false show a ... button that launches the calenadr. */
    showInline?: boolean;
    /** a boolean value if true show the more button when the control is not inline. */
    showMoreButton?: boolean;
    /** This is the initial time in milliseconds that is used to make selections on the calendar and time controls.  
     *  In controlled mode this is not used. */
    time?: number;
    /** when the controlled time is set then this component is controled and will always display the controlled time. */
    controlledTime?: number;
    /** CSS style class that should be applied to the outermost element of this component */
    className?: string;
    /** the handler for time change events.  */
    timeSelectionHandler?: (time?: number) => void;
    /** Callback that will be executed when the cancel button is clicked in the view.
     * Useful when displaying this content inline somewhere and handling cancel
     * operation from parent's end */
    onCancelClicked?: () => void;
    /** Flag to position the calendar icon to the right or left */
    iconRight?: boolean;
    /** Position of the popover */
    position?: PopoverPosition;
    /** **Disables** input, calendar icon and clear icon if ***true*** */
    disableControl?: boolean;
}

/** Renders the date/time range control.  This control allows the user to select a time range.
 *  @param props the properties passed in.
 *  @returns JSX with the date/time range control.*/
 export function DateTimeSelector (props: DateTimeSelectorProps) {
    const {showInline = false, time, controlledTime, timeSelectionHandler, onCancelClicked, className = "", showMoreButton, iconRight, position = "bottom-right", disableControl=false} = props;

    /** Custom timeRange */
    const prevailingTime = controlledTime || time;
    const [customTime, setCustomTime] = useState<Date | null>(prevailingTime ? new Date(prevailingTime) : new Date());
    const [appliedTime, setAppliedTime] = useState<Date | null>(prevailingTime ? new Date(prevailingTime) : null);

    const cachedControlledTime = useRef<number | undefined>(controlledTime);
    useEffect(() => {
        if (cachedControlledTime.current !== controlledTime) {
            setCustomTime(controlledTime ? new Date(controlledTime) : new Date());
            setAppliedTime(controlledTime ? new Date(controlledTime) : null);
            cachedControlledTime.current = controlledTime;    
        }
    }, [controlledTime]);

    const [timePeriodMorePopverOpen, setTimePeriodMorePopverOpen] = useState(false);
    const [disableApplyButton, setDisableApplyButton] = useState(false);

    /** Max DateRangePicker allowed Date.  */
    const maxDateValue = new Date(Date.now());

    /* Function closes Popover if clicked outside popover by setting isOpen false. */
    function handleClickOutside(e) {
        if (e) {
            setTimePeriodMorePopverOpen(false);
        }
    }

    /** Handle DatePicker date/time change */
    function handleDatePickerChange(dateTime) {
        setCustomTime(dateTime);
    }

    /** Disable ApplyButton if date invalid. */
    useEffect(() => {
        if (customTime && customTime.getTime() < new Date().getTime()) {
            setDisableApplyButton(false);
        } else {
            setDisableApplyButton(true);
        }
    }, [customTime] );

    /** Function submits custom selected date range and also validates date range. */
    function submitCustomDateRange(e) {
        setTimePeriodMorePopverOpen(false);
        if (timeSelectionHandler) {
            timeSelectionHandler(customTime?.getTime());
            setAppliedTime(customTime);
        }
        e.stopPropagation();
    }

    /** Function clears the date range. */
    function clearCustomDataRange(e) {
        setTimePeriodMorePopverOpen(false);
        setAppliedTime(null);
        if (timeSelectionHandler) {
            timeSelectionHandler(undefined);
        }
    }

    /** Creates more drop down select options. */
    function createSelectOptions() {
        const finalOptions = (
            <div className={"date-time-selector-more-menu text-nowrap p-0 h-max-4 d-flex " + Classes.MENU}>
                <div className="date-time-selector-date-picker" aria-label="date-time-selector-date-picker">
                    <DatePicker3
                        className="date-time-selector-date-control"
                        // Blueprint's date range picker is having a bug. Unless we force it to re-render by using a unique key,
                        // it's internal time picker's values are not getting updated when passed value changes
                        key={"key-" + (time ? time:  new Date().getTime())}
                        value={customTime ? new Date(customTime) : undefined}
                        onChange={(selectedDates) =>
                            handleDatePickerChange(selectedDates)
                        }
                        timePrecision={TimePrecision.MINUTE}
                        maxDate={maxDateValue}
                        shortcuts={false}
                        timePickerProps={{
                            useAmPm: true,
                            selectAllOnFocus: true,
                            showArrowButtons: false,
                            defaultValue: new Date(2022, 0, 1, 23, 59, 0)
                        }}
                    />
                    <div className="float-end">
                        <Button aria-label="date-time-selector-cancel-button" outlined minimal small
                            className={`m-2`} 
                            text={STRINGS.TIME_RANGE_SELECTOR.cancel}
                            onClick={(e) => {
                                setTimePeriodMorePopverOpen(false);
                                if (onCancelClicked) {
                                    onCancelClicked();
                                }
                                e.stopPropagation();
                            }}
                        />
                        <Button aria-label="date-time-selector-apply-button" intent={Intent.PRIMARY} minimal small 
                            className={`date-time-selector-apply-button ${disableApplyButton && Classes.DISABLED} m-2`}
                            onClick={(e) => submitCustomDateRange(e)}
                            text={STRINGS.TIME_RANGE_SELECTOR.apply}
                            disabled={disableApplyButton}
                        />
                    </div>
                </div>
            </div>
        );
        return finalOptions;
    }

    /** set correct time period selection on popover open. */
    function handelPopoverOpen(e) {
        setTimePeriodMorePopverOpen(true);
        e.stopPropagation();
    }

    return (
        <div className={"date-time-selector " + className}>
            {
                showInline ?
                createSelectOptions() : <>
                <Popover
                    popoverClassName="date-time-selector-popover"
                    content={createSelectOptions()}
                    position={position}
                    isOpen={timePeriodMorePopverOpen}
                    onClose={(e) => handleClickOutside(e)}
                >
                {!showMoreButton ?
                        <InputGroup 
                            disabled={disableControl}
                            value={appliedTime ? formatToLocalTimestamp(appliedTime, TIME_FORMAT.DISPLAY_TIME_FORMAT) : STRINGS.viewRunbooks.inputEndTimeNowText} 
                            onClick={(e) => handelPopoverOpen(e)} 
                        onChange={() => { }}
                            leftElement={!iconRight ?
                                <Button aria-label="date-selector-clear-button" icon={IconNames.CALENDAR} minimal
                                    className="date-selector-clear-icon" 
                                disabled={false} onClick={(e) => { handelPopoverOpen(e) }}
                            /> : <></>}
                            rightElement={iconRight ?
                                <Button aria-label="date-selector-clear-button" icon={IconNames.CALENDAR} minimal
                                    className="date-selector-clear-icon" 
                                disabled={disableControl} onClick={(e) => { handelPopoverOpen(e) }}
                            /> : <></>}
                    /> :
                    <Button
                            aria-label="date-time-selector-button"
                        minimal
                            small
                        className={`date-time-selector-option-button date-time-selector-more-button-selected fw-bold`}
                            icon={IconNames.MORE}
                            onClick={(e) => handelPopoverOpen(e)}
                />

                }
                </Popover>
                <Button aria-label="date-selector-clear-button" icon={IconNames.DELETE} minimal 
                    className="date-selector-clear-icon" 
                    disabled={disableControl}
                    onClick={(e) => {clearCustomDataRange(e)}} 
                />
                </>
            }
        </div>
    );
}
