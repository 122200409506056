import React from 'react';
import { FormGroup, ITextAreaProps, TextArea } from '@blueprintjs/core';
import { useField } from 'formik';
import { FieldError } from "./FieldError";

export type TextAreaFieldProps = {
    label?: string
    helperText?: string
    inline?: boolean
    name: string
    errorFieldName?: string
} & ITextAreaProps

function TextAreaField({ label, required, helperText, inline, errorFieldName, ...props }: TextAreaFieldProps) {

    const [field, meta] = useField(props);

    return (
        <FormGroup
            label={ label }
            labelFor={ field.name }
            inline={ inline }
            helperText={ helperText }
            labelInfo={required ? '* ' : ''}>
            <TextArea
                fill
                id={ field.name }
                { ...field }
                intent={ meta.touched && meta.error ? 'danger' : 'none' }
                { ...props }
            />
            <br/>
            <FieldError name={ errorFieldName || field.name }/>

        </FormGroup>
    );
}

export { TextAreaField };
