mutation createApplicationGroup($entityIds: [UUID!]!) {
    createApplicationGroup(input: { entityIds: $entityIds }) {
        id
        name
        applications {
            id
            name
            type
            reportedBy {
                entityId
                dataSourceId
                dataSourceType
            }
            customProperties {
                id
                name
                valueId
                valueName
            }
        }
    }
}
