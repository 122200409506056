/** This module is used as a replacement for the IncidentSearchPage.tsx
 * The IncidentSearchPage has parts built on top of it, handled by conditional statements.
 * This is a way to seperate those conditional statements and have a component that doesn't rely
 * on the type of page we're targetting.
 *  @module */
import React, { useRef, useState, useEffect, useCallback, useMemo } from "react";
import { isEqual, cloneDeep } from 'lodash';
import { STRINGS } from "app-strings";
import { PARAM_NAME } from "components/enums/QueryParams.ts";
import { useQueryParams, setQueryParam } from "utils/hooks/useQueryParams.ts";
import { SIZE } from 'components/enums/Sizes.ts';
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade.tsx";
import { TwoColumnContainer } from "components/common/layout/containers/two-column-container/TwoColumnContainer.tsx";
import { BasicDialog, updateDialogState } from "components/common/basic-dialog/BasicDialog.tsx";
import { WrapInTooltip } from 'components/common/wrap-in-tooltip/WrapInTooltip.tsx';
import { Button, Intent } from "@blueprintjs/core";
import { IconNames, useStateSafePromise } from "@tir-ui/react-components";
import { useApolloClient } from "@apollo/client";
import { SearchGraphqlApiService } from "utils/services/search/SearchGraphqlApiService.ts";
import { SearchCorrelationService } from "utils/services/SearchCorrelationApiService.ts";
import { Facet, FacetSummary, FacetValue, ORDERS, SearchItem, SearchRequest, SearchResult } from "utils/services/SearchApiService.ts";
import { DEFAULT_FACET_LIMIT, SEARCH_TYPE, SearchStats, SearchParams, getSelectedFacetsFromQueryParams, createSearchRequest, getFacet, mergeGroupedFacets, getFacetQueryParam } from "components/reporting/facet/FacetUtils.ts";
import { DEFAULT_SEARCH_PREF, FACET_MODE, SEARCH_ENGINE, SearchPreference } from "utils/services/UserPrefsTypes.ts";
import { FacetSettings } from "pages/incident-search/views/facet/FacetSectionSettings.tsx";
import FacetView from "pages/incident-search/views/facet/FacetView.tsx";
import PaginationControl from "pages/incident-search/PaginationControl.tsx";
import SearchControl from "pages/incident-search/SearchControl.tsx";

import "./FacetPanel.scss";

export interface FacetPanelChildrenProps {
    dataPoints: Array<any>;
}

export interface FacetPanelProps {
    searchType: SEARCH_TYPE;
    searchPreference?: SearchPreference;
    className?: string;
    children: ((props: FacetPanelChildrenProps) => React.ReactElement);
}

/** Renders the Facet Panel Component.
 *  @param searchType what query/page are we targetting?
 *  @param className className for the component (Optional)
 *  @param children JSX of the children, passing in the resulting query
 *  @returns JSX with the FacetPanel.*/
const FacetPanel = ({ searchType, searchPreference, className, children }: FacetPanelProps) => {
    const { params, setQueryParams } = useQueryParams({
        listenOnlyTo: [
            PARAM_NAME.searchText, PARAM_NAME.facets, PARAM_NAME.groupedFacets, PARAM_NAME.pageSize
        ]
    });

    const searchPreferences: SearchPreference = { ...DEFAULT_SEARCH_PREF, ...searchPreference };
    /** this is the maximum number of search results to load through pagination. */
    const MAX_LOADED_SEARCH_RESULTS = searchPreferences.maxResultLimit || 1000;

    const initSearchParams: SearchParams = {
        runInitialSearchRequest: false, searchType: searchType, searchText: "", timeRange: undefined, selectedFacets: {},
        sortColumn: undefined, sortOrder: undefined, pageSize: undefined, paginatedSearchRequest: undefined
    };
    const [searchParams, setSearchParamsState] = useState<SearchParams>(initSearchParams);

    const searchText = params[PARAM_NAME.searchText] ? params[PARAM_NAME.searchText] : "";

    // When the selected facets change, update the facet cache and run the cognitive search
    const selectedFacets = useRef<Record<string, Array<FacetValue>>>({});
    const newSelectedFacets = getSelectedFacetsFromQueryParams(
        params[PARAM_NAME.facets],
        params[PARAM_NAME.groupedFacets],
    );
    if (!isEqual(selectedFacets.current, newSelectedFacets)) {
        selectedFacets.current = cloneDeep(newSelectedFacets);
    }

    const [dialogState, setDialogState] = useState<any>({
        showDialog: false,
        loading: false,
        title: "",
        dialogContent: null,
        dialogFooter: null,
    });

    const pageSize: number | undefined = params[PARAM_NAME.pageSize] ? parseInt(params[PARAM_NAME.pageSize]) : undefined;

    const pageIndex = useRef<number>(0);
    const searchResultsByPage = useRef<any[]>([]);

    const [searchResults, setSearchResultsState] = useState<any>({});
    const searchResultsCache = useRef<any>({});
    const facetCache = useRef<Record<string, Array<Facet>>>({});
    const [limitsByFacetCategory, setLimitsByFacetCategory] = useState<Record<string, number>>({});
    const [executeSafely] = useStateSafePromise();

    const searchStats = useRef<SearchStats>({ truncatedAt: Number.MAX_SAFE_INTEGER, totalCount: 0, subCount: 0 });
    const loading = useRef<boolean>(true);

    // This key is used to get the values out of the search result
    const valueKey: string = "items";

    const data = useMemo(() => {
        if (!searchPreferences.serverSideSortingAndPagination) {
            return searchResults?.items || [];
        } else {
            return searchResultsByPage.current?.length > pageIndex.current ? searchResultsByPage.current[pageIndex.current].items : [];
        }
    }, [searchResults, searchPreferences.serverSideSortingAndPagination]);

    function setSearchResults(searchResults: any): void {
        setSearchResultsState(searchResults);
        searchResultsCache.current = searchResults;
    }

    const apolloClient = useApolloClient();
    const searchGraphqlService = useRef<SearchGraphqlApiService>(new SearchGraphqlApiService(apolloClient));

    // This is the last search request to be run
    const searchRequest = useRef<SearchRequest | undefined>();
    // This is the search request that the facets should use to run their search queries.
    // We remove the other facets from the filter of this search request.  The reason why
    // we do this is because we always show all the facets that existed after we run a
    // search request for the searchText and time range.  As more facets are selected the
    // other facets disappear, but we keep them and set their counts to 0 so the user
    // still sees all the facets that can bring back data.
    const facetSearchRequest = useRef<SearchRequest | undefined>();
    // This keeps track of the current search request, if you are processing a result and this number has changed
    // there is already a new search request that has been sent and we can disregard the results from the previous
    // search.  Right now we are only using this to determine whether to continue with pagination, but we can use
    // this for more in the future
    const searchSequence = useRef<number>(0);

    const runCognitiveSearch = useCallback(
        (searchParams: SearchParams) => {
            const sequenceNumber = ++searchSequence.current;
            loading.current = true;
            searchRequest.current = searchParams.paginatedSearchRequest ? searchParams.paginatedSearchRequest : createSearchRequest(
                searchParams.searchText, limitsByFacetCategory, searchParams.timeRange, !searchParams.runInitialSearchRequest ? searchParams.selectedFacets : {}, searchParams.searchType,
                searchParams.pageSize, undefined, searchParams.sortColumn, searchParams.sortOrder
            );
            facetSearchRequest.current = createSearchRequest(
                searchParams.searchText, limitsByFacetCategory, searchParams.timeRange, {}, searchParams.searchType,
                searchParams.pageSize, undefined, searchParams.sortColumn, searchParams.sortOrder
            );

            let searchService: { search: (searchRequest: SearchRequest) => Promise<SearchResult<SearchItem>> } | null = null;
            switch (searchPreferences.srchEngine) {
                case SEARCH_ENGINE.correlation_direct:
                    searchService = SearchCorrelationService;
                    break;
                case SEARCH_ENGINE.correlation_dal:
                    searchService = searchGraphqlService.current;
                    break;
            }
            return executeSafely(searchService!.search(searchRequest.current)).then((searchResult: SearchResult<SearchItem>) => {
                loading.current = false;
                if (searchResult) {
                    if (sequenceNumber !== searchSequence.current) {
                        // We might have launched a request and the search request might have changed while this was running
                        return;
                    }

                    if (Object.keys(facetCache.current).length === 0 || searchPreferences.facetMode === FACET_MODE.replace) {
                        // We have no previous search results
                        searchStats.current.totalCount = (searchResult.count) || 0;
                        if (searchPreferences.serverSideSortingAndPagination && !searchParams.paginatedSearchRequest) {
                            searchResultsByPage.current = Array(Math.ceil(searchStats.current.totalCount / (searchParams.pageSize || 1000)));
                        }
                    }

                    // Update the facet cache with the current set of facets adding any new facets and updating existings counts.
                    const facetResults = searchResult.facets || {};
                    for (const category in facetResults) {
                        if (!facetCache.current[category]) {
                            facetCache.current[category] = [];
                        }
                        const facets: Facet[] = (facetResults[category] as FacetSummary).items || [];
                        for (const facet of facets) {
                            const cachedFacet = getFacet(category, facet.value, facetCache.current);
                            if (cachedFacet) {
                                cachedFacet.count = facet.count;
                            } else {
                                facetCache.current[category].push({ ...facet });
                            }
                        }
                    }

                    // ensure that 'isGroupedFacet' is appended to dynamic
                    // facets
                    mergeGroupedFacets(facetCache, facetResults);

                    // Set every count to zero in the cache if it is no longer returned by search
                    for (const category in facetCache.current) {
                        for (const facet of facetCache.current[category]) {
                            let checkFacet: Facet | undefined = getFacet(category, facet.value, facetResults, true);
                            if (!checkFacet) {
                                facet.count = 0;
                            }
                        }
                    }

                    // Calculate the subcount
                    if (Object.keys(facetCache.current).length !== 0) {
                        // We have no previous search results
                        searchStats.current.subCount = searchResult.count || 0;
                        if (searchPreferences.serverSideSortingAndPagination && !searchParams.paginatedSearchRequest) {
                            searchResultsByPage.current = Array(Math.ceil(searchStats.current.subCount / (searchParams.pageSize || 1000)));
                        }
                    }

                    // Update facet selections
                    for (const category in facetCache.current) {
                        for (const facet of facetCache.current[category]) {
                            const shouldSelect = Boolean(selectedFacets.current[category]?.includes(facet.value));
                            if (shouldSelect !== Boolean(facet.selected)) {
                                facet.selected = shouldSelect;
                            }
                        }
                    }

                    // Take any facets that are currently selected and make sure they are in the cache so they are 
                    // displayed
                    for (const facetCategory in selectedFacets.current) {
                        if (!facetCache.current[facetCategory]) {
                            facetCache.current[facetCategory] = [];
                        }
                        const selectedValues = selectedFacets.current[facetCategory];
                        for (const value of selectedValues) {
                            const cachedFacet = getFacet(facetCategory, value, facetCache.current);
                            if (!cachedFacet) {
                                facetCache.current[facetCategory].push({ value, selected: true, count: 0 });
                            }
                        }
                    }

                    // The PMs would like some of the categories with known values always to appear.  Use the 
                    // Strings file to figure this out
                    for (const facetCategory in STRINGS.incidentSearch.facetView.facets) {
                        const facetInfo = STRINGS.incidentSearch.facetView.facets[facetCategory];
                        if (
                            facetInfo.searchSystem &&
                            (facetInfo.searchSystem !== searchPreferences.srchEngine)
                        ) {
                            continue;
                        }
                        if (facetInfo.type === searchParams.searchType && facetInfo.values) {
                            if (!facetCache.current[facetCategory]) {
                                facetCache.current[facetCategory] = [];
                            }
                            const selectedValues = facetInfo.values;
                            for (const value of selectedValues) {
                                const cachedFacet = getFacet(facetCategory, value, facetCache.current);
                                if (!cachedFacet) {
                                    facetCache.current[facetCategory].push({ value, selected: false, count: 0 });
                                }
                            }
                        }
                    }

                    (searchResult as any).type = searchParams.searchType;

                    if (searchParams.paginatedSearchRequest) {
                        // We have paginated results
                        if (!searchPreferences.serverSideSortingAndPagination) {
                            searchResult = { ...searchResult, [valueKey]: [...searchResultsCache.current[valueKey], ...(searchResult[valueKey] || [])] };
                        }
                        searchParams.paginatedSearchRequest = undefined;
                        setSearchParamsState(searchParams);
                    }

                    if (searchPreferences.serverSideSortingAndPagination) {
                        searchResultsByPage.current[pageIndex.current] = searchResult;
                    }

                    const resultCount: number = searchResult.count || 0;
                    searchStats.current.truncatedAt = resultCount > searchResult[valueKey]?.length ? searchResult[valueKey]?.length || 0 : Number.MAX_SAFE_INTEGER;

                    setSearchResults(searchResult);

                    if (searchParams.runInitialSearchRequest) {
                        // The selected facets indicate that this is a request from a URL change and we just did the 
                        // first query to get the search set and now we need to query the selected facets.  We need 
                        // to add any facets that might have been in the URL but were not found by the first query, so
                        // there is a URL with a list of selected facets, but those facets are not in the facet cache
                        // that was created after doing the first search query
                        loading.current = true;
                        setSearchParamsState({ ...searchParams, runInitialSearchRequest: false });
                    } else if (!searchPreferences.serverSideSortingAndPagination) {
                        /* istanbul ignore next */
                        const numResults = searchResult[valueKey]?.length || 0;
                        if (searchResult["@search.nextPageParameters"]) {
                            // We have more search results that have been paginated by the server
                            if (numResults < MAX_LOADED_SEARCH_RESULTS) {
                                setSearchParamsState({ ...searchParams, paginatedSearchRequest: searchResult["@search.nextPageParameters"] });
                            } else {
                                searchParams.paginatedSearchRequest = undefined;
                                setSearchParamsState(searchParams);
                            }
                        } else if (numResults < resultCount) {
                            if (numResults < MAX_LOADED_SEARCH_RESULTS) {
                                // Calculate the new top value, it should either be the page size or the remaining items count whichever is smaller
                                const topLimit: number = Math.min(MAX_LOADED_SEARCH_RESULTS - numResults, searchParams.pageSize || 1000);
                                const newPaginatedSearchRequest: SearchRequest = { ...(searchRequest.current as SearchRequest), top: topLimit, skip: numResults };
                                setSearchParamsState({ ...searchParams, paginatedSearchRequest: newPaginatedSearchRequest });
                            } else {
                                searchParams.paginatedSearchRequest = undefined;
                                setSearchParamsState(searchParams);
                            }
                        }
                    }
                }
            }, error => {
                // If there is an error, remove the loading animation and show the error dialog.
                loading.current = false;
                setDialogState({
                    showDialog: true,
                    loading: false,
                    title: STRINGS.incidentSearch.errorDialogTitle,
                    dialogContent: <span>{STRINGS.incidentSearch.errorDialogText}</span>,
                    dialogFooter: <>
                        <Button active={true} outlined={true}
                            text={STRINGS.primaryIndicatorView.okBtnText}
                            onClick={async (evt: any) => {
                                setDialogState(updateDialogState(dialogState, false, false, []))
                            }}
                        />
                    </>
                });

                if (error?.current) {
                    error.current = true;
                }
                console.error(error);
            });
        },
        [
            executeSafely, limitsByFacetCategory, MAX_LOADED_SEARCH_RESULTS,
            searchPreferences.facetMode, searchPreferences.serverSideSortingAndPagination,
            searchPreferences.srchEngine, dialogState, valueKey
        ]
    );

    const previousSearchText = useRef<string>("");
    const previousSearchType = useRef<SEARCH_TYPE>(searchType);

    const [showFacetView, setShowFacetView] = useState<boolean>(true);

    const prevSearchParams = useRef<SearchParams>(searchParams);
    useEffect(
        () => {
            if (searchParams !== prevSearchParams.current) {
                // Whenever search params changes run cognitive search
                prevSearchParams.current = searchParams;
                runCognitiveSearch(searchParams);
            }
        },
        [searchParams, runCognitiveSearch]
    )

    const hasInitialSearchRequest = useRef<boolean>(false);

    // When either the search text changes or the facets change re-run the search
    useEffect(
        () => {
            if (previousSearchType.current !== searchType) {
                // The search type has changed, clear out the facets and the pagination
                hasInitialSearchRequest.current = false;
                facetCache.current = {};
            }

            if (previousSearchType.current !== searchType || previousSearchText.current !== searchText) {
                // The search text has changed so we are doing a new search from the 
                // begining, update the facet cache
                hasInitialSearchRequest.current = false;
                facetCache.current = {};
                searchStats.current.totalCount = 0;
                searchStats.current.subCount = 0;

                previousSearchText.current = searchText;
                previousSearchType.current = searchType;
                setSearchResults({});
            }

            // Set the loading flag
            loading.current = true;

            if (searchPreferences.facetMode === FACET_MODE.replace) {
                // In replace mode we are always clearing the facets because we are replacing them
                facetCache.current = {};
            }

            pageIndex.current = 0;
            searchResultsByPage.current = [];

            // Update the search params, if we are initialized and something has changed
            const runInitialSearchRequest = !hasInitialSearchRequest.current && searchPreferences.facetMode !== FACET_MODE.replace;
            hasInitialSearchRequest.current = true;
            setSearchParamsState({
                runInitialSearchRequest: runInitialSearchRequest, searchType: searchType, searchText: searchText, timeRange: undefined,
                selectedFacets: selectedFacets.current, sortColumn: undefined, sortOrder: ORDERS.desc,
                pageSize: pageSize || searchPreferences.pageSize || 1000, paginatedSearchRequest: undefined
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [searchType, searchText, selectedFacets.current, limitsByFacetCategory, pageSize]
    );

    // Functions
    const onFacetSelectedHandler = (
        facetValue: FacetValue,
        facetCategory: string,
        selected: boolean,
    ) => {
        if (facetCache.current[facetCategory]) {
            const facets = facetCache.current[facetCategory];
            for (const facet of facets) {
                if (facet.value === facetValue) {
                    facet.selected = selected;
                    // Set the facets and leave the current search string, causing the component to re-render
                    setQueryParam(
                        facet.isGroupedFacet ? PARAM_NAME.groupedFacets : PARAM_NAME.facets,
                        getFacetQueryParam(facetCache.current, facet.isGroupedFacet || false),
                        true,
                    );
                    break;
                }
            }
        }
    };

    const onFilterSelectionMadeHandler = (selections) => {
        for (const facetCategory in facetCache.current) {
            for (const facet of facetCache.current[facetCategory]) {
                facet.selected =
                    selections &&
                    selections[facetCategory] &&
                    selections[facetCategory].includes(facet.value);
            }
        }
        setQueryParams(
            {
                [PARAM_NAME.facets]: getFacetQueryParam(facetCache.current, false),
                [PARAM_NAME.groupedFacets]: getFacetQueryParam(facetCache.current, true)
            },
            true,
        );
    };

    const onFacetCategorySelectedHandler = (
        facetCategory: string,
        selected: boolean,
    ) => {
        if (facetCache.current[facetCategory]) {
            const facets = facetCache.current[facetCategory];

            let isGrouped = false;
            for (const facet of facets) {
                facet.selected = selected;
                isGrouped = facet.isGroupedFacet || false;
            }
            // Set the facets and leave the current search string, causing the component to re-render
            setQueryParam(
                isGrouped ? PARAM_NAME.groupedFacets : PARAM_NAME.facets,
                getFacetQueryParam(facetCache.current, isGrouped),
                true,
            );
        }
    };

    const onFacetSettingsChangedHandler = (
        facetCategory: string,
        facetSettings: FacetSettings,
    ) => {
        const newLimitsByFacetCategory: Record<string, number> = {
            ...limitsByFacetCategory,
        };
        newLimitsByFacetCategory[facetCategory] =
            facetSettings.facetCategoryLimit;
        if (facetSettings.selectedFacets) {
            // If this is set then the selections are being passed
            if (facetCache.current[facetCategory]) {
                const facets: Facet[] = facetCache.current[facetCategory];
                let isGrouped = false;
                for (const facet of facets) {
                    facet.selected = false;
                    isGrouped = facet.isGroupedFacet || false;
                }
                for (const selectedFacet of facetSettings.selectedFacets) {
                    const cachedFacet = getFacet(
                        facetCategory,
                        selectedFacet.value,
                        facetCache.current,
                    );
                    if (cachedFacet) {
                        cachedFacet.selected = true;
                    } else {
                        facetCache.current[facetCategory].push({
                            ...selectedFacet,
                            selected: true,
                        });
                        isGrouped = selectedFacet.isGroupedFacet || false;
                    }
                }
                // Set the facets and leave the current search string, causing the component to re-render
                setQueryParam(
                    isGrouped ? PARAM_NAME.groupedFacets : PARAM_NAME.facets,
                    getFacetQueryParam(facetCache.current, isGrouped),
                    true,
                );
            }
        }
        setLimitsByFacetCategory(newLimitsByFacetCategory);
    };

    const clearAllFiltersHandler = () => {
        for (const facetCategory in facetCache.current) {
            const facets = facetCache.current[facetCategory];
            for (const facet of facets) {
                facet.selected = false;
            }
        }
        setQueryParams(
            {
                [PARAM_NAME.facets]: getFacetQueryParam(facetCache.current, false),
                [PARAM_NAME.groupedFacets]: getFacetQueryParam(facetCache.current, true),
                [PARAM_NAME.searchTime]: null,
            },
            true,
        );
    };

    return <>
        <BasicDialog
            dialogState={dialogState}
            className="facet-dialog"
            onClose={() =>
                setDialogState(updateDialogState(dialogState, false, false, []))
            }
        />
        <DataLoadFacade
            loading={loading.current}
            error={undefined /*data.error*/}
            data={undefined /*data.data*/}
            showContentsWhenLoading={true}
            className={"h-100 facet-facade" + (className ? " " + className : "")}
        >
            <TwoColumnContainer
                hideLeftColumn={!showFacetView}
                firstColumnSize={SIZE.s}
                noPaddingOnFirstColumn
                noPaddingOnSecondColumn
                doNotSetColumnWidth={false}
                className="facet-column-container"
            >
                <FacetView
                    className="facet-left-view p-2"
                    facets={JSON.parse(JSON.stringify(facetCache.current))}
                    customProperties={[]}
                    searchType={searchType}
                    limitsByFacetCategory={limitsByFacetCategory}
                    defaultLimit={DEFAULT_FACET_LIMIT}
                    searchRequest={searchRequest.current}
                    searchRequestWithNoFacets={facetSearchRequest.current}
                    searchText={searchText}
                    timeRange={undefined}
                    showQueryControl={false}
                    sortSelectedFacetsFirst={searchPreferences.sortFacets}
                    onFacetSelected={(facetValue, facetCategory, selected) => {
                        onFacetSelectedHandler(facetValue, facetCategory, selected);
                    }}
                    onFilterSelectionMade={(selections) => {
                        onFilterSelectionMadeHandler(selections);
                    }}
                    onFacetCategorySelected={(facetCategory, selected) => {
                        onFacetCategorySelectedHandler(facetCategory, selected);
                    }}
                    onFacetSettingsChanged={(
                        facetCategory: string,
                        facetSettings: FacetSettings,
                    ) => {
                        onFacetSettingsChangedHandler(facetCategory, facetSettings);
                    }}
                    searchQueries={undefined}
                    querySaveRequested={undefined}
                    queryDeleteRequested={undefined}
                    loadQuery={undefined}
                    clearAllFilters={() => clearAllFiltersHandler()}
                />
                <div className="facet-right-view">
                    <div className={"facet-search-header d-flex flex-wrap pt-3 pb-2 ps-2"}>
                        <WrapInTooltip tooltip={STRINGS.facetView.menu.showHideMenu}>
                            <Button
                                icon={showFacetView ? IconNames.DOUBLE_CHEVRON_LEFT : IconNames.DOUBLE_CHEVRON_RIGHT}
                                className={"filter-toggle-button me-3"}
                                intent={Intent.NONE}
                                onClick={() => {
                                    setShowFacetView(!showFacetView);
                                }}
                            />
                        </WrapInTooltip>
                        <div>
                            <SearchControl
                                searchText={searchText}
                                placeholder={STRINGS.facetView.facetSearch.placeholder}
                                onChange={(newSearchText: string) => {
                                    // Set the search text, causing the component to re-render
                                    setQueryParams({ [PARAM_NAME.searchText]: newSearchText || "" }, true);
                                }}
                            />
                            <div className="display-9 mt-1 search-result-count">
                                {searchPreferences.facetMode !== FACET_MODE.replace && searchStats.current.subCount !== undefined &&
                                    (<>
                                        <span className="font-weight-normal">
                                            {searchStats.current.subCount}
                                        </span>
                                        {` ${STRINGS.facetView.facetSearch.outOf} `}
                                    </>)}
                                <span className="font-weight-normal">
                                    {searchStats.current.totalCount}
                                </span>{" "}
                                {STRINGS.SEARCH.results}
                                {!searchPreferences.serverSideSortingAndPagination && searchStats.current.truncatedAt !== Number.MAX_SAFE_INTEGER &&
                                    (<>
                                        {` (${STRINGS.facetView.facetSearch.truncatedAt} `}
                                        <span className="font-weight-normal">
                                            {searchStats.current.truncatedAt}
                                        </span>
                                        {` ${STRINGS.facetView.facetSearch.result})`}
                                    </>)}
                            </div>
                        </div>
                    </div>
                    <div className="facet-data-view">
                        {children({ dataPoints: data })}
                    </div>
                    {searchPreferences.serverSideSortingAndPagination && (
                        <div className="facet-pagination p-3">
                            <PaginationControl
                                pageIndex={pageIndex.current}
                                numberOfPages={Math.ceil(
                                    (searchStats.current.subCount > 0
                                        ? searchStats.current.subCount
                                        : searchStats.current.totalCount) /
                                    (pageSize || searchPreferences.pageSize!),
                                )}
                                loading={loading.current}
                                onPageSelected={(newPageIndex: number) => {
                                    pageIndex.current = newPageIndex;
                                    const top = pageSize || searchPreferences.pageSize!;
                                    if (!searchResultsByPage.current[pageIndex.current]) {
                                        loading.current = true;
                                        const skip: number = pageIndex.current * top;
                                        const newPaginatedSearchRequest: SearchRequest = {
                                            ...(searchRequest.current as SearchRequest),
                                            top: top,
                                            skip: skip,
                                        };
                                        setSearchParamsState({
                                            ...searchParams,
                                            paginatedSearchRequest: newPaginatedSearchRequest,
                                        });
                                    } else {
                                        setSearchResults({
                                            ...searchResults,
                                        });
                                    }
                                }}
                                defaultPageSize={pageSize || searchPreferences.pageSize!}
                                onPageSizeSelected={(pageSize: number) => {
                                    setQueryParam(PARAM_NAME.pageSize, pageSize, true);
                                }}
                            />
                        </div>
                    )}
                </div>
            </TwoColumnContainer>
        </DataLoadFacade>
    </>
};

export default FacetPanel;
