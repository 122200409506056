/** This module defines the icon title React component.  The icon title displays an icon
 *      with text next to it.
 *  @module */
import type { ReactNode } from 'react';
import { Icon, Colors, IconNames } from '@tir-ui/react-components';
import type { IconName } from '@tir-ui/react-components';
import { SEVERITY } from 'components/enums/Severity.ts';
import { SEVERITY_CLASS, PRIORITY_CLASS } from 'components/enums/Colors.ts';
import { SIZE } from 'components/enums/Sizes.ts';
import { PRIORITY } from 'components/enums/Priority.ts';
import { useQueryParams } from 'utils/hooks/useQueryParams.ts';
import { PARAM_NAME } from 'components/enums/QueryParams.ts';
import { AnchorButton } from '@blueprintjs/core';
import "./IconTitle.scss";

/** This interface defines the properties passed into the icon title React component.*/
export interface IconTitleProps {
    /** an optional boolean value, if true show the title in bold. */
    bold?: boolean;
    /** the icon that is to be displayed in the title. */
    icon?: IconName | ReactNode;
    /** an optional boolean value, if true show the icon without the background. */
    showIconWithoutBg?: boolean;
    /** an optional parameter with either the SEVERITY or PRIORITY.  If this is present the background will have 
     *      a color that corresponds to one of the enumerated SEVERITYs or PRIORITYs. */
    status?: SEVERITY | PRIORITY;
    /** this component is used on pages that use both the SEVERITY enum for its status and the PRIORITY
     *      enum for its status.  Be default we assume the SEVERITY is being displayed.  Set this to 
     *      true if the component is displaying the PRIORITY enum. */
    isPriority?: boolean;
    /** a string or React node with the title. */
    title?: string|number|ReactNode;
    /** a boolean to center title. */
    centeredTitle?: boolean;
    /** a string or React node with the subtext for the title. */
    subText?: string|number|ReactNode;
    /** an optional className that can be used to further refine the css for this component. */
    className?: string;
    /** an optional size for the icon, if none is set, it defaults to large. */
    size?: SIZE.s | SIZE.m | SIZE.l;
    /** a boolean value, if true show the debug icon. */
    showDebug?: boolean;
    /** the handler for the debug action. */
    onDebug?: () => void;
}

/** Renders the icon title view.
 *  @param props the properties passed in.
 *  @returns JSX with the icon title component.*/
function IconTitle ({ size = SIZE.m, showIconWithoutBg = false, ...props}: IconTitleProps) {
    let { params } = useQueryParams({ listenOnlyTo: [PARAM_NAME.debug] });
    const showDebugInfo = params[PARAM_NAME.debug] === "true";

    // Create the element that displays the title.
    const titleElem = <span className={"title-holder" + (props.bold ? " fw-600" : "")}>{props.title}</span>;

    // Create the class name for the icon element
    const iconClassName = props.isPriority ?
        PRIORITY_CLASS[props.status && PRIORITY_CLASS[props.status] ? props.status : PRIORITY.UNKNOWN].text:
        SEVERITY_CLASS[props.status && SEVERITY_CLASS[props.status] ? props.status : SEVERITY.UNKNOWN].text;

    // Create the class name for the div element when there is no bg
    const divClassName = props.isPriority ?
        props.status && PRIORITY_CLASS[props.status] ? PRIORITY_CLASS[props.status].bg : "":
        props.status && SEVERITY_CLASS[props.status] ? SEVERITY_CLASS[props.status].bg : "";

    return <span className={"icon-title align-items-top " + 
        (size === SIZE.m ? "display-7" : (size === SIZE.s ? "display-8" : "display-6")) +
        " size-" + size +
        (props.className ? " " + props.className : "")}>
        {
            props.icon &&
            (showIconWithoutBg ?
                (props.icon &&
                    typeof props.icon === "string" ?
                    <Icon
                        size={(size === SIZE.s ? 9 : 12) + 12}
                        icon={props.icon}
                        className={
                            (size === SIZE.s ? "me-1 " : "me-2 ") + iconClassName
                        }
                    /> :
                    props.icon
                )
            :
            <div data-icon={props.icon} className={"led-holder " + (size === SIZE.s ? "me-2" : "me-3") + " " + divClassName}>
                {props.icon &&
                    typeof props.icon === "string" ?
                    <Icon
                        size={size === SIZE.s ? 9 : 12}
                        color={Colors.WHITE1}
                        icon={props.icon}
                        className="text-light"
                    /> :
                    props.icon
                }
            </div>)
        }
        {
            props.subText ?
            <span>
                {titleElem}
                <div className="display-9">{props.subText}</div>
            </span> :
            titleElem
        }
        {
            props.showDebug && showDebugInfo && <AnchorButton
                minimal icon={IconNames.DIAGNOSIS} className="ms-3 icon-title-debug-button" style={{cursor: "pointer", zIndex: 0}} 
                onClick={() => {
                    if (props.onDebug) {
                        props.onDebug();
                    }}
                }
            />
        }
    </span>;
}

export { IconTitle };
