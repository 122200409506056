import { useEffect, useState } from 'react'
import { Button, Callout, Checkbox, Classes, Divider, Intent, Label, Radio, RadioGroup } from '@blueprintjs/core'
import { ErrorToaster, IconNames, SuccessToaster } from '@tir-ui/react-components'
import { STRINGS } from 'app-strings'
import classNames from 'classnames'
import { Form } from 'components/common/form/Form.tsx'
import { SelectField } from 'components/common/form/SelectField.tsx'
import { InputField } from 'components/common/form/InputField.tsx'
import { isEmpty, find } from 'lodash'
import moment from "moment";
import { Colors } from "@blueprintjs/core";
import { openConfirm } from 'components/common/modal/ConfirmModal.tsx'
import * as yup from 'yup';
import { TimePrecision } from '@blueprintjs/datetime';
import { DateInput } from '@blueprintjs/datetime';
import { TIME_FORMAT } from 'components/enums/Time.ts';
import { SchedulerService } from "utils/services/SchedulerApiService.ts";
import { 
  convertTimestamp, parseInputs, parseRepeat, parseDaysOfWeek, parseDaysOfWeekReverse, parseRepeatReverse, convertDayToUTC 
} from './ScheduleRunbookModal.tsx'
import './ScheduleRunbookModal.scss'

interface IScheduleRunbookProps {
    runbookID: string;
    description?: string;
    inputs: any;
    schedule?: any;
    setScheduleSummary?: (any) => void;
    setSchedule: (any) => void;
    setScheduleType: (any) => void;
    scheduleType: string;
    canEdit: boolean;
    setCanEdit: (any) => void;
    editInfos: any;
    loadingSchedules: boolean;
    setLoadingSchedules: (any) => void;
}

const StepSchedule = (props: IScheduleRunbookProps) => {
    const [schedules, setSchedules] = useState<any>({});
    const [triggeredDelete, setTriggeredDelete] = useState(0);

    const convertISOToTimestamp = (isoString) => {
        try {
            const date = new Date(isoString);
            if (!isNaN(date.getTime())) {
                return date.getTime() / 1000;
            } else {
                return isoString;
            }
        } catch {
            return isoString;
        }
    };     

    useEffect(() => {
        const getSchedules = async () => {
            const schedules: Array<any> = await SchedulerService.getSchedules();
            
            const parsedSchedules = schedules?.map((item, _index) => {
                item.startOn = String(convertISOToTimestamp(item.startOn));
                return item;
            });

            setSchedules({
                runbookScheduleConfigs: parsedSchedules
            });
        };

        getSchedules();
    }, [triggeredDelete]);

    const stepStrings = STRINGS.scheduleRunbook.wizard.panelSchedule;

    const daysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
    const repeatFreq = {
        HOUR: 23,
        DAY: 31,
        WEEK: 4
    }
    const repeatFreqLabel = {
        HOUR: "hourly",
        DAY: "daily",
        WEEK: "weekly"
    }
    const freqUnits = {
        HOUR: stepStrings.labelHrs,
        DAY: stepStrings.labelDays,
        WEEK: stepStrings.labelWeeks
    }

    const today = new Date();
    const resetSchedule = { name: '', every: '', repeat: '', startOn: today, daysOfWeek: [] };
    const [popOpen, setPopOpen] = useState(false);

    useEffect(() => {
        if (schedules?.runbookScheduleConfigs?.length && props.editInfos) {
            props.setLoadingSchedules(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schedules?.runbookScheduleConfigs?.length]);

    const convertDaysFromUTCToLocal = (daysArray, localDateString) => {
        const localDate = new Date(localDateString);
        const utcDate = new Date(localDate.toISOString());
    
        const localDay = localDate.getDay() || 7;
        const utcDay = utcDate.getUTCDay() || 7;
    
        const dayDifference = localDay - utcDay;
    
        const convertDay = (day) => {
            let localDay = parseDaysOfWeek[day] + dayDifference;
            if (localDay < 1) {
                localDay += 7;
            } else if (localDay > 7) {
                localDay -= 7;
            }
            return localDay;
        };
    
        return daysArray.map(convertDay);
    };

    useEffect(() => {
        const daysOfWeekInitial = props.schedule.daysOfWeek;
        if (props.scheduleType === 'isSaved' && props.schedule.repeat === "WEEK" && !isEmpty(props.schedule?.daysOfWeek)) {
            props.schedule.daysOfWeek = convertDaysFromUTCToLocal(props.schedule.daysOfWeek, convertTimestamp(props.schedule.startOn)).map(day => parseDaysOfWeekReverse[day]);
        }
        return () => {
            props.schedule.daysOfWeek = daysOfWeekInitial;
        };
        // eslint-disable-next-line
    }, []);

    const handleWeekDayChange = (day) => {
        props.setSchedule(prevState => ({
            ...prevState,
            daysOfWeek: props.schedule?.daysOfWeek?.includes(day) ?
                props.schedule.daysOfWeek.filter(x => x !== day) :
                [...props.schedule.daysOfWeek, day]
        })
        )
    };

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        const validateInput = (value) => {
            return value.replace(/[^a-zA-Z0-9-_ ]/g, '');
        };
        const validatedValue = validateInput(value);
        props.setSchedule(prevState => ({
            ...prevState,
            [name]: validatedValue
        }));
    };

    const handleDelete = async () => {
        try {
            await SchedulerService.deleteSchedule(props.schedule.name);
            props.setSchedule(resetSchedule);
            setTriggeredDelete(triggeredDelete+1);
            SuccessToaster({
                message: stepStrings.queryMessages.success,
            });
        } catch (error: any) {
            console.log(error);
            ErrorToaster({
                message: stepStrings.queryMessages.error,
            });
            console.error(error?.message);
        }
    }

    const formatGetDate = (dateValue): Date => {
        if (typeof dateValue === 'string') {
            // convert from nanosecs to Date
            const asDate = new Date(parseFloat(dateValue) * 1000);
            return asDate
        } else {
            return dateValue
        }
    }

    const summary = `${stepStrings.summary.runs} 
    ${props.schedule.every} 
    ${freqUnits[props.schedule.repeat]} 
    ${(props.schedule.repeat === "WEEK" && !isEmpty(props.schedule?.daysOfWeek)) ? `each ${props.schedule.daysOfWeek}` : ''} 
    ${stepStrings.summary.starting} ${moment(formatGetDate(props.schedule.startOn)).format("hh:mm A on MMM Do, YYYY")}` || ''

    useEffect(() => {
        if (props?.setScheduleSummary) {
            props?.setScheduleSummary(summary);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [summary]);

    const validationSchema = yup.object().shape({
        saved_schedule: yup.string(),
        schedule_type: yup.string(),
        name: yup
            .string()
            .when([], {
                is: () => props.scheduleType === 'isNew' && find(schedules?.runbookScheduleConfigs, ['name', props.schedule.name]),
                then: yup.string().required(stepStrings.warnings.noDups),
            })
            .when([], {
                is: () => props.scheduleType === 'isNew' && !props.schedule.name,
                then: yup.string().required(stepStrings.warnings.required),
            })
            .max(1024, 'Name cannot exceed 1024 characters')
    });

    const handleSave = async () => {
        if (
            props.inputs?.detection?.entity?.attributes?.ifindex && 
            props.inputs?.detection?.entity?.attributes?.metadata?.ifindex
        ) {
            props.inputs.detection.entity.attributes.ifindex = String(props.inputs.detection.entity.attributes.ifindex);
            props.inputs.detection.entity.attributes.metadata.ifindex = String(props.inputs.detection.entity.attributes.metadata.ifindex);
        }

        const payload = {
            daysOfWeek: props.schedule.daysOfWeek?.map(day => convertDayToUTC(parseDaysOfWeek[day], convertTimestamp(props.schedule.startOn))),
            description: props.description,
            every: Number(props.schedule.every),
            name: props.schedule.name,
            repeat: parseRepeat[props.schedule.repeat] || 0,
            startDate: convertTimestamp(props.schedule.startOn),
            runbook: {
                detection: props.inputs.detection,
                incidentId: props.inputs.incidentId,
                inputs: parseInputs(props.inputs?.variableOverrides, props.inputs?.variableTypes),
                runbookId: props.runbookID,
                utid: props.inputs?.utid,
            }
        };

        try {
            await SchedulerService.updateSchedule(payload);
            props.setCanEdit(false);
            SuccessToaster({
                message: stepStrings.queryMessages.editSuccess,
            });
        } catch (error: any) {
            console.error(error);
            ErrorToaster({
                message: stepStrings.queryMessages.errorSuccess,
            });
            console.error(error?.message);
        }
    };

    return (
        <div className={classNames(Classes.DIALOG_BODY)}>
            <p className='fw-bold'>{stepStrings.title}</p>
            <Divider className='ms-0' />
            <Form initialValues={{ name: '' }} loading={false} validationSchema={validationSchema}>
                <div className="row pt-3">
                    <div className='col-4'>
                        <RadioGroup name="schedule_type"
                            selectedValue={props.scheduleType ? props.scheduleType : !props.editInfos ? "isNew" : "isSaved"}
                            onChange={(e) => {
                                if (props.canEdit) {
                                    openConfirm({
                                        message: stepStrings.warnings.isEdit,
                                        onConfirm: () => {
                                            props.setScheduleType(e.currentTarget?.value);
                                            props.setSchedule(resetSchedule);
                                            props.setCanEdit(false);
                                        },
                                        intent: Intent.PRIMARY,
                                        icon: IconNames.WARNING_SIGN,
                                    })
                                } else {
                                    props.setScheduleType(e.currentTarget?.value);
                                    props.setSchedule(resetSchedule);
                                    props.setCanEdit(false);
                                }
                            }}>
                            <Radio className='py-2' label={stepStrings.labelSaved} value="isSaved" />
                            {!props.editInfos && <Radio className='pt-1' label={stepStrings.labelNew} value="isNew" />}
                        </RadioGroup>
                    </div>
                    <div className="col-6">
                        <div className="row">
                            <div className='w-100'>
                                <SelectField name="saved_schedule" className="w-100"
                                    disabled={props.scheduleType === "isNew" || props.editInfos}
                                    onChange={e => {
                                        const selectedSchedule = find(schedules?.runbookScheduleConfigs, ['name', e.target.value]);
                                        // if (props.canEdit) {
                                        //     openConfirm({
                                        //         message: stepStrings.warnings.isEdit,
                                        //         onConfirm: () => {
                                        //             // here
                                        //         },
                                        //         intent: Intent.PRIMARY,
                                        //         icon: IconNames.WARNING_SIGN,
                                        //     })
                                        // }
                                        if (selectedSchedule) {
                                            const parsedSchedule = {...selectedSchedule};
                                            parsedSchedule.daysOfWeek =  parsedSchedule.daysOfWeek?.map(day => parseDaysOfWeekReverse[day]);
                                            parsedSchedule.repeat = parseRepeatReverse[parsedSchedule.repeat];
                                            props.setSchedule(props.editInfos ? { ...selectedSchedule } : {...parsedSchedule});
                                        } else {
                                            props.setSchedule(resetSchedule);
                                        }
                                        props.setCanEdit(false); // add warning on unsaved changes
                                    }}
                                    value={props.schedule.name}
                                >
                                    <option value="">Select Schedule</option>
                                    {schedules?.runbookScheduleConfigs?.map((x, i) => <option key={i} value={x.name}>{x.name}</option>)}
                                </SelectField>
                            </div>
                        </div>
                        {!props.editInfos && <div className="row">
                            <div className="w-100">
                                <InputField name="name"
                                    placeholder={stepStrings.placeholder}
                                    value={props.scheduleType === "isSaved" ? '' : props.schedule.name}
                                    onChange={handleFieldChange}
                                    disabled={props.scheduleType === "isSaved"} />
                            </div>
                        </div>}
                    </div>
                </div>
                <div className="pt-5 px-5 pb-2 bg-light">
                    <div className="row">
                        <Label className='col-2 mt-1'>{stepStrings.labelStart}</Label>
                        <div className='col-9 pb-3'>
                            <DateInput
                                showTimezoneSelect={false}
                                className="schedule-runbook-date-picker"
                                formatDate={date => moment(date).format(TIME_FORMAT.DISPLAY_DATE_INPUT)}
                                parseDate={str => new Date(str)}
                                maxDate={moment(today).add(1, 'y').toDate()}
                                // This switched from a date to a string
                                value={getIsoString(props?.schedule?.startOn)}
                                timePrecision={TimePrecision.MINUTE}
                                timePickerProps={{
                                    useAmPm: true,
                                    selectAllOnFocus: true
                                }}
                                showActionsBar
                                popoverProps={{
                                    isOpen: popOpen,
                                    onClose: () => setPopOpen(false)
                                }}
                                onChange={(date: string | null) => {
                                    const newDate = date ? new Date(date) : null;
                                    props.setSchedule(prevState => ({
                                        ...prevState,
                                        // This used to come as a Date, but a string comes from the new api,
                                        // The back-end could have either a Date or a string
                                        startOn: newDate
                                    }))
                                }}
                                rightElement={
                                    <Button aria-label="date-selector-clear-button" icon={IconNames.CALENDAR} minimal
                                        className="date-selector-clear-icon"
                                        disabled={props.scheduleType === 'isSaved' && !props.canEdit}
                                        onClick={() => setPopOpen(true)}
                                    />
                                }
                                disabled={props.scheduleType === 'isSaved' && !props.canEdit}
                                minDate={
                                    props.scheduleType !== 'isSaved' || !props.schedule.name ? 
                                        new Date() : 
                                        new Date(
                                            props.schedule.startOn * 1000 < Date.now() ? 
                                                props.schedule.startOn * 1000 : 
                                                Date.now()
                                        )
                                }
                            />
                        </div>
                    </div>
                    <div className="row">
                        <Label className='col-2 mt-1'>{stepStrings.labelRepeat}</Label>
                        <div className='col-3'>
                            <SelectField name="repeat"
                                disabled={props.scheduleType === 'isSaved' && !props.canEdit}
                                onChange={(e) => {
                                    handleFieldChange(e)
                                    props.setSchedule(prev => ({ ...prev, daysOfWeek: [], every: 0 }));
                                }}
                                value={props.schedule.repeat}
                            >
                                <option value="" />
                                {Object.keys(repeatFreq).map((x, i) => <option key={i} value={x}>{repeatFreqLabel[x]}</option>)}
                            </SelectField>
                        </div>
                        {props.schedule.repeat === "WEEK" && <>
                            <Label className='col-1 mt-1'>{stepStrings.labelOn}</Label>
                            <div className='col-6'>
                                <div className='days-of-week'>
                                    {daysOfWeek.map(day => (
                                        <Checkbox key={day} inline
                                            value={day}
                                            disabled={props.scheduleType === 'isSaved' && !props.canEdit}
                                            checked={!isEmpty(props.schedule.daysOfWeek) && props.schedule.daysOfWeek.includes(day)}
                                            onChange={() => handleWeekDayChange(day)}
                                        />
                                    ))}
                                </div>
                                {props.schedule.daysOfWeek.length === 0 && <span className='field-error day-error'>{stepStrings.warnings.dayRequired}</span>}
                            </div>
                        </>}
                    </div>
                    <div className="row">
                        <Label className='col-2 mt-1'>{stepStrings.labelEvery}</Label>
                        <div className='col-2'>
                            <SelectField name="every"
                                disabled={(props.scheduleType === 'isSaved' && !props.canEdit) || !props.schedule.repeat}
                                onChange={handleFieldChange}
                                value={props.schedule.every}
                            >
                                <option value="" />
                                {Array.from(Array(repeatFreq[props.schedule.repeat]).keys()).map((n, i) => <option key={i} value={n + 1}>{n + 1}</option>)}
                            </SelectField>
                        </div>
                        <span className="col ps-0 mt-1">{freqUnits[props.schedule.repeat]}</span>
                    </div>
                    <div className='text-center font-size-md-small py-1'>
                        {Object.values(props.schedule).every(value => value || [true, false].includes(value as any)) && summary}
                    </div>
                    {/* Show when editing a saved runbook schedule OR 
                        when trying to delete a schedule used by other Runbooks*/}
                    {props.scheduleType === "isSaved" && props.canEdit &&
                        <Callout intent='warning' icon={IconNames.WARNING_SIGN} className='my-2'>
                            Changes to this schedule will apply to all runbooks already using this schedule.
                        </Callout>
                    }
                    {<div className="d-flex justify-content-end">
                        {(props.scheduleType === "isSaved" && props.canEdit) &&
                            <>
                                <Button style={{ color: Colors.WHITE }}
                                    minimal
                                    intent="primary"
                                    className='me-2'
                                    icon={IconNames.UNDO}
                                    onClick={(_e) => {
                                        const selectedSchedule = find(schedules?.runbookScheduleConfigs, ['name', props.schedule.name]);
                                        const parsedSchedule = {...selectedSchedule};
                                        parsedSchedule.daysOfWeek = parsedSchedule.daysOfWeek?.map(day => parseDaysOfWeekReverse[day]);
                                        parsedSchedule.repeat = parseRepeatReverse[parsedSchedule.repeat];
                                        props.setSchedule(parsedSchedule); // maybe warning of unsaved
                                        props.setCanEdit(false);
                                    }}
                                >
                                    {stepStrings.ctas.revert}
                                </Button>
                                <Button style={{ color: Object.values(props.schedule).every(value => value || [true, false].includes(value as any)) ? Colors.WHITE : Colors.GRAY3 }}
                                    minimal
                                    disabled={
                                        !Object.values(props.schedule).every(value => value || 
                                            [true, false].includes(value as any)) ||
                                            (props.schedule.repeat === "WEEK" && props.schedule.daysOfWeek.length === 0)
                                        }
                                    intent="primary"
                                    icon={IconNames.FLOPPY_DISK}
                                    onClick={handleSave}
                                >
                                    {stepStrings.ctas.save}
                                </Button>
                            </>
                        }

                        {(Object.values(props.schedule).every(value => value || [true, false].includes(value as any)) && props.scheduleType === 'isSaved' && !props.canEdit) &&
                            <>
                                {schedules?.runbookScheduleConfigs && !schedules.runbookScheduleConfigs?.find(item => item.name === props.schedule.name)?.hasRunbooks && props.schedule.name && 
                                <Button style={{ color: Colors.WHITE }}
                                    minimal
                                    intent="primary"
                                    icon={IconNames.TRASH}
                                    onClick={() => {
                                        openConfirm({
                                            message: stepStrings.warnings.delete,
                                            onConfirm: () => handleDelete(),
                                            intent: Intent.PRIMARY,
                                            icon: IconNames.TRASH,
                                        })

                                    }}
                                    className="me-1"
                                >{stepStrings.ctas.delete}</Button>}
                                <Button style={{ color: Colors.WHITE }}
                                    minimal
                                    intent="primary"
                                    icon={IconNames.EDIT}
                                    disabled={!props.schedule.name}
                                    onClick={() => props.setCanEdit(true)}>{stepStrings.ctas.edit}</Button>
                            </>
                        }
                    </div>}
                </div>
            </Form>
        </div>
    )
}

export default StepSchedule

function getIsoString(date: Date | string | null): string | null {
    if (date) {
        if (typeof date === "string") {
            date = new Date(parseInt(date) * 1000);
        }
        return date.toISOString();  
    }
    return null;
}
