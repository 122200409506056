/** This module contains the component for the create runbook page.  The create runbook page displays
 *      the runbook editor.
 *  @module
 */

import { useState, useRef } from "react";
import CreateRunbookView from "./views/create-runbook/CreateRunbookView.tsx";
import { IS_EMBEDDED, PARAM_NAME } from "components/enums/QueryParams.ts";
import { PageDetailPaneRenderer } from 'components/common/page/PageDetailPaneRenderer.tsx';
import { ViewCollection } from "components/common/layout/view-collection/ViewCollection.tsx";
import { OneColumnContainer } from "components/common/layout/containers/one-column-container/OneColumnContainer.tsx";
import ViewRunbookView from "pages/view-runbook/views/view-runbook/ViewRunbookView.tsx";
import { useViewCollection } from "components/common/layout/view-collection/useViewCollection.ts";
import type { RunbookConfig, RunbookNode } from "utils/services/RunbookApiService.ts";
import { STRINGS } from "app-strings";
import { RunbookAzureMonitorIcon } from "utils/runbooks/RunbookAzureMonitorIcon.tsx";
import { AuthServiceProvider } from "utils/providers/AuthServiceProvider.ts";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { trackPageView } from "utils/appinsights/AppInsights.ts";
import RunbookNodeLibrary from "pages/create-runbook/views/create-runbook/node_library.json";
import LifecycleRunbookNodeLibrary from "pages/create-runbook/views/create-runbook/lifecycle_node_library.json";
import OnDemandRunbookNodeLibrary from "pages/create-runbook/views/create-runbook/on_demand_node_library.json";
import SubflowRunbookNodeLibrary from "pages/create-runbook/views/create-runbook/subflow_node_library.json";
import ExternalRunbookNodeLibrary from "pages/create-runbook/views/create-runbook/node_library_external.json";
import { NodeLibrary, type NodeLibrarySpec } from "pages/create-runbook/views/create-runbook/NodeLibrary.ts";
import { getQueryParam } from "utils/hooks/useQueryParams.ts";
import { Variant } from "components/common/graph/types/GraphTypes.ts";
import incidentRunbookExclusions from 'utils/runbooks/connection_exclusions.json';
import lifecycleRunbookExclusions from 'utils/runbooks/connection_exclusions_lifecycle.json';
import type { ConnectionExclusions } from "utils/runbooks/RunbookValidationUtils.ts";
import { ThirdPartyAuthenticationListView } from "pages/integrations/views/ThirdPartyAuthenticationListView/ThirdPartyAuthenticationListView.tsx";

const AuthService = AuthServiceProvider.getService();

/** Renders the view runbook page.
 *  @returns JSX with the view runbook page component.*/
const CreateRunbookPage = (): JSX.Element => { 
    const { activeView, updateView } = useViewCollection({
        // TBD: BlueprintJS tab switch action is rendering the new tab first and then unmounting the previous tab.
        // This is causing collision if the same default view URL key used for the sub-panels as it uses values from
        // previous tab before it gets unmounted. Providing a unique key until I figure out a solution.
        defaultActiveView: "create",
        viewURLKey: "view",
        varToQueryParamMap: { 
            [PARAM_NAME.rbViewMode]: PARAM_NAME.rbViewMode, [PARAM_NAME.incidentId]: PARAM_NAME.incidentId,
            [PARAM_NAME.runbookId]: PARAM_NAME.runbookId
        },
        //varDefaults: {device: getQueryParam(FILTER_NAME.hostId), deviceIp: getQueryParam(FILTER_NAME.deviceIp)}
    });
    const variant: string = getQueryParam(PARAM_NAME.variant);
    const [runbook, setRunbook] = useState<RunbookConfig | undefined>(undefined);

    // Handle being backwards compatible with Aternity embedded UI.  The embedded UI is going to say the variant 
    // is incident when it should be external
    const effectiveVariant: string = IS_EMBEDDED ? Variant.EXTERNAL : variant;

    let initNodeLibrary: NodeLibrary;
    let initExclusions: ConnectionExclusions | undefined;
    switch (effectiveVariant) {
        case Variant.INCIDENT:
            initNodeLibrary = new NodeLibrary(RunbookNodeLibrary as NodeLibrarySpec);
            initExclusions = incidentRunbookExclusions;
            break;
        case Variant.LIFECYCLE:
            initNodeLibrary = new NodeLibrary(LifecycleRunbookNodeLibrary as NodeLibrarySpec);
            initExclusions = lifecycleRunbookExclusions;
            break;
        case Variant.SUBFLOW:
            initNodeLibrary = new NodeLibrary(SubflowRunbookNodeLibrary as NodeLibrarySpec);
            initExclusions = incidentRunbookExclusions;
            break;
        case Variant.ON_DEMAND:
            initNodeLibrary = new NodeLibrary(OnDemandRunbookNodeLibrary as NodeLibrarySpec);
            initExclusions = incidentRunbookExclusions;
            break;
        case Variant.EXTERNAL:
            initNodeLibrary = new NodeLibrary(ExternalRunbookNodeLibrary as NodeLibrarySpec);
            initExclusions = incidentRunbookExclusions;
            break;
        default:
            initNodeLibrary = new NodeLibrary(RunbookNodeLibrary as NodeLibrarySpec);
            initExclusions = incidentRunbookExclusions;
    }

    const nodeLibrary = useRef<NodeLibrary>(initNodeLibrary);
    const exclusions = useRef<ConnectionExclusions | undefined>(initExclusions);

    // report metrics to App Insights
    const appInsightsContext = useAppInsightsContext();
    if (appInsightsContext) {
        const properties = {
            name: "Runbook Editor"
        };
        trackPageView(appInsightsContext, AuthService, properties);
    }    

    /********************************************************************************************************************************************************/
    /* We need to read this in                                                                                                                              */
    /********************************************************************************************************************************************************/
    const subflows = useRef<RunbookNode[]>([]);

    return (<>
        {/* For now don't show the header it takes some space away from the graph
        <PageWithHeader name={CreateRunbookPage.name} title={"Create Runbook"} 
            icon={SDWAN_ICONS.RUNBOOK} addPadding={false} showTimeBar={false}
        >
        */}
            <CreateRunbookView 
                nodeLibrary={nodeLibrary.current}
                exclusions={exclusions.current}
                key="create" editorHidden={activeView !== "create" ? true : false} onPreview={(runbook: RunbookConfig) => {
                    const mode = { [PARAM_NAME.rbViewMode]: "preview" };
                    setRunbook(runbook);
                    updateView("preview", { ...mode });
                }} onTest={(runbook: RunbookConfig) => {
                    const mode = {};
                    setRunbook(runbook);
                    updateView("test", { ...mode });
                }}
                variant={effectiveVariant as Variant}
                displaySubflows={effectiveVariant !== Variant.SUBFLOW}
                onSubflowsSet={(newSubflows) => {
                    subflows.current = newSubflows;
                }}
            />
            <ViewCollection activeView={activeView} defaultToFirstView={false} >
                <OneColumnContainer key="preview" noPadding={true} showBackButton={true} 
                    backText={<RunbookAzureMonitorIcon showAzIcon={false} showGauge={false} 
                    iconText={STRINGS.formatString(STRINGS.runbookEditor.previewRunbookTitle, runbook?.name || "")} />} 
                    onBackClicked={() => updateView("create", { [PARAM_NAME.rbViewMode]: "" })}
                >
                    <ViewRunbookView runbook={runbook} subflows={subflows.current}/>
                </OneColumnContainer>
                <OneColumnContainer key="test" noPadding={true} showBackButton={true} 
                    backText={<RunbookAzureMonitorIcon showAzIcon={false} showGauge={false} 
                    iconText={STRINGS.formatString(STRINGS.runbookEditor.testRunbookTitle, runbook?.name || "")} />}
                    onBackClicked={() => updateView("create", { [PARAM_NAME.rbViewMode]: "", [PARAM_NAME.incidentId]: "", [PARAM_NAME.runbookId]: "" })}
                >
                    <ViewRunbookView runbook={runbook} subflows={subflows.current}/>
                </OneColumnContainer>
                {IS_EMBEDDED ? 
                    <OneColumnContainer key="authProfile" noPadding={true} showBackButton={true} 
                        backText={<RunbookAzureMonitorIcon showAzIcon={false} showGauge={false} 
                        iconText={"Authentication Profiles"} />}
                        onBackClicked={() => updateView("create", { [PARAM_NAME.rbViewMode]: "" })}
                    >
                        <ThirdPartyAuthenticationListView />
                    </OneColumnContainer>
                : 
                    <></>
                }
            </ViewCollection>
            <PageDetailPaneRenderer/>
        {/*</PageWithHeader>*/}
    </>);
};
  
export default CreateRunbookPage;
