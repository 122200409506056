export function importFlags() {
    const modules: any = import.meta.glob('../../assets/images/flags/*', { eager: true });
    const flagMap: Record<string, any> = {};

    // Build the dictionary with [country_code].png to module
    for (const [path, module] of Object.entries(modules)) {
        flagMap[path.substring(path.lastIndexOf("/") + 1, path.length)] = module;
    }

    return flagMap;
}
