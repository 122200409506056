import { ScheduledRunbookInputs } from './ScheduledRunbookInputs'

interface ISpecifyInputsProps {
    id: string;
    onJsonInputChange: (any) => void;
    currentInputs: any;
    editInfos: any;
}
const StepSpecify = (props: ISpecifyInputsProps) => {
    return (
        <ScheduledRunbookInputs runbookId={props.id} onJsonInputChange={props.onJsonInputChange} currentInputs={props.currentInputs} editInfos={props.editInfos} />
    )
}

export default StepSpecify
