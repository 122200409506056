/** This module contains the implementation for the SummaryCard component.  A SummaryCard displays data in 
 *  a rectangle with rounded edges and a background color that is different than the default, 
 *  for exmple white when in light mode.
 *  @module
 */
import type { ReactNode } from 'react';
import { Card as CardContainer, type CardProps as CardContainerProps } from 'components/reporting/containers/card/Card';
import { Icon, type IconName } from '@tir-ui/react-components';
import { SEVERITY_CLASS, STATUS_COLORS } from 'components/enums/Colors.ts';
import type { SEVERITY } from 'components/enums/Severity.ts';
import { SIZE } from 'components/enums/Sizes.ts';
import { HealthSummaryLED, type HealthSummaryLEDProps } from 'components/common/health-summary-led/HealthSummaryLED.tsx';
import "./SummaryCard.scss";

/** this interface defines the properties that are passed into the component. */
export interface SummaryCardProps extends CardContainerProps {
    /** the optional status to show in the summary card. */
    status?: SEVERITY,
    /** the optional icon to show in the summary card. It appears to the right of the title. */
    icon?: IconName | ReactNode,
    /** the optional title to show in the summary card. */
    title?: string | ReactNode,
    /** a string with the font weight for the title, if none is provided then it defaults to fw-400 */
    titleFontWeight?: string,
    /** a boolean value, true if there should be padding between the title and the content. */
    titlePadding?: boolean,
    /** the alignment of the title which is one of: "left", "center", "right".  By default it is aligned to the left. */
    alignTitle?: "left" | "center" | "right"
    /** the optional text to show in the footer. */
    footerText?: string,
    /** the optional JSX content to show in the footer. */
    footerContent?: ReactNode,
    /** the width of the card. */
    size?: SIZE.s | SIZE.m | SIZE.l | "flex",
    /** an optional parameter which specifies the height of the card. */
    height?: SIZE.xs | SIZE.s | SIZE.m | SIZE.l,
    /** the optional health summary. */
    healthSummary?: HealthSummaryLEDProps,
    /** the aria label. */
    ariaLabel?: string,
    /** the optional padding that should be used for the card.  If this is not set it defaults to 3 on all sides. */
    padding?: string,
    /** a boolean value, if true center the contents, if false left justify the contents. */
    center?: boolean,
    /** a boolean value to add specific styles for summary page only  */
    isSummary?: boolean,
}

/** Creates the SummaryCard component, which is a component that displays a card in a flex 
 *  container.
 *  @param props an object with the properties passed to the SummaryCard component.
 *  @returns JSX with the SummaryCard component.*/
export function SummaryCard ({
    status, title, alignTitle="center", icon, className, children, footerText, footerContent,
    healthSummary, size = SIZE.m, height = SIZE.m, padding = "p-3", center = true, 
    titleFontWeight = "fw-400", titlePadding = true, ...props
}:SummaryCardProps): JSX.Element {
    const blueprintIcon:any = (icon !== undefined && typeof icon === "string") ? icon : undefined;
    const titleAlignmentClass = alignTitle === "center" ? "justify-content-center" : (alignTitle === "right" ? "justify-content-right" : "justify-content-between");
    return <CardContainer {...props}
        className={
            "card-with-title-and-status" +
            (status ? " status-border " + (SEVERITY_CLASS[status]?.border || "") : "") +
            (className? " " + className : "") +
            " card-size-" + size +
            " card-height-" + height
        }
        children={
            <div className={"d-flex flex-column " + padding}>
                {(title || icon) && <div className={"card-with-title-header d-flex " + titleAlignmentClass}>
                    {icon && (blueprintIcon ? <Icon icon={blueprintIcon} size={30} color={STATUS_COLORS.BLUE} className="card-icon"/> : icon)}
                    <span className={"card-title-holder text-uppercase text-center pt-2" + (props.isSummary ? ' display-8 ' : ' display-9 ') + (titleFontWeight ? " " + titleFontWeight : "") + (size === SIZE.s ? " text-secondary" : " text-black")}>{title}</span>
                </div>}
                <div className={"card-content-holder flex-grow-1 overflow-auto display-8" + (center ? " text-center" : "") + (titlePadding && (title || icon) ? " pt-4" : "")} >{children}</div>
                {footerContent ?
                    <div className="card-footer-holder">{footerContent}</div> :
                    (footerText || healthSummary) && <div className="card-footer-holder d-flex justify-content-between">
                        <span className="footer-text">{footerText}</span>
                        {healthSummary && <HealthSummaryLED {...healthSummary} size={SIZE.s} showWhenZero={true}/>}
                    </div>
                }
            </div>
        }
    />;
}
