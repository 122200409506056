query locationSearch($top: Int!, $skip: Int!, $count: Boolean!, $search: String, $searchFields: [LocationSearchFieldEnum], $facets: [LocationRequestFacetType!], $filter: LocationSearchFilterType, $order: [LocationSearchSortInputType!]) {
    searchItems: searchLocations(
        top: $top,
        skip: $skip,
        count: $count,
        facets: $facets,
        filter: $filter,
        order: $order,
        search: $search,
        searchFields: $searchFields
    ) {
        info {
            totalCount
            facets {
                key
                result {
                    count
                    items {
                        value
                        count
                    }
                }
            }
            groupedFacets {
                key
                results {
                    group
                    count
                    items {
                        count
                        value
                    }
                }
            }
        }
        page {
			id
			name
			type
            city
            state
            country
            countryCode
            elementType
            latitude
            longitude
			reportedBy {
				dataSourceId
				dataSourceType
				entityId
                entityName
			}
            customProperties {
                id
                name
                assignedValue {
                    id
                    name
                }
            }
		}
    }
}
