query impactedLocations($priority: [Priority], $incidentStatus: [Status], $completionStatus: CompletionStatus) {
  impactedLocations: summary(
    filter: {
        priorities: $priority, status: $incidentStatus, completionStatus: $completionStatus
    },
    order: {incidentsCount: DESC},
    first: 5
  ) {
        incidentsLocations {
            name
            count
            priorities {
                priority
                count
            }
        }
    }
}
