mutation createCustomProperty($name: String!, $description: String!, $validTypes: [CustomPropertyAssociationReadDtoInput!], $values: [CustomPropertyValueCreateDtoInput!]) {
    createCustomProperty(
        customProperty: { 
            name: $name, 
            description: $description,
            validTypes: $validTypes,
            values: $values
        }
    ) {
        id
        name
        description
    }
}
