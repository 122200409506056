/** This module contains the functional React component for rendering the logic node editor.
 *  This control allows users to set all the logic node properties including the logic node
 *  expression.
 *  @module
 */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { HTMLSelect, Intent, Radio, RadioGroup } from '@blueprintjs/core';
import { UniversalNode } from 'components/common/graph/UniversalNode.ts';
import { SimpleNodeEditorProps } from 'components/common/graph/editors/simple/SimpleNodeEditor.tsx';
import { 
  Action, CompareControls, CompareControlTargetValueTypes, Control, ControlConfig, 
  Operator, UpdatedControl 
} from 'components/common/compare-control/CompareControl.tsx';
import { LogicNodeUtil, LOGIC_NODE_EDIT_PROPS, MetricDataType } from 'components/common/graph/editors/logical/LogicNodeUtil.ts';
import { GenericKey, NodeUtils } from 'utils/runbooks/NodeUtil.ts';
import { Icon } from '@tir-ui/react-components';
import { IconNames } from '@blueprintjs/icons';
import { STRINGS } from 'app-strings';
import { getNodeFromGraphDef } from 'utils/runbooks/RunbookUtils.ts';
import { useStateSafePromise } from 'utils/hooks/useStateSafePromise.ts';
import { Unit } from 'reporting-infrastructure/types/Unit.class.ts';
import { DataLoadFacade } from 'components/reporting/data-load-facade/DataLoadFacade.tsx';
import { DataOceanMetadata } from 'components/common/graph/editors/data-ocean/DataOceanMetadata.type.ts';
import { SHOW_CONTEXT } from 'components/enums/QueryParams.ts';
import { RunbookContextSummary } from 'components/common/graph/editors/RunbookContextSummary.tsx';
import { DataOceanUtils } from 'components/common/graph/editors/data-ocean/DataOceanUtils.ts';
import { NodeLibraryNode } from 'pages/create-runbook/views/create-runbook/NodeLibrary.ts';
import { FunctionOperators, KeyOperators, MetricOperators, OperatorType } from 'components/common/graph/editors/LogicOperators.ts';

// Local constants
const TS_OP = "ts_op";
const BLANK_VALUE = " ";
const BLANK_ID = "_blank_";
// API specified expression format
export const KEY_PREFIX = "$DATA.KEYS.";
export const METRIC_PREFIX = "$DATA.DATA.";

export interface RunBookAPIExpression {
    id: string;
    op: string;
    value: string;
    ts_op?: string;
}
export interface RunBookAPIExpressions {
    keys?: RunBookAPIExpression[];
    metrics?: RunBookAPIExpression[];
}

interface LogicNodeProperties {
    inputType?: string;
    passedData?: PassedData;
    expression?: Control[];
    dataType?: MetricDataType;
}
export interface OperatorsInterface {
    // numeric operators
    gt: Operator,
    lt: Operator,
    gteq: Operator,
    lteq: Operator,
    eq: Operator,
    neq: Operator,
    // string operators
    oneOf: Operator,
    nOneOf: Operator,
    cont: Operator,
    nCont: Operator,
    regex: Operator,
    // function operators
    max: Operator,
    min: Operator,
    avg: Operator
}
enum PassedData {
    ALL = "ALL",
    MATCHED = "MATCHED"
}

enum ExpressionType {
    KEYS = "KEYS",
    METRIC = "METRIC"
}

// Map between API operator and Compare Control operator. Used for transating the api stored expression
// to a format that the Compare Control understands
const APIFunctionOperatorsMap = {
    "MAX": FunctionOperators.max,
    "MIN": FunctionOperators.min,
    "AVG": FunctionOperators.avg,
}
const APIMetricOperatorsMap = {...{
    "ONE-OF": KeyOperators.oneOf,
    "NOT-ONE-OF": KeyOperators.nOneOf,
    "CONTAINS": KeyOperators.cont,
    "NOT-CONTAIN": KeyOperators.nCont,
    "REGEX": KeyOperators.regex,
    "EQ": MetricOperators.eq,
    "NOT-EQ": MetricOperators.neq,
    "GT": MetricOperators.gt,
    "GT-EQ": MetricOperators.gteq,
    "LT": MetricOperators.lt,
    "LT-EQ": MetricOperators.lteq,
}, ...APIFunctionOperatorsMap}

const APIKeyOperatorsMap = {
    "ONE-OF": KeyOperators.oneOf,
    "NOT-ONE-OF": KeyOperators.nOneOf,
    "CONTAINS": KeyOperators.cont,
    "NOT-CONTAIN": KeyOperators.nCont,
    "REGEX": KeyOperators.regex,
    "EQ": KeyOperators.seq,
    "NOT-EQ": KeyOperators.sneq,
}

// Default operator that will be displyed in the Compare Control component when no value is selected
export const DefaultOperators: Partial<OperatorType> = {
    _blank_: {
        id: "_blank_",
        label: BLANK_VALUE
    }
}
// Default attibute config that will be displyed in the Compare Control component when no value is selected
export const defaultConfig: ControlConfig = {
    attributeId: BLANK_ID,
    label: BLANK_VALUE,
    operators: [{
        id: BLANK_ID,
        label: BLANK_VALUE
    }]
}
// Default config set. The is passed to the  Compare Control  to populate the drop downs in the compare control
// when no value is selected
const defaultConfSet: ControlConfig[] = [{
    attributeId: BLANK_ID,
    label: BLANK_VALUE,
    operators: Object.keys(DefaultOperators).map((key) => {
        return DefaultOperators[key];
    })
}]

/** Component for editing the properties in the logical node. */
export const LogicNodeEditor = React.forwardRef(({ selectedNode, libraryNode, graphDef }: SimpleNodeEditorProps, ref: any) => {
    //  Each expression gets a unique id. When an expression is edited in the Compare Control component
    //  the expId is used to update the right expression object in the selectedNode
    const expressionIdCtr = useRef(1);
    const [executeSafely] = useStateSafePromise();
    // Meta data about the object types, list of metrics and keys.
    const [objMetricMetaData, setObjMetricMetaData] = useState<DataOceanMetadata>();
    let selNodeId =  selectedNode?.getId();
    selNodeId = selNodeId? selNodeId: "";
    // Dataocean node properties
    const doNode = LogicNodeUtil.getParentNode(selNodeId, graphDef);
    const doInputType = LogicNodeUtil.getPropertyFromNode("objType", doNode)?.value;
    const doNodeMetrics =  LogicNodeUtil.getPropertyFromNode("metrics", doNode)?.value;

    const fetchData = useCallback(
        () => {
            return executeSafely(DataOceanUtils.init()).then((response: any) => {
                setObjMetricMetaData(response);
            }, error => {
                console.error(error);
            });
        },
        [executeSafely]
    );

    useEffect(() => {
        // Fetch Meta data on load.
        fetchData();
    }, [fetchData])

    // Component states
    const [curProperties, setCurProperties] = useState<LogicNodeProperties>({
        inputType: selectedNode?.getProperty(LOGIC_NODE_EDIT_PROPS.INPUT_TYPE),
        passedData: selectedNode?.getProperty(LOGIC_NODE_EDIT_PROPS.PASSED_DATA),
        expression: convertExpressionToControlFormat(selectedNode?.getProperty(LOGIC_NODE_EDIT_PROPS.EXPRESSION)),
        dataType: selectedNode?.getProperty(LOGIC_NODE_EDIT_PROPS.DATA_TYPE)
    });

    useEffect(() => {
        const exp = convertExpressionToControlFormat(selectedNode?.getProperty(LOGIC_NODE_EDIT_PROPS.EXPRESSION));
        // Try to infer the input type and the dataType(summary/timeseries) from the parent DO node if possible
        const pNode = selectedNode?.node?.id ? NodeUtils.getParentNode(selectedNode?.node?.id, graphDef) : undefined;
        const inferredInpType = LogicNodeUtil.getPropertyFromNode("objType", pNode);
        const selectedInputType = selectedNode?.getProperty(LOGIC_NODE_EDIT_PROPS.INPUT_TYPE);
        const inpType = (selectedInputType && selectedInputType !== BLANK_ID) ? selectedInputType : (inferredInpType?.value ? inferredInpType.value : selectedInputType);
        const selectedDataType = selectedNode?.getProperty(LOGIC_NODE_EDIT_PROPS.DATA_TYPE);
        const timeSeriesDataType = LogicNodeUtil.getPropertyFromNode("timeSeries", pNode);
        const datType = selectedDataType ? selectedDataType : (timeSeriesDataType?.value ? MetricDataType.TIMESERIES : MetricDataType.SUMMARY)
        setCurProperties({
            inputType: inpType,
            passedData: selectedNode?.getProperty(LOGIC_NODE_EDIT_PROPS.PASSED_DATA),
            expression: exp,
            dataType: datType
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNode, objMetricMetaData])

    /**
     * Tanslates Compare Control express to the API expression format
     * @param exps - list of Controls(expression object)
     * @returns  RunBookAPIExpressions
     */
    function convertControlExpressionToAPIFormat(exps: Array<Control> | undefined): RunBookAPIExpressions {
        const defAcc: { [key: string]: any[] } = {
            keys: [],
            metrics: []
        }
        const apiExp = exps?.reduce((result, exp: Control) => {
            const expType = getAttributeType(exp.attribute);
            let computedValue = exp.value;
            if (exp.operator?.multiInput) {
                // string to array, trim whitespace, ignore empty value
                computedValue = exp.value?.split(',').map((i: string) => i.trim()).filter((i: string) => i);
            }
            if (expType === ExpressionType.KEYS) {
                result.keys.push({
                    id: `${KEY_PREFIX}${exp.attribute}`,
                    op: exp.operator?.alt,
                    value: computedValue
                });
            } else if (expType === ExpressionType.METRIC) {
                let ctrlCnfg = getExpressionFromConfigSet(exp.attribute);
                if (exp.unit && exp.unit.unit) {
                    const curUnit: Unit = exp.unit;
                    const defUnit: Unit = ctrlCnfg?.unit ? ctrlCnfg.unit : curUnit;
                    if (ctrlCnfg?.type === "integer" || ctrlCnfg?.type === "float") {
                        computedValue = parseFloat(computedValue);
                        computedValue = curUnit.convert(computedValue, defUnit)
                    }
                }
                const expFormatted = {
                    id: `${METRIC_PREFIX}${exp.attribute}`,
                    op: exp.operator?.alt,
                    value: computedValue
                };
                if (selectedNode?.getProperty(LOGIC_NODE_EDIT_PROPS.DATA_TYPE) === MetricDataType.TIMESERIES) {
                    expFormatted[TS_OP] = exp.functionOperator?.alt
                }
                result.metrics.push(expFormatted)
            }
            return result;
        }, defAcc);
        return apiExp ? apiExp : {};
    }
    /**
     * Tanslates API expression format to Compare Control expression format
     * @param exps - Runbook api expression object
     * @returns  Array of Control object(expression object)
     */
    function convertExpressionToControlFormat(exps: RunBookAPIExpressions): Control[] {
        function getTranslatedExpression(exp: RunBookAPIExpression, isMetric: boolean) {
            expressionIdCtr.current++;
            const ctrlId = expressionIdCtr.current;
            const attr = exp.id.replace(isMetric ? METRIC_PREFIX : KEY_PREFIX, '');
            const lookUpOperatorMap = isMetric ? APIMetricOperatorsMap : APIKeyOperatorsMap;
            let computedValue: string | number = exp.value;
            if (lookUpOperatorMap[exp.op]?.multiInput) {
                if (Array.isArray(exp.value)) {
                    computedValue = exp.value.join(", ");
                }
            }
            let defaultUnitStr = objMetricMetaData?.metrics[attr]?.unit;
            defaultUnitStr = (defaultUnitStr && defaultUnitStr !== "none") ? defaultUnitStr : undefined
            let unit: Unit | undefined = undefined;
            if (defaultUnitStr) {
                const dUnit = Unit.parseUnit(defaultUnitStr);
                const val = parseFloat(computedValue);
                const result = dUnit.getScaledUnit(val);
                unit = result.unit;
                computedValue = val / result.scale;
            }

            let ctrl: Control = {
                id: ctrlId,
                attribute: attr ? attr : BLANK_ID,
                label: objMetricMetaData?.metrics[attr] ? objMetricMetaData.metrics[attr]?.label : BLANK_VALUE,
                operator: lookUpOperatorMap[exp.op] ? lookUpOperatorMap[exp.op] : DefaultOperators._blank_,
                type: objMetricMetaData?.metrics[attr]?.type as CompareControlTargetValueTypes,
                value: computedValue,
                unit: unit
            }
            if (exp.ts_op) {
                ctrl = { ...ctrl, ...{ functionOperator: lookUpOperatorMap[exp.ts_op] ? lookUpOperatorMap[exp.ts_op] : DefaultOperators._blank_ } };
            }
            return ctrl;
        }
        const keys = exps?.keys ? exps.keys : [];
        const metrics = exps?.metrics ? exps.metrics : [];
        const keyExpressionsComputed: Control[] = keys.map((exp) => {
            return getTranslatedExpression(exp, false);
        });
        const metricExpressionsComputed: Control[] = metrics.map((exp) => {
            return getTranslatedExpression(exp, true);
        })
        return [...keyExpressionsComputed, ...metricExpressionsComputed];
    }

    function getAttributeType(attr: string | undefined): ExpressionType | undefined {
        if (!attr) {
            return undefined;
        }

        const rootKey: string = attr.split(".")[0];
        if (objMetricMetaData) {
            if (objMetricMetaData.keys[rootKey]) {
                return ExpressionType.KEYS;
            } else if (objMetricMetaData.metrics[attr]) {
                return ExpressionType.METRIC;
            }    
        }
    }
    /**
     * Generates the drop down values for various input types
     * @param inputType - Input type as provided by the API
     * @returns  Array of Control object(expression object)
     */
    function getExpressionSetConfig(inputType: string | undefined): Array<ControlConfig> {
        if (objMetricMetaData && inputType && inputType !== BLANK_ID) {
            const expandedKeys: Array<GenericKey> = LogicNodeUtil.getExpandedKeys(objMetricMetaData, inputType);
            const keyConfigSet: ControlConfig[] = expandedKeys.map((item: any): ControlConfig => {
                return {
                    attributeId: item.id,
                    label: item.label,
                    operators: [DefaultOperators._blank_, ...Object.keys(KeyOperators).map((key) => {
                        return KeyOperators[key];
                    })],
                    type: item.type,
                    unit: undefined
                }
            })
            let fiteredMetricConfigSet = objMetricMetaData.obj_types[inputType].metrics;
            if(doNode &&  doInputType === inputType) {
                fiteredMetricConfigSet = objMetricMetaData.obj_types[inputType].metrics.filter((item)=>{
                    if(doNodeMetrics && doNodeMetrics.length>0) {
                        return doNodeMetrics.find((pItem)=> pItem=== item)
                    }
                    return false;
                })
            }
            // const metricConfigSet: ControlConfig[] = objMetricMetaData.obj_types[inputType].metrics.map((item: string): ControlConfig => {
            const metricConfigSet: ControlConfig[] = fiteredMetricConfigSet.map((item: string): ControlConfig => {
                let metricUnit: string = objMetricMetaData.metrics[item]?.unit || "";
                metricUnit = (metricUnit && metricUnit !== "none") ? metricUnit : "";
                const unit = Unit.parseUnit(metricUnit);
                return {
                    attributeId: item,
                    label: objMetricMetaData.metrics[item].label,
                    operators: [DefaultOperators._blank_, ...Object.keys(MetricOperators).map((key) => {
                        return MetricOperators[key];
                    })],
                    functionOperators: [DefaultOperators._blank_, ...Object.keys(FunctionOperators).map((key) => {
                        return FunctionOperators[key];
                    })],
                    type: objMetricMetaData.metrics[item].type as CompareControlTargetValueTypes,
                    unit: unit,
                }
            })
            return [...defaultConfSet, ...keyConfigSet, ...metricConfigSet];
        } else {
            return defaultConfSet;
        }
    }

    /**
     * Gets a ControlConfig for a given id from the list of ControlConfigs
     * @param id- control config identifier
     * @return  control config object if found in the list
     */
    function getExpressionFromConfigSet(id: string | undefined): ControlConfig | undefined {
        let expressionSet = getExpressionSetConfig(curProperties.inputType);
        const exp = expressionSet?.find((exp) => {
            return exp.attributeId === id;
        });
        return exp;
    }

    /**
     * Partially update the state of the current properties
     * @param partialProps -  logical node properties that needs to be updated
     */
    function syncProperties(partialProps: LogicNodeProperties): void {
        const updatedProperties = { ...curProperties, ...partialProps };
        setCurProperties(updatedProperties);
    }


    /**
     * Update the selected node porperties. This is invoked when done button is clicked
     * on the node editor dialog.
     * @param properties -  Properties in selected node that need to updated
     * @param selectedNode - node being editied
     * @param libraryNode - object that specifies all the editable properties for a given node.
     */
    function updateNode(properties: LogicNodeProperties, selectedNode: UniversalNode | undefined, libraryNode: NodeLibraryNode | undefined) {
        if (!selectedNode || !libraryNode || !properties) {
            console.warn("updateNode has invlaid inputs. Node update failed");
            return;
        }
        libraryNode.properties?.forEach((prop) => {
            if (properties.hasOwnProperty(prop.name)) {
                if (prop.name === LOGIC_NODE_EDIT_PROPS.EXPRESSION) {
                    const exp = convertControlExpressionToAPIFormat(properties?.expression);
                    selectedNode.setProperty(prop.name, exp);
                }
                else if (prop.name === LOGIC_NODE_EDIT_PROPS.PASSED_DATA) {
                    selectedNode.setProperty(prop.name, properties.passedData);
                }
                else if (prop.name === LOGIC_NODE_EDIT_PROPS.INPUT_TYPE) {
                    selectedNode.setProperty(prop.name, properties.inputType);
                }
                else if (prop.name === LOGIC_NODE_EDIT_PROPS.DATA_TYPE) {
                    selectedNode.setProperty(prop.name, properties.dataType)
                }
            } else {
                selectedNode.removeProperty(prop.name);
            }

        });
        let nodeId = selectedNode?.getId();
        nodeId = nodeId ? nodeId : "";
        const node = getNodeFromGraphDef(nodeId, graphDef);
        if (node) {
            node.editedByUser = true;
        }
    }
    ref.current = {
        updateNode: () => {
            updateNode(curProperties, selectedNode, libraryNode);
        },
        validate: () => {
            const errorMessages = new Array<string>();
            if (!curProperties.inputType || curProperties.inputType === BLANK_ID ) {
                errorMessages.push(STRINGS.runbookEditor.errors.logicNode.editorInputError);
            }
            if (!curProperties.expression || curProperties.expression.length < 1) {
                errorMessages.push(STRINGS.runbookEditor.errors.logicNode.editorExpError);
            } else {
                let invalidExpressionFound = false;
                curProperties.expression.forEach((exp) => {
                    if (!exp.attribute || exp.attribute === BLANK_ID || (!exp.operator || exp.operator?.id === BLANK_ID) ||
                        exp.value === "" || exp.value === undefined ||
                        (getAttributeType(exp.attribute) === ExpressionType.METRIC && curProperties.dataType === MetricDataType.TIMESERIES && (!exp.functionOperator || exp.functionOperator?.id === BLANK_ID))
                    ) {
                        invalidExpressionFound = true;
                    }
                })
                if (invalidExpressionFound) {
                    errorMessages.push(STRINGS.runbookEditor.errors.logicNode.editorInvalidExp);
                }
            }
            return errorMessages;
        }
    };



    function getOperator(id: string | undefined): Operator | undefined {
        if (id) {
            const allOperators = { ...MetricOperators, ...KeyOperators, ...FunctionOperators };
            return allOperators[id];
        }
    }

    /**
     * Creates a new Control object. This is done when a new expression is added in the editor dialog
     * @return A new Control object with unique id
     */
    function createNewExpression(): Control {
        const expId = expressionIdCtr.current + 1;
        expressionIdCtr.current = expId;
        const newExp: Control = {
            id: expId,
            attribute: defaultConfSet[0].attributeId,
            label: defaultConfSet[0].label,
            operator: DefaultOperators._blank_,
            value: "",
        }
        return newExp;
    }

    /**
     * Callback function that listens to changes to expression made in the Compare Control component.
     * It updates the state of the editor and keeps the properties in sync with the Compare Control component.
     * @param event - update contorl object that has details on the change and type
     */
    function expressionSetChanged(event: UpdatedControl) {
        const exp: Control | undefined = curProperties?.expression?.find((exp) => {
            return exp.id === event.id;
        });
        switch (event.action) {
            case Action.UPDATE:
                if (exp) {
                    if (event.attribute) {
                        let expConf = getExpressionFromConfigSet(event.attribute);
                        exp.attribute = expConf?.attributeId;
                        exp.label = expConf?.label;
                        exp.type = expConf?.type;
                        exp.unit = expConf?.unit;
                    }
                    if (event.operator) {
                        exp.operator = getOperator(event.operator);
                    }
                    if (event.fnOperator) {
                        exp.functionOperator = getOperator(event.fnOperator);
                    }
                    if (event.hasOwnProperty("value")) {
                        exp.value = event.value ? event.value : "";
                    }
                    if (event.unit) {
                        exp.unit = Unit.parseUnit(event.unit);
                    }
                    setCurProperties({ ...curProperties });
                }
                break;
            case Action.DELETE:
                if (exp) {
                    // remove expression 
                    const udpatedExps = curProperties?.expression?.filter((exp) => {
                        return exp.id !== event.id;
                    });
                    setCurProperties({ ...curProperties, ...{ expression: udpatedExps } })
                }
                break;
            case Action.CREATE:
                const newExp = createNewExpression();
                curProperties?.expression?.push(newExp);
                setCurProperties({ ...curProperties });
                break;
        }

    }
    // Wait for api call to complete before rendering the editor
    if (!objMetricMetaData) {
        return <tr><td colSpan={2}><DataLoadFacade loading/></td></tr>;
    }
    const inputTypeOptions = Object.keys(objMetricMetaData.obj_types).map((key) => {
        if (doInputType) {
            //Filter out inputTypes if the node is connected to a DO node.
            if (doInputType === key) {
                return <option key={key} label={objMetricMetaData.obj_types[key].label} value={key} />
            } else {
                return undefined;
            }
        } else {
            return <option key={key} label={objMetricMetaData.obj_types[key].label} value={key} />
        }
    }).filter((item) => item);
    return (
        <>
            <tr>
                <td className="pt-3">
                    {STRINGS.runbookEditor.nodeLibrary.propertyLabels.input}:
                    <Icon icon={IconNames.ASTERISK} intent={Intent.DANGER} size={8} />
                </td>
                <td className="pt-3">
                    <HTMLSelect
                        aria-label="input type"
                        name="inputType"
                        fill={true}
                        onChange={e => {
                            const updatedPartialProperties = {
                                inputType: e.currentTarget.value,
                                expression: [createNewExpression()]
                            }
                            syncProperties(updatedPartialProperties);
                        }}
                        value={curProperties.inputType}
                    >
                        <option key={"default"} label={STRINGS.runbookEditor.nodeEditor.defDropDownOption} value={undefined} />
                        { inputTypeOptions }
                    </HTMLSelect>
                </td>
            </tr>
            <tr>
                <td>
                    {STRINGS.runbookEditor.nodeLibrary.propertyLabels.metrics}:
                </td>
                <td className="p-1 pt-4 pb-3" colSpan={2}>
                    <RadioGroup
                        name="dataType"
                        onChange={e => {
                            const updatedPartialProperties = {
                                dataType: e.currentTarget.value as MetricDataType
                            }
                            syncProperties(updatedPartialProperties);
                        }}
                        selectedValue={curProperties.dataType}
                        inline={true}
                    >
                        <Radio label={STRINGS.runbookEditor.nodeLibrary.propertyLabels.summarized}
                            value={MetricDataType.SUMMARY} />
                        <Radio label={STRINGS.runbookEditor.nodeLibrary.propertyLabels.timeSeries}
                            value={MetricDataType.TIMESERIES} />
                    </RadioGroup>
                </td>
            </tr>
            <tr className="mt-2">
                <td colSpan={2} className="pt-3">
                    {STRINGS.runbookEditor.nodeLibrary.propertyLabels.pathText1}<b> {STRINGS.runbookEditor.nodeLibrary.propertyLabels.pathTextSuccess}</b> {STRINGS.runbookEditor.nodeLibrary.propertyLabels.pathText2}
                </td>
            </tr>
            <tr>
                <td className="p-1 pt-4 pb-3" colSpan={2}>
                    <RadioGroup
                        name="outputRows"
                        onChange={e => {
                            const updatedPartialProperties = {
                                passedData: e.currentTarget.value as PassedData
                            }
                            syncProperties(updatedPartialProperties);
                        }}
                        selectedValue={curProperties.passedData}
                        inline={true}
                    >
                        <Radio label={STRINGS.runbookEditor.nodeLibrary.propertyLabels.allRows}
                            value={PassedData.ALL} />
                        <Radio label={STRINGS.runbookEditor.nodeLibrary.propertyLabels.matchingRows}
                            value={PassedData.MATCHED} />
                    </RadioGroup>
                </td>
            </tr>
            <tr>
                <td className="" colSpan={2}>
                    {curProperties.inputType &&
                        <CompareControls controlSet={curProperties.expression} onChange={expressionSetChanged} hideFunctionOperators={curProperties.dataType === MetricDataType.SUMMARY} controlSetConfig={getExpressionSetConfig(curProperties.inputType)} />
                    }
                </td>
            </tr>
            <tr >
                <td colSpan={2} className="pt-4 pb-4">
                    {STRINGS.runbookEditor.nodeLibrary.propertyLabels.pathText3} <b> {STRINGS.runbookEditor.nodeLibrary.propertyLabels.pathTextFailure}</b> {STRINGS.runbookEditor.nodeLibrary.propertyLabels.pathText4}
                </td>
            </tr>
            {SHOW_CONTEXT && <RunbookContextSummary 
                currentProperties={JSON.parse(JSON.stringify({}))} 
                node={graphDef.nodes.find(node => node.id === selectedNode?.getId())!} graphDef={graphDef} 
                showOutputExample={true} showInputExample={true}
            />}
        </>
    )
});
