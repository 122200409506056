/** This file defines the TimeseriesTile React component.  The TimeseriesTile React component 
 *  displays a time series data along with the x-axis but no y-axis.
 *  @module */
import { useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "highcharts/modules/annotations";
import 'highcharts/modules/no-data-to-display';
import type { HighchartsData, timeSeriesTooltipFormatterParams } from "components/reporting/utils/Types.ts";
import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import { CHART_COLORS } from "components/enums/Colors.ts";
import { DEFAULT_TIME_SERIES_OPTIONS } from "components/reporting/charts/defaults/HighchartDefaults.ts";
import { convertPointsToArrays, markIsolatedPoints, type TimeseriesTileProps } from "./TimeseriesTile.tsx";
import "./TimeseriesTile.scss";

/** Renders the TimeseriesTileForDemo React component.
 *  @param props the properties passed in.
 *  @returns JSX with the TimeseriesTile React component.*/
export function TimeseriesTileForDemo(props: TimeseriesTileProps) {
    const chartRef = useRef<HighchartsReact.RefObject>(null);
    function getChartOptions() {
        let options = cloneDeep(DEFAULT_TIME_SERIES_OPTIONS);

        if (props.leadingMarker) {
            options.chart.events.load = function (this:any) {
                const [series] = this.series;
                if (series.data.length > 1) {
                    //set marker on last point
                    series.data[series.data.length - 1].update({ marker: { enabled: true } });
                }
            };
        }

        options.tooltip.formatter = function (this: timeSeriesTooltipFormatterParams) {
            if (props.tooltipFormatter) {
                return props.tooltipFormatter(this);
            } else {
                return this.y;
            }
        };

        if (props.showAsBars) {
            options.series[0].type = "column";
        }

        if (props.showAsSteps) {
            options.series[0].step = "true";
        }

        //merge chart config
        if (props.config) {
            options = merge(options, props.config);
        }
        if (props.yMin !== undefined) {
            options.yAxis.min = props.yMin;
        }
        if (props.yMax !== undefined) {
            options.yAxis.max = props.yMax;
        }
        //merge data
        if (props.chartData) {
            let tsData: HighchartsData = cloneDeep(props.chartData);
            markIsolatedPoints(tsData);
            options.series[0].data = tsData;
        }

        if (props.chartCompData) {
            let tsData: HighchartsData = cloneDeep(props.chartCompData);
            markIsolatedPoints(tsData);
            options.chart.type = 'line';
            options.series[0].type = 'line';
            options.series.unshift({
                color: new Highcharts.Color("#88C").setOpacity(0.4).get(),
                animation: {
                    duration: 500,
                },
                borderRadius: 4,
                borderColor: "transparent",
                maxPointWidth: 10,
                data: tsData
            });
        }

        if (props.thresholds && props.chartData?.length) {
            for (const threshold of props.thresholds) {
                options.series.push({
                    type: "line",
                    dashStyle: "Dash",
                    color: CHART_COLORS.THRESHOLD_DEFAULT,
                    lineWidth: 1,
                    data: Array.isArray(threshold) ? threshold : [...props.chartData].map(data => {
                        if (typeof data === "object" && (data as any).x !== undefined) {
                            return { x: (data as any).x, y: threshold };
                        } else {
                            return threshold;
                        }
                    }),
                });
            }
        }

        options.series = convertPointsToArrays(options.series);
        return options;
    }

    return <>{props.chartData && 
        <HighchartsReact
            highcharts={Highcharts}
            options={getChartOptions()}
            containerProps={{
                style: { width: "100%", height: "100%" },
            }}
            ref={chartRef}
        />
    }</>;
}
