/** This module contains the functional React component for rendering the data ocean comparison control.
 *  This control allows users to pick the comparison offset from a list of options.
 *  @module
 */

import { useState } from "react";
import { HTMLSelect, Switch } from "@blueprintjs/core";
import { STRINGS } from "app-strings";

/** This interface defines the properties passed into the data ocean comparison toggle React component.*/
export interface DataOceanComparisonProps {
    /** the current properties object with the value of all the controls in the editor. */
    currentProperties: any;
}

export const COMPARISON_LABEL_MAP = {
    "yesterday": STRINGS.runbookEditor.nodeLibrary.propertyLabels.comparisonOptionYesterday,
    "last_week": STRINGS.runbookEditor.nodeLibrary.propertyLabels.comparisonOptionLastWeek,
    "4_weeks_ago": STRINGS.runbookEditor.nodeLibrary.propertyLabels.comparisonOptionLastMonth,
}

/** Renders the component to render the data ocean comparison field.
 *  @param props the properties passed in.
 *  @returns JSX with the react data ocean comparison component.*/
export function DataOceanComparison (props: DataOceanComparisonProps): JSX.Element {
    const { currentProperties = {} } = props;

    const [comparedToEnabled, setComparedToEnabled] = useState<boolean>(currentProperties['comparedTo'] !== undefined);

    return <tr>
        <td className="ps-1" colSpan={2}>
            <Switch
                data-testid="data_ocean_comparison_switch"
                className="mb-0 me-3"
                checked={comparedToEnabled}
                disabled={false}
                inline={true}
                label={ STRINGS.runbookEditor.nodeLibrary.propertyLabels.comparison }
                onChange={(e) => {
                    const checked = e.currentTarget.checked;
                    if (checked) {
                        if (!currentProperties['comparedTo']) {
                            currentProperties['comparedTo'] = "yesterday";
                        }
                    } else {
                        delete currentProperties['comparedTo'];
                    }
                    setComparedToEnabled(checked);
                }}
            />
            <HTMLSelect
                name="time.comparisons"
                data-testid="data_ocean_comparison_select"
                fill={ false }
                defaultValue={ currentProperties['comparedTo'] || "yesterday" }
                onChange={ e => {
                    const newComparedTo = e.currentTarget.value;
                    currentProperties['comparedTo'] = newComparedTo;
                    if (!comparedToEnabled) {
                        setComparedToEnabled(true);
                    }
                } }
            >
                <option label={ COMPARISON_LABEL_MAP["yesterday"] } value="yesterday"/>
                <option label={ COMPARISON_LABEL_MAP["last_week"] } value="last_week"/>
                <option label={ COMPARISON_LABEL_MAP["4_weeks_ago"] } value="4_weeks_ago"/>
            </HTMLSelect>
        </td>
    </tr>;
}
 