/** This module contains the functional React component for rendering the data ocean group by control.
 *  This control allows users to select the group by list for queries where the group by can be set.
 *  @module
 */

import React, { useEffect, useState } from "react";
import { HELP, STRINGS } from "app-strings";
import { MultiSelectInput } from "components/common/multiselect/MultiSelectInput.tsx";
import type { DataOceanKey } from "components/common/graph/editors/data-ocean/DataOceanMetadata.type.ts";
import { type GenericKey, NodeUtils } from "utils/runbooks/NodeUtil.ts";
import { DataOceanUtils } from "components/common/graph/editors/data-ocean/DataOceanUtils.ts";
import { InlineHelp } from "components/common/layout/inline-help/InlineHelp.tsx";

/** This interface defines the properties passed into the data ocean group by React component.*/
export interface DataOceanGroupByProps {
    /** the current node type, can be either data_ocean or data_ocean_dynamic. */
    type: string;
    /** the current properties object with the value of all the controls in the editor. */
    currentProperties: any;
    /** the list of group bys for the current object type. */
    selectedTypeGroupBy: string[] | undefined;
    /** the list of data ocean metrics. */
    groupByMap: Record<string, DataOceanKey>;
    /** the handler for the group by change event. */
    onGroupByChanged: () => void;
}

/** Renders the component to render the data ocean metric toggle field.
 *  @param props the properties passed in.
 *  @returns JSX with the react data ocean metric toggle component.*/
export function DataOceanGroupBy(props: DataOceanGroupByProps): JSX.Element | null {
    const { 
        currentProperties, selectedTypeGroupBy, groupByMap, onGroupByChanged
    } = props;
    const [groupBys, setGroupBys] = useState(new Array<{ label: string, value: string }>());
    const [selectedGroupBys, setSelectedGroupBys] = useState(new Array<{ label: string, value: string }>());
    useEffect(() => {
        let availableGroupBys: {value: string, label: string}[] = [];
        const selectedObjectType = DataOceanUtils.dataOceanMetaData.obj_types[currentProperties['objType']];
        if (selectedObjectType.group_by_required) {
            const expandedKeys: GenericKey[] = NodeUtils.getExpandedKeysForAllGroupBys(DataOceanUtils.dataOceanMetaData, currentProperties['objType']);
            availableGroupBys = (expandedKeys || []).map(
                (keyDef) => {
                    return {value: keyDef?.id, label: keyDef?.label};
                }
            );
            setGroupBys(availableGroupBys);
            if (currentProperties['groupBy'] && currentProperties['groupBy'].length) {
                const selectedGroupBys: any[] = [];
                currentProperties["groupBy"].forEach((expandedId) => {
                    const keyDef = expandedKeys.find((keyDef) => {
                        return keyDef.id === expandedId;
                    });
                    selectedGroupBys.push({value: keyDef?.id, label: keyDef?.label});
                })
                setSelectedGroupBys(selectedGroupBys);
            }
        } else if (selectedObjectType?.group_by?.length) {
            // This is what we did for the old NPM+ demo.  We need this to keep the workspace demo working
            availableGroupBys = (selectedTypeGroupBy || []).map((groupById) => {return {value: groupById, label: groupByMap[groupById].label}});
            setGroupBys(availableGroupBys);

            if (currentProperties['groupBy'] && currentProperties['groupBy'].length) {
                setSelectedGroupBys(currentProperties["groupBy"].map((groupById) => {return {value: groupById, label: groupByMap[groupById].label}}));
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProperties, currentProperties.objType, groupByMap]);


    if (!groupBys.length) {
        return null;
    }

    const doHelpKey: string = props.type !== "data_ocean_dynamic" ? currentProperties.objType : "dynamic_traffic";

    return (
        <React.Fragment>
            <tr><td className="font-size-md-large fw-bold pt-2" colSpan={2}>
                <InlineHelp
                    helpMapping={HELP.RunbookNodeCategory.Dataquery[doHelpKey.replace('.', '_')]?.groupBy}
                >
                    {STRINGS.runbookEditor.nodeLibrary.nodes.data_ocean.labels.groupBySection}
                </InlineHelp>
            </td></tr>
            <tr>
                <td colSpan={2}>
                    <div data-testid="data_ocean_group_bys">
                        <MultiSelectInput
                            sortable
                            items={ groupBys.map(groupBy => {
                                return { display: groupBy.label, value: groupBy.value }
                            }) }
                            selectedItems={ selectedGroupBys.map(groupBy => {
                                return { display: groupBy.label, value: groupBy.value }
                            }) }
                            onChange={ updatedValues => {
                                currentProperties['groupBy'] = updatedValues ? updatedValues.map(item => {
                                    return item.value
                                }) : [];
                                onGroupByChanged();
                            } }
                            placeholder={ STRINGS.runbookEditor.nodeLibrary.propertyLabels.metricPlaceHolder }
                            disabled={groupBys.length === 1 && selectedGroupBys.length > 0}
                        />
                    </div>
                </td>
            </tr>
        </React.Fragment>
    );
}
