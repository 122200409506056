import { formatToLocalTimestamp } from "reporting-infrastructure/utils/formatters/GeneralFormatter.ts";
import { TIME_FORMAT } from "components/enums/Time.ts";

export interface TimeFormatterProps {
    /** Time in JavaScript Date format that is to be formatted */
    time?: Date;
    /** hides date if time passed is current*/
    concise ?: Boolean;
    /** Optional time format to be used for formatting the actual time information (if displayed) */
    format?: string;
}

export function TimeFormatter ({
    time,
    concise = true,
    format = TIME_FORMAT.DISPLAY_TIME_FORMAT
}: TimeFormatterProps) {
    if (time) {
        // DISPLAY_TIME_ONLY_FORMAT
        return <div className="d-inline-block">
            {
                <div className="display-10 opacity-100 actual-value"  aria-label='formatted timestamp' >{formatToLocalTimestamp(time, format)}</div>
            }
        </div>;
    } else {
        return null;
    }
}
