/** This module defines a React component that displays the standard search result.
 *  @module
 */
import { useState } from "react";
import { type SearchItem, USE_MOCK_DATA } from "components/common/search/search-items/search-results-item/SearchResultsItem.tsx";
import { SearchItemHeader } from "components/common/search/search-item-header/SearchItemHeader.tsx";
import { SearchItemDesc } from "components/common/search/search-item-desc/SearchItemDesc.tsx";
import { type LinkInfo, LinksGroup } from "components/common/search/search-links-group/LinksGroup.tsx";
import type { Samples, Severity } from "types/types.ts";
import { getPerformaceChartData } from "reporting-infrastructure/utils/formatters/GeneralFormatter.ts";
import { SEVERITY } from "components/enums/Severity.ts";
import { SparklineTile } from 'components/reporting/tiles/sparkline-tile/SparklineTile.tsx';
import { MetricThresholds } from "utils/conf/Thresholds.ts";
import { HealthInfo } from "components/common/search/search-health-info/HealthInfo.tsx";
import { Icon } from "@tir-ui/react-components";
import { APP_ICONS } from "components/sdwan/enums/icons.ts";

/** this interface defines the properties passed into the search standard item component. */
export interface SearchStandardItemProps {
    /** the SearchItem with the data to display in the search result. */
    data: SearchItem;
    /** any time series data to display in the search result. */
    timeseriesData?: Array<Samples>;
    /** the Severity object with the severity/priority if any. */
    severity?: Severity;
    /** the links to be displayed in the search result. */
    links?: Array<LinkInfo>;
    /** a boolean value, if true display the charts, otherwise do not display the charts. */
    showCharts?: boolean;
    /** a boolean value, if true display the health information, otherwise do not display the health information. */
    showHealth?: boolean;
    /** a boolean value, if true expand the additional info and make it visible, if false keep it collapsed. */
    expandAdditionalInfo?: boolean;
    /** additional information to display in the search results. */
    additionalInfo?: JSX.Element;
}

/** Renders the standard search result.
 *  @param data the properties passed in.
 *  @returns JSX with the standard search result react component.*/
export function SearchStandardItem(
    { data, timeseriesData, links, severity, showCharts = true, showHealth = true, additionalInfo = undefined, expandAdditionalInfo = false }: SearchStandardItemProps
) {
    let chartData = (showCharts && timeseriesData) ? getPerformaceChartData(USE_MOCK_DATA ? getMockedTimeSeriesPerformanceData() : timeseriesData) || [] : undefined;
    let healthScore = showHealth ? severity : undefined;
    const [showAdditionalInfo, setShowAdditionalInfo] = useState<boolean>(Boolean(expandAdditionalInfo));

    // TODO Can be removed after demo.
    chartData = USE_MOCK_DATA && showCharts ? getPerformaceChartData(getMockedTimeSeriesPerformanceData()) : chartData;
    healthScore = USE_MOCK_DATA && showHealth ? getMockSeverity() : severity;

    return <>
        <div className="d-flex flex-column flex-grow-2" key={data.id}>
            <SearchItemHeader type={data.type} severity={severity} title={data.formattedData.title ? data.formattedData.title : ""} />
            <SearchItemDesc>
                {data.formattedData.description ? data.formattedData.description : ""}
            </SearchItemDesc>
            {additionalInfo && <div className="mt-2 mb-2" onClick={() => {
                    setShowAdditionalInfo(!showAdditionalInfo);
                }}
            >
                <Icon icon={showAdditionalInfo ? APP_ICONS.SECTION_OPEN : APP_ICONS.SECTION_CLOSED}/>
                <span className="text-capitalize">{"Additional information"}</span>
            </div>}
            {showAdditionalInfo && additionalInfo && <div className="ms-4">
                {additionalInfo}
            </div>}
            {links &&
                <LinksGroup links={links} />
            }
        </div>
        {
            (chartData && chartData.length > 0) &&
            <div className="ms-1 d-flex flex-row  justify-content-center">
                <div className="line-chart">
                    <SparklineTile chartData={chartData}
                        thresholds={[MetricThresholds.Overlay.Performance.value]}
                        leadingMarker={false}
                        config={{ chart: { height: 60 } }}
                    />
                </div>
            </div>
        }
        {healthScore &&
            <HealthInfo severity={healthScore} />
        }
    </>
}

/// TODO Mock data To be removed after DEMO
export function getMockedTimeSeriesPerformanceData() {
    let result = [
        {
            "timestamp": 1619186400000,
            "severity": {
                "score": 0,
            },
        },
        {
            "timestamp": 1619187300000,
            "severity": {
                "score": 10,
            },
        },
        {
            "timestamp": 1619188200000,
            "severity": {
                "score": 11,
                "__typename": "Severity"
            },
            "__typename": "ApplicationSampleType"
        },
        {
            "timestamp": 1619189100000,
            "severity": {
                "score": 12,
                "__typename": "Severity"
            },
            "__typename": "ApplicationSampleType"
        },
        {
            "timestamp": 1619190000000,
            "severity": {
                "score": 23,
                "__typename": "Severity"
            },
            "__typename": "ApplicationSampleType"
        },
        {
            "timestamp": 1619190900000,
            "severity": {
                "score": 34,
                "__typename": "Severity"
            },
            "__typename": "ApplicationSampleType"
        },
        {
            "timestamp": 1619191800000,
            "severity": {
                "score": 56,
                "__typename": "Severity"
            },
            "__typename": "ApplicationSampleType"
        },
        {
            "timestamp": 1619192700000,
            "severity": {
                "score": 33,
                "__typename": "Severity"
            },
            "__typename": "ApplicationSampleType"
        },
        {
            "timestamp": 1619193600000,
            "severity": {
                "score": 20,
                "__typename": "Severity"
            },
            "__typename": "ApplicationSampleType"
        }
    ]
    return result.map((item) => {
        item.severity.score = getRandomInt(0, 100);
        return item;
    });
}

export function getMockSeverity() {
    return {
        score: getRandomInt(0, 100),
        value: SEVERITY.NORMAL
    }
}

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}
