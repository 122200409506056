// TODO: THESE ARE NOT HOOKS THEY NEED TO MOVE TO THE GENERAL UTILITY DIRECTORY NOT THE
// HOOKS DIRECTORY

/** Parse the date & time information returned by DAL which is in <seconds>.<nanoseconds> format
 * and return the actual date and time as a Date type. If an invalid input was provided, it will
 * return undefined. This method can also understand other formats that don't conform to the
 * above-mentioned DAL format -> If the input is already a Date type. If the input is a UNIX timestamp.
 * If the input is of the DAL format but the <nanoseconds> bit does not contain the entire 9 digits.
 */
export function parseTimeFromDAL(timeString?: Date | string | number): Date | undefined {
    let output: Date | undefined;

    if (timeString) {
        // If input provided was already of date type
        if (timeString instanceof Date) {
            output = timeString;
            // If input provided is a number with millisecond precision (Deprecated format)
        } else {
            const prasedUnixTimestamp = parseTimeFromDALToUnix(timeString);
            if (prasedUnixTimestamp) {
                output = new Date(prasedUnixTimestamp);
            }
        }

        if (typeof timeString === 'number' && timeString.toString().length === 13) {
            output = new Date(timeString);
        } else {
            const [dateAndTimeToSeconds, subSeconds] = String(timeString).split('.');
            const [
                milliSeconds,
                // nanoSeconds,
            ] = subSeconds ? [subSeconds.substring(0, 3), subSeconds.substring(3)] : ['000', '000000'];
            output = new Date(Number(dateAndTimeToSeconds + milliSeconds));
        }
    }
    // If no timestring was provided or output is not a valid date
    if (timeString && output?.getTime && !isNaN(output.getTime())) {
        return output;
    } else {
        return undefined;
    }
}

/** Parse date & time information returned by DAL which is in <seconds>.<nanoseconds>
 * format and return the actual timestamp in UNIX millisecond format
 */
export function parseTimeFromDALToUnix(timeString?: string | number): number | undefined {
    let output: number | undefined;

    if (timeString) {
        // If input provided is a number with millisecond precision (Deprecated format)
        if (typeof timeString === 'number' && timeString.toString().length === 13) {
            output = timeString;
        } else {
            const [dateAndTimeToSeconds, subSeconds] = String(timeString).split('.');
            const [
                milliSeconds,
                // nanoSeconds,
            ] = subSeconds ? [subSeconds.substring(0, 3), subSeconds.substring(3)] : ['000', '000000'];
            const outputInStringFormat = dateAndTimeToSeconds + milliSeconds;
            const parsedForOutput = Number(outputInStringFormat);
            // If final output isn't 13 digits long, something is wrong!
            if (!isNaN(parsedForOutput) && outputInStringFormat.length === 13) {
                output = parsedForOutput;
            }
        }
    }
    return output;
}

/** Parse duration information returned by DAL which is in <seconds>.<nanoseconds>
 * format and return the duration as a number in milliseconds
 */
export function parseDurationFromDAL(timeString?: string | number): number | undefined {
    let output: number | undefined;

    if (timeString) {
        const [durationInSeconds, subSeconds] = String(timeString).split('.');
        const [
            milliSeconds,
            // nanoSeconds,
        ] = subSeconds ? [subSeconds.substring(0, 3), subSeconds.substring(3)] : ['000', '000000'];
        const parsedForOutput = Number(durationInSeconds + milliSeconds);
        if (!isNaN(parsedForOutput)) {
            output = parsedForOutput;
        }
    }
    return output;
}

/** Normalize an input dateTime which can be of either a UNIX timestamp format string or number or
 * the DAL's <seconds>.<nanoseconds> format to the final <seconds>.<nanoseconds> format. This can be
 * used when passing filters to DAL queries.
 */
export function formatDateForDAL(dateTimeInput: string | number) {
    const dateTimeString = String(dateTimeInput);
    let [dateTimeTillSeconds, dateTimeAfterSeconds] = dateTimeString.includes('.')
        ? dateTimeString.split('.')
        : [dateTimeString.substring(0, 10), dateTimeString.substring(10)];
    dateTimeAfterSeconds = dateTimeAfterSeconds || '000000000';
    while (dateTimeAfterSeconds.length < 9) {
        dateTimeAfterSeconds = dateTimeAfterSeconds + '0';
    }
    return dateTimeTillSeconds + '.' + dateTimeAfterSeconds;
}
