/** This module defines a hook that can be used to run search queries for the.
 *  @module
 */
import React, { useCallback, useRef } from 'react';
import type { UseQueryRunProps } from './useQuery.ts';
import Fuse from "fuse.js";
import { SearchItemType, type SearchResultItem } from 'components/common/search/search-results-table/SearchResultsTable.tsx';
import { formatToLocalTimestamp } from 'reporting-infrastructure/utils/formatters/GeneralFormatter.ts';
import { ApiCallStatus } from 'components/enums/General.ts';
import { runbookService, getRunbookMetaData } from 'utils/runbooks/RunbookUtils.ts';
import { useStateSafePromise } from "@tir-ui/react-components";
import { FIELDS, type SearchItem, type SearchRequest, type SearchResult, FACET_FIELDS, ORDERS } from 'utils/services/SearchApiService.ts';
import { Variant } from 'components/common/graph/types/GraphTypes.ts';
import { useApolloClient } from "@apollo/client";
import { SearchGraphqlApiService } from "utils/services/search/SearchGraphqlApiService.ts";
import { hideSubflowsWithFactoryResourceNames } from "utils/runbooks/RunbookUtils.ts";

/** this interface defines the object that assigns a weight ot an attribute. */
interface AttributeWeights {
    /** a string with the name of the attribute in the format a.b.c. */
    name: string;
    /**  a number with the weight assigned to the attribute. */
    weight: number;
}

/** Weights associated with specific attributes that are searchable. This dictates how the search results are ranked.
 *  larger the weight assigned to an attribute higher the rank when there is a match found. */
const searchItemAttributeWeights: Array<AttributeWeights> = [
    {
        name: "resultItem.formattedData.name",
        weight: 3
    },
    {
        name: "resultItem.id",
        weight: 3
    },
    {
        name: "resultItem.formattedData.associatedInfoL1",
        weight: 1
    },
    {
        name: "resultItem.formattedData.name",
        weight: 3
    },
    {
        name: 'resultItem.device.cityName',
        weight: 2
    },
    {
        name: "resultItem.formattedData.description",
        weight: 2
    },
    {
        name: 'resultItem.inc.entity.name',
        weight: 3
    },
];

/** SearchResponse object that is returned by the hook.*/
export interface SearchResponse {
    /** Indicates the overall status for all the api calls combined.*/
    state: ApiCallStatus,
    /** a String with any error information. */
    error: string,
    /** Array of searchable items in a normalized format.*/
    data: Array<SearchResultItem>
}

/** This hook is responsible for making the api calls to various servcies to fetch searchable data. The data is the
 *  filtered by the search term and ranked using the Fuse.js module.
 *  @returns An array with the first element being the SearchResponse and the second element is the search Function
 *      whose invocation triggers search operation*/
export const useSearch = (searchText: string): [SearchResponse, Function] => {
    const [searchResponse, setSearchResponse] = React.useState<SearchResponse>({
        state: ApiCallStatus.LOADING,
        error: '',
        data: [],
    });
    
    const incidentRunBookResultsAvailable = useRef<boolean>(false);
    const lifecycleRunBookResultsAvailable = useRef<boolean>(false);
    const subflowRunBookResultsAvailable = useRef<boolean>(false);
    const onDemandRunBookResultsAvailable = useRef<boolean>(false);
    // const cognitiveSearchResultsAvailable = useRef<boolean>(false);
    const dalServiceResultAvailable = useRef<boolean>(false);

    const fuseSearchResults = useRef<Array<SearchResultItem>>([]);
    // const cognitiveSearchResults = useRef<Array<SearchResultItem>>([]);

    const apolloClient = useApolloClient();
    const searchGraphqlService = useRef<SearchGraphqlApiService>(new SearchGraphqlApiService(apolloClient));

    const updateData = useCallback((newData) => {
        setSearchResponse({ ...searchResponse, ...newData })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    const [executeSafely] = useStateSafePromise();

    // There are multiple sources(currently two) of data that needs to be serached on.
    // Once we receive the response from all the sources Consolidates results into a single dataset.
    const consolidateResults = useCallback(() => {
        // Check if wehave responses for all the services prior to ranking them.
        //if (runBookResultsAvailable.current && dalServiceResultAvailable.current && cognitiveSearchResultsAvailable.current) {
        if (incidentRunBookResultsAvailable.current && lifecycleRunBookResultsAvailable.current && subflowRunBookResultsAvailable.current && onDemandRunBookResultsAvailable.current && dalServiceResultAvailable.current) {
            const fuseConfig = {
                includeScore: true,
                shouldSort: false,
                minMatchCharLength: 1,
                ignoreLocation: true,
                keys: searchItemAttributeWeights,
                threshold: 0.0
            }
            const fuse = new Fuse(fuseSearchResults.current, fuseConfig);
            const fuzzySearchResults = fuse.search(searchText);
            const searchResults = fuzzySearchResults.map((fuzzyItem: any) => {
                fuzzyItem.item.resultItem.meta = { fuzzyScore: fuzzyItem.score };
                return { resultItem: fuzzyItem.item.resultItem }
            });

            // Combine the fuse search results with the cognitive search results
            // const combinedSearchResults: Array<SearchResultItem> = [...searchResults, ...cognitiveSearchResults.current];

            // Call update data which will call setSearchResponse which is the useState hook which will re-render the component
            updateData({
                state: ApiCallStatus.SUCCESS,
                data: searchResults
            });

            // Clear all the search data
            incidentRunBookResultsAvailable.current = false;
            lifecycleRunBookResultsAvailable.current = false;
            subflowRunBookResultsAvailable.current = false;
            onDemandRunBookResultsAvailable.current = false;
            // cognitiveSearchResultsAvailable.current = false;
            dalServiceResultAvailable.current = false;
            fuseSearchResults.current = [];
            // cognitiveSearchResults.current = [];
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateData, searchText]);

    // This effect is triggered once we have a response from the DAL api. The data is then normalized so that
    // it can be fed to the search results table.
    // Use consolidated search instead of DAL
    /*
    useEffect(() => {
        if (searchDALQuery.data) {
            dalServiceResultAvailable.current = true;
            const dalResult = searchDALQuery.data;
            let normalizedResult: Array<any> = [];*/
            /* hidding per [#6657, #6658]
            if (dalResult.sites && dalResult.sites.nodes) {
                const sites = dalResult.sites.nodes.map((site) => {
                    const relatedHostsIds = site.hosts?.reduce((acc, host) => {
                        const hostName = host.name ? `${host.name} ${host.id}` : host.id;
                        return `${acc} ${hostName},`;
                    }, "");
                    let descText = site.severity ? `${STRINGS.SEARCH.descSitePerf} ${site.severity.value}.` : "";
                    descText = (relatedHostsIds && relatedHostsIds.length > 1) ? `${descText} ${STRINGS.SEARCH.descDevList} ${relatedHostsIds}` : descText;
                    return {
                        resultItem: {
                            id: site.id,
                            type: SearchItemType.SITE,
                            formattedData: {
                                name: site.name,
                                description: descText,
                                title: site.name || site.id,
                                associatedInfoL1: relatedHostsIds,
                            },
                            site: site
                        }
                    }
                });
                normalizedResult = [...normalizedResult, ...sites];
            }
            if (dalResult.apps && dalResult.apps.nodes) {
                const apps = dalResult.apps.nodes.map((app) => {
                    const descText = app.severity ? `${STRINGS.SEARCH.descAppPerf} ${app.severity.value}.` : "";
                    return {
                        resultItem: {
                            id: app.id,
                            type: SearchItemType.APP,
                            formattedData: {
                                title: app.id,
                                description: descText
                            },
                            app: app
                        }
                    }
                });
                normalizedResult = [...normalizedResult, ...apps];
            }
            if (dalResult.devices && dalResult.devices.nodes) {
                const devices = dalResult.devices.nodes.map((device) => {
                    let descText = device.severity ? `${STRINGS.SEARCH.descDevPerf} ${device.severity.value}.` : "";
                    descText = descText + (device.cityName ? ` ${STRINGS.SEARCH.descDevLoc} ${device.cityName} ${STRINGS.SEARCH.descStatus} '${device.reachability}'.` : "");
                    descText = descText + (device.ip ? ` ${STRINGS.SEARCH.descTextIp} ${device.ip}.` : "");
                    descText = descText + (device.model ? ` ${STRINGS.SEARCH.descTextMod} ${device.model}.` : "");
                    descText = descText + (device.version ? ` ${STRINGS.SEARCH.descTextVer} ${device.version}.` : "");
                    const associatedInfoL1 = `${device.cityName} ${device.ip} ${device.model} ${device.version}`;
                    return {
                        resultItem: {
                            id: device.id,
                            type: SearchItemType.DEVICE,
                            device: device,
                            formattedData: {
                                title: device.id,
                                description: descText,
                                associatedInfoL1: associatedInfoL1,
                            }
                        }
                    }
                });
                normalizedResult = [...normalizedResult, ...devices];
            }
            */
            // Use consolidated search instead of DAL
            /*if (dalResult.inc?.nodes) {
                const incidents = dalResult.inc.nodes.map((incident) => {
                    const earliestTrigger = incident.earliestDetection ? formatToLocalTimestamp(parseTimeFromDAL(incident.earliestDetection)) : "";
                    //const durationInMilliSeconds = parseDurationFromDAL(incident.duration);
                    //const duration = durationInMilliSeconds ?  formatDurationToString(durationInMilliSeconds/1000) : "";
                    const [firstTrigger] = incident.triggers || [];
                    const type =  firstTrigger?.entity.kind=== "network_interface" ? STRINGS.interface : "unknown"
                    const descStrings: Array<string> =[];
                    if(incident.description) {
                        descStrings.push(`${incident.description}`);
                    }
                    descStrings.push(`${STRINGS.SEARCH.descIncText1} ${firstTrigger?.entity.name} ${STRINGS.SEARCH.descIncText2} ${type}`);
                    if (earliestTrigger) {
                        descStrings.push(`${STRINGS.SEARCH.descIncText3} ${earliestTrigger}`);
                    }
                    //if (duration) {
                    //    descStrings.push(`${STRINGS.SEARCH.descDuration}  ${earliestTrigger}`);
                    //}
                    const descText = descStrings.join('. ');
                    return {
                        resultItem: {
                            id: incident.id,
                            type: SearchItemType.INCIDENT,
                            formattedData: {
                                title: incident.description,
                                description: descText
                            },
                            incident: incident
                        }
                    }
                });
                normalizedResult = [...normalizedResult, ...incidents];
            }
            fuseSearchResults.current = [...fuseSearchResults.current, ...normalizedResult];
            consolidateResults();
        }
    }, [searchDALQuery.data, consolidateResults]);*/

    // This effect is triggered once we have a response from the Runbook api. The data is then normalized so that
    // it can be fed to the search results table.
    // useEffect(() => {
    //     if (runBookData) {
    //         const runBookResult = runBookData ? runBookData.data : [];
    //         const filteredRunbooks = runBookResult.filter((rb) => {
    //             return rb.type === "tab";
    //         });
    //         const normalizedResult = filteredRunbooks.map((rb) => {
    //             return {
    //                 resultItem: {
    //                     id: rb.id,
    //                     type: SearchItemType.RUNBOOK,
    //                     formattedData: {
    //                         title: rb.label,
    //                         name: rb.label,
    //                         description: rb.info
    //                     },
    //                     runbook: rb
    //                 }
    //             }
    //         });
    //         runBookResultsAvailable.current = true;
    //         consolidatedSearchResults.current = [...consolidatedSearchResults.current, ...normalizedResult];
    //         consolidateResults();
    //     }
    // }, [runBookData, consolidateResults])

    const fetchRunbooks = useCallback(
        (variant: Variant) => {
            return executeSafely(runbookService.getRunbooks(variant)).then((runBookData: any) => {
                // setRunbooksList(flows.map(flow => getRunbookRenders(flow, operationCount)));
                if (runBookData) {
                    const filteredRunBookData = runBookData?.filter(item => !hideSubflowsWithFactoryResourceNames.includes(item.factoryResourceName));
                    const normalizedResult: Array<SearchResultItem> = filteredRunBookData.map((runbook) => {
                        const {name, description, trigger, createdTime} = getRunbookMetaData(runbook);
                        const fmtDescription = `${description ? description + ".  " : ""}Triggered by ${trigger}. Created on ${createdTime ? formatToLocalTimestamp(createdTime) : "-"}`;
                        return {
                            resultItem: {
                                id: runbook.id,
                                type: SearchItemType.RUNBOOK,
                                formattedData: {
                                    title: name,
                                    name: name,
                                    description: fmtDescription
                                },
                                runbook: runbook
                            }
                        }
                    });
                    fuseSearchResults.current = [...fuseSearchResults.current, ...normalizedResult];
                }
                switch (variant) {
                    case Variant.INCIDENT:
                        incidentRunBookResultsAvailable.current = true;
                        break;
                    case Variant.LIFECYCLE:
                        lifecycleRunBookResultsAvailable.current = true;
                        break;
                    case Variant.SUBFLOW:
                        subflowRunBookResultsAvailable.current = true;
                        break;
                    case Variant.ON_DEMAND:
                        onDemandRunBookResultsAvailable.current = true;
                        break;
                }
                consolidateResults();
            }, error => {
                error.current = true;
                console.error(error);
                switch (variant) {
                    case Variant.INCIDENT:
                        incidentRunBookResultsAvailable.current = true;
                        break;
                    case Variant.LIFECYCLE:
                        lifecycleRunBookResultsAvailable.current = true;
                        break;
                    case Variant.SUBFLOW:
                        subflowRunBookResultsAvailable.current = true;
                        break;
                    case Variant.ON_DEMAND:
                        onDemandRunBookResultsAvailable.current = true;
                        break;
                }
                consolidateResults();
                // setRunbooksList([]);
            });
        },
        [ executeSafely, consolidateResults ]
    );

    /*const runCognitiveSearch = useCallback(
        () => {
            const searchRequest: SearchRequest = {
                search: searchText + "*", top: 10000,
                highlightPreTag: "<b>", highlightPostTag: "</b>", 
//                orderby: {field: FIELDS.devIpaddr, order: ORDERS.asc},
//                searchFields: [FIELDS.devIpaddr, FIELDS.devName],
//                select: [FIELDS.type, FIELDS.network_device],
//                filter: [FIELDS.network_device]
                searchFields: [
                    FIELDS.incidentDesc, 
                    // Search incidents with device name, ip address and location
                    FIELDS.incidentTriggeredOnIfcName, FIELDS.incidentTriggeredOnIfcIpAddr, FIELDS.incidentTriggeredOnIfcLocName, 
                    // Search incidents with interface name, ip address, and location
                    FIELDS.incidentTriggeredOnDevName, FIELDS.incidentTriggeredOnDevIpAddr, FIELDS.incidentTriggeredOnDevLocName,
                    // Search incidents with application name and location name
                    FIELDS.incidentTriggeredOnAppLocAppName, FIELDS.incidentTriggeredOnAppLocLocName,
                    // Search incidents with device name, ip address and location
                    FIELDS.incidentIndEntityIfcName, FIELDS.incidentIndEntityIfcIpAddr, FIELDS.incidentIndEntityIfcLocName, 
                    // Search incidents with interface name, ip address, and location
                    FIELDS.incidentIndEntityDevName, FIELDS.incidentIndEntityDevIpAddr, FIELDS.incidentIndEntityDevLocName,
                    // Search incidents with application name and location name
                    FIELDS.incidentIndEntityAppLocAppName, FIELDS.incidentIndEntityAppLocLocName,
                    FIELDS.incidentImpactApp, FIELDS.incidentImpactLoc, FIELDS.incidentImpactUser, 
                    //FIELDS.devIpaddr, FIELDS.devName, FIELDS.appName, FIELDS.ifcName, FIELDS.ifcIpaddr, 
                    //FIELDS.triggeredOnDevIpaddr, FIELDS.triggeredOnDevName, FIELDS.triggeredOnDevLocName,
                    //FIELDS.triggeredOnAppLocAppName, FIELDS.triggeredOnAppLocLocName, 
                    //FIELDS.triggeredOnIfcName, FIELDS.triggeredOnIfcIpaddr, FIELDS.triggeredOnIfcLocName,
                    FIELDS.indicatorEntityDevIpAddr, FIELDS.indicatorEntityDevName, FIELDS.indicatorEntityDevLocName,
                    FIELDS.indicatorEntityAppLocAppName, FIELDS.indicatorEntityAppLocLocName, 
                    FIELDS.indicatorEntityIfcName, FIELDS.indicatorEntityIfcIpAddr, FIELDS.indicatorEntityIfcLocName,
                    FIELDS.impactedUserName, FIELDS.impactedUserIpaddr, FIELDS.impactedApplicationName, FIELDS.impactedLocationName
                ],
                select: [
                    FIELDS.type, FIELDS.incident,  
                    //FIELDS.network_device, FIELDS.network_interface, FIELDS.application, 
                    //FIELDS.triggeredOn,
                    FIELDS.indicator,
                    FIELDS.impactedUser, FIELDS.impactedApplication, FIELDS.impactedLocation
                ],
                //filter: [FIELDS.incident, FIELDS.network_device, FIELDS.network_interface, FIELDS.application, FIELDS.impactedUser, FIELDS.impactedApplication, FIELDS.impactedLocation]
            };
            return executeSafely(SearchService.search(searchRequest)).then((searchResult: SearchResult<SearchItem>) => {
                // setRunbooksList(flows.map(flow => getRunbookRenders(flow, operationCount)));
                if (searchResult) {
                    const items: Array<SearchItem> = searchResult.value;
                    const normalizedResult: Array<SearchResultItem> = items.map((item: SearchItem): SearchResultItem => {
                        const searchType = item.type;
                        let objKey: string = "";
                        let obj: any = item[searchType];
                        let searchItemType: SearchItemType = SearchItemType.APPLICATION;
                        let id = typeof item[searchType] === "string" ? item[searchType] : (item as any)[searchType]?.uuid;
                        let title = typeof item[searchType] === "string" ? item[searchType] : (item as any)[searchType]?.name;
                        let name = typeof item[searchType] === "string" ? item[searchType] : (item as any)[searchType]?.name;
                        let desc = "";
                        switch (searchType) {
                            case FIELDS.triggeredOn:
                                if (obj.network_device) {
                                    searchItemType = SearchItemType.DEVICE;
                                    objKey = "device";
                                    obj = obj.network_device;  
                                } else if (obj.network_interface) {
                                    searchItemType = SearchItemType.INTERFACE;
                                    objKey = "interface";    
                                    obj = obj.network_interface;  
                                } else if (obj.application_location) {
                                    searchItemType = SearchItemType.APPLICATION;
                                    objKey = "application";    
                                    obj.application_location.application.reportedBy = obj.application_location.reportedBy;
                                    obj.application_location.application.location = obj.application_location.location;
                                    obj = obj.application_location.application;  
                                    // Use this to test location search until the back-end exposes it
                                    // searchItemType = SearchItemType.LOCATION;
                                    // objKey = "location";    
                                    // obj.application_location.location.reportedBy = obj.application_location.reportedBy;
                                    // obj.application_location.location.application = obj.application_location.application;
                                    // obj = obj.application_location.location;
                                }
                                id = obj.uuid;
                                title = obj.name;
                                name = obj.name;
                                break;
                            case FIELDS.indicator:
                                if (obj.entity.network_device) {
                                    searchItemType = SearchItemType.DEVICE;
                                    objKey = "device";
                                    obj = obj.entity.network_device;  
                                } else if (obj.entity.network_interface) {
                                    searchItemType = SearchItemType.INTERFACE;
                                    objKey = "interface";    
                                    obj = obj.entity.network_interface;  
                                } else if (obj.entity.application_location) {
                                    searchItemType = SearchItemType.APPLICATION;
                                    objKey = "application";
                                    obj.application_location = {};
                                    obj.application_location.application = obj.entity.application_location.application;
                                    obj.application_location.application.reportedBy = obj.entity.application_location.reportedBy;
                                    obj.application_location.application.location = obj.entity.application_location.location;
                                    obj = obj.application_location.application;  
                                    // Use this to test location search until the back-end exposes it
                                    // searchItemType = SearchItemType.LOCATION;
                                    // objKey = "location";    
                                    // obj.application_location.location.reportedBy = obj.application_location.reportedBy;
                                    // obj.application_location.location.application = obj.application_location.application;
                                    // obj = obj.application_location.location;
                                }
                                id = obj.uuid;
                                title = obj.name;
                                name = obj.name;
                                break;
                            case FIELDS.network_device:
                                searchItemType = SearchItemType.DEVICE;
                                objKey = "device";
                                break;
                            case FIELDS.network_interface:
                                searchItemType = SearchItemType.INTERFACE;
                                objKey = "interface";
                                break;
                            case FIELDS.application:
                                searchItemType = SearchItemType.APPLICATION;
                                objKey = "application";
                                break;
                            case FIELDS.impactedUser:
                                let ip = "", userName = "";
                                if (typeof obj === "string") {
                                    const [i, j] = obj.split(";");
                                    ip = i || "";
                                    userName = j || "";
                                } else {
                                    ip = obj.ipaddr || "";
                                    userName = obj.name || "";
                                    id = ip + ";" + userName;
                                }
                                searchItemType = SearchItemType.IMPACTED_USER;
                                objKey = "impactedUser";
                                obj = typeof obj === "string" ? {name: obj} : obj;
                                break;
                            case FIELDS.impactedApplication:
                                searchItemType = SearchItemType.IMPACTED_APPLICATION;
                                objKey = "impactedApplication";
                                obj = typeof obj === "string" ? {name: obj} : obj;
                                id = obj.name;
                                break;
                            case FIELDS.impactedLocation:
                                searchItemType = SearchItemType.IMPACTED_LOCATION;
                                objKey = "impactedLocation";
                                obj = typeof obj === "string" ? {name: obj} : obj;
                                id = obj.name;
                                break;
                            case FIELDS.incident:
                                searchItemType = SearchItemType.INCIDENT;
                                objKey = "incident";
                                id = obj.id;
                                title = obj.description;
                                name = obj.description;
                                desc = obj.description;
                                break;
                        }
                        return {
                            resultItem: {
                                id: id,
                                type: searchItemType,
                                formattedData: {
                                    title: title,
                                    name: name,
                                    description: desc
                                },
                                [objKey]: obj
                            }
                        }
                    });
                    cognitiveSearchResults.current = [...cognitiveSearchResults.current, ...normalizedResult];
                }
                cognitiveSearchResultsAvailable.current = true;
                consolidateResults();
            }, error => {
                error.current = true;
                console.error(error);
                cognitiveSearchResultsAvailable.current = true;
                consolidateResults();
                // setRunbooksList([]);
            });
        },
        [ executeSafely, consolidateResults, searchText ]
    );*/
    
    const countSearchTextWords = searchText ? searchText.split(" ").length : 1;

    const runDALSearch = useCallback(
        async () => {
            const searchRequest: SearchRequest = {
                type: "Incident",
                search: countSearchTextWords > 1 ? `"${searchText}"` : searchText, top: 5000,
                count: true,
                searchFields: [
                    FIELDS.DESCRIPTION,
                    FIELDS.INTERFACE_NAME, FIELDS.INTERFACE_IP_ADDRESS, FIELDS.INTERFACE_LOCATION_NAME,
                    FIELDS.DEVICE_NAME, FIELDS.DEVICE_IP_ADDRESS, FIELDS.DEVICE_LOCATION_NAME,
                    FIELDS.APPLICATION_NAME, FIELDS.APPLICATION_LOCATION_NAME,
                    FIELDS.IMPACTED_APPLICATION, FIELDS.IMPACTED_LOCATION, FIELDS.IMPACTED_USER_NAME,
                ],
                facets: [
                    getFacetRequestObject(FACET_FIELDS.DEVICE_NAME, DEFAULT_FACET_LIMIT),
                    getFacetRequestObject(FACET_FIELDS.DEVICE_IP_ADDRESS, DEFAULT_FACET_LIMIT),
                    getFacetRequestObject(FACET_FIELDS.DEVICE_LOCATION_NAME, DEFAULT_FACET_LIMIT),
                    getFacetRequestObject(FACET_FIELDS.INTERFACE_NAME, DEFAULT_FACET_LIMIT),
                    getFacetRequestObject(FACET_FIELDS.INTERFACE_IP_ADDRESS, DEFAULT_FACET_LIMIT),
                    getFacetRequestObject(FACET_FIELDS.INTERFACE_LOCATION_NAME, DEFAULT_FACET_LIMIT),
                    getFacetRequestObject(FACET_FIELDS.APPLICATION_NAME, DEFAULT_FACET_LIMIT),
                    getFacetRequestObject(FACET_FIELDS.APPLICATION_LOCATION_NAME, DEFAULT_FACET_LIMIT),
                    getFacetRequestObject(FACET_FIELDS.IMPACTED_LOCATION, DEFAULT_FACET_LIMIT),
                    getFacetRequestObject(FACET_FIELDS.IMPACTED_APPLICATION, DEFAULT_FACET_LIMIT),
                    getFacetRequestObject(FACET_FIELDS.IMPACTED_USER_NAME, DEFAULT_FACET_LIMIT),
                    getFacetRequestObject(FACET_FIELDS.IMPACTED_USER_IP_ADDRESS, DEFAULT_FACET_LIMIT)    
                ]
            };

            const searchRequestNetworkInterfaces: SearchRequest = {
                type: "NetworkInterface",
                search: countSearchTextWords > 1 ? `"${searchText}"` : searchText, top: 10000,
                orderby: {field: FIELDS.name, order: ORDERS.desc},
                count: true,
                searchFields: [
                    FIELDS.NAME, FIELDS.IP_ADDRESS, FIELDS.LOCATION_NAME
                ],
                facets: []
            };

            const searchRequestCustomProperties: SearchRequest = {
                type: "CustomProperty",
                search: countSearchTextWords > 1 ? `"${searchText}"` : searchText, top: 10000,
                orderby: {field: FIELDS.name, order: ORDERS.desc},
                count: true,
                searchFields: [
                    FIELDS.NAME, FIELDS.DESCRIPTION
                ],
                facets: []
            };

            const [networkInterfacesAll, customProperties] = 
                await Promise.allSettled([
                    searchGraphqlService.current.search(searchRequestNetworkInterfaces), 
                    searchGraphqlService.current.search(searchRequestCustomProperties)]) as {status: 'fulfilled' | 'rejected', value: {items: Array<SearchItem>}}[];

            return executeSafely(searchGraphqlService.current.search(searchRequest)).then((searchResult: SearchResult<SearchItem>) => {
                let normalizedResult: Array<any> = [];

                if (searchResult?.items) {
                    const incidents = searchResult.items.map((incident) => {
                        const descStrings: Array<string> =[];
                        if(incident.description) {
                            descStrings.push(`${incident.description}`);
                        }
                        if(incident.impactedUsers) {
                            descStrings.push(JSON.stringify(incident.impactedUsers));
                        }
                        if(incident.impactedApplications) {
                            descStrings.push(JSON.stringify(incident.impactedApplications));
                        }
                        if(incident.impactedLocations) {
                            descStrings.push(JSON.stringify(incident.impactedLocations));
                        }
                        const descText = descStrings.join();

                        let incidentLocation;
                        if (searchResult?.facets?.IMPACTED_LOCATION?.items) {
                            const incidentLocationObj = searchResult.facets.IMPACTED_LOCATION.items.find((item: any) => incident.description?.includes(item.value));
                            if (incidentLocationObj) {
                                incidentLocation = incidentLocationObj.value;
                            }
                        }

                        if (searchResult?.facets?.DEVICE_LOCATION_NAME?.items) {
                            const incidentLocationObj = searchResult.facets.DEVICE_LOCATION_NAME.items.find((item: any) => incident.description?.includes(item.value));
                            if (incidentLocationObj) {
                                incidentLocation = incidentLocationObj.value;
                            }
                        }

                        if (searchResult?.facets?.INTERFACE_LOCATION_NAME?.items) {
                            const incidentLocationObj = searchResult.facets.INTERFACE_LOCATION_NAME.items.find((item: any) => incident.description?.includes(item.value));
                            if (incidentLocationObj) {
                                incidentLocation = incidentLocationObj.value;
                            }
                        }

                        if (searchResult?.facets?.APPLICATION_LOCATION_NAME?.items) {
                            const incidentLocationObj = searchResult.facets.APPLICATION_LOCATION_NAME.items.find((item: any) => incident.description?.includes(item.value));
                            if (incidentLocationObj) {
                                incidentLocation = incidentLocationObj.value;
                            }
                        }

                        let incidentType;
                        let incidentSource = incident.description?.split(' ')?.[0];
                        const eventCategory = incident.eventCategory?.toLowerCase();

                        if (eventCategory?.includes('interface')) {
                            incidentType = "network_interface";
                            let split = incident.description?.split('on ');
                            if (split && split[1]) {
                                incidentSource = `${incidentSource}:${split[1]}`
                            }
                        } else if (eventCategory?.includes('device')) {
                            incidentType = "network_device";
                        } else if (eventCategory?.includes('application_location')) {
                            incidentType = "application_location";
                            let split = incident.description?.split(' at');
                            if (split && split[0]) {
                                incidentSource = split[0];
                            }
                        } else if (eventCategory?.includes('application')) {
                            incidentType = "application";
                        }

                        return {
                            resultItem: {
                                id: incident.id,
                                type: SearchItemType.INCIDENT,
                                formattedData: {
                                    title: incident.description,
                                    description: descText
                                },
                                incident: {
                                    ...incident, 
                                    incidentLocation,
                                    indicators: [
                                        {
                                            isPrimary: true,
                                            entity: {
                                                kind: incidentType,
                                                name: incidentSource
                                            }
                                        }
                                    ]
                                }
                            }
                        }
                    });
                    normalizedResult = [...normalizedResult, ...incidents];

                    if (searchResult?.facets?.DEVICE_NAME?.items) {
                        const deviceLocation = (deviceName) => {
                            let deviceLocation;
                            if (searchResult?.facets?.DEVICE_LOCATION_NAME?.items) {
                                searchResult.facets.DEVICE_LOCATION_NAME.items.forEach((location: any) => {
                                    if (searchResult.items) {
                                        for (const incident of searchResult.items) {
                                            if (incident.description && incident.description.includes(deviceName) && incident.description.includes(location.value)) {
                                                deviceLocation = location.value;
                                                break;
                                            }
                                        }
                                    }
                                });
                            }
                            return deviceLocation;
                        }
                        const devices = searchResult.facets.DEVICE_NAME.items.map((device,index) => {
                            return {
                                resultItem: {
                                    type: SearchItemType.DEVICE,
                                    formattedData: {
                                        title: device.value,
                                        name: device.value,
                                        description: `${searchResult?.facets?.DEVICE_IP_ADDRESS?.items[index]?.value};${device.value};${deviceLocation(device.value)}`
                                    },
                                    device: {
                                        name: device.value,
                                        ipaddr: searchResult?.facets?.DEVICE_IP_ADDRESS?.items[index]?.value,
                                        location: {
                                            name: deviceLocation(device.value)
                                        }
                                    }
                                }
                            }
                        });
                        normalizedResult = [...normalizedResult, ...devices];
                    }

                    if (searchResult?.facets?.INTERFACE_NAME?.items) {
                        const interfaceLocation = (interfaceName) => {
                            let interfaceLocation;
                            let split = interfaceName.split(':');
                            if (searchResult?.facets?.INTERFACE_LOCATION_NAME?.items) {
                                searchResult.facets.INTERFACE_LOCATION_NAME.items.forEach((location: any) => {
                                    if (searchResult.items) {
                                        for (const incident of searchResult.items) {
                                            if (
                                                incident.description &&
                                                (incident.description.includes(interfaceName) ||
                                                (split[0] && incident.description.includes(split[0]) && split[1] && incident.description.includes(split[1]))) &&
                                                incident.description.includes(location.value)) {
                                                interfaceLocation = location.value;
                                                break;
                                            }
                                        }
                                    }
                                });
                            }
                            return interfaceLocation;
                        }

                        const networkInterfaces = searchResult.facets.INTERFACE_NAME.items.map((networkInterface,index) => {
                            let interfaceIfIndex;
                            if (networkInterfacesAll?.value?.items) {
                                for (const networkInterfaceAll of networkInterfacesAll.value.items) {
                                    if (networkInterface.value === networkInterfaceAll.name) {
                                        interfaceIfIndex = networkInterfaceAll.ifIndex;
                                        break;
                                    }
                                }
                            }
                            return {
                                resultItem: {
                                    type: SearchItemType.INTERFACE,
                                    formattedData: {
                                        title: networkInterface.value,
                                        name: networkInterface.value,
                                        description: `${searchResult?.facets?.INTERFACE_IP_ADDRESS?.items[index]?.value};${networkInterface.value};${interfaceLocation(networkInterface.value)}`
                                    },
                                    interface: {
                                        name: networkInterface.value,
                                        ipaddr: searchResult?.facets?.INTERFACE_IP_ADDRESS?.items[index]?.value,
                                        ifindex: interfaceIfIndex,
                                        location: {
                                            name: interfaceLocation(networkInterface.value) ?? ""
                                        }
                                    }
                                }
                            }
                        });
                        normalizedResult = [...normalizedResult, ...networkInterfaces];
                    }

                    if (searchResult?.facets?.APPLICATION_LOCATION_NAME?.items) {
                        const applications: Array<object> = [];
                        const applicationsNamesAndLocations: Array<string> = [];
                        searchResult.facets.APPLICATION_LOCATION_NAME.items.forEach((location: any) => {
                            if (searchResult?.facets?.APPLICATION_NAME?.items) {
                                searchResult.facets.APPLICATION_NAME.items.forEach((application: any) => {
                                    searchResult?.items?.forEach((incident) => {
                                        if (incident.description && incident.description.toLowerCase().includes(searchText.toLowerCase()) && incident.description.includes(location.value)) {
                                            if (!applicationsNamesAndLocations.includes(`${application.value};${location.value}`)) {
                                                applicationsNamesAndLocations.push(`${application.value};${location.value}`);
                                                applications.push({
                                                    resultItem: {
                                                        type: SearchItemType.APPLICATION,
                                                        formattedData: {
                                                            title: application.value,
                                                            name: application.value,
                                                            description: `${application.value};${location.value}`
                                                        },
                                                        application: {
                                                            name: application.value,
                                                            location: {
                                                                name: location.value
                                                            }
                                                        }
                                                    }
                                                });
                                            }
                                        }
                                    });
                                });
                            }
                        });
                        normalizedResult = [...normalizedResult, ...applications];
                    }

                    if (searchResult?.facets?.IMPACTED_USER_IP_ADDRESS?.items) {
                        const impactedUsers = searchResult.facets.IMPACTED_USER_IP_ADDRESS.items.map((impactedUser,index) => {
                            return {
                                resultItem: {
                                    type: SearchItemType.IMPACTED_USER,
                                    formattedData: {
                                        title: searchResult?.facets?.IMPACTED_USER_NAME?.items?.[index]?.value ?? "",
                                        name: searchResult?.facets?.IMPACTED_USER_NAME?.items?.[index]?.value ?? "",
                                        description: searchText
                                    },
                                    impactedUser: {
                                        ipaddr: impactedUser.value,
                                        name: searchResult?.facets?.IMPACTED_USER_NAME?.items?.[index]?.value ?? ""
                                    },
                                    id: `${impactedUser.value};${searchResult?.facets?.IMPACTED_USER_NAME?.items?.[index]?.value ?? ""}`
                                }
                            }
                        });
                        normalizedResult = [...normalizedResult, ...impactedUsers];
                    }

                    if (searchResult?.facets?.IMPACTED_APPLICATION?.items) {
                        const impactedApplications = searchResult.facets.IMPACTED_APPLICATION.items.map(impactedApplication => {
                            return {
                                resultItem: {
                                    type: SearchItemType.IMPACTED_APPLICATION,
                                    formattedData: {
                                        title: impactedApplication.value,
                                        name: impactedApplication.value,
                                        description: ""
                                    },
                                    impactedApplication: {
                                        name: impactedApplication.value
                                    }
                                }
                            }
                        });
                        normalizedResult = [...normalizedResult, ...impactedApplications];
                    }

                    if (searchResult?.facets?.IMPACTED_LOCATION?.items) {
                        const impactedLocations = searchResult.facets.IMPACTED_LOCATION.items.map((impactedLocation) => {
                            return {
                                resultItem: {
                                    type: SearchItemType.IMPACTED_LOCATION,
                                    formattedData: {
                                        title: impactedLocation.value,
                                        name: impactedLocation.value,
                                        description: ""
                                    },
                                    impactedLocation: {
                                        name: impactedLocation.value
                                    }
                                }
                            }
                        });
                        normalizedResult = [...normalizedResult, ...impactedLocations];
                    }

                    if (customProperties?.value?.items) {
                        const resultCustomProperties = customProperties.value.items.map(customProperty => {
                            return {
                                resultItem: {
                                    id: customProperty.id,
                                    type: SearchItemType.CUSTOM_PROPERTY,
                                    formattedData: {
                                        title: customProperty.name,
                                        name: customProperty.name,
                                        description: ""
                                    },
                                    customProperty
                                }
                            }
                        });
                        normalizedResult = [...normalizedResult, ...resultCustomProperties];
                    }
                }
                dalServiceResultAvailable.current = true;
                fuseSearchResults.current = [...fuseSearchResults.current, ...normalizedResult];
                consolidateResults();
            }, error => {
                error.current = true;
                console.error(error);
                dalServiceResultAvailable.current = true;
                consolidateResults();
            });
        },
        [ searchGraphqlService, executeSafely, consolidateResults, searchText, countSearchTextWords ]
    );

    // Triggers a search operation. Exposed to the consumers of this hook.
    const search = useCallback(async (inp: UseQueryRunProps) => {
        if (searchText && searchText.length > 2) {
            incidentRunBookResultsAvailable.current = false;
            lifecycleRunBookResultsAvailable.current = false;
            subflowRunBookResultsAvailable.current = false;
            onDemandRunBookResultsAvailable.current = false;
            dalServiceResultAvailable.current = false;
            // cognitiveSearchResultsAvailable.current = false;
            updateData({
                state: ApiCallStatus.LOADING,
                data: []
            });
            inp.fetchPolicy = "no-cache";
            // searchDALQuery.run(inp);
            // const [loading] = useLoading(fetchFlows);
            // getRunBooks('flows');
            fetchRunbooks(Variant.INCIDENT);
            fetchRunbooks(Variant.LIFECYCLE);
            fetchRunbooks(Variant.SUBFLOW);
            fetchRunbooks(Variant.ON_DEMAND);
            await runDALSearch();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }
    }, [searchText, updateData, fetchRunbooks, runDALSearch]);

    React.useEffect(() => {
        updateData({
            state: ApiCallStatus.IDLE,
        });
    }, [updateData]);

    return [searchResponse, search];
};

/** this constant specifies the default facet limit. */
const DEFAULT_FACET_LIMIT: number = 10000;

/** returns the object to be used in the facets parameter of the search request.
 *  @param facetCategory a String with the facet category.
 *  @param limitsByFacetCategory the facet value limits indexed by facet category. 
 *  @returns the object to be used in the facets parameter of the search request. */
function getFacetRequestObject(facetCategory: string, limitsByFacetCategory: Record<string, number> | number): {name: string, skip: number, top: number, count: boolean} {
    return {name: facetCategory, skip: 0, top: (limitsByFacetCategory[facetCategory] || DEFAULT_FACET_LIMIT), count: false};
}
