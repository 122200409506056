/** This module contains a react component that provides a container for the runbook views (each chart, table, etc).
 *  The container implements common functionality such as displaying notes and the toolbar.
 *  @module
 */

import { ReactNode, useState, useMemo } from "react";
import DOMPurify from 'dompurify';
import { SIZE } from "components/enums/Sizes.ts";
import { Outline } from "components/enums/General.ts";
import { DataSet, DEBUG_OUTPUT_TYPE } from "pages/riverbed-advisor/views/runbook-view/Runbook.type.ts";
import { RunbookAzureMonitorIcon } from "utils/runbooks/RunbookAzureMonitorIcon.tsx";
import { STRINGS } from "app-strings";
import { Button, Classes, HTMLSelect, Intent } from "@blueprintjs/core";
import { BasicDialog, updateDialogState } from "components/common/basic-dialog/BasicDialog.tsx";
import { IconNames } from "@tir-ui/react-components";
import { RoundedLinkButton } from "components/common/rounded-link-button/RoundedLinkButton.tsx";
import { useQueryParams } from "utils/hooks/useQueryParams.ts";
import { PARAM_NAME, INCIDENT_DETAILS_STYLE } from "components/enums/QueryParams.ts";
import { InfoContent } from "pages/riverbed-advisor/views/runbook-view-container/InfoContent.tsx";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { JsonViewer } from "pages/incident-details/views/primary-indicator/JsonViewer.tsx";
import { getArDataSources } from "utils/stores/GlobalDataSourceTypeStore.ts";
import type { WidgetOptions } from "components/common/vis-framework/widget/Widget.type.ts";
import { ENABLE_VANTAGE_POINT } from "components/common/graph/editors/data-ocean/DataOceanUtils.ts";
import './RunbookViewContainer.scss';

/** this enum defines all the valid note positions. */
export enum NotesPosition {
    left = "left",
    right = "right",
    above = "above",
    below = "below"
}

/** This interface defines the properties passed into the runbook view container component.*/
export interface RunbookViewContainerProps {
    /** the ite of the view. */
    id?: string;
    /** the name or title for the view. */
    name?: string;
    /** the full list of options for the widget. */
    options: WidgetOptions;
    /** How wide should notes get compared to the main contents? (Applies only for left and right positions) */
    notesWidth?: SIZE.s | SIZE.m | SIZE.l;
    /** the data set that contains the DataSetInfo object that gives the following information: the actual times, data sources, filters, errors, etc. */
    datasets?: Array<DataSet>;
    /** the toolbar that contains some of the controls for sharing the component and adding notes. */
    toolbar?: ReactNode;
    /** a String Array with the list of Vantage Points. */
    selectedVantagePoints?: string[];
    /** the handler for vantage point change events. */
    onVantagePointsChange?: (dsids: string[]) => void;
    /** an optional boolean value, if true, show the all vantage points option. */
    showAllVantagePoints?: boolean;
    /** the children which should be one runbook view widget. */
    children?: ReactNode;
}

/** Renders the runbook view container.
 *  @param props the properties passed in.
 *  @returns JSX with the runbook view container React component.*/
export const RunbookViewContainer = ({
    name, id, toolbar, notesWidth = SIZE.s, options, children, datasets, selectedVantagePoints, onVantagePointsChange, 
    showAllVantagePoints = false
}: RunbookViewContainerProps): JSX.Element => {
    let { params } = useQueryParams({ listenOnlyTo: [ PARAM_NAME.debug ] });
    const showDebugInformation = params[PARAM_NAME.debug] === "true";

    const initDialogState = {showDialog: false, title: STRINGS.runbookOutput.infoDialogTitle, loading: false, dialogContent: <></>, dialogFooter: <></>};
    const [dialogState, setDialogState] = useState<any>(initDialogState);

    const notes = options?.notes;
    const notesPosition = options?.notesPosition && NotesPosition[options?.notesPosition] ? NotesPosition[options?.notesPosition] : NotesPosition.above;
    const notesSize = notesPosition === "left" || notesPosition === "right" ? (notesWidth === SIZE.l ? " w-75" : (notesWidth === SIZE.m ? " w-50" : " wpct-20") ) : "";
    const compSize = notes && (notesPosition === "left" || notesPosition === "right") ? (notesWidth === SIZE.l ? " w-25" : (notesWidth === SIZE.m ? " w-50" : " wpct-80") ) : " w-100";

    const displayedVantagePoints: {id: string, name: string}[] = useMemo(
        () => {
            let traversedIds: string[] = [];
            let newDisplayedVantagePoints: {id: string, name: string}[] = [];
            for (const dataset of (datasets || [])) {
                if (dataset?.datapoints) {
                    for (const datapoint of dataset.datapoints) {
                        if (datapoint.keys) {
                            if (datapoint.keys["data_source.id"] && datapoint.keys["data_source.name"] && !traversedIds.includes(datapoint.keys["data_source.id"])) {
                                newDisplayedVantagePoints.push({id: datapoint.keys["data_source.id"], name: datapoint.keys["data_source.name"]});
                                traversedIds.push(datapoint.keys["data_source.id"]);
                            }
                        }
                    }
                }
            }
            if (newDisplayedVantagePoints.length === 1) {
                newDisplayedVantagePoints = [];
            }
            return newDisplayedVantagePoints;
        }, [datasets]
    );

    return <>
        <BasicDialog dialogState={dialogState} className="runbook-info-dialog " onClose={() => setDialogState(updateDialogState(dialogState, false, false, []))} />
        <div className={"runbook-row mb-3 p-3" + (INCIDENT_DETAILS_STYLE === "noTableOneCardForEachWidget" ? " bg-light rounded" : "")} id={id}>
            <div className={"d-flex flex-row " + (name || (ENABLE_VANTAGE_POINT && getArDataSources()?.length > 0) ? "justify-content-between" : "justify-content-end")}>
                <div>
                    {name && <div className="display-9 fw-bold my-2 me-2">
                        {options.isAzure && <RunbookAzureMonitorIcon showAzIcon={true} showGauge={false} iconText={name} />}
                        {!options.isAzure && <>{name}</>}
                    </div>}
                    {ENABLE_VANTAGE_POINT && displayedVantagePoints.length > 0/*getArDataSources()?.length > 0*/ && <div className="d-flex justify-content-start">
                        <div className="align-self-center"><span className="me-2">Vantage Point:</span></div>
                        <div>
                            <HTMLSelect
                                data-testid="vantage-point-select"
                                className="align-self-center"
                                //required={true}
                                //key={ filterProps.key }
                                //name={ filterProps.key }
                                fill={ true }
                                options={
                                    //[{label: "All Vantage Points", value: "all"}, {label: "AR1", value: "id1"}, {label: "AR2", value: "id2"}, {label: "AR3", value: "id3"}]
                                    //[{label: "All Vantage Points", value: "all"}].concat((getArDataSources() || []).map((ds) => {return {label: ds.name, value: ds.id}}))
                                    (showAllVantagePoints ? [{label: "All Vantage Points", value: "all"}] : []).concat((displayedVantagePoints).map((ds) => {return {label: ds.name, value: ds.id}}))
                                }
                                value={selectedVantagePoints?.length && selectedVantagePoints[0] !== "all" ? selectedVantagePoints[0] : undefined}
                                onChange={ e => {
                                    const value = e.target.value;
                                    if (onVantagePointsChange) {
                                        onVantagePointsChange(value ? [value] : []);
                                    }
                                    console.log(value);
                                } }
                            />
                        </div>
                    </div>}
                </div>
                <div className="d-flex justify-content-end align-self-start">
                    {hasInfo(datasets) && <RoundedLinkButton aria-label="information" 
                        className={"show-information-icon align-self-center header-text sub-header-props" + (hasWarning(datasets) && !hasError(datasets) ?  ` ${Classes.INTENT_WARNING}` : "")}
                        size={SIZE.s} outline={Outline.SHOW_ON_HOVER} text={STRINGS.runbookOutput.infoIconText} icon={IconNames.INFO_SIGN} 
                        minimal={true} intent={hasError(datasets) ? Intent.DANGER : Intent.NONE} onClick={(e) => {
                            showInfoDialog(datasets!, dialogState, setDialogState, showDebugInformation);
                        }} 
                    />}
                    {hasDebug(datasets) && <RoundedLinkButton aria-label="debug-information" 
                        className={"show-debug-icon align-self-center header-text sub-header-props"}
                        size={SIZE.s} outline={Outline.SHOW_ON_HOVER} text={STRINGS.runbookOutput.debugIconText} icon={IconNames.DIAGNOSIS} 
                        minimal={true} onClick={(e) => {
                            showDebugDialog(datasets!, dialogState, setDialogState);
                        }} 
                    />}
                    {toolbar}
                </div>
            </div>
            <div className={"d-flex mb-3 " + (notes ? notesPosition === "above" ? " flex-column" : notesPosition === "below" ? " flex-column-reverse" : notesPosition === "right" ? " flex-row-reverse" : "" : "")}>
                {notes?.replace(/(<([^>]+)>)/gi, "") && <div className={"display-8" + (notesPosition === "above" ? " my-2 border-bottom pb-2" : notesPosition === "below" ? " my-2 border-top pt-2" : (notesSize + " mt-4" + (notesPosition === "right" ? " ms-4" : " me-3")))}>
                   <span className="content" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(notes)}}></span>
                </div>}
                <div className={"flex-grow-1" + compSize}>
                    {children}
                </div>
            </div>
        </div>
    </>;
};

/** returns whether or not there is information that can be displayed.
 *  @param datasets the Array of DataSets to use to obtain the DataSetInfo object with the information from the Data Ocean.
 *  @returns a boolean value which is true if there is information that can be displayed. */
function hasInfo(datasets: Array<DataSet> | undefined): boolean {
    if (datasets) {
        for (const dataset of datasets) {
            if (dataset) {
                const info = dataset.info;
                if (info?.actualTimes?.length && info.actualTimes.length > 0) {
                    if (info.actualTimes[0].startTime && info.actualTimes[0].endTime) {
                        // Check for start and end time
                        return true;
                    }
                    if (info.actualTimes[0]?.granularities?.length > 0) {
                        // Check for granularities
                        return true;
                    }
                }
                if (info?.dataSources?.length && info.dataSources.length > 0 && info.dataSources[0].type && info.dataSources[0].url) {
                    return true;
                }
                if (info?.actualFilters) {
                    return true;
                }
                if (info?.error || info?.warning) {
                    return true;
                }        
            }
        }    
    }
    return false;
}

/** returns whether or not there is an error that needs to be displayed.
 *  @param datasets the Array of DataSets to use to obtain the DataSetInfo object with the error information.
 *  @returns a boolean value which is true if there is error information that can be displayed. */
function hasError(datasets: Array<DataSet> | undefined): boolean {
    if (datasets) {
        for (const dataset of datasets) {
            if (dataset?.info?.error) {
                return true;
            }
        }
    }
    return false;
}

/** returns whether or not there is a warning that needs to be displayed.
 *  @param datasets the Array of DataSets to use to obtain the DataSetInfo object with the warning information.
 *  @returns a boolean value which is true if there is warning information that can be displayed. */
function hasWarning(datasets: Array<DataSet> | undefined): boolean {
    if (datasets) {
        for (const dataset of datasets) {
            if (dataset?.info?.warning) {
                return true;
            }
        }
    }
    return false;
}

/** returns whether or not there is debug information that can be displayed.
 *  @param datasets the Array of DataSets to use to obtain the DataSetInfo object with the information from the Data Ocean.
 *  @returns a boolean value which is true if there is debug information that can be displayed. */
function hasDebug(datasets: Array<DataSet> | undefined): boolean {
    if (datasets) {
        for (const dataset of datasets) {
            if (dataset && dataset.debug) {
                return true;
            }
        }
    }
    return false;
}

/** Creates a popup that displays the widget query information.  Right now the popup assumes there is only one 
 *      time range per widget and one data source per widget.  This can easily be expanded to the case where
 *      there is more than one time range or data source.
 *  @param datasets the Array of DataSets to use to obtain the DataSetInfo object with the information from the Data Ocean.
 *  @param dialogState the copied state object with the state setup to open the dialog.  The content
 *      needs to be appended and the title needs to be set in this function.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function.
 *  @param debug a boolean value, if true show debug information, if false, do not. */
function showInfoDialog(
    datasets: Array<DataSet>, dialogState: any, setDialogState: (dialogState: any) => void, debug: boolean
): void {

    const newDialogState = Object.assign({}, dialogState);
    newDialogState.title = STRINGS.runbookOutput.infoDialogTitle;
    newDialogState.showDialog = true;
    newDialogState.dialogContent = <InfoContent datasets={datasets} />;
    newDialogState.dialogFooter = <>
        <Button active={true} outlined={true}
            text={STRINGS.runbookOutput.okBtnText}
            onClick={async (evt) => {
                setDialogState(updateDialogState(newDialogState, false, false, []));
            }}
        />
    </>;
    setDialogState(newDialogState);
}

/** Creates a popup that displays the widget debug information.  The debug information is passed in the 
 *  dataset.
 *  @param datasets the Array of DataSets to use to obtain the DataSetInfo object with the debug information.
 *  @param dialogState the copied state object with the state setup to open the dialog.  The content
 *      needs to be appended and the title needs to be set in this function.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function. */
function showDebugDialog(
    datasets: Array<DataSet>, dialogState: any, setDialogState: (dialogState: any) => void
): void {
    let jsonOutput = {};
    if (datasets) {
        let dsIndex = 1;
        for (const dataset of datasets) {
            if (dataset) {
                const dsName = "Dataset: " + dsIndex++;
                if (dataset.debug?.length) {
                    jsonOutput[dsName] = {};
                    for (const debugOutput of dataset.debug) {
                        if (debugOutput.type === DEBUG_OUTPUT_TYPE.JSON) {
                            jsonOutput[dsName][debugOutput.name] = JSON.parse(debugOutput.value);
                        }
                    }
                }    
            }
        }
    }

    const newDialogState = Object.assign({}, dialogState);
    newDialogState.title = STRINGS.runbookOutput.debugDialogTitle;
    newDialogState.showDialog = true;
    newDialogState.dialogContent = <JsonViewer json={jsonOutput} />;
    newDialogState.dialogFooter = <>
        <CopyToClipboard text={JSON.stringify(jsonOutput || {}, null, 4)}>
            <Button active={true} outlined={true} 
                text={STRINGS.primaryIndicatorView.copyBtnText} onClick={() => {
                    setDialogState(updateDialogState(newDialogState, false, false, []));
                }}
            />
        </CopyToClipboard>
        <Button active={true} outlined={true}
            text={STRINGS.runbookOutput.okBtnText}
            onClick={async (evt) => {
                setDialogState(updateDialogState(newDialogState, false, false, []));
            }}
        />
    </>;
    setDialogState(newDialogState);
}
