/** This module contains the React component that displays the user management page.
 *  @module
 */
import React, { useState } from 'react';
import { Button } from '@blueprintjs/core';
import { TenantManagementService } from 'utils/services/TenantManagementService.ts';
import { SuccessToaster, LoadingOverlay, useDataLoading, IconNames } from '@tir-ui/react-components';
import { openModal } from 'components/common/modal/ModalRenderer.tsx';
import { UserListView } from './UserListView.tsx';
import { NoAccessErrorPage } from 'pages/NoAccessErrorPage.tsx';
import { GetRoutes as getRoutes } from 'config/routes.ts';
import { STRINGS, HELP } from 'app-strings';
import { PageWithHeader } from "components/sdwan/layout/page-with-header/PageWithHeader.tsx";
import { AuthServiceProvider } from "utils/providers/AuthServiceProvider.ts";
import { ButtonGroup } from '@blueprintjs/core';
import './UserAccountManagement.scss';

export const PRODUCT_NAME_REGISTERED_WITH_AZURE = "gelato";
const AuthService = AuthServiceProvider.getService();

export const MAPPED_ROLES = {
    'role.gelato.admin': 'Riverbed IQ Admin',
    'role.gelato.write': 'Riverbed IQ Write',
    'role.gelato.read': 'Riverbed IQ Read',
}
export const extractRoleParams = (role, type: 'filter' | 'select') => {
    const roleWithRequiredParams = {
        value: role.value,
    };
    if(type === 'filter') {
        roleWithRequiredParams['display'] = MAPPED_ROLES[role.value];
    } else {
        roleWithRequiredParams['name'] = MAPPED_ROLES[role.value];
    }
    return roleWithRequiredParams;
};

/** Renders the users account management.
 *  @returns the JSX with the user account management. */
export const UserAccountManagement = (): JSX.Element => {
    /* This is temporary and not the right way of imposing restrictions based on roles.
       We are working towards creating a more reliable framework that will map user roles to permissions
       and can be used across all services.
       DO NOT COPY THIS!
    * */

    if(
        !AuthService.isUserProductAdmin('gelato')
    ) {
        return <NoAccessErrorPage/>;
    } else {
        return <AccessUserAccountManagement/>;
    }
};

/** Fetches available role information. */
const fetchData = () => {
    return new Promise((resolve, reject) => {
        TenantManagementService.get('common/tenant_management/roles').then((response: any) => {
            // We just need roles in the hyperion app so we only extract that role
            resolve({
                products: { [PRODUCT_NAME_REGISTERED_WITH_AZURE] :response["products"][PRODUCT_NAME_REGISTERED_WITH_AZURE]}
            });
        }, error => {
            console.error(error);
            reject(error);
        });
    });
};

/** Renders the users list view based on the role provided
 *  @param productPlatformRoleData
 *  @param lastUpdated
 *  @returns the JSX with the user list view. */
const renderUsersListView = (
    productPlatformRoleData: {
        products: object;
        platform?: object;
    },
    lastUpdated: number
): JSX.Element | null => {
    if (!productPlatformRoleData) {
        return null;
    }
    return (
        <UserListView
            productKey={ PRODUCT_NAME_REGISTERED_WITH_AZURE }
            productPlatformRoleData={ productPlatformRoleData }
            lastUpdated={ lastUpdated }
        />
    );
};

/** Renders the user account management view
 *  @constructor
 *  @returns the JSX with the user account management page. */
const AccessUserAccountManagement = (): JSX.Element => {
    const [data, loading] = useDataLoading<any>(fetchData, undefined);
    const [lastUpdated, setLastUpdated] = useState<number>(new Date().getTime());

    return (
        <PageWithHeader name="UserAccountManagement" addPadding={true} showTimeBar={false} 
            showGlobalFilters={false} { ...getRoutes()['user-account-management'] } 
            helpInfo={HELP.userManagement} 
        >
            <div className="user-account-management loading-relative">
                <React.Fragment>
                    <ButtonGroup className='pb-4'>
                    <Button
                        // minimal
                        icon={ IconNames.NEW_PERSON }
                        // className="user-btn"
                        onClick={ () => {
                            openModal('inviteUserModal', {
                                rolesByProduct: data.products,
                                platformRoles: data.platform
                                    ? data.platform.roles
                                    : [],
                                onSuccess: () => {
                                    SuccessToaster({
                                        message: STRINGS.USER_ACCOUNT_MANAGEMENT
                                            .INVITE_USER.successMessage
                                    });
                                    setLastUpdated(new Date().getTime());
                                },
                            });
                        } }
                    >
                        { STRINGS.USER_ACCOUNT_MANAGEMENT.INVITE_USER.title }
                    </Button>
                    <Button
                        // minimal
                        icon={ IconNames.ENVELOPE }
                        // className="user-btn"
                        onClick={ () => {
                            openModal('editDomainsModal', {});
                        } }
                    >
                        { STRINGS.EDIT_DOMAINS.buttonText }
                    </Button>
                    </ButtonGroup>
                    <LoadingOverlay visible={ loading } spinnerSize={ 30 } loadingText="Fetching data..."/>
                    { data && data['products'] ? (
                        renderUsersListView(data, lastUpdated)
                    ) : null }
                </React.Fragment>
            </div>
        </PageWithHeader>
    );
};
