import React, { type ReactNode, useEffect, useRef } from 'react';
import { Tab as BluePrintTab, Tabs, Position, type TabId, Popover, Classes } from "@blueprintjs/core";
import { APP_ICONS } from "../../../sdwan/enums/icons.ts";
import { STRINGS } from "app-strings";
import type { SEVERITY } from 'components/enums/Severity.ts';
import { SIZE } from 'components/enums/Sizes.ts';
import { RoundedLinkButton } from "components/common/rounded-link-button/RoundedLinkButton.tsx";
import { StatusLED } from 'components/common/status-led/StatusLED.tsx';
import { clearQueryParam, setQueryParam, useQueryParams,getURL } from 'utils/hooks/useQueryParams.ts';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { useGlobalTime} from 'utils/hooks/useGlobalTime.ts';
import { Icon } from "@tir-ui/react-components";
import type { UserPreferences } from 'utils/services/UserPrefsTypes.ts';
import { useUserPreferences, setUserPreferences } from 'utils/hooks/useUserPreferences.ts';
import "./TabbedSubPages.scss";

interface TabProps {
    id: string;
    title: React.ReactNode;
    count?: number;
    subText?: string;
    className?: string;
    children?: ReactNode;
    status?: SEVERITY;
}

function Tab (props: TabProps) {
    return <span>{props.children}</span>;
}

interface TabbedSubPagesProps {
    /** Pass ID of tab to select in controlled mode (i.e. active tab is controlled
     * by parent by listening to onTabChange callback and passing back the active
     * tab ID using this selectedTabId param) */
    selectedTabId?: string;
    /** Callback that will be called with ID of clicked tab */
    onTabChange?: (tabId) => void;
    /** this notification is fired whenever the active tab is changed even if it is not from a user
     *  click.  The onTabChange notification only notifies of user clicks.  The tab can also change 
     *  when it is initialized due to a preference or URL change. */
    notifyActiveTab?: (tabId) => void;
    /** Style classes that will be applied to the container element  */
    className?: string;
    /** Style classes that will be applied to the content container element for every tab */
    childClassName?: string;
    /** One or more <Tab> elements (Note: This is not BluePrintJS' Tab).
     * TBD: Make this be a node of type 'Tab' component that's defined above
     * to maintain tighter typing. */
    children?: any;
    /** Flag to control automatic syncing of tab state with URL. Defaults to false */
    dontUpdateURL?: boolean;
    /** Name of key to be used in URL for syncing state. Defaults to 'tab' if not provided */
    urlStateKey?: string;
    /** Name of key to be used to store active tab state in user preferences if needed. */
    userPrefsStateKey?: keyof UserPreferences;
    /** Boolean to control if 'Share This View' button should be displayed */
    showShareButton?: boolean;
    renderActiveTabPanelOnly?: boolean;
}

export const DEFAULT_TAB_URL_STATE_KEY = "tab";

function TabbedSubPages ({
    showShareButton = false,
    urlStateKey = DEFAULT_TAB_URL_STATE_KEY,
    userPrefsStateKey,
    ...props
}: TabbedSubPagesProps) {
    const { params } = useQueryParams({
        listenOnlyTo: [urlStateKey] // Let's keep tab container rendering more efficient by listening only to the url state key
    });
    const preferences = useUserPreferences({
        listenOnlyTo: {
            [userPrefsStateKey || "void"]: ""
        }
    })
    const { absoluteTime } = useGlobalTime();
    const activeTabFromUserPrefs = userPrefsStateKey ? preferences[userPrefsStateKey] : undefined;
    const activeTabFromPropsOrURL = props.selectedTabId || (props.dontUpdateURL ? undefined : params[urlStateKey]);
    let activeTab: string | undefined = activeTabFromPropsOrURL || (activeTabFromUserPrefs as string || undefined);

    // If active tab information came from a prop or form the URL and it doesn't match the current saved value in
    // user preferences under the provided userPrefsStateKey, then update it
    if (userPrefsStateKey && activeTabFromPropsOrURL && activeTabFromPropsOrURL !== preferences[userPrefsStateKey]) {
        setUserPreferences({
            [userPrefsStateKey]: activeTabFromPropsOrURL
        });
    }

    // If active tab couldn't be derived from prop or from URL and a key to fetch it from
    // user preferences was also not provided, then set the first tab as active
    if (!activeTab) {
        React.Children.forEach(props.children, (child, index) => {
            if (index === 0) {
                activeTab = child?.props.id;
            }
        });
    }

    const cachedTab = useRef<string | undefined>(undefined);
    if (activeTab !== undefined && activeTab !== cachedTab.current) {
        cachedTab.current = activeTab;
        if (props.notifyActiveTab) {
            props.notifyActiveTab(activeTab);
        }
    }

    function handleTabChange (tabId) {
        if (!props.dontUpdateURL) {
            setQueryParam(urlStateKey, tabId, true);
        }
        if (props.onTabChange) {
            props.onTabChange(tabId);
        }
    }
    function getShareURL(){ 
        return window.location.origin + getURL(undefined, { "t": absoluteTime }, { replaceQueryParams: false });
    }
    function popupContent() {
        return (
                <div className="w-2 p-2">
                    <span>{STRINGS.share_help_text}</span>
                    <div className="py-2">
                    <CopyToClipboard text={getShareURL()}>   
                        <RoundedLinkButton
                            className={Classes.POPOVER_DISMISS + " copy-view-button"}
                            size={SIZE.s}
                            text={STRINGS.copy_view}
                            icon={<Icon icon={APP_ICONS.LINK}/>}
                        />
                    </CopyToClipboard>
                    </div>
                </div>)
    }
    useEffect(() => {
        return () => {
            if (!props.dontUpdateURL) {
                clearQueryParam(urlStateKey);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <Tabs className={"d-flex flex-column tabbed-sub-pages" + (props.className ? " " + props.className : "")}
        animate={true} selectedTabId={activeTab as TabId | undefined} onChange={handleTabChange}
        renderActiveTabPanelOnly={props.renderActiveTabPanelOnly !== undefined ? props.renderActiveTabPanelOnly : true}>
        {
            React.Children.map(props.children, child => {
                if (child) {
                    const title = child.props.count === undefined && child.props.subText === undefined ?
                        child.props.title :
                        <div className="d-flex align-items-center">
                            {
                                child.props.subText === undefined ?
                                child.props.title :
                                <div className="position-relative">
                                    {child.props.title}
                                    <div className="text-center position-absolute w-100" style={{ bottom: "-2px" }}>
                                        <div className="display-10">
                                            {child.props.subText}
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                child.props.count !== undefined &&
                                <div className="count-in-tab ms-1 bg-secondary text-light opacity-6">
                                    <span>{child.props.count}</span>
                                </div>
                            }
                        </div>;
                    return <BluePrintTab id={child.props.id}
                        title={title}
                        panel={
                            <div className={"tabbed-subpage-tab h-100" +
                                (props.childClassName ? " " + props.childClassName : "") +
                                (child.props.className ? " " + child.props.className : "")}
                            >
                                {child.props.children}
                            </div>
                        }
                    >{child.props.status && <StatusLED size={SIZE.xs} status={child.props.status}/>}</BluePrintTab>
                }
            })
        }
        {showShareButton &&
            <div className="button-share-view-div h-100 d-flex align-items-center flex-grow-1 justify-content-end">
                <Popover
                    content={popupContent()}
                    usePortal={true}
                    position={Position.BOTTOM}
                > 
                <RoundedLinkButton
                    className="button-share-view"
                    size={SIZE.s}
                    icon={<Icon icon={APP_ICONS.SHARE}/>}
                    text={STRINGS.share_view}
                />
                </Popover> 
            </div>
        }
    </Tabs>;
}

export { TabbedSubPages, Tab };
