mutation RemoveCustomPropertyFromNetworkInterfaces($id: ID!, $filter: NetworkInterfaceBulkFilterType, $search: String, $searchFields: [NetworkInterfaceSearchFieldEnum]) {
    removeCustomPropertyFromNetworkInterfaces(
        id: $id
        filter: $filter
        search: $search
        searchFields: $searchFields
    ) {
        modifiedResourcesCount
        ignoredResourcesCount
    }
}
