/** This module contains the component for the primary indicator view.  The primary indicator view displays
 *      the indicator regions, the time series of the indicator metric and the thresholds and baselines.  It
 *      can contain one or multiple charts.
 *  @module
 */
import { useEffect, useState, useMemo } from "react";
import { Query } from "reporting-infrastructure/types/Query.ts";
import { useQuery } from "utils/hooks/useQuery.ts";
import { useQueryParams } from "utils/hooks/useQueryParams.ts";
import { useStateSafePromise } from "utils/hooks/useStateSafePromise.ts";
import { parseTimeFromDALToUnix } from "utils/hooks/useQueryFormatters.ts";
import type { TIME_RANGE } from "utils/stores/GlobalTimeStore.ts";
import { Tab, TabbedSubPages } from "components/common/layout/tabbed-sub-pages/TabbedSubPages.tsx";
import { Card } from "components/reporting/containers/card/Card.tsx";
import { IconTitle } from "components/common/icon-title/IconTitle.tsx";
import { FILTER_NAME } from "components/sdwan/enums/filters.ts";
import { SDWAN_ICONS } from "components/sdwan/enums/icons.ts";
import { STRINGS } from "app-strings";
import { INDICATOR_TO_TERSE_LABEL_MAP, isBaselineIndicatorType, INDICATOR_TYPE } from "components/enums/IndicatorTypes.ts";
import { SIZE } from "components/enums/Sizes.ts";
import { TIME_FORMAT } from "components/enums/Time.ts";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade.tsx";
import { type Entity, getAttributesObject } from "utils/runbooks/EntityUtils.tsx";
import { IndicatorDetailedView } from "components/hyperion/views/indicator-detailed-view/IndicatorDetailedView.tsx";
import { Button } from "@blueprintjs/core";
import { BasicDialog, updateDialogState } from "components/common/basic-dialog/BasicDialog.tsx";
import { JsonViewer } from "./JsonViewer.tsx";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { EVENT_CATEGORY, type Incident, type Indicator, type IndicatorMapping, type Trigger } from "pages/incident-list/Incident.type.ts";
import { cloneDeep } from 'lodash';
import { formatToLocalTimestamp, formatToUtcTimestamp } from "reporting-infrastructure/utils/formatters/GeneralFormatter.ts";
import { ENTITY_KIND } from "components/enums/EntityKinds.ts";
import { PARAM_NAME, INCIDENT_DETAILS_STYLE } from "components/enums/QueryParams.ts";
import { IndicatorsListView } from "components/hyperion/views/indicators-list-view/IndicatorsListView.tsx";
import type { DataOceanMetadata } from "components/common/graph/editors/data-ocean/DataOceanMetadata.type.ts";
import { DataOceanUtils } from "components/common/graph/editors/data-ocean/DataOceanUtils.ts";
import type { RunbookOutput } from "pages/riverbed-advisor/views/runbook-view/Runbook.type.ts";
import { Variant } from "components/common/graph/types/GraphTypes.ts";
import { getArDataSources } from "utils/stores/GlobalDataSourceTypeStore.ts";
import PRIMARY_INDICATOR_QUERY from 'pages/incident-details/views/primary-indicator/primary-indicator-query.graphql';
import INDICATORS_LIST_QUERY from 'components/hyperion/views/indicators-list-view/indicators-list-query.graphql';
import './PrimaryIndicatorView.scss';

/** an interface that describes the properties that can be passed in to the component.*/
export interface PrimaryIndicatorViewProps {
    /** the current incident that is being viewed. */
    incident?: Incident;
    /** a boolean value, if true the title icons should be displayed if false do not show the title icons. */
    showTitleIcons?: boolean;
}

/** Creates the primary indicator view, which is a component that displays the chart with
 *  the indicator data.
 *  @param props an object with the properties passed to the priority reasons view.
 *  @returns JSX with the primary indicator view component.*/
export function PrimaryIndicatorView (props: PrimaryIndicatorViewProps): JSX.Element {
    let { params } = useQueryParams({ listenOnlyTo: [ PARAM_NAME.debug ] });
    const showDebugInformation = params[PARAM_NAME.debug] === "true";

    const [activeTab, setActiveTab] = useState<string>("primaryIndicator");

    const [executeSafely] = useStateSafePromise();
    const [objMetricMetaData, setObjMetricMetaData] = useState<DataOceanMetadata>();
    useEffect(() => {
        executeSafely(DataOceanUtils.init()).then(
            (response: DataOceanMetadata) => {
                setObjMetricMetaData(response);
            },
            (error) => {
                console.error(error);
            }
        );
    }, [executeSafely]);

    // We are using a separate query to fetch triggers for the active incident right now.
    // In the future, triggers list will come from incident query itself.
    const primaryIndicatorQuery = useQuery({
        name: "PrimaryIndicatorForPrimaryIndicatorView",
        query: new Query(PRIMARY_INDICATOR_QUERY),
        // In the incident details page we were using the global filters, so the incident id was coming in using the global
        // filters and then the trigger id came in below, but in the incident brief blade there 
        // is not an incident id global filter.  Let's manually specify the filters now
        //consumedFilters: [FILTER_NAME.incidentId, FILTER_NAME.detectionId],
        //requiredFilters: [FILTER_NAME.incidentId, FILTER_NAME.detectionId],
        skipGlobalFilters: true,
        lazy: true,
        timeNotRequired: true,
    });

    useEffect(() => {
        if (props.incident?.id) {
            primaryIndicatorQuery.run({
                filters: {
                    // In the incident details page we were using the global filters, so the incident id was coming in using the global
                    // filters and then the trigger id came in below, but in the incident brief blade there 
                    // is not an incident id global filter.  Let's manually specify the filters now
                    // Map triggers from [{ id: "ABC" }, { id: "DEF" }] to ["ABC", "DEF"]
                    [FILTER_NAME.incidentId]: props.incident?.id,
                    [FILTER_NAME.variant]: Variant.INCIDENT.toUpperCase()
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.incident])

    const {
        runbooks, isBaseline, primaryIndicatorMapping, primaryIndicator, indicatorMappingList,
        primaryIndicatorStartTime, primaryIndicatorEndTime, 
        earliestIndicator, latestIndicator,
        incidentStartTime, incidentEndTime, baselineTimeRangeToShow
    } = useMemo(() => {    
        let primaryIndicator: Indicator | undefined = undefined;
        let primaryIndicatorMapping: IndicatorMapping | undefined = undefined;
        const indicatorMappingList: IndicatorMapping[] = [];
        let runbooks: RunbookOutput[] = [];
        let earliestIndicator: number | undefined | null = null;
        let latestIndicator: number | undefined | null = null;
        if (primaryIndicatorQuery?.data?.incidents?.nodes?.length) {
            const incident: Incident = primaryIndicatorQuery?.data?.incidents?.nodes[0];    
            earliestIndicator = incident.earliestIndicator ? parseTimeFromDALToUnix(incident.earliestIndicator) : null;
            latestIndicator = incident.latestIndicator ? parseTimeFromDALToUnix(incident.latestIndicator) : null;
/*            
            if (incident.indicatorMappings?.length) {
                for (const mapping of incident.indicatorMappings) {
                    if (mapping.isPrimary) {
                        primaryIndicatorMapping = {
                            entityId: mapping.entityId, metric: mapping.metric, 
                            kind: mapping.kind, sourceId: mapping.sourceId
                        };
                    }
                    indicatorMappingList.push({
                        entityId: mapping.entityId, metric: mapping.metric, 
                        kind: mapping.kind, sourceId: mapping.sourceId
                    });
                }
            }
            if (primaryIndicatorMapping && incident.indicators?.length) {
                for (const indicator of incident.indicators) {
                    if (
                        indicator.kind === primaryIndicatorMapping.kind && indicator.entity?.id === primaryIndicatorMapping.entityId &&
                        indicator.metric === primaryIndicatorMapping.metric && indicator.entity?.source?.id === primaryIndicatorMapping.sourceId
                    ) {
                        primaryIndicator = indicator;
                        break;
                    }
                }
            }
*/
            if (incident.indicators?.length) {
                for (const indicator of incident.indicators) {
                    if (indicator.isEarliest) {
                        primaryIndicator = indicator
                        primaryIndicatorMapping = {
                            entityId: indicator.entity?.id || "", metric: indicator.metric, 
                            kind: indicator.kind, sourceId: indicator.entity?.source?.id
                        };
                    }
                    const newMapping = {
                        entityId: indicator.entity?.id || "", metric: indicator.metric, 
                        kind: indicator.kind, sourceId: indicator.entity?.source?.id
                    };
                    if (
                        !indicatorMappingList.find((mapping) => 
                            mapping.entityId === newMapping.entityId && mapping.metric === newMapping.metric &&
                            mapping.kind === newMapping.kind && mapping.sourceId === newMapping.sourceId
                        )
                    ) {
                        indicatorMappingList.push(newMapping);
                    }
                }
            }
        }
        if (primaryIndicatorQuery?.data?.runbooks?.nodes?.length) {
            runbooks = primaryIndicatorQuery.data.runbooks.nodes;
        }
        const isBaseline = isBaselineIndicatorType(primaryIndicator?.kind);

        // Is this just the primary indicator start and end time
        //let currentTriggerStartTime = firstTrigger ? parseTimeFromDALToUnix(firstTrigger.earliestIndicator) : null;
        //let currentTriggerEndTime = firstTrigger ? parseTimeFromDALToUnix(firstTrigger.latestIndicator) : null;
        let primaryIndicatorStartTime = primaryIndicator ? parseTimeFromDALToUnix(primaryIndicator.startTime) : null;
        let primaryIndicatorEndTime = primaryIndicator ? parseTimeFromDALToUnix(primaryIndicator.endTime) : null;
    
        // This is the time range to show in any baseline charts in hours
        let baselineTimeRangeToShow: number = 1;
        let incidentStartTime = primaryIndicatorStartTime;
        let incidentEndTime = primaryIndicatorEndTime;
        if (props.incident) {
            if (props.incident.endTime === null) {
                incidentEndTime = new Date().getTime();
            } else {
                incidentEndTime = parseTimeFromDALToUnix(props.incident.endTime);
            }
            // For the incident timeframe should we show the earliest detection or when it was created
            //if (props.incident.generated) {
            //    incidentStartTime = parseTimeFromDALToUnix(props.incident.generated);
            if (props.incident.earliestIndicator) {
                incidentStartTime = parseTimeFromDALToUnix(props.incident.earliestIndicator);
            }
            if (incidentStartTime && incidentEndTime) {
                baselineTimeRangeToShow = Math.ceil((incidentEndTime - incidentStartTime) / (1000 * 60 * 60));
            }
        }
        return {
            runbooks, isBaseline, primaryIndicatorMapping, primaryIndicator, indicatorMappingList,
            primaryIndicatorStartTime, primaryIndicatorEndTime, earliestIndicator, latestIndicator, 
            incidentStartTime, incidentEndTime, baselineTimeRangeToShow
        };
    }, [primaryIndicatorQuery.data, props.incident]);

    const initDialogState = {showDialog: false, title: STRINGS.primaryIndicatorView.debugDialogTitle, loading: false, dialogContent: <></>, dialogFooter: <></>};
    const [dialogState, setDialogState] = useState<any>(initDialogState);

    const { data } = useQuery({
        name: "IndicatorList",
        query: new Query(INDICATORS_LIST_QUERY),
        filters: {
            [FILTER_NAME.indicatorMapping]: indicatorMappingList,
        },
        consumedFilters: [FILTER_NAME.indicatorMapping],
        requiredFilters: [FILTER_NAME.indicatorMapping],
        time: {
            startTime: earliestIndicator,
            endTime: latestIndicator,
        } as TIME_RANGE,
    });

    /** Calculates the correlated indicators count.
     * @param data the IndicatorList query data object.
     * @returns a number with the correlated indicators count.*/
    const getIndicatorsCount = (data): number => {
        const uniqueSources: Array<string> = [];
        const uniqueEntityMetricCombo = {};
        const uniqueEntities = {};
        const uniqueMetrics = {};
        const indicatorCountPerTimestamp = {};
        const entityCountPerEntityType = {};
        let earliestStartTime = 0;
        let latestEndTime = 0;

        const indicatorsFromQuery: Array<Indicator> = data?.indicators?.nodes || [];
        const indicatorIDsFromQuery: Array<{id: string}> = data?.idsOnly?.nodes || [];

        const indicatorsFromQueryWithIDs: Array<Indicator> = indicatorsFromQuery.map((indicator, index) => {
            return {
                ...indicator,
                id: indicatorIDsFromQuery[index].id,
            };
        });

        const STATUS_METRICS = ["device_status", "iface_status", "admin_status", "op_status"];

        indicatorsFromQueryWithIDs.forEach(indicator => {
            // indicator id is sourceId::entityId::metric::anomalyKind
            let indicatorID: string = indicator.id || "";
            const entityType: string = indicator.entity?.kind || "";
            const metric: string = indicator.metric;
            const entityID: string = indicator.entity?.id || "";
            const anomalyKind: INDICATOR_TYPE = indicator.kind;
            const sourceId: string = indicator.entity?.source?.id || "";
            const sourceName: string = indicator.entity?.source?.name || indicator.entity?.source?.host || "";

            // exclude the primary indicator
            if (
                primaryIndicator && primaryIndicator?.entity?.id === indicator?.entity?.id &&
                primaryIndicator?.metric === indicator?.metric && primaryIndicator?.kind === indicator?.kind &&
                primaryIndicator?.entity?.source?.id === indicator?.entity?.source?.id
            ) {
                return;
            }

            // The entity may come from two different data sources and each needs to have its own plot
            // right now, so create a unique id from the source id and entity id.
            const uniqueEntityId: string = sourceId + "::" + entityID;

            const indicatorStartTime = parseFloat(indicator.startTime || "0");
            const indicatorEndTime = parseFloat(indicator.endTime || "0");

            if (isBaselineIndicatorType(anomalyKind)){
                indicatorID += "_baseline";
            } else {
                indicatorID += "_threshold";
            }

            if (!uniqueSources.includes(sourceId)) {
                uniqueSources.push(sourceId);
            }

            if (uniqueEntityMetricCombo[indicatorID] === undefined) {
                // This is the first time we are seeing this entity/source/metric/anomalyKind
                uniqueEntityMetricCombo[indicatorID] = true;

                entityCountPerEntityType[entityType] = (entityCountPerEntityType[entityType] || 0) + 1;
        
                uniqueMetrics[metric] = (uniqueMetrics[metric] || 0) + 1;
        
                if (uniqueEntities[uniqueEntityId] === undefined) {
                    uniqueEntities[uniqueEntityId] = {
                        id: entityID,
                        sourceId: sourceId,
                        sourceName: sourceName,
                        entity: indicator.entity,
                        metrics: {}
                    };
                }
                if (uniqueEntities[uniqueEntityId].metrics[metric] === undefined) {
                    uniqueEntities[uniqueEntityId].metrics[metric] = {
                        metric: metric,
                        anomalies: {}
                    };
                }
                if (uniqueEntities[uniqueEntityId].metrics[metric].anomalies[anomalyKind] === undefined) {
                    uniqueEntities[uniqueEntityId].metrics[metric].anomalies[anomalyKind] = {
                        kind: anomalyKind,
                        data: []
                    };
                }
                if (earliestStartTime === 0 || indicatorStartTime < earliestStartTime) {
                    earliestStartTime = indicatorStartTime;
                }
                if (latestEndTime === 0 || indicatorEndTime > latestEndTime) {
                    latestEndTime = indicatorEndTime;
                }
                if (!indicatorCountPerTimestamp[indicatorStartTime]) {
                    indicatorCountPerTimestamp[indicatorStartTime] = { x: indicator.startTime, y: 1 };
                } else {
                    indicatorCountPerTimestamp[indicatorStartTime].y++;
                }

                const expectedValue = indicator.details?.expectedValue;
                const expectedValueForCalculation = expectedValue === null || expectedValue === undefined ? 0 : expectedValue;
                const actualValue = indicator.details?.actualValue;
                const acceptableHigh = indicator.details?.acceptableHighValue;
                const acceptableLow = indicator.details?.acceptableLowValue;
                let percentExceeded:number = 0;
                let useTimes = false;
                let deviationInfo = "";
                if (actualValue !== undefined && actualValue !== null &&
                    (
                        (actualValue <= expectedValueForCalculation && acceptableLow !== null && acceptableLow !== undefined) ||
                        (actualValue > expectedValueForCalculation && acceptableHigh !== null && acceptableHigh !== undefined)
                    )
                ) {
                    if (actualValue > expectedValueForCalculation) {
                        if (expectedValue === null || expectedValue === undefined) {
                            useTimes = true;
                            deviationInfo = STRINGS.incidents.indicators.timesHigher;
                        } else {
                            deviationInfo = STRINGS.incidents.indicators.outsideRange;
                        }
                        percentExceeded = ((actualValue - acceptableHigh!) / (acceptableHigh! - expectedValueForCalculation)) * 100;
                    } else {
                        if (expectedValue === null || expectedValue === undefined) {
                            useTimes = true;
                            deviationInfo = STRINGS.incidents.indicators.timesLower;
                        } else {
                            deviationInfo = STRINGS.incidents.indicators.outsideRange;
                        }
                        percentExceeded = Math.abs((actualValue - acceptableLow!) / (acceptableLow! - expectedValueForCalculation)) * 100;
                    }
                }

                if (STATUS_METRICS.includes(metric)) {
                    // There is no deviation information for a status metric
                    deviationInfo = "";
                }

                uniqueEntities[uniqueEntityId].metrics[metric].anomalies[anomalyKind].data.push({
                    startTime: indicator.startTime,
                    endTime: indicator.endTime,
                    expected: expectedValue,
                    actual: actualValue,
                    acceptableHigh: acceptableHigh,
                    acceptableLow: acceptableLow,
                    deviation: percentExceeded,
                    useTimes: useTimes,
                    deviationInfo: deviationInfo,
                    indicator: indicator
                });
            } else {
                // Verify the start/end time for existing metrics.
                // The data array will always have only 1 entry since the
                // processing is done for unique entity/metric combos
                if (indicatorStartTime < earliestStartTime) {
                    earliestStartTime = indicatorStartTime;
                }
                if (indicatorStartTime < uniqueEntities[uniqueEntityId].metrics[metric].anomalies[anomalyKind].data[0].startTime) {
                    uniqueEntities[uniqueEntityId].metrics[metric].anomalies[anomalyKind].data[0].startTime = indicatorStartTime;
                }
                if (indicatorEndTime > latestEndTime) {
                    latestEndTime = indicatorEndTime;
                }
                if (indicatorEndTime > uniqueEntities[uniqueEntityId].metrics[metric].anomalies[anomalyKind].data[0].endTime) {
                    uniqueEntities[uniqueEntityId].metrics[metric].anomalies[anomalyKind].data[0].endTime = latestEndTime;
                }
            }
        });

        let indicatorsCount = 0;
        for (const uniqueEntityId in uniqueEntities) {
            const entityDataObj = uniqueEntities[uniqueEntityId];
            const metrics = Object.values(entityDataObj.metrics);

            for (const metricObj of metrics) {
                const anomaliesForThisMetric = Object.values((metricObj as any).anomalies);
                for (const anomaly of anomaliesForThisMetric) {
                    indicatorsCount += (anomaly as any).data.length;
                }
            }
        }

        return indicatorsCount;
    };

    return <>
        <BasicDialog dialogState={dialogState} className="primary-indicator-view-dialog" onClose={() => setDialogState(updateDialogState(dialogState, false, false, []))} />
        <IconTitle 
            icon={props.showTitleIcons ? SDWAN_ICONS.TRIGGER : undefined} 
            title={STRINGS.primaryIndicatorView.indicatorsTitle} size={SIZE.m} showDebug={showDebugInformation} 
            className="mb-2 fw-500"
            onDebug={() => {
                showDebugDialog(
                    getDebugInformation(props.incident, primaryIndicatorQuery.data?.incidents?.nodes, primaryIndicator), 
                    dialogState, setDialogState
                );
            }}
        />
        <DataLoadFacade
            loading={primaryIndicatorQuery.loading}
            data={primaryIndicatorQuery.data}
            error={primaryIndicatorQuery.error}
            showContentsWhenLoading={true}
        >{
            primaryIndicatorMapping && primaryIndicatorStartTime && primaryIndicatorEndTime && objMetricMetaData && <>
            {/*<div className="d-flex align-items-center my-3">
                <table className="display-8 me-3">
                    <tbody>
                        {getEntityContent(firstTrigger.entity, "details")}
                    </tbody>
                </table>*/}
                <Card className="pt-1 pb-1 mb-4" 
                    hideShadow={INCIDENT_DETAILS_STYLE === "noTableOneCardForEachWidget"}
                    hideBorder={INCIDENT_DETAILS_STYLE === "noTableOneCardForEachWidget"}
                    //className="flex-grow-1"
                >
                    <div className="d-block">
                    <TabbedSubPages urlStateKey="indicatorTab" selectedTabId={activeTab} onTabChange={setActiveTab}>
                        <Tab id="primaryIndicator" title={STRINGS.incidents.primaryIndicatorTabTitle}>
                            {getIndicatorSummary(objMetricMetaData, primaryIndicator?.entity, props.incident, primaryIndicator)}
                            {/*<div className="d-flex align-items-center my-3">*/}
                            {/*firstTrigger?.entity && <table className="display-8 me-3">
                                <tbody>
                                    {getEntityContent(firstTrigger.entity, "details")}
                                </tbody>
                            </table>*/}
                            <IndicatorDetailedView
                                className="flex-grow-1"
                                indicatorMapping={primaryIndicatorMapping}
                                indicatorTimeRange={{
                                    startTime: primaryIndicatorStartTime,
                                    endTime: primaryIndicatorEndTime,
                                }}
                                incidentTimeRange={{
                                    startTime: incidentStartTime || 0,
                                    endTime: incidentEndTime || 0,
                                }}
                                //initChartTimeRange={baselineTimeRangeToShow}
                                initChartTimeRange={baselineTimeRangeToShow > 8 ? 24 : baselineTimeRangeToShow > 1 ? 8 : 1}
                                isBaselineType={isBaseline}
                                annotationIncident={props.incident}
                                annotationIndicator={primaryIndicator}
                                isPrimaryIndicator={true}
                                runbooks={runbooks}
                            />
                            {/*</div>*/}
                        </Tab>
                        {indicatorMappingList?.length && earliestIndicator && latestIndicator && 
                            <Tab id="correlatedIndicator" title={`${STRINGS.incidents.correlatedIndicatorTabTitle} (${getIndicatorsCount(data)})`}>
                                <IndicatorsListView
                                    className="overflow-auto p-4"
                                    indicatorMappings={indicatorMappingList}
                                    indicatorsTimeRange={{
                                        startTime: earliestIndicator,
                                        endTime: latestIndicator,
                                    }}
                                    incidentTimeRange={{
                                        startTime: incidentStartTime || 0,
                                        endTime: incidentEndTime || 0,
                                    }}    
                                    primaryEntityId={primaryIndicator?.entity?.id}
                                    primaryIndicator={primaryIndicator}
                                    incident={props.incident}
                                    runbooks={runbooks}
                                />
                            </Tab>
                        }
                    </TabbedSubPages>
                    </div>
                </Card>
            {/*</div>*/}</>
        }</DataLoadFacade>
    </>;
}

/** this function returns the indicator summary. 
 *  @param metaData the DataOceanMetadata with the objects, metrics and keys for the data ocean.
 *  @param entity the entity that this indicator view is showing.
 *  @param incident the Incident whose start time needs to be displayed. 
 *  @param primaryIndicator the primary indicator.
 *  @returns the JSX with the indicator summary. */
export function getIndicatorSummary(metaData: DataOceanMetadata, entity?: Entity, incident?: Incident, primaryIndicator?: Indicator): JSX.Element {
    switch (incident?.eventCategory) {
        case EVENT_CATEGORY.SITE_OUTAGE_ISSUE:
        case EVENT_CATEGORY.MULTI_DEVICE_DOWN_ISSUE:
            return <div className="my-3" style={{marginLeft: "20px"}}>
                {getEntityText(entity, incident.eventCategory)} starting around <b>{getStartTime(incident)}</b>{getVantagePoint(entity)}
            </div>;
        default:
            return <div className="my-3" style={{marginLeft: "20px"}}>
                {getEntityText(entity)} experienced <b>{getMetricSummary(metaData, primaryIndicator)}</b> starting around <b>{getStartTime(incident)}</b>{getVantagePoint(entity)}
            </div>;
    }
    
}

/** returns the start time that should be displayed in the incidator summary.
 *  @param incident the Incident whose start time needs to be displayed. 
 *  @returns a String with the start time or an empty string if there is no start time. */
function getStartTime(incident?: Incident): string {
    if (incident?.earliestIndicator) {
        return formatToLocalTimestamp(new Date(parseInt(incident.earliestIndicator) * 1000), TIME_FORMAT.DISPLAY_TIME_FORMAT);
    }
    return "";
}

/** returns the Vantage Point for the entity, if the entity is an AR11, otherwise the 
 *  vantage point is not displayed.
 *  @param entity the Entity object with all the entity attributes including the data source.
 *  @returns a String with the vantage point text. */
export function getVantagePoint(entity?: Entity): JSX.Element {
    const dsArray: {id: string, name: string}[] = getArDataSources();
    const dsId = entity?.source?.id;
    let name: string | undefined = undefined;
    (dsArray || []).forEach(ds => {
        if (ds.id === dsId) {
            name = ds.name;
        }
    });
    return <>{name ? <span> (reported from vantage point <b>{name}</b>)</span> : <></>}</>;
}

/** this function returns the metric summary. 
 *  @param metaData the DataOceanMetadata with the objects, metrics and keys for the data ocean.
 *  @param primaryIndicator the primary indicator.
 *  @returns a String with the metric summary. */
function getMetricSummary(metaData: DataOceanMetadata, primaryIndicator?: Indicator): string {
    if (primaryIndicator) {
        return INDICATOR_TO_TERSE_LABEL_MAP[primaryIndicator.kind] + " " + metaData.metrics[primaryIndicator.metric].label;
    }
    return "";
}

/** returns the text to be displayed for the entity.
 *  @param enntity the Entity object with the information for the entity.
 *  @param eventCategory the EVENT_CATEGORY with the trigger event type.
 *  @returns the JSX with the entity information or null. */
function getEntityText(entity: Entity | undefined, eventCategory?: EVENT_CATEGORY): JSX.Element | null {
    if (entity) {
        const attributes = getAttributesObject(entity);
        switch (entity.kind) {
            case ENTITY_KIND.INTERFACE:
                if (entity.parent) {
                    const deviceType = entity?.parent?.type ? entity.parent.type : "device";
                    return <span>Interface <b>{attributes?.metadata?.name}</b> on {deviceType} <b>{entity.parent.name}</b>{getLocationText(attributes)}</span>;
                } else {
                    return <span>Interface <b>{attributes.name}</b>{getLocationText(attributes)}</span>;
                }
            case ENTITY_KIND.DEVICE:
                let deviceName = <b>{attributes.metadata.ipaddr}</b>;
                if (attributes.metadata.name) {
                    deviceName = <><b>{attributes.metadata.name}</b> ({attributes.metadata.ipaddr})</>;
                }
                if (eventCategory && [EVENT_CATEGORY.SITE_OUTAGE_ISSUE, EVENT_CATEGORY.MULTI_DEVICE_DOWN_ISSUE].includes(eventCategory)) {
                    return <span>{deviceName} and other devices are unreachable {getLocationText(attributes, eventCategory)}</span>;
                } else {
                    return <span>Device {deviceName}{getLocationText(attributes)}</span>;
                }
            case ENTITY_KIND.APPLICATION:
            case ENTITY_KIND.APPLICATION_LOCATION:
                return <span>Clients{getLocationText(attributes)} using application <b>{attributes?.metadata?.application?.name}</b></span>;
            case ENTITY_KIND.LOCATION:
                return <span>Location <b>{attributes?.metadata?.name}</b></span>;
        }    
    }
    return null;
}

/** returns the text to be displayed for the entity location if any.
 *  @param enntity the Entity object with the location for the entity.
 *  @param eventCategory the EVENT_CATEGORY with the trigger event type.
 *  @returns the JSX with the entity location information or null. */
function getLocationText(attributes: {[x: string]: any}, eventCategory?: EVENT_CATEGORY): JSX.Element | null {
    const location = attributes?.metadata?.location?.name;
    if (location) {
        if (eventCategory && [EVENT_CATEGORY.SITE_OUTAGE_ISSUE, EVENT_CATEGORY.MULTI_DEVICE_DOWN_ISSUE].includes(eventCategory)) {
            return <> at <b>{location}</b></>;
        } else {
            return <> located in <b>{location}</b></>;
        }
    }
    return null;
}

/** Creates a popup that displays the debug information.
 *  @param json the JSON object with the debug information.
 *  @param dialogState the copied state object with the state setup to open the dialog.  The content
 *      needs to be appended and the title needs to be set in this function.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function. */
/* istanbul ignore next */
function showDebugDialog(
    json: Record<string, any>, dialogState: any, setDialogState: (dialogState: any) => void
): void {
    const newDialogState = Object.assign({}, dialogState);
    newDialogState.showDialog = true;
    newDialogState.dialogContent = <JsonViewer json={json} />;
    newDialogState.dialogFooter = <>
        <CopyToClipboard text={JSON.stringify(json || {}, null, 4)}>
            <Button active={true} outlined={true} 
                text={STRINGS.primaryIndicatorView.copyBtnText} onClick={() => {
                    setDialogState(updateDialogState(newDialogState, false, false, []));
                }}
            />
        </CopyToClipboard>
        <Button active={true} outlined={true}
            text={STRINGS.primaryIndicatorView.okBtnText}
            onClick={async (evt) => {
                setDialogState(updateDialogState(newDialogState, false, false, []));
            }}
        />
    </>;
    setDialogState(newDialogState);
}

/** This function outputs the debug information for the debug dialog.
 *  @param incident the Incident whose primary indicators are being viewed.
 *  @param triggers the triggers for the incident.
 *  @param indicators the indicators for the primary trigger.
 *  @returns an object with the debug information. */
function getDebugInformation(
    incident: Incident | undefined, triggers: Array<Trigger>, primaryIndicator?: Indicator
): Record<string, any> {
    if (incident) {
        incident = cloneDeep(incident);
        annotateTimestamp(incident, "createdAt");
        annotateTimestamp(incident, "lastUpdatedAt");
        annotateTimestamp(incident, "endTime");
        annotateTimestamp(incident, "earliestIndicator");
        annotateTimestamp(incident, "latestIndicator");
    }
    if (triggers?.length) {
        triggers = cloneDeep(triggers);
        for (const trigger of triggers) {
            annotateTimestamp(trigger, "generated");
            annotateTimestamp(trigger, "earliestIndicator");
            annotateTimestamp(trigger, "latestIndicator");
            if (trigger.primaryIndicator) {
                annotateTimestamp(trigger.primaryIndicator, "startTime");
                annotateTimestamp(trigger.primaryIndicator, "endTime");        
            }
            if (trigger.indicators) {
                for (const indicator of trigger.indicators) {
                    annotateTimestamp(indicator, "startTime");
                    annotateTimestamp(indicator, "endTime");            
                }
            }
        }
    }
    if (primaryIndicator) {
        primaryIndicator = cloneDeep(primaryIndicator);
        annotateTimestamp(primaryIndicator, "startTime");
        annotateTimestamp(primaryIndicator, "endTime");
    }
    return {incident, triggers, primaryIndicator}
}

/** annotates the timestamp specified by the object and key with a modified timestamp that includes your 
 *      time and utc time.
 * @param obj the object to update.
 * @param key the key of the timestamp. */
export function annotateTimestamp(obj: any, key: string): void {
    if (obj && key && obj[key] && obj[key] !== "0") {
        obj[key] += " (your time: " + 
            formatToLocalTimestamp(new Date(parseInt(obj[key]) * 1000), TIME_FORMAT.DISPLAY_TIME_FORMAT) + 
            ", utc: " + 
            formatToUtcTimestamp(new Date(parseInt(obj[key]) * 1000), TIME_FORMAT.DISPLAY_TIME_FORMAT) + 
            ")";
    }
}
