/** This module contains the component that displays a dialog that shows the progress of downloading
 *      the incident ids necessary to update the incidents.
 *  @module
 */

import { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { Button } from "@blueprintjs/core";
import { STRINGS } from "app-strings";
import { BasicDialog, updateDialogState } from "components/common/basic-dialog/BasicDialog.tsx";
import { useStateSafePromise } from "utils/hooks/useStateSafePromise.ts";
import { useUserPreferences } from "utils/hooks/useUserPreferences.ts";
import { FIELDS, SearchItem, SearchRequest, SearchResult } from "utils/services/SearchApiService.ts";
import { SEARCH_ENGINE, SearchPreference, DEFAULT_SEARCH_PREF } from "utils/services/UserPrefsTypes.ts";
import { useApolloClient } from "@apollo/client";
import { SearchGraphqlApiService } from "utils/services/search/SearchGraphqlApiService.ts";
import { SearchCorrelationService } from "utils/services/SearchCorrelationApiService.ts";

/** this enum defines the various states that can occur when the user is downloading the ids. */
export enum DOWNLOAD_STATUS {
    /** the enumerate value for a successful download. */
    SUCCEEDED   = "SUCCEEDED",
    /** the enumerate value for a cancelled download. */
    CANCELLED   = "CANCELLED"
}

/** This interface defines the properties passed into the incident download dialog React component.*/
interface IncidentDownloadDialogProps {
    /** the current search request with all the filters including the facets. */
    searchRequest?: SearchRequest;
    /** the handler for the on incident ids retrieved event. */
    onIncidentIdsRetrieved: (ids: string[], error: DOWNLOAD_STATUS) => void;
}

/** Renders the incident table actions view.
 *  @param props the properties passed in.
 *  @returns JSX with the incident table actions component.*/
const IncidentDownloadDialog = (props: IncidentDownloadDialogProps) => {
    const loading = useRef<boolean>(true);
    const [searchRequest, setSearchRequest] = useState<SearchRequest>(JSON.parse(JSON.stringify(
        {...props.searchRequest, top: 1000, skip: 0, select: [FIELDS.incidentId]}
    )));
    const [executeSafely] = useStateSafePromise();

    const incidentIds = useRef<string[]>([]);

    const {onIncidentIdsRetrieved} = props;
    const initDialogState = useMemo(
        () => {
            const ds = {
                showDialog: false, title: STRINGS.incidentSearch.incidentControl.idDialog.title, loading: false, 
                dialogContent: <div><span>{STRINGS.incidentSearch.incidentControl.idDialog.text}</span></div>, dialogFooter: <></>
            };
            ds.dialogFooter = <>
                <Button active={true} outlined={true} data-testid="incident-id-download-dialog-ok-button"
                    text={STRINGS.primaryIndicatorView.okBtnText}
                    onClick={async (evt) => {
                        if (onIncidentIdsRetrieved) {
                            onIncidentIdsRetrieved(incidentIds.current, DOWNLOAD_STATUS.SUCCEEDED);
                        }
                        setDialogState(updateDialogState(ds, false, false, []));
                    }}
                />
            </>;
            return ds;
        },
        [onIncidentIdsRetrieved]
    );
    const [dialogState, setDialogState] = useState<any>(initDialogState);

    const userPreferences = useUserPreferences({listenOnlyTo: {search: {savedQueries: []}}});
    const searchPreferences: SearchPreference = {...DEFAULT_SEARCH_PREF, ...userPreferences.search};

    const apolloClient = useApolloClient();
    const searchGraphqlService = useRef<SearchGraphqlApiService>(new SearchGraphqlApiService(apolloClient));

    const runCognitiveSearch = useCallback(
        (runSearchRequest: SearchRequest) => {
            let searchService: {search: (searchRequest: SearchRequest) => Promise<SearchResult<SearchItem>>} | null = null;
            switch (searchPreferences.srchEngine) {
                case SEARCH_ENGINE.correlation_direct:
                    searchService = SearchCorrelationService;
                    break;
                case SEARCH_ENGINE.correlation_dal:
                    searchService = searchGraphqlService.current;
                    break;
            }
            return executeSafely(searchService!.search(runSearchRequest)).then((searchResult: SearchResult<SearchItem>) => {
                if (searchResult) {
                    switch (searchPreferences.srchEngine) {
                        case SEARCH_ENGINE.correlation_direct:
                        case SEARCH_ENGINE.correlation_dal:
                            if (searchResult.items?.length) {
                                for (const value of searchResult.items) {
                                    incidentIds.current.push((value as any).id);
                                }
                            }
                            break;
                    }

                    const totalCount = searchResult["@odata.count"] || 0;
                    let additionalResults: boolean = false;
                    if (searchResult["@search.nextPageParameters"]) {
                        // We have more search results that have been paginated by the server
                        if (incidentIds.current.length < totalCount) {
                            setSearchRequest(searchResult["@search.nextPageParameters"]);
                            additionalResults = true;
                        }
                    } else if (incidentIds.current.length < totalCount) {
                        setSearchRequest({...searchRequest, top: 1000, skip: incidentIds.current.length});
                        additionalResults = true;
                    }
                    if (!additionalResults) {
                        loading.current = false;
                        setDialogState(updateDialogState(dialogState, true, false, []));
                    }    
                }
            }, error => {
                error.current = true;
                console.error(error);
                setDialogState(updateDialogState(initDialogState, false, false, []));
            });
        },
        [ 
            executeSafely, searchRequest, dialogState, initDialogState, searchPreferences.srchEngine
        ]
    );

    // When either the search text changes or the facets change re-run the search
    const prevSearchRequest = useRef<SearchRequest>();
    useEffect(
        () => {
            if (searchRequest && searchRequest !== prevSearchRequest.current) {
                loading.current = true;
                prevSearchRequest.current = searchRequest;
                setDialogState(updateDialogState(dialogState, true, true, []));
                runCognitiveSearch(searchRequest);    
            }
        },
        [searchRequest, dialogState, runCognitiveSearch]
    );

    return <BasicDialog 
        dialogState={dialogState} className="incident-id-loading-dialog" onClose={() => {
            if (onIncidentIdsRetrieved) {
                onIncidentIdsRetrieved([], DOWNLOAD_STATUS.CANCELLED);
            }
            setDialogState(updateDialogState(dialogState, false, false, []));
        }} 
    />;
}

export default IncidentDownloadDialog;

