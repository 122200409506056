/** This module contains the component for displaying the actions that can be done on the incidents.
 *  @module
 */

import { useState } from "react";
import { HTMLSelect, Position, Radio, RadioGroup, Popover, Button } from "@blueprintjs/core";
import { STRINGS } from "app-strings";
import { SIZE } from "components/enums/Sizes.ts";
import { HIDDEN_STATE_KEYS, INCIDENT_STATUS_TO_LABEL_MAP, INCIDENT_STATUS } from "components/enums/IncidentStatus.ts";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade.tsx";
import { useMutation } from "@apollo/client";
import { IncidentKeyFieldsMutationInput } from "pages/incident-list/views/update-incidents-view/UpdateIncidentsView.tsx";
import { Icon, IconNames } from "@tir-ui/react-components";
import { SearchRequest } from "utils/services/SearchApiService.ts";
import IncidentDownloadDialog, { DOWNLOAD_STATUS } from "./IncidentIdDownloadDialog.tsx";
import { useUserPreferences } from "utils/hooks/useUserPreferences.ts";
import INCIDENT_KEY_FIELD_UPDATE_QUERY from 'pages/incident-list/views/update-incidents-view/incident-key-field-update-query.graphql';

/** This interface defines the properties passed into the incident table actions React component.*/
interface IncidentTableActionsProps {
    /** the classname to apply to the div for the component. */
    className?: string;
    /** the list of selected incident ids. */
    selectedIncidentIds?: Array<string>;
    /** all the currently displayed incident ids. */
    allIncidentIds?: Array<string> | undefined;
    /** the current search request with all the filters including the facets.  This will be used to look up the ids. */
    searchRequest?: SearchRequest;
    /** the handler for update events.  This is called when an update is successfully completed. */
    onUpdate?: () => void;
}

/** the enumerated values for the incident selection mode. */
enum INCIDENT_SELECTION {
    /** use all incidents when invoking an action. */
    ALL         = "ALL",
    /** use just the selected incidents when invoking an action. */
    SELECTED    = "SELECTED"
}

/** Renders the incident table actions view.
 *  @param props the properties passed in.
 *  @returns JSX with the incident table actions component.*/
const IncidentTableActions = (props: IncidentTableActionsProps) => {
    const userPreferences = useUserPreferences({listenOnlyTo: {search: {savedQueries: []}}});

    const [incidentStatus, setIncidentStatus] = useState<INCIDENT_STATUS|"">("");
    const [setIncidentKeyFields, { loading }] = useMutation<any, IncidentKeyFieldsMutationInput>(INCIDENT_KEY_FIELD_UPDATE_QUERY, {
			onCompleted: (data) => {
				if (data?.setIncidentKeyFields?.length) {
                    console.log("Successfully updated " + data?.setIncidentKeyFields?.length + " incidents");
				} else {
                    console.warn("DAL response for mutation had 0 rows!");
                }
                if (props.onUpdate) {
                    props.onUpdate();
                }
			},
			onError: (err) => {
				console.error(err?.message);
			}
    });

    const [idLookupDialogIsOpen, setIdLookupDialogIsOpen] = useState<boolean>(false);

    function onApplyClicked () {
        const commonInputs = {...(incidentStatus === "" ? {} : { status: incidentStatus }),};

        if (incidentSelectionMode === INCIDENT_SELECTION.ALL && props.allIncidentIds === undefined) {
            // The user has selected all incidents, but we do not have the array of incident ids because we are 
            // paginating so go out and get it.
            setIdLookupDialogIsOpen(true);
        } else {
            const ids = incidentSelectionMode === INCIDENT_SELECTION.ALL ? props.allIncidentIds : props.selectedIncidentIds;
            if (ids?.length) {
                setIncidentKeyFields({variables: {incidents: ids.map(incident => {return {id: incident, ...commonInputs};})}});
            }    
        }
    }

    const [incidentSelectionMode, setIncidentSelectionMode] = useState<INCIDENT_SELECTION>(INCIDENT_SELECTION.SELECTED);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return <DataLoadFacade loading={Boolean(loading)} showContentsWhenLoading size={SIZE.s} fullScreen={true} className="ms-auto" >
        <Popover position={Position.LEFT_TOP} 
            isOpen={isOpen}
            content={<div>
                <div className={"p-4" + (props.className ? " " + props.className : "")} >
                    {userPreferences?.search?.allowBulkChanges && <RadioGroup
                        name="selectedIncidentRadio"
                        onChange={e => {
                            setIncidentSelectionMode(e.currentTarget.value as INCIDENT_SELECTION);
                        }}
                        selectedValue={incidentSelectionMode}
                        inline={true}
                        disabled={false}
                        className="d-inline"
                    >
                        <Radio
                            label={
                                props.selectedIncidentIds?.length + " " + 
                                (props.selectedIncidentIds && props.selectedIncidentIds?.length > 1 ? STRINGS.incidents.incident_plural : STRINGS.incidents.incident) + 
                                " " + STRINGS.incidents.selectedLabel
                            }
                            value={INCIDENT_SELECTION.SELECTED}
                        />
                        <Radio
                            label={STRINGS.incidentSearch.incidentControl.applyStatusToAllLabel}
                            value={INCIDENT_SELECTION.ALL}
                        />
                    </RadioGroup>}
                    <HTMLSelect
                        className="me-4 w-min-1-5"
                        defaultValue={incidentStatus}
                        onChange={e => setIncidentStatus(INCIDENT_STATUS[e.currentTarget.value])}
                        data-testid="status-selector"
                    >
                        <option label={STRINGS.incidentStatus.label} disabled/>
                        {
                            Object
                                .keys(INCIDENT_STATUS)
                                .filter(status => {
                                    return !HIDDEN_STATE_KEYS.includes(status);
                                })
                                .map(s => <option
                                    key={"option-" + s}
                                    label={INCIDENT_STATUS_TO_LABEL_MAP[s] || s}
                                    value={s}
                                />)
                        }
                    </HTMLSelect>
                </div>
                <div className="d-flex justify-content-end w-100 p-3">
                    <Button intent="primary"
                        data-testid="update-incident-apply-button"
                        text={STRINGS.incidents.applyChanges}
                        className=""
                        disabled={
                            incidentStatus === undefined || incidentStatus === "" ||
                            (Boolean(incidentSelectionMode === INCIDENT_SELECTION.SELECTED && props?.selectedIncidentIds?.length === 0))
                        }
                        onClick={() => {
                            onApplyClicked();
                            setIsOpen(false);
                        }}
                    />
                </div>
            </div>} 
        >
            <span 
                data-testid="incident-actions-update-status-icon"
                onClick={() => {
                    setIsOpen(!isOpen);
                }} className="text-nowrap" style={{cursor: "pointer"}}
            >
                Update Status
                <Icon icon={IconNames.CHEVRON_DOWN} className="me-2" style={{verticalAlign: "baseline", cursor: "pointer"}} size={14} />
            </span>
        </Popover>
        {idLookupDialogIsOpen && <IncidentDownloadDialog searchRequest={props.searchRequest} onIncidentIdsRetrieved={(ids, status) => {
            if (status === DOWNLOAD_STATUS.SUCCEEDED && ids?.length) {
                const commonInputs = {...(incidentStatus === "" ? {} : { status: incidentStatus }),};
                setIncidentKeyFields({variables: {incidents: ids.map(incident => {return {id: incident, ...commonInputs};})}});
            }    
            setIdLookupDialogIsOpen(false);
        }}/>}
    </DataLoadFacade>;
};

export default IncidentTableActions;
