import { InputType, type RunbookInfo, Variant } from 'components/common/graph/types/GraphTypes';
import { useEffect, useState } from 'react';
import { 
    GLOBAL_SCOPE, INCIDENT_SCOPE, INCIDENT_SCOPE_BUILTIN_VARIABLES, RUNBOOK_SCOPE_BUILTIN_VARIABLES, 
    RUNTIME_SCOPE, type VariableCollection, getRuntimeBuiltinVariables, removeTypename 
} from 'utils/runbooks/VariablesUtils.ts';
import { useQuery } from './useQuery.ts';
import { Query } from "reporting-infrastructure/types/Query.ts";
import { useMutation } from '@apollo/client';
import { ErrorToaster, SuccessToaster } from '@tir-ui/react-components';
import { STRINGS } from 'app-strings';

import INCIDENT_VARIABLES_QUERY from './incident-variables-query.graphql';
import INCIDENT_VARIABLES_MUTATION from './incident-variables-mutation.graphql';

interface UseVariablesProps {
    runbookInfo?: RunbookInfo;
    variant?: Variant;
}

interface SetIncidentVariablesMutationInput {
    variables: VariableCollection
}

export const useVariables = ({runbookInfo, variant}: UseVariablesProps): [(scope, builtIn?: boolean) => any, (data) => any, (scope, data?) => any, any] => {
    // Manage the variables state until the runbook is saved
    const [unsavedVariables, setUnsavedVariables] = useState<any>({primitiveVariables: [], structuredVariables: []});
    const [variablesChanged, setVariablesChanged] = useState(false);
    const [incidentVariables, setIncidentVariables] = useState<any>();

    const incidentVariablesQuery = useQuery({
		name: 'IncidentVariables',
		query: new Query(INCIDENT_VARIABLES_QUERY),
	});

    const [setIncidentVariablesMutation] = useMutation< any, SetIncidentVariablesMutationInput>(INCIDENT_VARIABLES_MUTATION, {
            onCompleted: () => {
                incidentVariablesQuery.run({ noCache: true });
                SuccessToaster({
                    message: STRINGS.runbookEditor.variableDefinitions.incidentScope.successMsgUpdate
                });
            },
            onError: (err) => {
                ErrorToaster({
                    message: STRINGS.runbookEditor.variableDefinitions.incidentScope.errorMsgUpdate
                });
                console.error( err?.message);
            }
        });

    useEffect(() => {
        setIncidentVariables(removeTypename(incidentVariablesQuery.data));
        // API Fetch for incident and global variables
    }, [incidentVariablesQuery.data])

    function getVariables(scope?, builtIn?): VariableCollection {
        if (scope === RUNTIME_SCOPE) {
            if (variablesChanged) {
                return {
                    primitiveVariables: [
                        ...(unsavedVariables?.primitiveVariables || []),
                        ...(builtIn ? RUNBOOK_SCOPE_BUILTIN_VARIABLES : []),
                        ...(builtIn ? getRuntimeBuiltinVariables(runbookInfo?.triggerType) : [])
                    ],
                    structuredVariables: [
                        ...(unsavedVariables?.structuredVariables || []),
                    ]
                };
            } else {
                const variablesKey = variant === Variant.SUBFLOW ? "subflowVariables" : "runtimeVariables";
                return {
                    primitiveVariables: [
                        ...(runbookInfo?.[variablesKey]?.primitiveVariables || []),
                        ...(builtIn ? RUNBOOK_SCOPE_BUILTIN_VARIABLES : []),
                        ...(builtIn ? getRuntimeBuiltinVariables(runbookInfo?.triggerType) : [])
                    ],
                    structuredVariables: [
                        ...(runbookInfo?.[variablesKey]?.structuredVariables || []),
                    ]
                };
            }
        } else if (scope === INCIDENT_SCOPE) {
            if (runbookInfo?.triggerType === InputType.WEBHOOK) {
                return {primitiveVariables: [], structuredVariables: []};
            } else {
                return {
                    primitiveVariables: [...(incidentVariables?.incidentVariables?.variables?.primitiveVariables || []),
                    ...(builtIn ? INCIDENT_SCOPE_BUILTIN_VARIABLES : []),
                    ],
                    structuredVariables: [...(incidentVariables?.incidentVariables?.variables?.structuredVariables || [])]
                };
            }
        } else if (scope === GLOBAL_SCOPE) {
            //TODO: Return global variables
            return {primitiveVariables: [], structuredVariables: []};
        } else {
            // Return all variables including the built in ones
            // TODO: Add Incident and Global built in variables
            return {
                    primitiveVariables: [...RUNBOOK_SCOPE_BUILTIN_VARIABLES, 
                        ...getRuntimeBuiltinVariables(runbookInfo?.triggerType),
                        ...getVariables(RUNTIME_SCOPE, builtIn).primitiveVariables,
                        ...getVariables(INCIDENT_SCOPE, builtIn).primitiveVariables,
                        ...getVariables(GLOBAL_SCOPE, builtIn).primitiveVariables],
                    structuredVariables: [
                        ...getVariables(RUNTIME_SCOPE, builtIn).structuredVariables,
                        ...getVariables(INCIDENT_SCOPE, builtIn).structuredVariables,
                        ...getVariables(GLOBAL_SCOPE, builtIn).structuredVariables]
                };
        }
    };


    function syncRuntimeOrSubflowVariables(data) {
        setUnsavedVariables(data);
        setVariablesChanged(true);
    }

    function setVariables(scope, data?) {
        if (scope === INCIDENT_SCOPE) {
            setIncidentVariablesMutation({variables: { variables: data}});
        } else if (scope === GLOBAL_SCOPE) {
            //TODO: Push global variables changes
        } else if (scope === RUNTIME_SCOPE) {
            setUnsavedVariables([]);
            setVariablesChanged(false);
        }
    };
    return [getVariables, setVariables, syncRuntimeOrSubflowVariables, incidentVariables];
};
