query runbookScheduleJobs($count: Boolean!, $top: Int!, $skip: Int!, $order: [SearchRunbookScheduleJobSortInputType!]) {
    searchItems: searchRunbookScheduleJobs(
        count: $count,
        top: $top,
        skip: $skip,
        order: $order
    ) {
        info {
            totalCount
        }
        page {
            schedule {
                name
                description
            }
            nextRun
            enabled
            runbook {
                id
                name
            }
            details {
                repeat
                daysOfWeek
                every
                description
                name
                startOn
                runbook {
                    runbookId
                    incidentId
                    detection {
                        id
                        entity {
                            kind
                            attributes {
                                field
                                value
                            }
                            ipAddress
                        }
                    }
                    inputs {
                        name
                        type
                        isReadOnly
                        value
                    }
                }
            }
        }
    }
}
