import { Button, Intent } from '@blueprintjs/core';
import { ErrorToaster, SuccessToaster } from '@tir-ui/react-components';
import { TextAreaField } from 'components/common/form/TextAreaField.tsx';
import { Form } from 'components/common/form/Form.tsx';
import './NotesForm.scss'
import { STRINGS } from 'app-strings';
import { useMutation } from '@apollo/client';
import { NoteType } from 'components/enums/NotesActivity.ts';
import ADD_NOTES_MUTATION from './add-notes-mutation.graphql';
export interface NotesFormProps {
    /** ID of the incident associated with this trigger */
    incidentId?: string;
    afterAddingNote?: (noteId: string) => void;
}
interface NoteMutationInput {
    note: {
        incidentId: string
        content: string,
        type: string,
    }
}
/**
 * Form that adds new notes
 * @param props Refer to NotesFormProps for details on member attributes.
 * @return  JSX component
 */
export function NotesForm({ incidentId, afterAddingNote }: NotesFormProps) {
    const [setNote] = useMutation<any, NoteMutationInput>(ADD_NOTES_MUTATION, {
        onCompleted: (data) => {
            if (afterAddingNote && data?.addNote?.id) {
                afterAddingNote(data?.addNote?.id);
                SuccessToaster({
                    message: STRINGS.addNotes.successMsgAdd
                });
            }
        },
        onError: (err) => {
            ErrorToaster({
                message: STRINGS.addNotes.errorMsgAdd
            });
            console.error(err?.message);
        }
    });
    /***
     * onSubmit Handler function for the support formik form
     * Resets the form after successful submission
     * @param values
     * @param resetForm
     */
    const handleSubmit = (values, { resetForm }) => {
        if (values.note?.trim() && incidentId) {
            setNote({
                variables: {
                    note: {
                        incidentId: incidentId,
                        content: values.note,
                        type: NoteType.STANDARD
                    }
                }
            });
        }
        resetForm();
    };

    return (
        <div className="w-100">
            <Form
                initialValues={{
                    note: ""
                }}
                loading={false}
                onSubmit={handleSubmit}
            >
                <div className="position-relative">
                    <TextAreaField
                        placeholder={STRINGS.addNotes.placeHolder}
                        aria-label="notes text"
                        name="note"
                        fill={true}
                        style={{ width: "100%", height: "120px" }}
                    />
                    <Button
                        aria-label='submit note'
                        type="submit"
                        className="tir-ui-btn-submit rounded-button mt-1 position-absolute"
                        intent={Intent.PRIMARY}
                        style={{ bottom: "5px", right: "5px" }}
                    >
                        {STRINGS.send}
                    </Button>
                </div>
            </Form>
        </div>
    );
};
