/** This module contains an editor for the graph properties for the switch component.
 *  @module
 */

import React, { Component } from 'react';
import { RunbookInfo, NodeDef } from 'components/common/graph/types/GraphTypes.ts';
import { UniversalNode } from 'components/common/graph/UniversalNode.ts';
import { Button, HTMLSelect, ControlGroup, InputGroup, Label, Classes, TextArea, Checkbox } from '@blueprintjs/core';
import { STRINGS } from "app-strings";
import { NodeLibraryNode } from 'pages/create-runbook/views/create-runbook/NodeLibrary.ts';

/** This interface defines the properties passed into the node editor React component.*/
interface SwitchNodeEditorProps {
    /** the selected node. */
     selectedNode?: UniversalNode;
    /** the libaray node that describes the editable properites. */
    libraryNode?: NodeLibraryNode;
    /** the array of global nodes. */
    globalNodes: Array<NodeDef>;
    /** Currently active runbook info*/
    activeRunbook: RunbookInfo;
}
 
/** This interface defines the properties passed into the node editor React component.*/
interface SwitchNodeEditorState {
    /** the current set of rules. */
    rules: Array<any>;
    /** the state for the property and propertyType fields. */
    properties: {propertyType: string, property: string};
}

/** this class defines a react component for editing the properties in a switch node. */
export default class SwitchNodeEditor extends Component<SwitchNodeEditorProps, SwitchNodeEditorState> {
    /** a static member variable with the list of rules. */
    public static FILTER_OPTIONS = [
        {label: "Eq", value: "eq"}, {label: "Neq", value: "neq"}, {label: "Lt", value: "lt"}, {label: "Lte", value: "lte"},
        {label: "Gt", value: "gt"}, {label: "Gte", value: "gte"}, {label: "HasKey", value: "hask"}, {label: "IsBetween", value: "btwn"},
        {label: "Contains", value: "cont"}, {label: "Regex", value: "regex"}, {label: "IsTrue", value: "true"},
        {label: "IsFalse", value: "false"}, {label: "IsNull", value: "null"}, {label: "IsNotNull", value: "nnull"},
        {label: "IsType", value: "istype"}, {label: "IsEmpty", value: "empty"}, {label: "IsNotEmpty", value: "nempty"},
        {label: "Head", value: "head"}, {label: "Index", value: "index"}, {label: "Tail", value: "tail"},
        {label: "Json", value: "jsonata_exp"}, {label: "Otherwise", value: "else"},
    ];
    /** a static member variable with the list of property types. */
    public static PROP_TYPE_OPTIONS = [
        {label: "Msg", value: "msg"}, {label: "Flow", value: "flow"}, {label: "Global", value: "global"}, 
        {label: "Json", value: "jsonata"}, {label: "Dollar", value: "env"}
    ];

    /** the cached rules information from the last call to render. */
    private rules: any;
    /** the previous set of rules. */
    private oldRules: any;

    /** the cached property information from the last call to render. */
    private properties: any;
    /** the previous set of properties. */
    private oldProperties: any;

    /** a unique integer used as keys for the controls. */
    private controlId: number = 1;

    /** the constructor for the class
     *  @param props the properties passed in to the component.*/
     constructor(props: SwitchNodeEditorProps) {
        super(props);

        this.rules = React.createRef();
        this.oldRules = React.createRef();
        this.rules.current = [];
        this.oldRules.current = [];
        
        this.properties = React.createRef();
        this.oldProperties = React.createRef();
        this.properties.current = {};
        this.oldProperties.current = {};

        this.state = {rules: [], properties: {property: "payload", propertyType: "msg"}};
    }

    /** Renders the node editor panel. .
     *  @returns JSX with the node editor display panel.*/
    render(): JSX.Element {
        if (this.props?.selectedNode?.getProperty("rules") !== this.oldRules.current) {
            // This should only get called when the node changes
            this.rules.current = [].concat(this.props?.selectedNode?.getProperty("rules") || []);
            this.oldRules.current = this.props?.selectedNode?.getProperty("rules");

            // Setup the properties
            const property = this.props?.selectedNode?.getProperty("property") || "payload";
            const propertyType = this.props?.selectedNode?.getProperty("propertyType") || "msg";
            const propObj = {property, propertyType};
            this.properties.current = propObj;
            this.oldProperties.current = propObj;
        }

        const formElements: Array<JSX.Element> = [];
        if (this.props.libraryNode && this.props.libraryNode.properties) {
            for (const prop of this.props.libraryNode.properties) {
                const propLibraryValue = (prop.default !== null && prop.default !== undefined ? prop.default : undefined);
                let propValue = this.props?.selectedNode?.getProperty(prop.name);
                propValue = (propValue !== null && propValue !== undefined ? propValue : propLibraryValue);
                if (propValue !== null && propValue !== undefined) {
                    const propLabel = (STRINGS.runbookEditor.nodeLibrary.propertyLabels[prop.label] ?
                        STRINGS.runbookEditor.nodeLibrary.propertyLabels[prop.label] : prop.label
                    );
                    switch (prop.type) {
                        case "rules":
                        case "outputs":
                        case "property":
                        case "propertyType":
                            continue;
                        case "boolean":
                            formElements.push(
                                <tr key={this.controlId++ + ""}>
                                    <td className="p-1" colSpan={2}><Checkbox type="checkbox" id={prop.name} name={prop.name} 
                                        defaultChecked={propValue} label={propLabel} 
                                    /></td>
                                </tr>
                            );
                            break;
                        case "text":
                        case "password":
                        case "email":
                        case "tel":
                        case "url":
                            formElements.push(
                                <tr key={this.controlId++ + ""}>
                                    <td className="p-1"><label>{propLabel}:</label></td>
                                    <td className="p-1"><InputGroup type={prop.type} id={prop.name} name={prop.name} 
                                        defaultValue={propValue} className="editor-input-standard" 
                                    /></td>
                                </tr>
                            );
                            break;
                        case "textarea":
                            formElements.push(
                                <tr key={this.controlId++ + ""}>
                                    <td className="p-1"><label>{propLabel}:</label></td>
                                    <td className="p-1"><TextArea id={prop.name} name={prop.name} defaultValue={propValue} 
                                        className="editor-input-textarea" style={{height: "200px"}}
                                    /></td>
                                </tr>
                            );
                            break;
                        case "select": {
                            const options: Array<{label: string, value: string}> = [];
                            if (prop.options) {
                                for (const option of prop.options) {
                                    const optionLabel = (STRINGS.runbookEditor.nodeLibrary.propertyLabels[option.label] ?
                                        STRINGS.runbookEditor.nodeLibrary.propertyLabels[option.label] : option.label
                                    );
                                    options.push({label: optionLabel, value: option.value});
                                }
                            }
                            formElements.push(
                                <tr key={this.controlId++ + ""}>
                                    <td className="p-1"><label>{propLabel}:</label></td>
                                    <td className="p-1"><HTMLSelect id={prop.name} name={prop.name} defaultValue={propValue} 
                                        options={options} className="editor-input-standard"
                                    /></td>
                                </tr>
                            );
                            break;
                        }
                        case "group": {
                            // This one is special it requires the list of ui_group configuration nodes.
                            const options: Array<{label: string, value: string}> = [];
                            for (const globalNode of this.props.globalNodes) {
                                if (globalNode.type === "ui_group") {
                                    let tab = null;
                                    if (globalNode.properties) {
                                        for (const property of globalNode.properties) {
                                            if (property.key === "tab") {
                                                tab = property.value;
                                                break;
                                            }
                                        }
                                    }
                                    for (const tabGlobalNode of this.props.globalNodes) {
                                        if (tabGlobalNode.type === "ui_tab" && tab && tab === tabGlobalNode.id) {
                                            options.push({value: globalNode.id, label: tabGlobalNode.name + " - " + globalNode.name});
                                            break;
                                        }
                                    }
                                }
                            }
                            formElements.push(
                                <tr key={this.controlId++ + ""}>
                                    <td className="p-1"><label>{propLabel}:</label></td>
                                    <td className="p-1"><HTMLSelect id={prop.name} name={prop.name} defaultValue={propValue} 
                                        options={options} className="editor-input-standard" 
                                    /></td>
                                </tr>
                            );
                            break;
                        }
                        default:
                            formElements.push(<tr key={this.controlId++ + ""}>
                                <td className="p-1">{prop.name}:</td>
                                <td className="p-1">{propValue.toString()}</td>
                            </tr>);
                    }
                }
            }
        }

        const propertyLabel = STRINGS.runbookEditor.nodeLibrary.propertyLabels.switchProperty;
        const propertyOptions = SwitchNodeEditor.PROP_TYPE_OPTIONS.map((option) => {
            let label = STRINGS.runbookEditor.nodeLibrary.propertyLabels["switchPropertyOption" + option.label];
            return {label: label || option.label, value: option.value};
        });

        const enterTextLabel = STRINGS.runbookEditor.nodeLibrary.propertyLabels.switchEnterText;
        const casesLabel = STRINGS.runbookEditor.nodeLibrary.propertyLabels.switchCases;
        const casesOptions = SwitchNodeEditor.FILTER_OPTIONS.map((option) => {
            let label = STRINGS.runbookEditor.nodeLibrary.propertyLabels["switchCaseOption" + option.label];
            return {label: label || option.label, value: option.value};
        });

        const addBtnText = STRINGS.runbookEditor.nodeLibrary.propertyLabels.switchAddBtnText;

        // These are the custom controls
        const controls: Array<JSX.Element> = [];
        const editor = this;
        for (let ruleIndex1 = 0; ruleIndex1 < this.rules.current.length; ruleIndex1++) {
            const createControlClosure = function(ruleIndex) {
                return <ControlGroup key={editor.controlId++} fill={true}>
                    <HTMLSelect options={casesOptions} 
                        onChange={(evt) => {
                            editor.rules.current[ruleIndex].t = evt.target.value;
                        }} defaultValue={editor.rules.current[ruleIndex].t}
                    />
                    <InputGroup placeholder={enterTextLabel} 
                        onChange={(evt) => {
                            editor.rules.current[ruleIndex].v = evt.target.value;
                        }} defaultValue={editor.rules.current[ruleIndex].v}
                    />
                    <Button icon="delete" onClick={() => {
                        editor.rules.current.splice(ruleIndex, 1);
                        const newState = JSON.parse(JSON.stringify(editor.rules.current));
                        editor.setState(newState);
                    }} />
                </ControlGroup>;
            };
            controls.push(createControlClosure(ruleIndex1));
        }

        return (<>
            {formElements}
            <tr>
                <td className="pb-3" colSpan={2}>
                    <ControlGroup key={editor.controlId++} className="mt-4 mb-3" fill={true}>
                        <Label>{propertyLabel}:</Label>&nbsp;&nbsp;
                        <HTMLSelect options={propertyOptions} 
                            onChange={(evt) => {
                                editor.properties.current.propertyType = evt.target.value;
                            }} defaultValue={editor.properties.current.propertyType}
                        />
                        <InputGroup placeholder={enterTextLabel} 
                            onChange={(evt) => {
                                editor.properties.current.property = evt.target.value;
                            }} defaultValue={editor.properties.current.property}
                        />
                    </ControlGroup>
                    <ControlGroup fill={true}>
                        <Label>{casesLabel}:</Label>
                        <Button style={{height: "10px"}} aria-label="layout-edit-tab" type="submit" className={Classes.FIXED}
                            onClick={() => {
                                this.rules.current.push({t: "eq", "v": "Case 1", vt: "str"});
                                const newState = JSON.parse(JSON.stringify(this.rules.current));
                                this.setState(newState);
                        }}>
                            {addBtnText}
                        </Button>
                    </ControlGroup>
                    {controls}
                </td>
            </tr>
        </>);
    }
 
    /** updates the selected node with the current form values. */
    private updateNode (): void {
        if (this.props.libraryNode && this.props.libraryNode.properties) {
            for (const prop of this.props.libraryNode.properties) {
                if (this.props.selectedNode) {
                    let propValue;
                    switch (prop.type) {
                        case "boolean":
                            propValue = this.props?.selectedNode?.getProperty(prop.name);
                            propValue = (document?.getElementById(prop.name) as HTMLInputElement).checked;
                            if (propValue !== null && propValue !== undefined) {
                                this.props.selectedNode.setProperty(prop.name, propValue);
                            }
                            break;
                        case "text":
                        case "password":
                        case "email":
                        case "tel":
                        case "url":
                            propValue = (document?.getElementById(prop.name) as HTMLInputElement).value;
                            this.props.selectedNode.setProperty(prop.name, propValue);
                            break;
                         case "textarea":
                            propValue = (document?.getElementById(prop.name) as HTMLTextAreaElement).value;
                            this.props.selectedNode.setProperty(prop.name, propValue);
                            break;
                        case "select":
                        case "group":
                            propValue = (document?.getElementById(prop.name) as HTMLSelectElement).value;
                            this.props.selectedNode.setProperty(prop.name, propValue);
                            break;
                        case "rules":
                            this.props.selectedNode.setProperty("rules", [].concat(this.rules.current));
                            break;
                        case "outputs":
                            this.props.selectedNode.setProperty("outputs", this.rules.current.length);
                            break;
                        case "propertyType":
                            this.props.selectedNode.setProperty("propertyType", this.properties.current.propertyType);
                            break;
                        case "property":
                            this.props.selectedNode.setProperty("property", this.properties.current.property);
                            break;
                    }
                }
            }
        }
    }
}
