import { SummaryCard } from "components/common/layout/summary-card/SummaryCard.tsx";
import { SIZE } from "components/enums/Sizes.ts";
import { STRINGS } from "app-strings";
import { AWSSupportedTypes } from "utils/cloudim/TopologyUtils.ts";
import NetworkInTimeSeries from "./network-in-timeseries/NetworkInTimeSeriesView.tsx";
import NetworkOutTimeSeries from "./network-out-timeseries/NetworkOutTimeSeriesView.tsx";
import CPUUsageTimeSeriesView from "./cpu-usage-timeseries/CPUUsageTimeSeriesView.tsx";

export interface MetricsTabProps {
    type: AWSSupportedTypes;
    attributes: any;
}

const MetricsTab = ({ type, attributes }: MetricsTabProps) => {
    // Every Minute
    const defaultPeriod = 60;
    let metricType = "";

    if (type === AWSSupportedTypes.COMPUTEINSTANCE) {
        metricType = "InstanceId";
    }

    return (
        <div className="d-flex flex-wrap">
            <div className="col-lg-6">
                <SummaryCard size="flex" height={SIZE.l} title={STRINGS.cloudim.topology.modal.metrics.cpuUsage} alignTitle="left" titleFontWeight="fw-bold" titlePadding={false}>
                    <CPUUsageTimeSeriesView
                        id={attributes.entity_id}
                        type={metricType}
                        startTime={new Date(Date.now() - 60 * 60 * 1000)}
                        period={defaultPeriod}
                        dataSourceId={attributes.dataSourceId}
                        region={attributes.Region}
                    />
                </SummaryCard>
            </div>
            <div className="col-lg-6">
                <SummaryCard size="flex" height={SIZE.l} title={STRINGS.cloudim.topology.modal.metrics.networkIn} alignTitle="left" titleFontWeight="fw-bold" titlePadding={false}>
                    <NetworkInTimeSeries
                        id={attributes.entity_id}
                        type={metricType}
                        startTime={new Date(Date.now() - 60 * 60 * 1000)}
                        period={defaultPeriod}
                        dataSourceId={attributes.dataSourceId}
                        region={attributes.Region}
                    />
                </SummaryCard>
            </div>
            <div className="col-lg-6">
                <SummaryCard size="flex" height={SIZE.l} title={STRINGS.cloudim.topology.modal.metrics.networkOut} alignTitle="left" titleFontWeight="fw-bold" titlePadding={false}>
                    <NetworkOutTimeSeries
                        id={attributes.entity_id}
                        type={metricType}
                        startTime={new Date(Date.now() - 60 * 60 * 1000)}
                        period={defaultPeriod}
                        dataSourceId={attributes.dataSourceId}
                        region={attributes.Region}
                    />
                </SummaryCard>
            </div>
        </div>
    );
};

export default MetricsTab;
