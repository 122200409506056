import { type LangEN, STRINGS } from "app-strings";
import { Form } from "components/common/form/Form.tsx";
import { SelectField } from "components/common/form/SelectField.tsx";
import type { InstalledIntegration } from "pages/integrations/types/IntegrationTypes.ts";
import { Icon } from "@tir-ui/react-components";
import { SDWAN_ICONS } from "components/sdwan/enums/icons.ts";
import { useEffect, useState } from "react";
import type React from "react";
import { PrimitiveVariableType } from "utils/runbooks/VariablesUtils.ts";
import { IntegrationLibraryService } from "utils/services/IntegrationLibraryApiService.ts";
import { ThirdPartyIntegrationService } from "utils/services/ThirdPartyIntegrationApiService.ts";
import { Intent } from "@blueprintjs/core";

interface Props {
    integrations: InstalledIntegration[];
    subflows: Array<any>;
    integrationId: string;
    requiresProfile: boolean;
    subflowOriginalId: string;
    subflowResolvedId: string;
    connectorId: string;
    handleConnectorChange: (dependencyId: string, connectorId: string) => any;
}

export default function ImportRunbookConnectorSelector({
    integrations,
    subflows,
    integrationId,
    requiresProfile,
    subflowOriginalId,
    subflowResolvedId,
    connectorId,
    handleConnectorChange
}: Props) {
    const translations: LangEN["runbooks"]["importModal"] = STRINGS["runbooks"]["importModal"];
    const [loading, setLoading] =
        useState(true);
    const [options, setOptions] = useState<
        Array<{ label: string; value: string }>
    >([]);
    const [selectedValue, setSelectedValue] = useState(connectorId);

    useEffect(() => {
        async function getConnectors() {
            setLoading(true);
            const matchingIntegration = integrations?.find((el) => el.integrationId === integrationId);
            
            if (!matchingIntegration?.integrationId) {
                return [];
            }

            const subflowData = subflows.find(el => el.id === subflowResolvedId);
            const nodeTags = subflowData?.tagIds;

            // Need to retrieve the list of tags
            let subflowNodeTagsMap = {};
            let supportedTagsBySubflowConnector: Array<any> = [];
            if (nodeTags && nodeTags[0]?.value) {
                subflowNodeTagsMap =  nodeTags[0]?.value;
            } 
            
            const subflowConnectorVariable = (subflowData?.inputVariables || []).find(el => {
                return el.type === PrimitiveVariableType.CONNECTOR
            });

            if (Object.hasOwn(subflowNodeTagsMap, subflowConnectorVariable?.name || 'key')) {
                supportedTagsBySubflowConnector = subflowNodeTagsMap[subflowConnectorVariable?.name];
            }

            const integrationConnectors = await IntegrationLibraryService.getConnectors(matchingIntegration.integrationId);
            const options = integrationConnectors.filter(connector => {
                // Hide Disabled Connectors
                if (!connector.isEnabled) {
                    return false;
                }

                // Show Wildcard Connector
                if (!connector?.tags || connector?.tags?.length === 0) {
                    return true;
                }
                
                // Show integration connector that supports the subflow connector tags
                const connectorSupportsTag = connector.tags.find(el => supportedTagsBySubflowConnector.includes(el.key));
                
                return connectorSupportsTag;
            }).map((connector) => {
                return {
                    label: connector.name,
                    value: connector.connectorId,
                };
            });

            return options;
        }

        async function getProfiles() {
            setLoading(true);

            const result = await ThirdPartyIntegrationService.getRunbookAuthProfiles()
            const options = result.map((profile) => {
                return {
                    label: profile.name,
                    value: profile.id || profile.name,
                };
            });

            return options;
        }

        async function getOptions() {
            try {
                setLoading(true);
                const options = requiresProfile ? await getProfiles() : await getConnectors();
                
                setOptions(options);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }

        // Only set options if props are loaded

        // For Custom Subflows we use the profiles dropdown, we don't have integrations data
        if (requiresProfile) {
            getOptions();
        } else if (integrationId && subflowResolvedId && integrations?.length > 0) { // For Integration Subflows we have the integrationId
            getOptions();
        }
    }, [integrationId, subflows, subflowOriginalId, subflowResolvedId, integrations, integrations?.length, requiresProfile]);

    // Pre-select the option 
    useEffect(() => {
        if (subflowOriginalId && options && options?.length > 0) {
            const value = options[0]?.value;
            setSelectedValue(value);
            handleConnectorChange(subflowOriginalId, value);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subflowOriginalId, options, options?.length])

    if (loading) {
        return <span>{translations.loading}</span>;
    }

    if (options.length === 0) {
        return (
            <span> 
                <Icon
                    icon={SDWAN_ICONS.ALERT}
                    intent={Intent.DANGER}
                    className="me-1"
                />
                {translations.connectorSelector.noConnectorTag}
            </span>
        );
    }

    return (
        <Form
            loading={loading}
            initialValues={{ connector: connectorId }}
        >
            <SelectField
                data-testid="import-runbook_connector-select"
                name="connector"
                value={selectedValue}
                onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => {
                    ev.persist();
                    setSelectedValue(ev?.currentTarget.value);
                    handleConnectorChange(subflowOriginalId, ev?.currentTarget.value)
                }}
            >
                <option key={translations.connectorSelector.noSelection} label={translations.connectorSelector.noSelection} value={undefined} />
                {options.map(connector => <option key={connector.label} value={connector.value}>{connector.label}</option>)}
            </SelectField>
        </Form>
    );
}
