import { useRef, useState } from 'react';
import { Button, Icon, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@tir-ui/react-components';
import { type LangEN, STRINGS } from 'app-strings';
import { TextAreaField } from 'components/common/form/TextAreaField.tsx';
import { InputField } from 'components/common/form/InputField.tsx';
import type { FormikProps } from 'formik';

interface Props {
    formProps: FormikProps<object>;
    clientCertificateInPemFormat: string | undefined;
    clientPrivateKey: string | undefined;
    passphraseForPemFormat: string | undefined;
    hasClientCertificateInCloud: boolean;
    showClientCertificateFields: boolean;
    onChangeClientCertificateInPemFormatFromTextField?: (event) => void;
    onChangeClientCertificateInPemFormatFromFile?: (event) => void;
    onChangeClientPrivateKeyFromTextField?: (event) => void;
    onChangeClientPrivateKeyFromFile?: (event) => void;
    onChangePassphraseForPemFormat?: (event) => void;
}

const OptionalClientCertificateFields = ({ 
    formProps, 
    clientCertificateInPemFormat,
    clientPrivateKey,
    passphraseForPemFormat,
    hasClientCertificateInCloud,
    showClientCertificateFields,
    onChangeClientCertificateInPemFormatFromTextField,
    onChangeClientCertificateInPemFormatFromFile,
    onChangeClientPrivateKeyFromTextField,
    onChangeClientPrivateKeyFromFile,
    onChangePassphraseForPemFormat
}: Props) => {
    const translations: LangEN["thirdPartyIntegrations"]["addAuthProfile"]["panels"]["clientCertificateAuthParams"] = STRINGS.thirdPartyIntegrations.addAuthProfile.panels.clientCertificateAuthParams;
    const hiddenUploadCertificateFileInput = useRef<HTMLInputElement>(null); 
    const hiddenUploadPrivateKeyFileInput = useRef<HTMLInputElement>(null); 
    const [revealPrivateKeyField, setRevealPrivateKeyField] = useState<boolean>(false);
    const showEditPlaceholder = hasClientCertificateInCloud;

    return <fieldset className="border p-2 fieldset--with-bg">
        <div className="authentication-parameters-field-wrap mt-2">
            <TextAreaField
                name="client_certificate_in_pem_format"
                required={true}
                label={translations.fields.clientCertificateUsingPemFormat.label}
                placeholder={translations.fields.clientCertificateUsingPemFormat.placeholder}
                onBlur={(event) => {
                    const value = event.target.value?.trim();

                    formProps.handleBlur(event);
                    onChangeClientCertificateInPemFormatFromTextField && onChangeClientCertificateInPemFormatFromTextField({
                        target: {
                            value: value
                        }
                    });
                } }
                onChange={onChangeClientCertificateInPemFormatFromTextField}
                value={clientCertificateInPemFormat}
                disabled={false} />
            <Tooltip content={translations.tooltip} placement="right" usePortal={false}>
                <Icon icon={IconNames.HELP} />
            </Tooltip>
            <div className="authentication-parameters-upload-field-wrap">
                <Button intent="primary" onClick={() => { hiddenUploadCertificateFileInput.current?.click(); } } type="button">{translations.upload}</Button>
                <input type="file" ref={hiddenUploadCertificateFileInput} onChange={onChangeClientCertificateInPemFormatFromFile} />
            </div>
        </div>
        <div className="authentication-parameters-field-wrap client-private-key mt-4">
            {(!showEditPlaceholder || revealPrivateKeyField) && <TextAreaField
                name="client_private_key"
                required={!showEditPlaceholder}
                label={translations.fields.clientPrivateKey.label}
                placeholder={(showEditPlaceholder) ?
                    translations.fields.clientPrivateKey.placeholderForEdit :
                    translations.fields.clientPrivateKey.placeholder}
                onBlur={(event) => {
                    const value = event.target.value?.trim();

                    formProps.handleBlur(event);
                    onChangeClientPrivateKeyFromTextField && onChangeClientPrivateKeyFromTextField({
                        target: {
                            value: value
                        }
                    });
                } }
                onChange={onChangeClientPrivateKeyFromTextField}
                value={clientPrivateKey}
                disabled={false} />}
            {showEditPlaceholder && revealPrivateKeyField &&
                <Tooltip content={translations.tooltip} placement="right" usePortal={false}>
                    <Icon icon={IconNames.HELP} />
                </Tooltip>}
            {(showClientCertificateFields && hasClientCertificateInCloud) && !revealPrivateKeyField &&
                <div className="privateKeyClearedHeading">
                    {translations.fields.clientPrivateKey.labelWhenClear}
                    <span><Icon icon="tick-circle" size={17} intent="success" /> {translations.uploaded}</span>
                    <Button intent="primary" onClick={() => {
                        setRevealPrivateKeyField(true);
                    } } type="button">{translations.clear}</Button>
                </div>}
            <div className="authentication-parameters-upload-field-wrap">
                {(!hasClientCertificateInCloud || !showClientCertificateFields || revealPrivateKeyField) && <Button intent="primary" onClick={() => { hiddenUploadPrivateKeyFileInput.current?.click(); } } type="button">{translations.upload}</Button>}
                <input type="file" ref={hiddenUploadPrivateKeyFileInput} onChange={onChangeClientPrivateKeyFromFile} />
            </div>
        </div>

        <InputField
            name="passphrase_for_pem_format"
            type="password"
            required={false}
            label={translations.fields.passphrase.label}
            placeholder={(showEditPlaceholder) ?
                translations.fields.passphrase.placeholderForEdit :
                translations.fields.passphrase.placeholder}
            onBlur={(event) => {
                const value = event.target.value?.trim();

                formProps.handleBlur(event);
                onChangePassphraseForPemFormat && onChangePassphraseForPemFormat({
                    target: {
                        value: value
                    }
                });
            } }
            onChange={onChangePassphraseForPemFormat}
            value={passphraseForPemFormat}
            disabled={false}
            showPasswordOption={true} />
    </fieldset>;
}

export default OptionalClientCertificateFields;
