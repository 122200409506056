query interfaceSearch($top: Int!, $skip: Int!, $count: Boolean!, $search: String, $searchFields: [NetworkInterfaceSearchFieldEnum], $facets: [NetworkInterfaceRequestFacetType!], $filter: NetworkInterfaceSearchFilterType, $order: [NetworkInterfaceSearchSortInputType!]) {
    searchItems: searchNetworkInterfaces(
        top: $top,
        skip: $skip,
        count: $count,
        facets: $facets,
        filter: $filter,
        order: $order,
        search: $search,
        searchFields: $searchFields
    ) {
        info {
            totalCount
            facets {
                key
                result {
                    count
                    items {
                        value
                        count
                    }
                }
            }
            groupedFacets {
                key
                results {
                    group
                    count
                    items {
                        count
                        value
                    }
                }
            }
        }
        page {
            id
            name
            ifAlias
            ifDescription
            ifIndex
            ifIpAddresses
            ipaddr
            inboundSpeed
            outboundSpeed
            parentNetworkDevice {
                name
            }
            parentNetworkDeviceId
            elementType
            type
            location {
                id
                name
                city
                state
                country
            }
            locationId
            reportedBy {
                dataSourceId
                dataSourceType
                entityId
            }
            customProperties {
                id
                name
                assignedValue {
                    id
                    name                                            
                }
            }
        }
    }
}
