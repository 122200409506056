import React, { useRef, useState } from "react";
import { LangEN, STRINGS } from "app-strings";
import { IntegrationLibraryService } from "utils/services/IntegrationLibraryApiService";
import {
    AvailableIntegrationDetails,
    CreateIntegrationPayload,
    INTEGRATION_PROCESS_VARIANT,
    InstalledIntegrationStatus,
    IntegrationProcess,
} from "../types/IntegrationTypes";
import { useInterval } from "utils/hooks/useInterval";
import { ErrorToaster, IconNames } from "@tir-ui/react-components";
import {
    Button,
    ButtonGroup,
    Callout,
    Classes,
    Dialog,
    Intent,
    ProgressBar,
} from "@blueprintjs/core";
import { AxiosError } from "axios";
import { IntegrationErrorModal } from "./IntegrationErrorModal";
import { getErrorsFromApiResponse } from "utils/errors/ErrorUtils";

export interface IntegrationProgressDialogProps {
    integration: AvailableIntegrationDetails;
    payload: CreateIntegrationPayload;
    closeConfigModal: () => void;
    handleProcessFinish: (process: IntegrationProcess, integrationId: string) => void;
}

export const IntegrationProgressDialog = React.forwardRef(
    (
        {
            integration,
            payload,
            closeConfigModal,
            handleProcessFinish,
        }: IntegrationProgressDialogProps,
        ref
    ) => {
        const DEFAULT_POLLING_INTERVAL = 10 * 1000;
        const [changeInProgress, setChangeInProgress] = useState(false);
        const integrationErrorModalRef = useRef(null);
        const [progressPollingDelay, setProgressPollingDelay] = useState<
            number | null
        >(null);
        const [loading, setLoading] = useState(true);
        const [isOpen, setIsOpen] = useState(false);
        const [hasErrorDetails, setHasErrorDetails] = useState(false);
        const [activeProcess, setActiveProcess] =
            useState<IntegrationProcess>(INTEGRATION_PROCESS_VARIANT.install);
        const [errorMessage, setErrorMessage] = useState("");
        const [progressBarValue, setProgressBarValue] = useState(0);
        const translations: LangEN["INTEGRATIONS_PAGE"] =
            STRINGS.INTEGRATIONS_PAGE;
        const errorCodes:  LangEN["thirdPartyIntegrations"]["errors"] = STRINGS.thirdPartyIntegrations.errors;

        // Progress Bar Progress - fake progress for UX
        useInterval(() => {
            let val = progressBarValue;
            val += Math.random() / 5;

            if (val > 0.9) {
                val = 0.9;
            }

            setProgressBarValue(val);
        }, 1000);

        /**
         * Actions to control the Connector Modal from outside
         */
        React.useImperativeHandle(ref, () => ({
            toggleOpen() {
                setIsOpen(!isOpen);
            },
            triggerInstall() {
                setActiveProcess(INTEGRATION_PROCESS_VARIANT.install);
                setLoading(true);
                setProgressPollingDelay(null);
                setChangeInProgress(true);
                installIntegration();
            },
            triggerUninstall() {
                setActiveProcess(INTEGRATION_PROCESS_VARIANT.uninstall);
                setLoading(true);
                setProgressPollingDelay(null);
                setChangeInProgress(true);
                uninstallIntegration();
            },
            triggerUpdate() {
                setActiveProcess(INTEGRATION_PROCESS_VARIANT.update);
                setLoading(true);
                setChangeInProgress(true);
                setProgressPollingDelay(null);
                updateIntegration();
            },
            triggerUpgrade(newVersion: string) {
                setActiveProcess(INTEGRATION_PROCESS_VARIANT.upgrade);
                setLoading(true);
                setChangeInProgress(true);
                setProgressPollingDelay(null);
                upgradeIntegration(newVersion);
            },
        }));

        /**
         * Get the integration install/uninstall progress status
         *
         * @returns {Promise} - the status for the process of installing/uninstalling an integration
         */
        async function fetchIntegrationStatus() {
            if (!integration?.id) {
                return;
            }

            const response =
                await IntegrationLibraryService.getIntegrationStatus(
                    integration?.id
                );

            return response;
        }

        /**
         * Call the install integration endpoint
         */
        async function installIntegration() {
            try {
                await IntegrationLibraryService.installIntegration(
                    integration.id,
                    integration.version,
                    payload
                );

                // Start the polling for the status
                setProgressPollingDelay(DEFAULT_POLLING_INTERVAL);
            } catch (error) {
                const errors = getErrorsFromApiResponse(
                    error, 
                    errorCodes,
                    translations.progressDialog.errors.INTEGRATION_INSTALL_FAILURE
                )

                setErrorMessage(errors?.length > 0 ? 
                    errors.join('<br /> <br />'):
                    translations.progressDialog.errors.INTEGRATION_INSTALL_FAILURE);

                onProcessEnded(false);
                ErrorToaster({
                    message:
                        translations.progressDialog.errors
                            .INTEGRATION_INSTALL_FAILURE,
                });
            }
        }

        /**
         * Call the uninstall integration endpoint
         */
        async function uninstallIntegration() {
            try {
                await IntegrationLibraryService.uninstallIntegration(
                    integration.id
                );

                // Start the polling for the status
                setProgressPollingDelay(DEFAULT_POLLING_INTERVAL);
            } catch (error) {
                console.error(error);
                ErrorToaster({
                    message:
                        translations.progressDialog.errors
                            .INTEGRATION_UNINSTALL_FAILURE,
                });
            }
        }

        /**
         * Call the update integration endpoint
         */
        async function updateIntegration() {
            try {
                await IntegrationLibraryService.updateIntegration(
                    integration.id,
                    payload
                );

                // Start the polling for the status
                setProgressPollingDelay(DEFAULT_POLLING_INTERVAL);
            } catch (error) {
                console.error(error);
                ErrorToaster({
                    message: translations.progressDialog.errors.INTEGRATION_UPDATE_WITH_ERRORS,
                });
            }
        }

        /**
         * Call the upgrade integration endpoint
         */
        async function upgradeIntegration(newVersion) {
            try {
                await IntegrationLibraryService.upgradeIntegration(
                    integration.id,
                    newVersion
                );

                // Start the polling for the status
                setProgressPollingDelay(DEFAULT_POLLING_INTERVAL);
            } catch (error) {
                const errors = getErrorsFromApiResponse(
                    error, 
                    errorCodes,
                    translations.progressDialog.errors.INTEGRATION_UPDATE_WITH_ERRORS
                )

                setErrorMessage(errors?.length > 0 ? 
                    errors.join('<br /> <br />'):
                    translations.progressDialog.errors.INTEGRATION_UPDATE_WITH_ERRORS);

                onProcessEnded(false);
                ErrorToaster({
                    message: translations.progressDialog.errors.INTEGRATION_UPDATE_WITH_ERRORS,
                });
            }
        }
        
        /**
         * Manage the installation progress
         */
        async function manageInstallationProgress() {
            try {
                const statusResponse = await fetchIntegrationStatus();
                const finishedProcessStatuses = [
                    InstalledIntegrationStatus.InstallationCompletedWithErrors,
                    InstalledIntegrationStatus.InstallationCompletedWithSuccess,
                ];

                // The process has been finished
                if (
                    statusResponse &&
                    statusResponse?.status &&
                    finishedProcessStatuses.includes(statusResponse?.status)
                ) {
                    // Integration has been installed with errors
                    if (
                        statusResponse?.status ===
                        InstalledIntegrationStatus.InstallationCompletedWithErrors
                    ) {
                        setErrorMessage(
                            translations.progressDialog.errors
                                .INTEGRATION_INSTALL_WITH_ERRORS
                        );
                    }

                    onProcessEnded(true);
                }
            } catch (error) {
                console.error(error);
                setErrorMessage(
                    translations.progressDialog.errors
                        .INTEGRATION_INSTALL_FAILURE
                );
                onProcessEnded(true);
            }
        }

        /**
         * Manage the update progress
         */
        async function manageUpdateProgress() {
            try {
                const statusResponse = await fetchIntegrationStatus();
                const finishedProcessStatuses = [
                    InstalledIntegrationStatus.UpdateInstallationCompletedWithErrors,
                    InstalledIntegrationStatus.UpdateInstallationCompletedWithSuccess,
                ];

                // The process has been finished
                if (
                    statusResponse &&
                    statusResponse?.status &&
                    finishedProcessStatuses.includes(statusResponse?.status)
                ) {
                    // Integration has been updated with errors
                    if (
                        statusResponse?.status ===
                        InstalledIntegrationStatus.UpdateInstallationCompletedWithErrors
                    ) {
                        setErrorMessage(
                            translations.progressDialog.errors
                                .INTEGRATION_UPDATE_WITH_ERRORS
                        );
                    }

                    onProcessEnded(true);
                }
            } catch (error) {
                console.error(error);
                setErrorMessage(
                    translations.progressDialog.errors
                        .INTEGRATION_UPDATE_WITH_ERRORS
                );
                onProcessEnded(true);
            }
        }

        /**
         * Manage the upgrade progress
         */
        async function manageUpgradeProcess() {
            try {
                const statusResponse = await fetchIntegrationStatus();
                const finishedProcessStatuses = [
                    InstalledIntegrationStatus.UpgradeInstallationCompletedWithErrors,
                    InstalledIntegrationStatus.UpgradeInstallationCompletedWithSuccess,
                ];

                // The process has been finished
                if (
                    statusResponse &&
                    statusResponse?.status &&
                    finishedProcessStatuses.includes(statusResponse?.status)
                ) {
                    // Integration has been updated with errors
                    if (
                        statusResponse?.status ===
                        InstalledIntegrationStatus.UpgradeInstallationCompletedWithErrors
                    ) {
                        setErrorMessage(
                            translations.progressDialog.errors
                                .INTEGRATION_UPGRADE_WITH_ERRORS
                        );
                    }

                    onProcessEnded(true);
                }
            } catch (error) {
                console.error(error);
                setErrorMessage(
                    translations.progressDialog.errors.INTEGRATION_UPGRADE_WITH_ERRORS
                );
                onProcessEnded(true);
            }
        }
        
        /**
         * Manage the uninstall progress
         */
        async function manageUninstallProgress() {
            try {
                const statusResponse = await fetchIntegrationStatus();
                const finishedProcessStatuses = [
                    InstalledIntegrationStatus.UninstallCompletedWithErrors,
                ];

                // The uninstall process has been finished with errors
                if (
                    statusResponse &&
                    statusResponse?.status &&
                    finishedProcessStatuses.includes(statusResponse?.status)
                ) {
                    onProcessEnded(true);
                    setErrorMessage(
                        translations.progressDialog.errors
                            .INTEGRATION_UNINSTALL_FAILURE
                    );
                }
            } catch (error) {
                // Uninstalling an integration with success, means we will get a 404 when polling the status
                if ((error as AxiosError)?.response?.status === 404) {
                    onProcessEnded(true);

                    return;
                }

                // Other errors
                console.error(error);
                setErrorMessage(
                    translations.progressDialog.errors
                        .INTEGRATION_UNINSTALL_FAILURE
                );
                onProcessEnded(true);
            }
        }

        /**
         * Reset loading state on process ended
         */
        function onProcessEnded(showErrorDetails: boolean) {
            setHasErrorDetails(showErrorDetails);
            setLoading(false);
            setProgressPollingDelay(null);
            setChangeInProgress(false);
        }

        const actionsMap: { [key in IntegrationProcess]: () => void } = {
            update: manageUpdateProgress,
            install: manageInstallationProgress,
            uninstall: manageUninstallProgress,
            upgrade: manageUpgradeProcess,
        };

        /**
         * Polling Logic for Installation Status
         */
        /* istanbul ignore next */
        useInterval(() => {
            if (!actionsMap?.[activeProcess]) {
                return;
            }

            actionsMap[activeProcess]();
        }, progressPollingDelay);

        /**
         * Render the success callout after installing/uninstalling an integration
         */
        function renderSuccessCallout(
            message: string | string[],
            process: IntegrationProcess
        ) {
            const title = translations.progressDialog.title[process];

            return (
                <Callout
                    intent={Intent.NONE}
                    className="h-100 integration-library__progress-dialog p-4"
                >
                    <div className="content">
                        <h4 className={Classes.HEADING}>{title}</h4>
                        <p className="font-size-small">{`${message}`}</p>
                        {renderConfirmButton(process)}
                    </div>
                </Callout>
            );
        }

        /**
         * Render the confirm button
         * 
         * @param process 
         * 
         * @returns {JSX.Element}
         */
        function renderConfirmButton(process: IntegrationProcess) {
            return (
                <ButtonGroup fill>
                    <Button
                        text={translations.progressDialog.confirmButton}
                        className={`ms-auto px-5 ${Classes.FIXED}`}
                        intent={Intent.PRIMARY}
                        outlined={false}
                        minimal={false}
                        onClick={() => {
                            setIsOpen(false);
                            closeConfigModal();
                            handleProcessFinish(process, integration?.id);
                        }} />
                </ButtonGroup>
            );
        }

        /**
         * Render the error callout after installing/uninstalling an integration
         */
        function renderErrorCallout(
            message: string,
            process: IntegrationProcess
        ) {
            const title = translations.progressDialog.title[process];

            return (
                <Callout
                    intent={Intent.DANGER}
                    icon={IconNames.ERROR}
                    className="h-100 d-flex flex-column justify-content-center p-4 ps-5 integrations-library__progress-error-callout"
                    title={title}
                >
                    <p>
                        <span dangerouslySetInnerHTML={{__html: message}}/>
                        {hasErrorDetails && <Button
                            minimal
                            small
                            outlined={false}
                            text={<span className="btn-link">{translations.integrationDetailsModal.errors.seeDetails}</span>}
                            onClick={() => {
                                handleOpenIntegrationErrorModal(integration.id, integration.name);
                            }}
                        />}
                    </p>            
                    {renderConfirmButton(process)}
                </Callout>
            );
        }

        /**
        * Open the Install/Uninstall Error Details Modal
        */
       function handleOpenIntegrationErrorModal (integrationId: string, integrationName: string) {
           const modal = integrationErrorModalRef?.current;
   
           // @ts-ignore
           modal.toggleOpen();
   
           // @ts-ignore
           modal.setIntegrationName(integrationName);
           // @ts-ignore
           modal.setIntegrationId(integrationId);
       }

        /**
         * Render the progress bar shown during install/uninstall process
         */
        function renderProgressBar(process: IntegrationProcess) {
            const message = STRINGS.formatString(
                translations.progressDialog.inProgress[process],
                integration.name
            );

            return (
                <div className="d-flex flex-column p-4" data-cy="ProgressBar">
                    <ProgressBar
                        value={progressBarValue}
                        intent={Intent.PRIMARY}
                    />
                    <p className="font-size-sm mt-2">{message}</p>
                </div>
            );
        }

        const processInProgress = loading && changeInProgress;
        const processFinished = !loading && !changeInProgress;

        /** Render the Dialog */
        return (
            <>
                <Dialog
                    isOpen={isOpen}
                    autoFocus={true}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    enforceFocus={true}
                    usePortal={true}
                    style={{
                        minWidth: 200,
                        padding: 0,
                    }}
                >
                    <div className="d-flex align-items-center justify-content-center">
                        {processInProgress && renderProgressBar(activeProcess)}
                        {processFinished && (
                            <>
                                {errorMessage
                                    ? renderErrorCallout(
                                        errorMessage,
                                        activeProcess
                                    )
                                    : renderSuccessCallout(
                                        STRINGS.formatString(
                                            translations.progressDialog
                                                .successMessage[activeProcess],
                                            integration.name
                                        ),
                                        activeProcess
                                    )}
                            </>
                        )}
                    </div>
                </Dialog>

                {/* Display error details for install/uninstall process */}
                <IntegrationErrorModal 
                    ref={integrationErrorModalRef}                 
                    handleErrors={ (error) => console.error(error) }
                />
            </>
        );
    }
);
