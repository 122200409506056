/** This module creates a new node for the react-flow graph that is a skeleton node.  A 
 *  skeleton node is not a real node that is understood by the backend runbook library, it
 *  is just used in the UI to denote a location where we then the user should add a node.
 *  When the runbook is saved the skeleton nodes are removed.
 *  @module
 */

import { CSSProperties, useState } from "react";
import { Connection, Edge, Handle, NodeProps, Position } from "react-flow-renderer";
import { Menu, Popover, Position as PopoverPosition } from "@blueprintjs/core";
import { STRINGS } from "app-strings";
import "./SkeletonNode.scss";

export default function SkeletonNode({ data }: NodeProps) {
    const handleStyle: CSSProperties = { background: "#b1b1b7" };
    const onConnect = (params: Connection | Edge) => console.log('handle onConnect', params);
    const [showMenu, setShowMenu] = useState(false);
    const [menuItems, setMenuItems] = useState<Array<JSX.Element>>([]);

    return (
        <>
            <Handle
                type="target"
                key={"input-handle-node-" + data.id}
                position={Position.Left}
                onConnect={onConnect}
                style={handleStyle}
            />
            <Popover
                usePortal
                lazy
                position={PopoverPosition.RIGHT}
                isOpen={showMenu}
                content={<Menu>{menuItems}</Menu>}
                autoFocus={false}
                enforceFocus={false}
                hasBackdrop={true}
                canEscapeKeyClose={true}
                onInteraction={(nextOpenState, e) => {
                    if (nextOpenState !== showMenu) {
                        setShowMenu(nextOpenState);
                    }
                }}
            >
                <div className="content" onClick={(e) => onNodeClick(e)}>
                    {STRINGS.runbookEditor.nodeLibrary.nodes.skeleton.label}
                </div>
            </Popover>
        </>
    );

    function onNodeClick(e): any {
        e.preventDefault();
        e.stopPropagation();

        if (data.handleGetMenuItems) {
            const newMenuItems = data.handleGetMenuItems();

            setMenuItems(newMenuItems);
            setShowMenu(newMenuItems.length > 0);
        }

        return null;
    }
}
