/** This module contains the component for creating the list of dashboards.
 *  @module
 */
import { useCallback, useRef, useState } from "react";
import { Button, Intent, Menu, MenuItem, Position, Switch, Popover, PopoverInteractionKind } from "@blueprintjs/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { IconNames, Icon, Table, useLoading } from "@tir-ui/react-components";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade.tsx";
import { useStateSafePromise } from "@tir-ui/react-components";
import { STRINGS } from "app-strings";
import type { TableColumnDef } from "@tir-ui/react-components";
import { BasicDialog, DialogState, updateDialogState } from "components/common/basic-dialog/BasicDialog.tsx";
import { getQueryParam, getURL, setQueryParam } from "utils/hooks/useQueryParams.ts";
import { GetURLPath as getURLPath } from 'config/routes.ts';
import { PARAM_NAME } from "components/enums/QueryParams.ts";
import { ElapsedTimeFormatter } from "reporting-infrastructure/utils/formatters/elapsed-time-formatter/elapsed-time-formatter.tsx";
import { sortColumnWithTimeData } from "reporting-infrastructure/utils/commonUtils.ts";
import { Tab, TabbedSubPages } from "components/common/layout/tabbed-sub-pages/TabbedSubPages.tsx";
import { AuthServiceProvider } from "utils/providers/AuthServiceProvider.ts";
import { AutocompleteColumnFilterControl, CheckboxColumnFilterControl, DateRangeFilterControl, dateRangeFilterFunction } from "@tir-ui/react-components";
import { WrapInTooltip } from "components/common/wrap-in-tooltip/WrapInTooltip.tsx";
import type { DashboardConfig } from "pages/dashboards/Dashboard.type.ts";
import { parseTimeFromDAL } from "utils/hooks/useQueryFormatters.ts";
import { DataOceanUtils } from "components/common/graph/editors/data-ocean/DataOceanUtils.ts";
import DashboardListData from "./DashboardListData.json";
import Dashboard1 from "./Dashboard1.json";
import Dashboard2 from "./Dashboard2.json";
import Dashboard3 from "./Dashboard3.json";
import Dashboard4 from "./Dashboard4.json";
import Dashboard5 from "./Dashboard5.json";
import Dashboard6 from "./Dashboard6.json";
import Dashboard7 from "./Dashboard7.json";
import Dashboard8 from "./Dashboard8.json";
import Dashboard9 from "./Dashboard9.json";
import Dashboard10 from "./Dashboard10.json";
import Dashboard11 from "./Dashboard11.json";
import Dashboard12 from "./Dashboard12.json";
import './DashboardList.scss'
import { useNavigate } from "react-router-dom";

// A reference to the auth service
const AuthService = AuthServiceProvider.getService();

// If this value is true a new dashboard will be saved to the database when the new button is pressed.  If this
// value is false it will launch the editor without creating a new dashboard and the user can then leave the 
// editor without saving the dashboard.
const saveOnCreateNewDashboard = false;

// If true get rid of the individual columns with icons that handle actions and show the more menu
const showMoreMenu = true;

// If true show the more popup when the user hovers over the ..., otherwise they have to click on it
const morePopupOnHover = false;

/** Renders the dashboard list view.
 *  @returns JSX with the dashboard list view component.*/
export function DashboardListView (): JSX.Element {
    const [operationCount, setOperationCount] = useState<number>(0);
    const navigate = useNavigate();
    const [dashboardList, setDashboardList] = useState<Array<DashboardConfig>>();
    const error = useRef(false);
    const deleteId = useRef("");
    const [selectedId, setSelectedId] = useState<string | null>(null);
    const [executeSafely] = useStateSafePromise();
    const userName = AuthService.getUserAccount()?.getUsername() || "";

    const getDashboardRenders = useCallback((dashboard, operationCount)=> {
        const { name, description, createdTime, lastUpdatedTime, createdByUser, lastUpdatedUser } = getDashboardMetaData(dashboard);
        const moreMenuItems: Array<JSX.Element> = [];
        moreMenuItems.push(
            <MenuItem disabled={!canEditDashboard(dashboard)} text={STRINGS.dashboards.more.editViewMenuItem} active={false} key={"edit"}
                onClick={() => navigate(getURL(getURLPath("view-dashboard"), {[PARAM_NAME.rbConfigId]: dashboard.id, [PARAM_NAME.rbConfigNm]: dashboard.name}))} 
            />
        );
        moreMenuItems.push(
            <MenuItem disabled={false} text={STRINGS.dashboards.more.exportMenuItem} active={false} key={"export"}
                onClick={() => exportContent(dashboard.id, setDialogState)} 
            />
        );
        moreMenuItems.push(
            <MenuItem disabled={!canEditDashboards()} text={STRINGS.dashboards.more.duplicateMenuItem} active={false} key={"duplicate"}
                onClick={() => duplicateDashboard(dashboard.id, setDialogState, operationCount, setOperationCount)} 
            />
        );
        moreMenuItems.push(
            <MenuItem disabled={!canEditDashboard(dashboard)} text={STRINGS.dashboards.more.deleteMenuItem} active={false} key={"delete"}
                onClick={() => {
                    deleteId.current = dashboard.id; 
                    handleDelete(deleteId.current, true/*enabled*/, setDialogState, operationCount, setOperationCount);
                }} 
            />
        );
        moreMenuItems.push(
            <MenuItem disabled={false} text={STRINGS.dashboards.more.testMenuItem} active={false} key={"test"}
                onClick={() => navigate(getURL(getURLPath("view-dashboard"), {[PARAM_NAME.rbConfigId]: dashboard.id, [PARAM_NAME.rbConfigNm]: dashboard.name}))} 
            />
        );
        moreMenuItems.push(
            <MenuItem disabled={false} text={STRINGS.dashboards.more.previewMenuItem} active={false} key={"preview"}
                onClick={() => navigate(getURL(getURLPath("view-dashboard"), {[PARAM_NAME.rbConfigId]: dashboard.id, [PARAM_NAME.rbConfigNm]: dashboard.name, [PARAM_NAME.rbViewMode]: "preview"}))} 
            />
        );
        return {
            ...dashboard,
            operationCount,
            name,
            createdTime,
            createdByUser,
            description,
            lastUpdatedTime,
            lastUpdatedBy: lastUpdatedUser !== "" ? lastUpdatedUser : STRINGS.dashboards.systemText,
            edit: <Button aria-label="dashboard-edit-button" icon={IconNames.EDIT} minimal className="dashboard-action-icon" 
                disabled={!canEditDashboard(dashboard)} onClick={(e) => {
                    navigate(getURL(getURLPath("view-dashboard"), {[PARAM_NAME.rbConfigId]: dashboard.id, [PARAM_NAME.rbConfigNm]: dashboard.name}));
                }} 
            />,
            duplicate: <Button aria-label="dashboard-duplicate-button" icon={IconNames.DUPLICATE} minimal className="dashboard-action-icon" 
                disabled={!canEditDashboard(dashboard)} onClick={async (e) => {duplicateDashboard(dashboard.id, setDialogState, operationCount, setOperationCount)}} 
            />,
            delete: <Button aria-label="dashboard-delete-button" icon={IconNames.DELETE} minimal className="dashboard-action-icon" 
                disabled={!canEditDashboard(dashboard)} onClick={(e) => {
                    deleteId.current = dashboard.id; 
                    handleDelete(deleteId.current, true, setDialogState, operationCount, setOperationCount);
                }} 
            />,
            more: <div onClick={(e) => {e.stopPropagation();}}>
                <Popover position={Position.BOTTOM_RIGHT} 
                    interactionKind={morePopupOnHover ? PopoverInteractionKind.HOVER : PopoverInteractionKind.CLICK} 
                    content={
                        <Menu>{moreMenuItems}</Menu>
                } >
                    <Button aria-label="dashboard-more-button" icon={IconNames.MORE} minimal className="dashboard-action-icon" 
                        disabled={false} onClick={(e) => {}} 
                    />
                </Popover>
            </div>
        }
    }, [history]);

    const setDOUtilsInitialized = useState(false)[1];
    const fetchDashboards = useCallback(
        () => {
            return executeSafely(async () => {
                await DataOceanUtils.init();
                setDOUtilsInitialized(true);
                try {
                    const dashboards: DashboardConfig[] = DashboardListData as any; //await runbookService.getRunbooks();
                    setDashboardList(dashboards.map(dashboard => getDashboardRenders(dashboard, operationCount)));
                } catch (ex) {
                    error.current = true;
                    console.error(ex);
                    setDashboardList([]);
                }
            });
        },
        [operationCount, executeSafely, getDashboardRenders, setDOUtilsInitialized]
    );
    const [loading] = useLoading(fetchDashboards);

    const columns: Array<TableColumnDef> = [
        {
            id: "name",
            Header: STRINGS.dashboards.columns.name,
            accessor: "name",
            sortable: true,
            showFilter: true,
            multiValueFilter: true,
            style: { maxWidth: "350px", overflow: "hidden"},
            Cell: row => <WrapInTooltip tooltip={row.value}><span>{row.value}</span></WrapInTooltip>
        },
        {
            id: "description",
            Header: STRINGS.dashboards.columns.description,
            accessor: "description",
            sortable: true,
            showFilter: true,
            style: { maxWidth: "200px", overflow: "hidden"},
            headerClassName: "text-nowrap d-none d-xl-table-cell",
            className: "d-none d-xl-table-cell",
            Cell: row => <WrapInTooltip tooltip={row.value}><span className="display-10 d-inline-block text-jusitfy">{row.value}</span></WrapInTooltip>
        },
        {
            id: "isFactory",
            Header: STRINGS.dashboards.columns.factory,
            accessor: "isFactory",
            sortable: true,
            sortFunction: (a, b) => {
                const factoryA = a.isFactory;
                const factoryB = b.isFactory;
                return (factoryA && !factoryB ? 1 : (factoryB && !factoryA ? -1 : 0));
            },
            showFilter: true,
            filterControl: CheckboxColumnFilterControl,
            Cell: (tableInstance, cellModel) => {
                return tableInstance.value ? <Icon size={16} icon={IconNames.TICK} /> : <span />;
            },
        },
        {
            id: "createdByUser",
            Header: STRINGS.dashboards.columns.createdBy,
            accessor: "createdByUser",
            sortable: true,
            showFilter: true,
            filterControl: AutocompleteColumnFilterControl,
            headerClassName: "text-nowrap d-none d-xl-table-cell",
            className: "display-9 d-none d-xl-table-cell",
        },
        {
            id: "createdTime",
            Header: STRINGS.dashboards.columns.createdTime,
            accessor: "createdTime",
            sortable: true,
            showFilter: true,
            filterControl: DateRangeFilterControl,
            filterFunction: dateRangeFilterFunction,
            multiValueFilter: true,
            formatter: row => (row.createdTime? <ElapsedTimeFormatter time={row.createdTime} showOriginal suffix={STRINGS.incidents.elapsedSuffix}/> : ""),
            sortFunction: sortColumnWithTimeData,
            sortDescFirst: true,
            headerClassName: "text-nowrap d-none d-xl-table-cell",
            className: "display-9 d-none d-xl-table-cell",
        },
        {
            id: "lastUpdatedBy",
            Header: STRINGS.dashboards.columns.lastUpdateBy,
            accessor: "lastUpdatedBy",
            sortable: true,
            showFilter: true,
            filterControl: AutocompleteColumnFilterControl,
            headerClassName: "text-nowrap d-none d-xl-table-cell",
            className: "display-9 d-none d-xl-table-cell",
        },
        {
            id: "lastUpdatedTime",
            Header: STRINGS.dashboards.columns.lastUpdateTime,
            accessor: "lastUpdatedTime",
            sortable: true,
            showFilter: true,
            filterControl: DateRangeFilterControl,
            filterFunction: dateRangeFilterFunction,
            sortFunction: sortColumnWithTimeData,
            multiValueFilter: true,
            sortDescFirst: true,
            formatter: row => (row.lastUpdatedTime? <ElapsedTimeFormatter time={row.lastUpdatedTime} showOriginal suffix={STRINGS.incidents.elapsedSuffix}/> : ""),
            headerClassName: "text-nowrap d-none d-lg-table-cell",
            className: "display-9 d-none d-lg-table-cell",
        },
        {
            id: "active",
            Header: STRINGS.dashboards.columns.enabled,
            accessor: "active",
            sortable: true,
            sortFunction: (a, b) => {
                const enabledA = a.active;
                const enabledB = b.active;
                return (enabledA && !enabledB ? 1 : (enabledB && !enabledA ? -1 : 0));
            },
            formatter: row => {
                return <div onClick={(e) => {e.stopPropagation();}}>
                    <Switch checked={row.active} disabled={!canEditDashboards() || (row.errors && !row.active)}
                        className="mb-0"
                        innerLabel={row.active ? STRINGS.runbooks.onText : STRINGS.runbooks.offText} 
                        onChange={() => setDashboardEnabled(row.id, !row.active, setDialogState, row.operationCount, setOperationCount)}
                    />
                </div>;
            }
        },
        {
            id: "errors",
            Header: "",
            accessor: "errors",
            formatter: row => (row.errors ? <Icon aria-label="dashboard-error-button" icon={IconNames.ERROR} intent={Intent.DANGER} /> : ""),
        }
    ];
    if (!showMoreMenu) {
        columns.push({ id: "edit", Header: "", accessor: "edit", style: {minWidth: "16px"}, className: "dashboard-action-cell" });
        columns.push({ id: "duplicate", Header: "", accessor: "duplicate", style: {minWidth: "16px"}, className: "dashboard-action-cell" });
        columns.push({ id: "delete", Header: "", accessor: "delete", style: {minWidth: "16px"}, className: "dashboard-action-cell" });
    } else {
        columns.push({ id: "more", Header: "", accessor: "more", style: {minWidth: "24px"} });
    }

    const [dialogState, setDialogState] = useState<any>({showDialog: false, title: "My Dialog", loading: false, dialogContent: null, dialogFooter: null});

    const handleDashboardSelectionChange = rows => {
        setSelectedId(rows?.length === 1 ? rows[0].id : null);
    };

    const dashboardsForEachTab: { [index: string]: DashboardConfig[] } = {
        all: [],
        mine: [],
        active: [],
        inerror: [],
        isFactory: [],
    };
    if (dashboardList) {
        for (const dashboard of dashboardList) {
            dashboardsForEachTab.all.push(dashboard);
            if (dashboard?.createdByUser?.toLowerCase() === userName.toLowerCase()) {
                dashboardsForEachTab.mine.push(dashboard);
            }
            if (dashboard.active) {
                dashboardsForEachTab.active.push(dashboard);
            }
            //if (dashboard.errors) {
            //    dashboardsForEachTab.inerror.push(dashboard);
            //}
            if (dashboard.isFactory) {
                dashboardsForEachTab.isFactory.push(dashboard);
            }
        }
    }

    function getTab ({
        title,
        dashboards,
        tabKey = "all",
        showAlways = false
    }) {
        const sortByFromURL = getQueryParam("sortby");
        const [sortId, direction] = (sortByFromURL || "lastUpdatedTime:desc").split(":");
        const showTab = (showAlways || dashboards.length > 0);
        if (!showTab) {
            setQueryParam('tab', 'all');
        }
        return showTab && <Tab
            id={tabKey}
            title={title}
            count={dashboards.length}
        >
            <Table
                id="dashboardList"
                key={"dashboardList-" + tabKey}
                className="dashboard-list-table display-9"
                columns={columns}
                columnDefinitionDefaults={{
                    headerClassName: "text-nowrap"
                }}
                data={dashboards || []}
                defaultPageSize={20}
                //onRowClick={(e) => {setSelectedId(!e.ctrlKey ? e.id : null)}}
                enableSelection={!showMoreMenu}
                selectOnRowClick={false}
                onSelectionChange={handleDashboardSelectionChange}
                sortBy={[{ id: sortId, desc: direction === "desc" }]}
                onSortByChange={([newSortBy]) => setQueryParam("sortby", newSortBy.id + ":" + (newSortBy.desc ? "desc" : "asc"))}
                expandOnlyOneRow
            />
        </Tab>;
    }

    return (<>
        <BasicDialog dialogState={dialogState} onClose={() => setDialogState(updateDialogState(dialogState, false, false, []))} />
        <DataLoadFacade loading={loading} data={dashboardList} error={error.current} showContentsWhenLoading={true}>
            <>
                <div className="dashboardlist-toolbar">
                    <Button aria-label="dashboard-new-button" icon={IconNames.ADD} minimal
                        className="me-2" onClick={(e) => {createNewDashboard(navigate, setDialogState)}}
                        text={STRINGS.dashboards.newTitle}
                    />
                    <Button aria-label="dashboard-import-button" icon={IconNames.IMPORT} minimal
                        className="me-2" onClick={(e) => {importContent(setDialogState, operationCount, setOperationCount)}}  
                        text={STRINGS.dashboards.importTitle} disabled={!canEditDashboards()}
                    />
                    {!showMoreMenu && <Button aria-label="dashboard-export-button" icon={IconNames.EXPORT} minimal disabled={selectedId === null}
                        className="me-2" onClick={(e) => {
                            if (selectedId) {
                                exportContent(selectedId, setDialogState);
                            }
                        }}  
                    />}
                </div>
                <TabbedSubPages className="dashboardlist-tabs" userPrefsStateKey="rb_tab">
                    { getTab({ title: STRINGS.dashboards.tabNames.all, tabKey: "all", dashboards: dashboardsForEachTab.all, showAlways: true }) }
                    { getTab({ title: STRINGS.dashboards.tabNames.active, tabKey: "active", dashboards: dashboardsForEachTab.active, showAlways: true }) }
                    { dashboardsForEachTab.mine.length < dashboardsForEachTab.all.length && getTab({ title: STRINGS.dashboards.tabNames.mine, tabKey: "mine", dashboards: dashboardsForEachTab.mine }) }
                    { dashboardsForEachTab.inerror.length < dashboardsForEachTab.all.length && getTab({ title: STRINGS.dashboards.tabNames.inerror, tabKey: "inerror", dashboards: dashboardsForEachTab.inerror }) }
                    { dashboardsForEachTab.isFactory.length < dashboardsForEachTab.all.length && getTab({ title: STRINGS.dashboards.tabNames.factory, tabKey: "factory", dashboards: dashboardsForEachTab.isFactory }) }
                </TabbedSubPages>
            </>
        </DataLoadFacade>
    </>);
}

/** creates a new dashboard and opens the dashboard editor with the new dashboard selected.
 *  @param history the history object that we can append the URL to open the create dashboard page. 
 *  @param setDialogState the function from useState that is used to set the dialog state.
 *  @returns a Promise with empty result. */
async function createNewDashboard(navigate: any, setDialogState: (dialogState: DialogState) => void): Promise<void> {
    /* istanbul ignore else */
    if (!saveOnCreateNewDashboard) {
        // Go to the dashboard editor and just open a brand new dashboard
        navigate(getURL(getURLPath("view-dashboard"), {[PARAM_NAME.rbConfigId]: 0, [PARAM_NAME.rbConfigNm]: "New"}));
    } else {
        const newDialogState: any = {showDialog: true, loading: true, title: STRINGS.dashboards.createDialog.title};
        newDialogState.dialogContent = <div className="mb-3"><span>{STRINGS.dashboards.createDialog.bodyText}</span></div>;
        newDialogState.dialogFooter = <Button active={true} outlined={true} onClick={async (evt) => {
            setDialogState(updateDialogState(newDialogState, false, false, []));
        }} text={STRINGS.dashboards.okBtnText} />;
        setDialogState(newDialogState);
        
        try {
            // Get the current list of dashboard names
            const usedNames: Array<string> = [];
            const dashboards: Array<DashboardConfig> = []; //await runbookService.getRunbooks();
            for (const savedDashboard of dashboards) {
                if (savedDashboard.name) {
                    usedNames.push(savedDashboard.name as string);
                }
            }
    
            // Make sure the name is unique
            const nameRoot = STRINGS.dashboards.newDashboardName;
            let nameIndex = 1;
            let name = nameRoot;
            while (usedNames.includes(name)) {
                name = nameRoot + " " + nameIndex++;
            }
    
            //const newDashbaord: DashboardConfig = {name: name, description: STRINGS.dashboards.newDashboardDesc} as DashboardConfig;

            // We want all new dashboards to be inactive
            //newDashboard.active = false;
    
            // Save the new dashboard
            const savedDashboard: DashboardConfig = {} as DashboardConfig; //await runbookService.saveRunbook(newRunbook);
    
            setDialogState(updateDialogState(newDialogState, false, false, []));
            navigate(getURL(getURLPath("View-dashboard"), {[PARAM_NAME.rbConfigId]: savedDashboard.id, [PARAM_NAME.rbConfigNm]: savedDashboard.name}));
        } catch (error) {
            setDialogState(updateDialogState(newDialogState, true, false, [STRINGS.dashboards.createDialog.errorText]));
        }    
    }
}

/** creates a duplicate of the dashboard with the specified id and then reloads the list of dashboards.
 *  @param id a String with the id of the dashboard.
 *  @param setDialogState the function from useState that is used to set the dialog state.
 *  @param operationCount an integer with the current operation count.  This number needs to be incremented to 
 *      re-render the page.
 *  @param setOperationCount the settor function for the operation count.  Invoke this function with the new
 *      operation count to re-render the component.*/
async function duplicateDashboard(id: string, setDialogState: (dialogState: DialogState) => void, operationCount: number, setOperationCount: (count: number) => void): Promise<void> {
    const newDialogState: any = {showDialog: true, loading: true, title: STRINGS.dashboards.duplicateDialog.title};
    newDialogState.dialogContent = <div className="mb-3"><span>{STRINGS.dashboards.duplicateDialog.bodyText}</span></div>;
    newDialogState.dialogFooter = <Button active={true} outlined={true} onClick={async (evt) => {
        setDialogState(updateDialogState(newDialogState, false, false, []));
    }} text={STRINGS.dashboards.okBtnText} />;
    setDialogState(newDialogState);

    try {
        const dashboard = {}; //await runbookService.getRunbook(id);

        const newDashboard = JSON.parse(JSON.stringify(dashboard));
        let name  = "Copy of " + newDashboard.name;
        delete newDashboard.id;

        // Get the current list of dashboard names
        const usedNames: Array<string> = [];
        const dashboards: DashboardConfig[] = []; //await runbookService.getRunbooks();
        for (const savedDashboard of dashboards) {
            if (savedDashboard.name) {
                usedNames.push(savedDashboard.name as string);
            }
        }

        // Make sure the name is unique
        const nameRoot = name;
        let nameIndex = 1;
        while (usedNames.includes(name)) {
            name = nameRoot + " " + nameIndex++;
        }
        newDashboard.name = name;

/*        
        // Update all node ids        
        const newIdByOldId: Record<string, string> = {};
        for (const node of newDashboard.nodes) {
            const oldId = node.id;
            node.id = getUuidV4();
            newIdByOldId[oldId] = node.id;
        }

        // Update all wires
        for (const node of newDashboard.nodes) {
            if (node.wires) {
                for (const wire of node.wires) {
                    if (Array.isArray(wire)) {
                        for (let index = 0; index < wire.length; index++) {
                            wire[index] = newIdByOldId[wire[index]];
                        }
                    }
                }
            }
        }
*/

        // We want all duplicated dashboards to be inactive
        //newDashboard.active = false;

        // Save the new dashboard
        const dupeDashboard = {}; //await runbookService.saveRunbook(newRunbook);
        setOperationCount(operationCount + 1);
        setDialogState(updateDialogState(newDialogState, false, false, []));
        console.log(JSON.stringify(dupeDashboard));
    } catch (error) {
        setDialogState(updateDialogState(newDialogState, true, false, [STRINGS.dashboards.duplicateDialog.errorText]));
    }
}

/** handler for the delete button pressed event.
 *  @param deleteId a String with the id of the dashboard that is to be deleted.
 *  @param enabled a boolean value, true if the dashboard is currently enabled.
 *  @param setDialogState the function from useState that is used to set the dialog state.
 *  @param operationCount a unique id which if modified causes the component to re-render.
 *  @param setOperationCount the function from useState that is used to set the operation count.*/
async function handleDelete(deleteId: string, enabled: boolean, setDialogState: Function, operationCount: number, setOperationCount: Function): Promise<void> {
    const newDialogState: any = {showDialog: true, title: STRINGS.dashboards.deleteDialog.title};
    newDialogState.dialogContent = <div className="mb-3"><span>{STRINGS.dashboards.deleteDialog.bodyText}</span></div>;
    newDialogState.dialogFooter = (<>
        <Button active={true} outlined={true} onClick={async (evt) => {
            let errors: Array<string> = [];
            if (deleteId !== "") {
                setDialogState(updateDialogState(newDialogState, true, true, []));
                try {
                    //await runbookService.deleteRunbook(deleteId);
                    setOperationCount(operationCount + 1);
                } catch (error) {
                    errors = [STRINGS.dashboards.deleteDialog.generalDeleteError];
                }
            }
            setDialogState(updateDialogState(newDialogState, errors?.length > 0, false, errors || []));
        }} text={STRINGS.dashboards.deleteDialog.btnText} />
    </>);
    setDialogState(newDialogState);
}

/** sets the dashboard enabled flag on the dashboard and saves it to the back-end.
 *  @param id a String with the id of the dashboard.
 *  @param enabled the new value of the enabled flag.
 *  @param setDialogState the function from useState that is used to set the dialog state.
 *  @param operationCount an integer with the current operation count.  This number needs to be incremented to 
 *      re-render the page.
 *  @param setOperationCount the settor function for the operation count.  Invoke this function with the new
 *      operation count to re-render the component.*/
async function setDashboardEnabled(
    id: string, enabled: boolean, setDialogState: (dialogState: DialogState) => void, operationCount: number, setOperationCount: (count: number) => void
): Promise<void> {
    const isDashboardMapped = true;//await getRunbookMappingStatus(id);
    const newDialogState: any = {showDialog: true, loading: enabled, title: STRINGS.dashboards.activateDialog.title};
    newDialogState.dialogContent = <div className="mb-3">
        <span>{enabled ? STRINGS.dashboards.activateDialog.bodyText : STRINGS.dashboards.activateDialog.disabledBodyText}</span>
    </div>;
    newDialogState.dialogFooter = <>
        <Button active={true} outlined={true} onClick={async (evt) => {
            if (!enabled) {
                try {
                    setDialogState(updateDialogState(newDialogState, true, true, []));
                    await setEnabledFlag(id, enabled);
                    setOperationCount(operationCount + 1);
                    setDialogState(updateDialogState(newDialogState, false, false, []));
                } catch (error) {
                    const errorMsg = (error as any)?.response?.status === 412 ? 
                        STRINGS.dashboards.activateDialog.etagErrorText : STRINGS.dashboards.activateDialog.generalErrorText;
                    setDialogState(updateDialogState(newDialogState, true, false, [errorMsg]));
                }
            } else {
                // If the user is trying to enable a runbook you could get here if an error occurs trying to 
                // get the active count.  Allow them to try it again
                setDashboardEnabled(id, enabled, setDialogState, operationCount, setOperationCount);
            }
        }} text={STRINGS.runbooks.okBtnText} />
        {!enabled && <Button active={true} outlined={true} onClick={async (evt) => {
            setDialogState(updateDialogState(newDialogState, false, false, []));
        }} text={STRINGS.runbooks.cancelBtnText} />}
    </>;
    setDialogState(newDialogState);

    try {
        const afterDialogState = updateDialogState(newDialogState, true, false, [])
        afterDialogState.dialogContent = <div className="mb-3">
            <span>{enabled ?
                STRINGS.dashboards.activateDialog.enabledBodyText :
                ( !isDashboardMapped ?
                    STRINGS.dashboards.activateDialog.disabledBodyText :
                     STRINGS.dashboards.activateDialog.mappedDisablingBodyText )}</span>
        </div>;
        afterDialogState.dialogFooter = <>
            {((!isDashboardMapped) || (isDashboardMapped && enabled)) &&
                <Button active={true} outlined={true} onClick={async (evt) => {
                    try {
                        setDialogState(updateDialogState(newDialogState, true, true, []));
                        await setEnabledFlag(id, enabled);
                        setOperationCount(operationCount + 1);
                        setDialogState(updateDialogState(newDialogState, false, false, []));
                    } catch (error) {
                        const errorMsg = (error as any)?.response?.status === 412 ? 
                            STRINGS.dashboards.activateDialog.etagErrorText : STRINGS.dashboards.activateDialog.generalErrorText;
                        setDialogState(updateDialogState(newDialogState, true, false, [errorMsg]));
                    }
                }} text={STRINGS.runbooks.okBtnText} />
            }
            <Button active={true} outlined={true} onClick={async (evt) => {
                setDialogState(updateDialogState(newDialogState, false, false, []));
            }} text={STRINGS.runbooks.cancelBtnText} />
        </>;
        setDialogState(afterDialogState);
    } catch (error) {
        const errorMsg = (error as any)?.response?.status === 412 ? 
            STRINGS.dashboards.activateDialog.etagErrorText : STRINGS.dashboards.activateDialog.generalErrorText;
        setDialogState(updateDialogState(newDialogState, true, false, [errorMsg]));
    }
}

/** sets the enabled flag.
 *  @param id a String with the id of the dashboard.
 *  @param enabled the new value of the enabled flag.
 *  @returns a Promise that resolves to the id of the updated dashboard. */
async function setEnabledFlag(id: string, enabled: boolean): Promise<string> {
    const updatedDashboard: DashboardConfig | undefined = {} as DashboardConfig; //await runbookService.getRunbook(id);

    // Save the new runbook
    if (updatedDashboard) {
        const currentEnabled = updatedDashboard.active;
        if (currentEnabled !== enabled) {
            //const runbookToSave = {id: updatedRunbook.id};
            //runbookToSave.isReady = enabled;
            updatedDashboard.active = enabled;
            const dashboardId = "dashboard1"; //await runbookService.updateRunbook(id, updatedRunbook, updatedRunbook.eTag || "unknown-etag");
            return Promise.resolve(dashboardId);
        } else {
            // The active state already has been set, possibly by another user.
            return Promise.resolve(updatedDashboard.id || "");
        }
    } else {
        return Promise.reject("Error saving dashboard");
    }
}

/** Creates a popup that imports the dashboard.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function.
 *  @param operationCount an integer with the current operation count.  This number needs to be incremented to 
 *      re-render the page.
 *  @param setOperationCount the settor function for the operation count.  Invoke this function with the new
 *      operation count to re-render the component. */
function importContent(
    setDialogState: (dialogState: DialogState) => void, operationCount: number, setOperationCount: (count: number) => void
): void {
    const newDialogState: any = {showDialog: true, title: STRINGS.dashboards.importDialog.title};
    newDialogState.dialogContent = <>
        <div className="mb-3"><span>{STRINGS.dashboards.importDialog.text}</span></div>
        <input id="dashboard-file" type="file" />
    </>;
    newDialogState.dialogFooter = <Button active={true} outlined={true} onClick={async (evt) => {
        const fileList = (document.getElementById("dashboard-file") as any).files;
        if (fileList && fileList.length === 1) {
            const fileReader = new FileReader();
            fileReader.onload = async function () {
                const text: string = fileReader.result as string;
                if (text && text.length > 0) {
                    let errors: Array<string> = [];
                    try {
                        setDialogState(updateDialogState(newDialogState, true, true, []));
                        const dashboardExportFormat = JSON.parse(text);
                        await saveImportedDashboard(dashboardExportFormat);    
                        setOperationCount(operationCount + 1);
                    } catch (error) {
                        const errorMsg = (error as any)?.response?.status === 422 && (error as any)?.response?.data?.details[0]?.code
                            ? STRINGS.runbookEditor.errors.codes[(error as any)?.response?.data?.details[0]?.code]
                            : STRINGS.formatString(STRINGS.runbooks.importDialog.errorText, {variant: "dashboard"}) + '<br/>' + error;
                        errors.push(errorMsg);
                    }
                    setDialogState(updateDialogState(newDialogState, errors?.length > 0, false, errors || []));
                }
            }
            fileReader.readAsText(fileList[0]);
        }
    }} text={STRINGS.dashboards.importDialog.btnText} />;
    setDialogState(newDialogState);
}

/** saves a new dashboard from the contents of the import file.
 *  @param dashboardExportFormat the JSON object with the contents of the import file.
 *  @returns a Promise which resolves to the dashboard that was saved. */
async function saveImportedDashboard(dashboardExportFormat: DashboardConfig): Promise<DashboardConfig> {
    return Promise.resolve({} as DashboardConfig);
/*
    try {
        let name: string = "";
        let triggerType: InputType | undefined;
        let description: string = "";
        let disabled: boolean | null = null;
        
        // Get the current list of dashboard names
        const usedNames: Array<string> = [];
        const dashboards = // await runbookService.getRunbooks();
        for (const savedDashboard of dashboards) {
            if (savedDashboard.name) {
                usedNames.push(savedDashboard.name as string);
            }
        }
    
        // Make sure the name is unique
        const nameRoot = name;
        let nameIndex = 1;
        while (usedNames.includes(name)) {
            name = nameRoot + " " + nameIndex++;
        }
    
        const newDashboard: DashboardConfig = {name: name, description: description, triggerType: triggerType, runtimeVariables, nodes};
        if (disabled !== null) {
            newDashboard.isReady = disabled;
        }
        // Shouldn't need to do this.
        delete newDashboard.id;
    
        // Update all node ids
        const newIdByOldId: Record<string, string> = {};
        for (const node of newDashboard.nodes!) {
            const oldId = node.id;
            node.id = getUuidV4();
            newIdByOldId[oldId] = node.id;
        }
    
        // Update all wires
        for (const node of newDashboard.nodes!) {
            if (node.wires) {
                for (const wire of node.wires) {
                    if (Array.isArray(wire)) {
                        for (let index = 0; index < wire.length; index++) {
                            wire[index] = newIdByOldId[wire[index]];
                        }
                    }
                }
            }
        }

        // Update filters on DO object
        updateDoFilters(newDashboard, newIdByOldId);

        // We want all imported dashboards to be inactive
        newDashboard.isReady = false;

        // Save the new dashboard
        const dupeDashboard = await runbookService.saveRunbook(newDashboard);
        return Promise.resolve(dupeDashboard as DashboardConfig);
    } catch (error) {
        return Promise.reject(error);
    }
*/
} 

/** exports the currently selected dashboard to the clipboard and displays it in a text area
 *      in a dialog.
 *  @param id a String with the id of the dashboard to export.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function. */
async function exportContent(id: string, setDialogState: (dialogState: DialogState) => void): Promise<void> {
    const newDialogState: any = {showDialog: true, loading: true, title: STRINGS.dashboards.exportDialog.title};
    newDialogState.dialogContent = <div className="mb-3"><span>{STRINGS.dashboards.exportDialog.preText}</span></div>;
    newDialogState.dialogFooter = undefined;
    setDialogState(newDialogState);
    
    try {
        let dashboard: DashboardConfig | undefined = getFakeDashboard(id); //await runbookService.getRunbook(id);
        const newDialogState: any = {showDialog: true, title: STRINGS.dashboards.exportDialog.title};
        newDialogState.dialogContent = <>
            <div className="mb-3"><span>{STRINGS.dashboards.exportDialog.text}</span></div>
            <textarea defaultValue={JSON.stringify(dashboard, null, 4)} style={{width: "470px", height: "350px"}} disabled={true} />
        </>;
        newDialogState.dialogFooter = <CopyToClipboard text={JSON.stringify(dashboard)}>
            <Button active={true} outlined={true} 
                text={STRINGS.dashboards.exportDialog.btnText} onClick={() => {
                    setDialogState(updateDialogState(newDialogState, false, false, []));
                }}
            />
        </CopyToClipboard>;
        setDialogState(newDialogState);
    } catch (error) {
        const afterDialogState: any = updateDialogState(newDialogState, true, false, [STRINGS.dashboards.exportDialog.errorText]);
        setDialogState(afterDialogState);
    }
}

/** returns whether or not the specified dashboard can be edited. 
 *  @param dashboard the dashboard to check to see if it can be edited.
 *  @returns a boolean value, true if the dashboard can be edited, false otherwise. */
export function canEditDashboard(dashboard: DashboardConfig): boolean {
    return !dashboard?.isFactory && canEditDashboards();
}

/** returns whether or not the user has the privileges to edit dashboards. 
 *  @returns a boolean value, true if the user has privileges to edit dashboards, false otherwise. */
export function canEditDashboards(): boolean {
    return AuthService.userHasWriteAccess("gelato");
}

/** returns the meta data for the specified dashboard.
 *  @param dashboard the DashboardConfig object.
 *  @returns the meta data for the dashboard. */
export function getDashboardMetaData(dashboard: DashboardConfig): 
{name: string, description: string, createdTime?: Date, lastUpdatedTime?: Date, createdByUser: string, lastUpdatedUser: string} {
    const name: string = dashboard.name || ''
    let description: string = dashboard.description;
    const createdTime = parseTimeFromDAL(dashboard.createdTime);
    const lastUpdatedTime = parseTimeFromDAL(dashboard.lastUpdatedTime);
    const createdByUser = dashboard.createdByUser || "";
    const lastUpdatedUser = dashboard.lastUpdatedUser || "";
    return ({name, description, createdTime, lastUpdatedTime, createdByUser, lastUpdatedUser});
}

/** returns a fake dashboard based on the id.
 *  @param id a String with the id.
 *  @returns the DashboardConfig with the dashboard for the id. */
export function getFakeDashboard(id: string): DashboardConfig | undefined {
    let dashboard: DashboardConfig | undefined = undefined;
    switch (id) {
        case "dashboard1":
            dashboard = Dashboard1 as any;
            break;
        case "dashboard2":
            dashboard = Dashboard2 as any;
            break;
        case "dashboard3":
            dashboard = Dashboard3 as any;
            break;
        case "dashboard4":
            dashboard = Dashboard4 as any;
            break;
        case "dashboard5":
            dashboard = Dashboard5 as any;
            break;
        case "dashboard6":
            dashboard = Dashboard6 as any;
            break;
        case "dashboard7":
            dashboard = Dashboard7 as any;
            break;
        case "dashboard8":
            dashboard = Dashboard8 as any;
            break;
        case "dashboard9":
            dashboard = Dashboard9 as any;
            break;
        case "dashboard10":
            dashboard = Dashboard10 as any;
            break;
        case "dashboard11":
            dashboard = Dashboard11 as any;
            break;
        case "dashboard12":
            dashboard = Dashboard12 as any;
            break;
    }
    return dashboard;
}
