/** This module contains tools for formatting the runbook output keys.
 *  @module
 */

import { Column, COLUMN_TYPE } from "pages/riverbed-advisor/views/runbook-view/Runbook.type.ts";
import { PopoverPosition, Classes, Tooltip } from "@blueprintjs/core";
import { STRINGS } from "app-strings";
import { Unit } from "reporting-infrastructure/types/Unit.class.ts";
import DOMPurify from 'dompurify';

/** this interface defines a data structure that stores all the keys in a nested hierarchy of keys and their values. */
interface KeyObject {
    /** any property name that will have either the KeyObjectValue or another KeyObject as a value. */
    [key: string]: KeyObjectValue | KeyObject;
}

/** this inteface defines the object that contains the value for a key in a KeyObject. */
interface KeyObjectValue {
    /** a String with the type of value, string, integer, float, ipaddr. */
    type: COLUMN_TYPE | undefined;
    /** a String with the value for the key. */
    value: string;
    /** a boolean value, whose only use is to allow us to check quickly if this ia a KeyObjectValue or KeyObject. */
    isValue: boolean;
}

/** formats the label from a set of data ocean keys.
 *  @param keyDefs the definition of the keys.
 *  @param dataKeys the values for the keys in the data.
 *  @param showTooltip a boolean value, true if the tooltip should be displayed, false otherwise.
 *  @returns a string with the formatted label for all the keys. */
export function formatKeys(keyDefs: Column[], dataKeys: Record<string, string>, showTooltip: boolean = false): JSX.Element | string {
    let name: string = "";
    if (keyDefs && dataKeys) {
        // Convert the keys and data to an object
        const outputObjs = convertKeysAndDataToOject(keyDefs, dataKeys);
        if (keyDefs?.length === 1) {
            // We have only one key, there is no reason to go through the formatter, just show the value of the 
            // key.  This fixes a problem whereby the aggregator node groups by one key and does not have the 
            // rest of the object.  If we try to format the object we will keep getting Unknown XXX if the primary
            // keys are missing.
            return dataKeys[keyDefs[0].id];
        }
        return formatKeyObject(outputObjs, showTooltip) || "";
    }
    return name;
}

/** formats the label from the object that was created from the flat list of key values.
 *  @param outputObjs a nested set of keys and values with the key type and its value or the subkeys.
 *  @param showTooltip a boolean value, true if the tooltip should be displayed, false otherwise.
 *  @returns a String with the formatted label for all the keys or a JSX.Element with the label and a tooltip. */
function formatKeyObject(outputObjs: KeyObject, showTooltip: boolean): JSX.Element | string | undefined {
    // Take the keys and create a unique string to represent each set of data to be formatted
    const keyIds: Array<string> = Object.keys(outputObjs);

    // Extract the data source, if any, and handle it separately.  Right now discard it 
    // because it can be added as a separate column if needed.  In the future, maybe we
    // want to append it to the end of the string
    if (keyIds.includes("data_source")) {
        keyIds.splice(keyIds.indexOf("data_source"), 1);
    }

    keyIds.sort();
    const formatterId = keyIds.join("-");
    
    // See if there are any formatters for the specified unique strings
    switch(formatterId) {
        case "network_device": 
            return formatIp(outputObjs.network_device, "Device", outputObjs.data_source, showTooltip);
        case "network_host": 
            return formatIp(outputObjs.network_host, "Host", outputObjs.data_source, showTooltip);
        case "network_client": 
            return formatIp(outputObjs.network_client, "Client", outputObjs.data_source, showTooltip);
        case "network_server": 
            return formatIp(outputObjs.network_server, "Server", outputObjs.data_source, showTooltip);
        case "network_client-network_server": 
            return formatHostPairs(outputObjs.network_client, outputObjs.network_server, outputObjs.data_source, showTooltip);
        case "network_client_server": 
            return formatHostPairs(
                (outputObjs.network_client_server as KeyObject).network_client, 
                (outputObjs.network_client_server as KeyObject).network_server, 
                outputObjs.data_source,
                showTooltip
            );
        case "network_interface":
        case "network_device-network_interface":
            return formatIfc(outputObjs.network_interface, outputObjs.network_device, outputObjs.data_source, showTooltip);
        case "cpu_util_type-network_device":
            return formatGenericNetImKeys(outputObjs.cpu_util_type);
        case "disk_path-network_device":
            return formatGenericNetImKeys(outputObjs.disk_path);
        case "memory_type-network_device":
            return formatGenericNetImKeys(outputObjs.memory_type);
        case "application":
            return formatApplication(outputObjs.application, outputObjs.data_source, showTooltip);
        case "application_location":
            return formatApplicationLocation(outputObjs.application_location, outputObjs.data_source, showTooltip);
        case "location":
            return formatLocation(outputObjs.location, "Host", outputObjs.data_source, showTooltip);
        case "client_location":
            return formatLocation(outputObjs.client_location, "Client", outputObjs.data_source, showTooltip);
        case "server_location":
            return formatLocation(outputObjs.server_location, "Server", outputObjs.data_source, showTooltip);
        case "protocol":
            return formatProtocol(outputObjs.protocol, outputObjs.data_source, showTooltip);
        case "dscp":
            return formatDscp(outputObjs.dscp, outputObjs.data_source, showTooltip);
        case "client_location-server_location": 
            return formatLocationPairs(outputObjs.client_location, outputObjs.server_location, outputObjs.data_source, showTooltip);
        case "client_server_location": 
            return formatLocationPairs(
                (outputObjs.client_server_location as KeyObject).client_location, 
                (outputObjs.client_server_location as KeyObject).server_location, 
                outputObjs.data_source,
                showTooltip
            );
        case "network_client-network_server-protoport": 
            return formatHostPairsProtoPort(
                outputObjs.network_client, outputObjs.network_server, outputObjs.protoport, outputObjs.data_source, 
                showTooltip
            );
        case "network_client_server_protoport": 
            return formatHostPairsProtoPort(
                (outputObjs.network_client_server_protoport as KeyObject).network_client, 
                (outputObjs.network_client_server_protoport as KeyObject).network_server, 
                (outputObjs.network_client_server_protoport as KeyObject).protoport, 
                outputObjs.data_source,
                showTooltip
            );
        case "user_device":
            return formatUserDevice(outputObjs.user_device, outputObjs.data_source, showTooltip);
        default:
            // Default formatting, hyphen all the keys
            let name: string = "";
            const keys = Object.keys(outputObjs);
            if (keys.length > 1) {
                // If we have more than one key try to format each key individually
                for (const key in outputObjs) {
                    let keyValue = outputObjs[key];
    
                    let textToAdd = "";
                    if (keyValue.isValue) {
                        if (keyValue.value === undefined || keyValue.value === null || keyValue.value === "") {
                            textToAdd = STRINGS.formatter.unknownKey;
                        } else {
                            textToAdd = (keyValue as KeyObjectValue).value;
                        }
                    } else {
                        textToAdd = formatKeyObject({[key]: keyValue}, false) as string;
                    }
                    name += (name.length > 0 ? " - " : "") + textToAdd;
                } 
            } else {
                // We only have one key and it has already been checked
                return formatUnknownKeyObject(outputObjs);
            }
            return name;
    }
}

/** this function formats the output for an unknown key.
 *  @param outputObjs the object or string with the data.
 *  @return a String with the formatted output. */
function formatUnknownKeyObject(outputObjs: KeyObject): string {
    let name = "";
    for (const key in outputObjs) {
        let keyValue = outputObjs[key];

        let textToAdd = "";
        if (keyValue.isValue) {
            if (keyValue.value === undefined || keyValue.value === null || keyValue.value === "") {
                textToAdd = STRINGS.formatter.unknownKey;
            } else {
                textToAdd = (keyValue as KeyObjectValue).value;
            }
        } else {
            textToAdd = formatUnknownKeyObject(keyValue as KeyObject);
        }
        name += (name.length > 0 ? " - " : "") + textToAdd;
    } 
    return name;
}

/** format a protocol.
 *  @param data the object or string with the data.
 *  @param dataSource the object or string with the data source information.
 *  @param showTooltip a boolean value, true if the tooltip should be displayed, false otherwise.
 *  @return a String with the formatted protocol. */
export function formatProtocol(data: any, dataSource: any, showTooltip: boolean = false): JSX.Element | string {
    if (typeof data === "string") {
        // We have a string, just output it.
        return data;
    } else {
        let tooltipContent: Array<JSX.Element> = [];
        let name = "";

        // We have an object, find the right fields and output it
        if (data?.name?.value) {
            name = data?.name?.value;
        } else if (data?.number?.value !== undefined && data?.number?.value !== null) {
            name = data?.number?.value;
        } else {
            name = STRINGS.formatter.unknownProtocol;
        }

        if (showTooltip) {
            if (dataSource?.name?.value) {
                tooltipContent.push(<tr key="data-source"><td className="text-end pe-2">{STRINGS.tooltips.dataSource}</td><td>{dataSource?.name?.value}</td></tr>)
            }
            if (data?.number?.value) {
                tooltipContent.push(<tr key="protocol-number"><td className="text-end pe-2">{STRINGS.tooltips.protocolNumber}</td><td>{data?.number?.value}</td></tr>)
            }
            if (data?.name?.value) {
                tooltipContent.push(<tr key="protocol-name"><td className="text-end pe-2">{STRINGS.tooltips.protocolName}</td><td>{data?.name?.value}</td></tr>)
            }
        }

        return tooltipContent.length > 0 ? 
            <Tooltip className={Classes.TOOLTIP_INDICATOR + " border-0"} content={<div className="px-2 py-2"><table><tbody>{tooltipContent}</tbody></table></div>} position={PopoverPosition.RIGHT} transitionDuration={50}>
                {name}
            </Tooltip> : name;
    }
}

/** format a dscp.
 *  @param data the object or string with the data.
 *  @param dataSource the object or string with the data source information.
 *  @param showTooltip a boolean value, true if the tooltip should be displayed, false otherwise.
 *  @return a String with the formatted dscp. */
export function formatDscp(data: any, dataSource: any, showTooltip: boolean = false): JSX.Element | string {
    if (typeof data === "string") {
        // We have a string, just output it.
        return data;
    } else {
        let tooltipContent: Array<JSX.Element> = [];
        let name = "";

        // We have an object, find the right fields and output it
        if (data?.name?.value) {
            name = data?.name?.value;
        } else if (data?.number?.value !== undefined && data?.number?.value !== null) {
            name = data?.number?.value;
        } else {
            name = STRINGS.formatter.unknownDscp;
        }

        if (showTooltip) {
            if (dataSource?.name?.value) {
                tooltipContent.push(<tr key="data-source"><td className="text-end pe-2">{STRINGS.tooltips.dataSource}</td><td>{dataSource?.name?.value}</td></tr>)
            }
            if (data?.number?.value) {
                tooltipContent.push(<tr key="dscp-number"><td className="text-end pe-2">{STRINGS.tooltips.dscpNumber}</td><td>{data?.number?.value}</td></tr>)
            }
            if (data?.name?.value) {
                tooltipContent.push(<tr key="dscp-name"><td className="text-end pe-2">{STRINGS.tooltips.dscpName}</td><td>{data?.name?.value}</td></tr>)
            }
        }

        return tooltipContent.length > 0 ? 
            <Tooltip className={Classes.TOOLTIP_INDICATOR + " border-0"} content={<div className="px-2 py-2"><table><tbody>{tooltipContent}</tbody></table></div>} position={PopoverPosition.RIGHT} transitionDuration={50}>
                {name}
            </Tooltip> : name;
    }
}

/** format a user device.
 *  @param data the object or string with the data.
 *  @param dataSource the object or string with the data source information.
 *  @param showTooltip a boolean value, true if the tooltip should be displayed, false otherwise.
 *  @return a String with the formatted dscp. */
export function formatUserDevice(data: any, dataSource: any, showTooltip: boolean = false): JSX.Element | string {
    if (typeof data === "string") {
        // We have a string, just output it.
        return data;
    } else {
        let tooltipContent: Array<JSX.Element> = [];
        let name = "";

        // We have an object, find the right fields and output it
        if (data?.device_name?.value) {
            name = data?.device_name?.value;
        } else if (data?.username?.value !== undefined && data?.username?.value !== null) {
            name = data?.username?.value;
        } else {
            name = STRINGS.formatter.unknownUserDevice;
        }

        if (showTooltip) {
            if (dataSource?.device_name?.value) {
                tooltipContent.push(<tr key="device-name"><td className="text-end pe-2">{STRINGS.tooltips.deviceName}</td><td>{dataSource?.device_name?.value}</td></tr>)
            }
            if (data?.username?.value) {
                tooltipContent.push(<tr key="username"><td className="text-end pe-2">{STRINGS.tooltips.ipUsername}</td><td>{data?.username?.value}</td></tr>)
            }
            if (data?.location?.name?.value) {
                tooltipContent.push(<tr key="location-name"><td className="text-end pe-2">{STRINGS.tooltips.location}</td><td>{data?.location?.name?.value}</td></tr>)
            }

            // These are Aternity properties
            if (data?.device_isp?.value) {
                tooltipContent.push(<tr key="ip-user-device-isp"><td className="text-end pe-2">{STRINGS.tooltips.ipUserDeviceIsp}</td><td>{data?.device_isp?.value}</td></tr>)
            }
            if (data?.user_connection_location?.value) {
                tooltipContent.push(<tr key="ip-user-connection-location"><td className="text-end pe-2">{STRINGS.tooltips.ipUserConnectionLocation}</td><td>{data?.user_connection_location?.value}</td></tr>)
            }
            if (data?.wifi_bssid?.value) {
                tooltipContent.push(<tr key="ip-wifi-bssid"><td className="text-end pe-2">{STRINGS.tooltips.ipWifiBssid}</td><td>{data?.wifi_bssid?.value}</td></tr>)
            }
            if (data?.wifi_channel?.value) {
                tooltipContent.push(<tr key="ip-wifi-channel"><td className="text-end pe-2">{STRINGS.tooltips.ipWifiChannel}</td><td>{data?.wifi_channel?.value}</td></tr>)
            }
            if (data?.wifi_ssid?.value) {
                tooltipContent.push(<tr key="ip-wifi-ssid"><td className="text-end pe-2">{STRINGS.tooltips.ipWifiSsid}</td><td>{data?.wifi_ssid?.value}</td></tr>)
            }
            if (data?.physical_location?.name?.value) {
                tooltipContent.push(<tr key="ip-physical-location-name"><td className="text-end pe-2">{STRINGS.tooltips.ipPhysicalLocationName}</td><td>{data?.physical_location?.name?.value}</td></tr>)
            }
            if (data?.physical_location?.geo?.city?.value) {
                tooltipContent.push(<tr key="ip-physical-location-geo-city"><td className="text-end pe-2">{STRINGS.tooltips.ipPhysicalLocationGeoCity}</td><td>{data?.physical_location?.geo?.city?.value}</td></tr>)
            }
            if (data?.physical_location?.geo?.state?.value) {
                tooltipContent.push(<tr key="ip-physical-location-geo-state"><td className="text-end pe-2">{STRINGS.tooltips.ipPhysicalLocationGeoState}</td><td>{data?.physical_location?.geo?.state?.value}</td></tr>)
            }
            if (data?.physical_location?.geo?.country?.value) {
                tooltipContent.push(<tr key="ip-physical-location-geo-country"><td className="text-end pe-2">{STRINGS.tooltips.ipPhysicalLocationGeoCountry}</td><td>{data?.physical_location?.geo?.country?.value}</td></tr>)
            }            
        }

        return tooltipContent.length > 0 ? 
            <Tooltip className={Classes.TOOLTIP_INDICATOR + " border-0"} content={<div className="px-2 py-2"><table><tbody>{tooltipContent}</tbody></table></div>} position={PopoverPosition.RIGHT} transitionDuration={50}>
                {name}
            </Tooltip> : name;
    }
}

/** formats an Application.
 *  @param data the object or string with the data.
 *  @param dataSource the object or string with the data source information.
 *  @param showTooltip a boolean value, true if the tooltip should be displayed, false otherwise.
 *  @return a String with the formatted application. */
export function formatApplication(data: any, dataSource: any, showTooltip: boolean = false): JSX.Element | string {
    if (typeof data === "string") {
        // We have a string, just output it.
        return data;
    } else {
        let tooltipContent: Array<JSX.Element> = [];
        let name = "";

        // We have an object, find the right fields and output it
        if (data?.name?.value) {
            name = data?.name?.value;
            if (data?.version?.value) {
                name += " (v" + data.version.value + ")";
            }
        } else {
            name = STRINGS.formatter.unknownApplication;
        }

        if (showTooltip) {
            if (dataSource?.name?.value) {
                tooltipContent.push(<tr key="data-source"><td className="text-end pe-2">{STRINGS.tooltips.dataSource}</td><td>{dataSource?.name?.value}</td></tr>)
            }
            if (data?.name?.value) {
                tooltipContent.push(<tr key="app-name"><td className="text-end pe-2">{STRINGS.tooltips.appName}</td><td>{data?.name?.value}</td></tr>)
            }
            if (data?.type?.value) {
                tooltipContent.push(<tr key="app-type"><td className="text-end pe-2">{STRINGS.tooltips.appType}</td><td>{data?.type?.value}</td></tr>)
            }
            if (data?.version?.value) {
                tooltipContent.push(<tr key="app-version"><td className="text-end pe-2">{STRINGS.tooltips.appVersion}</td><td>{data?.version?.value}</td></tr>)
            }
            if (data?.tags?.value) {
                let items = [];
                try {
                    items = JSON.parse(data.tags.value);
                } catch (error) {}
                tooltipContent.push(<tr key="application-tags"><td className="text-end pe-2">{STRINGS.tooltips.tags}</td><td>{items.join(", ")}</td></tr>)
            }
        }

        return tooltipContent.length > 0 ? 
            <Tooltip className={Classes.TOOLTIP_INDICATOR + " border-0"} content={<div className="px-2 py-2"><table><tbody>{tooltipContent}</tbody></table></div>} position={PopoverPosition.RIGHT} transitionDuration={50}>
                {name}
            </Tooltip> : name;
    }
}

/** formats an Application location.
 *  @param data the object or string with the data.
 *  @param dataSource the object or string with the data source information.
 *  @param showTooltip a boolean value, true if the tooltip should be displayed, false otherwise.
 *  @return a String with the formatted application. */
export function formatApplicationLocation(data: any, dataSource: any, showTooltip: boolean = false): JSX.Element | string {
    if (typeof data === "string") {
        // We have a string, just output it.
        return data;
    } else {
        let tooltipContent: Array<JSX.Element> = [];
        const name: string = formatApplication(data.application, false) as string + " (" + formatLocation(data.location, "Host", false) + ")";

        if (showTooltip) {
            if (dataSource?.name?.value) {
                tooltipContent.push(<tr key="data-source"><td className="text-end pe-2">{STRINGS.tooltips.dataSource}</td><td>{dataSource?.name?.value}</td></tr>)
            }
            if (data?.application?.name?.value) {
                tooltipContent.push(<tr key="app-name"><td className="text-end pe-2">{STRINGS.tooltips.appName}</td><td>{data?.application?.name?.value}</td></tr>)
            }
            if (data?.application?.type?.value) {
                tooltipContent.push(<tr key="app-type"><td className="text-end pe-2">{STRINGS.tooltips.appType}</td><td>{data?.application?.type?.value}</td></tr>)
            }
            if (data?.application?.version?.value) {
                tooltipContent.push(<tr key="app-version"><td className="text-end pe-2">{STRINGS.tooltips.appVersion}</td><td>{data?.application?.version?.value}</td></tr>)
            }
            if (data?.application?.tags?.value) {
                let items = [];
                try {
                    items = JSON.parse(data.application.tags.value);
                } catch (error) {}
                tooltipContent.push(<tr key="application-tags"><td className="text-end pe-2">{STRINGS.tooltips.tags}</td><td>{items.join(", ")}</td></tr>)
            }
            if (data?.location?.name?.value) {
                tooltipContent.push(<tr key="location"><td className="text-end pe-2">{STRINGS.tooltips.location}</td><td>{data?.location?.name?.value}</td></tr>)
            }
            if (data?.location?.type?.value) {
                tooltipContent.push(<tr key="location-type"><td className="text-end pe-2">{STRINGS.tooltips.locationType}</td><td>{data?.location?.type?.value}</td></tr>)
            }
            if (data?.location?.tags?.value) {
                let items = [];
                try {
                    items = JSON.parse(data.location.tags.value);
                } catch (error) {}
                tooltipContent.push(<tr key="location-tags"><td className="text-end pe-2">{STRINGS.tooltips.tags}</td><td>{items.join(", ")}</td></tr>)
            }
        }

        return tooltipContent.length > 0 ? 
            <Tooltip className={Classes.TOOLTIP_INDICATOR + " border-0"} content={<div className="px-2 py-2"><table><tbody>{tooltipContent}</tbody></table></div>} position={PopoverPosition.RIGHT} transitionDuration={50}>
                {name}
            </Tooltip> : name;
    }
}

/** format an ip.
 *  @param data the object or string with the data.
 *  @param type the type of ip: Host, Client, or Server.  It is used to format any text that need to be displayed to the user.
 *  @param dataSource the object or string with the data source information.
 *  @param showTooltip a boolean value, true if the tooltip should be displayed, false otherwise.
 *  @return a String with the formatted IP. */
export function formatIp(data: any, type: "Device" | "Host" | "Client" | "Server" = "Host", dataSource: any, showTooltip: boolean = false): JSX.Element | string {
    if (typeof data === "string") {
        // We have a string, just output it.
        return data;
    } else {
        let tooltipContent: Array<JSX.Element> = [];
        let name = "";

        // We have an object, find the right fields and output it
        const ip = data?.ipaddr?.value;
        const dns = data?.name?.value;
        const deviceName = data?.device_name?.value;
        const username = data?.username?.value;
        if ((deviceName !== undefined && deviceName !== null && deviceName !== "") || (username !== undefined && username !== null && username !== "")) {
            if (deviceName !== undefined && deviceName !== null && deviceName !== "") {
                // prefer the device name
                name = deviceName;
            } else {
                name = username;
            }
        } else if ((dns !== undefined && dns !== null && dns !== "") || (ip !== undefined && ip !== null && ip !== "")) {
            if ((dns !== undefined && dns !== null && dns !== "") && dns !== ip) {
                name = dns;
            } else {
                name = ip;
            }
        } else {
            name = STRINGS.formatter["unknown" +  type];
        }

        if (ip && name !== ip) {
            name = name + " (" + ip + ")";
        }

        if (showTooltip) {
            // Format the vantage point if it exists
            if (dataSource?.name?.value) {
                tooltipContent.push(<tr key="data-source"><td className="text-end pe-2">{STRINGS.tooltips.dataSource}</td><td>{dataSource?.name?.value}</td></tr>)
            }

            // These properties should exist on all IPs
            if (data?.ipaddr?.value) {
                tooltipContent.push(<tr key="ip-addr"><td className="text-end pe-2">{STRINGS.tooltips.ip}</td><td>{data?.ipaddr?.value}</td></tr>)
            }
            if (data?.name?.value) {
                tooltipContent.push(<tr key="ip-name"><td className="text-end pe-2">{STRINGS.tooltips.name}</td><td>{data?.name?.value}</td></tr>);
            }
            if (data?.device_name?.value) {
                tooltipContent.push(<tr key="ip-device-name"><td className="text-end pe-2">{STRINGS.tooltips.ipDeviceName}</td><td>{data?.device_name?.value}</td></tr>)
            }
            if (data?.location?.name?.value) {
                tooltipContent.push(<tr key="location"><td className="text-end pe-2">{STRINGS.tooltips.location}</td><td>{data.location.name?.value}</td></tr>);
            }

            // These are Aternity properties
            if (data?.username?.value) {
                tooltipContent.push(<tr key="ip-user-name"><td className="text-end pe-2">{STRINGS.tooltips.ipUsername}</td><td>{data?.username?.value}</td></tr>)
            }
            if (data?.device_isp?.value) {
                tooltipContent.push(<tr key="ip-user-device-isp"><td className="text-end pe-2">{STRINGS.tooltips.ipUserDeviceIsp}</td><td>{data?.device_isp?.value}</td></tr>)
            }
            if (data?.user_connection_location?.value) {
                tooltipContent.push(<tr key="ip-user-connection-location"><td className="text-end pe-2">{STRINGS.tooltips.ipUserConnectionLocation}</td><td>{data?.user_connection_location?.value}</td></tr>)
            }
            if (data?.wifi_bssid?.value) {
                tooltipContent.push(<tr key="ip-wifi-bssid"><td className="text-end pe-2">{STRINGS.tooltips.ipWifiBssid}</td><td>{data?.wifi_bssid?.value}</td></tr>)
            }
            if (data?.wifi_channel?.value) {
                tooltipContent.push(<tr key="ip-wifi-channel"><td className="text-end pe-2">{STRINGS.tooltips.ipWifiChannel}</td><td>{data?.wifi_channel?.value}</td></tr>)
            }
            if (data?.wifi_ssid?.value) {
                tooltipContent.push(<tr key="ip-wifi-ssid"><td className="text-end pe-2">{STRINGS.tooltips.ipWifiSsid}</td><td>{data?.wifi_ssid?.value}</td></tr>)
            }
            if (data?.physical_location?.name?.value) {
                tooltipContent.push(<tr key="ip-physical-location-name"><td className="text-end pe-2">{STRINGS.tooltips.ipPhysicalLocationName}</td><td>{data?.physical_location?.name?.value}</td></tr>)
            }
            if (data?.physical_location?.geo?.city?.value) {
                tooltipContent.push(<tr key="ip-physical-location-geo-city"><td className="text-end pe-2">{STRINGS.tooltips.ipPhysicalLocationGeoCity}</td><td>{data?.physical_location?.geo?.city?.value}</td></tr>)
            }
            if (data?.physical_location?.geo?.state?.value) {
                tooltipContent.push(<tr key="ip-physical-location-geo-state"><td className="text-end pe-2">{STRINGS.tooltips.ipPhysicalLocationGeoState}</td><td>{data?.physical_location?.geo?.state?.value}</td></tr>)
            }
            if (data?.physical_location?.geo?.country?.value) {
                tooltipContent.push(<tr key="ip-physical-location-geo-country"><td className="text-end pe-2">{STRINGS.tooltips.ipPhysicalLocationGeoCountry}</td><td>{data?.physical_location?.geo?.country?.value}</td></tr>)
            }
            if (data?.tags?.value) {
                let items = [];
                try {
                    items = JSON.parse(data.tags.value);
                } catch (error) {}
                tooltipContent.push(<tr key="ip-tags"><td className="text-end pe-2">{STRINGS.tooltips.tags}</td><td>{items.join(", ")}</td></tr>)
            }

            // These properties only exist on devices
            if (data?.type?.value) {
                tooltipContent.push(<tr key="type"><td className="text-end pe-2">{STRINGS.tooltips.type}</td><td>{data?.type?.value}</td></tr>)
            }
            if (data?.vendor?.value) {
                tooltipContent.push(<tr key="vendor"><td className="text-end pe-2">{STRINGS.tooltips.vendor}</td><td>{data?.vendor?.value}</td></tr>)
            }
            if (data?.model?.value) {
                tooltipContent.push(<tr key="model"><td className="text-end pe-2">{STRINGS.tooltips.model}</td><td>{data?.model?.value}</td></tr>)
            }
            if (data?.os_version?.value) {
                tooltipContent.push(<tr key="os-version"><td className="text-end pe-2">{STRINGS.tooltips.osVersion}</td><td>{data?.os_version?.value}</td></tr>)
            }
            if (data?.serial_number?.value) {
                tooltipContent.push(<tr key="serial-number"><td className="text-end pe-2">{STRINGS.tooltips.serialNumber}</td><td>{data?.serial_number?.value}</td></tr>)
            }
            if (data?.is_gateway?.value !== undefined && data?.is_gateway?.value !== null && data?.is_gateway?.value.toLowerCase() === "true") {
                tooltipContent.push(<tr key="is-gateway"><td className="text-end pe-2">{STRINGS.tooltips.isGateway}</td><td>{formatBooleanValue(data?.is_gateway?.value)}</td></tr>)
            }
        }

        return tooltipContent.length > 0 ? 
            <Tooltip className={Classes.TOOLTIP_INDICATOR + " border-0"} content={<div className="px-2 py-2"><table><tbody>{tooltipContent}</tbody></table></div>} position={PopoverPosition.RIGHT} transitionDuration={50}>
                {name}
            </Tooltip> : name;
    }
}

/** format a client-server host pair.
 *  @param clientData the object or string with the client data.
 *  @param serverData the object or string with the server data.
 *  @param dataSource the object or string with the data source information.
 *  @param showTooltip a boolean value, true if the tooltip should be displayed, false otherwise.
 *  @return a String with the formatted IP. */
export function formatHostPairs(clientData: any, serverData: any, dataSource: any, showTooltip: boolean = false): JSX.Element | string {
    let tooltipContent: Array<JSX.Element> = [];
    const name: string = formatIp(clientData, "Client", undefined, false) + " - " + formatIp(serverData, "Server", undefined, false);

    if (showTooltip && typeof clientData === "object" && typeof serverData === "object") {
        // Format the vantage point if it exists
        if (dataSource?.name?.value) {
            tooltipContent.push(<tr key="data-source"><td className="text-end pe-2">{STRINGS.tooltips.dataSource}</td><td>{dataSource?.name?.value}</td></tr>)
        }

        // Handle the client properties
        if (clientData?.ipaddr?.value) {
            tooltipContent.push(<tr key="cli-ip-addr"><td className="text-end pe-2">{STRINGS.tooltips.cliIp}</td><td>{clientData?.ipaddr?.value}</td></tr>)
        }
        if (clientData?.name?.value) {
            tooltipContent.push(<tr key="cli-name"><td className="text-end pe-2">{STRINGS.tooltips.cliName}</td><td>{clientData?.name?.value}</td></tr>)
        }
        if (clientData?.location?.name?.value) {
            tooltipContent.push(<tr key="cli-location"><td className="text-end pe-2">{STRINGS.tooltips.cliLocation}</td><td>{clientData.location.name?.value}</td></tr>);
        }

        // Handle the server properties
        if (serverData?.ipaddr?.value) {
            tooltipContent.push(<tr key="srv-ip-addr"><td className="text-end pe-2">{STRINGS.tooltips.srvIp}</td><td>{serverData?.ipaddr?.value}</td></tr>)
        }
        if (serverData?.name?.value) {
            tooltipContent.push(<tr key="srv-name"><td className="text-end pe-2">{STRINGS.tooltips.srvName}</td><td>{serverData?.name?.value}</td></tr>)
        }
        if (serverData?.location?.name?.value) {
            tooltipContent.push(<tr key="srv-location"><td className="text-end pe-2">{STRINGS.tooltips.srvLocation}</td><td>{serverData.location.name?.value}</td></tr>);
        }
    }

    return tooltipContent.length > 0 ? 
        <Tooltip className={Classes.TOOLTIP_INDICATOR + " border-0"} content={<div className="px-2 py-2"><table><tbody>{tooltipContent}</tbody></table></div>} position={PopoverPosition.RIGHT} transitionDuration={50}>
            {name}
        </Tooltip> : name;
}

/** format a client-server host pair with protoport.
 *  @param clientData the object or string with the client data.
 *  @param serverData the object or string with the server data.
 *  @param protoPortData the object or string with the protocol port data.
 *  @param dataSource the object or string with the data source information.
 *  @param showTooltip a boolean value, true if the tooltip should be displayed, false otherwise.
 *  @return a String with the formatted host pair protocol port. */
export function formatHostPairsProtoPort(
     clientData: any, serverData: any, protoPortData: any, dataSource: any, showTooltip: boolean = false
): JSX.Element | string {
    let tooltipContent: Array<JSX.Element> = [];

    let port = null;
    if (protoPortData?.port?.number?.value !== undefined && protoPortData?.port?.number?.value !== null) {
        port = protoPortData.port.number.value;
    } else {
        // Bug 16229
        //port = STRINGS.formatter.unknownPort;
        port = null;
    }

    const name: string = formatHostPairs(clientData, serverData, dataSource, false) + " " + formatProtocol(protoPortData.protocol, false)
        + (port ? ":" + port : "");

    if (showTooltip && typeof clientData === "object" && typeof serverData === "object" && typeof protoPortData === "object") {
        // Handle the client properties
        if (clientData?.ipaddr?.value) {
            tooltipContent.push(<tr key="cli-ip-addr"><td className="text-end pe-2">{STRINGS.tooltips.cliIp}</td><td>{clientData?.ipaddr?.value}</td></tr>)
        }
        if (clientData?.name?.value) {
            tooltipContent.push(<tr key="cli-name"><td className="text-end pe-2">{STRINGS.tooltips.cliName}</td><td>{clientData?.name?.value}</td></tr>)
        }
        if (clientData?.location?.name?.value) {
            tooltipContent.push(<tr key="cli-location"><td className="text-end pe-2">{STRINGS.tooltips.cliLocation}</td><td>{clientData.location.name?.value}</td></tr>);
        }

        // Handle the server properties
        if (serverData?.ipaddr?.value) {
            tooltipContent.push(<tr key="srv-ip-addr"><td className="text-end pe-2">{STRINGS.tooltips.srvIp}</td><td>{serverData?.ipaddr?.value}</td></tr>)
        }
        if (serverData?.name?.value) {
            tooltipContent.push(<tr key="srv-name"><td className="text-end pe-2">{STRINGS.tooltips.srvName}</td><td>{serverData?.name?.value}</td></tr>)
        }
        if (serverData?.location?.name?.value) {
            tooltipContent.push(<tr key="srv-location"><td className="text-end pe-2">{STRINGS.tooltips.srvLocation}</td><td>{serverData.location.name?.value}</td></tr>);
        }

        // Handle the protoport properties
        if (protoPortData?.protocol?.number?.value) {
            tooltipContent.push(<tr key="protocol-number"><td className="text-end pe-2">{STRINGS.tooltips.protocolNumber}</td><td>{protoPortData?.protocol?.number?.value}</td></tr>)
        }
        if (protoPortData?.protocol?.name?.value) {
            tooltipContent.push(<tr key="protocol-name"><td className="text-end pe-2">{STRINGS.tooltips.protocolName}</td><td>{protoPortData?.protocol?.name?.value}</td></tr>)
        }
        if (protoPortData?.port?.number?.value) {
            tooltipContent.push(<tr key="port-number"><td className="text-end pe-2">{STRINGS.tooltips.port}</td><td>{protoPortData?.port?.number?.value}</td></tr>)
        }
    }

    return tooltipContent.length > 0 ? 
        <Tooltip className={Classes.TOOLTIP_INDICATOR + " border-0"} content={<div className="px-2 py-2"><table><tbody>{tooltipContent}</tbody></table></div>} position={PopoverPosition.RIGHT} transitionDuration={50}>
            {name}
        </Tooltip> : name;
}

/** format a location.
 *  @param data the object or string with the data.
 *  @param type the type of ip: Host, Client, or Server.  It is used to format any text that need to be displayed to the user.
 *  @param dataSource the object or string with the data source information.
 *  @param showTooltip a boolean value, true if the tooltip should be displayed, false otherwise.
 *  @return a String with the formatted location. */
export function formatLocation(data: any, type: "Host" | "Client" | "Server" = "Host", dataSource: any, showTooltip: boolean = false): JSX.Element | string {
    if (typeof data === "string") {
        // We have a string, just output it.
        return data;
    } else {
        let tooltipContent: Array<JSX.Element> = [];
        let name = "";

        // We have an object, find the right fields and output it
        if (data?.name?.value) {
            name = data?.name?.value;
        } else {
            name = STRINGS.formatter["unknown" + type + "Location"];
        }

        if (showTooltip) {
            // Format the vantage point if it exists
            if (dataSource?.name?.value) {
                tooltipContent.push(<tr key="data-source"><td className="text-end pe-2">{STRINGS.tooltips.dataSource}</td><td>{dataSource?.name?.value}</td></tr>)
            }

            // These properties should exist on all IPs
            if (data?.name?.value) {
                tooltipContent.push(<tr key="location"><td className="text-end pe-2">{STRINGS.tooltips.location}</td><td>{data?.name?.value}</td></tr>)
            }
            if (data?.tags?.value) {
                let items = [];
                try {
                    items = JSON.parse(data.tags.value);
                } catch (error) {}
                tooltipContent.push(<tr key="location-tags"><td className="text-end pe-2">{STRINGS.tooltips.tags}</td><td>{items.join(", ")}</td></tr>)
            }
        }

        return tooltipContent.length > 0 ? 
            <Tooltip className={Classes.TOOLTIP_INDICATOR + " border-0"} content={<div className="px-2 py-2"><table><tbody>{tooltipContent}</tbody></table></div>} position={PopoverPosition.RIGHT} transitionDuration={50}>
                {name}
            </Tooltip> : name;
    }
}

/** format a client-server location pair.
 *  @param clientData the object or string with the client data.
 *  @param serverData the object or string with the server data.
 *  @param dataSource the object or string with the data source information.
 *  @param showTooltip a boolean value, true if the tooltip should be displayed, false otherwise.
 *  @return a String with the formatted location pair. */
export function formatLocationPairs(clientData: any, serverData: any, dataSource: any, showTooltip: boolean = false): JSX.Element | string {
    let tooltipContent: Array<JSX.Element> = [];
    const name: string = formatLocation(clientData, "Client", false) + " - " + formatLocation(serverData, "Server", false);

    if (showTooltip && typeof clientData === "object" && typeof serverData === "object") {
        // Format the vantage point if it exists
        if (dataSource?.name?.value) {
            tooltipContent.push(<tr key="data-source"><td className="text-end pe-2">{STRINGS.tooltips.dataSource}</td><td>{dataSource?.name?.value}</td></tr>)
        }

        // Handle the client properties
        if (clientData?.name?.value) {
            tooltipContent.push(<tr key="cli-location"><td className="text-end pe-2">{STRINGS.tooltips.cliLocation}</td><td>{clientData.name?.value}</td></tr>);
        }

        // Handle the server properties
        if (serverData?.name?.value) {
            tooltipContent.push(<tr key="srv-location"><td className="text-end pe-2">{STRINGS.tooltips.srvLocation}</td><td>{serverData?.name?.value}</td></tr>)
        }
    }

    return tooltipContent.length > 0 ? 
        <Tooltip className={Classes.TOOLTIP_INDICATOR + " border-0"} content={<div className="px-2 py-2"><table><tbody>{tooltipContent}</tbody></table></div>} position={PopoverPosition.RIGHT} transitionDuration={50}>
            {name}
        </Tooltip> : name;
}

/** format an interface.
 *  @param ifcData the object or string with the interface data.
 *  @param deviceData the object or string with the device data.
 *  @param dataSource the object or string with the data source information.
 *  @param showTooltip a boolean value, true if the tooltip should be displayed, false otherwise.
 *  @return a String with the formatted interface. */
export function formatIfc(ifcData: any, deviceData: any, dataSource: any, showTooltip: boolean = false): JSX.Element | string {
    if (typeof ifcData === "string") {
        // We have a string, just output it.
        return ifcData;
    } else {
        // We have an object, find the right fields and output it
        let tooltipContent: Array<JSX.Element> = [];
        let name = "";

        const ifcName = ifcData?.name?.value;
        const ifcIp = ifcData?.ipaddr?.value;
        const ifcIfIndex = ifcData?.ifindex?.value;
        if (ifcName || (ifcIp && (ifcIfIndex !== undefined && ifcIfIndex !== null))) {
            if (ifcName) {
                name = ifcName;
            } else {
                name = ifcIp + ":" + ifcIfIndex;
            }    
        } else {
            name = STRINGS.formatter.unknownInterface;            
        }

        if (showTooltip) {
            // Format the vantage point if it exists
            if (dataSource?.name?.value) {
                tooltipContent.push(<tr key="data-source"><td className="text-end pe-2">{STRINGS.tooltips.dataSource}</td><td>{dataSource?.name?.value}</td></tr>)
            }

            // These properties should exist on all IPs
            if (ifcData?.ipaddr?.value) {
                tooltipContent.push(<tr key="ip-addr"><td className="text-end pe-2">{STRINGS.tooltips.ip}</td><td>{ifcData?.ipaddr?.value}</td></tr>)
            }
            if (ifcData?.ifindex?.value) {
                tooltipContent.push(<tr key="if-index"><td className="text-end pe-2">{STRINGS.tooltips.ifIndex}</td><td>{ifcData?.ifindex?.value}</td></tr>)
            }
            if (ifcData?.ifalias?.value) {
                tooltipContent.push(<tr key="if-alias"><td className="text-end pe-2">{STRINGS.tooltips.ifAlias}</td><td>{ifcData?.ifalias?.value}</td></tr>)
            }
            if (ifcData?.ifdescr?.value) {
                tooltipContent.push(<tr key="if-descr"><td className="text-end pe-2">{STRINGS.tooltips.ifDescr}</td><td>{ifcData?.ifdescr?.value}</td></tr>)
            }
            if (ifcData?.ifipaddrs?.value) {
                let items = [];
                try {
                    items = JSON.parse(ifcData.ifipaddrs.value);
                } catch (error) {}
                tooltipContent.push(<tr key="if-ips"><td className="text-end pe-2">{STRINGS.tooltips.ifIps}</td><td>{items.join(", ")}</td></tr>)
            }
            tooltipContent.push(<tr key="name"><td className="text-end pe-2">{STRINGS.tooltips.name}</td><td>{name}</td></tr>);
            if (ifcData?.location?.name?.value) {
                tooltipContent.push(<tr key="location"><td className="text-end pe-2">{STRINGS.tooltips.location}</td><td>{ifcData.location.name?.value}</td></tr>);
            }
            if (ifcData?.type?.value) {
                tooltipContent.push(<tr key="type"><td className="text-end pe-2">{STRINGS.tooltips.type}</td><td>{ifcData?.type?.value}</td></tr>)
            }
            if (ifcData?.inbound_speed?.value) {
                const unit = Unit.parseUnit("bps");
                let value: any = parseFloat(ifcData.inbound_speed.value);
                const result = unit.getScaledUnit(value);
                value = value / result.scale + " " + result.unit.getDisplayName();
                tooltipContent.push(<tr key="inbound-speed"><td className="text-end pe-2">{STRINGS.tooltips.inboundSpeed}</td><td>{value}</td></tr>)
            }
            if (ifcData?.outbound_speed?.value) {
                const unit = Unit.parseUnit("bps");
                let value: any = parseFloat(ifcData.outbound_speed.value);
                const result = unit.getScaledUnit(value);
                value = value / result.scale + " " + result.unit.getDisplayName();
                tooltipContent.push(<tr key="outbound-speed"><td className="text-end pe-2">{STRINGS.tooltips.outboundSpeed}</td><td>{value}</td></tr>)
            }

            if (deviceData) {
                if (deviceData?.ipaddr?.value) {
                    tooltipContent.push(<tr key="device-ip-addr"><td className="text-end pe-2">{STRINGS.tooltips.deviceIp}</td><td>{deviceData?.ipaddr?.value}</td></tr>)
                }
                if (deviceData?.name?.value) {
                    tooltipContent.push(<tr key="device-name"><td className="text-end pe-2">{STRINGS.tooltips.deviceName}</td><td>{deviceData?.name?.value}</td></tr>);
                }
                if (deviceData?.location?.name?.value) {
                    tooltipContent.push(<tr key="device-location"><td className="text-end pe-2">{STRINGS.tooltips.deviceLocation}</td><td>{deviceData.location.name?.value}</td></tr>);
                }
                if (deviceData?.type?.value) {
                    tooltipContent.push(<tr key="device-type"><td className="text-end pe-2">{STRINGS.tooltips.deviceType}</td><td>{deviceData?.type?.value}</td></tr>)
                }
                if (deviceData?.vendor?.value) {
                    tooltipContent.push(<tr key="device-vendor"><td className="text-end pe-2">{STRINGS.tooltips.deviceVendor}</td><td>{deviceData?.vendor?.value}</td></tr>)
                }
                if (deviceData?.model?.value) {
                    tooltipContent.push(<tr key="device-model"><td className="text-end pe-2">{STRINGS.tooltips.deviceModel}</td><td>{deviceData?.model?.value}</td></tr>)
                }
                if (deviceData?.os_version?.value) {
                    tooltipContent.push(<tr key="device-os-version"><td className="text-end pe-2">{STRINGS.tooltips.deviceOsVersion}</td><td>{deviceData?.os_version?.value}</td></tr>)
                }
                if (deviceData?.serial_number?.value) {
                    tooltipContent.push(<tr key="device-serial-number"><td className="text-end pe-2">{STRINGS.tooltips.deviceSerialNumber}</td><td>{deviceData?.serial_number?.value}</td></tr>)
                }
                if (deviceData?.is_gateway?.value !== undefined && deviceData?.is_gateway?.value !== null && deviceData?.is_gateway?.value.toLowerCase() === "true") {
                    tooltipContent.push(<tr key="device-is-gateway"><td className="text-end pe-2">{STRINGS.tooltips.deviceIsGateway}</td><td>{formatBooleanValue(deviceData?.is_gateway?.value)}</td></tr>)
                }
            }
        }

        return tooltipContent.length > 0 ? 
            <Tooltip className={Classes.TOOLTIP_INDICATOR + " border-0"} content={<div className="px-2 py-2"><table><tbody>{tooltipContent}</tbody></table></div>} position={PopoverPosition.RIGHT} transitionDuration={50}>
                {name}
            </Tooltip> : name;
    }
}

/** formats the label from a set of NetIM keys.
 *  @param data the object or string with the data.
 *  @return a String with the formatted interface. */
export function formatGenericNetImKeys(data: any): string {
    if (typeof data === "string") {
        // We have a string, just output it.
        return data;
    } else {
        // We have an object, find the right fields and output it
        return data?.name?.value || "";
    }
}

/** convert the keys and data to an object.  Here is an example of the format of the object for a host
 *  {network_host: {
 *      ipaddr: {type: "ipaddr", value: "1.1.1.1"},
 *      name: {type: "string", value: "www.riverbed.com"},
 *      location: {
 *          name: {type: "string", value: "Boston"}
 *      }
 *  }}
 *  @param keyDefs the definition of the keys.
 *  @param dataKeys the values for the keys in the data.
 *  @returns an object with the values. */
export function convertKeysAndDataToOject(keyDefs: Column[], dataKeys: Record<string, string>): KeyObject {
    const outputObject = {};
    for (const column of keyDefs) {
        const fieldNames: Array<string> = column.id.split(".");
        let objLocation = outputObject;
        for (let index = 0; index < fieldNames.length - 1; index++) {
            if (!objLocation[fieldNames[index]]) {
                objLocation[fieldNames[index]] = {};
            }
            objLocation = objLocation[fieldNames[index]];
        }
        objLocation[fieldNames[fieldNames.length -1]] = {type: column.type, value: dataKeys[column.id], isValue: true};
    }
    return outputObject;
}

/** formats a boolean value as Yes or No.
 *  @param value the string or boolean value.
 *  @returns a String with either "Yes" or "No". */
export function formatBooleanValue(value: string | boolean): string {
    if (typeof value === "string") {
        return "true" === value.toLowerCase() ? "Yes" : "No";
    } else {
        return Boolean(value) ? "Yes" : "No";
    }
}

/**
 * Formats the value replacing url strings with a link html element.
 * 
 *  @param value the value returned by the back-end.  
 * 
 *  @returns an html span element that has links instead of url strings value for the column. 
 */
export function markUrlsFromText(value: string | number) {
    if (typeof value !== "string") {
        return value;
    }

    const urlPattern =
        /(http|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?/gm;
    const words = value.split(" ");
    const formattedWords = words.map((word) => {
        const hasLinkInside = urlPattern.test(word); // use test as it is faster than replace;

        if (!hasLinkInside) {
            return word;
        }

        return addMarkup(word);
    });
    const html = formattedWords.join(" ");
    const cellWithLink = (
        <span
            dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(html, {
                    ALLOWED_TAGS: ["a"],
                    ADD_ATTR: ["target", "rel"],
                }),
            }}
        />
    );

    /**
     * 
     * @param word - the word that might contain an URL pattern
     * 
     * @returns a string which contains the replaced url with an html link element
     */
    function addMarkup(word: string) {
        return word.replace(
            urlPattern,
            (match) =>
                `<a href="${match}" target="_blank" rel="noopener noreferrer">${match}</a>`
        );
    }

    return cellWithLink;
}
