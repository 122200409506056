import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
	type ButtonProps,
	DialogStep,
	Intent,
	MultistepDialog,
} from '@blueprintjs/core';
import {
	LoadingOverlay, useStateSafePromise,
} from '@tir-ui/react-components';
import { values, isEmpty, omit } from "lodash";
import { STRINGS } from 'app-strings';
import { BasicAuthDetailsPanel, getAuthMethod } from './BasicAuthDetailsPanel.tsx';
import { type ApiKeyInterface, AuthenticationMethodTypeEnum, type BasicAuthInterface, type OAuth2GrantType, type OAuth2Interface, type ClientCertificateUsingPemFormatInterface, type AWSsignatureInterface, type ProfileInterface, ThirdPartyIntegrationService, type TokenBasedInterface } from 'utils/services/ThirdPartyIntegrationApiService.ts';
import { BasicAuthParamsPanel } from './BasicAuthParamsPanel.tsx';
import { TestQueryPanel } from './TestQueryPanel.tsx';
import { EndpointTypeOptions, EndpointTypePanel } from './EndpointTypePanel.tsx';
import { VerifyResponsePanel } from './VerifyResponsePanel.tsx';
import { ReviewAuthPanel } from './ReviewAuthPanel.tsx';
import { HttpNodeUtil } from 'components/common/graph/editors/http/HttpNodeUtil.ts';
import { ApiKeyAuthDetailsPanel, ApiKeyMethodTypeEnum, getPassingMethod } from './ApiKeyAuthParamsPanel.tsx';
import { useQuery } from "utils/hooks/useQuery.ts";
import { Query } from 'reporting-infrastructure/types/Query.ts';
import { DURATION, durationToRoundedTimeRange } from 'utils/stores/GlobalTimeStore.ts';
import { OAuth2ParamsPanel, getGrantType } from './Oauth2ParamsPanel.tsx';
import { ClientCertificateParamsPanel } from './ClientCertificateParamsPanel.tsx';
import { AWSsignatureAuthParamsPanel } from './AWSsignatureAuthParamsPanel.tsx';
import { TokenBasedAuthParamsPanel } from './TokenBasedAuthParamsPanel.tsx';
import { ConnectorDetailsPanel } from './ConnectorDetailsPanel.tsx';
import type { InstalledIntegrationConnectorDetails, IntegrationConnectorConfig, IntegrationConnectorPayload, IntegrationConnectorPropertyField, IntegrationConnectorPropertyPayload, IntegrationConnectorTag } from '../types/IntegrationTypes.ts';
import { getParameterCaseInsensitive, getRequestBodyFromString } from '../IntegrationLibraryUtils.tsx';
import { CONNECTOR_ENABLED_BY_DEFAULT } from './IntegrationConfigurationModal.tsx';
import EDGE_CONFIG_QUERY from 'pages/edge-configuration/queries/edge-config.graphql';
import './AddAuthenticationModal.scss';

const initialProfileState = { name: '', description: '', authenticationMethod: undefined };
const initialTestQueryState = { verifyHttpMethod: 'GET', verifyUrl: '', verifyRequestBody: '', verifyRequestHeaders: STRINGS.thirdPartyIntegrations.addAuthProfile.panels.testQuery.fields.httpHeaders.defaultValue };
const initialEndpointDetailsState = { endpointType: 'public', edgeDeviceIds: []};

const AddAuthenticationModal = React.forwardRef((props: any, ref) => {
	React.useImperativeHandle(ref, () => ({
        setProfileId(id: string) {
            if (id) {
                setProfileId(id);
            }
        },
        // Pre-set the values from the connector data, we are re-using the auth profile values
        setConnector(data: IntegrationConnectorPayload | InstalledIntegrationConnectorDetails, connectorProfileId?: string) {
			if ((data as IntegrationConnectorPayload)?.authenticationProfile) {
				fillProfileInfo((data as IntegrationConnectorPayload)?.authenticationProfile);
			} else {
				setProfile({ ...profile, name: data?.name })
			}

			// If it is an installed connector we need to retrieve the profile info from the endpoint
			if (connectorProfileId) {
				setProfileId(connectorProfileId);
				setConnectorId((data as InstalledIntegrationConnectorDetails)?.connectorId);
			} else {
				setProfileId('');
				setConnectorId('');
			}

			setSelectedTagsPayload(((data as InstalledIntegrationConnectorDetails)?.tags || []));
			setConnectorCustomProperties(data?.properties);
        },
		setIntegrationId(id: string) {
			setIntegrationId(id)
		},
        setConnectorIndex(index: number) {
            setConnectorIndex(index);
        },
		setIsClone(isClone: boolean) {
			setIsClone(isClone);
		},
        setEdit(edit: boolean, isConnector: boolean = false) {
            setEdit(edit);
            setIsConnector(isConnector);

			if (!edit) {
				resetProfileInfo();

				// Fill info from connector config
				if (isConnector) {
					// If only one auth method available, select it
					preselectAuthMethodForConnector();
					fillVerifyFieldsFromConnectorConfig();
					setConnectorCustomProperties(getInitialCustomProperties());
				}
			}
        },
        handleOpen() {
            setIsOpen(!isOpen);
        },
    }));
	const [profile, setProfile] = useState<ProfileInterface>(initialProfileState);
	const [connectorCustomProperties, setConnectorCustomProperties] = useState<Array<IntegrationConnectorPropertyPayload>>(
		getInitialCustomProperties()
	);
	const [selectedTagsPayload, setSelectedTagsPayload] = useState<Array<IntegrationConnectorTag>>([]);
	const [testQuery, setTestQuery] = useState<any>(initialTestQueryState);
	const [endpointDetails, setEndpointDetails] = useState<any>(initialEndpointDetailsState);
	const [sendRequestTo, setSendRequestTo] = useState<any>('');
	const [profileId, setProfileId] = useState<string>('');
	const [connectorId, setConnectorId] = useState<string>('');
	const [integrationId, setIntegrationId] = useState<string>('');
	const [isConnector, setIsConnector] = useState<boolean>(false);
	const [isClone, setIsClone] = useState<boolean>(false);
	const [connectorIndex, setConnectorIndex] = useState<number>(0);
	const [edit, setEdit] = useState<boolean>(false);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isVerified, setIsVerified] = useState<any>({ verified: null, time: 0 });
	const [isEdit, setIsEdit]= useState<boolean>(false);
	const [executeSafely] = useStateSafePromise();
	const [loading, setLoading] = useState(false);
    const [hasOptionalClientCertificate, setHasOptionalClientCertificate] = useState(false);
	const hasClientCertificateInCloud = useRef(false);
	const [clientCertificateSource, setClientCertificateSource] = useState<string>('ClientCertificateUsingPemFormat');
	const [clientCertificateError, setClientCertificateError] = useState(false);
	const supportedAuthenticationMethods = props?.connectorConfig?.authenticationProfile?.supportedAuthenticationMethods || {};
	const hasSupportedAuthenticationMethods = Object.keys(supportedAuthenticationMethods)?.length > 0;
	const showAuthParamsPanel = (isConnector && hasSupportedAuthenticationMethods) || !isConnector;

	const fetchAuthenticationProfile = useCallback(
		(id: string) => {
			setLoading(true);
			if (isConnector) {
				setLoading(false);
				setProfile(initialProfileState);
			}

			executeSafely(ThirdPartyIntegrationService.getAuthProfile(id)).then(
				(result) => {		
					fillProfileInfo(result);
				},
				(error) => {
					props.handleErrors(error);
				}).finally(
					() => {
						setLoading(false);
					}
				);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[executeSafely]
	);

	const handleSubmit = () => {
		let publicProfile = getPublicProfile(profile);		
		testQuery?.verifyUrl?.trim() === "" && (testQuery.verifyUrl = null); // Bug 11909
		let authenticationMethod = getAuthMethod(publicProfile.authenticationMethod);
		authenticationMethod = authenticationMethod === 'ClientCertificate' ? clientCertificateSource : authenticationMethod;
		let awsSignatureVersion = '';
		let profileCopy = {...publicProfile};
		let useProfileCopy = false;
		if (publicProfile?.sensitiveData && 'awsSignatureVersion' in publicProfile.sensitiveData) {
			awsSignatureVersion = publicProfile.sensitiveData.awsSignatureVersion || '';
		}

		// Has optional client certificate disabled
		if (!hasOptionalClientCertificate && profileCopy?.sensitiveData && 'clientCertificate' in profileCopy?.sensitiveData) {
			useProfileCopy = true;
			delete profileCopy.sensitiveData.clientCertificate
		}

		authenticationMethod = authenticationMethod === 'awsSignature' && awsSignatureVersion ? awsSignatureVersion : authenticationMethod;
		if (profileCopy?.sensitiveData && 'awsSignatureVersion' in profileCopy.sensitiveData) {
			const { awsSignatureVersion, ...rest } = profileCopy.sensitiveData;
			profileCopy.sensitiveData = rest as any;
			useProfileCopy = true;
		}
		// If it is a connector, we are not saving it using the ThirdPartyIntegrationService
		if (isConnector) {
			const payload = getPayloadFromConnector()

			props?.handleAddConnector && props.handleAddConnector(payload, connectorIndex, edit);
			setIsOpen(false);
			handleClose();

			return;
		}

		if (!edit) {
			ThirdPartyIntegrationService.saveAuthProfile(
				{
					...(useProfileCopy ? profileCopy: profile),
					isEnabled: true,
					...testQuery,
					authenticationMethod,
					isVerified: isVerified.verified,
					lastVerifiedOn: new Date(isVerified.time)
				})
				.then(
					(_) => {
						props.refreshData();
						setIsOpen(false);
						handleClose();
					},
					(error) => {
						props.handleErrors(error);
					},
				);
		} else {
			ThirdPartyIntegrationService.updateAuthProfile(profileId,
				{
					...(useProfileCopy ? profileCopy: publicProfile),
					...testQuery,
					authenticationMethod,
					isVerified: (isVerified.verified !== null || isVerified.verified !== undefined) ? isVerified.verified : publicProfile.isVerified,
					lastVerifiedOn: isVerified.time ? new Date(isVerified.time) : publicProfile.lastVerifiedOn
				}).then(
					(_) => {
						props.refreshData();
						setIsOpen(false);
						handleClose();
					},
					(error) => {
						props.handleErrors(error);
						if (authenticationMethod === "ClientCertificateUsingPemFormat") {
							setClientCertificateError(true);
						}
					}
				);
		}

		function getPayloadFromConnector() {
			const customPropsPayload = connectorCustomProperties?.length ? connectorCustomProperties.filter(
				customProp => (customProp.value !== null || customProp.value !== undefined)
			) : [];

			const tagsPayload: Array<IntegrationConnectorTag> = selectedTagsPayload;

			let payload: IntegrationConnectorPayload = {
				name: publicProfile.name,
				isEnabled: CONNECTOR_ENABLED_BY_DEFAULT,
				properties: customPropsPayload,
				tags: tagsPayload
			}

			if (connectorId?.length) {
				payload = {
					...payload,
					connectorId: connectorId
				}
			}

			if (hasSupportedAuthenticationMethods) {
				payload = {
					...payload,
					authenticationProfile: {
						...publicProfile,
						...testQuery,
						authenticationMethod,
						isVerified: (isVerified.verified !== null || isVerified.verified !== undefined) ? isVerified.verified : publicProfile.isVerified,
						lastVerifiedOn: isVerified.time ? new Date(isVerified.time) : publicProfile.lastVerifiedOn
					}
				};
			}

			return payload;
		}
	}

	const { data } = useQuery({
        name: 'EdgeConfig',
        query: new Query(EDGE_CONFIG_QUERY),
        queryVariables: {
            ...(durationToRoundedTimeRange(DURATION.HOUR_1) as any),
        },
    });

	useEffect(() => {
		if (edit && profileId && isOpen) {
			fetchAuthenticationProfile(profileId);
		}
	}, [edit, profileId, isOpen, fetchAuthenticationProfile]);

	const handleClose = useCallback(() => {
		setIsOpen(false);
		resetProfileInfo();
	}, []);

	/**
	 * Automatically select the auth method for the connector if only one is supported
	 */
	function preselectAuthMethodForConnector() {
		if (!(Object.keys(supportedAuthenticationMethods)?.length === 1)) {
			return
		}
		
		const supportedAuthMethod = Object.keys(supportedAuthenticationMethods)[0];

		if (!supportedAuthMethod) {
			return;
		}

		const authMethod = Object.keys(AuthenticationMethodTypeEnum).find(el => el.toLowerCase() === supportedAuthMethod.toLowerCase());

		if (!authMethod) {
			return;
		}
		
		setAuthMethodForConnector((authMethod as unknown) as AuthenticationMethodTypeEnum);
	}

	/**
	 * Fill verify profile fields from connector configuration
	 */
	function fillVerifyFieldsFromConnectorConfig() {
		const verifyProfilePrefill = (props.connectorConfig as IntegrationConnectorConfig).authenticationProfile.verifyProfile;
		const headers = Object.fromEntries(verifyProfilePrefill?.headers.map((el) => {
			return [el.name, el.value];
		}));

		const verifyRequestBody = getRequestBodyFromString(verifyProfilePrefill?.body || '');

		setTestQuery(
			{
				verifyHttpMethod: verifyProfilePrefill?.httpMethod || '',
				verifyUrl: verifyProfilePrefill?.relativeUrl || '',
				verifyRequestBody: verifyRequestBody,
				verifyRequestHeaders: headers && Object.keys(headers)?.length > 0 ?
					HttpNodeUtil.parseHttpHeadersToString(headers || {}) :
					STRINGS.thirdPartyIntegrations.addAuthProfile.panels.testQuery.fields.httpHeaders.defaultValue
			});
	}

	/**
	 * Get the initial custom properties
	 * 
	 * @returns Array with the initial custom properties
	 */
	function getInitialCustomProperties(): IntegrationConnectorPropertyPayload[] | (() => IntegrationConnectorPropertyPayload[]) {
		const properties = (props?.connectorConfig as IntegrationConnectorConfig)?.properties || [];

		return properties.map(prop => {
			return {
				name: prop.name,
				value: `${prop.defaultValue || ''}`
			};
		});
	}

	/**
	 * Reset the profile information
	 */
	function resetProfileInfo() {
		setProfile(initialProfileState);
		setConnectorId('');
		setSelectedTagsPayload([]);
		setTestQuery(initialTestQueryState);
		setEndpointDetails(initialEndpointDetailsState);
		setSendRequestTo('');
		setEdit(false);
		setIsEdit(false);
		setIsVerified({ verified: null, time: 0 });
		setClientCertificateError(false);
		setHasOptionalClientCertificate(false);
		hasClientCertificateInCloud.current = false;
	}

	const handleChangeStep = (newDialogStepId, prevDialogStepId) => {
		// Convert headers to json
		if (prevDialogStepId === "test_query") {
			if (typeof(testQuery.verifyRequestHeaders) ===  'string') {
				setTestQuery({ ...testQuery, verifyRequestHeaders: HttpNodeUtil.parseStringToHttpHeaders(testQuery.verifyRequestHeaders) });
			}
		}
		// Convert json to headers
		if (newDialogStepId === "test_query") {
			if (testQuery.verifyUrl && testQuery.verifyRequestHeaders && Object.keys(testQuery.verifyRequestHeaders).length > 0) {
				setTestQuery({ ...testQuery, verifyRequestHeaders:
					typeof(testQuery.verifyRequestHeaders) !=  'string' ? HttpNodeUtil.parseHttpHeadersToString(testQuery.verifyRequestHeaders) : testQuery.verifyRequestHeaders });
			} else {
				setTestQuery({ ...testQuery, verifyRequestHeaders:
					testQuery.verifyRequestHeaders ? typeof(testQuery.verifyRequestHeaders) !=  'string' ? HttpNodeUtil.parseHttpHeadersToString(testQuery.verifyRequestHeaders) : testQuery.verifyRequestHeaders
					: testQuery.verifyUrl ? STRINGS.thirdPartyIntegrations.addAuthProfile.panels.testQuery.fields.httpHeaders.defaultValue : undefined });
			}
		}
	}

	const finalButtonProps: Partial<ButtonProps> = {
		text: isConnector ? STRINGS.thirdPartyIntegrations.addAuthProfile.buttons.submit.connector : STRINGS.thirdPartyIntegrations.addAuthProfile.buttons.submit.profile,
		intent: Intent.PRIMARY,
		onClick: handleSubmit
	};

	const isNextDisabledForAuthParams = (profile, isEdit, isClone, hasOptionalClientCertificate, hasClientCertificateInCloud) => {
        const authMethod = getAuthMethod(profile?.authenticationMethod);
		const invalidOptionalClientCertificate = hasOptionalClientCertificate && 
			(
				(hasClientCertificateInCloud && !profile?.sensitiveData?.clientCertificate?.certificate) ||
				(!hasClientCertificateInCloud && (!profile?.sensitiveData?.clientCertificate?.certificate || !profile?.sensitiveData?.clientCertificate?.privateKey))
			);
						
		// Basic Authentication
        if (authMethod === AuthenticationMethodTypeEnum[0]) {
            return (!profile?.sensitiveData?.username) || (((!isEdit || isClone) && !profile?.sensitiveData?.password) ?? true);
        }
		// API Key Authentication 
		else if (authMethod === AuthenticationMethodTypeEnum[1]) {
            const passingMethod = getPassingMethod(profile?.sensitiveData?.passingMechanism);
            const isDisabled = (((!isEdit || isClone) && !profile?.sensitiveData?.apiKey) ?? true) ||
                (!passingMethod) ||
                (passingMethod === ApiKeyMethodTypeEnum[0] && !profile?.sensitiveData?.queryStringParamName) ||
                (passingMethod === ApiKeyMethodTypeEnum[1] && !profile?.sensitiveData?.authenticationSchemeName) ||
                (passingMethod === ApiKeyMethodTypeEnum[2] && !profile?.sensitiveData?.customHeaderName) ||
				(passingMethod === ApiKeyMethodTypeEnum[2] && profile?.sensitiveData?.additionalRequestHeaders?.length && 
					(!isEdit || isClone) && 
					profile.sensitiveData.additionalRequestHeaders.find(item => hasEmptyValues(item))) ||
                (passingMethod === ApiKeyMethodTypeEnum[3] && !profile?.sensitiveData?.tokenName) ||
				// Client Certificate Validation	
				(invalidOptionalClientCertificate)

			return isDisabled;
        } 
		// Client Certificate Auth
		else if (authMethod === AuthenticationMethodTypeEnum[3]) {
            return (!profile?.sensitiveData?.certificate) || (((!isEdit || isClone) && !profile?.sensitiveData?.privateKey) ?? true);
        }
		// AWS Auth 
		else if (authMethod === AuthenticationMethodTypeEnum[4]) {
            return (!profile?.sensitiveData?.accessKeyId) 
					|| (((!isEdit || isClone) && !profile?.sensitiveData?.secretAccessKey) ?? true) 
					// || ((profile?.sensitiveData?.awsSignatureVersion === "AwsSignatureV4" && !profile?.sensitiveData?.regionName) ?? true)
					// || (!profile?.sensitiveData?.serviceName ?? true)
					|| (!profile?.sensitiveData?.awsSignatureVersion);
        } 
		// Token Based Auth
		else if (authMethod === AuthenticationMethodTypeEnum[6]) {
			return (
				!profile?.sensitiveData?.accessTokenRequestUrl || 
				(!profile?.sensitiveData?.clientSecretPassingMethod && profile?.sensitiveData?.clientSecretPassingMethod !== 0) ||
				!profile?.sensitiveData?.responseBodyTokenName ||
				(!profile?.sensitiveData?.tokenPassingMethod && profile?.sensitiveData?.tokenPassingMethod !== 0) ||
				(
					["RequestBodyField", 2].includes(profile?.sensitiveData?.clientSecretPassingMethod) && 
					((!edit && !profile?.sensitiveData?.clientSecret) ||
					!profile?.sensitiveData?.clientSecretBodyPlaceholder ||
					!profile?.sensitiveData?.requestBodyContent)
				) ||
				(
					["AuthorizationHeader", 0].includes(profile?.sensitiveData?.clientSecretPassingMethod) && 
					((!edit && !profile?.sensitiveData?.clientSecret) ||
					!profile?.sensitiveData?.clientSecretAuthenticationSchemeName)
				) ||
				(
					["RequestHeader", 1].includes(profile?.sensitiveData?.clientSecretPassingMethod) && 
					(!profile?.sensitiveData?.clientSecretCustomHeaderName ||
					(!edit && !profile?.sensitiveData?.clientSecret))
				) ||
				(
					["AuthorizationHeader", 0].includes(profile?.sensitiveData?.tokenPassingMethod)  && 
					!profile?.sensitiveData?.tokenAuthenticationSchemeName
				) ||
				(
					["RequestHeader", 1].includes(profile?.sensitiveData?.tokenPassingMethod) && 
					!profile?.sensitiveData?.tokenCustomHeaderName
				)
			);
		}
		// OAuth2 Authentication
		else {
			const grantType = getGrantType(profile?.sensitiveData?.grantType);

            return !profile?.sensitiveData || !grantType ||
                ((grantType === "ClientCredentials" &&
                    (!profile?.sensitiveData?.clientId || ((!isEdit || isClone) && !profile?.sensitiveData?.clientSecret) || !profile?.sensitiveData?.serverUri 
                        || (!isEmpty(profile?.sensitiveData?.optionalParameters) && !profile?.sensitiveData?.optionalParameters?.every(x => x.key !== "" && x.value !== "")))) ||
                (grantType === "Password" &&
                    (!profile?.sensitiveData?.userName || ((!isEdit || isClone) && !profile?.sensitiveData?.password) || !profile?.sensitiveData?.serverUri || 
                        (!isEmpty(profile?.sensitiveData?.optionalParameters) && !profile?.sensitiveData?.optionalParameters?.every(x => x.key !== "" && x.value !== "")))) ||
				(grantType === "jwt" && (!profile?.sensitiveData?.serverUri || ((!isEdit || isClone) && !profile?.sensitiveData?.jwtPrivateKey) || (profile?.sensitiveData?.jwtClaims?.length && 
				profile.sensitiveData.jwtClaims.find(item => hasEmptyValues(item)))))) ||
				(invalidOptionalClientCertificate)
        }
    }

	/**
	 * Render the details panel depending if the panel is used for connector / or normal auth profile
	 * 
	 * @param isConnector {boolean}
	 * 
	 * @returns JSX.Element
	 */
	function renderDetailsPanel(isConnector: boolean): JSX.Element {
		return isConnector ?
			<ConnectorDetailsPanel 
				connectorName={profile?.name}
				connectorAuthMethod={profile?.authenticationMethod}
				supportedAuthenticationMethods={supportedAuthenticationMethods}
				onChangeName={(event) => { setProfile({ ...profile, name: event.target.value }) }}
				onChangeAuthMethod={(value) => { setAuthMethodForConnector(value) }}
				profileNameList={props.profileNameList} 
				fields={props.connectorConfig.properties}
				availableTags={props.connectorConfig.tags}
				selectedTags={selectedTagsPayload.map(el => {
					return {
						display: el.value,
						value: el.key
					}
				}) || []}
				onChangeSelectedTags={(tags) => {
					// Make sure the tag label comes from the configuration file
					const tagsPayload = tags.map(tag => {
						const payloadTag: IntegrationConnectorTag = {
							key: tag?.value || 'unknown-key',
							value: tag?.display || 'Unknown Tag',
							isDeprecated: false,
						};

						return payloadTag;
					})

					setSelectedTagsPayload(tagsPayload);
				}}
				customProperties={connectorCustomProperties || []}
				onChangeCustomProperties={(name, value) => {
					setConnectorCustomProperties(
						connectorCustomProperties?.map(prop => {
							if (prop.name === name) {
								return {
									...prop,
									value: value
								}
							}

							return prop;
						})
					)
				}}
			/>
			: <BasicAuthDetailsPanel
				authProfileName={profile?.name}
				authProfileDescription={profile?.description}
				authProfileMethod={profile?.authenticationMethod}
				onChangeName={(event) => { setProfile({ ...profile, name: event.target.value }) }}
				onChangeDescription={(event) => { setProfile({ ...profile, description: event.target.value }) }}
				onChangeAuthMethod={(value) => { setProfile({ ...profile, authenticationMethod: value }) }}
				profileNameList={props.profileNameList}
			/>
	}

	function setAuthMethodForConnector(value: AuthenticationMethodTypeEnum | undefined) {
		if (!value) {
			return
		}

		const sensitiveDataPrefill = getSensitiveDataFromConfig(supportedAuthenticationMethods, value);

		setProfile({
			...profile,
			authenticationMethod: value,
			sensitiveData: { ...sensitiveDataPrefill },
		});
	}

	/**
	 * Fill the profile data
	 * 
	 * @param {ProfileInterface} result
	 */
	function fillProfileInfo(result: ProfileInterface | undefined) {
		if (!result) {
			return;
		}

		const oAuthData = result?.sensitiveData as OAuth2Interface;
		const hasData = !(values(oAuthData).every(isEmpty));
		setIsEdit(hasData);
		const hasClientCertificateData = Boolean(result.sensitiveData && 'clientCertificate' in result?.sensitiveData && (result.sensitiveData?.clientCertificate?.certificate || '').length > 0);
		hasClientCertificateInCloud.current = hasClientCertificateData;
		setHasOptionalClientCertificate(hasClientCertificateData);

		// If oAuth has already set clientId (pre-grantType), fallback to ClientCredentials
		if (result.authenticationMethod === AuthenticationMethodTypeEnum.OAuth2) {
			if (hasData && !oAuthData.hasOwnProperty('grantType')) {
				oAuthData.grantType = 'ClientCredentials';
			}

			setProfile({ ...result, sensitiveData: { ...oAuthData } });
		} else {
			setProfile(result);
		}

		setTestQuery(
			{
				verifyHttpMethod: result?.verifyHttpMethod || '',
				verifyUrl: result?.verifyUrl || '',
				verifyRequestBody: result?.verifyRequestBody || '',
				verifyRequestHeaders: result?.verifyRequestHeaders && Object.keys(result?.verifyRequestHeaders).length > 0 ? HttpNodeUtil.parseHttpHeadersToString(result?.verifyRequestHeaders || "") :
					STRINGS.thirdPartyIntegrations.addAuthProfile.panels.testQuery.fields.httpHeaders.defaultValue
			});
		setIsVerified(
			{
				verified: result?.isVerified,
				time: result?.lastVerifiedOn || 0
			});
		setEndpointDetails(
			{
				endpointType: (result?.edgeDeviceIds && result.edgeDeviceIds?.length) ? EndpointTypeOptions.private : EndpointTypeOptions.public,
				edgeDeviceIds: result?.edgeDeviceIds || []
			});
		setSendRequestTo('');
	}

	/**
	 * Get sensitive data information from configuration
	 * 
	 * @param supportedAuthenticationMethods 
	 * @param value 
	 * 
	 * @returns 
	 */
	function getSensitiveDataFromConfig(supportedAuthenticationMethods: any, value: any) {
		const sensitiveData = getParameterCaseInsensitive(supportedAuthenticationMethods, value);

		return sensitiveData;
	}

	/**
	 * Check if the required custom properties are set in the connector
	 * 
	 * @returns boolean
	 */
	function hasValidRequiredCustomProperties() {
		const requiredProperties = (props?.connectorConfig.properties as IntegrationConnectorPropertyField[] || []).filter(el => {
			return el.isRequired
		});
		
		if (!requiredProperties?.length || !connectorCustomProperties?.length) {
			return true; 
		}
		
		// Every required property should have a value set
		const isValid = requiredProperties.every((requiredProp) => connectorCustomProperties.find(customProp => {
			const isBoolean = (requiredProp.type === "boolean" || requiredProp.type === "bool");

			const check = customProp.name === requiredProp.name && (
						  isBoolean ? !!customProp.value : ((customProp.value as string || '').length > 0));

			return check
		}));

		if (isValid) {
			return true;
		}

		return false;
	}

	/**
	 * Return the auth panel depending on the selected authentication method
	 * 
	 * @returns JSXElement
	 */
	function renderAuthParametersPanel(): JSX.Element {
		const authMethod = getAuthMethod(profile?.authenticationMethod);

        function getInitSensitiveDataByGrantType(type: OAuth2GrantType | undefined, fieldName: string, fieldValue: string) {
			if (type && isConnector && profile.authenticationMethod && typeof authMethod === 'string') {
				const initValues = {
					...profile.sensitiveData
				}
				
				initValues[fieldName] = fieldValue;

				return initValues as OAuth2Interface;
			}

			if (type === 'ClientCredentials') {
				const initValues = {
					grantType: "ClientCredentials",
					clientId: "",
					clientSecret: "",
					scope: "", 
					serverUri: "",
                    optionalParameters: []
				}
				
				initValues[fieldName] = fieldValue;

				return initValues as OAuth2Interface;
			}

			const initValues = {
				grantType: "",
				clientId: "",
				clientSecret: "",
				userName: "",
				password: "",
				scope: "", 
				serverUri: "",
                optionalParameters: []
			}
			
			initValues[fieldName] = fieldValue;

			return initValues as OAuth2Interface;
		}
        
		// Basic Auth
		if (authMethod === AuthenticationMethodTypeEnum[0]) {
			return <BasicAuthParamsPanel
				isEdit={isEdit && !isClone}
				authProfileUsername={profile.sensitiveData && 'username' in profile.sensitiveData ? profile?.sensitiveData?.username : ''}
				authProfilePassword={profile.sensitiveData && 'password' in profile.sensitiveData ? profile?.sensitiveData?.password : ''}
				onChangeUsername={(event) => {
					const sensitiveData: BasicAuthInterface = { ...profile.sensitiveData, username: event.target.value } 
					setProfile({ ...profile, sensitiveData: sensitiveData });
				}}
				onChangePassword={(event) => {
					const sensitiveData: BasicAuthInterface = { ...profile.sensitiveData, password: event.target.value }
					setProfile({ ...profile, sensitiveData: sensitiveData });
				}} />
		}

		// Api Auth
		if (authMethod === AuthenticationMethodTypeEnum[1]) {
			return <ApiKeyAuthDetailsPanel
				isEdit={isEdit && !isClone}
				hasClientCertificateInCloud={hasClientCertificateInCloud.current}
				showClientCertificateFields={hasOptionalClientCertificate}
				onToggleClientCertificateFields={() => {
					setHasOptionalClientCertificate(!hasOptionalClientCertificate)
				}}
				apiKey={profile.sensitiveData && 'apiKey' in profile?.sensitiveData ? profile?.sensitiveData?.apiKey : ''}
				onChangeApiKey={(event) => {
					const sensitiveData: ApiKeyInterface = { ...profile.sensitiveData, apiKey: event.target.value }
					setProfile({ ...profile, sensitiveData: sensitiveData });
				}}
				passingMethod={profile.sensitiveData && 'passingMechanism' in profile?.sensitiveData ? getPassingMethod(profile?.sensitiveData?.passingMechanism) : undefined}
				onChangePassingMethod={(value) => {
					const sensitiveData: ApiKeyInterface = profile.sensitiveData && 'passingMechanism' in profile.sensitiveData
						? { ...profile?.sensitiveData, apiKey: profile.sensitiveData.apiKey, passingMechanism: value, queryStringParamName: '', authenticationSchemeName: '', isEncoded: false, customHeaderName: '', additionalRequestHeaders: [], tokenName: '' }
						: { ...profile?.sensitiveData, apiKey: '', passingMechanism: value, queryStringParamName: '', authenticationSchemeName: '', isEncoded: false, customHeaderName: '', additionalRequestHeaders: [], tokenName: '' };
					setProfile({ ...profile, sensitiveData: sensitiveData });
				}}
				queryStringParameterName={profile.sensitiveData && 'queryStringParamName' in profile?.sensitiveData ? profile?.sensitiveData?.queryStringParamName : ''}
				onChangeQueryStringParameter={(event) => {
					const sensitiveData: ApiKeyInterface =  { ...profile.sensitiveData, queryStringParamName: event.target.value }
					setProfile({ ...profile, sensitiveData: sensitiveData });
				}}
				authenticationScheme={profile.sensitiveData && 'authenticationSchemeName' in profile?.sensitiveData ? profile?.sensitiveData?.authenticationSchemeName : ''}
				onChangeAuthScheme={(value) => {
					const sensitiveData: ApiKeyInterface = { ...profile.sensitiveData, authenticationSchemeName: value }
					setProfile({ ...profile, sensitiveData: sensitiveData });
				}}
				encodedAuthenticationScheme={profile.sensitiveData && 'isEncoded' in profile?.sensitiveData ? profile?.sensitiveData?.isEncoded : false}
				onChangeEncodedAuthScheme={(event) => {
					const sensitiveData: ApiKeyInterface = { ...profile.sensitiveData, isEncoded: event.target.checked }
					setProfile({ ...profile, sensitiveData: sensitiveData });
				}}
				customHeaderName={profile.sensitiveData && 'customHeaderName' in profile?.sensitiveData ? profile?.sensitiveData?.customHeaderName : ''}
				onChangeCustomHeaderName={(event) => {
					const sensitiveData: ApiKeyInterface = { ...profile.sensitiveData, customHeaderName: event.target.value }
					setProfile({ ...profile, sensitiveData: sensitiveData });
				}}
				additionalRequestHeaders={profile.sensitiveData && 'additionalRequestHeaders' in profile?.sensitiveData ? profile?.sensitiveData?.additionalRequestHeaders : []}
				onChangeAdditionalRequestHeaders={(requestHeaders) => {
					const sensitiveData: ApiKeyInterface = { ...profile.sensitiveData, additionalRequestHeaders: requestHeaders }
					setProfile({ ...profile, sensitiveData: sensitiveData });
				}}
				tokenName={profile.sensitiveData && 'tokenName' in profile?.sensitiveData ? profile?.sensitiveData?.tokenName : ''}
				onChangeTokenName={(event) => {
					const sensitiveData: ApiKeyInterface = { ...profile.sensitiveData, tokenName: event.target.value }
					setProfile({ ...profile, sensitiveData: sensitiveData });
				}}
				clientCertificateInPemFormat={profile.sensitiveData && 'clientCertificate' in profile?.sensitiveData ? profile?.sensitiveData?.clientCertificate?.certificate : ''}
				onChangeClientCertificateInPemFormatFromTextField={(event) => {
					let certificateValue = event.target.value;
					certificateValue = certificateValue?.replace(/[\r\n]/gm, '');
					const sensitiveData: ApiKeyInterface = { ...profile.sensitiveData, 
						clientCertificate: {
							...(profile.sensitiveData as ApiKeyInterface)?.clientCertificate,
							certificate: certificateValue
						}
					}
					setProfile({ ...profile, sensitiveData: sensitiveData });
				}}
				onChangeClientCertificateInPemFormatFromFile={
					/* istanbul ignore next */
					async (event) => {
						let certificateValue;
						const fileUploaded = event?.target?.files?.[0];
						if (fileUploaded) {
							certificateValue = await fileUploaded.text();
							certificateValue = certificateValue?.replace(/[\r\n]/gm, '');
							const sensitiveData: ApiKeyInterface = { ...profile.sensitiveData, 
								clientCertificate: {
									...(profile.sensitiveData as ApiKeyInterface)?.clientCertificate,
									certificate: certificateValue
								}
							}
							setProfile({ ...profile, sensitiveData: sensitiveData });
						}
					}
				}
				clientPrivateKey={profile.sensitiveData && 'clientCertificate' in profile?.sensitiveData ? profile?.sensitiveData?.clientCertificate?.privateKey : ''}
				onChangeClientPrivateKeyFromTextField={(event) => {
					let privateKeyValue = event.target.value;
					privateKeyValue = privateKeyValue?.replace(/[\r\n]/gm, '');
					const sensitiveData: ApiKeyInterface = { ...profile.sensitiveData, 
						clientCertificate: {
							...(profile.sensitiveData as ApiKeyInterface)?.clientCertificate,
							privateKey: privateKeyValue
						}
					}
					setProfile({ ...profile, sensitiveData: sensitiveData });
				}}
				onChangeClientPrivateKeyFromFile={
					/* istanbul ignore next */
					async (event) => {
						let privateKeyValue;
						const fileUploaded = event?.target?.files?.[0];
						if (fileUploaded) {
							privateKeyValue = await fileUploaded.text();
							privateKeyValue = privateKeyValue?.replace(/[\r\n]/gm, '');
							const sensitiveData: ApiKeyInterface = { ...profile.sensitiveData, 
								clientCertificate: {
									...(profile.sensitiveData as ApiKeyInterface)?.clientCertificate,
									privateKey: privateKeyValue
								}
							}
							setProfile({ ...profile, sensitiveData: sensitiveData });
						}
					}
				}
				passphraseForPemFormat={profile.sensitiveData && 'clientCertificate' in profile?.sensitiveData ? profile?.sensitiveData?.clientCertificate?.passPhrase : ''}
				onChangePassphraseForPemFormat={(event) => {
					const sensitiveData: ApiKeyInterface = { ...profile.sensitiveData, 
						clientCertificate: {
							...(profile.sensitiveData as ApiKeyInterface)?.clientCertificate,
							passPhrase: event.target.value
						}
					}
					setProfile({ ...profile, sensitiveData: sensitiveData });
				}}
			/>
		}

		// Client Certificate Auth
		if (authMethod === AuthenticationMethodTypeEnum[3]) {
			return <ClientCertificateParamsPanel 
				isEdit={edit && !isClone}
				clientCertificateError={clientCertificateError}
				clientCertificateSource={edit ? 'ClientCertificateUsingPemFormat' : clientCertificateSource} // revisit this check when implementing the PFX certificate auth method
				onChangeClientCertificateSource={(event) => {
					setClientCertificateSource(event.target.value);
				}}
				clientCertificateInPemFormat={profile.sensitiveData && 'certificate' in profile?.sensitiveData ? profile?.sensitiveData?.certificate : ''}
				onChangeClientCertificateInPemFormatFromTextField={(event) => {
					let certificateValue = event.target.value;
					certificateValue = certificateValue?.replace(/[\r\n]/gm, '');
					const sensitiveData: ClientCertificateUsingPemFormatInterface = profile.sensitiveData && 'certificate' in profile.sensitiveData
						? { ...profile.sensitiveData, certificate: certificateValue }
						: { privateKey: '', passPhrase: '', certificate: certificateValue };
					setProfile({ ...profile, sensitiveData: sensitiveData });
				}}
				onChangeClientCertificateInPemFormatFromFile={
					/* istanbul ignore next */
					async (event) => {
						let certificateValue;
						const fileUploaded = event?.target?.files?.[0];
						if (fileUploaded) {
							certificateValue = await fileUploaded.text();
							certificateValue = certificateValue?.replace(/[\r\n]/gm, '');
							const sensitiveData: ClientCertificateUsingPemFormatInterface = profile.sensitiveData && 'certificate' in profile.sensitiveData
								? { ...profile.sensitiveData, certificate: certificateValue }
								: { privateKey: '', passPhrase: '', certificate: certificateValue };
							setProfile({ ...profile, sensitiveData: sensitiveData });
						}
					}
				}
				clientPrivateKey={profile.sensitiveData && 'privateKey' in profile?.sensitiveData ? profile?.sensitiveData?.privateKey : ''}
				onChangeClientPrivateKeyFromTextField={(event) => {
					let privateKeyValue = event.target.value;
					privateKeyValue = privateKeyValue?.replace(/[\r\n]/gm, '');
					const sensitiveData: ClientCertificateUsingPemFormatInterface = { ...profile.sensitiveData, privateKey: privateKeyValue };
					setProfile({ ...profile, sensitiveData: sensitiveData });
				}}
				onChangeClientPrivateKeyFromFile={
					/* istanbul ignore next */
					async (event) => {
						let privateKeyValue;
						const fileUploaded = event?.target?.files?.[0];
						if (fileUploaded) {
							privateKeyValue = await fileUploaded.text();
							privateKeyValue = privateKeyValue?.replace(/[\r\n]/gm, '');
							const sensitiveData: ClientCertificateUsingPemFormatInterface = { ...profile.sensitiveData, privateKey: privateKeyValue }

							setProfile({ ...profile, sensitiveData: sensitiveData });
						}
					}
				}
				passphraseForPemFormat={profile.sensitiveData && 'passPhrase' in profile?.sensitiveData ? profile?.sensitiveData?.passPhrase : ''}
				onChangePassphraseForPemFormat={(event) => {
					const sensitiveData: ClientCertificateUsingPemFormatInterface = { ...profile.sensitiveData, passPhrase: event.target.value }
					setProfile({ ...profile, sensitiveData: sensitiveData });
				}}
			/>
		}

		// AWS Signature Auth
		if (authMethod === AuthenticationMethodTypeEnum[4]) {
			return <AWSsignatureAuthParamsPanel 
				isEdit={edit && !isClone} 
				authMethod={edit && profile.authenticationMethod ? profile.authenticationMethod : ""}
				awsSignatureVersion={profile.sensitiveData && 'awsSignatureVersion' in profile?.sensitiveData ? profile?.sensitiveData?.awsSignatureVersion : ''}
				onChangeAwsSignatureVersion={(event) => {
					const sensitiveData: AWSsignatureInterface = profile.sensitiveData && ('awsSignatureVersion' in profile.sensitiveData || edit)
						? { ...profile.sensitiveData, awsSignatureVersion: event.target.value }
						: {accessKeyId: '', secretAccessKey: '', /*regionName: '', serviceName: '',*/ awsSignatureVersion: event.target.value };
					// if (event.target.value === "AwsSignatureV4A") {
					// 	sensitiveData.regionName = "*";
					// }
					setProfile({ ...profile, sensitiveData: sensitiveData });
				}}
				awsAccessKeyId={profile.sensitiveData && 'accessKeyId' in profile?.sensitiveData ? profile?.sensitiveData?.accessKeyId : ''}
				onChangeAwsAccessKeyId={(event) => {
					let awsSignatureVersion = '';
					if (profile?.sensitiveData && 'awsSignatureVersion' in profile.sensitiveData) {
						awsSignatureVersion = profile.sensitiveData.awsSignatureVersion || '';
					}
					const sensitiveData: AWSsignatureInterface = profile.sensitiveData && 'accessKeyId' in profile.sensitiveData
						? { ...profile.sensitiveData, accessKeyId: event.target.value }
						: { accessKeyId: event.target.value, secretAccessKey: '', /*regionName: '', serviceName: '',*/ awsSignatureVersion };
					setProfile({ ...profile, sensitiveData: sensitiveData });
				}}
				awsSecretAccessKey={(profile?.sensitiveData as AWSsignatureInterface)?.secretAccessKey || ''}
				onChangeAwsSecretAccessKey={(event) => {
					const sensitiveData:AWSsignatureInterface = { ...profile.sensitiveData, secretAccessKey: event.target.value }
					setProfile({ ...profile, sensitiveData: sensitiveData });
				}}
				// awsRegion={profile.sensitiveData && 'regionName' in profile?.sensitiveData ? profile?.sensitiveData?.regionName : ''}
				// onChangeAwsRegion={(item) => {
				// 	let awsSignatureVersion = '';
				// 	if (profile?.sensitiveData && 'awsSignatureVersion' in profile.sensitiveData) {
				// 		awsSignatureVersion = profile.sensitiveData.awsSignatureVersion || '';
				// 	}
				// 	const sensitiveData: AWSsignatureInterface = profile.sensitiveData && 'regionName' in profile.sensitiveData
				// 		? { ...profile.sensitiveData, regionName: item }
				// 		: { accessKeyId: '', secretAccessKey: '', regionName: item, serviceName: '', awsSignatureVersion };
				// 	setProfile({ ...profile, sensitiveData: sensitiveData });
				// }}
				// awsService={profile.sensitiveData && 'serviceName' in profile?.sensitiveData ? profile?.sensitiveData?.serviceName : ''}
				// onChangeAwsService={(item) => {
				// 	let awsSignatureVersion = '';
				// 	if (profile?.sensitiveData && 'awsSignatureVersion' in profile.sensitiveData) {
				// 		awsSignatureVersion = profile.sensitiveData.awsSignatureVersion || '';
				// 	}
				// 	const sensitiveData: AWSsignatureInterface = profile.sensitiveData && 'serviceName' in profile.sensitiveData
				// 		? { ...profile.sensitiveData, serviceName: item }
				// 		: { accessKeyId: '', secretAccessKey: '', regionName: '', serviceName: item, awsSignatureVersion };
				// 	setProfile({ ...profile, sensitiveData: sensitiveData });
				// }}
			/>
		}

		// Token Based Auth
		if (authMethod === AuthenticationMethodTypeEnum[6]) {
			return <TokenBasedAuthParamsPanel 
				isEdit={edit && !isClone} 
				accessTokenRequestUrl={profile.sensitiveData && 'accessTokenRequestUrl' in profile?.sensitiveData ? profile?.sensitiveData?.accessTokenRequestUrl : ''}
				onChangeAccessTokenRequestUrl={(event) => {
					const sensitiveData: TokenBasedInterface = { ...(profile.sensitiveData as TokenBasedInterface), accessTokenRequestUrl: event.target.value }
					setProfile({ ...profile, sensitiveData });
				}}
				clientSecretPassingMethod={profile.sensitiveData && 'clientSecretPassingMethod' in profile?.sensitiveData ? profile?.sensitiveData?.clientSecretPassingMethod : ''}
				onChangeClientSecretPassingMethod={(event) => {
					const sensitiveData: TokenBasedInterface = { ...(profile.sensitiveData as TokenBasedInterface), clientSecretPassingMethod: event.target.value }
					const newProfile = { ...profile, sensitiveData };
					if (["RequestBodyField", 2].includes(event.target.value) && (profile.sensitiveData as TokenBasedInterface)?.clientSecretPassingMethod) {
						delete (newProfile.sensitiveData as TokenBasedInterface)?.clientSecretAuthenticationSchemeName;
						delete (newProfile.sensitiveData as TokenBasedInterface)?.encodeSecret;
						delete (newProfile.sensitiveData as TokenBasedInterface)?.additionalRequestHeaders;
						delete (newProfile.sensitiveData as TokenBasedInterface)?.clientSecretCustomHeaderName;
					} else if (["AuthorizationHeader", 0].includes(event.target.value) && (profile.sensitiveData as TokenBasedInterface)?.clientSecretPassingMethod) {
						delete (newProfile.sensitiveData as TokenBasedInterface)?.clientSecretBodyPlaceholder;
						delete (newProfile.sensitiveData as TokenBasedInterface)?.requestBodyContent;
						delete (newProfile.sensitiveData as TokenBasedInterface)?.additionalRequestHeaders;
						delete (newProfile.sensitiveData as TokenBasedInterface)?.clientSecretCustomHeaderName;
					} else if (["RequestHeader", 1].includes(event.target.value) && (profile.sensitiveData as TokenBasedInterface)?.clientSecretPassingMethod) {
						delete (newProfile.sensitiveData as TokenBasedInterface)?.clientSecretBodyPlaceholder;
						delete (newProfile.sensitiveData as TokenBasedInterface)?.requestBodyContent;
						delete (newProfile.sensitiveData as TokenBasedInterface)?.clientSecretAuthenticationSchemeName;
						delete (newProfile.sensitiveData as TokenBasedInterface)?.encodeSecret;
					}
					setProfile(newProfile);
				}}
				clientSecret={profile.sensitiveData && 'clientSecret' in profile?.sensitiveData ? profile?.sensitiveData?.clientSecret : ''}
				onChangeClientSecret={(event) => {
					const sensitiveData: TokenBasedInterface = { ...(profile.sensitiveData as TokenBasedInterface), clientSecret: event.target.value }
					setProfile({ ...profile, sensitiveData });
				}}
				clientSecretBodyPlaceholder={profile.sensitiveData && 'clientSecretBodyPlaceholder' in profile?.sensitiveData ? profile?.sensitiveData?.clientSecretBodyPlaceholder : ''}
				onChangeClientSecretBodyPlaceholder={(event) => {
					const sensitiveData: TokenBasedInterface = { ...(profile.sensitiveData as TokenBasedInterface), clientSecretBodyPlaceholder: event.target.value }
					setProfile({ ...profile, sensitiveData });
				}}
				requestBodyContent={profile.sensitiveData && 'requestBodyContent' in profile?.sensitiveData ? profile?.sensitiveData?.requestBodyContent : ''}
				onChangeRequestBodyContent={(event) => {
					const sensitiveData: TokenBasedInterface = { ...(profile.sensitiveData as TokenBasedInterface), requestBodyContent: event.target.value }
					setProfile({ ...profile, sensitiveData });
				}}
				responseBodyTokenName={profile.sensitiveData && 'responseBodyTokenName' in profile?.sensitiveData ? profile?.sensitiveData?.responseBodyTokenName : ''}
				onChangeResponseBodyTokenName={(event) => {
					const sensitiveData: TokenBasedInterface = { ...(profile.sensitiveData as TokenBasedInterface), responseBodyTokenName: event.target.value }
					setProfile({ ...profile, sensitiveData });
				}}
				tokenPassingMethod={profile.sensitiveData && 'tokenPassingMethod' in profile?.sensitiveData ? profile?.sensitiveData?.tokenPassingMethod : ''}
				onChangeTokenPassingMethod={(event) => {
					const sensitiveData: TokenBasedInterface = { ...(profile.sensitiveData as TokenBasedInterface), tokenPassingMethod: event.target.value }
					const newProfile = { ...profile, sensitiveData };
					if (["AuthorizationHeader", 0].includes(event.target.value) && (profile.sensitiveData as TokenBasedInterface)?.tokenPassingMethod) {
						delete (newProfile.sensitiveData as TokenBasedInterface)?.tokenCustomHeaderName;
					} else if (["RequestHeader", 1].includes(event.target.value) && (profile.sensitiveData as TokenBasedInterface)?.tokenPassingMethod) {
						delete (newProfile.sensitiveData as TokenBasedInterface)?.tokenAuthenticationSchemeName;
						delete (newProfile.sensitiveData as TokenBasedInterface)?.encodeToken;
					}
					setProfile(newProfile);
				}}
				clientSecretAuthenticationSchemeName={profile.sensitiveData && 'clientSecretAuthenticationSchemeName' in profile?.sensitiveData ? profile?.sensitiveData?.clientSecretAuthenticationSchemeName : ''}
				onChangeClientSecretAuthenticationSchemeName={(event) => {
					const sensitiveData: TokenBasedInterface = { ...(profile.sensitiveData as TokenBasedInterface), clientSecretAuthenticationSchemeName: event.target.value }
					setProfile({ ...profile, sensitiveData });
				}}
				tokenCustomHeaderName={profile.sensitiveData && 'tokenCustomHeaderName' in profile?.sensitiveData ? profile?.sensitiveData?.tokenCustomHeaderName : ''}
				onChangeTokenCustomHeaderName={(event) => {
					const sensitiveData: TokenBasedInterface = { ...(profile.sensitiveData as TokenBasedInterface), tokenCustomHeaderName: event.target.value }
					setProfile({ ...profile, sensitiveData });
				}}
				tokenAuthenticationSchemeName={profile.sensitiveData && 'tokenAuthenticationSchemeName' in profile?.sensitiveData ? profile?.sensitiveData?.tokenAuthenticationSchemeName : ''}
				onChangeTokenAuthenticationSchemeName={(event) => {
					const sensitiveData: TokenBasedInterface = { ...(profile.sensitiveData as TokenBasedInterface), tokenAuthenticationSchemeName: event.target.value }
					setProfile({ ...profile, sensitiveData });
				}}
				encodeSecret={profile.sensitiveData && 'encodeSecret' in profile?.sensitiveData ? profile?.sensitiveData?.encodeSecret : false}
				onChangeEncodeSecret={(event) => {
					const sensitiveData: TokenBasedInterface = { ...(profile.sensitiveData as TokenBasedInterface), encodeSecret: event.target.checked }
					setProfile({ ...profile, sensitiveData });
				}}
				encodeToken={profile.sensitiveData && 'encodeToken' in profile?.sensitiveData ? profile?.sensitiveData?.encodeToken : false}
				onChangeEncodeToken={(event) => {
					const sensitiveData: TokenBasedInterface = { ...(profile.sensitiveData as TokenBasedInterface), encodeToken: event.target.checked }
					setProfile({ ...profile, sensitiveData });
				}}
				additionalRequestHeaders={profile.sensitiveData && 'additionalRequestHeaders' in profile?.sensitiveData ? profile?.sensitiveData?.additionalRequestHeaders : []}
				onChangeAdditionalRequestHeaders={(requestHeaders) => {
					const sensitiveData: TokenBasedInterface = { ...(profile.sensitiveData as TokenBasedInterface), additionalRequestHeaders: requestHeaders }
					setProfile({ ...profile, sensitiveData: sensitiveData });
				}}
				clientSecretCustomHeaderName={profile.sensitiveData && 'clientSecretCustomHeaderName' in profile?.sensitiveData ? profile?.sensitiveData?.clientSecretCustomHeaderName : ''}
				onChangeClientSecretCustomHeaderName={(event) => {
					const sensitiveData: TokenBasedInterface = { ...(profile.sensitiveData as TokenBasedInterface), clientSecretCustomHeaderName: event.target.value }
					setProfile({ ...profile, sensitiveData });
				}}
			/>
		}

		const selectedGrantType = profile.sensitiveData && 'grantType' in profile?.sensitiveData ? profile?.sensitiveData?.grantType : undefined;
		// OAuth
		return <OAuth2ParamsPanel 
			isEdit={isEdit && !isClone}
			hasClientCertificateInCloud={hasClientCertificateInCloud.current}
			showClientCertificateFields={hasOptionalClientCertificate}
			onToggleClientCertificateFields={() => {
				setHasOptionalClientCertificate(!hasOptionalClientCertificate)
			}}
            oA2Data={profile.sensitiveData && 'optionalParameters' in profile?.sensitiveData ? profile?.sensitiveData?.optionalParameters : []}
            onChangeOA2={(customParams) => {
                const sensitiveData = { ...profile.sensitiveData, optionalParameters: customParams.map(cP => omit(cP, "id")) }
                setProfile(() => ({ ...profile, sensitiveData: sensitiveData }));
            }}
			oauth2GrantType={profile.sensitiveData && 'grantType' in profile?.sensitiveData ? getGrantType(profile?.sensitiveData?.grantType) : undefined}
			onChangeOauth2GrantType={(event) => {
				const sensitiveData: OAuth2Interface = getInitSensitiveDataByGrantType(event.target.value as OAuth2GrantType, 'grantType', event.target.value);
                setProfile({ ...profile, sensitiveData: sensitiveData });
			}}
			oauth2ClientId={profile.sensitiveData && 'clientId' in profile?.sensitiveData ? profile?.sensitiveData?.clientId : ''}
			onChangeOauth2ClientId={(event) => {
				const sensitiveData: OAuth2Interface = profile.sensitiveData && 'clientId' in profile.sensitiveData
					? { ...profile.sensitiveData, clientId: event.target.value }
					: getInitSensitiveDataByGrantType(selectedGrantType, 'clientId', event.target.value);
				setProfile({ ...profile, sensitiveData: sensitiveData });
			}}
			oauth2ClientSecret={profile.sensitiveData && 'clientSecret' in profile?.sensitiveData ? profile?.sensitiveData?.clientSecret : ''}
			onChangeOauth2ClientSecret={(event) => {
				const sensitiveData: OAuth2Interface = { ...profile.sensitiveData, clientSecret: event.target.value }
				setProfile({ ...profile, sensitiveData: sensitiveData });
			}}
			oauth2AuthUri={profile.sensitiveData && 'serverUri' in profile?.sensitiveData ? profile?.sensitiveData?.serverUri : ''}
			onChangeOauth2AuthUri={(event) => {
				const sensitiveData: OAuth2Interface = profile.sensitiveData && 'serverUri' in profile.sensitiveData
					? { ...profile.sensitiveData, serverUri: event.target.value }
					: getInitSensitiveDataByGrantType(selectedGrantType, 'serverUri', event.target.value);
				setProfile({ ...profile, sensitiveData: sensitiveData });
			}}
			oauth2Scope={profile.sensitiveData && 'scope' in profile?.sensitiveData ? profile?.sensitiveData?.scope : ''}
			onChangeOauth2Scope={(event) => {
				const sensitiveData: OAuth2Interface = profile.sensitiveData && 'scope' in profile.sensitiveData
					? { ...profile.sensitiveData, scope: event.target.value }
					: getInitSensitiveDataByGrantType(selectedGrantType, 'scope', event.target.value);
				setProfile({ ...profile, sensitiveData: sensitiveData });
			}}
			oauth2Username={profile.sensitiveData && 'userName' in profile.sensitiveData ? profile?.sensitiveData?.userName : ''}
			onChangeUsername={(event) => {
				const sensitiveData: OAuth2Interface = profile.sensitiveData && 'userName' in profile.sensitiveData 
					? { ...profile.sensitiveData, userName: event.target.value } 
					: getInitSensitiveDataByGrantType(selectedGrantType, 'userName', event.target.value);
				setProfile({ ...profile, sensitiveData: sensitiveData });
			}}
			oauth2Password={profile.sensitiveData && 'password' in profile.sensitiveData ? profile?.sensitiveData?.password : ''}
			onChangePassword={(event) => {
				const sensitiveData: OAuth2Interface ={ ...profile.sensitiveData, password: event.target.value } 
				setProfile({ ...profile, sensitiveData: sensitiveData });
			}} 
			clientCertificateInPemFormat={profile.sensitiveData && 'clientCertificate' in profile?.sensitiveData ? profile?.sensitiveData?.clientCertificate?.certificate : ''}
				onChangeClientCertificateInPemFormatFromTextField={(event) => {
					let certificateValue = event.target.value;
					certificateValue = certificateValue?.replace(/[\r\n]/gm, '');
					const sensitiveData: OAuth2Interface = { ...profile.sensitiveData, 
						clientCertificate: {
							...(profile.sensitiveData as OAuth2Interface)?.clientCertificate,
							certificate: certificateValue
						}
					}
					setProfile({ ...profile, sensitiveData: sensitiveData });
				}}
				onChangeClientCertificateInPemFormatFromFile={
					/* istanbul ignore next */
					async (event) => {
						let certificateValue;
						const fileUploaded = event?.target?.files?.[0];
						if (fileUploaded) {
							certificateValue = await fileUploaded.text();
							certificateValue = certificateValue?.replace(/[\r\n]/gm, '');
							const sensitiveData: OAuth2Interface = { ...profile.sensitiveData, 
								clientCertificate: {
									...(profile.sensitiveData as OAuth2Interface)?.clientCertificate,
									certificate: certificateValue
								}
							}
							setProfile({ ...profile, sensitiveData: sensitiveData });
						}
					}
				}
				clientPrivateKey={profile.sensitiveData && 'clientCertificate' in profile?.sensitiveData ? profile?.sensitiveData?.clientCertificate?.privateKey : ''}
				onChangeClientPrivateKeyFromTextField={(event) => {
					let privateKeyValue = event.target.value;
					privateKeyValue = privateKeyValue?.replace(/[\r\n]/gm, '');
					const sensitiveData: OAuth2Interface = { ...profile.sensitiveData, 
						clientCertificate: {
							...(profile.sensitiveData as OAuth2Interface)?.clientCertificate,
							privateKey: privateKeyValue
						}
					}
					setProfile({ ...profile, sensitiveData: sensitiveData });
				}}
				onChangeClientPrivateKeyFromFile={
					/* istanbul ignore next */										
					async (event) => {
						let privateKeyValue;
						const fileUploaded = event?.target?.files?.[0];
						if (fileUploaded) {
							privateKeyValue = await fileUploaded.text();
							privateKeyValue = privateKeyValue?.replace(/[\r\n]/gm, '');
							const sensitiveData: OAuth2Interface = { ...profile.sensitiveData, 
								clientCertificate: {
									...(profile.sensitiveData as OAuth2Interface)?.clientCertificate,
									privateKey: privateKeyValue
								}
							}
							setProfile({ ...profile, sensitiveData: sensitiveData });
						}
					}
				}
				passphraseForPemFormat={profile.sensitiveData && 'clientCertificate' in profile?.sensitiveData ? profile?.sensitiveData?.clientCertificate?.passPhrase : ''}
				onChangePassphraseForPemFormat={(event) => {
					const sensitiveData: OAuth2Interface = { ...profile.sensitiveData, 
						clientCertificate: {
							...(profile.sensitiveData as OAuth2Interface)?.clientCertificate,
							passPhrase: event.target.value
						}
					}
					setProfile({ ...profile, sensitiveData: sensitiveData });
				}}
				jwtClaims={profile.sensitiveData && 'jwtClaims' in profile?.sensitiveData ? profile?.sensitiveData?.jwtClaims : []}
				onChangeJWTClaims={(jwtClaims) => {
					const sensitiveData: OAuth2Interface = { ...(profile.sensitiveData as OAuth2Interface), jwtClaims }
					setProfile({ ...profile, sensitiveData: sensitiveData });
				}}
				jwtPrivateKey={profile.sensitiveData && 'jwtPrivateKey' in profile?.sensitiveData ? profile?.sensitiveData?.jwtPrivateKey : ''}
				onChangeJWTPrivateKey={(event) => {
					const sensitiveData: OAuth2Interface = { ...(profile.sensitiveData as OAuth2Interface), jwtPrivateKey: event.target.value }
					setProfile({ ...profile, sensitiveData });
				}}
				onChangeJWTPrivateKeyFromFile={
					/* istanbul ignore next */
					async (event) => {
						let privateKeyValue;
						const fileUploaded = event?.target?.files?.[0];
						if (fileUploaded) {
							privateKeyValue = await fileUploaded.text();
							privateKeyValue = privateKeyValue?.replace(/[\r\n]/gm, '');
							const sensitiveData: OAuth2Interface = { ...(profile.sensitiveData as OAuth2Interface), jwtPrivateKey: privateKeyValue }
							setProfile({ ...profile, sensitiveData: sensitiveData });
						}
					}
				}
				serverUri={profile.sensitiveData && 'serverUri' in profile?.sensitiveData ? profile?.sensitiveData?.serverUri : ''}
				onChangeServerUri={(event) => {
					const sensitiveData: OAuth2Interface = { ...(profile.sensitiveData as OAuth2Interface), serverUri: event.target.value }
					setProfile({ ...profile, sensitiveData });
				}}
		/>
	}

	/**
	 * Get the public profile data (without sensitive data, as passwords/client secrets)
	 * @param profile 
	 * 
	 * @returns {Object}
	 */
	function getPublicProfile(profile: ProfileInterface) {
		const SENSITIVE_DATA_FIELDS = ['password', 'apiKey', 'api_key', 'Key', 'secretAccessKey', 'aws_secret_access_key', 'profile_password', 'passphrase_for_pem_format', 'client_private_key', 'clientSecret'];
		let publicProfile = { ...profile };

		if (profile?.sensitiveData) {
			const filteredSensitiveData = Object.fromEntries(
				Object.entries(profile.sensitiveData).filter(
					([key]) => {
						const isSecretField = SENSITIVE_DATA_FIELDS.includes(key);
						const hasValue = (JSON.stringify(profile?.sensitiveData).indexOf(`"${key}":`) > -1) && profile?.sensitiveData![key].length > 0;
						const shouldBeIncluded = !isSecretField || (isSecretField && hasValue);

						return shouldBeIncluded;
					}
				)
			);

			publicProfile = { ...profile, sensitiveData: filteredSensitiveData };
		}

		return publicProfile;
	}

	/**
	 * Handle the http method verb change
	 */
	function handleVerbChange(event: any) {
		const method = event.target.value;
		if (method === 'GET') {
			// reset the request body, if set to GET
			setTestQuery({ ...testQuery, verifyRequestBody: '', verifyHttpMethod: event.target.value})

			return;
		}

		setTestQuery({ ...testQuery, verifyHttpMethod: event.target.value });
	}

	// Check if all required custom properties have been set
	let isNextDisabledForRequiredCustomProps = isConnector && !hasValidRequiredCustomProperties();

	return (
		<React.Fragment>
			<LoadingOverlay visible={loading} />
			<div>
				<MultistepDialog
					className="authentication-profile-modal main-content"
					onClose={handleClose}
					finalButtonProps={finalButtonProps}
					title={
						isConnector ? STRINGS.INTEGRATIONS_PAGE.integrationDetailsModal.title :
						edit
							? STRINGS.thirdPartyIntegrations.addAuthProfile
								.editTitle
							: STRINGS.thirdPartyIntegrations.addAuthProfile
								.title
					}
					autoFocus={true}
					canEscapeKeyClose={true}
					canOutsideClickClose={false}
					enforceFocus={true}
					isCloseButtonShown={true}
					isOpen={isOpen}
					resetOnClose={true}
					onChange={handleChangeStep}
				>
					<DialogStep
						id="basic_details"
						className="panel-content"
						panel={renderDetailsPanel(isConnector)}
						title={
							STRINGS.thirdPartyIntegrations.addAuthProfile.panels
								.basicDetails.title
						}
						nextButtonProps={{
							disabled:
								!profile ||
								profile.name === '' ||
								profile.name?.length > 128 ||
								profile.description?.length > 256 ||
								(isNextDisabledForRequiredCustomProps) ||
								(isConnector && hasSupportedAuthenticationMethods && profile.authenticationMethod === undefined) ||
								(!isConnector && profile.authenticationMethod === undefined) ||
								(props.profileNameList.includes(profile.name) && !edit)
						}}
					/>
					
					{showAuthParamsPanel &&
						<DialogStep
							id="basic_auth_params"
							className="panel-content"
							panel={ renderAuthParametersPanel() }
							title={
								STRINGS.thirdPartyIntegrations.addAuthProfile.panels
									.basicAuthParams.stepTitle
							}
							backButtonProps={{
								disabled: !isEmpty((profile?.sensitiveData as OAuth2Interface)?.optionalParameters) &&
									getAuthMethod(profile.authenticationMethod) === AuthenticationMethodTypeEnum[2] &&
									// @ts-ignore
									!profile.sensitiveData.optionalParameters.every(x => x.key !== "" && x.value !== "")
							}}
							nextButtonProps={{
								disabled: showAuthParamsPanel && isNextDisabledForAuthParams(
									profile, 
									Boolean(isEdit), 
									Boolean(isClone), 
									hasOptionalClientCertificate,
									hasClientCertificateInCloud.current
								)
							}}
						/>
					}	

					{!isConnector &&
						<DialogStep
							id="public_private_endpoint"
							className="panel-content"
							panel={
								<EndpointTypePanel
									alluvioEdgeData={data}
									endpointDetails={endpointDetails}
									onChangeEndpointType={(event) => {
										setEndpointDetails({...endpointDetails, endpointType: event.target.value});
										if ( event && event.target.value === EndpointTypeOptions.public) {
											setProfile({...profile, edgeDeviceIds: []});
										} else if (event && event.target.value === EndpointTypeOptions.private) {
											setProfile({...profile, edgeDeviceIds: endpointDetails?.edgeDeviceIds || []})
										}
									}}
									onAddPrivateEndpoint={(event) => {
										setEndpointDetails({...endpointDetails, edgeDeviceIds : [...endpointDetails.edgeDeviceIds, event]});
										setProfile({...profile, edgeDeviceIds: [...(profile.edgeDeviceIds || []), event]});
									}}
									onDeletePrivateEndpoint={(event) => {
										setEndpointDetails({...endpointDetails, edgeDeviceIds : endpointDetails.edgeDeviceIds.filter(item => item !== event)});
										setProfile({...profile, edgeDeviceIds: profile.edgeDeviceIds?.filter((item => item !== event))});
									}}
								/>
							}
							title={
								STRINGS.thirdPartyIntegrations.addAuthProfile.panels
									.endpointType.stepTitle
							}
							nextButtonProps={{
								disabled:
									!endpointDetails ||
									(endpointDetails?.endpointType === 'private'  && endpointDetails?.edgeDeviceIds?.length === 0)
							}}
						/>
					}

					{showAuthParamsPanel &&
						<DialogStep
							id="test_query"
							className="panel-content"
							panel={
								<TestQueryPanel
									connectorCustomProperties={connectorCustomProperties}
									testQuery={testQuery}
									onChangeVerb={handleVerbChange}
									onChangeUrl={(event) => { setTestQuery({ ...testQuery, verifyUrl: event.target.value,
											verifyRequestHeaders: testQuery.verifyRequestHeaders ? testQuery.verifyRequestHeaders :
											STRINGS.thirdPartyIntegrations.addAuthProfile.panels.testQuery.fields.httpHeaders.defaultValue}) }}
									onChangeHeaders={(event) => { setTestQuery({ ...testQuery, verifyRequestHeaders: event.target.value }) }}
									onChangePayload={(event) => { setTestQuery({ ...testQuery, verifyRequestBody: event.target.value }) }}
								/>
							}
							title={
								STRINGS.thirdPartyIntegrations.addAuthProfile.panels
									.testQuery.stepTitle
							}
						/>
					}
					{/* BlueprintJS won't count the panel in the left menu if it is not at the top level */}
					{showAuthParamsPanel &&
						<DialogStep
							id="verify_response"
							className="panel-content"
							panel={
								<VerifyResponsePanel
									alluvioEdgeData={data}
									profile={profile}
									query={testQuery}
									sendRequestTo={sendRequestTo}
									onChangeSendRequestTo={(event) => setSendRequestTo(event)}
									setVerified={(verified) => {
										setIsVerified(verified);
									}}
									hasClientCertificateSet={hasOptionalClientCertificate}
									connectorCustomProperties={connectorCustomProperties}
									isEdit={isEdit}
								/>
							}
							title={
								STRINGS.thirdPartyIntegrations.addAuthProfile.panels
									.verifyResponse.title
							}
						/>
					}

					<DialogStep
						id="review"
						className="panel-content"
						panel={
							<ReviewAuthPanel
								profileName={profile.name}
								profileDescription={profile.description}
								authMethod={profile.authenticationMethod}
								edgeDeviceIds={profile.edgeDeviceIds}
								verified={isVerified}
								isEdit={edit}
								isConnector={isConnector}
								integrationId={integrationId}
								isConnectorExisting={!!connectorId}
							/>
						}
						title={
							STRINGS.thirdPartyIntegrations.addAuthProfile.panels
								.review.title
						}
					/>
				</MultistepDialog>
			</div>
		</React.Fragment>
	);
});

export { AddAuthenticationModal };


/** Checks if an object has any key with an empty value.
 *  @param obj the object passed in.
 *  @returns true if the object has any key with an empty value or false otherwise.*/
function hasEmptyValues(obj) {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (
                value === null ||
                value === undefined ||
                value === '' ||
                (Array.isArray(value) && value.length === 0) ||
                (typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length === 0)
            ) {
                return true;
            }
        }
    }
    return false;
}

