import { PageWithHeader } from "components/sdwan/layout/page-with-header/PageWithHeader.tsx";
import { STRINGS } from "app-strings";
import { IconNames } from "@tir-ui/react-components";
import { FeatureFlag } from "components/common/feature-flag/FeatureFlag.tsx";
import CloudIMLegal from "./views/cloudim-legal";
import { CloudIMRulesView } from "./views/cloudim-rules/CloudIMRulesView.tsx";

const CloudIMRulesPage = (): JSX.Element => {
    return (
        <FeatureFlag flagName="cloudim">
            <CloudIMLegal />
            <PageWithHeader
                name={CloudIMRulesPage.name}
                title={STRINGS.cloudimRules.pageTitle}
                icon={IconNames.DOCUMENT}
                showGlobalFilters={false}
                className="overflow-hidden"
                addPadding={false}
                showTimeBar={false}
                helpInfo={{
                    "comment": "Monitor your cloud assets by setting rules.",
                    "helpSummary": "Rules are applied on all incoming cloud assets data. It is used to perform certain actions based on the conditions set for each cloud asset.",
            		"showMore": true,
                    "url": "/IQ/Content/cloudIM_support.htm"
                }}
            >
                <CloudIMRulesView />
            </PageWithHeader>
        </FeatureFlag>
    );
};

export default CloudIMRulesPage;
