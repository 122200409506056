/** This module contains the component for rendering the Edge card.
 *  @module
 */
import React from 'react';
import { Icon, IconNames } from '@tir-ui/react-components';
import { SIZE } from 'components/enums/Sizes.ts';
import { GetURLPath as getURLPath } from 'config/routes.ts';
import { SummaryCard } from 'components/common/layout/summary-card/SummaryCard.tsx';
import { HealthStatuses, HEALTH_STATUSES } from 'pages/health-monitor/views/HealthMonitorCardsView.tsx';
import { useQuery } from "utils/hooks/useQuery.ts";
import { Query } from 'reporting-infrastructure/types/Query.ts';
import { DURATION, durationToRoundedTimeRange, } from 'utils/stores/GlobalTimeStore.ts';
import { DataLoadFacade } from 'components/reporting/data-load-facade/DataLoadFacade.tsx';
import { STRINGS } from 'app-strings';
import ALLUVIO_EDGES from './alluvio-edges.graphql';

/** Renders the Edge card.
 *  @returns JSX with the Edge card.*/
const AlluvioEdgesCard = (): JSX.Element => {
	const { loading, data, error } = useQuery({
		name: 'AlluvioEdges',
		query: new Query(ALLUVIO_EDGES),
		// @ts-ignore
		queryVariables: {
			...durationToRoundedTimeRange(DURATION.DAY_1),
		},
	});

	let edgesCount = 0;
	let edgesConnected = 0;
	let edgesNotConnected = 0;
	let dataSourcesNotConnected = 0;
	let dataSourceCount = 0;

	if (!loading && data && 'edges' in data) {
		(data['edges'] as []).forEach((element) => {
			element['connected'] && edgesConnected++;
			if ((element['dataSources'] as []).length > 0) {
				(element['dataSources'] as []).forEach((dataSource) => {
					dataSourceCount++;
					dataSource['status'] === 'FAILED' &&
						dataSourcesNotConnected++;
				});
			}
		});
		edgesCount = (data['edges'] as []).length;
		edgesNotConnected = edgesCount - edgesConnected;
	}

	let statusState: string;
	if (edgesConnected === 0 && edgesNotConnected === 0) {
		statusState = HEALTH_STATUSES.UNKNOWN;
	} else if (
		(edgesNotConnected > 0 && edgesNotConnected < edgesCount) ||
		(dataSourcesNotConnected > 0 &&
			dataSourcesNotConnected < dataSourceCount)
	) {
		statusState = HEALTH_STATUSES.PARTIAL_OUTAGE;
	} else if (
		edgesNotConnected === edgesCount ||
		dataSourcesNotConnected === dataSourceCount
	) {
		statusState = HEALTH_STATUSES.MAJOR_OUTAGE;
	} else {
		statusState = HEALTH_STATUSES.OPERATIONAL;
	}

	return (
		<React.Fragment>
			<DataLoadFacade
				key="edges"
				loading={loading}
				error={error}
				data={data}
			>
				<SummaryCard
					title={
						<span className="text-black">
							{
								STRINGS.HEALTH_MONITORING_PAGE.cards
									.alluvioEdges.title
							}
						</span>
					}
					size="flex"
					height={SIZE.m}
					className="w-min-2"
					footerContent={
						<React.Fragment>
							<a
								href={getURLPath('edge-configuration')}
								className="text-decoration-underline"
								target="_blank"
								rel="noreferrer"
							>
								<Icon
									icon={HealthStatuses[statusState].icon}
									size={14}
									className={
										'me-2 align-middle tile-icon ' +
										HealthStatuses[statusState].classname
									}
								/>
								{HealthStatuses[statusState].name}
							</a>
						</React.Fragment>
					}
				>
					<div className="mt-3">
						<span
							title={
								STRINGS.HEALTH_MONITORING_PAGE.cards
									.alluvioEdges.tooltips.edgesUp
							}
							style={{ whiteSpace: 'nowrap' }}
							className="m-3"
						>
							<Icon
								icon={IconNames.TICK_CIRCLE}
								size={14}
								className="me-2 align-middle tile-icon text-success"
							/>
							{edgesConnected}
						</span>
						{edgesNotConnected > 0 ? (
							<span
								title={
									STRINGS.HEALTH_MONITORING_PAGE.cards
										.alluvioEdges.tooltips.edgesDown
								}
								style={{ whiteSpace: 'nowrap' }}
								className="m-3"
							>
								<Icon
									icon={IconNames.ISSUE}
									size={14}
									className="me-2 align-middle tile-icon text-danger"
								/>
								{edgesNotConnected}
							</span>
						) : null}
					</div>
				</SummaryCard>
			</DataLoadFacade>
		</React.Fragment>
	);
};

export { AlluvioEdgesCard };
